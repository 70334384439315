import React from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import moment from 'moment';

const icon = 'https://dflportal.com/pickupTruck.png'

const calculateVisitFrequency = (frequency) => {
    let numberOfVisits = 0
    switch (frequency) {
        case 'weekly':
            numberOfVisits = 52
            break;
        case 'biweekly':
            numberOfVisits = 26
            break;
        case 'monthly':
            numberOfVisits = 12
            break;
    }
    return numberOfVisits / 12
}

const calculateOurDollarsPerHours = (item) => {
    if (item && item.estMinutes) {
        let frequency = item.frequency
        let numberOfVisitsPerYear = calculateVisitFrequency(frequency)
        let estMinutes = item.estMinutes
        // let totalMinutesOfWorkAnnually = Number(estMinutes) * Number(numberOfVisitsPerYear)
        let hour = estMinutes / 60
        console.log('Hours of work:', hour)
        let totalHoursPerMonth = numberOfVisitsPerYear * hour
        let amt = item.price
        let dolhr = amt / totalHoursPerMonth
        console.log('$ dollars per hour', dolhr)
        dolhr = parseFloat(dolhr).toFixed(2)
        return dolhr
    }
    return null
}

const CusomMarker = (props) => {
    console.log('Custom Marker: ', props)
    const [state, setState] = React.useState({
        showInfoWindow: false,
        stopHide: false
    })
    let dolhr = calculateOurDollarsPerHours(props.jobsite)
    const handleMouseOver = e => {
        setState(prevState => ({
            ...prevState,
            showInfoWindow: true
        }));
     ;
        // if (props.addy) {
        //     props.updateActiveMarker(props.addy)
        // }
        // if (props.activity) {
        //     props.updateActiveMarker(props.activity)
        // }
        if (props.jobSite) props.updateActiveMarker(props.jobSite)
    };
    const handleMouseExit = e => {
        setTimeout(() => {
            if (!state.stopHide) {
                setState(prevState => ({
                    ...prevState,
                    showInfoWindow: false
                }));
            }
        }, 2000)


    };
    const handleInfoWindowMouseOver = e => {
        setState(prevState => ({
            ...prevState,
            showInfoWindow: true,
            stopHide: true
        }));

    }
    const handleInfoWindowMouseExit = e => {
        setState(prevState => ({
            ...prevState,
            stopHide: false
        }));
        // call handleMouseExit to start the delay before hiding the InfoWindow
        handleMouseExit();
    };

    const ourPos = props.position

    if (ourPos && ourPos.lat && ourPos.lng) {
        const lat = Number(ourPos.lat)
        const lng = Number(ourPos.lng)
        let theColor = props.color
        if (props.jobsite && props.jobsite.color) theColor = props.jobsite.color
        let scale = 6
        let strokeColor = 'black'
        // if (ourPos._id === activeMarker._id) {
        //     // console.log('This is our boy!!!', addy)
        //     theColor = '#fff'
        //     scale = 9
        //     strokeColor = 'red'
        // }
        //  { lat, lng } = addy
        // console.log('lat: ', lat)
        // console.log('lng: ', lng)
        return (
            <Marker style={{ transform: 'rotate(45)' }} position={{ lat, lng }} icon={{
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: scale,
                fillColor: theColor,
                fillOpacity: 0.8,
                strokeWeight: 2,
                rotation: 0,
                strokeColor: strokeColor
            }} 
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseExit}>
                {(state.showInfoWindow && props.jobsite) ? (
                    <InfoWindow 
                        onMouseOver={handleInfoWindowMouseOver}
                        onMouseOut={handleInfoWindowMouseExit}
                    >
                       <>
                            <h4>Client: <a style={{ cursor: 'pointer' }} href={`/clients/${props.jobsite.clientId}/Maintenance/${props.jobsite.clientId}`} target="_blank">{props.jobsite.clientName}</a></h4>
                            <h4>Crew: {props.jobsite.crewName} </h4>
                            <h4>Est Minutes: {props.jobsite.estMinutes} (${props.jobsite.price}) - ${dolhr}</h4>
                            <h4>Preferred Day: {props.jobsite.day} </h4>
                            <h4>Frequency: {props.jobsite.frequency} 
                                {
                                    (props.jobsite.frequency === 'biweekly') ? `(${props.jobsite.week})` : null
                                }
                                 </h4>
                        </>
                    </InfoWindow>
                ) : null
                   }
               
            </Marker>
        );
    } else {
        return null
    }


}

export default React.memo(CusomMarker)