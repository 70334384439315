import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, CircularProgress }  from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import LaborRates from './LaborRates'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  }
}));

const defaultRates = {
  notes: '',
  treeWorkerRate: '',
  operatorRate: '',
  laborRate: '',
  sprayTechRate: '',
  supervisorRate: '',
  irrigationTechRate: '',
  arboristRate: ''
}

const EditCustomerModal = (props) => {
  // console.log('EditCustomerModal Modal: ', props)
  const classes = useStyles()
  const [loading, setLoading] = React.useState(false)
  const [client, setClient] = React.useState({})
  const [state, setState] = React.useState({
    displayName: props.client.displayName,
    email: props.client.email,
    firstName: props.client.firstName,
    lastName: props.client.lastName,
    companyName: props.client.companyName,
    initials: props.client.initials,
    address: props.client.address,
    address2: props.client.address2,
    city: props.client.city,
    state: props.client.state,
    zipcode: props.client.zip,
    telephone: props.client.phone,
    client,
    HOA: props.client.HOA,
    paperInvoice: props.client.paperInvoice,
    contractor: props.client.contractor,
    government: props.client.government,
    builder: props.client.builder,
    autopayAll: props.client.autopayAll,
    autopayMTC: props.client.autopayMTC,
    doNotEmail: props.client.doNotEmail,
    active: props.client.active,
    testClient: props.client.testClient,
    hydrawise: props.client.hydrawise,
    requiresWorkorder: props.client.requiresWorkorder,
    collectionNotifications: props.client.collectionNotifications,
    invoiceFirstLine: props.client.invoiceFirstLine,
    laborRates: { ...props.client.laborRates, ...defaultRates },
    hmiPastDueStatements: props.client.hmiPastDueStatements,
    requiresHoursAndRates: props.client.requiresHoursAndRates || false,
    displayJobSiteNameAndBuildingNumber: props.client.displayJobSiteNameAndBuildingNumber || false
  })

  React.useEffect(() => {
    // console.log('Use Effect Edit Customer MODAL', props)
    setClient(props.client)
    let laborRates = props.client.laborRates
    if (laborRates && laborRates.laborRate) {
      laborRates = props.client.laborRates
    } else { 
      laborRates = {...defaultRates}
    }
    // console.log('Set labor rates', laborRates)
    setState({
      displayName: props.client.displayName,
      firstName: props.client.firstName,
      lastName: props.client.lastName,
      companyName: props.client.companyName,
      initials: props.client.initials,
      email: props.client.email,
      address: props.client.address,
      address2: props.client.address2,
      city: props.client.city,
      state: props.client.state,
      testClient: props.client.testClient,
      zipcode: props.client.zip,
      telephone: props.client.phone,
      client: props.client,
      HOA: props.client.HOA,
      paperInvoice: props.client.paperInvoice,
      contractor: props.client.contractor,
      government: props.client.government,
      builder: props.client.builder,
      autopayAll: props.client.autopayAll,
      autopayMTC: props.client.autopayMTC,
      doNotEmail: props.client.doNotEmail,
      active: props.client.active,
      hydrawise: props.client.hydrawise,
      requiresWorkorder: props.client.requiresWorkorder,
      collectionNotifications: props.client.collectionNotifications,
      invoiceFirstLine: props.client.invoiceFirstLine,
      laborRates: laborRates,
      hmiPastDueStatements: props.client.hmiPastDueStatements,
      requiresHoursAndRates: props.client.requiresHoursAndRates,
      displayJobSiteNameAndBuildingNumber: props.client.displayJobSiteNameAndBuildingNumber
    })
  }, [props.client])

  const handleClose = () => {
    // console.log('Closing invoice modal')
    setLoading(false)
    props.updatedClient('Close')
  }

  // const handleEmailInvoice = () => {
  //   // console.log('Email Invoice')
  //   setLoading(true)
  //   let updateObject = {
  //     client: props.client,
  //     pdf: pdf,
  //     user: props.user
  //   }
  //   axios({
  //     method: 'post',
  //     url:  `${props.url}/api/customer/emailInvoice`,
  //     data: updateObject,
  //     // validateStatus: (status) => {
  //     //   // console.log('Validate status: ', status)
  //     //   if (status && status === 500) {
  
  //     //   }
  //     // },
  //   }).then(response => {
  //     setLoading(false)
  //     // console.log('EMAIL INVOICE RSPONSE: ', response)
  //     props.notification({
  //       type: 'success',
  //       title: 'Invoice Emailed',
  //       message: 'Email Sent. Grab a cup of coffee, you did great!'
  //     })
  //   })
  // }
  const handleChange = (name, value) => {
    // console.log('Update our stuff: ', name)
    // console.log('value: ', value)
    let customer = client
    customer[name] = !customer[name]
    setState({...state, client: customer})
  }

  const handleSave = () => {
    console.log('Save the custoemr: ', state)
    setLoading(true)
    let updateObject = {
      client: client,
      state: state,
      user: props.user
    }
    // console.log('TO SAVE: ', updateObject)
    axios({
      method: 'post',
      url:  `${props.url}/api/customer/updateClient`,
      data: updateObject,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      setLoading(false)
      // console.log('Update Client RSPONSE: ', response)
      if (response && response.data && response.data.client) {
        props.updatedClient('saved', response.data.client)
        // let jobsChecked = response.data.jobsChecked
        // let jobsMatched = response.data.jobsMatched
        // let jobsUpdated = response.data.jobsUpdated
        let message = 'Your Updates Were Saved'
        let otherUpdates = response.data.updates

        if (otherUpdates && otherUpdates.jobsChecked && otherUpdates.jobsChecked > 0) {
          message = <div>
            <b>Update Results</b><br />
            <b>Jobs Checked: </b>{otherUpdates.jobsChecked}<br />
            <b>Jobs Matched: </b>{otherUpdates.jobsMatched}<br />
            <b>Jobs Updated: </b>{otherUpdates.jobsUpdated}<br />
            <b>Mtc Agreements Checked: </b>{otherUpdates.mtcChecked}<br />
            <b>Mtc Agreements Matched: </b>{otherUpdates.mtcMatched}<br />
            <b>Mtc Agreements Updated: </b>{otherUpdates.mtcUpdated}<br />
            <b>Invoices Checked: </b>{otherUpdates.invoicesChecked}<br />
            <b>Invoices Matched: </b>{otherUpdates.invoicesMatched}<br />
            <b>Invoices Updated: </b>{otherUpdates.invoicesUpdated}
          </div>
        }
        // console.log('Notification: ',)
        props.notification({
          type: 'lastNameChange',
          title: 'Customer Updated Successfully',
          message: message
        })
      }
    })
  }

  const handleUpdateLaborRates = (newRateItem) => {
    console.log('Set Labor Rates', newRateItem)
    let newLaborRates = {...state.laborRates, ...newRateItem}
    console.log('New Rates:', newLaborRates)
    setState({...state, laborRates: newLaborRates })
  }

  return (
    <div className={classes.root}>
       <Dialog
        // fullScreen={true}
        maxWidth='sm'
        fullWidth
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Edit Customer</DialogTitle>
        <DialogContent>
        <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
          <CloseIcon />
        </IconButton>
          <Grid container>
            <Grid item lg={12}>
             <h3>Customer Information</h3>
            </Grid>
            <Grid item lg={6}>
              <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={state.active} onChange={ e => setState({...state, active: !state.active } ) } name="Active" />}
                    label="Active"
                  />
                </FormGroup>
            </Grid>
            <Grid item lg={6}>
              <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={state.testClient} onChange={ e => setState({...state, testClient: !state.testClient } ) } name="Test Client" />}
                    label="Test Client"
                  />
                </FormGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12}>
            <Grid container>
                <Grid item lg={6} xs={6} className={classes.gridItem}> 
                          <TextField
                            autoFocus
                            label="Customer First Name"
                            onChange={ e => setState({...state, firstName: e.target.value })}
                            name="customerfirstname"
                            value={ state.firstName }
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={6} xs={6} className={classes.gridItem}> 
                                    
                          <TextField
                          autoFocus
                            label="Customer Last Name"
                            onChange={ e => setState({...state, lastName: e.target.value })}
                            name="customerlastname"
                            value={ state.lastName }
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
            </Grid>
            <Grid item lg={12}>
              <TextField
                autoFocus
                label="Company Name"
                onChange={ e => setState({...state, companyName: e.target.value })}
                name="companyName"
                value={ state.companyName }
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item lg={12}>
              <TextField
                autoFocus
                label="Invoice PDF Name"
                onChange={ e => setState({...state, initials: e.target.value })}
                name="companyInitials"
                value={ state.initials }
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item lg={12}>
              <TextField
                autoFocus
                className={classes.paddedStuff}
                margin="dense"
                id="DisplayName"
                label="Display Name"
                type="text"
                onChange={(e) => setState({...state, displayName: e.target.value})}
                value={state.displayName}
                fullWidth
            />
             <TextField
                autoFocus
                className={classes.paddedStuff}
                margin="dense"
                id="Email"
                label="Email"
                type="text"
                onChange={(e) => setState({...state, email: e.target.value})}
                value={state.email}
                fullWidth
            />
             <TextField
                autoFocus
                className={classes.paddedStuff}
                margin="dense"
                id="Telephone"
                label="Telephone Number"
                type="telephone"
                onChange={(e) => setState({...state, telephone: e.target.value})}
                value={state.telephone}
                fullWidth
            />
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}> 
                        <TextField
                      label="Billing Address"
                      fullWidth
                      value= {state.address}
                        onChange={ e => setState({...state, address:e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />
                    </Grid>
                    <Grid item lg={12} xs={12} className={classes.gridItem}> 
                        <TextField
                      label="Billing Address Line 2"
                      fullWidth
                      value= {state.address2}
                        onChange={ e => setState({...state, address2:e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />
                    </Grid>
                    <Grid item lg={6} xs={6} className={classes.gridItem}>
                      <TextField
                        label="Billing City"
                        fullWidth
                        value= {state.city}
                        onChange={ e => setState({...state, city:e.target.value } ) }
                        // variant="outlined"
                        // value= {state.service.time}
                        // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                        />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                      <TextField
                        label="Billing State"
                        fullWidth
                        value= {state.state}
                        onChange={ e => setState({...state, state:e.target.value } ) }
                      />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                    <TextField
                        label="Billing Zipcode"
                        fullWidth
                        // type="number"
                        // variant="outlined"
                        value= {state.zipcode}
                        onChange={ e => setState({...state, zipcode:e.target.value } ) }
                        />
                    </Grid>
                </Grid>
            <Grid container>
              <Grid item lg={12}>
              <h3>Customer Settings</h3>
              </Grid>
              <Grid item lg={12}>
                <h4>Auto-Pay Options</h4>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                      control={<Checkbox checked={state.autopayAll} onChange={ e => setState({...state, autopayAll: !state.autopayAll } ) } name="autopayAll" />}
                      label="Autopay (ALL)"
                    />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                      control={<Checkbox checked={state.autopayMTC} onChange={ e => setState({...state, autopayMTC: !state.autopayMTC } ) } name="autopayMTC" />}
                      label="Autopay (Maintenance Only)"
                    />
              </Grid>
              <Grid item lg={12}>
                <h4>Invoice Email Options</h4>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.paperInvoice} onChange={ e => setState({...state, paperInvoice: !state.paperInvoice } ) } name="paperInvoice" />}
                    label="Paper Invoice"
                  />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.doNotEmail}onChange={ e => setState({...state, doNotEmail: !state.doNotEmail } ) } name="doNotEmail" />}
                    label="Do NOT Email"
                  />
              </Grid>
              <Grid item lg={12}>
                <h4>Special Customer Type</h4>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.HOA} onChange={ e => setState({...state, HOA: !state.HOA } ) } name="HOA" />}
                    label="HOA"
                  />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.contractor} onChange={ e => setState({...state, contractor: !state.contractor } ) } name="contractor" />}
                    label="Contractor"
                  />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.builder} onChange={ e => setState({...state, builder: !state.builder } ) } name="Builder" />}
                    label="Builder"
                  />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.government} onChange={ e => setState({...state, government: !state.government } ) } name="Government" />}
                    label="Government"
                  />
              </Grid>
              <Grid item lg={12}>
                <h4>Collection Notification Options</h4>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.collectionNotifications} onChange={ e => setState({...state, collectionNotifications: !state.collectionNotifications } ) } name="collectionNotifications" />}
                    label="Collections Notifications"
                  />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.hmiPastDueStatements} onChange={ e => setState({...state, hmiPastDueStatements: !state.hmiPastDueStatements } ) } name="hmiPastDueStatements" />}
                    label="Receive HMI Past Due Statement"
                  />
              </Grid>
              <Grid item lg={12}>
                <h4>Customer Up-Charges</h4>
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.hydrawise} onChange={ e => setState({...state, hydrawise: !state.hydrawise } ) } name="hydrawise" />}
                    label="Hydrawise"
                  />
              </Grid>
              <Grid item lg={12}>
                <h4>Customer Requirements</h4>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.requiresWorkorder} onChange={ e => setState({...state, requiresWorkorder: !state.requiresWorkorder } ) } name="requiresWorkorder" />}
                    label="Requires Customer Generated Workorder"
                  />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.displayJobSiteNameAndBuildingNumber} onChange={ e => setState({...state, displayJobSiteNameAndBuildingNumber: !state.displayJobSiteNameAndBuildingNumber } ) } name="displayJobSiteNameAndBuildingNumber" />}
                    label="Requires Building Name and Number on Invoice"
                  />
              </Grid>

              

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <h4>Invoice Extras</h4>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  
                  rows={4}
                  variant="outlined"
                  label="Invoice First Line (PO/PODO) - GOV'T Stuff"
                  onChange={ e => setState({...state, invoiceFirstLine: e.target.value })}
                  name="invoiceFirstLine"
                  multiline
                  value={ state.invoiceFirstLine }
                  margin="normal"
                  fullWidth
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <h4>Labor Rates</h4>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={state.requiresHoursAndRates} onChange={ e => setState({...state, requiresHoursAndRates: !state.requiresHoursAndRates } ) } name="requiresHoursAndRates" />}
                    label="Requires Labor Hours & Rate on Estimates/Invoices"
                  />
              </Grid>
              <LaborRates laborRates={state.laborRates} updateParent={handleUpdateLaborRates} />


              

              
                {/* <FormGroup row>
                  
                  <FormControlLabel
                      control={<Checkbox checked={state.autopayAll} onChange={ e => setState({...state, autopayAll: !state.autopayAll } ) } name="autopayAll" />}
                      label="Autopay (ALL)"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={state.autopayMTC} onChange={ e => setState({...state, autopayMTC: !state.autopayMTC } ) } name="autopayMTC" />}
                      label="Autopay (Maintenance Only)"
                    />
                </FormGroup> */}
              {/* <FormGroup row>
                <h4>Invoice Options</h4>
                <FormControlLabel
                    control={<Checkbox checked={state.paperInvoice} onChange={ e => setState({...state, paperInvoice: !state.paperInvoice } ) } name="paperInvoice" />}
                    label="Paper Invoice"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.doNotEmail}onChange={ e => setState({...state, doNotEmail: !state.doNotEmail } ) } name="doNotEmail" />}
                    label="Do NOT Email"
                  />
              </FormGroup>
              <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={state.HOA} onChange={ e => setState({...state, HOA: !state.HOA } ) } name="HOA" />}
                    label="HOA"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.contractor} onChange={ e => setState({...state, contractor: !state.contractor } ) } name="contractor" />}
                    label="Contractor"
                  />
              </FormGroup> */}
              
              {/* </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            Save { loading ? <CircularProgress /> : '' }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(EditCustomerModal))