import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, LinearProgress}  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import UploadService from '../../services/uploadFileService'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const UploadButton = (props) => {
  const uploadInputRef = React.useRef(null);

  const onChange = (e) => {
  // console.log('File upload', e.target.files)
    props.addFiles(e.target.files)
  }

  return (
    <React.Fragment>
    <input
      ref={uploadInputRef}
      type="file"
      accept="image/*,application/pdf,application/vnd.ms-excel,.csv,.xsl,.xlsx"
      multiple
      style={{ display: "none" }}
      onChange={onChange}
    />
    <Button
      onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
      variant="contained"
      size="small"
      style={{backgroundColor: 'rgb(172,202,205)', color: 'rgb(81,110,118)'}}
    >
      Upload
    </Button>
  </React.Fragment>
  )
}

const UploadDetails = (props) => {
// console.log('Upload Details: ', props)
  const [files, setFiles] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [ourProgress, setOurProgress] = React.useState([])
  // const ourProgress = React.useRef([])
  React.useEffect(() => {
    if (props.files && props.files.length) {
      setFiles(props.files)
    }
  }, [props])

  const handleUploadNow = async () => {
    let index = 0
    setLoading(true)
    let stuffUploaded = []
    for (const file of files) {
    // console.log('Upload this one: ', file.name)
      let url = `${props.url}/api/projects/uploadProjectFile`
      let data = {
       projectId: props.project._id,
       client: props.project.client._id,
       entity: props.project.entity,
       number: props.project.number
      }
      let resp = await UploadAFile(file, url, data, index)
    // console.log('Got a response from our file upload 169', resp)
      if (resp && resp.data) {
        if (resp.data && resp.data.success) {
          let msg = 'Your File Was Uploaded & Saved.'
          props.notification({
            type: 'success',
            title: 'File Uploaded Successfully!',
            message: msg
          })
          stuffUploaded.push(resp.data.data)
        } else {
          let msg = 'An Unknown Error Occurred When We Attempted To Upload This File'
          if (resp.data && resp.data.error && resp.data.error.message) msg = resp.data.error.message
          props.notification({
            type: 'warning',
            title: 'Error Uploading File!',
            message: msg
          })
        }
      }
      index++
    }
    setLoading(false)
    setFiles([])
    setOurProgress([])
    // ourProgress.current = []
    props.updateFiles(stuffUploaded)
  }

  const uploadItNow = (file, url, data, index, callback) => {
    UploadService.upload(file, url, data, (event) => {
      // console.log('Progress ', event)
      // let loaded = event.loaded
      // let total = event.total
      // let pct = parseInt(parseFloat(loaded / total) * 100)
      // // alert(p ct)
      // console.log(`${pct}% loaded - ${loaded} / ${total}`)
      // // // file.progress = loaded
      // // let theFiles = files
      // // let ourFile = theFiles[index]
      // // console.log(ourFile)
      // // ourFile.progress = loaded
      // // console.log(ourFile)
      // // setFiles(theFiles)
      // let theProgress = ourProgress
      // console.log('our Progress: ', theProgress)
      // theProgress[index] = pct
      // console.log('our Progress: ', theProgress)
      // setOurProgress(theProgress)
      // ourProgress.current[index] = loaded
      // props.updateLoadingPct(pct)
    }).then(response => {
      // console.log('Resp: ', response)
      return callback(null, response)
    }).catch(e => {
      // console.log('Error: ', e)
      return callback(e)
    })
  }
  const UploadAFile = (file, url, data, index) => {
      return new Promise((resolve) => uploadItNow(file, url, data, index, (err, resp) => {
    // console.log('Error: ', err)
    // console.log('Got a resp! ', resp)
      if (err) {
        resolve(err)
      } else {
        resolve(resp)
      }
    }))
  }
  // React.useEffect(() => {
  //   if (props.files && props.files.length) {
  //     setFiles(props.files)
  //   }
  // }, [files])
  // console.log(files)
  let fileList = []
  for (const item of files) {
  // console.log(item)
    fileList.push(item)
  }
// console.log(fileList)
  let filesToUploadMessage = ''
  if (files.length > 1) {
    filesToUploadMessage = `${files.length} Files to Upload`
  } else {
    filesToUploadMessage = `1 File to Upload`
  }
  if (fileList && fileList.length) {
  // console.log('render file List')

    return (
      <div>
        <Grid container>
          <Grid item xs={6} sm={6} md={4} lg={4}>
            Upload Details
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={4}>
            {filesToUploadMessage}
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}></Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {/* <LinearProgress variant="buffer" value={val} valueBuffer={1000} /> */}
          </Grid>
          
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Grid container>
              <Grid item lg={3} md={3} sm={4} xs={6}>
                File Name
              </Grid>
              <Grid item lg={3} md={3} sm={4} xs={6}>
                File Size
              </Grid>
              <Grid item>
                
              </Grid>
            </Grid>
            <Grid container>
              {
                fileList.map((file, index) => {
                  let progress = 0
                  // if (file.progress) progress = file.progress
                  let val = 0
                // console.log(ourProgress)
                  if (ourProgress[index] > 0) val = ourProgress[index]
                // console.log('Val: ', val)
                  return (
                    <React.Fragment key={index}>
                      <Grid item lg={3} md={3} sm={4} xs={6} style={{textOverflow: 'ellipsis'}}>
                        {file.name}
                      </Grid>
                      <Grid item lg={3} md={3} sm={4} xs={6}>
                        {file.size}
                      </Grid>
                      <Grid item lg={6} md={6} sm={4} xs={12} style={{textOverflow: 'hidden', marginBottom: 5}}>
                        {/* <LinearProgress variant="buffer" value={val} valueBuffer={100} /> */}
                      </Grid>
                    </React.Fragment>
                  )
                })
              }
            </Grid>
            <Grid container>
              <Grid item lg={12}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleUploadNow}
                  >{ loading ? (<CircularProgress size={12} style={{color: '#fff'}} />) : 'Upload All' }</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  } else {
    return null
  }

}

const FileUpload = (props) => {
  const [filesToUpload, setFilesToUpload] = React.useState([])

  const handleAddFiles = async (files) => {
  // console.log('Add Files!', files)
    setFilesToUpload(files)
  }

  const updateOurFiles = (d) => {
    setFilesToUpload([])
    props.updateFiles(d)
  }
  return (
    <div style={{margin: 10, padding: 5}}>
      <UploadButton addFiles={handleAddFiles}/>
      { filesToUpload.length ? <UploadDetails project={props.project} files={filesToUpload} url={props.url} notification={props.notification} updateFiles={updateOurFiles} /> : null } 
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(FileUpload)
