import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, Tooltip, MenuItem,  FormControl,
  InputLabel,
  Select, }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteComponent from '../Common/DeleteComponent'
import FileUploadComponent from '../Uploads/UploadComponent'
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
// import DialogContentText from '@material-ui/core/DialogContentText';
import { KeyboardDatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
import BigImageComponent from '../Uploads/BigImageComponent'

const PunchlistComponent = (props) => {
  console.log('Punchlist Component Props: ', props)
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [list, setList] = useState(props.list || []);
  const [projectMilestones, setProjectMilestones] = useState([]);
  const [workorderUploads, setWorkorderUploads] = useState([]);
  const [activeFilter, setActiveFilter] = useState(null);

  const workorderId = React.useRef(null)

  useEffect(() => {
    if (props?.workorder?._id) {
      console.log('WorkorderId', workorderId)
      if (workorderId?.current) {
        console.log('We already have it...')
      } else {
        workorderId.current = props.workorder._id
        fetchPunchListItems(props.workorder._id)
      }
    }
  }, [props.workorder]);

  const fetchPunchListItems = async (woId) => {
    console.log('Fetch Punchlist iTems', woId)
    try {
      const resp = await axios.get(`${props.url}/api/jobs/punchlistItems?woId=${woId}`);
      if (resp && resp.data && resp.data.success) {
        const { punchlistItems, milestones, uploads } = resp.data;
        setList(punchlistItems);
        setProjectMilestones(milestones);
        setWorkorderUploads(uploads);
      }
    } catch (error) {
      console.error("Error fetching punch list items:", error);
    }
  };

  const handleOpenPunchList = () => {
    setOpen(true)
  }
  const handleClosePunchListModal = () => {
    setOpen(false)
  }
  const completedPunchlistItems = 0

  return (
    <>
       <Button
          variant="contained"
          style={{backgroundColor: '#e66b49'}}
          color="secondary"
          size="small"
          startIcon={<PlaylistAddCheckIcon />}
          // onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteService() } }
          onClick={handleOpenPunchList}
          // onClick={offerDeleteOption}
          // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
        >
          Punch List { list?.length ? `${completedPunchlistItems} / ${list.length}` : ''}
        </Button>
        <PunchlistModal
          {...props}
          list={list}
          setList={setList}
          activeFilter={activeFilter}
          projectMilestones={projectMilestones}
          setProjectMilestones={setProjectMilestones}
          workorderUploads={workorderUploads}
          setWorkorderUploads={setWorkorderUploads}
          setActiveFilter={setActiveFilter}
          open={open} 
          closeModal={handleClosePunchListModal}
         />
    </>
  )
}
  



const PunchlistModal = (props) => {
  console.log('Punchlist Modal', props)
   const classes = useStyles();

  //  const [state, setState] = React.useState({
  //    note: '',
  //    list: []
  //  })

  const list = props.list
  const activeFilter = props.activeFilter
  const handleClose = () => props.closeModal('Close');
  const filteredList = activeFilter
    ? list.filter(item => item.milestone === activeFilter)
    : list;

return (
  <div className={classes.root}>
    
    <Dialog open={props.open} fullScreen={true} onClose={handleClose} maxWidth='md'>
      <div className={classes.headerArea}>
        <DialogTitle id="form-dialog-title">Workorder Punch List</DialogTitle>
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </div>
      
      <DialogContent>
        <MainContent 
          list={filteredList}
          projectMilestones={props.projectMilestones}
          workorderUploads={props.workorderUploads}
          workorder={props.workorder}
          activeFilter={props.activeFilter}
          setActiveFilter={props.setActiveFilter}
          setList={props.setList}
          url={props.url}
          employees={props.employees}
          client={props.client}
          notification={props.notification}
        />
      </DialogContent>
    </Dialog>
  </div>
);
};

const MainContent = ({ 
  list, projectMilestones, workorderUploads, workorder, activeFilter, setActiveFilter, 
  url, client, notification, setList, employees
}) => {
  const classes = useStyles();
  const [newItemModalOpen, setNewItemModalOpen] = useState(false);
  const [editItemModalOpen, setEditItemModalOpen] = useState(false);
  const [activeItem, setActiveItem] = useState({});

  const handleOpenNewItemModal = () => setNewItemModalOpen(true);
  const handleCloseNewItemModal = () => setNewItemModalOpen(false);
  const handleCloseEditItemModal = () => setEditItemModalOpen(false);

  const handleSaveNewItem = async (newItem) => {
    setNewItemModalOpen(false);
    try {
      const response = await axios.post(`${url}/api/jobs/addPunchlistItem`, {
        workorder: workorder._id,
        project: workorder.project,
        item: newItem,
        entity: workorder.entity
      });
      if (response.data && response.data._id) {
        setList([response.data, ...list]);
        
      }
    } catch (error) {
      notification({
        type: 'warning',
        title: 'Failed to Save',
        message: JSON.stringify(error)
      });
    }
  };

  const handleEditItem = (itemToEdit) => {
    setActiveItem(itemToEdit);
    setEditItemModalOpen(true);
  };

  const handleSaveEditedItem = async (editedItem) => {
    setEditItemModalOpen(false);
    try {
      const response = await axios.post(`${url}/api/jobs/updatePunchlistItem`, { item: editedItem });
      if (response.data && response.data._id) {
        const newList = list.map(item => item._id === response.data._id ? response.data : item);
        setList(newList);
       
      }
    } catch (error) {
      notification({
        type: 'warning',
        title: 'Failed to Save Item',
        message: JSON.stringify(error)
      });
    }
  };

  const handleDeleteItem = (itemToDelete) => {
    const newList = list.filter(item => item._id !== itemToDelete._id);
    setList(newList);
  };

  const handlePrint = async () => {
    try {
      const response = await axios.get(`${url}/api/jobs/printPunchlistItems?wo=${workorder._id}`);
      if (response && response.data && !response.data.error) {
        // Handle PDF display logic here
      }
    } catch (error) {
      console.error("Error printing punch list:", error);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <ControlArea workorder={workorder} />
      </div>
      <div className={classes.middleContainer}>
        <div className={classes.toDoArea}>
          <AllItems 
            list={list} 
            client={client}
            employees={employees}
            uploads={workorderUploads} 
            workorderId={workorder._id}
            projectMilestones={projectMilestones}
            openNewItemModal={handleOpenNewItemModal} 
            editItem={handleEditItem} 
            deleteItem={handleDeleteItem}
          />
        </div>
        <div className={classes.middleRightContainer}>
          <div className={classes.calendarArea}>
            <Button variant="contained" color="secondary" size="small" onClick={handlePrint}>
              Print
            </Button>
          </div>
          <div className={classes.projectMilestonesArea}>
            <div>Project Milestones ({projectMilestones.length})</div>
            <MilestonesList 
              list={projectMilestones} 
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
            />
          </div>
        </div>
      </div>
      {activeFilter && (
        <div className={classes.filterIndicator}>
          Filtered by milestone: {projectMilestones.find(m => m._id === activeFilter)?.name}
          <Button onClick={() => setActiveFilter(null)}>Clear Filter</Button>
        </div>
      )}
      <AddNewPunchListItemModal 
        open={newItemModalOpen}
        employees={employees}
        closeModal={handleCloseNewItemModal} 
        saveItem={handleSaveNewItem}
        milestones={projectMilestones}
      />
      <EditPunchListItemModal 
        open={editItemModalOpen} 
        item={activeItem}
        employees={employees}
        closeModal={handleCloseEditItemModal} 
        saveItem={handleSaveEditedItem}
        milestones={projectMilestones}
        
      />
    </div>
  );
};

const MilestonesList = ({ list, activeFilter, setActiveFilter }) => {
  const classes = useStyles();

  return (
    <div className={classes.milestonesList}>
      {list.map((item, index) => (
        <div 
          key={index} 
          className={`${classes.milestoneItem} ${activeFilter === item._id ? classes.activeMilestone : ''}`}
          onClick={() => setActiveFilter(activeFilter === item._id ? null : item._id)}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};


  const ClosePDF = (props) => {
    return (
      <div style={{ width: '100%', display: 'flex', alignSelf: 'stretch', justifyContent: 'space-between', flexDirection: 'row'}}>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={props.handleClose}
        >Close PDF</Button>
      </div>
    )
  }

  const ControlArea = ({ workorder }) => {
    console.log('Control area 434', workorder)
    return (
      <div>
        {workorder?.name} ({workorder?.number}) - {workorder?.jobName}
      </div>
    )
  }
  
  const AllItems = (props) => {
    const classes = useStyles()
    return (
      <div className={classes.allItemContainer}>
        <div className={classes.allItemHeader}>
          <div>
          Punch List Items #{props.list.length || 0} 
          </div>
         
          <Button variant="contained" color="primary" size="small" onClick={props.openNewItemModal}>Add New</Button>
        </div>
        <div className={classes.allItemContent}>
          <OurList list={props.list} employees={props.employees} uploads={props?.uploads || []} projectMilestones={props.projectMilestones} workorderId={props.workorderId} editItem={props.editItem} deleteItem={props.deleteItem} clientId={props.client._id} />
        </div>
      </div>
    )
  }

  const OurList = (props) => {
    const classes = useStyles()
    const [showBigImages, setShowBigImages] = React.useState(false)
    const [activeItem, setActiveItem] = React.useState({})

    const handleUpdateResponse = (d) => {
      console.log('UPdate from our uploads component...', d)
    }

    const handleCloseBigImageModal = () => {
      setShowBigImages(false)
    }

    const handleViewBigImage = (d) => {
      setActiveItem(d)
      setShowBigImages(true)
    }
    // console.log('Our List props', props)
    
    return (
      <div className={classes.listContainer}>
      <BigImageComponent open={showBigImages} item={activeItem} images={props.uploads} closeModal={handleCloseBigImageModal} />
          {
            props.list.map((item, index) => {
              // console.log('Punchlist item', item)
              const filteredUploads = props?.uploads.filter(upload => (upload.punchlistId === item._id))
              let associatedMilestone = null
              if (item.milestone) {
                associatedMilestone = props.projectMilestones.find(milestone => (milestone._id === item.milestone))
              }
              let assignedTo = null
              if (item.assignedTo) {
                assignedTo = props?.employees.find(emp => (emp._id === item.assignedTo))
              }
              // console.log('Assigned To', assignedTo)
              return (
                <div className={classes.listItemContainer} key={index}>
                  
                  <div className={classes.listItemMainArea}>
                    <div className={classes.listItemName}>{item.name}</div>
                    <div className={classes.listItemDetails}>{item.details}</div>
                    
                  </div>
                  <div className={classes.secondRow}>
                    <div className={classes.statusArea}>{item.status}</div>
                    <div className={classes.listItemControls}>
                          <Tooltip
                            title="Edit">
                            <IconButton
                              // color={props.color}
                              size="small"
                              onClick={() => props.editItem(item)}
                            ><EditRoundedIcon  sx={{ fontSize: "10px", color: "#fff" }}  /></IconButton>
                          </Tooltip>
                          
                            <DeleteComponent
                                  type="Tasks"
                                  item={item}
                                  title="Delete Item?"
                                  verificationText={`Are you sure you wish to Delete this punch list item?`}
                                  confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
                                  confirmationButton="DELETE"
                                  denyText="NO"
                                  ifApproved={e => props.deleteItem(item)}
                                  deleteFunction={'punchlistItem'}
                                />
                         
                      </div>
                    </div>
                    <div className={classes.uploadsArea}>
                      <div className={classes.uploadsInputArea}>
                        <FileUploadComponent clientId={props.clientId} workorderId={props.workorderId} punchlistId={item._id} updateParent={handleUpdateResponse} message={`Add Files Here`}  />
                      </div>
                      <div>
                      Uploads: {filteredUploads?.length}
                      <div className={classes.thumbnailsContainer}>
                        {filteredUploads.map((upload, index) => (
                          <div key={index} className={classes.thumbnail} onClick={ () => handleViewBigImage(upload)}>
                            <img src={upload.url} alt={`upload-${index}`} className={classes.thumbnailImage} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className={classes.bottomLineContainer}>
                    <div >
                      {associatedMilestone?.name || ''}
                    </div>
                    <div>{assignedTo && `${assignedTo.firstName} ${assignedTo.lastName}`}</div>
                  </div>
                </div>
              )
            })
          }
       
      </div>
    )
  }

  const AddNewPunchListItemModal = ({ open, closeModal, saveItem, milestones, employees }) => {
    const classes = useStyles();
    const [state, setState] = useState({
      name: '',
      details: '',
      status: 'Not Started',
      milestone: null,
      assignedTo: null
    });
  
    const handleChange = (e) => setState({ ...state, [e.target.id]: e.target.value });

    const handleSave = () => {
      saveItem(state);
      setState({ name: '', details: '', status: 'Not Started', milestone: {} });
    };

    const handleChangeStatus = (e) => {
      setState(prevState => ({ ...prevState, status: e.target.value }))
    }

    const handleTabInteraction = (e) => {
      if (e.keyCode == 9) {
      // console.log(e.target)
        if (e.target.id === 'milestone') {
          // window.alert(`Tab on custoemr ${e.target.value}`)
          let newArr = []
          let string = e.target.value
          milestones.filter(o => {
            let str = o['name'].toString().toLowerCase()
            if (str.includes(string.toLowerCase())) {
              return newArr.push(o)
            } else {
              return null
            }
          })
          // console.log(newArr)
          if (newArr && newArr.length) {
            handleChooseMilestone(null, newArr[0])
          }
        }
      }
    }
  
    const handleChooseMilestone = (e, newMilestone) => {
      setState(prevState => ({...prevState, milestone: newMilestone}))
    }
    const handleAssignedToChange = (d) => {
      setState(prevState => ({...prevState, assignedTo: d }))
    }

    const handleUrgencyChange = (e) => {
      setState(prevState => ({...prevState, urgency: e.target.value}));
    }
  
    const handleDueDateChange = (date) => {
      setState(prevState => ({...prevState, dueDate: date}));
    }

    return (
      <Dialog size="md" fullWidth open={open} onClose={closeModal}>
        <DialogTitle>Add New Punch List Item</DialogTitle>
        <DialogContent>
          <TextField
            id="name"
            variant="outlined"
            fullWidth
            label="Item Name"
            className={classes.newItemInput}
            value={state.name}
            onChange={handleChange}
          />
          <TextField
            id="details"
            variant="outlined"
            fullWidth
            label="Item Details"
            multiline
            minRows={4}
            className={classes.newItemInput}
            value={state.details}
            onChange={handleChange}
          />
          <TextField
            id="status"
            variant="outlined"
            fullWidth
            select
            label="Item Status"
            className={classes.newItemInput}
            value={state.status}
            onChange={handleChangeStatus}
          >
            <MenuItem value="Not Started">Not Started</MenuItem>
            <MenuItem value="Pending Materials">Pending Materials</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Complete">Complete</MenuItem>
          </TextField>
          <Autocomplete
            id="milestone"
            options={milestones}
            variant="outlined"
            getOptionLabel={(option) => {
              return `${option.name}`
            }}
            value={state.milestone || ''}
            onChange={handleChooseMilestone}
            className={classes.newItemInput}
            renderInput={(params) => <TextField fullWidth onKeyDown={handleTabInteraction} variant="outlined" {...params} label="Milestone" />}
          />
              <TextField
            id="status"
            variant="outlined"
            fullWidth
            select
            label="Item Status"
            className={classes.newItemInput}
            value={state.status}
            onChange={handleChangeStatus}
          >
            <MenuItem value="Not Started">Not Started</MenuItem>
            <MenuItem value="Pending Materials">Pending Materials</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Complete">Complete</MenuItem>
          </TextField>
          <FormControl variant="outlined" fullWidth className={classes.newItemInput}>
          <InputLabel id="urgency-label">Urgency</InputLabel>
          <Select
            labelId="urgency-label"
            id="urgency"
            value={state.urgency || ''}
            onChange={handleUrgencyChange}
            label="Urgency"
          >
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="High">High</MenuItem>
            <MenuItem value="Critical">Critical</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" fullWidth className={classes.newItemInput}>

            <KeyboardDatePicker
              disableToolbar
              format="MM/dd/yyyy"
              variant="inline"
              margin="normal"
              id="due-date"
              label="Needs to be done by"
              value={state.dueDate || null}
              onChange={handleDueDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              fullWidth
            />
          </FormControl>
          <Autocomplete
            id="assigned-to"
            options={employees || []}
            getOptionLabel={(option) => `${option.firstName || ''} ${option.lastName || ''}`}
            value={state.assignedTo || null}
            onChange={(e, newValue) => handleAssignedToChange(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Assigned To" variant="outlined" fullWidth  className={classes.newItemInput} />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>CLOSE</Button>
          <Button color="primary" onClick={handleSave}>SAVE</Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  const EditPunchListItemModal = ({ open, item, closeModal, saveItem, milestones, employees }) => {
    const classes = useStyles();
    const [state, setState] = useState(item);
  
    useEffect(() => {
      console.log('Set item milestone....', item)
      let milestoneId = item?.milestone
      console.log('Miestone ite', milestoneId)
      let itemToSet = { ...item }
      if (milestoneId) {
        itemToSet.milestone = milestones.find(ms => (ms._id === milestoneId))
        console.log('Setting milestone!!', itemToSet)
      }
      setState(itemToSet);
    }, [item]);
  
    const handleChange = (e) => {
      // console.log('Handle change', e.target.id)
      // console.log('Val', e.target.value)
      setState({ ...state, [e.target.id]: e.target.value });
    }

    const handleChangeStatus = (e) => {
      setState(prevState => ({ ...prevState, status: e.target.value }))
    }

    const handleSave = () => {
      saveItem(state);
      closeModal();
    };
  
    const handleTabInteraction = (e) => {
      if (e.keyCode == 9) {
      // console.log(e.target)
        if (e.target.id === 'milestone') {
          // window.alert(`Tab on custoemr ${e.target.value}`)
          let newArr = []
          let string = e.target.value
          milestones.filter(o => {
            let str = o['name'].toString().toLowerCase()
            if (str.includes(string.toLowerCase())) {
              return newArr.push(o)
            } else {
              return null
            }
          })
          // console.log(newArr)
          if (newArr && newArr.length) {
            handleChooseMilestone(null, newArr[0])
          }
        }
      }
    }
  
    const handleChooseMilestone = (e, newMilestone) => {
      setState(prevState => ({...prevState, milestone: newMilestone}))
    }

    const handleAssignedToChange = (d) => {
      // console.log('Assign to', d)
      setState(prevState => ({...prevState, assignedTo: d }))
    }

    const handleUrgencyChange = (e) => {
      setState(prevState => ({...prevState, urgency: e.target.value}));
    }
  
    const handleDueDateChange = (date) => {
      setState(prevState => ({...prevState, dueDate: date}));
    }

    return (
      <Dialog size="md" fullWidth open={open} onClose={closeModal}>
        <DialogTitle>Edit Punch List Item</DialogTitle>
        <DialogContent>
          <TextField
            id="name"
            variant="outlined"
            fullWidth
            label="Item Name"
            className={classes.newItemInput}
            value={state.name}
            onChange={handleChange}
          />
          <TextField
            id="details"
            variant="outlined"
            fullWidth
            label="Item Details"
            multiline
            minRows={4}
            className={classes.newItemInput}
            value={state.details}
            onChange={handleChange}
          />
          <TextField
            id="status"
            variant="outlined"
            fullWidth
            select
            label="Item Status"
            className={classes.newItemInput}
            value={state.status}
            onChange={handleChangeStatus}
          >
            <MenuItem value="Not Started">Not Started</MenuItem>
            <MenuItem value="Pending Materials">Pending Materials</MenuItem>
            <MenuItem value="In Progress">In Progress</MenuItem>
            <MenuItem value="Complete">Complete</MenuItem>
          </TextField>
          <FormControl variant="outlined" fullWidth className={classes.newItemInput}>
          <InputLabel id="urgency-label">Urgency</InputLabel>
          <Select
            labelId="urgency-label"
            id="urgency"
            value={state.urgency || ''}
            onChange={handleUrgencyChange}
            label="Urgency"
          >
            <MenuItem value="Low">Low</MenuItem>
            <MenuItem value="Medium">Medium</MenuItem>
            <MenuItem value="High">High</MenuItem>
            <MenuItem value="Critical">Critical</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" fullWidth className={classes.newItemInput}>
         
            <KeyboardDatePicker
              disableToolbar
              format="MM/dd/yyyy"
              variant="inline"
              margin="normal"
              id="due-date"
              label="Needs to be done by"
              value={state.dueDate || null}
              onChange={handleDueDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              fullWidth
              className={classes.newItemInput}
            />
         
          </FormControl>
          <Autocomplete
            id="milestone"
            options={milestones}
            variant="outlined"
            getOptionLabel={(option) => {
              return `${option.name}`
            }}
            value={state.milestone}
            onChange={handleChooseMilestone}
            className={classes.newItemInput}
            renderInput={(params) => <TextField fullWidth onKeyDown={handleTabInteraction} variant="outlined" {...params} label="Milestone" />}
          />
          <Autocomplete
            id="assigned-to"
            options={employees || []}
            getOptionLabel={(option) => `${option.firstName || ''} ${option.lastName || ''}`}
            value={state.assignedTo || null}
            onChange={(e, newValue) => handleAssignedToChange(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Assigned To" variant="outlined" fullWidth  className={classes.newItemInput} />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>CLOSE</Button>
          <Button color="primary" onClick={handleSave}>UPDATE</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      employees: state.employees
    }
  }
  
  export default React.memo(connect(mapStateToProps)(PunchlistComponent))

  
const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  headerArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  listItemContainer: {
    backgroundColor: '#769ba2',
    border: '2px solid #444',
    // margin: 5,
    padding: 5,
    color: '#eee',
    // display: 'flex',
    // alignSelf: 'stretch',
    // flexGrow: 1,
    // width: '100%',
    // maxHeight: 75,
    // overflow: 'hidden',
    borderRadius: 5,
    // flexDirection: 'column',
    // justifyContent: 'space-between',
    // backgroundColor: '#769ba2',
    // border: '2px solid #444',
    margin: 5,
    // padding: 5,
    // color: '#eee'
  },
  listItemMainArea: {
    display: 'flex',
    // alignSelf: 'stretch',
    // flexGrow: 1,
    // width: '100%',
    maxHeight: 75,
    overflow: 'hidden',
    borderRadius: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
    // backgroundColor: '#769ba2',
    // border: '2px solid #444',
    // // margin: 5,
    // padding: 5,
    // color: '#eee'
  },
  listItemName: {
    display: 'flex',
    // flexGrow: 3,

  },
  listItemDetails: {
    display: 'flex',
    // flexGrow: 1,
    // height: '100%',
    
  },
  statusArea: {
    display: 'flex',
    flexGrow: 1,
    marginTop: 'auto'
  },
  listItemControls: {
    display: 'flex',
    width: '15%'
  },
  secondRow: {
    marginTop: 5,
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  allItemContainer: {
    // backgroundColor: 'yellow',
    display: 'flex',
    flexGrow: 1,
    height: '100%',
    borderRadius: 10,
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  allItemHeader: {
    display: 'flex',
    alignSelf: 'stretch',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 5
  },
  allItemContent: {
    display: 'flex',
    alignSelf: 'flex-start',
    flexGrow: 1,
    width: '100%',
    maxHeight: '80vh',
    // backgroundColor: 'red',
    margin: 5,
    overflowY: 'auto'
  },
  listContainer: {
    display: 'flex',
    // flexGrow: 1,
    alignSelf: 'stretch',
    width: '98%',
    flexDirection: 'column'
    // backgroundColor: 'blue'
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'flex-start',
    flex: 1,
    height: '100%',
    padding: 5,
    backgroundColor: '#fff',
    // minHeight: 600,
    border: '2px solid #aaa',
    borderRadius: 8
  },
  mainContentArea: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    // backgroundColor: 'red',
    minHeight: 680
  },
  titleContainer: {
    margin: 5,
    // border: '1px solid #bbb',
    // borderRadius: 4,
    display: 'flex',
    height: '15%',
    maxHeight: '60px',
    // backgroundColor: 'yellow',
    flexGrow: 1
  },
  middleContainer: {
    // margin: 5,
    // backgroundColor: 'green',
    display: 'flex',
    flexGrow: 6,
    flexDirection: 'row',
    alignSelf: 'stretch'
    // border: '1px solid #bbb',
    // borderRadius: 4,
  },
  toDoArea: {
    width: '70%',
    border: '1px solid #bbb',
    borderRadius: 4,
    margin: 5,
    padding: 5,
    overflow: 'hidden'
  },
  middleRightContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'stretch',
    width: '30%',

  },
  calendarArea: {
    // width: '70%',
    // border: '1px solid #bbb',
    // borderRadius: 4,
    height: '10%',
    padding: 10,
    margin: 5
  },
  projectMilestonesArea: {
    // width: '30%',
    border: '1px solid #bbb',
    borderRadius: 4,
    flex: 1,
    margin: 5
  },
  bottomContainer: {
    margin: 5,
    // backgroundColor: 'yellow',
    display: 'flex',
    height: '25%',
    flexGrow: 3,
    border: '1px solid #bbb',
    borderRadius: 4,
  },
  newItemInput: {
    marginTop: 15
  },
  uploadsArea: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  uploadsInputArea: {
    flexBasis: '30%'
  },
  thumbnailsContainer: {
    display: 'flex',
    overflowX: 'auto',  // Enable horizontal scrolling when necessary
    padding: theme.spacing(1),
    '&::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '4px',
    },
  },
  thumbnail: {
    flexShrink: 0,  // Prevents the thumbnails from shrinking
    width: 75,  // Thumbnail width
    height: 75, // Thumbnail height
    marginRight: theme.spacing(1),  // Space between thumbnails
    borderRadius: '4px',
    overflow: 'hidden',
    border: `1px solid ${theme.palette.divider}`,
  },
  thumbnailImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',  // Ensures the image covers the thumbnail area
  },
  milestonesList: {
    display: 'flex',
    flexDirection: 'column',
  },
  milestoneItem: {
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  activeMilestone: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  filterIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.info.light,
    color: theme.palette.info.contrastText,
    marginBottom: theme.spacing(2),
  },
  bottomLineContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: 10,
    margin: 10,
    // backgroundColor: 'blue',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  }
}));
