import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, InputAdornment }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import InputMask from "react-input-mask";

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  label: {
    color: '#aaa',
    fontSize: '1.1em'
  }
}));

const NewEntityModal = (props) => {
// console.log('New entity Modal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [individual, setIndividual] = React.useState(true);
  // const [invoice, setInvoice] = React.useState(null)
  const [state, setState] = React.useState({
    firstname: '',
    lastname: '',
    displayName: '',
    entityName: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    telephone: '',
    email: '',
    autopayMTC: false,
    autopayAll: false,
    contractor: false,
    HOA: false,
    doNotEmail: false,
    paperInvoice: false,
    jobSiteAddress: '',
    jobSiteCity: '',
    jobSiteZip: '',
    jobSiteIsBilling: false,
    primaryColor: '#ccc',
    secondaryColor: '#232'
  })
  const [item, setItem] = React.useState({})

const handleClose = () => {
// console.log('Closing DepositModal Modal')
  props.closeModal()
}

const handleSave = () => {
  setLoading(true)
// console.log('Save this Entity!!!', state)
  // console.log('Send this Deposit to The Server')
    let obj = {
      entity: state,
      user: props.user
    }
    // const formData = new FormData();
    // formData.append('logo', state.file);
    // formData.append('smallLogo', state.smallLogo);
    // formData.append('item', JSON.stringify(obj));
    // formData.append('name', item.name);
    axios({
      method: 'post',
      url:  `${props.url}/api/entities/addNewEntity`,
      data: obj
    }).then(response => {
      setLoading(false)
      // console.log('Axios response from Add addNewEntity: ', response)
      if (response && response.data && response.data._id) {
      // console.log('We have a new Entity!!', response.data)
        setItem(response.data)
        props.notification({
          type: 'success',
          title: 'Entity Created!',
          message: 'New Entity Was Created Successfully'
        })
        if (state.file) uploadLogo({ entityId: response.data._id, logo: state.file})
        if (state.navLogo) uploadNavLogo({ entityId: response.data._id, logo: state.smallLogo})

        setState({...state,
          firstname: '',
          lastname: '',
          displayName: '',
          companyname: '',
          address: '',
          address2: '',
          city: '',
          state: '',
          zipcode: '',
          telephone: '',
          email: '',
          autopayMTC: false,
          autopayAll: false,
          contractor: false,
          HOA: false,
          doNotEmail: false,
          paperInvoice: false,
          jobSiteAddress: '',
          jobSiteCity: '',
          jobSiteZip: '',
          jobSiteIsBilling: false
        })
        // handleClose('success', response.data)
        props.updateParent({entity: response.data})

       
      } else {
        props.notification({
          type: 'warning',
          title: 'Unknown Error',
          message: 'We Do Not Know Why This Shows Up...'
        })
      }
  })
}

const handleAddNavLogo = () => {
  hiddenFileInputNav.current.click()
}

const handleFileChangeNav = event => {
// console.log('file chanage')
  const fileUploaded = event.target.files[0];
// console.log('File UPlaoded: ', fileUploaded)
  // props.handleFile(fileUploaded);
  const pictureURL = URL.createObjectURL(fileUploaded)
  setState({...state, smallLogo: fileUploaded, navLogo: pictureURL })

};

const uploadNavLogo = ({ entityId, logo }) => {
  const formData = new FormData();
// console.log('Updload Nav Logo: ', entityId)
  formData.append('navLogo', logo);
  // formData.append('smallLogo', state.smallLogo);
  // formData.append('item', JSON.stringify(obj));
  formData.append('entityId', entityId);
  axios({
    method: 'post',
    url:  `${props.url}/api/entities/addNavLogo`,
    data: formData
  }).then(response => {
    setLoading(false)
  })
}

const uploadLogo = ({ entityId, logo }) => {
  const formData = new FormData();
  formData.append('logo', logo);
  // formData.append('smallLogo', state.smallLogo);
  // formData.append('item', JSON.stringify(obj));
  formData.append('entityId', entityId);
  axios({
    method: 'post',
    url:  `${props.url}/api/entities/addLogo`,
    data: formData
  }).then(response => {
    setLoading(false)
  })
}

const handleAddLogo = () => {
// console.log('Add Logo To Entity')
  hiddenFileInput.current.click()
  // hiddenFileInput.blur()
}

const hiddenFileInput = React.useRef(null);
const hiddenFileInputNav = React.useRef(null);

const handleFileChange = event => {
// console.log('file chanage')
  const fileUploaded = event.target.files[0];
// console.log('File UPlaoded: ', fileUploaded)
  // props.handleFile(fileUploaded);
  const pictureURL = URL.createObjectURL(fileUploaded)
  setState({...state, file: fileUploaded, pictureURL: pictureURL })
};

const ShowPic = () => {
  if (state.pictureURL) {
    return <img width="250" src={state.pictureURL} style={{margin: '25px'}}></img>
  } else {
    return ''
  }
}

const ShowPicNav = () => {
  if (state.navLogo) {
    return <img width="250" src={state.navLogo} style={{margin: '25px'}}></img>
  } else {
    return ''
  }
}

const updatePhoneNumber = (value) => {
  // console.log('Update mask', value)
  let result = value.replace(/[^0-9]/g,"")
  // console.log('Nums only', result)
  // let ourResult = ''
  // if (result && parseInt(result[0])) {
  //   // console.log('Is a number!')
  //   ourResult = result.join("")
  // }
  setState({...state, telephoneFormatted: value, telephone: result })
}

return (
  <div className={classes.root}>
    <Dialog open={props.open} fullWidth={false} onClose={handleClose} maxWidth='sm' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Entity</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Provide Entity Information Below
          </DialogContentText>
          <Grid container>
                  <Grid item lg={12}>
                      <h3 >Entity Information</h3>
                    </Grid>
                    <strong className={classes.label}>Basic Info To Display</strong>
                    <Grid item lg={12} md={12} xs={12} className={classes.gridItem}> 
                          <TextField
                            autoFocus
                            label="Entity Name"
                            onChange={ e => setState({...state, entityName: e.target.value })}
                            name="entityName"
                            value={ state.entityName }
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                    {/* <Grid item lg={6} xs={6} className={classes.gridItem}> 
                          <TextField
                            autoFocus
                            label="Contact First Name"
                            onChange={ e => setState({...state, firstName: e.target.value })}
                            name="firstname"
                            value={ state.firstname }
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={6} xs={6} className={classes.gridItem}> 
                                    
                          <TextField
                          
                            label="Contact Last Name"
                            onChange={ e => setState({...state, lastname: e.target.value })}
                            name="lastname"
                            value={ state.lastname }
                            margin="normal"
                            fullWidth
                          />
                        </Grid> */}
                    <Grid item lg={12}>

             <TextField
                className={classes.paddedStuff}
                margin="dense"
                id="Email"
                label="Displayed Email"
                type="text"
                onChange={(e) => setState({...state, email: e.target.value})}
                value={state.email}
                fullWidth
            />
         <InputMask mask="(999) 999-9999" maskPlaceholder="_" onChange={e => updatePhoneNumber(e.target.value)} value={state.telephone} >
                          {
                            () => {
                              return (

                                <TextField
                                className={classes.paddedStuff}
                                margin="dense"
                                id="Telephone"
                                label="Displayed Telephone Number"
                                type="telephone"
                                // onChange={(e) => setState({...state, telephone: e.target.value})}
                                value={state.telephone}
                                fullWidth
                            />

                                // <TextValidator
                                //   label="Expiration"
                                //   // onChange={ e => setState({...state, expiry:e.target.value } ) }
                                //   name="expiry"
                                //   // value={ state.expiry }
                                //   validators={[
                                //     'required',
                                //     'minStringLength:4',
                                //     'maxStringLength:5'
                                //   ]}
                                //   errorMessages={[
                                //     'this field is required',
                                //     'min 4 characters',
                                //     'max 5 characters'
                                //   ]}
                                //   margin="dense"
                                //   fullWidth
                                // />
                              )
                            }
                          }
                        
                          </InputMask>
            </Grid>
            
            <Grid item lg={12} xs={12} className={classes.gridItem}> 
            <strong className={classes.label}>Displayed Billing Address</strong>
                        <TextField
                      label="Address"
                      fullWidth
                      value= {state.address}
                        onChange={ e => setState({...state, address:e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />

                    </Grid>
                    <Grid item lg={6} xs={6} className={classes.gridItem}>
                      <TextField
                        label="City"
                        fullWidth
                        value= {state.city}
                        onChange={ e => setState({...state, city:e.target.value } ) }
                        // variant="outlined"
                        // value= {state.service.time}
                        // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                        />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                      <TextField
                        label="State"
                        fullWidth
                        value= {state.state}
                        onChange={ e => setState({...state, state:e.target.value } ) }
                      />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                    <TextField
                        label="Zipcode"
                        fullWidth
                        // type="number"
                        // variant="outlined"
                        value= {state.zipcode}
                        onChange={ e => setState({...state, zipcode:e.target.value } ) }
                        />
                    </Grid>
          </Grid>
          <Grid container>
            <Grid item lg={12} xs={12} className={classes.gridItem}> 
              <strong className={classes.label}>Company Theme Information</strong>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              <span className={classes.label}>Please Provide a Primary Color for the New Entity</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              Need help? You can use this <a href='https://g.co/kgs/pGcMxe' target='_blank'>Online Color Picker</a> to find the right shade.
            </Grid>
            

            <Grid item lg={12} xs={12} className={classes.gridItem}>
              <TextField
                label="Primary Color (Hex Code #XXXXXX)"
                fullWidth
                // type="number"
                // variant="outlined"
                value= {state.primaryColor}
                onChange={ e => setState({...state, primaryColor:e.target.value } ) }
                helperText={'This will be used as a the primary color on Invoices/Statements/Etc'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <div style={{ height: '30px', width: '30px', margin: '3px', backgroundColor: state.primaryColor}}>&nbsp;</div>
                    </InputAdornment>
                  )
                }}
                />
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              <span className={classes.label}>Please Provide a Secondary Color for the New Entity</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              <TextField
                label="Secondary Color (Hex Code #XXXXXX)"
                fullWidth
                // type="number"
                // variant="outlined"
                value= {state.secondaryColor}
                onChange={ e => setState({...state, secondaryColor:e.target.value } ) }
                helperText={'This will be used as a complimentary color on Invoices/Statements/Etc'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <div style={{ height: '30px', width: '30px', margin: '3px', backgroundColor: state.secondaryColor}}>&nbsp;</div>
                    </InputAdornment>
                  )
                }}
                />
                
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              <span className={classes.label}>Please Provide a Primary Logo for the New Entity</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              {state.pictureURL ? <ShowPic /> : ''}
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}> 
                  <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddLogo}
                    >Add/Change Logo 
                  </Button>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{display: 'none'}}
                  />
               
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              <span className={classes.label}>Please Provide a Secondary (SMALLER) Logo for the Navigation Bar</span>
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              {state.navLogo ? <ShowPicNav /> : ''}
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}> 
                  {/* { state.navLogo ? (
                    <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveNavLogo}
                      >Save Nav Logo 
                    </Button>
                  ) : (
                    <> */}
                    <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddNavLogo}
                    >Add/Change Nav Logo 
                  </Button>
                  <input
                    type="file"
                    ref={hiddenFileInputNav}
                    onChange={handleFileChangeNav}
                    style={{display: 'none'}}
                  />
                  {/* </>
                  )} */}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading || !state.entityName || !state.email || !state.telephone || !state.address || !state.city || !state.state || !state.zipcode } onClick={handleSave}>
            {
              loading ? <CircularProgress /> : 'Save'
            }
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(NewEntityModal)