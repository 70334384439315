import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  }
}));

// function htmlToText(html){
//   // console.log('Strip html tags: ', html)
//   //remove code brakes and tabs
//   html = html.replace(/\n/g, "");
//   html = html.replace(/\t/g, "");

//   //keep html brakes and tabs
//   html = html.replace(/<table>/g, "");
//   html = html.replace(/<tr>/g, "");
//   html = html.replace(/<td>/g, "");
//   html = html.replace(/<\/td>/g, "\t");
//   html = html.replace(/<\/table>/g, "\n");
//   html = html.replace(/<\/tr>/g, "\n");
//   html = html.replace(/<\/p>/g, "\n");
//   html = html.replace(/<\/div>/g, "\n");
//   html = html.replace(/<div>/g, "");
//   html = html.replace(/<\/h>/g, "\n");
//   html = html.replace(/<br>/g, "\n"); 
//   html = html.replace(/<br( )*\/>/g, "\n");
//   // console.log('Stripped: ', html)
//   //parse html into text
//   // var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
//   // return dom.body.textContent;
//   return html;
// }

const EditJob = (props) => {
  // console.log('Edit  Job: ', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [jobNumber, setJobNumber] = React.useState(null)
  const [state, setState] = React.useState({
    jobName: props.client.displayName + '-',
    billingAddress: {},
    jobSiteAddress: '',
    jobSite: {},
    job: {},
    salesRep: {name: ''},
    billingAddy: {},
    jobName: ''
  })
  React.useEffect(() => {
    // console.log('Use Effect New Job')
    setOpen(props.open)
    // Find our Jobsite
    if (props.jobSites && props.jobSites.length && props.job && props.job._id && props.salesReps && props.salesReps.length) {
      // console.log('Job Sites: ', props.jobSites)
      let index = props.jobSites.findIndex(crewItem =>  (crewItem['_id'] === props.job['jobSite']));
      let ourSite = props.jobSites[index]
      let repIndex = props.salesReps.findIndex(crewItem =>  (crewItem['_id'] === props.job['salesRep']));
      let ourRep = props.salesReps[repIndex]
      // console.log('Job Site: ', ourSite)
      // console.log('Index: ', index)
      setState({...state, salesRep: ourRep, job: props.job, jobName: props.job.name, jobSiteAddress: ourSite})
    }
    // if (props.salesReps && props.salesReps.length && props.job && props.job._id) {
    //   // console.log('Find our initial salesrep: ', props.job)
    //   let index = props.salesReps.findIndex(crewItem =>  (crewItem['_id'] === props.job['salesRep']));
    //   let ourRep = props.salesReps[index]
    //   // console.log('Job Site: ', ourSite)
    //   // console.log('Index: ', index)
    //   setState({...state, salesRep: ourRep})
    // }
  }, [props])

const handleClose = () => {
  // console.log('Closing Edit Job Modal')
  setOpen(false)
  props.jobDetailsResponse('Close')
}

const handleConfirm = (data) => {
  // console.log('CONFIRMED NEW JOB!!', data)
}
const handleSave = () => {
  // console.log('handle save', state)
  // console.log(props)
  let obj = {
    client: props.client,
    name: state.jobName,
    jobSite: state.jobSiteAddress,
    user: props.user,
    jobId: props.job._id,
    salesRep: state.salesRep
    // props: props
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/updateJob`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to update the workorder: ', response)
    // setJobNumber(response.data)
    if (response.data) {
      if (response.data && response.data._id) {
        // props.jobDetailsResponse(response.data)
        props.notification({
          type: 'success',
          title: 'Job Number' + response.data.number + ' was updated and saved!',
          message: 'Well Done!!'
        })
        props.jobDetailsResponse('Edited', response.data)
        // setOpen(false)
      } else {
        if (response.data && response.data.error) {
          props.notification({
            type: 'warning',
            title: 'Error Updating Job',
            message: `${response.data.message}`
          })
        }
      }
    } else {
      // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up saving this workorder...not sure why!'
      })
    }
    
  }).catch(err => {
    // console.log('Error saving workorder: ', err)
  })
}

const updateBillingAddress = (e, addy) => {
  // console.log('Update Billing Address: ', e)
  // console.log(addy)
  if (addy && addy.address) {
    setState({...state, billingAddress: addy})
  }
}
const updateJobSiteAddress = (e, addy) => {
  // console.log('Update Job Site Address: ', e)
  // console.log(addy)
  if (addy && addy.address) {
    setState({...state, jobSite: addy.id, jobSiteAddress: addy})
  }
}

const updateSalesRep = (e, rep) => {
  // console.log('Update Sales rep ', e)
  // console.log(rep)
  if (rep && rep._id) {
    setState({...state, salesRep: rep})
    // console.log('State after updating rep?', state)
  }
}

return (
  <div className={classes.root}>
    <Dialog open={open} fullWidth={true} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Job (Work Order)</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Provide The Basic Job Info Below
          </DialogContentText>
          <TextField
            autoFocus
            className={classes.paddedStuff}
            margin="dense"
            id="JobName"
            label="Job Name"
            type="text"
            onChange={(e) => setState({...state, jobName: e.target.value})}
            value={state.jobName}
            fullWidth
          />
           <Autocomplete
            id="JobSite"
            options={props.jobSites}
            getOptionLabel={(option) => option.address}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            onChange={updateJobSiteAddress}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {state.jobSiteAddress}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} label="Job Site Address" />}
          />
           <Autocomplete
            id="SalesPerson"
            options={props.salesReps}
            getOptionLabel={(option) => option.displayName}
            className={classes.paddedStuff}
            // style={{ width: 200 }}
            onChange={updateSalesRep}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {state.salesRep}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} label="Sales Person"  />}
          />
          {/*


          <Autocomplete
            id="BillingAddress"
            options={props.jobSites}
            getOptionLabel={(option) => option.address}
            className={classes.paddedStuff}
            // style={{ width: 200 }}
            onChange={updateBillingAddress}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {state.billingAddress}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} label="Billing Address"  />}
          />

            */}
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      salesReps: state.salesReps
    }
  }
  
  export default React.memo(connect(mapStateToProps)(EditJob))