import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';

const defaultValues = {
  pin1: '',
  pin2: ''
}

const SetPINModal = (props) => {

  const [state, setState] = React.useState(defaultValues)
  const handleCloseModal = () => {
    props.closeModal()
  }

  const handleSubmit = () => {
    if (state.pin1 !== state.pin2) {
      props.notification({
        type: 'warning',
        title: 'PIN Mismatch Error',
        message: `Please Enter the Same PIN Twice`
      })
      return
    }
    let obj = {
      employee: props.employee,
      pin: state.pin1
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/employees/savePIN`,
      data: obj
    }).then(response => {
      // console.log('Absence Reported', response.data)
      if (response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Employee PIN Saved',
          message: `Your PIN Has Been Saved - You Can Now Use This PIN To Login To The Mobile App!`
        })
        setState(defaultValues)
        props.closeModal()
      } else {
        props.notification({
          type: 'warning',
          title: 'Error Saving PIN',
          message: response.data.message
        })
      }
    })
    
    // .catch(err => {
    // // console.log('Error reporting absence', err)
    //   props.notification({
    //     type: 'warning',
    //     title: 'Error Saving Absence',
    //     message: `Error Saving Absence (Error Code: AM50)`
    //   })
    // })
  }

  const handleUpdatePIN = (e, num) => {
    if (num === 1) {
      setState({...state, pin1: e.target.value})
    }
    if (num === 2) {
      setState({...state, pin2: e.target.value})
    }
  }

return (
  <div>
    
      <Dialog
        // fullScreen={false}
        // maxWidth={'lg'}
        // width='lg'
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Set Employee PIN -  {props.employee.displayName}</DialogTitle>
        <DialogContent>
          <DialogContentText>Haga que el empleado ingrese su PIN secreto a continuación:</DialogContentText>
          <DialogContentText>
            Please have the employee enter their secret PIN below:
          </DialogContentText>
          <FormControl fullWidth style={{marginBottom: '20px'}}>
                    <TextField
                    id="pin1"
                    label="PIN Attempt #1"
                    variant="outlined"
                    fullWidth
                    type="password"
                    
                    // className={classes.textSaveLineBreaks}
                    value={state.pin}
                    onChange={e => handleUpdatePIN(e, 1)}
                    inputProps={{
                      maxLength: 8,
                      style: {
                        height: '70px',
                        padding: '0 14px',
                        fontSize: '25px'
                      },
                  }}
                    // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                    // defaultValue="Default Value"
                />
                <FormHelperText>PIN Should Be 4 to 8 Digits and MUST Be Unique.</FormHelperText>
              </FormControl>
              <FormControl fullWidth style={{marginBottom: '20px'}}>
                    <TextField
                    id="pin2"
                    label="PIN Attempt #2"
                    variant="outlined"
                    fullWidth
                    type="password"
                    
                    // className={classes.textSaveLineBreaks}
                    value={state.pin2}
                    onChange={e => handleUpdatePIN(e, 2)}
                    inputProps={{
                      maxLength: 8,
                      style: {
                        height: '70px',
                        padding: '0 14px',
                        fontSize: '25px'
                      },
                  }}
                    // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                    // defaultValue="Default Value"
                />
                <FormHelperText>PIN Should Be 4 to 8 Digits and MUST Be Unique.</FormHelperText>
              </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button disabled={!state.pin1 || !state.pin2} onClick={() => handleSubmit()} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(SetPINModal)