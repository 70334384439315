import React, { useState, useRef, useCallback } from 'react';
import { GoogleMap, LoadScript, Polygon, Marker, DrawingManagerF, InfoWindow  } from '@react-google-maps/api';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, Select, MenuItem } from '@material-ui/core';

const libs = ['drawing']

const containerStyle = {
  width: '100%',
  height: '100vh'
};

const center = {
  lat: 33.91023013848473,
  lng: -112.084847523098
};

// Define the coordinates for your polygons
const polygonData = []

//   {
//     paths: [
//       { lat: 33.90985577070521, lng: -112.08535049065343 },
//       { lat: 33.90990256676756, lng: -112.08513171104411 },
//       { lat: 33.90986700176252, lng: -112.08509562368587 },
//       { lat: 33.90974533189616, lng: -112.08510690098534 },
//       { lat: 33.9097322289771, lng: -112.08519486392103 },
//       { lat: 33.90984266780312, lng: -112.08535049065343 }
//     ],
//     area: 1,
//     color: '#FF0000',
//     fillColor: '#ff11ff'
//   },
//   {
//     paths: [
//       { lat: 33.90972315285817, lng: -112.08478814262192 },
//       { lat: 33.909819984346385, lng: -112.08479887145772 },
//       { lat: 33.909819984346385, lng: -112.08488336103959 },
//       { lat: 33.90973650893206, lng: -112.08488336103959 },
//       // { lat: 33.9097322289771, lng: -112.08519486392103 },
//       // { lat: 33.90984266780312, lng: -112.08535049065343 }
//     ],
//     area: 2,
//     color: '#FF0000',
//     fillColor: '#ff11ff'
//   },
//   // {
//   //   paths: [
//   //     { lat: 51.52, lng: -0.1 },
//   //     { lat: 51.53, lng: -0.11 },
//   //     { lat: 51.53, lng: -0.13 },
//   //     { lat: 51.52, lng: -0.12 }
//   //   ],
//   //   color: '#00FF00'
//   // }
// ];
const getMapOptions = {
  mapTypeId: 'satellite',
  disableDefaultUI: true,
  tilt: 45,
  scrollwheel: false,
}

// Define the coordinates for your markers
const markerData = [
  { position: { lat: 51.505, lng: -0.09 }, label: 'A' },
  { position: { lat: 51.52, lng: -0.1 }, label: 'B' }
];
const mapsStyle = 'satelite'
const MapWithPolygons = () => {
  const [polygons, setPolygons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [newArea, setNewArea] = useState({ name: '', strokeColor: '#FF0000', fillColor: '#FF0000', paths: [] });
  const [isDrawing, setIsDrawing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const drawingManagerRef = useRef(null);

  const onPolygonComplete = (polygon) => {
    const paths = polygon.getPath().getArray().map(latlng => ({ lat: latlng.lat(), lng: latlng.lng() }));
    setNewArea({ ...newArea, paths });
    setIsDialogOpen(true);
    polygon.setMap(null); // Remove the polygon from the map until confirmed
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsDrawing(false);
  };

  const handleSaveArea = () => {
    const newPolygons = [...polygons, newArea];
    setPolygons(newPolygons);
    localStorage.setItem('polygons', JSON.stringify(newPolygons));
    setIsDialogOpen(false);
    setNewArea({ name: '', strokeColor: '#FF0000', fillColor: '#FF0000', paths: [] });
    setIsDrawing(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewArea({ ...newArea, [name]: value });
  };

  // Load polygons from local storage or server
  const loadPolygons = () => {
    const savedPolygons = JSON.parse(localStorage.getItem('polygons'));
    if (savedPolygons) {
      let newPolys = [
        ...savedPolygons,
        ...polygonData
      ] 
      console.log(newPolys)
      setPolygons(newPolys);
    }
  };

  // Load polygons when the component mounts
  React.useEffect(() => {
    loadPolygons();
  }, []);

  const handlePolygonClick = (index) => {
    console.log('Handle Polygon Click....', index)
    console.log('index', index)
    setSelectedPolygon(index);
    const polygon = polygons[index];
    setNewArea({ name: polygon.name, strokeColor: polygon.strokeColor, fillColor: polygon.fillColor, paths: polygon.paths });
    setIsDialogOpen(true);
  }

  const handleDeletePolygon = () => {
    const newPolygons = polygons.filter((_, index) => index !== selectedPolygon);
    setPolygons(newPolygons);
    localStorage.setItem('polygons', JSON.stringify(newPolygons));
    setSelectedPolygon(null);
    setIsDialogOpen(false);
  };

  const handleUpdatePolygon = () => {
    const updatedPolygons = polygons.map((polygon, index) =>
      index === selectedPolygon ? newArea : polygon
    );
    setPolygons(updatedPolygons);
    localStorage.setItem('polygons', JSON.stringify(updatedPolygons));
    setSelectedPolygon(null);
    setIsDialogOpen(false);
  };

  const startDrawingPolygon = () => {
    setIsDrawing(true);
    if (drawingManagerRef.current) {
      drawingManagerRef.current.state.drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    }
  };

  const getPolygonCenter = useCallback((paths) => {
    if (window.google && window.google.maps) {
      const bounds = new window.google.maps.LatLngBounds();
      paths.forEach(path => {
        bounds.extend(new window.google.maps.LatLng(path.lat, path.lng));
      });
      return bounds.getCenter();
    }
    return center; // Default center if google maps is not available
  }, []);
  

  console.log('Polygons', polygons)
  return (
    <div>
      <Button variant="contained" color="primary" onClick={ startDrawingPolygon }>Create New Area</Button>

    <LoadScript googleMapsApiKey={process.env.REACT_APP_GMAPKEY} libraries={libs} >
      <GoogleMap 
        mapContainerStyle={containerStyle}
        center={center} 
        zoom={19}
        onLoad={(map)=>{
          console.log('Map has loaded...', map)
        }}
        options={{
          streetViewControl: false,
          draggable: true, // make map draggable
          zoomControlOptions: { position: 19 },
          keyboardShortcuts: false, // disable keyboard shortcuts
          scaleControl: true, // allow scale controle
          scrollwheel: true, // allow scroll wheel
          styles: mapsStyle,// change default map styles,
          mapTypeId: 'satellite'
        }}
       >
        {polygons.map((polygon, index) => (
          <React.Fragment key={index}>
          <Polygon
            paths={polygon.paths}
            options={{
              fillColor: polygon.fillColor,
              fillOpacity: 0.4,
              strokeColor: polygon.strokeColor,
              strokeOpacity: 1,
              strokeWeight: 2,
              editable: selectedPolygon === index,
              draggable: selectedPolygon === index
            }}
            onClick={() => handlePolygonClick(index)}
          />
          <InfoWindow position={getPolygonCenter(polygon.paths)}>
            <div>{polygon.name}</div>
          </InfoWindow>
        </React.Fragment>
        ))}
{/*         
        {markerData.map((marker, index) => (
          <Marker key={index} position={marker.position} label={marker.label} />
        ))} */}
       {isDrawing && (
         <DrawingManagerF
          ref={drawingManagerRef}
          onPolygonComplete={onPolygonComplete}
          options={{
            drawingControl: true,
            drawingControlOptions: {
              position: 21, // window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: ['polygon']
            },
            polygonOptions: {
              fillColor: newArea.fillColor,
              fillOpacity: 0.4,
              strokeColor: newArea.strokeColor,
              strokeOpacity: 1,
              strokeWeight: 2,
              editable: true,
              draggable: true
            }
          }}
        />
       )}
      </GoogleMap>


      <Dialog open={isDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{selectedPolygon !== null ? 'Edit Area' : 'Create New Area'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            label="Area Name"
            type="text"
            fullWidth
            value={newArea.name}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="strokeColor"
            label="Stroke Color"
            type="color"
            fullWidth
            value={newArea.strokeColor}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="fillColor"
            label="Fill Color"
            type="color"
            fullWidth
            value={newArea.fillColor}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Cancel</Button>
          {selectedPolygon !== null ? (
            <>
              <Button onClick={handleDeletePolygon} color="secondary">Delete</Button>
              <Button onClick={handleUpdatePolygon} color="primary">Update</Button>
            </>
          ) : (
            <Button onClick={handleSaveArea} color="primary">Save</Button>
          )}
        </DialogActions>
      </Dialog>


    </LoadScript>
  </div>
  );
};

export default MapWithPolygons;
