import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Tooltip, Typography } from '@material-ui/core';

// Assuming this is the structure of your data
const initialRows = [
  { employeeName: 'John Doe', '2024-03-11': 8, '2024-03-12': 9 },
  { employeeName: 'Jane Smith', '2024-03-11': 7, '2024-03-12': 8 },
  // Add more employees and dates as needed
];

// Columns derived from your data keys (excluding employeeName)
const columns = Object.keys(initialRows[0]).filter(key => key !== 'employeeName').map(date => ({
  id: date,
  label: date,
}));

const SortableTable = (props) => {
  console.log('SortableTable:: ', props)
  const [rows, setRows] = useState(initialRows);
  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState(columns[0].id); // Default sort column
  const data = props.data
  const uniqueDates = [...new Set(data.flatMap(emp => emp.dates.map(d => d.date)))].sort();

  console.log('Unique Dates:', uniqueDates)
  React.useState(() => {
    if (props.data && props.data.length) setRows(props.data)
  }, [props.data])

  const handleSort = (property) => (event) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);

    const sortedRows = [...rows].sort((a, b) => {
      if (a[property] < b[property]) {
        return isAsc ? -1 : 1;
      }
      if (a[property] > b[property]) {
        return isAsc ? 1 : -1;
      }
      return 0;
    });

    setRows(sortedRows);
  };

  return (
    <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Employee Name</TableCell>
          {uniqueDates.map(date => (
            <TableCell key={date} align="right">{date}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((employee) => (
          <TableRow key={employee.employeeId}>
            <TableCell component="th" scope="row">
              {employee.employeeName}
            </TableCell>
            {uniqueDates.map(date => {
              const dateInfo = employee.dates.find(d => d.date === date);
              return (
                <TableCell key={date} align="right">
                  {dateInfo ? (
                          <React.Fragment>
                            <React.Fragment>
                            {dateInfo.shifts.map((shift, index) => {
                              console.log('Shift details', shift)
                              return (
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                  <Typography key={index} variant="body2">
                                  {shift.clockIn ? `Clock-In: ${formatOurTime(shift.clockIn || null)}` : '' }
                                 
                                 </Typography>
                                 <Typography key={index} variant="body2">
                                  { shift.clockOut ? `Clock-Out: ${formatOurTime(shift.clockOut || null)}` : '' }
                                 </Typography>
                                </div>

                              )}
                            )}
                          </React.Fragment>
                          <span>{`${dateInfo.totalHours} hrs`}</span> {/* Clickable span to show tooltip */}
                          </React.Fragment>
                        
                      ) : '—'}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  );
};

const formatOurTime = (date) => {
  let formattedDate = ''
  if (date) {
    console.log('Format this date', date)
    const ourDate = new Date(date)
    console.log('Our date', ourDate)
    formattedDate = ourDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) 
  }
  return formattedDate
}

export default SortableTable;
