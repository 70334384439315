import React from 'react'
import { connect } from 'react-redux'
import WorkorderList from './WorkorderList'
import axios from 'axios'
import { CircularProgress, Grid }  from '@material-ui/core';
const AssignWorkordersComponent = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState({
    workorders: []
  })
  async function fetchData(entityToUse) {
    // console.log('Update our clients')
    setLoading(true)
    const response = await axios.get(`${props.url}/api/jobs/getWorkordersToAssign?entity=${entityToUse}`)
  // console.log(response)
    setLoading(false)
    if (response.data && response.data.wos && response.data.wos.length > 0) {
    // console.log('Got WOS to Assign', response.data)
      setState({...state, workorders: response.data.wos, crews: response.data.crews})
    } else {
    // console.log('Fucked up getting Workorders')
    }
  }

  React.useEffect(() => {
  // console.log('Assing WOrkorders Init')
    fetchData('All')
  }, [])

  const updateEntity = (newEntity) => {
  // console.log('Update ', newEntity)
    fetchData(newEntity._id)
  }
  return (
    <div >
      { loading ? <CircularProgress style={{ textAlign: 'center', margin: '30px' }} color="primary" size={ 96 } /> : <WorkorderList updateEntity={updateEntity} workorders={state.workorders} crews={state.crews} loading={props.loading} /> }
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    user: state.user
  }
}

export default connect(mapStateToProps)(AssignWorkordersComponent)