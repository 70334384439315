import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { fade, makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
// import Cards from 'react-credit-cards';
// import { createForm } from 'final-form'
// import Styles from './Styles'
// import Card from './Card'
// import {
//   formatCreditCardNumber,
//   formatCVC,
//   formatExpirationDate
// } from './cardUtils'


const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  textSaveLineBreaks: {
    whiteSpace: 'pre-line'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

let requiresUpdate = 0;

function SimpleModal(props) {
  // console.log('Payment Method Modal Props: ', props)
  const classes = useStyles();
  // console.log('FOrm: ', createForm)
  // const preventDefault = event => event.preventDefault();
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const closeModal = props.closeFunc
  // const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  // const [modalStyle] = React.useState(getModalStyle);
  const user = props.user
  // console.log('Got our user: ', user)
  // const lead = props.lead
  const url = props.url
  const notification = props.notification
// console.log(url)

const [open, setOpen] = React.useState(false)
const [client, setClient] = React.useState(props.client)
const [contact, setContact] = React.useState(props.contact)
// const [confirmOpen, setconfirmOpen] = React.useState(false);
// const [selectedTour, setselectedTour] = React.useState({});
// const [newTourOpen, setnewTourOpen] = React.useState(false);
// const [tours, setTours] = React.useState([]);
// const [tourNotes, settourNotes] = React.useState('');
const [loading, setLoading] = React.useState(false);
const [individual, setIndividual] = React.useState(true);
const [locationName, setLocationName] = React.useState('');
const [saveCardDetails, setSaveCardDetails] = React.useState(true)
const [state, setState] = React.useState({
  accountType: 'checking',
  routingNumber: '',
  focused: '',
  nameOnAccount: '',
  accountNumber: '',
  eCheckType: 'PPD',
  bankName: '',
  firstname: '',
  lastname: '',
  companyname: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  client: props.client,
  customerType: ''
});

  useEffect(() => {
    // console.log('Use effect', requiresUpdate)
    // console.log('Use Effect Create Location Modal')
    // setAddress('')
    // setLocationName('')
    // setCity('')
    // setLocState('')
    // setZipcode('')
    let companyName = ''
    if (props.client && props.client.companyName) {
      setIndividual(false)
      companyName = props.client.companyName
    }
    setState({...state, 
      client: props.client,
      firstname: props.contact.firstName || '',
      companyname: props.client.companyName || '',
      lastname: props.contact.lastName || '',
      address: props.client.address || '',
      city: props.client.city || '',
      state: props.client.state || '',
      zipcode: props.client.zip || '',
      customerType: companyName
    })
  //   axios
  //     .get(
  //       `${url}/api/tours/getAll`
  //       )
  //     .then(({ data }) => {
  //     // console.log(data)
  //       setTours(data)
  //     }).catch(function (err) {
  //     // console.log('Error getting stuff', err)
  //     });
  }, [props]);


  const handleClick = (d) => {
    // console.log('Save Card Info', d)
  // console.log(state)
    let data = state
    if (individual) {
      data.customerType = 'individual'
      data.nameOnAccount = state.firstname + ' ' + state.lastname
    } else {
      data.customerType = 'business'
      data.nameOnAccount = state.companyname
    }
    data.amountToCharge = props.amountToCharge
    data.estimate = props.estimate
    data.savePaymentMethodDetails = saveCardDetails
    data.type = 'eCheck'

    setLoading(true)
    // console.log(client)
    axios({
      method: 'post',
      url:  `${url}/api/proposal/initialDeposit`,
      data: data,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {

      //   }
      // },
    }).then(response => {
    // console.log('Axios response: ', response)
      if (response && response.data.error) {
      // console.log('Error: ', response.data.error)
        props.notification({
          type: 'warning',
          title: 'We Ran Into A Problem!',
          message: response.data.message
        })
      }
      let data = response.data
      if (data && data.API_Response) {
      // console.log('Got some data', data.API_Response)
        let apiResponse = data.API_Response
        if (apiResponse && apiResponse.messages && apiResponse.messages.resultCode === 'Error') {
        // console.log('ERROR Saving payment profile!!!', apiResponse.messages.message)
          let errMessage = apiResponse.messages.message[0]['text']
          props.notification({
            type: 'danger',
            title: 'Failure!',
            message: errMessage
          })
        } else {
        // console.log('Worked??', apiResponse.customerProfileId)
          props.notification({
            type: 'success',
            title: 'Success!',
            message: 'Payment Profile saved!'
          })
          setTimeout(() => { handleClose('refreshClientDetails', apiResponse.customerProfileId) }, 1500)
        }
      }
      if (response && response.data && response.data.deposit) {
        props.notification({
          type: 'success',
          title: 'Success!',
          message: 'Deposit Authorized! Your Project Manager Will Be Notified Immediately.'
        })
      }
      setLoading(false)
    }).catch(error => {
    // console.log('AXios error: ', error)
      setLoading(false)
    })
  };
  // const handleClickAddNew = (d) => {
  // // console.log('Save location and Create a New')
    
  //   let obj = {
  //     locationname: locationName,
  //     address: address,
  //     city: city,
  //     state: locState,
  //     zipcode: zipcode
  //   }
  // // console.log('Location Object', obj)
  //   // SEND TO BACK END
  //   requiresUpdate++
  //   setOpen(false)
  //   closeModal(obj, 'new')
  //   setTimeout(function (){
  //   // console.log('Open it again')
  //     setOpen(true)
  //   }, 500)
  // };

  // const handleClickConfigure = function (e) {
  // // console.log('Save location and CONFIGURE', e)
  //   requiresUpdate++
  //   let obj = {
  //     locationname: locationName,
  //     address: address,
  //     city: city,
  //     state: locState,
  //     zipcode: zipcode
  //   }
  // // console.log('Location Object', obj)

  //   // Send to back end

  //   requiresUpdate++
  //   setOpen(false)
  //   closeModal(obj, 'configure')
  // };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (d, f) => {
    setOpen(false);
  // console.log(d)
    if (d) props.updateParent(d, f)
  };

  const divStyle = {
    // margin: '40px',
    // border: '5px solid pink',
    display: 'inline'
  };

  const onSubmit = async values => {
  // console.log(values)
  // console.log(state)
  }

  const handleSubmit =  async (e) => {
  // console.log('Handle submit: ', e)
  }

  return (
    <div style={ divStyle }>


          <ValidatorForm
                id="myForm"
                // ref="myForm"
                onSubmit={ handleSubmit }
                onError={ errors => console.log(errors) }
                // className={ styles.content }
              >
                {/* <TextValidator
                  label="Cardholder First Name"
                  onChange={ e => setState({...state, firstname: e.target.value })}
                  name="cardholderfirstname"
                  value={ state.firstname }
                  validators={[
                    'required',
                    'minStringLength:2',
                    'maxStringLength:100'
                  ]}
                  errorMessages={[
                    'this field is required',
                    'min 2 characters',
                    'max 100 characters'
                  ]}
                  margin="normal"
                  fullWidth
                />
                <TextValidator
                  label="Cardholder Last Name"
                  onChange={ e => setState({...state, lastname: e.target.value })}
                  name="cardholderlastname"
                  value={ state.lastname }
                  validators={[
                    'required',
                    'minStringLength:2',
                    'maxStringLength:100'
                  ]}
                  errorMessages={[
                    'this field is required',
                    'min 2 characters',
                    'max 100 characters'
                  ]}
                  margin="normal"
                  fullWidth
                /> */}
                <Grid container>
                <Grid item lg={12} xs={12} className={classes.gridItem}> 
                  Choose Individual or Business: <br />
                  <FormControlLabel
                        control={
                          <Switch
                            checked={individual}
                            onChange={() => setIndividual(!individual)}
                            name="Individual"
                            color="primary"
                          />
                        }
                        label={individual ? 'Individual' : 'Business'}
                      />
                  </Grid>
                  { individual ? 
                    (
                      <React.Fragment>
                      <Grid item lg={6} xs={12} className={classes.gridItem}> 
                          <TextValidator
                            label="Accountholder First Name"
                            onChange={ e => setState({...state, firstname: e.target.value })}
                            name="cardholderfirstname"
                            value={ state.firstname }
                            validators={[
                              'required',
                              'minStringLength:2',
                              'maxStringLength:100'
                            ]}
                            errorMessages={[
                              'this field is required',
                              'min 2 characters',
                              'max 100 characters'
                            ]}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={6} xs={12} className={classes.gridItem}> 
                                    
                          <TextValidator
                            label="Accountholder Last Name"
                            onChange={ e => setState({...state, lastname: e.target.value })}
                            name="cardholderlastname"
                            value={ state.lastname }
                            validators={[
                              'required',
                              'minStringLength:2',
                              'maxStringLength:100'
                            ]}
                            errorMessages={[
                              'this field is required',
                              'min 2 characters',
                              'max 100 characters'
                            ]}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        </React.Fragment>
                      ) : (
                        <Grid item lg={12} xs={12} className={classes.gridItem}> 
                                    
                          <TextValidator
                            label="Company Name"
                            onChange={ e => setState({...state, companyname: e.target.value })}
                            name="companyname"
                            value={ state.companyname }
                            validators={[
                              'required',
                              'minStringLength:2',
                              'maxStringLength:100'
                            ]}
                            errorMessages={[
                              'this field is required',
                              'min 2 characters',
                              'max 100 characters'
                            ]}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                      )
                    }
                    <Grid item lg={12} xs={12} className={classes.gridItem}> 
                        <TextField
                      label="Billing Address"
                      fullWidth
                      value= {state.address}
                        onChange={ e => setState({...state, address:e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />
                    </Grid>
                    <Grid item lg={6} xs={12} className={classes.gridItem}>
                      <TextField
                        label="Billing City"
                        fullWidth
                        value= {state.city}
                        onChange={ e => setState({...state, city:e.target.value } ) }
                        // variant="outlined"
                        // value= {state.service.time}
                        // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                        />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                      <TextField
                        label="Billing State"
                        fullWidth
                        value= {state.state}
                        onChange={ e => setState({...state, state:e.target.value } ) }
                      />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                    <TextField
                        label="Billing Zipcode"
                        fullWidth
                        // type="number"
                        // variant="outlined"
                        value= {state.zipcode}
                        onChange={ e => setState({...state, zipcode:e.target.value } ) }
                        />
                    </Grid>

                    <Grid item lg={12} xs={12} className={classes.gridItem}>
                        <TextValidator
                      label="Bank Name"
                      onChange={ e => setState({...state, bankName:e.target.value } ) }
                      name="bankName"
                      value={ state.bankName }
                      validators={[
                        'required',
                        'minStringLength:3',
                        'maxStringLength:50'
                      ]}
                      errorMessages={[
                        'this field is required',
                        'min 3 characters',
                        'max 50 characters'
                      ]}
                      margin="normal"
                      fullWidth
                    />
                    </Grid>
                    <Grid item lg={6} xs={12} className={classes.gridItem}>
                      
                <TextValidator
                  label="Routing Number"
                  onChange={ e => setState({...state, routingNumber:e.target.value } ) }
                  name="routingNumber"
                  value={ state.routingNumber }
                  validators={[
                    'required',
                    'minStringLength:9',
                    'maxStringLength:9'
                  ]}
                  errorMessages={[
                    'this field is required',
                    'min 9 characters',
                    'max 9 characters'
                  ]}
                  margin="normal"
                  fullWidth
                />
                
                    </Grid>
                    <Grid item lg={6} xs={12} className={classes.gridItem}> 
                    <TextValidator
                  label="Account Number"
                  onChange={ e => setState({...state, accountNumber:e.target.value } ) }
                  name="accountNumber"
                  value={ state.accountNumber }
                  validators={[
                    'required',
                    'minStringLength:3',
                    'maxStringLength:17'
                  ]}
                  errorMessages={[
                    'this field is required',
                    'min 3 characters',
                    'max 17 characters'
                  ]}
                  margin="normal"
                  fullWidth
                /> 
                    </Grid>
               
              
               
                
                </Grid>

                
                
          </ValidatorForm>
          <p>
              <FormControlLabel
                    control={<Checkbox checked={saveCardDetails} onChange={ e => setSaveCardDetails(!saveCardDetails) } name="saveDetails" />}
                    label="Save Payment Details (We Will Never Charge Your Account Without Your Permission)"
                  />
            </p>
          <p>
            {
                  loading ?
                  <Button variant="contained" fullWidth color="secondary" disabled> <CircularProgress color="primary" size={ 24 } /></Button> :
                  <Button variant="contained" fullWidth color="secondary" onClick={handleClick}>Submit</Button>
                }
            </p>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(SimpleModal))