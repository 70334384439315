import React from 'react'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Will be used to migrate equipment/crews/employees between Entities
// props.entity will be the entity
const AssignedEntityComponent = (props) => {
  // console.log('Assigned Entity Component', props)
  const [state, setState] = React.useState({
    entities: [{ name: 'None'}],
    activeEntity: null,
    entity: {}
  })
  let variant = 'outlined'
  if (props.removeOutline) variant = 'standard'
  let ourEntity = null

  // React.useEffect(() => {
  //   if (props.entity && props.entity) {
  //   // console.log('ENtity update:', props.entity)
  //     setState({ ...state, activeEntity: props.entity, entities: props.user.entities })
  //   }
  // }, [])

  // React.useEffect(() => {
  //   if (props.entity) {
  //   // console.log('Set this entity...', props.entity)
  //   }
  // }, [props.entity])

  React.useEffect(() => {
  // console.log('35 USE EFFECT', props)
    if (props.user && props.user.entities && props.user.entities.length) {
    // console.log('37 USE EFFECT 35')
      // console.log('ENtities; ', props.user.entities)
      // console.log('Entity:', props.entity)
      if (props.entity && props.entity._id) {
      // console.log('Set this entity...', props.entity)
        let theEntity = props.entity
        if (theEntity && theEntity._id) theEntity = theEntity._id
        let entitiyIndex = props.user.entities.findIndex(item => (item._id === theEntity))
        if (entitiyIndex > -1) {
        // console.log('This is the entity', props.user.entities[entitiyIndex])
          ourEntity = props.user.entities[entitiyIndex]
          setState({...state, entities: props.user.entities, activeEntity: ourEntity})
        } else {
        // console.log('Cant find any entity to match that....')
        }
      } else if (props.entity) {
      // console.log('Set this entity...51', props.entity)
        let theEntity = props.entity
        let ourEntity = null
        // if (theEntity && theEntity._id) theEntity = theEntity._id
        let entitiyIndex = props.user.entities.findIndex(item => (item._id === theEntity))
        if (entitiyIndex > -1) {
          // console.log('This is teh entity', props.user.entities[entitiyIndex])
          ourEntity = props.user.entities[entitiyIndex]
          
        }
        setState({...state, entities: props.user.entities, activeEntity: ourEntity})
      } else {
      // console.log('SEt it as something else??? 61')
        setState({...state, entities: props.user.entities })
      }
    } else {
    // console.log('56 USE EFFECT')
    }

  }, [props])

  const updateEntitiesToView = (e, entity) => {
    // console.log('Update entitiy', entity)
    // console.log('Current props', props)
    // console.log('Current state', state)
    let availableEntities = []
    // availableEntities.push({
    //   internalName: 'All Assigned',
    //   name: 'All Assigned'
    // })
    if (entity && entity._id) {
      setState({...state, activeEntity: entity})
      props.updateParent(entity)
    } else {
      availableEntities = availableEntities.concat(props.user.entities)
      let activeEnt = {
        internalName: 'None',
        name: 'None Assigned'
      }
    // console.log('Set entities 79', availableEntities)
      setState({...state, entities: availableEntities, activeEntity: null})
      props.updateParent(null)
    }
  }


  return (
    <div>
    {/* {`${user.name} has ${user.entities.length} entities assigned.`}
    {JSON.stringify(user.entities)} */} 
    <Autocomplete
      id={`entityList`}
      options={state.entities}
      getOptionLabel = { (option) => {
        // console.log('Get Option Label', option)
        return option.name
      }}
      // style={{ width: 200 }}
      onChange={updateEntitiesToView}
      // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
      value = {state.activeEntity}
      disabled={props.disabled}
      // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
      renderInput={(params) => <TextField {...params} label={props.title} helperText={props.helperText || ''} variant={ variant } />}
      
    />
  </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(AssignedEntityComponent))