import React from 'react'
import { Chip } from '@material-ui/core';
import moment from 'moment'

const LastUpdateByDetails = ({ estimate }) => {
  // console.log('Last Update Details!!!!')
    const [timeElapsed, setTimeElapsed] = React.useState(moment(estimate.updated_at).fromNow())
    React.useEffect(() => {
      const timer = setInterval(() => {
        // console.log('Update our time....')
        setTimeElapsed(moment(estimate.updated_at).fromNow());
      }, 1000);
    
      return () => {
        clearInterval(timer);
      };
    }, []);
    let label = `Estimate Updated By ${estimate.updated_by.name} ${timeElapsed}`
    return (
        <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label={label} />   
    )
}

export default LastUpdateByDetails