import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, IconButton, Badge, Tooltip }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddLineItemModal from './AddLotInventoryItemModal'
import LotInventoryList from './LotInventoryList'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import HealingIcon from '@material-ui/icons/Healing';
import TransferLineItemsModal from './TransferLotInventoryModal'

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  paper: {
    width: '100%',
    height: '100%',
    minHeight: '80vh',
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  }
}))

const yardOptions = [
  'New River',
  'Cave Creek'
]

const Dashboard = (props) => {
  console.log('Dashboar dProps', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [takeoffs, setTakeoffs] = React.useState([])
  const [inventoryItems, setInventoryItems] = React.useState([])
  const [showModifyInventoryModal, setShowModifyInventoryModal] = React.useState(false)
  const [showTransferModal, setShowTransferModal] = React.useState(false)

  React.useEffect(() => {
    let isMounted = true
    const fetchTakeoffs = async () => {
      setLoading(true)
      const resp = await axios.get(`${props.url}/api/company/listTakeOffs`)
      setTakeoffs(resp.data)
      setLoading(false)
    }
    const fetchInventory = async () => {
      setLoading(true)
      const resp = await axios.get(`${props.url}/api/inventory/getCurrentLotInventory`)
      setInventoryItems(resp.data)
      setLoading(false)
    }
    
    fetchInventory()
    fetchTakeoffs()
    return () => {
      isMounted = false
    }
  }, [])

  const handleOpenModifyInventoryModal = () => {
    setShowModifyInventoryModal(true)
  }

  const handleCloseAddItemModal = () => {
    setShowModifyInventoryModal(false)
  }

  const handleSaveItems = async (obj) => {
    console.log('save these', obj)
    setShowModifyInventoryModal(false)
    // Save this to the backend
    setLoading(true)
    const resp = await axios.post(`${props.url}/api/inventory/adjustInventory?type=add`, obj)
    console.log('Response', resp.data)
    if (resp.data && resp.data.inventoryItems) {
      let newList = [
        ...inventoryItems,
        resp.data.inventoryItems
      ]
      console.log('New List: 92', newList)
      setInventoryItems(newList)
    }

    setLoading(false)
  }

  const handleTransferInventory = () => {
    setShowTransferModal(true)
  }

  const handleCloseTransferModal = () => {
    setShowTransferModal(false)
  }

  const handleSaveTransfer = (obj) => {
    console.log('Save this trasnfer', obj)
  }

  return (
    <div className={classes.root}>
     <AddLineItemModal url={props.url} open={showModifyInventoryModal} handleClose={handleCloseAddItemModal} takeoffs={takeoffs} handleSave={handleSaveItems} yardOptions={yardOptions} />
     <TransferLineItemsModal url={props.url} open={showTransferModal} handleClose={handleCloseTransferModal} takeoffs={takeoffs} handleSave={handleSaveTransfer} yardOptions={yardOptions} />
      <Paper className={classes.paper}>
        <h1>Inventory Dashboard</h1>
        <div>
          <Tooltip title="Add Inventory">
            <IconButton onClick={handleOpenModifyInventoryModal} >
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Transfer Inventory">
            <IconButton onClick={handleTransferInventory} >
              <LocalShippingIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Damaged/Dying Inventory">
            <IconButton onClick={handleTransferInventory} >
              <HealingIcon />
            </IconButton>
          </Tooltip>

          
         
        </div>
        Items: { inventoryItems.length}
        {
          loading ? <CircularProgress size={54} /> : null
        }
        <LotInventoryList list={inventoryItems} />
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    entities: state.entities,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(Dashboard)