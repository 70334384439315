import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { FileUploader } from 'react-drag-drop-files';


const ProjectUploadComponent = (props) => {
  const [filesToAdd, setNewFiles] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0,
    projects: [],
    filesToUpload: []
  })
  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "XLSX", "CSV", "TXT", "JPEG"];
  const [file, setFile] = React.useState([])
  let projectId = ''
  let clientId = ''
  if (props.project && props.project._id) projectId = props.project._id
  if (props.project && props.project.client) clientId = props.project.client
  const uploadFilesNow = (fileList) => {
    // console.log('Time to upload this shit!!', fileList)
    let formData = new FormData()
    fileList.forEach(file => {
      // console.log('Append to formdata:', file)
      file.scanType = 'projectUpload'
      formData.append('theFileList', file)
    })
    formData.append('project', projectId)
    formData.append('client', clientId)
  // console.log('Form Data: ', formData)
    let url = `${props.url}/api/projects/uploadProjectFilesMultiple?type=project`
    axios({
      method: 'POST',
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: progressEvent => {
        // console.log('Upload Progress', progressEvent)
      }
    }).then(resp => {
    // console.log('REsponse from axios::', resp)
      let allGood = true
      if (resp && resp.data && resp.data.duplicateFiles && resp.data.duplicateFiles.length) {
        allGood = false
      }
      if (resp && resp.data && resp.data.errorFiles && resp.data.errorFiles.length) {
        allGood = false
      }
      props.onUploadComplete(resp.data)
    })
  }

  const handleChange = (fileList) => {
    // console.log('FILES TO UPLOAD: ', fileList)
    let files = Array.from(fileList)
    // console.log('files', files)
    // fileList.map(item => (files.push(item)))
    // console.log('files: ', files)
    let newFiles = file.concat(files)
    setFile(newFiles)
    setState({...state, filesToUpload: newFiles})
  // console.log('New Files to upload:', newFiles)
    // UPload the files now
    uploadFilesNow(files)
  };

  const handleDragChange= (e, d) => {
    // console.log('Drag change', e)
    // console.log(d)
  }

  const handleSelect = (e, d) => {
    // console.log('Select: ', e)
    // console.log(d)
  }


  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      multiple
      fileOrFiles='Array'
      onSelect={handleSelect}
      onDraggingStateChange={handleDragChange}
      >
        <div style={{ marginBottom: 15, textAlign: 'center', border: '3px dashed #ccc', borderRadius: '10px', padding: '15px', cursor: 'pointer' }}>
          Click Here to Upload or Drag and Drop Files Here
        </div>
    </FileUploader>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ProjectUploadComponent)
