import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SupplierComponent from './SupplierComponent'

const irrigationSizes = [
  {
    name: '1/4"',
    alternate: '.25"'
  },
  {
    name: '1/2"',
    alternate: '.5"'
  },
  {
    name: '3/4"',
    alternate: '.75"'
  },
  {
    name: '1"',
    alternate: '1"'
  },
  {
    name: '1 1/4"',
    alternate: '1.25"'
  },
  {
    name: '1 1/2"',
    alternate: '1.5"'
  },
  {
    name: '1 3/4"',
    alternate: '1.75"'
  },
  {
    name: '2"',
    alternate: '2"'
  },
  {
    name: '2 1/2"',
    alternate: '2.5"'
  }
]

const filter = createFilterOptions();

const irrigationCategories = [
  {
    name: 'PVC Parts'
  },
  {
    name: 'Poly Parts'
  },
  {
    name: 'Copper'
  },
  {
    name: 'Pipe'
  },
  {
    name: 'Wire'
  },
  {
    name: 'Valves'
  },
  {
    name: 'Timers'
  },
  {
    name: 'Valve Box'
  },
  {
    name: 'Filters'
  },
  {
    name: 'Drain Parts'
  },
  {
    name: 'Sprinklers'
  }
]

const pvcPartsSubCategoryList = [
  'Adapters', 'Bushings', 'Caps', 'Couplings', 'Crosses', 'Elbows', 'Reducers', 'Tees'
]

const polyPartsSubCategoryList = [
  'Adapters', 'Barbed Valves', 'Compression Couplings', 'Compression Elbows', 'Compression End Plugs', 'Compression Tee', 'Connector (Barbed)', 'Cut Riser', 'Cutoff Nipple', 'Drip Adapters', 'Drip Coupling', 'Drip Emitter', 'Drip Tee', 'Elbow', 'Goof Plugs', 'Multiport Outlets', 'Shrubblers', 'Waterproof Connectors']


const SupplyInventoryInputItem = (props) => {

  const defaultState = {
    department: props.department || 'Misc. Supplies',
    name: '',
    description: '',
    notes: '',
    sku: '',
    mfg: '',
    upc: '',
    partNumber: '',
    category: ''
  }

  const [state, setState] = React.useState(defaultState)
  const [value, setValue] = React.useState(null)
  const [dialogValue, setDialogValue] = React.useState(null)
  const [open, toggleOpen] = React.useState(false);
  const [item, setItem] = React.useState({
    name: ''
  })
  const [category, setCategory] = React.useState(null)
  const [categoryList, setCategoryList] = React.useState([])
  const [subCategoryList, setSubcategoryList] = React.useState([])
  const [sizeList, setSizeList] = React.useState([])

  React.useEffect(() => {
    if (props.department === 'Irrigation') {
      setCategory('Irrigation')
      setCategoryList(irrigationCategories)
      setSizeList(irrigationSizes)
    }
    // setSubcategoryList()
  }, [props.department])

  const handleCategoryChange = (e) => {
    setState({...state, category: e.target.value})
    const cat = e.target.value
    if (cat === 'PVC Parts') {
      setSubcategoryList(pvcPartsSubCategoryList)
    }
    if (cat === 'Poly Parts') {
      setSubcategoryList(polyPartsSubCategoryList)
    }
  }

  return (
    <React.Fragment>
      <Grid container style={{width: '100%'}} spacing={2}>
        <Grid item lg={6} sm={12}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              fullWidth
              label="Common Name"
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              fullWidth
              label="Alternative Name"
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl variant="outlined" style={{width: '100%'}} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state.category}
              onChange={handleCategoryChange}
              label="Category"
            >
              {
                categoryList.map(unit => {
                  return (
                  <MenuItem key={unit.name} value={unit.name} >{unit.name}</MenuItem>
                )})
              }
            </Select>
        </FormControl>
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl variant="outlined" style={{width: '100%'}} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Sub-Category</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state.subCategory}
              onChange={e=>setState({...state, subCategory: e.target.value})}
              label="Unit"
            >
              {
                subCategoryList.map(unit => {
                  return (
                  <MenuItem key={unit} value={unit} >{unit}</MenuItem>
                )})
              }
            </Select>
        </FormControl>
        </Grid>
        <Grid item lg={6} sm={12}>
        <Autocomplete
        freeSolo
        id={`description-input`}
        // value={state.description}
        options={sizeList}
        value={value}
        // getOptionLabel={(option) => option.name}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return 'Save ' + option.inputValue + ' as a New Item';
          }
          // Regular option
          return option.name + ' - ' + option.alternate;
        }}
        // style={{ width: 200 }}
        // onChange={handleDescriptionChange}
        onChange={(event, newValue) => {
          // console.log('Change in value:', newValue)
          event.preventDefault()
          if (newValue && newValue._id) {
            // console.log('AN Active object was selected')
            setItem(newValue)
            setState({...state, ...newValue})
          } else {
            toggleOpen(true);
            setItem({
              title: newValue,
              name: newValue
            })
          }
          if (typeof newValue === 'string') {
            setValue({
              title: newValue,
              name: newValue
            });
            // setItem({
            //   title: newValue,
            //   name: newValue
            // })
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              title: newValue.inputValue,
              name: newValue.inputValue
            });
            setItem({
              title: newValue.inputValue,
              name: newValue.inputValue,
              description: newValue.inputValue
            })
            // toggleOpen(true);
            setDialogValue({
              title: newValue.inputValue,
              year: '',
            });
          } else {
            // console.log('NEWV AL: ', newValue)
            setValue(newValue);
            if (newValue && newValue.name) {
              // setItem(newValue)
            }
          
          }
        }}
        filterOptions={(options, params) => {
          // console.log('Filtered Options', options)
          // console.log('Params', params)
          // console.log('Input value: ', params.inputValue)
          const filtered = filter(options, params);
  
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
              name: 'Test This'
            });
          }
  
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
        // value={state.activeLineItem}
        fullWidth
        // InputProps={{ style: { fontSize: 20, color: '#fff', backgroundColor: '#333' } }}
        PaperComponent={({ children }) => (
          <Paper style={{ padding: 5, fontSize: 20, color: '#bbb', backgroundColor: '#fff' }}>{children}</Paper>
        )}
        // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
        renderInput={(params) => <TextField {...params}  fullWidth label="Size" variant="outlined" />}
      />
        </Grid>
        <Grid item lg={6} sm={12}>
        <TextField
              variant="outlined"
              fullWidth
              label="Notes"
            />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default SupplyInventoryInputItem