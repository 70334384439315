import React from 'react'
import styles from './profileModify.module.scss'
import { connect } from 'react-redux'
import { setUser } from '../../actions/connectionActions'
import {
  Paper,
  Typography,
  Button,
  Grid,
  MenuItem,
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel
} from '@material-ui/core'
import { ValidatorForm, TextValidator, SelectValidator } from 'react-material-ui-form-validator'
import axios from 'axios'
import InputMask from "react-input-mask";
import TextField from '@material-ui/core/TextField';
import EmailSignatureBuilder from './SignatureComponent'

const initialNotificationPreferences = [
  {
    type: 'Payment',
    details: 'Receive a Notification When a Job Assigned To, or a Job that You Follow Receives a Payment',
    options: {
      sms: false,
      email: false,
      task: false
    }
  }
]

const defaultState = {
  id: '',
  username: '',
  name: '',
  email: '',
  telephone: '',
  loading: false,
  notificationPreferences: initialNotificationPreferences
}

const Profile = (props) => {
  const [state, setState] = React.useState(defaultState)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (props.user && props.user._id) {
      setState({...state, ...props.user })
    }
  }, [props])

  const saveProfile = async () => {
    setLoading(true)
    const response = await axios.post(`${props.url}/api/user/profileupdate`, state)
    setLoading(false)
    if (response.data.success) {
    // console.log(response.data)
      props.setUser(response.data.user)
      props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Profile saved!'
      })
      props.history.push('/profile', response.data.user )
    } else {
      props.notification({
        type: 'danger',
        title: 'Save failed!',
        message: 'Try again later!'
      })
    }
  }

  const handleSaveSignature = async (newSignature) => {
    console.log('Save this:', newSignature)
    setLoading(true)
    const response = await axios.post(`${props.url}/api/user/saveUserSignature`, newSignature)
    setLoading(false)
    props.notification({
      type: 'success',
      title: 'Success!',
      message: 'Email Signature Saved!'
    })
    console.log('Signature saved', response.data)
    let newUser = { ...props.user, emailSignature: response.data.user.emailSignature }
    console.log('Set this new user', newUser)
    props.setUser(newUser)
  }

  const handleChange = (event) => {
  // console.log("change")
    const name = event.target.name
    const value = event.target.value
  // console.log('Changing %s to %S', name, value)
    setState({
      ...state,
      [name]: value
    })
  }

  const handleNotficationUpdate = (updatedItem, index) => {
    console.log('Update our prefernce', updatedItem)
    let newNotificationPreferences = [...state.notificationPreferences ]
    if (index > -1) {
      newNotificationPreferences[index] = updatedItem
      let newState = {
        notificationPreferences: [
          ...newNotificationPreferences
        ]
      }
      setState({...state, ...newState})
    }
  }

  const formRef = React.useRef(null)
  return (
      <div className={ styles.profileModify }>
        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
          <Paper>
            <Typography className={ styles.title } component="h1" variant="h5">
              Modify profile
            </Typography>
            <ValidatorForm
              ref={ formRef }
              onSubmit={ saveProfile }
              // onError={ errors => console.log(errors) }
              className={ styles.content }
            >
              <TextValidator
                label="Username"
                onChange={ handleChange }
                name="username"
                value={ state.username || '' }
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
              />
              <TextValidator
                label="Name"
                onChange={ handleChange }
                name="name"
                value={ state.name || '' }
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
              />
              <InputMask name="telephone" mask="(999) 999-9999" maskPlaceholder=" " onChange={e => handleChange(e)}  value={state.telephone }>
                  {
                    (inputProps) => {
                      return (
                      <TextField                        
                        margin="dense"
                        id="Telephone"
                        label={"Telephone Number #"}
                        type="telephone"
                        fullWidth
                        {...inputProps}
                    />
                      )
                    }
                  }
              </InputMask>
              <div style={{ margn: 10, paddingTop: 20, paddingBottom: 20 }}>
                  <div style={{ fontSize: 18, fontWeight: 500 }}>
                    Notification Options
                  </div>
                  <div>
                    {
                      state.notificationPreferences.map((item, index) => {
                        const handleUpdate = (e, type) => {
                          let newOptions = item.options
                          newOptions[type] = e.target.value
                          const newItem = {
                            ...item,
                            options: newOptions
                          }
                          handleNotficationUpdate(newItem, index)
                        }
                        return (
                          <div key={index} style={{ padding: 15 }}>
                            <div style={{ fontSize: '1.1em', fontWeight: 400, marginVertical: 10 }}>{item.type}</div>
                            <div style={{ fontSize: '0.9em', fontWeight: 200, marginVertical: 10 }}>{item.details}</div>
                            <div>
                              <FormGroup row>
                              <FormControlLabel
                                  control={<Checkbox checked={item.sms} onChange={ e => handleUpdate(e, 'sms') } name="SMS" />}
                                  label="SMS"
                                />
                                <FormControlLabel
                                  control={<Checkbox checked={item.email} onChange={ e => handleUpdate(e, 'email') } name="email" />}
                                  label="Email"
                                />
                                <FormControlLabel
                                  control={<Checkbox checked={item.task} onChange={ e => handleUpdate(e, 'task') } name="task" />}
                                  label="Task"
                                />
                              </FormGroup>
                            </div>
                          </div>
                          
                        )
                      })
                    }
                
                    
                  </div>
              </div>
              <div style={{ margn: 10, paddingTop: 20, paddingBottom: 20 }}>
                <div>
                  Email Signature
                </div>
                <div style={{ border: '2px solid #ccc', borderRadius: 8, padding: 10, marginVertical: 20 }}>
                  <EmailSignatureBuilder {...props} saveSignatue={handleSaveSignature} />
                </div>
              </div>

             
             
              {
                loading ?
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled
                >
                  <CircularProgress color="primary" size={ 24 } />
                </Button> :
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Save
                </Button>
              }
            </ValidatorForm>
          </Paper>
        </Grid>
      </div>
    )
}



const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => { dispatch(setUser(user)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
