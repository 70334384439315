import React from 'react'
import ServiceVisitList from './ServiceVisitList.js'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core';

const defaultState = {
  client: {
    _id: 'all'
  },
  loading: false
}
const ServiceVisits = (props) => {
// console.log('ServiceVisits Component', props)

  const [state, setState] = React.useState(defaultState)
  const [loading, setLoading] = React.useState(false)

  // async function fetchData(toReturn) {
  // // console.log('Fetch our clients')
  //   setLoading(true)
  //   axios.get(`${props.url}/api/customer/clients`).then((response, status, data) => {
  //     // console.log(response)
  //     // console.log(status)
  //     // console.log(data)
  //     if (response && response.data && response.data.length > 0) {
  //     // console.log('Got Clients!!')
  //       if (toReturn) {
  //         return response.data
  //       } else {
  //         setLoading(false)
  //         setClients(response.data)
  //         setState({...state, clients: response.data})
  //       }
  //     } else {
  //     // console.log('Fucked up', response)
  //       if (response === 'Unauthorized') props.history.push('/restricted')
  //     }
  //   }).catch((error, status) => {
  //     // console.log('Error', error)
  //     // console.log(status)
  //   })

  // }

  React.useEffect(() => {

    // fetchData()
  }, [props.location])


  return (
    <div >
     
      <Grid container>
        <Grid item lg={6} xs={12}>
          <h1>Service Visits</h1>
        </Grid>
      
      </Grid>
      { loading ? <CircularProgress color="primary" size={ 96 } /> :
        <ServiceVisitList client={state.client} />
      }
  </div>
  )
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(ServiceVisits))