import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TableSortLabel } from '@material-ui/core';
import { currencyFormat } from '../../services/common';
import { makeStyles } from '@material-ui/core/styles';


function SortableTable({ data }) {
  const classes = useStyles();
  const [orderDirection, setOrderDirection] = useState('asc');
  const [valueToOrderBy, setValueToOrderBy] = useState('MonthYear');

  const handleRequestSort = (property) => {
    const isAscending = (valueToOrderBy === property && orderDirection === 'asc');
    setValueToOrderBy(property);
    setOrderDirection(isAscending ? 'desc' : 'asc');
  };

  const sortData = (data) => {
    return data.sort((a, b) => {
      if (a[valueToOrderBy] < b[valueToOrderBy]) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (a[valueToOrderBy] > b[valueToOrderBy]) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const createSortHandler = (property) => () => {
    handleRequestSort(property);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {Object.keys(data[0]).map((key) => (
              <TableCell key={key}>
                <TableSortLabel
                  active={valueToOrderBy === key}
                  direction={valueToOrderBy === key ? orderDirection : 'asc'}
                  onClick={createSortHandler(key)}
                >
                  {key.replace('MonthYear', 'Month/Year')}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortData(data).map((row, index) => (
            <TableRow key={index}>
              {Object.keys(row).map((cellKey) => {
                const isNumeric = !isNaN(row[cellKey]) && cellKey !== 'MonthYear';
                const cellClass = isNumeric && row[cellKey] < 0 ? classes.negativeCell : classes.normalCell;
                return (
                  <TableCell key={cellKey} className={cellClass}>
                    {isNumeric ? currencyFormat(parseFloat(row[cellKey])) : row[cellKey]}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const useStyles = makeStyles(theme => ({
  normalCell: {

  }, 
  negativeCell: {
    backgroundColor: 'hsla(357, 86%, 69%, 1)',
    borderRadius: 5
  }
}))

export default SortableTable;
