import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, TextField, IconButton, Checkbox, FormControlLabel, Paper } from '@material-ui/core';
import axios from 'axios'
import CreateNewEstimateModal from './NewEstimateModal'
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from '@material-ui/icons/Search'
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import BallotIcon from '@material-ui/icons/Ballot';
import { Link } from 'react-router-dom'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    margin: 15
  },
  paper: {
    minHeight: '90vh',
    margin: 10,
    padding: 10,
    overflow: 'auto'
  },
  table: {
    width: '100%',
    padding: 0,
    margin: 0
  },
  tableHeadCell: {
    fontSize: 14,
    color: '#bbb',
    padding: 0
  },
  tableRow: {
    height: 33,
    padding: 0
  },
  tableCell: {
    height: 33,
    margin: 5,
    padding: 0,
    color: '#888'
  }
}))

const ProposalList = (props) => {
// console.log('Proposal List: ', props)
  const classes = useStyles();
  const [showModal, setShowModal] = React.useState(false)
  const [estimates, setEstimates] = React.useState([])
  const [activeEstimate, setActiveEstimate] = React.useState({})
  const [addys, setAddresses] = React.useState([])
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [state, setState] = React.useState({
    count: 0,
    showArchived: false,
    showConverted: true
  })
  const [jobSites, setJobSites] = React.useState([])
  const [allAddresses, setAllAddresses] = React.useState([])
  const [searchValue, setSearchValue] = React.useState('')
  const [filteredRows, setFilteredRows] = React.useState([])

  React.useEffect(() => {
    let isMounted = true

    if (props.estimates && props.estimates.length) {

      setEstimates(props.estimates)
      // setFilteredRows(props.estimates)
      filterOut(props.estimates, searchValue)
    } else {
      setEstimates([])
      setFilteredRows([])
    }


    return () => {
      isMounted = false
    }
  }, [props.estimates])

  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const filterOut = (r, searchText) => {
    let string = searchText
    let newArr = []
    r.filter(o => {
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log('String', str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    setFilteredRows(newArr)

  }

  const handleClick = (e, item) => {
    e.preventDefault()
    console.log('Open estimate: ', item)
    // setActiveEstimate(item)
    // setShowModal(true)
  }

  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setSearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(estimates)
    } else {
      filterOut(estimates, event.target.value)
    }
    setPage(0)
  }

   const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExportVisibleEstimates = () => {
  // console.log('Export Estimates')
    let obj = {
      estimates: ourFilteredProposals,
      client: props.client
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/company/exportToCSV?type=visible`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Export response: ', response.data)
      let newBlob = new Blob([response.data], {type: "text/csv"})
      // console.log(newBlob)
      const data = window.URL.createObjectURL(newBlob);
      // console.log(data)
      var link = document.createElement('a');
      link.href = data;
      let fileName = 'CSV_Export'.replace(/[^a-z0-9]/gi, '_').toLowerCase()
      link.download=`${fileName}.csv`;
      link.click();
    })
  }

  const toggleDisplayArchived = () => {
    setState({...state, showArchived: !state.showArchived })
  }

  const toggleDisplayConverted = () => {
    setState({...state, showConverted: !state.showConverted })
  }

  let ourFilteredProposalsByArchive = filteredRows.filter(row => {
    if (state.showArchived) {
      return row
    } else {
      return (row.status !== 'Archived')
    }
  })

  let ourFilteredProposals = [...ourFilteredProposalsByArchive].filter(row => {
    if (state.showConverted) {
      return row
    } else {
      if (row && row.status && row.status.includes('Converted')) {
        // Ignore it
      } else {
        return row
      }
      // return (row && row.status && !row.status.includes('Converted'))
    }
  })

  return (
    <div style={{width: '100%'}}>
       <Grid container spacing={2}>
       <Grid item lg={4} md={4} sm={6}>
          
          </Grid>
         <Grid item lg={8} md={8} sm={6}>
          <Tooltip title="Export to CSV" >
            <IconButton edge='end' onClick={handleExportVisibleEstimates} alt="Export Estimates" aria-label="Export Estimates">
              <ImportExportIcon />
            </IconButton>
          </Tooltip>
         </Grid>
         <Grid item xs={12} md={4}>
          {/* <Grid item lg={12} md={12} sm={12} > */}
            {/* <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'center' }}> */}
              <TextField
                name="search" 
                value={ searchValue } 
                onChange={ handleChange('search') }
                label="Search Proposals"
                type="search"
                variant="outlined"
                margin="normal"
                fullWidth
                style={{ flexGrow: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />

              </Grid>
          <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* Space between the search box and checkbox, potentially for future components */}
            <FormControlLabel
              control={<Checkbox name="showArchived" onChange={toggleDisplayArchived} checked={state.showArchived} />}
              label={state.showArchived ? `Click to Hide Archived Estimates` : `Click to Show Archived Estimates`}
            />
            <FormControlLabel
              control={<Checkbox name="showConverted" onChange={toggleDisplayConverted} checked={state.showConverted} />}
              label={state.showConverted ? `Click to Hide Converted Estimates` : `Click to Show Converted Estimates`}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {/* Checkbox positioned towards the end of the row */}
            
          </Grid>
          <Grid item lg={12} style={{fontSize: '1.3em', color: '#bbb', margin: 5}}>
            Matching Estimates: {ourFilteredProposals.length}
          </Grid>
          
        </Grid>

     { (ourFilteredProposals && ourFilteredProposals.length) ? (
        <Grid container>
          <Grid item lg={12} md={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeadCell}>
                    Proposal Name
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Proposal Number
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Status
                  </TableCell>
                  {/* <TableCell className={classes.tableHeadCell}>
                    Total Cost
                  </TableCell>
                   */}
                   <TableCell className={classes.tableHeadCell}>
                    Total Price
                  </TableCell>
                  {/* <TableCell className={classes.tableHeadCell}>
                    Created By
                  </TableCell> */}
                  <TableCell className={classes.tableHeadCell}>
                    Designer
                  </TableCell>
                  {/* <TableCell className={classes.tableHeadCell}>
                    Created At
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                { ourFilteredProposals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                  <TableRow className={classes.tableRow} key={index}>
                    <TableCell className={classes.tableCell}>
                      <Link to={{
                            pathname: `/clients/${item.client}/Estimates/${item._id}`,
                            // search: '?sort=name',
                            // hash: '#the-hash',
                            state: {estimate: item, stuff: 'My Estimate List'}
                          }}
                          style={{pointer: 'cursor'}}
                          target="_blank"
                          > 
                          {item.name}
                          
                        </Link>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        {
                          item.number
                            ?
                            <span >
                              #{item.number}
                            </span>
                            : null
                        }
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {item.status}
                    </TableCell>
                    {/* <TableCell className={classes.tableCell}>
                      {currencyFormat(item.totalCost)}
                    </TableCell>
                     */}
                     <TableCell className={classes.tableCell}>
                      {currencyFormat(item.totalPrice)}
                    </TableCell>
                    {/* <TableCell className={classes.tableCell}>
                      {((item.created_by && item.created_by.name) ? item.created_by.name : '')}
                    </TableCell> */}
                    <TableCell className={classes.tableCell}>
                      {((item.salesRep && item.salesRep.displayName) ? item.salesRep.displayName : '')}
                    </TableCell>
                    {/* <TableCell className={classes.tableCell}>
                      {moment(item.created_at).format('MM/DD/YYYY [at] hh:mma')}
                    </TableCell> */}
                  </TableRow>
                  ))
                }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100, 500]}
              component="div"
              count={ourFilteredProposals.length}
              rowsPerPage={rowsPerPage}
              page={page}
              // onChangePage={handleChangePage}
              // onPageChange={handleChangePage}
              // onChangeRowsPerPage={handleChangeRowsPerPage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />  
            </Grid>
            
     
        </Grid>
      ) : '' }
    </div>
  )
}

const NameArea = ({ item, handleClick}) => {
  return (
    <div style={{ margin: '0.4em' }}>
      <div>
        <a  onClick={e=>handleClick(e, item)} href="#" >{item.name ? item.name : 'Proposal'}</a>
        {
          item.signed_at ? <Chip style={{color: '#fff', background: '#39a33d', marginLeft: 10 }} size="small" label="eSigned" /> : null
        }
        {
          item.accepted ? <Chip style={{color: '#fff', background: '#82d8f5', marginLeft: 10 }} size="small" label="Accepted" /> : null
        }
        
        &nbsp;&nbsp;&nbsp;&nbsp;---<a href={`/proposals/${item._id}`} target="_blank" >NEW STYLE </a>
          <Tooltip title="View Paper Style Estimate" >
            <IconButton edge='end' alt="View Estimate Paper Style" aria-label="Paper Style Estimate">
              <a href={`/newestimateview/${item._id}`} target="_blank" ><BallotIcon fontSize="medium" /></a>
            </IconButton>
          </Tooltip>
      </div>
      <div style={{ fontSize: '0.75rem', color: '#aaa', margin: 4 }}>
        {`Created By ${((item.created_by && item.created_by.name) ? item.created_by.name : '')} on ${moment(item.created_at).format('MM/DD/YYYY [at] hh:mma')}`}
      </div>
    </div>
  )
}

const MyEstimateList = (props) => {
  const classes = useStyles()
  const [estimates, setEstimates] = React.useState([])

  React.useEffect(() => {
    let isMounted = true
    const fetchEstimates = async () => {
      console.log('Fetch Estimates!!')
      axios({
        method: 'get',
        url: `${props.url}/api/proposal/list?byUser=true`
      }).then(response => {
        console.log('Got our estimates; ', response.data)
        if (isMounted) {
          setEstimates(response.data)
          // setFilteredRows(response.data)
        }
      })
    }
    fetchEstimates()
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        
          <ProposalList estimates={estimates} url={props.url} />
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(MyEstimateList)
