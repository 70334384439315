import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux'
import axios from 'axios'
import { 
  AppBar, 
  Tabs, 
  Tab, 
  Typography, 
  Box, 
  Card, 
  CardContent, 
  Grid,
  makeStyles,
  Chip,
  Paper,
  IconButton
} from '@material-ui/core';
import { 
  Timeline as TimelineIcon, 
  DirectionsCar as CarIcon, 
  Build as BuildIcon,
  AccessTime as TimeIcon,
  Warning as WarningIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon
} from '@material-ui/icons';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import WeeklyRouteTable from './WeeklyRouteTable';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  card: {
    marginBottom: theme.spacing(2),
  },
  icon: {
    marginRight: theme.spacing(1),
    verticalAlign: 'middle',
  },
  cardContent: {
    '&:last-child': {
      paddingBottom: theme.spacing(2),
    },
  },
  overtimeChip: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginTop: theme.spacing(1),
  },
  weekNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  chartContainer: {
    height: 300,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function WeeklyRoutesComponent({ weeklyRoutes, state, fetchRoutesByDate }) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins}m`;
  };

  const calculateTotalTime = (route) => {
    return route.totalDriveTime + route.totalServiceTime;
  };

  const isOvertime = (totalTime) => {
    return totalTime > 480; // 8 hours = 480 minutes
  };

  const sortedRoutes = Object.entries(weeklyRoutes)
    .filter(([key]) => !isNaN(key))
    .sort(([a], [b]) => Number(a) - Number(b))
    .map(([_, value]) => ({
      ...value,
      routes: value.routes.sort((a, b) => calculateTotalTime(a) - calculateTotalTime(b))
    }));

  const handlePreviousWeek = () => {
    const prevWeekStart = new Date(state.weekStart);
    prevWeekStart.setDate(prevWeekStart.getDate() - 7);
    fetchRoutesByDate(prevWeekStart.toISOString().split('T')[0]);
  };

  const handleNextWeek = () => {
    const nextWeekStart = new Date(state.weekStart);
    nextWeekStart.setDate(nextWeekStart.getDate() + 7);
    fetchRoutesByDate(nextWeekStart.toISOString().split('T')[0]);
  };

  const prepareChartData = () => {
    return sortedRoutes.map(day => ({
      name: day.day,
      totalTime: day.routes.reduce((acc, route) => acc + calculateTotalTime(route), 0) / 60, // Convert to hours
      overtime: day.routes.reduce((acc, route) => {
        const totalTime = calculateTotalTime(route);
        return acc + (isOvertime(totalTime) ? (totalTime - 480) / 60 : 0); // Convert overtime to hours
      }, 0),
    }));
  };

  if (!sortedRoutes || sortedRoutes.length < 1) return null;

  return (
    <div className={classes.root}>
      <Paper className={classes.weekNavigation}>
        <IconButton onClick={handlePreviousWeek}>
          <ChevronLeftIcon />
        </IconButton>
        <Typography variant="h6">
          Week of {new Date(state.weekStart).toLocaleDateString()} - {new Date(state.weekEnd).toLocaleDateString()}
        </Typography>
        <IconButton onClick={handleNextWeek}>
          <ChevronRightIcon />
        </IconButton>
      </Paper>

      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="route days">
          {sortedRoutes.map((day, index) => (
            <Tab label={day.day} {...a11yProps(index)} key={day.day} />
          ))}
        </Tabs>
      </AppBar>

      <div className={classes.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={prepareChartData()}>
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="totalTime" name="Total Time (hours)" fill="#8884d8" />
            <Bar dataKey="overtime" name="Overtime (hours)" fill="#82ca9d" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {sortedRoutes.map((day, index) => (
        <TabPanel value={value} index={index} key={day.day}>
          <Typography variant="h6" gutterBottom>
            Routes for {day.day}
          </Typography>
          <Grid container spacing={3}>
            {day.routes.map((route) => {
              const totalTime = calculateTotalTime(route);
              const overtime = isOvertime(totalTime);
              return (
                <Grid item xs={12} sm={6} md={4} key={route.id}>
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <Typography variant="h6" gutterBottom>
                        {route.crewName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" gutterBottom>
                        <TimelineIcon className={classes.icon} />
                        Stops: {route.numberOfStops}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" gutterBottom>
                        <CarIcon className={classes.icon} />
                        Distance: {route.totalMilesDriven.toFixed(1)} miles
                      </Typography>
                      <Typography variant="body2" color="textSecondary" gutterBottom>
                        <CarIcon className={classes.icon} />
                        Drive Time: {formatTime(route.totalDriveTime)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" gutterBottom>
                        <BuildIcon className={classes.icon} />
                        Service Time: {formatTime(route.totalServiceTime)}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        <TimeIcon className={classes.icon} />
                        Total Time: {formatTime(totalTime)}
                      </Typography>
                      {overtime && (
                        <Chip
                          icon={<WarningIcon />}
                          label="Overtime Expected"
                          className={classes.overtimeChip}
                        />
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>
      ))}
    </div>
  );
}


export default WeeklyRoutesComponent