import React, { useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { fade, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Tooltip,
  Paper,
  DialogContentText,
 }  from '@material-ui/core';
 import InputAdornment from '@material-ui/core/InputAdornment';
 import AppsIcon from '@material-ui/icons/Apps';
import moment from 'moment'
import { currencyFormat, currencyFormatWithOutFlair } from '../../services/common'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  textField: {
    margin: '10px',
    padding: '10px'
  },
  mismatchedHours: {
    margin: '10px',
    padding: '10px',
    '& input': {
      color: 'red',
    },
  },
  paddedStuff: {
    padding: '15px'
  },
  dumpFeeContainer: {
    margin: '10px',
    padding: '20px',
    border: '2px solid #ddd',
    borderRadius: 8,
  },
  dumpFeeContainerTopRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dumpFeeInputArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  
  },
  dumpFeeArea: {
    display: 'flex',
    flexDirection: 'column-reverse',   
  },
  dumpTicketContainer: {
    margin: '5px',
    padding: '10px',
    border: '2px solid #ddd',
    borderRadius: 8,
  },
  dumpTicketHeader: {
    fontSize: '1.14em',
    color: '#333',
    fontWeight: 500,
   
  },
  helperTextMismatched: {
    color: 'red',
  },
  stopsContainer: {
    padding: 5,
    margin: 10,
    border: '2px solid #ddd',
    borderRadius: 5
  },
  stopItemContainer: {
    padding: 4,
    margin: 4,
    border: '1px solid #888',
    borderRadius: 15
  }
}))

const handleFocus = (event) => event.target.select();

const laborTypes = [
  'General Labor',
  'Spray Tech',
  'Irrigation Tech'
]

const logTypes = [
  'Commercial/County',
  'Northern Parkway',
  'ADOT'
]

const DumpFeesComponent = (props) => {
  const classes = useStyles();

  const handleAddDumpFee = () => {
    let currentFees = [...props.fees] 
    currentFees.push({
      fee: 0,
      tons: 0,
      date: new Date()
    })
    // setState({...state, fees: currentFees })
    props.updateParent(currentFees)
  }

  const handleChange = (e, index) => {
    let currentFees = [...props.fees]
    let tonsDumped = e.target.value ? e.target.value : 0
    currentFees[index]['tons'] = tonsDumped
    currentFees[index]['fee'] = currencyFormatWithOutFlair(77 * Number(tonsDumped))
    // setState({...state, fees: currentFees })

    props.updateParent(currentFees)
  }
  const handleChangeFee = (e, index) => {
    console.log('Change fee', e.target.value)
    let currentFees = [...props.fees] 
    currentFees[index]['fee'] = e.target.value
    // currentFees[index]
    // setState({...state, fees: currentFees })
    console.log('New fees', currentFees)
    props.updateParent(currentFees)
  }
  

  return (
    <div className={classes.dumpFeeContainer}>
      <div className={classes.dumpFeeContainerTopRow}>
      
      <Button variant="contained" color="secondary" size="small" onClick={handleAddDumpFee}>Add Dump Fee</Button>
      </div>
      <div className={classes.dumpFeeArea}>
      {
        props.fees.map((dumpFee, index) => {
          // console.log('Dump Fee', dumpFee)
          return (
            <div key={index} className={classes.dumpTicketContainer}>
               <div className={classes.dumpTicketHeader} >Dump Ticket #{Number(index) + 1}</div>
            <Grid container className={classes.dumpFeeInputArea} key={index}>
             
                 <Grid item lg={6} md={6} sm={12} xs={12} >
                    <TextField
                      key={index}
                      value={dumpFee.tons}
                      id={ `tons-${index}` }
                      fullWidth
                      onFocus={handleFocus}
                      onChange={e => handleChange(e, index)}
                      className={classes.textField}
                      variant="outlined"
                      // onChange={ e => setJobName(e.target.value) }
                      // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={`Tons Dumped (Dump #${index + 1})`}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                    <TextField
                      key={index}
                      value={dumpFee.fee}
                      id={ `fee-${index}` }
                      fullWidth
                      onFocus={handleFocus}
                      onChange={e => handleChangeFee(e, index)}
                      className={classes.textField}
                      variant="outlined"
                      // onChange={ e => setJobName(e.target.value) }
                      // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={`Dump Fee #${index + 1}`}
                    />
               </Grid>
              </Grid>
          </div>
          )
        })
      }
      </div>
    </div>
  )
}

export default DumpFeesComponent