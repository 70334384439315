import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import PlusIcon from '@material-ui/icons/Add';
import { fade, makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import VendorList from './VendorList'

// const useStyles = makeStyles(theme => ({
const styles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      margin: 10,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  topButtons: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center',
      margin: 5,
    },
    [theme.breakpoints.down('md')]: {
      margin: 5,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  }
  })
);


const TopRow = (props) => {
  const classes = styles()
  return (
    <Grid container className={classes.root}>
      <Grid item lg={3} sm={6} xs={12} md={3}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.topButtons}
          onClick={props.handleOpenNewProjectModal}
          startIcon={<FiberNewIcon  color="primary" />}
        >New Project</Button>
      </Grid>
      <Grid item lg={3} sm={6} xs={12} md={3}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.topButtons}
          style={{ color: '#bf5c1f', borderColor: '#bf5c1f'}}
          startIcon={<PlusIcon  color="#bf5c1f" />}
        >PO Request</Button>
      </Grid>
      <Grid item lg={3} sm={6} xs={12} md={3}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.topButtons}
          style={{ color: '#1fbfac', borderColor: '#1fbfac'}}
          startIcon={<AddIcon  color="#1fbfac" />}
          onClick={e => props.setVendorInvoiceInputModalOpen(true)}
        >Vendor Invoice</Button>
      </Grid>
      <Grid item lg={3} sm={6} xs={12} md={3}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          className={classes.topButtons}
          style={{ color: '#bf3253', borderColor: '#bf3253'}}
          startIcon={<CreateIcon  color="#bf3253" />}
        >Track Labor</Button>
      </Grid>
    </Grid>
  )
}

// const ProjectList = (props) => {
//   if (props.projects && props.projects.length) {
//     return (
//       JSON.stringify(props.projects)
//     )
//   } else {
//     return null
//   }
// }

const VendorDashboard = (props) => {
  const [newProjectModalOpen, setNewProjectModalOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [clients, setClientList] = React.useState([])
  const [salesList, setSalesList] = React.useState([])
  const [projects, setProjects] = React.useState([])
  const [vendors, setVendors] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0,
    projects: []
  })
  const [vendorInvoiceInputModalOpen, setVendorInvoiceInputModalOpen] = React.useState(false)

  React.useEffect(() => {
    let isMounted = true
    const fetchClients = async () => {
      setLoading(true)
      // ?byEntity=610a290f45ef7427f94b2e78
      let url = `${props.url}/api/customer/clients`
      axios.get(url).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setClientList(response.data)
        }
      })
    }
    // const fetchCrews = async () => {
    //   setLoading(true)
    //   axios.get(`${props.url}/api/crews/listCrews`).then((response, status, data) => {
    //     setLoading(false)
    //     if (response && response.data) {
    //       if (isMounted) setCrews(response.data.crews)
    //     }
    //   })
    // }

    const fetchVendors = async () => {
    // console.log('Fetch Vendors')
      setLoading(true)
      axios.get(`${props.url}/api/company/getVendors`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setVendors(response.data)
        }
      })
    }

    const fetchSalesReps = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/employees/getSalesReps`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setSalesList(response.data)
        }
      })
    }

    const fetchProjects = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/projects/listProjects`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) {
            setProjects(response.data)
            setState({...state, projects: response.data})
          }
        }
      })
    }

    // fetchClients()
    // fetchSalesReps()
    // fetchProjects()
    fetchVendors()
    // fetchCrews()
    // fetchSupplies()
    return () => {
      isMounted = false
    }
  }, [])

  const handleUpdateVisibleEntity = (activeEntity) => {
  // console.log('Active Entity: ', activeEntity)
  }

  const handleOpenNewProjectModal = () => {
    setNewProjectModalOpen(true)
  }

  const handleAddProject = (d) => {
  // console.log('New Project to save!', d)
    if (d && d._id) {
      let projs = state.projects
      projs.unshift(d)
      setProjects(projs)
      // console.log(projs)
      setNewProjectModalOpen(false)
      let counter = state.counter - 0 + 1
      setState({...state, projects: projs, counter: counter })
    }

  }

  const handleVendorDeletion = (vendor) => {
    let vendorList = vendors
    let index = vendorList.findIndex(item => (item._id === vendor._id)) 
    if (index > -1) {
      vendorList.splice(index, 1)
      setVendors(vendorList)
      setState({...state, counter: state.counter++ })
    }
  }
  return (
    <Grid container style={{margin: 5}} spacing={2}>

        <Grid item lg={6} xs={12} sm={12} md={6}>
          <h1>Vendors</h1>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper style={{ height: '80vh', marginRight: 20, padding: 10, overflowY: 'scroll' }}>
            <VendorList vendors={vendors} deleteVendor={handleVendorDeletion} />
          </Paper>
        </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(VendorDashboard))