import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import styled from 'styled-components'
import GenericUploadComponent from './GenericUploadComponent'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '10px',
    // margin: '5px'
  },
  scanComponentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2rem',
    alignSelf: 'stretch'
  },
  scanComponentItem: {
    display: 'flex',
    flexGrow: 1,
    margin: '1em',
    border: '1px solid rgba(22,2,1,0.2)',
    borderRadius: 7,
    minHeight: '20em',
    padding: '0.4em'
  }
}));

const Container = styled(Grid)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Item = styled(Grid)`
  flex-basis: calc(33.33% - 10px);
  margin-bottom: 20px;
  overflow: hidden;
  @media (max-width: 960px) {
    flex-basis: 100%
    margin-bottom: 10;
    max-width: calc(100% - 20px);
  }
`;

const HMIScansComponent = (props) => {
  // console.log('EditMtcAgreement Mtc Agreement: ', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [activeUpload, setActiveUpload] = React.useState({})

  const handleOpenSprayLogModal = (upload) => {
  // console.log('Open Spray Log Modal', upload)
    setOpen(true)
  }
  const handleOpenAdotModal = (upload) => {
  // console.log('Open ADOT modal', upload)
    setOpen(true)
  }
  const handleOpenUploadModal = (upload) => {
  // console.log('Open standard upload', upload)
    setOpen(true)
  }
  
  return (
    <Container>
      <Item item><GenericUploadComponent title="Spray Logs" email="hmispraylogs@mail.com" type="sprayLog" openModal={handleOpenSprayLogModal} /></Item>
      <Item item><GenericUploadComponent title="ADOT Timesheets" email="hmiadotoncall@mail.com"  type="adotOnCall" openModal={handleOpenAdotModal} /></Item>
      <Item item><GenericUploadComponent title="Uploads/Scans" email="hmiscans@mail.com"  type="hmiScan" openModal={handleOpenUploadModal} /></Item>
    </Container>
  )
  }

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(HMIScansComponent))