import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
// import ServiceDetailList from './ServiceDetailList'
// import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import DialogContentText from '@material-ui/core/DialogContentText';
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
// import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { currencyFormat } from '../../services/common'
import Checkbox from '@material-ui/core/Checkbox';
import DeleteComponent from '../Common/DeleteComponent'
import ServiceLineItems from './ServicePlanLineItems'
import SendToCustomerComponent from '../Common/SendToCustomer/SendToCustomerComponent'


// console.log('Test Currency format', currencyFormat(4))
const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px',
    margin: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  serviceConfigContainer: {
    margin: 10,
    padding: 5
  }
}));

const SiteRow = styled(Grid)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

const SiteName = styled(Grid)`
  display: flex;
  align-self: flex-start;
  flex-grow: 1;
`;

const SiteDetails = styled(Grid)`
  display: flex;
  flex-grow: 1;
  align-self: flex-start;
  justify-content: space-evenly;
`;
const SiteDetail = styled.div`
  display: flex;
`;

const SiteAction = styled(Grid)`
  display: flex;
  align-self: flex-end;
`;

const daysOfTheWeek = [
  {
    name: 'No Preference',
    num: 0
  },
  {
    name: 'Sunday',
    num: 1
  },
  {
    name: 'Monday',
    num: 2
  },
  {
    name: 'Tuesday',
    num: 3
  },
  {
    name: 'Wednesday',
    num: 4
  },
  {
    name: 'Thursday',
    num: 5
  },
  {
    name: 'Friday',
    num: 6
  },
  {
    name: 'Saturday',
    num: 7
  }
]
const servicePlans = [
  {
    name: 'Basic Service Plan',
    num: 1,
    value: 'BSP'
  },
  {
    name: 'Advantage Service Plan',
    num: 2,
    value: 'ASP'
  },
  {
    name: 'Benhart Hourly Plan',
    num: 3,
    value: 'BHP'
  },
  {
    name: 'Flat Rate Plan (Benhart)',
    num: 4,
    value: 'FRP'
  },
  // {
  //   name: 'Super Service Plan',
  //   num: 3,
  //   value: 'SSP'
  // }
]

const frequencyOptions = [
  {
    name: 'Weekly',
    num: 1,
    value: 'weekly'
  },
  {
    name: 'Bi-Weekly',
    num: 2,
    value: 'biweekly'
  },
  {
    name: 'Monthly',
    num: 3,
    value: 'monthly'
  },
  {
    name: 'Quarterly',
    num: 4,
    value: 'quarterly'
  }
]


const advantageServiceList = [
  'Testing of water; keep proper balance by testing and adding appropriate chemicals',
  'Clean all baskets including skimmers, pumps, and any deck canisters for cleaning systems ',
  'Back wash weekly of sand filters; once a month clean and inspect cartridge filters, and/or D.E. filters ',
  'Inspect all equipment and valves for proper operation and lube valves, as needed ',
  'Inspect salt cell for corrosion, if applicable  [Note: cleaning is an extra cost] ',
  'Sweep all steps, swim ups, and benches ',
  'Sweep tile for debris ',
  'Skim pool surface',
  'Vacuum Pool',
  'Pools with hot tubs and/or water feature will be serviced weekly for an additional $25.00 each service',
  'Hot tub or spool only will be quoted at time of inspection'
]

const basicServiceList = [
  'Testing of water; keep proper balance by testing and adding appropriate chemicals',
  'Clean all baskets including skimmers, pumps, and any deck canisters for cleaning systems ',
  'Back wash weekly of sand filters; once a month clean and inspect cartridge filters, and/or D.E. filters ',
  'Inspect all equipment and valves for proper operation and lube valves, as needed ',
  'Inspect salt cell for corrosion, if applicable  [Note: cleaning is an extra cost] ',
  'Sweep all steps, swim ups, and benches ',
  'Sweep tile for debris ',
  'Skim pool surface '
]

const defaultStateValues = {
  notes: '',
  preferredDay: 'None',
  basicRate: 185,
  advantageRate: 250,
  servicePlan: 'Basic',
  jobSite: {},
  basicPlanList: basicServiceList,
  advantagePlanList: advantageServiceList,
  numberOfSpas: 0,
  numberOfSpools: 0,
  numberOfWaterFeatures: 0,
  addlFee: 0,
  isCurrent: false
}

const NewMtcAgreement = (props) => {
// console.log('New Pool Mtc Agreement: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [jobName, setJobName] = React.useState('')
  const [state, setState] = React.useState(defaultStateValues)
  const [contact, setContact] = React.useState({})
  const [pdf, setPDF] = React.useState(null)
  const [showPDF, setShowPDF] = React.useState(false)
  const [showSendToCustomerModal, setShowSendToCustomerModal] = React.useState(false)

  React.useEffect(() => {
    if (props.contract && props.contract._id) {
    console.log('We have a contract!!', props.contract)
      let newState = {...props.contract}
    // console.log('New State:', newState)
      if (props.contacts && props.contacts.length) {
        let index = props.contacts.findIndex(item => (item._id === props.contract.contact))
        if (index > -1) {
          setContact(props.contacts[index])
        }
      }

      if (props.jobSites && props.jobSites.length) {
        let index = props.jobSites.findIndex(item => (item._id === props.contract.jobSite))
        if (index > -1) {
          newState.jobSite = props.jobSites[index]
        }
      }
    console.log('Set Our State for the contract...', newState)
      setState({...state, ...newState})
    }
  }, [props])
  // React.useEffect(() => {
  // // console.log('Use Effect POol MTC Modal',  props)
  //   // let jobName = 'Job - '
  //   let newState = { crewList: props.crewList, jobSites: props.jobSites, jobSiteAddress: props.jobSites[0], jobSite: props.jobSites[0] }
  //   // if (props.client.lastName) jobName = props.client.lastName + '- '
  //   // if (props.client.companyName) jobName = props.client.companyName + '- '
  //   if (props.contract) {
  //   // console.log('We have an active contract!', props.contract)
  //   // console.log('New State so far:', newState)
  //     let updatedState = Object.assign(newState, props.contract)
  //   // console.log('Updated State:', updatedState)
  //     newState = updatedState
  //     if (props.contract && props.contract.expirationDate) {
  //     // console.log('Deal w/ expiration Date:', props.contract.expirationDate)
  //       // const tomorrow = new Date(props.contract.expirationDate.getTime() - 0 + (24 * 60 * 60 * 1000)).toISOString().slice(0, -8)
  //       // // moment(state.expDate).format('YYYY-MM-DD').toLocale 
  //       let expDate = moment(props.contract.expirationDate).format('MM/DD/YYYY') // moment(props.contract.expirationDate).format('YYYY-MM-DD').toLocale()
  //     // console.log('Expiration DATE::', expDate)
  //       newState.expirationDate = props.contract.expirationDate
  //       if (props.contract && props.contract.startDate) {
  //         newState.startDate = props.contract.startDate
  //       }
  //     }
  //     if (props.contract && props.contract.jobSites && props.contract.jobSites.length) {
  //       newState.includedSites = props.contract.jobSites
  //       let sitesLeftOver = props.jobSites.filter((site) => {
  //         let siteIndex = props.contract.jobSites.findIndex(item => (item._id === site._id))
  //         if (siteIndex > -1) {
  //           return false
  //         } else {
  //           return true
  //         }
  //       })
  //       newState.jobSites =  sitesLeftOver
  //     } else {
  //       newState.jobSites = props.jobSites
  //     }
  //   }
  //   // setOpen(props.open)
  // // console.log('Set the state 727:', newState)
  //   setState({...state, ...newState})
  // }, [props])



const handleClose = () => {
// console.log('Handle Close')
  props.updateParent('Close')
  setPDF(null)
  setShowPDF(false)
  setContact(null)
  setState(defaultStateValues)
}

const handleFocus = e => e.target.select()

const handleSave = () => {
  setLoading(true)
// console.log('handle save', state)
  // let details = { ...state }
  // if (details.includedSites && details.includedSites.length) {
  //   let siteIds = details.includedSites.map(site => site._id)
  // // console.log('Site IDs', siteIds)
  // }
  // delete details.jobSites
  // delete details.crewList
  // details.client = null
  // if (props.client && props.client_id) details.client = props.client_id

  // // console.log(props)
  let obj = {
    client: props.client,
    details: state,
    contact: contact
  }
// console.log('Obj', obj)
  axios({
    method: 'post',
    url:  `${props.url}/api/pools/createNewMtcAgreement`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    console.log('Axios response to create new Note: ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response.data && response.data._id) {
      // setState(defaultStateValues)
      // props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'New Maintenance Agreement was created and saved!',
        message: 'Well Done!!'
      })
      props.saveAgreement(response.data)
      // generatePDF(response.data)
      // setShowPDF(true)
      // setPDF(response.data)
      // setOpen(false)
      // console.log('Contact:', contact)
      // console.log(state)
    
    } else {
      // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up creating this agreement...not sure why!'
      })
    }
    
  }).catch(err => {
    props.notification({
      type: 'warning',
      title: 'Uh Oh!',
      message: JSON.stringify(err)
    })
  })
}

const generatePDF = (agreement) => {
  axios({
    method: 'get',
    url:  `${props.url}/api/pools/generatePDF?id=${agreement._id}`
  }).then(response => {
  // console.log('Got the pDF!!', response.data)
    setShowPDF(true)
    setPDF(response.data)
  })
}

const handleUpdate = () => {
// console.log('UPdate this fucker!!')
  let obj = {
    client: props.client,
    details: state,
    contact: contact,
    contract: props.contract
  }
// console.log('Obj', obj)
  axios({
    method: 'post',
    url:  `${props.url}/api/pools/updateMtcAgreement`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to create new Note: ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response.data && response.data._id) {
      // setState(defaultStateValues)
      // props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'New Maintenance Agreement was created and saved!',
        message: 'Well Done!!'
      })
      props.saveAgreement(response.data)
      // if (response.data && response.data.isCurrent) generatePDF(response.data)
    }
  })
  // let details = { ...state }
  // if (details.includedSites && details.includedSites.length) {
  //   let siteIds = details.includedSites.map(site => site._id)
  // // console.log('Site IDs', siteIds)
  // }
  // delete details.jobSites
  // delete details.crewList
  // details.client = null
  // // details._id = props.contract._id
  // if (props.client && props.client_id) details.client = props.client_id

  // // console.log(props)
  // let obj = {
  //   client: props.client,
  //   details: details,
  //   agreement: props.contract
  // }
  // console.log('Obj', obj)
  // axios({
  //   method: 'post',
  //   url:  `${props.url}/api/hmi/updateMtcAgreement`,
  //   data: obj,
  //   // validateStatus: (status) => {
  //   //   // console.log('Validate status: ', status)
  //   //   if (status && status === 500) {

  //   //   }
  //   // },
  // }).then(response => {
  //   // console.log('Axios response to create new Note: ', response)
  //   setLoading(false)
  //   // setState({})
  //   // setJobNumber(response.data)
  //   if (response.data._id) {
  //     setState(defaultStateValues)
  //     setOpen(false)
  //     props.updateParent('Updated', response.data)
  //     // setState({})
  //     props.notification({
  //       type: 'success',
  //       title: 'Maintenance Agreement was updated and saved!',
  //       message: 'Well Done!!'
  //     })
  //     // setOpen(false)
  //   } else {
  //     // console.log('No fucking workorder')
  //     props.notification({
  //       type: 'warning',
  //       title: 'Uh Oh!',
  //       message: 'Something messed up updating this agreement...not sure why!'
  //     })
  //   }
    
  // }).catch(err => {
  //   // console.log('Error saving workorder: ', err)
  // })
}
const formatOption = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.address) {
    return `${opt.name} - ${opt.address} ${opt.city}`
  } else {
    return ''
  }
}

const handleChange = (e) => {
console.log('Change: %s - %s ', e.target.id, e.target.value)
  setState({...state, [e.target.id]: e.target.value })
}

const handleChangePreferredDay = (e) => {
  setState({...state, preferredDay: e.target.value })
}

const isNotIncludedInNewSites = (value) => {
// console.log('Is included in new sites??', value)
}

const handleDelete = (itemToRemove) => {
// console.log('Remove this:', itemToRemove)
}

const handleContactChange = (e, newContact) => {
// console.log('Change in contacat:', newContact)
  // e.preventDefault()
  if (newContact && newContact._id) {
  // console.log('Cohntact')
    setContact(newContact)
    // setState({...state, contact: newContact })
  }
}

const updateJobSite = (e, newSite) => {
  setState({...state, jobSite: newSite })
}

const handleClosePDF = () => {
  setShowPDF(false)
  setLoading(false)
// console.log('Contact??', contact)
// console.log(state)
}

const handleShowPDF = () => {
  if (pdf) {
    setShowPDF(true)
  } else {
  // console.log('Get that PDF!')
    generatePDF(props.contract)
  }

}

const handleServicePlanChange = (data) => {
// console.log('Save Service Plan Change:', data)
  const { type, itemUpdated, plan } = data
// console.log('Update %s service plan: ', plan, type)
  let existingList = (plan === 'Basic') ? state.basicPlanList: state.advantagePlanList
// console.log(itemUpdated)
  let newState = { ...state }
// console.log('List as it is', existingList)
  if (type === 'Delete') {
    let index = existingList.findIndex(item => (item.trim() === itemUpdated.trim()))
  // console.log('Index:', index)
    if (index > -1) {
      existingList.splice(index, 1)
    }
  }
  if (type === 'Save') {
  // console.log('Save...', itemUpdated)
    existingList.push(itemUpdated)
  }
// console.log('Existing List after change:', existingList)
  if (plan === 'Basic') {
    newState.basicPlanList= existingList
  } else {
    newState.advantagePlanList = existingList
  }
// console.log('Set New State:', newState)
// console.log(state)
  setState({...state, ...newState})
}

const handleChangePlan = (e) => {
  const plan = e.target.value
  let newState = {...state}
  newState.servicePlan = plan

  setState({...state, ...newState})
}

const sendPDFToCustomer = () => {
  setShowSendToCustomerModal(true)
}

const handleCloseSendToCustoemrModal = () => {
  setShowSendToCustomerModal(false)
}

return (
  <div className={classes.root}>
    <SendToCustomerComponent
      open={showSendToCustomerModal}
      data={props.contract}
      handleCloseModal={handleCloseSendToCustoemrModal}
      type='Pool Maintenance Bid'
      eSign={true}
      client={props.client}
      contact={contact}
      pdf={pdf}
      entity={props.entity}
    />
    <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth='lg' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          { (props.contract && props.contract._id) ? 'Edit' : 'Create New' } Pool Maintenance Agreement</DialogTitle>
        <DialogContent >
          <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
            <CloseIcon />
          </IconButton>
          {/* <Tooltip title="Delete MTC Contract" >
            <DeleteComponent
              type="Jobs"
              item={state}
              title="Delete Pool MTC Agreement?"
              verificationText={`Are you sure you wish to Delete this agreement?`}
              confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
              confirmationButton="DELETE"
              denyText="NO"
              ifApproved={handleDelete}
              deleteFunction={'poolMtcAgreement'}
            />
          </Tooltip> */}

            <DialogContentText>
              Please fill out the information below.
            </DialogContentText>
            {
              ((props.contract || pdf) && !showPDF) ? (
                <DialogContentText>
                  If you'd like, you can view this on a PDF and even email to the customer for acceptance.
                </DialogContentText>
              ) : null
            }
            
            {
              ((props.contract || pdf) && !showPDF) ? <OpenPDF openPDF={handleShowPDF} /> : null
            }
            {
              (pdf && showPDF) ? (
                <React.Fragment>
                  <ClosePDF handleClose={ handleClosePDF } sendPDF={sendPDFToCustomer} />
                  <object style={{padding: '15px', minHeight: '960px'}} aria-label="PDF" data={ pdf ? pdf : null } width="100%" height="960px" type="application/pdf" />
                
                </React.Fragment>
                

              ) : (

      
            <Grid container>
                <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem} >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state.isCurrent}
                        onChange={() => setState({...state, isCurrent: !state.isCurrent })}
                        name="Active"
                        color="primary"
                      />
                    }
                    label={state.isCurrent ? 'Active (Click To De-Activate)' : 'Inactive (Click to Activate)'}
                  />
                  
                </Grid>
              <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem} >
                <Autocomplete
                  id="contact"
                  options={props.contacts || []}
                  getOptionLabel={(option) => {
                    return `${option.firstName || ''} ${option.lastName || ''}`
                  }}
                  value={contact}
                  selectOnFocus
                  className={classes.paddedStuff}
                  onChange={handleContactChange}
                  // onInputChange={(e, inputValue) => {
                  //   // console.log('input changed', inputValue)
                  //   let newContact = {
                  //     firstName: inputValue
                  //   }
                  //   setState({...state, contact: newContact })
                  // }}
                  // freeSolo
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Contact" placeholder="Who?" helperText="Who Is This Agreement Addressed To?" />
                  )}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                <Autocomplete
                    id="jobsite-picker"
                    fullWidth
                    options={props.jobSites}
                    value={state.jobSite}
                    onChange={updateJobSite}
                    className={classes.paddedStuff}
                    getOptionLabel={formatJobSiteOptions}
                    renderInput={(params) => <TextField {...params} label="Job Site" variant="outlined" />}
                  />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                <TextField
                  id="dayOfTheWeek"
                  label="Preferred Day"
                  helperText="What Day Should We Schedule Our Technician?"
                  fullWidth
                  variant="outlined"
                  select
                  value={state.preferredDay}
                  // className={classes.paddedStuff}
                  onChange={ handleChangePreferredDay }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  // onBlur={updateParent}
                >
                  <MenuItem key="0" value="None">No Preference</MenuItem>
                  <MenuItem key="1" value="Monday">Monday</MenuItem>
                    <MenuItem key="2" value="Tuesday">Tuesday</MenuItem>
                    <MenuItem key="3" value="Wednesday">Wednesday</MenuItem>
                    <MenuItem key="4" value="Thursday">Thursday</MenuItem>
                    <MenuItem key="5" value="Friday">Friday</MenuItem>
                </TextField>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                <TextField
                  id="servicePlan"
                  label="Service Plan"
                  helperText="Which Plan Should We Be Providing?"
                  fullWidth
                  variant="outlined"
                  select
                  value={state.servicePlan}
                  // className={classes.paddedStuff}
                  onChange={ handleChangePlan }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  // onBlur={updateParent}
                >
                  <MenuItem key="0" value="Basic">Basic Plan</MenuItem>
                  <MenuItem key="1" value="Advantage">Advantage Plan</MenuItem>
                  
                </TextField>
              </Grid>
              <Grid item lg={3} md={3} sm={4} xs={12} className={classes.gridItem}>
                <TextField
                    id="numberOfSpas"
                    label="Number of Spas"
                    helperText="How Many Spas Are We Servicing"
                    fullWidth
                    variant="outlined"
                    type="number"
                    onFocus={handleFocus}
                    value={state.numberOfSpas}
                    onChange={ handleChange }
                  />
              </Grid>
              <Grid item lg={3} md={3} sm={4} xs={12} className={classes.gridItem}>
                <TextField
                    id="numberOfSpools"
                    label="Number of Spools"
                    helperText="How Many Spools Are We Servicing"
                    fullWidth
                    variant="outlined"
                    type="number"
                    onFocus={handleFocus}
                    value={state.numberOfSpools}
                    onChange={ handleChange }
                  />
              </Grid>
              <Grid item lg={3} md={3} sm={4} xs={12} className={classes.gridItem}>
                <TextField
                    id="numberOfWaterFeatures"
                    label="Number of Water Features"
                    helperText="How Many Water Features Are We Servicing"
                    fullWidth
                    onFocus={handleFocus}
                    variant="outlined"
                    type="number"
                    value={state.numberOfWaterFeatures}
                    onChange={ handleChange }
                  />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItem}>
                <TextField
                    id="addlFee"
                    label="Fee For Add'l Spas/Spools/Water Features"
                    helperText="What Is The Additional Monthly Cost?"
                    fullWidth
                    onFocus={handleFocus}
                    variant="outlined"
                    type="number"
                    value={state.addlFee}
                    onChange={ handleChange }
                  />
                </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                <span style={{ fontSize: '1.28em', color: '#999'}}>Service Configurations</span>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                  <ServicePlanConfiguration list={state.basicPlanList} type={'Basic'} updateServicePlanDetails={handleServicePlanChange} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                <TextField
                    id="basicRate"
                    label="Basic Plan Rate"
                    helperText="What Is The Basic Plant Monthly Cost?"
                    fullWidth
                    onFocus={handleFocus}
                    variant="outlined"
                    type="number"
                    value={state.basicRate}
                    onChange={ handleChange }
                  />
                </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                  <ServicePlanConfiguration list={state.advantagePlanList} type={'Advantage'} updateServicePlanDetails={handleServicePlanChange} />
              </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                <TextField
                    id="advantageRate"
                    label="Advantage Plan Rate"
                    helperText="What Is The Advantage Plant Monthly Cost?"
                    fullWidth
                    onFocus={handleFocus}
                    variant="outlined"
                    type="number"
                    value={state.advantageRate}
                    onChange={ handleChange }
                  />
                </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
                <TextField
                  id="notes"
                  label="Notes"
                  multiline
                  minRows={4}
                  variant="outlined"
                  className={classes.paddedStuff}
                  fullWidth
                  // className={classes.textSaveLineBreaks}
                  value={state.notes}
                  onChange={e => setState({...state, notes: e.target.value })}
                  // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                  // defaultValue="Default Value"
                />
              </Grid>
            </Grid>
                    )
                  }
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          {
            (props.contract && props.contract._id) ? (
              <Button disabled={loading || (!contact || !contact._id) || !state.basicRate || !state.advantageRate || (!state.jobSite || !state.jobSite._id)} color="primary" onClick={handleUpdate}>
              Update
              </Button>
            ) : (
              <Button color="primary" disabled={loading || (!contact || !contact._id) || !state.basicRate || !state.advantageRate || (!state.jobSite || !state.jobSite._id)} onClick={handleSave}>
                Save
              </Button>
            )
          }
          
        </DialogActions>
      </Dialog>
  </div>
  )
}

const ServicePlanConfiguration = (props) => {
  const classes = useStyles()

  const handleSaveLineItems = (updatedList) => {
  // console.log('Items to save!', updatedList)
    let obj = {
      type: 'Save',
      itemUpdated: updatedList,
      plan: props.type
    }
    props.updateServicePlanDetails(obj)
  }

  const handleDeleteItem = (itemToDelete) => {
  // console.log('Delete this:', itemToDelete)
    let obj = {
      type: 'Delete',
      itemUpdated: itemToDelete,
      plan: props.type
    }
    props.updateServicePlanDetails(obj)
  }

  return (
    <div className={classes.serviceConfigContainer}>
      <ServiceLineItems {...props} saveLineItem={handleSaveLineItems} deleteLineItem={handleDeleteItem} />
    </div>
  )
}

const ClosePDF = (props) => {
  return (
    <div style={{ width: '100%', display: 'flex', alignSelf: 'stretch', justifyContent: 'space-between', flexDirection: 'row'}}>
    <Button
      variant="contained"
      color="secondary"
      size="small"
      onClick={props.handleClose}
      >Close PDF</Button>
      <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={props.sendPDF}
      >Send To Customer</Button>
    </div>
  )
}

const OpenPDF = (props) => {
  return (
   
      <Button
        variant="contained"
        color="secondary"
        size="small"
        onClick={props.openPDF}
        >Show PDF</Button>



  )
}

const formatJobSiteOptions = (option) => {
  if (option && option.name) {
    return `${option.name ? option.name : 'Jobsite: '} - ${option.address} ${option.buildingNumber ? `(${option.buildingNumber})` : '' }`
  } else {
    return ''
  }
}
  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      contacts: state.contacts,
      client: state.client
    }
  }
  
  export default React.memo(connect(mapStateToProps)(NewMtcAgreement))