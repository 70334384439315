import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { setCrew } from '../../actions/connectionActions'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import moment from 'moment'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress }  from '@material-ui/core';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import EventIcon from '@material-ui/icons/Event';

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
`;

const Title = styled(Box)`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

const RightSideButtons = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

const SideButtons = styled(Button)`
  margin: 5px;
  padding: 5px;
`;

const WorkorderDetails = styled(Box)`
  display: flex;
  width: 60%;
  overflow: wrap;
  height: 40vh;
`;

const BigText = styled(Box)`
  font-size: 1.5em;
  width: 100%;
  display: flex;
  flex-grow: 1;
  overflow: wrap;
  color: #999;
  flex-direction: column;
  padding: 10px;
`;

const Label = styled.span`
  font-size: 1.5em;
  color: #999;
`;

const Details = styled.span`
  font-size: 1.4em;
  color: #777;
`;

const NotesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const OfficeNotes = styled(Box)`
  display: flex;
  flex-grow: 1;
  padding: 5px;
  margin: 5px;
`;

const TechNotes = styled(Box)`
  display: flex;
  flex-grow: 1;
  padding: 5px;
  margin: 5px;
`;

const ManagerNotes = styled(Box)`
  display: flex;

  flex-grow: 1;
  padding: 5px;
  margin: 5px;
`;

const ViewWorkorder = (props) => {
// console.log('ViewWorkorder Component', props)
  const [loading, setLoading] = React.useState(false)

  // React.useEffect(() => {
  //   if (props.crew && props.crew._id) fetchData(props.crew._id)
  // }, [ ])

  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleUpdateWorkorder = (wo) => {
  // console.log('Edit WO: ', wo)
  }

  const handleComplete = () => {
    console.log('Complete this one out...', props)
    let msg = `Are you sure you wish to Complete this Work Order? It will no longer be viewable to the Crew in the App.`
    if (window.confirm(msg)) {
      const obj = {
        _id: props?.workorder?._id
      }
      
      axios({
        method: 'post',
        url:  `${props.url}/api/jobs/completeWithoutInvoicing`,
        data: obj,
        // validateStatus: (status) => {
        //   // console.log('Validate status: ', status)
        //   if (status && status === 500) {
    
        //   }
        // },
      }).then(response => {
        // if (response.status ===)
        // console.log(response.data)
        props.notification( {
            title: 'Completion Response',
            message: 'Workorder is Now Complete!',
            type: 'success'
        } )
        props.updateParent('complete', props?.workorder?._id)
      }).catch(err => {
        props.notification({
          type: 'Warning',
          title: 'Completion Error',
          message: 'Error...' + JSON.stringify(err)
        })
      })
    }
  }

  return (
    <div>
          <Dialog
          // fullScreen={false}
          // maxWidth={lg}
          // width='md'
          fullWidth={true}
          maxWidth = {'lg'}
          // maxwidth='md'
          // style={{width: '90hw'}}
          open={props.open}
          // scroll='paper'
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          >
                {/* <Dialog
        maxWidth='lg'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      > */}
          <DialogTitle id="responsive-dialog-title">
          <Container>
            <Title>
              {props.workorder.jobName} - {moment(props.workorder.scheduledDate).format('ddd MM/DD/YYYY')}
              <RightSideButtons>
                   <SideButtons
                    variant="contained"
                    size="small"
                    color="secondary"
                    startIcon={<EventIcon />}
                  >Re-Schedule</SideButtons>

                  <SideButtons
                    variant="contained"
                    size="small"
                    color="primary"
                    startIcon={<AssignmentIndIcon />}
                  >Re-Assign</SideButtons>

              </RightSideButtons>
            </Title>
           
          </Container>

           </DialogTitle>
          <DialogContent>
                <Container>
                  <WorkorderDetails>
                    <BigText>
                      
                      <div>
                      <Label>Job Name: </Label><Details>{props.workorder.jobName}</Details>
                      </div>
                      <div>
                      <Label>Job Site Address:</Label><Details> {props.workorder.jobSiteAddress}</Details>
                      </div>
                      <div>
                      <Label> Service:</Label><Details> {props.workorder.serviceDescription} </Details>
                      </div>
                      <div>
                      <Label>Service Code:</Label><Details> {props.workorder.serviceName}</Details>
                      </div>
                      <div>
                      <Label>Assigned Crew: </Label><Details>{props.workorder.crewName}</Details>
                      </div>
                      <div>
                        { props?.workorder?.serviceCode === '1200' && <CompleteButton handleComplete={handleComplete} /> }
                      </div>
                    </BigText>
                    
                  </WorkorderDetails>
                  <NotesContainer>
                    <OfficeNotes>
                      
                      <TextField
                           multiline
                           rows={4}
                           style={{width: '100%'}}
                           variant="outlined"
                          label="Office Notes"
                          value={props.workorder.serviceNotes}
                          disabled
                          />
                    </OfficeNotes>
                    <TechNotes>
                        <TextField
                           multiline
                           rows={4}
                           style={{width: '100%'}}
                           variant="outlined"
                          label="Field Notes"
                          disabled
                          />
                      </TechNotes>
                    <ManagerNotes >
                        <TextField
                           multiline
                           rows={4}
                           style={{width: '100%'}}
                           variant="outlined"
                          label="Manager Notes"
                          />
                    </ManagerNotes>
                  </NotesContainer>
                </Container>
            </DialogContent>
            <DialogActions>
              <Button color="secondary" onClick={handleCloseModal}>
                Close
              </Button>
              <Button color="primary">
                Save
              </Button>
            </DialogActions>
            </Dialog>
    </div>
  )
}

const CompleteButton = (props) => {

  return (
    <Button
      variant="outlined"
      color="primary"
      onClick={props.handleComplete}
    >
      Complete
    </Button>
  )
}
const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ViewWorkorder)