import React from 'react'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box';
import { Button, CircularProgress }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import NewContactModal from './NewContactModal'
import EditContactModal from './EditContactModal'
import axios from 'axios'
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { fade, makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
    width: '95%'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PhoneNumberList = (props) => {
  return (
    <div>
      {props.numbers.map(number => {
        return (
          <div key={number.number}>
            {number.type ? `${number.type} - ` : null} { number.number ? number.number : null }
          </div>
        )
      })}
    </div>
  )
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'companyName', numeric: false, disablePadding: false, label: 'Company Name' },
  { id: 'phone', numeric: false, disablePadding: false, label: 'Telephone' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'notes', numeric: false, disablePadding: false, label: 'Notes' }
];

function EnhancedTableHead(props) {
  // console.log('Got our props for ', props)
  
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
  // console.log('Sort Property: ', property)
  // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
  // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const ContactList = (props) => {
// console.log('Project Contact List: ', props)
  const [newContactModalOpen, setNewContactModalOpen] =  React.useState(false)
  const [editContactModalOpen, setEditContactModalOpen] =  React.useState(false)
  const [contacts, setContacts] = React.useState([])
  const [activeContact, setActiveContact] = React.useState({})
  const [state, setState] = React.useState({
    contacts: []
  })
  const [loading, setLoading] = React.useState(false)
  const [rows, setRows] = React.useState([])
  const [filteredRows, setFilteredRows] = React.useState([])
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);

  React.useEffect(() => {
    if (props.project && props.project.contacts && props.project.contacts.length) {
    // console.log('Project contacts', props.project.contacts)
      setFilteredRows(props.project.contacts)
    } else {
      setFilteredRows([])
    }
  }, [props.project])
  //   // console.log('Get Contacts: ')
  //   async function getContacts() {
  //   // console.log('Get our CONTACTS')
  //     const response = await axios.get(`${props.url}/api/projects/getContacts?id=${props.project._id}&client=${props.project.client}`)
  //   // console.log('CONTATS', response)
  //     if (response && response.data && response.data.contacts) {
  //       setContacts(response.data.contacts)
  //       setRows(response.data.contacts)
  //       setFilteredRows(response.data.contacts)
  //       // setState({...state, contacts: response.data})
  //       // props.updateContacts(response.data)
  //     }
  //     if (response && response.data && response.data.contact) {
  //       setActiveContact(response.data.contact)
  //       // setState({...state, contacts: response.data})
  //       // props.updateContacts(response.data)
  //     }
  //   }
  //   getContacts()
  // }, [])
  // const contacts = [
  //   {
  //     name: 'Frank Abbruscatto',
  //     phoneNumbers: [
  //       {
  //         type: 'Mobile',
  //         number: '480 451-1212'
  //       },
  //       {
  //         type: 'Home',
  //         number: '480 555-1212'
  //       }
  //     ],
  //     emailAddresses: [
  //       {
  //         statement: true,
  //         invoice: true,
  //         address: 'frank@gmail.com'
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Ginger Abbruscatto',
  //     phoneNumbers: [
  //       {
  //         type: 'Mobile',
  //         number: '602 585-1111'
  //       }
  //     ],
  //     emailAddresses: [
  //       {
  //         statement: false,
  //         invoice: true,
  //         address: 'ginger@adsfasd.com'
  //       },
  //       {
  //         address: 'ginger-dfl@gmail.com',
  //         invoice: false,
  //         statement: true
  //       }
  //     ]
  //   },
  //   {
  //     name: 'Ginger Abbruscatto',
  //     phoneNumbers: [
  //       {
  //         type: 'Mobile',
  //         number: '602 585-1111'
  //       }
  //     ],
  //     emailAddresses: [
  //       {
  //         statement: false,
  //         invoice: true,
  //         address: 'ginger@adsfasd.com'
  //       },
  //       {
  //         address: 'ginger-dfl@gmail.com',
  //         invoice: false,
  //         statement: true
  //       }
  //     ]
  //   }
  // ]
  const handleEditContact = (contact, index) => {
    // console.log('Open Edit Contact Modal', contact)
    setEditContactModalOpen(true)
    setActiveContact(contact)
  }

  const handleAddContact = () => {
    // console.log('Add Contact')
    // Open New Contact Modal
    setNewContactModalOpen(true)
  }

  const saveContact = (project) => {
  // console.log('Save this contact!', project)
    let newContacts = project.contacts
    // newContacts.push(newContact)
    setState({...state, contacts: newContacts})
    setNewContactModalOpen(false)
    setFilteredRows(newContacts)
  }

  const updateContact = (contact) => {
    // console.log('Update this contact', contact)
    let newContacts = contacts
    let index = contacts.findIndex(item =>  (contact._id === item._id));
    // let ourContact = state.contacts[index]
    // console.log('Contact to update', ourContact)
    newContacts[index] = contact
    setState({...state, contacts: newContacts})
    setEditContactModalOpen(false)
  }

  const closeNewContactModal = () => {
    setNewContactModalOpen(false)
  }

  const closeEditContactModal = () => {
    setEditContactModalOpen(false)
  }
  const handleDeleteContact = (contact, index) => {
    // setLoading(true)
    // console.log('handle save new contact', state)
    if (window.confirm('Are you sure you wish to delete this contact? This is not reversible.')) {
      // console.log(props)
      let updatedContacts = contacts
      // console.log('Contacts', updatedContacts)
      updatedContacts.splice(index, 1)
      // console.log('Updated Contacts: ', updatedContacts)
      // setContacts(updatedContacts)
      setState({...state, contacts: updatedContacts})
      let obj = {
        contact: contact,
        user: props.user
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/project/deleteContact`,
        data: obj,
        // validateStatus: (status) => {
        //   // console.log('Validate status: ', status)
        //   if (status && status === 500) {
    
        //   }
        // },
      }).then(response => {
        // console.log('Axios response to delete Contact: ', response)
        // setLoading(false)
        props.notification({
          type: 'success',
          title: 'Contact Deleted!',
          message: `You'll never have to think about ${contact.firstName} again!`
        })
      }).catch(err => {
        // console.log('Err: ', err)
      })
    }
  }

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    // console.log('Lenght: ', newArr.length)
    // console.log(newArr)
    // storeSearchMatches(newArr)
    // setsearchMatches(newArr.length)
    // return newArr
    setFilteredRows(newArr)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div className={classes.tableWrapper}>
      <NewContactModal saveContact={saveContact} project={props.project} contacts={contacts} open={newContactModalOpen} closeModal={closeNewContactModal} />
      <EditContactModal updateContact={updateContact} project={props.project} contact={activeContact} open={editContactModalOpen} closeModal={closeEditContactModal} />
       <>
        <Box display="flex" flexDirection="row" justifyContent="space-around" alignContent="center">
          <Box flexGrow={3} alignSelf="flex-start" style={{fontSize: '20px'}}>
            Contacts &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <Tooltip title="Add Contact" >
              <Button  
                variant="contained"
                color="primary"
                size="small"
                alt="Add Contact"
                style={{fontSize: '10px'}}
                onClick={handleAddContact}
              >
              Add 
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            options={{
              rowStyle: {height: 55}
            }}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody >
              { stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log('Row map: ', row)
                  // console.log('Total rows; ', rows)
                  // const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    
                      <TableRow
                        hover
                        // onClick={event => handleClick(event, row._id)}
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        className={classes.rowPad}
                        // selected={isItemSelected}
                      >
                       <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.firstName} {row.lastName}
                        </TableCell>

                        <TableCell align="left">
                          
                          {  row.companyName ? row.companyName : ''}
                        </TableCell>
                        <TableCell align="left">
                          <PhoneNumberList numbers={row.phoneNumbers || []} />
                        </TableCell>
                        <TableCell align="left">
                          {row.email}
                        </TableCell>
                        <TableCell align="left">
                          {row.notes}
                        </TableCell>
                      </TableRow>
                    );
 
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          { loading ?<CircularProgress style={{color: '#fff'}} size={ 16 } /> : ''}
                    
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          // onPageChange={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
  )
}
  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(ContactList))