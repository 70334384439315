import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { currencyFormat } from '../../services/common'

const ChoosePlanModal = (props) => {
// console.log('ChoosePlanModal Modal: ', props)
  const [consentChecked, setConsentChecked] = React.useState(false)
  const [chosenPlan, setChosenPlan] = React.useState('Advantage')

  const handleClose = () => {
    props.handleClose()
  }

  const handleSubmit = () => {
    props.makeSelection(chosenPlan)
  }

  return (
    <Dialog
      open={props.open}
      maxWidth={'md'}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle style={{textAlign: 'center'}}>Select Plan</DialogTitle>
      <DialogContent style={{ overflowY: "scroll" }}>
        <DialogContentText>Please Select You Preferred Plan From The Options Below:</DialogContentText>
        <PlanOptions contract={props.contract} chosenPlan={chosenPlan} setChosenPlan={setChosenPlan} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit}
          color="primary"
          >Submit</Button>
          <Button
            color="secondary"
            onClick={handleClose}
          >Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}

const PlanOptions = ({ contract, chosenPlan, setChosenPlan }) => {
  return (
    <div>
      <TextField
        select
        type="text"
        value={chosenPlan}
        fullWidth
        onChange={ e => { setChosenPlan(e.target.value) }}
        >

        <MenuItem value={'Basic'}>Basic Plan ({currencyFormat(contract.basicRate)})</MenuItem>
        <MenuItem value={'Advantage'}>Advantage Service Plan ({currencyFormat(contract.advantageRate)})</MenuItem>
        </TextField>
    </div>
  )
}
export default ChoosePlanModal
