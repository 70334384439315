import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import axios from 'axios'
import { DialogTitle, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment'
import { socket } from '../../SocketIo/Socket'
import ViewUploadModal from './ViewUploadModal';

const Container = styled.div`
  background-color: 'rgba(4,4,4,0.5)';
  min-height: 20em;
  border: 2px solid #333;
  border-radius: 7px;
  height: 100%;
  overflow: hidden;
  max-height: 70vh;
`;

const Title = styled.div`
  color: #888;
  font-weight: 700;
  font-size: 1.3em;
  text-align: center;
`;

const SubTitle = styled.div`
  color: #333;
  font-weight: 400;
  font-size: 1em;
  text-align: center;
`;

const GenericUploadComponent = (props) => {
  const [uploads, setUploads] = React.useState([])
  const [open, setOpen] = React.useState(false)
  const [state, setState] = React.useState({
    type: props.type,
    uploads: [],
    activeUpload: {}
  })
  const shareNewUpload = (newUpload) => {
  // console.log('We have a new upload!!', newUpload)
  // console.log('Current uploads: ', uploads)
  // console.log(newUpload)
    let currentUploads = uploads
  // console.log('We have uploads', uploads)
    currentUploads.unshift(newUpload)
  // console.log('New:', currentUploads)
    setUploads(currentUploads)
    setState({...state, uplaods: currentUploads})
  }
  
  React.useEffect(() => {
    let isMounted = true
    socket.on(`${props.type}_uploads`, function (newUpload) {
    // console.log('WE have a message on this channel!!', props.type)
      if (isMounted) shareNewUpload(newUpload)
    })
    async function getUploads (type) {
      // console.log('Get %s type uploads!!!', type)
      const response = await axios.get(`${props.url}/api/hmi/getUploadsByType?type=${type}`)
      // console.log('LIst ', response.data)
      if (response && response.data && response.data.length && isMounted) setUploads(response.data)
    }

    getUploads(props.type)
    return () => {
      isMounted = false
      socket.off(`${props.type}_uploads`)
    }
  }, [])

  const openModal = (data) => {
  // console.log('Open the modal', data)
    setOpen(true)
    setState({...state, activeUpload: data})
  }
  
  const handleCloseModal = () => {
    setOpen(false)
  }

  return (
    <Container>
      <ViewUploadModal open={open} upload={state.activeUpload} closeModal={handleCloseModal} />
      <Title>{props.title} </Title>
      <SubTitle>{props.email}</SubTitle>
      <UplaodDetails list={uploads} openModal={openModal} />
    </Container>
  )
}

const DetailsContainer = styled.div`
  height: 100%;
  overflow-y: scroll;
  max-width: 100%;
`

const FakeLink = styled.span`
  cursor: 'pointer';
  textDecoration: 'underline';
  color: 'blue';
  font-size: '1.2em';
`

const UplaodDetails = (props) => {
  const list = props.list || []
  const openModal = (row) => {
  // console.log('open', row)
    props.openModal(row)
  }
  return (
    <DetailsContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Created</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            list.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell style={{ maxWidth: '50%' }} onClick={() => openModal(item)}>
                    <span 
                      style={{
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        color: 'blue',
                        fontSize: '1.2em' 
                      }}
                    >
                        {item.name}
                    </span>
                  </TableCell>
                  <TableCell style={{ maxWidth: '30%' }}>{moment(item.created_at).format('MM/DD/YY hh:mma')}</TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </DetailsContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(GenericUploadComponent)
