import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'

const VariableBilling = (props) => {
  // console.log('Variable Billing: ', props)
  const [state, setState] = React.useState({
    janPrice: 0,
    febPrice: 0,
    marchPrice:  0,
    aprilPrice: 0,
    mayPrice: 0,
    junePrice: 0,
    julyPrice: 0,
    augPrice:  0,
    septPrice:  0,
    octPrice:  0,
    novPrice: 0,
    decPrice: 0,
  })
  
  React.useEffect(() => {
   // console.log('Monthly Fee Changed!!!', props.monthlyFee)
  //// console.log('Variable Billing Use Effect', props)
  //// console.log(state)
   if (props.stuff.savedVariableBilling) {
     // console.log('Weve already saved oru billing so leave it alone!!!')
     setState({...state,
      janPrice: props.stuff.variablePricing.janPrice,
      febPrice: props.stuff.variablePricing.febPrice,
      marchPrice: props.stuff.variablePricing.marchPrice,
      aprilPrice: props.stuff.variablePricing.aprilPrice,
      mayPrice: props.stuff.variablePricing.mayPrice,
      junePrice: props.stuff.variablePricing.junePrice,
      julyPrice: props.stuff.variablePricing.julyPrice,
      augPrice: props.stuff.variablePricing.augPrice,
      septPrice: props.stuff.variablePricing.septPrice,
      octPrice: props.stuff.variablePricing.octPrice,
      novPrice: props.stuff.variablePricing.novPrice,
      decPrice: props.stuff.variablePricing.decPrice,
    })
   } else {
     if (props.contract && props.contract.billingByMonth) {
      let prices = props.contract.billingByMonth
      setState({...state,
       janPrice: prices[0]['amount'],
       febPrice:  prices[1]['amount'],
       marchPrice: prices[2]['amount'],
       aprilPrice: prices[3]['amount'],
       mayPrice: prices[4]['amount'],
       junePrice: prices[5]['amount'],
       julyPrice: prices[6]['amount'],
       augPrice: prices[7]['amount'],
       septPrice: prices[8]['amount'],
       octPrice: prices[9]['amount'],
       novPrice: prices[10]['amount'],
       decPrice: prices[11]['amount']
     }) 
    } else {
      if (props.monthlyPrice) {
        setState({...props,
          janPrice: props.monthlyPrice,
          febPrice:  props.monthlyPrice,
          marchPrice: props.monthlyPrice,
          aprilPrice: props.monthlyPrice,
          mayPrice: props.monthlyPrice,
          junePrice: props.monthlyPrice,
          julyPrice: props.monthlyPrice,
          augPrice: props.monthlyPrice,
          septPrice: props.monthlyPrice,
          octPrice: props.monthlyPrice,
          novPrice: props.monthlyPrice,
          decPrice: props.monthlyPrice
        }) 
      }
    }
  }
     // console.log('We have a contract - use those prices!!', props.contract)
  //   else {
  //   setState({...state,
  //     janPrice: props.monthlyFee,
  //     febPrice: props.monthlyFee,
  //     marchPrice: props.monthlyFee,
  //     aprilPrice: props.monthlyFee,
  //     mayPrice: props.monthlyFee,
  //     junePrice: props.monthlyFee,
  //     julyPrice: props.monthlyFee,
  //     augPrice: props.monthlyFee,
  //     septPrice: props.monthlyFee,
  //     octPrice: props.monthlyFee,
  //     novPrice: props.monthlyFee,
  //     decPrice: props.monthlyFee
  //   })
  //  }
  }, [props])

  const saveVariableBilling = () => {
  // console.log('Save our variable billing!!', state)
    props.saveVariableBilling(state)
  }
  return (

    <Grid container>
      <Grid item lg={4}>
        <TextField
          id="janPrice"
          label="January Price"
          fullWidth
          // className={classes.paddedStuff}
          // type="number"
          variant="standard"
          value= {state.janPrice}
          // onChange={ e => setJanPrice(e.target.value) }
          onBlur={ saveVariableBilling }
          onChange={ e => setState({...state, janPrice:e.target.value } ) }
          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
        />
      </Grid>
      <Grid item lg={4}>
        <TextField
          id="febPrice"
          label="February Price"
          fullWidth
          // className={classes.paddedStuff}
          // type="number"
          variant="standard"
          value= {state.febPrice}
          // onChange={ e => setFebPrice(e.target.value) }
          // value= {state.febPrice}
          onBlur={ saveVariableBilling }
          onChange={ e => setState({...state, febPrice:e.target.value } ) }
          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
        />
      </Grid>
      <Grid item lg={4}>
        <TextField
          id="marchPrice"
          label="March Price"
          fullWidth
          // className={classes.paddedStuff}
          // type="number"
          variant="standard"
          onBlur={ saveVariableBilling }
          value= {state.marchPrice}
          onChange={ e => setState({...state, marchPrice:e.target.value } ) }
          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
        />
      </Grid>
      <Grid item lg={4}>
        <TextField
          id="aprilPrice"
          label="April Price"
          fullWidth
          onBlur={ saveVariableBilling }
          // className={classes.paddedStuff}
          // type="number"
          variant="standard"
          value= {state.aprilPrice}
          onChange={ e => setState({...state, aprilPrice:e.target.value } ) }
          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
        />
      </Grid>
      <Grid item lg={4}>
        <TextField
          id="mayPrice"
          label="May Price"
          onBlur={ saveVariableBilling }
          fullWidth
          // className={classes.paddedStuff}
          // type="number"
          variant="standard"
          value= {state.mayPrice}
          onChange={ e => setState({...state, mayPrice:e.target.value } ) }
          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
        />
      </Grid>
      <Grid item lg={4}>
        <TextField
          id="junePrice"
          label="June Price"
          onBlur={ saveVariableBilling }
          fullWidth
          // className={classes.paddedStuff}
          // type="number"
          variant="standard"
          value= {state.junePrice}
          onChange={ e => setState({...state, junePrice:e.target.value } ) }
          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
        />
      </Grid>
      <Grid item lg={4}>
        <TextField
          id="julyPrice"
          label="July Price"
          onBlur={ saveVariableBilling }
          fullWidth
          // className={classes.paddedStuff}
          // type="number"
          variant="standard"
          value= {state.julyPrice}
          onChange={ e => setState({...state, julyPrice:e.target.value } ) }
          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
        />
      </Grid>
      <Grid item lg={4}>
        <TextField
          id="augPrice"
          label="August Price"
          onBlur={ saveVariableBilling }
          fullWidth
          // className={classes.paddedStuff}
          // type="number"
          variant="standard"
          value= {state.augPrice}
          onChange={ e => setState({...state, augPrice:e.target.value } ) }
          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
        />
      </Grid>
      <Grid item lg={4}>
        <TextField
          id="septPrice"
          label="September Price"
          onBlur={ saveVariableBilling }
          fullWidth
          // className={classes.paddedStuff}
          // type="number"
          variant="standard"
          value= {state.septPrice}
          onChange={ e => setState({...state, septPrice:e.target.value } ) }
          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
        />
      </Grid>
      <Grid item lg={4}>
        <TextField
          id="octPrice"
          label="October Price"
          onBlur={ saveVariableBilling }
          fullWidth
          // className={classes.paddedStuff}
          // type="number"
          variant="standard"
          value= {state.octPrice}
          onChange={ e => setState({...state, octPrice:e.target.value } ) }
          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
        />
      </Grid>
      <Grid item lg={4}>
        <TextField
          id="novPrice"
          label="November Price"
          onBlur={ saveVariableBilling }
          fullWidth
          // className={classes.paddedStuff}
          // type="number"
          variant="standard"
          value= {state.novPrice}
          onChange={ e => setState({...state, novPrice:e.target.value } ) }
          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
        />
      </Grid>
      <Grid item lg={4}>
        <TextField
          id="decPrice"
          label="December Price"
          onBlur={ saveVariableBilling }
          fullWidth
          // className={classes.paddedStuff}
          // type="number"
          variant="standard"
          value= {state.decPrice}
          onChange={ e => setState({...state, decPrice:e.target.value } ) }
          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
        />
      </Grid>
      <Grid item lg={12}>
      </Grid>
    </Grid>
  )
}

export default React.memo(VariableBilling)