import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import LineItemDetails from './ConvertToJobLineItemTable'
import Paper from '@material-ui/core/Paper';
import TrackProjectModal from './TrackProjectModal'

const ConfigurationContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
align-content: center;
text-align: center;
border-radius: 15px;
height: 110px;
margin-top: 25px;
width: 100%;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  }
}));


const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

const defaultValues = {
  qty: 0,
  unit: '',
  description: '',
  totalPrice: 0,
  costPer: 0,
  pricePer: 0,
  inventoryItem: null,
  linkedProject: null
}

const ConvertToJobModal = (props) => {
  // console.log('Convert To Job PROPS ', props)
  const classes = useStyles();
  const [state, setState] = React.useState(defaultValues)
  const [workorders, setWorkorders] = React.useState([])
  const [showTrackProjectModal, setShowTrackProjectModal] = React.useState(true)


  React.useEffect(() => {
    // console.log('Track PRoject Modal: ', props)
    if (props.estimate && props.estimate.project) {
      setShowTrackProjectModal(false)
    } else {
      setShowTrackProjectModal(true)
    }
    setWorkorders(props.workorders)
    // setShowTrackProjectModal(true)
  }, [props])

  const handleClose = () => {
    props.updateParent('closeModal')
  }

  const handleComplete = () => {
    // console.log('Finished - choose to track as project or not')
    // setShowTrackProjectModal(true)
    setWorkorders([])
    props.updateParent('finished')
  }

  const generateWorkorderFromLineItems = (items) => {
    // console.log('Make Our Workorder now: ', items)
    // console.log(props)
    // console.log('props.job', props.job)
    let obj = {
      estimate: props.estimate._id,
      lineItems: items,
      job: props.job._id,
      project: state.linkedProject
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/proposal/createWOFromEstimate`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      console.log('Created Workorder: ', response.data)
      if (response.data && response.data._id) {
        let wos = workorders
        if (response.data.existing) {
          // Job Already Exists from previous creation...
        } else {
          wos.push(response.data)
        }
        // console.log('Workoers created:', wos)
        setWorkorders(wos)
        setState({...state, counter: wos.length })
      }
    }) 

  }
  // const LineItemDetails = () => {
  //   let list = props.estimate.lineItems
  //   // console.log('List: ', list)
  //   if (list && list.length) {
  //     return (
  //       <ul>
  //         { list.map((item, index) => (
  //           <li key={index}>{ item.description}</li>
  //           )
  //       )}
  //       </ul>
  //     )
  //   } else {
  //     return 'No Line Items to Assign'
  //   }
  // }
  const responseFromTrackProjectModal = async (d) => {
    if (d && d._id) {
      // project to link to these workorders
      // console.log('Link to this project', d)
      setShowTrackProjectModal(false)
      setState({...state, linkedProject: d })
    } else {
      setShowTrackProjectModal(false)
    }
  }
  // Workorders already available
  if (props.workorders && props.workorders.length) {
    console.log('We have workorders!!', props.workorders)
  }

  return (
    <Dialog
      maxWidth='xl'
      fullWidth open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <TrackProjectModal open={showTrackProjectModal} updateParent={responseFromTrackProjectModal} />
      <DialogTitle id="form-dialog-title">Create Workorders from Estimate Line Items</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Job Created: {props.job ? `${props.job.name} #${props.job.number}` : ''}
         
        </DialogContentText>
        <DialogContentText>You can either create Workorders from here (the way we always do) or you can now go to the Job and Generate Workorders</DialogContentText>
     
      <Grid container spacing={2}>
      
        <Grid item lg={12}>
          <Grid container>
            <Grid item lg={8}>
              <LineItemDetails estimate={props.estimate} generateWorkorderFromLineItems={generateWorkorderFromLineItems} />             
            </Grid>
            <Grid item lg={4}>
              <Paper style={{marginLeft: 10, padding: 5, height: '100%', width: '100%'}}>
                <Typography>Workorders Created</Typography>
                <ul>
                  { workorders.map((item, index) => (
                    <li key={index}>{item.number} - {item.items} Items (${item.price})</li>
                  ))}
                </ul>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose} color="secondary">
          Close
        </Button> */}
        <Button onClick={handleComplete} disabled={!workorders || workorders.length < 1} color="primary">
          Finished
        </Button>
      </DialogActions>

   </Dialog>
  )
}
const mapStateToProps = (state) => {
  // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
  }
}

export default connect(mapStateToProps)(ConvertToJobModal)
