import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, CircularProgress }  from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import EmailStatementComponent from '../Statement/EmailTheStatementModal'

const StatementModal = (props) => {
  // console.log('StatementModal Modal: ', props)
  const [pdf, setPDF] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    
    // console.log('Use Effect StatementModal MODAL', props)
    setPDF(props.pdf)
  }, [props])
  const handleClose = () => {
    // console.log('Closing StatementModal modal')
    props.closeStatementModal('Close')
  }

  const handleEmailStatement = () => {
    // console.log('Email StatementModal')

    // setLoading(true)
    // let updateObject = {
    //   client: props.client,
    //   pdf: pdf,
    //   user: props.user
    // }
    // axios({
    //   method: 'post',
    //   url:  `${props.url}/api/customer/emailStatement`,
    //   data: updateObject,
    //   // validateStatus: (status) => {
    //   //   // console.log('Validate status: ', status)
    //   //   if (status && status === 500) {
  
    //   //   }
    //   // },
    // }).then(response => {
    //   setLoading(false)
    //   // console.log('EMAIL StatementModal RSPONSE: ', response)
    //   props.notification({
    //     type: 'success',
    //     title: 'Statement Emailed',
    //     message: 'Email Sent. Grab a cup of coffee, you did great!'
    //   })
    // })
  }
  return (
    <div>
       <Dialog
        // fullScreen={true}
        maxWidth='sm'
        fullWidth
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Customer Statement</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item lg={12}>
              <EmailStatementComponent pdf={pdf} {...props} />
               
            </Grid>
          </Grid>
          <object aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="800px" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(StatementModal))