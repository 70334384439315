import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const Reassignmodal = (props) => {
// console.log('Reassignmodal: ', props)
  // const [crew, setCrew]
  const [state, setState] = React.useState({
    crew: props.crew,
    newCrew: {},
    item: props.item
  })

  // React.useEffect(() => {
  //   setState({...state, crwe: props.crew})
  // }, [])
  const crewNameAndForeman = (crew) => {
    // console.log('Get Label Option', crew)
    if (crew) {
      if (crew && crew.foremanName) {
        return crew.name + ' (' + crew.foremanName + ')'
      } else {
        return crew.name
      }
    } else {
      return ''
    }
  }

  const updateCrew = (id, val) => {
  // console.log('UPdate crew!!!!', id)
  // console.log(val)
    let newItem = props.item
    newItem.crew = val._id
    newItem.crewName = val.name
  // console.log('New Item w/ crew: ', newItem)
    setState({...state, newCrew: val, item: newItem})
  }

  const handleSave = () => {
  // console.log('Save this shit', state)
    props.saveAssignment(state.item)
  }
  return (
      <Dialog
        open={props.open}
        onClose={props.closeModal}
        PaperProps={{
          style: {
            backgroundColor: '#eee',
            boxShadow: 'none',
            width: 600
          },
        }}
        
        >
          <DialogTitle style={{textAlign: 'center', color: '#aaa'}}>
            Re-Assign Workorder
          </DialogTitle>
          <DialogContent>
            <p>Please choose the Crew to Assign:</p>
            <Autocomplete
              id={`${props.item._id}`}
              options={props.crews}
              getOptionLabel={(option) => crewNameAndForeman(option)}
              // style={{ width: 200 }}
              onChange={updateCrew}
              // onChange={ (e) => updateCrew(e.target.id, e.target.value) }
              value = {state.crew}
              // onChange={ e => setState({...state, crew: e.target.value, requiresUpdate: true }) }
              renderInput={(params) => <TextField {...params} label="Assigned Crew" variant="outlined" />}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" disabled={!state.newCrew} onClick={handleSave}>
              Save
            </Button>
          </DialogActions>
      </Dialog>

  )
}
export default Reassignmodal