import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Typography } from '@material-ui/core'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { fade, makeStyles } from '@material-ui/core/styles';
import AccountSummary from './AccountSummary'
import AccountBalance from './AccountBalance'
import MonthEndProduction from './MonthEndProduction'
import MonthEndOptions from './MonthEndOptions'
import CommissionOptionsModal from './CommissionOptionsModal'
import { Button, CircularProgress } from '@material-ui/core';
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import ProtectedButton from '../../actions/protectedButtons'
import PendingInvoicesReport from './PendingInvoices'
import WorkordersNotInvoiced from './WorkordersNotInvoiced'
import AuthNetReport from './AuthnetReport'
import PaymentDetailsReport from './PaymentDetailsReport'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import MtcIndexReport from './MtcIndexReport'
import UnreconciledPOReport from './UnreconciledPOReport'
import HoursWorkedReport from './HoursWorkedReport'
import HMIMtcReport from './HMIMtcReport'
import GPByDivisionReport from './GPByDivisionReport'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  sweetBorder: {
    border: '1px solid',
    borderRadius: '10px'
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  }
}));

const TimeFrameSelectionModal = (props) => {
  const classes = useStyles()
  const now = new Date()
  const start = moment(now).subtract(24, 'hours')
  const [state, setState] = React.useState({
    start: start,
    end: now
  })
  const handleSubmit = () => {
    props.submit(state)
  }

  const handleCloseModal = () => {
    props.closeModal()
  }

  return (
    <Dialog
          // fullScreen={true}
          maxWidth='sm'
          open={props.open}
          // onClose={handleClose}
          PaperProps={{
            style: {
              overflow: 'hidden',
              height: '300px'
            }}}
          aria-labelledby="responsive-dialog-title"
          >
          <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
          <DialogContent>
            <Grid container spacing={6}>
            <Grid item lg={12} xs={12}>
              Please choose the timeframe you'd like covered by this report:
            </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  id="date"
                  label="Beginning Date"
                  type="date"
                  className={classes.paddedStuff}
                  fullWidth
                  value={moment(state.start).format('YYYY-MM-DD') } // "2017-05-24"}
                  onChange={ e => setState({...state, start: e.target.value }) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  id="date"
                  label="End Date"
                  type="date"
                  className={classes.paddedStuff}
                  fullWidth
                  value={moment(state.end).format('YYYY-MM-DD') } // "2017-05-24"}
                  onChange={ e => setState({...state, end: e.target.value }) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
  )
}
const Reports = (props) => {
  const classes = useStyles();
  const [reportData, setReportData] = React.useState({})
  const [reportToShow, setReportToShow] = React.useState(null)
  const [showReport, setShowReport] = React.useState(false)
  const [dateRange, setDateRange] = React.useState({
    start: null,
    end: null
  })
  const [loading, setLoading] = React.useState(false)
  const [monthEndOptionsOpen, setMonthEndOptionsOpen] = React.useState(false)
  const [commissionReportOpen, setCommissionModalOpen] = React.useState(false)
  const [activeEntity, setActiveEntity] = React.useState({ _id: 'All'})
  const [showTimeSelectionModal, setShowTimeSelectionModal] = React.useState(false)
  const [showHoursWorkedModal, setShowHoursWorkedModal] = React.useState(false)

  const runAccountSummaryReport = async () => {
    setLoading(true)
    const response = await axios.get(`${props.url}/api/reports/accountSummary?entity=${activeEntity._id}`)
    if (response && response.data) {
    // console.log('Acct Summary Report Data', response.data)
      setReportData(response.data)
      setReportToShow('acctSummary')
      setShowReport(true)
      setLoading(false)
    }
  }

  const runMonthEndReport = async () => {
    setMonthEndOptionsOpen(true)
  }

  const submitMonthEndReport = async (data) => {
  // console.log('Submit the Month End Report')
    setDateRange(data)
    setMonthEndOptionsOpen(false)
    setLoading(true)
    const response = await axios.post(`${props.url}/api/reports/monthEnd?entity=${activeEntity._id}`, data)
    if (response && response.data) {
    // console.log('Month End Report Data', response.data)
      setReportData(response.data)
      setReportToShow('monthEnd')
      setShowReport(true)
      setLoading(false)
    }
  }

  const runAccountBalanceReport = async () => {
    setLoading(true)
    const response = await axios.get(`${props.url}/api/reports/accountBalance?entity=${activeEntity._id}`)
    if (response && response.data) {
    // console.log('Acct Balance Report Data', response.data)
      setReportData(response.data)
      setReportToShow('acctBalance')
      setShowReport(true)
      setLoading(false)
    }
  }

  const runBenhartMtcReport = async () => {
    setLoading(true)
    const response = await axios.get(`${props.url}/api/reports/benhartMtc`)
    if (response && response.data) {
      // console.log('Acct Balance Report Data', response.data)
      // setReportData(response.data)
      // setReportToShow('acctBalance')
      // setShowReport(true)
      setLoading(false)
      let newBlob = new Blob([response.data], {type: "text/csv"})
      // console.log(newBlob)
      const data = window.URL.createObjectURL(newBlob);
      // console.log(data)
      var link = document.createElement('a');
      link.href = data;
      link.download="file.csv";
      link.click();
    }
  }

  const runBenhartLateFeesReport = async () => {
    setLoading(true)
    const response = await axios.get(`${props.url}/api/reports/peggysLateFeeReport`)
    if (response && response.data) {
      // console.log('Acct Balance Report Data', response.data)
      // setReportData(response.data)
      // setReportToShow('acctBalance')
      // setShowReport(true)
      setLoading(false)
      let newBlob = new Blob([response.data], {type: "text/csv"})
      // console.log(newBlob)
      const data = window.URL.createObjectURL(newBlob);
      // console.log(data)
      var link = document.createElement('a');
      link.href = data;
      link.download="benhartlatefees.csv";
      link.click();
    }
  }
  const runPendingInvoicesReport = async () => {
    setLoading(true)
    const response = await axios.get(`${props.url}/api/reports/pendingInvoices?entity=${activeEntity._id}`)
    if (response && response.data) {
    // console.log('Pending Invoices Data', response.data)
      setReportData(response.data)
      setReportToShow('pendingInvoices')
      setShowReport(true)
      setLoading(false)
    }
  }

  const runWorkordersNotInvoicedReport = async () => {
    setLoading(true)
    const response = await axios.get(`${props.url}/api/reports/workordersNotInvoiced?entity=${activeEntity._id}`)
    if (response && response.data) {
      // console.log('Pending Invoices Data', response.data)
      setReportData(response.data)
      setReportToShow('workordersNotInvoiced')
      setShowReport(true)
      setLoading(false)
    }
  }

  const runAuthNetReport = async () => {
    setLoading(true)
    const response = await axios.get(`${props.url}/api/reports/authorizedPaymentsReport?timeframe=30`)
    if (response && response.data) {
      // console.log('Pending Invoices Data', response.data)
      setReportData(response.data)
      setReportToShow('authNetReport')
      setShowReport(true)
      setLoading(false)
    }
  }

  const runDailyPaidReport = async () => {
    setReportToShow('dailyPaidReport')
    setShowTimeSelectionModal(true)
  }

  const runMtcIndexReport = async () => {
    setLoading(true)
    const response = await axios.get(`${props.url}/api/reports/maintenanceIndexReport?entity=${activeEntity._id}`)
    if (response && response.data) {
      // console.log('Pending Invoices Data', response.data)
      setReportData(response.data)
      setReportToShow('maintenanceIndexReport')
      setShowReport(true)
      setLoading(false)
    }
  }

  const runHMIMtcReport = async () => {
    setReportToShow('HMIMtc')
    setShowTimeSelectionModal(true)
  }

  const runDivisionBreakdownReport = async () => {
    setReportToShow('divisionBreakdown')
    setShowTimeSelectionModal(true)
  }

  const runInvoiceDetailsReport = async () => {
    setReportToShow('invoiceDetails')
    setShowTimeSelectionModal(true)
  }

  const runReport = (type) => {
  // console.log('Run Report!!', type)
    setShowReport(false)
    if (type === 'acctSummary') runAccountSummaryReport()
    if (type === 'acctBalance') runAccountBalanceReport()
    if (type === 'monthEnd') runMonthEndReport()
    if (type === 'commission') setCommissionModalOpen(true)
    if (type === 'benhartMtc') runBenhartMtcReport()
    if (type === 'pendingInvoices') runPendingInvoicesReport()
    if (type === 'benhartLateFees') runBenhartLateFeesReport()
    if (type === 'workordersNotInvoiced') runWorkordersNotInvoicedReport()
    if (type === 'authNetReport') runAuthNetReport()
    if (type === 'dailyPaidReport') runDailyPaidReport()
    if (type === 'maintenanceIndexReport') runMtcIndexReport()
    if (type === 'unreconciledPOs') runUnreconciledPOsReport()
    if (type === 'hoursWorked') runHousWorkedReport()
    if (type === 'hmiMtc') runHMIMtcReport()
    if (type === 'divisionBreakdown') runDivisionBreakdownReport()
    if (type === 'invoiceDetails') runInvoiceDetailsReport()
  }

  const runUnreconciledPOsReport = () => {
    setReportToShow('unreconciledPOs')
    setShowTimeSelectionModal(true)
  }

  const CommissionDataComing = () => {
    // console.log('Report data', reportData)
    const startDate = new Date(reportData?.dates?.start)
    const endDate = new Date(reportData?.dates?.end)
    return (
      <Card style={{margin: '5px'}}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Commission Report Running In the Background
          </Typography>
          <Grid container spacing={1} >
            <Grid item lg={12} xs={12}>
            We are currently pulling all paid invoices starting on {moment(startDate).format('ddd MM/DD/YYYY')} and ending on {moment(endDate).format('ddd MM/DD/YYYY')}.
            <br /><br />  When this report is finished running, a copy of it will be sent to your email: {props.user.email}.
          
            </Grid>
            <Grid item lg={12} xs={12}>
              {reportData.invoices ? reportData.invoices > 1000 ? 'A report this large may take some time.' : '' : ''}
            </Grid>  
          </Grid>
        </CardContent>
      </Card>
    )
  }

  const ShowReport = () => {
  // console.log('Show Report', reportToShow)
  // console.log('Report Data', reportData)
    if (reportToShow === 'acctSummary') return <AccountSummary data={reportData} />
    if (reportToShow === 'acctBalance') return <AccountBalance data={reportData} />
    if (reportToShow === 'monthEnd') return <MonthEndProduction data={reportData} dates={dateRange} />
    if (reportToShow === 'commission') return <CommissionDataComing />
    if (reportToShow === 'pendingInvoices') return <PendingInvoicesReport data={reportData} />
    if (reportToShow === 'workordersNotInvoiced') return <WorkordersNotInvoiced data={reportData} />
    if (reportToShow === 'authNetReport') return <AuthNetReport data={reportData} />
    if (reportToShow === 'dailyPaidReport') return <PaymentDetailsReport data={reportData} />
    if (reportToShow === 'maintenanceIndexReport') return <MtcIndexReport data={reportData} />
    if (reportToShow === 'unreconciledPOs') return <UnreconciledPOReport data={reportData} />
    if (reportToShow === 'hoursWorked') return <HoursWorkedReport data={reportData} />
    if (reportToShow === 'HMIMtc') return <HMIMtcReport data={reportData} />
    if (reportToShow === 'divisionBreakdown') return <GPByDivisionReport data={reportData} />
    return <div>No Report Chosen</div>
  }

  const closeModal = () => {
    setMonthEndOptionsOpen(false)
  }

  const closeCommissionModal = () => {
    setCommissionModalOpen(false)
  }

  const submitCommissionReport = async (data) => {
  // console.log('Run Commission', data)
    setCommissionModalOpen(false)
    setLoading(true)
    const obj = {
      dates: data
    }
    const response = await axios.post(`${props.url}/api/reports/commission?entity=${activeEntity._id}`, obj)
    if (response && response.data) {
    // console.log('Commission Report Data', response.data)
      setReportData(response.data)
      setReportToShow('commission')
      setShowReport(true)
      setLoading(false)
    }
  }

  const handleUpdateVisibleEntity = (activeEntity) => {
    // let customerList = null
    // if (showAllCustomers) {
    //   customerList = props.clients
    // } else {
    //   customerList = props.clients.filter(item => { return item.active })
    // }    
    // if (activeEntity.name === 'All Assigned') {
    //   // Show all available Customers
    //   filterOut(customerList, searchValue)
    //   setRows(customerList)
    // } else {
    //   // Filter by the active Entity
    //   let filteredClients = customerList.filter(item => { return item.entity === activeEntity._id })
    //   // console.log('Active: ', activeClients)
    //   filterOut(filteredClients, searchValue)
    //   setRows(filteredClients)
    // }
    setActiveEntity(activeEntity)
    setShowReport(false)
  }

  const handlePaymentDetailsReport = async (data) => {
    // setShowTimeSelectionModal(false)
  // console.log('Paymetn Details', data)
    // let timeframe = moment(data.start).diff(moment(data.end), 'days')
    // console.log('Timeframe: ', timeframe)
    let obj = data
    obj.entity = activeEntity
    setLoading(true)
    const response = await axios.post(`${props.url}/api/reports/paymentDetailsReport`, obj)
    if (response && response.data) {
      // console.log('Pending Invoices Data', response.data)
      setReportData({ timeframe: data, data: response.data })
      // setReportToShow('dailyPaidReport')
      setShowReport(true)
      setLoading(false)
    }
  }

  const handleUnreconciledPOReport = async (data) => {
  // console.log('Unreconciled POs', data)
    let obj = data
    obj.entity = activeEntity
    setLoading(true)
    const response = await axios.post(`${props.url}/api/reports/unreconciledPOReport`, obj)
    if (response && response.data) {
    // console.log('Unreconciled POs Data', response.data)
      setReportData({ timeframe: data, data: response.data })
      // setReportToShow('dailyPaidReport')
      setShowReport(true)
      setLoading(false)
    }
  }
  // handlePaymentDetailsReport
  const updateTimeframe = (obj) => {
    setShowTimeSelectionModal(false)
  // console.log('Update Timeframes:', obj.start)
  // console.log('End:', obj.end)
  // console.log('Which report', reportToShow)
    let data = {
      start: obj.start,
      end: obj.end
    }
    // RUn that Report
    if (reportToShow === 'dailyPaidReport') handlePaymentDetailsReport(data)
    if (reportToShow === 'unreconciledPOs') handleUnreconciledPOReport(data)
    if (reportToShow === 'hoursWorked') handleHoursWorkedReport(data)
    if (reportToShow === 'HMIMtc') handleHMIMtcReport(data)
    if (reportToShow === 'divisionBreakdown') handleGPByDivisionReport(data)
    if (reportToShow === 'invoiceDetails') handleInvoiceDetailsReport(data)
  }

  const handleInvoiceDetailsReport = async (data) => {
    setLoading(true)
    // console.log('Handle invoice details report', data)
    // const startDate = moment(data.start).startOf('day').subtract(7, 'hours').format('YYYY-MM-DD')
    // // console.log('Start date', startDate)
    // const endDate = moment(data.end).endOf('day').subtract(7, 'hours').format('YYYY-MM-DD')
    // console.log('End date', endDate)
    let obj = {
      startDate: data.start,
      endDate: data.end,
      activeEntity
    }
    try {
      const response = await axios.post(`${props.url}/api/reports/invoiceDetailsReport`, obj, {
        responseType: 'blob', // Ensure the response is treated as a file blob
      });
    
      if (response && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Invoice_Details_${obj.startDate}_${obj.endDate}.xlsx`); // Suggested file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    
        props.notification({
          type: 'success',
          title: 'Report Generated',
          message: 'Spreadsheet has been downloaded',
        });
    
        setLoading(false);
      }
    } catch (e) {
      console.log('Error getting invoice spreadsheet', e);
    }
  }

  const handleHMIMtcReport = async (data) => {
    console.log('HMI Mtc Report', data)
    setLoading(true)
    const response = await axios.get(`${props.url}/api/reports/maintenanceIndexReport?type=HMI&data=${JSON.stringify(data)}`)
    if (response && response.data) {
      console.log('HMI Mtc Data', response.data)
      setReportData(response.data)
      // setReportToShow('hmiMtc')
      setShowReport(true)
      setLoading(false)
    }
  }

  const handleGPByDivisionReport = async (data) => {
    console.log('HMI handleGPByDivisionReport ', data)
    setLoading(true)
    const response = await axios.get(`${props.url}/api/reports/maintenanceIndexReport?type=divisionBreakdown&data=${JSON.stringify(data)}&entity=${activeEntity._id}`)
    if (response && response.data) {
      console.log('divisionBreakdown Data', response.data)
      setReportData(response.data)
      // setReportToShow('hmiMtc')
      setShowReport(true)
      setLoading(false)
    }
  }

  const handleHoursWorkedReport = async (data) => {
  // console.log('Unreconciled POs', data)
    let obj = data
    obj.entity = activeEntity
    setLoading(true)
    const response = await axios.post(`${props.url}/api/reports/hoursWorked`, obj)
    if (response && response.data) {
    console.log('Hours Worked Data', response.data)
      setReportData({ timeframe: data, data: response.data })
      // setReportToShow('dailyPaidReport')
      setShowReport(true)
      setLoading(false)
    }
  }

  const runHousWorkedReport = () => {
    setReportToShow('hoursWorked')
    setShowTimeSelectionModal(true)
  }

  return (
    <div style={{padding: '15px'}}>
      <TimeFrameSelectionModal open={showTimeSelectionModal} submit={updateTimeframe} closeModal={() => setShowTimeSelectionModal(false)} />
      <CommissionOptionsModal open={commissionReportOpen} runReport={submitCommissionReport} closeModal={closeCommissionModal} />
      <MonthEndOptions open={monthEndOptionsOpen} runReport={submitMonthEndReport} closeModal={closeModal} />
      <h1>Reports</h1>
      <Card>
        <CardContent>
        {
          (props.user && props.user.entities && props.user.entities.length > 1) ? (
            <Grid container style={{padding: '10px'}}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ChooseEntityComponent allowAll={true} title={'Visible Entities'} user={props.user} updateParent={handleUpdateVisibleEntity} />
            </Grid>
          </Grid>
          ) : ''
        }
          <Typography variant="h5" component="h2" gutterBottom>
            Choose a Report to Generate:
          </Typography>
          <Grid container spacing={1}>
          <ProtectedButton type="Finance Reports" kind="view" perms={props.perms}>
              <Grid item lg={12} xs={12}>
                <span className={classes.fakeLink} onClick={() => runReport('acctSummary')}>Account Summary</span>
                </Grid>
            </ProtectedButton>


            <Grid item lg={12} xs={12}>
            <span className={classes.fakeLink} onClick={() => runReport('acctBalance')}>Account Balance Details</span>
            </Grid>
            <Grid item lg={12} xs={12}>
            <span className={classes.fakeLink} onClick={() => runReport('monthEnd')}>Month End Production</span>
            </Grid>
            <Grid item lg={12} xs={12}>
            <span className={classes.fakeLink} onClick={() => runReport('pendingInvoices')}>Pending Invoices Report</span>
            </Grid>
            <Grid item lg={12} xs={12}>
            <span className={classes.fakeLink} onClick={() => runReport('workordersNotInvoiced')}>Workorders Not Invoiced Report</span>
            </Grid>
            <ProtectedButton type="Finance Reports" kind="view" perms={props.perms}>
              <Grid item lg={12} xs={12}>
                <span className={classes.fakeLink} onClick={() => runReport('commission')}>Commission Report</span>
              </Grid>
            </ProtectedButton>
            
            <Grid item lg={12} xs={12}>
            <span className={classes.fakeLink} onClick={() => runReport('benhartMtc')}>Benhart Maintenance Report</span>
            </Grid>
            <Grid item lg={12} xs={12}>
            <span className={classes.fakeLink} onClick={() => runReport('benhartLateFees')}>Benhart Maintenance Late Fee Report</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span className={classes.fakeLink} onClick={() => runReport('authNetReport')}>Authorize.net Report</span>
            </Grid>
            <Grid item lg={12} xs={12}>
            <span className={classes.fakeLink} onClick={() => runReport('dailyPaidReport')}>Payment Details Report</span>

            
            </Grid>
            <Grid item lg={12} xs={12}>
              <span className={classes.fakeLink} onClick={() => runReport('maintenanceIndexReport')}>MTC Index Report</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span className={classes.fakeLink} onClick={() => runReport('unreconciledPOs')}>Unreconciled Purchase Orders</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span className={classes.fakeLink} onClick={() => runReport('hoursWorked')}>View Hours Worked by Employee</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span className={classes.fakeLink} onClick={() => runReport('hmiMtc')}>HMI Maintenance Report</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span className={classes.fakeLink} onClick={() => runReport('divisionBreakdown')}>Breakdown By Division Report</span>
            </Grid>
            <Grid item lg={12} xs={12}>
              <span className={classes.fakeLink} onClick={() => runReport('invoiceDetails')}>Invoice Details Report</span>
            </Grid>
          </Grid>
          { loading ? <CircularProgress style={{color: 'green'}} size={ 48 } /> : ''}
        </CardContent>
      </Card>
      
      {
        showReport ? <ShowReport /> : <br />
      }
      
    </div>
  )
}

  const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms,
    employees: state.employees
  }
}

export default connect(mapStateToProps)(Reports)
