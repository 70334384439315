import React from 'react'
import { connect } from 'react-redux'
import { setClient, setContacts } from '../../actions/connectionActions'
import axios from 'axios'
import { CircularProgress } from '@material-ui/core'
import { fade, makeStyles, withStyles } from '@material-ui/core/styles'
import CustomerDetailsModal from './CustomerDetail'
// import {useHistory, useLocation  } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    textAlign: 'center',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  statusModal: {
    overflow: 'hidden'
  },
  wrapper: {
    maxWidth: '260px',
    minWidth: '100px',
    whiteSpace: 'nowrap',
    display: 'inline'
  },
  box: {
    borderLeft: '7px solid #333',
    padding: '7px',
    backgroundColor: '#234543',
    width: '100%',
    color: '#aaa',
    fontSize: '10px'
  },
  bigText: {
   color: '#777',
   fontSize: '16px'
   },
   invoiceBox: {
    borderLeft: '7px solid #ad8409',
    padding: '7px',
    backgroundColor: '#fdf7e7',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  paymentsBox: {
    borderLeft: '7px solid #56b349',
    padding: '7px',
    backgroundColor: '#eef8ed',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  depositsBox: {
    borderLeft: '7px solid #56b349',
    padding: '7px',
    backgroundColor: '#eef9ed',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  creditsBox: {
    borderLeft: '7px solid #e6b730',
    padding: '7px',
    backgroundColor: '#fbe59c',
    // width: '100%',
    color: '#444',
    fontSize: '10px',
    display: 'inlineBlock'
  },
  balanceBox: {
    borderLeft: '7px solid #d7393f',
    padding: '7px',
    backgroundColor: '#f6d5d6',
    // width: '100%',
    color: '#444',
    fontSize: '10px',
    display: 'inlineBlock'
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  }
}));


const CustomerDetail = (props) => {
  // console.log('Customer Detail Standalone Page ', props)
  const classes = useStyles();
  // const history = useHistory()
  // const location = useLocation()
  const [client, setClient] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [customerDetailModalOpen, setCustomerDetailModalOpen] = React.useState(false)
  const [state, setState] = React.useState({
    openSnoutWeevil: false,
    counter: 0,
    openItem: null,
    _id: null
  })

  React.useEffect(() => {
    let isMounted = true
    async function getClient(clientId) {
      // console.log('Get our Client!!!')
      setLoading(true)
      const response = await axios.get(`${props.url}/api/customer/client?customerId=${clientId}`)
      // console.log('Client', response)
      if (response && response.data && response.data.client && isMounted) {
        // console.log('Open customer details')
        setLoading(false)
        if (response.data.contacts) props.setContacts(response.data.contacts)
        setClient(response.data.client)
        setCustomerDetailModalOpen(true)
      }
    }
    
    if (props.match && props.match.params && props.match.params.clientId) {
      // console.log('Get Clinet:', props.match.params.clientId)
    // console.log('Do we have anything else to open??', props.match.params)
      getClient(props.match.params.clientId)
      setState({...state, openItem: props.match.params.type, _id: props.match.params._id })


    }

    return () => {
      isMounted = false
    }
  }, [])

  const updateParent = (d) => {
    // console.log('Update Parent from Customer Detail Standalone Page:', d)
  }

  const onModalClose = (d) => {
    // console.log('Close Customer Standalone Page...', d)
    setCustomerDetailModalOpen(false)
    props.history.push('/clients')
  }
  return (
    <div className={classes.root} >
      { loading ?<CircularProgress style={{color: '#ACCACD', marginTop: '100px'}} size={ 156 } /> : ''}
      <CustomerDetailsModal updateParent={updateParent} openSnoutWeevil={state.openSnoutWeevil} open={customerDetailModalOpen} customerDetailModalOpen={customerDetailModalOpen} closeModal={onModalClose} client={client} sentData={state} />
    </div>
  )
  }


  const mapStateToProps = (state) => {
    // console.log('Mat to customer: ', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      perms: state.perms
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    // console.log('UPdate our contacts/client!!! dispatch', dispatch)
    return {
      setClient: (client) => { dispatch(setClient(client)) },
      setContacts: (contacts) => { dispatch(setContacts(contacts)) }
    }
  }

  export default React.memo(connect(mapStateToProps, mapDispatchToProps)(CustomerDetail))
