import React, { useState, useEffect } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete'

const equipmentTypes = ['Cell Phone', 'Vehicle', 'Trailer', 'Tool', 'Other'];

const EquipmentAutocomplete = ({ equipmentList, onAddEquipment }) => {
  console.log('Equipment Auto Complete', equipmentList)
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false);
  const [newEquipment, setNewEquipment] = useState({
    type: '',
    name: '',
  });


  const handleAddNew = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewEquipment({ type: '', name: '' });
  };

  const handleSave = () => {
    // console.log('Save', newEquipment)
    setValue(newEquipment)
    onAddEquipment(newEquipment);
    handleClose();
  };

  return (
    <div>
      <Autocomplete
        options={equipmentList || []}
        getOptionLabel={(option) => {
          console.log('Get our label...', option)
          let displayName = `${option.type} - ${option.name} ${option?.manufacturer || ''}`
          if (option && option.type === 'Vehicle') {
            displayName += ` (#${option.number})`
            if (option.plate) displayName += ` Plate: ${option.plate}`
          }
          console.log('Display Name', displayName)
          return displayName
        }}
        value={value}
        onChange={(event, newValue) => {
          if (newValue && newValue.inputValue) {
            handleAddNew();
          } else {
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = options.filter((option) => {
            let stringifiedOption = JSON.stringify(option)
            // console.log('Stringified option', stringifiedOption)
            return (stringifiedOption && stringifiedOption.toLowerCase().includes(params.inputValue.toLowerCase()))
          }
          );
          // console.log('Filtered Options', filtered)
          if (params.inputValue !== '' && !filtered.some(option => option.name === params.inputValue)) {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }
          return filtered;
        }}
        renderInput={(params) => <TextField {...params} label="Select Equipment" variant="outlined" />}
      />

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Equipment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details of the new equipment.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Type"
            select
            fullWidth
            value={newEquipment.type}
            onChange={(e) => setNewEquipment({ ...newEquipment, type: e.target.value })}
          >
            {equipmentTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            label="Name"
            fullWidth
            value={newEquipment.name}
            onChange={(e) => setNewEquipment({ ...newEquipment, name: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EquipmentAutocomplete;
