import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    backgroundColor: '#eee'
  },
  paper: {
    // width: '100%',
    marginTop: 10,
    marginBottom: theme.spacing(2),
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
      maxWidth: 960,
      textAlign: 'center'
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '50vh',
      maxWidth: 1360,
      textAlign: 'center'
    },
    // margin: 20
  },
  sigBox: {
    // backgroundColor: 'green',
    margin: 20,
    width: '100%',
    textAlign: 'center'
  },
  headerImages: {
    marginTop: 5,
    width: '100%',
    overflow: 'hidden'
  },
  footerImages: {
    marginTop: 5,
    width: '100%',
    overflow: 'hidden'
  },
  disclosureArea: {
    fontSize: '0.8rem',
    color: '#bbb',
    textAlign: 'center'
  },
  headerText: {
    fontSize: 32,
    color: '#aaa',
    // color: 'rgb(119,156,164)',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 18,
      // color: 'red'
    },
    [theme.breakpoints.down('xs')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 14,
      // color: 'pink'
    },
    [theme.breakpoints.up('md')]: {
      // minHeight: '50vh',
      // maxWidth: 1360,
      // textAlign: 'center'
      fontSize: 24,
      // color: 'green'
    }
  },
  headerLogo: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '65%'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '90%'
    }
  },
  giantText: {
    textAlign: 'center',
    fontSize: 48,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
    marginBottom: -15
  },
  subText: {
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
  },
  squigglyText: {
    textAlign: 'center',
    fontSize: 42,
    fontFamily: 'Inspiration',
    color: 'rgb(119,156,164)',
  },
  signUpText: {
    marginTop: 15,
    fontFamily: 'Amiri',
    fontSize: 32,
    textAlign: 'center',
    color: 'rgb(119,156,164)',
  },
  disclaimerText: {
    fontSize: 16,
    marginBottom: 30,
    marginTop: 15,
    color: '#aaa'
  },
  nameText: {
    fontSize: 24,
    marginTop: 10,
    color: '#aaa'
  }
}));

function ViewWorkorderOnline(props) {
// console.log('View Online Proposal ', props)
const classes = useStyles();
  const [state, setState] = React.useState({
    counter: 0
  })
  const [logoURL, setLogoURL] = React.useState(null)
  const [contact, setContact] = React.useState({})
  const [pdf, setPDF] = React.useState({})
  const [showESigModal, setShowESigModal] = React.useState(false)
  const [showChoosePlanModal, setShowChoosePlanModal] = React.useState(false)
  const [plan, setPlan] = React.useState('Advantage')
  const [workorder, setWorkorder] = React.useState({})

  const generatePDF = (agreementId) => {
  // console.log('Generate PDF!', agreementId)
    axios({
      method: 'get',
      url:  `${props.url}/api/pools/generatePDF?id=${agreementId}`
    }).then(response => {
    // console.log('Got the pDF!!', response.data)
      setPDF(response.data)
    })
  }

  React.useEffect(() => {
    let isMounted = true

    const fetchWorkorder = async (woId) => {

        let url = `${props.url}/api/jobs/getdetailsforworkorder?id=${woId}`
        // console.log('URL to retrieve: ', url)
        const response = await axios.get(url)
        if (response && response.data && isMounted) {
          console.log('STUFF: ', response.data)
          if (response.data._id) setWorkorder(response.data)
        }
      
    }

    if (props.match && props.match.params && props.match.params.workorderId) {
        fetchWorkorder(props.match.params.workorderId)
      }


    return () => {
      isMounted = false
    }
  }, [])






  return (
    <div className={classes.root}>
     <Paper className={classes.paper}>
      
        <Grid container>
          
          <Grid item lg={12} xs={12} className={classes.headerImages}>
        
            {/* <img src="/poolHeader.png" width="100%" /> */}
            <img src="/Logo_W_BestOfValleyCHips.png" />
          </Grid>
          <Grid item lg={12} xs={12} className={classes.headerImages}>
        
        <img src="/OnlineProposalHeaderImage.png" />
        {/* <img src="/Logo_W_BestOfValleyCHips.png" /> */}
      </Grid>

          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.giantText} >
            {/* {(proposal && proposal.entity) ? proposal.entity.name : ''} is excited to propose to you a grand adventure
             */}
            BEAUTIFUL
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.subText} >
            {/* {(proposal && proposal.entity) ? proposal.entity.name : ''} is excited to propose to you a grand adventure
             */}
            OUTDOOR LIVING SPACES THAT BENEFIT
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.squigglyText} >
            {/* {(proposal && proposal.entity) ? proposal.entity.name : ''} is excited to propose to you a grand adventure
             */}
            The Mind, Body, And Soul
          </Grid>

         
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.signUpText}>
            
            Workorder Details
          </Grid>
         
         
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.signUpText}>
                  
              Workorder { (workorder && workorder.number) && `#${workorder.number} - (${workorder.jobName})`}
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.disclosureArea}>
            Workorder Details TBD
          </Grid>
          {/* <GetStartedButton contract={contract} handleChoosePlan={handleChoosePlan} /> */}
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.footerImages}>
          <img src='/OnlineProposalFooterImage.png' width="100%"/>
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.disclosureArea}>
            This Workorder was intended to be viewed by those with a need to know, if you were sent this link inadvertantly, please close the browser now.
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.disclosureArea}>
            Copyright { (workorder.entity && workorder.entity._id) ? workorder.entity.name : ''} { new Date().getFullYear()}, All Rights Reserved.
          </Grid>
        </Grid>
     </Paper>
    </div>
  );
}



const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(ViewWorkorderOnline)

