import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
  Button,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { GoogleMap, LoadScript, Marker, useJsApiLoader } from '@react-google-maps/api';
import moment from 'moment';
import CustomMarker from './CustomMarker';
import { isEven } from '../../../services/common';
import { addDays, subDays } from 'date-fns';
import Box from '@material-ui/core/Box';
import axios from 'axios'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AssignmentConfirmationDialog from './AssignmentConfirmationDialog';
import { ArrowUpward, ArrowDownward, Sort } from '@material-ui/icons';

const sortCrews = (crews, sortMethod, sortDirection) => {
  return [...crews].sort((a, b) => {
    let aValue, bValue;
    switch (sortMethod) {
      case 'todayServiceHours':
        aValue = (a.todayData.totalServiceTime || 0) / 60;
        bValue = (b.todayData.totalServiceTime || 0) / 60;
        break;
      case 'todayTotalHours':
        aValue = ((a.todayData.totalServiceTime || 0) + (a.todayData.totalDriveTime || 0)) / 60;
        bValue = ((b.todayData.totalServiceTime || 0) + (b.todayData.totalDriveTime || 0)) / 60;
        break;
      case 'weekTotalServiceHours':
        aValue = (a.weekData.totalServiceTime || 0) / 60;
        bValue = (b.weekData.totalServiceTime || 0) / 60;
        break;
      case 'weekTotalHours':
        aValue = ((a.weekData.totalServiceTime || 0) + (a.weekData.totalDriveTime || 0)) / 60;
        bValue = ((b.weekData.totalServiceTime || 0) + (b.weekData.totalDriveTime || 0)) / 60;
        break;
      default:
        aValue = a.name;
        bValue = b.name;
    }
    if (aValue < bValue) return sortDirection === 'asc' ? -1 : 1;
    if (aValue > bValue) return sortDirection === 'asc' ? 1 : -1;
    return 0;
  });
};

const filterSitesForCrewAndDate = (weeklyRoutes, date, crewId) => {
  console.log('Filter Sites for crew and date', weeklyRoutes, date, crewId);
  const dayOfWeek = moment(date).format('dddd');

  // Find the route for the given day
  const dayRoute = weeklyRoutes.find(route => route.day === dayOfWeek);
  
  if (!dayRoute) {
    console.log('No route found for this day');
    return [];
  }
  console.log('Here are the routes fo rhtis day', dayRoute)
  // Find the crew's route for this day
  const crewRoute = dayRoute.routes.find(route => route.crewId === crewId);

  if (!crewRoute) {
    console.log('No route found for this crew on this day');
    return [];
  }

  // Return the stops for this crew on this day
  return crewRoute.stops.map(stop => ({
    ...stop,
    crew: crewId,
    crewName: crewRoute.crewName
  }));
};

const getSortMethodLabel = (method) => {
  switch (method) {
    case 'todayServiceHours': return "Today's Service Hours";
    case 'todayTotalHours': return "Today's Total Hours";
    case 'weekTotalServiceHours': return "Week's Service Hours";
    case 'weekTotalHours': return "Week's Total Hours";
    default: return "Name";
  }
};

const findNextMatchingDate = (site, startDate = moment()) => {
  const { frequency, evenOrOddWeeks, preferredDay, preferredDays } = site;
  let currentDate = moment(startDate);

  const fullDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

  const isEvenWeek = (date) => moment(date).week() % 2 === 0;

  const getNextWeekday = (date, targetDay) => {
    console.log('Get next weekday ', date.format('YYYY-MM-DD'), targetDay);
    if (!targetDay || targetDay === 'None') {
      return date.clone(); // Return the same day if no target day specified
    }

    const targetIndex = fullDays.indexOf(targetDay.toLowerCase());
    if (targetIndex === -1) {
      throw new Error('Invalid preferred day');
    }

    let daysToAdd = targetIndex - date.day();
    if (daysToAdd <= 0) daysToAdd += 7;
    console.log('Days To Add', daysToAdd);
    return date.clone().add(daysToAdd, 'days');
  };

  const getDayToCheck = () => {
    if (preferredDay && preferredDay !== 'None') {
      return preferredDay.toLowerCase();
    }
    if (preferredDays && preferredDays.length > 0) {
      return preferredDays[0].toLowerCase();
    }
    return null; // No preference specified
  };

  const findNextDate = () => {
    const dayToCheck = getDayToCheck();
    console.log('Day to check: %s  - Frequency: %s ', dayToCheck, frequency.toLowerCase());

    switch (frequency.toLowerCase()) {
      case 'monthly':
      case 'quarterly':
      case 'semi-annually':
      case 'on-demand':
        if (!dayToCheck) return currentDate; // No matching day, use today
        let nextDate = currentDate.clone();
        if (frequency.toLowerCase() === 'monthly') nextDate.add(1, 'month').startOf('month');
        if (frequency.toLowerCase() === 'quarterly') nextDate.add(1, 'quarter').startOf('quarter');
        if (frequency.toLowerCase() === 'semi-annually') nextDate.add(6, 'months').startOf('month');
        return getNextWeekday(nextDate, dayToCheck);

      case 'weekly':
        return dayToCheck ? getNextWeekday(currentDate, dayToCheck) : currentDate;

      case 'bi-weekly':
        if (!dayToCheck) return currentDate; // No matching day, use today
        let biWeeklyDate = getNextWeekday(currentDate, dayToCheck);
        const targetEvenWeek = evenOrOddWeeks.toLowerCase() === 'even';
        while (isEvenWeek(biWeeklyDate) !== targetEvenWeek) {
          biWeeklyDate.add(1, 'week');
        }
        return biWeeklyDate;

      case 'twice a week':
        if (currentDate.day() < 3) { // Before Wednesday
          return currentDate.day(3); // Set to Wednesday
        } else if (currentDate.day() < 6) { // Wednesday to Friday
          return currentDate.day(6); // Set to Saturday
        } else { // Saturday or Sunday
          return currentDate.add(1, 'week').day(3); // Set to next Wednesday
        }

      case 'every other day (m-w-f)':
        const nextDays = [1, 3, 5]; // Monday, Wednesday, Friday
        const nextDay = nextDays.find(day => day > currentDate.day()) || nextDays[0];
        return currentDate.day(nextDay);

      default:
        return currentDate; // For any unhandled frequency, use today
    }
  };

  return findNextDate();
};

const formatDistance = (meters) => {
  return Math.round(Number(meters / 1609) * 100) / 100
}

const getCrewColor = (index) => {
  return distinctHexColors[index % distinctHexColors.length];
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const defaultCenter = {
  lat: 33.50547813946138,
  lng: -112.15212417301781
};

const position = {
  lat: 33.5,
  lng: -112.1
}

const days = ['M', 'T', 'W', 'Th', 'F'];
const fullDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const getBackgroundColor = (hours) => {
  if (hours > 8) return 'rgba(212,102,102,0.5)';
  if (hours > 6) return 'rgba(240,223,113,0.5)';
  if (hours > 4) return 'rgba(203,250,192,0.5)';
  return '#eee';
};

const calculateHours = (filteredSites) => {
  let totalHours = 0;
  const crewHours = {};

  filteredSites.forEach(site => {
    const hours = parseFloat(site.estimatedHours) || 0;
    totalHours += hours;

    if (site.crew) {
      crewHours[site.crew] = (crewHours[site.crew] || 0) + hours;
    }
  });

  return { totalHours, crewHours };
};

const defaultStateVals = {
  assigningToService: null,
  servicesRemaining: []
}

const RouteMapViewComponent = ({ weeklyRoutes, crews, url, sites }) => {
  const classes = useStyles();
  const [state, setState] = useState(defaultStateVals)
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [totalHours, setTotalHours] = useState(0);
  const [unassignedJobs, setUnassignedJobs] = useState([]);
  const [filteredCrews, setFilteredCrews] = useState([]);
  const [markers, setMarkers] = useState([]);
  const [filteredMarkers, setFilteredMarkers] = useState([]);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedCrew, setSelectedCrew] = useState({});
  const [zoomLevel, setZoomLevel] = useState(9);
  const [center, setCenter] = useState(defaultCenter);
  const [jobSiteDetails, setJobSiteDetails] = useState({});
  const [allFilteredMarkers, setAllFilteredMarkers] = useState([]);
  const [weeklyCrewHours, setWeeklyCrewHours] = useState({});
  // const [jobSites, setJobSites] = useState([])
  const jobSites = sites || []
  const [weeklyRouteData, setWeeklyRouteData] = useState({});
  const [sortMethod, setSortMethod] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const [anchorEl, setAnchorEl] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GMAPKEY
  });

  const dayOfWeek = moment(selectedDate).format('dddd');
  const weekOfTheYear = moment(selectedDate).week();
  const evenWeek = isEven(weekOfTheYear);
  const thisWeek = evenWeek ? 'Even' : 'Odd';

  useEffect(() => {
    if (weeklyRoutes && weeklyRoutes.length) {
      const processedData = processWeeklyRouteData(weeklyRoutes);
      setWeeklyRouteData(processedData);
      updateDisplayData(processedData, selectedDate, crews);
    }
  }, [weeklyRoutes, selectedDate, crews]);
  
  const processWeeklyRouteData = (routes) => {
    console.log('Process data', routes);
    const processedData = {};
    routes.forEach(dayRoute => {
      // console.log('Dayroute', dayRoute)
      const day = dayRoute.day;
      processedData[day] = dayRoute.routes.reduce((acc, route) => {
        console.log('Reduce htis bitch...', route)
        acc[route.crewId] = {
          crewId: route.crewId,
          crewName: route.crewName,
          totalPay: route.totalPay,
          totalRevenue: route.totalRevenue,
          mileageCost: route.mileageCost,
          totalDriveTime: route.totalDriveTime,
          totalServiceTime: route.totalServiceTime,
          stops: route.stops
        };
        return acc;
      }, {});
    });
    console.log('Processed:', processedData);
    return processedData;
  };
  
  const updateDisplayData = (weeklyData, date, crews) => {
    // console.log('Update displayed data', weeklyData)
    const dayOfWeek = moment(date).format('dddd');
    const todayData = weeklyData[dayOfWeek] || {};
    // console.log('Todays data', todayData)
    // Calculate total hours and update markers
    let totalHours = 0;
    const todayMarkers = [];
    Object.values(todayData).forEach(crewData => {
      // console.log('Crew Data item 305', crewData)
      crewData.stops.forEach(stop => {
        totalHours += parseFloat(stop.estimatedHours) || 0;
        todayMarkers.push({
          ...stop,
          crewName: crewData.crewName,
          crewId: crewData.crewId,
          crewColor: getCrewColor(crews.findIndex(c => c._id.toString() === crewData.crewId.toString()))
        });
      });
    });
    // console.log('Todays markers', todayMarkers)
    setTotalHours(totalHours);
    setFilteredMarkers(todayMarkers);
    setAllFilteredMarkers(todayMarkers);
  
    // Update crew data
    const updatedCrews = crews.map(crew => {
      // console.log('Updated crew 323', crew._id)
      // console.log('Today data', todayData)
      const crewDayData = todayData[crew._id] || {};
      // console.log('Crew Day Data', crewDayData)
      const crewWeekData = Object.values(weeklyData).reduce((acc, day) => {
        // console.log('Day 327', day)
        const dayData = day[crew._id] || {};
        // console.log('Day Data 329', dayData)
        acc.totalPay += dayData.totalPay || 0;
        acc.totalRevenue += dayData.totalRevenue || 0;
        acc.mileageCost += dayData.mileageCost || 0;
        acc.totalDriveTime += dayData.totalDriveTime || 0;
        acc.totalServiceTime += dayData.totalServiceTime || 0;
        acc.totalHours += dayData.stops ? dayData.stops.reduce((sum, stop) => sum + (parseFloat(stop.estimatedHours) || 0), 0) : 0;
        return acc;
      }, { totalPay: 0, totalRevenue: 0, mileageCost: 0, totalDriveTime: 0, totalServiceTime: 0, totalHours: 0 });
      const objToReturn = {
        ...crew,
        todayData: {
          ...crewDayData,
          totalHours: crewDayData.stops ? crewDayData.stops.reduce((sum, stop) => sum + (parseFloat(stop.estimatedHours) || 0), 0) : 0
        },
        weekData: crewWeekData
      }
      // console.log('Crew Item w/ data', objToReturn)
      return objToReturn;
    });
    // console.log('Set the filtered crews', updatedCrews)
    setFilteredCrews(updatedCrews);
  };

  const calculateWeeklyHours = (date, sites, crews) => {
    const startOfWeek = moment(date).startOf('week');
    let weeklyData = {};
  
    crews.forEach(crew => {
      weeklyData[crew._id] = {
        Monday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
        Tuesday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
        Wednesday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
        Thursday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
        Friday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
        Saturday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
        Sunday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
        total: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 }
      };
    });
  
    for (let i = 0; i < 7; i++) {
      const currentDate = moment(startOfWeek).add(i, 'days');
      const dayName = currentDate.format('dddd');
      const filteredSitesForDay = filterSitesForDate(sites, currentDate);
      const { crewHours } = calculateHours(filteredSitesForDay);
  
      Object.keys(crewHours).forEach(crewId => {
        if (!weeklyData[crewId]) {
          weeklyData[crewId] = {
            Monday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
            Tuesday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
            Wednesday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
            Thursday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
            Friday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
            Saturday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
            Sunday: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 },
            total: { hours: 0, mileageCost: 0, driveTimeCost: 0, revenue: 0 }
          };
        }

        const dailyHours = crewHours[crewId] || 0;
        const routeData = weeklyRouteData[dayName]?.[crews.find(c => c._id === crewId)?.name] || {};
        
        weeklyData[crewId][dayName] = {
          hours: dailyHours,
          mileageCost: routeData.mileageCost || 0,
          driveTimeCost: calculateDriveTimeCost(routeData.totalDriveTime, routeData.totalPay),
          revenue: routeData.totalRevenue || 0
        };
  
        weeklyData[crewId].total.hours += dailyHours;
        weeklyData[crewId].total.mileageCost += routeData.mileageCost || 0;
        weeklyData[crewId].total.driveTimeCost += calculateDriveTimeCost(routeData.totalDriveTime, routeData.totalPay);
        weeklyData[crewId].total.revenue += routeData.totalRevenue || 0;
      });
    }
  
    return weeklyData;
  };
  
  const handleSort = (method) => {
    if (method === sortMethod) {
      // If clicking the same method, toggle direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      // If clicking a new method, set it and default to ascending
      setSortMethod(method);
      setSortDirection('asc');
    }
    handleCloseSortingMenu()
  };

  const handleCloseSortingMenu = () => {
    setAnchorEl(null)
  }



  const calculateDriveTimeCost = (driveTimeInMinutes, hourlyRate) => {
    if (!driveTimeInMinutes || !hourlyRate) return 0;
    return (driveTimeInMinutes / 60) * hourlyRate;
  };

  const updateDataFromWeeklyRoutes = (dayRoutes, allWeeklyRoutes) => {
    const newMarkers = dayRoutes.routes.flatMap((route, routeIndex) => 
      route.stops.map(stop => ({
        ...stop,
        crewName: route.crewName,
        crewColor: getCrewColor(routeIndex),
        latitude: parseFloat(stop.latitude),
        longitude: parseFloat(stop.longitude),
      }))
    );
  
    setMarkers(newMarkers);
    setFilteredMarkers(newMarkers);
  
    const updatedCrews = dayRoutes.routes.map((route, index) => {
      const todayHours = route.stops.reduce((sum, stop) => sum + parseFloat(stop.estimatedHours), 0);
      
      // Calculate weekly hours for this crew
      const weeklyHours = allWeeklyRoutes.reduce((weekSum, dayRoute) => {
        const crewRoute = dayRoute.routes.find(r => r.crewName === route.crewName);
        if (crewRoute) {
          return weekSum + crewRoute.stops.reduce((daySum, stop) => daySum + parseFloat(stop.estimatedHours), 0);
        }
        return weekSum;
      }, 0);
  
      return {
        _id: route.id, // Assuming route has an id, adjust if needed
        name: route.crewName,
        color: getCrewColor(index),
        todayHours: todayHours,
        weeklyHours: {
          total: weeklyHours,
          Monday: getDayHours(allWeeklyRoutes, 'Monday', route.crewName),
          Tuesday: getDayHours(allWeeklyRoutes, 'Tuesday', route.crewName),
          Wednesday: getDayHours(allWeeklyRoutes, 'Wednesday', route.crewName),
          Thursday: getDayHours(allWeeklyRoutes, 'Thursday', route.crewName),
          Friday: getDayHours(allWeeklyRoutes, 'Friday', route.crewName),
          Saturday: getDayHours(allWeeklyRoutes, 'Saturday', route.crewName),
          Sunday: getDayHours(allWeeklyRoutes, 'Sunday', route.crewName),
        }
      };
    });
  
    setFilteredCrews(updatedCrews);
  
    const totalHours = newMarkers.reduce((sum, marker) => sum + parseFloat(marker.estimatedHours), 0);
    setTotalHours(totalHours);
  
    // Set unassigned jobs if applicable
    const unassigned = newMarkers.filter(marker => !marker.crewName);
    setUnassignedJobs(unassigned);
  };
  
  // Helper function to get hours for a specific day and crew
  const getDayHours = (allWeeklyRoutes, day, crewName) => {
    const dayRoute = allWeeklyRoutes.find(route => route.day === day);
    if (dayRoute) {
      const crewRoute = dayRoute.routes.find(route => route.crewName === crewName);
      if (crewRoute) {
        return crewRoute.stops.reduce((sum, stop) => sum + parseFloat(stop.estimatedHours), 0);
      }
    }
    return 0;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // The effect will trigger updateDataFromWeeklyRoutes
  };

  const handlePreviousDay = () => {
    const yesterday = subDays(selectedDate, 1);
    handleDateChange(yesterday);
  };

  const handleNextDay = () => {
    const tomorrow = addDays(selectedDate, 1);
    handleDateChange(tomorrow);
  };

  const updateActiveMarker = (markerClicked) => {
    console.log('Update active marker...', markerClicked)
    const crew = crews.find(crewItem => (crewItem._id === markerClicked.crew))
    if (crew?._id && selectedCrew && selectedCrew._id === crew._id) {
      // If the clicked crew is already selected, deselect it
      // setSelectedCrew(null);
      // Reset to show all filtered markers
      // filterTheMarkers(jobSites, selectedDate, crews);
    } else {
      setSelectedCrew(crew);
      console.log('Chosen crew', crew)
      console.log('Filter these sites for that crew', jobSites)
      // Only display markers associated with this crew for the current date
      const crewSites = filterSitesForCrewAndDate(weeklyRoutes, selectedDate, crew._id);
      setFilteredMarkers(crewSites);
    }

  }

  const handleClickAssign = (d) => {
    console.log('Assign this one', d)
    let updatedMarkers = [
      ...filteredMarkers.filter(marker => (marker._id !== activeMarker?._id)),
      // d
    ]
    setFilteredMarkers(updatedMarkers)
    setActiveMarker(d)
    // Set Zoom to 11
    // Center Map on this marker
    setZoomLevel(13)
    let lng = d?.location?.coordinates?.length ? d.location.coordinates[0] : d.longitude
    let lat = d?.location?.coordinates?.length ? d.location.coordinates[1] : d.latitude
    let newCenter = {
      lat: lat || defaultCenter.lat,
      lng: lng || defaultCenter.lng
    }
    setCenter(newCenter)
    getDetailsAboutThisSite(d)
    setState(prevState => ({ ...prevState, assigningToService: false }))
  }

  const handleClickOnDayForCrew = (crew, day) => {
    console.log('Click on Day', day)
    console.log(crew)
    // Calculate the date for the clicked day
    const clickedDate = moment(selectedDate).day(day);
    
    // Filter markers for this crew and day
    const filteredSitesForCrewAndDay = filterSitesForCrewAndDate(weeklyRoutes, clickedDate, crew._id);
    
    // // Update the filtered markers
    setFilteredMarkers(filteredSitesForCrewAndDay);
    
    // Update the selected date
    setSelectedDate(clickedDate.toDate());
    
    // Update the selected crew
    setSelectedCrew(crew);
  }

  const handleUnassignment = (site) => {
    console.log('Unassign this site...', site)
    let currentMarkers = [...markers]
    let index = currentMarkers.findIndex(item => (item._id === site._id))
    if (index > -1) {
      const siteItem = currentMarkers[index]
      siteItem.crew = null
      siteItem.crewName = 'Unassigned'
    }
    console.log('Index', index)
    console.log('Markers to set', currentMarkers)
    filterTheMarkers(currentMarkers, selectedDate, crews)
    let currentUnassignedJobs = [...unassignedJobs]
    currentUnassignedJobs.unshift(site)
    setUnassignedJobs(currentUnassignedJobs)
  }

  const handleAssignToCrew = (crew, site) => {
    console.log('Assign to crew', crew)
    console.log(site)
    console.log(selectedDate)
    console.log(activeMarker)
    // setActiveMarker({})
    // setSelectedCrew({})
    // let currentMarkers = [...markers]
    // let index = currentMarkers.findIndex(item => (item._id === site._id))
    // if (index > -1) {
    //   const siteItem = currentMarkers[index]
    //   siteItem.crew = crew._id
    //   siteItem.crewName = crew.name
    // }
    // console.log('Index', index)
    // console.log('Markers to set', currentMarkers)
    // filterTheMarkers(currentMarkers, selectedDate, crews)
    // let currentUnassignedJobs = [...unassignedJobs]
    // // currentUnassignedJobs.unshift(site)
    // let unassignedJobsIndex = currentUnassignedJobs.findIndex(item => (item._id === site._id))
    // if (unassignedJobsIndex > -1) {
    //   currentUnassignedJobs.splice(unassignedJobsIndex, 1)
    //   setUnassignedJobs(currentUnassignedJobs)
    // }
  }

  const handleClickOnSiteNearby = (site) => {
    console.log('Click On this nearby site...', site)
    const nextDate = findNextMatchingDate(site);
    console.log('Next matching date:', nextDate);    
    // Filter markers for this crew and day
    const filteredSitesForCrewAndDay = filterSitesForCrewAndDate(weeklyRoutes, nextDate, site.crew);
    
    // // Update the filtered markers
    setFilteredMarkers(filteredSitesForCrewAndDay);
    const date = new Date(nextDate)
    console.log('Date object', date)
    console.log(nextDate.toDate())
    // Update the selected date
    setSelectedDate(date);
    const crew = filteredCrews.find(item => (item._id === site.crew))
    console.log('Crews: ', crews)
    console.log('props.crews', crews)
    console.log('filtered crews', filteredCrews)
    // Update the selected crew
    console.log('Set this crew', crew)
    setSelectedCrew(crew);
  }

  const filterSitesForDate = (sites, date) => {
    const dayOfWeek = moment(date).format('dddd');
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const isWeekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(dayOfWeek);
    const weekOfTheYear = moment(date).week();
    const evenWeek = isEven(weekOfTheYear);
    const thisWeek = evenWeek ? 'Even' : 'Odd';
  
    return sites.filter(site => {
      let includeSite = false;
  
      switch (site.frequency) {
        case 'Daily':
          includeSite = isWeekday;
          break;
        case 'Every Other Day (M-W-F)':
          includeSite = ['Monday', 'Wednesday', 'Friday'].includes(dayOfWeek);
          break;
        case 'Bi-Weekly':
          if (thisWeek === site.evenOrOddWeeks) {
            includeSite = site.preferredDay === dayOfWeek;
          }
          break;
        case 'Monthly':
        case 'Quarterly':
        case 'Semi-Annually':
        case 'Annually':
        case 'On-Demand':
          if (site.scheduledDates && site.scheduledDates.length > 0) {
            includeSite = site.scheduledDates.includes(formattedDate);
          }
          break;
        default:
          includeSite = site.preferredDay === dayOfWeek || (site.preferredDays && site.preferredDays.includes(dayOfWeek));
          break;
      }
  
      if (site.scheduledDates && site.scheduledDates.length > 0) {
        includeSite = includeSite || site.scheduledDates.includes(formattedDate);
      }
  
      return includeSite;
    });
  };

  const filterTheMarkers = (sites, date, crewsPassed) => {
    console.log('Filter the Markers!!!', crewsPassed)
    let crewsToUse = [...crews]
    console.log('Sites to filter', sites)
    if (crewsPassed?.length) crewsToUse = crewsPassed
    console.log('Date to filter:', date)
    const dayOfWeek = moment(date).format('dddd')
    const formattedDate = moment(date).format('YYYY-MM-DD')
    const filteredSitesNew = filterSitesForDate(sites, date);
  
    setFilteredMarkers(filteredSitesNew)
    setAllFilteredMarkers(filteredSitesNew);
  
    const { totalHours, crewHours } = calculateHours(filteredSitesNew);
    setTotalHours(totalHours);
    const weeklyHours = calculateWeeklyHours(date, sites, crewsToUse);
    setWeeklyCrewHours(weeklyHours);
    
    const updatedCrews = crewsToUse.map(crew => ({
      ...crew,
      weeklyHours: weeklyHours[crew._id],
      todayHours: weeklyHours[crew._id][dayOfWeek].hours
    }));
    
    console.log('Updated crews', updatedCrews)
    setFilteredCrews(updatedCrews);
  }

  const getDetailsAboutThisSite = async (site) => {
    console.log('Get the closest jobsite and shit like that from the backend for this guy', site)
    const siteDetails = await axios.post(`${url}/api/hmi/getNearestSiteDetails`, site)
    console.log('Got the site details', siteDetails)
    // Lets merge the distance w/ the active sites
    let nearestSites = []
    let data = {
      ...siteDetails.data
    }
    if (siteDetails?.data?.nearestSites?.length) {
      let filteredSites = siteDetails.data.nearestSites.filter(item => (item._id !== site._id))
      let markersWithDistance = []
      filteredSites.forEach((siteItem, index) => {
        let markerIndex = markers.findIndex(markerItem => (markerItem._id === siteItem._id))
        if (markerIndex > -1) {
          console.log('Merge this shit', markers[markerIndex])
          console.log('Site', siteItem)
          let itemToReturn = {
            ...markers[markerIndex],
            distance: formatDistance(siteItem.distance),
          }
          markersWithDistance.push(itemToReturn)
        }
      })
      console.log('Markers w/ Distance', markersWithDistance)
      data.nearestSites = markersWithDistance
      // filterTheMarkers(markersWithDistance, selectedDate, crews);
      // setFilteredMarkers(markersWithDistance)
      let currentFilteredMarkers = filteredMarkers.map(item => {
        let markerWithDistance = markersWithDistance.find(markerItem => (markerItem?._id === item._id))
        if (markerWithDistance?._id) {
          return ({
            ...item,
            distance: markerWithDistance.distance
          })
        }
      })
      console.log('Filtered markers w/ Distance...', currentFilteredMarkers)
      // setFilteredMarkers(currentFilteredMarkers)
      // setAllFilteredMarkers(markersWithDistance);
    }
    console.log('Set the data', data)
    setJobSiteDetails(data || {})
  }

  const handleClickOnCrew = (crew) => {
    console.log('Click on crew', crew)
    if (selectedCrew && selectedCrew._id === crew._id) {
      // If the clicked crew is already selected, deselect it
      // setSelectedCrew(null);
      // // Reset to show all filtered markers
      // filterTheMarkers(jobSites, selectedDate, crews);
    } else {
      setSelectedCrew(crew);
      // Only display markers associated with this crew for the current date
      const crewSites = filterSitesForCrewAndDate(weeklyRoutes, selectedDate, crew._id);
      console.log('Here are that crews sites...', crewSites)
      // Merge the site data with our other jobsite data
      let sitesWithData = crewSites.map(crewSite => {
        const siteFound = sites.find(siteItem => (siteItem?._id === crewSite?.siteId))
        console.log('Found SIte 802:::', siteFound)
        return {
          ...siteFound,
          ...crewSite,
          color: crew.color || '#483'
        }
      })
      console.log('Sites w/ data', sitesWithData)
      setFilteredMarkers(sitesWithData);
    }

  }
  const handleSortClick = (e) => {
    setAnchorEl(e.currentTarget);
  }
  const handleSortChange = (newMethod) => {
    if (newMethod === sortMethod) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortMethod(newMethod);
      setSortDirection('asc');
    }
  };

  const handleDirectionChange = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  console.log('692', filteredMarkers)
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={3}>
          <Paper className={classes.calendarPaper}>
            {/* <CalendarTabArea /> */}
            <Typography variant="h7">
              Total Properties: {sites.length}
              <br />
            </Typography>
            <div className={classes.dateArea}>
              <Button onClick={handlePreviousDay}>PREV</Button>
              <DatePicker
                value={selectedDate}
                onChange={handleDateChange}
                animateYearScrolling
              />
              <Button onClick={handleNextDay}>NEXT</Button>
            </div>
            <Typography variant="h5">
              {dayOfWeek} (Week #{weekOfTheYear} - {thisWeek})
            </Typography>
            <Typography variant="h6">
              Total Hours: {Math.round(100 * totalHours) / 100}
            </Typography>
            <Typography variant="h7">
              Filtered Stops: {filteredMarkers.length}
              <br />
              Filtered Hours: {Math.round(100 * totalHours) / 100}
            </Typography>
          </Paper>
          <Paper className={classes.crewPaper}>
            <div className={classes.sortButtonContainer}>
              <SortButton
                sortMethod={sortMethod}
                sortDirection={sortDirection}
                onSortChange={handleSortChange}
                onDirectionChange={handleDirectionChange}
              />
            </div>
            <div className={classes.crewList}>
            {sortCrews(filteredCrews, sortMethod, sortDirection).map((crew, index) => {
              // console.log('777', crew)
              const clickOnCrewDay = (day) => {
                handleClickOnDayForCrew(crew, day)
              }
              const todayName = moment(selectedDate).format('dddd');
              // console.log('Day', todayName)
              const todayData = crew.todayData
              const weekTotals = crew.weekData
              const color = getCrewColor(index)
              // console.log('Today Data', todayData)
              // console.log('Week totals', weekTotals)
              // console.log('color: %s   crew.color: %s ', color, crew.color)
              if (!todayData || !weekTotals) return null
              // console.log(':')
              
              const todayServiceHours = Math.round(100 * (Number(todayData.totalServiceTime / 60))) / 100 || ''
              const todayTotalHours = Math.round(100 * (Number(todayData.totalServiceTime / 60) + Number(todayData.totalDriveTime / 60))) / 100 || ''
              const weekTotalServiceHours = Math.round(100 * (Number(weekTotals.totalServiceTime / 60))) / 100 || ''
              const weekTotalHours = Math.round(100 * (Number(weekTotals.totalServiceTime / 60) + Number(weekTotals.totalDriveTime / 60))) / 100 || ''
              return (
                <div 
                  key={crew._id} 
                  style={{ 
                    borderRight: `12px solid ${crew.color}`, 
                    cursor: 'pointer', 
                    backgroundColor: '#fff',
                    padding: 10, 
                    margin: 5, 
                    borderRadius: 8,
                    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)'
                  }} 
                  onClick={e => handleClickOnCrew(crew)}
                >
                  <div className={classes.crewDetailsContainer}>
                    <div className={classes.crewDetailsTopLine}>
                      <InfoItem 
                        label="Crew Name" 
                        value={`${crew.name}`}
                      />
                    
                    </div>
                    <div className={classes.crewDetailsTopLine}>
                      <InfoItem 
                        label="Work Hours Today" 
                        value={`${todayServiceHours}`} 
                        centerItem
                      />
                       <InfoItem 
                        label="Total Hours Today" 
                        value={`${todayTotalHours}`} 
                        centerItem
                      />
                     </div>
                     <div className={classes.crewDetailsTopLine}>
                      <InfoItem 
                        label="Work Hours This Week" 
                        value={`${weekTotalServiceHours}`}
                        centerItem
                      />
                      <InfoItem 
                        label="Total Hours This Week" 
                        value={`${weekTotalHours}`}
                        centerItem
                      />
                    </div>
                    <WeeklyHoursDisplay 
                      weeklyHours={crew.weeklyHours}
                      handleDayClick={clickOnCrewDay}
                      selectedDay={selectedCrew && selectedCrew._id === crew._id ? moment(selectedDate).format('dddd') : null}
                    />
                  </div>
                </div>
              )
            })}
            </div>
          </Paper>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <div className={classes.mapContainer}>
              <div className={classes.map}>
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={zoomLevel}
                  >
                    {filteredMarkers.map((marker, index) => (
                      <CustomMarker
                        key={index}
                        position={{ lat: marker.latitude, lng: marker.longitude }}
                        icon="dfl"
                        color={marker.crewColor}
                        jobsite={marker}
                        activeMarker={activeMarker}
                        updateActiveMarker={updateActiveMarker}
                        hideInfoWindowOnActiveItem={true}
                      />
                    ))}
                  </GoogleMap>
                ) : (
                  <></>
                )}
              </div>
             
            </div>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.unassignedJobsPaper}>
            <Typography variant="h6">What do We Put here</Typography>
            {
              activeMarker?._id ? (
                <div>
                  <div>
                    <div>
                      <Button 
                        onClick={() => {
                          setActiveMarker({})
                          setState(prevState => ({ ...prevState, assigningToService: null }))
                          // setFilteredMarkers(allFilteredMarkers)
                          filterTheMarkers(markers, selectedDate, crews)
                        }}
                        variant="contained"
                        color="primary"
                        style={{ margin: '10px 0' }}
                      >
                        Show All Unassigned Jobs
                      </Button>
                    </div>
                    { activeMarker?.name }
                    <br />
                    { activeMarker?.frequency}
                    <br />
                    Preferred Day: { activeMarker?.preferredDay}
                    <br />
                    Estimated Hours: { activeMarker?.estimatedHours}
                    <br />
                    Last Workorder Date: { moment(jobSiteDetails?.lastWorkorder?.scheduledDate).format('ddd MM/DD/YY') || ''}
                    <br />
                    Services Remaining: {jobSiteDetails?.totalServicesRemaining || ''}
                    <br />
                    {/* {
                      jobSiteDetails?.servicesRemaining?.length && <ServiceScheduler servicesRemaining={jobSiteDetails?.servicesRemaining} updateDate={handleUpdateDateFromScheduler} />
                    } */}
                  
                  </div>
                  <div style={{ overflowY: 'auto', margin: 5, padding: 5 }}>
                      <ClosestLocationDetails jobSiteDetails={jobSiteDetails} site={activeMarker} crews={filteredCrews} handleClickOnSiteNearby={handleClickOnSiteNearby} />
                  </div>
                  
                </div>
              ) : null
            }
           
             {selectedCrew?._id && (
                <div className={classes.selectedCrewContainer}>
                  <SelectedCrewDetails
                    crew={selectedCrew}
                    crews={filteredCrews}
                    date={selectedDate}
                    stops={filteredMarkers}
                    activeMarker={activeMarker}
                    jobSiteDetails={jobSiteDetails}
                    handleUnassignment={handleUnassignment}
                    handleAssignToCrew={handleAssignToCrew}
                    markers={markers}
                  />
                </div>
              )}
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const SortButton = ({ sortMethod, sortDirection, onSortChange, onDirectionChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSortChange = (newMethod) => {
    onSortChange(newMethod);
    handleClose();
  };

  const handleDirectionClick = (event) => {
    event.stopPropagation();
    onDirectionChange();
  };

  return (
    <>
      <Button
        aria-controls="sort-menu"
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<Sort />}
      >
        Sort By: {getSortMethodLabel(sortMethod)}
        <div onClick={handleDirectionClick} style={{ marginLeft: 8, cursor: 'pointer' }}>
          {sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />}
        </div>
      </Button>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleSortChange('name')}>
          <ListItemIcon>
            {sortMethod === 'name' && (sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />)}
          </ListItemIcon>
          <ListItemText primary="Name" />
        </MenuItem>
        <MenuItem onClick={() => handleSortChange('todayServiceHours')}>
          <ListItemIcon>
            {sortMethod === 'todayServiceHours' && (sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />)}
          </ListItemIcon>
          <ListItemText primary="Today's Service Hours" />
        </MenuItem>
        <MenuItem onClick={() => handleSortChange('todayTotalHours')}>
          <ListItemIcon>
            {sortMethod === 'todayTotalHours' && (sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />)}
          </ListItemIcon>
          <ListItemText primary="Today's Total Hours" />
        </MenuItem>
        <MenuItem onClick={() => handleSortChange('weekTotalServiceHours')}>
          <ListItemIcon>
            {sortMethod === 'weekTotalServiceHours' && (sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />)}
          </ListItemIcon>
          <ListItemText primary="Week's Service Hours" />
        </MenuItem>
        <MenuItem onClick={() => handleSortChange('weekTotalHours')}>
          <ListItemIcon>
            {sortMethod === 'weekTotalHours' && (sortDirection === 'asc' ? <ArrowUpward /> : <ArrowDownward />)}
          </ListItemIcon>
          <ListItemText primary="Week's Total Hours" />
        </MenuItem>
      </Menu>
    </>
  );
};

const SiteList = ({ nearestSites, handleClickOnSiteNearby, closestMatchSites }) => {
  const classes = useStyles();

  return (
    <div>
      {nearestSites.map((site, index) => (
        <div key={index} className={classes.nearestSiteItemContainer} onClick={() => handleClickOnSiteNearby(site)}>
          <div className={classes.colorBar} style={{ backgroundColor: site.color }} />
          <div className={classes.contentContainer}>
            <div className={`${classes.column} ${classes.smallColumn}`}>
              <InfoItem 
                label="Distance" 
                value={`${Math.round(100 * site?.distance) / 100} mi`}
              />
              <InfoItem 
                label="Estimated Hours" 
                value={Math.round(100 * site?.estimatedHours) / 100} 
              />
             
            </div>
            <div className={`${classes.column} ${classes.largeColumn}`}>
              <InfoItem 
                label="Name" 
                value={site?.name} 
              />
              <InfoItem 
                label="Frequency" 
                value={`${site?.frequency} ${site?.frequency === 'Bi-Weekly' ? `(${site.evenOrOddWeeks})` : ''}`} 
              />
            </div>
            <div className={`${classes.column} ${classes.largeColumn}`}>
              <InfoItem 
                label="Preferred Day" 
                value={site.preferredDay} 
              />
              <InfoItem 
                label="Assigned Crew" 
                value={site.crewName} 
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

const WeeklyHoursDisplay = ({ weeklyHours, handleDayClick, selectedDay }) => {
  if (!weeklyHours) return null;
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #ccc', paddingTop: '10px', marginTop: '10px' }}>
      {days.map((day, index) => (
        <div key={day} 
          style={{
            textAlign: 'center', 
            flex: 1, 
            cursor: 'pointer',
            backgroundColor: fullDays[index] === selectedDay ? '#e0e0e0' : 'transparent',
            borderRadius: '4px',
            padding: '2px'
          }} 
          onClick={e => handleDayClick(fullDays[index])}>
          <div style={{ fontWeight: 'bold' }}>{day}</div>
          <div>{weeklyHours[fullDays[index]].hours.toFixed(1)}h</div>
         
        </div>
      ))}
    </div>
  );
};


const CalendarTabArea = () => {

  return (
    <div style={{ height: '30px', backgroundColor: 'blue', width: '100%'}}>
      <div>Day</div>
      <div>Week</div>
      <div>Month</div>
      <div>Quarter</div>
    </div>
  )
}

const ClosestLocationDetails = (props) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  // console.log('Closest Location Details', props)
  const longtermFrequencies = [
    'Monthly',
    'Quarterly',
    'Semi-Annually',
    'Annual'
  ]
  const {
    nearestSites = [],
    lastWorkorder,
  } = props?.jobSiteDetails
  if (!nearestSites?.length) return null

  const site = props.site

  let filteredSites = nearestSites.filter(item => {
    let includeThis = false
    if (!item.crew || item?.crewName === 'Unassigned') return false
    if (item._id !== site._id) {
      // console.log('Not our active marker...', item)
      if (!longtermFrequencies.includes(site.frequency)) {
        // Short term frequency - dont show our longterms
        includeThis = !longtermFrequencies.includes(item.frequency)
      } else {
        includeThis = true
      }
      if (site.frequency === item.frequency) includeThis = true
    }
    // if (includeThis) {
    //   let crew = props?.crews.find(crewItem => (crewItem._id === site.crew))
    //   if (crew?._id) site.crewName = `${crew.name} ${crew.foremanName ? `(${crew.foremanName})` : ''}`
    // }
    return includeThis
  }).slice(0, 10)
  
  let closestMatchSites = nearestSites.filter(item => {
    if (!item.crew || item?.crewName === 'Unassigned') return false
    let includeThis = false
    if (item._id !== site._id) {
      if (site.frequency === item.frequency) includeThis = true
    }
    return includeThis
  }).slice(0, 10)

  let perfectMatches = nearestSites.filter(item => {
    if (!item.crew || item?.crewName === 'Unassigned') return false
    let includeThis = false
    // console.log('Seek Perfect Matches', site)
    if (item._id !== site._id && item?.crewName !== 'Unassigned' ) {
      // console.log('This one might work', item)
      if (site.frequency === item.frequency) {
        // console.log('Frequency Match')
        if (site.preferredDay === item.preferredDay) {
          // console.log('Days Match')
          if (site.frequency === 'Bi-Weekly') {
            if (site.evenOrOddWeeks === item.evenOrOddWeeks) {
              // console.log('Even or odd matches')
              includeThis = true
            }
          } else {
            includeThis = true
          }
        }
      }
    }
    return includeThis
  }).slice(0, 10)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // console.log('Perfect Matches', perfectMatches)
  return (
    <div className={classes.tabsRoot}>
      <Paper square>
        <Tabs
          value={tabValue}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
          aria-label="closest sites tabs"
        >
          <Tab label="Closest Sites" />
          <Tab label="Closest Matching Sites" />
          <Tab label="Perfect Matches" />
        </Tabs>
      </Paper>
      <TabPanel value={tabValue} index={0}>
        <Typography variant="h6" gutterBottom>Nearest Locations:</Typography>
        <SiteList 
          nearestSites={filteredSites} 
          handleClickOnSiteNearby={props.handleClickOnSiteNearby} 
        />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Typography variant="h6" gutterBottom>Nearest Matching Locations:</Typography>
        <SiteList 
          nearestSites={closestMatchSites} 
          handleClickOnSiteNearby={props.handleClickOnSiteNearby} 
        />
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <Typography variant="h6" gutterBottom>Perfect Matching Locations:</Typography>
        <SiteList 
          nearestSites={perfectMatches} 
          handleClickOnSiteNearby={props.handleClickOnSiteNearby} 
        />
      </TabPanel>
    </div>
  )
}

const InfoItem = ({ label, value, centerItem }) => {
  const classes = useStyles();
  return (
    <div className={classes.infoItem}>
      <Typography className={classes.infoValue} style={ centerItem ? { textAlign: 'center' } : null }>{value}</Typography>
      <Typography className={classes.infoLabel}>{label}</Typography>
    </div>
  );
};

const SelectedCrewDetails = ({ crew, stops, date, activeMarker, jobSiteDetails, handleUnassignment, handleAssignToCrew, crews, markers }) => {
  const classes = useStyles()
  const [isAssignmentDialogOpen, setIsAssignmentDialogOpen] = useState(false);

  const openAssignmentDialog = () => {
    setIsAssignmentDialogOpen(true);
  };

  const closeAssignmentDialog = () => {
    setIsAssignmentDialogOpen(false);
  };

  const handleConfirmAssignment = (assignmentDetails) => {
    handleAssignToCrew(assignmentDetails);
  };

  console.log('Stops', stops)
  console.log('Date', date)
 
  let totaHours = 0
  let withThisJobHours = Number(activeMarker?.estimatedHours || 0)
  let withThisJobWeeklyHours = Number(activeMarker?.estimatedHours || 0)
  let crewStops = [...stops].filter(site => { 
    return (site.crew === crew._id)
  })
  console.log('Crew:', crew)
  // let filteredStopsW
  console.log('Crew Stops', crewStops)
  crewStops.forEach(stop => {
    console.log('Hours so far %s   add estimated hours %s ', withThisJobHours, stop.estimatedHours)
    withThisJobHours += Number(stop.estimatedHours || 0)
    totaHours += Number(stop?.estimatedHours || 0)
    if (jobSiteDetails?.nearestSites?.length) {
      let nearestSite = jobSiteDetails.nearestSites.find(site => (site._id === stop._id))
      if (nearestSite?._id) {
        console.log('959 -- ', nearestSite)
        if (nearestSite?._id) {
          stop.distance = nearestSite.distance
        }
      }
    }
  })
  console.log('Find Weekly Horus in cerws', crews)
  let crewIndex = crews.findIndex(item => (item._id === crew._id))
  if (crewIndex > -1) {
    let crewItem = crews[crewIndex]
    console.log('This is the item w/ weekly hours', crewItem)
    withThisJobWeeklyHours += Number(crewItem?.weeklyHours?.total)
  }
  console.log('Crew Details ', date)
  return (
    <div style={{ backgroundColor: '#fff', borderLeft: `14px solid ${crew.color}`, borderRadius: 8, overflow: 'hidden', padding: 5 }}>
      <div style={{ fontSize: '16px', }}>
      
        Working Hours: {Math.round(100 * totaHours) / 100} ({crewStops.length} stops)
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <div>{crew.name} {crew?.foremanName ? `(${crew.foremanName})` : ''} </div>
        <div>
          {
            activeMarker?._id && (
              <Button
                variant="contained"
                color="primary"
                onClick={openAssignmentDialog}
                >Assign</Button>
            )
          }
        
        </div>
      </div>
     
      <div className={classes.crewsStopsContainer}>
        {
          [...crewStops].map((site, index) => {
            return (
              <div key={index} style={{ width: '100%', margin: 5, padding: 5, display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', border: '1px solid #ccc' }}>
                <div>
                  <div>{site?.name}</div>
                  <div>{site.address}  -  {site?.estimatedHours}hrs  { site?.distance ? `(${site.distance}mi)` : '' }</div>
                </div>
                  <div>{site.frequency} { site?.frequency === 'Bi-Weekly' ? `${site.evenOrOddWeeks}` : '' } </div>
                <div>
                  <Button
                    onClick={() => handleUnassignment(site)}
                    >Un-Assign</Button>
                </div>
                
              </div>
            )
          })
        }
      
       
      </div>
      {/* <div style={{ fontSize: '16px', marginTop: 10 }}>
        
        Current Working Hours: {Math.round(100 * totaHours) / 100}   - Hours w/ This Job: {Math.round(100 * withThisJobHours) / 100}
      </div> */}
      <div style={{ marginTop: 20, height: '100px'}}>&nbsp;</div>
      <AssignmentConfirmationDialog
        open={isAssignmentDialogOpen}
        onClose={closeAssignmentDialog}
        crew={crew}
        site={activeMarker}
        selectedDate={date}
        onConfirm={handleConfirmAssignment}
        jobSiteDetails={jobSiteDetails}
        markers={markers}
      />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '80vh',
    marginTop: -20
    
  },
  tabsRoot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: theme.spacing(2),
  },
  paper: {
    height: '90%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  calendarPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: '#ccc',
    minHeight: '20vh',
    maxHeight: '30vh',
    marginBottom: 5
  },
  crewPaper: {
    // padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: '#eee',
    // maxHeight: '70vh',
    // overflowY: 'auto',
    paddingBottom: 80,
    marginBottom: 80,
    width: '100%'
  },
  unassignedJobsPaper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    backgroundColor: '#eee',
    maxHeight: '90vh',
    overflowY: 'auto'
  },
  mapContainer: {
    position: 'relative',
    height: '90%',
  },
  map: {
    width: '100%',
    height: '100%',
  },
  sidebarDetailsContainer: {
    // backgroundColor: 'pink',
    width: '100%',
    // height: '500px',
    // border: '2px solid blue'
  },
  selectedCrewContainer: {
    backgroundColor: '#eee',
    width: '100%',
    // height: '40%',
    // position: 'absolute',
    // bottom: 20,
    // left: 0,
    zIndex: 1,
    overflow: 'hidden',
    // padding: theme.spacing(2),
    // margin: 5,

    // marginBottom: 50,
    // boxSizing: 'border-box',
  },
  unassignedJobItem: {
    border: '2px solid #000',
    margin: 5,
    padding: 5,
    backgroundColor: '#fff',
    borderRadius: 12
  },
  activeJobItem: {
    border: '3px solid red',
    margin: 5,
    padding: 5,
    backgroundColor: '#ddd',
    borderRadius: 12
  },
  crewList: {
    backgroundColor: '#eee',
    margin: 3,
    padding: 5,
    width: '100%',
    maxHeight: '70vh',
    overflowY: 'auto',
  },
  crewDetailsTopLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  crewDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  crewBlockColor: {
    width: '80px',
    height: '80px',
    borderRadius: 5
  },
  dateArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'space-evenly'
  },
  mapOverlayActionArea: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    top: 30,
    left: 250,
    // width: '250px',
    // height: '80px',
    // backgroundColor: 'rgba(0, 0, 0, 0.09)',
    // border: '3px solid rgba(0, 0, 0, 0.09)',
    // borderRadius: 5,
    // margin: 5,
    // padding: 10
  },
  crewsStopsContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '500px',
    padding: 5,
    // margin: 3,
    width: '100%'
  },
  nearestSiteItemContainerOLD: {
    border: '1px solid #ddd',
    borderRadius: 5,
    marginBottom: 5,
    padding: 10,
    backgroundColor: '#fff',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  nearestSiteItemContainer: {
    display: 'flex',
    alignItems: 'stretch',
    marginBottom: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    overflow: 'hidden',
    cursor: 'pointer',
  },
  colorBar: {
    width: 8,
  },
  contentContainer: {
    flex: 1,
    display: 'flex',
    padding: theme.spacing(2),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  smallColumn: {
    flex: '0 0 20%',
    marginRight: theme.spacing(2),
  },
  largeColumn: {
    flex: 1,
  },
  infoItem: {
    marginBottom: theme.spacing(1),
  },
  infoValue: {
    fontSize: '1.1rem',
    fontWeight: 700,
  },
  infoLabel: {
    fontSize: '0.65rem',
    fontWeight: 400,
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
  root: {
    flexGrow: 1,
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
  },

  sidePanel: {
    width: '300px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  stopsList: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  jobDetails: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  availabilityChip: {
    margin: theme.spacing(0.5),
  },
  sortButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(2),
    flexWrap: 'wrap',
    '& > button': {
      margin: theme.spacing(1),
    },
  },
  sortButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
  },
}));

const distinctHexColors = [    
  "#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF", "#FFA500", 
  "#800080", "#008000", "#FFC0CB", "#A52A2A", "#808080", "#FFFFFF", "#FFD700", 
  "#4B0082", "#7FFF00", "#FF4500", "#1E90FF", "#8B4513"
];

export default RouteMapViewComponent
