import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import { currencyFormat } from '../../../services/common'
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  border: 4px solid #ddd;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: stretch;
  padding: 10px;
`;

const TabsChoice = styled.div`
  min-width: 150px;
  width: 25%;
  overflow: auto;
  height: 250px;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  height: 250px;
  align-self: stretch;
  overflow-y: scroll;
  background-color: #fff;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  }
}));

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const defaultValues = {
  attachmentType: '',
  dataOptions: [],
  lastUpdate: null
}

const AddAttachmentsModal = (props) => {
  // console.log('AddAttachmentsModal Modal:', props)
  const classes = useStyles();
  const [state, setState] = React.useState(defaultValues)
  const [dataOptions, setDataOptions] = React.useState([])
  const [checked, setChecked] = React.useState([]);
  const [attachhmentList, setAttachmentList] = React.useState([])
  const [searchValue, setSearchValue] = React.useState('')
  // const [filtered]
  const fetchDataForClient = async (type) => {
    const response = await axios.get(`${props.url}/api/customer/getDataThatCanBeEmailed?id=${props.clientId}&type=${type}`)
    if (response && response.data && response.data.length) {
      setDataOptions(response.data)
      // setState({...state, lastUpdate: new Date(), dataOptions: response.data })
    } else {
      // setState({...state, lastUpdate: new Date(), dataOptions: [] })
      setDataOptions([])
    }
  }
  
  React.useEffect(() => {
    if (props.attachments && props.attachments.length) {
      console.log('Set these ', props.attachments)
      setAttachmentList(props.attachments)
      setChecked(props.attachments)
    } else {
      setAttachmentList([])
      setChecked([])
    }
  }, [props.attachments])

  const handleSubmit = () => {
    console.log('Submit')
  }
  
  const handleClose = () => {
    props.closeModal()
  }

  const handleUpdate = e => {
    setState({...state, [e.target.id]: e.target.value })
  }

  const handleChooseType = e => {
    fetchDataForClient(e.target.value)
    setState({...state, attachmentType: e.target.value })
    
  }

  const handleToggle = (item) => () => {
    console.log('Toggle ', item)
    let desc = ''
    if (state.attachmentType === 'Estimate') {
      desc = `Estimate #${item.number}`
    }
    if (state.attachmentType === 'Upload') {
      desc = `Upload ${item.name}`
    }
    if (state.attachmentType === 'Invoice') {
      desc = `Invoice #${item.invoiceNumber}`
    }
    console.log(desc)
    let value = item._id
    console.log('Handle toggle ', value)
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];    
    if (currentIndex === -1) {
      newChecked.push(value);
      // Add this to our list of attachments
      let currentAttachments = [...attachhmentList]
      currentAttachments.push({
        _id: value,
        type: state.attachmentType,
        description: desc
      })
      // let itemIndex = dataOptions.findIndex(item => (item._id === value))

      // if (itemIndex > -1) {
      //   let data = dataOptions[itemIndex]
      //   currentAttachments.push({
      //     _id: value,
      //     type: state.attachmentType
      //   })
        setAttachmentList(currentAttachments)
     


    } else {
      newChecked.splice(currentIndex, 1);
      // Remove this from our list of attachments
    }
    
    setChecked(newChecked);
  };

  const handleAdd = () => {
    props.addTheAttachments(attachhmentList)
  }

  const handleSearch = (e) => {
    console.log('search ', e.target.value)
    setSearchValue(e.target.value.toLowerCase())
  }

  // function searchInObject(obj, query) {
  //     console.log('%s    - %s ', obj, query)
  //   // Base case: If the object is a string, check if it contains the query
  //   if (typeof obj === 'string') {
  //     return obj.toLowerCase().includes(query.toLowerCase());
  //   }
  
  //   // If the object is an array, search each item
  //   if (Array.isArray(obj)) {
  //     return obj.some(item => searchInObject(item, query));
  //   }
  
  //   // If the object is an object, search each property
  //   if (typeof obj === 'object' && obj !== null) {
  //     return Object.values(obj).some(value => searchInObject(value, query));
  //   }
  
  //   // If it's any other type, just return false (or convert to string and search if you prefer)
  //   return false;
  // }
  
  // function filterDataByQuery(data, query) {
  //   return data.filter(item => searchInObject(item, query));
  // }
  const isMatch = (value, query) => {
    if (typeof value === 'string') {
      return value.toLowerCase().includes(query.toLowerCase());
    } else if (typeof value === 'number' || value instanceof Date) {
      return value.toString().includes(query);
    }
    return false;
  };
  
  const searchInObject = (obj, query) => {
    return Object.values(obj).some(value => isMatch(value, query));
  };
  
  const filterDataByQuery = (data, query) => {
    return data.filter(item => searchInObject(item, query));
  };
  // console.log('search value:', searchValue)
  const filteredOptions = filterDataByQuery([...dataOptions], searchValue)
  // console.log('Filtered dataOptions', filteredOptions)

  return (
    <Dialog maxWidth={'lg'} fullWidth open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
   
      <DialogTitle id="form-dialog-title">Add Attachment(s)</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please Choose The Type of Attachment
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
           <TextField
              variant="outlined"
              id="attachmentType"
              label="Attachment Type"
              fullWidth
              select
              value={state.attachmentType}
              onChange={handleChooseType}
              // onBlur={handleVerifyEmail}
            >
              <MenuItem value={`Invoice`}>
                 Invoice
              </MenuItem>
              <MenuItem value={`Estimate`}>
                 Estimate
              </MenuItem>
              <MenuItem value={`Upload`}>
                 Upload
              </MenuItem>
            </TextField>
          </Grid>
          {
            state.attachmentType ? (
              <React.Fragment>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <LabelText>Select Which {state.attachmentType}(s) You'd Like to Attach</LabelText>
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                      name="search" 
                      value={ searchValue } 
                      onChange={ handleSearch } 
                      type="text"
                      label="Search"
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        )
                      }}
                  />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12} >
                  <List style={{ maxHeight: '400px', overflowY: 'scroll', border: '2px soid #ccc' }}>
                    { filteredOptions.map((item) => { 
                          // console.log('option to list:', item)
                          let description = ''
                          let createdClean = moment(item.created_at).format('MM/DD/YY hh:mma')
                          if (item && item.name) description += item.name
                          if (item.invoiceNumber) description += `Invoice #${item.invoiceNumber} - Amount: ${currencyFormat(item.amount)}  --- Balance: ${currencyFormat(item.balance)} --- ${createdClean}`
                          if (state.attachmentType === 'Estimate') {
                            description = `Estimate #${item.number} - ${item.name} Amount: ${currencyFormat(item.totalPrice)}  --- ${createdClean}`
                          }
                          if (state.attachmentType === 'Upload') {
                            description = `${item.name}  ${item.type} (${item.documentType}) ---Uploaded By ${item.created_by} ${createdClean}`
                          }
                        return (
                          <ListItem key={item._id} role={undefined} dense button onClick={handleToggle(item)}>
                            <ListItemText primary={description} />
                            <ListItemSecondaryAction>
                              <Checkbox
                                edge="end"
                                onChange={handleToggle(item)}
                                checked={checked.indexOf(item._id) !== -1}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        )})
                    }
          
                  </List>
                </Grid>
              </React.Fragment>
                ) : null
              }

          <Grid item lg={12} md={12} sm={12} xs={12} >
              # of Attachments {attachhmentList.length}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} >
              <DialogContentText>
              Each Of Your Attachments Will Be Added to the Email
            </DialogContentText>
          </Grid>
        </Grid>

      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          onClick={handleAdd}
          disabled={(!attachhmentList || attachhmentList.length < 1)}
        >
          Add
        </Button>
      </DialogActions>

   </Dialog>
  )
}


export default AddAttachmentsModal


