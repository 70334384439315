import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  }
}));

// function htmlToText(html){
//   // console.log('Strip html tags: ', html)
//   //remove code brakes and tabs
//   html = html.replace(/\n/g, "");
//   html = html.replace(/\t/g, "");

//   //keep html brakes and tabs
//   html = html.replace(/<table>/g, "");
//   html = html.replace(/<tr>/g, "");
//   html = html.replace(/<td>/g, "");
//   html = html.replace(/<\/td>/g, "\t");
//   html = html.replace(/<\/table>/g, "\n");
//   html = html.replace(/<\/tr>/g, "\n");
//   html = html.replace(/<\/p>/g, "\n");
//   html = html.replace(/<\/div>/g, "\n");
//   html = html.replace(/<div>/g, "");
//   html = html.replace(/<\/h>/g, "\n");
//   html = html.replace(/<br>/g, "\n"); 
//   html = html.replace(/<br( )*\/>/g, "\n");
//   // console.log('Stripped: ', html)
//   //parse html into text
//   // var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
//   // return dom.body.textContent;
//   return html;
// }

const ApplyDepositToJob = (props) => {
  // console.log('ApplyDepositToJob Job: ', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [jobNumber, setJobNumber] = React.useState(null)
  const [state, setState] = React.useState({
    jobName: '',
    billingAddress: {},
    jobSiteAddress: '',
    jobSite: {},
    salesRep: {},
    billingAddy: {},
    amount: 0
  })
  React.useEffect(() => {
    // console.log('Use Effect Apply to Job')
    setOpen(props.open)
    setState({...state, amount: props.deposit.balance})
  }, [props])

const handleClose = () => {
  // console.log('Closing New Job Modal')
  // setOpen(false)
  props.updateParent('Close')
}

const handleConfirm = (data) => {
  // console.log('CONFIRMED NEW JOB!!', data)
}
const handleSave = () => {
  let obj = {
    client: props.client,
    amount: state.amount,
    user: props.user,
    job: props.job,
    deposit: props.deposit
  }
  if (props.forInvoiceOnly) {
    props.forInvoiceOnly(obj)
  } else {
    setLoading(true)
    // console.log('handle save APPLY TO JOB', state)
    // console.log(props)

    axios({
      method: 'post',
      url:  `${props.url}/api/payment/applyDepositToJob`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Axios response to apply to job: ', response)
      setLoading(false)
      // setState({})
      // setJobNumber(response.data)
      if (response.data.deposit) {
        // props.jobDetailsResponse(response.data)
        setState({...state, amount: response.data.deposit.balance})
        if (response.data.balance > 0) {
          props.notification({
            type: 'success',
            title: 'Deposit Applied to Job, Should We Apply The Remaining Elsewhere?',
            message: 'Well Done!!'
          })
        } else {
          props.notification({
            type: 'success',
            title: 'Deposit Applied to Job and Balance Used Completely',
            message: 'Well Done!!'
          })
        }
        props.updateParent('DepositApplied', response.data)
        // setOpen(false)
      } else {
        // console.log('No fucking workorder')
        props.notification({
          type: 'warning',
          title: 'Uh Oh!',
          message: 'Something messed up creating this workorder...not sure why!'
        })
      }
      
    }).catch(err => {
      // console.log('Error saving workorder: ', err)
    })
  }

}

return (
  <div className={classes.root}>
    <Dialog open={open} fullWidth={true} onClose={handleClose} maxWidth='xs' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Apply Deposit To This Job</DialogTitle>
        <DialogContent >
          <DialogContentText>
            How Much Should We Apply?
          </DialogContentText>
          <TextField
            autoFocus
            className={classes.paddedStuff}
            margin="dense"
            id="DepositAmountToApply"
            label="Deposit Amount"
            type="text"
            onChange={(e) => (e.target.value > props.deposit.amount ? setState({...state, amount: props.deposit.amount}) : setState({...state, amount: e.target.value}))}
            value={state.amount}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading || !state.amount} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(ApplyDepositToJob))