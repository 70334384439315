import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { Button, CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper'
import axios from 'axios'
import OptionsList from './OptionsList'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const getDetails = {
  'objectives': {
    name: 'Objective',
    title: 'Proposal Objectives',
    formalName: 'Proposal Objectives'
  },
  'introductions': {
    name: 'Introducton',
    title: 'Proposal Introductons',
    formalName: 'Proposal Introductons'
  },
  'proposalNotes': {
    name: 'Proposal Note',
    title: 'Proposal Notes',
    formalName: 'Proposal Notes'
  },
  'disclosures': {
    name: 'Disclosure',
    title: 'Proposal Disclosures',
    formalName: 'Proposal Disclosures'
  },
  'finalParagraphs': {
    name: 'Final Paragraph',
    title: 'Proposal Final Paragraphs',
    formalName: 'Proposal Final Paragraphs'
  },
  'closings': {
    name: 'Closing',
    title: 'Proposal Closing',
    formalName: 'Proposal Closings'
  },
  'paymentSchedules': {
    name: 'Payment Schedule',
    title: 'Payment Schedule',
    formalName: 'Payment Schedule'
  }
}

const Options = (props) => {
  // console.log('Generic Options Component', props)
  const [list, setList] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0
  })

  let dataToUse = {
    name: 'option',
    title: 'Proposal Options',
    formalName: 'Option'
  }


  let optionType = null
  if (props.match && props.match.params) {
  // console.log('Props.match params', props.match.params)
      if (props.match.params.optionType) {
      // console.log('Type:' , props.match.params.optionType)
        optionType = props.match.params.optionType
        let specificDataToUse = getDetails[props.match.params.optionType]
        if (specificDataToUse) dataToUse = specificDataToUse
      }
  }

  // if (optionType) dataToUse = getDetails[optionType]
  React.useEffect(() => {
    let isMounted = true
    async function fetchOptions () {
      axios
      .get(
        `${props.url}/api/settings/getGenericOptions?type=${optionType}`
        )
      .then(({ data }) => {
      // console.log("Data", data)
        if (isMounted) setList(data)
      }).catch(function (err) {
      // console.log('Error getting stuff', err)
      });
    }
    if (optionType) fetchOptions()
    return () => {
      isMounted = false
    }
  }, [])

  const updateOptions = (optionToUpdate) => {
  // console.log('Update Option: ', optionToUpdate)
    let existingOptions = list
    // console.log('Update Objective: ', scope)
    // console.log('Got %d clients: ', clients.length)
    let index = existingOptions.findIndex(item =>  (item._id === optionToUpdate._id))
    if (index > -1) {
      // console.log('Svc already exists', index)
      // console.log(services[index])
      existingOptions[index] = optionToUpdate
      // console.log('Agai: ', services[index])
      // console.log(services)
      // setRows(services)
      // setFilteredRows(services)
      setList(existingOptions)
    } else {
      existingOptions.push(optionToUpdate)
      setList(existingOptions)
    }
    setState({...state, counter: state.counter++ })
  }

  const deleteFromDB = async (id) => {
    axios({
      method: 'post',
      url:  `${props.url}/api/settings/deleteOption?id=${id}`,
      data: {
        type: optionType,
        id: id
      }
    }).then(response => {
      // console.log('Axios response from deleting objective: ', response)
      if (response && response.data && response.data.deleted) {
        props.notification({
          title: 'Option Deleted',
          message: 'That Option Is History!',
          type: 'success'
        })
      }
    })
  }

  const handleDelete = (id) => {
    let existingOptions = list
    let index = existingOptions.findIndex(item =>  (item._id ===  id))
    if (index > -1) {
      existingOptions.splice(index, 1)
      setList(existingOptions)
      deleteFromDB(id)
    }
  }

  const handleBack= () => {
    props.history.push('/settings')
  }
  // console.log(dataToUse)
  return (
    <div style={{padding: '5px', margin: '10px'}}>
      <Grid container>
        <Grid item lg={6} xs={12}>
          <h1>{dataToUse.formalName || ''}</h1>
        </Grid>
        <Grid item lg={6} xs={12}>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Paper style={{ minHeight: '80vh' }}>
            <Button
              variant="outlined"
              onClick={handleBack}
              style={{margin: 8}}
              startIcon={<ArrowBackIcon />}>Back</Button>
            <Grid container spacing={4} style={{ padding: '10px' }}>
            
              <Grid item lg={12} xs={12}>
                <OptionsList list={list} type={optionType} dataToUse={dataToUse} updateOption={updateOptions} deleteOption={handleDelete} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      
  </div>
  )
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(Options)