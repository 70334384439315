import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { fade, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Paper,
  DialogContentText,
 }  from '@material-ui/core';
import DumpFeesComponent from './DumpFeeComponent'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    overflow: 'hidden'
  },
  textField: {
    margin: '10px'
  },
  paddedStuff: {
    padding: '15px'
  },
  dumpFeeContainer: {
    margin: '10px',
    padding: '20px',
    border: '2px solid #ddd',
    borderRadius: 8,
  },
  dumpFeeContainerTopRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  modalContainer: {
    width: '100%',
    height: '100vh', // Set the height to take the full view height
    margin: '20px',
    display: 'flex',
    flexDirection: 'row', // Ensures side by side layout
    overflow: 'hidden'
  },
  detailsAreaContainer: {
    height: '100%',
    backgroundColor: '#eee',
    // padding: theme.spacing(1),
    // margin: theme.spacing(1),
  },
  leftColumn: {
    flex: 0.7, // Takes 70% of the width
    height: '100%', // Full height to match the dialog
    overflow: 'auto', // Enables vertical scrolling
    marginRight: theme.spacing(2), // Adds spacing between columns
  },
  rightColumn: {
    flex: 0.3, // Takes 30% of the width
    height: '100%', // Full height to match the dialog
    overflowY: 'auto', // Allows scrolling
    padding: theme.spacing(2), // Adds internal spacing
  },
}))

const handleFocus = (event) => event.target.select();

const defaultStateVals = {
  photos: [],
  index: 0,
  hoursWorked: 0,
  totalLaborHours: 0,
  totalSupervisorHours: 0,
  date: '',
  crewSize: 3,
  requiresUpdate: false,
  dumpFees: [],
  mtcAgreement: ''
}
const ViewDailyLogDetails = (props) => {
  // console.log('Northern Pkwy data', props)
  const classes = useStyles();
  const [state, setState] = React.useState(defaultStateVals)
  
  React.useEffect(() => {
    let isMounted = true
    const fetchAttachments = async (item) => {
      let newState = {
        totalLaborHours: item?.totalLaborHours || 0,
        totalSupervisorHours: item?.totalSupervisorHours || 0,
        hoursWorked: item?.hoursWorked || 0,
        crewSize: item?.crewSize || 3,
        date: item?.date || '',
        dumpFees: item?.dumpFees || [],
        mtcAgreement: item?.mtcAgreement
      }
      // console.log('Fetch this', item)
      
      axios.get(`${props.url}/api/hmi/fetchDailyLogAttachments?dailyLogId=${item._id}`).then((response, status, data) => {
        if (response && response.data) {
          // console.log('got em', response.data)
          setState({...state, ...newState, photos: response.data })
        }
      })
    }
    if (props.item && props.item._id) fetchAttachments(props.item)
    return () => {
      isMounted = false
    }
  }, [props.item])

  const handleClose = () => {
    props.updateParent('close')
  }

  const handleChange = (type) => {
    let index = state.index
    let newIndex = 0
    // console.log('Change: %s -- %s ', type, index)
    if (type === 'next') newIndex = index - 0 + 1
    if (type ==='prev') newIndex = index - 1
    if (newIndex < 0) newIndex = state.photos.length - 1
    if (newIndex > state.photos.length - 1) newIndex = 0
    // console.log('New index', newIndex)
    setState({...state, index: newIndex })
  }

  const handleSave = () => {
    console.log('Save the changes', state)
    let cleanFees = state?.dumpFees?.filter(item => {
      if (item.tons && item.fee) {
        if (Number(item.fee) > 0 && Number(item.tons) > 0) return item
      }
    })
    console.log('Clean Fees', cleanFees)
    const obj = {
      _id: props.item?._id,
      data: {
        hoursWorked: state.hoursWorked,
        totalLaborHours: state.totalLaborHours,
        totalSupervisorHours: state.totalSupervisorHours,
        date: state.date,
        crewSize: state.crewSize,
        dumpFees: cleanFees,
        mtcAgreement: state?.mtcAgreement?._id || null
      }
    }
    axios.post(`${props.url}/api/hmi/updateDailyLogData`, obj).then((response, status, data) => {
      if (response && response.data) {
        console.log('SUCCESS', response.data)
        props.notification({
          type: 'success',
          title: 'Saved Changes',
          message: 'Those Changes Have Been Saved'
        })
        props.updateParent('update', response.data)
      }
    }).catch((err) => {
      props.notification({
        type: 'warning',
        title: 'Failed To Save Changes',
        message: 'There Was An Error Trying To Save Those Changes'
      })
    })
  }

  const handleChangeDetails = (e) => {
    // setHighlightedOption(null)
    let newState = {...state, [e.target.id]: e.target.value, requiresUpdate: true }
    console.log('New state', newState)
    if ((e.target.id === 'timeIn' || e.target.id === 'timeOut') && newState.timeIn && newState.timeOut) {
      // Update our hours worked
      // calculateHoursWorked(newState)
    } else if (e.target.id === 'hoursWorked') {
      // User overriding the hours worked
      // setIsHoursMismatched(true)
      setState(newState)
    } else {
      setState(newState)
    }
  }

  const handleUpdateDumpFees = (data) => {
    console.log('Update Dump Fees', data)
    setState({...state, dumpFees: data, requiresUpdate: true  })
  }

  return (
    <div className={classes.root}>
      <Dialog open={props.open} fullScreen={true} onClose={handleClose} maxWidth='lg' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">View Daily Log Details</DialogTitle>
          <DialogContent >
          <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
            <CloseIcon />
          </IconButton>
            <DialogContentText>
              Here are the Associated Images
            </DialogContentText>
            <Grid container className={classes.modalContainer}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
               Number of Photos #{state.photos.length}
              </Grid>
              <Grid item lg={9} md={8} sm={12} xs={12} className={classes.leftColumn}>

                {
                 (state.photos && state.photos.length > 0) ? <ViewUploadDetails photos={state.photos} index={state.index} handleChange={handleChange} /> : null
                }
              </Grid>
              <Grid item lg={3} md={4} sm={12} xs={12} className={classes.rightColumn}>
               <DetailVerificationArea item={props.item} state={state} handleChange={handleChangeDetails} handleSave={handleSave} ags={props.ags} handleUpdateDumpFees={handleUpdateDumpFees} />
              </Grid>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignSelf: 'stretch' }}>
                {
                  state.photos.map((photo, index) => {
                    let cleanURL = photo.url
                    if (!cleanURL.includes('https')) cleanURL = `https://${photo.url}`
                    return (
                        <img src={cleanURL} width={300} />
                    )
                  })
                }
                </div>
              </Grid> */}
            </Grid>
          </DialogContent>
          <DialogActions>
          
           </DialogActions>
        </Dialog>
      </div>
  )
}

const DetailVerificationArea = ({ item, state, handleChange, handleSave, handleUpdateDumpFees, ags }) => {
  const classes = useStyles();
  // console.log('Display the details here:', item)
  const updateAG = (e, newAg) => {
    // console.log('ADOT crew', newAg)
    const mockE = {
      target: {
        id: 'mtcAgreement',
        value: newAg
      }
    }
    handleChange(mockE)
  }
  return (
    <Grid container spacing={2} className={classes.detailsAreaContainer} >
       <Grid item lg={12} md={12} sm={12} xs={12}>
          Verify This Matches The Paper Log
       </Grid>
       <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="date"
            label="Date"
            type="date"
            fullWidth
            value={state.date}
            className={classes.textField}
            variant="standard"
            onChange={handleChange}
            // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
            InputLabelProps={{
              shrink: true,
            }}
          />
       </Grid>
       <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
            id="agreement"
            options={ags.filter(ag => (ag.customerType === 'ADOT'))}
            // onHighlightChange={handleHighlightChange}
            getOptionLabel={(option) => {
              // // console.log('Option:', option)
              return `${option.jobName} ${option.customerType}`
            }}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            // onBlur={clearHighlightedOption}
            // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
            onChange={updateAG}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {state.mtcAgreement}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} label="ADOT Crew" />}
          />


        </Grid>

       <Grid item lg={6} md={6} sm={6} xs={6}>
        <TextField
          id="hoursWorked"
          label="Hours Worked"
          type="number"
          fullWidth
          onFocus={handleFocus}
          value={state.hoursWorked}
          onChange={handleChange}
          className={classes.textField}
          variant="standard"
          // onChange={ e => setJobName(e.target.value) }
          // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
          InputLabelProps={{
            shrink: true,
          }}
          helperText="Hours of The Shift (Per Person)"
          
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <TextField
          id="crewSize"
          label="Crew Size"
          type="number"
          fullWidth
          onFocus={handleFocus}
          value={state.crewSize}
          onChange={handleChange}
          // className={isHoursMismatched ? classes.mismatchedHours : classes.textField}
          variant="standard"
          className={classes.textField}
          // onChange={ e => setJobName(e.target.value) }
          // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
          InputLabelProps={{
            shrink: true,
          }}
          helperText="# of People On the Crew"
          
        />
      </Grid>

      <Grid item lg={6} md={6} sm={6} xs={6}>
        <TextField
          id="totalLaborHours"
          label="Total Labor Hours"
          type="number"
          fullWidth
          onFocus={handleFocus}
          value={state.totalLaborHours}
          onChange={handleChange}
          className={classes.textField}
          variant="standard"
          // onChange={ e => setJobName(e.target.value) }
          // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
          InputLabelProps={{
            shrink: true,
          }}
          helperText="Total Labor Hours"
          
        />
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={6}>
        <TextField
          id="totalSupervisorHours"
          label="Total Supervisor Hours "
          type="number"
          fullWidth
          onFocus={handleFocus}
          value={state.totalSupervisorHours}
          onChange={handleChange}
          // className={isHoursMismatched ? classes.mismatchedHours : classes.textField}
          variant="standard"
          className={classes.textField}
          // onChange={ e => setJobName(e.target.value) }
          // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
          InputLabelProps={{
            shrink: true,
          }}
          helperText="Total Supervisor Hours"
          
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <DumpFeesComponent fees={state.dumpFees} updateParent={handleUpdateDumpFees} />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={handleSave}
          disabled={!state.requiresUpdate}
        >SAVE</Button>
      </Grid>
    </Grid>
  )
}

const ViewUploadDetails = ({ photos, index, handleChange}) => {
  const photo = photos[index]
  let cleanURL = photo.url
  if (!cleanURL.includes('https')) cleanURL = `https://${photo.url}`
  console.log('Photo:', photo)
  console.log('URL', cleanURL)
  return (
    <div style={{ padding: 10, margin: 10, width: '95%', height: 'auto', border: '2px solid #ccc', borderRadius: 5}}>
      {
        photos?.length > 1 ? (
          <div>
            Number: {index - 0 + 1}
          <Button onClick={() => handleChange('prev')}>Previous</Button>
             
          <Button onClick={() => handleChange('next')}>Next</Button>
          </div>
        ) : null
      }
        
        {
          (cleanURL && cleanURL.includes('spaces')) ? <img src={cleanURL} alt="Uploaded Content" 
          style={{ 
            height: 'auto', 
            maxWidth: '95%', 
            objectFit: 'cover', 
            display: 'block',
          }}  /> : null
        }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(ViewDailyLogDetails)