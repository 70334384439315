import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { CircularProgress, Grid, Button }  from '@material-ui/core';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import PhotoGallery from '../Common/Photos/PhotoGallery'

const WorkorderPhotos = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState({
    workorder: {},
    photos: []
  })
  const [showPhotoGallery, setShowPhotoGallery] = React.useState(false)

  async function fetchData(wo) {
  // console.log('Get Photos for Workorder')
    setLoading(true)
    const response = await axios.get(`${props.url}/api/jobs/getPhotosForWorkorder?_id=${wo._id}`)
  // console.log(response)
    setLoading(false)
    if (response.data && response.data.length > 0) {
    // console.log('Got WOS to Assign', response.data)
      let cleanPhotos = []
      response.data.map((item, index) => {
        let photo = {
          title: `Photo #${index}`,
          author: item.created_by,
          url: item.url,
          created_at: item.created_at
        }
        if (item.type.includes('jpg')) cleanPhotos.push(photo)
      })
    // console.log('Photos Cleaned UP:', cleanPhotos)
      setState({...state, workorder: wo, photos: cleanPhotos})
    } else {
    // console.log('Fucked up getting Workorder Photos')
    }
  }

  React.useEffect(() => {
  // console.log('Assing WOrkorders Init')
    if (props.workorder && props.workorder._id) {
      fetchData(props.workorder)
    } else {
      setState({...state, photos: [], workorder: {} })
    }

  }, [props.workorder])

  const openPhotoGallery = () => {
    setShowPhotoGallery(true)
  }

  const closePhotoGallery = () => {
    setShowPhotoGallery(false)
  }

  return (
    <div >
      <PhotoGallery open={showPhotoGallery} photos={state.photos} closeModal={closePhotoGallery} />
      { loading ? <CircularProgress style={{ textAlign: 'center', margin: '30px' }} color="primary" size={ 96 } /> : <PhotoButton num={state.photos.length} openModal={openPhotoGallery} /> }
    </div>
  )
}

const PhotoButton = ({ num, openModal }) => {
  let label = `${num > 0 ? num : 'No' } Photos To View`
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<PhotoLibraryIcon color="#eee" /> }
      disabled={num < 1}
      onClick={openModal}
      >{label}</Button>
  )
}

// const PhotoList = ({ photos }) => {
//   if (photos && photos.length) {
//     <div>
//       {photos.length} Photos To Show
//   </div>
//   } else {
//     return (
//       <div>
//         No Photos To Show
//       </div>
//     )
//   }
// }
const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    user: state.user
  }
}

export default connect(mapStateToProps)(WorkorderPhotos)