import React from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import moment from 'moment';

const icon = 'https://dflportal.com/pickupTruck.png'

const CusomMarker = (props) => {
    // console.log('Custom Marker: ', props)
    const [state, setState] = React.useState({
        showInfoWindow: false,
        stopHide: false,
        isActive: false
    })
    const timeoutRef = React.useRef()

    React.useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    React.useEffect(() => {
        if (props.activeMarker && props.activeMarker._id && props.activeMarker._id === props.jobsite._id) {
            setState({...state, isActive: true })
        }
    }, [props])
 
    
    const handleMouseOver = e => {
        setState(prevState => ({
            ...prevState,
            showInfoWindow: true
        }))
        // if (props.jobSite) props.updateActiveMarker(props.jobSite)
    };

const handleMouseExit = e => {
    // Clear any existing timeouts to ensure we don't have multiple timeouts running
    if (state.isActive) {
        // console.log('Dont close this one')
    } else {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    
        timeoutRef.current = setTimeout(() => {
            if (!state.stopHide) {
                setState(prevState => ({
                    ...prevState,
                    showInfoWindow: false
                }));
            }
        }, 2000);
    }
};


    const handleInfoWindowMouseOver = e => {
        setState(prevState => ({
            ...prevState,
            showInfoWindow: true,
            stopHide: true
        }));

    }
    const handleInfoWindowMouseExit = e => {
        // setState(prevState => ({
        //     ...prevState,
        //     stopHide: false
        // }));
        // call handleMouseExit to start the delay before hiding the InfoWindow
        handleMouseExit();
    };

    const handleClick = (e) => {
        // console.log('Click on this one!!', props)
        // console.log(e)
       
        setState(prevState => ({
            ...prevState,
            showInfoWindow: true,
            stopHide: true
        }));
        props.updateActiveMarker(props.jobsite)
    }
    const ourPos = props.position

    if (ourPos && ourPos.lat && ourPos.lng) {
        const lat = Number(ourPos.lat)
        const lng = Number(ourPos.lng)
        let theColor = props.color
        // if (props.jobsite && props.jobsite.assignedCrew)
        if (props.jobsite && props.jobsite.color) theColor = props.jobsite.color
        let scale = 6
        let strokeColor = 'black'
        // if (ourPos._id === activeMarker._id) {
        //     // console.log('This is our boy!!!', addy)
        //     theColor = '#fff'
        //     scale = 9
        //     strokeColor = 'red'
        // }
        //  { lat, lng } = addy
        // console.log('lat: ', lat)
        // console.log('lng: ', lng)
        // console.log('Marker Data', props.jobsite)
        let theIcon = {
            path: window.google.maps.SymbolPath.CIRCLE,
            // url: 
            scale: scale,
            fillColor: theColor,
            fillOpacity: 0.8,
            strokeWeight: 2,
            rotation: 0,
            strokeColor: strokeColor
        }
        let clientName = (props.jobsite.client && props.jobsite.client.displayName) ? props.jobsite.client.displayName : 'CLIENT'
        const clientId = props?.jobsite?.client?._id ? props.jobsite.client._id : ''
        // let thePath =  window.google.maps.SymbolPath.CIRCLE
        // if (props.activeMarker && props.activeMarker._id && props.activeMarker._id === props.jobsite._id) {
        //     console.log('Is Active!!', props.activeMarker)
        //     // thePath =  window.google.maps.SymbolPath.PinView
        //     // setState({...state, showInfoWindow: true })
        //     theIcon = undefined
        //     //  {
        //     //     scale: scale,
        //     //     fillColor: theColor,
        //     //     fillOpacity: 0.8,
        //     //     strokeWeight: 2,
        //     //     rotation: 0,
        //     //     strokeColor: strokeColor
        //     // }
        // }
        let noCrewAssigned = true
        if (props.jobsite && props.jobsite.assignedCrew && props.jobsite.assignedCrew._id) noCrewAssigned = false
        if (state.isActive && noCrewAssigned) theIcon = undefined
        const assignedCrew = props?.jobsite?.assignedCrew

        return (
            <Marker position={{ lat, lng }} icon={theIcon} 
                onClick={handleClick}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseExit}>
                {
                    (state.isActive || (state.showInfoWindow  && props.jobsite)) ? (
                        <InfoWindow>
                            <>
                                <h4>Client: 
                                    <a style={{ cursor: 'pointer' }} href={`/clients/${clientId}/Customer/${clientId}`} target="_blank">
                                        {clientName}
                                    </a>
                                </h4>
                                <h4>Site Name: {props.jobsite.name}</h4>
                                <h4>Address: {props.jobsite.address}</h4>
                                {
                                    assignedCrew ? <h4>Irrigation Crew: {assignedCrew.name} ({assignedCrew.foremanName})</h4> : null
                                }
                            </>
                        </InfoWindow>
                    ) : null}
               
            </Marker>
        );
    } else {
        return null
    }


}

export default React.memo(CusomMarker)