import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import axios from 'axios'
import clsx from 'clsx';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Button, CircularProgress }  from '@material-ui/core';
import ThumbDownAltIcon from '@material-ui/icons/ThumbDownAlt';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import LinearProgress from '@material-ui/core/LinearProgress';
import EditServiceVisitModal from './EditServiceVisitModal'

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#779ca4',
  },
}))(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  dangerAlert: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 8
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 8
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22
    },
    backgroundColor: 'red',
    borderRadius: 25,
    border: '1px solid #eee',
    color: '#fff',
    height: 50,
    padding: 10,
    textAlign: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden'
    // [theme.breakpoints.up('md')]: {
    //   width: '80%'
    // },
    // [theme.breakpoints.up('lg')]: {
    //   width: '60%'
    // }
  },
  warningAlert: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 8
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 8
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 12
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22
    },
    backgroundColor: 'orange',
    borderRadius: 25,
    border: '1px solid #eee',
    color: '#fff',
    height: 50,
    padding: 10,
    textAlign: 'center',
    verticalAlign: 'middle',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    
  },
  tableRow: {
    "&:hover": {
      backgroundColor: '#ddd'
    }
  }
}));

const defaultValues = {
  onSite: null,
  offSite: null,
  hoursWorked: 0,
  travelTime: 0,
  notes: '',
  servicesPerformed: [],
  workorder: {},
  verificationComplete: false
}

const AlertBar = (props) => {
  const classes = useStyles();
  let className = props.className
  // console.log(className)
  let html = <div className={classes[props.className]}>{props.message}</div>
  // console.log(html)
  return html
  // return (
    
  // )
}

const ShiftsList = (props) => {
  return (
    <Table style={{fontSize: 10, color: '#aaa', padding: 0}}>
      <TableHead>
        <TableRow>
          <TableCell>Employee</TableCell>
          <TableCell>Date</TableCell>
          <TableCell>Clock In</TableCell>
          <TableCell>Clock Out</TableCell>
          <TableCell>Hours Worked</TableCell>
          <TableCell>Hours Assigned</TableCell>
          <TableCell>Percent Assigned</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {
        props.shifts.map(shift => {
          // console.log('SHIFT to display ', shift)
          return (
            <TableRow key={shift._id} style={{fontSize: 10, color: '#aaa', padding: 0}}>
              <TableCell> {(shift.employee && shift.employee._id) ? `${shift.employee.firstName} ${shift.employee.lastName}` : null}</TableCell>
              <TableCell>{moment(shift.clockIn).format('ddd MM/DD/YYYY')}</TableCell>
              <TableCell>{moment(shift.clockIn).format('hh:mma')}</TableCell>
              <TableCell>{moment(shift.clockOut).format('hh:mma')}</TableCell>
              <TableCell>{shift.hoursWorked}</TableCell>
              <TableCell>{shift.hoursAccountedFor}</TableCell>
              <TableCell><BorderLinearProgress style={{width: '90%', marginTop: 8}} variant="determinate" value={shift.hoursWorkedVsClockedIn ? shift.hoursWorkedVsClockedIn : 0} /></TableCell>
            </TableRow>
          )
        })
      }
      </TableBody>
    </Table>
  )
}

const EmployeeInfo = ({ employees }) => {
  // console.log('Employees: ', employees)
  // return <><br />Test</>
  return (
    <div style={{ display: 'flex', flexDirection: 'column', fontSize: 9, color: '#bbb'}}>
        {
          employees.map(item => {
            return <span>{item.firstName} {item.lastName}</span>
          })
        }
    </div>
  )
}

// const ServiceVisitList = (props) => {
//   // console.log('Service Visits LIST: ', props)
//   return (
//     <React.Fragment>
//       Service Visits Assigned to Shift:
//       {
//         props.serviceVisits.map(shift => {
//           // console.log('SERVICE VISIT: ', shift)
//           return (
//             <>
//               <DialogContentText key={shift._id}>
//                 { `${shift.clientName}    -  ${shift.jobName} (${shift.jobNumber})     ${moment(shift.onSite).format('hh:mma')}  - ${moment(shift.offSite).format('hh:mma')}    Man Hours Worked: ${shift.hoursWorked} (${shift.travelTime} Mins. Travel)`}
                
//               </DialogContentText>
//               <div>
//                 {
//                   (shift.emps && shift.emps.length) ? <EmployeeInfo employees={shift.emps} /> : null
//                 }
//               </div>
//             </>
//           )
//         })
//       }
//     </React.Fragment>
//   )
// }
const ServiceVisitList = (props) => {
  // console.log('Service Visits LIST: ', props)
  const classes = useStyles();
  return (
    <Table style={{fontSize: 11, color: '#aaa', padding: 0, cursor: 'pointer'}}>
      <TableHead>
        <TableRow>
          <TableCell>Client</TableCell>
          <TableCell>Workorder Number</TableCell>
          <TableCell>Job Name (Number)</TableCell>
          <TableCell>Employees On Job</TableCell>
          <TableCell>On-Site</TableCell>
          <TableCell>Off-Site</TableCell>
          <TableCell>Hours Worked</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {
        props.serviceVisits.map(shift => {
          // console.log('SERVICE VISIT: ', shift)
          return (
            <TableRow className={classes.tableRow} key={shift._id} onDoubleClick={e => props.handleEditServiceVisit(shift)}>
              <TableCell>{shift.clientName}</TableCell>
              <TableCell>{shift.workorder.number}</TableCell>
              <TableCell>{shift.jobName} ({shift.jobNumber}) </TableCell>
              <TableCell> 
                {
                  (shift.emps && shift.emps.length) ? <EmployeeInfo employees={shift.emps} /> : null
                }
              </TableCell>
              <TableCell>{moment(shift.onSite).format('hh:mma')}</TableCell>
              <TableCell>{moment(shift.offSite).format('hh:mma')}</TableCell>
              <TableCell>{shift.hoursWorked}</TableCell>
            </TableRow>
          )
        })
      }
      </TableBody>
    </Table>

  )
}

const VerificationModal = (props) => {
  const classes = useStyles();
  return (
    <Dialog className={classes.paper} open={props.open} fullWidth={true} maxWidth='md' aria-labelledby="form-dialog-title">
      <DialogTitle>Finished With This Shift?</DialogTitle>
      <DialogContent style={{overflow: 'hidden', margin: 10}}>
        <DialogContentText>
          Are you finished accounting hours for this shift so we may remove it from your view?
        </DialogContentText>
        <Grid container spacing={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <Grid item lg={6} xs={6}>
            <Button onClick={e => props.verifyFinishedWithShift(true) } fullWidth color="primary" variant="contained" edge='end' alt="Yes" label="Yes" aria-label="Yes" startIcon={ <ThumbUpAltIcon />}>
                Yes
            </Button>
          </Grid>
          <Grid item lg={6} xs={6}>
            <Button onClick={e => props.verifyFinishedWithShift(false) } fullWidth variant="contained" color="secondary" edge='end' label="No" alt="No" aria-label="No" startIcon={<ThumbDownAltIcon />}>
              No
            </Button>
          </Grid>
        </Grid>
        {/* <div style={{display: 'flex', alignItems: 'center'}}>

            <IconButton fullWidth color="primary" variant="contained" edge='end' alt="Yes" aria-label="Yes">
              <ThumbUpAltIcon />
            </IconButton>
            <IconButton fullWidth style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="No" aria-label="No">
              <ThumbDownAltIcon />
            </IconButton>
           
        </div> */}
      </DialogContent>
    </Dialog>
  )
}
const ReconcileShiftToWorkorder = (props) => {
  // console.log('Reconcile Shifts:', props.shifts)
  const classes = useStyles();
  const [state, setState] = React.useState(defaultValues)
  const [client, setClient] = React.useState({})
  const [workorder, setWorkorder] = React.useState({})
  const [clientWorkorders, setClientWorkorders] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [serviceVisits, setServiceVisits] = React.useState([])
  const [totalHoursWorked, setTotalHoursWorked] = React.useState(0)
  const [hoursAccountedFor, setHoursAccountedFor] = React.useState(0)
  const [latestTimeAssigned, setLatestTimeAssigned] = React.useState(null)
  const [totalTravelTime, setTotalTravelTime] = React.useState(0)
  const [showVerificationModal, setShowVerificationModal] = React.useState(false)
  const [lastState, setLastState] = React.useState({
    onSite: null,
    offSite: null
  })
  const [activeServiceVisit, setActiveVisit] = React.useState({})
  const [showEditServiceVisitModal, setShowEditServiceVisitModal] = React.useState(false)
  const [shifts, setShifts] = React.useState([])
  // Add up hours accounted for by SHIFT vs by Service Visit
  React.useEffect(() => {
    // let latestTime = null
  // console.log('RECONCILE props.shifts CHANGE', props.shifts)
    if (props.shifts && props.shifts.length) {
      setOurTimes(props.shifts)
    }
  }, [props.shifts])

  // React.useEffect(() => {
  // // console.log('State has changed', props.state)
  //   if (props.state && props.state.shifts && props.state.shifts.length) {
  //     setShifts(props.state.shifts)
  //     setOurTimes(props.shifts)
  //   }
  // }, [props.state])

  React.useEffect(() => {
    // console.log('Client Changed - get workorders for ', client)
    let isMounted = true
  
    async function fetchWos(id) {
      // console.log('Get id:', id)
      setLoading(true)
      axios.get(`${props.url}/api/jobs/getClientWorkorders?id=${id}&open=true`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          // console.log('Res', response.data)
          if (isMounted) {
            setClientWorkorders(response.data)
            if (props.shifts && props.shifts.length) {
              let onSiteTime = moment(props.shifts[0]['clockIn'])
              if (onSiteTime) {
                
              } else {
                onSiteTime = moment(new Date()).subtract(8, 'hours')
              }
              let offSiteTime = moment(props.shifts[0]['clockOut'])
              if (offSiteTime) {
        
              } else {
                offSiteTime = moment(new Date())
              }
              let hoursWorked = Math.abs(onSiteTime - offSiteTime) / 36e5
              hoursWorked = parseFloat(hoursWorked).toFixed(2)
              // let hoursWorked = moment(onSiteTime).diff(offSiteTime).asMinutes()
              // console.log('Hours WOrked: ', hoursWorked)
              if (latestTimeAssigned) onSiteTime = moment(latestTimeAssigned)
              if (onSiteTime > offSiteTime) offSiteTime = moment(onSiteTime).add(30, 'minutes')
              setState({...state, onSite: onSiteTime, offSite: offSiteTime, hoursWorked: hoursWorked, travelTime: 0})
            }
          }
        }
      })
  
    }
    if (client && client._id) fetchWos(client._id)
    return () => {
      isMounted = false
    }
  }, [client])

  React.useEffect(() => {
  // console.log('SERVICE VISITS APPEARED IN OUR PROPS:', props.serviceVisits)
    if (props.serviceVisits && props.serviceVisits.length) setServiceVisits(props.serviceVisits)
      //     let hoursWorked = Math.abs(onSiteTime - offSiteTime) / 36e5
    //     hoursWorked = parseFloat(hoursWorked).toFixed(2)
    //     // let hoursWorked = moment(onSiteTime).diff(offSiteTime).asMinutes()
    //     // console.log('Hours WOrked: ', hoursWorked)
    //     let totHours = 0
    //     props.shifts.map(item => {
    //       // console.log(item.hoursWorked)
    //       // console.log('Total Hours', totHours)
    //       totHours += Math.abs(item.hoursWorked)
    //     })
    //     // console.log('TOTS:', totHours)
    //     let finalHours = parseFloat(totHours).toFixed(2)
    //     // console.log('Final Horus:', finalHours)
    //     setTotalHoursWorked(finalHours)
    //     setState({...state, onSite: onSiteTime, offSite: offSiteTime, hoursWorked: hoursWorked, travelTime: 0})
    //     // Deal with hours accounted for
    //     let newHoursAccountedFor = 0
    //     // console.log('Get our Hours Accounted for: ', newHoursAccountedFor)
    //     props.shifts.map(item => {
    //       if (item.hoursAccountedFor) newHoursAccountedFor = parseFloat(hoursAccountedFor - 0 + item.hoursAccountedFor)
    //     })
    //     // console.log('SET our Hours Accounted for: ', newHoursAccountedFor)
    //     setHoursAccountedFor(newHoursAccountedFor)
  }, [props.serviceVisits])

  const setOurTimes = (shifts) => {
    // console.log('Set our times!!', shifts)
    let onSiteTime = moment(shifts[0]['clockIn'], 'YYYY-MM-DDThh:mm:ss.000Z').local()
      
    if (onSiteTime) {
      
    } else {
      onSiteTime = moment(new Date(), 'YYYY-MM-DDThh:mm:ss.000Z').local().subtract(8, 'hours')
    }
    // latestTime = onSiteTime
    let offSiteTime = moment(shifts[0]['clockOut'], 'YYYY-MM-DDThh:mm:ss.000Z').local()// , // moment(shifts[0]['clockOut']) 
    if (offSiteTime) {

    } else {
      offSiteTime = moment(new Date(), 'YYYY-MM-DDThh:mm:ss.000Z').local()
    }
    let hoursWorked = Math.abs(onSiteTime - offSiteTime) / 36e5
    hoursWorked = parseFloat(hoursWorked).toFixed(2)
    // let hoursWorked = moment(onSiteTime).diff(offSiteTime).asMinutes()
    // console.log('Hours WOrked: ', hoursWorked)
    let totHours = 0
    shifts.map(item => {
      // console.log(item.hoursWorked)
      // console.log('Total Hours', totHours)
      totHours += Math.abs(item.hoursWorked)
    })
    // console.log('TOTS:', totHours)
    let finalHours = parseFloat(totHours).toFixed(2)
    // console.log('Final Horus:', finalHours)
    // console.log('SET ONSITE: ', onSiteTime)
    // console.log("SET OFFSITE", offSiteTime)
    setTotalHoursWorked(finalHours)
    setState({...state, onSite: onSiteTime, offSite: offSiteTime, hoursWorked: hoursWorked, travelTime: 0})
    // Deal with hours accounted for
    let newHoursAccountedFor = 0
    // console.log('Get our Hours Accounted for: ', newHoursAccountedFor)
    shifts.map(item => {
      if (item.hoursAccountedFor) newHoursAccountedFor = parseFloat(hoursAccountedFor - 0 + item.hoursAccountedFor)
    })
    // console.log('SET our Hours Accounted for: ', newHoursAccountedFor)
    setHoursAccountedFor(newHoursAccountedFor)
  }

  const handleTimeChange = (type, value) => {
    // console.log('Type: ', type)
    let isValid = moment(value, 'YYYY-MM-DDThh:mm').isValid()
    // console.log('Valid? ', isValid)
    // lastState.current.onSite = state.onSite
    // lastState.current.offSite = state.offSite
    let lastTimes = {
      onSite: moment(state.onSite, 'YYYY-MM-DDThh:mm').local(),
      offSite: moment(state.offSite, 'YYYY-MM-DDThh:mm').local(),
      hoursWorked: state.hoursWorked
    }
    // console.log('Set Last Times: ', lastTimes)
    setLastState(lastTimes)
    if (isValid) {
      // console.log('Value: ', value)
      let onSite = null
      let offSite = null
      if (type === 'onSite') {
        onSite =  moment(value, 'YYYY-MM-DDThh:mm').local()
        // offSite = moment(value, 'YYYY-MM-DDThh:mm').add(1, 'hours').local()
        if (state.offSite) {
          offSite = state.offSite // moment(state.offSite, 'YYYY-MM-DDThh:mm').local()
        } else {
          offSite = moment(value, 'YYYY-MM-DDThh:mm').add(1, 'hours').local()
        }
  
      }
      if (type === 'offSite') {
        onSite = moment(state.onSite, 'YYYY-MM-DDThh:mm').local()
        offSite = moment(value, 'YYYY-MM-DDThh:mm').local()
      }
      // console.log('onSite IN', onSite)
      // console.log('offSite out: ', offSite)
      let hoursWorked = Math.abs(onSite - offSite) / 36e5
      // console.log('Hours WOrked: ', hoursWorked)
      hoursWorked = parseFloat(hoursWorked).toFixed(2)
      // console.log(hoursWorked)
      // if (clockOut < clockIn && state.weWantThis) {
      //   if (window.confirm('Your Clock-Out Time Is Before the Clock-In Time...Should We Reverse Them?')) {
      //     // console.log('Reverse em!')
      //     setState({...state, clockIn: clockOut, clockOut: clockIn, hoursWorked: hoursWorked})
      //     props.notification({
      //       type: 'warning',
      //       title: `Uh Oh - That Doesn't Seem Right!`,
      //       message: 'Your Clock-In Time Was After The Clock-Out - So We Reversed Them!'
      //     })
      //   } else {
      //     setState({...state, clockIn: clockIn, clockOut: clockOut, hoursWorked: 0})
      //   }
      // } else {
        // if (offSite < onSite) {
        //   props.notification({
        //     type: 'warning',
        //     title: `Uh Oh - That Doesn't Seem Right!`,
        //     message: 'Your On-Site Time Was After The Clock-Out - Please Fix Them!'
        //   })
        // } else {
          setState({...state, onSite: onSite, offSite: offSite, hoursWorked: hoursWorked})
        // }
        
      // }
    }
  }

  const checkOurTimes = () => {
    // console.log('On-Site/Off-Site: ', state)
    // console.log('Last State: ', lastState)
    if (state.offSite < state.onSite) {
      props.notification({
        type: 'warning',
        title: `Uh Oh - That Doesn't Seem Right!`,
        message: 'Your On-Site Time Was After The Clock-Out - Please Fix Them!'
      })
      // console.log('Current OnSite: ', state.onSite)
      // console.log('Set OnSite: ', lastState.onSite)
      // console.log('Current OffSite', state.offSite)
      // console.log('Set OffSite: ', lastState.offSite)
      setState({...state, offSite: lastState.offSite, onSite: lastState.onSite, hoursWorked: lastState.hoursWorked })
    } 
  }

  const handleFocus = (event) => event.target.select();

  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      // console.log(e.target)
      if (e.target.id === 'customername') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          setClient(newArr[0])
        }
      }
      if (e.target.id === 'workorder') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.clients.filter(option => {
          let str = `${option.serviceName ? option.serviceName : ''} - ${option.jobName ? option.jobName : '' } - ${option.jobNumber ? option.jobNumber : ''}`.toString().toLowerCase()
          // o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(option)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          setWorkorder(newArr[0])
        }
      }
    }
  }

  const updateCustomer = (e, customer) => {
    if (customer && customer._id) {
      setWorkorder({})
      setClient(customer)
      setClientWorkorders([])
    } else {
      setClient({})
      setClientWorkorders([])
      setWorkorder({})
    }
  }

  // const fetchServiceVisits = async (id) => {
  //   setLoading(true)
  //   axios.get(`${props.url}/api/jobs/getAssociatedServiceVisits?id=${id}`).then((response, status, data) => {
  //     setLoading(false)
  //     if (response && response.data) {
  //       // console.log('Service Visits: ', response.data)
  //       setServiceVisits(response.data)
  //     }
  //   })
  // }

  const updateWorkorder = (e, wo) => {
    // console.log('update workorder:', wo)
    if (wo && wo._id) {
      setWorkorder(wo)
      // fetchServiceVisits(wo._id)
      setState({...state, workorder: wo })
    }
  }

  const handleSubmit = () => {
    
    // let visits = serviceVisits
    // console.log('WO: ', workorder)
    // console.log('CLIENT: ', client)
    let line = {
      onSite: state.onSite,
      offSite: state.offSite,
      jobName: workorder.jobName,
      jobNumber: workorder.jobNumber,
      clientName: client.displayName,
      client: client._id,
      workorder: workorder._id,
      jobSite: workorder.jobSite,
      entity: workorder.entity,
      job: workorder.job,
      // shifts: props.shifts,
      hoursWorked: state.hoursWorked,
      totalManHours: state.hoursWorked * props.shifts.length,
      travelTime: state.travelTime
    }
    

   // Take this out when we save each service visit one at a time
    // visits.unshift(line)
    // console.log('Updated Visits', visits)
    // setServiceVisits(visits)
    setClient({})
    setWorkorder({})
    // setLatestTimeAssigned(null)
    setHoursAccountedFor(0)
    setTotalTravelTime(0)
    setState(defaultValues)
    props.saveServiceVisit(line)
    // setShowVerificationModal(true)
    // // console.log('Hours WOrked: ', state.hoursWorked)
    // // console.log('Hous Accounted For:', hoursAccountedFor)
    // // console.log('Add these: ', line.hoursWorkedInTotal)
    // let totalHoursAccounted = hoursAccountedFor
    // let currentTravelTime = totalTravelTime 
    // currentTravelTime += parseInt(state.travelTime)
    // setTotalTravelTime(currentTravelTime)
    // // console.log('Current total hours Accounted: ', )
    // totalHoursAccounted += Math.abs(line.totalManHours)
    // // console.log('New Total Hours Accounted for: ', totalHoursAccounted)
    // if (totalHoursAccounted > totalHoursWorked) {
    //   let tooMuch = parseFloat(Math.abs(totalHoursWorked - totalHoursAccounted)).toFixed(2)
    //   if (window.confirm(`Are you sure you wish to assign this? You would have accounted for ${totalHoursAccounted} hours, which is ${tooMuch} too much.`)) {
    //     // VERIFY WE ARE GOING OVER
    //     setHoursAccountedFor(totalHoursAccounted)
    //     setClient({})
    //     setWorkorder({})
    //     let latestTime = state.onSite
    //     if (state.offSite && state.offSite > state.onSite) latestTime = state.offSite
    //     // Add travel time
    //     let halfOfTravelTime = parseInt(state.travelTime * 0.5)
    //     let timePlusTravel = moment(latestTime).add(halfOfTravelTime, 'minutes')
    //     setLatestTimeAssigned(timePlusTravel)
    //     // props.createServiceVisit(line)
    //     setState({...state, defaultValues })
    //   }

    // } else {
    //   // NORMAL METHOD
    //   setHoursAccountedFor(totalHoursAccounted)
    //   setClient({})
    //   setWorkorder({})
    //   let latestTime = state.onSite
    //   if (state.offSite && state.offSite > state.onSite) latestTime = state.offSite
    //   // Add travel time
    //   let halfOfTravelTime = parseInt(state.travelTime * 0.5)
    //   let timePlusTravel = moment(latestTime).add(halfOfTravelTime, 'minutes')
    //   setLatestTimeAssigned(timePlusTravel)
    //   // props.createServiceVisit(line)
    //   setState({...state, defaultValues })
    // }
    // updateOurShifts(line)
  }

  const updateOurShifts = (line) => {
    // Add this to our shift totals
    // console.log('Add this to shift totals:', line)
    let updatedShifts = props.shifts
    updatedShifts.map(shift => {
      let hoursWorked = Number(shift.hoursWorked)
      let travelTime = Number(shift.travelTime)
      let hoursAccountedFor = Number(shift.hoursAccountedFor)
      // console.log('Hours Worked %d --- travel time:  %d   hoursAccounted for: %d', hoursWorked, travelTime, hoursAccountedFor)
      hoursWorked = parseFloat(hoursWorked - 0 + Number(line.hoursWorked)).toFixed(2)
      travelTime = parseInt(travelTime - 0 + Number(line.travelTime))
      hoursAccountedFor = parseFloat(hoursAccountedFor - 0 + Number(line.hoursWorked)).toFixed(2)
      // console.log('POST Hours Worked %d --- travel time:  %d   hoursAccounted for: %d', hoursWorked, travelTime, hoursAccountedFor)
      shift.hoursWorked = hoursWorked
      shift.travelTime = travelTime
      shift.hoursAccountedFor = hoursAccountedFor
    })
    // console.log('Update our shifts here...')
    props.updateShifts(updatedShifts)
  }
  const handleCreateServiceVisits = async (isFinished) => {
    // console.log('Create Service Visits', serviceVisits)

    setClient({})
    setWorkorder({})
    setLatestTimeAssigned(null)
    setHoursAccountedFor(0)
    setTotalTravelTime(0)
    // props.saveServiceVisits(serviceVisits, isFinished)
    setServiceVisits([])
    setState(defaultValues)
  }

  const handleClose = () => {
    setShowVerificationModal(true)
    // if (serviceVisits && serviceVisits.length) {
    //   if (window.confirm(`Are You Sure You Wish To Close This Modal Without Saving These Service Visits?`)) {
    //     setClient({})
    //     setWorkorder({})
    //     setLatestTimeAssigned(null)
    //     setHoursAccountedFor(0)
    //     setTotalTravelTime(0)
    //     setServiceVisits([])
    //     setState(defaultValues)
    //     props.closeModal()
    //   }
    // } else {
    // setClient({})
    // setWorkorder({})
    // setLatestTimeAssigned(null)
    // setHoursAccountedFor(0)
    // setTotalTravelTime(0)
    // setServiceVisits([])
    // setState(defaultValues)
    // props.closeModal()
    // }
  }

  const verifyLaborTracked = () => {
    setShowVerificationModal(true)
  }

  const verifyFinishedWithShift = async (isFinished) => {
    // console.log('Verification Complete: ', isFinished)
    // setState({...state, verificationComplete: isFinished })
    setShowVerificationModal(false)
    if (isFinished) {
      setClient({})
      setWorkorder({})
      setLatestTimeAssigned(null)
      setHoursAccountedFor(0)
      setTotalTravelTime(0)
      setServiceVisits([])
      setState(defaultValues)
      props.finishedWithShifts(props.shifts)
    } else {
      setClient({})
      setWorkorder({})
      setLatestTimeAssigned(null)
      setHoursAccountedFor(0)
      setTotalTravelTime(0)
      setServiceVisits([])
      setState(defaultValues)
      props.closeModal()
    }
    // handleCreateServiceVisits(isFinished)
    // setClient({})
    // setWorkorder({})
    // setLatestTimeAssigned(null)
    // setHoursAccountedFor(0)
    // setTotalTravelTime(0)
    // setServiceVisits([])
    // setState(defaultValues)
    // props.closeModal()
  }

  const handleEditServiceVisit = (visit) => {
    // console.log('Edit Service Visit:', visit)
    setActiveVisit(visit)
    setShowEditServiceVisitModal(true)

  }

  const updateServiceVisit = (data, isDelete) => {
    let visit = data.serviceVisit
    let updatedShifts = data.shifts
    // console.log('Update service visits....', data)
    let visits = serviceVisits
    let index = visits.findIndex(item => (item._id === visit._id))
    if (index > -1) {
      if (isDelete) {
        visits.splice(index, 1)
      } else {
        visits[index] = visit
      }
      setServiceVisits(visits)
    }
    // setOurTimes(props.shifts)
    setShowEditServiceVisitModal(false)
    props.updateShifts(updatedShifts)
  }

  const hoursLeftToAssign = parseFloat(totalHoursWorked - hoursAccountedFor - parseFloat(totalTravelTime / 60).toFixed(2)).toFixed(2)
  const percentAssigned = parseInt((hoursAccountedFor / totalHoursWorked) * 100)
  return (
    <Dialog className={classes.paper} open={props.open} fullWidth={true} maxWidth='md' onClose={handleClose} aria-labelledby="form-dialog-title">
      <VerificationModal open={showVerificationModal} verifyFinishedWithShift={verifyFinishedWithShift} />
      <EditServiceVisitModal open={showEditServiceVisitModal} serviceVisit={activeServiceVisit} updateParent={updateServiceVisit} closeModal={e => setShowEditServiceVisitModal(false)}/>
      {/* <SuppliesUsedModal open={showSuppliesUsedModal} client={client} crew={crew} supplies={props.supplies} closeModal={e => setShowSuppliesUsedModal(false)} /> */}
        <DialogTitle id="form-dialog-title">
          <Grid container>
            <Grid lg={6} xs={6}>
                Reconcile Labor to Workorder
            </Grid>
            <Grid lg={6} xs={6}>
                {/* <BorderLinearProgress style={{width: '200px', marginTop: 15}} variant="determinate" value={percentAssigned ? percentAssigned : 0} /> */}
                <div style={{ position: "relative" }}>
                <LinearProgress
                  variant="determinate"
                  style={{height: '40px', borderRadius: '15px'}}
                  value={100 * (percentAssigned / 100)}
                  // {...props}
                />
                <Typography
                  style={{
                    position: "absolute",
                    color: "#fff",
                    top: 0,
                    left: "5%",
                    // transform: "translateX(-50%)",
                    fontSize: '.6em'
                  }}
                >
                  {hoursAccountedFor} Hours Accounted For
                </Typography>
                <Typography
                  style={{
                    position: "absolute",
                    color: "#fff",
                    top: 0,
                    left: "75%",
                    // transform: "translateX(-50%)",
                    fontSize: '.6em'
                  }}
                >
                  {totalHoursWorked} Hours Worked
                </Typography>
              </div>
            </Grid>
          </Grid>
          { hoursLeftToAssign <= 0 ? <AlertBar className="warningAlert" message={`You Have Assigned ${parseFloat(hoursAccountedFor).toFixed(2)} out of ${totalHoursWorked} Already.`} /> : null }
          
        </DialogTitle>
        <DialogContent >
          <DialogContentText>
            {props.shifts.length > 1 ? `Reconcile These ${props.shifts.length} Shifts To Workorder(s)    (${totalHoursWorked} Total Hours)` : `Reconcile This Shift To a Workorder    (${totalHoursWorked} Total Hours)`} 
          </DialogContentText>
          <DialogContentText>
            Total Hours Assigned: {parseFloat(hoursAccountedFor).toFixed(2)} ({totalTravelTime} Mins. of Travel Time)
            {
              hoursLeftToAssign < totalHoursWorked * 0.25 ? <span style={{color: 'red'}}> ({parseFloat(hoursLeftToAssign).toFixed(2)} Hours Left To Assign) </span> : <span> ({parseFloat(hoursLeftToAssign).toFixed(2)} Hours Left To Assign) </span>
            }
          </DialogContentText>
          
          <DialogContentText>
            <ShiftsList shifts={props.shifts} /> 
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item lg={12} xs={12}>
              <Autocomplete
                  id={`customername`}
                  options={props.clients || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.displayName || ''
                  }}
                  // style={{ width: 200 }}
                  style={{marginBottom: '20px'}}
                  onChange={updateCustomer}
                  // disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {client}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Customer" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
               {
                 (client && client._id) ? (
                    <Autocomplete
                    id={`workorder`}
                    options={clientWorkorders || []}
                    getOptionLabel={(option) => {
                      // console.log('Options: ', option)
                      let retValue = ''
                      if (option && option.number) retValue = `WO #${option.number} -- ${option.serviceName ? option.serviceName : ''} - ${option.jobName ? option.jobName : '' } - ${option.jobNumber ? option.jobNumber : ''} Scheduled: ${moment(option.scheduledDate).format('MM/DD/YYYY')}`
                      return retValue 
                    }}
                    // style={{ width: 200 }}
                    style={{marginBottom: '20px'}}
                    onChange={updateWorkorder}
                    disabled={loading}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    value = {workorder}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="Workorder" onKeyDown={handleTabInteraction} variant="outlined" />}
                  />
                 ) : null
               } 
              </Grid> 
            <Grid item xs={12} lg={6}>
            <TextField
              id="date"
              label="On-Site"
              type="datetime-local"
              value={moment(state.onSite).format('YYYY-MM-DDTHH:mm') } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              onBlur={checkOurTimes}
              onChange={e => handleTimeChange('onSite', e.target.value)}
              // onChange={e => setState({...state, clockIn: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />
            </Grid> 
            <Grid item xs={12} lg={6}>
           <TextField
              id="date"
              label="Off-Site"
              type="datetime-local"
              fullWidth
              onBlur={checkOurTimes}
              value={moment(state.offSite).format('YYYY-MM-DDTHH:mm') } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              // onChange={e => setState({...state, clockOut: e.target.value })}
              onChange={e => handleTimeChange('offSite', e.target.value)}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              style={{marginBottom: '20px'}}
            />
            </Grid>
            <Grid item xs={12} lg={6}>
            <TextField
              id="hoursWorked"
              label="Hours Worked"
              type="text"
              disabled
              value={state.hoursWorked } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              // onChange={e => setState({...state, hoursWorked: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />
            </Grid>
            <Grid item xs={12} lg={6}>
            <TextField
              id="hoursWorked"
              label="Travel Time (Minutes)"
              type="text"
              value={state.travelTime } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              onFocus={handleFocus}
              onChange={e => setState({...state, travelTime: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />
             </Grid>    
             <Grid item xs={12} lg={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={loading || !workorder._id || !state.onSite || !state.offSite}
                  >Save</Button>
               </Grid>  
               <Grid item xs={12} lg={12}>
                  <ServiceVisitList serviceVisits={serviceVisits} handleEditServiceVisit={handleEditServiceVisit} />
                </Grid>          
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
          {/* <Button onClick={verifyLaborTracked} color="primary">
            Save Service Visits
          </Button> */}
        </DialogActions>
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(ReconcileShiftToWorkorder))