import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import CategorySelections from './CategorySelections'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  gridItem: {
    width: '800px'
  }
}));

const ScopeDetailsModal = (props) => {
  // console.log('Got our ServiceDetailsModal modal', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
const [open, setOpen] = React.useState(false);
const categories = [
  'Monthly Maintenance', 'Landscapes', 'Specialty', 'Clean-Ups', 'Irrigation', 'Tree Trimming', 'Lighting', 'Pest Control', 'Water Feature', 'Flowers', 'Other'
]
const [statementName, setStatementName] = React.useState('Nov2020')
const [state, setState] = React.useState({
  name: '',
  description: '',
  Scope: '',
  serviceCode: ''
});
const [scopeCategories, setScopeCategories] = React.useState([])

  React.useEffect(() => {
    // console.log('Use effect Service Details', props)
    let service = props.service
    if (service && service._id) {
      if (service._id === 'new') {
        setState({...state,
          name: '',
          Scope: '',
          description: '',
          serviceCode: '',
          notes: '',
          markupPct: 40
        })
      } else {
        setState({...state,
          name: service.name,
          Scope: service.Scope || 0,
          description: service.description,
          serviceCode: service.serviceCode,
          notes: service.notes,
          markupPct: 40
        })
      }
      if (service && service.categories && service.categories.length) {
        setScopeCategories(service.categories)
      } else {
        setScopeCategories([])
      }
    }
  }, [props.service]);
// const handleChange = (event) => {
//   setRange(event.target.value);
//   let newStartDate = moment().subtract(event.target.value, 'days')
// // console.log('New start Date', newStartDate)
//   setStartDate(newStartDate)
//   setOpen(false);
// };

// const handleClose = () => {
//   setOpen(false);
//   // props.onUpdate()
// }

// const handleOpen = () => {
//   setOpen(true);
// };
const handleDeleteService = () => {
  // Delete Service
  props.updateParent('delete', props.service._id)
}

const handleSubmit = () => {
// console.log('Update this scope!', state)
  setLoading(true)
// console.log('handle save', state)
// console.log(props)
  let obj = {
    details: state,
    scope: props.service,
    categories: scopeCategories
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/settings/updatescope`,
    data: obj,
    // validateStatus: (status) => {
    // // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
  // console.log('Axios response to update scope ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response && response.data && response.data._id) {
      // props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'Saved Changes to that Service!',
        message: 'Well Done!!'
      })
      // setState({...state,
      //   name: response.data.name,
      //   Scope: response.data.Scope || 0,
      //   description: response.data.description
      // })
      // handleCloseModal()
      props.updateParent('update', response.data)
      // setOpen(false)
    } else if (response && response.data && response.data.err) {
    // console.log('Got an error code: ', response.data.err)
      let err = response.data.err
      props.notification({
        type: 'danger',
        title: 'Uh Oh! Houston, We Have a Problem...Error Code ' + err.code,
        message: err.errmsg
      })
    } else {
    // console.log('No fucking reponse.data???')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up creating this service...not sure why!'
      })
    }
  }).catch(err => {
  // console.log('Error saving Service: ', err)
    props.notification({
      type: 'danger',
      title: 'Uh Oh! Houston, We Have a Problem...',
      message: err.message
    })
  })
}

const handleCloseModal = () => {
  props.updateParent('closeModal')
}

const setServiceName = (name) => {
  setState({...state, name: name})
}

const handleSaveNewCategory = (name) => {
// console.log('New category', name)
  let list = scopeCategories
  const item = {
    name: name,
    subCategories: [],
    _id: `${name}_${Date.now()}`
  }
  list.push(item)
  setScopeCategories(list)
  // let obj = {
  //   category: item,
  //   scope: props.service
  // }
  // axios({
  //   method: 'post',
  //   url:  `${props.url}/api/settings/addscopecategory`,
  //   data: obj,
  //   // validateStatus: (status) => {
  //   // // console.log('Validate status: ', status)
  //   //   if (status && status === 500) {

  //   //   }
  //   // },
  // }).then(response => {
  // // console.log('Axios response to update scope ', response)
  //   setLoading(false)
}

const handleUpdateCategory = (data) => {
// console.log('Update category', data)
  // name = data.name
  // subcategories = data.subCategories
  let editedItemId = data.category._id
  let list = scopeCategories
  let index = list.findIndex(item => (item._id === editedItemId))
  if (index > -1) {
    let editedItem = list[index]
  // console.log('Replace this: ', list[index])
    editedItem.name = data.name
    editedItem.subCategories = data.subCategories
  // console.log('With this: ', editedItem)
  }
// console.log('New LIst of categoires::', list)
  setScopeCategories(list)
}

  return (
    <div>
        <Dialog
          // fullScreen={false}
          maxWidth={'lg'}
          width='lg'
          // maxwidth='lg'
          // style={{width: 700}}
          open={props.open}
          // scroll='paper'
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          >
                {/* <Dialog
        maxWidth='lg'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      > */}
          <DialogTitle id="responsive-dialog-title">Scope Details</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} className={classes.gridItem}>
            <Grid item lg={10} xs={10}></Grid>
            <Grid item lg={2} xs={2}>
              <Button
                variant="contained"
                style={{backgroundColor: '#ed4545'}}
                className={classes.button}
                size="small"
                startIcon={<DeleteIcon />}
                onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteService() } }
                // onClick={handleDeleteService}
                // onClick={offerDeleteOption}
                // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
              >
                Delete
              </Button> 
            </Grid>
             <Grid item lg={12} xs={12}>
                <TextField
                  id="Scope Name"
                  label="Scope Name"
                  type="text"
                  fullWidth
                  value={state.name}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setServiceName(e.target.value) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <TextField
                  id="Scope Description"
                  label="Scope Description"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  value={state.description}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setState({...state, description: e.target.value}) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <TextField
                  id="Scope Notes"
                  label="Scope Notes"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  value={state.notes}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setState({...state, notes: e.target.value}) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <TextField
                  id="Scope Markup Percentage"
                  label="Scope Percentage"
                  type="text"
                  fullWidth
                  value={state.markupPct}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setState({...state, markupPct: e.target.value}) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <CategorySelections list={scopeCategories} saveCategory={handleSaveNewCategory} updateCategory={handleUpdateCategory} />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit(4)} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
    </div>
  )
 

}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ScopeDetailsModal)