import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SignaturePad from 'react-signature-canvas';
import Paper from '@material-ui/core/Paper';
// import PaymentOptions from './PaymentMethodOptionsRadioButtons'
import PaymentManager from './PaymentManager';

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  border: 4px solid #ddd;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: stretch;
  padding: 10px;
`;

const TabsChoice = styled.div`
  min-width: 150px;
  width: 25%;
  overflow: auto;
  height: 250px;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  height: 250px;
  align-self: stretch;
  overflow-y: scroll;
  background-color: #fff;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

const ContainerArea = styled.div`
  margin: 2em;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{width: '100%'}}>
          <Typography  component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    width: '100%',
    backgroundColor: '#ddd',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center', // Ensure content is centered
  },
  container: {
    margin: 0,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'center',
  },
  headerArea: {
    backgroundColor: '#accacd',
    // height: '50px',
    width: '100%',
  },
  headerAreaContent: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  headerContentItem: {
    margin: 0,
    padding: 0,
    display: 'inline',
    textAlign: 'center'
  },
  headerContentItemLabel: {
    fontSize: '1.2em',
    fontWeight: '600',
    color: 'hsl(180, 18%, 39%)',
  },
  contentArea: {
    width: '100%',
    // maxWidth: '960px',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'center',  
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
    // height: '100%',
    padding: 20,
    minHeight: '100vh'
  },
  estimateOutline: {
    flexGrow: 3,
    width: '100%',
    minHeight: '960px',
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
    padding: 20,
    [theme.breakpoints.up('sm')]: {
      // width: '66.66%', // 2/3 of the content area, ensures some space
      marginRight: theme.spacing(2), // Margin for spacing
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '80vh',
      maxWidth: '990px',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '90vh',
      // maxWidth: '1950px',
      maxWidth: '990px',
    },
    // maxWidth: '1950px',
    maxWidth: '990px',
  },
  actionArea: {
    minHeight: '360px', // Minimum height set
    backgroundColor: '#fff',
    boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
    borderRadius: 3,
    padding: 20,
    width: '33.33%',
    height: '30%',
    [theme.breakpoints.down('md')]: {
      // width: '33.33%', // 1/3 of the content area
      width: '100%',
      height: '100%',
      marginBottom: theme.spacing(2), // Margin for spacing
    },
    marginRight: '16px'
  },
    estimateHeader: {
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    padding: 10,
    margin: 10
  },
  estimateHeaderDFLStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    padding: 10,
    margin: 10
  },
  estimateHeaderTextDFLStyle: {
    fontWeight: '600'
  },
  customerDetailsContainer: {
    margin: 15,
    padding: 10,
    border: '1px solid #333',
    maxWidth: '500px',
    maxHeight: '300px',
    minHeight: '150px',
    borderRadius: 5
  },
  lineItemsContainer: {
    margin: 15,
    padding: 10,
    border: '1px solid #333',
    width: '95%',
    borderRadius: 5,
    // maxWidth: '500px',
    maxHeight: '1300px',
    minHeight: '500px'
  },
  lineItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch'
  },
  lineItemDescription: {
    width: '70%'
  },
  lineItemAmount: {
    width: '30%'
  },
  paymentActionAreaContainer: {
    width: '90%',
    margin: 10,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'center'
  },
  paymentMethodsHeader: {
    fontSize: '1.2em',
    fontWeight: '600',
    color: '#aaa',
    textAlign: 'center'
  },
  paymentMethodOptionButton: {

  },
  dflStyleSecondRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    margin: 10
  },
  DFLStyleCustomerInformationBoxionBox: {
    width: '43%',
    border: '2px solid #000',
    minHeight: '75px',
    maxHeight: '200px',
    margin: 10
  },
  dflStyleFilledContainer: {
    backgroundColor: '#accacd',
    fontWeight: '600',
    padding: 2,
    fontSize: '1.1em'
  },
  dflStyleSecondRowRightSide: {
    width: '20%',
    textAlign: 'right',
    // minHeight: '90px',
    // maxHeight: '200px',
    margin: 10
  },
  rightSideContainer: {
    border: '2px solid #000',
  },
  dflStyleSecondRowText: {
    fontWeight: '500'
  },
  dflLineItemsContainer: {
    margin: 15,
    marginLeft: 25,
    padding: 10,
  },
  transactionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  transactionContainerFilled: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    backgroundColor: '#accacd',
    fontWeight: '600',
    padding: 2,
    fontSize: '1.1em'
  },
  dflTransactionFirstColumn: {
    width: '20%',
    minWidth: '40px'
  },
  dflTransactionSecondColumn: {
    width: '60%',
    minWidth: '240px'
  },
  dflTransactionThirdColumn: {
    width: '20%',
    minWidth: '80px'
  },
  serviceDescriptionContainer: {
    margin: 15,
    border: '2px solid #000',
  },
  serviceDescriptionLabel: {
    fontWeight: '600',
    fontSize: '1.1em',
    borderBottom: '2px solid #000',
  },
  serviceDescriptonContent: {
    padding: 5,
    whiteSpace: 'pre-wrap'
  },
  dflLateFeesContainer: {
    border: '2px solid #000',
    margin: 15
  },
  dflLateFeesLabel: {
    fontWeight: '600',
    // fontSize: 'em',
    backgroundColor: '#ddd',
    borderBottom: '2px solid #000',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  dflLateFeesDetailsContainer: {

  },
  dflLateFeeItemContainer: {
    display: 'flex',
    flexDirection: 'row',
    color: 'red',
    justifyContent: 'flex-start',
  },
  dflLateFeeFirstColumn: {
    width: '20%'
  },
  dflLateFeeSecondColumn: {
    width: '60%'
  },
  dflLateFeeThirdColumn: {
    width: '15%',
    textAlign: 'right'
  },
  dflStyleFooter: {
    // marginTop: '200px',
    margin: 35,
  },
  dflFooterContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    width: '100%',
    alignItems: 'flex-start'
  },
  workSiteContainer: {
    width: '35%',
    border: '2px solid #000',
    alignSelf: 'flex-start', // Adjust this as necessary
  },
  totalContainer: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignSelf: 'stretch'
  },
  dflStyleFilledContainerWithBorderBottom: {
    backgroundColor: '#accacd',
    fontWeight: '600',
    padding: 2,
    fontSize: '1.1em',
    borderBottom: '2px solid #000',
  },
  dflStyleFilledContainerWithBorderRight: {
    backgroundColor: '#accacd',
    fontWeight: '600',
    padding: 2,
    fontSize: '1.1em',
    borderRight: '2px solid #000',
    width: '100%'
  },
  smallPaddedContainer: {
    margin: 3,
    fontWeight: '600',
  },
  smallPaddedContainerRight: {
    margin: 3,
    fontWeight: '600',
    textAlign: 'right',
    width: '100%'
  },
  totalContainerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignSelf: 'stretch',
    border: '2px solid #000'
  },
  disclosureArea: {
    marginTop: 10
  },
  disclosureAreaItem: {
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: '3000'
  },
  totalsContainer: {
    marginTop: 50,
   
    width: '100%',
    
  },
  totalsItem: {
    marginTop: 10,
    textAlign: 'right',
    fontWeight: '600'
  }
}));



// const useStyles = makeStyles((theme) => ({
//   root: {
//     margin: 0,
//     // display: 'flex',
//     // justifyContent: 'flex-start',
//     // alignSelf: 'stretch',
//     width: '100%',
//     backgroundColor: '#ddd'
//   },
//   contentArea: {
//     // width: '100%',
//     margin: 10,
//     display: 'flex',
//     flexDirection: 'row',
//     alignSelf: 'stretch',
//     justifyContent: 'center',
//     marginTop: 10,
//   },
//   tabs: {
//     borderRight: `1px solid ${theme.palette.divider}`
//   },
//   inputRoot: {
//     color: '#bbb',
//     fontSize: 20
//   },
//   signatureCanvas: {
//     backgroundColor: 'red',
//     width: '100%',
//     minHeight: '300px'
//   },
//   buttonGroup: {
//     marginBottom: '2em',
//     marginTop: '2em'
//   },
//   estimateOutline: {
//     margin: 20,
//     width: '70%',
//     flexGrow: 3,
//     // border: '2px solid #333',
//     // borderRadius: 2,
//     backgroundColor: '#fff',
//     width: '90%',
//     height: '100%',
//     minHeight: '960px',
//     boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
//     // overflowY: 'scroll'
//     [theme.breakpoints.down('sm')]: {
//       // backgroundColor: 'pink'
//       // textAlign: 'center',
//       width: '100%'
//     },
//     [theme.breakpoints.up('md')]: {
//       minHeight: '80vh',
//       maxWidth: '1800px',
//       // maxWidth: 960,
//       // textAlign: 'center'
//     },
//     [theme.breakpoints.up('lg')]: {
//       minHeight: '90vh',
//       maxWidth: '1950px',
//       // maxWidth: 960,
//       // textAlign: 'center'
//     },
//   },
//   estimateHeader: {
//     display: 'flex',
//     alignSelf: 'stretch',
//     justifyContent: 'space-between',
//     padding: 10,
//     margin: 10
//   },
//   customerDetailsContainer: {
//     margin: 15,
//     padding: 10,
//     border: '1px solid #333',
//     maxWidth: '500px',
//     maxHeight: '300px',
//     minHeight: '150px',
//     borderRadius: 5
//   },
//   lineItemsContainer: {
//     margin: 15,
//     padding: 10,
//     border: '1px solid #333',
//     width: '95%',
//     borderRadius: 5,
//     // maxWidth: '500px',
//     maxHeight: '1300px',
//     minHeight: '500px'
//   },
//   actionArea: {
//     margin: 20,
//     padding: 20,
//     backgroundColor: '#fff',
//     width: '50%',
//     flexGrow: 1,
//     height: '35%',
//     minHeight: '460px',
//     boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
//     borderRadius: 10
//   }
// }));


const AddPaperCheckDetails = (props) => {
  const checkNumRef = React.useRef(null)
  const handlePaperCheck = () => {
    // console.log(checkNumRef.current)
    // console.log(checkNumRef.current.val)
    props.updateParent(checkNumRef.current.value)
  }
  return (
    <div>
      <LabelText>Paper Check:</LabelText>
      <DialogContentText>
        Please Write Invoice #{props.invoice.invoiceNumber} On Your Check
      </DialogContentText>
        <TextField
          margin="dense"
          id="description"
          inputRef={checkNumRef}
          // value={dialogValue.description}
          // onChange={(event) => setDialogValue({ ...dialogValue, description: event.target.value })}
          label="Check Number"
          type="text"
          fullWidth
          helperText="What Check Number Will You Be Sending So We Can Associate It To This Estimate?"
        />
        <p>
            {
                  props.loading ?
                  <Button variant="contained" fullWidth color="secondary" disabled> <CircularProgress color="primary" size={ 24 } /></Button> :
                  <Button variant="contained" fullWidth color="secondary" onClick={handlePaperCheck}>Submit</Button>
                }
        </p>
    </div>
  )
}

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const logoURL = `${process.env.PUBLIC_URL}/logowide2.png`

const PayInvoiceOnline = (props) => {
  
  // console.log('COLLECT DEPOSIT PROPS:', props)
  const classes = useStyles();
  const sigPadRef = React.useRef({})
  const [state, setState] = React.useState({ paymentMethod: null, counter: 0, amount: 0 })
  const [proposal, setProposal] = React.useState({})
  // const [logoURL, setLogoURL] = React.useState(null)
  const [contact, setContact] = React.useState({})
  const [client, setClient] = React.useState({})
  const [paymentMethod, setPaymentMethod] = React.useState('')
  const [amountToCharge, setAmountToCharge] = React.useState(0)
  const [errorDetails, setErrorDetails] = React.useState(null)
  const [invoice, setInvoice] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
  // console.log('Online Pay', props.match)
    let isMounted = true

    const fetchInvoice = async (invoiceId, contactId) => {
      setLoading(true)
      const response = await axios.get(`${props.url}/api/invoice/viewInvoiceOnline?invoiceId=${invoiceId}`)
      if (response && response.data) {
        console.log('STUFF: ', response.data)
        if (isMounted) {
        console.log('Got the invoice', response.data)
          if (response.data.error) {
          // console.log('Error', response.data.message)
            props.notification({
              type: 'warning',
              title: 'We Ran Into A Problem!',
              message: response.data.message
            })
            setErrorDetails(response.data)
          }
          if (response.data && response.data._id) {
            // const inv = {
            //   ...response.data.invoice,
            //   client: response.data.client,
            //   payments: response.data.payments
            // }
            // console.log('INV:::', inv)
            const client = response.data.client
            setInvoice(response.data)
            setClient(client)
            setLoading(false)
            // setState({...state, amount: response.data.balance})
          }
          // if (response.data.client && response.data.client._id) setClient(response.data.client)
        }
      }
    }

    if (props.match && props.match.params && props.match.params.invoiceId) {
    // console.log(props.match.params.invoiceId)
      fetchInvoice(props.match.params.invoiceId)
    }

    return () => {
      isMounted = false
    }
  }, [])


  const handlePaymentActionUpdate = (type, msg, amount) => {
    console.log('Payment Action Update:::', type)
    if (type === 'success') {
      let newBalance = parseFloat(Number(invoice.balance) - Number(amount)).toFixed(2)
      setInvoice({
        ...invoice,
        status: newBalance < 0.1 ? 'Paid' : 'Partially Paid',
        paidDate: new Date(),
        balance: newBalance
      })
    }
  }


  if (invoice && invoice._id) {
    return <PaperStyleInvoice {...props} invoice={invoice} client={client} loading={loading} updateParent={handlePaymentActionUpdate} /> 
  } else {
    return (
      <></>
    )
  }
}

const PaperStyleInvoice = (props) => {
  console.log('Paper style invoice', props)
  const classes = useStyles()
  const [state, setState] = React.useState({ paymentMethod: null, counter: 0, amount: 0 })
  const [paymentMethod, setPaymentMethod] = React.useState('')
  const [amountToCharge, setAmountToCharge] = React.useState(0)
  const invoice = props.invoice
  const entity = invoice.entity
  const client = props.client
  console.log(invoice, client, entity)
  let logoURL = `${process.env.PUBLIC_URL}/aciLogo.png`
  if (entity && entity._id) {
    logoURL = `${process.env.PUBLIC_URL}/${entity._id}_Logo.png`
    if (entity && entity.slugName === 'dfl') logoURL = `${process.env.PUBLIC_URL}/logo.jpeg`
  }

  return (
    <div className={classes.root}>
      { props.loading ? <CircularProgress size={88} /> : (
         
          
            <div className={classes.container}>
              <div className={classes.headerArea}>
                <div className={classes.headerAreaContent}>
                  <div className={classes.headerContentItem}>
                    <div className={classes.headerContentItemLabel}>Invoice Number</div>
                    <div>{invoice?.invoiceNumber}</div>
                  </div>
                  <div className={classes.headerContentItem}>
                    <div className={classes.headerContentItemLabel}>Total Amount</div>
                    <div>{currencyFormat(invoice?.totalDue)}</div>
                  </div>
                  <div className={classes.headerContentItem}>
                    <div className={classes.headerContentItemLabel}>Balance Due</div>
                    <div>{currencyFormat(invoice?.balance)}</div>
                  </div>
                  <div className={classes.headerContentItem}>
                    <div className={classes.headerContentItemLabel}>Due Date</div>
                    <div>{new Date(invoice.dueDate).toLocaleDateString({ year: "numberic", month: "short", day: "numberic" })}</div>
                  </div>
                </div>
              </div>
              <div className={classes.contentArea}>
                {
                  entity.slugName === 'dfl' ? (
                    <div className={classes.estimateOutline}>
                    <div className={classes.estimateHeaderDFLStyle}>
                      <div className={classes.estimateHeaderTextDFLStyle}>
                        <div>{entity.entityNameFormal} </div>
                        <div>{entity.billingAddress} </div>
                        <div>{entity.billingCity}, {entity.billingState} {entity.billingZip}</div>
                        <div>{entity.telephone}</div>
                        <div>{entity.returnEmail}</div>
                      </div>
                      <div className={classes.logoArea}>
                        <div> <img alt="Logo" src={logoURL} style={{margin: '5px', maxWidth: 300, maxHeight: 150}} /></div>
                        
                      </div>
                      <div className={classes.rocInfoArea}></div>
                      <div className={classes.estimateDetailsArea}>
                        <div style={{ fontSize: '1.3em', fontWeight: 600, textAlign: 'right' }}>Invoice {invoice.invoiceNumber}</div>
                       
                      </div>
                    </div>
                    <div className={classes.dflStyleSecondRow}>
                      <DFLStyleCustomerInformationBox invoice={invoice} client={client} />
                      <DFLStyleDueDatBox invoice={invoice} />
                    </div>
                    <DFLStyleInvoiceLineItems invoice={invoice} />
                    <div style={{ height: '150px'}}>&nbsp;</div>
                    <DFLStyleFooter invoice={invoice} />
                  </div>
                  ) : (
                    <div className={classes.estimateOutline}>
                    <div className={classes.estimateHeader}>
                      <div className={classes.logoArea}>
                        <div> <img alt="Logo" src={logoURL} style={{margin: '5px', maxWidth: 300, maxHeight: 150}} /></div>
                        <div>{entity.entityNameFormal} </div>
                        <div>{entity.billingAddress} </div>
                        <div>{entity.billingCity}, {entity.billingState} {entity.billingZip}</div>
                        <div>Phone {entity.telephone} Fax 480-488-6230</div>
                      </div>
                      <div className={classes.rocInfoArea}></div>
                      <div className={classes.estimateDetailsArea}>
                        <div style={{ fontSize: '1.3em', fontWeight: 600, textAlign: 'right' }}>INVOICE</div>
                        <div style={{  textAlign: 'right' }}>INVOICE #{invoice.invoiceNumber}</div>
                        {/* <div style={{  textAlign: 'right' }}>{invoice.jobName}</div> */}
                        {/* <div style={{  textAlign: 'right' }}>CREATED: {new Date(invoice.created_at).toLocaleDateString({ year: "numberic", month: "short", day: "numberic" })}</div> */}
                        <div style={{  textAlign: 'right' }}>DUE: {new Date(invoice.dueDate).toLocaleDateString({ year: "numberic", month: "short", day: "numberic" })}</div>
                        
                      </div>
                    </div>
                    <div className={classes.customerDetailsContainer}>
                    
                      <div><CustomerInformationArea entity={entity} invoice={invoice} client={client} /></div>
                    </div>
                    <InvoiceLineItems invoice={invoice} />
                  </div>
                  )
                }
                
                 {
                  invoice.balance > 0 ? (
                    <div className={classes.actionArea}>
                      <PaymentActionArea invoice={invoice} updateParent={props.updateParent} />
                    </div>
                    ) : null 
                  }
          </div>
    </div>
    )
  }

  </div>
  )
}

const DFLStyleFooter = ({ invoice }) => {
  const classes = useStyles()
  let paymentTotal = 0
  if (invoice && invoice.payments && invoice.payments.length) {
    invoice.payments.forEach(item => {
      paymentTotal += Number(item.amount)
    })
  }
  return (
    <div className={classes.dflStyleFooter}>
      <div className={classes.dflFooterContent}>
        <div className={classes.workSiteContainer}>
          <div className={classes.dflStyleFilledContainerWithBorderBottom}>
            Work Site
          </div>
          <div className={classes.smallPaddedContainer}>{invoice.jobSite}</div>
        </div>
        <div style={{ width: '40%'}}>&nbsp;</div>
        <div className={classes.totalContainer}>
          <div className={classes.totalContainerRow}>
            <div className={classes.dflStyleFilledContainerWithBorderRight}>Sub-Total</div>
            <div className={classes.smallPaddedContainerRight}>{currencyFormat(invoice.totalDue)}</div>
          </div>
          <div className={classes.totalContainerRow}>
            <div className={classes.dflStyleFilledContainerWithBorderRight}>Tax</div>
            <div className={classes.smallPaddedContainerRight}>{currencyFormat(invoice.taxAmount)}</div>
          </div>
          <div className={classes.totalContainerRow}>
            <div className={classes.dflStyleFilledContainerWithBorderRight}>Paid</div>
            <div className={classes.smallPaddedContainerRight}>{currencyFormat(paymentTotal)}</div>
          </div>
          <div className={classes.totalContainerRow}>
            <div className={classes.dflStyleFilledContainerWithBorderRight}>Due</div>
            <div className={classes.smallPaddedContainerRight}>{currencyFormat(invoice.balance)}</div>
          </div>
            
        </div>
       
      </div>
      <div className={classes.disclosureArea}>
        <div className={classes.disclosureAreaItem}>
          *Credit Card payments will be charged a 3.75% surcharge
        </div>
        <div className={classes.disclosureAreaItem}>
          *DECLINED Credit Cards returned Checks, and accounts with insufcient funds will be charged a $25 fee on their next invoice
        </div>
        <div className={classes.disclosureAreaItem}>
          *Any payments that are not paid within 30 days of receipt will be assessed a 1.5% late fee on the amount due
        </div>
      </div>
    </div>
  )
}

const DFLStyleCustomerInformationBox = ({ invoice, client }) => {
  const classes = useStyles()
  let clientName = ''
  if (client && client._id && client._id.length) {
    clientName = client.searchName ? client.searchName : client.displayName
  }
  const billingAddress = invoice.billingAddress
  return (
    <div className={classes.DFLStyleCustomerInformationBoxionBox}>
      <div className={classes.dflStyleFilledContainer}>
        Customer
      </div>
      <div>
        <div className={classes. dflStyleSecondRowText}>{clientName}</div>
        <div className={classes. dflStyleSecondRowText}>{billingAddress.address}</div>
        <div className={classes. dflStyleSecondRowText}>{billingAddress.city || ''}, {billingAddress.state || ''} {billingAddress.zip || ''}</div>
      </div>
    </div>
  )
}



const DFLStyleDueDatBox = ({ invoice }) => {
  const classes = useStyles()

  return (
    <div className={classes.dflStyleSecondRowRightSide}>
      <div className={classes.rightSideContainer}>
        <div className={classes.dflStyleFilledContainer}>
          Date
        </div>
        <div className={classes. dflStyleSecondRowText}>{new Date(invoice.dueDate).toLocaleDateString({ year: "numberic", month: "short", day: "numberic" })}</div>
      </div>
      <br />
      <div className={classes.rightSideContainer}>
        <div className={classes.dflStyleFilledContainer}>
          Amount Due
        </div>
        <div className={classes. dflStyleSecondRowText}>{currencyFormat(invoice.balance)}</div>
      </div>
    
    </div>
  )
}

const PaymentActionArea = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.paymentActionAreaContainer}>
      <PaymentManager invoice={props.invoice} updateParent={props.updateParent} />
    </div>
  )
}
const CustomerInformationArea = ({ invoice, client }) => {
  const classes = useStyles()
  console.log('Cusomter Info Called 494', invoice)
  console.log('client', client)
  let clientName = ''
  if (client && client._id && client._id.length) {
    clientName = client.searchName ? client.searchName : client.displayName
  }
  const billingAddress = invoice.billingAddress
  return (
    <div>
      <div>{clientName}</div>
      <div>{billingAddress.address}</div>
      <div>{billingAddress.city || ''}, {billingAddress.state || ''} {billingAddress.zip || ''}</div>
    </div>
  )
}

const DFLStyleInvoiceLineItems = ({ invoice }) => {
  const classes = useStyles()
  return (
    <div className={classes.dflLineItemsContainer}>
       <div className={classes.transactionContainerFilled}>
        <div className={classes.dflTransactionFirstColumn}>Invoice Date</div>
        <div className={classes.dflTransactionSecondColumn}>Transaction</div>
        <div className={classes.dflTransactionThirdColumn}>Amount</div>
      </div>
      <div className={classes.transactionContainer}>
        <div className={classes.dflTransactionFirstColumn}>{new Date(invoice.dueDate).toLocaleDateString({ year: "numberic", month: "short", day: "numberic" })}</div>
        <div className={classes.dflTransactionSecondColumn}>{invoice.jobName}</div>
        <div className={classes.dflTransactionThirdColumn}>{currencyFormat(invoice.amount)}</div>
      </div>
      <div className={classes.serviceDescriptionContainer}>
        <div className={classes.serviceDescriptionLabel}>Service Description</div>
        <div className={classes.serviceDescriptonContent}>{invoice.description}</div>
      </div>
      <DFLLateFeeDetails invoice={invoice} />
    </div>
  )
}

const DFLLateFeeDetails = ({ invoice }) => {
  const classes = useStyles()
  console.log('Late fees/??', invoice)
  return (
    <div className={classes.dflLateFeesContainer}>
      <div className={classes.dflLateFeesLabel}>
        <div className={classes.dflLateFeeFirstColumn}>
          Fee Date
        </div>
        <div className={classes.dflLateFeeSecondColumn}>
          Fee Details
        </div>
        <div className={classes.dflLateFeeThirdColumn}>
          Amount
        </div>
      </div>
      <div className={classes.dflLateFeesDetailsContainer}>
        {
          invoice.lateFees.map((fee, index) => {
            return (
              <div className={classes.dflLateFeeItemContainer} key={index}>
                 <div className={classes.dflLateFeeFirstColumn}>
                 {new Date(fee.dueDate).toLocaleDateString({ year: "numberic", month: "short", day: "numberic" })}
                </div>
                <div className={classes.dflLateFeeSecondColumn}>
                  Late Payment Fee (1.5% of Balance)
                </div>
                <div className={classes.dflLateFeeThirdColumn}>
                  {currencyFormat(fee.amount)}
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
const InvoiceLineItems = (props) => {
  const classes = useStyles()
  const body = props?.invoice?.invoiceBody
  console.log('Body for our invoice', body)
  // HMI STYLE
  if (body && body.length) {
    return (
      <div className={classes.lineItemsContainer}>
        {
          body.map((item, index) => {
            console.log('Body Item::', item)
            return (
              <div key={index} className={classes.lineItemContainer}>
                <div className={classes.lineItemDescription}>{item.text}</div>
                <div className={classes.linteItemAmount}>{item.amount ? item.amount : '' }</div>
              </div>
            )
          })
        }
        <HMIInvoiceTotalsArea invoice={props.invoice} />
      </div>
    )
  } else {
    return (
      <div className={classes.lineItemsContainer}>
        {props?.invoice?.description}
      </div>
    )
  }

}

const HMIInvoiceTotalsArea = ({ invoice }) => {
  const classes = useStyles()
  return (
    <div className={classes.totalsContainer}>
      <div className={classes.totalsItem}>{`Sub-Total: ${currencyFormat(invoice.amount)}`}</div>
      <div className={classes.totalsItem}>{`Tax: ${currencyFormat(invoice.taxAmount)}`}</div>
      <div className={classes.totalsItem}>{`Total: ${currencyFormat(invoice.totalDue)}`}</div>
    </div>
  )
}

const mapStateToProps = (state) => {
  // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
  }
}

export default connect(mapStateToProps)(PayInvoiceOnline)
