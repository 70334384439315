import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import AddNewEntityModal from './AddNewEntityModal'
import axios from 'axios'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    minHeight: '75vh',
    padding: 10,
    margin: 10
    // overflow: 'scroll'
  },
}))

const defaultStateValue = {
  showNewEntityModal: false,
  laborRate: 60,
  installRate: 70,
  techRate: 95,
  sprayTechRate: 90,
  operatorRate: 90,
  supervisorRate: 95,
  bobcatRate: 300,
  craneRate: 900,
  dataToUpdate: []
}

const Entities = (props) => {
  // console.log('Serivce Component', props)
  const classes = useStyles();
  const [state, setState] = React.useState(defaultStateValue)
  const [entities, setEntities] = React.useState([])

  const fetchEntities = async () => {
    const resp = await axios.get(`${props.url}/api/entities/list`)
  // console.log('Entities;', resp.data)
    setEntities(resp.data)
  }
  
  React.useEffect(() => {
    fetchEntities()
  }, [])
  
  const handleUpdateFromNewEntityModal = (type, data) => {
    setState({...state, showNewEntityModal: false})
  }

  const handleCloseNewEntModal = () => {
    setState({...state, showNewEntityModal: false})
  }

  const handleFocus = (event) => event.target.select()
  
  const handleSave = async () => {
    console.log('Save the entitiy details!!' )
    console.log('Update:', state.dataToUpdate)
    const resp = await axios.post(`${props.url}/api/entities/update`, state.dataToUpdate)
    console.log('response', resp.data)
    setState({...state, dataToUpdate: {}})
  }

  const updateOurDataToUpdate = (passedData) => {
    console.log('updateOurDataToUpdate', passedData)
    const { 
      entityId,
      data
    } = passedData
    let newState = {}
    const newObj = {
      _id: entityId,
      updatedData: { ...data }
    }
    console.log('Data: ', data)
    console.log('New obj', newObj)
    let newStateDataToUpdate = []
    if (state.dataToUpdate && state.dataToUpdate.length) {
      newStateDataToUpdate = [...state.dataToUpdate]
      const index = [...newStateDataToUpdate].findIndex(item => (item._id === entityId))
      if (index > -1) {
        console.log('Use this', newStateDataToUpdate)

        let itemToUpdate = { ...newStateDataToUpdate[index] }
        console.log('Gotta update this one', itemToUpdate)

        // itemToUpdate.dataToUpdate = {...itemToUpdate.dataToUpdate, ...data}
        let dataBeingUpdated = { ...itemToUpdate.updatedData }
        console.log('Update....it ', dataBeingUpdated)
        let newData = { ...dataBeingUpdated, ...data }
        console.log('New Data', newData)
        itemToUpdate.updatedData = newData
        console.log('103', itemToUpdate)
        newStateDataToUpdate[index] = itemToUpdate
        newState = {
          dataToUpdate: newStateDataToUpdate
        }
      } else {
        console.log('build new 105', state)
        
        newStateDataToUpdate = [...state.dataToUpdate, newObj ]
        newState = {
          dataToUpdate: newStateDataToUpdate
        }
        console.log('New State to set', newState)
      }
    } else {
      console.log('build new 114')
      newStateDataToUpdate = [newObj]
      newState = {
        dataToUpdate: newStateDataToUpdate
      }
    }
    console.log('New State to set', newState)
    setState({...state, ...newState })
  }

  const updateOurEntity = (passedData) => {
    console.log('Update this entitiy:', passedData)
    updateOurDataToUpdate(passedData)
    const { 
      entityId,
      data
    } = passedData
    let entitiyIndex = [...entities].findIndex(item => (item._id === entityId))
    if (entitiyIndex > -1) {
      let newEntities = [...entities]
      newEntities[entitiyIndex] = {
        ...newEntities[entitiyIndex],
        ...data
      }
      setEntities(newEntities)
    }
  }

  return (
    <div style={{padding: '5px', margin: '10px'}}>
      <AddNewEntityModal open={state.showNewEntityModal} updateParent={handleUpdateFromNewEntityModal} closeModal={handleCloseNewEntModal} />
      <Paper className={classes.paper}>
      <Grid container>
        <Grid item lg={6} xs={12}>
          <h1>Entities</h1>
        </Grid>
        <Grid item lg={6} xs={12}>
        </Grid>
        <Grid item lg={12} xs={12}>
        
            <Grid container style={{padding: '10px'}}>
                
              <Grid item lg={3} md={4} xs={6}>
               
              </Grid>
              <Grid item lg={3} md={4} xs={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  label="Add New Client"
                  size="small"
                  onClick={e => setState({...state, showNewEntityModal: !state.showNewEntityModal})}
                  >Add New Entity</Button>
              </Grid>
            </Grid>
              <List component="div" role="list">
                {entities.map((item, index) => {
                  const labelId = `transfer-list-item-${index}-label`;
                  const handleChange = (e) => {
                    const updateInfo = {
                      data: {
                        [e.target.id]: e.target.value
                      },
                      entityId: item._id
                    }
                    updateOurEntity(updateInfo)
                  }
                  return (  
                    <div style={{ margin: 10, borderRadius: 8, border: '3px solid #aaa', padding: 10 }} key={index}>
                      <ListItem  role="listitem" >
                        <ListItemIcon>
                            <img alt="Logo" src={`${process.env.PUBLIC_URL}/${item._id}_Logo.png`} style={{margin: '5px', padding: '5px', maxWidth: 150, maxHeight: 110}} />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={item.name} />
                        <ListItemText id={`ba_${index}`} primary={item.billingAddress} />
                       
                      </ListItem>
                      <Grid container style={{padding: '10px'}}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Grid container spacing={2} style={{padding: '10px'}}>
                          <Grid item lg={2} md={3} sm={4} xs={12}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="entityNameFormal"
                              onChange={handleChange}
                              value={item.entityNameFormal}
                              helperText={`What is the Formal Name for ${item.name}?`}
                            />
                          </Grid>
                          <Grid item lg={2} md={3} sm={4} xs={12}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              id="name"
                              onChange={handleChange}
                              value={item.name}
                              helperText={`What is the Common Name for ${item.name}?`}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={2} md={3} sm={4} xs={12}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="returnEmail"
                            onChange={handleChange}
                            value={item.returnEmail}
                            helperText={`What is the Return Email Address for ${item.name}?`}
                          />
                        </Grid>
                 
                        <Grid item lg={2} md={3} sm={4} xs={12}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="rocNumber"
                            onChange={handleChange}
                            value={item.rocNumber}
                            helperText={`What is the ROC Number for ${item.name}?`}
                          />
                        </Grid>
                      </Grid>
                      <LaborDetails entity={item} handleFocus={handleFocus} entities={entities} setEntities={setEntities} />
                    </div>
                        );
                      })}
                   
                {entities.length === 0 ? (
                  <span style={{padding: '10', margin: 10}}>List Empty</span>
                ) : ''}
              </List>
             
        </Grid>
        <Grid item lg={12} xs={12}>
        <Button
                  variant="contained"
                  color="primary"
                  label="Save"
                  size="small"
                  onClick={handleSave}
                  >Save Changes</Button>
        </Grid>
      </Grid>
      </Paper>
  </div>
  )
}

const LaborDetails = ({ entity, handleFocus, entities, setEntities }) => {
  const handleUpdate = (e) => {
    console.log(e)
    console.log(e.target)
    console.log(e.target.value)
    let updatedItem = {
      [e.target.id]: e.target.value
    }
    console.log('UPdate:', updatedItem)
    let updatedEntities = [...entities ]
    updatedEntities.map(item => {
      if (item._id.toString() === entity._id.toString()) {
        item[e.target.id] = e.target.value
        // return item
      }
      console.log('updated:???', item)
      return item
    })
    console.log(updatedEntities)
    setEntities(updatedEntities)
  }

  return (
    <React.Fragment >
      <div style={{ textAlign: 'center'}}>Labor Details </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
        <div style={{ display: 'flex'}}>
          <TextField
            label="General Labor Rate"
            variant="outlined"
            type="number"
            id="laborRate"
            value={entity.laborRate || 33}
            onChange={handleUpdate}
            fullWidth
            onFocus={handleFocus}
            />
        </div>
        <div style={{ display: 'flex'}}>
          <TextField
            label="Install Labor Rate"
            variant="outlined"
            type="number"
            id="installRate"
            value={entity.installRate || 33}
            onChange={handleUpdate}
            fullWidth
            onFocus={handleFocus}
            />
        </div>
        <div style={{ display: 'flex'}}>
          <TextField
            label="Tech Rate"
            variant="outlined"
            id="techRate"
            type="number"
            value={entity.techRate || 33}
             onChange={handleUpdate}
            fullWidth
            onFocus={handleFocus}
            />
        </div>
        <div style={{ display: 'flex'}}>
          <TextField
            label="Spray Tech Rate"
            variant="outlined"
            id="sprayTechRate"
            type="number"
            value={entity.sprayTechRate || 33}
             onChange={handleUpdate}
            fullWidth
            onFocus={handleFocus}
            />
        </div>
        <div style={{ display: 'flex'}}>
          <TextField
            label="Operator Rate"
            variant="outlined"
            id="operatorRate"
            type="number"
            value={entity.operatorRate || 33}
             onChange={handleUpdate}
            fullWidth
            onFocus={handleFocus}
            />
        </div>
        <div style={{ display: 'flex'}}>
          <TextField
            label="Supervisor Rate"
            variant="outlined"
            id="supervisorRate"
            type="number"
            value={entity.supervisorRate || 33}
             onChange={handleUpdate}
            fullWidth
            onFocus={handleFocus}
            />
        </div>
        <div style={{ display: 'flex'}}>
          <TextField
            label="Bobcat Rate"
            variant="outlined"
            id="bobcatRate"
            type="number"
            value={entity.bobcatRate || 300}
             onChange={handleUpdate}
            fullWidth
            onFocus={handleFocus}
            />
        </div>
        <div style={{ display: 'flex'}}>
          <TextField
            label="Crane Rate"
            variant="outlined"
            id="craneRate"
            type="number"
            value={entity.craneRate || 900}
             onChange={handleUpdate}
            fullWidth
            onFocus={handleFocus}
            />
        </div>
      </div>
      
    </React.Fragment>
  )
}
  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(Entities)