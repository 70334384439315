import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, IconButton, Badge, TextField, InputAdornment, Grid }  from '@material-ui/core';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FileUploader } from 'react-drag-drop-files';
import { useHistory } from "react-router-dom";
import RoomIcon from '@material-ui/icons/Room';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PrintIcon from '@material-ui/icons/Print';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import ProtectedButton from '../../actions/protectedButtons'
import StorageIcon from '@material-ui/icons/Storage';
import EmployeeSearchComponent from '../DailyRoster/EmployeeSearchComponent';

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  paper: {
    width: '100%',
    height: '100%',
    minHeight: '80vh',
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  searchBoxContainer: {
    width: '50%',
    margin: 10,
    padding: 10
  },
  dashboardItem: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 250, // Adjust based on your content
  },
  dashboardItemName: {
    fontSize: '1.1rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  dashboardItemNumber: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  dashboardItemNameSmall: {
    fontSize: '1.1rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  dashboardItemNumberSmall: {
    // fontSize: 'em',
    // fontWeight: 'bold',
    color: theme.palette.text.primary,
  }
}))

const EmployeeMobileAppSimulatedView = (props) => {
  console.log('EmployeeMobileAppSimulatedView Props', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [employee, setEmployee] = React.useState({})
  const [workorders, setWorkorders] = React.useState([])
  const [crew, setCrew] = React.useState({})
  const [searchValue, setsearchValue] = React.useState('')
  const [mobileAppData, setMobileAppData] = React.useState({})
  React.useEffect(() => {
    let isMounted = true
    
    // const fetchEmployees = async () => {
    //   console.log('Fetch Employees')
    //   axios.get(`${props.url}/api/hmi/fetchFuelSmartData`).then((response, status, data) => {
    //     // setLoading(false)
    //     if (response && response.data) {
    //     console.log('Smart Tank Data', response.data)
    //       if (isMounted && response.data.length) {
    //         // setMtcSites(response.data.markers)
    //         console.log('HEre is the fuel data', response.data)
    //         setSmartTankData(response.data)
    //       }
    //     }
    //   })
    // }
    // fetchEmployees()

  }, [])

  const fetchData = async (id) => {
    console.log('Fetch Employee mobile app data')
    axios.get(`${props.url}/api/mobileapp/simulateMobileAppInfo?_id=${id}`).then((response, status, data) => {
      // setLoading(false)
      if (response && response.data) {
      console.log('Mobile App Data', response.data)
      setMobileAppData(response.data)
      setLoading(false)
      }
    })
  }

 const handleSearch = (e) => {
  setsearchValue(e.target.value)
 }

 const handleEmployeeSelection = (d) => {
  console.log('Select this employee', d)
  if (d && d._id) {
    setLoading(true)
    fetchData(d._id)
  }
 }

 const filterOutEmployeesWithOutUsers = (arr) => {
  return arr.filter(item => (item && item.user && item.user.length))
 }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <h1>Mobile App Simulated View</h1>
        <div className={classes.searchBoxContainer}>
         <EmployeeSearchComponent selectTheEmployee={handleEmployeeSelection} filterBy={filterOutEmployeesWithOutUsers} />
        </div>
        {
          loading ? <CircularProgress size={72} /> : (
            <div>
              { (mobileAppData && mobileAppData.workorders) ? <DashboardData mobileAppData={mobileAppData} /> : null }
            </div>
          )
        } 
        {/* <SearchArea searchValue={searchValue} handleSearch={handleSearch} /> */}
        {/* <DetailsArea /> */}
      </Paper>
      
    </div>
  )
}

const DashboardData = ({ mobileAppData }) => {
  const classes = useStyles()
  return (
    <div className={classes.dashboardContainer}>
   
      <TopRow data={mobileAppData} />
      <WorkorderList data={mobileAppData.workorders} />
      {/* {JSON.stringify(mobileAppData)} */}
    </div>
  )
}

const WorkorderList = ({ data }) => {
  const classes = useStyles()
  let rows = data || []
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Expected Arrival Time</TableCell>
          <TableCell>Site Name</TableCell>
          <TableCell>Site Address</TableCell>
          <TableCell>Work Complete</TableCell>
          <TableCell>Estimated Minutes</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          [...data].map((row, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{new Date(row.scheduledDate).toLocaleDateString('en-US', { timeZone: 'America/Phoenix', dateStyle: 'short' })} {new Date(row.scheduledDate).toLocaleTimeString('en-US', { timeZone: 'America/Phoenix',  timeStyle: 'short' })}</TableCell>
                <TableCell>{row.clientName}</TableCell>
                <TableCell>{row.jobSiteAddress}</TableCell>
                <TableCell>{row.workComplete}</TableCell>
                <TableCell>{row.estMinutes}</TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
  )
}

const TopRow = ({ data }) => {
  const numberOfWorkorders = (data && data.workorders) ? data.workorders.length : 0
  const numberOfPOs = (data && data.purchaseOrders) ? data.purchaseOrders.length : 0
  const crewMates = (data.crew && data.crew.employees && data.crew.employees.length) ? data.crew.employees.map(emp => (<div>{emp.displayName} (<EmployeeStatus emp={emp} />)</div>)) : 0
  const lastLocation = (data.crew && data.crew.lastLocation && data.crew.lastLocation.name) ? (
    <div>
      <div>{data.crew.lastLocation.name}</div>
      <div>{new Date(data.crew.lastLocation.timestamp).toLocaleTimeString({ dateStyle: 'short', timeStyle: 'short' })}</div>
    </div>
  ) : 'Unknown'
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={3} md={3} sm={4} xs={6}>
          <DashboardDataItem
            name='Workorders'
            data={numberOfWorkorders}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={4} xs={6}>
          <DashboardDataItem
            name='Purchase Orders'
            data={numberOfPOs}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={4} xs={6}>
          <DashboardDataItem
            name='Crew Mates'
            data={crewMates}
            smallText={true}
          />
        </Grid>
        <Grid item lg={3} md={3} sm={4} xs={6}>
          <DashboardDataItem
            name='Last Location'
            data={lastLocation}
            smallText={true}
          />
        </Grid>
      </Grid>
      
    </div>
  )
}

const EmployeeStatus = ({ emp }) => {
  let status = emp.isClockedIn ? 'Clocked-In' : emp.isCheckedIn ? 'Checked-In' : 'Off The Clock'
  return status
}

const DashboardDataItem = ({ data, name, smallText }) => {
  const classes = useStyles()
  return (
    <div className={classes.dashboardItem}>
      <div className={smallText ? classes.dashboardItemNameSmall : classes.dashboardItemName}>{name}</div>
      <div className={smallText ? classes.dashboardItemNumberSmall : classes.dashboardItemNumber}>{data}</div>
    </div>
  )
}

const SearchArea = ({ searchValue, handleSearch }) => {
  const classes = useStyles()
  return (
    <div className={classes.searchBoxContainer}>
       <TextField
        name="search" 
        value={ searchValue } 
        onChange={ handleSearch }
        label="Search"
        type="search"
        variant="outlined"
        margin="normal"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
    />
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    entities: state.entities,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(EmployeeMobileAppSimulatedView)