import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Table, Chip, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@material-ui/core';
import PhotoSlideshow from './PhotoSlideshow'

const hmiColor = 'rgb(2 164 9)'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: hmiColor
  },
  
}));

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
  return array;
};

function HMIPhotosForTV(props) {
  const classes = useStyles()
  const [state, setState] = React.useState({
    loading: false,
    photos: [],
    lastUpdated: '',
    error: {}
  })

  const fetchPhotos = async () => {
    setState(prevState => ({ ...prevState, loading: true }))
    try {
      const url = `${props.url}/api/hmi/getphotosForTV`; // URL from props
      const response = await axios.get(url);
      if (response.data) {
        console.log('Got some data!!', response.data);
        const lastUpdated = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute:'2-digit' })
        // let shuffledPhotos = shuffleArray(response.data)
        setState({ photos: response.data, loading: false, lastUpdated });
      }
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setState(prevState => ({ ...prevState, loading: false, error: error }));
    }
  };

  React.useEffect(() => {
    fetchPhotos();
  }, []);

  return (
  <div className={classes.root}>
    {
      state.loading ? 'Loading' : <PhotoSlideshow photos={state.photos} fetchPhotos={fetchPhotos} />
    }
  </div>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.url
  }
}

export default connect(mapStateToProps)(HMIPhotosForTV)

