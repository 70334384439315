import React from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { connect } from 'react-redux'
import axios from 'axios'
import CustomMarker from './CustomMarker'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import ReactDOM from 'react-dom';

const containerStyle = {
  width: '100%',
  height: '90vh'
};

const center = {
  lat: 33.81972977616888,
  lng: -111.9539555679331
};

const position = {
  lat: 33.7,
  lng: -111.97
}

const onLoad = marker => {
// console.log('marker: ', marker)
}
// const image = "https://dflportal.com/favicon.png"
const dfl = "https://dflportal.com/favicon.png"
const benhart = "https://www.benhartlandscaping.com/wp-content/themes/benhart/img/icons/favicon-16x16.png"

const Sidebar = (props) => {
  let markers = props.markers
  // console.log('SIde bar: ', markers)
  return (
    <div style={{ overflowY: 'scroll', padding: 10, width: '100%', height: '90vh', zIndex: 9999, backgroundColor: '#bbb'}} >
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <h2>Recent Activity: ({markers.length} Items)</h2>
        </Grid>
        <Grid item lg={12} xs={12}>
          {
            markers.map((activity, index) => {
            // console.log('Marker info:', activity)
              let crewName = ''
              if (activity && activity.crew && activity.crew.name) crewName = activity.crew.name
              let isActive = false
              let color = '#bbb'
              const item = activity.location
              if (activity._id === props.activeMarker._id) {
                isActive = true
                color = '#153'
                // console.log('Search the DOM for this item!!')
                // let ele = ReactDOM.findDOMNode(this) // .getBoundingClientRect()
                // console.log('ELE: ', ele)
              }
              return (
                <li key={index} onMouseEnter={e => props.setActiveMarker(activity)} style={{ backgroundColor: color }}>
                  <Grid container>
                    <Grid item lg={12}>{crewName}</Grid>
                    <Grid item lg={6}>{activity.locationName}</Grid>
                    <Grid item lg={6}>{activity.activity}</Grid>
                    <Grid item lg={6}>{moment(activity.created_at).format('MM-DD-YYYY hh:mma')}</Grid>
                    <Grid item lg={3}>
                      Speed: 
                    </Grid>
                    <Grid item lg={3}>
                      {(item.speed && item.speed > 0) ? parseFloat(item.speed * 2.23694).toFixed(2) : 0}
                    </Grid>
                    <Grid item lg={3}>
                      Heading: 
                    </Grid>
                    <Grid item lg={3}>
                      {item.heading ? parseFloat(item.heading).toFixed(2) : null }
                    </Grid>
                    <Grid item lg={3}>
                      Latitude: 
                    </Grid>
                    <Grid item lg={3}>
                      {item.latitude ? parseFloat(item.latitude).toFixed(8) : null }
                    </Grid>
                    <Grid item lg={3}>
                      Longitude: 
                    </Grid>
                    <Grid item lg={3}>
                      {item.longitude ? parseFloat(item.longitude).toFixed(8) : null }
                    </Grid>
                  </Grid>
                </li>
              )
            })
          }
        </Grid>
      </Grid>
    </div>
  )
}
const colors = ['#ACCACD', '#1c801f', '#84d1e0', '#84e0b0', '#bbe084', '#689623', '#239670']

const normalizeOurActivity = (activity) => {
  // console.log('normalize this:', activity)
  if (activity && activity.location && activity.location.longitude) {
    console.log('Mark this bitch', activity)
    // markersToUse.push(activity)
    return activity
  } else if (activity && activity.location && activity.location.coords && activity.location.coords.longitude) {
    console.log('Mark this fucker', activity)
    console.log('Activity.location.coords', activity.location)
    console.log(activity.location.coords)
    // markersToUse.push(activity)
    return activity
  }
}

function MyComponent(props) {
  const [markers, setMarkers] = React.useState([])
  const [crews, setCrews] = React.useState([])
  const [state, setState] = React.useState({
    pending: false
  })
  const [activeMarker, setActiveMarker] = React.useState({})
  // const dfl = `${props.url}/pickupTruck.svg`
  // const benhart = "https://www.benhartlandscaping.com/wp-content/themes/benhart/img/icons/favicon-16x16.png"

  React.useEffect(() => {
    let isMounted = true
    let params = props.location.search
    let crewId = null
    // console.log('Params: ', params)
    if (params) {
      let stuff = params.split('?')
      let val = stuff[1]
      val = val.split('=')
      val = val[1]
      // console.log('Value: ', val)
      if (val) crewId = val
    }
    const fetchMarkers = async () => {

      if (props.location && props.location.search) {

      }
      const response = await axios.get(`${props.url}/api/crews/crewDailyLog?crew=${crewId}`)
      if (response.data && response.data.length) {
        console.log('Marker Data: ', response.data)
        if (isMounted) {
          // console.log('Activities:', response.data)
          // setCrews(response.data)
          let markersToUse = []
          // var i = 0
          response.data.map(activity => {
            console.log('activity to work on: ,', activity._id)
            // Get the last 5 checkins for this guy
            // accurateData??
            let normalizedItem = normalizeOurActivity(activity)
            console.log('Actiivty to use a sa marker', normalizedItem)
            if (normalizedItem && normalizedItem._id) markersToUse.push(normalizedItem)
            // if (crew.accurateData && crew.accurateData.length) {
            //   let data = crew.accurateData // .splice(crew.accurateData.length, crew.accurateData.length)
            //   // console.log('Data to use: ', data)
            //   for (const item of data) {
            //     item.color = colors[i]
            //   }
            //   markersToUse = markersToUse.concat(data)
            //   i++
            // } else {
            //   let data = crew.data // .splice(crew.data.length, crew.data.length)
            //   // console.log('Data to use: ', data)
            //   for (const item of data) {
            //     item.color = colors[i]
            //   }
            //   markersToUse = markersToUse.concat(data)
            //   i++
            // }
          })
          // console.log('We have %d total markers!!', markersToUse.length)
          setMarkers(markersToUse)
          // let pending = state.pending
          // setState({...state, pending: !pending })
        }
      }
    }

    fetchMarkers()

    return () => {
      isMounted = false
    }
  }, [])


  // const OurCrews = () => {
  // // console.log('Get our crew data...', crews)
  //   if (crews && crews.length) {
  //     let markersToUse = []
  //     crews.map(crew => {
  //     // console.log('Crew to work on: ,', crew)
  //       // Get the last 5 checkins for this guy
  //       let data = crew.data.splice(crew.data.length - 5, crew.data.length)
  //     // console.log('Data to use: ', data)
  //       markersToUse = markersToUse.concat(data)
  //     })
  //   // console.log('We have %d total markers!!', markersToUse)
  //     setMarkers(markersToUse)
  //     return markersToUse
  //   } else {
  //     return <span>Nothing to Show</span>
  //   }
  // }

  const updateActiveMarker = (d) => {
  console.log('UPdate Active Marker: ', d)
    setActiveMarker(d)
  }
  const OurMarkers = ({ markers }) => {
    console.log('Generate our Markes', markers.length)
    let icon = dfl
    if (markers && markers.length) {
      console.log('Active Marker', activeMarker)
      let ourMarkers = []
      markers.map(activity => {
      console.log('Marker activity: ', activity)
        // if (addy && addy.entity === '610a290f45ef7427f94b2e78') {
        //   icon = benhart
        // }
        let markerPosition = {}
        if (activity && activity.location && activity.location.coords && activity.location.coords.longitude) {
        // console.log('Got activity.coords:::', activity.location)
          markerPosition = {
            lat: Number(activity.location.coords.latitude),
            lng: Number(activity.location.coords.longitude),
            heading: Number(activity.location.coords.heading)
          }
          activity.location = activity.location.coords
        } else if (activity && activity.location && activity.location.longitude) {
        // console.log('Got activity.coords:::', activity.location)
          markerPosition = {
            lat: Number(activity.location.latitude),
            lng: Number(activity.location.longitude),
            heading: Number(activity.location.heading)
          }
        } else {
        // console.log('No fucking lat/lng...227', activity)
        }
        // console.log(markerPosition)
          let color = '#214'
          if (activeMarker._id === activity._id) {
            color = '#832'
            console.log('Change that color!!', color)
          }

          // console.log('COLOR: ', color)
          // console.log(activeMarker)
          ourMarkers.push(
            <CustomMarker
              onLoad={onLoad}
              key={activity._id}
              position={markerPosition}
              icon={icon}
              color={color}
              activity={activity}
              activeMarker={activeMarker}
              updateActiveMarker={updateActiveMarker}
            />
          )

      })
      return ourMarkers
    } else {
      return (
        <CustomMarker
          onLoad={onLoad}
          position={position}
          color='red'
          icon={icon}
        />
      )
    }
  }
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GMAPKEY}
    >
      <Grid container>
        <Grid item lg={9} xs={9} md={9} sm={9}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={11}
          >
            { /* Child components, such as markers, info windows, etc. */ }
            <>
            { (markers && markers.length) ? <OurMarkers markers={markers} /> : null }
            </>
          
          </GoogleMap>
        </Grid>
        <Grid item lg={3} xs={3} md={3} sm={3}>    
          <Sidebar stuff markers={markers} setActiveMarker={updateActiveMarker} activeMarker={activeMarker} />
        </Grid>
      </Grid>

    </LoadScript>
  )
}

// export default React.memo(MyComponent)
const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(React.memo(MyComponent))

// import React from 'react'
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
// import { Button, CircularProgress }  from '@material-ui/core';
// const options = {
//   zoomControlOptions: {
//     position: 'right-center',
//     // ...otherOptions
//   }
// }

// function MyComponent() {
//   const { isLoaded, loadError } = useJsApiLoader({
//     googleMapsApiKey: {process.env.REACT_APP_GMAPKEY} // ,
//     // ...otherOptions
//   })

//   const renderMap = () => {
//   // console.log('Render Map')
//     // wrapping to a function is useful in case you want to access `window.google`
//     // to eg. setup options or create latLng object, it won't be available otherwise
//     // feel free to render directly if you don't need that
//     const onLoad = () => {
//     // console.log('On Load')
//       function onLoad (mapInstance) {
//         // do something with map Instance
//       // console.log('On Load....')
//       }
//     }
//     return <GoogleMap
//       options={options}
//       onLoad={onLoad}
//       style={{ height: '90vh', width: '100vw'}}
//     >
//       {
//         // ...Your map components
//       }
//     </GoogleMap>
//   }

//   if (loadError) {
//     return <div>Map cannot be loaded right now, sorry.</div>
//   }

//   return isLoaded ? renderMap() : <CircularProgress />
// }

// export default React.memo(MyComponent)
