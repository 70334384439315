import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import VariableBilling from './VariableBilling'
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import DialogContentText from '@material-ui/core/DialogContentText';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '10px',
    // margin: '5px'
  }
}));

// function htmlToText(html){
//   // console.log('Strip html tags: ', html)
//   //remove code brakes and tabs
//   html = html.replace(/\n/g, "");
//   html = html.replace(/\t/g, "");

//   //keep html brakes and tabs
//   html = html.replace(/<table>/g, "");
//   html = html.replace(/<tr>/g, "");
//   html = html.replace(/<td>/g, "");
//   html = html.replace(/<\/td>/g, "\t");
//   html = html.replace(/<\/table>/g, "\n");
//   html = html.replace(/<\/tr>/g, "\n");
//   html = html.replace(/<\/p>/g, "\n");
//   html = html.replace(/<\/div>/g, "\n");
//   html = html.replace(/<div>/g, "");
//   html = html.replace(/<\/h>/g, "\n");
//   html = html.replace(/<br>/g, "\n"); 
//   html = html.replace(/<br( )*\/>/g, "\n");
//   // console.log('Stripped: ', html)
//   //parse html into text
//   // var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
//   // return dom.body.textContent;
//   return html;
// }

const NewMtcAgreement = (props) => {
  // console.log('New Mtc Agreement: ', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [jobName, setJobName] = React.useState('')
  const [gasSurcharge, setGasSurcharge] = React.useState(true)
  const [environmentalSurcharge, setEnvironmentalSurcharge] = React.useState(true)
  // const [febPrice, setFebPrice] = React.useState(0)
  // const [variablePricing, setVariablePricing] = React.useState([
  //   {
  //     janPrice: 0
  //   },
  //   {
  //     febPrice: 0
  //   }
  // ])
  const daysOfTheWeek = [
    {
      name: 'No Preference',
      num: 0
    },
    {
      name: 'Sunday',
      num: 1
    },
    {
      name: 'Monday',
      num: 2
    },
    {
      name: 'Tuesday',
      num: 3
    },
    {
      name: 'Wednesday',
      num: 4
    },
    {
      name: 'Thursday',
      num: 5
    },
    {
      name: 'Friday',
      num: 6
    },
    {
      name: 'Saturday',
      num: 7
    }
  ]
  const servicePlans = [
    {
      name: 'Basic Service Plan',
      num: 1,
      value: 'BSP'
    },
    {
      name: 'Advantage Service Plan',
      num: 2,
      value: 'ASP'
    },
    {
      name: 'Benhart Hourly Plan',
      num: 3,
      value: 'BHP'
    },
    {
      name: 'Flat Rate Plan (Benhart)',
      num: 4,
      value: 'FRP'
    },
    // {
    //   name: 'Super Service Plan',
    //   num: 3,
    //   value: 'SSP'
    // }
  ]

  const frequencyOptions = [
    {
      name: 'Weekly',
      num: 1,
      value: 'weekly'
    },
    {
      name: 'Bi-Weekly',
      num: 2,
      value: 'biweekly'
    },
    {
      name: 'Monthly',
      num: 3,
      value: 'monthly'
    }
  ]
  const [state, setState] = React.useState({
    notes: '',
    daysOfTheWeek: daysOfTheWeek,
    frequencyOptions: frequencyOptions,
    preferredDay: {name: 'No Preference', num: 0},
    jobSiteAddress: {},
    jobSites: [],
    servicePlan: {name: 'Standard Service Plan', num: 1, value: 'BSP'},
    servicePlans: servicePlans,
    monthlyPrice: 270,
    estMinutes: 3,
    jobSite: {},
    startDate: new Date(),
    variableBillingOptions: [{
      name: 'No'
    }, {name: 'Yes'}],
    variableBilling: {
      name: 'No'
    },
    firstMonthPrice: 0,
    savedVariableBilling: false,
    waterFeatureSchedule: 0,
    irrigationCheckSchedule: 0,
    snoutWeevil: false,
    fertilizations: false,
    lightinCheckSchedule: 0,
    assignedCrew: { name: 'None' },
    crewList: [{ name: 'None' }],
    frequency: { name: 'None' },
    gasSurcharge: false,
    environmentalSurcharge: false
    //   {
    //     name: 'No'
    //   },
    //   {
    //     name: 'Yes'
    //   }
    // ],

  })
  React.useEffect(() => {
    // console.log('Create our Job Name')
    let jobName = 'Maintenance Job'
    if (props && props.client) {
      // let year = new Date().getFullYear().toString().substr(-2)
      // console.log(props.client)
      if (props.client.companyName) {
        jobName = props.client.companyName + ' - Maintenance '
      } else {
        jobName = props.client.lastName + ' - Maintenance '
      }
      // console.log(jobName)
      setJobName(jobName)
    }
  }, [])

  React.useEffect(() => {
    // console.log('Use Effect New Note')
    // let jobName = 'Job - '
    // if (props.client.lastName) jobName = props.client.lastName + '- '
    // if (props.client.companyName) jobName = props.client.companyName + '- '
    setOpen(props.open)
    setState({...state, crewList: props.crewList, jobSites: props.jobSites, jobSiteAddress: props.jobSites[0], jobSite: props.jobSites[0]})
  }, [props])

const handleClose = () => {
  // console.log('Closing New Note Modal')
  // setOpen(false)
  // setState({
  //   note: ''
  // })
  props.updateParent('Close')
}

const handleConfirm = (data) => {
  // console.log('CONFIRMED NEW JOB!!', data)
}
const handleSave = () => {
  setLoading(true)
  // console.log('handle save', state)
  // console.log(props)
  let obj = {
    client: props.client,
    details: state,
    jobName: jobName,
    user: props.user,
    gasSurcharge,
    environmentalSurcharge
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/createNewMtcAgreement`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to create new Note: ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response.data._id) {
      props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'New Maintenance Agreement was created and saved!',
        message: 'Well Done!!'
      })
      // setOpen(false)
    } else {
      // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up creating this workorder...not sure why!'
      })
    }
    
  }).catch(err => {
    // console.log('Error saving workorder: ', err)
  })
}
const formatOption = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.address) {
    return `${opt.name} - ${opt.address} ${opt.city}`
  } else {
    return ''
  }
}
const updateAutoComplete = (id, value) => {
  console.log('Update our autocomplete: ', id)
  console.log('Vauel:', value)
  let split = id.split('-')
  // console.log('Split: ', split)
  let optNum = split[2]
  let optItem = split[0]
  // console.log('Update: ', optItem)
  // console.log('To number: ', optNum)
  let newItem = null
  if (optItem === 'preferredDay') {
  newItem = state.daysOfTheWeek[optNum]
  setState({...state, preferredDay: newItem})
  }
  if (optItem === 'jobSite') {
    newItem = state.jobSites[optNum]
    // console.log('New job site: ', newItem)
    setState({...state, jobSite: newItem})
  }
  if (optItem === 'servicePlan') {
    newItem = state.servicePlans[optNum]
    setState({...state, servicePlan: newItem})
  }
  if (optItem === 'frequency') {
    newItem = state.frequencyOptions[optNum]
    setState({...state, frequency: newItem})
  }
  if (optItem === 'variableBilling') {
    newItem = state.variableBillingOptions[optNum]
    setState({...state, variableBilling: newItem})
  }
  if (optItem === 'assignedCrew') {
    newItem = state.crewList[optNum]
    setState({...state, assignedCrew: newItem})
  }
  // state[optItem] = newItem
  // console.log('Item to update: to: ', newItem)
}

const updateMonthlyPrice = (id, value) => {
  // console.log('Update our Monthly Price', id)
  // console.log(value)
  setState({...state, monthlyPrice: value})
  // ,
  //   janPrice: value,
  //   febPrice: value,
  //   marchPrice: value,
  //   aprilPrice: value,
  //   mayPrice: value,
  //   junePrice: value,
  //   julyPrice: value,
  //   augPrice: value,
  //   septPrice: value,
  //   octPrice: value,
  //   novPrice: value,
  //   decPrice: value
  // })
}

const updateJobSiteAddress = (e, addy) => {
  // console.log(e)
  // console.log('addy: ', addy)
  setState({...state, jobSite: addy, jobSiteAddress: addy})
}

const saveVariableBilling = (data) => {
  // console.log('Save our variable billing!!', data)
  setState({...state, savedVariableBilling: true, variablePricing: data })
}

return (
  <div className={classes.root}>
    <Dialog open={open} fullWidth={true} onClose={handleClose} maxWidth='sm' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create New Maintenance Agreement</DialogTitle>
        <DialogContent >
        <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
          <CloseIcon />
        </IconButton>
          <DialogContentText>
            Please fill out the information below. After saving, the system will automatically generate a new Maintenance Job for this customer.
          </DialogContentText>
          <Grid container>
          <Grid item lg={12} xs={12} className={classes.gridItem}>
              <TextField
                id="name"
                label="Job Name"
                type="text"
                fullWidth
                value={jobName}
                className={classes.textField}
                variant="standard"
                onChange={ e => setJobName(e.target.value) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          <Grid item lg={12}>
            <Autocomplete
            id="jobSite"
            options={state.jobSites}
            getOptionLabel={(option) => (formatOption(option))}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
            onChange={updateJobSiteAddress}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {state.jobSite}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} label="Job Site" />}
          />

            </Grid>
            <Grid item lg={6} xs={12} className={classes.gridItem}>
              <TextField
                id="date"
                label="First Payment Date"
                type="date"
                fullWidth
                value={moment(state.startDate).format('YYYY-MM-DD').toLocale } // "2017-05-24"}
                className={classes.textField}
                variant="standard"
                onChange={ e => setState({...state, startDate: e.target.value }) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item lg={6} xs={12} className={classes.gridItem}>
            <TextField
              id="firstMonthPrice"
              label="First Month Price"
              fullWidth
              // className={classes.paddedStuff}
              // type="number"
              variant="standard"
              value= {state.firstMonthPrice}
              onChange={ e => setState({...state, firstMonthPrice:e.target.value } ) }
              // onChange={ (e) => updateMonthlyPrice( e.target.id, e.target.value) }
              />
            </Grid>
            <Grid item lg={12}>
            <Autocomplete
              id="frequency"
              options={state.frequencyOptions}
              getOptionLabel={(option) => option.name}
              className={classes.paddedStuff}
              // style={{ width: 200 }}
              // onChange={updateServiceType}
              // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
              value = {state.frequency}
              onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              // onChange={ e => setState({...state, preferredDay: e.target.value }) }
              renderInput={(params) => <TextField {...params} label="Visit Frequency" variant="standard" />}
            />
            </Grid>
            <Grid item lg={12}>
            <Autocomplete
              id="preferredDay"
              options={state.daysOfTheWeek}
              getOptionLabel={(option) => option.name}
              className={classes.paddedStuff}
              // style={{ width: 200 }}
              // onChange={updateServiceType}
              // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
              value = {state.preferredDay}
              onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              // onChange={ e => setState({...state, preferredDay: e.target.value }) }
              renderInput={(params) => <TextField {...params} label="Assigned Day Of The Week" variant="standard" />}
            />
            </Grid>
            <Grid item lg={12}>
            <Autocomplete
              id="assignedCrew"
              options={state.crewList}
              getOptionLabel={(option) => option.name}
              className={classes.paddedStuff}
              // style={{ width: 200 }}
              // onChange={updateServiceType}
              onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              value = {state.assignedCrew}
              // onChange={ e => setState({...state, assignedCrew: e.target.value }) }
              renderInput={(params) => <TextField {...params} label="Assigned Crew" variant="standard" />}
            />
            </Grid>
            <Grid item lg={12}>
            <Autocomplete
              id="servicePlan"
              options={state.servicePlans}
              getOptionLabel={(option) => option.name}
              className={classes.paddedStuff}
              
              // style={{ width: 200 }}
              // onChange={updateServiceType}
              onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              value = {state.servicePlan}
              // onChange={ e => setState({...state, servicePlan: e.target.value }) }
              renderInput={(params) => <TextField {...params} label="Service Plan" variant="standard" />}
            />
            </Grid>
            <Grid item lg={6} className={classes.paddedStuff}>
            <TextField
              label="Estimated Minutes"
              fullWidth
              id="estMinutes"
              // type="number"
              variant="standard"
              value= {state.estMinutes}
              // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              onChange={ e => setState({...state, estMinutes:e.target.value } ) }
              />
            </Grid>
            <Grid item lg={6} className={classes.paddedStuff}>
            <TextField
              id="monthlyPrice"
              label="Monthly Price"
              fullWidth
              // className={classes.paddedStuff}
              // type="number"
              variant="standard"
              value= {state.monthlyPrice}
              // onChange={ e => setState({...state, monthlyPrice:e.target.value } ) }
              onChange={ (e) => updateMonthlyPrice( e.target.id, e.target.value) }
              />
            </Grid>
           
            <Grid item lg={12}>
            <Autocomplete
              id="variableBilling"
              options={state.variableBillingOptions}
              getOptionLabel={(option) => option.name}
              className={classes.paddedStuff}
              
              // style={{ width: 200 }}
              // onChange={updateServiceType}
              onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              value = {state.variableBilling}
              // onChange={ e => setState({...state, variableBilling: e.target.value }) }
              renderInput={(params) => <TextField {...params} label="Variable Billing" variant="standard" />}
            />
            </Grid>
            <Grid item lg={12}>
              { state.variableBilling.name === 'Yes' ? (
          
                <VariableBilling client={props.client} saveVariableBilling={saveVariableBilling} monthlyFee={state.monthlyPrice} stuff={state} />

              ) : '' }
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              <TextField
              id="notes"
              label="Notes"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              // className={classes.textSaveLineBreaks}
              value={state.notes}
              onChange={e => setState({...state, notes: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
              // defaultValue="Default Value"
            />
            </Grid>
            <Grid item lg={12}>
                <h3>Service Options</h3>
            </Grid>
           <Grid item lg={12} className={classes.paddedStuff}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="demo-simple-select-helper-label">Water Features</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={state.waterFeatureSchedule}
                  onChange={(e) => setState({...state, waterFeatureSchedule: e.target.value })}
                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Monthly</MenuItem>
                  <MenuItem value={2}>Quarterly</MenuItem>
                  <MenuItem value={3}>Bi-Annually</MenuItem>
                  <MenuItem value={4}>Annually</MenuItem>
                </Select>
                <FormHelperText>Water Feature Service Schedule </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={12} className={classes.paddedStuff}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="irrigation-check">Irrigation Checks</InputLabel>
                <Select
                  labelId="irrigation-check-opetions"
                  id="irrigation-check--schedule"
                  value={state.irrigationCheckSchedule}
                  onChange={(e) => setState({...state, irrigationCheckSchedule: e.target.value })}
                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Monthly</MenuItem>
                  <MenuItem value={2}>Quarterly</MenuItem>
                  <MenuItem value={3}>Bi-Annually</MenuItem>
                  <MenuItem value={4}>Annually</MenuItem>
                </Select>
                <FormHelperText>Irrigation Check Schedule </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={12} className={classes.paddedStuff}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="lighting-check">Lighting Checks</InputLabel>
                <Select
                  labelId="lighting-check-opetions"
                  id="lighting-check--schedule"
                  value={state.lightinCheckSchedule}
                  onChange={(e) => setState({...state, lightinCheckSchedule: e.target.value })}
                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Monthly</MenuItem>
                  <MenuItem value={2}>Quarterly</MenuItem>
                  <MenuItem value={3}>Bi-Annually</MenuItem>
                  <MenuItem value={4}>Annually</MenuItem>
                </Select>
                <FormHelperText>Lighting Check Schedule </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={12} className={classes.paddedStuff}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="fertilizations-check">Fertilizations</InputLabel>
                <Select
                  labelId="fertilizations-check-opetions"
                  id="fertilizations-check--schedule"
                  value={state.fertilizations}
                  onChange={(e) => setState({...state, fertilizations: e.target.value })}
                >
                  <MenuItem value={false}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={true}>Twice a Year</MenuItem>
                </Select>
                <FormHelperText>Include Fertilizaitons (2x Per Year)</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={12} className={classes.paddedStuff}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel id="snoutweevil-check">Snout Weevil</InputLabel>
                <Select
                  labelId="snoutweevil-check-opetions"
                  id="snoutweevil-check--schedule"
                  value={state.snoutWeevil}
                  onChange={(e) => setState({...state, snoutWeevil: e.target.value })}
                >
                  <MenuItem value={false}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={true}>Twice a Year</MenuItem>
                </Select>
                <FormHelperText>Include Snout Weevil (2x Per Year)</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item lg={6} className={classes.paddedStuff}>
              Gas Surcharge<br/>
              <FormControlLabel
              control={
                <Switch
                  checked={gasSurcharge}
                  onChange={() => setGasSurcharge(!gasSurcharge)}
                  name="Active"
                  color="primary"
                />
              }
              label={gasSurcharge ? 'Active' : 'No Surcharge'}
            />
            </Grid>
            
          </Grid>

        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading || (state.variableBilling.name === 'Yes' && !state.savedVariableBilling) || !state.servicePlan || !state.jobSiteAddress || !state.estMinutes || !state.monthlyPrice} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(NewMtcAgreement))