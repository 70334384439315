import React from 'react'
import { Button, CircularProgress, IconButton, Paper, Chip, InputAdornment, TextField }  from '@material-ui/core';
import { makeStyles, fade} from '@material-ui/core/styles';
import { currencyFormat, currencyFormatWithOutFlair } from '../../services/common'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import moment from 'moment'
import DeleteComponent from '../Common/DeleteComponent'
import Tooltip from '@material-ui/core/Tooltip';
import DropDownButton from '../Common/DropdownButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PageviewIcon from '@material-ui/icons/Pageview';
import DailyLogInputModal from './DailyLogInputModal'
import SearchIcon from '@material-ui/icons/Search'

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  groupContainer: {
    border: '3px solid #ddd',
    borderRadius: 8,
    padding: 10,
    marginBottom: theme.spacing(3),
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignSelf: 'stretch',
    alignItems: 'center'
  },
  headerArea: {
    display: 'flex',
    flexGrow: 1,
    // backgroundColor: 'red',
    fontSize: '1.3em',
    fontWeight: 'bold'
  },
  detailsArea: {
    display: 'flex',
    flexGrow: 3,
    justifyContent: 'space-evenly',
    alignContent: 'flex-start',
    alignSelf: 'stretch'
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  logItemContainer: {
    display: 'flex',
    justifyContent: 'space-between', 
    margin: '5px', 
    padding: '10px'
  },
  logItemDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    alignItems: 'center',
    flexGrow: 3
  },
  dumpFeeArea: {
    marginLeft: 20,
    alignItems: 'center',
    width: '15%',
    justifyContent: 'center',
    display: 'flex',
  },
  actionArea: {
    marginLeft: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  topRowButton: {
    margin: 45,
    marginTop: 10,
    marginBottom: 10,
    width: 276
  },
  summaryOutputContainer: {
    display: 'flex',
    alignSelf: 'stretch'
  },
  summaryDataContainer: {
    margin: 20,
    padding: 20
  },
  centerCell: {
    textAlign: 'center'
  },
  logDetailRowItem: {
    background: `rgba(192,63, 68, .7)`
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '75%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const dropDownOptions = [
    {
      name: 'Approve',
      icon: <ThumbUpIcon />
    },
    {
      name: 'Edit',
      icon: <EditIcon />
    },
    {
      name: 'View Attachments',
      icon: <PageviewIcon />
    },
    
  ]

  function desc(a, b, orderBy, isDate) {
    // console.log('desc: ', a)
    // console.log('desc b: ', b)
    if (isDate) {
      if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
        // console.log('B is < A')
        return -1;
      }
      if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
        // console.log('B is > A')
        return 1;
      }
      // console.log('B is ? A')
      // console.log('A: ', a[orderBy])
      // console.log('B: ', b[orderBy])
      return 0;
    } else {
      if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
        return -1;
      }
      if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
        return 1;
      }
      return 0;
    }
  }
  
  function stableSort(array, cmp) {
    // console.log('Stable sort: ', array.length)
    // console.log('CMP: ', cmp)
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    // console.log('Matches: ', stabilizedThis.length)
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    if (orderBy==='lastUpdated') {
      // console.log('Last Updated')
      return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
    } else {
      return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
    }
    
  }
  
  const headCells = [
    { id: 'date', numeric: false, disablePadding: true, label: 'Date' },
    { id: 'logType', center: false, disablePadding: false, label: 'Log Type' },
    { id: 'tags', numeric: false, disablePadding: false, label: 'Log Details' },
    { id: 'crewName', numeric: false, disablePadding: false, label: 'Crew Info' },
    { id: 'startTime', numeric: false, disablePadding: false, label: 'Start/STop Time' },
    { id: 'hoursWorked', numeric: false, disablePadding: false, label: 'Hours Worked' },
    { id: 'other_fees', numeric: false, disablePadding: false, label: 'Other Fees' },
    { id: 'created_by', numeric: false, disablePadding: false, label: 'Entered By' },
    { id: 'created_at', numeric: false, disablePadding: false, label: 'Last Update' },
    { id: 'action', numeric: false, disablePadding: true, label: 'Actions' },
  
  ];
  
  function EnhancedTableHead(props) {
    // console.log('Got our props', props)
    
    const { classes, order, orderBy, onRequestSort, num } = props;
    const createSortHandler = property => event => {
      // console.log('Sort Property: ', property)
      // console.log('sort event; ', event)
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {/* <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all leads' }}
            />
          </TableCell> */}
          {headCells.map(headCell => (
            <TableCell
              key={headCell.id}
              align={headCell.center ? 'center' : headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={order}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  // EnhancedTableHead.propTypes = {
  //   classes: PropTypes.object.isRequired,
  //   // numSelected: PropTypes.number.isRequired,
  //   onRequestSort: PropTypes.func.isRequired,
  //   // onSelectAllClick: PropTypes.func.isRequired,
  //   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  //   orderBy: PropTypes.string.isRequired,
  //   rowCount: PropTypes.number.isRequired,
  // };
    
const HMIDailyLogTable = React.memo((props) => {
  const classes= useStyles()
  // console.log('Render HMIDailyLogTable', props)
  let data = props.rows
  const [showEditModal, setShowEditModal] = React.useState(false)
  const [activeItem, setActiveItem] = React.useState({})
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [searchValue, setSearchValue] = React.useState('')
  const [filteredRows, setFilteredRows] = React.useState([])

  React.useEffect(() => {
    setFilteredRows(props.rows)
  }, [props.rows])

  const handleDeleteLog = React.useCallback((dailyLogId) => {
    // console.log('Delete this one:', dailyLogId)
    // console.log(groupedData[index])
    const arrItem = [ ...data ]
    let index = arrItem.findIndex(item => {
      // console.log('Item to check', item)
      return (item._id === dailyLogId)
    })
    // console.log('Index:', index)
    if (index > -1) {
      const itemToRemove = arrItem[index]
      // console.log('Delete this one', itemToRemove)
      props.deleteDailyLogItem(itemToRemove)
    }
  }, [data, props.deleteDailyLogItem])

  const handleEditLog = (itemToEdit) => {
    props.editDailyLogItem(itemToEdit)
  }

  const handleViewLogDetails = (itemId) => {
    console.log('View Log ', itemId)
    props.viewItemDetails(itemId)
  }

  const handleOptionSelection = (opt, item) => {
    console.log('Chose this option', opt)
    console.log('Item', item)
    if (opt && opt.name === 'Edit') {
      handleEditLog(item)
    }
    if (opt && opt.name === 'View Attachments') {
      handleViewLogDetails(item._id)
    }
    if (opt && opt.name === 'Delete') {
      handleDeleteLog(item._id)
    }
    if (opt && opt.name === 'Approve') {
      handleApproveLog(item._id)
    }
  }

  const handleApproveLog = (logId) => {
    const msg = `Are you sure you wish to Approve this Daily Log?`
    if (window.confirm(msg)) {
      props.approveDailyLogItem(logId)
    }
  }
  
  const handleResponseFromDailyInput = (data, type) => {
    console.log('Daily Input Response', data)
    console.log('Type', type)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };
  
  const handleSearch = (e) => {
    setSearchValue(e.target.value)
    if (e.target.value === '') {
      setFilteredRows(props.rows)
    } else {
      filterOut(props.rows, e.target.value)
    }
    setPage(0)
  }

  const filterOut = (r, searchText) => {
    // console.log('FIlter out:- %s', searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          if (typeof o[k] === 'object') {
            const itemObject = o[k]
            Object.keys(itemObject).some(t => {
              if (itemObject[t]) {
                let str = itemObject[t].toString().toLowerCase() 
                // console.log('String', str)
                if (str.includes(string.toLowerCase())) {
                  // console.log('Mathc')
                  return newArr.push(o)
                } else {
                  return null
                }
              } else {
                return null
              }
            })
          } else {
            let str = o[k].toString().toLowerCase() 
            // console.log('String', str)
            if (str.includes(string.toLowerCase())) {
              // console.log('Mathc')
              return newArr.push(o)
            } else {
              return null
            }
          }
        }

      })
      // return newArr
    })
    setFilteredRows(newArr)
  }


  return (
    <div className={classes.summaryDataContainer}>
      {/* <DailyLogInputModal notification={props.notification} url={props.url} mtcSites={props.mtcSites} open={showEditModal} itemToEdit={activeItem} ags={props.ags} crews={props.crews} updateParent={handleResponseFromDailyInput} /> */}
     
      <div className={classes.search}>
          {/* <div >
            <SearchIcon /> Search { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
          </div> */}
          {/* <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input> */}
          <TextField
              name="search" 
              value={ searchValue } 
              onChange={ handleSearch }
              label="Search"
              type="search"
              variant="outlined"
              margin="normal"
              
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
          />
          {/* <div className={classes.fakeLink} onClick={toggleDeepSearch}>Go Deeper</div> */}
        </div>
      Summary data
      <Table>
        <EnhancedTableHead
          classes={classes}
          // numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          // onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={filteredRows.length}
        />
        <TableBody>
        { stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {

            // console.log('Daily Log item:', item)
            let dumpFeeTotal = 0
            let dumpFees = []
            if (item && item.dumpFees && item.dumpFees.length) dumpFees = item.dumpFees
            dumpFees.forEach(dumpFee => {
              // console.log('dump Fee', dumpFee)
              if (dumpFee && dumpFee.fee) {
                let fee = parseFloat(dumpFee.fee)
                if (fee && fee > 0) {
                  dumpFeeTotal += Number(fee)
                }
              }                    
            })
            let irrigationTotal = 0
            let irrigationParts = []
            if (item && item.irrigationParts && item.irrigationParts.length) irrigationParts = item.irrigationParts
            irrigationParts.forEach(irrigationPart => {
              // console.log('dump Fee', dumpFee)
              if (irrigationPart && irrigationPart.fee) {
                let fee = parseFloat(irrigationPart.fee)
                if (fee && fee > 0) {
                  irrigationTotal += Number(fee)
                }
              }                    
            })
            return (
              <TableRow key={index}>
                <TableCell>{item.date}</TableCell>
                 <TableCell>{item.logType}</TableCell>
                <TableCell>
                  <LogDetails item={item} />
                </TableCell>
                <TableCell >
                  {item.crewName}
                </TableCell  >
                <TableCell  >
                  {item.timeIn} - {item.timeOut}
                </TableCell>
                <TableCell  >
                  {item.hoursWorked}
                </TableCell>
                
                
                <TableCell  >
                  <div>
                    {
                      (dumpFeeTotal > 0) ? (
                          `${item.dumpFees.length} Dump Trip${item.dumpFees.length > 1 ? 's' : ''} - ${currencyFormat(dumpFeeTotal)}`
                      ) : ''
                    }
                  </div>
                  <div>
                    {
                      (irrigationTotal > 0) ? (
                          `${item.irrigationParts.length} Irrigation Part${item.irrigationParts.length > 1 ? 's' : ''} - ${currencyFormat(irrigationTotal)}`
                      ) : ''
                    }
                  </div>
                  
                </TableCell>
                <TableCell>{item.created_by}</TableCell>
                <TableCell>{moment(item.created_at).format('MM/DD/YY hh:mma')}</TableCell>
                <TableCell align='center' >
                  <div className={classes.actionArea}>
         
                          <DropDownButton
                            name='Action'
                            options={dropDownOptions}
                            chooseOption={(opt) => {
                              // console.log('Option selected...277 ', opt)
                              handleOptionSelection(opt, item)
                            }}
                          />
                         
                            <DeleteComponent
                              type="Jobs"
                              item={item}
                              title="Delete Daily Log?"
                              verificationText={`Are you sure you wish to Delete this Daily Log Item?`}
                              confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
                              confirmationButton="DELETE"
                              denyText="NO"
                              ifApproved={e => handleDeleteLog(item._id)}
                              deleteFunction={'hmiDailyLog'}
                            />
                        
                         
                      </div>
                </TableCell>
              </TableRow>
            )
          })
        }
        </TableBody>
      </Table>
      <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500, 1000, 2000]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    </div>
  )
})

const LogDetails = ({ item }) => {
  const classes = useStyles()
  // console.log('Log Details item:', item)
  const client = item.client
  const jobSite = item?.jobSite
  const jobSiteInfo = (jobSite && jobSite._id) ? `${jobSite?.communityName ? jobSite.communityName : ''} ${jobSite.name} ${jobSite.address} ${jobSite.buildingNumber ? jobSite.buildingNumber : ''}` : 'Un-Known Site'
  const clientName = (client && client.searchName) ? client.searchName : 'Un-Known Client'
  return (
    <div>
      {item.crewSize ? `${item.crewSize} Men` : <Chip key={'active'} className={classes.logDetailRowItem} size="small" label="No Crew Size" /> }
      {
        item.logType === 'ADOT' ? (
          <div>
            {item?.mtcAgreement?.jobName}
          </div>
        ) : (item.logType === 'NPKWY' || item.logType === 'Northern Parkway') ? (
          <div>
            {item.laborType}
          </div>
        ) : (
          <div>
           {
            (jobSite && jobSite._id) ? (
              `${jobSiteInfo}`
            ) : `${clientName}`
           }
          </div>
        )
      }
    </div>
  )
}
export default HMIDailyLogTable