import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { fade, makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputMask from "react-input-mask";
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const defaultState = {
  firstName: '',
  lastName: '',
  email: '',
  note: '',
  receiveInvoices: true,
  receiveStatements: true,
  receiveMarketing: true,
  phoneNumbers: [
    {
      number: '',
      type: 'Primary'
    }
  ],
  contact: {
    phoneNumbers: []
  }
}

const EditContactModal = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState(defaultState)
  const [contact , setContact] = React.useState({ phoneNumbers: [] })

  React.useEffect(() => {
  // console.log('Edit Contact: ', props.contact)
    if (props.contact && props.contact._id) {
      let theContact = props.contact
      if (theContact && theContact.phoneNumbers && theContact.phoneNumbers.length) {

      } else {
        theContact.phoneNumbers = [
          {
            number: '',
            type: 'Primary'
          }
        ]
      }
      setContact(theContact)
    // console.log('Set Contact', theContact)
      setState({...state, contact: theContact})
    }
  }, [props])

  const handlePhoneNumberChange = (e, phone, index) => {
  // console.log('Handle Phone Number Change: ', phone)
  // console.log(index)
  // console.log(e.target.value)
    let newPhoneNumbers = [...contact.phoneNumbers]
    newPhoneNumbers[index] = {
      number: e.target.value,
      type: phone.type
    }
  // console.log('new Phone Numbers: ', newPhoneNumbers)
    setContact({...contact, phoneNumbers: newPhoneNumbers})
  }
  
  const addPhoneNumber = () => {
    let newPhoneNumbers = [...contact.phoneNumbers]
    newPhoneNumbers.push({
      number: '',
      type: 'Primary'
    })
    setContact({...contact, phoneNumbers: newPhoneNumbers})
  }
  
  const removePhoneNumber = (index) => {
    if (state.phoneNumbers.length > 1) {
      if (window.confirm('Are you sure you wish to delete this phone number? This is not reversible.')) {
        let newPhoneNumbers = [...contact.phoneNumbers]
        newPhoneNumbers.splice(index, 1)
        setContact({...contact, phoneNumbers: newPhoneNumbers})
      }
    }
  }
  
  const changePhoneType = (e, phone, index) => {
  // console.log('Phone Type Change: ', phone)
  // console.log(e.target.value)
    let updatedNumbers = contact.phoneNumbers
    updatedNumbers[index]['type'] = e.target.value
    setContact({...contact, phoneNumbers: updatedNumbers})
  }
  const handleClose = () => {
    props.closeModal()
  } 
  const handleSave = () => {
    props.saveContact(contact)
  }
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      // size={'lg'}
      maxWidth='lg'
      fullWidth
    >
        {/* <ContactComponent open={showNewContactModal} saveContact={saveContact} closeModal={handleCoseAddVCModal} /> */}
        <DialogTitle>Edit Vendor Contact </DialogTitle>
        <DialogContent>
          <DialogContentText>{(contact && contact._id) ? `${contact.firstName} ${contact.lastName}` : ''}</DialogContentText>
        <Grid container>
          <Grid item lg={6} xs={6} className={classes.gridItem}> 
                  <TextField
                    
                    label="Contact First Name"
                    onChange={ e => setContact({...contact, firstName: e.target.value })}
                    name="customerfirstname"
                    value={ contact.firstName }
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item lg={6} xs={6} className={classes.gridItem}> 
                            
                  <TextField
                  
                    label="Contact Last Name"
                    onChange={ e => setContact({...contact, lastName: e.target.value })}
                    name="customerlastname"
                    value={ contact.lastName }
                    margin="normal"
                    fullWidth
                  />
                </Grid>
                <Grid item lg={12} xs={12}>
                  <TextField
                      className={classes.paddedStuff}
                      margin="dense"
                      id="Email"
                      label="Email"
                      type="text"
                      onChange={(e) => setContact({...contact, email: e.target.value})}
                      value={contact.email}
                      fullWidth
                  />
                
                </Grid>
                <Grid item lg={12} xs={12}>
                  { contact.phoneNumbers.map((phone, index) => (
                    <Grid container key={index}>
                    <Grid item lg={4} xs={4}>
                    <InputMask mask="(999) 999-9999" maskPlaceholder=" " onChange={e => handlePhoneNumberChange(e, phone, index)}  value={phone.number} >
                          {
                            () => {
                              return (
                                <TextField
                                  className={classes.paddedStuff}
                                  margin="dense"
                                  id="Telephone"
                                  label={"Telephone Number #" + (index - 0 + 1)}
                                  type="telephone"
                                  // onChange={(e) => handlePhoneNumberChange(e, phone, index)}
                                  // value={state.phoneNumbers[index]['number']}
                                  // value={phone.number}
                                  fullWidth
                              />
                              )
                            }
                          }
                          </InputMask>
                          </Grid>
                          <Grid item lg={4} xs={4}>
                          <FormControl className={classes.formControl} fullWidth>
                            <Select
                              value={phone.type}
                              onChange={(e) => changePhoneType(e, phone, index)}
                              // onChange={(e) => setState({...state, phone['type']: e.target.value })}
                              className={classes.selectEmpty}
                              inputProps={{ 'aria-label': 'Phone Number Type' }}
                              fullWidth
                              placeholder="Phone Number Type"
                            >
                              <MenuItem value={"Primary"}>
                                Primary
                              </MenuItem>
                              <MenuItem value={"Home"}>Home</MenuItem>
                              <MenuItem value={"Mobile"}>Mobile</MenuItem>
                              <MenuItem value={"Work"}>Work</MenuItem>
                            </Select>
                            <FormHelperText>Phone Number Type</FormHelperText>
                          </FormControl>
                          </Grid>
                          <Grid item lg={2} xs={2} style={{
                              display: 'flex',
                              marginLeft: '50px',
                              alignItems: 'center',
                              flexWrap: 'wrap'
                          }}>
                          <IconButton edge='start' alt="Remove" aria-label="Remove" onClick={removePhoneNumber}>
                              <RemoveCircleIcon />
                            </IconButton>
                            { index === contact.phoneNumbers.length - 1 ?  <IconButton edge='end' alt="Add" aria-label="Add" onClick={addPhoneNumber}>
                              <AddCircleIcon />
                            </IconButton> : ''}
                          </Grid>
                    </Grid>
                  ))}
              </Grid>
              <Grid item lg={12} xs={12}>

              <TextField
                
                className={classes.paddedStuff}
                variant="outlined"
                id="Note"
                label="Contact Notes"
                multiline
                rows={4}
                type="text"
                onChange={(e) => setContact({...contact, notes: e.target.value})}
                value={contact.notes}
                fullWidth
              />
              </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditContactModal
