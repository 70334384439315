import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Typography, Divider } from '@material-ui/core';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing(2),
  },
  date: {
    fontWeight: 'bold',
  },
  dayOfWeek: {
    color: theme.palette.text.secondary,
  },
  crew: {
    color: theme.palette.text.secondary,
  },
  title: {
    fontSize: '1.2em',
    fontWeight: '500'
  }
}));

const FutureVisitsDetails = ({ visits }) => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
       <Typography
        component="span"
        variant="body2"
        color="textPrimary"
        className={classes.title}
      >
        Future Visit Details
      </Typography>
      {visits.map((visit, index) => {
        const visitDate = new Date(visit.visitDate);
        return (
          <React.Fragment key={visit._id}>
            <ListItem alignItems="flex-start" className={classes.listItem}>
              <ListItemText
                primary={
                  <React.Fragment>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <div className={classes.dateContainer}>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.date}
                          color="textPrimary"
                        >
                          {format(visitDate, 'MMM dd, yyyy')}
                        </Typography>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.dayOfWeek}
                        >
                          {format(visitDate, 'EEEE')}
                        </Typography>
                      </div>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.crew}
                      >
                        {visit.crewId.name} ({visit.crewId?.foremanName})
                      </Typography>
                    </div>
                  </React.Fragment>
                }
              />
            </ListItem>
            {index < visits.length - 1 && <Divider component="li" />}
          </React.Fragment>
        );
      })}
    </List>
  );
};

export default FutureVisitsDetails;