import React, { useEffect, useRef, useState, useCallback } from 'react';
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'
import { Button, CircularProgress } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UploadsList from './UploadsList'

const Accordion = withStyles({
    root: {
      border: '1px solid rgba(0, 0, 0, .125)',
      boxShadow: 'none',
      '&:not(:last-child)': {
        borderBottom: 0,
      },
      '&:before': {
        display: 'none',
      },
      '&$expanded': {
        margin: 'auto',
      },
    },
    expanded: {},
  })(MuiAccordion);
  
  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
        minHeight: 56,
      },
    },
    content: {
      '&$expanded': {
        margin: '12px 0',
      },
    },
    expanded: {},
  })(MuiAccordionSummary);
  
  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiAccordionDetails);

  const UploadsAccordion = ({
    client,
    projects,
    url,
    notification,
    handleChange,
    expanded,
    numberOfUploads
  }) => {
    const [uploads, setUploads] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
  
    const getClientUploads = useCallback(async () => {
      if (!client?._id) return;
  
      try {
        setIsLoading(true);
        setError(null);
        
        const response = await axios.get(`${url}/api/customer/getClientUploads`, {
          params: { id: client._id },
          // Add error handling timeout
          timeout: 10000
        });
        
        if (response?.data) {
          const projUploads = response.data.projectUploads || [];
          const otherUploads = response.data.uploads || [];
          setUploads([...projUploads, ...otherUploads]);
        }
      } catch (err) {
        console.error('Error fetching uploads:', err);
        const errorMessage = err.response?.data?.message || 'Failed to load uploads. Please try again.';
        setError(errorMessage);
        notification({
          type: 'warning',
          title: 'Upload Loading Issue',
          message: errorMessage
        });
      } finally {
        setIsLoading(false);
      }
    }, [client?._id, url, notification]);

    useEffect(() => {
      console.log('Effect triggered - Client:', client?._id, 'Expanded:', expanded);
      
      if (expanded === 'panelUploads' && client?._id) {
        // Clear uploads when client changes
        if (uploads.length > 0) {
          setUploads([]);
        }
        getClientUploads();
      }
    }, [client?._id, expanded]); // Combine both dependencies
  
    const handleAccordionChange = useCallback((panel) => (event, isExpanded) => {
      handleChange(panel)(event, isExpanded);
    }, [handleChange]);

    return (
      <Accordion 
        square 
        expanded={expanded === 'panelUploads'} 
        onChange={(e, expanded) => {
          handleAccordionChange('panelUploads')(e, expanded);
          handleChange('panelUploads')(e, expanded);
        }}
      >
        <AccordionSummary 
          aria-controls="panelUploads-content" 
          expandIcon={<ExpandMoreIcon />} 
          id="panelUploads-header"
        >
          <Typography>
            Uploads (Files/Photos {numberOfUploads || uploads.length})
            {isLoading && <CircularProgress size={16} style={{ marginLeft: 8 }} />}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          
          {error ? (
            <div>
              <Typography color="error">{error}</Typography>
              <Button 
                onClick={() => getClientUploads()} 
                variant="outlined" 
                size="small"
                style={{ marginTop: 8 }}
              >
                Retry Loading
              </Button>
            </div>
          ) : (
            <UploadsList client={client} uploads={uploads} projects={projects} />
          )}
        </AccordionDetails>
      </Accordion>
    );
  };
  
  export default UploadsAccordion;
