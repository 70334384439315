import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const defaultKeyVals = {
  name: '',
  number: '',
  notes: '',
  keyType: 'Key'
}

const NewKeyModal = (props) => {
// console.log('NewEquipmentModal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState(defaultKeyVals)

  const handleSave = () => {
    let obj = {
      data: state
    }
    props.addKey(obj)
  }

  const handleClose = () => {
    props.closeModal()
  }


  return (
    <div className={classes.root}>
      <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth='sm' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Key</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Provide Key Information Below
          </DialogContentText>
          <Grid container>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <TextField
              
                  className={classes.paddedStuff}
                  margin="dense"
                  id="name"
                  label="Name"
                  type="text"
                  // ref={cellNumber}
                  // onChange={updateVal}
                  onChange={(e) => setState({...state, name: e.target.value})}
                  value={state.name}
                  fullWidth
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <TextField
              
                  className={classes.paddedStuff}
                  margin="dense"
                  id="number"
                  label="Key Number"
                  type="text"
                  // ref={cellNumber}
                  // onChange={updateVal}
                  onChange={(e) => setState({...state, number: e.target.value})}
                  value={state.number}
                  fullWidth
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <TextField
                value={state.keyType }
                onChange={(e) => setState({...state, keyType: e.target.value})}
                inputProps={{ 'aria-label': 'Key Type' }}
                fullWidth
                select
                label="Type"
                variant="outlined"
                placeholder="Type of Key"
              >
                <MenuItem value={"Key"}>
                  Key
                </MenuItem>
                <MenuItem value={"Keycard"}>
                  Keycard
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} >
              <TextField
              
                  className={classes.paddedStuff}
                  margin="dense"
                  id="notes"
                  label="Notes"
                  type="text"
                  // ref={cellNumber}
                  // onChange={updateVal}
                  onChange={(e) => setState({...state, notes: e.target.value})}
                  value={state.notes}
                  fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {
              loading ? <CircularProgress /> : 'Save'
            }
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }

  
  const VehicleInput = (props) => {
  // console.log('Vehicle Input:', props)
    const classes = useStyles()
    const [state, setState] = React.useState({
      equipment: {},
      name: '',
      counter: 0
    })

    const handleBlur = () => {
      props.updateParent(state.equipment)
    }
    return (
      <React.Fragment>
      <Grid item lg={9} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            id="Name"
            onBlur={handleBlur}
            label="Equipment Name"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, name: e.target.value}})}
            value={state.equipment.name}
            fullWidth
        />
      </Grid>
      <Grid item lg={4} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            onBlur={handleBlur}
            id="Year"
            label="Year"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, year: e.target.value}})}
            value={state.equipment.year}
            fullWidth
        />
      </Grid>
      <Grid item lg={4} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            onBlur={handleBlur}
            id="Make"
            label="Manufacturer"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, manufacturer: e.target.value}})}
            value={state.equipment.manufacturer}
            fullWidth
        />
      </Grid>
      <Grid item lg={4} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            onBlur={handleBlur}
            id="Model"
            label="Model"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, model: e.target.value}})}
            value={state.equipment.model}
            fullWidth
        />
      </Grid>
      <Grid item lg={6} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            onBlur={handleBlur}
            id="Plate"
            label="Plate"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, plate: e.target.value}})}
            value={state.equipment.plate}
            fullWidth
        />
      </Grid>
      <Grid item lg={6} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            onBlur={handleBlur}
            id="mileage"
            label="Mileage"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, mileage: e.target.value}})}
            value={state.equipment.mileage}
            fullWidth
        />
      </Grid>
      <Grid item lg={12} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            onBlur={handleBlur}
            id="VIN"
            label="VIN"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, vin: e.target.value}})}
            value={state.equipment.vin}
            fullWidth
        />
      </Grid>
      
</React.Fragment>
    )
  }

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(NewKeyModal)