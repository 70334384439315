import React, { useState, useMemo } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TableSortLabel,
  makeStyles,
  Typography,
  Box
} from '@material-ui/core';
import { 
  Build as BuildIcon,
  DirectionsCar as CarIcon,
  AccessTime as TimeIcon,
  Warning as WarningIcon,
  AttachMoney as MoneyIcon
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  headerCell: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cellContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: '0.9rem',
  },
  totalRow: {
    backgroundColor: theme.palette.action.hover,
  },
  overtimeCell: {
    backgroundColor: theme.palette.error.light,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  overtimeWarning: {
    color: theme.palette.error.dark,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  profit: {
    color: theme.palette.success.main,
  },
  loss: {
    color: theme.palette.error.main,
  },
  crewCell: {
    minWidth: '200px',
  },
}));

const WeeklyRouteTable = ({ weeklyRoutes }) => {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
  const OVERTIME_THRESHOLD = 480; // 8 hours in minutes
  const MINUTES_PER_HOUR = 60;
  const OVERTIME_RATE = 1.5;

  const formatTime = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins.toString().padStart(2, '0')}m`;
  };

  const formatMoney = (amount) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount);
  };

  const crewData = useMemo(() => {
    const data = {};

    Object.values(weeklyRoutes).forEach((dayData) => {
      if (days.includes(dayData.day)) {
        dayData.routes.forEach((route) => {
          if (!data[route.crewName]) {
            data[route.crewName] = {
              crewName: route.crewName,
              hourlyRate: route.totalPay,
              weeklyTotals: {
                serviceTime: 0,
                driveTime: 0,
                distance: 0,
                totalTime: 0,
                revenue: 0,
                cost: 0,
                profit: 0,
                mileageCost: 0
              },
              ...days.reduce((acc, day) => ({
                ...acc,
                [day]: { serviceTime: 0, driveTime: 0, distance: 0, totalTime: 0, revenue: 0, mileageCost: 0 }
              }), {})
            };
          }

          data[route.crewName][dayData.day].serviceTime += route.totalServiceTime;
          data[route.crewName][dayData.day].driveTime += route.totalDriveTime;
          data[route.crewName][dayData.day].distance += route.totalMilesDriven;
          data[route.crewName][dayData.day].mileageCost += route.mileageCost;
          data[route.crewName][dayData.day].totalTime += route.totalServiceTime + route.totalDriveTime;
          data[route.crewName][dayData.day].revenue += route.totalRevenue;
        });
      }
    });

    // Calculate costs, profits, and weekly totals
    Object.values(data).forEach(crew => {
      days.forEach(day => {
        const dayData = crew[day];
        console.log('Day data', dayData)
        const regularHours = Math.min(dayData.totalTime, OVERTIME_THRESHOLD) / MINUTES_PER_HOUR;
        const overtimeHours = Math.max(0, dayData.totalTime - OVERTIME_THRESHOLD) / MINUTES_PER_HOUR;
        const laborCost = (regularHours * crew.hourlyRate) + (overtimeHours * crew.hourlyRate * OVERTIME_RATE);
        
        const mileageCost = Number(dayData.mileageCost)
        dayData.cost = laborCost + mileageCost;
        dayData.profit = dayData.revenue - dayData.cost;
        console.log('Mileage Cost:', mileageCost)
        // Update weekly totals
        crew.weeklyTotals.serviceTime += dayData.serviceTime;
        crew.weeklyTotals.driveTime += dayData.driveTime;
        crew.weeklyTotals.distance += dayData.distance;
        crew.weeklyTotals.totalTime += dayData.totalTime;
        crew.weeklyTotals.revenue += dayData.revenue;
        crew.weeklyTotals.cost += dayData.cost;
        crew.weeklyTotals.profit += dayData.profit;
      });
    });

    return Object.values(data);
  }, [weeklyRoutes]);

  const handleSort = (day) => {
    const isAsc = orderBy === day && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(day);
  };

  const sortedData = useMemo(() => {
    if (!orderBy) return crewData;
    return [...crewData].sort((a, b) => {
      if (order === 'asc') {
        return a[orderBy].profit - b[orderBy].profit;
      } else {
        return b[orderBy].profit - a[orderBy].profit;
      }
    });
  }, [crewData, order, orderBy]);

  const renderCell = (crew, day) => {
    const isOvertime = crew[day].totalTime > OVERTIME_THRESHOLD;
    return (
      <TableCell 
        key={day} 
        align="left" 
        className={isOvertime ? classes.overtimeCell : ''}
      >
        <Box className={classes.cellContent}>
          <Typography variant="body2">
            <BuildIcon className={classes.icon} />
            Service: {formatTime(crew[day].serviceTime)}
          </Typography>
          <Typography variant="body2">
            <CarIcon className={classes.icon} />
            Drive: {crew[day].distance.toFixed(1)}mi / {formatTime(crew[day].driveTime)}
          </Typography>
          <Typography variant="body2">
            <TimeIcon className={classes.icon} />
            Total: {formatTime(crew[day].totalTime)}
          </Typography>
          <Typography variant="body2">
            <MoneyIcon className={classes.icon} />
            Revenue: {formatMoney(crew[day].revenue)}
          </Typography>
          <Typography variant="body2">
            Cost: {formatMoney(crew[day].cost)}
          </Typography>
          <Typography variant="body2" className={crew[day].profit >= 0 ? classes.profit : classes.loss}>
            P/L: {formatMoney(crew[day].profit)}
          </Typography>
          {isOvertime && (
            <Typography variant="body2" className={classes.overtimeWarning}>
              <WarningIcon className={classes.icon} />
              Overtime
            </Typography>
          )}
        </Box>
      </TableCell>
    );
  };

  const renderCrewCell = (crew) => (
    <TableCell component="th" scope="row" className={classes.crewCell}>
      <Box className={classes.cellContent}>
        <Typography variant="subtitle1">{crew.crewName}</Typography>
        <Typography variant="body2">
          <TimeIcon className={classes.icon} />
          Total Time: {formatTime(crew.weeklyTotals.totalTime)}
        </Typography>
        <Typography variant="body2">
          <CarIcon className={classes.icon} />
          Total Distance: {crew.weeklyTotals.distance.toFixed(1)}mi
        </Typography>
        <Typography variant="body2">
          <MoneyIcon className={classes.icon} />
          Total Revenue: {formatMoney(crew.weeklyTotals.revenue)}
        </Typography>
        <Typography variant="body2">
          Total Cost: {formatMoney(crew.weeklyTotals.cost)}
        </Typography>
        <Typography variant="body2" className={crew.weeklyTotals.profit >= 0 ? classes.profit : classes.loss}>
          Weekly P/L: {formatMoney(crew.weeklyTotals.profit)}
        </Typography>
      </Box>
    </TableCell>
  );

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell className={classes.headerCell}>Crew</TableCell>
            {days.map((day) => (
              <TableCell key={day} align="center" className={classes.headerCell}>
                <TableSortLabel
                  active={orderBy === day}
                  direction={orderBy === day ? order : 'asc'}
                  onClick={() => handleSort(day)}
                >
                  {day}
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedData.map((crew) => (
            <TableRow key={crew.crewName}>
              {renderCrewCell(crew)}
              {days.map((day) => renderCell(crew, day))}
            </TableRow>
          ))}
          <TableRow className={classes.totalRow}>
            <TableCell>Total</TableCell>
            {days.map((day) => renderCell({
              [day]: {
                serviceTime: sortedData.reduce((sum, crew) => sum + crew[day].serviceTime, 0),
                driveTime: sortedData.reduce((sum, crew) => sum + crew[day].driveTime, 0),
                distance: sortedData.reduce((sum, crew) => sum + crew[day].distance, 0),
                totalTime: sortedData.reduce((sum, crew) => sum + crew[day].totalTime, 0),
                revenue: sortedData.reduce((sum, crew) => sum + crew[day].revenue, 0),
                cost: sortedData.reduce((sum, crew) => sum + crew[day].cost, 0),
                profit: sortedData.reduce((sum, crew) => sum + crew[day].profit, 0)
              }
            }, day))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WeeklyRouteTable;