import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  IconButton,
  Button,
  CircularProgress,
  isMuiElement,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
}  from '@material-ui/core';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Tooltip from '@material-ui/core/Tooltip';
// import NewReminderComponent from '../Reminders/NewReminderComponent'
import MergeTypeIcon from '@material-ui/icons/MergeType'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    zIndex: 8,
    [theme.breakpoints.down('sm')]: {
      // marginLeft: theme.spacing(2),
      width: '100%',
      margin: 0
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: 1
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '10px',
    margin: '5px'
  },
  uploadDropZone: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: '2px dashed #0658c2',
    borderRadius: 10,
    width: 300,
    height: 55,
    margin: 15,
    padding: 10,
    "&:hover": {
      backgroundColor: '#444',
      color: '#eee',
      border: '2px dashed rgb(183 224 152)',
    }
},
leadDesireContainer: {
  border: '2px solid #0658c2',
  borderRadius: 5,
  backgroundColor: '#eee',
  margin: 2,
  padding: 2,
  height: 200,
  overlow: 'hidden'
},
  leadDesireBox: {
    margin: 2,
    marginTop: -5,
    padding: 2,
    height: 150,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  topRowLeadDesires: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0
  },
  leadDesireDetails: {
    display: 'flex',
    
    // justifyContent: 'center'
  }
}));

const activityTypes = [
  'Phone Call',
  'Text Message',
  'Email',
  'On-Site',
  'In Office',
  'Request Pricing',
  'Send Estimate'
]

const activtyDrawerDefaultState = {
  notes: '',
  contact: {},
  activityType: 'Phone Call',
  reminder: null
}

const formatOptionForProject = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.name) {
    return `${opt.name} (#${opt.number})`
  } else {
    return ''
  }
}

const formatOptionForClient = (opt) => {
  if (opt && opt.searchName) {
    return `${opt.searchName} (${opt.agId})`
  } else if (opt && opt.displayName) {
    return `${opt.displayName} (${opt.agId})`
  } else {
    return null
  }
}
const MergeProjectsComponent = (props) => {
// console.log('MergeProjectsComponent', props)
  const classes = useStyles()
  const [projects, setProjects] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = (hideIt) => {
    setShowModal(false)
    if (hideIt) {
    // console.log('Leave hti spage - this project is deleted now.')

    }
  }
  return (
    <React.Fragment>
      <Modal open={showModal} notification={props.notification} closeModal={handleCloseModal} client={props.client} clients={props.clients} projects={props.projects} project={props.project} url={props.url} />
      <InitialButton openModal={handleOpenModal} />
    </React.Fragment>
  )
}

const Modal = (props) => {
// console.log('Merge Modal Props', props)
  const classes = useStyles()
  const [associatedProject, setAssociatedProject] = React.useState({})
  const [otherProjects, setOtherProjects] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [responseData, setResponseData] = React.useState({})
  const [client, setClient] = React.useState({})
  const [projectList, setProjectList] = React.useState([])

  React.useEffect(() => {
    if (props.projects && props.projects.length) {
      let newProjects = props.projects
      let index = newProjects.findIndex(item => (item._id === props.project._id))
    // console.log('228 index', index)
      if (index > -1) {
        newProjects.splice(index, 1)
        setOtherProjects(newProjects)
        setProjectList(newProjects)
      }
    }
  }, [props.projects])

  React.useEffect(() => {
    setClient(props.client)
  }, [props.client])

  // const fetchClientProjects = 
  const handleClose = () => {
    props.closeModal()
  }

  const handleSave = () => {
    // Save it tot he backend and do our shit
    setLoading(true)
    let data = {
      from: props.project._id,
      to: associatedProject._id
    }
    
    const fromClient = props.client?._id || null
    const toClient = client?._id || null
    if (toClient && toClient !== fromClient) {
      console.log('Gotta change the client too')
      data.updateClient = toClient
    }

    console.log('Data to send', data)
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/merge`,
      data: data
    }).then(response => {
      setLoading(false)
      if (response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Project Merged',
          message: 'That Project Has Been Merged. Great Job!'
        })
        setResponseData(response.data)
        props.closeModal(true)
      }

    }).catch(err => {
      props.notification({
        type: 'error',
        title: 'Error!',
        message: 'We ran into an issue ' + JSON.stringify(err)
      })
    })
    // props.closeModal()
  }

  const updateProject = (e, newProj) => {
  // console.log('New Project:', newProj)
    if (newProj && newProj._id) {
      setAssociatedProject(newProj)
    } else {
      setAssociatedProject({})
    }
  }

  const fetchProjects = async (projectId) => {
    const resp = await axios.get(`${props.url}/api/projects/clientProjects?id=${projectId}`)
    // console.log('Projects', resp.data)
    if (resp.data && resp.data.length) {
      setProjectList(resp.data)
    } else {
      setProjectList(otherProjects)
    }
  }

  const updateClient = (e, newClient) => {
    if (newClient && newClient._id) {
      setClient(newClient)
      fetchProjects(newClient._id)
    } else {
      setClient({})
    }
  }

  return (
    <Dialog 
      open={props.open}
      maxWidth={'sm'}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>Merge Project</DialogTitle>
      <DialogContent>
        <span>You are working on {props.project.name} (#{props.project.number})- by merging it, this project will be deleted and all related data will be moved to the project you select</span>
        <DialogContentText>Please Select the Project You'd Like to Push This Project Data Into</DialogContentText>
          <Autocomplete
            id="Associated Project"
            options={projectList || []}
            getOptionLabel={(option) => (formatOptionForProject(option) || '')}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            style={{marginBottom: 10, marginTop: 15}}
            onChange={updateProject}
            fullWidth
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {associatedProject || ''}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Project" />}
          />
          {
            (responseData && responseData.success) ? (
              <div>Success!!!</div>
            ) : null
          }
          <div>
              <DialogContentText>If you are moving this information to a different Client, please choose the Customer Below and then Choose a Project.</DialogContentText>
              <Autocomplete
                id="client"
                options={props.clients || []}
                getOptionLabel={(option) => (formatOptionForClient(option) || '')}
                // style={{ width: 200 }}
                className={classes.paddedStuff}
                style={{marginBottom: 10, marginTop: 15}}
                onChange={updateClient}
                fullWidth
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {client || ''}
                // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} variant="outlined" label="New Customer" helperText="Which Customer Should We Associate the POs/Vendor Invoices/Uploads?" />}
              />
          </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleClose}
          disabled={loading}
        >
          Close
        </Button>
        <Button
          color="primary"
          onClick={handleSave}
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
const InitialButton = (props) => {

  return (
    <Tooltip title="Merge Project">
      <IconButton onClick={props.openModal}>
        <MergeTypeIcon />
      </IconButton>
    </Tooltip>
  )
}
const mapStateToProps = (state) => {
  // console.log('MAP States...', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    client: state.client,
    clients: state.clients,
    projects: state.projects
  }
}

export default connect(mapStateToProps)(MergeProjectsComponent)
