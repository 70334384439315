import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import AbsentModal from '../Employees/AbsentModal'
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TimeEditModal from './TimeEditModal'
import EmployeeModal from '../Employees/EmployeeModal'
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  gridItem: {
    width: '800px'
  },
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  }
}));

const EmployeeDetailsModal = (props) => {
  console.log('Got our Employee Modal modal', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showAbsentModal, setShowAbsentModal] = React.useState(false)
  // const [timeClockData, setTimeClockData] = React.useState([])
  const [now, setNow] = React.useState(Date.now())
  const [didMount, setDidMount] = React.useState(false)
  const [timeToEdit, setTimeToEdit] = React.useState({})
  const [timeEditOpen, setTimeEditOpen] = React.useState(false)

  const crewTypes = props.crewTypes //  [...new Set(props.crews.map(item => item.division ? item.division : 'Other' ))]
  const categories = [
    'Monthly Maintenance', 'Landscapes', 'Specialty', 'Clean-Ups', 'Irrigation', 'Tree Trimming', 'Lighting', 'Pest Control', 'Water Feature', 'Flowers', 'Other'
  ]
  // console.log('Crew TYpes', crewTypes)

  const [statementName, setStatementName] = React.useState('Nov2020')
  const [state, setState] = React.useState({
    employee: {},
    isDriver: false,
    isBilingual: false,
    isActive: false,
    timeClockData: [],
    absentData: [],
    empModalOpen: false
  });

const employeeTypes = [
  'Temp',
  'Floater',
  'Crewman',
  'Lead Man',
  'Foreman',
  'Bobcat Operator',
  'Crane Operator',
  'Supervisor',
  'Manager',
  'El Jefe',
  'Yard Crew',
  'Mechanic',
  'Office Staff',
  'Sales'
]

const divisions = [
  'Customer Service',
  'Specialty',
  'Irrigation and Lighting',
  'Maintenance',
  'Landscape Install',
  'Yard'
]

const getTimeClockInfo = async () => {
  axios({
    method: 'get',
    url:  `${props.url}/api/employees/getRecentTimeClockData?id=${props.employee._id}&limit=10`,
  }).then((response) => {
    console.log('Time clock data: ', response.data)
    // setTimeClockData(response.data)
    
    setState(prevState => ({
      ...prevState,
      timeClockData: response.data.timeClockData, 
      absentData: response.data.absentData
    }))
  }).catch((err) => {
  // console.log('Time Clock Error: ', err)
  })
}

// React.useEffect(() => { setDidMount(true) }, [])

React.useEffect(() => {
console.log('EMploye Detail modal - EmployeeId Changed: ', props.employee._id)
  if (props.employee && props.employee._id) {
    getTimeClockInfo()
    if (props.employee.crew) {
      let ourIndex = props.crews.map(function (e) { 
        // console.log('WHHH?? ', e)
        return e._id
      }).indexOf(props.employee.crew)
    // console.log('OUR CREW INDEX: ', ourIndex)
      setState(prevState => ({
        ...prevState,
        employee: props.employee,
        isDriver: props.employee.isDriver || false,
        isBilingual: props.employee.isBilingual || false,
        assignedCrew: props.crews[ourIndex],
        isActive: props.employee.activeEmployee || false
      }))
    } else {
      setState(prevState => ({
        ...prevState,
        employee: props.employee,
        isDriver: props.employee.isDriver || false,
        isBilingual: props.employee.isBilingual || false,
        isActive: props.employee.activeEmployee || false
        // assignedCrew: assignedCrew
      }))
    }
  }
}, [props?.employee?._id])

  // React.useEffect(() => {
  // console.log('Use effect EMPLOYEE MODAL Details', props)
  //   // let service = props.service
  //   // let assignedCrew   
  //   if (props.employee.timeClockData) {
  //     // setTimeClockData(props.employee.timeClockData)
  //     setState({...state, setTimeClockData: props.employee.timeClockData})
  //   } else {
  //     // if (props.employee && props.employee._id) getTimeClockInfo()
  //   }
  //   if (props.employee.crew) {
  //     let ourIndex = props.crews.map(function (e) { 
  //       // console.log('WHHH?? ', e)
  //       return e._id
  //     }).indexOf(props.employee.crew)
  //   // console.log('OUR CREW INDEX: ', ourIndex)
  //     setState({...state,
  //       employee: props.employee,
  //       isDriver: props.employee.isDriver || false,
  //       isBilingual: props.employee.isBilingual || false,
  //       assignedCrew: props.crews[ourIndex],
  //       isActive: props.employee.activeEmployee || false
  //     })
  //   } else {
  //     setState({...state,
  //       employee: props.employee,
  //       isDriver: props.employee.isDriver || false,
  //       isBilingual: props.employee.isBilingual || false,
  //       isActive: props.employee.activeEmployee || false
  //       // assignedCrew: assignedCrew
  //     })
  //   }
  // }, []);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  }

const handleSubmit = () => {
// console.log('Update this employee!', state)
  setLoading(true)
// console.log('handle save', state)
  // console.log(props)
  let obj = {
    // details: state,
    employee: state.employee,
    user: props.user
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/employees/updateEmployee`,
    data: obj,
    // validateStatus: (status) => {
    // // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
  // console.log('Axios response to update service ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response.data._id) {
      // props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'Saved Changes to this Employee!',
        message: 'Well Done!!'
      })
      // setState({...state,
      //   name: response.data.name,
      //   category: response.data.category || 0,
      //   description: response.data.description
      // })
      // handleCloseModal()
      // props.updateParent('update', response.data)
      handleCloseModal()
      // setOpen(false)
    } else {
    // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up updating this employee...not sure why!'
      })
    }
    
  }).catch(err => {
  // console.log('Error saving workorder: ', err)
  })
}

const handleCloseModal = () => {
  props.updateParent('closeModal')
}

const handleMarkAbsent = () => {
// console.log('OPen Absent Modal for EMployee; ', state.employee)
  setShowAbsentModal(true)
}

const updateFromAbsentModal = (d, a) => {
// console.log('Update from our Absent Modal: ', d, a)
  if (d === 'closeModal') setShowAbsentModal(false)
  if (d === 'absent') {
    setShowAbsentModal(false)
    let emp = state.employee
    emp.isCheckedIn = false
    emp.isClockedIn = false
    emp.absent = a
  // console.log('EMP:', emp)
    setState({...state, employee: emp})
  }
}

const CloseButton = () => {
  return (
    <IconButton onClick={handleCloseModal} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
      <CloseIcon />
    </IconButton>
  )
}

const MarkAbsentButton = () => {
  return (
    <Tooltip title="Mark Absent">
      <IconButton onClick={handleMarkAbsent}
        style={{ 'color': '#fff', 
          'backgroundColor': 'rgba(140,30,25, .8)', 
          'marginLeft': '40px' }} edge='end' alt="Mark Absent" aria-label="Mark Absent">
        <AlarmAddIcon />
      </IconButton>
    </Tooltip>
  )
}

const setServiceName = (name) => {
  setState({...state, name: name})
}

const updateEmployeeDivision = (id, value) => {
  // console.log('Update employee: ', id)
// console.log('Value: ', value)
  setState({...state, employee: {...state.employee, division: value}})
}

const updateEmployeeType = (id, value) => {
  // console.log('Update employee: ', id)
// console.log('Value: ', value)
  setState({...state, employee: {...state.employee, type: value}})
}

const updateAssignedCrew = (event, crew) => {
// console.log('Update crew')
// console.log('Event: ', event)
// console.log('Option: ', crew)
  if (crew && crew._id) {
    setState({
      ...state,
      assignedCrew: crew,
      employee: {
        ...state.employee,
        crew: crew._id,
        crewName: crew.name
      }
    })
  } else {
    setState({
      ...state,
      assignedCrew: {},
      employee: {
        ...state.employee,
        crew: null,
        crewName: 'None'
      }
    })
  }


  // console.log(event.target.id)
  // let split = event.target.id.split('-')
  // let crewNum = split[3]
  // let optIndex = split[1]
  // console.log('Crew Numb: ', crewNum)
  // console.log('Opt Index: ', optIndex)
  // // // console.log(state.jobDetails[optIndex])
  // // let jobDetail = state.service[optIndex]
  // let newCrew = props.crews[crewNum]
  // console.log('nEW Crew:', newCrew)
  // let newWO = state.workorder
  // newWO.crewNumber = newCrew['agValue']
  // console.log('New Crew: ', newCrew)
  // console.log('For this job: ', jobDetail)
  // let newJobDetails = state.jobDetails
  // let ourIndex = state.jobDetails.map(function (e) { 
  //   // console.log('WHHH?? ', e)
  //   return e.number
  // }).indexOf(jobDetail.number)
  // newJobDetails[ourIndex]['crew'] = newCrew
  // newJobDetails[ourIndex]['requiresUpdate'] = true
  // setState({...state, employee: {...employee, crew}})
}

const AbsentChip = ({ type }) => {
// console.log('Absent Type: ', type)
  if (type === 'off') {
    return  <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label="Sick Time" />
  }
  if (type === 'approved') {
    return  <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label="PTO" />
  }
  if (type === 'nocall') {
    return  <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label="No Call/No Show" />
  }
  return ''
}
const EmployeeStatus = ({ employee }) => {
  return (
  <span style={{'marginLeft': '20px'}}>{
    employee.isClockedIn ? (
      <Chip style={{color: '#fff', background: '#a2d67a' }} size="small" label="Clocked-In" />
    ) : employee.isCheckedIn ? (
      <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label="Checked-In" />
    ) : employee.absent ? (
      <AbsentChip type={employee.absent} />
    ) : ''
  }</span>
  )
}

const handleTimeEdit = (row) => {
  console.log('Edit this ', row)
  setTimeToEdit(row)
  setTimeEditOpen(true)
}

const updateFromTimeEdit = (type, data) => {
// console.log('Update from time edit', type)
  setTimeEditOpen(false)
  if (type === 'closeModal') {
    
  }
  if (type === 'saved') {
  // console.log('This shift: ', data)
    let timeData = state.timeClockData
    let index = timeData.findIndex(item =>  (item['_id'].toString() === data._id.toString()));
    if (index > -1) timeData[index] = data
  // console.log('Time Data; ', timeData)
    // setTimeClockData(timeData)
    setState({...state, timeClockData: timeData})
  }
}

const HoursMath = ({data}) => {
// console.log('Hours Math: ', data)
  if (data.clockIn && !data.clockOut) {
  // console.log('We hvae  clock in time: ', data.clockIn)
    let now = Date.now()
  // console.log('Now: ', now)
    let today = moment(now)
    let start = moment(data.clockIn)
    // now = now.toLocaleDateString()
    // console.log(now)
    let hoursWorked = parseFloat(moment.duration(today.diff(start)).asHours()).toFixed(2)
  // console.log('Hours WOrked: ', hoursWorked)
    return hoursWorked
  } else if (data.hoursWorked === 0) {
    return 0
  } else if (!data.clockIn) {
    return ''
  }
  return ''
}

const  EditDetails = ({ data }) => {
// console.log('EDITE DETAILS: ', data)
  if (data.edited) {
    return (
      <TableRow style={{backgroundColor: '#ccc'}}>
      <TableCell padding="none">Shift Was Edited: </TableCell>
      <TableCell padding="none" colSpan="4">{data.notes ? data.notes : ''}</TableCell>

    </TableRow>
    )
  } else {
    return null
  }
  
} 


const Timeclock = () => {
  // console.log('Time clock data: ', timeClockData)
  return (
  <div>
      <TimeEditModal open={timeEditOpen} data={timeToEdit} updateParent={updateFromTimeEdit} />
      <TableContainer component={Paper}>
        <h3>Recent Shifts Worked</h3>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell >Check-In</TableCell>
            <TableCell >Clock-In</TableCell>
            <TableCell >Clock-Out</TableCell>
            <TableCell >Hours Worked</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
          {state.timeClockData.map((row) => (
            <React.Fragment key={row._id}>
              <TableRow  style={{cursor: 'pointer'}}
                onDoubleClick={() => handleTimeEdit(row)} >
                <TableCell scope="row">
                  {moment(row.clockIn).format('ddd MM/DD/YYYY')}
                </TableCell>
                <TableCell >{moment(row.checkIn).format('hh:mm:ss A')}</TableCell>
                <TableCell >{row.clockIn ? moment(row.clockIn).format('hh:mm:ss A') : ''}</TableCell>
                <TableCell >{row.clockOut ? moment(row.clockOut).format('hh:mm:ss A') : ''}</TableCell>
                <TableCell >{row.hoursWorked ? row.hoursWorked : <HoursMath data={row} />}</TableCell>
                
              </TableRow>
              { row.edited ? <EditDetails data={row} /> : ''}
            </React.Fragment>

          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}

const AbsentType = ({ type }) => {
  if (type === 'approved') {
    return <span>Approved PTO</span>
  }
  if (type === 'off') {
    return <span>Called/Off</span>
  }
  if (type === 'nocall') {
    return <span>No-Call/No-Show</span>
  }
  return ''
}

const AbsentList = () => {
  // console.log('Time clock data: ', timeClockData)
  return (
  <div>
      <TableContainer component={Paper}>
        <h3>Absence Details</h3>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Return Date</TableCell>
            <TableCell >Type</TableCell>
            <TableCell >Who</TableCell>
            <TableCell >Notes</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
          {state.absentData.map((row) => (
            <React.Fragment key={row._id}>
              <TableRow  style={{cursor: 'pointer'}} >
                <TableCell scope="row">
                  {moment(row.created_at).format('ddd MM/DD/YYYY')}
                </TableCell>
                <TableCell scope="row">
                  {row.returned_at ? moment(row.returned_at).format('ddd MM/DD/YYYY') : ''}
                </TableCell>
                <TableCell ><AbsentType type={row.type} /></TableCell>
                <TableCell >{row?.requestor?.name}</TableCell>
                <TableCell >{row.notes}</TableCell>
              </TableRow>
              { row.edited ? <EditDetails data={row} /> : ''}
            </React.Fragment>

          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}

const updateFromEmployeeModal = (d) => {
// console.log('Update from EMployee Modal', d)
  setState({...state, empModalOpen: false })
}

const handleEditEmployee = () => {
  setState({...state, empModalOpen: true })
}

  return (
    <div>
       <EmployeeModal open={state.empModalOpen} employee={state.employee} crews={props.crews} updateParent={updateFromEmployeeModal} /> 
      <AbsentModal open={showAbsentModal} employee={state.employee} updateParent={updateFromAbsentModal} />
        <Dialog
          // fullScreen={false}
          // maxWidth={lg}
          // width='md'
          fullWidth={true}
          maxWidth = {'md'}
          // maxwidth='md'
          // style={{width: '90hw'}}
          open={props.open}
          // scroll='paper'
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          >
                {/* <Dialog
        maxWidth='lg'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      > */}
          <DialogTitle id="responsive-dialog-title">
            {state.employee.displayName} 
            <IconButton edge='end' onClick={handleEditEmployee} alt="Edit Employee" aria-label="Edit Employee">
                    <EditIcon />
                  </IconButton>
            <EmployeeStatus employee={state.employee} />
            <MarkAbsentButton />
            <CloseButton />
           </DialogTitle>
          <DialogContent>
            
          
            <Grid container spacing={2} >

              <Grid item lg={12} xs={12}>
              <Autocomplete
                id="Division"
                options={crewTypes}
                // getOptionLabel={(option) => option.name}
                // style={{ width: 200 }}
                onChange={updateEmployeeDivision}
                // onChange={ (e) => updateEmployee(e.target.id, e.target.value) }
                value = {state.employee.division}
                // onChange={ e => setState({...state, employee: {...state.employee, division: e.target.value}, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} label="Division" variant="outlined" />}
              />
              </Grid>
              
              <Grid item lg={12} xs={12}>
                <Autocomplete
                  id="AssignedCrew"
                  options={props.crews}
                  getOptionLabel={(option) => option.name}
                  // style={{ width: 200 }}
                  onChange={updateAssignedCrew}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.assignedCrew}
                  // onChange={ e => setState({...state, employee: {...state.employee, employeeType: e.target.value}, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Assigned Crew" variant="outlined" />}
                />
              </Grid>
              <Grid item lg={12}>
                <AbsentList />
                <Timeclock />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit()} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
    </div>
  )
 

}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    crews: state.crews
  }
}

export default connect(mapStateToProps)(EmployeeDetailsModal)