import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { 
  Typography, 
  AppBar, 
  Tabs, 
  Tab, 
  Box 
} from '@material-ui/core';
import WeeklyRoutesComponent from './WeeklyRoutesComponent';
import WeeklyRouteTable from './WeeklyRouteTable';
import RouteMapView from './RouteMapView';

const distinctHexColors = [    
  "#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF", "#FFA500", 
  "#800080", "#008000", "#FFC0CB", "#A52A2A", "#808080", "#FFFFFF", "#FFD700", 
  "#4B0082", "#7FFF00", "#FF4500", "#1E90FF", "#8B4513"
];

const getCrewColor = (index) => {
  return distinctHexColors[index % distinctHexColors.length];
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const RouteDashboard = (props) => {
  const [weeklyRoutes, setWeeklyRoutes] = useState({});
  const [state, setState] = useState({
    weekStart: '',
    weekEnd: ''
  });
  const [sites, setSites] = useState([])
  const [crews, setCrews] = useState([])
  const [tabValue, setTabValue] = useState(0);
  const { url } = props;

  useEffect(() => {
    fetchRoutes();
  }, [url]);

  const fetchRoutes = async (date = new Date().toISOString().split('T')[0]) => {
    try {
      const resp = await axios.get(`${url}/api/hmi/genericGetRequest?type=weeklyRoutesByDate&date=${date}`);
      if (resp?.data?.data?.routes) {
        setWeeklyRoutes(resp.data.data.routes);
        if (resp.data.data?.sites?.length) {
          const hmiCrews = props.crews.filter(crew => (crew.entity === "640a56404965b71a9edd0e7d"))
          console.log('Hmi Crews', hmiCrews)
          const jobsites = resp.data.data.sites
          
          console.log('jobsites:', jobsites)
          const uniqueCrewIds = [...new Set(jobsites.map(site => site.crew))]
          const uniqueCrews = []
          console.log('Unique Crew Ids', uniqueCrewIds)
          if (uniqueCrewIds.length) {
            uniqueCrewIds.forEach((crewId, uniqueCrewIndex) => {
              // console.log('Find CrewId', crewId)
              let crewIndex = [...hmiCrews].findIndex(crew => (crew._id === crewId))
              console.log(crewIndex)
              if (crewIndex > -1) {
                // console.log('CREW: 953', props.crews[crewIndex])
                const crewItem = hmiCrews[crewIndex]
                console.log('crewItem 976', crewItem)
                const crewObj = {
                  ...crewItem,
                  name: crewItem?.name ? `${crewItem.name} ${crewItem.foremanName ? `(${crewItem.foremanName})` : ''}` : 'Un-Named Crew',
                  color: getCrewColor(uniqueCrewIndex)
                }
                uniqueCrews.push(crewObj)
              } else {
                console.log('No valid crew!!!', crewId)
              }
            })
          }
          setCrews(uniqueCrews)
          setSites(resp.data.data.sites)
        }
        setState({ weekStart: resp.data.data.weekStart, weekEnd: resp.data.data.weekEnd });
      }
    } catch (error) {
      console.error('Error fetching routes:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };


  // const crewsWithColor = hmiCrews.map((crew, index) => {
  //   return (
  //     {
  //       ...crew,
  //       color: getCrewColor(index)
  //     }
  //   )
  // })
  // console.log('Crews w/ color', crewsWithColor)
  return (
    <div>
      {/* <Typography variant="h4" gutterBottom>Route Dashboard</Typography> */}
      {weeklyRoutes && Object.keys(weeklyRoutes).length > 0 && (
        <>
          <AppBar position="static">
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="route dashboard tabs">
              <Tab label="Weekly Routes" {...a11yProps(0)} />
              <Tab label="Route Table" {...a11yProps(1)} />
              <Tab label="Route Map" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={tabValue} index={0}>
            <WeeklyRoutesComponent 
              weeklyRoutes={weeklyRoutes} 
              state={state} 
              fetchRoutesByDate={fetchRoutes} 
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <WeeklyRouteTable weeklyRoutes={weeklyRoutes} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <RouteMapView weeklyRoutes={weeklyRoutes} sites={sites} crews={crews} url={props.url} />
          </TabPanel>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    crews: state.crews,
    notification: state.notification
  };
};

export default connect(mapStateToProps)(RouteDashboard);