import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const hmiColor = 'rgb(2 164 9)';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
    backgroundColor: hmiColor,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  photoContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  photoItem: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  caption: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
    color: 'white',
    padding: '5px',
    boxSizing: 'border-box',
    fontSize: '36px'
  }
}));

const PhotoSlideshow = ({ photos, fetchPhotos }) => {
  const classes = useStyles();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [shouldFetch, setShouldFetch] = useState(false);

  const advanceSlide = useCallback(() => {
    if (photos.length > 1) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
    }
    if (currentIndex === photos.length - 1) {
      setShouldFetch(true);
    }
  }, [photos.length, currentIndex]);

  useEffect(() => {
    const interval = setInterval(advanceSlide, 10000);
    return () => clearInterval(interval);
  }, [advanceSlide]);

  useEffect(() => {
    if (shouldFetch) {
      fetchPhotos();
      setShouldFetch(false);
    }
  }, [shouldFetch, fetchPhotos]);

  if (photos.length === 0) {
    return <div className={classes.root}></div>;
  }

  const photo = photos[currentIndex];
  let imageURL = photo?.url;
  if (imageURL && !imageURL.includes('https')) imageURL = `https://${imageURL}`;
  
  const workorderName = photo?.workorder?.jobName || photo?.client?.displayName || '';
  const addy = photo?.workorder?.jobSiteAddress || '';

  return (
    <div className={classes.root}>
      <div className={classes.photoContainer}>
        <img src={imageURL} alt="Slideshow" className={classes.photoItem} />
        <div className={classes.caption}>
          <div>{photo.created_by}</div>
          <div>
            {new Date(photo.created_at).toLocaleDateString({ dateStyle: 'short' })}{' '}
            {new Date(photo.created_at).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
          </div>
          <div>{workorderName} {photo?.workorder?.number ? `(#${photo.workorder.number})` : ''}</div>
          <div>{addy}</div>
          {photo?.mobileUploadType && (
            <div>
              {photo.mobileUploadType === 'before' ? 'Before Photo' : 
               photo.mobileUploadType === 'after' ? 'After Photo' : 
               photo.mobileUploadType}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhotoSlideshow;