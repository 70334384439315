import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const MakePaymentModal = (props) => {
  // console.log('MakePayment Modal  Props: ', props)
  const classes = useStyles();
  const [paymentTypes, setPaymentTypes] = React.useState([
    {
      Text: 'Cash Collected',
      value: '1'
    },
    {
      Text: 'Paper Check Received',
      value: '2'
    },
    {
      Text: 'Credit Card',
      value: '3'
    },
    {
      Text: 'ACH/Direct Deposit',
      value: '4'
    },
    {
      Text: 'Write-Off (Bad Debt)',
      value: '5'
    }
    ])

    // {
    //   Text: 'Cash Collected',
    //   value: '1'
    // },
    // {
    //   Text: 'Paper Check Received',
    //   value: '2'
    // },
    // {
    //   Text: 'Credit Card',
    //   value: '3'
    // }
    // {
    //   Text: 'eCheck',
    //   value: '4'
    // },
    // {
    //   Text: 'Other',
    //   value: '9'
    // }
  // ])

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [deposits, setDeposits] = React.useState([])
  const [credits, setCredits] = React.useState([])
  // const [invoice, setInvoice] = React.useState(null)
  const [state, setState] = React.useState({
    date: new Date(),
    amount: 0,
    checkNumber: '',
    paymentType: {
      Text: '',
      value: '0'
    },
    showCheckNumber: 'none'
  })
  // React.useEffect(() => {
  //   // console.log('Use Effect Make Payment Types')
  //   // Add client payment profiles here
  //   let paymentProfiles = props.paymentProfiles
      
  //   if (paymentProfiles && paymentProfiles.length > 0) {
  //     // console.log('Add our payment profiles!!!')
  //     let profiles = []
  //     let paymentOptions = [
  //       {
  //         Text: 'Cash Collected',
  //         value: '1'
  //       },
  //       {
  //         Text: 'Paper Check Received',
  //         value: '2'
  //       },
  //       {
  //         Text: 'Credit Card',
  //         value: '3'
  //       }]
  //     paymentProfiles.map(pp => {
  //       let obj = {}
  //       if (pp.payment.creditCard) {
  //         obj.Text = 'Charge ' + pp.payment.creditCard.cardType + ' ' + pp.payment.creditCard.cardNumber
  //         obj.value = '3'
  //         obj.id = pp.customerPaymentProfileId
  //       } else {
  //         obj.Text = 'Charge ' + pp.payment.bankAccount.bankName + ' ' + pp.payment.bankAccount.accountNumber
  //         obj.value = '4'
  //         obj.id = pp.customerPaymentProfileId
  //       }
  //       paymentOptions.push(obj)
  //     })
  //     // profiles.push({ Text: ' -------- Offline Collection -----------  '})
  //     // let newArr = profiles.concat(paymentTypes)
  //     // console.log('NEW PAYMENT TYPES:: ', newArr)
  //     // console.log('Payment Options', newArr)
  //     setPaymentTypes(paymentOptions)
  //   }
  // }, [props])

  // React.useEffect(() => {
  //   let newArr = paymentTypes
  //   // console.log('Credits Changed!!')
  //   if (props.credits || props.deposits) {
  //       // console.log('We have credits available!')
  //       // console.log(props.credits)
  //       props.credits.map(item => {
  //         if (item.balance > 0) {
  //           newArr.push({ value: '99', type: 'Credit', _id: item._id, amount: item.balance, Text: `Apply Credit Balance - ${currencyFormat(item.balance) || 0}`})
  //         }
  //       })
  //     setPaymentTypes(newArr)
  //   }
  // }, [props.credits])

  // React.useEffect(() => {
  //   let newArr = paymentTypes
  //   // console.log('Deposits Changed!!')
  //     if (props.deposits) {
  //       let newArr = paymentTypes
  //       // console.log(props.deposits)
  //       props.deposits.map(item => {
  //         if (item.balance > 0) {
  //           newArr.push({ value: '98', type: 'Deposit', _id: item._id, amount: item.balance, Text: `Apply Deposit Balance - ${currencyFormat(item.balance) || 0}`})
  //         }
  //       })
  //     setPaymentTypes(newArr)
  //   }
  // }, [props.deposits])

  React.useEffect(() => {
    // console.log('Use Effect Make Payment PROPS CHANGE')
    // console.log('Use Effect Make Payment Types')
    // Add client payment profiles here
    let paymentProfiles = props.paymentProfiles
    let paymentOptions = [
      {
        Text: 'Cash Collected',
        value: '1'
      },
      {
        Text: 'Paper Check Received',
        value: '2'
      },
      // {
      //   Text: 'Credit Card',
      //   value: '3'
      // },
      {
        Text: 'ACH/Direct Deposit',
        value: '4' 
      },
      {
        Text: 'Write-Off (Bad Debt)',
        value: '5'
      }]
    if (paymentProfiles && paymentProfiles.length > 0) {
      // console.log('Add our payment profiles!!!')
      let profiles = []
      paymentProfiles.map(pp => {
        let obj = {}
        if (pp.payment.creditCard) {
          obj.Text = 'Charge ' + pp.payment.creditCard.cardType + ' ' + pp.payment.creditCard.cardNumber
          obj.value = '3'
          obj.id = pp.customerPaymentProfileId
        } else {
          obj.Text = 'Charge ' + pp.payment.bankAccount.bankName + ' ' + pp.payment.bankAccount.accountNumber
          obj.value = '4'
          obj.id = pp.customerPaymentProfileId
        }
        paymentOptions.push(obj)
      })
    }
      // profiles.push({ Text: ' -------- Offline Collection -----------  '})
      // let newArr = profiles.concat(paymentTypes)
      // console.log('NEW PAYMENT TYPES:: ', newArr)
      // console.log('Payment Options', newArr)
      // setPaymentTypes(paymentOptions)
    // console.log(props)
    // Add client payment profiles here
    // console.log('Deposits Changed!!')
    if (props.deposits) {
      setDeposits(props.deposits)
      // let newArr = paymentTypes
      // console.log(props.deposits)
      // deposits.map(item => {
      //   if (item.balance > 0) {
      //     paymentOptions.push({ value: '98', type: 'Deposit', _id: item._id, amount: item.balance, Text: `Apply Deposit Balance - ${currencyFormat(item.balance) || 0}`})
      //   }
      // })
    // setPaymentTypes(newArr)
  }
  if (props.credits) {
    setCredits(props.credits)
    // console.log('We have credits available!')
    // console.log(props.credits)
    // credits.map(item => {
    //   if (item.balance > 0) {
    //     paymentOptions.push({ value: '99', type: 'Credit', _id: item._id, amount: item.balance, Text: `Apply Credit Balance - ${currencyFormat(item.balance) || 0}`})
    //   }
    // })
  // setPaymentTypes(newArr)
}
  setState({...state, showCheckNumber: 'none', amount: props.invoice.balance})
  setPaymentTypes(paymentOptions)
// setOpen(props.open)
}, [])

React.useEffect(() => {
  setOpen(props.open)
  if (props.credits) {
    setCredits(props.credits)
  }
  if (props.deposits) {
    setDeposits(props.deposits)
  }
  let paymentProfiles = props.paymentProfiles
  let paymentOptions = [
    {
      Text: 'Cash Collected',
      value: '1'
    },
    {
      Text: 'Paper Check Received',
      value: '2'
    },
    // {
    //   Text: 'Credit Card',
    //   value: '3'
    // },
    {
      Text: 'ACH/Direct Deposit',
      value: '4' 
    }, 
    {
      Text: 'Write-Off (Bad Debt)',
      value: '5'
    }]

  if (paymentProfiles && paymentProfiles.length > 0) {
    // console.log('Add our payment profiles!!!')
    let profiles = []
    paymentProfiles.map(pp => {
      let obj = {}
      if (pp.payment.creditCard) {
        obj.Text = 'Charge ' + pp.payment.creditCard.cardType + ' ' + pp.payment.creditCard.cardNumber
        obj.value = '3'
        obj.id = pp.customerPaymentProfileId
      } else {
        obj.Text = 'Charge ' + pp.payment.bankAccount.bankName + ' ' + pp.payment.bankAccount.accountNumber
        obj.value = '4'
        obj.id = pp.customerPaymentProfileId
      }
      paymentOptions.push(obj)
    })
  }

  if (props.deposits) {
    // console.log('Deposits to add: ', deposits)
    deposits.map(item => {
      if (item.balance > 0) {
        paymentOptions.push({ created: item.created_at, value: '98', type: 'Deposit', _id: item._id, amount: item.balance, Text: `Apply Deposit Balance from ${moment(item.created_at).format('MM/DD/YYYY')} (${item.accountNumber ? `${item.accountNumber} for ` : ''}$${item.amount})`})
      }
    })
  // setPaymentTypes(newArr)
}
if (props.credits) {
  // console.log('Credits to add', credits)
  credits.map(item => {
    if (item.balance > 0) {
      paymentOptions.push({ created: item.created_at, value: '99', type: 'Credit', _id: item._id, amount: item.balance, Text: `Apply Credit Balance - ${currencyFormat(item.balance) || 0}`})
    }
  })
// setPaymentTypes(newArr)
}
// console.log('Paymetn Types to Set: ', paymentOptions)
setPaymentTypes(paymentOptions)
}, [props])

React.useEffect(() => {
  setState({...state, amount: props.invoice.balance})
}, [props.invoice])

React.useEffect(() => {
  // console.log('Use Effect MAKE PAYMENT (CREDITS/DEPOSITS)')
  // Add client payment profiles here
  let paymentProfiles = props.paymentProfiles
  let paymentOptions = [
    {
      Text: 'Cash Collected',
      value: '1'
    },
    {
      Text: 'Paper Check Received',
      value: '2'
    },
    // {
    //   Text: 'Credit Card',
    //   value: '3'
    // },
    {
      Text: 'ACH/Direct Deposit',
      value: '4' 
    },
    {
      Text: 'Write-Off (Bad Debt)',
      value: '5'
    }]
  if (paymentProfiles && paymentProfiles.length > 0) {
    // console.log('Add our payment profiles!!!')
    let profiles = []
    paymentProfiles.map(pp => {
      let obj = {}
      if (pp.payment.creditCard) {
        obj.Text = 'Charge ' + pp.payment.creditCard.cardType + ' ' + pp.payment.creditCard.cardNumber
        obj.value = '3'
        obj.id = pp.customerPaymentProfileId
      } else {
        obj.Text = 'Charge ' + pp.payment.bankAccount.bankName + ' ' + pp.payment.bankAccount.accountNumber
        obj.value = '4'
        obj.id = pp.customerPaymentProfileId
      }
      paymentOptions.push(obj)
    })
  }

  if (deposits) {
    // console.log('Deposits to add: ', deposits)
    deposits.map(item => {
      if (item.balance > 0) {
        paymentOptions.push({ created: item.created_at, value: '98', type: 'Deposit', _id: item._id, amount: item.balance, Text: `Apply Deposit Balance from ${moment(item.created_at).format('MM/DD/YYYY')} (${item.accountNumber ? `${item.accountNumber} for ` : ''}$${item.amount})`})
        // paymentOptions.push({ created: item.created_at, value: '98', type: 'Deposit', _id: item._id, amount: item.balance, Text: `Apply Deposit Balance - ${currencyFormat(item.balance) || 0}`})
      }
    })
  // setPaymentTypes(newArr)
}
if (credits) {
  // console.log('Credits to add', credits)
  credits.map(item => {
    if (item.balance > 0) {
      paymentOptions.push({ created: item.created_at, value: '99', type: 'Credit', _id: item._id, amount: item.balance, Text: `Apply Credit Balance - ${currencyFormat(item.balance) || 0}`})
      // paymentOptions.push({ created: item.created_at, value: '99', type: 'Credit', _id: item._id, amount: item.balance, Text: `Apply Credit Balance - ${currencyFormat(item.balance) || 0}`})
    }
  })
// setPaymentTypes(newArr)
}
// console.log('Paymetn Types to Set: ', paymentOptions)
setPaymentTypes(paymentOptions)

}, [credits, deposits])


  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

 const updatePaymentOptionsList = () => {
  let paymentProfiles = props.paymentProfiles
  let paymentOptions = [
    {
      Text: 'Cash Collected',
      value: '1'
    },
    {
      Text: 'Paper Check Received',
      value: '2'
    },
    // {
    //   Text: 'Credit Card',
    //   value: '3'
    // },
    {
      Text: 'ACH/Direct Deposit',
      value: '4' 
    },
    {
      Text: 'Write-Off (Bad Debt)',
      value: '5'
    }]
  if (paymentProfiles && paymentProfiles.length > 0) {
    // console.log('Add our payment profiles!!!')
    let profiles = []
    paymentProfiles.map(pp => {
      let obj = {}
      if (pp.payment.creditCard) {
        obj.Text = 'Charge ' + pp.payment.creditCard.cardType + ' ' + pp.payment.creditCard.cardNumber
        obj.value = '3'
        obj.id = pp.customerPaymentProfileId
      } else {
        obj.Text = 'Charge ' + pp.payment.bankAccount.bankName + ' ' + pp.payment.bankAccount.accountNumber
        obj.value = '4'
        obj.id = pp.customerPaymentProfileId
      }
      paymentOptions.push(obj)
    })
  }

  if (deposits) {
    // console.log('Deposits to add: ', deposits)
    deposits.map(item => {
      if (item.balance > 0) {
        paymentOptions.push({ created: item.created_at, value: '98', type: 'Deposit', _id: item._id, amount: item.balance, Text: `Apply Deposit Balance from ${moment(item.created_at).format('MM/DD/YYYY')} (${item.accountNumber ? `${item.accountNumber} for ` : ''}$${item.amount})`})
        // paymentOptions.push({created: item.created_at,  value: '98', type: 'Deposit', _id: item._id, amount: item.balance, Text: `Apply Deposit Balance - ${currencyFormat(item.balance) || 0}`})
      }
    })
  // setPaymentTypes(newArr)
}
if (credits) {
  // console.log('Credits to add', credits)
  credits.map(item => {
    if (item.balance > 0) {
      paymentOptions.push({created: item.created_at,  value: '99', type: 'Credit', _id: item._id, amount: item.balance, Text: `Apply Credit Balance - ${currencyFormat(item.balance) || 0}`})
    }
  })
// setPaymentTypes(newArr)
}
console.log('Paymetn Types to Set: ', paymentOptions)
setPaymentTypes(paymentOptions)
 }
const updateDepositsOrCreditsWIthThis = (type, item) => {
  // console.log('UPdate our credits/deposits', type)
  if (type === 'credit') {
    let ourCredits = credits
    // console.log('Find Index', ourCredits)
    let index = ourCredits.findIndex(ourItem =>  (item._id.toString() === ourItem._id.toString()));
    // console.log('INdex: ', index)
    if (index > -1) {
      let theItem = ourCredits[index]
      // console.log('Replace this credit: ', theItem)
      // console.log('With this: ', item)
      ourCredits[index] = item
      // console.log('UPdated Credit List: ', ourCredits)
      setCredits(ourCredits)
    } else {
      // console.log('Cannot find a match to this: ', item)
    }
  }
  if (type === 'deposit') {
    let ourDeposits = deposits
    // console.log('Find Index', ourDeposits)
    let index = ourDeposits.findIndex(ourItem =>  (item._id.toString() === ourItem._id.toString()));
    // console.log('INdex: ', index)
    if (index > -1) {
      let theItem = ourDeposits[index]
      // console.log('Replace this deposit: ', theItem)
      // console.log('With this: ', item)
      ourDeposits[index] = item
      // console.log('UPdated deposit List: ', ourDeposits)
      setDeposits(ourDeposits)
    } else {
      // console.log('Cannot find a match to this: ', item)
    }
  }
  updatePaymentOptionsList()
}
const handleClose = (data, pmt) => {
  // console.log('Closing Make Payment Modal')
  // setOpen(false)
  setState({
    date: new Date(),
    amount: 0,
    checkNumber: '',
    paymentType: {
      Text: '',
      value: '0'
    },
    showCheckNumber: 'none'
  })
  setLoading(false)
  let reason = 'Close'
  if (data) reason = data
  props.closeModal(data, pmt)
}
const sendPayment = (overpayment) => {
  let obj = {
    client: props.client,
    invoice: props.invoice,
    data: state,
    user: props.user,
    overpayment: overpayment
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/payment/makePayment`,
    data: obj
  }).then(response => {
    setLoading(false)
    // console.log('Axios response from making payment: ', response)
    if (response.data && response.data.credit) {
      // console.log('Update our credit item', response.data.credit)
      updateDepositsOrCreditsWIthThis('credit', response.data.credit)
    }
    if (response.data && response.data.deposit) {
      // console.log('Update our deposit item', response.data.deposit)
      updateDepositsOrCreditsWIthThis('deposit', response.data.deposit)
    }
    if (response && response.data && response.data.payment && response.data.payment._id) {
      // console.log('We have a payment !!!')
      if (response.data.raw && response.data.raw.decline) {
        let errMessage = 'Error Authorizing Payment'
        if (response.data && response.data.message) errMessage = response.data.message
        props.notification({
          type: 'warning',
          title: 'Error Processing Transaction',
          message: errMessage
        })
      }
      if (response.data.raw && response.data.raw.authorized) {
        props.notification({
          type: 'success',
          title: 'Payment Applied!',
          message: 'Payment Was Applied Successfully'
        })
      }
      handleClose('success', response.data)
    } else {
      props.notification({
        type: 'warning',
        title: 'Unknown Error',
        message: 'We Do Not Know Why This Shows Up...'
      })
    }
  })
}

const handleSave = () => {
  setLoading(true)
  // console.log('Save this payment!!!', state)
  // console.log(props.invoice)
  if (state.amount > props.invoice.balance) {
    if (window.confirm(`The payment amount of ${currencyFormat(state.amount)} is more than the balance owed of ${currencyFormat(props.invoice.balance)} - Are you sure you wish to proceed?`)) {
      sendPayment(true)
    } else {
      setLoading(false)
    }
    // props.notification({
    //   type: 'warning',
    //   title: 'Amount Cannot Be Greater Than Balance!',
    //   message: 'Was that a typo? We can\'t pay more than they owe...'
    // })
    setLoading(false)
  // } 
  // else if (state.amount < 1) {
  //   props.notification({
  //     type: 'warning',
  //     title: 'Amount Cannot Be Less Than $1.00!',
  //     message: 'Was that a typo? We can\'t pay less than they $1 or Arbor Gold Freaks Out'
  //   })
  //   setLoading(false)
  // } 
  } else {
    // console.log('Send this Paymetn to The Server')
    sendPayment(false)
    // let obj = {
    //   client: props.client,
    //   invoice: props.invoice,
    //   data: state,
    //   user: props.user
    // }
    // axios({
    //   method: 'post',
    //   url:  `${props.url}/api/payment/makePayment`,
    //   data: obj
    // }).then(response => {
    //   setLoading(false)
    //   // console.log('Axios response from making payment: ', response)
    //   if (response && response.data && response.data.payment && response.data.payment._id) {

    //     // console.log('We have a payment with an AR Number!!!')
    //     props.notification({
    //       type: 'success',
    //       title: 'Payment Applied!',
    //       message: 'Payment Was Applied Successfully'
    //     })
    //     handleClose('success', response.data)
    //   } else {
    //     props.notification({
    //       type: 'warning',
    //       title: 'Unknown Error',
    //       message: 'We Do Not Know Why This Shows Up...'
    //     })
    //   }
    //   // if (response && response.data && response.data.message && response.data.message.includes('success')) {
    //   //   props.notification({
    //   //     type: 'success',
    //   //     title: 'Payment Applied!',
    //   //     message: 'Payment Was Applied Successfully'
    //   //   })
    //   //   handleClose()
    //   // } else if (response && response.data && response.data.message !== '') {
    //   //   props.notification({
    //   //     type: 'warning',
    //   //     title: 'Error',
    //   //     message: response.data.message
    //   //   })
    //   // } else if (response && response.data && response.data.message3 !== '') {
    //   //   props.notification({
    //   //     type: 'warning',
    //   //     title: 'Error',
    //   //     message: response.data.message3
    //   //   })
    //   // }else {
    //   //   props.notification({
    //   //     type: 'warning',
    //   //     title: 'Unknown Error',
    //   //     message: 'We Do Not Know Why This Shows Up...'
    //   //   })
    //   // }
    // })
  }
}
const updatePaymentType = (a, b) => {
  // console.log('Update payment: ', b)
  // console.log(a)
  if (b && b.value) {
    if (b.value === '2') {
      setState({...state, showCheckNumber: 'inline', paymentType: b})
    } else {
      setState({...state, showCheckNumber: 'none', paymentType: b})
    }
    if (b.value === '99' || b.value === '98') {
      // console.log('Applying a Credit Here Make Sure Its enough to Cover')
      if (parseFloat(state.amount) > parseFloat(b.amount)) {
        // console.log('Not Enough to Cover It')
        setState({...state, date: b.created, amount: b.amount,  showCheckNumber: 'none', paymentType: b })
      } else {
        setState({...state, date: b.created, showCheckNumber: 'none', paymentType: b})
      }
    }
  }
}
const updateAmount = (e) => {
  // console.log('Update Amoutn: ', e.target.value)
  // e => setState({...state, amount: parseFloat(e.target.value)})
  if (state.paymentType && state.paymentType._id) {
    // console.log('Gotta make sure we are good here...')
    if (e.target.value > state.paymentType.amount) {
      setState({...state, amount: state.paymentType.amount})
    } else {
      setState({...state, amount: e.target.value})
    }
  } else {
    setState({...state, amount: e.target.value})
  }
}
return (
  <div className={classes.root}>
    <Dialog open={open} fullWidth={false} onClose={handleClose} maxWidth='sm' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Make Payment</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Provide The Basic Payment Info Below
          </DialogContentText>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <TextField
                  id="amount"
                  label="Payment Amount"
                  type="number"
                  className={classes.paddedStuff}
                  fullWidth
                  value={state.amount || ''} // "2017-05-24"}
                  // className={classes.textField}
                  onChange={ updateAmount }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
                <Autocomplete
                id="PaymentType"
                label="Payment Type"
                options={paymentTypes}
                getOptionLabel={(option) => option.Text}
                onChange={updatePaymentType}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {state.paymentType}
                // onChange={ e => setState({...state, paymentType: e.target.value }) }
                renderInput={(params) => <TextField className={classes.paddedStuff} InputLabelProps={{shrink: true}} {...params} label="Payment Type" />}
              />
            </Grid>
            <Grid item xs={12} lg={12} style={{display: state.showCheckNumber}}>
              <TextField
                  id="checknumber"
                  label="Check Number"
                  // type="number"
                  className={classes.paddedStuff}
                  fullWidth
                  value={state.checkNumber } // "2017-05-24"}
                  onChange={ e => setState({...state, checkNumber: e.target.value}) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                id="date"
                label="Effective Date"
                type="date"
                className={classes.paddedStuff}
                fullWidth
                value={moment(state.date).format('YYYY-MM-DD') } // "2017-05-24"}
                onChange={ e => setState({...state, date: e.target.value}) }
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading || (state.paymentType  && state.paymentType.value === 0) || !state.amount} onClick={handleSave}>
            {
              loading ? <CircularProgress /> : 'Save'
            }
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(MakePaymentModal))