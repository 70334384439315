import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Typography, Paper, CircularProgress } from '@material-ui/core'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'

const Dashboard = (props) => {
// console.log('Dashboard Component!!', props)
  const [data, setData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [arSummary, setArSummary] = React.useState([])
  const [payments, setPayments] = React.useState([])
  const [sales, setSales] = React.useState([])
  const [latePaymentFees, setLatePaymentFees] = React.useState(0)
  const [latePaymentFeesPaid, setLatePaymentFeesPaid] = React.useState(0)
  const [ccFees, setCCFees] = React.useState(0)
  const [unusedDeposits, setUnusedDeposits] = React.useState({})
  const [open, setOpen] = React.useState(false);
  const [entity, setActiveEntity] = React.useState({ _id: 'All Assigned' })
  const [range, setRange] = React.useState(30);
  const [startDate, setStartDate] = React.useState(moment(new Date()).subtract(30, 'days'));
  const [endDate, setEndDate] = React.useState(new Date());


const handleChange = async (event) => {
  setRange(event.target.value);
  let newStartDate = moment().subtract(event.target.value, 'days')
// console.log('New start Date', newStartDate)
  setStartDate(newStartDate)
  setOpen(false);
  refreshOurData(event.target.value)
};

const handleClose = () => {
  setOpen(false);
  // props.onUpdate()
}

const handleOpen = () => {
  setOpen(true);
};

const refreshOurData = async (startDate, entityToUse) => {
  setLoading(true)
  let chosenEntity = entity._id
  if (entityToUse) chosenEntity = entityToUse._id
// console.log('Refresh Data', startDate)
// console.log("Entity", entity)
  const response = await axios.get(`${props.url}/api/customer/getDashboardData?timeframe=${startDate}&entity=${chosenEntity}`)
  if (response && response.data) {
    setLoading(false)
  // console.log('Dashbaord Data to SET', response.data)
    let dashboardData = response.data
    let arSummary = dashboardData.arStuff
    setArSummary(arSummary)
    // if (dashboardData && dashboardData.payments) setPayments(dashboardData.payments)
    // if (dashboardData && dashboardData.unusedDepositBalance) setUnusedDeposits(dashboardData.unusedDepositBalance[0])
    // if (dashboardData && dashboardData.recentSales) setSales(dashboardData.recentSales)
    // setLatePaymentFees(dashboardData.latePaymentFees)
    // setCCFees(dashboardData.creditCardFees)
    if (dashboardData && dashboardData.payments) setPayments(dashboardData.payments)
    if (dashboardData && dashboardData.unusedDepositBalance) setUnusedDeposits(dashboardData.unusedDepositBalance[0])
    if (dashboardData && dashboardData.recentSales) setSales(dashboardData.recentSales)
    if (dashboardData && dashboardData.latePaymentFees) setLatePaymentFees(dashboardData.latePaymentFees)
    if (dashboardData && dashboardData.latePaymentFeesPaid) setLatePaymentFeesPaid(dashboardData.latePaymentFeesPaid)
    if (dashboardData && dashboardData.creditCardFees) setCCFees(dashboardData.creditCardFees)
  }
}

  // const getDashboardData = async (timeframe, callback) => {
  // // console.log('Get our Dashboard Data!', timeframe)
  //   const response = await axios.get(`${props.url}/api/customer/getDashboardData?timeframe=${timeframe}&entity=${entity._id}`)
  //   if (response && response.data) {
  //   // console.log('Dashbaord Data', response.data)
  //     return callback(response.data)
  //   }
  // }
  React.useEffect(() => {

    refreshOurData('30')
  //   // , (dashboardData) => {
  //   //   let arSummary = dashboardData.arStuff
  //   //   setArSummary(arSummary)
  //   //   if (dashboardData && dashboardData.payments) setPayments(dashboardData.payments)
  //   //   if (dashboardData && dashboardData.unusedDepositBalance) setUnusedDeposits(dashboardData.unusedDepositBalance[0])
  //   //   if (dashboardData && dashboardData.recentSales) setSales(dashboardData.recentSales)
  //   //   if (dashboardData && dashboardData.latePaymentFees) setLatePaymentFees(dashboardData.latePaymentFees)
  //   //   if (dashboardData && dashboardData.latePaymentFeesPaid) setLatePaymentFeesPaid(dashboardData.latePaymentFeesPaid)
  //   //   if (dashboardData && dashboardData.creditCardFees) setCCFees(dashboardData.creditCardFees)
  //   // })
  }, [])

  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

 const handleUpdateVisibleEntity = (activeEntity) => {
   if (activeEntity && activeEntity === 'All Assigned') {
    setActiveEntity({ _id: 'all' })
   } else {
    setActiveEntity(activeEntity)
   }
   refreshOurData(range, activeEntity)
}

  return (
    <Paper style={{padding: '15px'}}>
      {
        (props.user && props.user.entities && props.user.entities.length > 1) ? (
          <Grid container style={{padding: '10px'}}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ChooseEntityComponent allowAll={true} title={'Visible Entities'} user={props.user} updateParent={handleUpdateVisibleEntity} />
          </Grid>
        </Grid>
        ) : ''
      }
       {
          loading ? <CircularProgress size={48} color="primary" /> : (
      <Grid container spacing={3}>
        <Grid item lg={6} xs={6}>
         
          <h1>Dashboard</h1>
        </Grid>
        <Grid item lg={6} xs={6}>
          <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={range}
              onChange={handleChange}
              
            >
              <MenuItem value={1}>Past 24 Hours</MenuItem>
              <MenuItem value={7}>Last 7 Days</MenuItem>
              <MenuItem value={14}>Last 14 Days</MenuItem>
              <MenuItem value={30}>Last 30 Days</MenuItem>
              <MenuItem value={60}>Last 60 Days</MenuItem>
              <MenuItem value={90}>Last 90 Days</MenuItem>
              <MenuItem value={180}>Last 6 Months</MenuItem>
              <MenuItem value={'YTD'}>Year To Date</MenuItem>
              <MenuItem value={365}>Last Year</MenuItem>
              <MenuItem value={9999}>Forever</MenuItem>
            </Select>
        </Grid>
        <Grid item lg={4} xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                AR Summary
              </Typography>
             
                    <Grid container spacing={1}>
                      <Grid item lg={12} xs={12}>
                        <Card>
                          <CardContent>
                            Total Due: <br /> {(arSummary && arSummary.total) ? currencyFormat(arSummary['total']['totalValue']) : ''}
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item lg={3} xs={6}>
                        <Card>
                          <CardContent>
                            0-30: {(arSummary && arSummary.zeroToThirty) ? currencyFormat(arSummary['zeroToThirty']['totalValue']) : ''}
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item lg={3} xs={6}>
                        <Card>
                          <CardContent>
                            31-60: {(arSummary && arSummary.thirtyOneToSixty) ? currencyFormat(arSummary['thirtyOneToSixty']['totalValue']) : ''}
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item lg={3} xs={6}>
                        <Card>
                          <CardContent>
                            61-90: {(arSummary && arSummary.sixtyOneToNinety) ? currencyFormat(arSummary['sixtyOneToNinety']['totalValue']) : ''}
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item lg={3} xs={6}>
                        <Card>
                          <CardContent>
                            91+: {(arSummary && arSummary.ninetyPlus) ? currencyFormat(arSummary['ninetyPlus']['totalValue']) : ''}
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                 
            </CardContent>
          </Card>
          <Grid item lg={12} xs={12}>
            <Card>
              <CardContent>
                Credit Card Fees: <br /> {currencyFormat(ccFees)}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Card>
              <CardContent>
                Late Payment Fees: <br /> {currencyFormat(latePaymentFees)}
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={12} xs={12}>
            <Card>
              <CardContent>
                Late Payment Fees Waived: <br /> {currencyFormat(latePaymentFeesPaid)}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item lg={4} xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                Payment History
              </Typography>
              <Grid container spacing={1}>
                {payments.map(pmt => (
                  <Grid item lg={4} key={pmt.status}>
                     <Card>
                      <CardContent>
                        <strong>{pmt.status}</strong>: {pmt.count} {currencyFormat(pmt.value)}
                      </CardContent>
                    </Card>
                    
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={4} xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                Unused Deposit Balance
              </Typography>
              <Grid container spacing={1}>
                <Grid item lg={12} xs={12}>
                    <Card>
                      <CardContent>
                        Total Balance: <br /> {unusedDeposits ? currencyFormat(unusedDeposits['totalValue']) : ''}
                      </CardContent>
                    </Card>
                  </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="h2" gutterBottom>
                Recent Sales
              </Typography>
              <Grid container spacing={1}>
                {sales.map(row => (
                  <Grid item lg={12} key={row._id}>
                    <strong>{row.name}</strong>: {row.count} {currencyFormat(row.totalValue)}
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
       ) }
    </Paper>
  ) 
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(Dashboard)