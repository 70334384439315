import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField';
import axios from 'axios'
import { Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';
import { currencyFormat } from '../../services/common'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 0,
    backgroundColor: '#eee',
    minHeight: 80,
    border: '1px solid #ccc',
    borderRadius: 6,
    padding: 10,
    maxHeight: 350,
    overflowY: 'scroll'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  IncidentHistoryBox: {

  }
}))

const EmployeeIncident = (props) => {
  const classes = useStyles()
  // const [incidentHistory, setIncidentHistory] = React.useState([])
  const fetchIncidents = async (employeeId) => {
  // console.log('FETCH INCIDENTS!!!!!!!!!!!')
    const resp = await axios.get(`${props.url}/api/employees/getIncidentHistory?employeeId=${employeeId}`)
  // console.log('Response: ', resp.data)
    if (resp && resp.data && resp.data.length) props.setIncidents(resp.data)
  }
  React.useEffect(() => {
    if (props.employee && props.employee._id) {
      fetchIncidents(props.employee._id)
    }
  }, [props.employee])

  // React.useEffect(() => {
  // // console.log('We must have new incidents!!!')
  //   fetchIncidents(props.employee._id)
  // }, [props.newIncidentsToUpdate])

  return (
    <div className={classes.root}>
      <span style={{ fontSize: '1.12em', color: '#aaa' }}>
        Incident History
      </span>
      <IncidentHistory {...props} incidentHistory={props.incidents} />
    </div>
  )
}

const IncidentHistory = (props) => {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Reported By</TableCell>
            <TableCell>Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props.incidentHistory.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{moment(item.created_at).format('MM/DD/YY hh:mma')}</TableCell>
                  <TableCell>{item.created_by_name}</TableCell>
                  <TableCell>{item.notes}</TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(EmployeeIncident)