import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import { Button, CircularProgress, TextField, Tooltip, IconButton, Typography}  from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import PlusIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DeleteComponent from '../Common/DeleteComponent'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// const useStyles = makeStyles(theme => ({
const styles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      margin: 10,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  topButtons: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center',
      margin: 5,
    },
    [theme.breakpoints.down('md')]: {
      margin: 5,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  helperText: {
    fontSize: '1.25em',
    color: '#bbb'
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    
  },
  list: {
    width: '100%',
    maxHeight: 400,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  containerArea: {
    // backgroundColor: 'red',
    // border: '3px solid blue'
  },
  categoryHeader: {
    fontSize: '1.35em',
    fontWeight: 800,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  pageHeader: {
    fontSize: '1.4em',
    fontWeight: 600,
    color: '#ccc'
  },
  h4: {
    textAlign: 'center',
    fontSize: '1.4em'
  },
  mainContainer: {
    margin: 5,
    width: '100%',
    padding: 10,
    border: '1px solid #ccc',
    borderRadius: 8,
    backgroundColor: '#eee'
  }
  })
);

function not(a, b) {
  // console.log('NOt A:', a)
  // console.log('NOt B:', b)

  return a.filter((value) => {
    // let val = b.indexOf(value)
    // console.log(val)
    return (b.map(obj => obj._id).indexOf(value._id) === -1)
    // return (b.indexOf(value) === -1)
  })
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const ChooseTasksSelected = (props) => {
  console.log('Included Tasks LIst: ', props)
  const classes = styles();
  // const [checked, setChecked] = React.useState([]);
  const [state, setState] = React.useState({
    selected: []
  })
  const [item, setItem] = React.useState({})
  const [isModalOpen, setModalOpen] = React.useState(false)
  const list = [...props.list, ...props.selected]
  const items = props.list || []
  const checked = props.selected
  React.useEffect(() => {
    if (props.selected && props.selected.length) {
      
      // setOurCheckedItems(union(checked, props.selected))
  
    } else {
      // setOurCheckedItems([])
    }

  }, [props])

  
  const handleOpenModal = () => {
    setModalOpen(true)
  }
  
  const handleUpdateFromModal = async (data) => {
    console.log('Update from modal', data)
    setModalOpen(false)
    if (data && data.addToGlobalList) {
      console.log('Save this to the db')
      const obj = {
        updatedItem: data
      }
      const response = await axios.post(`${props.url}/api/settings/setDefaultMtcChecklistItems`, obj)
      console.log('Update Response', response.data)
      let newItem = response.data[response.data.length - 1]
      console.log('New item to add', newItem)
      props.addItem(newItem)
    } else if (data) {
      let newItem = {...data, _id: Date.now() }
      props.addItem(newItem)
    } else {
      console.log('Just cancel....')
    }   
  }

  
const setOurCheckedItems = (checkedItems, allItems) => {
  console.log('Set our checked items...', checkedItems)
  props.updateParent(checkedItems)
}

const handleToggle = (value) => () => {
  const currentIndex = checked.indexOf(value._id);
  // console.log('Currnet index: ', currentIndex)
  let index = checked.findIndex(item => (item._id === value._id))
  // console.log('Index: ', index)
  const newChecked = [...checked];

  if (index === -1) {
    newChecked.push(value);
  } else {
    newChecked.splice(index, 1);
  }
  // setOurCheckedItems(newChecked);
  props.updateParent(newChecked)
};

const numberOfChecked = (items) => { return checked.length };

// const handleToggleAll = (items) => () => {
//   if (numberOfChecked(items) === items.length) {
//     setOurCheckedItems(not(checked, items));
//   } else {
//     setOurCheckedItems(union(checked, items));
//   }
// };

  const handleUpdateSelected = (e, newList) => {
    console.log('Upate:', newList)
  }

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      className={classes.mainContainer}
    >
      
      <ModifyItemModal open={isModalOpen} item={item} updateParent={handleUpdateFromModal} />

      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.categoryHeader}>
        <div>Tasks</div>
        <div> <IconButton onClick={handleOpenModal} ><AddIcon size={48} /></IconButton></div>
      </Grid>

      <Grid item lg={12} md={12} sm={12} xs={12} >
        <Typography>Whatever is Checked Below Will Show Up On the App For the Crew</Typography>
          <Card className={classes.containerArea}>
     
       
            <List className={classes.list} component="div" role="list">
              {props.list.map((value) => {
                // console.log('LIST Items 222: ', value)
                const labelId = `transfer-list-all-item-${value}-label`;
                let isChecked = checked.indexOf(value) !== -1
                // console.log('Is checked 241:', isChecked)
                let inOurChecked = checked.findIndex(item => (item._id.toString() === value._id.toString()))
                
                // console.log('In our checked:', inOurChecked)
                if (inOurChecked > -1) isChecked = true
                return (
                  <ListItem style={{ fontSize: '0.7em', margin: '-1.5em', paddingBottom: -5 }} key={value._id} role="listitem" button onClick={handleToggle(value)}>
                    <ListItemIcon>
                      <Checkbox
                        checked={isChecked}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText style={{ fontSize: '0.7em', margin: '-0.85em' }} id={labelId} primary={`${value.english } / ${value.spanish}`} />
                  </ListItem>
                );
              })}
              <ListItem />
            </List>
          </Card>
       
      </Grid>

    </Grid>
  );
}


const defaultVals = {
  english: '',
  spanish: '',
  addToGlobalList: false
}

const ModifyItemModal = (props) => {
  const [state, setState] = React.useState(defaultVals)

  React.useEffect(() => {
    setState({ ...state, ...props.item })
  }, [props.item])

  const handleClose = () => {
    props.updateParent(null)
  }
  const updateItem = (e) => {
    setState({...state, [e.target.id]: e.target.value})
  }
  const handleSave = () => {
    // console.log('Save it', state)
    if (props.item && props.item.english) {
      props.updateParent({
        type: 'update',
        originalItem: props.item,
        updatedItem: state
      })
      setState(defaultVals)
    } else {
      props.updateParent(state)
      setState(defaultVals)
    }
  }
  return (
    <Dialog
      size="md"
      fullWidth
      open={props.open}
      onClose={handleClose}
      style={{ margin: 10, padding: 15 }}
      >
        <DialogContent>
          <DialogTitle>{(props.item && props.item.english ) ? 'Modify' : 'Add'} Item</DialogTitle>
            <TextField
                id="english"
                label="English"
                value={state.english}
                helperText="What Will Someone Viewing the App See in English? This should be short and concise ideally"
                fullWidth
                variant="outlined"
                
                // className={classes.paddedStuff}
                onChange={ updateItem }
              />
              <TextField
                id="spanish"
                label="Spanish"
                value={state.spanish}
                helperText="What Will Someone Viewing the App See in Spanish? This should be short and concise ideally"
                fullWidth
                variant="outlined"
                
                // className={classes.paddedStuff}
                onChange={ updateItem }
              />
                <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={state.addToGlobalList} onChange={ e => setState({...state, addToGlobalList: !state.addToGlobalList } ) } name="Share" />}
                    label="Add to Global List"
                  />
                </FormGroup>
        </DialogContent>
       
     
      <DialogActions>
      <Button
          onClick={handleClose}
          >CANCEL</Button>
        <Button
          onClick={handleSave}
          >SAVE</Button>
      </DialogActions>
      </Dialog>
  )
}

// const ChooseTasksSelected = (props) => {
// console.log('Choose Categories Includded? ', props)
//   const classes = styles()
//   const handleScopeChoices = (d) => {
//     props.updateParent(d)
//   }
//   return (
//     <CategoryList item={props.item} categories={props.scopes} submitOptions={handleScopeChoices} />
//   )
// }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(ChooseTasksSelected))