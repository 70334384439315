import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper'
import axios from 'axios'
import ObjectivesList from './ObjectivesList'

const Objectives = (props) => {
  // console.log('Scopes Component', props)
  const [list, setList] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0
  })

  React.useEffect(() => {
    let isMounted = true
    async function fetchObjectives () {
      axios
      .get(
        `${props.url}/api/settings/listObjectives`
        )
      .then(({ data }) => {
      // console.log("Data", data)
        if (isMounted) setList(data)
      }).catch(function (err) {
      // console.log('Error getting stuff', err)
      });
    }
    fetchObjectives()
    return () => {
      isMounted = false
    }
  }, [])

  const updateObjectives = (objectiveToUpdate) => {
  // console.log('Update Objectives: ', objectiveToUpdate)
    let existingObjectives = list
    // console.log('Update Objective: ', scope)
    // console.log('Got %d clients: ', clients.length)
    let index = existingObjectives.findIndex(item =>  (item._id === objectiveToUpdate._id))
    if (index > -1) {
      // console.log('Svc already exists', index)
      // console.log(services[index])
      existingObjectives[index] = objectiveToUpdate
      // console.log('Agai: ', services[index])
      // console.log(services)
      // setRows(services)
      // setFilteredRows(services)
      setList(existingObjectives)
    } else {
      existingObjectives.push(objectiveToUpdate)
      setList(existingObjectives)
    }
    setState({...state, counter: state.counter++ })
  }

  const deleteFromDB = async (id) => {
    axios({
      method: 'post',
      url:  `${props.url}/api/settings/deleteObjective?id=${id}`
    }).then(response => {
      // console.log('Axios response from deleting objective: ', response)
      if (response && response.data && response.data.deleted) {
        props.notification({
          title: 'Objective Deleted',
          message: 'That Objective Is History!',
          type: 'success'
        })
      }
    })
  }

  const handleDelete = (id) => {
    let existingObjectives = list
    let index = existingObjectives.findIndex(item =>  (item._id ===  id))
    if (index > -1) {
      existingObjectives.splice(index, 1)
      setList(existingObjectives)
      deleteFromDB(id)
    }
  }

  return (
    <div style={{padding: '5px', margin: '10px'}}>
      <Grid container>
        <Grid item lg={6} xs={12}>
          <h1>Objectives</h1>
        </Grid>
        <Grid item lg={6} xs={12}>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Paper style={{ minHeight: '80vh' }}>
            <Grid container spacing={4} style={{ padding: '10px' }}>
            
              <Grid item lg={12} xs={12}>
                <ObjectivesList list={list} updateObjective={updateObjectives} deleteObjective={handleDelete} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      
  </div>
  )
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(Objectives)