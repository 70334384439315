import React, { useState, useRef } from 'react';
import { Button, TextField, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Paper, IconButton, Tooltip } from '@material-ui/core';
import { GoogleMap, LoadScript, DrawingManager, Polygon } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { connect } from 'react-redux'
import axios from 'axios'
import CustomMarker from './CustomMarker'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { useLocation } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PrintIcon from '@material-ui/icons/Print'
import CloseIcon from '@material-ui/icons/Close'
import { isEven } from '../../../services/common'

const mapLibraries = ["drawing", "geometry"];

const containerStyle = {
  width: '100%',
  height: '90vh'
};

const center = {
  lat: 33.72972977616888,
  lng: -111.9839555679331
};

const position = {
  lat: 33.7,
  lng: -111.97
}

const onLoad = marker => {
// console.log('marker: ', marker)
}
// const image = "https://dflportal.com/favicon.png"
const dfl = "https://dflportal.com/favicon.png"
const benhart = "https://www.benhartlandscaping.com/wp-content/themes/benhart/img/icons/favicon-16x16.png"

const colors = ['#ACCACD', '#1c801f', '#84d1e0', '#84e0b0', '#bbe084', '#689623', '#239670']

const distinctHexColorsOLD = [
  "#D32F2F", "#1976D2", "#388E3C", "#FBC02D", "#8E24AA",
  "#0288D1", "#C2185B", "#7B1FA2", "#C2185B", "#F57C00",
  "#0288D1", "#7B1FA2", "#C0CA33", "#F9A825", "#8C9EFF",
  "#FF8A80", "#B9F6CA", "#FFAB40", "#84FFFF", "#80D8FF",
  "#A7FFEB", "#FF9E80", "#FF6E40", "#1DE9B6", "#00BFA5",
  "#69F0AE", "#00E676", "#76FF03", "#C6FF00", "#FFD600",
  "#FF5733", "#33FF57", "#5733FF", "#FF33E3", "#E333FF",
  "#33FFE3", "#FFFF33", "#33FFFF", "#FF5733", "#33FF57",
  "#5733FF", "#FF33E3", "#E333FF", "#33FFE3", "#FFFF33",
  "#33FFFF", "#33FF33", "#FF3333", "#3333FF", "#33FFFF",
  "#AA00FF", "#00AAFF", "#FF00AA", "#FFAA00", "#00FFAA",
  "#5500FF", "#0055FF", "#FF0055", "#FF5500", "#0055FF",
  "#11FF00", "#FF0011", "#0011FF", "#FF1100", "#0011FF",
  "#FF00FF", "#00FFFF", "#FFFF00", "#00FF00", "#FF0000",
  "#0000FF", "#0000AA", "#000055", "#555555", "#AAAAAA",
  "#111111", "#222222", "#333333", "#444444", "#666666",
  "#777777", "#888888", "#999999", "#BBBBBB", "#CCCCCC",
  "#DDDDDD", "#EEEEEE", "#F0F0F0", "#F1F1F1", "#F2F2F2",
  "#F3F3F3", "#F4F4F4", "#F5F5F5", "#F6F6F6", "#F7F7F7",
  "#F8F8F8", "#F9F9F9", "#FAFAFA", "#FBFBFB", "#FCFCFC",
  "#FEFEFE", "#C0C0C0", "#B0B0B0", "#A0A0A0", "#909090",
];

const calculateVisitFrequency = (frequency) => {
  let numberOfVisits = 0
  switch (frequency) {
      case 'weekly':
          numberOfVisits = 52
          break;
      case 'biweekly':
          numberOfVisits = 26
          break;
      case 'monthly':
          numberOfVisits = 12
          break;
  }
  return numberOfVisits / 12
}

const calculateJakesThing = (markers) => {
  let totalHoursOfWorkPerMonth = 0
  let totalDollarsChargedPerMonth = 0
  markers.map(item => {
    // console.log('Calculate our hours and rate')
    let numberOfVisitsPerMonth = calculateVisitFrequency(item.frequency)
    // console.log('Visits:', numberOfVisitsPerMonth)
    let monthlyRate = item.price
    let minutesOfWorkPerVisit = item.estMinutes
    let hour = minutesOfWorkPerVisit / 60
    // console.log('Hours of work:', hour)
    let totalHoursPerMonth = numberOfVisitsPerMonth * hour
    let dolhr = monthlyRate / totalHoursPerMonth
    // console.log('$ dollars per hour', dolhr)
    dolhr = parseFloat(dolhr).toFixed(2)
    // console.log('This guy is:', dolhr)
    totalHoursOfWorkPerMonth += Number(totalHoursPerMonth)
    totalDollarsChargedPerMonth += Number(monthlyRate)
  })
  // Average charge per HOUR
  // console.log("Total WORK", totalHoursOfWorkPerMonth)
  // console.log('TOtal Revenue:', totalDollarsChargedPerMonth)
  let ourAverage = parseFloat(totalDollarsChargedPerMonth / totalHoursOfWorkPerMonth).toFixed(2)
  // console.log('Average:', ourAverage)
  return ourAverage
}

const calculateOurDollarsPerHours = (item) => {
  if (item && item.estMinutes) {
      let frequency = item.frequency
      let numberOfVisitsPerMonth = calculateVisitFrequency(frequency)
      let estMinutes = item.estMinutes
      // let totalMinutesOfWorkAnnually = Number(estMinutes) * Number(numberOfVisitsPerYear)
      let hour = estMinutes / 60
      console.log('Hours of work:', hour)
      let totalHoursPerMonth = numberOfVisitsPerMonth * hour
      let amt = item.price
      let dolhr = amt / totalHoursPerMonth
      console.log('$ dollars per hour', dolhr)
      dolhr = parseFloat(dolhr).toFixed(2)
      return dolhr
  }
  return null
}

function generateUniqueHexColors(count) {
  const colors = new Set();
  const result = [];

  while (colors.size < count) {
    const color = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
    colors.add(color);
  }

  colors.forEach((color) => {
    result.push(color);
  });

  return result;
}

// Example usage to generate 70 unique colors:
const distinctHexColors = [    
  "#FF0000", // Red
  "#00FF00", // Lime
  "#0000FF", // Blue
  "#FFFF00", // Yellow
  "#FF00FF", // Magenta
  "#00FFFF", // Cyan
  "#FFA500", // Orange
  "#800080", // Purple
  "#008000", // Green
  "#FFC0CB", // Pink
  "#A52A2A", // Brown
  "#808080", // Gray
  // "#111", // Black
  "#FFFFFF", // White
  "#FFD700", // Gold
  "#4B0082", // Indigo
  "#7FFF00", // Chartreuse
  "#FF4500", // OrangeRed
  "#1E90FF", // DodgerBlue
  "#8B4513"  // SaddleBrown
]

// const distinctHexColors = ["#c331a2", "#998531", "#839fc7", "#4b61b6", "#131409", "#bb2989", "#2ee1f2", "#f3f039", "#a5ca90", "#55106b", "#ce082f", "#0f447f", "#df8145", "#8eb7ee", "#8c9fa5", "#6858e8", "#9689e0", "#69f468", "#404061", "#972e3e", "#a02f62", "#2ba010", "#ed93b1", "#149091", "#793be2", "#fe3d0e", "#1a239f", "#7a0aee", "#d96f62", "#18546d", "#510ad6", "#6dca75", "#101224", "#a42c25", "#8c1739", "#a558ba", "#4e2aea", "#4ce829", "#96e0c8", "#4f3842", "#fd2953", "#3d020c", "#eb6419", "#50bbce", "#292208", "#26e6b2", "#ea857e", "#9b44d0", "#c52f5c", "#056e88", "#21dd3b", "#bc6eea", "#b24545", "#857d44", "#1331fa", "#731d99", "#b60b0c", "#b43d94", "#787348", "#f015a9", "#6ed073", "#dc496d", "#b87447", "#880e01", "#dbf99d", "#5308e2", "#0a54dc", "#f38648", "#77cbbe", "#c48ad1"]
const polygonOptions = {
  fillOpacity: 0.3,
  fillColor: '#ff0000',
  strokeColor: '#ff0000',
  strokeWeight: 2,
  draggable: true,
  editable: true
}

const Sidebar = (props) => {
  console.log('SIDEBAR;', props)
  const activeMarker = props.activeMarker
  let invalidMarkers = props.invalidMarkers
  // const crews = props.crews
  let markers = props.markers
  let filteredCrewIds = [...new Set(markers.map(site => site.crew))]
  let filteredCrews = []
  filteredCrewIds.forEach(crewId => {
    let crewItem = props.crews.find(crew => (crew._id === crewId))
    if (crewItem?._id) filteredCrews.push(crewItem)
  })
  console.log('SIde bar: filteredCrews', filteredCrews)
  // const legendData = []
  // markers.map(item => {
  //   let isInLegendIndex = legendData.findIndex(crew => (crew.crewId === item.crewId))
  //   if (isInLegendIndex < 0) {
  //     let color = setMarkerColorBasedOnCrew(item.crewId, crews)
  //     console.log('Color:', color)
  //     legendData.push(
  //       {
  //       color: color,
  //       crewName: item.crewName,
  //       crewId: item.crewId
  //     })
  //   }
  // })
  // let avgHourlyRate = calculateJakesThing(markers) || 'Not Available'
  // console.log('Avg hourly rate:', avgHourlyRate)
  // console.log('Legend Data:', legendData)
  // console.log('Active Marker', activeMarker)
  const handlePrintList = async () => {
    // console.log('Print that shit!')
    let resp = await axios.get(`${props.url}/api/hmi/printJobsites`)
    // console.log('Print Response:', resp)
    let newBlob = new Blob([resp.data], {type: "text/csv"})
    // console.log(newBlob)
    const data = window.URL.createObjectURL(newBlob);
    // console.log(data)
    var link = document.createElement('a');
    link.href = data;
    let fileName = 'HMI_Jobsites'.replace(/[^a-z0-9]/gi, '_').toLowerCase()
    link.download=`${fileName}.csv`;
    link.click();
  }

  let filteredFrequencies = [
    'All',
    'Weekly',
    'Bi-Weekly'
  ]  
  if (props?.jobSites?.length) {
    props.jobSites.forEach(site => {
      if (!filteredFrequencies.includes(site.frequency)) {
        filteredFrequencies.push(site.frequency)
      }
    })
  }
  console.log('Filtered Frequencies', filteredFrequencies)


  return (
    <div style={{ overflowY: 'scroll', padding: 10, width: '100%', height: '90vh', zIndex: 9999, backgroundColor: '#bbb'}} >
      <Grid container spacing={2}>
      <Grid item lg={6} xs={12} sm={12} md={6}>
        <div style={{ marginTop: '16px'}}>
          Total Markers: {markers.length}
          <br />
          Invalid: {invalidMarkers?.length || 0}
        </div>
       
      </Grid>
      <Grid item lg={6} xs={12} sm={12} md={6}>
        <Tooltip
          title="Print List"
          >
                      <IconButton
           
           onClick={handlePrintList}
         >
           <PrintIcon size={24} />
         </IconButton>

          </Tooltip>

      </Grid>
    
      {
        (activeMarker && activeMarker._id) ? (
          <React.Fragment>
            
            
     

            <Grid item lg={12} xs={12} sm={12} md={12}>
                <ActiveMarkerDetails activeMarker={activeMarker} handleUnassign={props.handleUnassign} /> 
            </Grid>
          </React.Fragment>
        ) : null
      }
      {
        props?.filteredDays?.length ? (
          <CrewBreakdown {...props} filteredCrews={filteredCrews} />
        ) : null
      }
        {/* <Grid item lg={12} xs={12} sm={12} md={12}>
          <Legend data={props.legendData} />
        </Grid> */}
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <FilterArea filteredFrequencies={filteredFrequencies} crews={props.crews} updateCrews={props.handleUpdateVisibleCrews} updateDays={props.handleUpdateVisibleDays} updateWeeks={props.handleUpdateVisibleByWeek} updateFrequency={props.handleUpdateVisibilityByFrequency} />
        </Grid>
      </Grid>
    </div>
  )
}

const CrewBreakdown = (props) => {
  console.log('Crew brekadown', props)
  // Breakdown total work and drive time per day
  return (
    <div style={{ maxHeight: '480px', overflowY: 'auto', width: '100%' }}>
      Crew Breakdown:
      {
        props.filteredDays.map((day, dayIndex) => {
          return (
            <div key={dayIndex}>
              Day: {day}
              {
                props.filteredCrews.map((crew, crewIndex) => {
                  return (
                    <div key={crewIndex} style={{ margin: '5px', padding: '5px', backgroundColor: crew.color, borderRadius: 5, border: '1px solid #111' }}>
                      {crew.name} - <CrewTotals crew={crew} day={day} jobSites={props?.jobSites} />
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </div>
  )
}

const CrewTotals = ({ crew, day, jobSites }) => {
  console.log('Display Crew Totals', day)
  let totalHoursEvenWeek = 0
  let totalHoursOddWeek = 0
  let totalMonthlyHours = 0
  let totalDriveTime = 0
  let filteredStops = jobSites.filter(site => (site.crew === crew._id))
  console.log('Filtered Stops', filteredStops)
  filteredStops.forEach(site => {
    let includeIt = false
    const frequency = site.frequency
    if (frequency === 'Daily') {
      includeIt = true
      totalHoursOddWeek += parseFloat(site?.estimatedHours || 0)
      totalHoursEvenWeek += parseFloat(site?.estimatedHours || 0)
    }
    if (frequency === 'Every Other Day (M-W-F)' && (day === 'Monday' || day === 'Wednesday' || day === 'Friday')) {
      totalHoursOddWeek += parseFloat(site?.estimatedHours || 0)
      totalHoursEvenWeek += parseFloat(site?.estimatedHours || 0)
      includeIt = true
    }
    if (frequency === 'Twice a Week' && site.preferredDays && site.preferredDays.includes(day)) {
      includeIt = true
      totalHoursOddWeek += parseFloat(site?.estimatedHours || 0)
      totalHoursEvenWeek += parseFloat(site?.estimatedHours || 0)
    }
    if (frequency === 'Weekly' && site.preferredDay === day) {
      totalHoursOddWeek += parseFloat(site?.estimatedHours || 0)
      totalHoursEvenWeek += parseFloat(site?.estimatedHours || 0)
      includeIt = true
    }
    if (frequency === 'Bi-Weekly' && site.preferredDay === day) {
      console.log('Bi-WEekly!', site)
      if (site.evenOrOddWeeks === 'Even') {
        totalHoursEvenWeek += parseFloat(site?.estimatedHours || 0)
      } else {
        totalHoursOddWeek += parseFloat(site?.estimatedHours || 0)
      }
    }
    if (frequency === 'Monthly' && site.preferredDay === day) {
      totalMonthlyHours += parseFloat(site?.estimatedHours || 0)
    }
  })
  return (
    <div>
      Even Week Hours: {Math.round(totalHoursEvenWeek * 100) / 100}
      <br/>
      Odd Week Hours: {Math.round(totalHoursOddWeek * 100) / 100}
      <br />
      Monthly Hours Needed: {Math.round(totalMonthlyHours * 100) / 100}
    </div>
  )
}

const ActiveMarkerDetails = ({ activeMarker, handleUnassign }) => {
  const clientName = activeMarker?.client?.displayName || activeMarker?.clientName
  const address = `${activeMarker?.address} ${activeMarker?.city}, ${activeMarker?.zip}`
  console.log('active marker', activeMarker)

  return (
    <Grid container style={{ margin: 10, backgroundColor: '#fff', padding: 15, borderRadius: 8 }}>
      Active Marker
      <Grid item lg={12} xs={12} sm={12} md={12}>
        Client:  { clientName }
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        Address:  { address }
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        Hours Required:  { activeMarker?.estimatedHours || '' }
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}      >
       

        {
           (activeMarker.assignedCrew && activeMarker.assignedCrew._id) ? (
            <div   style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingHorizontal: '4px'
            }}>
               <div>Assigned Irrigation Crew: </div>
              <div style={{ marginLeft: '5px' }}>{`  ${activeMarker.assignedCrew.name}   `}</div>
               <div style={{ width: '20px', height: '20px', backgroundColor: activeMarker.color, display: 'inlineBlock', marginLeft: '5px' }}></div>
               <div onClick={e => handleUnassign(activeMarker) } style={{ marginBottom: '5px', cursor: 'pointer' }}><CloseIcon size={12} /></div>
            </div>
             
           ) : null
        }
      </Grid>
    </Grid>
  )
}

const Legend = ({ data }) => {
  console.log('Build our Legend:', data)

  return (
    <Grid container style={{ backgroundColor: '#aaa', padding: 15, borderRadius: 8 }}>
      Legend
      <Grid item lg={12} xs={12} sm={12} md={12}>
        {
          data.map((item, index) => {

            return (
              <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', alignContent: 'center', padding: 8, border: '2px solid #555', margin: 5, borderRadius: 5 }}>
                <div style={{ backgroundColor: item.color, height: 30, width: 30, borderRadius: 5, marginRight: 10  }}></div>
                <div>{item.crewName}</div>
              </div>
            )
          })
        }
      </Grid>
    </Grid>
  )
}
const MarkerDetails = ({ markers, handleClearPolygons}) => {
  console.log('Markers in details...', markers.length)
  let totalMinutesOfWork = 0
  let totalDollars = 0
  markers.map(item => {
    totalMinutesOfWork += Number(item.estMinutes)
    totalDollars += Number(item.price)
  })
  totalDollars = parseFloat(totalDollars).toFixed(2)
  return (
    <div>
      <Button onClick={handleClearPolygons}>Clear</Button>
      Markers Selected: {markers.length}
      <br />
      Total Minutes of Work: {totalMinutesOfWork}
      <br/>
      Total $: {totalDollars}
      {
        markers.map((item, index) => {
          console.log(item)
          return (
            <div key={index} style={{ margin: 5, padding: 10, borderRadius: 10, backgroundColor: '#fff' }}>
              Client: {item.clientName}<br/>
              Price: {item.price}<br/>
              Minutes: {item.estMinutes}
            </div>
          )
        })
      }
    </div>
  )
}
// function stringToColorCode(inputString) {
//   // Take the first 6 characters of the input string
//   const substring = inputString.substring(0, 6);

//   // Convert the substring to a hexadecimal number
//   const colorCode = parseInt(substring, 16);

//   // Convert the number to a 6-digit hexadecimal string
//   const hexColor = colorCode.toString(16).padStart(6, '0');

//   // Add a '#' prefix to make it a valid color code
//   return `#${hexColor}`;
// }
const setMarkerColorBasedOnCrew = (crewId, crews) => {
  console.log('Get hex for crew', crewId)
  let hexColor = '#f43acb'
  let crewIndex = crews.findIndex(crew => (crew._id === crewId))
  if (crewIndex > -1) {
    console.log('INDEX: ', crewIndex)
    hexColor = distinctHexColors[crewIndex]
  }
  console.log('Hex color:', hexColor)
  // const substring = crewId.substring(0, 9)
  // const colorCode = parseInt(substring, 16);
  // const hexColor = colorCode.toString(16).padStart(6, '0');
  return `${hexColor}`;
}

function HMIMapView(props) {
  console.log('Map view///crews exist???', props.crews)
  const [markers, setMarkers] = React.useState([])
  const [crews, setCrews] = React.useState(props.crews || [])
  const [state, setState] = React.useState({
    pending: false
  })
  const [activeMarker, setActiveMarker] = React.useState({})
  const [filteredCrews, setFilteredCrews] = React.useState([])
  const [filteredDays, setFilteredDays] = React.useState([])
  const [weekChosen, setWeekChosen] = React.useState('All')
  const [frequencyChosen, setFrequencyChosen] = React.useState('All')
  const [selectedMarkers, setSelectedMarkers] = React.useState([])
  const [jobSites, setJobSites] = React.useState([])
  const [polygons, setPolygons] = useState([])
  const [invalidMarkers, setInvalidMarkers] = useState([])
  const [legendData, setLegendData] = useState([])

  const mapRef = useRef();
  const polygonRefs = useRef([]);
  const activePolygonIndex = useRef();
  const autocompleteRef = useRef();
  const drawingManagerRef = useRef();


  // const [chosenWeek, setChosenWeek] = React.useState('All')
  // const dfl = `${props.url}/pickupTruck.svg`
  // const benhart = "https://www.benhartlandscaping.com/wp-content/themes/benhart/img/icons/favicon-16x16.png"

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const chosenWeek = searchParams.get('chosenWeek');
  const day = searchParams.get('day');




  React.useEffect(() => {
    let isMounted = true

    const fetchMarkers = async () => {

      const activeSites = await axios.get(`${props.url}/api/hmi/getActiveSites`)
      console.log('Got the sites', activeSites)
      // if (activeSites && activeSites.data && activeSites.data.length) filterOurMarkers(activeSites.data, props.crews)
      if (isMounted) {
        // let coloredCrews = activeSites
        // console.log('Setting our Crew Colors')
        // activeSites.data.map((item, index) => {
        //   // let randomNum = Math.floor((Math.random() * 70) + 1)
        //   let crewIndex = [...uniqueCrews].findIndex(crew => (crew._id === item.crew))
        //   console.log(crewIndex)
        //   if (crewIndex > -1) {
        //     const crewItem = uniqueCrews[crewIndex]
        //     let cleanItem = {
        //       ...item,
        //       color: crewItem.color
        //     }
        //     console.log('Clean Item', cleanItem)

        //     coloredCrews.push(cleanItem)
        //   }
        // })
        // console.log('Colored Crews::', coloredCrews)
        setJobSites(activeSites.data)
        updateData(activeSites.data)
      }
    }

    fetchMarkers()

    return () => {
      isMounted = false
    }
  }, [])

  // React.useEffect(() => {
  //   if (props.crews && props.crews.length) {
  //     updateData()
  //   }
  // }, [props])

  const updateData = async (sitesPassed) => {
    let unassignedSites = []
    console.log('UpdateData:: Props::', props)
    let sites = sitesPassed?.length ? sitesPassed : jobSites?.length ? jobSites : []
    console.log('SIteS:', sites)
    const uniqueCrewIds = [...new Set(sites.map(site => site.crew))]
    const uniqueCrews = []
    console.log('Unique Crew Ids', uniqueCrewIds)
    if (uniqueCrewIds.length) {
      uniqueCrewIds.forEach((crewId, uniqueCrewIndex) => {
        // console.log('Find CrewId', crewId)
        let crewIndex = [...props.crews].findIndex(crew => (crew._id === crewId))
        console.log(crewIndex)
        if (crewIndex > -1) {
          // console.log('CREW: 953', props.crews[crewIndex])
          const crewItem = props.crews[crewIndex]
          let hexColor = distinctHexColors[uniqueCrewIndex]
          console.log('crewItem 976', crewItem)
          const crewObj = {
            _id: crewItem._id,
            color: hexColor,
            name: crewItem?.name ? `${crewItem.name} ${crewItem.foremanName ? `(${crewItem.foremanName})` : ''}` : 'Un-Named Crew'
          }
          uniqueCrews.push(crewObj)
        } else {
          console.log('No valid crew!!!', crewId)
        }
      })
    }
    console.log('Unique Crews', uniqueCrews)
    sites.forEach(siteItem => {
      if (siteItem.crew) {
        console.log('SIte...crew', siteItem.crew)
        let crewIndex = [...uniqueCrewIds].findIndex(crewId => (crewId === siteItem.crew))
        console.log('483', crewIndex)
        if (crewIndex > -1) {
          const crewItem = uniqueCrews[crewIndex]
          console.log('Crew item', crewItem)
          siteItem.color = crewItem.color
        } else {
          console.log('489 invalid color....')
        }
      } else {
        unassignedSites.push(siteItem)
      }
    })
    console.log('Sites w/ color...', sites)
    console.log('Unassigned Sites', unassignedSites)
    setCrews(uniqueCrews)
    setJobSites(sites)
    filterOurMarkers(sites)
    setFilteredCrews(uniqueCrews)
  }

  const filterOurMarkers = (data, days, week, frequency, crewsPassed) => {
    console.log('Markers to Filter:', data);
    console.log('Day: %s  Week: %s   Frequency: %s ', days, week, frequency);
    if (!crewsPassed) crewsPassed = filteredCrews || [];
    console.log('Crews Selected', crewsPassed);

    const cleanMarkers = data.filter((stop, index) => {
        console.log('Stop:', stop);
        if (!days) days = []
        // Check frequency filter
        if (frequency && frequency !== 'All' && stop.frequency !== frequency) {
            return false;
        }

        let isDayMatch = true;

        switch(stop.frequency) {
            case 'Every Other Day (M-W-F)':
                isDayMatch = days.some(day => {
                  console.log('664 day', day)
                  return (['Monday', 'Wednesday', 'Friday'].includes(day))
                });
                break;
            case 'Daily':
                // Always show, no need for further day checks
                break;
            case 'Twice a Week':
                isDayMatch = !days?.length || 
                    (stop.preferredDays && stop.preferredDays.some(day => days.includes(day)));
                break;
            case 'biweekly':
                if (week && week !== 'All' && week !== stop.week) {
                    console.log('This one should be ignored');
                    return false;
                }
                // Fall through to check preferred days
            default:
                isDayMatch = !days?.length || days.includes(stop.preferredDay) ||
                    (stop.preferredDays && stop.preferredDays.some(day => days.includes(day)));
        }

        console.log('Is Day Match', isDayMatch);

        // Check crew filter
        const isCrewMatch = !crewsPassed.length || crewsPassed.includes(stop.crew);

        // Construct marker if both day and crew match
        if (isDayMatch && isCrewMatch) {
            const markerPosition = {
                lat: Number(stop.latitude),
                lng: Number(stop.longitude),
            };
            return {
                _id: `${stop._id}_${index}`,
                position: markerPosition,
                ...stop
            };
        }

        return false;
    });

    console.log('Total Markers to filter:', cleanMarkers.length);
    console.log('Markers to set:', cleanMarkers);
    setMarkers(cleanMarkers);
};

  const updateActiveMarker = (d) => {
  console.log('UPdate Active Marker: ', d)
    setActiveMarker(d)
  }

  const OurMarkers = ({ markers, setInvalidMarkers }) => {
    console.log('Generate our Markes', markers.length)
    let icon = dfl
    let invalidMarkerSites = []
    if (markers && markers.length) {
      console.log('Active Marker', activeMarker)
      console.log(markers)
      let ourMarkers = []
      markers.map((jobsite, index) => {
        let isValid = false
      // console.log('Marker jobsite: ', jobsite)
        // if (addy && addy.entity === '610a290f45ef7427f94b2e78') {
        //   icon = benhart
        // }
        let markerPosition = {}
        let latitude = null
        let longitude = null
        if (jobsite.location && jobsite.location.coordinates && jobsite.location.coordinates.length) {
          longitude = jobsite.location.coordinates[0]
          latitude = jobsite.location.coordinates[1]
        } else if (jobsite.latitude && jobsite.longitude) {
          longitude = jobsite.longitude
          latitude = jobsite.latitude
        } else if (jobsite?.position?.lat && jobsite?.position?.lng) {
          longitude = jobsite.position.lng
          latitude = jobsite.position.lat
        }

        if (longitude && latitude) {
        // console.log('Got activity.coords:::', activity.location)
          markerPosition = {
            lat: Number(latitude),
            lng: Number(longitude),
            // heading: Number(activity.location.coords.heading)
          }
          isValid = true
          // activity.location = activity.location.coords
        } else {
        // console.log('No fucking lat/lng...227', jobsite)
        }
        // console.log(markerPosition)
          let color = '#214'
          if (jobsite.color) color = jobsite.color
          if (activeMarker._id === jobsite._id) {
            color = '#832'
            // console.log('Change that color!!', color)
          }
          // color = setMarkerColorBasedOnCrew(jobsite.crewId, crews)
          // console.log('COLOR by Crew: ', color)
          // console.log(activeMarker)
          if (isValid) {
            ourMarkers.push(
              <CustomMarker
                onLoad={onLoad}
                key={index}
                position={markerPosition}
                icon={icon}
                color={color}
                jobsite={jobsite}
                activeMarker={activeMarker}
                updateActiveMarker={updateActiveMarker}
              />
            )
          } else {
            console.log('Invalid Marker...', jobsite)
            invalidMarkerSites.push(jobsite)
            // setInvalidMarkers(prevMarkers => [...prevMarkers, jobsite]);
          }
      })
      console.log('Invalid Markers', invalidMarkerSites)
      // setInvalidMarkers(invalidMarkerSites)
      return ourMarkers
    } else {
      return null
      //  (
      //   // <CustomMarker
      //   //   onLoad={onLoad}
      //   //   position={position}
      //   //   icon={icon}
      //   // />
      // )
    }
   
  }

  const updateTheMarkersOnTheMap = (data) => {
    console.log('Update the visible markers to only include the crews:', data)
    // console.log('PROPS', props)
    // console.log('Markers', markers)
    console.log('Crews???', crews)
    // console.log('Jobsites', jobSites)
    let markersToInclude = jobSites.filter(site => {
      // console.log('Crew @ site: %s  ---- ', site?.crew, site?.name, site?.address)
      return (data.includes(site.crew))
    })
    // let crewsTo
    // setFilteredCrews(data)
    setFilteredCrews(data)
    // let crewsToInclude = props.crews.filter(crew => (data.includes(crew._id)))
    // console.log('Use theese markers:', markersToInclude)
    filterOurMarkers(markersToInclude, filteredDays, weekChosen, frequencyChosen, data)
  }

  const updateMarkersByDay = (data) => {
    console.log('Update Markers by day', data, weekChosen)
    if (data && data.length) {
      filterOurMarkers(jobSites, data, weekChosen, frequencyChosen, filteredCrews)
      setFilteredDays(data)
    }
  }

  const updateMarkersByWeek = (data) => {
    console.log('Update By Week', data)
    filterOurMarkers(jobSites, filteredDays, data, frequencyChosen, filteredCrews)
    setWeekChosen(data)
  }

  const updateMarkersByFrequency = (data) => {
    console.log('Update By Frequency', data)
    filterOurMarkers(jobSites, filteredDays, weekChosen, data, filteredCrews)
    setFrequencyChosen(data)
  }

  function onOverlayComplete(event) {
    // console.log('Overlay complete:', event)
    let newMarkers = []
    console.log('Type:', event.type)
    if (event.type === "circle") {
      const circle = event.overlay;
      
      markers.forEach(marker => {
          const isInside = window.google.maps.geometry.spherical.computeDistanceBetween(
              marker.position, 
              circle.getCenter()
          ) <= circle.getRadius();
          
          if (isInside) {
              // Marker is inside the circle
              // console.log('This one is inside ', marker)
              newMarkers.push(marker)
          }
      });
  } else if (event.type === "polygon") {
    const polygon = event.overlay;

    markers.forEach(marker => {
        const isInside = window.google.maps.geometry.poly.containsLocation(
            marker.position,
            polygon
        );
        
        if (isInside) {
            // Marker is inside the polygon
            console.log('This one is inside the polygon', marker);
            newMarkers.push(marker)
        }
    });
  }
  console.log('FInished coming up w/ updated markers', newMarkers)
  setSelectedMarkers(newMarkers)
}

const onLoadPolygon = (polygon, index) => {
  polygonRefs.current[index] = polygon;
}

const onClickPolygon = (index) => {
  console.log('on polygon click')
  activePolygonIndex.current = index; 
}

const handlePolygonClick = (index) => {
  console.log('Click Polygon')
  const shouldDelete = window.confirm("Do you want to delete this polygon?");
    if (shouldDelete) {
      // Create a new array without the clicked polygon
      const newPolygons = polygons.filter((_, i) => i !== index);
      setPolygons(newPolygons);
    }
}


const onEditPolygon = (index) => {
  console.log("On Edit polygon")
  const polygonRef = polygonRefs.current[index];
  if (polygonRef) {
      const coordinates = polygonRef.getPath()
          .getArray()
          .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

      const allPolygons = [...polygons];
      allPolygons[index] = coordinates;
      setPolygons(allPolygons)
  }
}
const clearAllPolygons= () => {
  setPolygons([])
}

const assignACrewToAJobSite = async (crewToAssign, currentCrews) => {
  console.log('Assign this crew:', crewToAssign)
  let crewIndex = currentCrews.findIndex(item => (crewToAssign._id === item._id))
  console.log('Crew Index', crewIndex)
  console.log(currentCrews)
  console.log('this guy', activeMarker)
  activeMarker.assignedCrew = crewToAssign
  let existingMarkers = [...markers]
  let color = '#fff'
  existingMarkers.map((item, index) => {
    if (item._id === activeMarker._id) {
      console.log('This is the one!', item)
      console.log(index)
      item.assignedCrew = crewToAssign
      item.color = distinctHexColors[crewIndex]
      color = distinctHexColors[crewIndex]
    }
  })
  console.log('Now filter these bitches', existingMarkers)
  filterOurMarkers(existingMarkers, currentCrews)
  const data = {
    crewId: crewToAssign._id,
    jobSiteId: activeMarker._id,
    color: color
  }
  const response = await axios.post(`${props.url}/api/hmi/assignCrewToJobsite`, data)
  if (response && response.data) {
    console.log('Saved ', response.data)
  } else {
    console.log('Not saved...')
  }
  // filterOurMarkers(prevMarkers => [...prevMarkers, activeMarker]);
}
const handleUnassign = async (marker) => {
  console.log('Remove the crew from this marker:', marker)
  activeMarker.assignedCrew = null
  let existingMarkers = [...markers]
  let color = '#214'
  existingMarkers.map((item, index) => {
    if (item._id === activeMarker._id) {
      console.log('This is the one!', item)
      console.log(index)
      item.assignedCrew = null
      item.color = color
    }
  })
  console.log('Now filter these bitches', existingMarkers)
  filterOurMarkers(existingMarkers, crews)
  const data = {
    crewId: null,
    jobSiteId: activeMarker._id
  }
  const response = await axios.post(`${props.url}/api/hmi/assignCrewToJobsite`, data)
  if (response && response.data) {
    console.log('Saved ', response.data)
  } else {
    console.log('Not saved...')
  }
}
// console.log('Markers 786::', markers)
// console.log('Polygons:', polygons)
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GMAPKEY}
      libraries={mapLibraries}
    >
     HMI Maintenance Map View
      <Grid container>
      <Grid item lg={12} xs={12} md={12} sm={12}>
        
        {markers.length}
      </Grid>
        <Grid item lg={9} xs={9} md={9} sm={9}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={9}
          >
              <DrawingManager 
                  defaultDrawingMode={'POLYGON'}
                  onOverlayComplete={onOverlayComplete}
                  defaultOptions={{
                    drawingControl: true,
                    drawingControlOptions: {
                      position: 'TOP_CENTER',
                      drawingModes: [
                        'POLYGON'
                      ]
                    },
                    polygonOptions: {
                      fillColor: '#accacd',
                      fillOpacity: 1,
                      strokeWeight: 5,
                      clickable: true,
                      editable: true,
                      draggable: true,
                      zIndex: 1
                    }
                  }}
              
              />
               {/* {
                        polygons.map((iterator, index) => (
                            <Polygon
                                key={index}
                                onLoad={(event) => onLoadPolygon(event, index)}
                                onMouseDown={() => onClickPolygon(index)}
                                onMouseUp={() => onEditPolygon(index)}
                                onDragEnd={() => onEditPolygon(index)}
                                onClick={() => handlePolygonClick(index)}
                                options={polygonOptions}
                                paths={iterator}
                                draggable
                                editable
                            />
                        ))
                    } */}

            { /* Child components, such as markers, info windows, etc. */ }
            <>
            { (markers && markers.length) ? <OurMarkers markers={markers} setInvalidMarkers={setInvalidMarkers} /> : null }
            </>
          
          </GoogleMap>
        </Grid>
        <Grid item lg={3} xs={3} md={3} sm={3}>    
          <Sidebar
            url={props.url}
            markers={markers}
            invalidMarkers={invalidMarkers}
            selectedMarkers={selectedMarkers}
            clearPolygons={clearAllPolygons} 
            setActiveMarker={setActiveMarker} 
            activeMarker={activeMarker}
            jobSites={jobSites}
            crews={crews}
            filteredDays={filteredDays}
            handleAssignCrew={assignACrewToAJobSite}
            handleUpdateVisibleCrews={updateTheMarkersOnTheMap} 
            handleUpdateVisibleDays={updateMarkersByDay} 
            handleUpdateVisibleByWeek={updateMarkersByWeek} 
            handleUpdateVisibilityByFrequency={updateMarkersByFrequency}
            legendData={legendData}
            handleUnassign={handleUnassign}
           />
        </Grid>
      </Grid>

    </LoadScript>
  )
}


const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'No Preference'
]

const weekOptions = [
  'All',
  'Odd',
  'Even'
]

const FilterArea = (props) => {
  const [date, setDate] = React.useState(new Date())
  const [selected, setSelected] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [weekChosen, setWeekChosen] = React.useState('All')
  const [frequency, setFrequency] = React.useState('All')

  console.log('Filter Props', props)
  React.useEffect(() => {
    props.updateCrews(selected)
    console.log('Selected Changed...884', selected)
  }, [selected])


  React.useEffect(() => {
    console.log('Selected Days changed')
    props.updateDays(selectedDays)
  }, [selectedDays])

  React.useEffect(() => {
    console.log('Week chosen changed')
    props.updateWeeks(weekChosen)
  }, [weekChosen])

  React.useEffect(() => {
    console.log('Frequency')
    props.updateFrequency(frequency)
  }, [frequency])

  const handleSelect = (id) => {

      if (selected.includes(id)) {
          setSelected((prev) => prev.filter((i) => i !== id));
      } else {
          setSelected((prev) => [...prev, id]);
      }
  };

  const handleSelectAll = () => {
    console.log('Select All', selected)
    if (selected.length) {
      if (selected.length === props.crews.length) {
          setSelected([]);
      } else {
          setSelected(props.crews.map((crew) => crew._id));
      }
    } else {
      setSelected(props.crews.map((crew) => crew._id));
    }
  };

  const handleSelectDays = (id) => {
    if (selectedDays.includes(id)) {
      setSelectedDays((prev) => prev.filter((i) => i !== id));
    } else {
      setSelectedDays((prev) => [...prev, id]);
    }
};

const handleSelectAllDays = () => {
    if (selectedDays.length === days.length) {
      setSelectedDays([]);
    } else {
      setSelectedDays(days.map((day) => day));
    }
};

const handleSelectFrequency = () => {
  console.log('Select Frequency')
};

  const handleClose = () => {
    // props.closeModal()
  }

  const handleUpdateDate = (e) => {
    setDate(e.target.value)
  }
  // const uniqueCrewIds = [...new Set(props.crews.map(crew => crew.crew))]
  // const uniqueCrews = []
  // console.log('Unique Crew Ids', uniqueCrewIds)
  // uniqueCrewIds.forEach((crewId, uniqueCrewIndex) => {
  //   // console.log('Find CrewId', crewId)
  //   let crewIndex = [...props.crews].findIndex(site => (site.crew === crewId))
  //   console.log(crewIndex)
  //   if (crewIndex > -1) {
  //     // console.log('CREW: 953', props.crews[crewIndex])
  //     const site = props.crews[crewIndex]
  //     let hexColor = distinctHexColors[uniqueCrewIndex]
  //     console.log('Site 976', site)
  //     const crewObj = {
  //       _id: site.crew,
  //       color: hexColor,
  //       name: site?.crewName ? `${site.crewName}` : 'Un-Named Crew'
  //     }
  //     uniqueCrews.push(crewObj)
  //   } else {
  //     console.log('No valid crew!!!', crewId)
  //   }
  // })

  console.log('Unique Crews', props.crews)

  return (
    <div style={{ paddingVertical: 20 }}>
      <DayOptions selected={selectedDays} handleSelectAll={handleSelectAllDays} handleSelect={handleSelectDays} />
      <WeekChooser weekChosen={weekChosen} changeWeek={setWeekChosen} />
      <FrequencyChooser frequencyOptions={props.filteredFrequencies} frequencyChosen={frequency} changeFrequency={setFrequency} />
      <CrewOptions crews={props.crews} selected={selected} handleSelectAll={handleSelectAll} handleSelect={handleSelect} />
    </div>
  )
}

const FrequencyChooser = (props) => {
  console.log('FrequencyChooser Chooser ', props)
  
    const handelChangeFrequency = (e) => {
      props.changeFrequency(e.target.value)
    }

  
    return (
      <Paper style={{ marginTop: 20, padding: 10, paddingBottom: 15 }} >
        <div style={{ fontSize: '18px', color: '#aaa' }}>Choose Which Type of Frequency Should Be Included:</div>
         <FormControl fullWidth>
          <TextField
            value={props.frequencyChosen}
            onChange={handelChangeFrequency}
            inputProps={{ 'aria-label': 'Day of the Week' }}
            fullWidth
            select
            style={{ margin: 5, padding: 10}}
            placeholder="Choose Which Day"
          >
            {
              props?.frequencyOptions.map((item, index) => {
                return (
                  <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
                )
              })
            }
          </TextField>
          <FormHelperText>Weekly/Bi-Weekly/All</FormHelperText>
        </FormControl>
      </Paper>
    )
  }

  const WeekChooser = (props) => {
    console.log('Week Chooser ', props)
    
      const handleChangeWeek = (e) => {
        props.changeWeek(e.target.value)
      }
      let weekOfTheYear = moment(new Date()).week()
      let evenWeek = isEven(weekOfTheYear)
      let thisWeek = evenWeek ? 'Even' : 'Odd'
      return (
        <Paper style={{ marginTop: 20, padding: 10, paddingBottom: 15 }} >
          <div style={{ fontSize: '18px', color: '#aaa' }}>Choose Which Weeks Should Be Included:</div>
           <FormControl fullWidth>
            <TextField
              value={props.weekChosen}
              onChange={handleChangeWeek}
              inputProps={{ 'aria-label': 'Day of the Week' }}
              fullWidth
              select
              style={{ margin: 5, padding: 10}}
              placeholder="Choose Which Day"
            >
               <MenuItem value={"All"}>
                All
              </MenuItem>
              <MenuItem value={"Odd"}>
                Odd (1, 3, 5, 7...)
              </MenuItem>
              <MenuItem value={"Even"}>Even (2, 4, 6, 8...)</MenuItem>
            </TextField>
            <FormHelperText>  This Week is an {thisWeek} Week</FormHelperText>
          </FormControl>
        </Paper>
      )
    }


const DayOptions = ({ selected, handleSelectAll, handleSelect }) => {

return (
  <Paper style={{ overflowY: 'scroll', marginTop: 20 }}>
     <div style={{ fontSize: '18px', color: '#aaa' }}>Choose Which Days Should Be Included:</div>
      <Table size="small">
          <TableHead>
              <TableRow>
                  <TableCell padding="checkbox">
                      <Checkbox
                          checked={selected.length === days.length}
                          onChange={handleSelectAll}
                      />
                  </TableCell>
                  <TableCell>Day (SELECT ALL)</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
              {days.map((day, index) => {
                const checked = selected.includes(day) ? true : false
             
                return (
                  <TableRow key={index}
                    
                  >
                      <TableCell padding="checkbox">
                          <Checkbox
                              checked={checked}
                              onChange={() => handleSelect(day)}
                          />
                      </TableCell>
                     
                      <TableCell >{day}</TableCell>
                  </TableRow>
              )})}
          </TableBody>
      </Table>
  </Paper>
)
}

const CrewOptions = ({ selected, crews, handleSelectAll, handleSelect }) => {
  console.log('Crwe optionsHMI', crews)
  return (
    <Paper style={{ overflowY: 'scroll', marginTop: 20 }}>
       <div style={{ fontSize: '18px', color: '#aaa' }}>Choose Which Crews Should Be Included:</div>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            checked={selected.length === crews.length}
                            onChange={handleSelectAll}
                        />
                    </TableCell>
                    <TableCell>Crew Name (SELECT ALL)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {crews.map((crew, index) => {
                  const checked = selected.includes(crew._id) ? true : false
                  let bgColor = crew.color // checked ? crew.color : 'inherit'
                  let fontColor = '#000'; // checked ? '#ccc' : 'inherit'
                  return (
                    <TableRow key={index}
                      
                    >
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={checked}
                                onChange={() => handleSelect(crew._id)}
                            />
                        </TableCell>
                       
                        <TableCell style={{ backgroundColor: bgColor, color: fontColor }} >{crew.name}</TableCell>
                    </TableRow>
                )})}
            </TableBody>
        </Table>
    </Paper>
  )
}

// export default React.memo(MyComponent)
const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    crews: state.crews,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(React.memo(HMIMapView))
