import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Grid,
  Paper,
  Typography,
  Checkbox,
  Divider,
  Card,
  CardContent
} from '@material-ui/core';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  board: {
    minHeight: '70vh',
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2),
  },
  item: {
    padding: theme.spacing(2),
    cursor: 'move',
    marginBottom: theme.spacing(2),
  },
  selectedItem: {
    backgroundColor: theme.palette.grey[200],
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const initialTasks = [
  { id: 1, title: 'Task 1', selected: false, status: 'todo' },
  { id: 2, title: 'Task 2', selected: false, status: 'todo' },
  { id: 3, title: 'Task 3', selected: false, status: 'todo' },
  { id: 4, title: 'Task 4', selected: false, status: 'todo' },
];

const itemType = 'task';

function MultiDragKanban() {
  const [tasks, setTasks] = useState(initialTasks);
  const [selectedTasks, setSelectedTasks] = useState([])
  const classes = useStyles();

  const moveTask = (taskId, targetStatus) => {
    const newTasks = tasks.map((task) => {
      if (selectedTasks.includes(task.id)) {
        return { ...task, status: targetStatus };
      }
      return task;
    });
    setTasks(newTasks);
    setSelectedTasks([]); // Clear the selection after moving tasks
  };
  const toggleTaskSelection = (index) => {
  // console.log('Toggle task', index)
    setTasks((prevTasks) =>
      prevTasks.map((task, idx) =>
        idx === index ? { ...task, selected: !task.selected } : task,
      ),
    );
  };

  const Task = ({ task }) => {
    const [{ isDragging }, drag] = useDrag({
      item: { id: task.id },
      type: itemType,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
  
    const handleCheckboxChange = (event) => {
      if (event.target.checked) {
        setSelectedTasks((prevSelectedTaskIds) => [
          ...prevSelectedTaskIds,
          task.id,
        ]);
      } else {
        setSelectedTasks((prevSelectedTaskIds) =>
          prevSelectedTaskIds.filter((id) => id !== task.id),
        );
      }
    };
  
    return (
      <Grid item xs>
        <Card className={classes.card} ref={drag} style={{ opacity: isDragging ? 0.5 : 1 }}>
          <CardContent>
            <Typography>
              <Checkbox
                checked={selectedTasks.includes(task.id)}
                onChange={handleCheckboxChange}
              />
              {task.title}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  
  

  const DropZone = ({ status, children }) => {
    const [, drop] = useDrop({
      accept: itemType,
      drop(item) {
        moveTask(item.id, status);
      },
    });

    return (
      <Grid item xs={12} md={6} ref={drop} className={classes.board}>
        {children}
      </Grid>
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.title}>
              Multi-drag Kanban Board
            </Typography>
            <Divider />
          </Grid>
          <DropZone status="todo">
            <Typography variant="h5" className={classes.title}>
              To Do
            </Typography>
            {tasks
              .filter((task) => task.status === 'todo')
              .map((task, index) => (
                <Task key={task.id} task={task} index={index} setSelectedTasks={setSelectedTasks} />
            ))}

          </DropZone>
          <DropZone status="done">
            <Typography variant="h5" className={classes.title}>
              Done
            </Typography>
            {tasks
              .filter((task) => task.status === 'done')
              .map((task, index) => (
                <Task key={task.id} task={task} index={index} setSelectedTasks={setSelectedTasks} />
            ))}

          </DropZone>
        </Grid>
      </div>
    </DndProvider>
  );
}

export default MultiDragKanban;

