import React, { Component } from 'react'
import styles from './home.module.scss'
import { Card, CardContent, Typography } from '@material-ui/core'
import RegistrationFinish  from '../RegistrationFinish/RegistrationFinish'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'
import SearchEngine from './SearchEngine'
import ProtectedButton from '../../actions/protectedButtons'
import { setUrl, setAuth, setUser, setNotifications, setPaypal, setGPS, setPerms, setServices, setSalesReps, setClients, setEmployees, setTasks, setUsers, setCrews, setSnoutWeevilButtonActive, setFertilizationButtonActive } from '../../actions/connectionActions'
import CrewsSupervisedList from './CrewsSupervisedList.js'

const CrewsSupervisedComponent = (props) => {
  // console.log('CrewsSupervisedComponent', props)
  const { user, employee, crews } = props

  return (
     <Card className={ styles.card }>
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          Crews Supervised
        </Typography>
           <CrewsSupervisedList crews={crews || []} />
          
      </CardContent>
    </Card>
    )

}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    user: state.user,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(CrewsSupervisedComponent)
