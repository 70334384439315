import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const ProjectJobList = (props) => {
// console.log('Project Job List: ', props)
  return (
    <>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            Job Name
          </TableCell>
          <TableCell>
            Workorder Number
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { props.workorders.map((wo, index) => {

          return (
            <TableRow key={wo._id}>
              <TableCell>{wo.jobName}</TableCell>
              <TableCell>{wo.number}</TableCell>
            </TableRow>
          )
          })}
      </TableBody>
    </Table>

    </>
  )
}

const mapStateToProps = (state) => {
  // console.log('JOB LIST MAP STATE TO PROPS: ', state)
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default React.memo(connect(mapStateToProps)(ProjectJobList))