import React from 'react';
import Grid from '@material-ui/core/Grid'
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

const LineItemDetails = (props) => {
  let lineItems = props.lineItems
// console.log(lineItems)
  if (lineItems && lineItems.length) {
    return (
      <div style={{marginLeft: 10, overflowY: 'scroll', overflowX: 'hidden', maxHeight: 400 }}>
        {
      lineItems.map((item, index) => {
        return (
          <Grid container key={index} spacing={2}>
            
            <Grid item lg={3} xs={6}>
            {/* <span style={{verticalAlign: 'bottom', fontSize: '16px'}}>{`#${index + 1}      `}</span> */}
              <TextField
                label={`Name`}
                fullWidth
                value={item.name}
                id={`name${index}`}
                onChange={e=>props.changeItem(item, 'name', e.target.value, index)}
              />
              </Grid>
              <Grid item lg={3} xs={6}>
              <TextField
                label="Description"
                fullWidth
                value={item.desc}
                id={`qty${index}`}
                onChange={e=>props.changeItem(item, 'desc', e.target.value, index)}
              />
            </Grid>
              <Grid item lg={2} xs={4}>
              <TextField
                label="Quantity"
                value={item.qty}
                fullWidth
                id={`qty${index}`}
                onChange={e=>props.changeItem(item, 'qty', e.target.value, index)}
              />
            </Grid>
            <Grid item lg={2} xs={4}>
              <TextField
                label="Cost Per"
                value={item.costPer}
                fullWidth
                id={`costPer${index}`}
                onChange={e=>props.changeItem(item, 'costPer', e.target.value, index)}
              />
            </Grid>
            <Grid item lg={2} xs={4}>
              <TextField
                label="Cost"
                value={item.cost}
                fullWidth
                id={`cost${index}`}
                disabled
                // onChange={e=>props.changeItem(item, 'cost', e.target.value, index)}
              />
            </Grid>
          </Grid>
        )
      })}
      </div>
    )
  } else {
    return null
  }
}

const defaultLineItem = {
  name: 'New Item',
  description: '',
  qty: '',
  costPer: '',
  assigned: false,
  assignedTo: null,
  totalCost: ''
}
const defaultState = {
  needsUpdate: false,
  lineItems: []
}
const InvoiceLineItems = (props) => {
  const [lineItems, setLineItems] = React.useState([])
  const [state, setState] = React.useState(defaultState)

  const handleAddLineItem = () => {
    let items = lineItems
  // console.log(items)
    items.push({
      name: ``,
      description: '',
      qty: '',
      costPer: '',
      assigned: false,
      assignedTo: null,
      totalCost: '',
      cost: ''
    })
  // console.log(items)
    // setLineItems(items)
    setState({...state, lineItems: items, needsUpdate: true })
  }

  const changeItem = (item, type, val, index) => {
    // console.log('Change this item: ', item)
    // console.log(type)
    // console.log(index)
    // console.log(val)
    let items = lineItems
    let theItem = items[index]
    // console.log(theItem)
    theItem[type] = val
    if (theItem.costPer && theItem.qty) theItem.cost = parseFloat(parseFloat(theItem.costPer) * parseInt(theItem.qty)).toFixed(2)
    // console.log(theItem)
    // console.log(items)
    // items[0]['name'] = 'FUCK'
    // setLineItems(items)
    setState({...state, needsUpdate: true, lineItems: items })
  }
  return (
    <Grid container spacing={2}>
      <Grid item lg={6} xs={6}>
      <h2>Invoice Line Items ({`${state.lineItems.length}`})</h2>
        </Grid>
        <Grid item lg={6} xs={6}>
        <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleAddLineItem}
        startIcon={<NoteAddIcon />}
        >Add Line Item</Button>
        </Grid>
        <Grid item lg={12} xs={12}>
        <LineItemDetails style={{marginLeft: 20, overflowY: 'scroll', maxHeight: 600 }} lineItems={state.lineItems} changeItem={changeItem} />
        </Grid>
      
    </Grid>
  )
}

export default InvoiceLineItems
