import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Radio from '@material-ui/core/Radio';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import DialogContentText from '@material-ui/core/DialogContentText';
import styled from 'styled-components'
import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
// import BuildIcon from '@material-ui/icons/Build';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteComponent from '../Common/DeleteComponent'
import { handleFocus } from '../Common/index';

// console.log('Test Currency format', currencyFormat(4))
const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '10px',
    // margin: '5px'
  }
}));

const LaborRates = (props) => {
  const classes = useStyles()
  const rates = { ...props.laborRates }
  const updateRates = (e) => {
    const newState = {
      [e.target.id]: e.target.value
    }
    // console.log('update this', newState)
    // setState({...state, ...newState})
    props.updateParent(newState)
  }

  return (
    <React.Fragment>
         <Grid item lg={4} md={4} sm={4} xs={12} className={classes.gridItem}>
              <TextField
                id="laborRate"
                label="Labor Rate"
                fullWidth
                value={ rates.laborRate } // "2017-05-24"}
                className={classes.textField}
                variant="standard"
                onFocus={handleFocus}
                onChange={updateRates}
                // onChange={ e => setState({...state, laborRate: e.target.value }) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={'What is the Standard Labor Rate?'}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} className={classes.gridItem}>
              <TextField
                id="installRate"
                label="Install Labor Rate"
                fullWidth
                value={ rates.installRate } // "2017-05-24"}
                className={classes.textField}
                variant="standard"
                onFocus={handleFocus}
                onChange={updateRates}
                // onChange={ e => setState({...state, laborRate: e.target.value }) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={'What is the Install Labor Rate?'}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} className={classes.gridItem}>
              <TextField
                id="supervisorRate"
                label="Supervisor Rate"
                fullWidth
                value={ rates.supervisorRate } // "2017-05-24"}
                className={classes.textField}
                variant="standard"
                onFocus={handleFocus}
                onChange={updateRates}
                // onChange={ e => setState({...state, supervisorRate: e.target.value }) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={'What is the Supervisor Rate?'}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} className={classes.gridItem}>
              <TextField
                id="sprayTechRate"
                label="Spray Tech Rate"
                fullWidth
                onFocus={handleFocus}
                value={ rates.sprayTechRate } // "2017-05-24"}
                className={classes.textField}
                variant="standard"
                onChange={updateRates}
                // onChange={ e => setState({...state, sprayTechRate: e.target.value }) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={'What is the Spray Tech Rate?'}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} className={classes.gridItem}>
              <TextField
                id="arboristRate"
                label="Arborist Rate"
                fullWidth
                onFocus={handleFocus}
                value={ rates.arboristRate } // "2017-05-24"}
                className={classes.textField}
                variant="standard"
                onChange={updateRates}
                // onChange={ e => setState({...state, arboristRate: e.target.value }) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={'What is the Arborist Rate?'}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} className={classes.gridItem}>
              <TextField
                id="treeWorkerRate"
                label="Tree Worker Rate"
                fullWidth
                onFocus={handleFocus}
                value={ rates.treeWorkerRate } // "2017-05-24"}
                className={classes.textField}
                variant="standard"
                onChange={updateRates}
                // onChange={ e => setState({...state, treeWorkerRate: e.target.value }) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={'What is the Tree Worker Rate?'}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={12} className={classes.gridItem}>
              <TextField
                id="operatorRate"
                label="Operator Rate"
                fullWidth
                onFocus={handleFocus}
                value={ rates.operatorRate } // "2017-05-24"}
                className={classes.textField}
                variant="standard"
                onChange={updateRates}
                // onChange={ e => setState({...state, operatorRate: e.target.value }) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={'What is the Operator Rate?'}
              />
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={12} className={classes.gridItem}>
              <TextField
                id="irrigationTechRate"
                label="Irrigation Tech Rate"
                fullWidth
                onFocus={handleFocus}
                value={ rates.irrigationTechRate } // "2017-05-24"}
                className={classes.textField}
                variant="standard"
                // onChange={ e => setState({...state, irrigationTechRate: e.target.value }) }
                onChange={updateRates}
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={'What is the Irrigation Tech Rate?'}
              />
              </Grid>

    </React.Fragment>
  )
}

export default LaborRates