import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import SendToCustomerModal from './SendToCustomerModal'
import { fade, makeStyles } from '@material-ui/core/styles'

const SendToCustomerComponent = (props) => {
// console.log('Send To Customer Component:', props)
  return (
    <>
      <SendToCustomerModal entity={props.entity} {...props} />
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(SendToCustomerComponent)

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '5px',
    padding: 5
  },
  paper: {
    width: '100%',
    // height: '100%',
    // minHeight: '80vh',
    // padding: 10,
    marginBottom: '3rem',
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  topRowButton: {
    margin: 45,
    marginTop: 10,
    marginBottom: 10,
    width: 276
  },
  addNewButtonContainer: {
    // backgroundColor: 
  }
}))