import React from 'react'
import { connect } from 'react-redux'
import { Button, IconButton } from '@material-ui/core'
import NewMtcAgreementModal from './NewMtcAgreementModal'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import EditContractDetails from './EditContractDetails'
import Chip from '@material-ui/core/Chip'; 
import FileUploadComponent from '../../Uploads/UploadComponent'
import CloseIcon from '@material-ui/icons/Close';
import { socket } from '../../SocketIo/Socket'

const MtcDetails = (props) => {
  // console.log('MtcDetails: ', props)
  const [state, setState] = React.useState({
    counter: 0
  })
  const [showNewNoteModal, setShowNewNoteModal] = React.useState(false)
  const [mtcAgreement, setMtcAgreement] = React.useState([])
  const [activeContract, setActiveContract] = React.useState({})
  const [editModalOpen, setEditModalOpen] = React.useState(false)
  
  React.useEffect(() => {
    // console.log('Use Effect MTC Agreement Details')
    if (props.client && props.client._id) getMtcAgreements(props.client)
    socket.on(`${props.client._id}-hmiMtcList`, (msg) => {
      dealWithSocketData(msg)
    })
    return () => {
      socket.off(`${props?.client?._id}-hmiMtcList`)
    }
  }, [])

  const dealWithSocketData = (updatedAgs) => {
    console.log('HMI MTc Socket data', updatedAgs)
    if (updatedAgs && updatedAgs.length) setMtcAgreement(updatedAgs)
  }
  const getMtcAgreements = (client) => {
    // console.log('Get Mtc Agreemetnsf or client: ', client)
    axios({
      method: 'get',
      url:  `${props.url}/api/hmi/getMtcAgreementsPerCustomer?clientId=${client._id}`,
    }).then(response => {
      // console.log('REsponse from getting Mtc Agreemnts: ', response)
      if (response.data && response.data.length) {
        setMtcAgreement(response.data)
      } 
 
    })
  }

  const addNewMtcAgreement = () => {
    // console.log('Add a MTC Agreement')
    setShowNewNoteModal(true)
  }

  const newMtcAgreementResponse = (type, data) => {
    setActiveContract(null)
  // console.log('Response from new mtcagreement ', type)
    setShowNewNoteModal(false)
    if (type === 'Saved') {
      let agreements = mtcAgreement
      agreements.unshift(data)
      setMtcAgreement(agreements)
      setState({...state, counter: state.counter++})
      // Update Job List to Include this
      // props.refreshCustomer()
      // setShowNewNoteModal(false)
    }
    if (type === 'Updated') {
      let agreements = mtcAgreement
      // agreements.unshift(data)
      let index = agreements.findIndex(item => (item._id === data._id))
      if (index > -1) {
        agreements[index] = data
        setMtcAgreement(agreements)
        setState({...state, counter: state.counter++})
      }
      // Update Job List to Include this
      // props.refreshCustomer()
      // setShowNewNoteModal(false)
    }

  }

  const openDetailsModal = (row) => {
  // console.log('Open this: ', row)
    setActiveContract(row)
    // setEditModalOpen(true)
    setShowNewNoteModal(true)
  }
  const editMtcAgreementResponse = (d, ag) => {
    // console.log('Edited our mtc agreement!!!', d)
    if (d === 'Close') setEditModalOpen(false)
    if (d === 'Saved') {
      // console.log('Update our agreement to this one!', ag)
      let agreements = [...mtcAgreement]
      let index = agreements.findIndex(agrreement => (ag._id === agrreement._id));
      // console.log('Agreemnent index: ', index)
      // console.log('AGreement to update: ', agreements[index])
      if (index > -1) agreements[index] = ag
      // console.log('Updted agreements: ', agreements)
      setMtcAgreement(agreements)
      setEditModalOpen(false)
    }
  }

  const handleDeleteMtcAgreement = (itemToDelete) => {
  // console.log('Delete this bitch:', itemToDelete)
    setShowNewNoteModal(false)
    if (itemToDelete._id) {
      let agreements = [...mtcAgreement]
      let index = agreements.findIndex(agrreement => (itemToDelete._id === agrreement._id));
    // console.log('Agreemnent index: ', index)
    // console.log('AGreement to update: ', agreements[index])
      if (index > -1) {
        agreements.splice(index, 1)
      // console.log('updated agreements:', agreements)
        setMtcAgreement(agreements)
        

        // SelectionState()
      }
      setActiveContract({})
      setState({...state, counter: state.counter++ })
    }
  }

  const handleToggleModal = (newValue) => {
    setShowNewNoteModal(newValue)
  }

  const handleUpdateResponse = (filesResponse) => {
    if (filesResponse && filesResponse.filesUploaded && filesResponse.filesUploaded.length) {
      const d = filesResponse.filesUploaded[0]
      let agreements = [...mtcAgreement]
      let index = agreements.findIndex(agrreement => (d.hmiMtcAgreement === agrreement._id));
      if (index > -1) {
        let theAg = { ...agreements[index] }
        let currentUploads = theAg.uploads || []
        currentUploads.unshift(d)
        theAg.uploads = currentUploads
        agreements[index] = theAg
        setMtcAgreement(agreements)
        setState({...state, counter: state.counter++ })
      }
    }
  }

  const handleDeleteUpload = (uploadToDelete) => {
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/deleteUpload`,
      data: uploadToDelete
    }).then(response => {
      console.log(response.data)
      if (response.data && response.data._id) {
        let agreements = [...mtcAgreement]
        let index = agreements.findIndex(agrreement => (uploadToDelete.hmiMtcAgreement === agrreement._id));
        if (index > -1) {
          const ag = agreements[index]
          let currentUploads = [ ...ag.uploads ]
          let uploadIndex = currentUploads.findIndex(item => (item._id === response.data._id))
          if (uploadIndex > -1) {
            currentUploads.splice(uploadIndex, 1)
            ag.uploads = currentUploads
            agreements[index] = ag
            setMtcAgreement(agreements)
            setState({...state, counter: state.counter++ })
          }
        }
      }
    })
  }

  return (
    <div style={{minHeight: '80px'}}>
      {/* <EditContractDetails open={editModalOpen} crewList={props.crewList} contract={activeContract} client={props.client} jobSites={props.jobSites} updateParent={editMtcAgreementResponse} /> */}
      <div>
        {/* <Button
            variant="contained"
            color="secondary"
            disabled={props.jobSites.length < 1}
            style={{margin: '5px', position: 'absolute', right: '20px'}}
            // className={classes.button}
            // startIcon={<CreditCardIcon />}
            size="small"
            onClick={addNewMtcAgreement}
          >
           
            {props.jobSites.length < 1 ? <span> New Mtc Agreement<br/><span style={{fontSize: '8px'}}>Add a Job Site</span></span> : 'New Mtc Agreement'}
          </Button> */}
          
        </div>
      <NewMtcAgreementModal client={props.client} crewList={props.crewList} jobSites={props.jobSites} contract={activeContract} contacts={props.contacts} updateParent={newMtcAgreementResponse} open={showNewNoteModal} deleteItem={handleDeleteMtcAgreement} toggleModal={handleToggleModal} />
      {/* {JSON.stringify(mtcAgreement)} */}
      <Grid container>
        {mtcAgreement.map(row => (
          <div key={row._id} style={{padding: '10px', margin: '5px', marginBottom: '15px', border: '2px solid #ccc', borderRadius: 20, padding: 10, width: '90%'}}>
            <Grid item lg={12}>
              
              <Grid container>
              <Grid item lg={6} md={6} sm={12} sm={12}>
                 { row.isActive ? (
                   <Chip key={'active'} style={{background: `rgba(63,192,63, .7)` }} size="small" label="Active" />
                 ) : (
                   <Chip key={'deactive'} style={{background: `rgba(244,92,11, .6)` }} size="small" label="Inactive" />
                 ) }
                </Grid>
                <Grid item lg={6} md={6} sm={12} sm={12}>
                  <FileUploadComponent hmiMtcAgreement={row} clientId={props?.client?._id} updateParent={handleUpdateResponse} message={`Add Files For Next Maintenance Cycle Here`} />
                </Grid>
              <Grid item lg={12}>
                  Job Details:  <span style={{ cursor: 'pointer',
                    textDecoration: 'underline',
                    color: 'blue'}} onClick={() => openDetailsModal(row)}>{row.jobName ? row.jobName : 'Un-Named Mtc Agreement'}</span>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Contract Number: {row.contractNumber}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Expiration Date: {moment(row.expirationDate).format('MM/DD/YYYY')}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Job Number: {row.jobNumber}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  PO Number: {row.poNumber}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Contract Type: {row.customerType}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Billing Frequency: {row.billingFrequency}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  {
                    (row.combineDumpInvoices && row.customerType === 'ADOT') ? (
                      <span>Combine All Dump Receipts</span>
                    ) : null
                  }
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                 
                </Grid>
                {/* <Grid item lg={6} md={6} sm={12} xs={12}>
                  Monthly Payment: {row.paymentAmount}
                </Grid> */}
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Notes: {row.notes}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  Site Notes: {row.siteNotes}
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                {
                  (row.uploads && row.uploads.length) ? (
                    <div style={{ marginTop: 10 }}>
                     <h3>Uploads:</h3>
                    {
                      row.uploads.map(upload => {
                        return (
                          <div key={upload._id} style={{ margin: 5, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                            {upload.name}                                
                            </div>
                            <div> {moment(upload.created_at).format('MM/DD/YY hh:mma')}</div>
                            <div><CloseButton updateParent={handleDeleteUpload} upload={upload} /></div>
                          </div>
                        )
                      })
                    }
                    </div>
                  ) : null
                }
                </Grid>
               
              </Grid>
             
            </Grid>
          </div>
        ))}
        
      </Grid>
    </div>
  )
}

const CloseButton = (props) => {
  const handleClick = () => {
    if (window.confirm('Are you sure you wish to delete this upload? ' + props.upload.name)) {
      props.updateParent(props.upload)
    }
  }

  return (
    <IconButton onClick={handleClick} edge='end' alt="Close" aria-label="Close">
      <CloseIcon />
    </IconButton>
  )
}

const mapStateToProps = (state) => {
  // console.log('State 308', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    contacts: state.contacts
  }
}

export default React.memo(connect(mapStateToProps)(MtcDetails))