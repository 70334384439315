import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid'
import styled from 'styled-components'

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
`;

const EditOrderQuantity = (props) => {
  // console.log('Time to Edit: ', props)
  const [state, setState] = React.useState({
    item: {}
  })
  const [itemList, setItemList] = React.useState([])

  React.useEffect(() => {
    const fetchItems = async () => {
      const response = await axios.get(`${props.url}/api/inventory/list`)
      setItemList(response.data)
    }
    // setState({...state, qty: props.item.orderQuantity || 0 })
    fetchItems()
  }, [])

  const handleCloseModal = () => {
    setState({...state, item: {}})
    props.updateParent('closeModal')
  }

  const handleQtyChange = () => {
  // console.log('Handle qty change')
  }

  const getItemLabel = (item) => {
    if (item && item._id) {
      return `${item.name}    ---     ${item.sku}      --     (${item.notes})     -----       $${item.price}`
    }
    return 'Please Enter Your Search Terms'
  }

  const updateItem = (event, newItem) => {
    if (newItem && newItem._id) {
      // setIgnoreKeyPress(false)
      // console.log('Update our crew: ', state)
      setState({...state, item: newItem })
    } else {
      // setState({...state, crew: {name: ' '}})
    }
  }

  const handleSubmit = () => {
  // console.log('Submit', state.item)
    props.updateParent('submit', state.item)
  }

  const ItemDetails = ({ item }) => {
    if (item && item._id) {
      return (
        <>
        <ItemInfo>
          <Grid container>
            <Grid item lg={6} md={12} sm={12}>
            <label>Name:</label> {item.name} <br/>
            <label>SKU:</label>{item.sku} <br/>
            <label>Price:</label> {item.price}<br/>
            <label>Notes:</label> {item.notes}<br />
            </Grid>
            <Grid item lg={6} md={12} sm={12}>
              { (item && item.photos && item.photos.length) ? (
                <img width="250" src={item.pictureURL} style={{margin: '25px'}}></img>
              ) : ''}
              
            </Grid>
        </Grid>
        <Grid container style={{ margin: '20px' }}>
            <Grid item lg={126} md={12} sm={12}>
              <Button color="primary" variant="contained" fullWidth onClick={handleSubmit}>Add to Order</Button>
            </Grid>
        </Grid>
        </ItemInfo>
      </>
      )
    } else {
      return ''
    }
  }

return (
  <div>
    
      <Dialog
        // fullScreen={false}
        maxWidth='md'
        // width='lg'
        // fullScreen
        fullWidth
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Modify Quantity</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Search Below
          </DialogContentText>
          <div style={{ textAlign: 'center' }}>
            {/* <img src={props.item.photoURL} height="250px" /> */}
            </div>
          <br />
          <Autocomplete
            id="itemlist"
            options={itemList}
            getOptionLabel={(option) => getItemLabel(option)}
            // style={{ width: 200 }}
            onChange={updateItem}
            // onChange={ (e) => updateCrew(workorder, e.target.id, e.target.value, serviceIndex) }
            value = {state.item}
            // onChange={ e => setState({...state, crew: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} label="Search For Item" variant="outlined" />}
        />
          { ItemDetails({ item: state.item}) }
          </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          {/* <Button disabled={!state.qty} onClick={() => handleSubmit()} color="primary">
            Save
          </Button> */}
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(EditOrderQuantity)