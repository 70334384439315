import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import { Button, CircularProgress, TextField, Tooltip }  from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import PlusIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import FiberNewIcon from '@material-ui/icons/FiberNew';


// const useStyles = makeStyles(theme => ({
const styles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      margin: 10,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  topButtons: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center',
      margin: 5,
    },
    [theme.breakpoints.down('md')]: {
      margin: 5,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  helperText: {
    fontSize: '1.25em',
    color: '#bbb'
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    
  },
  list: {
    width: '100%',
    height: 600,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  containerArea: {
    // backgroundColor: 'red',
    // border: '3px solid blue'
  },
  categoryHeader: {
    fontSize: '1.35em',
    fontWeight: 800
  },
  pageHeader: {
    fontSize: '1.4em',
    fontWeight: 600,
    color: '#ccc'
  },
  h4: {
    textAlign: 'center',
    fontSize: '1.4em'
  }
  })
);

function not(a, b) {
  // console.log('NOt A:', a)
  // console.log('NOt B:', b)

  return a.filter((value) => {
    // let val = b.indexOf(value)
    // console.log(val)
    return (b.map(obj => obj._id).indexOf(value._id) === -1)
    // return (b.indexOf(value) === -1)
  })
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const CategoryList = (props) => {
  // console.log('CategorY LIst: ', props)
  const classes = styles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [list, setList] = React.useState([])

  React.useEffect(() => {

    setLeft(props.categories)
    if (props.estimate && props.estimate.scopes && props.estimate.scopes.length) {
    // console.log('We have some scopes', props.estimate.scopes)
      setChecked(union(checked, props.estimate.scopes))
      let leftovers = not(props.categories, props.estimate.scopes)

    } else {
      setChecked([])

    }


  }, [props])


  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value._id);
    // console.log('Currnet index: ', currentIndex)
    let index = checked.findIndex(item => (item._id === value._id))
    // console.log('Index: ', index)
    const newChecked = [...checked];

    if (index === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(index, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => { return checked.length };

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const customList = (title, items, hideCheckbox) => (
    <Card className={classes.containerArea}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          hideCheckbox ? null : (
            <Tooltip title="Select All" aria-label="select-all">
              <Checkbox
                onClick={handleToggleAll(items)}
                checked={numberOfChecked(items) === items.length && items.length !== 0}
                indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                disabled={items.length === 0}
                inputProps={{ 'aria-label': 'all items selected' }}
              />
            </Tooltip>
          )

        }
        title={title}
        titleTypographyProps={{variant:'h5' }}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} component="div" role="list">
        {items.map((value) => {
          // console.log('LIST Items 222: ', value)
          const labelId = `transfer-list-all-item-${value}-label`;
          let isChecked = checked.indexOf(value) !== -1
          // console.log('Is checked 241:', isChecked)
          let inOurChecked = checked.findIndex(item => (item._id.toString() === value._id.toString()))
          
          // console.log('In our checked:', inOurChecked)
          if (inOurChecked > -1) isChecked = true
          return (
            <ListItem style={{ fontSize: '0.7em', margin: '-1.5em', paddingBottom: -5 }} key={value._id} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={isChecked}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText style={{ fontSize: '0.7em', margin: '-0.85em' }} id={labelId} primary={`${value.name }`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const handleSubmit = () => {
    props.submitOptions(checked)
  }
  
// console.log('CHecked', checked)
  return (
    <Grid
      container
      spacing={2}
      // maxHeight='90vh'
      // justifyContent="center"
      alignItems="center"
    >
       <Grid item lg={12} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={handleSubmit}
              aria-label="Submit"
              disabled={checked.length < 1}
            >
              Save
            </Button>
          </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.categoryHeader}>{customList('Category Options', left)}</Grid>
      <Grid item lg={2} md={2} sm={2} xs={2}>
       
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={handleSubmit}
              aria-label="Submit"
              disabled={checked.length < 1}
            >
              Save
            </Button>
          </Grid>
    </Grid>
  );
}

const ChooseScopes = (props) => {
console.log('Choose scopes -- estimate ID?? ', props)
  const classes = styles()
  const handleScopeChoices = (d) => {
    props.updateParent(d)
  }

  return (
    <Paper style={{  margin: 10, padding: 10, height: '100%', overflowY: 'auto' }}>
      <Grid container spacing={2}>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <h1>Choose Proposal Scopes</h1>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} className={classes.helperText}>
            
            Please choose which categories will be included on this proposal by checking the boxes below:      
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <CategoryList estimate={props.estimate} categories={props.categories} submitOptions={handleScopeChoices} />
          </Grid>
      </Grid>
    </Paper>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(ChooseScopes))