import React from 'react'
import ActivityListTable from './ActivitylistTable'

const ActivityDetailsContainer = (props) => {
  // console.log('Activity Details: PROPS:', props)
  return (
    <>
      <ActivityListTable {...props} />
    </>
  )
}

export default ActivityDetailsContainer
