import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  IconButton,
  Button,
  CircularProgress,
  isMuiElement,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText
}  from '@material-ui/core';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Tooltip from '@material-ui/core/Tooltip';
// import NewReminderComponent from '../Reminders/NewReminderComponent'
import MergeTypeIcon from '@material-ui/icons/MergeType'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    zIndex: 8,
    [theme.breakpoints.down('sm')]: {
      // marginLeft: theme.spacing(2),
      width: '100%',
      margin: 0
    }
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: 1
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '10px',
    margin: '5px'
  },
  uploadDropZone: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: '2px dashed #0658c2',
    borderRadius: 10,
    width: 300,
    height: 55,
    margin: 15,
    padding: 10,
    "&:hover": {
      backgroundColor: '#444',
      color: '#eee',
      border: '2px dashed rgb(183 224 152)',
    }
},
leadDesireContainer: {
  border: '2px solid #0658c2',
  borderRadius: 5,
  backgroundColor: '#eee',
  margin: 2,
  padding: 2,
  height: 200,
  overlow: 'hidden'
},
  leadDesireBox: {
    margin: 2,
    marginTop: -5,
    padding: 2,
    height: 150,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  topRowLeadDesires: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0
  },
  leadDesireDetails: {
    display: 'flex',
    
    // justifyContent: 'center'
  }
}));

const activityTypes = [
  'Phone Call',
  'Text Message',
  'Email',
  'On-Site',
  'In Office',
  'Request Pricing',
  'Send Estimate'
]

const activtyDrawerDefaultState = {
  notes: '',
  contact: {},
  activityType: 'Phone Call',
  reminder: null
}

const formatOptionForVendor = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.name) {
    return `${opt.name} (${opt.address})`
  } else {
    return ''
  }
}

const formatOptionForClient = (opt) => {
  if (opt && opt.searchName) {
    return `${opt.searchName} (${opt.agId})`
  } else if (opt && opt.displayName) {
    return `${opt.displayName} (${opt.agId})`
  } else {
    return null
  }
}
const MergeVendorsComponent = (props) => {
// console.log('MergeVendorsComponent', props)
  const classes = useStyles()
  const [vendors, setVendors] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)

  const handleOpenModal = () => {
    setShowModal(true)
  }

  const handleCloseModal = (hideIt) => {
    setShowModal(false)
    if (hideIt) {
    // console.log('Leave hti spage - this vendor is deleted now.')

    }
  }
  return (
    <React.Fragment>
      <Modal open={showModal} notification={props.notification} closeModal={handleCloseModal} vendors={props.vendors} vendor={props.vendor} url={props.url} />
      <InitialButton openModal={handleOpenModal} />
    </React.Fragment>
  )
}

const Modal = (props) => {
console.log('Merge Vendors Modal Props', props)
  const classes = useStyles()
  const [associatedVendor, setAssociatedVendor] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [responseData, setResponseData] = React.useState({})
  // const [vendorList, setVendorList] = React.useState([])

  // React.useEffect(() => {
  //   if (props.vendors && props.vendors.length) {
  //     let newVendors = props.vendors
  //     let index = newVendors.findIndex(item => (item._id === props.vendor._id))
  //   // console.log('228 index', index)
  //     if (index > -1) {
  //       newVendors.splice(index, 1)
  //       setOtherVendors(newVendors)
  //       setVendorList(newVendors)
  //     }
  //   }
  // }, [props.vendors])

  // React.useEffect(() => {
  //   setClient(props.client)
  // }, [props.client])

  // const fetchClientVendors = 
  const handleClose = () => {
    props.closeModal()
  }

  const handleSave = () => {
    // Save it tot he backend and do our shit
    console.log('Handle Save...')
    // setLoading(true)
    let data = {
      from: props.vendor._id,
      to: associatedVendor._id
    }


    console.log('Data to send', data)
    axios({
      method: 'post',
      url:  `${props.url}/api/vendors/merge`,
      data: data
    }).then(response => {
      setLoading(false)
      if (response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Vendor Merged',
          message: 'That Vendor Has Been Merged. Great Job!'
        })
        setResponseData(response.data)
        props.closeModal(true)
      }

    }).catch(err => {
      props.notification({
        type: 'error',
        title: 'Error!',
        message: 'We ran into an issue ' + JSON.stringify(err)
      })
    })
    props.closeModal()
  }

  const updateVendor = (e, newVendor) => {
    console.log('New Vendor:', newVendor)
    if (newVendor && newVendor._id) {
      setAssociatedVendor(newVendor)
    } else {
      setAssociatedVendor({})
    }
  }

  // const fetchVendors = async (vendorId) => {
  //   const resp = await axios.get(`${props.url}/api/vendors/clientVendors?id=${vendorId}`)
  //   // console.log('Vendors', resp.data)
  //   if (resp.data && resp.data.length) {
  //     setVendorList(resp.data)
  //   } else {
  //     setVendorList(otherVendors)
  //   }
  // }

  // const updateClient = (e, newClient) => {
  //   if (newClient && newClient._id) {
  //     setClient(newClient)
  //     fetchVendors(newClient._id)
  //   } else {
  //     setClient({})
  //   }
  // }

  const vendorList = props?.vendors.filter(item => (item._id !== props?.vendor?._id))
  return (
    <Dialog 
      open={props.open}
      maxWidth={'sm'}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>Merge Vendor</DialogTitle>
      <DialogContent>
        <div>You are working on {props.vendor.name} - by merging it, this vendor will be deleted and all related data will be moved to the vendor you select</div>
        <DialogContentText>Please Select the Vendor You'd Like to Push This Vendor Data Into</DialogContentText>
          <Autocomplete
            id="Associated Vendor"
            options={vendorList || []}
            getOptionLabel={(option) => (formatOptionForVendor(option) || '')}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            style={{marginBottom: 10, marginTop: 15}}
            onChange={updateVendor}
            fullWidth
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {associatedVendor || ''}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Vendor" />}
          />
          {
            (responseData && responseData.success) ? (
              <div>Success!!!</div>
            ) : null
          }

      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleClose}
          disabled={loading}
        >
          Close
        </Button>
        <Button
          color="primary"
          onClick={handleSave}
          disabled={loading}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
const InitialButton = (props) => {

  return (
    <Tooltip title="Merge Vendor">
      <IconButton onClick={props.openModal}>
        <MergeTypeIcon />
      </IconButton>
    </Tooltip>
  )
}
const mapStateToProps = (state) => {
  // console.log('MAP States...', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    // vendors: state.vendors
  }
}

export default connect(mapStateToProps)(MergeVendorsComponent)
