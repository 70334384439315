import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SignatureInput from './SignatureInput'

const ESignatureModal = (props) => {
// console.log('Esignature Modal: ', props)
  const [consentChecked, setConsentChecked] = React.useState(false)
  const [name, setName] = React.useState('')
  const handleCheck = () => {
    let checked = !consentChecked
    setConsentChecked(checked)
  }
  const handleClose = () => {
    props.handleClose()
  }

  const handleAgree = () => {
    props.submit(name)
  }

  const getName = (name) => {
    // console.log('Got our name: ', name)
    setName(name)
  }
  return (
    <Dialog
      open={props.open}
      maxWidth={'md'}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle style={{textAlign: 'center'}}>Electronic Signature</DialogTitle>
      <DialogContent style={{ overflowY: "scroll" }}>
        <DialogContentText>In order to proceed, you must consent to the use of electronic signature.</DialogContentText>
        <Checkbox value={consentChecked} onClick={handleCheck} /> By checking this box, I submit under penalty of perjury that I am {props.contact.firstName} {props.contact.lastName}, and I consent to use electronic records and signatures.
        {
          consentChecked ? (
            <SignatureInput contact={props.contact} setTheName={getName} />
          ) : null
        } 
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleAgree}
          color="primary"
          >I Agree</Button>
          <Button
            color="secondary"
            onClick={handleClose}
          >Cancel</Button>
      </DialogActions>
    </Dialog>
  )
}
export default ESignatureModal
