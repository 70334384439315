import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { CircularProgress, Grid, Button }  from '@material-ui/core';
import ImageList from './ImageListComponent'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const PhotoGallery = (props) => {
  
  const handleClose = () => {
    props.closeModal()
  }

  return (
    <Dialog
      maxWidth={'lg'}
      
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Workorder Photos</DialogTitle>
      <DialogContent>
        <ImageList photos={props.photos} />
      </DialogContent>
      
    </Dialog>
  )
}
export default PhotoGallery