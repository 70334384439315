import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

let theYear = new Date().getFullYear()

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  }
}));

// function htmlToText(html){
//   // console.log('Strip html tags: ', html)
//   //remove code brakes and tabs
//   html = html.replace(/\n/g, "");
//   html = html.replace(/\t/g, "");

//   //keep html brakes and tabs
//   html = html.replace(/<table>/g, "");
//   html = html.replace(/<tr>/g, "");
//   html = html.replace(/<td>/g, "");
//   html = html.replace(/<\/td>/g, "\t");
//   html = html.replace(/<\/table>/g, "\n");
//   html = html.replace(/<\/tr>/g, "\n");
//   html = html.replace(/<\/p>/g, "\n");
//   html = html.replace(/<\/div>/g, "\n");
//   html = html.replace(/<div>/g, "");
//   html = html.replace(/<\/h>/g, "\n");
//   html = html.replace(/<br>/g, "\n"); 
//   html = html.replace(/<br( )*\/>/g, "\n");
//   // console.log('Stripped: ', html)
//   //parse html into text
//   // var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
//   // return dom.body.textContent;
//   return html;
// }

const WinterFrostProtectionModal = (props) => {
  // console.log('WinterFrostProtectionModal Props: ', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [jobNumber, setJobNumber] = React.useState(null)
  const [crewList, setCrewList] = React.useState(props.crews || [])
  const [state, setState] = React.useState({
    jobName: '',
    billingAddress: {},
    jobSiteAddress: '',
    jobSite: null,
    salesRep: {},
    billingAddy: {},
    salesPeople: [],
    jobSites: [],
    cupsUsed: 0,
    squareFootage: 0,
    job: {},
    crew: null,
    date: new Date(),
    cost: 0
  })



  React.useEffect(() => {
    let isMounted = true
    if (props.client && props.client._id) fetchClientJobSiteAddresses()

    async function fetchClientJobSiteAddresses() {
      // console.log('Fetch our crews for snout weevil')
      setLoading(true)
      axios.get(`${props.url}/api/customer/getSnoutWeevilData?id=${props.client._id}`).then((response, status, data) => {
        // console.log(response)
        // console.log(status)
        // console.log(data)
        setLoading(false)
        if (response && response.data && isMounted) {
          // console.log('Got Crews for snout weevil!!', response.data )
          // setCrewList(response.data.crews)
          let theJobName = props.client.companyName ? props.client.companyName : props.client.lastName 
          theJobName += '- Winter Frost Protection (' + theYear + ')'
          // setState({...state, jobName: theJobName})
          setState({...state, jobName: theJobName,  jobSites: response.data.jobSites })
        }
      }).catch((error, status) => {
        // console.log('Error', error)
        // console.log(status)
      })
  
    }
    return () => {
      isMounted = false
    }
  }, [props.client])

  // React.useEffect(() => {
  //   // console.log('Use Effect New Snout Weevil')
  //   // console.log(props)
  //   setOpen(props.open)
  //   // if (props && props.client && props.client._id) {
  //   //   let theJobName = props.client.companyName ? props.client.companyName : props.client.lastName 
  //   //   theJobName += '- Snout Weevil (May 2021)'
  //   //   setState({...state, jobName: theJobName})
  //   // }
  // }, [props])

const handleClose = () => {
  // console.log('Closing New Snout Weevil Modal')
  // setOpen(false)
  // setState({})
  props.closeModal('Close')
}

const handleConfirm = (data) => {
  // console.log('CONFIRMED NEW JOB!!', data)
}
const handleSave = () => {
  setLoading(true)
  // console.log('handle save snout Weevil', state)
  // console.log(props)
  let obj = {
    client: props.client,
    data: state
  }
  // console.log(obj)
  axios({
    method: 'post',
    url:  `${props.url}/api/customer/createFrostProtectionJob`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to create new fertilization: ', response)
    setLoading(false)
    // setJobNumber(response.data)
    if (response.data && response.data.scheduledJob) {
      let job = response.data.scheduledJob
      // console.log(job)
      // if (job.StatusMessage) console.log('MES: ', job.StatusMessage)
      // props.jobDetailsResponse(response.data)
      props.notification({
        type: 'success',
        title: 'Success! - Job Saved',
        message:  'Fertilization Workorder ' + response.data.scheduledJob.number + ' was created and saved!'
      })
      props.closeModal('Saved')
      setState({
        jobName: '',
        billingAddress: {},
        jobSiteAddress: '',
        jobSite: null,
        salesRep: {},
        billingAddy: {},
        salesPeople: [],
        jobSites: [],
        cupsUsed: '',
        job: {},
        crew: null,
        date: new Date()
      })
      // setOpen(false)
    } else {
      // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up creating this workorder...not sure why!'
      })
    }
    
  }).catch(err => {
    // console.log('Error saving workorder: ', err)
  })
}

const updateBillingAddress = (e, addy) => {
  // console.log('Update Billing Address: ', e)
  // console.log(addy)
  if (addy && addy.address) {
    setState({...state, billingAddress: addy})
  }
}
const updateJobSiteAddress = (e, addy) => {
  // console.log('Update Job Site Address: ', e)
  // console.log(addy)
  if (addy && addy.address) {
    setState({...state, jobSite: addy._id, jobSiteAddress: addy})
  }
}
const updateSalesRep = (e, rep) => {
  // console.log('Update Sales rep ', e)
  // console.log(rep)
  if (rep && rep._id) {
    setState({...state, salesRep: rep})
  }
}

const updateCrew = (e, crew) => {
  // console.log('Update crew: ', crew)
  // console.log('Crew: ', crew)
  // let newService = state.service
  // newService.crew = crew
  if (crew && crew._id) {
    setState({...state, crew: crew})
  } else {
    setState({...state, crew: {}})
  }
}

return (
  <div className={classes.root}>
    <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create New Winter Frost Protection Work Order {props.client ? `(${props.client.displayName})` : null} </DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Provide The Basic Job Info Below
          </DialogContentText>
          <Autocomplete
            id="AssignedCrew"
            options={props.crews || []}
            getOptionLabel={(option) => option.name}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            onChange={updateCrew}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {state.crew}
            // onChange={ e => setState({...state, crew: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField className={classes.paddedStuff} {...params} label="Assigned Crew" />}
          />
           <TextField
            id="date"
            label="Scheduled Date"
            type="date"
            className={classes.paddedStuff}
            fullWidth
            value={moment(state.date).format('YYYY-MM-DD') } // "2017-05-24"}
            className={classes.textField}
            onChange={ e => setState({...state, date: e.target.value}) }
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            autoFocus
            className={classes.paddedStuff}
            // margin="dense"
            id="JobName"
            label="Job Name"
            type="text"
            onChange={(e) => setState({...state, jobName: e.target.value})}
            value={state.jobName}
            fullWidth
          />
          
          <Autocomplete
            id="JobSite"
            options={state.jobSites}
            getOptionLabel={(option) => typeof option === 'string' ? option : option.address}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            onChange={updateJobSiteAddress}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {state.jobSiteAddress}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} label="Job Site Address" />}
          />
           <TextField
            autoFocus
            className={classes.paddedStuff}
            margin="dense"
            id="NumberOfCups"
            label="Styrofoam Cups Used"
            type="text"
            onChange={(e) => setState({...state, cupsUsed: e.target.value})}
            value={state.cupsUsed}
            fullWidth

            onFocus={event => {
              event.target.select();
            }}
          />
           <TextField
            autoFocus
            className={classes.paddedStuff}
            margin="dense"
            id="clothUsed"
            label="Sq Ft of Cloth"
            type="text"
            onChange={(e) => setState({...state, squareFootage: e.target.value})}
            value={state.squareFootage}
            fullWidth

            onFocus={event => {
              event.target.select();
            }}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading || !state.crew || (!state.cupsUsed && !state.squareFootage) || !state.jobSite} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    // console.log('SNOUT WEEVIL STATE: ', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      crews: state.crews
    }
  }

export default React.memo(connect(mapStateToProps)(WinterFrostProtectionModal))