import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SignaturePad from 'react-signature-canvas';
import AddCreditCard from '../Customer/AddCreditCard';
import AddCustomerEcheck from './AddECheck';
import Paper from '@material-ui/core/Paper';

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  border: 4px solid #ddd;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: stretch;
  padding: 10px;
`;

const TabsChoice = styled.div`
  min-width: 150px;
  width: 25%;
  overflow: auto;
  height: 250px;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  height: 250px;
  align-self: stretch;
  overflow-y: scroll;
  background-color: #fff;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

const ContainerArea = styled.div`
  margin: 2em;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{width: '100%'}}>
          <Typography  component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center'
  },
  paper: {
    // width: '100%',
    marginTop: 10,
    marginBottom: theme.spacing(2),
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      textAlign: 'center',
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
      maxWidth: 960,
      textAlign: 'center'
    },
    // margin: 20
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  },
  signatureCanvas: {
    backgroundColor: 'red',
    width: '100%',
    minHeight: '300px'
  },
  buttonGroup: {
    marginBottom: '2em',
    marginTop: '2em'
  }
}));

const AddPaperCheckDetails = (props) => {
  const checkNumRef = React.useRef(null)
  const handlePaperCheck = () => {
    // console.log(checkNumRef.current)
    // console.log(checkNumRef.current.val)
    props.updateParent(checkNumRef.current.value)
  }
  return (
    <div>
      <LabelText>Paper Check:</LabelText>
      <DialogContentText>
        Please Write Estimate #{props.estimate.number} On Your Check
      </DialogContentText>
        <TextField
          margin="dense"
          id="description"
          inputRef={checkNumRef}
          // value={dialogValue.description}
          // onChange={(event) => setDialogValue({ ...dialogValue, description: event.target.value })}
          label="Check Number"
          type="text"
          fullWidth
          helperText="What Check Number Will You Be Sending So We Can Associate It To This Estimate?"
        />
        <p>
            {
                  props.loading ?
                  <Button variant="contained" fullWidth color="secondary" disabled> <CircularProgress color="primary" size={ 24 } /></Button> :
                  <Button variant="contained" fullWidth color="secondary" onClick={handlePaperCheck}>Submit</Button>
                }
        </p>
    </div>
  )
}

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ReceiveDeposit = (props) => {
  // console.log('COLLECT DEPOSIT PROPS:', props)
  const classes = useStyles();
  const sigPadRef = React.useRef({})
  const [state, setState] = React.useState({ paymentMethod: null, counter: 0 })
  const [proposal, setProposal] = React.useState({})
  const [logoURL, setLogoURL] = React.useState(null)
  const [contact, setContact] = React.useState({})
  const [client, setClient] = React.useState({})
  const [paymentMethod, setPaymentMethod] = React.useState({})
  const [amountToCharge, setAmountToCharge] = React.useState(0)
  React.useEffect(() => {

    let isMounted = true

    const fetchEstimate = async (estimateId, contactId) => {
      const response = await axios.get(`${props.url}/api/proposal/getOnlineProposalDetails?estimateId=${estimateId}&contactId=${contactId}&forDeposit=true`)
      if (response && response.data) {
        // console.log('STUFF: ', response.data)
        if (isMounted) {
          setProposal(response.data.estimate)
          setContact(response.data.contact)
          setClient(response.data.client)
          let url = response.data.estimate.entity._id
          // console.log('URL: ', url)
          setLogoURL(`../../../${url}_Logo.png`)
          const amtToCharge = currencyFormat(response.data.estimate.totalPrice * 0.5)
          setAmountToCharge(amtToCharge)
          // setState({...state, counter: state.counter + 1})
        }
      }
    }

    if (props.match && props.match.params && props.match.params.estimateId) {
    // console.log(props.match.params.estimateId)
      if (props.match && props.match.params && props.match.params.contactId) {
        fetchEstimate(props.match.params.estimateId, props.match.params.contactId)
      }
    }

    return () => {
      isMounted = false
    }
  }, [props])

  const handleAdd = (e) => {
    // console.log('REf: ', sigPadRef)
    sigPadRef.current.clear()
  }

  const handlePaymentMethod = (e, method) => {
    // console.log('New Payment Method:', e)
    // console.log(paymentMethod)
    // let method = e.target.id
    // console.log('Method: ', method)
    // console.log(state)
    setState({...state, paymentMethod: method, counter: state.counter + 1})
    setPaymentMethod(method)
  }

  const updatePmtMethodResponse = (message, paymentProfileId) => {
    // console.log('Updated Payment Method: ', message)
  }

  const updatePaperCheck = (checkNumber) => {
    // console.log('CHeck Number:', checkNumber)
        // console.log(client)
        let data = {
          type: 'paperCheck',
          checkNumber: checkNumber,
          amountToCharge: amountToCharge,
          estimate: proposal
        }
        // console.log('DATA: ', data)
        axios({
          method: 'post',
          url:  `${props.url}/api/proposal/initialDeposit`,
          data: data,
          // validateStatus: (status) => {
          // // console.log('Validate status: ', status)
          //   if (status && status === 500) {
    
          //   }
          // },
        }).then(response => {
          // console.log('Axios response: Initial Deposit ', response)
          if (response && response.data.error) {
            // console.log('Error: ', response.data.error)
            props.notification({
              type: 'warning',
              title: 'We Ran Into A Problem!',
              message: response.data.error.messages.message[0]['text']
            })
          }

        if (response && response.data && response.data.deposit) {
          props.notification({
            type: 'success',
            title: 'Success!',
            message: 'Your Check Number Has Been Noted. Thank You For Your Business.'
          })
        }
          // setLoading(false)
        }).catch(error => {
          // console.log('AXios error: ', error)
          // setLoading(false)
        })
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        { proposal._id ? <img alt="Logo" src={ logoURL} style={{ marginTop: '5px', maxHeight: 100, maxWidth: 300}}/> : null }
        <DialogContentText>
          Before we may begin working on this job, a deposit of {amountToCharge} is required. 
          We offer three convenient options to pay this deposit. Please choose your preferred method below.
        </DialogContentText>
        {/* <LabelText>Please provide the item name:</LabelText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={dialogValue.name}
          onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
          label="Name"
          type="text"
          fullWidth
        />
        <LabelText>Please provide the full description that you wish to have show up when you choose this item for an Estimate:</LabelText>
        <TextField
          margin="dense"
          id="description"
          value={dialogValue.description}
          onChange={(event) => setDialogValue({ ...dialogValue, description: event.target.value })}
          label="Description"
          type="text"
          fullWidth
        /> */}
      <LabelText>Please Choose Method Of Payment: </LabelText>

      <ButtonGroup className={classes.buttonGroup} size="large" color="primary" aria-label="large outlined primary button group">
        <Button id="creditCard" onClick={e => handlePaymentMethod(e, 'creditCard')}>Credit Card</Button>
        <Button id="eCheck" onClick={e => handlePaymentMethod(e, 'eCheck')}>eCheck/ACH</Button>
        <Button id="paperCheck" onClick={e => handlePaymentMethod(e, 'paperCheck')}>Paper Check</Button>
      </ButtonGroup>
     
        <ContainerArea>
      {
        (paymentMethod && paymentMethod === 'creditCard') ? (
          <AddCreditCard updateParent={updatePmtMethodResponse} estimate={proposal} amountToCharge={amountToCharge} paymentMethod={state.paymentMethod} client={client} contact={contact} />
        ) : null
      }

      {
        (paymentMethod && paymentMethod === 'eCheck') ? (
            <AddCustomerEcheck updateParent={updatePmtMethodResponse} estimate={proposal} amountToCharge={amountToCharge} paymentMethod={state.paymentMethod} client={client} contact={contact} />
        ) : null
      }
            {
        (paymentMethod && paymentMethod === 'paperCheck') ? (
            <AddPaperCheckDetails updateParent={updatePaperCheck} estimate={proposal} amountToCharge={amountToCharge} paymentMethod={state.paymentMethod} client={client} contact={contact} />
        ) : null
      }
        </ContainerArea>
        
      </Paper>
    </div>
  )
}
const mapStateToProps = (state) => {
  // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
  }
}

export default connect(mapStateToProps)(ReceiveDeposit)
