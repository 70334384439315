import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, IconButton, Badge, Tooltip }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import AddLineItemModal from './AddLotInventoryItemModal'

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  paper: {
    // width: '100%',
    // height: '100%',
    // minHeight: '80vh',
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  topRightArea: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'flex-end',
    alignContent: 'flex-end'
  }
}))

const takeoffs = [
  {
    name: 'Tree',
    options: ['Small', 'Medium', 'Large']
  },
  {
    name: 'Shrub',
    options: ['Small', 'Medium', 'Large']
  }
];

const LotInventoryLineItem = (props) => {
  console.log('LotInventoryLineItem Props', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [list, setList] = React.useState([])
  const [addItemModalOpen, setAddItemModalOpen] = React.useState(false)
  const [selectedItems, setSelectedItems] = React.useState([])

  React.useEffect(() => {
    let isMounted = true

    const fetchTakeoffs = async () => {
      setLoading(true)
      const response = await axios.get(`${props.url}/api/company/listTakeOffs`)
    // console.log(response)
      if (response.data && response.data.length) {
        setList(response.data)
      }
    }

    fetchTakeoffs()

    return () => {
      isMounted = false
    }
  }, [])
  const handleOpenAddItemModal = () => {
    console.log('Open our modal')
    setAddItemModalOpen(true)
  }

  const handleSaveItems = (data) => {
    console.log('Save this', data)
    const cleanData = {
      costPer: Number(data.costPer || 0),
      pricePer: Number(data.pricePer || 0),
      count: Number(data.count || 0),
      takeOffItem: data.selectedTakeoff,
      optionItem: data.selectedOption,
      yard: data.yard
    }
    let newList = [
      ...selectedItems,
      cleanData
    ]
    setSelectedItems(newList)
    setAddItemModalOpen(false)
    props.updateParent(newList)
  }

  const handleCloseAddItemModal = () => {
    setAddItemModalOpen(false)
  }

  return (
    <div className={classes.root}>
     <AddLineItemModal url={props.url} open={addItemModalOpen} handleClose={handleCloseAddItemModal} takeoffs={list} handleSave={handleSaveItems} />
      <Paper className={classes.paper}>
        <h1>{props.label || 'Items'}</h1>
        <div className={classes.topRightArea}>
          <p> Click the Plus Button to Add Items to Our Order</p>
          <Tooltip title="Add Item">
            <IconButton onClick={handleOpenAddItemModal}>
              <AddCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </div>

        <div>
            Items Selected {selectedItems.length}
        </div>
        <div>
          <ListOfSelectedItems items={selectedItems} />
        </div>
      </Paper>
    </div>
  )
}

const ListOfSelectedItems = ({ items }) => {
  return (
    <div>
      {
        items.map((item, index) => {
          return (
            <div key={index}>
             {item?.count} {item?.takeOffItem?.name} {item?.optionItem?.name}
            </div>
          )
        })
      }
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    entities: state.entities,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(LotInventoryLineItem)