import React from 'react'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import ModalToApply from './ModalToApplyDepositToJob'
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  }
}));

// function htmlToText(html){
//   // console.log('Strip html tags: ', html)
//   //remove code brakes and tabs
//   html = html.replace(/\n/g, "");
//   html = html.replace(/\t/g, "");

//   //keep html brakes and tabs
//   html = html.replace(/<table>/g, "");
//   html = html.replace(/<tr>/g, "");
//   html = html.replace(/<td>/g, "");
//   html = html.replace(/<\/td>/g, "\t");
//   html = html.replace(/<\/table>/g, "\n");
//   html = html.replace(/<\/tr>/g, "\n");
//   html = html.replace(/<\/p>/g, "\n");
//   html = html.replace(/<\/div>/g, "\n");
//   html = html.replace(/<div>/g, "");
//   html = html.replace(/<\/h>/g, "\n");
//   html = html.replace(/<br>/g, "\n"); 
//   html = html.replace(/<br( )*\/>/g, "\n");
//   // console.log('Stripped: ', html)
//   //parse html into text
//   // var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
//   // return dom.body.textContent;
//   return html;
// }

const JobPicker = (props) => {
  console.log('Job Picker ', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [jobNumber, setJobNumber] = React.useState(null)
  const [state, setState] = React.useState({
    jobName: '',
    billingAddress: {},
    jobSiteAddress: '',
    jobSite: {},
    salesRep: {},
    billingAddy: {},
    jobs: [],
    showApplyToModal: false,
    job: {},
    balance: 0,
    deposit: {}
  })
  React.useEffect(() => {
    // console.log('Use Effect Job Picker ')
    let openJobs = props.jobs.filter(job => (job.percentComplete < 100))
    setState({...state, jobs: openJobs, deposit: props.deposit, balance: props.deposit.balance})
    // console.log('Open Jobs: ', openJobs)
  }, [props])

  const applyToThisJob = (job) => {
    // console.log('Apply tot his job: ', job)
    setState({...state, showApplyToModal: true, job: job})
  }

  const responseFromApplyingToJob = (type, d) => {
    // console.log('Deposit Appplied: ', type)
    // console.log('D: ', d)
    if (type === 'Close') setState({...state, showApplyToModal: false})
    if (type === 'DepositApplied') {
      // console.log('Applied SOme of our Deposit', d)
      let dep = d.deposit
      let job = d.job
      let index = state.jobs.findIndex(crewItem =>  (crewItem._id === job._id));
      let ourJob = state.jobs[index]
      // console.log('Job to replace', ourJob)
      let newJobs = state.jobs
      newJobs[index] = job
      // console.log('Deposit: ', dep)
      setState({...state, jobs: newJobs, balance: dep.balance, deposit: dep, showApplyToModal: false})
      props.updateParent('DepositApplied', dep, newJobs)
    }
    // console.log('Response from our applying modal: ', d)
  }
  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }
return (
  <div>
    <ModalToApply open={state.showApplyToModal} forInvoiceOnly={props?.forInvoiceOnly} client={props.client} deposit={state.deposit} job={state.job} updateParent={responseFromApplyingToJob} />
    <Grid container>
    <Grid item lg={12} className={classes.paddedStuff}>
        Please Choose Job(s) To Apply This Deposit To ({currencyFormat(state.balance)}):
      </Grid>
      <Grid item lg={4}>
        Job Name (Number)
      </Grid>
      <Grid item lg={2}>
        Deposit Amount
      </Grid>
      <Grid item lg={2}>
       Balance
      </Grid>
      <Grid item lg={4}>
        Apply
      </Grid>
     

      { state.jobs.length ? (
        <Grid item lg={12}>
        {state.jobs.map(item => (
          <Grid container className={classes.paddedStuff}  style={{
            display: 'flex',
            alignItems: 'center'
        }} 
        key={item._id} >
          <Grid item lg={4}>
            <strong>{item.name} ( {item.number})</strong>
          </Grid>
          <Grid item lg={2} >
          {currencyFormat(item.depositAmount)}
              </Grid>
              <Grid item lg={2} >
                {currencyFormat(item.depositBalance)}
              </Grid>
            <Grid item lg={4}>
            <Button disabled={state.balance <= 0} color="primary" onClick={() => applyToThisJob(item)}>
              Apply to Job
            </Button>
          </Grid>
          </Grid>
        ))}
    </Grid>
      ) : (
        <DialogContentText>
          There are no open jobs available. Please create one.
        </DialogContentText>
      )}
  </Grid>
  </div>
)}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(JobPicker))