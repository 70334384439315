import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListView from './IrrigationAssetList'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '95%',
    margin: 10,
    marginBottom: theme.spacing(2),
    height: '80vh',
    overflow: 'auto'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  }
}));

const defaultStateValues = {
  view: 'List',
  data: []
}

const HMIIrrigationAssets = (props) => {
  const classes = useStyles()
  const [state, setState] = React.useState(defaultStateValues)

  const fetchData = async () => {
    const url = `${props.url}/api/mobileApp/hminpkwydata`
    const resp = await axios.get(url)
    console.log('Got the data', resp)
    if (resp && resp.data && resp.data.length) setState({...state, data: resp.data })
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div>HMI Irrigation Assets</div>
        {
          state.view === 'List' ? (
            <ListView data={state.data} />
          ) : (
            <MapView state={state} />
          )
        }
      </Paper>
      
    </div>
  )
}

const MapView = () => {
  return (
    <div>Map View</div>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(HMIIrrigationAssets)

