import React, { useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { useHistory } from 'react-router-dom'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box'
import ListOfItems from './ConfirmReceiptListOfItems'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  rowContainer: {
    // border: '3px solid red',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rowItem: {
    // border: '2px solid blue',
    fontSize: '1.25em',
    padding: 5,
    margin: 5
    // marginRight: 15
  },
  rowImage: {
    // border: '1px solid green',
    display: 'flex',
    alignSelf: 'stretch'
    // margin: -10,
    // padding: -5,
  },
  formControl: {
    verticalAlign: 'bottom',
    fontSize: '0.8em',
    marginTop: 5
  }
}));

const theDate = moment(new Date()).add(1, 'week').format('YYYY-MM-DD')

const defaultValues = {
  receivedItems: [],
  counter: 0
}



const ConfirmReceiptModal = (props) => {
  // console.log('ConfirmReceiptModal Props: ', props)
  const classes = useStyles();
  const history = useHistory()
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState(defaultValues)
  const [itemCounts, setItemCounts] = useState([])

  React.useEffect(() => {
    // console.log('edit po modal use effect')
      let isMounted = true

      const fetchPurchasedItemsDetails = async (po) => {
        console.log('Fetch PO Details')
        setLoading(true)
        // ?byEntity=610a290f45ef7427f94b2e78
        let url = `${props.url}/api/purchaseorders/getPurchasedItemsDetails`
        const obj = {
          po: po
        }
        axios.post(url, obj).then((response, status, data) => {
          setLoading(false)
          if (response && response.data) {
            console.log('Set Purchased Items...: ', response.data)
            if (isMounted) {
              console.log('Set em...')
              setState({...state, receivedItems: response.data})
                const newCounts = response.data.map((item) => ({ _id: item._id, count: item.count, id: item._id, received: item.count || 0, previousReceipt: item.received || 0, yard: '' }))
                setItemCounts(newCounts)
            }
          }
        })
      }

      if (props.purchaseOrder && props.purchaseOrder._id) fetchPurchasedItemsDetails(props.purchaseOrder)

      return () => {
        isMounted = false
      }
  }, [props.purchaseOrder?._id])

   const handleSave = () => {
    console.log('Save this shit...')
    setLoading(true)
    // ?byEntity=610a290f45ef7427f94b2e78
    let url = `${props.url}/api/inventory/adjustInventory?type=updateQuantity`
    const obj = {
      purchaseOrderId: props.purchaseOrder?._id,
      items: state.receivedItems,
      itemCounts,
      updateType: 'ConfirmReceipt'
    }
    axios.post(url, obj).then((response, status, data) => {
      setLoading(false)
      if (response && response.data) {
        console.log('Response from adjustment.... ', response.data)
        // setState({...state, receivedItems: response.data})
        const newCounts = response.data.map((item) => {
          let previousReceipt = item?.received || 0
          let currentReceived = item?.count || 0
          if (previousReceipt > 0) currentReceived = item.count - previousReceipt
          return ({ _id: item._id, count: item.count, id: item._id, received: currentReceived, previousReceipt: previousReceipt, yard: '' })
        })
        console.log('New Counts', newCounts)
        setItemCounts(newCounts)

        // console.log('Set em...')
        // setState({...state, receivedItems: response.data})
        // const newCounts = response.data.map((item) => ({ _id: item._id, count: item.count, id: item._id, received: item.count }))
        // setItemCounts(newCounts)
      }
    }).catch(e => {
      console.log('Erro 211 Confirmation Modal', e)
    })
   }

   const handleClose = () => {
    props.closeModal()
   }

  return (
    <div >
      <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth='lg' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Confirm Receipt of Items</DialogTitle>
          <DialogContent >
            <DialogContentText>
              Please Provide Details Of Received Items Below
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
               {
                  (state.receivedItems && state.receivedItems.length) ? <ListOfItems items={ state?.receivedItems || [] } itemCounts={itemCounts} setItemCounts={setItemCounts} state={state} setState={setState} /> : null
                }
               </Grid>
              
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button color="primary" disabled={loading} onClick={handleSave}>
                {
                  loading ? <CircularProgress /> : 'Save'
                }
              </Button>

          </DialogActions>
        </Dialog>
    </div>
  )
}

  const mapStateToProps = (state) => {
  // console.log('Create Purchase request', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(ConfirmReceiptModal)