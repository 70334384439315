import React, { useState } from 'react';
import { Fab, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert'; // This is the icon for the FAB itself
// Import icons for menu items


const FloatingActionMenu = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const options = props?.options
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={props.style ? props.style : null}>
      <Fab color="primary" aria-label="add" onClick={handleClick}>
        <MoreVertIcon />
      </Fab>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} onClick={() => {
            handleClose();
            option.onClick();
          }}>
            <ListItemIcon>
              {option.icon}
            </ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default FloatingActionMenu;
