import React from 'react'
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AssignedEntityComponent from '../Entities/AssignedEntityComponent'
import PhotoUploadComponent from '../Uploads/PhotoUploadComponent'
import QRCode from 'qrcode'

const AddLotInventoryModal = (props) => {
// console.log('ADD LOT INVENTORY:::', props)
  const [showModal, setShowModal] = React.useState(false)

  React.useEffect(() => {
  // console.log('Add Lot inventory modal useeffect', props)
    if (props.activeLotInventoryItem && props.activeLotInventoryItem._id) {
    // console.log('Open the modal!')
      setShowModal(true)
    } else {
    // console.log('do not open the modal!')
    }
  }, [props])

  const handleAddMaterials = () => {
    setShowModal(true)
  }
  const handleCloseModal = (item) => {
  // console.log('Close Modal - new item??', item)
    if (item && item._id) {
      if (props.activeLotInventoryItem) {
      // console.log('update this one:', item)
        props.modifyItem(item)
      } else {
      // console.log('Add this one:', item)
        props.addNewItem(item)
      }
    } else {
      props.closeModalOnly()
    }
    setShowModal(false)
  }

  return (
    <React.Fragment>
      <Tooltip title="Add Lot Inventory">
        <IconButton
          size="small"
          edge="end"
          onClick={handleAddMaterials}
          style={{marginLeft: 25}}
          >
          <AddIcon />
        </IconButton>
      </Tooltip>
      <LineItemModal activeLotInventoryItem={props.activeLotInventoryItem} url={props.url} notification={props.notification} client={props.client} project={props.project} open={showModal} closeModal={handleCloseModal} />
    </React.Fragment>
  )
}

const defaultValues = {
  amount: 0,
  description: '',
  notes: '',
  date: ''
}

const LineItemModal = (props) => {
// console.log('ADD INVENTORY LINE ITEM:', props)
  const classes = useStyles()
  const [state, setState] = React.useState(defaultValues)

  React.useEffect(() => {
    if (props.activeLotInventoryItem && props.activeLotInventoryItem._id) {
    // console.log('Set Our state values')
      setState({...state, ...props.activeLotInventoryItem })
    } else {
    // console.log('do not open the modal!')
      setState(defaultValues)
    }
  }, [props])

  const handleChange = (e) => {
    setState({...state, [e.target.id]: e.target.value })
  }

  const handleCloseModal = (data) => {
    // setState(defaultValues)
    props.closeModal(data)
  }

  const handleUpdate = async () => {
    let obj = {...state}
    obj._id = props.activeLotInventoryItem._id
    obj.project = props.project._id
    obj.client = props.client._id
    obj.entity = props.client.entity
  // console.log('end:', obj)
    const response = await axios.post(`${props.url}/api/projects/modifyLotInventoryUsed`, obj)
    // console.log('Response', response)
    if (response && response.data && response.data._id) {
      props.notification({
        title: 'Saved',
        message: 'We Updated That Item!',
        type: 'success'
      })
      handleCloseModal(response.data)
    } else {
      let errMsg = 'Error'
      if (response && response.data && response.data.error) {
        errMsg = response.data.error.message
        props.notification({
          title: 'Error',
          message: errMsg,
          type: 'warning'
        })
      }
    }
  }

  const handleSave = async () => {
  // console.log('State', state)
    let obj = {...state}
    obj.project = props.project._id
    obj.client = props.client._id
    obj.entity = props.client.entity
  // console.log('end:', obj)
    const response = await axios.post(`${props.url}/api/projects/addLotInventoryUsed`, obj)
    // console.log('Response', response)
    if (response && response.data && response.data._id) {
      props.notification({
        title: 'Saved',
        message: 'We Saved That Item!',
        type: 'success'
      })
      handleCloseModal(response.data)
    } else {
      let errMsg = 'Error'
      if (response && response.data && response.data.error) {
        errMsg = response.data.error.message
        props.notification({
          title: 'Error',
          message: errMsg,
          type: 'warning'
        })
      }
    }
  }

  const handleFocus = (event) => event.target.select();
  return (
    <Dialog
      open={props.open}
      size="md"
      onClose={handleCloseModal}
      fullWidth
      >
      <DialogTitle>{ props.activeLotInventoryItem?._id ? 'Modify Lot Inventory' : 'Add Lot Inventory' }</DialogTitle>
      <DialogContent
        className={classes.root}>
        <DialogContentText>
          Please Provide a Description of the Item(s) Used and the Cost to the Company.
        </DialogContentText>
        <TextField
          id="description"
          fullWidth
          className={classes.paddedItem}
          label="Description"
          variant="outlined"
          type="text"
          value={state.description}
          onFocus={handleFocus}
          onChange={handleChange}
          helperText="What Did We Use?"
        />
         <TextField
          id="amount"
          fullWidth
          type="text"
          label="Cost"
          variant="outlined"
          onFocus={handleFocus}
          className={classes.paddedItem}
          onChange={handleChange}
          value={state.amount}
          helperText="How Much Did It Cost?"
        />
         <TextField
          id="date"
          fullWidth
          type="date"
          label="Date"
          variant="outlined"
          onFocus={handleFocus}
          className={classes.paddedItem}
          onChange={handleChange}
          value={state.date}
          helperText="When Was This Used?"
        />
        <TextField
          id="notes"
          fullWidth
          type="text"
          label="Notes"
          onFocus={handleFocus}
          variant="outlined"
          className={classes.paddedItem}
          onChange={handleChange}
          value={state.notes}
          helperText="Any Notes?"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="secondary">Cancel</Button>
        {
          props.activeLotInventoryItem ? <Button onClick={handleUpdate} color="primary">Update</Button> : <Button onClick={handleSave} color="primary">Save</Button>
        }
      </DialogActions>

    </Dialog>
  )
}


const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    client: state.client
  }
}

export default connect(mapStateToProps)(AddLotInventoryModal)

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    // margin: '20px',
    // backgroundColor: '#ddd'
  },
  paddedItem: {
    margin: 10,
    padding: 5
  }
}))