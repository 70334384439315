import React from 'react'
import IndividualQuestions from './IndividualQuestions'

const Questions = (props) => {
// console.log('Questions:', props)
  let questionsToAsk = []
  if (props.calculator && props.calculator.length) {
  // console.log('We have a calculator!!')
    questionsToAsk = questionOptions[props.calculator]
  }
// console.log('Questions to ask', questionsToAsk)

  const saveQuestionAnswers = (data) => {
  // console.log('Save Answer:', data)
    let numberOfQuestions = questionsToAsk.length
    data.totalQuestions = numberOfQuestions
    props.updateParent(data)
  }

  if (questionsToAsk && questionsToAsk.length) {
    return (
      <>
        {
          questionsToAsk.map((item, index) => {
            return <IndividualQuestions key={index} question={item} updateParent={saveQuestionAnswers} />
          })
        }
      </>
    )
  } else {
    return null
  }
}

const questionOptions = {
  'Plant Material Labor': [
    {
      question: 'Have You Entered ALL Plant Material?',
      value: null,
      name: 'done',
      helperText: 'We Need To Know What Trees/Plants/Cacti We Are Planting to Calculate Labor Requirements.'
    }
  ],
  'Gravel/Rip-Rap': [
    {
      question: 'How Deep?',
      name: 'depth',
      value: null,
      defaultValue: 'Answer In Inches',
      helperText: 'We Need the Depth to Determine The Tonnage...typically we do 2 Inches Deep.'
    },
    {
      question: 'What Size?',
      value: null,
      name: 'size',
      defaultValue: 'Answer in Inches',
      helperText: 'What Size of Gravel/Rip-Rap are We Using?',
      options: [`1/4"`, `1/2"`, `3/4"`, `1"`, `Rip-Rap`]
    },
    {
      question: 'How Many Square Feet?',
      value: null,
      name: 'sf',
      defaultValue: 'Answer In Feet',
      helperText: 'How Many Square Feet Do You Need to Cover?'
    }
  ],
  'Irrigation': [
    {
      question: 'Have You Entered ALL Plant Material?',
      value: null,
      name: 'done',
      helperText: 'We Need To Know What Trees/Plants/Cacti We Are Planting to Calculate Irrigation Requirements.'
    },
    {
      question: 'Square Footage of Sod?',
      value: null,
      name: 'sod',
      helperText: 'We Need To Know If We Have Any Sod to Calculate Irrigation Requirements.'
    }
  ],
  'Lighting': [
    {
      question: 'How Many Lights?',
      value: null,
      name: 'qty',
      helperText: 'We Need the Total Number of Lights'
    }
  ],
  'Firepit': [
    {
      question: 'How Deep?',
      value: null,
      helperText: 'We Need the Depth to Determine The Tonnage...typically we do 2 Inches Deep.'
    }
  ],
  'BBQ': [
    {
      question: 'How Deep?',
      value: null,
      helperText: 'We Need the Depth to Determine The Tonnage...typically we do 2 Inches Deep.'
    }
  ],
  'Pool/Spa': [
    {
      question: 'How Deep?',
      value: null,
      helperText: 'We Need the Depth to Determine The Tonnage...typically we do 2 Inches Deep.'
    }
  ],
  'Spray': [
    {
      question: 'Gallons of Water?',
      value: null,
      name: 'gallonsOfWater',
      helperText: 'How Many Gallons of Water Are In The Tank?'
    },
    {
      question: 'Square Footage?',
      value: null,
      name: 'sf',
      helperText: 'How Large is the Area of Coverage?'
    },
    {
      question: 'Chemical Ratio Percentage?',
      value: null,
      name: 'ratio',
      helperText: 'Amount of Chemical to Water Ratio 1:10 = 10'
    }
  ],
}

export default Questions
