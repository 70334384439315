import React from 'react';
import { 
  Grid, 
  Paper, 
  Typography, 
  Tooltip, 
  IconButton, 
  Box 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useHistory  } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  amount: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  balanceAmount: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: 'red'
  },
  fieldHeader: {
    fontWeight: 500,
    fontSize: '1.1rem',
    color: '#aaa'
  },
  fieldHeaderBottom: {
    fontWeight: 500,
    fontSize: '1.1rem',
    color: '#aaa',
    marginTop: 'auto',
    paddingTop: theme.spacing(2)
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  spacer: {
    flexGrow: 1,
  },
}));

const ProjectInvoicingDetails = ({ state, estimateTotalAccepted, currencyFormat, client }) => {
  const classes = useStyles();
  const history = useHistory()

  const handleGenerateInvoice = () => {
    history.push(`/generate-invoice/${client._id}`, { replace: true })
  }

  let projectBalance = estimateTotalAccepted - state?.estimateInvoicedTotal

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
              <div>
                <Typography className={classes.fieldHeader}>Estimated Total</Typography>
                <Typography className={classes.amount}>
                  {currencyFormat(estimateTotalAccepted)}
                </Typography>
                <Typography className={classes.fieldHeader}>Invoiced</Typography>
                <Typography className={classes.amount}>
                  {currencyFormat(state?.estimateInvoicedTotal)}
                </Typography>
              </div>
              <div>
                <Typography className={classes.fieldHeader}>Remaining Balance</Typography>
                <Typography className={projectBalance > 0 ? classes.balanceAmount : classes.amount}>
                  {currencyFormat(projectBalance)}
                </Typography>
              </div>
            </div>
          </Paper>
        </Grid>
      
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <div className={classes.contentWrapper}>
              <div>
                <Typography className={classes.fieldHeader}>Adds/Changes/T&M Invoices</Typography>
                <Typography className={classes.amount}>
                  {currencyFormat(state?.nonEstimateInvoicedTotal)}
                </Typography>
              </div>
              <div className={classes.spacer} />
              <div>
                <Typography className={classes.fieldHeaderBottom}>Total Invoiced to Project</Typography>
                <Typography className={classes.amount}>
                  {currencyFormat(state?.invoicedTotal)}
                </Typography>
              </div>
            </div>
          </Paper>
        </Grid>
        
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Typography variant="body1" style={{ marginRight: '10px' }}>
              Need to generate a new invoice?
            </Typography>
            <Tooltip title="Generate Invoice">
              <IconButton
                size="medium"
                edge="end"
                onClick={handleGenerateInvoice}
                color="primary"
              >
                <AttachMoneyIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProjectInvoicingDetails;