import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
// import { Link } from 'react-router-dom'

// function createData(name, status, created, lastactivity, tourbooked) {
//   return { name, status, created, lastactivity, tourbooked };
// }

// const rows = [
//   createData('Tom Smith', 'New', '12/17/2019', null, null),
//   createData('Donut', 'Working', '12/18/2019', 51, 4.9),
//   createData('Eclair', 'New', '11/29/2019', 24, 6.0),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Honeycomb', 408, 3.2, 87, 6.5),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Jelly Bean', 375, 0.0, 94, 0.0),
//   createData('KitKat', 518, 26.0, 65, 7.0),
//   createData('Lollipop', 392, 0.2, 98, 0.0),
//   createData('Marshmallow', 318, 0, 81, 2.0),
//   createData('Nougat', 360, 19.0, 9, 37.0),
//   createData('Oreo', 437, 18.0, 63, 4.0),
// ];

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
// console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
  // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'clientName', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'invoiceNumber', numeric: false, disablePadding: false, label: 'Invoice Number' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
  { id: 'balance', numeric: false, disablePadding: false, label: 'Balance' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Created' },
  { id: 'qbInvNumber', numeric: false, disablePadding: false, label: 'Quickbooks Invoice Number' },
];

function EnhancedTableHead(props) {
// console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort, num } = props;
  const createSortHandler = property => event => {
  // console.log('Sort Property: ', property)
  // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.center ? 'center' : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    border: '3px solid #ccc',
    borderRadius: 25,
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'scroll',
    minHeight: '40vh',
    maxHeight: '55vh'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    fontSize: '1.2em'
  },
  rowContainer: {
    // border: '3px solid red',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rowItem: {
    // border: '2px solid blue',
    fontSize: '1.25em',
    padding: 5,
    margin: 5
    // marginRight: 15
  },
  rowImage: {
    // border: '1px solid green',
    display: 'flex',
    alignSelf: 'stretch'
    // margin: -10,
    // padding: -5,
  }
}));

function EnhancedTable(props) {
// console.log('Customer List Props: ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [crewList, setCrewList] = React.useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [customerDetailModalOpen, setCustomerDetailModalOpen] = React.useState(false)
  const [snoutWeevilModalOpen, setSnoutWeevilModalOpen] = React.useState(false)
  const [client, setClient] = React.useState({});
  const [loading, setLoading] = React.useState(false)
  const [showAllCustomers, setShowAllCustomers] = React.useState(false)
  const [filteredRows, setFilteredRows] = React.useState([])
  const [state, setState] = React.useState({
    searchMatches: 0,
    openSnoutWeevil: false
  })
  const [showNewClientModal, setShowNewClientModal] = React.useState(false)
  const [doDeepSearch, setDoDeepSearch] = React.useState(false)
  // const [leads, setLeads] = React.useState([]);
  // setLeads(props.leads)
  // let clients  = []
  // if (clients && clients.length > 0) clients = props.clients
  // console.log('Clients on table: ', clients)
  // const rows = leads
  const [rows, setRows] = React.useState(props.invoices || []);
  const [activeEntity, setActiveEntity] = React.useState({})
  // setRows(leads)
  // setRows(leads)
  // useEffect(() => {
  // // console.log('Use effect LEADS', leads)
  //   axios
  //     .get(
  //       `${url}/api/tours/getAll`
  //       )
  //     .then(({ data }) => {
  //     // console.log(data)
  //       setLeads(data)
  //     }).catch(function (err) {
  //     // console.log('Error getting stuff', err)
  //     });
  // }, [leads]);

  //   async function fetchCrews() {
  // // console.log('Fetch our crews so we have them for snout weevil')
  //   setLoading(true)
  //   axios.get(`${props.url}/api/crews/listCrews`).then((response, status, data) => {
  //   // console.log(response)
  //     // console.log(status)
  //     // console.log(data)
  //     if (response && response.data && response.data.crews) {
  //     // console.log('Got Crews for snout weevil!!', response.data )
  //       setCrewList(response.data.crews)
  //     }
  //   }).catch((error, status) => {
  //     // console.log('Error', error)
  //     // console.log(status)
  //   })
  // }
  // React.useEffect(() => {
  // // console.log('Get Crews for SNout Weevil')
  //   fetchCrews()
  // }, [props])

  React.useEffect(() => {
  // console.log('Use effect bhl invoices list ', props.invoices)
    if (props.invoices && props.invoices.length) {
      setFilteredRows(props.invoices)
      setRows(props.invoices)
    } else {
      setFilteredRows([])
      setRows([])
    }  
  }, [props.invoices])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
  // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const storeSearchMatches = (d) => {
  // console.log('Search matches: ', d)
    // setState({...state, searchMatches: d.length})
  }
  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log('String', str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    // console.log('Lenght: ', newArr.length)
    // console.log(newArr)
    // storeSearchMatches(newArr)
    // setsearchMatches(newArr.length)
    // return newArr
    setFilteredRows(newArr)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }
  // async function fetchData() {
  // // console.log('Update our clients')
  //   const response = await axios.get(`${props.url}/api/customer/clients`)
  // // console.log(response)
  //   if (response.data && response.data.length > 0) {
  //   // console.log('Got Updated Clients!!')
  //     setFilteredRows(response.data)
  //     setRows(response.data)
  //   } else {
  //   // console.log('Fucked up getting Clients')
  //   }
  // }

  const openClientModal = (client) => {
  // console.log('Open client modal: ', client)
    setClient(client)
    setCustomerDetailModalOpen(true)
    // props.updateCustomerList(clients => {
    // // console.log('Got updated client list!', clients)
    // // console.log('SHOW AL: ', showAllCustomers)
    //   if (showAllCustomers) {
    //   // console.log('Show everyone')
    //     setRows(clients)
    //     filterOut(clients, searchValue)
    //   } else {
    //   // console.log('Gonna just show the active clients')
    //     setRows(clients)
    //     let activeClients = clients.filter(item => { return item.active })
    //     filterOut(activeClients, searchValue)
    //   }
    // })
  }
  
  const getCustomers = async (deep) => {
    // console.log('Fetch our clients but keep our search value', searchValue)
    setLoading(true)
    axios.get(`${props.url}/api/customer/clients?deep=${deep}`).then((response, status, data) => {
      // console.log(response)
      // console.log(status)
      // console.log(data)
      if (response && response.data && response.data.length > 0) {
        // console.log('Got Clients!!')
        setLoading(false)
        let customerList = []
        if (showAllCustomers) {
          // console.log('Show everyone')
          // setRows(response.data)
          // filterOut(response.data, searchValue)
          customerList = response.data
        } else {
          // console.log('Gonna just show the active clients')
          let activeClients = response.data.filter(item => { return item.active })
          // console.log('Active: ', activeClients)
          // filterOut(activeClients, searchValue)
          // setRows(activeClients)
          customerList = activeClients
        }
        if (!activeEntity.name || activeEntity.name === 'All Assigned') {
          // Show all available Customers
          filterOut(customerList, searchValue)
          setRows(customerList)
        } else {
          // Filter by the active Entity
          let filteredClients = customerList.filter(item => { return item.entity === activeEntity._id })
          // console.log('Active: ', activeClients)
          filterOut(filteredClients, searchValue)
          setRows(filteredClients)
        }
        // setRows(response.data)
        // filterOut(response.data, searchValue)
      } else {
        // console.log('Fucked up', response)
        if (response === 'Unauthorized') props.history.push('/restricted')
      }
    }).catch((error, status) => {
      // console.log('Error', error)
      // console.log(status)
    })
  }
  
  const onModalClose = (toClose) => {
  // console.log('Modal close...', toClose)
    // setClient(null)
    setClient({})
    setCustomerDetailModalOpen(false)
    setLoading(false)
    getCustomers()
    // props.updateCustomerList(searchValue)
    //  => {
    //   // console.log('Got updated client list!', clients)
    //     setLoading(false)
      // // console.log('SHOW AL: ', showAllCustomers)
      //   if (showAllCustomers) {
      //   // console.log('Show everyone')
      //     setRows(clients)
      //     filterOut(clients, searchValue)
      //   } else {
      //   // console.log('Gonna just show the active clients')
      //     setRows(clients)
      //     let activeClients = clients.filter(item => { return item.active })
      //     filterOut(activeClients, searchValue)
      //   }
      // })
  }

  const handleSnoutWeevil = (client) => {
  // console.log('Open Snout Weevil Modal')
    // setClient(client)
    // setState({...state, openSnoutWeevil: true})
    // setSnoutWeevilModalOpen(true)
    // setCustomerDetailModalOpen(true)
    props.openSnoutWeevilModal(client)
  }
  const onSWModalClose = (d) => {
  // console.log('Close snout weevil modal', d)
    setState({...state, openSnoutWeevil: false})
    setSnoutWeevilModalOpen(false)
    setCustomerDetailModalOpen(false)
  }
  const updateParent = (d) => {
  // console.log('Update from child ', d)
  // console.log('Search Value: ', searchValue)
    if (d && d === 'onSWModalClose') onSWModalClose(d)
    if (d && d.client) {
      setShowNewClientModal(false)
      openClientModal(d.client)
    }
  }

const handleAddClient = () => {
// console.log('Add new client')
  setShowNewClientModal(true)
}

function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const setShowAllOrNot = () => {
// console.log('To Shor all or not', state)
  setsearchValue('')
  if (showAllCustomers) {
  // console.log('Gonna just show the active now')
    setShowAllCustomers(false)
    let activeClients = props.clients.filter(item => { return item.active })
    // console.log('Got %d activeClients: ', activeClients.length)
    setFilteredRows(activeClients)
    setRows(activeClients)
  } else {
  // console.log('Show everyone')
    setShowAllCustomers(true)
    setFilteredRows(props.clients)
    setRows(props.clients)
  }
  setPage(0)
}

const handleUpdateVisibleEntity = (activeEntity) => {
  let customerList = null
  if (showAllCustomers) {
    customerList = props.clients
  } else {
    customerList = props.clients.filter(item => { return item.active })
  }    
  if (activeEntity.name === 'All Assigned') {
    // Show all available Customers
    filterOut(customerList, searchValue)
    setRows(customerList)
  } else {
    // Filter by the active Entity
    let filteredClients = customerList.filter(item => { return item.entity === activeEntity._id })
    // console.log('Active: ', activeClients)
    filterOut(filteredClients, searchValue)
    setRows(filteredClients)
  }
  setActiveEntity(activeEntity)
}

const toggleDeepSearch = () => {
// console.log('Toggle', doDeepSearch)
  let newState = !doDeepSearch
  if (newState) {
  // console.log('Deep search is on!')
    // getCustomers(true)
  }
  setDoDeepSearch(newState)
}
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container style={{padding: '10px'}}>
          <Grid item lg={4} xs={6}>

            <div className={classes.search}>
                {/* <div >
                  <SearchIcon /> Search { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
                </div> */}
                {/* <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input> */}
                <TextField
                    name="search" 
                    value={ searchValue } 
                    onChange={ handleChange('search') } 
                    type="text"
                    label="Search"
                    type="search"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                />
                {/* <div className={classes.fakeLink} onClick={toggleDeepSearch}>Go Deeper</div> */}
              </div>
              
          </Grid>
          {/* <Grid item lg={3} xs={6}>
            <FormControlLabel
                    control={<Checkbox checked={doDeepSearch} onChange={toggleDeepSearch} name="DeepSearch" />}
                    label="Deep Search"
                  />
          </Grid> */}
          <Grid item lg={3} xs={6}>
            {/* <FormControlLabel
                    control={<Checkbox checked={showAllCustomers} onChange={setShowAllOrNot} name="showAllCustomers" />}
                    label="Show All Customers"
                  /> */}
          </Grid>
        </Grid>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              { stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log('Row map: ', row)
                  // console.log('Total rows; ', rows)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // setsearchMatches(rows.length)
                  // console.log(row)
                  return (
                    
                    <TableRow
                      hover
                      // onClick={event => handleClick(event, row._id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      // style={row.pastDue ? { backgroundColor: 'rgba(233, 103, 103, .5)', } : null}
                    >
                      <TableCell component="td" id={labelId} scope="row" padding="none">
                        {/* <span className={classes.fakeLink} onClick={() => openClientModal(row)}>{row.displayName}</span> */}
                        <Link 
                          style={{pointer: 'cursor'}}
                          target="_blank"
                          to={{
                                pathname: `/clients/${row.clientId}/Invoices/${row.invoiceId}`,
                                // search: '?sort=name',
                                // hash: '#the-hash',
                                state: {customer: row, stuff: 'Search Engine'}
                              }}> {row.clientName} 
                              
                        </Link>
                      </TableCell>

                   
                      <TableCell align="left">
                        {/* { moment(row.created_at).format('MM/DD/YYYY - hh:mmA') } */}
                        { row.invoiceNumber }
                      </TableCell>
                      <TableCell align="left">
                        {currencyFormat(row.amount)}
                      </TableCell>
                      <TableCell align="left">
                        {currencyFormat(row.balance)}
                      </TableCell>
                      <TableCell align="left">
                        { moment(row.created_at).format('MM/DD/YYYY hh:mma') }
                      </TableCell>
                      <TableCell align="left">
                        {row.quickbooksNumber}
                      </TableCell>
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          { loading ?<CircularProgress style={{color: '#fff'}} size={ 16 } /> : ''}
                    
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500, 1000, 2000]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    user: state.user
  }
}

export default React.memo(connect(mapStateToProps)(EnhancedTable))

