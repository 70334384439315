import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(2),
  },
  fieldContainer: {
    marginTop: theme.spacing(2),
  },
}));

const BulkEditDialog = ({ 
  open, 
  onClose, 
  selectedIds, 
  onSave, 
  loading 
}) => {
  const classes = useStyles();
  const [fields, setFields] = React.useState({
    confidence: { enabled: false, value: '' },
    duration: { enabled: false, value: '' },
    expectedCloseDate: { enabled: false, value: '' }
  });

  const handleFieldToggle = (fieldName) => {
    setFields(prev => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        enabled: !prev[fieldName].enabled
      }
    }));
  };

  const handleValueChange = (fieldName, value) => {
    setFields(prev => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        value: value
      }
    }));
  };

  const handleSave = () => {
    const updates = {};
    Object.keys(fields).forEach(key => {
      if (fields[key].enabled && fields[key].value !== '') {
        updates[key] = fields[key].value;
      }
    });

    if (Object.keys(updates).length > 0) {
      onSave(updates);
    }
  };

  const isValid = Object.values(fields).some(field => 
    field.enabled && field.value !== ''
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Bulk Edit {selectedIds.length} Proposals
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary">
          Select which fields you want to update for all selected proposals
        </Typography>
        
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup className={classes.formGroup}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fields.confidence.enabled}
                  onChange={() => handleFieldToggle('confidence')}
                />
              }
              label="Confidence"
            />
            {fields.confidence.enabled && (
              <TextField
                type="number"
                label="Confidence %"
                variant="outlined"
                size="small"
                value={fields.confidence.value}
                onChange={(e) => handleValueChange('confidence', e.target.value)}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 1
                }}
              />
            )}
          </FormGroup>

          <FormGroup className={classes.formGroup}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fields.duration.enabled}
                  onChange={() => handleFieldToggle('duration')}
                />
              }
              label="Duration"
            />
            {fields.duration.enabled && (
              <TextField
                type="number"
                label="Duration (months)"
                variant="outlined"
                size="small"
                value={fields.duration.value}
                onChange={(e) => handleValueChange('duration', e.target.value)}
                inputProps={{
                  min: 0,
                  max: 120,
                  step: 1
                }}
              />
            )}
          </FormGroup>

          <FormGroup className={classes.formGroup}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={fields.expectedCloseDate.enabled}
                  onChange={() => handleFieldToggle('expectedCloseDate')}
                />
              }
              label="Expected Close Date"
            />
            {fields.expectedCloseDate.enabled && (
              <TextField
                type="date"
                label="Expected Close Date"
                variant="outlined"
                size="small"
                value={fields.expectedCloseDate.value}
                onChange={(e) => handleValueChange('expectedCloseDate', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button 
          onClick={handleSave} 
          color="primary" 
          variant="contained"
          disabled={!isValid || loading}
        >
          {loading ? 'Updating...' : 'Update All'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkEditDialog;
