import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const EditOrderQuantity = (props) => {
// console.log('Time to Edit: ', props)
  const [state, setState] = React.useState({
    qty: 0
  })
  const [loading, setLoading] = React.useState(false)
  const [item, setItem] = React.useState({})
  React.useEffect(() => {
    // setState({...state, qty: props.item.orderQuantity || 0 })
    setItem(props.item)
  }, [props.item])

  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleSubmit = () => {
  // console.log('Handle Submit', state)
    setLoading(true)
    let obj = {
      item: item,
      user: props.user
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/inventory/updateBHLSupplyItem`,
      data: obj,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Axios response to update inventory ', response)
      props.updateParent('updated', response.data)
      setLoading(false)
      // setState({})
      // setJobNumber(response.data)
      // if (response.data._id) {
      //   // props.newNoteResponse('newNote', response.data)
      //   props.updateContact(response.data)
        // setState({note: ''})
        props.notification({
          type: 'success',
          title: 'Inventory Item Saved!',
          message: 'Updates To This Item Have Been Saved!'
        })
  })
    
  }

  const handleQtyChange = () => {
  // console.log('Handle qty change')
  }

return (
  <div>
    
      <Dialog
        // fullScreen={false}
        // maxWidth={'sm'}
        // width='lg'
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Modify BHL Supply Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Update The Proper Fields Below:
          </DialogContentText>
          
          <Grid container spacing={2}>

              <Grid item lg={4}>
                <TextField
                label="Code"
                onChange={ e => setItem({...item, code: e.target.value })}
                name="code"
                value={ item.code }
                margin="normal"
                fullWidth
              />
              </Grid>

               <Grid item lg={8}>
                <TextField
                label="Name"
                onChange={ e => setItem({...item, name: e.target.value })}
                name="itemName"
                value={ item.name }
                margin="normal"
                fullWidth
              />
              </Grid>
              <Grid item lg={12}>
                <TextField
                label="Price"
                onChange={ e => setItem({...item, unitCost: e.target.value })}
                name="itemPrice"
                value={ item.unitCost }
                margin="normal"
                fullWidth
              />
              </Grid>
              {/* <Grid item lg={4}>
                <TextField
                label="Quantity On Hand"
                onChange={ e => setItem({...item, quantity: e.target.value })}
                name="itemQty"
                value={ item.quantity }
                margin="normal"
                fullWidth
                disabled={item.bulkItem}
              />
              </Grid>
              <Grid item lg={4}>
                <TextField
                label="Minimum Quantity"
                onChange={ e => setItem({...item, min_quantity: e.target.value })}
                name="itemMinQty"
                value={ item.min_quantity }
                margin="normal"
                fullWidth
                disabled={item.bulkItem}
              />
              </Grid>
              <Grid item lg={4}>
                <TextField
                label="Bulk Quantity"
                onChange={ e => setItem({...item, bulkQty: e.target.value })}
                name="bulkQty"
                value={ item.bulkQty }
                margin="normal"
                fullWidth
                disabled={!item.bulkItem}
              />
              </Grid> */}
              <Grid item lg={4} xs={12}>
                <TextField
                label="SKU"
                onChange={ e => setItem({...item, sku: e.target.value })}
                name="itemSKU"
                value={ item.sku }
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item lg={4} xs={12}>
                <TextField
                label="Mfg Num"
                onChange={ e => setItem({...item, mfgNum: e.target.value })}
                name="mfgNum"
                value={ item.sku }
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item lg={4} xs={12}>
                <TextField
                label="Product ID"
                onChange={ e => setItem({...item, productId: e.target.value })}
                name="productId"
                value={ item.productId }
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item lg={12}>
                <TextField
                label="Horizon URL"
                onChange={ e => setItem({...item, url: e.target.value })}
                name="url"
                value={ item.url }
                margin="normal"
                fullWidth
              />
            </Grid>

            <Grid item lg={12}>
                <TextField
                label="Notes"
                onChange={ e => setItem({...item, notes: e.target.value })}
                name="itemNotes"
                value={ item.notes }
                margin="normal"
                fullWidth
              />
            </Grid>

        </Grid>
        <Grid item lg={12} style={{ textAlign: 'center'}}>
        <img src={item.pictureURL} width='90%' />
        </Grid>
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button disabled={!item.name || !item.unitCost} onClick={() => handleSubmit()} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(EditOrderQuantity)