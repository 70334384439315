import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions
} from '@material-ui/core'
import FileUploadComponent from '../Uploads/UploadComponent'

const WarrantyUpload = (props) => {
  const [filesUploaded, setFilesUploaded] = React.useState(0)
  const handleCloseModal = () => {
    props.closeModal(false)
  }

  const handleConfirm = () => {
    props.closeModal(true)
  }
  
  const updateFromPhotoUpload = (data) => {
  // console.log('Upload Response', data)
    if (data && data.filesUploaded && data.filesUploaded.length) {
      let num = filesUploaded
      num += Number(data.filesUploaded.length)
      setFilesUploaded(num)
    }
  }

  return (
    <Dialog
      open={ props.open }
      // onClose={ handleCloseModal }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Photo Upload</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Please upload photo(s) of the items requiring a warranty replacement
        </DialogContentText>
        <FileUploadComponent po={props.po} warranty={true} updateParent={updateFromPhotoUpload} />
        <DialogContentText>Without Photos of the Damaged Items, Your PO Will NOT Be Approved.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={ filesUploaded < 1 } onClick={ handleCloseModal } color="primary">
          Close
        </Button>
        
      </DialogActions>
    </Dialog>
  )
}


export default WarrantyUpload
