const initState = {
  user: null,
  authenticated: false,
  notification: null,
  paypal: null,
  theme: 'light',
  position: null,
  perms: [],
  activeJob: null,
  salesReps: [],
  employees: [],
  clients: [],
  client: {},
  users: [],
  crews: [],
  services: [],
  fertilizationButtonActive: false,
  snoutWeevilButtonActive: false,
  winterFrostProtectionButtonActive: false,
  configs: [],
  entities: [],
  contacts: [],
  projects: [],
  scopes: []
}

const rootReducer = (state = initState, action) => {
  // console.log('Reducer: ', state);
  // console.log('action: ', action);
  switch (action.type) {
    case 'setUrl':
      return {
        ...state,
        url: action.url
      }
    case 'setServices':
      return {
        ...state,
        services: action.data
      }
    case 'setNotifications':
      return {
        ...state,
        notification: action.notification
      }
    case 'setAuth':
      return {
        ...state,
        authenticated: action.authenticated
      }
    case 'setUser':
      return {
        ...state,
        user: action.user,
        perms: (action.user && action.user.permissions) ? action.user.permissions : []
      }
    case 'setGPS':
      return {
        ...state,
        position: action.position
      }
    case 'setCrew':
      return {
        ...state,
        crew: action.crew
      }
    case 'setCrews':
      return {
        ...state,
        crews: action.crews
      }
    case 'setClient':
      return {
        ...state,
        client: action.client
      }
    case 'setClients':
      return {
        ...state,
        clients: action.data
      }
    case 'setUsers':
      return {
        ...state,
        users: action.data
      }

      case 'setSalesReps':
        return {
          ...state,
          salesReps: action.data
        }
    case 'setEmployees':
      return {
        ...state,
        employees: action.data
      }
      case 'setTasks':
        return {
          ...state,
          tasks: action.data
        }

    case 'setActiveJob':
      return {
        ...state,
        activeJob: action.setActiveJob
      }
    case 'setPaypal':
      return {
        ...state,
        paypal: action.paypal
      }
    case 'setTheme':
      return {
        ...state,
        theme: action.theme
      }
    case 'setPerms':
      // console.log('Set Permissions: ', action)
      return {
        ...state,
        perms: action.perms
      }
    case 'setEntities':
      // console.log('Set Permissions: ', action)
      return {
        ...state,
        entities: action.entities
      }
    case 'setFertilizationButtonActive':
      // console.log('Set Fertilization Button 120: ', action)
      return {
        ...state,
        fertilizationButtonActive: action.fertilizationButtonActive
      }
    case 'setSnoutWeevilButtonActive':
      // console.log('Set Snout Weevil 126: ', action)
      return {
        ...state,
        snoutWeevilButtonActive: action.snoutWeevilButtonActive
      }

    case 'setWinterFrostProtectionButtonActive':
      // console.log('Set Snout Weevil 126: ', action)
      return {
        ...state,
        winterFrostProtectionButtonActive: action.winterFrostProtectionButtonActive
      }
    case 'setConfigs':
      // console.log('Set Configs ', action)
    return {
      ...state,
      configs: action.configs
    }
      
    case 'setContacts':
      // console.log('Set Snout Weevil 126: ', action)
      return {
        ...state,
        contacts: action.contacts
      }
      
    case 'setProjects':
    // console.log('Set Snout Weevil 126: ', action)
    return {
      ...state,
      projects: action.projects
    }

    case 'setScopes':
      return {
        ...state,
        scopes: action.scopes
      }

    default:
      return state
  }
}

export default rootReducer
