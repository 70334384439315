import React, { useState } from 'react';
import { Button, TextField, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Paper, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import moment, { isDate } from 'moment'
import axios from 'axios'

const PrintOptionModal = (props) => {
  const [date, setDate] = React.useState(new Date())
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = React.useState(false)

  const handleSelect = (id) => {
      if (selected.includes(id)) {
          setSelected((prev) => prev.filter((i) => i !== id));
      } else {
          setSelected((prev) => [...prev, id]);
      }
  };

  const handleSelectAll = () => {
      if (selected.length === props.crews.length) {
          setSelected([]);
      } else {
          setSelected(props.crews.map((crew) => crew._id));
      }
  };

  const handleClose = () => {
    props.closeModal()
  }

  const handleUpdateDate = (e) => {
    setDate(e.target.value)
  }

  const handlePrint = () => {
    setLoading(true)
    // console.log('Print it:', date)
    let data = {
      crews: selected,
      date: date
    }
    let url = `${props.url}/api/mtcRoutes/printRouteSheet`
    if (props.isHMI) url = `${props.url}/api/hmi/printRouteSheet`
    axios.post(url, data).then(response => {
      setLoading(false)
      console.log('Print Response', response.data)
      // Return this to the parent as its our PDF
      props.handleShowPDF(response.data)
    }).catch(e => {
      console.log('Error printing: ', e)
    })
  }


  return (
      <Dialog open={props.open} onClose={handleClose}>
        <DialogTitle>Route Print</DialogTitle>
        <DialogContent style={{ margin: 10, paddingVertical: 15 }}>
          <div style={{ fontSize: '18px', color: '#aaa', margin: '15px' }}>Choose Which Day You'd Like to Print: </div>
          <div  style={{ fontSize: '18px', color: '#aaa', margin: '15px' }}>
            { date ? moment(date).format('dddd, MMMM Do YYYY') : ''}
          </div>
          <DateChooser date={date} changeDate={handleUpdateDate}/>         
          <CrewOptions crews={props.crews} selected={selected} handleSelectAll={handleSelectAll} handleSelect={handleSelect} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handlePrint} disabled={loading}>
            {
              loading ? <CircularProgress color="primary" size={48} /> : 'PRINT'
            }
          </Button>
          
        
      </DialogActions>
      </Dialog>
  );
};

const CrewOptions = ({ selected, crews, handleSelectAll, handleSelect }) => {

  return (
    <div style={{  maxHeight: '300px', overflowY: 'scroll', marginTop: 20 }}>
       <div style={{ fontSize: '18px', color: '#aaa' }}>Choose Which Crews Should Be Included in Your PDF:</div>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            checked={selected.length === crews.length}
                            onChange={handleSelectAll}
                        />
                    </TableCell>
                    <TableCell>Crew Name (SELECT ALL)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {crews.map((crew) => (
                    <TableRow key={crew._id}  onClick={() => handleSelect(crew._id)} style={{ cursor: 'pointer' }}>
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={selected.includes(crew._id)}
                                // onChange={() => handleSelect(crew._id)}
                            />
                        </TableCell>
                       
                        <TableCell>{crew.number ? `Crew #${crew.number}` : ''} {crew.name} {crew.foremanName ? `(${crew.foremanName})` : '' }</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </div>
  )
}

const DateChooser = ({ date, changeDate }) => {

  return (
    <TextField
      id="date"
      label="Date"
      type="date"
      fullWidth
      value={moment(date).format('YYYY-MM-DD') }
      onChange={ changeDate }
      InputLabelProps={{
        shrink: true,
      }}
    />
  )
}

export default PrintOptionModal;