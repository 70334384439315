import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import { Button, CircularProgress, Tooltip }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteDialog from './DeleteDialog'
import ProtectedButton from '../../actions/protectedButtons'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const DeleteComponent = (props) => {
  // console.log('DeleteComponent  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

  const updateFromDeleteDialog = async (toDelete) => {
  // console.log('Update from delet edialog', toDelete)
    if (toDelete) {
      setLoading(true)
      let data = {
        itemToDelete: props.item,
        functionToCall: props.deleteFunction
      }
      let url = `${props.url}/api/company/genericDeletion`
      const response = await axios.post(url, data)
    // console.log('Axios response', response.data)
      setShowDeleteDialog(false)
      setLoading(false)
      props.ifApproved(props.item)
    } else {
      setShowDeleteDialog(false)
    }

  }
  if (props.unprotected) {
    return (
      <>
        <DeleteButton {...props} setShowDeleteDialog={setShowDeleteDialog} />
        <DeleteDialog
            {...props}
            open={showDeleteDialog}
            closeModal={updateFromDeleteDialog}
          />
      </>
  
    )
  } else {
    return (
      <ProtectedButton type={props.type} kind="delete" perms={props.perms || []}>
        <DeleteButton {...props} setShowDeleteDialog={setShowDeleteDialog} />
        <DeleteDialog
            {...props}
            open={showDeleteDialog}
            closeModal={updateFromDeleteDialog}
          />
      </ProtectedButton>
  
    )
  }

}

const DeleteButton = (props) => {
// console.log('Delete button props:', props)
  
  const toggleDeleteDialog = () => {
    props.setShowDeleteDialog(true)
  }

  return (
    <Tooltip
      title="Delete">
        <IconButton
          color={props.color}
          onClick={toggleDeleteDialog}
        ><DeleteIcon /></IconButton>
      </Tooltip>
  )
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      perms: state.perms
    }
  }
  
  export default connect(mapStateToProps)(DeleteComponent)