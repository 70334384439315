import React from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { connect } from 'react-redux'
import axios from 'axios'
import CustomMarker from './CustomMarker'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import ReactDOM from 'react-dom';
import { CircularProgress }  from '@material-ui/core';

const containerStyle = {
  width: '100%',
  height: '90vh'
};

const center = {
  lat: 33.81972977616888,
  lng: -111.9539555679331
};

const position = {
  lat: 33.7,
  lng: -111.97
}

const onLoad = marker => {
// console.log('marker: ', marker)
}
// const image = "https://dflportal.com/favicon.png"
const dfl = "https://dflportal.com/favicon.png"
const benhart = "https://www.benhartlandscaping.com/wp-content/themes/benhart/img/icons/favicon-16x16.png"

const Sidebar = (props) => {
  let markers = props.markers
  const loading = props.loading
  // console.log('SIde bar: ', markers)
  return (
    <div style={{ overflowY: 'scroll', padding: 10, width: '100%', height: '90vh', zIndex: 9999, backgroundColor: '#bbb'}} >
      <Grid container spacing={2}>
        <Grid item lg={12} xs={12}>
          <h2>Location Data: { loading ? 'Fetching Data' : `(${markers.length} Items)` }</h2>
        </Grid>
        <Grid item lg={12} xs={12}>
          {
            loading ? <CircularProgress size={64} />
             : (
            markers.map((item, index) => {
              // console.log('Sidebar Marker', item)
              let isActive = false
              let color = '#bbb'
              if (item._id === props.activeMarker._id) {
                isActive = true
                color = '#153'
                // console.log('Search the DOM for this item!!')
                // let ele = ReactDOM.findDOMNode(this) // .getBoundingClientRect()
                // console.log('ELE: ', ele)
              }
              let longLat = getOurLongAndLat(item)
              return (
                <div key={index} onMouseEnter={e => props.setActiveMarker(item)} style={{ backgroundColor: color, marginBottom: 10 }}>
                  <Grid container>
                    <Grid item lg={6}>{item.crewName} ({item.user})</Grid>
                    <Grid item lg={6}>{moment(item.timestamp).format('MM-DD-YYYY hh:mma')}</Grid>
                    <Grid item lg={3}>
                      Speed: 
                    </Grid>
                    <Grid item lg={3}>
                      {item.speed ? parseFloat(item.speed * 2.23694).toFixed(2) : 0}
                    </Grid>
                    <Grid item lg={3}>
                      Heading: 
                    </Grid>
                    <Grid item lg={3}>
                      {item.heading ? parseFloat(item.heading).toFixed(2) : null }
                    </Grid>
                    <Grid item lg={3}>
                      Latitude: 
                    </Grid>
                    <Grid item lg={3}>
                      {longLat.lat ? parseFloat(longLat.lat).toFixed(8) : null }
                    </Grid>
                    <Grid item lg={3}>
                      Longitude: 
                    </Grid>
                    <Grid item lg={3}>
                      {longLat.lng ? parseFloat(longLat.lng).toFixed(8) : null }
                    </Grid>
                  </Grid>
                </div>
              )
            })
          )
          }
        </Grid>
      </Grid>
    </div>
  )
}

const getOurLongAndLat = (addy) => {
  let long = addy.lng ? addy.lng : addy.long ? addy.long : addy.longitude ? addy.longitude : null
  let lat = addy.lat ? addy.lat : addy.latitude ? addy.latitude : null
  if (long && lat) {
    return {
      lat: lat,
      lng: long
    }
  } else {
    console.log('Do not have valid coordinates on this one', addy)
    return null
  }
}

const colors = ['#ACCACD', '#1c801f', '#84d1e0', '#84e0b0', '#bbe084', '#689623', '#239670']
function MyComponent(props) {
  const [markers, setMarkers] = React.useState([])
  const [crews, setCrews] = React.useState([])
  const [state, setState] = React.useState({
    pending: false
  })
  const [activeMarker, setActiveMarker] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  // const dfl = `${props.url}/pickupTruck.svg`
  // const benhart = "https://www.benhartlandscaping.com/wp-content/themes/benhart/img/icons/favicon-16x16.png"

  React.useEffect(() => {
    let isMounted = true
    let params = props.location.search
    let crewId = null
    // console.log('Params: ', params)
    if (params) {
      let stuff = params.split('?')
      let val = stuff[1]
      val = val.split('=')
      val = val[1]
      // console.log('Value: ', val)
      if (val) crewId = val
    }
    const fetchMarkers = async () => {
      console.log('Fetch Markers')
      setLoading(true)
      if (props.location && props.location.search) {

      }
      const response = await axios.get(`${props.url}/api/crews/loadAllLocationDataForCrew?crew=${crewId}`)
      setLoading(false)
      console.log(response.data)
      if (response.data && response.data.cleanLocationData && response.data.cleanLocationData.length) {
        // console.log('Marker Data: ', response.data)
        console.log('Using clean data', response.data.cleanLocationData)
        let gpsAggregate = response.data.gpsAggregate[0]['data']
        console.log('GPS Agg', gpsAggregate)
        setMarkers(gpsAggregate)
      } else {
        setMarkers([])
      }
    }

    fetchMarkers()

    return () => {
      isMounted = false
    }
  }, [])


  // const OurCrews = () => {
  // // console.log('Get our crew data...', crews)
  //   if (crews && crews.length) {
  //     let markersToUse = []
  //     crews.map(crew => {
  //     // console.log('Crew to work on: ,', crew)
  //       // Get the last 5 checkins for this guy
  //       let data = crew.data.splice(crew.data.length - 5, crew.data.length)
  //     // console.log('Data to use: ', data)
  //       markersToUse = markersToUse.concat(data)
  //     })
  //   // console.log('We have %d total markers!!', markersToUse)
  //     setMarkers(markersToUse)
  //     return markersToUse
  //   } else {
  //     return <span>Nothing to Show</span>
  //   }
  // }

  const updateActiveMarker = (d) => {
  // console.log('UPdate Active Marker: ', d)
    setActiveMarker(d)
  }
  const OurMarkers = ({ markers }) => {
    // console.log('Generate our Markes', markers.length)
    let icon = dfl
    if (markers && markers.length) {
      // console.log('Active Marker', activeMarker)
      let ourMarkers = []
      markers.map(locItem => {
        // console.log('Marker addy: 180', locItem)
        // if (addy && addy.entity === '610a290f45ef7427f94b2e78') {
        //   icon = benhart
        // }
        let longLat = getOurLongAndLat(locItem)
        let addy = {
          ...locItem,
          lat: longLat.lat,
          lng: longLat.lng
        }
        if (addy && addy.lat && addy.lng) {
          let markerPosition = {
            lat: Number(addy.lat),
            lng: Number(addy.lng),
            heading: Number(addy.heading)
          }
          // console.log(markerPosition)
          let color = '#214'
          if (activeMarker._id === addy._id) {
            color = '#832'
            // console.log('Change that color!!', color)
          }

          // console.log('COLOR: ', color)
          // console.log(activeMarker)
          ourMarkers.push(
            <CustomMarker
            onLoad={onLoad}
            key={addy._id}
            position={markerPosition}
            icon={icon}
            addy={addy}
            activeMarker={activeMarker}
            updateActiveMarker={updateActiveMarker}
          />
          )
        } else {
          console.log('Ignoring this...')
          return null
        }
      })
      return ourMarkers
    } else {
      return (
        <CustomMarker
          onLoad={onLoad}
          position={position}
          icon={icon}
        />
      )
    }
  }

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GMAPKEY}
    >
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          Choose Date Range
        </Grid>
        <Grid item lg={9} xs={9} md={9} sm={9}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={11}
          >
            { /* Child components, such as markers, info windows, etc. */ }
            <>
            { (markers && markers.length) ? <OurMarkers markers={markers} /> : null }
            </>
          
          </GoogleMap>
        </Grid>
        <Grid item lg={3} xs={3} md={3} sm={3}>    
          <Sidebar stuff markers={markers} setActiveMarker={setActiveMarker} activeMarker={activeMarker} loading={loading} />
        </Grid>
      </Grid>

    </LoadScript>
  )
}

// export default React.memo(MyComponent)
const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(React.memo(MyComponent))

// import React from 'react'
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
// import { Button, CircularProgress }  from '@material-ui/core';
// const options = {
//   zoomControlOptions: {
//     position: 'right-center',
//     // ...otherOptions
//   }
// }

// function MyComponent() {
//   const { isLoaded, loadError } = useJsApiLoader({
//     googleMapsApiKey: {process.env.REACT_APP_GMAPKEY}// ,
//     // ...otherOptions
//   })

//   const renderMap = () => {
//   // console.log('Render Map')
//     // wrapping to a function is useful in case you want to access `window.google`
//     // to eg. setup options or create latLng object, it won't be available otherwise
//     // feel free to render directly if you don't need that
//     const onLoad = () => {
//     // console.log('On Load')
//       function onLoad (mapInstance) {
//         // do something with map Instance
//       // console.log('On Load....')
//       }
//     }
//     return <GoogleMap
//       options={options}
//       onLoad={onLoad}
//       style={{ height: '90vh', width: '100vw'}}
//     >
//       {
//         // ...Your map components
//       }
//     </GoogleMap>
//   }

//   if (loadError) {
//     return <div>Map cannot be loaded right now, sorry.</div>
//   }

//   return isLoaded ? renderMap() : <CircularProgress />
// }

// export default React.memo(MyComponent)
