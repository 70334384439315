import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Box, Typography } from '@material-ui/core';

const CustomLinearProgress = withStyles((theme) => ({
  bar1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bar: {
    borderRadius: 3,
    backgroundColor: (props) => {
      if (props.value < 66) {
        return 'green';
      } else if (props.value >= 66 && props.value <= 81) {
        return 'yellow';
      } else if (props.value >= 81 && props.value <= 99) {
        return 'orange';
      } else {
        return 'red';
      }
    }
  },
  barText: {
    position: 'absolute',
    color: 'red',
    zIndex: 99999
  }
}))(LinearProgress);

function LinearProgressWithLabel(props) {
  // let normalizedNum = props.value * 3
  let normalizedValue = (props.value / 45) * 100
// console.log('Normalized:', normalizedValue)
  return (
    <Box display="flex" flexDirection="column" alignItems="center" >
      <Box width="100%" mr={1} style={{ minHeight: 25, position: 'relative' }}>
        <CustomLinearProgress variant="determinate" value={normalizedValue > 99 ? 100 : normalizedValue} style={{ minHeight: 25, height: '100%' }} />
        <Typography variant="body2" style={{
            position: "absolute",
            color: "black",
            fontWeight: 800,
            top: 0,
            left: "50%",
            transform: "translateX(-50%)",
          }}>{`${Math.round(
          props.value,
        )} Days`}</Typography>
      </Box>
      <Box>
        <Typography variant="body2" color="textSecondary">{`Avg Days To Pay: ${Math.round(
          props.value,
        )}`}</Typography>
      </Box>
    </Box>
  );
}

export default withStyles((theme) => ({
  bar1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bar: {
    borderRadius: 3,
    backgroundColor: (props) => {
      if (props.value < 28) {
        return 'green';
      } else if (props.value >= 28 && props.value <= 37) {
        return 'yellow';
      } else if (props.value >= 38 && props.value <= 59) {
        return 'orange';
      } else {
        return 'red';
      }
    },
    minHeight: 20,
    height: '100%'
  },
  barText: {
    position: 'absolute',
    color: theme.palette.common.white,
  }
}))(LinearProgressWithLabel);
