import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';

const AbsenteeModal = (props) => {

  const [state, setState] = React.useState({
    absenceType: 'off',
    notes: ''
  })
  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleSubmit = () => {
    let obj = {
      employee: props.employee,
      absenceType: state.absenceType,
      notes: state.notes,
      user: props.user
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/employees/submitAbsence`,
      data: obj
    }).then(response => {
    // console.log('Absence Reported', response.data)
      props.notification({
        type: 'success',
        title: 'Absence Recorded',
        message: `This absence has been recorded and ${props.employee.firstName} has been Clocked-Out and Checked-Out`
      })
      props.updateParent('absent', state.absenceType)
    }).catch(err => {
    // console.log('Error reporting absence', err)
      props.notification({
        type: 'warning',
        title: 'Error Saving Absence',
        message: `Error Saving Absence (Error Code: AM50)`
      })
    })
  }
return (
  <div>
    
      <Dialog
        // fullScreen={false}
        // maxWidth={'lg'}
        // width='lg'
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Report Absence -  {props.employee.displayName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please choose the type of absence for {props.employee.firstName} from the list below:
          </DialogContentText>
          <FormControl fullWidth style={{marginBottom: '20px'}}>
                <InputLabel id="demo-simple-select-helper-label">Type of Absence</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={state.absenceType}
                  onChange={(e) => setState({...state, absenceType: e.target.value })}
                >
                  <MenuItem value={'approved'}>Approved PTO</MenuItem>
                  <MenuItem value={'vacation'}>Vacation</MenuItem>
                  <MenuItem value={'off'}>Called/Off</MenuItem>
                  <MenuItem value={'nocall'}>No-Call, No-Show</MenuItem>
                </Select>
                <FormHelperText>Absence Type (PTO, Sick-Time, No-Call, No-Show)</FormHelperText>
              </FormControl>
              <TextField
                id="notes"
                label="Notes"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                // className={classes.textSaveLineBreaks}
                value={state.notes}
                onChange={e => setState({...state, notes: e.target.value})}
                // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                // defaultValue="Default Value"
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit()} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(AbsenteeModal)