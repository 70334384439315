import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import ProtectedButton from '../../actions/protectedButtons'
const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const defaultValues = {
  onSite: null,
  offSite: null,
  hoursWorked: 0,
  travelTime: 0,
  notes: ''
}

const EditServiceVisitModal = (props) => {
// console.log('EDIT SERVICE VISIT MODAL Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState(defaultValues)
  const [lastState, setLastState] = React.useState({
    onSite: null,
    offSite: null
  })
  const inputRef = React.useRef(null)

  React.useEffect(() => {
    // console.log('EDIT SERVICE VISIT MODAL USE EFFECT', props.serviceVisit)
    if (props.serviceVisit && props.serviceVisit._id) {
      // console.log('Set our shit for the visit:', props.serviceVisit)
      let onSite = moment(props.serviceVisit.onSite, 'YYYY-MM-DDThh:mm:ss.000Z')
      let offSite = moment(props.serviceVisit.offSite, 'YYYY-MM-DDThh:mm:ss.000Z')
      // console.log('SETTING ONSITE; ', onSite)
      // console.log('Setting offsite: ', offSite)
      setState({...state, notes: props.serviceVisit.notes, offSite: offSite, onSite: onSite, hoursWorked: props.serviceVisit.hoursWorked, travelTime: props.serviceVisit.travelTime })
    }
  }, [props.serviceVisit])

  const handleDelete = () => {
    if (window.confirm(`Are You Sure You Wish To Delete This Service Visit? This is not a reversible decision...please choose wisely.`)) {
      // console.log('Delete it:')
      setLoading(true)
      // setClient({})
      // setState({...state, note})
      if (props.serviceVisit._id) {
        let data = {
          serviceVisitId: props.serviceVisit._id
        }
        axios({
          method: 'post',
          url:  `${props.url}/api/company/deleteServiceVisit`,
          data: data
        }).then(response => {
          setLoading(false)
          if (response.data && response.data) {
            if (response.data.shifts) {
              // console.log('Got updated shifts post delete:', response.data.shifts)
            }
            if (response.data.serviceVisit) {
              props.notification({
                type: 'success',
                title: 'Service Visit Deleted',
                message: 'That Service Visit Has Been Deleted Forever!'
              })
              props.updateParent(response.data, 'delete')
            }
  
            // props.closeModal()
          }
        })
      } else {
        props.updateParent({ serviceVisit: props.serviceVisit }, 'delete')
      }
    }
  }
  const handleSave = () => {
    // console.log(inputRef)
    // console.log('save: ', state)
    setLoading(true)
    // setClient({})
    // setState({...state, note})
    let data = {
      serviceVisitId: props.serviceVisit._id,
      details: state
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/company/editServiceVisit`,
      data: data
    }).then(response => {
      setLoading(false)
      if (response.data) {
        if (response.data.shifts) {
          // console.log('Got updates shifts post edit', response.data.shifts)
        }
        if (response.data.serviceVisit) {
          props.notification({
            type: 'success',
            title: 'Service Visit Updated',
            message: 'That Service Visit Has Been Saved!'
          })
          props.updateParent(response.data)
        }

        // props.closeModal()
      }
    })
  }
  
  const updateRef = (e) => {
    inputRef.current = e.target.value
  }

  const handleCloseModal = () => {
    props.closeModal()
  }

  const checkOurTimes = () => {
    // console.log('On-Site/Off-Site: ', state)
    // console.log('Last State: ', lastState)
    if (state.offSite < state.onSite) {
      props.notification({
        type: 'warning',
        title: `Uh Oh - That Doesn't Seem Right!`,
        message: 'Your On-Site Time Was After The Clock-Out - Please Fix Them!'
      })
      // console.log('Current OnSite: ', state.onSite)
      // console.log('Set OnSite: ', lastState.onSite)
      // console.log('Current OffSite', state.offSite)
      // console.log('Set OffSite: ', lastState.offSite)
      // setState({...state, offSite: lastState.offSite, onSite: lastState.onSite, hoursWorked: lastState.hoursWorked })
    } 
  }

  const handleFocus = (event) => event.target.select();

  const handleTimeChange = (type, value) => {
    // console.log('Type: ', type)
    // console.log('TIME CHANGE EDIT')
    let isValid = moment(value, 'YYYY-MM-DDThh:mm').isValid()
    // console.log('Valid? ', isValid)
    // console.log('Value: ', value)
    // lastState.current.onSite = state.onSite
    // lastState.current.offSite = state.offSite
    // let lastTimes = {
    //   onSite: moment(state.onSite, 'YYYY-MM-DDThh:mm').local(),
    //   offSite: moment(state.offSite, 'YYYY-MM-DDThh:mm').local(),
    //   hoursWorked: state.hoursWorked
    // }
    // console.log('Set Last Times: ', lastTimes)
    // setLastState(lastTimes)
    // console.log('CUrrent onsite:', state.onSite)
    // console.log('Current offiste: ', state.offSite)
    if (isValid) {
      // console.log('Value: ', value)
      let onSite = null
      let offSite = null
      if (type === 'onSite') {
        // console.log('259')
        onSite =  moment(value, 'YYYY-MM-DDThh:mm').local()
        // offSite = moment(state.offSite, 'YYYY-MM-DDThh:mm').add(1, 'hours').local()
        if (state.offSite) {
          // console.log('263')
          offSite =  moment(state.offSite, 'YYYY-MM-DDThh:mm').local() // moment(state.offSite, 'YYYY-MM-DDThh:mm').local()
        } else {
          // console.log('266')
          offSite = moment(value, 'YYYY-MM-DDThh:mm').add(1, 'hours').local()
        }
  
      }
      if (type === 'offSite') {
        // console.log('272')
        onSite = moment(state.onSite, 'YYYY-MM-DDThh:mm').local()
        offSite = moment(value, 'YYYY-MM-DDThh:mm').local()
      }
      // console.log('onSite IN', onSite)
      // console.log('offSite out: ', offSite)
      let hoursWorked = Math.abs(onSite - offSite) / 36e5
      // console.log('Hours WOrked: ', hoursWorked)
      hoursWorked = parseFloat(hoursWorked).toFixed(2)
      // console.log('On Site: ', onSite)
      // console.log('Off Site:', offSite)
      // console.log('Horus WOrked: ', hoursWorked)
      // console.log(hoursWorked)
      // if (clockOut < clockIn && state.weWantThis) {
      //   if (window.confirm('Your Clock-Out Time Is Before the Clock-In Time...Should We Reverse Them?')) {
      //     // console.log('Reverse em!')
      //     setState({...state, clockIn: clockOut, clockOut: clockIn, hoursWorked: hoursWorked})
      //     props.notification({
      //       type: 'warning',
      //       title: `Uh Oh - That Doesn't Seem Right!`,
      //       message: 'Your Clock-In Time Was After The Clock-Out - So We Reversed Them!'
      //     })
      //   } else {
      //     setState({...state, clockIn: clockIn, clockOut: clockOut, hoursWorked: 0})
      //   }
      // } else {
        // if (offSite < onSite) {
        //   props.notification({
        //     type: 'warning',
        //     title: `Uh Oh - That Doesn't Seem Right!`,
        //     message: 'Your On-Site Time Was After The Clock-Out - Please Fix Them!'
        //   })
        // } else {
          // console.log('NEW ONSITE', onSite)
          // console.log('New offsite:', offSite)
         setState({...state, onSite: onSite, offSite: offSite, hoursWorked: hoursWorked})
        // }
        
      // }
    }
  }

  const EmployeeInfo = ({ employees }) => {
    // console.log('Employees: ', employees)
    // return <><br />Test</>
    return (
      <DialogContentText style={{ display: 'flex', flexDirection: 'column', fontSize: 12, color: '#bbb'}}>
          {
            employees.map(item => {
              return <span>{item.firstName} {item.lastName}</span>
            })
          }
      </DialogContentText>
    )
  }
  
  return (

      <Dialog open={props.open} fullWidth={true} onClose={handleCloseModal} maxWidth='md' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title" >
            <span style={{alignSelf: 'flex-start'}}>Edit Service Visit</span>
            <span style={{marginTop: -10, display: 'flex', justifyContent: 'flex-end', alignSelf: 'flex-end'}}>
                <ProtectedButton type="Jobs" kind="delete" perms={props.perms}>
                  <IconButton
                      size="small"
                      edge="end"
                      onClick={handleDelete}
                      >
                      <DeleteIcon />
                    </IconButton>
                </ProtectedButton>
            </span>
            
            
          </DialogTitle>
          <DialogContent >
            <DialogContentText>Employees Included On Service Visit:</DialogContentText>
              {
                props.serviceVisit.emps ? <EmployeeInfo employees={props.serviceVisit.emps} /> : null 
              }
            <Grid container>
            <Grid item xs={12} lg={6}>
            <TextField
              id="date"
              label="On-Site"
              type="datetime-local"
              value={moment(state.onSite).format('YYYY-MM-DDTHH:mm') } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              // onBlur={checkOurTimes}
              onChange={e => handleTimeChange('onSite', e.target.value)}
              // onChange={e => setState({...state, clockIn: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />
            </Grid> 
            <Grid item xs={12} lg={6}>
           <TextField
              id="date"
              label="Off-Site"
              type="datetime-local"
              fullWidth
              // onBlur={checkOurTimes}
              value={moment(state.offSite).format('YYYY-MM-DDTHH:mm') } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              // onChange={e => setState({...state, clockOut: e.target.value })}
              onChange={e => handleTimeChange('offSite', e.target.value)}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              style={{marginBottom: '20px'}}
            />
            </Grid>
            <Grid item xs={12} lg={6}>
            <TextField
              id="hoursWorked"
              label="Hours Worked"
              type="text"
              disabled
              value={state.hoursWorked } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              // onChange={e => setState({...state, hoursWorked: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />
            </Grid>
            <Grid item xs={12} lg={6}>
            <TextField
              id="hoursWorked"
              label="Travel Time (Minutes)"
              type="text"
              value={state.travelTime } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              onFocus={handleFocus}
              onChange={e => setState({...state, travelTime: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />
             </Grid>    
            </Grid>
            <DialogContentText>
              Please Add Any Relevant Notes Below:
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="name"
                  label="Service Visit Notes"
                  // style={{ margin: 8 }}
                  value={state.notes}
                  // onChange={updateRef}
                  onChange={e=> setState({...state, notes: e.target.value})}
                  // ref={inputRef['projectName']}
                  placeholder="Service Visit Notes"
                  helperText="What Notes Would You Like To Leave?"
                  fullWidth
                  multiline
                  rows={4}
                  // value={inputRef.current['projectName']}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Grid>
          
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              Cancel
            </Button>
            <Button color="primary" disabled={loading} onClick={handleSave}>
              {
                loading ? <CircularProgress /> : 'Save'
              }
            </Button>
          </DialogActions>
        </Dialog>
  
  )
}


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      perms: state.perms
    }
  }
  
  export default connect(mapStateToProps)(EditServiceVisitModal)