import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
// import ServiceDetailList from './ServiceDetailList'
// import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import { Link } from 'react-router-dom'
import { setClient } from '../../actions/connectionActions'
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CustomerDetail from '../Customer/CustomerDetail'
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  }
}));

const DisplayItem = (props) => {
  let item = props.item
  // {`${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}` }
  // {item.email ? `   ${item.email}` : null}
  // {(item.phoneNumbers && item.phoneNumbers.length) ? <PhoneNumbers numbers={item.phoneNumbers} /> : null}
  // {item.invoiceNumber ? `Invoice Number: ${item.invoiceNumber} (Amt: ${currencyFormat(item.amount)} - Bal: ${currencyFormat(item.balance)})` : '' }
  // {item.jobName ? `  -  Job Number: ${item.jobNumber} Job Name: ${item.jobName}` : ''}
  // {item.accountNumber ? `${item.accountType ? item.accountType : ''} ${item.accountNumber}` : ''}
  // {item.notes ? `${item.notes}` : ''}
  // {item.name ? item.name : ''} {item.number ? `   #${item.number}` : '' }
  // {item.totalPrice ? `
  // Total Price: ${currencyFormat(item.totalPrice)}` : ''}
  // { item.address ? `${item.address} ${item.address2 ? item.address2 : ''} ${item.city ? `${item.city},` : ''} ${item.state ? item.state : ''}` : '' }
  let itemsToList = []
  if (item.firstName && item.lastName) {
    itemsToList.push({
      type: 'name',
      value: `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}`
    })
  }
  if (item.email) {
    itemsToList.push({
      type: 'email',
      value: item.email
    })
  }
  return (
    <div>
      {
        itemsToList.map((line, index) => {
          return (
            <p>
              {line.value}
            </p>
          )
        })
      }
    </div>
  )
}
function currencyFormat(num) {
  // console.log('Currencyformat: ', num)
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const tableOptions = [
  'Choose Search Type',
  'Contacts',
  'Customer',
  'Job Site',
  'Deposits',
  'Estimates',
  'Invoices',
  'Payments',
  'Workorders',
  'Proposal Line Items'
]

const fieldOptions = {
  'Contacts': {
    fields: [
      'Phone Number',
      'First Name',
      'Last Name',
      'Email Address'
    ]
  },
  'Customer': {
    fields: [
      'Name'
    ]
  },
  'Job Site': {
    fields: [
      'Name',
      'Address',
      'City'
    ]
  },
  'Deposits': {
    fields: [
      // 'Customer Name',
      'Invoice Number',
      'Amount',
      'Date',
      'Check Number'
    ]
  },
  'Estimates': {
    fields: [
      'Estimate Name',
      'Estimate Number',
      'Amount',
      // 'Date',
      // 'Check Number'
    ]
  },
  'Invoices': {
    fields: [
      // 'Customer Name',
      'Invoice Number',
      'Amount',
      // 'Date',
      'Check Number'
    ]
  },
  'Payments': {
    fields: [
      // 'Customer Name',
      'Invoice Number',
      'Amount',
      // 'Date',
      'Check Number'
    ]
  },
  'Workorders': {
    fields: [
      'Job Name',
      'Work Order Number',
    ]
  }
}

const MatchingLineItems = (props) => {
// console.log('Matching items!!')
  if (props.list && props.list.length) {
  // console.log('List:', props.list)
    return (
      <ul>
        {
          props.list.map((item, index) => {
          // console.log('The item>.', item)
            return (
            <li key={index}>{item.qty} - {item.unit ? item.unit.name ? item.unit.name : item.unit : null} {item.description} - {item.costPer ? `(${item.costPer} cper/${item.pricePer ? item.pricePer : ''} per)` : null} {item.totalPrice} - <span style={{ color: 'red' }}>{item.internalNotes}</span></li>
            )
          })
        }
      </ul>
    )

  } else {
    return null
  }
}
const PhoneNumbers = (props) => {
  // console.log('numbers;', props.numbers)
  if (props.numbers && props.numbers.length) {
    return (
      props.numbers.map(item => {
        return (
          <div>{item.number} - {item.type}</div>
        )
      })
    )
  } else {
    return <></>
  }
}
const SearchEngine = (props) => {
  // console.log('SearchEngine ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState('')
  const [searchTable, setSearchTable] = React.useState(null)
  const [client, setClient] = React.useState({})
  const [fieldSearchOptions, setFieldSearchOptions] = React.useState(null)
  const [fieldChosen, setFieldChosen] = React.useState(null)
  const [list, setList] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0,
    searchTable: tableOptions[0],
    fieldChosen: ''
  })
  const [showCustomerDetails, setShowCustomerDetails] = React.useState(false)
  const inputRef = React.useRef({})

  React.useEffect(() => {
  // console.log('Search ENgine Use Effect...inpurtref: ', inputRef.current)
  }, [props])

  React.useEffect(() => {
    if (searchTable && searchTable !== tableOptions[0]) {
    // console.log('UPdate oru filed options, ', searchTable)
      setFieldSearchOptions(fieldOptions[searchTable])
      if (fieldOptions[searchTable] && fieldOptions[searchTable].fields && fieldOptions[searchTable].fields.length === 1) {
      // console.log('Set field')
        setState({...state, fieldChosen: fieldOptions[searchTable].fields[0]})
      } else {
        setState({...state, fieldChosen: 'Choose A Field'})
      }
    }
  }, [searchTable])

const handleChange = (e) => {
  setSearchValue(e.target.value)
}

const handleChooseTable = (e) => {
  // console.log('sfda')
  setSearchTable(e.target.value)
  setFieldChosen(null)
  setState({...state, searchTable: e.target.value })
}

const handleChooseField = (e) => {
  // setSearchTable(e.target.value)
  setFieldChosen(e.target.value)
  setState({...state, fieldChosen: e.target.value })
}

const handleSearch = () => {
  setList([])
  let data = {
    table: searchTable,
    value: searchValue,
    field: fieldChosen
  }
  // console.log('Search: ', data)
  axios({
    method: 'post',
    url:  `${props.url}/api/company/searchEngineRequest`,
    data: data
  }).then(response => {
    // setLoading(false)
    if (response.data && response.data.docs && response.data.docs.length) {
      // inputRef.current.list = response.data.docs
      // inputRef.current.matches = response.data.matches
      setList(response.data.docs)
    // console.log(response.data)
      props.notification({
        type: 'success',
        title: 'Search Complete',
        message: 'Here Are Your Results'
      })
    } else {
      props.notification({
        type: 'warning',
        title: 'Search Failed',
        message: 'We Were Unable To Find Any Matching Results'
      })
    }
  })
}

const handleSelectClient = (client) => {

}

const handleOpenClientPage = (client) => {
// console.log('Open Clien tPage:", client', client)
  setClient(client)
  props.setClient(client)
  setShowCustomerDetails(true)
  // props.location.push(`/clients/${client._id}`)
}

const handleCloseCustomerDetailModal = () => {
  setShowCustomerDetails(false)
}

const handleKeyDownEvent = (e) => {
  // e.preventDefault()
  // console.log('Key Down!!!', e)
  // console.log(e.keyCode)
  if (e.keyCode == 13) {
    // console.log('ENTER')
    e.preventDefault()
    handleSearch()
  }
}

const FieldSelection = ({ table }) => {
  if (fieldSearchOptions && fieldSearchOptions.fields && fieldSearchOptions.fields.length) {
  // console.log('Fields: ', fieldSearchOptions.fields)
    // if (fieldSearchOptions && fieldSearchOptions.fields && fieldSearchOptions.fields.length && fieldSearchOptions.fields.length > 1) {
    //   return (
    //     <Select
    //     labelId="demo-simple-select-outlined-label"
    //     id="demo-simple-select-outlined"
    //     value={state.fieldChosen}
    //     fullWidth
    //     style={{
    //       marginTop: '16px',
    //       marginBottom: '8px'
    //     }}
    //     variant="outlined"
    //     // onChange={handleChooseTable}
    //     label="Project Status"
    //   >
    //     {
    //       fieldSearchOptions.fields.map(unit => {
    //       // console.log('FIELD: ', unit)
    //         return (
    //         <MenuItem key={unit} value={unit} >{unit}</MenuItem>
    //       )})
    //     }
    //   </Select>
    //   )
    // } else {
      return (
        <TextField
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={state.fieldChosen}
        fullWidth
        style={{
          marginTop: '16px',
          marginBottom: '8px'
        }}
        select
        variant="outlined"
        onChange={handleChooseField}
        label="Choose Field"
      >
        {
          fieldSearchOptions.fields.map(unit => {
            // console.log('FIELD: ', unit)
            return (
            <MenuItem key={unit} value={unit} >{unit}</MenuItem>
          )})
        }
      </TextField>
      )
    // }
  }
  return null
  // return (
  //   <Select
  //   labelId="demo-simple-select-outlined-label"
  //   id="demo-simple-select-outlined"
  //   value={state.searchTable}
  //   fullWidth
  //   style={{
  //     marginTop: '16px',
  //     marginBottom: '8px'
  //   }}
  //   variant="outlined"
  //   onChange={handleChooseTable}
  //   label="Project Status"
  // >
  //   {
  //     tableOptions.map(unit => {
  //       return (
  //       <MenuItem key={unit} value={unit} >{unit}</MenuItem>
  //     )})
  //   }
  // </Select>
  // )
}

return (
  <div className={classes.root}>
    <CustomerDetail open={showCustomerDetails} client={client} closeModal={handleCloseCustomerDetailModal} />
   <Grid container>
     
     <Grid item lg={6} md={6} sm={12} xs={12}>
 
                {/* <div >
                  <SearchIcon /> Search { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
                </div> */}
                {/* <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input> */}
                <TextField
                    name="search" 
                    value={ searchValue } 
                    onChange={ handleChange } 
                    type="text"
                    label="Search Input"
                    type="search"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                    onKeyDown={handleKeyDownEvent}
                />
                {/* <div className={classes.fakeLink} onClick={toggleDeepSearch}>Go Deeper</div> */}
           
     </Grid>
     <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          labelid="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.searchTable}
          fullWidth
          select
          style={{
            marginTop: '16px',
            marginBottom: '8px'
          }}
          variant="outlined"
          onChange={handleChooseTable}
          label="Choose Search Type"
        >
          {
            tableOptions.map(unit => {
              return (
              <MenuItem key={unit} value={unit} >{unit}</MenuItem>
            )})
          }
        </TextField>
      </Grid>
      {/* <Grid item lg={4} md={4} sm={12} xs={12}>
          {
            (state.searchTable && state.searchTable !== tableOptions[0]) ? 
            (
              <FieldSelection table={state.searchTable} />
            ) : null
          } 
      </Grid> */}
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSearch}
          disabled={!searchValue || !searchTable}
        >
          Search Database
        </Button>
      </Grid>
     
      <Grid item lg={12} xs={12}>
        <span style={{fontSize: '1.2em', color: '#bbb', textAlign: 'center', padding: 10}}>{(list && list.length) ? `Found ${list.length} Matches` : ''}</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        {(list && list.length) ? (
          <div style={{maxHeight: 450, overflowY: 'scroll'}} >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='small'
              aria-label="enhanced table"
            >
              <TableHead>
                <TableRow>
                {/* <TableCell>
                    Select
                  </TableCell> */}
                  <TableCell>
                    Client Name (AG ID)
                  </TableCell>
                  <TableCell>
                    Other Information
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { (list && list.length) ? list.map(item => {
                  // console.log('585  ....', item)
                  let client = null
                  if (item && item.client && item.client._id) {
                    client = item.client
                  } else {
                    client = item
                  }
                  let linkPath = '/clients/' + client._id + '/' + state.searchTable + '/' + item._id
                  // console.log('State 593', state)
                  if (state.searchTable === 'Workorders') {
                    linkPath = '/clients/' + client._id + '/Job/' + item.job
                  }
                  // console.log('Link Path:', linkPath)
                  // console.log('ITEM::::  -- how do we find the job instead of the workorder', item)
                  // const client = item.client
                  return (
                    <TableRow key={item._id}>
                      {/* <TableCell>
                        <FormControlLabel
                          control={<Checkbox checked={client.selected} onChange={ e => handleSelectClient(client) } name="Selected" />}
                          label={client.selected ? 'Included' : 'Select'}
                        />
                    </TableCell> */}
                    <TableCell>
                      {/* <span style={{cursor: 'pointer', color: 'blue'}}
                        onClick={e => handleOpenClientPage(client)}>
                      {client.displayName} ({client.agId})
                      </span> */}

                   <Link 
                      style={{pointer: 'cursor'}}
                      target="_blank"
                      to={{
                            pathname: linkPath,
                            // search: '?sort=name',
                            // hash: '#the-hash',
                            state: { customer: client, type: state.searchTable, item: item, stuff: 'Search Engine' }
                          }}> {client.displayName} ({client.agId}) 
                          
                        </Link>
                        {
                            !client.active ? <Chip style={{color: '#fff', background: '#bbb', marginLeft: 10 }} size="small" label="Inactive" /> : null
                        }
                    </TableCell>
                    {/* <TableCell>
                      {client.defaultJobSiteAddress} ({client.address})
                    </TableCell> */}
                    <TableCell>
                      {/* <DisplayItem item={item} /> */}
                      {`${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''}` }
                      {item.email ? `   ${item.email}` : null}
                      {(item.phoneNumbers && item.phoneNumbers.length) ? <PhoneNumbers numbers={item.phoneNumbers} /> : null}
                      {item.invoiceNumber ? `Invoice Number: ${item.invoiceNumber} (Amt: ${currencyFormat(item.amount)} - Bal: ${currencyFormat(item.balance)})` : '' }
                      {item.jobName ? `  -  Job Number: ${item.jobNumber} Job Name: ${item.jobName}` : ''}
                      {item.accountNumber ? `${item.accountType ? item.accountType : ''} ${item.accountNumber}` : ''}
                      {item.notes ? `${item.notes}` : ''}
                      {item.name ? item.name : ''} {item.number ? `   #${item.number}` : '' }
                      {item.totalPrice ? `Total Price: ${currencyFormat(item.totalPrice)}` : ''}
                      {(item.matchingLines && item.matchingLines.length) ? <MatchingLineItems list={item.matchingLines} /> : '' }
                      { item.address ? `${item.communityName ? item.communityName : ''} ${item.address} ${item.address2 ? item.address2 : ''} ${item.buildingNumber ? `Bldg #${item.buildingNumber}` : ''} ${item.city ? `${item.city},` : ''} ${item.state ? item.state : ''}` : '' }
                    </TableCell>
                    
                  </TableRow>
                )}) : null}
              </TableBody>
            </Table>

          </div>
        ) : null }
      </Grid>
   </Grid>
   
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setClient: (client) => { dispatch(setClient(client)) }
    }
  }

  export default React.memo(connect(mapStateToProps, mapDispatchToProps)(SearchEngine))

  // export default React.memo(connect(mapStateToProps)(SearchEngine))