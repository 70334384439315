import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 5
  },
  flexItem: {
    flexGrow: 1,
    padding: 5
  },
  bigText: {
    fontSize: 16,
    color: '#bbb',
    margin: 5
  }
}))

const defaultValues = {
  percentage: 50,
  amount: 0
}

const DepositComponent = (props) => {
  // console.log('Deposit Component Update: ', props)
  const classes = useStyles();
  const [state, setState] = React.useState(defaultValues)
  const handlePercentChange = (e) => {
    if (e.target.value > 100) {
      setState({...state, percentage: 100})
      props.updatePercentage(100)
    } else {
      setState({...state, percentage: e.target.value})
      props.updatePercentage(e.target.value)
    }

  }

   

  return (
    <div className={classes.root}>
      <div className={classes.bigText}>How Much Is The Initial Deposit?</div>

          <TextField
            type="number"
            label="% of Total"
            variant="outlined"
            fullWidth
            value={state.percentage}
            onChange={handlePercentChange}
          />

    </div>
  )
}

export default DepositComponent