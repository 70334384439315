import React from 'react'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ChooseEntityComponent = (props) => {
  // console.log('Choose Entity Component', props)
  const [state, setState] = React.useState({
    entities: [{ name: 'None'}],
    activeEntity: {
      internalName: 'All Assigned',
      name: 'All Assigned'
    }
  })
  let variant = 'outlined'
  if (props.removeOutline) variant = 'standard'

  React.useEffect(() => {
    if (props.defaultEntity && props.defaultEntity._id) {
    // console.log('SEt the default entity!!!!', props.defaultEntity)
      setState({ ...state, activeEntity: props.defaultEntity})
    } else {
      setState({ ...state, activeEntity: null })
    }
  }, [props.defaultEntity])

  React.useEffect(() => {
    // console.log('Choose Entity....', props)
    if (props.user) {
    // console.log('ENtities; ', props.user.entities)
    // console.log('User Default Entity: ', props.user.defaultEntity)
      if (props.allowAll === true) {
        let availableEntities = []
        availableEntities.push({
          internalName: 'All Assigned',
          name: 'All Assigned'
        })
        availableEntities = availableEntities.concat(props.user.entities)
        let activeEnt = {
          internalName: 'All Assigned',
          name: 'All Assigned'
        }
        setState({...state, entities: availableEntities, activeEntity: activeEnt})
      } else {
        // console.log('45...', props)
        // let activeEnt = null
        // props.user.entities.map(item => { if (item._id === props.user.defaultEntity) activeEnt = item})
        if (props.user.defaultEntity) {
          // console.log('Default', props.user.defaultEntity)
          let theEntity = props.user.entities.find(entity => (entity._id === props.user.defaultEntity ))
          setState({...state, entities: props.user.entities, activeEntity: theEntity})
          props.updateParent(theEntity)
        } else {
          // console.log('Not default...', props.user.entities)
          setState({...state, entities: props.user.entities, activeEntity: props.user.entities[0] })
          props.updateParent(props.user.entities[0] || {})
        }
      }
    }

  }, [props.user, props.allowAll])

  const updateEntitiesToView = (e, entity) => {
    // console.log('Update entitiy', entity)
    // console.log('Current props', props)
    // console.log('Current state', state)
    let availableEntities = []
    availableEntities.push({
      internalName: 'All Assigned',
      name: 'All Assigned'
    })
    if (entity && entity._id) {
      setState({...state, activeEntity: entity})
      props.updateParent(entity)
    } else {
      availableEntities = availableEntities.concat(props.user.entities)
      let activeEnt = {
        internalName: 'All Assigned',
        name: 'All Assigned'
      }
      setState({...state, entities: availableEntities, activeEntity: activeEnt})
      props.updateParent(activeEnt)
    }
  }

  const EntityList = () => {
    return (
      <div>
        {/* {`${user.name} has ${user.entities.length} entities assigned.`}
        {JSON.stringify(user.entities)} */} 
        <Autocomplete
          id={`entityList`}
          options={state.entities}
          getOptionLabel = { (option) => {
            // console.log('Get Option Label', option)
            return option.name
          }}
          // style={{ width: 200 }}
          onChange={updateEntitiesToView}
          // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
          value = {state.activeEntity}
          disabled={props.disabled}
          // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
          renderInput={(params) => <TextField {...params} label={props.title} helperText={props.helperText || ''} variant={ variant } />}
          
        />
      </div>
    )

  }
  return (
    <>
      { state.entities.length > 1 ? (
        <EntityList />
      ) : (
        ''
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(ChooseEntityComponent))