import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios'
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress }  from '@material-ui/core';
import QtyEditModal from './EditItemQtyModal'
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchForItemModal from './SearchForItemModal'

const Container = styled(Paper)`
  min-height: 85vh;
  margin: 5px;
  && {
    color: grey;
    background-color: white;
  }
`
const Header = styled(Box)`
  text-align: center;
  font-size: 3em;
  color: rgb(119 156 164);
`;

const ScannerInput = styled(Grid)`
  background-color: grey;
  color: #fff;
  font-size: 2.5em;
  min-height: 50px;
  max-height: 80px;
  text-align: center;
`

const OptionPanelContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  justify-content: flex-end;
  align-items: space-between;
  flex-flow: flex-end;
  align-self: stretch;
`;

const SearchButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-self: flex-end;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  justify-content: space-between;
  align-items: space-between;
  align-self: stretch;
`;

const OrderDetails = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px;
  height: 400px;
  overflow-y: scroll;
  flex-wrap: wrap;
  border: 3px solid rgb(119 156 164);
  overflow: auto;
`;

const CheckoutArea = styled.div`
  width: 40%;
  margin-left: 25px;
`
const ScanDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px;
  flex-wrap: wrap;
  border: 3px solid rgb(119 156 164);
  overflow: auto;
`;

const OrderLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-item: flex-start;
  font-size: 2em;
  &:hover {
    color: #fff;
    background-color: rgb(172 202 205);
  }
  margin: 10px;
`

const LineItemDetail = styled.div`
  display: flex;
  &:hover {
    cursor: pointer;
    color: #232;
  }
`
const OrderSummaryArea = styled.div`
  margin: 15px;
`
const CrewDetailsArea = styled.div`
  margin: 10px;
  font-size: 1.2em;
`

const initialState = {
  inputValue: '',
  scan: '',
  crew: {
    displayName: ''
  }
}

const ShedView = (props) => {
  const [state, setState] = React.useState(initialState)
  const [loading, setLoading] = React.useState(false)
  const [ignoreKeyPress, setIgnoreKeyPress] = React.useState(false)
  const [showSearchModal, setShowSearchModal] = React.useState(false)
  const [order, setOrder] = React.useState([])
  const [item, setItem] = React.useState({
    name: ''
  })
  const [itemToEdit, setItemToEdit] = React.useState({})
  const [showEditDetails, setShowEditDetails] = React.useState(false)
  const [employees, setEmployeeList] = React.useState([])


  const fetchCrews = async () => {
    const response = await axios.get(`${props.url}/api/crews/listCrews`)
    // console.log('Crews: ', response.data)
    setEmployeeList(response.data.employees)
  }

  React.useEffect(() => {
    fetchCrews()
  }, [])
  
  const findItemInDatabase = async (sku) => {
  // console.log('FInd THis: ', sku)
    setLoading(true)
    const lastScan = state.scan
    const response = await axios.get(`${props.url}/api/inventory/findItem?sku=${sku}`)
  // console.log(response)
    setLoading(false)
    if (response && response.data && response.data._id) {
      if (response.data.bulkItem) {
        setItem(response.data.individualItem)
        let actualItem = response.data.individualItem
        let existingOrder  = order
      // console.log('Order so far: ', existingOrder)
        let index = existingOrder.findIndex(orderItem => (orderItem._id === actualItem._id ))
      // console.log('Index: ', index)
        if (index > -1) {
        // console.log('Already existss.....')
          let obj = existingOrder[index]
          let updatedQuantity = obj.orderQuantity - 0 + response.data.bulkQty
          obj.orderQuantity = updatedQuantity
          obj.quantity = obj.quantity - response.data.bulkQty
        // console.log('Object: ', obj)
          setOrder(existingOrder)
        } else {
          // let obj = response.data
          actualItem.orderQuantity = response.data.bulkQty
          actualItem.quantity = actualItem.quantity - response.data.bulkQty
        // console.log('Object: ', actualItem)
          existingOrder.push(actualItem)
        // console.log('Order updated: ', existingOrder)
          setOrder(existingOrder)
        }
      } else {
        setItem(response.data)
        let existingOrder  = order
      // console.log('Order so far: ', existingOrder)
        let index = existingOrder.findIndex(orderItem => (orderItem._id === response.data._id ))
      // console.log('Index: ', index)
        if (index > -1) {
        // console.log('Already existss.....')
          let obj = existingOrder[index]
          let updatedQuantity = obj.orderQuantity - 0 + 1
          obj.orderQuantity = updatedQuantity
          obj.quantity = obj.quantity - 1
        // console.log('Object: ', obj)
          setOrder(existingOrder)
        } else {
          let obj = response.data
          obj.orderQuantity = 1
          obj.quantity = obj.quantity - 1
        // console.log('Object: ', obj)
          existingOrder.push(obj)
        // console.log('Order updated: ', existingOrder)
          setOrder(existingOrder)
        }
      }
      setState({...state, scan: '', lastScan: lastScan, inputValue: ''})
    } else {
    // console.log('Invalid Barcode Scanned')
      props.notification({
        type: 'warning',
        title: 'Inventory Not Found!',
        message: 'This barcode ' + lastScan + ' is not in our system!'
      })
      setItem({})
      setState({...state, scan: '', lastScan: lastScan, inputValue: ''})
    }
  }

  const getItemDetails = (sku) => {
  // console.log('Get Our item details', sku)
    // If in our order already pull it up locally
    let existingOrder  = order
  // console.log('Order so far: ', existingOrder)
    // Gotta deal with bulk Items
    let index = existingOrder.findIndex(orderItem => (orderItem.sku === sku ))
  // console.log('Index: ', index)
    if (index > -1) {
    // console.log('In our order!!.....')
      let obj = existingOrder[index]
      if (obj && obj.bulkItem) {
        let actualObject = obj.individualItem
      // console.log('Need to add %d of thsee to this %o', obj.bulkQty, actualObject)
        // Find the individual item
        let indIndex = existingOrder.findIndex(orderItem => (orderItem.sku === actualObject.sku ))
        if (indIndex > -1) {
          let objectToUpdate = existingOrder[indIndex]
          objectToUpdate.orderQuantity += Number(obj.bulkQty)
          objectToUpdate.quantity = actualObject.quantity - obj.bulkQty
          setOrder(existingOrder)
          setItem(objectToUpdate)
        } else {
        // console.log('WTF we dont have that sku riht now')
        }
      } else {
        let updatedQuantity = obj.orderQuantity - 0 + 1
        obj.orderQuantity = updatedQuantity
        // Subtract from our on hand
        obj.quantity = obj.quantity - 1
      // console.log('Object: ', obj)
        setOrder(existingOrder)
        setItem(obj)
      }
      setState({...state, scan: '', lastScan: sku, inputValue: ''})
    } else {
      // otherwise get it from the database
      findItemInDatabase(sku)
    }
  }

  window.onkeypress = function (e) {
    // console.log('Press: ', e)
    if (ignoreKeyPress) {
    // console.log('Ignore This')
      if (e.code === "Enter") {
        props.notification({
          type: 'warning',
          title: 'Scanner Not Available',
          message: 'Please finish what you are doing before continuing to scan'
        })
      }
    } else {
      if (!state.crew || !state.crew._id) {
        // console.log('Ignore this shit!!')
        if (e.code === "Enter") {
          props.notification({
            type: 'warning',
            title: 'Please Choose A Crew Foreman!',
            message: 'You must choose a Foreman to start an order. Please ask them their name and enter it into the "Foreman Box"'
          })
        }
      } else {
        if (e.code === "Enter") {
          // console.log('Scan Complete')
          // console.log(state.scan)
          // const lastScan = state.scan
          // setState({...state, scan: '', lastScan: lastScan, inputValue: ''})
          getItemDetails(state.scan)
        } else {
          let updatedScan = state.scan + e.key
          // console.log('Updated Scan: ', updatedScan)
          setState({...state, scan: updatedScan })
        }
      }
    }
  }

  React.useEffect(() => {

  }, [])

  const handleQtyChange = (e) => {
  // console.log('Handle Quantity change', e.target.value)
  }
  const OrderQuantityComponent = ({ qty }) => {
    return (
      <TextField
      label="Qty"
      onChange={ handleQtyChange }
      name="itemQty"
      value={ qty }
      margin="normal"
      variant="outlined"
    />
    )
  }

  const handleEditQty = (item, index) => {
  // console.log('Edit Quantity: ', index)
  // console.log(item)
    let url = ''
    if (item && item.photos && item.photos.length) url = item.photos[0]['url']
    item.photoURL = url
    // setItem(item)
    setItemToEdit(item)
    setShowEditDetails(true)
    // setState({...state, lastScan: item.sku, scan: '', inputValue: ''})
  }

  const handleRemoveItem = (item, index) => {
  // console.log('Remove item: ', item)
  // console.log(index)
    let existingOrder = order
    if (existingOrder.length > 1) {
      let newOrder = existingOrder.filter((orderItem, orderIndex) => orderItem._id !== item._id)
    // console.log('After delete: ', newOrder)
      setOrder(newOrder)
    } else {
      setOrder([])
    }
  }
  const OrderDetailComponent = () => {
    // console.log('Order Details: ', details)
    // console.log('Order: ', order)
    return <>
      <Grid container>
        {order.map((item, index) => (
          item.bulkItem ? '' : (
            <Grid item key={index} lg={12}>
            <OrderLine>
              <LineItemDetail onClick={e => handleEditQty(item, index)}>{item.orderQuantity}  -</LineItemDetail>
              <LineItemDetail onClick={e => handleEditQty(item, index)}>{item.name} ({item.sku})</LineItemDetail>
              <LineItemDetail>
               <Tooltip title="Delete">
                 <IconButton onClick={() => handleRemoveItem(item, index) } size="small" align="edge" alt="Delete" label="Delete" aria-label="Delete">
                   <DeleteIcon />
                 </IconButton>
               </Tooltip>
              </LineItemDetail>
            </OrderLine> 
           </Grid>
          )
        ))}
      </Grid>
    </>
  }

  const handleSave = () => {
  // console.log('save', item)
    setLoading(true)
    let obj = {
      item: item,
      user: props.user
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/inventory/updateItem`,
      data: obj,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Axios response to update inventory ', response)
      setLoading(false)
      setState({...state, scan: '', inputValue: ''})
      // setState({})
      // setJobNumber(response.data)
      // if (response.data._id) {
      //   // props.newNoteResponse('newNote', response.data)
      //   props.updateContact(response.data)
        // setState({note: ''})
        props.notification({
          type: 'success',
          title: 'Inventory Item Saved!',
          message: 'Updates To This Item Have Been Saved!'
        })
  })
}

const handleCheckout = () => {
  if (window.confirm('Are you sure that you are completed with this order?')) {
  // console.log('Checkout this order: ', order)
    setLoading(true)
    let obj = {
      order: order,
      user: props.user,
      crew: state.crew
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/inventory/checkoutOrder`,
      data: obj,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Axios response to order checkout ', response)
      setLoading(false)
      setState(initialState)
      setOrder([])
      setItem({})
      // setState({})
      // setJobNumber(response.data)
      // if (response.data._id) {
      //   // props.newNoteResponse('newNote', response.data)
      //   props.updateContact(response.data)
        // setState({note: ''})
        props.notification({
          type: 'success',
          title: 'Order Complete!',
          message: 'You are now read to start your next order!'
        })
  })
  }
}

const updateFromQtyEdit = (d) => {
// console.log('UPdate from quantity edit: ', d)
  setShowEditDetails(false)
  if (d === 'closeModal') {

  } else {
  // console.log('Updated Quantity for our item to edit: ', d)
  // console.log(itemToEdit)
    let existingOrder  = order
  // console.log('Order so far: ', existingOrder)
    let index = existingOrder.findIndex(orderItem => (orderItem._id === itemToEdit._id ))
  // console.log('Index: ', index)
    if (index > -1) {
      let theItem = existingOrder[index]
      let difference = theItem.orderQuantity - d
      theItem.quantity = theItem.quantity + difference
      theItem.orderQuantity = d
      setOrder(existingOrder)
      setState({...state, scan: '', inputValue: ''})
      setItemToEdit({})
    }
  }

}

const crewNameAndForeman = (crew) => {
  // console.log('Crew Name: ', crew)
  // if (crew) {
  //   if (crew && crew.foremanName) {
  //     return crew.name + ' (' + crew.foremanName + ')'
  //   } else {
  //     return crew.name
  //   }
  // } else {
  //   return ''
  // }
  if (crew && crew.displayName) {
    return crew.displayName
  } else {
    return ''
  }
}

const updateCrew = (event, newCrew) => {
  // console.log('Update crew: ', crew)
// console.log('newCrew Crew: ', newCrew)
  // console.log('Event: ', event)
  // console.log(event.target.id)
  // let split = event.target.id.split('-')
  // let crewNum = split[3]
  // let optIndex = split[1]
  // console.log('Crew Numb: ', crewNum)
  // console.log('Opt Index: ', optIndex)
  // let newCrew = props.crews[crewNum]
  // console.log('nEW Crew:', newCrew)
  if (newCrew && newCrew._id) {
    // setIgnoreKeyPress(false)
  // console.log('Update our crew: ', state)
    setState({...state, crew: newCrew })
  } else {
    // setState({...state, crew: {name: ' '}})
  }
}
  const ScanDetailComponent = ({ item }) => {
    // console.log('Item: ', item)
    if (item && item._id) {
      let url = ''
      if (item && item.photos && item.photos.length) url = item.photos[0]['url']
      return <>
        <Grid container style={{margin: '8px'}}>
          <Grid item lg={12} md={12} sm={12}>{item.name}</Grid>
          <Grid item lg={6} sm={12} md={6}>
            <Grid container>
              <Grid item lg={12} md={12} sm={12}>
                <TextField
                label="Name"
                disabled
                onChange={ e => setItem({...item, name: e.target.value })}
                name="itemName"
                value={ item.name }
                margin="normal"
                fullWidth
              />
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <TextField
                label="Price"
                disabled
                onChange={ e => setItem({...item, price: e.target.value })}
                name="itemPrice"
                value={ item.price }
                margin="normal"
                fullWidth
              />
              </Grid>
              <Grid item lg={6} md={6} sm={6}>
                <TextField
                label="Quantity On Hand"
                disabled
                onChange={ e => setItem({...item, quantity: e.target.value })}
                name="itemQty"
                value={ item.quantity }
                margin="normal"
                fullWidth
              />
              </Grid>
              <Grid item lg={6} md={6} sm={6}>
                <TextField
                label="Minimum Quantity"
                disabled
                onChange={ e => setItem({...item, min_quantity: e.target.value })}
                name="itemMinQty"
                value={ item.min_quantity }
                margin="normal"
                fullWidth
              />
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <TextField
                label="SKU"
                disabled
                onChange={ e => setItem({...item, sku: e.target.value })}
                name="itemSKU"
                value={ item.sku }
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
                <TextField
                label="Notes"
                disabled
                onChange={ e => setItem({...item, notes: e.target.value })}
                name="itemNotes"
                value={ item.notes }
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            {/* <Button color="primary" variant="contained" fullWidth disabled={loading} onClick={handleSave} >
              Save
            </Button> */}
          </Grid>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <img width="250" src={item.pictureURL} style={{margin: '25px'}}></img>
          </Grid>
          
        </Grid>
      </>
    } else {
      return <></>
    }
  }

  const handleSearch = () => {
    setIgnoreKeyPress(true)
  // console.log('Open Search Modal')
    setShowSearchModal(true)
  }

  const updateFromSearchItem = (d, t) => {
    if (d === 'submit') {
      getItemDetails(t.sku)
    }
    setState({...state, scan: '' })
    setShowSearchModal(false)
    setIgnoreKeyPress(false)
  }
    return <>
    <SearchForItemModal open={showSearchModal} updateParent={updateFromSearchItem} />
    <QtyEditModal open={showEditDetails} item={itemToEdit} updateParent={updateFromQtyEdit} />
    <Container>
      <Header>Horizon Shed Point Of Sale</Header>
      <ScannerInput container>
        <Grid item lg={12} sm={12} md={12}>
          {state.lastScan}
        </Grid>
      </ScannerInput>
      <CrewDetailsArea>
        <Autocomplete
          id="crewlist"
          options={employees}
          getOptionLabel={(option) => crewNameAndForeman(option)}
          // style={{ width: 200 }}
          onChange={updateCrew}
          // onChange={ (e) => updateCrew(workorder, e.target.id, e.target.value, serviceIndex) }
          value = {state.crew}
          // onChange={ e => setState({...state, crew: e.target.value, requiresUpdate: true }) }
          renderInput={(params) => <TextField {...params} label="Foreman Name" variant="outlined" />}
        />
      </CrewDetailsArea>
      <OptionPanelContent>
        <SearchButton>
          <Button label="Search For Item" color="secondary" variant="contained" onClick={handleSearch}>Search</Button>
        </SearchButton>
      </OptionPanelContent>
      <MainContent>
        {/* <OrderDetailComponent /> */}
        <OrderDetails>
          {OrderDetailComponent({ item: item}) }
        </OrderDetails>
        <ScanDetails>{ScanDetailComponent({ item: item})}</ScanDetails>
      </MainContent>
      <CheckoutArea>
            <Button color="primary" variant="contained" fullWidth disabled={!state.crew || loading || order.length < 1} onPress={handleCheckout} onClick={handleCheckout} >
              Checkout
            </Button>
          </CheckoutArea>
      <OrderSummaryArea>
      </OrderSummaryArea>
    </Container>
  </>
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ShedView)