import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, IconButton, Paper, Typography, TextField, Table, TableBody, TableCell, TableHead, TableRow, InputAdornment }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { currencyFormat, currencyFormatWithOutFlair, isEven, daysOfTheWeek, mtcFrequencies } from '../../services/common'
import { connect } from 'react-redux'
import axios from 'axios'
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import SearchIcon from '@material-ui/icons/Search';
import EditJobSiteModal from './MaintenanceStuff/EditJobSiteModal'

let weekOfTheYear = moment(new Date()).week()

const defaultVals = {
  sites: [],
  searchValue: '',
  crewToConfirm: null,
  siteToConfirm: null,
  evenOrOddWeeks: 'Odd'
}

const HMICrewAssignmentComponent = (props) => {
  const classes = useStyles()
  const [state, setState] = useState(defaultVals)
  const [searchValue, setSearchValue] = React.useState('')
  const [filteredRows, setFilteredRows] = React.useState([])
  const [showEditJobsiteModal, setShowEditJobsiteModal] = React.useState(false)
  const [activeSite, setActiveSite] = React.useState({})
  const [loading, setLoading] = React.useState(false)

  const hmiCrews = props.crews.filter(crew => (crew.entity === "640a56404965b71a9edd0e7d"))
  // console.log('Got HMI Crews', hmiCrews)

  useEffect(() => {
    let isMounted = true

    const fetchAgs = async () => {
      setLoading(true)
      const resp = await axios.get(`${props.url}/api/hmi/getActiveSites`)
      const sites = resp.data
      // console.log('sites...', sites)
      if (sites && sites.length && isMounted) {
        filterOut(sites, searchValue)
        setState({...state, sites: sites })
      }
    }
    fetchAgs()
    return () => {
      isMounted = false
    }
  }, [])

  const saveThisToTheBackEnd = async (updatedSiteInfo) => {
    // console.log('save this', updatedSiteInfo)
    axios({
      method: 'post',
      url:  `${props.url}/api/hmi/assignCrewToAg`,
      data: updatedSiteInfo,
    }).then(response => {
      // console.log("Update", response.data)
      if (response && response.status === 200) {
        props.notification({
          type: 'success',
          title: 'Saved That!',
          message: 'Great job!'
        })
      } else {
        props.notification({
          type: 'warning',
          title: 'Failed to Save That Update!',
          message: response?.data?.message || 'Unknown Error Occurred'
        })
      }

    })
  }
  
  const handleUpdateAssignedCrew = (site, crew) => {
    // console.log('Updat the assignment here:', site, crew)
    setState({...state, crewToConfirm: crew, siteToConfirm: site})
  }

  const handleCancel = () => {
    setState({...state, siteToConfirm: null })
  }

  const handleConfirm = () => {
    // console.log('Confirm this shit', state)
    let crewToAssign = null
    if (state.crewToConfirm && state.crewToConfirm._id) {
      crewToAssign = {
        _id: state.crewToConfirm._id,
        name: `${state.crewToConfirm.name} ${state.crewToConfirm.foremanName ? `(${state.crewToConfirm.foremanName})` : ''}`
      } 
    }
    let newSites = [...state.sites]
    if (state.siteToConfirm) {
      let index = newSites.findIndex(item => {
        if (item._id === state.siteToConfirm._id && state.siteToConfirm.contract === item.contract) {
          return true
        } else {
          return false
        }
      })
      if (index > -1) {
        let siteToUpdate = {...newSites[index]}
        siteToUpdate.crew = crewToAssign?._id
        siteToUpdate.crewName = siteToUpdate.name
        saveThisToTheBackEnd(siteToUpdate)
        newSites[index] = {...siteToUpdate}
        // console.log('w/ our crew:', siteToUpdate)
      } else {
        console.log('Set no Crew on this bitch!!!!!')
      }
    }
    // console.log('New Sites', newSites)
    filterOut(newSites, searchValue)
    setState({...state, siteToConfirm: null, crewToConfirm: null, sites: newSites })
  }

  const handleUpdateTheAssignedWeek = (site, newWeek) => {
    // console.log("Update this", site)
    console.log(newWeek)
    let newSites = [...state.sites]

    let index = newSites.findIndex(item => (item._id === site._id && item.contract === site.contract))
    if (index > -1) {
      let siteToUpdate = {...newSites[index]}
      siteToUpdate.evenOrOddWeeks = newWeek
      // console.log('site to update', siteToUpdate)
      saveThisToTheBackEnd(siteToUpdate)
      newSites[index] = {...siteToUpdate}
      // console.log('w/ our crew:', siteToUpdate)
      setState({...state, sites: newSites })
    }
  }

  const handleUpdateTheSite = (siteToUpdate) => {
    // console.log('Update this one', siteToUpdate)
    let siteId = (siteToUpdate && siteToUpdate._id) ? siteToUpdate._id : (siteToUpdate && siteToUpdate.jobSite && siteToUpdate.jobSite._id) ? siteToUpdate.jobSite._id : siteToUpdate.jobSiteId
    // console.log('Site ID', siteId)
    siteToUpdate._id = siteId
    let newSites = [...state.sites].map(item => {
      // console.log(item)
      let itemId = item._id || item.jobSiteId
      if (itemId === siteId) {
        // console.log('This is ti!!', item)
        const {
          frequency,
          evenOrOddWeeks,
          crew,
          preferredDay,
          preferredDays,
          amountType,
          amount,
          irrigationCheckFrequency,
          mowingRequirements,
          mowingTime,
          crewSize,
          estimatedHours
        } = siteToUpdate
        const newSite = {
          ...item,
          frequency,
          evenOrOddWeeks,
          crew,
          preferredDay,
          preferredDays,
          amountType,
          amount,
          irrigationCheckFrequency,
          mowingRequirements,
          mowingTime,
          crewSize,
          estimatedHours
        }
        // console.log('site we are using:', newSite)
        // if (newSite && newSite.crew && newSite.crew._id) {
        //   newSite.crew = newSite.crew._id
         
        // }
        if (siteToUpdate?.crew?._id) {
            newSite.crew = siteToUpdate.crew._id
           newSite.crewName = `${siteToUpdate.crew.name} ${siteToUpdate.crew.foremanName ? `(${siteToUpdate.crew.foremanName})` : ''}`
        } else {
          newSite.crewName = 'Unassigned'
        }
        saveThisToTheBackEnd(newSite)

        return newSite
      } else {
        return item
      }
    })
    // console.log('Updated sites', newSites)
    filterOut(newSites, searchValue)
    setState(prevState => ({...prevState, sites: newSites}))
  }

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log('String', str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    setFilteredRows(newArr)
    setLoading(false)
  }

  const handleSearch = (e) => {
    if (e.target.value === '') {
      setSearchValue('')
      setFilteredRows(state.sites)
    } else {
      setSearchValue(e.target.value)
      filterOut(state.sites, e.target.value)
    }
  }

  const handleOpenEditSiteModal = (site) => {
    // console.log('Open Edit Site Modal', site)
    setActiveSite(site)
    setShowEditJobsiteModal(true)
  }

  const handleToggleEditJobSiteModal = () => {
    setShowEditJobsiteModal(!showEditJobsiteModal)
  }

  const updateJobSiteAfterEdit = (siteToUpdate) => {
    // console.log('Update jobsite... ', siteToUpdate)
    setShowEditJobsiteModal(false)
    handleUpdateTheSite(siteToUpdate)
  }
  
  return (
    <div className={classes.root}>
       <EditJobSiteModal open={showEditJobsiteModal} {...props} activeSite={activeSite} closeAddJobsiteModal={handleToggleEditJobSiteModal} updateParent={updateJobSiteAfterEdit} />
      <Paper className={classes.paper}>
        <Typography>HMI Crew Assignment</Typography>
        <TextField
                name="search" 
                value={ searchValue } 
                onChange={ handleSearch } 
                label="Search"
                type="search"
                variant="outlined"
                margin="normal"
                
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
            />
        <div>
          # of Matching Sites: {filteredRows.length}
        </div>
        {
          loading ? 'Loading' : <ListOfJobsites
          crews={hmiCrews}
          state={state}
          filteredRows={filteredRows}
          updateTheAssignedCrew={handleUpdateAssignedCrew} 
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
          updateTheAssignedWeek={handleUpdateTheAssignedWeek}
          updateTheSite={handleUpdateTheSite}
          handleOpenSite={handleOpenEditSiteModal}
        />
        }
       
      </Paper>
    </div>
  )
}

const frequencies = mtcFrequencies.map(frequencyOption => (frequencyOption.name))
const ListOfJobsites = ({ crews, state, updateTheAssignedCrew, handleConfirm, handleCancel, updateTheAssignedWeek, updateTheSite, filteredRows, handleOpenSite }) => {
  const classes = useStyles()
  const { siteToConfirm, crewToConfirm, sites } = state
  let evenWeek = isEven(weekOfTheYear)
  let weekType = evenWeek ? 'Even' : 'Odd'
  // console.log('Find crews...', crews)
  return (
    <div>
      <Table size="small">
        <TableHead>
            <TableRow>
                <TableCell className={classes.headerRow} >Client Name</TableCell>
                <TableCell className={classes.headerRow} >Site Name</TableCell>
                <TableCell className={classes.headerRow} >Job Name</TableCell>
                <TableCell className={classes.headerRow} >Contract Type</TableCell>
                <TableCell className={classes.headerRow} >Address Details</TableCell>
                <TableCell className={classes.headerRow} >Preferred Day</TableCell>
                <TableCell className={classes.headerRow} >Frequency</TableCell>
                <TableCell className={classes.headerRow} >Even/Odd Weeks</TableCell>
                <TableCell className={classes.headerRow} >Pricing Details</TableCell>
                <TableCell className={classes.headerRow} >Crew (Foreman)</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
        {
            filteredRows.map((site, siteIndexNum) => {
              const updateCrew = (e, newCrew) => {
                updateTheAssignedCrew(site, newCrew)
              }

              const handleUpdateItem = (e, type) => {
                let theSite = { ...site, [type]: e.target.value}
                // console.log('Site to update to ', theSite)
                updateTheSite(theSite)
              }

              // console.log('site:', site)
              let theCrew = null
              if (site && site.crew) {
               
                let index = crews.findIndex(crew => (crew._id.toString() === site.crew.toString()))
                if (index > -1) {
                  theCrew = crews[index]
                }
              }
              // console.log('the crew 332', theCrew)
              let crewDetails = ''
              if (theCrew && theCrew._id) crewDetails = `${theCrew.name} ${theCrew.foremanName ? `(${theCrew.foremanName})` : '' }`
              const showConfirm = ((siteToConfirm && siteToConfirm._id === site._id) && (siteToConfirm && siteToConfirm.contract === site.contract))
              return (
                <TableRow key={siteIndexNum}  style={{ cursor: 'pointer' }} onDoubleClick={() => handleOpenSite(site)} >
                  <TableCell > {site.clientName}</TableCell>
                  <TableCell> {site.name}</TableCell>
                  <TableCell> {site.jobName}</TableCell>
                  <TableCell> {site.customerType}</TableCell>
                  <TableCell  >{site.communityName ? site.communityName : ''}  {site.address}  {site.buildingNumber ? site.buildingNumber : ''} </TableCell>
                  <TableCell> 
                    {/* <FormControl className={classes.paddedStuff} fullWidth>
                      <TextField
                        type="select"
                        select
                        value={site.preferredDay}
                        onChange={e => handleUpdateItem(e, 'preferredDay')}
                        label="Preferred Day"
                        variant="outlined"
                        // onChange={(e) => setState({...state, phone['type']: e.target.value })}
                        // className={classes.paddedStuff}
                        inputProps={{ 'aria-label': 'Preferred Day' }}
                        fullWidth
                        placeholder="Choose Preferred Day"
                      >
                       {
                        daysOfTheWeek.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                          )
                        })}
                      </TextField>
                    </FormControl> */}
                    <PreferredDays site={site} />
                  </TableCell>
                  <TableCell> 
                  {site.frequency}
                    {/* <FormControl className={classes.paddedStuff} fullWidth>
                      <TextField
                        type="select"
                        select
                        value={site.frequency}
                        onChange={e => handleUpdateItem(e, 'frequency')}
                        label="Frequency"
                        variant="outlined"
                        // onChange={(e) => setState({...state, phone['type']: e.target.value })}
                        // className={classes.paddedStuff}
                        inputProps={{ 'aria-label': 'Odd or Even Weeks' }}
                        fullWidth
                        placeholder="Choose Frequency"
                      >
                       {
                        frequencies.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                          )
                        })}
                      </TextField>
                      <FormHelperText>Visit Frequency</FormHelperText>
                    </FormControl> */}
                  </TableCell>
                  <TableCell> 
                        {site.evenOrOddWeeks || ''}
                    {/* {
                      site.frequency === 'Bi-Weekly' ? (
                        <FormControl className={classes.paddedStuff} fullWidth>
                      <TextField
                        type="select"
                        select
                        value={site.evenOrOddWeeks || null }
                        onChange={e => handleUpdateItem(e, 'evenOrOddWeeks')}
                        label="Week Assignment"
                        variant="outlined"
                        // onChange={(e) => setState({...state, phone['type']: e.target.value })}
                        // className={classes.paddedStuff}
                        inputProps={{ 'aria-label': 'Odd or Even Weeks' }}
                        fullWidth
                        placeholder="Choose Which Week"
                      >
                       
                        <MenuItem value={"Odd"}>
                          Odd
                        </MenuItem>
                        <MenuItem value={"Even"}>Even</MenuItem>
                      </TextField>
                      <FormHelperText>Odd Or Even Week (This week is {weekType})</FormHelperText>
                    </FormControl>
                      ) : ''
                    } */}
                    
                  </TableCell>
                  <TableCell >
                    {site.amount ? currencyFormat(site.amount) : '' } {site.amountType ? `(${site.amountType === 'perVisit' ? 'Per Visit' : 'Per Month'})` : ''}
                  </TableCell>
                  <TableCell style={{ width: '15%' }}>
                    {/* {
                      showConfirm ? (
                        <div className={classes.confirmationContainer}>
                          <div>
                            Assign to {crewToConfirm ? crewToConfirm.name : ''} {(crewToConfirm && crewToConfirm.foremanName) ? `(${crewToConfirm.foremanName})` : ''} 
                          </div>
                          <div className={classes.confirmationButtonsArea}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleConfirm}
                              size="small"
                              >CONFIRM</Button>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={handleCancel}
                              >CANCEL</Button>
                          </div>
                          
                        </div>
                      ) : (
                        <Autocomplete
                          id="crew"
                          options={crews || []}
                          getOptionLabel={(option) => {
                            // console.log('Optoin - foreman?', option)
                            // Add foreman name
                            return `${option.name} ${option.foremanName ? `(${option.foremanName})` : ''}`
                          }}
                          // style={{ width: 200 }}
                          className={classes.paddedStuff}
                          // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
                          onChange={updateCrew}
                          // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                          value = {theCrew}
                          // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                          renderInput={(params) => <TextField {...params} variant="outlined" label="Crew" />}
                        />
                      )
                    } */}

                    {/* {`${theCrew.name} ${theCrew.foremanName ? `(${theCrew.foremanName})` : ''}`} */}
                    {crewDetails}
                  </TableCell>
                </TableRow>
              )
            })}

        </TableBody>
      </Table>

    </div>
  )
}

const PreferredDays = ({ site }) => {
  if (site && (site.frequency === 'Twice a Week' || site.frequency === 'Four Days a Week')) {
    return site.preferredDays.join(', ')
  } else {
    return site.preferredDay
  }

}

// const CrewAssignment = ({ crews, site, updateCrew }) => {
//   const classes = useStyles()
//   return (
//     <Autocomplete
//       id="crew"
//       options={crews || []}
//       getOptionLabel={(option) => {
//         return option.name 
//       }}
//       // style={{ width: 200 }}
//       className={classes.paddedStuff}
//       // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
//       onChange={updateCrew}
//       // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
//       value = {state.crew}
//       // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
//       renderInput={(params) => <TextField {...params} label="HMI Crew" />}
//     />
//   )
// }
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    crews: state.crews
  }
}

export default connect(mapStateToProps)(HMICrewAssignmentComponent)

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  paper: {
    width: '100%',
    height: '100%',
    minHeight: '80vh',
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  topRowButton: {
    margin: 45,
    marginTop: 10,
    marginBottom: 10,
    width: 276
  },
  confirmationContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
    textAlign: 'center'
  },
  confirmationButtonsArea: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-evenly'
  }
}))
