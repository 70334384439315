import React, { useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TextField, MenuItem, CircularProgress } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import axios from 'axios'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';


const ScrollableTableWithFixedColumnAndArrows = ({ data, thisMonth, daysInMonth }) => {
  const classes = useStyles()
  const daysHeader = Object.keys(data[0] || {}).filter(key => key.startsWith('Day '));
  const scrollRef = useRef(null);
  const dayOfTheMonth = new Date().getDate()
  const handleScroll = (direction) => {
    if (scrollRef.current) {
      const currentScroll = scrollRef.current.scrollLeft;
      const scrollAmount = 200; // Adjust based on your needs

      if (direction === 'left') {
        scrollRef.current.scrollLeft = currentScroll - scrollAmount;
      } else {
        scrollRef.current.scrollLeft = currentScroll + scrollAmount;
      }
    }
  };

  console.log('Days in the month:', daysInMonth)
  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      
      
      <div style={{ display: 'flex', overflowX: 'hidden', flexGrow: 1, height: '80vh' }}>
        {/* <TableContainer component={Paper} style={{ width: 'auto', flexShrink: 0 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.evenRowSize}>Crew Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((crew, index) => (
                <TableRow key={crew.crewId || index}>
                  <TableCell component="th" scope="row">{crew.crewName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.floatinButtonLeft}>
          <IconButton onClick={() => handleScroll('left')}><ArrowBackIosIcon /></IconButton>
       </div>
        <TableContainer ref={scrollRef} component={Paper} style={{ width: 'auto', overflowX: 'auto', flexGrow: 1 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {daysHeader.sort((a, b) => parseInt(a.split(' ')[1]) - parseInt(b.split(' ')[1])).map(day => (
                  <TableCell key={day} align="center" className={classes.evenRowSize}>{day}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((crew, index) => (
                <TableRow key={crew.crewId || index + 'days'}>
                  {daysHeader.map((day, dayIndex) => {
                    let valueOfCell = crew[day]
                    const isConfirmed = valueOfCell === 'Yes'
                    let notYetHere = false
                    console.log('Day Index..', dayIndex)
                    
                    // if (dayIndex >= dayOfTheMonth) {
                    //   valueOfCell = ''
                    //   notYetHere = true
                    // }
                    return (
                    <TableCell key={day} align="center"  className={ notYetHere ? classes.invalidDate : isConfirmed ? classes.confirmArea : classes.nonConfirmArea }>{valueOfCell}</TableCell>
                  )})}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}
         <TableContainer component={Paper} style={{ width: '95%', overflowX: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.biggerCell}>Crew Name</TableCell>
                  {daysHeader.sort((a, b) => parseInt(a.split(' ')[1]) - parseInt(b.split(' ')[1])).map((day) => (
                    <TableCell key={day} align="center" className={classes.regularCell}>{day}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((crew) => (
                  <TableRow key={crew.crewId} >
                    <TableCell component="th" scope="row" className={classes.biggerCell}>
                      {crew.crewName}<br />
                      {crew.division}
                    </TableCell>
                    {daysHeader.map((day, dayIndex) => {
                      const valueOfCell = crew[day];
                      const isConfirmed = valueOfCell === 'Yes';
                      const notYetHere = (thisMonth && (dayIndex >= dayOfTheMonth || dayIndex > daysInMonth));
                      let cellClass = notYetHere ? classes.invalidDate : isConfirmed ? classes.confirmArea : classes.nonConfirmArea;

                      return (
                        <TableCell key={day} align="center" className={cellClass}>
                          {!notYetHere ? valueOfCell : ''}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        {/* <div className={classes.floatinButtonRight}>
          <IconButton onClick={() => handleScroll('right')}><ArrowForwardIosIcon /></IconButton>
        </div> */}
      </div>
      
     
    </div>
  );
};



const HMIDailyLogDashboard = (props) => {
  const classes = useStyles()
  const [ourData, setOurData] = React.useState([])
  const [thisMonth, setThisMonth] = React.useState(true)
  const [daysInMonth, setDaysInMonth] = React.useState(30)
  const [loading, setLoading] = React.useState(false)
  const [crewsToShow, setCrewsToShow] = React.useState('All')

  React.useEffect(() => {
    let isMounted = true
    
    const fetchData = async () => {
      const resp = await axios.get(`${props.url}/api/hmi/fetchLogsByCrew?thisMonth=${thisMonth}`)
      console.log('Response ', resp)
      console.log(resp.data)
      // setCrewsToShow(crewTypes[0])
      if (resp.data && isMounted && resp.data.report2 && resp.data.report2.length) setOurData(resp.data.report2)
      if (resp.data && isMounted && resp.data.daysInMonth) setDaysInMonth(resp.data.daysInMonth)
    }

    fetchData()
    return () => {
      isMounted = false
    }
  }, [])

  const handleChange = async (e, newOpt) => {
    console.log('Change', e.target.value)
    setLoading(true)
    console.log('New opt', newOpt)
    let getCurrent = false
    if (e.target.value === 'last') {
      setThisMonth(false)
    } else {
      setThisMonth(true)
      getCurrent = true
    }
    const resp = await axios.get(`${props.url}/api/hmi/fetchLogsByCrew?thisMonth=${getCurrent}`)
    console.log('Response ', resp)
    console.log(resp.data)
    if (resp.data && resp.data.report2 && resp.data.report2.length) setOurData(resp.data.report2)
    if (resp.data && resp.data.daysInMonth) setDaysInMonth(resp.data.daysInMonth)
    setLoading(false)
  }

  const handleChooseCrewTypes = (e) => {
    // console.log('choose', e.target.value)
    setCrewsToShow(e.target.value)
  }

  let today = new Date()
  var month = today.toLocaleString('default', { month: 'long' })
  today.setMonth(today.getMonth() - 1)
  var lastMonth = today.toLocaleString('default', { month: 'long' })

  const filteredLogs = ourData.filter(log => {
    // console.log('Filtered log', log)
    let toReturn = false
    const division = log.division || 'Unknown'
    console.log('Division', division)
    if (crewsToShow === 'All') toReturn = true
    if (crewsToShow === 'County') {
      if (division === 'Northern Parkway') toReturn = true
      if (division === 'Maricopa County') toReturn = true
    }
    if (crewsToShow === 'ADOT') {
      if (division === 'ADOT') toReturn = true
      if (division === 'ADOT On-Call') toReturn = true
    }
    if (crewsToShow === 'Other') {
      if (division !== 'ADOT' && division !== 'Northern Parkway' && division !== 'Maricopa County') toReturn = true
    }
    return toReturn
  })

  console.log('filteredLogs:::: ', filteredLogs)
  
  return (
    <Paper className={classes.root}>
      <div>
        Daily Log Dashboard 
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignContent: 'center' }}>
        <TextField
          select
          helperText={`This Months Logs or Last??`}
          variant="outlined"
          value={thisMonth ? 'current' : 'last' }
          onChange={handleChange}
          disabled={loading}
        >
            <MenuItem value={'last'}>Last Month ({lastMonth})</MenuItem>
            <MenuItem value={'current'}>This Month ({month})</MenuItem>
          </TextField>
          <div>
              <TextField
              select
              helperText={`Which Crews Do You Wish To See?`}
              variant="outlined"
              value={crewsToShow }
              onChange={handleChooseCrewTypes}
              disabled={loading}
            >
                {
                  crewTypes.map((item, index) => {
                    return (
                      <MenuItem value={item} key={index} >{item}</MenuItem>
                    )
                  })
                }
              </TextField>
          </div>
      </div>
      <Paper className={classes.tableContainer}>
        {
          thisMonth ? month : lastMonth
        }
        { loading ? <CircularProgress size={72} /> : <ScrollableTableWithFixedColumnAndArrows data={filteredLogs} thisMonth={thisMonth} daysInMonth={daysInMonth} /> }
      </Paper>
    </Paper>
  )
}

const crewTypes = [
  'All',
  'County',
  'ADOT',
  'Other'
]

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    entities: state.entities,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(HMIDailyLogDashboard)

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    margin: '20px',
    padding: 20
  },
  tableContainer: {
    // maxHeight: 800,
    // maxWidth: 1080,
    margin: 10,
    padding: 10
  },
  confirmArea: {
    backgroundColor: 'green'
  },
  nonConfirmArea: {
    backgroundColor: 'red'
  },
  invalidDate: {
    backgroundColor: '#ccc'
  },
  floatinButtonRight: {
    backgroundColor: '#ccc',
    borderRadius: 50,
    padding: 10,
    position: 'fixed',
    right: 35,
    top: 150,
    border: '1px solid #aaa',
    zIndex: 9999
  },
  floatinButtonLeft: {
    backgroundColor: '#ccc',
    borderRadius: 50,
    padding: 10,
    position: 'fixed',
    left: 355,
    top: 150,
    border: '1px solid #aaa',
    zIndex: 9999
  },
  tableWrapper: {
    display: 'flex',
    alignItems: 'center',
    overflowX: 'hidden',
    width: '100%',
    position: 'relative', // For positioning arrows relative to this container
  },
  scrollButtons: {
    position: 'absolute',
    top: theme.spacing(1), // Adjust based on your header height
  },
  leftButton: {
    left: 0,
  },
  rightButton: {
    right: 0,
  },
  fixedHeader: {
    display: 'flex',
    background: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 1, // Ensure it stays on top of other content
    boxShadow: '0 2px 2px -1px rgba(0,0,0,0.4)', // Optional: adds shadow to the header to distinguish it from the body
  },
  headerCell: {
    flex: 1,
    padding: theme.spacing(1),
    minWidth: 100, // Adjust based on your content or leave flexible
  },
  scrollableTableContainer: {
    flexGrow: 1,
    overflowX: 'auto',
  },
  evenRowSize: {
    // '&.MuiTableCell-root': {
    //   height: '96px !important',
    // },
    // height: '96px !important',
  },
  evenRowSizeCrew: {
    width: '200px'
  },
  biggerCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '250px', // Adjust the max-width as needed
  },
  regularCell: {
    // width: '5%'
  }
}))
