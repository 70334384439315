import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const TaskEditModal = (props) => {
// console.log('TaskEditModal ', props)
  const [state, setState] = React.useState({
    clockIn:  '',
    clockOut: '',
    hoursWorked: '',
    clockInTime: '12:00 am',
    dueDate: new Date()
  })

  React.useEffect(() => {
    // console.log(props.data)
    if (props.task && props.task.dueDate) {
      setState({...state, dueDate: props.task.dueDate })
    }
    // let clockIn = moment(props.data.clockIn).local()
    // let clockOut = moment(props.data.clockOut).local()
    // console.log('Clock In: ', clockIn)
    // setState({...state, clockIn: clockIn, clockOut: clockOut, hoursWorked: props.data.hoursWorked })
  }, [props])

  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleSubmit = () => {
  // console.log('Handle Submit', state)
    let obj = {
      task: props.task,
      dueDate: state.dueDate
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/tasks/updateTaskDueDate`,
      data: obj,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Axios response to update Time Entry ', response)
      props.notification({
        type: 'success',
        title: 'Task Due Date Modified',
        message: 'We have updated that task due date per your request.'
      })
      props.updateParent('saved', response.data)
    })
  }

  // const handleTimeChange = (type, value) => {
  // // console.log('Type: ', type)
  //   let isValid = moment(value, 'YYYY-MM-DDThh:mm').isValid()
  // // console.log('Valid? ', isValid)
  //   if (isValid) {
  //   // console.log('Value: ', value)
  //     let clockIn = null
  //     let clockOut = null
  //     if (type === 'clockIn') {
  //       clockIn =  moment(value, 'YYYY-MM-DDThh:mm').local()
  //       clockOut = moment(state.clockOut, 'YYYY-MM-DDThh:mm').local()
  //     }
  //     if (type === 'clockOut') {
  //       clockIn = moment(state.clockIn, 'YYYY-MM-DDThh:mm').local()
  //       clockOut = moment(value, 'YYYY-MM-DDThh:mm').local()
  //     }
  //   // console.log('Clock IN', clockIn)
  //   // console.log('Clock out: ', clockOut)
  //     let hoursWorked = Math.abs(clockIn - clockOut) / 36e5
  //   // console.log('Hours WOrked: ', hoursWorked)
  //     hoursWorked = parseFloat(hoursWorked).toFixed(2)
  //   // console.log(hoursWorked)
  //     // if (clockOut < clockIn && state.weWantThis) {
  //     //   if (window.confirm('Your Clock-Out Time Is Before the Clock-In Time...Should We Reverse Them?')) {
  //     //   // console.log('Reverse em!')
  //     //     setState({...state, clockIn: clockOut, clockOut: clockIn, hoursWorked: hoursWorked})
  //     //     props.notification({
  //     //       type: 'warning',
  //     //       title: `Uh Oh - That Doesn't Seem Right!`,
  //     //       message: 'Your Clock-In Time Was After The Clock-Out - So We Reversed Them!'
  //     //     })
  //     //   } else {
  //     //     setState({...state, clockIn: clockIn, clockOut: clockOut, hoursWorked: 0})
  //     //   }
  //     // } else {
  //       setState({...state, clockIn: clockIn, clockOut: clockOut, hoursWorked: hoursWorked})
  //     // }
  //   }
  // }

  const handleTimeChange = (e) => {
  // console.log('Time Change: ', e)
    let isValid = moment(e.target.value, 'YYYY-MM-DDThh:mm').isValid()
  // console.log('Valid? ', isValid)
    let newDueDate =  moment(e.target.value, 'YYYY-MM-DDThh:mm').local()
  // console.log(newDueDate)
    if (isValid) {
      setState({...state, dueDate: newDueDate})
    }
  }

return (
  <div>
    
      <Dialog
        // fullScreen={false}
        // maxWidth={'sm'}
        // width='lg'
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Modify Task Due Date</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Choose The New Due Date:
          </DialogContentText>
          <TextField
              id="dueDate"
              label="Task Due Date"
              type="datetime-local"
              
              value={moment(state.dueDate).format('YYYY-MM-DDTHH:mm')}
              variant="outlined"
              onChange={handleTimeChange}
              // onChange={e => setState({...state, dueDate: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(TaskEditModal))