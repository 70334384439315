import React from 'react'
// import { socket } from '../SocketIo/Socket'
import axios from 'axios'
import BallotIcon from '@material-ui/icons/Ballot';
import { Button, CircularProgress }  from '@material-ui/core';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import BatchStatementOptions from './BatchStatementOptions.js'

const BatchStatementInfo = (props) => {
  // console.log('Batch Statement info!!')
  const [visibility, setVisibility] = React.useState('hidden')
  const [buttonVisibility, setButtonVisibility] = React.useState('inline-block')
  const [inProcess, setInProcess] = React.useState(false)
  const [statementMessage, setStatementMessage] = React.useState({
    client: '',
    step: '',
    message: ''
  })
  const [state, setState] = React.useState({
    open: false
  })

  // React.useEffect(() => {
  //   socket.on('batchStatement', function (msg) {
  //     // console.log('GOT A SOCKET MESSAGE: ', msg)
  //     setInProcess(true)
  //     setButtonVisibility('none')
  //     // let newMessage = msg
  //     // if (!msg.client) newMessage.client = statementMessage.client
  //     // if (!msg.step) newMessage.step = statementMessage.step
  //     // if (isNaN(msg.step)){
  //     //   newMessage.step = msg.step
  //     // } else {
  //     //   newMessage.step = ''
  //     // }
  //     // if (!msg.message) newMessage.message = statementMessage.message
  //     // if (msg && msg.step === 'Complete') {
  //     //   setInProcess(false)
  //     //   newMessage.client = ''
  //     //   newMessage.step = 'Process Complete'
  //     //   setTimeout(function () {
  //     //     // console.log('Hide this shit and show the button again')
  //     //     setVisibility('hidden')
  //     //     setButtonVisibility('inline-block')
  //     //     setInProcess(false)
  //     //   }, 10000)
  //     // } else {
  //     setStatementMessage(msg)
  //     setVisibility('visible')
  //     // }
  //   })
  //   return () => {
  //     socket.off('batchStatement')
  //   }
  // })

  const runBatchStatements = () => {
    // console.log('Run Batch STatements now')
    setState({...state, open: true})
    // setInProcess(true)
    // // setButtonVisibility('none')
    // axios({
    //   method: 'post',
    //   url: `${props.url}/api/invoice/runBatchStatements`,
    //   data: {
    //     user: props.stuff.user,
    //     name: 'Jan2021',
    //     limit: 9999
    //   }
    // })
    //   .then(({ data }) => {
    //     // console.log(data)
    //     // setLeads(data)
    //   }).catch(function (err) {
    //     // console.log('Error getting stuff', err)
    //   });
  }

  return (
    <div style={{display: 'inline'}}>
      <BatchStatementOptions closeModal={() => setState({...state, open: false})} open={state.open} />
      <span style={{display: buttonVisibility}}>
      <Button
          variant="contained"
          style={{backgroundColor: '#ccb056'}}
          // className={classes.button}
          size="small"
          startIcon={<BallotIcon />}
          disabled={inProcess}
          // onClick={handleSnoutWeevil }
          onClick={runBatchStatements}
          // onClick={handleDeleteService}
          // onClick={offerDeleteOption}
          // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
        >
          Run Month End
        </Button> 
        </span>
      <div style={{visibility: visibility}}>
        <Grid container>
          <Grid item xs={6} style={{textAlign: 'right'}}>
            <CircularProgress style={{color: '#fff'}} size={ 36 } />
          </Grid>
          <Grid item xs={6} style={{textAlign: 'left'}}>
          {statementMessage.client} {statementMessage.step} 
          <br/> 
          {statementMessage.message}
          </Grid>
        </Grid>
      </div>
    </div> 
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default React.memo(connect(mapStateToProps)(BatchStatementInfo))