import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import { Redirect } from "react-router-dom";
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import Permissions from './Permissions'
const InviteUserModal = (props) => {

  const [state, setState] = React.useState({
    absenceType: 'off',
    notes: '',
    email: ' '
  })
  const [permissions, setPermissions] = React.useState([])
  React.useEffect(() => {
    if (props.employee.email && props.employee.email.length) {
      setState({...state, email: props.employee.email})
    }
  }, [props.employee])

  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleSubmit = () => {
  // console.log('Handle Submit')
    let obj = {
      employee: props.employee,
      email: state.email,
      user: props.user,
      permissions: permissions
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/auth/employeeInvitation`,
      data: obj
    }).then(response => {
    // console.log('User Invited', response.data)
      props.notification({
        type: 'success',
        title: 'User Created',
        message: `${props.employee.firstName} has been sent an invitation.`
      })
      props.updateParent('inviteSent', response.data)

    }).catch(err => {
    // console.log('Error reporting absence', err)
      props.notification({
        type: 'warning',
        title: 'Error Saving Absence',
        message: `Error Saving Absence (Error Code: AM50)`
      })
    })
  }

  const showErrors = (er) => {
  // console.log('Errors:', er)
  // console.log(er[0])
    const t = er[0]
  // console.log(t.getErrorMessage())
    const err = er[0]['state']
  // console.log('Err: ', err)
    const valid = err.isValid
  // console.log('Valid?? ', valid)
    const msg = err.errorMessages.join(', ')
  // console.log(msg)
    if (valid) {
    // console.log('Submit')

    } else {
      props.notification({
        type: 'danger',
        title: 'Invalid Email',
        message: t.getErrorMessage()
      })
    }
  }

  const handlePermissionsUpdate = (d) => {
  // console.log('Permissions response: ', d)
    setPermissions(d)
  }


return (
  <div>
    
      <Dialog
        // fullScreen={false}
        // maxWidth={'lg'}
        // width='lg'
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Invite User -  {props.employee.displayName}</DialogTitle>
        <ValidatorForm
                // ref="form"
                onSubmit={ handleSubmit }
                onError={ errors => showErrors(errors) }
                // className={ styles.content }
            >
        <DialogContent>
          <DialogContentText>
            Please choose how we should notify this person:
          </DialogContentText>

              <TextValidator
                label="Email"
                onChange={e => setState({...state, email: e.target.value.trim() })}
                name="email"
                value={ state.email || '' }
                validators={[ 'required', 'isEmail', 'minStringLength:5', 'maxStringLength:100' ]}
                errorMessages={[ 'this field is required', 'email is not valid', 'min length 5', 'max length 100' ]}
                margin="normal"
                fullWidth
              />
           
           <DialogContentText>
            Please Set Their Permissions:
          </DialogContentText>
           <Permissions profile={props.employee} updateParent={handlePermissionsUpdate} />
           
        </DialogContent>
        <DialogActions>
        <DialogContentText>
            Are you sure you want to invite this user? 
            </DialogContentText>
          <Button onClick={handleCloseModal} color="primary">
            Cancel
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button type="submit" color="primary">
            Yes
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
        </ValidatorForm>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(InviteUserModal)