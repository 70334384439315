import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, TableContainer }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  table: {
    width: '100%'
  },
  tableWrapper: {
    overflowX: 'scroll',
    minHeight: '40vh',
    maxHeight: '55vh'
  }
}));

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


const LateFeeTableHead = (props) => {
  const classes = useStyles();
  return (
    <TableHead className={classes.table}>
      <TableRow >
        <TableCell>
          Customer Name
        </TableCell>
        <TableCell>
          Invoice Number
        </TableCell>
        <TableCell>
          Amount
        </TableCell>
        <TableCell>
          Waived By
        </TableCell>
      </TableRow>
    </TableHead>
  )
}

const LateFeeTable = (props) => {
  const classes = useStyles();
  return (
    <TableBody className={classes.table}>
      {
        props.lateFees.map(row => {
          return (
            <TableRow key={row._id}>
              <TableCell>
                {row.client.displayName}
              </TableCell>
              <TableCell>
                {(row.invoice && row.invoice.invoiceNumber) ? row.invoice.invoiceNumber : ''} ({(row.invoice && row.invoice.jobName) ? row.invoice.jobName : '' })
              </TableCell>
              <TableCell>
                {currencyFormat(row.amount)}
              </TableCell>
              <TableCell>
                {row.waived_by.name}
              </TableCell>
              
            </TableRow>
          )
        })
      }
  </TableBody>
  )
}

const EmployeeTimeSheetDetailsModal = (props) => {
  console.log('EmployeeTimeSheetDetailsModal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState({
    note: ''
  })

  const inputRef = React.useRef(null)

  const handleSave = () => {
    // console.log(inputRef)
    // setLoading(true)
    // // setClient({})
    // // setState({...state, note})
    // let data = {
    //   notes: inputRef.current,
    //   projectId: props.project._id
    // }
    // axios({
    //   method: 'post',
    //   url:  `${props.url}/api/projects/addProjectNotes`,
    //   data: data
    // }).then(response => {
    //   setLoading(false)
    //   if (response.data && response.data._id) {
    //     props.notification({
    //       type: 'success',
    //       title: 'Project Note Saved',
    //       message: 'That Note Has Been Saved. Great Job!'
    //     })
    //     props.updateParent(response.data)
    //     // props.closeModal()
    //   }
    // })
  }
  
  const updateRef = (e) => {
    inputRef.current = e.target.value
  }
  
  const dates = [] // [...new Set(employeesData.flatMap(employee => employee.shifts.map(shift => shift.date)))].sort();

  const employeesData = []
  return (

      <Dialog open={props.open} fullWidth={true} onClose={props.closeModal} maxWidth='md' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Hours Worked Details</DialogTitle>
          <DialogContent >
            <DialogContentText>
              Here are the Shifts That Made That Data
            </DialogContentText>
            {/* {JSON.stringify(props.lateFees)} */}
            <div className={classes.tableWrapper} style={{margin: '10px'}}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={'small'}
                aria-label="enhanced table"
                stickyHeader
              >
                {/* <LateFeeTableHead />
                <LateFeeTable lateFees={props.lateFees} /> */}
              </Table>
            </div>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Employee Name</TableCell>
                    {dates.map(date => (
                      <TableCell key={date} align="right">{date}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {employeesData.map((employee) => (
                    <TableRow key={employee.employeeName}>
                      <TableCell component="th" scope="row">
                        {employee.employeeName}
                      </TableCell>
                      {dates.map(date => {
                        const shift = employee.shifts.find(shift => shift.date === date);
                        return (
                          <TableCell key={date} align="right">
                            {shift ? shift.hoursWorked : '—'}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
              Cancel
            </Button> */}
            <Button color="primary" disabled={loading} onClick={props.closeModal}>
              {
                loading ? <CircularProgress /> : 'Close'
              }
            </Button>
          </DialogActions>
        </Dialog>
  
  )
}


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(EmployeeTimeSheetDetailsModal)