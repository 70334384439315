import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'

const months = [
  {
    name: 'January',
    abbr: 'jan'
  },
  {
    name: 'February',
    abbr: 'feb'
  },
  {
    name: 'March',
    abbr: 'march'
  },
  {
    name: 'April',
    abbr: 'april'
  },
  {
    name: 'May',
    abbr: 'may'
  },
  {
    name: 'June',
    abbr: 'june'
  },
  {
    name: 'July',
    abbr: 'july'
  },
  {
    name: 'August',
    abbr: 'aug'
  },
  {
    name: 'September',
    abbr: 'sept'
  },
  {
    name: 'October',
    abbr: 'oct'
  },
  {
    name: 'November',
    abbr: 'nov'
  },
  {
    name: 'December',
    abbr: 'dec'
  }
]

const defaultPricing = new Array(12).fill(0)

const VariableBilling = (props) => {
  // console.log('Variable Billing: ', props)
  const [state, setState] = React.useState({
    variablePrices: defaultPricing
  })
  
  React.useEffect(() => {
   // console.log('Monthly Fee Changed!!!', props.monthlyFee)
 // console.log('Variable Billing Use Effect', props)
  //// console.log(state)
   if (props.stuff.savedVariableBilling) {
   // console.log('Weve already saved oru billing so leave it alone!!!', props.stuff)
     setState({...state,
      janPrice: props.stuff.variablePricing.janPrice,
      febPrice: props.stuff.variablePricing.febPrice,
      marchPrice: props.stuff.variablePricing.marchPrice,
      aprilPrice: props.stuff.variablePricing.aprilPrice,
      mayPrice: props.stuff.variablePricing.mayPrice,
      junePrice: props.stuff.variablePricing.junePrice,
      julyPrice: props.stuff.variablePricing.julyPrice,
      augPrice: props.stuff.variablePricing.augPrice,
      septPrice: props.stuff.variablePricing.septPrice,
      octPrice: props.stuff.variablePricing.octPrice,
      novPrice: props.stuff.variablePricing.novPrice,
      decPrice: props.stuff.variablePricing.decPrice,
    })
   } else {
     if (props.contract && props.contract.billingByMonth) {
      let prices = props.contract.billingByMonth
      setState({...state,
       janPrice: prices[0]['amount'],
       febPrice:  prices[1]['amount'],
       marchPrice: prices[2]['amount'],
       aprilPrice: prices[3]['amount'],
       mayPrice: prices[4]['amount'],
       junePrice: prices[5]['amount'],
       julyPrice: prices[6]['amount'],
       augPrice: prices[7]['amount'],
       septPrice: prices[8]['amount'],
       octPrice: prices[9]['amount'],
       novPrice: prices[10]['amount'],
       decPrice: prices[11]['amount']
     }) 
    } else {
      if (props.monthlyPrice) {
        setState({...props,
          janPrice: props.monthlyPrice,
          febPrice:  props.monthlyPrice,
          marchPrice: props.monthlyPrice,
          aprilPrice: props.monthlyPrice,
          mayPrice: props.monthlyPrice,
          junePrice: props.monthlyPrice,
          julyPrice: props.monthlyPrice,
          augPrice: props.monthlyPrice,
          septPrice: props.monthlyPrice,
          octPrice: props.monthlyPrice,
          novPrice: props.monthlyPrice,
          decPrice: props.monthlyPrice
        }) 
      }
    }
  }
     // console.log('We have a contract - use those prices!!', props.contract)
  //   else {
  //   setState({...state,
  //     janPrice: props.monthlyFee,
  //     febPrice: props.monthlyFee,
  //     marchPrice: props.monthlyFee,
  //     aprilPrice: props.monthlyFee,
  //     mayPrice: props.monthlyFee,
  //     junePrice: props.monthlyFee,
  //     julyPrice: props.monthlyFee,
  //     augPrice: props.monthlyFee,
  //     septPrice: props.monthlyFee,
  //     octPrice: props.monthlyFee,
  //     novPrice: props.monthlyFee,
  //     decPrice: props.monthlyFee
  //   })
  //  }
  }, [props])

  const saveVariableBilling = () => {
  // console.log('Save our variable billing!!', state)
    props.saveVariableBilling(state.variablePrices)
  }

  const handleChange = (e) => {
  // console.log('handle change', e.target.value)
  // console.log(e.target.id)
    let newPrices = state.variablePrices
    let index = e.target.id.split('month-')[1]
  // console.log('Index:', index)

    newPrices[index] = e.target.value
  // console.log(newPrices)
    setState({...state, variablePrices: newPrices })
  }
  
  const handleFocus = (e) => {
    e.target.select()
  }
  return (

    <Grid container spacing={2} style={{margin: 10}}>
      {
        months.map((month, index) => {
          const abbr = month.abbr
          const name = month.name
          // const monthIndex = new Date().getMonth()
          return (
            <Grid item lg={4} md={4} sm={4} xs={6}>
              <TextField
                id={`month-${index}`}
                label={`${name} Price`}
                fullWidth
                // className={classes.paddedStuff}
                // type="number"
                onFocus={handleFocus}
                variant="standard"
                value={state.variablePrices[index]}
                // onChange={ e => setJanPrice(e.target.value) }
                onBlur={ saveVariableBilling }
                onChange={ handleChange }
                // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
              />
            </Grid>
          )
        })
      }

    </Grid>
  )
}

export default React.memo(VariableBilling)