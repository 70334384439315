import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, CircularProgress, IconButton, Tooltip }  from '@material-ui/core';
import axios from 'axios'
import ProtectedButton from '../../actions/protectedButtons';
import SearchComponent from '../Common/SearchComponent'
import ReceiptIcon from '@material-ui/icons/Receipt';
import SendToCustomerModal from '../Common/SendToCustomer/SendToCustomerModal'

const useStyles = makeStyles({
  table: {
    minWidth: 600,
  },
  button: {
    width: '40px'
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

const defaultStateVals = {
  payment: {}
}

const PaymentList = (props) => {
  // console.log('Payment List Props: ', props)
  const classes = useStyles();
  const [payments, setPayments] = React.useState([])
  const [filteredRows, setFilteredRows] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [searchValue, setSearchValue] = React.useState('')
  const [state, setState] = React.useState(defaultStateVals)
  const [showSendToCustomerModal, setShowSendToCustomerModal] = React.useState(false)

  React.useEffect(() => {
    // console.log('Use Effect Payment List')
    if (props.payments) {
      setPayments(props.payments)
      filterOut(props.payments, searchValue)
      // setFilteredRows(props.payments)
    }
    if (props.stuff && props.stuff.payments) {
      setPayments(props.stuff.payments)
      filterOut(props.stuff.payments, searchValue)
      // setFilteredRows(props.stuff.payments)
    } 
    
  }, [props])

  function currencyFormat(num) {
    // console.log('The Num: ', num)
    if (!num) num = 0
    // console.log('NUM: ', num)
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

 const handleDeletePayment = (item) => {
   // console.log('Delete this payment: ', item)
   setLoading(true)
   axios({
    method: 'post',
    url:  `${props.url}/api/payment/delete`,
    data: { payment: item, user: props.user, client: props.client }
  }).then(response => {
    // console.log('Payment Delete Response: ', response)
    
    let index = payments.findIndex(pmt =>  (pmt._id === item._id));
    let ourPmt = payments[index]
    // console.log('Payment to Remove: ', ourPmt)
    let pmts = payments
    pmts.splice(index, 1)
    setPayments(pmts)
    filterOut(pmts, searchValue)
    setLoading(false)
  })
 }

 const handleVoidPayment = (item) => {
  // console.log('Void this payment: ', item)
  setLoading(true)
  axios({
   method: 'post',
   url:  `${props.url}/api/payment/void`,
   data: { payment: item, user: props.user, client: props.client }
 }).then(response => {
   // console.log('Payment Void Response: ', response)
   let index = payments.findIndex(pmt =>  (pmt._id === item._id))
   let pmts = payments
   let ourPmt = pmts[index]
   // console.log('Payment to Void: ', ourPmt)
   ourPmt.status = 'Voided'
   setPayments(pmts)
   filterOut(pmts, searchValue)
   setLoading(false)
 })
}

const handleRefundPayment = (item) => {
  // console.log('Refund this payment: ', item)
  setLoading(true)
  axios({
   method: 'post',
   url:  `${props.url}/api/payment/refundPayment`,
   data: { payment: item, user: props.user, client: props.client }
 }).then(response => {
   // console.log('Payment refundPayment Response: ', response)
   let index = payments.findIndex(pmt =>  (pmt._id === item._id))
   let pmts = payments
   let ourPmt = pmts[index]
   // console.log('Payment to refundPayment: ', ourPmt)
   ourPmt.status = 'Refunded'
   setPayments(pmts)
   filterOut(pmts, searchValue)
   setLoading(false)
 })
}

 const ActionButton = (props) => {
  //// console.log('ACTION BUTTON:', props)
   let ourButton = <span></span>
   if (props && props.perms && props.perms.length) {
    if (props.row && props.row.status === 'Authorized') {
      ourButton = (
       <ProtectedButton type="Payments" kind="delete" perms={props.perms}>
 
         <Button
         variant="contained"
         // style={{backgroundColor: '#ed4545'}}
         className={classes.button}
         size="small"
         // startIcon={<DeleteIcon />}
         onClick={() => { if (window.confirm('Are you sure you wish to void this item?')) handleVoidPayment(props.row) } }
         // onClick={handleDeleteService}
         // onClick={offerDeleteOption}
         // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
       >
         Void
       </Button>
     </ProtectedButton>
      )
    }
    if (props.row && props.row.status === 'Paid' && props.row.manualInput === false) {
     ourButton = (
       <ProtectedButton type="Payments" kind="delete" perms={props.perms}>
         <Button
           variant="contained"
           // style={{backgroundColor: '#ed4545'}}
           // className={classes.button}
           size="small"
           // startIcon={<DeleteIcon />}
           // onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeletePayment(props.row) } }
           // onClick={handleDeleteService}
           // onClick={offerDeleteOption}
           onClick={() => { if (window.confirm('Are you sure you wish to refund this item?')) handleRefundPayment(props.row) } }
           // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
         >
           Refund
         </Button>
       </ProtectedButton> 
      )
    }
    if (props.row && props.row.status === 'Due' || props.row.status === 'Pending' || props.row.manualInput === true) {
     ourButton = (
       <div className={classes.wrapper}>
         <ProtectedButton type="Payments" kind="delete" perms={props.perms}>
           <Button
             variant="contained"
             style={{backgroundColor: '#ed4545'}}
             // className={classes.button}
             size="small"
             disabled={loading}
             startIcon={<DeleteIcon />}
             onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeletePayment(props.row) } }
             // onClick={handleDeleteService}
             // onClick={offerDeleteOption}
             // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
           >
             Delete
           </Button>
         </ProtectedButton>
      </div>
      )
   }
   }
  return ourButton
 }

 const NotesDetails = (data) => {
   let row = data.row
   // console.log('Notes Details: ', row)
  let html = ''
  if (row.transactionId) {
    html = (
      `Transaction ID: ${row.transactionId} (${row.accountType} - ${row.accountNumber}) - ${row.notes}`
    )
  } else if (row.accountType && row.accountNumber) {
    html = (
      `${row.accountType} - ${row.accountNumber} - ${row.notes}`
    )
  } else if (row.accountType) {
    html = (
      `${row.accountType} - ${row.notes}`
    )
  } else if (row.accountNumber) {
    html = (
      `${row.accountNumber} - ${row.notes}`
    )
  } else {
    html = row.notes
  }
  return html
 }

 const handleSearch = (searchTerm) => {
 // console.log('Search for ', searchTerm)
   setSearchValue(searchTerm)
   filterOut(payments, searchTerm)
 }
 
 const filterOut = (r, searchText) => {
  // console.log('FIlter out: %s - %s', r, searchText)
  let string = searchText
  let newArr = []
  r.filter(o => {
    // console.log('Object:  ', o)
    Object.keys(o).some(k => {
      // console.log(o[k])
      if(o[k]) {
        // console.log('OK: ', o[k])
        let str = o[k].toString().toLowerCase() 
        // console.log('String', str)
        if (str.includes(string.toLowerCase())) {
          // console.log('Mathc')
          return newArr.push(o)
        } else {
          return null
        }
      } else {
        return null
      }
    })
    // return newArr
  })

  setFilteredRows(newArr)

}

const handleSendReceipt = (row) => {
  console.log('Send receipt', row)
  setShowSendToCustomerModal(true)
  setState({...state, payment: row })
}

const handleCloseSendModal = (d) => {
  console.log('close it', d)
  setShowSendToCustomerModal(false)
}

  return (
    <div style={{maxHeight: '500px', overflowY: 'scroll'}}>
      <SearchComponent updateParent={handleSearch} matchingDocs={filteredRows.length} totalDocs={payments.length} />
      <SendToCustomerModal type="Receipt" payment={state.payment} open={showSendToCustomerModal} client={props.client} contacts={props.contacts} handleCloseModal={handleCloseSendModal} />
      <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Payment Type</TableCell>
            <TableCell>Invoice Number</TableCell>
            <TableCell>Job Details</TableCell>
            <TableCell >Amount</TableCell>
            <TableCell >Total (Including Fees)</TableCell>
            <TableCell >Status</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell >Paid Date</TableCell>
            <TableCell >Created Date</TableCell>
            <TableCell >Created By</TableCell>
            <TableCell >Action</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows.map((row) => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">
                {row.type === 'Prepayment' ? 'Deposit Used' : row.type}
              </TableCell>
              <TableCell >{row.invoiceNumber}</TableCell>
              <TableCell >{row.jobName} ({row.jobNumber})</TableCell>
              <TableCell >{currencyFormat(row.amount || 0)}</TableCell>
              <TableCell >{(row.totalCharge && row.totalCharge > 0) ? currencyFormat(row.totalCharge) : ''}</TableCell>
              <TableCell >{row.status}</TableCell>
              <TableCell ><NotesDetails row={row} /></TableCell>
              <TableCell >{row.paidDate ? moment(row.paidDate).format('MM/DD/YYYY') : ''}</TableCell>
              
              <TableCell >{row.created_at ? moment(row.created_at).format('MM/DD/YY hh:mmA') : ''}</TableCell>
              <TableCell >{row.created_by ? row.created_by : ''}</TableCell>
              <TableCell >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <ActionButton key={row._id} row={row} perms={props.perms} />
                  {
                    row.status === 'Paid' ? <SendReceiptButton row={row} sendReceipt={handleSendReceipt} /> : ''
                  }
                </div>
                
                
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}
const SendReceiptButton = (props) => {
  const handleClick = () => {
    props.sendReceipt(props.row)
  }
  return (
    <Tooltip title="Send Receipt">
      <IconButton
        variant="contained"
        size="small"
        onClick={handleClick}
        >
        <ReceiptIcon />
      </IconButton>
    </Tooltip>
    
  )
}

const mapStateToProps = (state) => {
  // console.log('Payment List  map state to PRops: ', state)
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    user: state.user,
    perms: state.perms
  }
}

export default React.memo(connect(mapStateToProps)(PaymentList))

