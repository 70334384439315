import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types';
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, MenuItem }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import TextField from '@material-ui/core/TextField';
import { FileUploader } from "react-drag-drop-files";
import FileUploadComponent from '../Uploads/UploadComponent'
import Autocomplete from '@material-ui/lab/Autocomplete';
// import POPhotos from './POPhotos';
import POUploads from './POUploads';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#eee',
    margin: 5,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  paper: {
    // width: '100%',
    marginTop: 10,
    marginBottom: theme.spacing(2),
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
      maxWidth: 960,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '50vh',
      maxWidth: 1360,
    },
    // margin: 20
  },
  headerText: {
    fontSize: 32,
    color: '#aaa',
    // color: 'rgb(119,156,164)',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 18,
      // color: 'red'
    },
    [theme.breakpoints.down('xs')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 14,
      // color: 'pink'
    },
    [theme.breakpoints.up('md')]: {
      // minHeight: '50vh',
      // maxWidth: 1360,
      textAlign: 'center',
      fontSize: 24,
      // color: 'green'
    }
  },
  giantText: {
    textAlign: 'center',
    fontSize: 48,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
    marginBottom: -15
  },
  subText: {
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
  },
  squigglyText: {
    textAlign: 'center',
    fontSize: 42,
    fontFamily: 'Inspiration',
    color: 'rgb(119,156,164)',
  },
  signUpText: {
    marginTop: 15,
    fontFamily: 'Amiri',
    fontSize: 32,
    textAlign: 'center',
    color: 'rgb(119,156,164)',
  },
  disclaimerText: {
    fontSize: 16,
    marginBottom: 30,
    marginTop: 15,
    color: '#aaa'
  },
  nameText: {
    fontSize: 24,
    marginTop: 10,
    color: '#aaa'
  },
  poDetailContainer: {
    width: '100%',
    height: '100%',
    margin: 10,
    padding: 10
  },
  poTitleItem: {
    fontSize: '1.3em',
    fontColor: '#ccc'
  },
  poDetailItem: {

  }
}));

function ViewPOOnline(props) {
// console.log('PO Online ', props)
  const [po, setPO] = React.useState({})
  let { poId } = useParams()

  React.useEffect(() => {
    let isMounted = true
    // console.log('***** ***   *    PO ID has changed!!', poId)

    const fetchPurchaseRequest = async (poId) => {
      // console.log('Fetching ', poId)
      let url = `${props.url}/api/purchaseorders/viewPO?id=${poId}`
    // console.log('URL to retrieve: ', url)
      const response = await axios.get(url)
      if (response && response.data && isMounted) {
      // console.log('PO to Aprovae: ', response.data)
        setPO(response.data)
      }
    }
    if (poId && poId.toString().length) fetchPurchaseRequest(poId)
    return () => {
      isMounted = false
    }
  }, [poId])

  const classes = useStyles();
  const updateFromApprovalAction = (data) => {
    setPO(data)
    // props.closeModal()
  }

  const updateVendorInvoiceDetails = async (data) => {


    // console.log(inputRef)
    let obj = {
      poId: po._id,
      data: data
    }
    // setLoading(true)
    // setClient({})
    // setState({...state, status: 'New', projectName: ''})
    axios({
      method: 'post',
      url:  `${props.url}/api/purchaseorders/updatePurchaseRequest`,
      data: obj
    }).then(response => {
      // setLoading(false)
      // console.log('Response', response.data)
      if (response.data && response.data._id) {
      //   props.closeModal()
      //   setState({...state, ...defaultValues})
        props.notification({
          type: 'success',
          title: `Purchase Request Updated`,
          message: 'Thank you for your help!'
        })
        props.history.push('/')
      } else if (response && response.data && response.data.error) {
          props.notification({
            type: 'warning',
            title: 'Upload Error',
            message: 'Error Uploading File - Error Code 199'
          })
        }
      //   // if (response.data && response.data.po && response.data.po._id) inputRef.current._id = response.data.po._id
      //   // inputRef.current.poNumber = response.data.poNumber
      //   // setState({...state, poNumber: response.data.poNumber})
      //   // props.updateParent(response.data)
      //   // handleClose()
      //   // props.closeModal()


    })

  }

  const isDisabled = (po.status === 'Deleted' || po.status === 'Reconciled' || po.status === 'Approved' || po.status === 'Denied') ? true : false
  return (
    <div className={classes.root}>
     <Paper className={classes.paper}>
       <Grid container>
          
        <Grid item lg={12} xs={12} sm={12} md={12}>
            <Typography className={classes.giantText}>
              Purchase Request #{po.number}
            </Typography>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          {
              po.status === 'Deleted' ? <Chip style={{color: '#fff', background: '#715', marginLeft: 10 }} size="small" label="Deleted" /> : null
            }
          {
              po.status === 'Reconciled' ? <Chip style={{color: '#fff', background: '#39a33d', marginLeft: 10 }} size="small" label="Reconciled" /> : null
            }
            {
              po.status === 'Pending Approval' ? <Chip style={{color: '#fff', background: '#39a33d', marginLeft: 10 }} size="small" label="Pending Approval" /> : null
            }
            {
              po.status === 'Approved' ? <Chip style={{color: '#fff', background: '#191', marginLeft: 10 }} size="small" label="Approved" /> : null
            }
            {
              po.status === 'Denied' ? <Chip style={{color: '#fff', background: '#911', marginLeft: 10 }} size="small" label="Denied" /> : null
            }
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <PurchaseRequestDetails po={po} />
        </Grid> 
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <VendorInvoiceInfo po={po} employees={props.employees} updateParent={updateVendorInvoiceDetails} />
        </Grid>           
      </Grid>
     </Paper>
    </div>
  );
}

const defaultState = {
  amount: 0,
  invoiceNumber: ''
}
const VendorInvoiceInfo = ({ po, employees, updateParent }) => {
  const [state, setState] = React.useState(defaultState)
  const [purchaser, setPurchaser] = React.useState({})
  const [notes, setNotes] = React.useState('')
  const [uploadDetails, setUploadDetails] = React.useState({})

  React.useEffect(() => {
    let currentState = {...defaultState, ...po}
    setState(currentState)
  }, [po])

  const updatePurchaser = (e, person) => {
    setPurchaser(person)
  }

  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      // console.log(e.target)
      if (e.target.id === 'purchaser') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        employees.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          updatePurchaser(null, newArr[0])
        }
      }
      
    }
  }

  const updateNotes = (e) => {
    setNotes(e.target.value)
  }

  const handleSave = () => {
    const { amount, invoiceNumber } = state
    updateParent({ notes, purchaser, amount, invoiceNumber })
  }

  const handleUpdateResponse = (data) => {
  // console.log('Update ', data)
    setUploadDetails(data)
    // if (data && data.filesUploaded && data.filesUploaded.length) {

    // }
    // if (data && data.duplicateFiles && data.duplicateFiles.length) {
      
    // }
    // if (data && data.errorFiles && data.errorFiles.length) {
      
    // }
  }

  const handleChange = (e) => {
    setState({...state, [e.target.id]: e.target.value })
  }

  const handleFocus = (event) => event.target.select();

  const classes = useStyles()
  return (
    <div style={{ margin: 10 }}>
      <div className={classes.poDetailContainer}>
        <div className={classes.poTitleItem}>Upload A Picture of the Invoice/Receipt:</div>
        <div  className={classes.poDetailItem}>
          <FileUploadComponent po={po} updateParent={handleUpdateResponse} />
        </div>
      </div>
      <div className={classes.poDetailContainer}>
        <div className={classes.poTitleItem}>Who Purchased The Item(s)?:</div>
        <div  className={classes.poDetailItem}>
          <Autocomplete
            id={`purchaser`}
            options={employees || []}
            getOptionLabel={(option) => {
              // console.log('Options: ', option)
              return option.displayName || ''
            }}
            // style={{ width: 200 }}
            // style={{marginBottom: '20px'}}
            onChange={updatePurchaser}
            // disabled={loading}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            // value = {requestor}
            // ref={inputRef.client}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} label="Purchaser" helperText="Who Made the Actual Purchase?" onKeyDown={handleTabInteraction} variant="outlined" />}
          />
        </div>
      </div>
      <div className={classes.poDetailContainer}>
        <div className={classes.poTitleItem}>What Was the Total Cost?:</div>
        <div  className={classes.poDetailItem}>
          <TextField
            id="amount"
            label="How Much Will This Cost?"
            // style={{ margin: 8 }}
            // ref={inputRef.projectDescription}
            placeholder="Total Price"
            helperText="How Much Will This Cost?"
            fullWidth
            onFocus={handleFocus}
            onChange={handleChange}
            value={state.amount}
            margin="normal"
            variant="outlined"
            // InputLabelProps={{
            //   shrink: true,
            // }}
          />
        </div>
      </div>
      <div className={classes.poDetailContainer}>
        <div className={classes.poTitleItem}>What Is the Invoice Number?:</div>
        <div  className={classes.poDetailItem}>
          <TextField
            id="invoiceNumber"
            label="What is the Invoice Number?"
            // style={{ margin: 8 }}
            // ref={inputRef.projectDescription}
            placeholder="Invoice Number"
            helperText="What is the Reference Number?"
            fullWidth
            onChange={handleChange}
            onFocus={handleFocus}
            value={state.invoiceNumber}
            margin="normal"
            variant="outlined"
            // InputLabelProps={{
            //   shrink: true,
            // }}
          />
        </div>
      </div>
      <div className={classes.poDetailContainer}>
        <div className={classes.poTitleItem}>Notes/Details From The Purchase:</div>
        <div  className={classes.poDetailItem}>
          <TextField
            fullWidth
            variant="outlined"
            multiline
            minRows={4}
            value={notes}
            onChange={updateNotes}
            label="Notes From The Purchase"
            />
        </div>
      </div>
      <div className={classes.poDetailContainer}>
        <Button fullWidth color="primary" variant="contained" onClick={handleSave}>Save</Button>
      </div>
      <POUploads po={po} />
    </div>
  )
}

const PurchaseRequestDetails = ({ po }) => {
  const classes = useStyles()
  return (
    <div className={classes.poDetailContainer}>
      <div className={classes.poTitleItem}>Request Type:</div>
      <div  className={classes.poDetailItem}>
        {po.poType}
      </div>
      {
        po.poType === 'Client Project/Job' ? (
          <ClientProjectDetails po={po} />
        ) : null
      }
      <div className={classes.poTitleItem}>Division:</div>
      <div  className={classes.poDetailItem}>
        {po.division}
      </div> 
      <div className={classes.poTitleItem}>Preferred Vendor</div>
      <div  className={classes.poDetailItem}>
        {po.vendorName}
      </div>
      <div className={classes.poTitleItem}>Requested By:</div>
      <div  className={classes.poDetailItem}>
        {po.requestorName}
      </div> 
      <div className={classes.poTitleItem}>Needed By:</div>
      <div  className={classes.poDetailItem}>
        {moment(po.needed_by).format('dddd MM/DD/YY')}
      </div>
      <div className={classes.poTitleItem}>Request Details:</div>
      <div  className={classes.poDetailItem}>
        {po.description}
      </div> 
    </div>
  )
}

const ClientProjectDetails = ({ po }) => {
  const classes = useStyles()
  if (po && po.client) {
    return (
      <div>
        <div className={classes.poTitleItem}>Needed For:</div>
        <div  className={classes.poDetailItem}>
          {po.clientName}
          {
          po.projectName ? `    - (${po.projectName})`  : null
        }
        </div>
        
      </div>
    )
  } else {
    return (
      <div>No Client</div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    perms: state.perms,
    employees: state.employees
  }
}

export default connect(mapStateToProps)(ViewPOOnline)

