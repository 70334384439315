import React, { useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, Box } from '@material-ui/core';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import styled from 'styled-components';
import '../Common/SendToCustomer/editorStyles.css'
// import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const StyledEditor = styled.div`
  /* Targeting the figure that wraps the image */
  .rdw-image-alignment, .rdw-image-left {
    margin: 0 !important;
    padding: 0 !important;
    text-align: left; /* Ensuring the image aligns to the left */
  }

  /* Targeting the image wrapper span */
  .rdw-image-imagewrapper {
    display: block; /* Making sure the wrapper behaves as a block */
    margin: 0 !important;
    padding: 0 !important;
  }

  /* Directly targeting the img tag */
  img {
    display: block; /* Ensuring images are block elements */
    margin-left: 0 !important; /* Force image to align to the left */
    padding-left: 0 !important; /* Ensure no padding pushes it away from the left */
    height: auto; /* Adjust height as needed, or remove if setting height directly */
    width: auto; /* Adjust width as needed */
  }

  figure {
    margin-inline-start: 0 !important;
  }
  .demo-editor .public-DraftStyleDefault-block {
    margin: 0; /* Remove margin between blocks */
  }

  .demo-editor p {
    margin-top: 0px;
    margin-bottom: 2px; /* Adjust this value as needed */
  }
`;

const Container = styled.div`
  margin: 10px;
  margin-bottom: 30px;
`

function EmailSignatureBuilder(props) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContentState] = React.useState({})

  React.useEffect(() => {
    if (props.user && props.user.emailSignature) setOurSignature(props.user.emailSignature)
  }, [props])

  const setOurSignature = (sig) => {
    const html = sig
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      // console.log('231 go')
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setContentState(contentState)
      setEditorState(editorState)
    }
  }

  const handleSave = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    let markup = draftToHtml(rawContentState);
    console.log('Markup before we replace shit', markup)
    markup = markup.replace(/<p[^>]*>/g, '<p style="margin: 0; margin-left: 0px !important; padding: 0; line-height: 1.4 !important;">');
    // Here you can handle the HTML markup, for example, send it to a backend server
    console.log('Markup:::', markup);
    // let noNewLines = markup.replace(/\n/g, '')
    // console.log('No new lines', noNewLines)
    props.saveSignatue({ html: markup })
  };

  return (
    <Container>
      Customize Your Email Signature Below:
        <StyledEditor>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="myEditorClassName"
          onEditorStateChange={setEditorState}
        />
        <Box mt={2}>
          <Button variant="contained" color="secondary" fullWidth onClick={handleSave}>
            Save Signature
          </Button>
        </Box>
      </StyledEditor>
    </Container>

  );
}

export default EmailSignatureBuilder;

