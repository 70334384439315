import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles';

const DefaultLineItemContainer = styled.div`
background-color: ${props => (props.isDraggingOver ? '#34eb80' : 'inherit')};
-webkit-user-select: none;
-ms-user-select: none;
user-select: none;
min-height: 100px;
`;

const DefaultLineItemBox = styled(Grid)`
  border: ${props => ((props.isClockedIn || props.isDragging) ? '3px solid #444' : '2px solid lightgrey')};
  color: ${props => ((props.absenceType || props.isCheckedIn || props.isClockedIn)  ? '#ddd;' : '#444;')};
  border-radius: 5px;
  padding: 4px;
  margin: 2px;
  background-color: ${props => (
    props.isDragging ? '#34eb80' : 'white' )};
`;

const useStyles = makeStyles(theme => ({
  containerBoundary: {
    border: '1px solid #ccc',
    borderRadius: '1px',
    margin: '8',
    padding: 5
  },
  subContainerArea: {
    border: '1px solid #aaa',
    backgroundColor: '#eee',
    borderRadius: '5px',
    marginTop: 5,
    marginBottom: 8,
    padding: 15
  },
  headingText: {
    color: '#999',
    fontSize: '1.4em'
  },
  headingSubText: {
    color: '#999',
    fontSize: '1.2em'
  }
}))

const ScopeOfWorkList = (props) => {
// console.log('Scope of work list', props)
  const classes = useStyles()
  const [list, setList] = React.useState(props.estimate.scopeOfWork || [])

  React.useEffect(() => {
    if (props.estimate && props.estimate.scopeOfWork && props.estimate.scopeOfWork.length) {
      setList(props.estimate.scopeOfWork)
    } else {
      setList([])
    }
  }, [props.estimate])

  const onDragStart = () => {
  // console.log('Drag Start')
    document.body.style.color = 'orange';
    document.body.style.transition = 'background-color 0.2s ease'
  }

  const onDragUpdate = update => {
  // console.log('Drag Update', update)
    const { destination } = update
    const opacity = destination ? destination.index / Object.keys(list).length : 0;
    document.body.style.backgroundColor = `rgba(153, 141, 217, ${opacity})`
  }

  const onDragEnd = result => {
  // console.log('DRAG END', result)
    document.body.style.color = 'inherit';
    document.body.style.backgroundColor = 'inherit'
    // console.log('on Drag End', result)
    const { destination, source, draggableId } = result;
  // console.log('Destination: ', destination)
  // console.log('Source: ', source)
  // console.log('ID: ', draggableId)
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    let index = list.findIndex(item => (item._id === draggableId))
  // console.log('INdex', index)
    if (index > -1) {
      let itemBeingDragged = list[index]
    // console.log('Item being dragged', itemBeingDragged)
    }
  
   }
 
  function handleOnDragEnd(result) {
  // console.log('DRAG END:', result)
    if (!result.destination) return;
    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setList(items);
    props.updateScopeOfWorkOrder(items)
  }

  return (
    <div>
      <Grid container className={classes.containerBoundary}>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.headingText}>
        Scope Of Work Order
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12} className={classes.headingSubText} >
        Drag n Drop to Order the Scope of Work As Desired
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12} style={{textAlign: 'right'}} >

      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.subContainerArea}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="dropArea">
              {(provided, snapshot) => (
                <DefaultLineItemContainer className="listItem" {...provided.droppableProps} ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {list.map(({_id, qty, unit, description }, index) => {
                    return (
                      <Draggable key={_id} draggableId={_id} index={index}>
                        {(provided, snapshot) => (
                          <Grid container key={index} style={{ border: '1px #ddd solid', backgroundColor: '#eee', borderRadius: 4, padding: 4, marginTop: 5, }} 
                            ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                          >
                            <DefaultLineItemBox isDragging={snapshot.isDragging} item lg={9} xs={12} style={{ marginTop: 10 }}>{qty} {unit} {description}</DefaultLineItemBox>

                          </Grid>
                          // <li ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            
                          //   <p>
                          //     { name }
                          //   </p>
                          // </li>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </DefaultLineItemContainer>
              )}
            </Droppable>
          </DragDropContext>
      </Grid>
    </Grid>
    </div>
  );
}

export default ScopeOfWorkList
