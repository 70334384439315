import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialogTitle-root': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white
    }
  },
  button: {
    margin: theme.spacing(1)
  }
}));

const ConfirmationModal = ({ open, onClose, onConfirmUnassignment, activeSite }) => {
  const classes = useStyles();

  const onConfirmAll = () => {
    onConfirmUnassignment(true)
  }

  const onConfirmOne = () => {
    onConfirmUnassignment(false)
  }
  return (
    <Dialog open={open} onClose={onClose} className={classes.root}>
      <DialogTitle>
        Unassign Service Visit
      </DialogTitle>
      <DialogContent>
      <DialogContentText>
        {activeSite?.communityName || ''} {activeSite?.name || ''}  {activeSite?.address || ''}  {activeSite?.buildingNumber || ''}  {activeSite?.frequency || ''}
        </DialogContentText>
        <DialogContentText>
          Are you sure you wish to unassign this jobsite?
        </DialogContentText>
        
      </DialogContent>
      <DialogActions>
      <Button
          onClick={() => {
            onConfirmOne();
            // onClose();
          }}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Unassign
        </Button>
        <Button
          onClick={onClose}
          variant="outlined"
          className={classes.button}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
