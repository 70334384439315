import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
// import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SignaturePad from 'react-signature-canvas';
import AddCreditCard from '../../Customer/AddCreditCard';
import AddCustomerEcheck from '../AddECheck';
import Paper from '@material-ui/core/Paper';

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  border: 4px solid #ddd;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: stretch;
  padding: 10px;
`;

const TabsChoice = styled.div`
  min-width: 150px;
  width: 25%;
  overflow: auto;
  height: 250px;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  height: 250px;
  align-self: stretch;
  overflow-y: scroll;
  background-color: #fff;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

const ContainerArea = styled.div`
  margin: 2em;
`;

const ServicesContainer = styled.div`

`
const ServiceContainer = styled.div`
  border: 2px solid #bbb;
  border-radius: 10px;
  background-color: #ddd;
  margin: 6px;
  padding: 7px;
`

const ServiceContainerHeaderRow = styled.header`
  display: flex;
`
const ServiceContainerHeaderTitle = styled.div`
  flex-grow: 1;
  display: flex;
  font-size: 1.25em;
  font-color: #aaa;
`
const ServiceContainerHeaderAction = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
  font-size: 1.25em;
  font-color: #aaa;
`

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{width: '100%'}}>
          <Typography  component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center'
  },
  paper: {
    // width: '100%',
    marginTop: 10,
    marginBottom: theme.spacing(2),
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      textAlign: 'center',
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
      maxWidth: 960,
      textAlign: 'center'
    },
    // margin: 20
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  },
  signatureCanvas: {
    backgroundColor: 'red',
    width: '100%',
    minHeight: '300px'
  },
  buttonGroup: {
    marginBottom: '2em',
    marginTop: '2em'
  }
}));

const ListOfShit = ({ list, editItem, type, deleteItem }) => {
  console.log('List of shit: ', list)


  const handleEditService = (item, index) => {
  // console.log('Modify this: ', item)
    let ourItem = {
      service: item,
      type: type
    }
    editItem(ourItem)
  }

  const handleEditLineItem = (item, index) => {
  // console.log('Edit line item...make it the description ', item)
  }

  const handleDeleteService = (item) => {
    // console.log('Delete Item 173')
    const message = `Are you sure you wish to delete this service? ${item.name}`
    if (window.confirm(message)) deleteItem(item, type)
  }

  return (
    <ServicesContainer>
      {
        list.map((item, index) => {
        console.log('MAP SERVICE: ', item)
          // let service = item.value
          return (
            <ServiceContainer key={item._id} onDoubleClick={e => handleEditService(item, index)}>
              <ServiceContainerHeaderRow>
                <ServiceContainerHeaderTitle> Service: {item.name}  </ServiceContainerHeaderTitle>
                <ServiceContainerHeaderAction>
                  <Button
                    onClick={e => handleEditService(item, index)}
                    variant="contained"
                    color="secondary"
                    >Edit
                  </Button>
                  <Button
                    onClick={e => handleDeleteService(item)}
                    style={{marginLeft: 5, color: '#fff', backgroundColor: 'red'}}
                    variant="contained"
                    
                    >Delete
                  </Button>
                </ServiceContainerHeaderAction>
              </ServiceContainerHeaderRow>
              # of Line Items Chosen {(item.lineItems && item.lineItems.length) ? item.lineItems.length : 0 }
              {
                (item.lineItems && item.lineItems.length) ? (
                  <ul>
                    {
                      item.lineItems.map((lineItem, index) => {
                        console.log('Line Item:', lineItem)
                        return (
                          <li key={index}>{lineItem.qty}   {lineItem.name}  {lineItem.description} {lineItem.totalPrice}
                           
                          </li>
                        )
                      })
                    }
                  </ul>
                ) : null
              }
            </ServiceContainer>
          )
        })
      }
    </ServicesContainer>
  )
}

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ServiceConfiguration = (props) => {
console.log('Service Configuration PROPS:', props)
  const classes = useStyles();
  const sigPadRef = React.useRef({})
  const [state, setState] = React.useState({ 
    counter: 0,
    services: []
   })
  const [services, setServices] = React.useState([])
  let type = props.type
// console.log('Type:L ', type)
  let est = props.estimate
// console.log('Estimate w/ Details: ', est)
  let ourStuff = []
  let ourServices = []
  // const services = []
  if (props.estimate && props.estimate.serviceList && props.estimate.serviceList.length) {
    // Get only Services related to the type
    props.estimate.serviceList.map(item => {
    // console.log('Item...build our service list', item)
    // console.log(item.type)
      if (item.type === type) {
      console.log('Match', item)
        ourStuff = item.services
        // ourStuff.push(item)
        // // Now we are on the right TYPE
        // // Double Check if the SErvice Already Exists
        // if (item.value && item.value._id) {
        // // console.log('We have a value id!!')
        //   let service_id = item.value._id
        // // console.log(service_id)
        // } else {
        // // console.log('No Value _id....')
        // }
      }
      // ourStuff.push(item)
    })
  // console.log('Services: ', props.estimate.serviceList)
    // ourStuff = props.estimate.serviceList
  // console.log('OUR STUFF: ', ourStuff)
    // setServices(props.estimate.services[type])
    // setState({...state, services: props.estimate.services[type]})
    // console.log('Services: ', services)
  }

  // React.useEffect(() => {
  // // console.log('Estimate has updated on Service COnfiguraiton: ', props.estimate)
  //   // let theSvcs = props.estimate.services
  //   // console.log('The svcs: ', theSvcs)
  //   // if (theSvcs  && theSvcs[type]) {
  //   //   let specSvc = theSvcs[type] || []
  //   // // console.log('Spec SVC: ', specSvc)
  //   // }

  //   // if (props.estimate && props.estimate.services && props.estimate.services[type]) {
  //   // // console.log('set those services', props.estimate.services[type])
  //   //   setServices(props.estimate.services[type])
  //   //   setState({...state, counter: state.counter++, services: props.estimate.services[type] })
  //   //   // console.log('Services: ', services)
  //   // }
  // // console.log('Props.chosen: ', props.chosen)
  // }, [props.chosen])

  return (
    <div  >
      {/* <Paper > */}

        <DialogContentText>
          # of Services Chosen: {ourStuff ? ourStuff.length : 0}
        </DialogContentText>
        <ListOfShit list={ourStuff} type={props.type} editItem={props.handleEditService} deleteItem={props.handleDeleteService} />

      {/* </Paper> */}
    </div>
  )
}
const mapStateToProps = (state) => {
  // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
  }
}

export default connect(mapStateToProps)(ServiceConfiguration)
