import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const defaultState = {
  firstname: '',
  lastname: '',
  displayName: '',
  companyname: '',
  address: '',
  address2: '',
  city: '',
  state: '',
  zipcode: '',
  telephone: '',
  email: '',
  autopayMTC: false,
  autopayAll: false,
  contractor: false,
  HOA: false,
  doNotEmail: false,
  paperInvoice: false,
  jobSiteAddress: '',
  jobSiteCity: '',
  jobSiteZip: '',
  jobSiteIsBilling: false,
  entity: null
}

const NewEmployeeModal = (props) => {
// console.log('NewEmployeeModal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [individual, setIndividual] = React.useState(true);
  const [activeEntity, setEntity] = React.useState({})
  // const [invoice, setInvoice] = React.useState(null)
  const [state, setState] = React.useState(defaultState)

  React.useEffect(() => {
    if (props.user.defaultEntity) {
      // console.log('DEFAULT ENTITY: ', props.user.defaultEntity)
      let defaultEntity = null
      if (props.user.entities && props.user.entities.length) {
        if (props.user.entities.length > 1) {
          // console.log('Multiple Entitie', props.user.entities)
          let index = props.user.entities.findIndex(item => (item._id === props.user.defaultEntity))
          if (index > -1) {
            defaultEntity = props.user.entities[index]
          }
          // props.user.entities.map(item => {
          //   if (item._id === props.user.defaultEntity) {
          //     defaultEntity = item
          //   } 
          // })
          if (defaultEntity && defaultEntity._id) {
            // console.log('Default Entity: ', defaultEntity)
            setEntity(defaultEntity)
            setState({...state, entity: defaultEntity })
          }
        } else {
          setEntity(props.user.entities[0])
          setState({...state, entity: props.user.entities[0] })
        }

      } else {
        // console.log('No entitiees....')
      }
    } else {
      // console.log('NO Default ENTITY')
      // let defaultEntity = null
      if (props.user.entities && props.user.entities.length) {
        // console.log('Set entity', props.user.entities[0])
        setEntity(props.user.entities[0])
        setState({...state, entity: props.user.entities[0] })
      }
    }
  }, [props])

const handleClose = () => {
// console.log('Closing DepositModal Modal')
  props.closeModal()
}

const handleSave = () => {
  setLoading(true)
// console.log('Save this Client!!!', state)
  // console.log('Send this Deposit to The Server')
    let obj = {
      employee: state,
      user: props.user
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/employees/addNewEmployee`,
      data: obj
    }).then(response => {
      setLoading(false)
    // console.log('Axios response from Add addNewEmployee: ', response)
      if (response && response.data && response.data._id) {
      // console.log('We have a new client!!', response.data)
        props.notification({
          type: 'success',
          title: 'Employee Created!',
          message: 'Employee Was Created Successfully'
        })
        setState(defaultState)
        // handleClose('success', response.data)
        props.updateParent({employee: response.data})
      } else {
        props.notification({
          type: 'warning',
          title: 'Unknown Error',
          message: 'We Do Not Know Why This Shows Up...'
        })
      }
  })
}

const handleUpdateVisibleEntity = (activeEntity) => {
  // console.log('Entity to assigN: ', activeEntity)
  setEntity(activeEntity)
  setState({...state, entity: activeEntity._id })
}

return (
  <div className={classes.root}>
    <Dialog open={props.open} fullWidth={false} onClose={handleClose} maxWidth='sm' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Employee</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Provide Employee Information Below
          </DialogContentText>
          <Grid container>
                  <Grid item lg={12}>
                      <h3>Employee Information</h3>
                    </Grid>
                    {
                      (props.user && props.user.entities && props.user.entities.length > 1) ? (
                        <React.Fragment>
                          <DialogContentText>
                            Please Choose The Customer's Entity
                          </DialogContentText>
                          <Grid item xs={12} lg={12} style={{padding: 10}}>
                            <ChooseEntityComponent allowAll={false} defaultEntity={activeEntity} title={'Assigned Entity'} user={props.user} updateParent={handleUpdateVisibleEntity} />
                              { (activeEntity && activeEntity._id) ? (
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                  <img alt="Logo" src={`${process.env.PUBLIC_URL}/${activeEntity._id}_Logo.png`} style={{margin: '5px', padding: '5px', maxWidth: 220, maxHeight: 100}} /> 
                                </div>
                                
                              ) : '' }
                          </Grid>
                        </React.Fragment>
                    
                      ) : (
                        (activeEntity && activeEntity._id) ? (
                          <div style={{display: 'flex', justifyContent: 'center'}}>
                            <img alt="Logo" src={`${process.env.PUBLIC_URL}/${activeEntity._id}_Logo.png`} style={{margin: '5px', padding: '5px', maxWidth: 220, maxHeight: 100}} /> 
                          </div>
                          
                        ) : '' 
                      )
                    }
                    <Grid item lg={6} xs={6} className={classes.gridItem}> 
                          <TextField
                            autoFocus
                            label="First Name"
                            onChange={ e => setState({...state, firstname: e.target.value })}
                            name="firstname"
                            value={ state.firstname }
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={6} xs={6} className={classes.gridItem}> 
                                    
                          <TextField
                          
                            label="Last Name"
                            onChange={ e => setState({...state, lastname: e.target.value })}
                            name="lastname"
                            value={ state.lastname }
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                    <Grid item lg={12}>

             <TextField
                className={classes.paddedStuff}
                margin="dense"
                id="Email"
                label="Email"
                type="text"
                onChange={(e) => setState({...state, email: e.target.value})}
                value={state.email}
                fullWidth
            />
             <TextField
                className={classes.paddedStuff}
                margin="dense"
                id="Telephone"
                label="Telephone Number"
                type="telephone"
                onChange={(e) => setState({...state, telephone: e.target.value})}
                value={state.telephone}
                fullWidth
            />
            </Grid>
            
            <Grid item lg={12} xs={12} className={classes.gridItem}> 
            <strong>Address</strong>
                        <TextField
                      label="Address"
                      fullWidth
                      value= {state.address}
                        onChange={ e => setState({...state, address:e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />

                    </Grid>
                    <Grid item lg={6} xs={6} className={classes.gridItem}>
                      <TextField
                        label="City"
                        fullWidth
                        value= {state.city}
                        onChange={ e => setState({...state, city:e.target.value } ) }
                        // variant="outlined"
                        // value= {state.service.time}
                        // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                        />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                      <TextField
                        label="State"
                        fullWidth
                        value= {state.state}
                        onChange={ e => setState({...state, state:e.target.value } ) }
                      />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                    <TextField
                        label="Zipcode"
                        fullWidth
                        // type="number"
                        // variant="outlined"
                        value= {state.zipcode}
                        onChange={ e => setState({...state, zipcode:e.target.value } ) }
                        />
                    </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading || !state.firstname || !state.lastname } onClick={handleSave}>
            {
              loading ? <CircularProgress /> : 'Save'
            }
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(NewEmployeeModal)