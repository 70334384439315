import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { fade, makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  textSaveLineBreaks: {
    whiteSpace: 'pre-line'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

let requiresUpdate = 0;

const defaultStateValues = {
  accountType: 'checking',
  routingNumber: '',
  focused: '',
  nameOnAccount: '',
  accountNumber: '',
  eCheckType: 'PPD',
  bankName: '',
  firstname: '',
  lastname: '',
  companyname: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  client: {},
  customerType: ''
}

function InputECheckDetailsForm(props) {
// console.log('add eCheck', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [individual, setIndividual] = React.useState(true);
  const [locationName, setLocationName] = React.useState('');
  const [saveCardDetails, setSaveCardDetails] = React.useState(false)
  const [state, setState] = React.useState(defaultStateValues);
  const [formIsValid, setFormIsValid] = React.useState(false);

  const checkIfValidForm = () => {
    // console.log('STATE::', state)
    // Example condition: checks if all necessary state fields are filled and valid   
    let nameIsValid = false
    if (individual) {
      if (state.firstname.trim() !== '' && state.lastname.trim() !== '') nameIsValid = true
    } else {
      if (state.companyname.trim() !== '') nameIsValid = true
    }
    return (
      nameIsValid &&
      state.address.trim() !== '' &&
      state.city.trim() !== '' &&
      state.state.trim() !== '' &&
      state.zipcode.trim() !== '' &&
      state.bankName.trim() !== '' &&
      state.accountNumber.trim() !== '' &&
      state.routingNumber.trim() !== ''
    );
  };
  
  // Using useEffect to update the form's validity state whenever relevant state changes
  useEffect(() => {
    setFormIsValid(checkIfValidForm());
  }, [state.firstname, state.lastname, state.companyname, state.address, state.city, state.state, state.zipcode, state.bankName, state.accountNumber, state.routingNumber]);

  
  const handleClick = (d) => {
  // console.log('Save Card Info', d)
    let data = {...state}
    data.saveCardDetails = saveCardDetails
    props.updateParent(data)
  };

  const divStyle = {
    // margin: '40px',
    // border: '5px solid pink',
    display: 'inline'
  };

  const onSubmit = async values => {
  // console.log(values)
  // console.log(state)
  }

  const handleSubmit =  async (e) => {
  // console.log('Handle submit: ', e)
  let data = {
    ...state,
    individual,
    saveCardDetails
  }
  props.updateParent(data)
  }

  return (
    <div style={ divStyle }>


          <ValidatorForm
                id="myForm"
                // ref="myForm"
                onSubmit={ handleSubmit }
                onError={ errors => console.log(errors) }
                // className={ styles.content }
              >

                <Grid container>
                <Grid item lg={12} xs={12} className={classes.gridItem}> 
                  Choose Individual or Business: <br />
                  <FormControlLabel
                        control={
                          <Switch
                            checked={individual}
                            onChange={() => setIndividual(!individual)}
                            name="Individual"
                            color="primary"
                          />
                        }
                        label={individual ? 'Individual' : 'Business'}
                      />
                  </Grid>
                  { individual ? 
                    (
                      <React.Fragment>
                      <Grid item lg={6} xs={12} className={classes.gridItem}> 
                          <TextValidator
                            label="Accountholder First Name"
                            onChange={ e => setState({...state, firstname: e.target.value })}
                            name="cardholderfirstname"
                            value={ state.firstname }
                            validators={[
                              'required',
                              'minStringLength:2',
                              'maxStringLength:100'
                            ]}
                            errorMessages={[
                              'this field is required',
                              'min 2 characters',
                              'max 100 characters'
                            ]}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={6} xs={12} className={classes.gridItem}> 
                                    
                          <TextValidator
                            label="Accountholder Last Name"
                            onChange={ e => setState({...state, lastname: e.target.value })}
                            name="cardholderlastname"
                            value={ state.lastname }
                            validators={[
                              'required',
                              'minStringLength:2',
                              'maxStringLength:100'
                            ]}
                            errorMessages={[
                              'this field is required',
                              'min 2 characters',
                              'max 100 characters'
                            ]}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        </React.Fragment>
                      ) : (
                        <Grid item lg={12} xs={12} className={classes.gridItem}> 
                                    
                          <TextValidator
                            label="Company Name"
                            onChange={ e => setState({...state, companyname: e.target.value })}
                            name="companyname"
                            value={ state.companyname }
                            validators={[
                              'required',
                              'minStringLength:2',
                              'maxStringLength:100'
                            ]}
                            errorMessages={[
                              'this field is required',
                              'min 2 characters',
                              'max 100 characters'
                            ]}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                      )
                    }
                    <Grid item lg={12} xs={12} className={classes.gridItem}> 
                        <TextField
                      label="Billing Address"
                      fullWidth
                      value= {state.address}
                        onChange={ e => setState({...state, address:e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />
                    </Grid>
                    <Grid item lg={6} xs={12} className={classes.gridItem}>
                      <TextField
                        label="City"
                        fullWidth
                        value= {state.city}
                        onChange={ e => setState({...state, city:e.target.value } ) }
                        // variant="outlined"
                        // value= {state.service.time}
                        // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                        />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                      <TextField
                        label="State"
                        fullWidth
                        value= {state.state}
                        onChange={ e => setState({...state, state:e.target.value } ) }
                      />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                    <TextField
                        label="Zip"
                        fullWidth
                        // type="number"
                        // variant="outlined"
                        value= {state.zipcode}
                        onChange={ e => setState({...state, zipcode:e.target.value } ) }
                        />
                    </Grid>

                    <Grid item lg={12} xs={12} className={classes.gridItem}>
                        <TextValidator
                      label="Bank Name"
                      onChange={ e => setState({...state, bankName:e.target.value } ) }
                      name="bankName"
                      value={ state.bankName }
                      validators={[
                        'required',
                        'minStringLength:3',
                        'maxStringLength:50'
                      ]}
                      errorMessages={[
                        'this field is required',
                        'min 3 characters',
                        'max 50 characters'
                      ]}
                      margin="normal"
                      fullWidth
                    />
                    </Grid>
                    <Grid item lg={6} xs={12} className={classes.gridItem}>
                      
                <TextValidator
                  label="Routing Number"
                  onChange={ e => setState({...state, routingNumber:e.target.value } ) }
                  name="routingNumber"
                  value={ state.routingNumber }
                  validators={[
                    'required',
                    'minStringLength:9',
                    'maxStringLength:9'
                  ]}
                  errorMessages={[
                    'this field is required',
                    'min 9 characters',
                    'max 9 characters'
                  ]}
                  margin="normal"
                  fullWidth
                />
                
                    </Grid>
                    <Grid item lg={6} xs={12} className={classes.gridItem}> 
                    <TextValidator
                  label="Account Number"
                  onChange={ e => setState({...state, accountNumber:e.target.value } ) }
                  name="accountNumber"
                  value={ state.accountNumber }
                  validators={[
                    'required',
                    'minStringLength:3',
                    'maxStringLength:17'
                  ]}
                  errorMessages={[
                    'this field is required',
                    'min 3 characters',
                    'max 17 characters'
                  ]}
                  margin="normal"
                  fullWidth
                /> 
                    </Grid>
               
              
               
                
                </Grid>

                
                
          </ValidatorForm>
          {/* <p>
              <FormControlLabel
                    control={<Checkbox checked={saveCardDetails} onChange={ e => setSaveCardDetails(!saveCardDetails) } name="saveDetails" />}
                    label="Save Payment Details (We Will Never Charge Your Account Without Your Permission)"
                  />
            </p> */}
          <p>
            <Button variant="contained" fullWidth color="secondary" disabled={!formIsValid} onClick={handleSubmit}>
              {
                    loading ?
                    <CircularProgress color="primary" size={ 24 } /> : 'SUBMIT'
              }
            </Button>
            </p>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(InputECheckDetailsForm))