import React from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  prettyText: {
    fontSize: '1.5em',
    color: '#56b349'
  }
}));

const PostBulkPaymentsModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(props.open);
  const [invoices, setInvoices] = React.useState([]);
  const [range, setRange] = React.useState(30);
  const [startDate, setStartDate] = React.useState(moment(new Date()).subtract(30, 'days'));
  const [endDate, setEndDate] = React.useState(new Date());
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [totalCharge, setTotalCharge] = React.useState(0);
  const [invoicesToPay, setInvoicesToPay] = React.useState([]);
  const [paymentTotal, setPaymentTotal] = React.useState(0);
  const [paymentNumber, setPaymentNumber] = React.useState('');
  const [state, setState] = React.useState({
    invoices: []
  });
  
  // Add ref to track previous client ID
  const previousClientId = React.useRef(props.client?._id);

  const getInvoicesForClient = async (client) => {
    if (!client?._id) return;
    
    setLoading(true);
    try {
      const response = await axios.get(`${props.url}/api/invoice/getAllClientInvoices`, {
        params: {
          due: true,
          id: client._id
        }
      });

      if (response?.data) {
        setInvoices(response.data);
        setState(prev => ({ ...prev, invoices: response.data }));
      } else {
        throw new Error('No data received');
      }
    } catch (error) {
      console.error('Error fetching invoices:', error);
      props.notification({
        type: 'warning',
        title: 'We Ran Into A Problem!',
        message: 'An Error Occurred Trying to Get Invoices from Arbor Gold - please hang tight while we attempt to resolve it.'
      });
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const clientChanged = previousClientId.current !== props.client?._id;
    
    if (clientChanged && props.client?._id) {
      console.log('Client changed, fetching new invoices');
      getInvoicesForClient(props.client);
    }
    
    // Update the previous client ID reference
    previousClientId.current = props.client?._id;
  }, [props.client?._id]);

const handleChange = (event) => {
  setRange(event.target.value);
  let newStartDate = moment().subtract(event.target.value, 'days')
  // console.log('New start Date', newStartDate)
  setStartDate(newStartDate)
  setOpen(false);
};

const handleClose = () => {
  // setOpen(false);
  setInvoicesToPay([])
  setTotalCharge(0)
  // props.onUpdate()
  setPaymentNumber('')
  setPaymentTotal(0)
  // setState({...state, invoices: []})
  props.closeModal()
}

const handleOpen = () => {
  setOpen(true);
};

const handleSubmit = async () => {
  // console.log('Submit this bulk Payment!!')
  setLoading(true)
  let numberToPay = invoicesToPay.length
  if (window.confirm(`Are you sure you wish to pay these ${numberToPay} invoices for ${currencyFormat(totalCharge)}?`)) {
    // let checkNumber = window.prompt('Please Enter the Check Number:')
    // console.log('CHeck Number: ', checkNumber)
    // if (checkNumber === null) {
    //   // console.log('Cancelled out')
    //   setLoading(false)
    // } else {
      let data = {
        invoicesToPay: invoicesToPay,
        user: props.user,
        checkNumber: paymentNumber,
        client: props.client,
        paymentNumber: paymentNumber,
        paymentTotal: paymentTotal,
        prettyTotal: currencyFormat(paymentTotal),
        totalCharge: totalCharge
      }
      // console.log('Data to send: ', data)
      const response = await axios.post(`${props.url}/api/payment/makeBulkPayment`, data)
      // console.log('Paid Bulk Invoices!!', response)
      setLoading(false)
      if (response && response.data) {
        props.notification({
          type: 'success',
          title: 'Payments Made!',
          message: 'Payments Were Created and Invoices Were Paid!'
        })
        handleClose()
      } else {
        // console.log('Something went wrong')
        props.notification({
         type: 'warning',
         title: 'We Ran Into A Problem!',
         message: 'An Error Occurred E1234.'
       })
      }
    // }
  }
}

// const handleCloseModal = () => {
//   props.closeModal()
// }
const handleClear = () => {
  setInvoicesToPay([])
  setPaymentTotal(0)
  let newInvoices = state.invoices
  for (const inv of newInvoices) {
    inv.selected = false
  }
  setState({...state, invoices: newInvoices })
  setTotalCharge(0)
}

const handleSelect = (invoice) => {
  // console.log('Select: ', invoice)
  // console.log('Payment Total ', paymentTotal)
  let totalSelected = totalCharge
  // console.log('Total Payment Amount', totalCharge)
  let leftOver = paymentTotal - totalCharge
  let selectedInvoices = invoicesToPay
  // console.log('Selected Invoices: ', selectedInvoices)
  // console.log('Total Amount Charged %d - total Payment Amount %d--- left over %d', totalCharge, paymentTotal, leftOver)
  if (invoice.selected) {
    invoice.selected = false
    if (invoice.partial) {
      totalSelected = totalSelected - invoice.partialPaymentAmount
      invoice.partial = false
      invoice.partialPaymentAmount = null
    } else {
      totalSelected = totalSelected - invoice.balance
    }
    // console.log('New Total Charge', totalSelected)
    if (totalSelected < 0.10) totalSelected = 0
    let index = selectedInvoices.findIndex(item =>  (invoice._id === item._id ));
    // console.log('Index: ', index)
    if (index > -1) selectedInvoices.splice(index, 1)
    // console.log('Selected Invoices: ', selectedInvoices)
    // let invoiceSelected = selectedInvoices[index]
    // selectedInvoices = selectedInvoices.splice(index, 1)
    setInvoicesToPay(selectedInvoices)

    // console.log('Total Charge: ', totalSelected)
    setTotalCharge(totalSelected)
  } else {
    if (leftOver > 0) {
      // console.log('We have money!!!')
      invoice.selected = true
      totalSelected = totalSelected - 0 + invoice.balance
      if (totalSelected < 0.10) totalSelected = 0
      selectedInvoices.push(invoice)
      if (totalSelected > paymentTotal) {
        // Partial Payment on this one...unless available amount is $0
        if (paymentTotal - totalCharge > 0) {
          let partialPaymentAmount = paymentTotal - totalCharge
          // console.log('This gives us a partial payment amount of ', partialPaymentAmount)
          totalSelected = totalCharge - 0 + partialPaymentAmount
            // setInvoices(invoices)
            // console.log('Invoices: ', invoices)
            // console.log('Invoices to pay: ', invoicesToPay)
            invoice.selected = true
            invoice.partial = true
            invoice.partialPaymentAmount = partialPaymentAmount
            // totalSelected = totalSelected - 0 + invoice.balance
            // selectedInvoices.push(invoice)
            setInvoicesToPay(selectedInvoices)
            // console.log('Total Charge: ', totalSelected)
            setTotalCharge(totalSelected)
        } else {
          props.notification({
            type: 'warning',
            title: 'We Cannot Do This...',
            message: `You Have Spent All of Your Money`
          })
        }
    
            // let newInvoices = invoices
            // let index = newInvoices.findIndex(item => item._id === invoice._id)
            // console.log('Index of our selected item: ', index)
            // if (index > -1) newInvoices[index]['selected'] = false
            // console.log('New Invocie: ', newInvoices)
            // setState({...state, invoices: newInvoices})
    
        // setLoading(false)
      } else {
        setInvoicesToPay(selectedInvoices)
        // console.log('Total Charge: ', totalSelected)
        setTotalCharge(totalSelected)
        // console.log(invoice.selected)
        // console.log('Invoiecs to Pay: ', selectedInvoices)
      }
    } else {
      // console.log('You got now money!')
      props.notification({
        type: 'warning',
        title: 'We Cannot Do This...',
        message: `You Have Spent All of Your Money`
      })
    }
  }
}

function currencyFormat(num) {
  // console.log('Currencyformat: ', num)
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const PaymentDetails = ({ invoices }) => {
  // if (paymentTotal && paymentNumber) {
   return (
    <>
    <Grid item lg={12} xs={12} className={classes.prettyText}>
        Total Charge: {currencyFormat(totalCharge)}
      </Grid>
        <Grid item lg={12} xs={12}>
          <h3>Please choose the invoices you'd like to cover with this payment:</h3>
      </Grid>
      <Grid item lg={12} xs={12}>
        <div>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  Invoice Number
                </TableCell>
                <TableCell>
                  Invoice Date
                </TableCell>
                <TableCell>
                  Job Name (Number)!!
                </TableCell>
                <TableCell>
                  Invoice Amount
                </TableCell>
                <TableCell>
                  Invoice Balance
                </TableCell>
                <TableCell>
                  Total Due
                </TableCell>
                <TableCell>
                  Select
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.invoices.map(invoice => (
                  <TableRow key={invoice._id}>
                  <TableCell>
                    {invoice.invoiceNumber}
                  </TableCell>
                  <TableCell>
                    {moment(invoice.created_at).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>
                    {invoice.jobName} ({invoice.jobNumber})
                  </TableCell>
                  <TableCell>
                    {currencyFormat(invoice.amount)}
                  </TableCell>
                  <TableCell>
                  {currencyFormat(invoice.balance)}
                  </TableCell>
                  <TableCell>
                  {currencyFormat(invoice.totalDue)}
                  </TableCell>
                  <TableCell>
                      <FormControlLabel
                        control={<Checkbox checked={invoice.selected} onChange={ e => handleSelect(invoice) } name="Selected" />}
                        label={invoice.selected ? 'Included' : 'Select'}
                      />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </div>
      </Grid>
      </>
    )
  // } else {
  //   return null
  // }
}
  return (
    <div>
        <Dialog
          // fullScreen={true}
          maxWidth='lg'
          fullWidth
          open={props.open}
          onClose={handleClose}
          // PaperProps={{
          //   style: {
          //     overflow: 'hidden',
          //     height: '500px'
          //   }}}
          aria-labelledby="responsive-dialog-title"
          >
          <DialogTitle id="responsive-dialog-title">Post Payment(s) Options</DialogTitle>
          <DialogContent>
          <Grid container spacing={1}>
            <Grid item lg={6}>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  Please Enter the Total Amount of the Payment:
                </Grid>
                <Grid item lg={12}>
                  <TextField
                    label="Payment Amount"
                    variant="outlined"
                    type="number" min="0" step="0.01"
                    fullWidth
                    helperText="Amount of the Entire Payment"
                    // disabled={state.isInvoiced || state.isComplete}
                    value= {paymentTotal}
                    // onClick={ handleClear }
                    onChange={ e => setPaymentTotal(Number(e.target.value)) }
                    // defaultValue="Default Value"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6}>
                
                <Grid container spacing={2}>
                  <Grid item lg={12}>
                    Please Enter the Check Number/PO For this Payment:
                  </Grid>
                  <Grid item lg={12}>
                    <TextField
                      label="Payment ID"
                      helperText="Check Number/Direct Deposit ID"
                      variant="outlined"
                      type="Text"
                      fullWidth
                      // disabled={state.isInvoiced || state.isComplete}
                      value= {paymentNumber}
                      onChange={ e => setPaymentNumber(e.target.value) }
                      // defaultValue="Default Value"
                    />
                  </Grid>
                </Grid>
              </Grid>
          </Grid>

            <Grid container spacing={2}>
              <PaymentDetails invoices={invoices} />
            </Grid>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit()} color="primary">
            { loading ? <CircularProgress color="primary" size={ 24 } />  : '' } Submit
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
    </div>
  )
 

}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(PostBulkPaymentsModal))