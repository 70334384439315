import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';


const ConfigurationContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
align-content: center;
text-align: center;
border-radius: 15px;
margin-top: 25px;
width: 100%;
height: 100%;
`;

const LabelText = styled.div`
  color: #222;
  font-size: 16px;
  text-align: left;
`;

const defaultValues = {
  qty: 0,
  unit: '',
  description: '',
  totalPrice: 0,
  costPer: 0,
  pricePer: 0,
  counter: 0,
  editing: false,
  inventoryItemId: null
}

const unitsOfMeasureAll = [
  {
    name: 'Each'
  },
  {
    name: 'Pint'
  },
  {
    name: 'Quart'
  },
  {
    name: '1 Gallon'
  },
  {
    name: '2 Gallon'
  },
  {
    name: '3 Gallon'
  },
  {
    name: '5 Gallon'
  },
  {
    name: '10 Gallon'
  },
  {
    name: '15 Gallon'
  },
  {
    name: '25 Gallon'
  },
  {
    name: 'Bare Root'
  },
  {
    name: '24" Box'
  },
  {
    name: '30" Box'
  },
  {
    name: '36" Box'
  },
  {
    name: '42" Box'
  },
  {
    name: '48" Box'
  },
  {
    name: '54" Box'
  },
  {
    name: '60" Box'
  },
  {
    name: `70" Box`,
    label: `70" Box`
  },
  {
    name: `72" Box`,
    label: `72" Box`
  },
  {
    name: `78" Box`,
    label: `78" Box`
  },
  {
    value: `84" Box`,
    label: `84" Box`
  },
  {
    name: 'Square Foot'
  },
  {
    name: 'Linear Foot'
  },
  {
    name: 'Flat'
  },
  {
    name: 'Ton'
  }
]

const ConfigureItem = (props) => {
  // console.log('CONFIGURE ITEM42: ', props)
  const [state, setState] = React.useState(defaultValues)

  React.useEffect(() => {
  // console.log('CONFIG INPUT USEEFFECT - Set Quantity and shit here', props)
    let newState = {}
    let templatedDescription =null
    if (props.service && props.service.service && props.service.service.lineItemTemplate) {
      templatedDescription = props.service.service.lineItemTemplate
    }
    if (props.item && props.item.templatedDescription) templatedDescription = props.item.templatedDescription
  // console.log('Templated Description: ', templatedDescription)
    // SEt Inventory Id
  // console.log('130 ****')
    if (props.item && props.item._id) {
    // console.log('132 **** has _Id')
      if (props.item._id.includes('uuid')) {
      // console.log('134 isnt an inventory item...****')
      // console.log('We have an item to edit!!', props.item)
        let qty = 0
        let pricePer = 0
        let costPer = 0
        let totalPrice = 0
        if (props.item.qty) qty = props.item.qty
        if (props.item.pricePer) pricePer = Number(props.item.pricePer)
        if (props.item.costPer) costPer = Number(props.item.costPer)
        // if (props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length === 1) {
        // pricePer = Number(costPer * 1.4).toFixed(2)
        totalPrice = Number(props.item.totalPrice)
      // console.log('Set QTY: %d - - Cost: %d    Price: %d   Total: %d', qty, costPer, pricePer, totalPrice)
        newState.qty = qty
        newState.unit = props.item.unit
        newState.description = props.item.description
        newState.costPer = costPer
        newState.pricePer = pricePer
        newState.totalPrice = totalPrice
        newState.templatedDescription = templatedDescription
        newState.editing = true
      // console.log('New State', newState)
      // console.log('Total Price', totalPrice)
      // console.log('Newstate total price', newState.totalPrice)
      } else {
      // console.log('156 Inventory Item ****', props)
        let totalPrice = 0
        let pricePer = 0
        let costPer = props.item.price || 0
        pricePer = Number(costPer * 1.4).toFixed(2)
        totalPrice = Number(pricePer * state.qty).toFixed(2)
        // newState.qty = props.state.qty
        // newState.unit = props.state.unit
        newState.description = props.item.description
        newState.costPer = costPer
        newState.pricePer = pricePer
        newState.totalPrice = totalPrice
        // newState.templatedDescription = templatedDescription
        newState.inventoryItemId = props.item._id
      // console.log('New State::', newState)
      }
    }
  // console.log('160 ****')
    if (props.item.inventoryItemId) {
    // console.log('162 ****')
      newState.inventoryItemId = props.item.inventoryItemId
    // console.log('New State', newState)
    // console.log('Newstate total price', newState.totalPrice)
    }
    if (props.item && props.item.sku) {
    // console.log('166 ****', props)
      // console.log('CONFIGURATION 48', props)
      
      if (props.state && props.state.qty) {
      // console.log('170 ****')
        let pricePer = props.state.pricePer
        let totalPrice = 0
        let costPer = props.item.price || 0
        pricePer = Number(costPer * 1.4).toFixed(2)
        totalPrice = Number(pricePer * props.state.qty).toFixed(2)
        newState.qty = props.state.qty
        newState.unit = props.state.unit
        newState.description = props.item.description
        newState.costPer = costPer
        newState.pricePer = pricePer
        newState.totalPrice = totalPrice
        newState.templatedDescription = templatedDescription
      } else {
      // console.log('184 ****')
        // console.log('CONFIGURATION 57', props)
        let pricePer = 0
        let totalPrice = 0
        let costPer = props.item.price || 0
        pricePer = Number(costPer * 1.4).toFixed(2)
        totalPrice = Number(pricePer * state.qty).toFixed(2)
        newState.description = props.item.description
        newState.costPer = costPer
        newState.pricePer = pricePer
        newState.totalPrice = totalPrice
        newState.templatedDescription = templatedDescription
      }
    } else if (props.state && props.state.qty) {
    // console.log('198 ****')
      // console.log('CONFIGURATION 68', props.state)
        let pricePer = props.state.pricePer
        let totalPrice = 0
        let costPer = props.item.price || 0
        pricePer = Number(costPer * 1.4).toFixed(2)
        totalPrice = Number(pricePer * props.state.qty).toFixed(2)
        // setState({...state, qty: props.state.qty, unit: props.state.unit, description: props.item.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice })
        if (props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length === 1) {
        // console.log('207 ****')
          // console.log('CONFIGURATION 72', props)
          newState.qty = props.state.qty
          newState.unit = props.state.unit
          newState.description = props.item.description
          newState.costPer = costPer
          newState.pricePer = pricePer
          newState.totalPrice = totalPrice
          newState.templatedDescription = templatedDescription
          // setState({...state, qty: props.state.qty, unit: props.state.unit, description: props.state.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice, templatedDescription: templatedDescription })
        } else if (props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length < 1) {
          // console.log('CONFIGURATION 74', props)
        // console.log('218 ****')
          newState.qty = props.state.qty
          newState.unit = 'EA'
          newState.description = props.item.description
          newState.costPer = costPer
          newState.pricePer = pricePer
          newState.totalPrice = totalPrice
          newState.templatedDescription = templatedDescription
          // setState({...state, unit: 'EA', description: props.item.description, qty: props.state.qty, description: props.item.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice, templatedDescription: templatedDescription})
        } else {
        // console.log('229 ****')
          // console.log('CONFIGURATION 76', props)
          newState.qty = props.item.qty
          newState.unit = props.item.unit
          newState.description = props.item.description
          newState.costPer = props.item.costPer
          newState.pricePer = props.item.pricePer
          newState.totalPrice = props.item.totalPrice
          newState.templatedDescription = templatedDescription
          // setState({...state, qty: props.item.qty, unit: props.item.unit, description: props.item.description, costPer: props.item.costPer, pricePer: props.item.pricePer, totalPrice: props.item.totalPrice, templatedDescription: templatedDescription})
        }
        
      } else {
      // console.log('242 ****', props)
        // console.log('CONFIGURATION 87', props)
        // let pricePer = 0
        // let totalPrice = 0
        // let costPer = props.item.price || 0
        // pricePer = Number(costPer * 1.4).toFixed(2)
        // totalPrice = Number(pricePer * state.qty).toFixed(2)
        // // newState.qty = props.item.qty
        // // newState.unit = props.item.unit
        // newState.description = props.item.description
        // newState.costPer = costPer
        // newState.pricePer = pricePer
        // newState.totalPrice = totalPrice
        // newState.templatedDescription = templatedDescription
        // setState({...state, description: props.item.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice, templatedDescription: templatedDescription })
      }
    // console.log('258 ****')
  // console.log('**** ***   UseEffectCurrent State', state)
  // console.log('UseEffect State Update', newState)
    setState({...state, ...newState })
  }, [props.item])


  // React.useEffect(() => {
  // // console.log('CONFIG INPUT USEEFFECT - Set Quantity and shit here', props)
  //   let templatedDescription =null
  //   if (props.service && props.service.service && props.service.service.lineItemTemplate) {
  //     templatedDescription = props.service.service.lineItemTemplate
  //   }
  // // console.log('Templated Description: ', templatedDescription)
  //   if (props.item && props.item.templatedDescription) templatedDescription = props.item.templatedDescription
  //   if (props.item && props.item.sku) {
  //     // console.log('CONFIGURATION 48', props)
      
  //     if (props.state && props.state.qty) {
  //       let pricePer = props.state.pricePer
  //       let totalPrice = 0
  //       let costPer = props.item.price || 0
  //       pricePer = Number(costPer * 1.4).toFixed(2)
  //       totalPrice = Number(pricePer * props.state.qty).toFixed(2)
  //       setState({...state, qty: props.state.qty, unit: props.state.unit, description: props.item.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice, templatedDescription: templatedDescription })
  //     } else {
  //       // console.log('CONFIGURATION 57', props)
  //       let pricePer = 0
  //       let totalPrice = 0
  //       let costPer = props.item.price || 0
  //       pricePer = Number(costPer * 1.4).toFixed(2)
  //       totalPrice = Number(pricePer * state.qty).toFixed(2)
  //       setState({...state, description: props.item.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice, templatedDescription: templatedDescription })
  //     }
  //   } else if (props.item && props.item._id && props.item._id) {
  //     if (props.item._id.includes('uuid')) {
  //     // console.log('We have an item to edit!!', props.item)
  //       let qty = 0
  //       let pricePer = 0
  //       let costPer = 0
  //       let totalPrice = 0
  //       if (props.item.qty) qty = props.item.qty
  //       if (props.item.pricePer) pricePer = Number(props.item.pricePer)
  //       if (props.item.costPer) costPer = Number(props.item.costPer)
  //       // if (props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length === 1) {
  //       // pricePer = Number(costPer * 1.4).toFixed(2)
  //       totalPrice = Number(props.item.totalPrice)
  //       // console.log('Set QTY: %d - - Cost: %d    Price: %d   Total: %d', qty, costPer, pricePer, totalPrice)
  //       setState({...state, editing: true, qty: props.item.qty, unit: props.item.unit, description: props.item.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice, templatedDescription: templatedDescription})
  //     } else {
  //     // console.log('Update Item w/ an intentory Item on it!!!', props.item._id)
  //       setState({...state, inventoryItemId: props.item._id, description: props.item.description })
    
  //       // setState({ ...state, inventoryItemId: props.item._id })
  //     }
  //     // console.log('CONFIGURATION 72', props)
  //     //   setState({...state,  qty: qty, unit: props.item.unitsOfMeasure[0], description: props.item.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice })
  //     // } else if (props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length < 1) {
  //     //   // console.log('CONFIGURATION 74', props)
  //     //   setState({...state, unit: 'EA', description: props.item.description, qty: props.item.qty, description: props.item.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice})
  //     // } else {
  //     //   // console.log('CONFIGURATION 76', props)
  //     //   setState({...state, qty: props.item.qty, unit: props.item.unit, description: props.item.description, costPer: props.item.costPer, pricePer: props.item.pricePer, totalPrice: props.item.totalPrice})
  //     // }
  //   } else {
  //     // console.log('CONFIGURATION 66', props)
  //     if (props.state && props.state.qty) {
  //       // console.log('CONFIGURATION 68', props)
  //       let pricePer = props.state.pricePer
  //       let totalPrice = 0
  //       let costPer = props.item.price || 0
  //       pricePer = Number(costPer * 1.4).toFixed(2)
  //       totalPrice = Number(pricePer * props.state.qty).toFixed(2)
  //       // setState({...state, qty: props.state.qty, unit: props.state.unit, description: props.item.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice })
  //       if (props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length === 1) {
          
  //         // console.log('CONFIGURATION 72', props)
  //         setState({...state, qty: props.state.qty, unit: props.state.unit, description: props.state.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice, templatedDescription: templatedDescription })
  //       } else if (props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length < 1) {
  //         // console.log('CONFIGURATION 74', props)
  //         setState({...state, unit: 'EA', description: props.item.description, qty: props.state.qty, description: props.item.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice, templatedDescription: templatedDescription})
  //       } else {
  //         // console.log('CONFIGURATION 76', props)
  //         setState({...state, qty: props.item.qty, unit: props.item.unit, description: props.item.description, costPer: props.item.costPer, pricePer: props.item.pricePer, totalPrice: props.item.totalPrice, templatedDescription: templatedDescription})
  //       }
  //     } else {
  //       // console.log('CONFIGURATION 87', props)
  //       let pricePer = 0
  //       let totalPrice = 0
  //       let costPer = props.item.price || 0
  //       pricePer = Number(costPer * 1.4).toFixed(2)
  //       totalPrice = Number(pricePer * state.qty).toFixed(2)
  //       setState({...state, description: props.item.description, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice, templatedDescription: templatedDescription })
  //     }
  //   }

  // }, [props.item])

  const handleSaveNewLineItem = () => {
    // console.log('Save Line Item', state)
    // Add this to our Estimate
    let itemToSend = {
      ...state
    }
    itemToSend.item = props.item
    // setState({...state, ...defaultValues })
    props.addLineItemToEstimate(itemToSend)
  }

  const handleUpdateExistingLineItem = () => {
  // console.log('Update: ', state)
    let itemToSend = {
      ...state
    }
    if (props.item._id && !props.item._id.includes('uuid')) {
    // console.log('Add our inventoryI tem id', props.item._id)
      // itemToSend.inventoryItemId = props.item._id
      itemToSend.invItem = props.item._id
      itemToSend.inventoryItemId = props.item._id
    }
    // itemToSend._id = props.item._id
    // itemToSend.inventoryItemId = state.inventoryItemId
    // console.log('Send this: ', itemToSend)
    // console.log('State: ', state)
    props.updateLineItemOnEstimate(itemToSend)
  // console.log('SetState 382')
    // setState({...state, ...defaultValues })
  }

  const handleFocus = (e) => {
    e.preventDefault()
    e.target.focus()
    e.target.setSelectionRange(0, e.target.value.length)
  }

  const handleCostChangeUpdate = (e) => {
    let pricePer = 0
    let totalPrice = 0
    let costPer = e.target.value
    pricePer = Number(costPer * 1.4).toFixed(2)
    totalPrice = Number(pricePer * state.qty).toFixed(2)
  // console.log('SetState 398')
    setState({...state, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice})
  }

  const handlePriceManualChange = (e) => {
    let pricePer = e.target.value
    let totalPrice = 0
    totalPrice = Number(pricePer * state.qty).toFixed(2)
  // console.log('SetState 406')
    setState({...state, pricePer: pricePer, totalPrice: totalPrice})
  }

  const handleQtyUpdate = (e) => {
    let pricePer = state.pricePer
    let totalPrice = 0
    totalPrice = Number(pricePer * e.target.value).toFixed(2)
  // console.log('SetState 414')
    setState({...state, qty: e.target.value, totalPrice: totalPrice})
  }
  return (
    <ConfigurationContainer>
      
      <Grid container spacing={2}>
        {/* <Grid item lg={12} xs={12}>
          <LabelText>The following information will show up on the estimate, please type it up accordingly:</LabelText>
        </Grid> */}
        <Grid item lg={1} >
          <TextField
              label="Quantity"
              variant="outlined"
              type="text"
              value={state.qty}
              onChange={handleQtyUpdate}
              onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2}>

            {(props.item && props.item.size) ? null : (
              <FormControl variant="outlined" style={{width: '100%'}}>
                <InputLabel id="demo-simple-select-outlined-label">Size</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.unit}
                  disabled={(props.item && props.item.size)}
                  onChange={e=>setState({...state, unit: e.target.value})}
                  label="Size"
                >
                  {
                    unitsOfMeasureAll.map(unit => {
                      return (
                      <MenuItem key={unit.name} value={unit.name} >{unit.name}</MenuItem>
                    )})
                  }
        
                </Select>
            </FormControl>
            )}

        </Grid>
        <Grid item lg={5}>
          <TextField
            label="Description"
            variant="outlined"
            type="text"
            disabled
            spellCheck={true}
            value={state.description || ''}
            onChange={e=>setState({...state, description: e.target.value})}
            fullWidth
            />
        </Grid>
        <Grid item lg={1}>
          <TextField
            label="Cost Per"
            variant="outlined"
            type="text"
            value={state.costPer}
            onChange={handleCostChangeUpdate}
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={1}>
          <TextField
            label="Price Per"
            variant="outlined"
            type="text"
            value={state.pricePer}
            onChange={handlePriceManualChange}
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2}>
          <TextField
            label="Total Price"
            variant="outlined"
            type="text"
            value={state.totalPrice}
            onChange={e=>setState({...state, totalPrice: e.target.value})}
            onFocus={handleFocus}
            disabled
            />
        </Grid>
        <Grid item lg={12}>
        <TextField
            label="Description"
            variant="outlined"
            type="text"
            multiline
            rows={4}
            value={state.description || ''}
            onChange={e=>setState({...state, description: e.target.value})}
            fullWidth
            />
        </Grid>
        <Grid item lg={12}>
          <TextField
              label="Templated Description"
              variant="outlined"
              type="text"
              multiline
              rows={4}
              value={state.templatedDescription || ''}
              onChange={e=>setState({...state, templatedDescription: e.target.value})}
              fullWidth
              helperText={`You can use {QTY}, {SIZE}, {DESC}, {PRICE} and those items will be filled in on the proposal automatically`}
              />
          </Grid>
        <Grid item lg={12}>
          {
            state.editing ? <Button style={{margin: 10}} fullWidth variant="contained" color="primary" disabled={!state.description} onClick={handleUpdateExistingLineItem}>Update Line Item</Button> :
            <Button style={{margin: 10}} fullWidth variant="contained" color="primary" disabled={!state.description} onClick={handleSaveNewLineItem}>Save Line Item</Button>
          }
        </Grid>
      </Grid>
    </ConfigurationContainer>
  )
}

export default ConfigureItem