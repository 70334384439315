import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListOfExistingOptions from './ListOfOptions'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import NewSizeModal from '../../Settings/ProposalSettings/GenericOptionModal'
import ModifyOptionsInBulk from './ModifyOptionsMulti'
import Modal from './AddNewOptionModal'

const filter = createFilterOptions();

const AddOptionsComponent = (props) => {
console.log('Add Options Component 28', props)
  const [showModal, setShowModal] = React.useState(false)
  const [listOfShit, setListOfShit] = React.useState([])
  const [itemEditing, setItemEditing] = React.useState({})
  const [optionToEdit, setOptionToEdit] = React.useState({})
  const [sizes, setSizes] = React.useState(props.sizes || [])
  const [optionState, setOptionState] = React.useState({})

  React.useEffect(() => {
    if (props.item && props.item.options && props.item.options.length) {
      setListOfShit(props.item.options)
    } else {
      setListOfShit([])
    }
    if (props.chosenScopes && props.chosenScopes.length) {
      console.log('Set Sizes based on scopes', props.chosenScopes)
      // Iterate over chosenScopes and szies to find where they intersect
      //
      let sizesToSet = []
      props.chosenScopes.map(scope => {
        props.sizes.findIndex(size => {
          if (size?.scopes?.includes(scope._id)) {
            console.log('Include this one', size)
            console.log('scope._id:', scope._id)
            sizesToSet.push(size)
          }
        })
      })
      console.log('Sizes to set:', sizesToSet)
      let sizeSet = new Set(sizesToSet)
      console.log('size set', sizeSet)
    }
    // setItemToEdit(props.item)
  }, [props.item])

  const handleOpenNewSizeModal = () => {
    setOptionToEdit(null)
    setShowModal(true)
  }

  const saveOurData = (data) => {
    let obj = {
      option: data,
      item: props.item,
      scope: props.scope,
      optionState
    }
  console.log('Object to send:', obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/company/modifyTakeoffOptions`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      console.log('Update our form options 89', response.data)
      if (response.data && response.data.item && response.data.item._id) {
      // console.log('If our ID does NOT match update it!!!')
      props.updateParent(response.data.item)
      } else if (response.data && response.data._id) {
      // console.log('This should be our updated item w/ options now....')
        props.updateParent(response.data)
      }
      // // console.log(props)
      //   let list = [...listOfShit]
      //   list.unshift(response.data)
      // // console.log('Update the list:', list)
      //   setListOfShit(list)
      // } else if (response.data.noItem) {
      // // console.log('This is either an old item or a brand new item so we gotta hold this shit', data)
      //   let list = [...listOfShit]
      //   let line = {
      //     name: data.name,
      //     size: data.size.name,
      //     sizeId: data.size._id,
      //     notes: data.notes
      //   }
      //   list.unshift(line)
      // // console.log('Update the list:', list)
      //   setListOfShit(list)
      //   props.updateParent(data)
      // }
    })
  }

  const handleCloseNewOptionModal = (type, data) => {
    setShowModal(false)
    if (type === 'newOptionAdded') {
      console.log('Add new option', data)
      props.updateParent(data)
    } else {
      if (type) {

        // console.log('Gotta save this somehow', data)
          saveOurData(type)
          // props.updateParent(data)
        }
    }

  }

  const handleEditAnItem = (opt) => {
  console.log('Edit this item: - open the modal', opt)

    setOptionToEdit(opt)
    setShowModal(true)
    // props.editThisItem(itemToEdit)
  }

  const handleDeleteAnItem = (item) => {
    console.log('Delete Option', item)
    let obj = {
      option: item,
      item: props.item
    }
  // console.log('Object to send:', obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/company/modifyTakeoffOptions?delete=true`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    console.log('Update the options', response.data)
      if (response.data && response.data.item && response.data.item._id) {
      // console.log('If our ID does NOT match update it!!!')
      } else if (response.data && response.data._id) {
        console.log('This should be our updated item w/ options now....', response.data)
        props.updateParent(response.data)
      }
    })
  }
  
  const handleSaveOptions = (updatedTakeOffItem) => {
    console.log('Save these', updatedTakeOffItem)
    props.updateParent(updatedTakeOffItem)
  }
  // console.log('Item to EDIT:', itemToEdit)
  return (
    <div style={{ padding: 10, border: '1px solid #bbb', borderRadius: 10, height: 300, overflow: 'hidden', width: '100%' }}>
      
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ fontSize: '1.2em' }}>Options (Sizes & Colors)</div>
        <ModifyOptionsInBulk {...props} saveOptions={handleSaveOptions} />
        <div>
          {
            (props.item && props.item._id) ? <TheButton handleOpenNewSizeModal={handleOpenNewSizeModal} /> : null
          }
          
        </div>
      </div>

      <div >
        <ListOfExistingOptions list={listOfShit || []} editItem={handleEditAnItem} deleteItem={handleDeleteAnItem} />
      </div>
      <Modal scope={props.scope} optionToEdit={optionToEdit} item={props.item} sizes={props.sizes} open={showModal} closeModal={handleCloseNewOptionModal} />
    </div>
  )
}

const defaultState = {
  size: '',
  name: '',
  notes: '',
  msrp: '',
  barcode: ''
}

const NewOptionConfiguration = ({ sizes, setParentState, parentState, item }) => {
  console.log('NEW OPTION CONFIGS::', item)
  console.log('Parent State', parentState)
// console.log('Sizes::', sizes)
  const [state, setState] = React.useState({
    size: {},
    name: '',
    notes: '',
    option: '',
    barcode: ''
  })
  const [showNewSizeModal, setShowNewSizeModal] = React.useState(false)
  const type = 'size'

  const handleUpdateSize = (e, newSize) => {
  // console.log('Update size', newSize)
    if (typeof newSize === 'string') {
    // console.log('User typed this shit!!')
    } else if (newSize && newSize.inputValue) {
    // console.log('We hvae an input value')
      // setState({...state, size: newSize })
      setShowNewSizeModal(true)
    } else {
    // console.log('using an existing size')
      // setState({...state, size: newSize })
      setParentState({...parentState, size: newSize})
    }
    // setState({...state, size: newSize })
    // setParentState({...parentState, size: newSize})
  }

  const updateOption = (data) => {
  // console.log("192", data)
    setShowNewSizeModal(false)
    setState({...state, size: data })
    setParentState({...parentState, size: data})
  }

  const handleCloseOptionModal = () => {
    setShowNewSizeModal(false)
  }

  const sizeObj = {
    name: 'Size'
  }

  const handleDeleteOption = (d) => {
  // console.log('Delete this bitch')
  }

  return (
    <div>
      <NewSizeModal open={showNewSizeModal} type={'size'} dataToUse={sizeObj} closeModal={handleCloseOptionModal} deleteOption={handleDeleteOption} updateParent={updateOption} />
      <Grid container spacing={3} style={{ paddingBottom: 15}}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="name"
            value={parentState.name}
            onChange={e=>setParentState({...parentState, name: e.target.value})}
            fullWidth
            variant="outlined"
            // onBlur={handleUpdateParent}
            label="Option Name"
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
        <Autocomplete
            freeSolo
            id="size-list"
            fullWidth
            options={sizes}
            onChange={handleUpdateSize}
            value={parentState.size}
            // disableCloseOnSelect={props.single ? false : true }
            style={{marginTop: 25}}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return 'Save ' + option.inputValue + ' as a New Size';
              }
              // Regular option
              return option.name;
            }}
                  
            filterOptions={(options, params) => {

              const filtered = filter(options, params);
      
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                  name: 'Add New Size'
                });
              }
      
              return filtered;
            }}

            renderInput={(params) => (
              <TextField {...params} label={'Size'} placeholder={`8' x 4' or 12"...`} variant="outlined" helperText={'What Is the Size of This Option?'} />
            )}
          />
            {/* <TextField
              labelId="size-select"
              id="select-size"
              value={parentState.size}
              select
              fullWidth
              variant="outlined"
              onChange={e=>setParentState({...parentState, size: e.target.value})}
              // onBlur={handleUpdateParent}
              label="Size"
            >
              {
                sizes.map(unit => {
                  // console.log('Size option:', unit)
                  return (
                  <MenuItem key={unit.name} value={unit} >{unit.name}</MenuItem>
                )})
              }
            </TextField> */}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="msrp"
            value={parentState.msrp}
            rows={4}
            fullWidth
            variant="outlined"
            onChange={e=>setParentState({...parentState, msrp: e.target.value})}
            // onBlur={handleUpdateParent}
            helperText='What Should We Charge For This Item?'
            label="Option MSRP"
          />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="notes"
            value={parentState.notes}
            rows={4}
            multiline
            fullWidth
            variant="outlined"
            onChange={e=>setParentState({...parentState, notes: e.target.value})}
            // onBlur={handleUpdateParent}
            label="Option Notes"
          />
        </Grid>
      </Grid>
     
    </div>
  )
}
const TheButton = ({ handleOpenNewSizeModal }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={handleOpenNewSizeModal}
    >
      Add
    </Button>
  )
}

const mapStateToProps = (state) => {
// console.log('Map State to Props add options', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(AddOptionsComponent)