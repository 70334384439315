import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import moment from 'moment'
import axios from 'axios'
import { connect } from 'react-redux'
import { FileUploader } from 'react-drag-drop-files';


const ClientUploadComponent = (props) => {
  const [filesToAdd, setNewFiles] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0,
    projects: [],
    filesToUpload: []
  })
  // const [showUploadStatusModal, setShowUploadStatusModal] = React.useState(false)
  // const [uploadStatus, setUploadStatus] = React.useState({})
  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "XLSX", "CSV", "TXT", "JPEG"];
  const [file, setFile] = React.useState([])
  let clientId = ''
  if (props.client && props.client._id) clientId = props.client._id
  const uploadFilesNow = (fileList) => {
    // console.log('Time to upload this shit!!', fileList)
    let formData = new FormData()
    fileList.forEach(file => {
      // console.log('Append to formdata:', file)
      file.scanType = 'hmiScan'
      formData.append('theFileList', file)
    })

    formData.append('client', clientId)
  // console.log('Form Data: ', formData)
    let url = `${props.url}/api/projects/uploadProjectFilesMultiple?type=client`
    axios({
      method: 'POST',
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: progressEvent => {
        // console.log('Upload Progress', progressEvent)
      }
    }).then(resp => {
    // console.log('REsponse from axios::', resp)
      let allGood = true
      if (resp && resp.data && resp.data.duplicateFiles && resp.data.duplicateFiles.length) {
        allGood = false
        for (const item of resp.data.duplicateFiles) {
        // console.log('Duplicate File:', item)
          let uploadName = item.file.originalname
          for (const dupe of item.existingFiles) {
            const when = moment(dupe.created_at).format('MM/DD/YY [at] hh:mma')
            const who = dupe.created_by
            const dupeName = dupe.name
            let clientName = ''
            let message = `You attempted to upload ${uploadName} but we found a file that was uploaded by ${who} on ${when} called ${dupeName}`
          // console.log(message)
            if (dupe && dupe.client && dupe.client.displayName) {
              clientName = dupe.client.displayName
              message = `${message} - (${clientName})`
            }
          // console.log('The message:', message)
            props.notification({
              type: 'warning',
              title: 'Duplicate File Detected',
              message: message
            })
          }
        }
      }
      if (resp && resp.data && resp.data.errorFiles && resp.data.errorFiles.length) {
        allGood = false
      }
      props.updateParent(resp.data)
    })
  }

  const handleChange = (fileList) => {
    // console.log('FILES TO UPLOAD: ', fileList)
    let files = Array.from(fileList)
    // console.log('files', files)
    // fileList.map(item => (files.push(item)))
    // console.log('files: ', files)
    let newFiles = file.concat(files)
    setFile(newFiles)
    setState({...state, filesToUpload: newFiles})
  // console.log('New Files to upload:', newFiles)
    // UPload the files now
    uploadFilesNow(files)
  };

  const handleDragChange= (e, d) => {
    // console.log('Drag change', e)
    // console.log(d)
  }

  const handleSelect = (e, d) => {
    // console.log('Select: ', e)
    // console.log(d)
  }


  return (
    <FileUploader
      handleChange={handleChange}
      name="file" 
      multiple
      fileOrFiles='Array'
      onSelect={handleSelect}
      onDraggingStateChange={handleDragChange}
      >
        <div style={{ marginBottom: 15, textAlign: 'center', border: '3px dashed #ccc', borderRadius: '10px', padding: '15px', cursor: 'pointer' }}>
          Click Here to Upload or Drag and Drop Files Here
        </div>
    </FileUploader>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ClientUploadComponent)
