import React, { useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { fade, makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button,
  CircularProgress,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Tooltip,
  Paper,
  DialogContentText,
 }  from '@material-ui/core';
 import InputAdornment from '@material-ui/core/InputAdornment';
 import AppsIcon from '@material-ui/icons/Apps';
import moment from 'moment'
import { currencyFormat, currencyFormatWithOutFlair } from '../../services/common'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  textField: {
    margin: '10px',
    padding: '10px'
  },
  mismatchedHours: {
    margin: '10px',
    padding: '10px',
    '& input': {
      color: 'red',
    },
  },
  paddedStuff: {
    padding: '15px'
  },
  dumpFeeContainer: {
    margin: '10px',
    padding: '20px',
    border: '2px solid #ddd',
    borderRadius: 8,
  },
  dumpFeeContainerTopRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dumpFeeInputArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  
  },
  dumpFeeArea: {
    display: 'flex',
    flexDirection: 'column-reverse',   
  },
  dumpTicketContainer: {
    margin: '5px',
    padding: '10px',
    border: '2px solid #ddd',
    borderRadius: 8,
  },
  dumpTicketHeader: {
    fontSize: '1.14em',
    color: '#333',
    fontWeight: 500,
   
  },
  helperTextMismatched: {
    color: 'red',
  },
  stopsContainer: {
    padding: 5,
    margin: 10,
    border: '2px solid #ddd',
    borderRadius: 5
  },
  stopItemContainer: {
    padding: 4,
    margin: 4,
    border: '1px solid #888',
    borderRadius: 15
  }
}))

const handleFocus = (event) => event.target.select();

const laborTypes = [
  'General Labor',
  'Spray Tech',
  'Irrigation Tech'
]

const logTypes = [
  'Commercial/County',
  'Northern Parkway',
  'ADOT'
]

const defaultStateVals = {
  crew: null,
  laborType: 'General Labor',
  date: '',
  timeIn: '',
  timeOut: '',
  hoursWorked: 0,
  crewSize: 0,
  dumpFees: [],
  agreement: null,
  logType: logTypes[0],
  jobSites: [],
  jobSite: null,
  stops: [],
  hasValidStop: false,
  irrigationParts: [],
  totalLaborHours: 0,
  totalSupervisorHours: 0
}


// const timeIn = new Date(`1970/01/01 ${newState.timeIn}`);
// const timeOut = new Date(`1970/01/01 ${newState.timeOut}`);
// // Calculate difference in minutes
// let diffMinutes = (timeOut - timeIn) / (1000 * 60);
// // Round to nearest 5 minutes
// diffMinutes = Math.round(diffMinutes / 5) * 5;
// const hoursWorked = parseFloat(diffMinutes / 60).toFixed(2)
// console.log('Time In', timeIn)
// console.log('Tim eout', timeOut)
// console.log('Diff ', diffMinutes)
// console.log('Hours worked', hoursWorked)
// newState.hoursWorked = hoursWorked
// // newState.timeIn = moment(` ${timeIn}`, 'YYYY/MM/DD hh:mma').format("HH:mm") // new Date(`1970/01/01 ${newState.timeIn}`);
// // newState.timeOut = moment(`${timeOut}`, 'YYYY/MM/DD hh:mma').format("HH:mm")


const ADOTLogModificationModal = (props) => {
  console.log('Daily Log Modification', props)
  const classes = useStyles();
  const [state, setState] = useState(defaultStateVals)
  const [highlightedOption, setHighlightedOption] = useState(null);
  const [isHoursMismatched, setIsHoursMismatched] = useState(false)
  const [loading, setLoading] = useState(false)
  // console.log('State', state)
  React.useEffect(() => {
    // console.log('Use Effect 122', props)
    if (props.itemToEdit && props.itemToEdit._id) {
      console.log('Make this oour item ', props.itemToEdit)
      let newState = { ...props.itemToEdit, hasValidStop: true }
      let crew = null
      if (newState.crew && newState.crew._id) {
       crew = newState.crew 
      } else {
        crew = props?.crews?.find(crew => (crew._id === newState.crew))
      }
      newState.crew = crew
      let contract = null
      if (newState.mtcAgreement && newState.mtcAgreement._id) {
        contract = newState.mtcAgreement
      } else {
        contract = props?.ags?.find(ag => (ag._id === newState.mtcAgreement))
      }
      newState.agreement = contract
      // Update our labor type
      let laborType = newState.laborType || null
      if (newState.laborHours) laborType = 'General Labor'
      if (newState.irrigationHours) laborType = 'Irrigation Tech'
      if (newState.herbicideHours) laborType = 'Spray Tech'
      newState.laborType = laborType
      if (newState.jobSite && !newState.jobSite._id) {
        // console.log('Find this jobsite 122', newState.jobSite)
        let site = [...props.mtcSites].find(item => (item._id === newState.jobSite))
        if (site && site._id) newState.jobSite = site
      }
      // console.log('New state', newState)
      if (newState.timeIn && newState.timeOut) {
        // const timeInDate = new Date(`1970/01/01 ${newState.timeIn}`)
        // console.log('Time in date', timeInDate)
        const timeIn24HourFormat = moment(`1970/01/01 ${newState.timeIn}`, 'YYYY/MM/DD h:mma').format("HH:mm")
        // console.log('Time in 24:', timeIn24HourFormat)
        const timeOut24HourFormat = moment(`1970/01/01 ${newState.timeOut}`, 'YYYY/MM/DD h:mma').format("HH:mm")
        // console.log('Time Out 24:', timeOut24HourFormat)
        newState.timeIn = timeIn24HourFormat // newState.timeIn.substr(0, newState.timeIn.length - 2)
        newState.timeOut = timeOut24HourFormat // newState.timeOut.substr(0, newState.timeOut.length - 2)
      }
      // Set Super/Labor Hours
      console.log('197 ', newState)
      if (newState.hoursWorked && newState.hoursWorked > 0 && newState.crewSize && newState.crewSize > 1) {
        console.log('200')
        if ((!newState.totalLaborHours || newState.totalLaborHours === 0) && (!newState.totalSupervisorHours || newState.totalSupervisorHours === 0)) {
          console.log('202')
          // This is an old log probably that wasn't ever set...set it
          let totLabHours = Number(newState.crewSize - 1) * newState.hoursWorked
          newState.totalSupervisorHours = newState.hoursWorked
          newState.totalLaborHours = totLabHours
          console.log('New state', newState)
        }
      }
      // setState({...state, ...newState })
      // }
      console.log('Set the state', newState)
      setState({ ...defaultStateVals, ...newState})
    } else {
      // console.log('SEt jobSites')
      setState({...defaultStateVals, jobSites: props.mtcSites })
    }
  }, [props])

  const handleHighlightChange = (event, option) => {
    // console.log('set highlighted option:', option)
    setHighlightedOption(option);
  };

  const handleClose = () => {
    setState({...defaultStateVals, timeIn: '', timeOut: '', date: '', dumpFees: [], irrigationParts: [] })
    props.updateParent(null, 'close')
  }

//   Crew
// ADOT Customer - Chris/Luis — ADOT/RTP Mtc
// Date
// Time In
// Time Out
// # of Men
// Total Hours - 1 of the crew is always supervisor
// Freeway
// Milepost Start
// Milepost Stop
// Service Performed
// Dump Fees - 0 to Many Dollar Amount ONLY

const updateCrew = (e, newCrew) => {
  setHighlightedOption(null)
  // if (newCrew && newCrew.assignADOTCrew)
  setState({...state, crew: newCrew })
}

const handleChange = (e) => {
  setHighlightedOption(null)
  let newState = {...state, [e.target.id]: e.target.value }
  if ((e.target.id === 'timeIn' || e.target.id === 'timeOut') && newState.timeIn && newState.timeOut) {
    // Update our hours worked
    calculateHoursWorked(newState)
  } else if (e.target.id === 'hoursWorked') {
    // User overriding the hours worked
    setIsHoursMismatched(true)
    setState(newState)
  } else {
    setState(newState)
  }
}

const handleSave = async () => {
  setLoading(true)
  console.log('save: ', state)
  const obj = {
    crewSize: state.crewSize,
    timeIn: state.timeIn,
    timeOut: state.timeOut,
    agreement: state.agreement,
    dumpFees: state.dumpFees,
    totalLaborHours: state.totalLaborHours,
    totalSupervisorHours: state.totalSupervisorHours
  }
  console.log('send this:', obj)
  // const resp = await axios.post(`${props.url}/api/hmi/saveDailyLogData`, obj)
  // console.log('REsp:', resp)
  // if (resp.data && resp.data.success) {
  //   props.notification({
  //     type: 'success',
  //     message: resp.data.message || 'Saved!!',
  //     title: 'Daily Log Saved'
  //   })
  //   setLoading(false)
  //   setState({...defaultStateVals, stops: [], dumpFees: [] })
  //   if (resp.data.data && resp.data.data.logs && resp.data.data.logs.length) props.updateParent(resp.data.data.logs, 'new')
  // }
}

const handleUpdateDailyLog = async () => {
  setLoading(true)
  console.log('Handle Update:', state)
  let mtcAgreementId = props?.itemToEdit?.mtcAgreement
  if (mtcAgreementId?._id) mtcAgreementId = props?.itemToEdit?.mtcAgreement._id
  const obj = {
    _id: props?.itemToEdit?._id,
    crewSize: state.crewSize,
    timeIn: state.timeIn,
    timeOut: state.timeOut,
    dumpFees: state.dumpFees,
    totalLaborHours: state.totalLaborHours,
    totalSupervisorHours: state.totalSupervisorHours,
    mtcAgreement: mtcAgreementId
  }
  console.log('update this this:', obj)
  const resp = await axios.post(`${props.url}/api/hmi/saveDailyLogData`, obj)
  console.log('REsp:', resp)
  setLoading(false)
  if (resp.data && resp.data.success) {
    props.notification({
      type: 'success',
      message: resp.data.message || 'Saved!!',
      title: 'Daily Log Updated'
    })
    setState({...defaultStateVals, stops: [], dumpFees: [] })
    if (resp.data && resp.data.itemToEdit) props.updateParent(resp.data.itemToEdit, 'update')
  }
}

const handleUpdateDumpFees = (dumpFees) => {
  // console.log('Set the dump fees', dumpFees)
  setState({...state, dumpFees: dumpFees})
  setHighlightedOption(null)
}

const updateLaborType = (e, newType) => {
  setState({...state, laborType: newType })
  setHighlightedOption(null)
}

const updateLogType = (e, newLogType) => {
  setState({...state, logType: newLogType })
  setHighlightedOption(null)
}

const handleTabInteractionWithJobsites = (e) => {
  if (e.key === 'Tab') {
    // console.log('TAB', e.target.id)
    // console.log(e.target.value)
    if (e.target.id && highlightedOption) {
      // console.log('Tab pressed on:', highlightedOption);
    }
  }
}

const clearHighlightedOption = () => {
  setHighlightedOption(null)
}
const handleTabInteraction = (e) => {
  if (e.key === 'Tab' && e.target.id && highlightedOption) {
    // console.log('Tab pressed on:', highlightedOption);
    // console.log('e.target.id', e.target.id)
    if (e.target.id) {
      // console.log('Set based on highlighted option - clear HIGHLIGHTED OPTION')
      setHighlightedOption(null)
      // console.log(e.target.value)
      if (e.target.id === 'agreement') {
        // updateAG(e, highlightedOption)
      } else {
        setState({...state, [e.target.id]: highlightedOption })
      }
    } else {
      // console.log('No idea what to set here???', e.target)
    }
  } else if (e.key === 'Tab' ) {
    // console.log('Tab Interaction:', e)
    // console.log('e.target.id', e.target.id)
    // console.log(e.target.value)
  // // console.log(e.target)
    if (e.target.id === 'crew') {
      // window.alert(`Tab on custoemr ${e.target.value}`)
      let newArr = []
      let string = e.target.value
      props.crews.filter(o => {
        let str = o['name'].toString().toLowerCase()
        if (str.includes(string.toLowerCase())) {
          return newArr.push(o)
        } else {
          return null
        }
      })
      // // console.log(newArr)
      if (newArr && newArr.length) {
        updateCrew(e, newArr[0])
      }
    }

      // if (e.target.id === 'agreement') {
      //   // window.alert(`Tab on custoemr ${e.target.value}`)
      //   let newArr = []
      //   let string = e.target.value
      //   props.ags.filter(o => {
      //     let str = o['jobName'].toString().toLowerCase()
      //     if (str.includes(string.toLowerCase())) {
      //       return newArr.push(o)
      //     } else {
      //       return null
      //     }
      //   })
      //   // // console.log(newArr)
      //   if (newArr && newArr.length) {
      //     updateAG(e, newArr[0])
      //   }
      // }

        if (e.target.id === 'logType') {
          // window.alert(`Tab on custoemr ${e.target.value}`)
          let newArr = []
          let string = e.target.value
          logTypes.filter(o => {
            let str = o.toString().toLowerCase()
            if (str.includes(string.toLowerCase())) {
              return newArr.push(o)
            } else {
              return null
            }
          })
          // // console.log(newArr)
          if (newArr && newArr.length) {
            updateLogType(e, newArr[0])
          }
        }
  

        if (e.target.id === 'laborType') {
          // window.alert(`Tab on custoemr ${e.target.value}`)
          let newArr = []
          let string = e.target.value
          laborTypes.filter(o => {
            let str = o.toString().toLowerCase()
            if (str.includes(string.toLowerCase())) {
              return newArr.push(o)
            } else {
              return null
            }
          })
          // // console.log(newArr)
          if (newArr && newArr.length) {
            updateLaborType(e, newArr[0])
          }
        }
        // if (e.target.id === 'jobSite') {
        //   // window.alert(`Tab on custoemr ${e.target.value}`)
        //   let newArr = []
        //   let string = e.target.value
        //   state.jobSites.filter(option => {
        //     let ourString = `${option.communityName ? option.communityName : ''}${option.name} ${option.buildingNumber ? option.buildingNumber : ''} ${option.address} (${option.city})`
        //     let str = ourString.toString().toLowerCase()
        //     if (str.includes(string.toLowerCase())) {
        //       return newArr.push(option)
        //     } else {
        //       return null
        //     }
        //   })
        //   // // console.log(newArr)
        //   if (newArr && newArr.length) {
        //     handleACUpdate(e, newArr[0])
        //   }
        // }
        // console.log('CLEAR THAT HIGHLIGHTED OPTION NOW:::')
        setHighlightedOption(null)
      }
 
}

const handleUpdateLogType = (e, newLogType) => {
  let newState = { logType: newLogType, hasValidStop: false }
  if (newState.logType === 'Northern Parkway') {
    let index = props.ags.findIndex(ag => (ag.isNorthernPkwy))
    if (index > -1) {
      newState.agreement = props.ags[index]
    }
  }
  setState({ ...state, ...newState })
}
    
const handleUpdate = (e, newValue) => {
  console.log('Update:', e.target.id)
  let id = e.target.id
  if (id && id.includes('-')) {
    id = id.split('-')[0]
  }
  console.log('e.target.value', e.target.value)
  console.log('new value', newValue)
  if (!newValue) newValue = e.target.value
  setHighlightedOption(null)
  setState({...state, [id]: newValue })

}

const handleUpdateLaborType = (e, newVal) => {
  setHighlightedOption(null)
  setState({...state, laborType: newVal })
}

const calculateHoursWorked = (statePassed) => {
  // console.log('Calculate hours worked', statePassed)
  let newState = { ...state }
  // console.log('Calculate thos ehours!!!', newState)
  if (statePassed && statePassed.timeIn && statePassed.timeOut) newState = statePassed
  // console.log('new state...', newState)
  if (newState.timeIn && newState.timeOut) {
    // console.log('Calculate our hours worked', newState)
    // Calculate our hours worked
    const timeIn = new Date(`1970/01/01 ${newState.timeIn}`);
    const timeOut = new Date(`1970/01/01 ${newState.timeOut}`);

    // Calculate difference in minutes
    let diffMinutes = (timeOut - timeIn) / (1000 * 60);
    // Round to nearest 5 minutes
    diffMinutes = Math.round(diffMinutes / 5) * 5;
    const hoursWorked = parseFloat(diffMinutes / 60).toFixed(2)
    // console.log('Time In', timeIn)
    // console.log('Tim eout', timeOut)
    // console.log('Diff ', diffMinutes)
    // console.log('Hours worked', hoursWorked)
    // let labHours = Math.round(((newState.crewSize - 1) * hoursWorked) / 5) * 5;
    const totalLaborHours = Math.round(((newState.crewSize - 1) * hoursWorked) / 5) * 5;
    if (timeOut < timeIn || hoursWorked < 0) {
      props.notification({
        type: 'warning',
        title: 'Uh Oh...Something Is Not Right',
        message: 'The Time Out cannot be earlier than the Time In.'
      })
    } else {
      newState.hasValidStop = true
      newState.hoursWorked = hoursWorked
      newState.totalLaborHours = totalLaborHours
      newState.totalSupervisorHours = hoursWorked
      setIsHoursMismatched(false)
      console.log('set our new state', newState)
      setState(newState)
    }
  }
}

const updateAG = (e, newAg) => {
  console.log('ADOT crew', newAg)
  let newLogType = null
  setHighlightedOption(null)
  let newState = {}
  if (!newAg) {
    // console.log('Clearing the ag - make jobsites the props.jobsites')
    newState = { agreement: newAg, logType: newLogType, jobSites: props.mtcSites, jobSite: null }
  } else {
    if (newAg && newAg.customerType === 'Commercial') newLogType = 'Commercial'
    if (newAg && newAg.customerType === 'County') newLogType = 'County'
    if (newAg && newAg.customerType === 'ADOT') newLogType = 'ADOT'
    if (newAg && newAg.customerType === 'County' && newAg.isNorthernPkwy) newLogType = 'Northern Parkway'
    // console.log('New log type:', newLogType)
    let newJobSites = []
    if (newAg && newAg.jobSites && newAg.jobSites.length) newJobSites = newAg.jobSites
    newState = { agreement: newAg, logType: newLogType, jobSites: newJobSites }
    // console.log('New state 229', newState)
    if (newJobSites && newJobSites.length === 1) {
      newState.jobSite = newJobSites[0]
    } else {
      newState.jobSite = null
    }
    if (newAg && newAg.customerType === 'ADOT') {
      // Find crew that works this agreement
      let index = props.crews.findIndex((crew) => (crew.asssignedADOTCrew === newAg._id))
      newState.crew = props.crews[index]
    }
  }
  console.log('Set the state 505', newState)
  setState({ ...state, ...newState })
}

const handleACUpdate = (e, newVal) => {
  // console.log('Set jobsite', newVal)
  setHighlightedOption(null)
  let updatedOurAg = false
  let newState = {...state, jobSite: newVal }
  if (newVal && newVal._id) {
    // Set the contract
    let client = newVal.client
    let jobSiteId = newVal._id
    let updatedOurAg = false
    // console.log('Find this site', jobSiteId)
    props.ags.map(ag => {
      let jobsites = ag.jobSites
      // console.log('Look in here:', jobsites)
      let site = jobsites.find(site => (site._id === jobSiteId))
      if (site && site._id) {
        // console.log('This is our site!!', site)
        let stuff = {
          jobSite: newVal,
          jobSites: jobsites
        }
        updatedOurAg = true
        updateAG(null, ag, stuff)
      } else {
        // console.log('Nope....')
      }
    })
    // console.log('ACU update finished...did we find the log??', updatedOurAg)
    if (!updatedOurAg) {
      props.notification({
        type: 'warning',
        title: 'Uh Oh...Something Is Not Right',
        message: 'Cannot Find a Matching Maintenance Agreement. Need to add it to an existing Maintenance Agreement or create a new one.'
      })
    }
  } else {
    setState(newState)
  }
  
}

const handleIrrigationPartsUpdate = (d) => {
  console.log('Irrigation Parts update', d)
  console.log('State now:', state)
  const newState = { ...state, irrigationParts: d }
  console.log('new State to set', newState)
  setState(newState)
}


  return (
    <div className={classes.root}>
      <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Modify Daily Work Report</DialogTitle>
          <DialogContent >
          <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
            <CloseIcon />
          </IconButton>
            <DialogContentText>
              Please Update the Data for the Daily Report Form Below
            </DialogContentText>
            <Grid container>
             
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id="crewSize"
                  label="Crew Size"
                  type="number"
                  fullWidth
                  onFocus={handleFocus}
                  value={state.crewSize}
                  onChange={handleChange}
                  className={classes.textField}
                  variant="standard"
                  // onChange={ e => setJobName(e.target.value) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
           
                 
               <Grid item lg={12} md={12} sm={12} xs={12}>
                <OtherLogDetails
                      {...props}
                      state={state}
                      setState={setState}
                      handleHighlightChange={handleHighlightChange}
                      handleTabInteraction={handleTabInteraction}
                      handleChange={handleChange}
                      isHoursMismatched={isHoursMismatched}
                      calculateHoursWorked={calculateHoursWorked}
                      handleACUpdate={handleACUpdate}
                      clearHighlightedOption={clearHighlightedOption}
                      jobSites={props.mtcSites} 
                      
                    />
               </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                
                  <DumpFeesComponent fees={state.dumpFees} updateParent={handleUpdateDumpFees} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {
              (props.itemToEdit && props.itemToEdit._id) ? (
                <Button disabled={ loading || !state.crewSize }  color="primary" onClick={handleUpdateDailyLog}>
                  {
                    loading ? <CircularProgress size={14} /> : 'Update'
                  }
                </Button>
              ) : (
                <Button disabled={ loading || !state.crewSize || !state.date || (!state.crew || !state.crew._id) || !state.hasValidStop }  color="primary" onClick={handleSave}>
                  
                  {
                    loading ? <CircularProgress size={14} /> : 'Save'
                  }
                </Button>
              )
            }
            
          </DialogActions>
        </Dialog>
      </div>
  )
}

const IrrigationPartsComponent = ({ irrigationParts, updateParent }) => {
  const classes = useStyles();
  // console.log('Irrigation parts', irrigationParts)
  const handleAddDumpFee = () => {
    let currentFees = [...irrigationParts] 
    currentFees.push({
      fee: 0,
      date: new Date()
    })
    // setState({...state, fees: currentFees })
    updateParent(currentFees)
  }

  const handleChangeFee = (e, index) => {
    // console.log('Change fee', e.target.value)
    let currentFees = [...irrigationParts] 
    currentFees[index]['fee'] = e.target.value
    // currentFees[index]
    // setState({...state, fees: currentFees })
    // console.log('New fees', currentFees)
    updateParent(currentFees)
  }
  
  console.log('Irrigation Parts', irrigationParts)
  return (
    <div className={classes.dumpFeeContainer}>
      <div className={classes.dumpFeeContainerTopRow}>
      
      <Button variant="contained" color="secondary" size="small" onClick={handleAddDumpFee}>Add Irrigation Parts</Button>
      </div>
      <div className={classes.dumpFeeArea}>
      {
        irrigationParts.map((irrigationPart, index) => {
          console.log('irrigationPart Fee', irrigationPart)
          return (
            <div key={index} className={classes.dumpTicketContainer}>
               <div className={classes.dumpTicketHeader} >Part Receipt Ticket #{Number(index) + 1}</div>
            <div className={classes.dumpFeeInputArea} key={index}>
             
          
                  <div>
                    <TextField
                      key={index}
                      value={irrigationPart.fee}
                      id={ `fee-${index}` }
                      fullWidth
                      onFocus={handleFocus}
                      onChange={e => handleChangeFee(e, index)}
                      className={classes.textField}
                      variant="outlined"
                      // onChange={ e => setJobName(e.target.value) }
                      // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={`Receipt #${index + 1}`}
                    />
                  </div>
          </div>
          </div>
          )
        })
      }
      </div>
    </div>
  )
}

const OtherLogDetails = ({ itemToEdit, handleChange, jobSites, state, clearHighlightedOption, isHoursMismatched, calculateHoursWorked, handleHighlightChange, handleTabInteraction, handleACUpdate }) => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          id="timeIn"
          label="Time In"
          type="time"
          fullWidth
          value={state.timeIn}
          onChange={handleChange}
          className={classes.textField}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <TextField
          id="timeOut"
          label="Time Out"
          type="time"
          fullWidth

          value={state.timeOut}
          onChange={handleChange}
          className={classes.textField}
          variant="standard"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <TextField
                id="totalLaborHours"
                label="Total Labor Hours"
                type="number"
                fullWidth
                onFocus={handleFocus}
                value={state.totalLaborHours}
                onChange={handleChange}
                className={classes.textField}
                variant="standard"
                // onChange={ e => setJobName(e.target.value) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
                helperText="Total Labor Hours"
                
              />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
                id="totalSupervisorHours"
                label="Total Supervisor Hours "
                type="number"
                fullWidth
                onFocus={handleFocus}
                value={state.totalSupervisorHours}
                onChange={handleChange}
                // className={isHoursMismatched ? classes.mismatchedHours : classes.textField}
                variant="standard"
                className={classes.textField}
                // onChange={ e => setJobName(e.target.value) }
                // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                InputLabelProps={{
                  shrink: true,
                }}
                helperText="Total Supervisor Hours"
                
              />
           </Grid>
       
    </Grid>
  )
}

const StopDetails = (props) => {
  const classes= useStyles()
  const state = props.state
  const setState = props.setState
  console.log('StopDetails Details', props)
  return (
    <div className={classes.stopsContainer} >
    
     <div>
      <StopList {...props} />
     </div>
     <AddStopButton setState={setState} state={state} />
    </div>
  )
}

const AddStopButton = ({ state, setState }) => {

  const handleAddStop = () => {
    let newStop = {
      timeIn: '',
      timeOut: '',
      hoursWorked: 0,
      jobSite: null,
      _id: Date.now()
    }
    setState({...state, stops: [ ...state.stops, newStop ]})
  }

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleAddStop}
    >
      Add Stop
    </Button>
  )
}

const StopList = (props) => {
  const classes = useStyles()
  const state = props.state
  const setState = props.setState
  const stops = state.stops
  const [highlightedOption, setHighlightedOption] = useState(null);
  const [isHoursMismatched, setIsHoursMismatched] = useState(false)

  const clearHighlightedOption = () => {
    setHighlightedOption(null)
  }
  
  const updateLaborType = (e, newType) => {
    setState({...state, laborType: newType })
    setHighlightedOption(null)
  }
  
  const updateLogType = (e, newLogType) => {
    setState({...state, logType: newLogType })
    setHighlightedOption(null)
  }

  const updateAG = (e, newAg, otherStateSettings) => { 
    // console.log('UPdate ag - other settings:', otherStateSettings)
    let newLogType = null
    setHighlightedOption(null)
    let newState = {}
    if (!newAg) {
      // console.log('Clearing the ag - make jobsites the props.jobsites')
      newState = { agreement: newAg, logType: newLogType, jobSites: props.mtcSites, jobSite: null }
    } else {
      if (newAg && newAg.customerType === 'Commercial') newLogType = 'Commercial'
      if (newAg && newAg.customerType === 'County') newLogType = 'County'
      if (newAg && newAg.customerType === 'ADOT') newLogType = 'ADOT'
      if (newAg && newAg.customerType === 'County' && newAg.isNorthernPkwy) newLogType = 'Northern Parkway'
      // console.log('New log type:', newLogType)
      let newJobSites = []
      if (newAg && newAg.jobSites && newAg.jobSites.length) newJobSites = newAg.jobSites
      newState = { agreement: newAg, logType: newLogType, jobSites: newJobSites }
      // console.log('New state 229', newState)
      if (newJobSites && newJobSites.length === 1) {
        newState.jobSite = newJobSites[0]
      } else {
        newState.jobSite = null
      }
      if (otherStateSettings && otherStateSettings.jobSite) newState.jobSite = otherStateSettings.jobSite
      if (otherStateSettings && otherStateSettings.jobSites && otherStateSettings.jobSites.length) newState.jobSites = otherStateSettings.jobSites
    }
    console.log('Set the state 243', newState)
    setState({ ...state, ...newState })
  }
  
  const handleTabInteraction = (e, stop) => {
    if (e.key === 'Tab' && e.target.id && highlightedOption) {
      // console.log('Tab pressed on:', highlightedOption);
      // console.log('e.target.id', e.target.id)
      if (e.target.id) {
        // console.log('Set based on highlighted option - clear HIGHLIGHTED OPTION')
        setHighlightedOption(null)
        // console.log(e.target.value)
        if (e.target.id === 'agreement') {
          updateAG(e, highlightedOption)
        } else {
          setState({...state, [e.target.id]: highlightedOption })
        }
      } else {
        // console.log('No idea what to set here???', e.target)
      }
    } else if (e.key === 'Tab' ) {
      // console.log('Tab Interaction:', e)
      // console.log('e.target.id', e.target.id)
      // console.log(e.target.value)
    // // console.log(e.target)
  
        if (e.target.id === 'agreement') {
          // window.alert(`Tab on custoemr ${e.target.value}`)
          let newArr = []
          let string = e.target.value
          props.ags.filter(o => {
            let str = o['jobName'].toString().toLowerCase()
            if (str.includes(string.toLowerCase())) {
              return newArr.push(o)
            } else {
              return null
            }
          })
          // // console.log(newArr)
          if (newArr && newArr.length) {
            updateAG(e, newArr[0])
          }
        }
  
          if (e.target.id === 'logType') {
            // window.alert(`Tab on custoemr ${e.target.value}`)
            let newArr = []
            let string = e.target.value
            logTypes.filter(o => {
              let str = o.toString().toLowerCase()
              if (str.includes(string.toLowerCase())) {
                return newArr.push(o)
              } else {
                return null
              }
            })
            // // console.log(newArr)
            if (newArr && newArr.length) {
              updateLogType(e, newArr[0])
            }
          }
    
  
          if (e.target.id === 'laborType') {
            // window.alert(`Tab on custoemr ${e.target.value}`)
            let newArr = []
            let string = e.target.value
            laborTypes.filter(o => {
              let str = o.toString().toLowerCase()
              if (str.includes(string.toLowerCase())) {
                return newArr.push(o)
              } else {
                return null
              }
            })
            // // console.log(newArr)
            if (newArr && newArr.length) {
              updateLaborType(e, newArr[0])
            }
          }
          if (e.target.id === 'jobSite') {
            // window.alert(`Tab on custoemr ${e.target.value}`)
            let newArr = []
            let string = e.target.value
            state.jobSites.filter(option => {
              let ourString = `${option.communityName ? option.communityName : ''}${option.name} ${option.buildingNumber ? option.buildingNumber : ''} ${option.address} (${option.city})`
              let str = ourString.toString().toLowerCase()
              if (str.includes(string.toLowerCase())) {
                return newArr.push(option)
              } else {
                return null
              }
            })
            // // console.log(newArr)
            if (newArr && newArr.length) {
              updateStopJobSite(stop, newArr[0])
            }
          }
          // console.log('CLEAR THAT HIGHLIGHTED OPTION NOW:::')
          setHighlightedOption(null)
        }
   
  }

  const updateStopJobSite = (stop, site) => {
    console.log('Set updateStopJobSite', site)
    setHighlightedOption(null)
    let updatedOurAg = false
    let newStop = {...stop, jobSite: site }
    let newStops = [...state.stops ]
    let stopIndex = newStops.findIndex(item => (item._id === stop._id))
    console.log('stop Index', stopIndex)
    if (stopIndex > -1) {
      newStops[stopIndex] = newStop
    }
    let newState = {...state, stops: newStops }
    if (site && site._id) {
      // Set the contract
      let client = site.client
      let jobSiteId = site._id
      let updatedOurAg = false
      // console.log('Find this site', jobSiteId)
      props.ags.map(ag => {
        let jobsites = ag.jobSites
        // console.log('Look in here:', jobsites)
        let site = jobsites.find(site => (site._id === jobSiteId))
        if (site && site._id) {
          // console.log('This is our site!!', site)
          let stuff = {
            jobSite: site,
            jobSites: jobsites
          }
          updatedOurAg = true
          updateAG(null, ag, stuff)
        } else {
          // console.log('Nope....')
        }
      })
      // setState({...state, ...newState})
      checkIfValid(newState)
      // console.log('ACU update finished...did we find the log??', updatedOurAg)
      if (!updatedOurAg) {
        props.notification({
          type: 'warning',
          title: 'Uh Oh...Something Is Not Right',
          message: 'Cannot Find a Matching Maintenance Agreement. Need to add it to an existing Maintenance Agreement or create a new one.'
        })
      }
    } else {
      // setState({...state, ...newState})
      checkIfValid(newState)
    }
    
  }
  
  const checkIfValid = (newState) => {
    console.log('Check if this is valid', newState)
    let allStopsValid = true
    newState.stops.map(stop => {
      console.log('stop to check if valid', stop)
      console.log(stop.timeIn.length)
      console.log(stop.timeOut.length)
      if ((!stop.jobSite || !stop.jobSite._id) || stop.timeIn.length < 3 || stop.timeOut.length < 2) {
        console.log('invalid stop', stop)
        allStopsValid = false
      }
    })
    console.log('All stops valid:', allStopsValid)
    newState.hasValidStop = allStopsValid
    console.log('New stat to set: 817', newState)
    setState({...state, ...newState })
  }

  const handleHighlightChange = (event, option) => {
    // console.log('set highlighted option:', option)
    setHighlightedOption(option);
  };

  const handleChange = (e, stop) => {
    setHighlightedOption(null)
    console.log('Handle change', e.target.id)
    let stopToEdit = { ...stop, [e.target.id]: e.target.value }
    console.log('Update this:', stopToEdit)
    console.log('To this:', stopToEdit)
    let newStops = [...state.stops ]
    let stopIndex = newStops.findIndex(item => (item._id === stopToEdit._id))
    console.log('stop Index', stopIndex)
    if (stopIndex > -1) {
      newStops[stopIndex] = stopToEdit
    }
    let newState = {...state, stops: newStops }
    // setState({...state, ...newState })
    if ((e.target.id === 'timeIn' || e.target.id === 'timeOut') && stopToEdit.timeIn && stopToEdit.timeOut) {
      // Update our hours worked
      calculateHoursWorked(newState, e.target.id, stopToEdit)
    } else if (e.target.id === 'hoursWorked') {
      // User overriding the hours worked
      setIsHoursMismatched(true)
      // setState(newState)
      checkIfValid(newState)
    } else {
      // setState(newState)
      checkIfValid(newState)
    }
  }

  const calculateHoursWorked = (statePassed, idPassed, stopToEdit) => {
    console.log('Calculate hours worked', statePassed)
    // let newState = { ...statePassed }
    // console.log('Calculate thos ehours!!!', newState)
    // if (stop && stop.timeIn && stop.timeOut) newState = statePassed
    // console.log('new state...', newState)
    if (stopToEdit.timeIn && stopToEdit.timeOut) {
      // console.log('Calculate our hours worked', newState)
      // Calculate our hours worked
      const timeIn = new Date(`1970/01/01 ${stopToEdit.timeIn}`);
      const timeOut = new Date(`1970/01/01 ${stopToEdit.timeOut}`);
      // Calculate difference in minutes
      let diffMinutes = (timeOut - timeIn) / (1000 * 60);
      // Round to nearest 5 minutes
      diffMinutes = Math.round(diffMinutes / 5) * 5;
      const hoursWorked = parseFloat(diffMinutes / 60).toFixed(2)
      
      // console.log('Time In', timeIn)
      // console.log('Tim eout', timeOut)
      // console.log('Diff ', diffMinutes)
      // console.log('Hours worked', hoursWorked)
      console.log('Hours worked', hoursWorked)
      if (timeOut < timeIn || hoursWorked < 0) {
        props.notification({
          type: 'warning',
          title: 'Uh Oh...Something Is Not Right',
          message: 'The Time Out cannot be earlier than the Time In.'
        })
        if (idPassed === 'timeIn') stopToEdit.timeIn = ''
        if (idPassed === 'timeOut') stopToEdit.timeOut = ''
      } else {
        stopToEdit.hoursWorked = hoursWorked
      }
    }
    let newStops = [...state.stops ]
    console.log('Edited stop to save', stopToEdit)
    let stopIndex = newStops.findIndex(item => (item._id === stopToEdit._id))
    // console.log('stop Index', stopIndex)
    if (stopIndex > -1) {
      newStops[stopIndex] = stopToEdit
    }
    let newState = {...statePassed, stops: newStops }
    console.log('New state 886', newState)
    setIsHoursMismatched(false)
    // setState(newState)
    checkIfValid(newState)
  }

  return (
    <div >
      {
        [...stops].map((stop, index) => {
          const handleJobsiteUpdate = (e, newSite) => {
            console.log('New jobsite...', newSite)
            updateStopJobSite(stop, newSite)
          }
          return (
            <div key={index} className={classes.stopItemContainer}>
              Stop #{index + Number(1)}
              <Grid container>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id="timeIn"
                  label="Time In"
                  type="time"
                  fullWidth
                  value={stop.timeIn}
                  onChange={e => handleChange(e, stop)}
                  className={classes.textField}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id="timeOut"
                  label="Time Out"
                  type="time"
                  fullWidth

                  value={stop.timeOut}
                  onChange={e => handleChange(e, stop)}
                  className={classes.textField}
                  variant="standard"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    id="jobSite"
                    onHighlightChange={handleHighlightChange}
                    options={state.jobSites || []}
                    onBlur={clearHighlightedOption}
                    getOptionLabel={(option) => {
                      // // console.log('jobsite Option:', option)
                      return `${option.communityName ? option.communityName : ''} ${option.name} ${option.buildingNumber ? option.buildingNumber : ''} ${option.address} (${option.city})`
                    }}
                    // style={{ width: 200 }}
                    className={classes.paddedStuff}
                    // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
                    onChange={handleJobsiteUpdate}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    value = {stop.jobSite}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="Jobsite" onKeyDown={e => handleTabInteraction(e, stop)} />}
                  />

                </Grid>
                {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Autocomplete
                    id="agreement"
                    options={props.ags}
                    onHighlightChange={handleHighlightChange}
                    getOptionLabel={(option) => {
                      // // console.log('Option:', option)
                      return `${option.jobName} ${option.customerType}`
                    }}
                    // style={{ width: 200 }}
                    className={classes.paddedStuff}
                    onBlur={clearHighlightedOption}
                    // onChange={ (e) => updateAutoComplete( e.target.id, e.target.value) }
                    onChange={e => updateAG(e, newVal, stop)}
                    // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                    value = {stop.agreement}
                    // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                    renderInput={(params) => <TextField {...params} label="Mtc Agreement" onKeyDown={handleTabInteraction} />}
                  />

              </Grid> */}
              </Grid>
            </div>
          )
        })
      }
    </div>
  )
}
const DumpFeesComponent = (props) => {
  const classes = useStyles();

  const handleAddDumpFee = () => {
    let currentFees = [...props.fees] 
    currentFees.push({
      fee: 0,
      tons: 0,
      date: new Date()
    })
    // setState({...state, fees: currentFees })
    props.updateParent(currentFees)
  }

  const handleChange = (e, index) => {
    let currentFees = [...props.fees]
    let tonsDumped = e.target.value ? e.target.value : 0
    currentFees[index]['tons'] = tonsDumped
    currentFees[index]['fee'] = currencyFormatWithOutFlair(77 * Number(tonsDumped))
    // setState({...state, fees: currentFees })

    props.updateParent(currentFees)
  }
  const handleChangeFee = (e, index) => {
    console.log('Change fee', e.target.value)
    let currentFees = [...props.fees] 
    currentFees[index]['fee'] = e.target.value
    // currentFees[index]
    // setState({...state, fees: currentFees })
    console.log('New fees', currentFees)
    props.updateParent(currentFees)
  }
  

  return (
    <div className={classes.dumpFeeContainer}>
      <div className={classes.dumpFeeContainerTopRow}>
      
      <Button variant="contained" color="secondary" size="small" onClick={handleAddDumpFee}>Add Dump Fee</Button>
      </div>
      <div className={classes.dumpFeeArea}>
      {
        props.fees.map((dumpFee, index) => {
          // console.log('Dump Fee', dumpFee)
          return (
            <div key={index} className={classes.dumpTicketContainer}>
               <div className={classes.dumpTicketHeader} >Dump Ticket #{Number(index) + 1}</div>
               <Grid container className={classes.dumpFeeInputArea} key={index}>
             
             <Grid item lg={6} md={6} sm={12} xs={12} >
                    <TextField
                      key={index}
                      value={dumpFee.tons}
                      id={ `tons-${index}` }
                      fullWidth
                      onFocus={handleFocus}
                      onChange={e => handleChange(e, index)}
                      className={classes.textField}
                      variant="outlined"
                      // onChange={ e => setJobName(e.target.value) }
                      // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={`Tons Dumped (Dump #${index + 1})`}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12} >
                    <TextField
                      key={index}
                      value={dumpFee.fee}
                      id={ `fee-${index}` }
                      fullWidth
                      onFocus={handleFocus}
                      onChange={e => handleChangeFee(e, index)}
                      className={classes.textField}
                      variant="outlined"
                      // onChange={ e => setJobName(e.target.value) }
                      // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={`Dump Fee #${index + 1}`}
                    />
                  </Grid>
                </Grid>
          </div>
          )
        })
      }
      </div>
    </div>
  )
}

export default ADOTLogModificationModal