import React from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import ChooseEntityComponent from '../../Entities/ChooseEntityComponent'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField }  from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import PlusIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ServiceConfiguration from './ServiceConfiguration'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
// console.log('Tab Panel Props: ', props)
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ padding: 0 }}>
          <React.Fragment>{children}</React.Fragment>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles(theme => ({
const styles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      margin: 10,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  topButtons: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center',
      margin: 5,
    },
    [theme.breakpoints.down('md')]: {
      margin: 5,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  helperText: {
    fontSize: '1.5em',
    color: '#bbb'
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    
  },
  list: {
    width: '100%',
    height: 600,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  containerArea: {
    // backgroundColor: 'red',
    // border: '3px solid blue'
  },
  categoryHeader: {
    fontSize: '1.3em',
    fontWeight: 800
  },
  pageHeader: {
    fontSize: '1.4em',
    fontWeight: 600,
    color: '#ccc'
  },
  tabsroot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 550,
  },
  tabsroot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 550,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: 550
  },
  tabDetailContainer: {
    // width: 270,
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'stretch'
  },
  tabDetails: {
    // display: 'flex',
    // alignSelf: 'stretch',
    // flexGrow: 1
  },
  subHeader: {
    fontSize: '1.4em',
    fontWeight: 700
  }
  })
);

const PlantDetails = (props) => {
  return (
    <>PLant DETAILS BABY</>
  )
}

const RequiredInputModal = ({ open, service, saveInputs, url }) => {
  // console.log('Required Input modal: ', service)
  let requiredInputs = service.requiredInputs || []

  const updateItem = (item, val) => {
    // console.log('Update this: ', item)
    // console.log(val)
    let inputIndex = requiredInputs.findIndex(inputItem => (inputItem.name === item.name))
    if (inputIndex > -1) {
      requiredInputs[inputIndex]['value'] = val
    }
    // console.log('Service w/ updated inputs: ', service)
    // console.log(requiredInputs)
  }

  const dealWithEquations = (service) => {
    let obj = {
      service: service
    }
    axios({
      method: 'post',
      url:  `${url}/api/proposal/dealWithEquations`,
      data: obj,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {

      //   }
      // },
    }).then(response => {
    // console.log('Rsponse.data', response.data)
       saveInputs({ service: service, equations: response.data })
    })
  }

  const handleSaveInputs = () => {
  // console.log('Save this shit:', service)
    // Good time to deal w/ our equations
  // console.log('Deal w/ Our Equations!!!', service)
    dealWithEquations(service)
    // saveInputs(service)
  }
  return (
    <Dialog
      open={open}
      >
        <DialogTitle>Required Input</DialogTitle>
        <DialogContent>
          <DialogContentText>Please Fill In The Following Required Input(s):</DialogContentText>
          {
            requiredInputs.map((item, index) => {
              return (
                <p key={index}>
                  <TextField 
                    fullWidth
                    label={item.name}
                    variant="outlined"
                    onChange={e=> updateItem(item, e.target.value)}
                    helperText={item.description}
                  />
                </p>
              )
            })
          }
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleSaveInputs}
            color="primary"
            fullWidth
          >Save</Button>
        </DialogActions>
      </Dialog>
  )
}

const TabDetails = ({ type, services, updateParent, stuff, estimate, sizes, handleEditServiceOnParent, handleDeleteServiceOnParent, url }) => {
// console.log('Tab Details Stuff: ', estimate)
// console.log('Tab Details URL: ', url)
  const classes = styles()
  const [state, setState] = React.useState({
    serviceType: '',
    chosen: []
  })
  const [showRequiredInput, setShowRequiredInput] = React.useState(false)

  let ourChosen = []
  // if (estimate && estimate.services && estimate.services[type]) {
  // // console.log('Set chosen:', estimate.services)
  // // console.log(estimate.services[type])
  //   ourChosen = estimate.services[type]
  // // console.log('OUR CHOSEN:', ourChosen)
  //   setState({...state, chosen: ourChosen })
  // }
  React.useEffect(() => {
    // setState({...state, serviceType: ''})
  // console.log('Estimate changed: ', estimate)
    if (estimate && estimate.serviceList && estimate.serviceList.length) {
    // console.log('Use this shit????', estimate.serviceList)
    }
  }, [estimate])

  React.useEffect(() => {
  // console.log('Type changed - reset service type:')
    setState({...state, serviceType: null })
  }, [type])

  const updateServiceType = (e, newVal) => {
    setState({...state, serviceType: newVal})
    // updateParent({ type: type, value: newVal })
  }

  const handleSaveSvc = () => {
    let newVal = state.serviceType
    // if (newVal && newVal.requiredInputs && newVal.requiredInputs.length) {
    //   setShowRequiredInput(true)
    // } else {
    //   setState({...state, serviceType: null })
    //   updateParent({ type: type, value: newVal})
    // }
    setState({...state, serviceType: null })
    updateParent({ type: type, value: newVal})
  }

  const handleEditService = (item, index) => {
  // console.log('Edit this: ', item)
    // console.log('SEnd the scope type too', state)
    handleEditServiceOnParent(item, index)
  }

  const handleDeleteService = (item, type) => {
    // console.log('Delete this fucker', item)
    handleDeleteServiceOnParent(item, type)
  }

  const handleSaveInputValues = (data) => {
  // console.log('Service saved w/ values AFTER Equations Run:', data)
    const serviceWithInputValues = data.service
  // console.log('Service:', serviceWithInputValues)
    setShowRequiredInput(false)
    setState({...state, serviceType: null })
    updateParent({ type: type, value: serviceWithInputValues})
  }

  const requiredInputs = (state.serviceType && state.serviceType.requiredInputs && state.serviceType.requiredInputs.length) ? state.serviceType.requiredInputs : []
  return (
    <div style={{ width: 700, height: '100%', margin: 5, padding: 5 }}>
      { requiredInputs.length > 0 && <RequiredInputModal url={url} open={showRequiredInput} service={state.serviceType} saveInputs={handleSaveInputValues} /> }
      <Typography className={classes.subHeader}>
        Choose Service
      </Typography>
      <Autocomplete
        id={`servicename-${state.serviceIndex}`}
        options={services || []}
        
        getOptionLabel={(option) => {
          // console.log('Options: ', option)
          return option.name || ''
        }}
        // style={{ width: 200 }}
        onChange={updateServiceType}
        // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
        value = {state.serviceType}
        // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
        renderInput={(params) => <TextField {...params} fullWidth label="Service Type" variant="outlined" />}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSaveSvc}
        disabled={!state.serviceType || !state.serviceType._id}
        >Save</Button>
      <div>
        <div style={{height: '400px', overflowY: 'auto'}}>

          <ServiceConfiguration estimate={estimate} type={type} sizes={sizes} chosen={ourChosen} handleEditService={handleEditService} handleDeleteService={handleDeleteService} />
        </div>
      </div>
    </div>
  )
}

const CustomTabPanels = ({ scopes, value, services, sizes, updateParent, stuff, estimate, handleEditService, handleDeleteService, url }) => {
  const classes = styles()
  // console.log('Custom Tab Panesl: ', services)
  // console.log('Estimate:', estimate)
  // console.log('SCOPES:', scopes)
  // console.log('VALUE: ', value)
  let filteredServices = []
  // console.log('Find this: ', value._id)
  // console.log('SERVICES: ', services)
  if (services && services.length) {
    services.map((item, index) => {
    if (item.scope) {
      // Match this to our scope value
      // console.log(item.scope)
      if (value._id === item.scope) {
        filteredServices.push(item)
      }
    }
    // console.log('Item: ', item)
    // console.log(item.scope)
    })
  }
  // Remvoe already selected services
  for (const listItem of estimate.serviceList) {
    let servicesChosen = listItem.services
    // console.log('Services chosen:', servicesChosen)
    servicesChosen.map(item => {
      let index = filteredServices.findIndex(svc => (svc._id === item._id))
      if (index > -1) {
        // console.log('REmove this:', item)
        filteredServices.splice(index, 1)
      }
    })
  }

  // console.log('Filtered Services: ', filteredServices)
  return (
    <TabPanel index={value.name} value={value.name} className={classes.tabDetailContainer}>
 
   <TabDetails url={url} type={value.name} services={filteredServices} sizes={sizes} updateParent={updateParent} stuff={stuff} estimate={estimate} handleEditServiceOnParent={handleEditService} handleDeleteServiceOnParent={handleDeleteService} className={classes.tabDetails}/>

  </TabPanel>

    // <TabPanel index={value.name} value={value.name} style={{ backgroundColor: 'orange', width: '100%'}}>
    //   <div className={classes.tabDetailContainer}>
    //     <div className={classes.tabDetails}>Test</div>
    //     <div className={classes.tabDetails}>Test</div>
    //   </div>
    //   {/* <TabDetails type={value.name} services={filteredServices} updateParent={updateParent} stuff={stuff} estimate={estimate} />
    //     { TabDetails({ type: value.name, services: filteredServices, updateParent: updateParent, stuff: stuff, estimate: estimate }) } */}
    // </TabPanel>
  )
  // return (
  //   <React.Fragment>
  //     {/* {
  //       scopes.map((item, index) => {
  //       // console.log('ITEM TO MAKE A TAB: ', item)
  //         return (
  //           <TabPanel value={item} index={index}>
  //               {item}
  //           </TabPanel>
  //         )
  //       })
  //     } */}
  //   </React.Fragment>

  // )

}

const ServiceList = (props) => {
  // console.log('Service List Props: ', props)
  const estimate = props.estimate
  const scopes = estimate.scopes
  const classes = styles();
  const [value, setValue] = React.useState(scopes[0]);
  // props.setStuff()

  // React.useEffect(() => {
  // // console.log('Service List Props.estimate Updated: ', props.estimate)
  // }, [props.estimate])
  
  const handleChange = (event, newValue) => {
    // console.log('New Value: ', newValue)
    setValue(newValue);
  };

  const customTabPanelUpdate = (d) => {
  // console.log('Custom Tab Panel: ', d)
    props.setStuff(d, value)
  }

  return (
    <div className={classes.tabsroot}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={classes.tabs}
      >
        {
          scopes.map((item, index) => {

            return (
              <Tab label={item.name} key={index} value={item} {...a11yProps(index)} />
            ) 
          })
        }
        {/* <Tab label="Item One" {...a11yProps(0)} />
        <Tab label="Item Two" {...a11yProps(1)} />
        <Tab label="Item Three" {...a11yProps(2)} />
        <Tab label="Item Four" {...a11yProps(3)} />
        <Tab label="Item Five" {...a11yProps(4)} />
        <Tab label="Item Six" {...a11yProps(5)} />
        <Tab label="Item Seven" {...a11yProps(6)} /> */}
      </Tabs>

      <CustomTabPanels url={props.url} scopes={scopes} sizes={props.sizes} value={value} services={props.services} updateParent={customTabPanelUpdate} handleEditService={props.handleEditService} handleDeleteService={props.handleDeleteService} stuff={props.stuff[value]} estimate={props.estimate} />
      {/* {
        value === 'Irrigation' ? (
          <TabPanel >
            Irrigation
          </TabPanel>
        ) : null
      }
      {
        value === 'Trees' ? (
          <TabPanel >
            Trees
          </TabPanel>
        ) : null
      }
      <TabPanel value={'Irrigation'} index={0}>
        Irrigation
      </TabPanel>
      <TabPanel value={'Lighting'} index={1}>
        Lighting
      </TabPanel>
      <TabPanel value={'Trees'} index={2}>
        Trees
      </TabPanel>
      <TabPanel value={'Plants'} index={3}>
        Plants
      </TabPanel> */}
    </div>
  );
}

const ChooseServices = (props) => {
// console.log('ChooseServices -- estimate ID?? ', props)
  const classes = styles()
  const [loading, setLoading] = React.useState(false)
  const [clients, setClientList] = React.useState([])
  const [crews, setCrews] = React.useState([])
  const [employees, setEmployees] = React.useState([])
  const [salesList, setSalesList] = React.useState([])
  const [projects, setProjects] = React.useState([])
  const [vendors, setVendors] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0
  })
  const [vendorInvoiceInputModalOpen, setVendorInvoiceInputModalOpen] = React.useState(false)
  const [showLaborTrackerModal, setShowLaborTrackerModal] = React.useState(false)
  const [estimate, setEstimate] = React.useState({})
  const [stuff, setStuff] = React.useState([])
  const [services, setServices] = React.useState([])

  React.useEffect(() => {
    let isMounted = true
  // console.log('Estimate Has Updated...have our services??', props.estimate)
    // console.log(props.estimate.services)
    // console.log("Filtered Services: ', filtered")
    setServices(props.services)
    if (props.estimate && props.estimate.serviceList && props.estimate.serviceList.length) {
      console.log('Set our list:', props.estimate.serviceList)
      setStuff(props.estimate.serviceList)
    } else {
      console.log('Clear the list:')
      setStuff([])
    }
    // // console.log('We have a service list:!!', props.estimate.serviceList)
    //   let theStuffToSet = props.estimate.serviceList
    // // console.log('Use this: ', theStuffToSet)
    // // console.log('EXISTING STUFF: ', stuff)
    // // console.log('PRops.stuff', props.stuff)
    //   // if (theStuffToSet && theStuffToSet.length) setStuff(theStuffToSet)
    // } else {
    // // console.log('No Service LIst length')
    // }
    
    return () => {
      isMounted = false
    }
  }, [props.estimate])

  // React.useEffect(() => {
  // // console.log('Stuff has changed!!!', stuff)

  // }, [stuff])

  const updateCategoriesByRemovingAddedScope = (d) => {
    let itemToRemoveFromServices = d.value
    // console.log("Remove this", itemToRemoveFromServices)
    let newServices = services
    let index = newServices.findIndex(item => (item._id === itemToRemoveFromServices._id))
    if (index > -1) {
      newServices.splice(index, 1)
    }
    // console.log('UPdated Services List:', newServices)
    setServices(newServices)
  }

  const handleSetStuff = (d, ourType) => {
    // setStuff({...stuff, }
    console.log('Set STUFF:: ', d)
    updateCategoriesByRemovingAddedScope(d)
  // console.log('Type: ', ourType)
    let theStuff = stuff
  // console.log('Existing stuff; ', theStuff)
    // After leaving services screen we lose STUFF - check Estimate.serviceList instead???
  // console.log('Estimate serviceList?', props.estimate)
    // console.log()
    let typeIndex = theStuff.findIndex(item => (item.type === d.type))
  // console.log('Type Index:', typeIndex)
    if (typeIndex > -1) {
    // console.log('Our stuff: ', theStuff[typeIndex])
    // console.log('Now add the new service to it...???')
      let servicesToUpdate = theStuff[typeIndex]['services']
    // console.log('Services to Update:', servicesToUpdate)
      servicesToUpdate.push(d.value)
    } else {
      // theStuff.push(d)
      let serviceArray = []
      serviceArray.push(d.value)
      let ourItem = {
        type: d.type,
        scopeId: d._id,
        services: serviceArray
      }
      theStuff.push(ourItem)
    // console.log('That doesnt exist...but it should????')
    }
    // theStuff.push(d)

  // console.log('Updated stuff', theStuff)
    setStuff(theStuff)
    // If this has Default Line Items 
    props.updateParent(theStuff, false, d, ourType)
  }

  const handleSubmit = () => {
    props.finishedAddingServices(stuff, true)
  }

  return (
    <Paper style={{  margin: 10, padding: 10, height: '100%', overflowY: 'auto' }}>
      <Grid container spacing={2}>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <h1>Choose Proposal Services</h1>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} style={{ overflowY: 'auto', maxHeight: 800 }}>
            <ServiceList url={props.url} estimate={props.estimate} sizes={props.sizes} services={services} setStuff={handleSetStuff} stuff={stuff} handleEditService={props.handleEditService} handleDeleteService={props.handleDeleteService} />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
          <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={handleSubmit}
              aria-label="Submit"
              // disabled={!right.length}
            >
              Finished Adding Services
            </Button></Grid>
          {/* 
          <Grid item lg={6} md={8} sm={12} xs={12}>
            <Button
              variant="contained"
              fullWidth
              color="primary"

              aria-label="Submit"
            >
              Create Proposal
            </Button>
          </Grid> */}
      </Grid>
    </Paper>
  )
}

const mapStateToProps = (state) => {
  // console.log('MAP PROPS: ,', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    services: state.services
  }
}

export default React.memo(connect(mapStateToProps)(ChooseServices))