import React, { useEffect, useState } from 'react';
import { Modal, Backdrop, Fade, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pdfViewer: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    width: '90%',
    height: '90vh',
    maxHeight: '90vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  actionRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  pdfContainer: {
    flex: 1,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative'
  },
  pdfObject: {
    width: '100%',
    height: '100%',
    border: 'none'
  }
}));

const PDFModal = ({ pdf, open, handleClose, extraButton }) => {
  const classes = useStyles();
  const [pdfUrl, setPdfUrl] = useState('');
  const isMobile = typeof window !== 'undefined' && window.innerWidth < 768;

  useEffect(() => {
    if (!pdf) return;

    let url = pdf;
    console.log('54 ', url)
    // If it's a base64 string, convert it to a blob URL
    if (pdf && pdf.toString().startsWith('data:application/pdf;base64,')) {
      try {
        const base64Data = pdf.split(',')[1];
        const byteCharacters = atob(base64Data);
        const byteArrays = [];
        
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
          const slice = byteCharacters.slice(offset, offset + 512);
          const byteNumbers = new Array(slice.length);
          
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          
          byteArrays.push(new Uint8Array(byteNumbers));
        }
        
        const blob = new Blob(byteArrays, { type: 'application/pdf' });
        url = URL.createObjectURL(blob);
      } catch (error) {
        console.error('Error converting base64 to blob:', error);
        return;
      }
    }
    
    setPdfUrl(url);
    
    return () => {
      console.log('83 URL', url)
      if (url && url.toString().startsWith('blob:')) {
        URL.revokeObjectURL(url);
      }
    };
  }, [pdf]);

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={open}>
        <div className={classes.pdfViewer}>
          <div className={classes.actionRow}>
            <div>
              {extraButton}
            </div>
            <Button 
              onClick={handleClose} 
              variant="contained" 
              size="small" 
              color="secondary"
            >
              Close
            </Button>
          </div>
          
          {pdfUrl && (
            <div className={classes.pdfContainer}>
              {isMobile ? (
                <iframe
                  src={pdfUrl}
                  className={classes.pdfObject}
                  title="PDF Viewer"
                />
              ) : (
                <object
                  data={pdfUrl}
                  type="application/pdf"
                  className={classes.pdfObject}
                >
                  <p>
                    Your browser does not support PDFs.{' '}
                    <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                      Download PDF
                    </a>
                  </p>
                </object>
              )}
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default PDFModal;