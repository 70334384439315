import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { fade, makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import MenuItem from "@material-ui/core/MenuItem";
import Questions from './CalculationQuestions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  textSaveLineBreaks: {
    whiteSpace: 'pre-line'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

const calculationOptions = [
  'Plant Material Labor',
  'Gravel/Rip-Rap',
  'Irrigation',
  'Lighting',
  'Firepit',
  'BBQ',
  'Pool/Spa',
  'Spray'
]

const defaultStateValues = {
  calculator: '',
  savedCalculations: {},
  isComplete: false
}

function CalculationModal(props) {
  // console.log('CalculationModal Modal Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState(defaultStateValues)

  const handleClose = () => {
    setState({...state, savedCalculations: {}, isComplete: false, calculator: ''})
    props.closeModal()
  }

  const handleSelect = (e) => {
    setState({...state, calculator: e.target.value })
  }

  const answerTheQuestions = (data) => {
  console.log('Answers:', data)
    let quest = data.question
    let name = data.name
    let isComplete = false
    let numberOfQuestions = data.totalQuestions
    let currentCalculations = state.savedCalculations
    console.log('Current Calculations:', currentCalculations)
    currentCalculations[name] = data
    // if (currentCalculations[name]) {
    // // console.log('We found it!', currentCalculations[name])
    // } else {
    // // console.log('Add it')
    //   currentCalculations[name] = data
    // }
  // console.log(currentCalculations)
  console.log('# of questions', numberOfQuestions)
    // Determine if ALL questions are answered
    let questionsAnswered = Object.keys(currentCalculations).length
  console.log('How many questions are answered?', questionsAnswered)
    if (questionsAnswered === numberOfQuestions) {
    // console.log('We are done!!')
      isComplete = true
    }
    setState({...state, savedCalculations: currentCalculations, isComplete: isComplete })
  }

  const handleCalculation = () => {
  // console.log('Calculate this shit!!', state)
    setLoading(true)
    axios({
      method: 'post',
      url:  `${props.url}/api/proposal/handleCalculation`,
      data: {
        question: state,
        estimate: props.estimate,
        service: props.service
      }
    }).then(response => {
      setLoading(false)
      setState({...state, savedCalculations: {}, isComplete: false, calculator: ''})
      if (response.data && response.data.success) {
      // console.log('Data', response.data)
        // let lineItemObject = {
        //   qty: 0,
        //   pricePer: 0,
        //   totalPrice: 0,
        //   name: '',
        //   description: ''
        // }
        // console.log(lineItemObject)
        // lineItemObject = {...response.data}
        // console.log('NOw:', lineItemObject)
        props.notification({
          type: 'success',
          title: 'Calculation Complete',
          message: 'Check Out The Line Item Details!'
        })
        // handleClose()
        // setState(defaultStateValues)
        if (props.service) {
          props.updateParent(response.data)
        } else {
          props.updateParent(response.data.lines)
        }
       
        // props.closeModal()
      }
    })
  }
// console.log('State RIGHT NOW:', state)
  return (
    <div>
      <Dialog
        maxWidth='lg'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">DFLAZ Calculators</DialogTitle>
        <DialogContent> 
          <DialogContentText>
            Please choose the type of calculation that you are trying to accomplish
          </DialogContentText>
          <TextField
            select
            fullWidth
            variant="outlined"
            label="Calculation Type"
            defaultValue="Please Choose"
            value={state.calculator}
            helperText="What Are You Trying To Calculate?"
            onChange={handleSelect}
            >
              {
                calculationOptions.map((item, index) => (
                  <MenuItem key={index} value={item}>{item}</MenuItem>
                ))
              }
              </TextField>
            <Questions {...state} updateParent={answerTheQuestions} />
        </DialogContent>
        <DialogActions>
          {
            loading ? <CircularProgress color="primary" size={ 36 } />: (
              <React.Fragment>
                <Button autoFocus onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button disabled={!state.isComplete || props.estimate.accepted } onClick={handleCalculation} color="primary" autoFocus>
                  Calculate
                </Button>
              </React.Fragment>
              
            )
          }

        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(CalculationModal)