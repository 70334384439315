import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper'
import axios from 'axios'
import ScopesList from './ScopesList'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
const Scopes = (props) => {
  // console.log('Scopes Component', props)
  const [list, setList] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0
  })

  React.useEffect(() => {
    let isMounted = true
    async function fetchScopes () {
      axios
      .get(
        `${props.url}/api/settings/listScopes`
        )
      .then(({ data }) => {
        setList(data)
      }).catch(function (err) {
      // console.log('Error getting stuff', err)
      });
    }
    fetchScopes()
    return () => {
      isMounted = false
    }
  }, [])

  const updateScopes = (scope) => {
    let scopes = list
  // console.log('Update Scope: ', scope)
    // console.log('Got %d clients: ', clients.length)
    let index = scopes.findIndex(service =>  (service._id === scope._id))
    if (index > -1) {
      // console.log('Svc already exists', index)
      // console.log(services[index])
      scopes[index] = scope
      // console.log('Agai: ', services[index])
      // console.log(services)
      // setRows(services)
      // setFilteredRows(services)
      setList(scopes)
    } else {
      scopes.push(scope)
      setList(scopes)
    }
    setState({...state, counter: state.counter++ })
  }

  const handleBack= () => {
    props.history.push('/settings')
  }

  return (
    <div style={{padding: '5px', margin: '10px'}}>
      <Grid container>
        <Grid item lg={6} xs={12}>
          <h1>Scopes</h1>
        </Grid>
        <Grid item lg={6} xs={12}>
        </Grid>

        <Grid item lg={12} xs={12}>
          <Paper style={{ minHeight: '80vh' }}>
            <Grid container spacing={4} style={{ padding: '10px' }}>
              <Grid item lg={12} xs={12}>
                <Button
                  variant="outlined"
                  onClick={handleBack}
                  style={{margin: 8}}
                  startIcon={<ArrowBackIcon />}>
                    Back
                  </Button>
              </Grid>
              <Grid item lg={12} xs={12}>
                <ScopesList scopes={list} updateScopes={updateScopes} />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      
  </div>
  )
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(Scopes)