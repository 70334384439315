import React from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import moment from 'moment';

const icon = 'https://dflportal.com/pickupTruck.png'

const CusomMarker = (props) => {
    // console.log('Custom Marker: ', props)
    const [state, setState] = React.useState({
        showInfoWindow: false
    })

    const handleMouseOver = e => {
        setState({
            ...state,
            showInfoWindow: true
        });
        props.updateActiveMarker(props.addy)
    };
    const handleMouseExit = e => {
        setState({
            ...state,
            showInfoWindow: false
        });
    };
    // console.log('Cusom Marker ', props)
    let angle = 0
    if (props && props.addy && props.addy.heading) {
        angle = Number(props.addy.heading)
        // console.log('addy', props.addy)
    }
    // var symbol = {  
    //     path: "M0 0 H 90 V 90 H 0 L 0 0",
    //     fillColor: '#FF0000',
    //     fillOpacity: .5,
    //     strokeWeight: 0,
    //     scale: .5,
    //     rotation: angle
    // }
    const { showInfoWindow } = state;
    // const { lat, lng } = props.position
    const addy = props.addy
    const activeMarker = props.activeMarker
    // console.log('Our Active Marker: ', activeMarker)
    let client = {}
    if (addy && addy.client) client = addy.client
    // console.log('Client: ', client)
    const info = `${client.displayName}`
    // console.log('Addy: ', addy)
    // if (props.position && props.position.lat && props.position.lng) {
    //     // console.log('Custom Marker: ', props.position)
    //     return (
    //         <Marker style={{ transform: 'rotate(45)' }} position={{ lat, lng }} icon={{
    //             path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    //             scale: 6,
    //             fillColor: '#ACCACD',
    //             fillOpacity: 0.8,
    //             strokeWeight: 2,
    //             rotation: angle,
    //         }} 
    //             onMouseOver={handleMouseOver}
    //             onMouseOut={handleMouseExit}>
    //             {state.showInfoWindow && (
    //                 <InfoWindow>
    //                     <h4>Crew: {addy.crewName}</h4>
    //                     <h4>Heading: {angle}</h4>
    //                 </InfoWindow>
    //             )}
               
    //         </Marker>
    //     );
    if (addy && addy.lat && addy.lng) {
        // console.log('Custom Marker: ', props.position)
        const lat = Number(addy.lat)
        const lng = Number(addy.lng)
        let theColor = addy.color
        let scale = 3
        let strokeColor = 'black'
        if (addy._id === activeMarker._id) {
            // console.log('This is our boy!!!', addy)
            theColor = '#fff'
            scale = 9
            strokeColor = 'red'
        }
        //  { lat, lng } = addy
        // console.log('lat: ', lat)
        // console.log('lng: ', lng)
        return (
            <Marker style={{ transform: 'rotate(45)' }} position={{ lat, lng }} icon={{
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: scale,
                fillColor: theColor,
                fillOpacity: 0.8,
                strokeWeight: 2,
                rotation: angle,
                strokeColor: theColor
            }} 
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseExit}>
                {state.showInfoWindow && (
                    <InfoWindow>
                       <>
                            <h4>Job: {addy.jobName}</h4>
                            <h5>Day: {addy.day}</h5>
                            <h5>Crew: {addy.crewName}</h5>
                            <h5>Amount: ${addy.amount.toFixed(2)}</h5>
                        </>
                    </InfoWindow>
                )}
               
            </Marker>
        );
    } else {
        return null
    }


}

export default React.memo(CusomMarker)