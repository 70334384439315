import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  }
}));

// function htmlToText(html){
//   // console.log('Strip html tags: ', html)
//   //remove code brakes and tabs
//   html = html.replace(/\n/g, "");
//   html = html.replace(/\t/g, "");

//   //keep html brakes and tabs
//   html = html.replace(/<table>/g, "");
//   html = html.replace(/<tr>/g, "");
//   html = html.replace(/<td>/g, "");
//   html = html.replace(/<\/td>/g, "\t");
//   html = html.replace(/<\/table>/g, "\n");
//   html = html.replace(/<\/tr>/g, "\n");
//   html = html.replace(/<\/p>/g, "\n");
//   html = html.replace(/<\/div>/g, "\n");
//   html = html.replace(/<div>/g, "");
//   html = html.replace(/<\/h>/g, "\n");
//   html = html.replace(/<br>/g, "\n"); 
//   html = html.replace(/<br( )*\/>/g, "\n");
//   // console.log('Stripped: ', html)
//   //parse html into text
//   // var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
//   // return dom.body.textContent;
//   return html;
// }
const defaultStateValues = {
  jobName: '',
  billingAddress: {},
  jobSiteAddress: '',
  jobSite: {},
  communityName: '',
  directions: '',
  gateCode: '',
  salesRep: {},
  billingAddy: {},
  notes: '',
  jobSiteName: '',
  address: '',
  buildingNumber: '',
  city: '',
  state: '',
  zipcode: ''

}

const NewJobSite = (props) => {
  // console.log('New NewJobSite: ', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.open)
  const [loading, setLoading] = React.useState(false)
  const [jobNumber, setJobNumber] = React.useState(null)
  const [state, setState] = React.useState(defaultStateValues)
  React.useEffect(() => {
    // console.log('Use Effect New jobSiteName')
    // let jobName = 'Job - '
    // if (props.client.lastName) jobName = props.client.lastName + '- '
    // if (props.client.companyName) jobName = props.client.companyName + '- '
    setOpen(props.open)
    // setState({...state, jobName: jobName})
  }, [props])

const handleClose = () => {
  // console.log('Closing New Job Modal')
  // setOpen(false)
  setState(defaultStateValues)
  props.updateParent('Close')
}

const handleConfirm = (data) => {
  // console.log('CONFIRMED NEW JOB!!', data)
}
const handleSave = () => {
  setLoading(true)
  // console.log('handle save', state)
  // console.log(props)
  let obj = {
    client: props.client,
    data: state,
    user: props.user
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/customer/createNewJobSite`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to create new Job Site: ', response)
    setLoading(false)
    if (response && response.data && response.data._id) {
      props.notification({
        type: 'success',
        title: 'New Job Site was created and saved!',
        message: 'Well Done!!'
      })
      setState(defaultStateValues)
      props.updateParent(response.data)
    }
    // setState({})
    // setJobNumber(response.data)
    // if (response.data.number) {
    //   props.jobDetailsResponse(response.data)
    //   setState({})
    //   props.notification({
    //     type: 'success',
    //     title: 'Workorder ' + response.data.Job + ' was created and saved!',
    //     message: 'Well Done!!'
    //   })
    //   // setOpen(false)
    // } else {
    //   // console.log('No fucking workorder')
    //   props.notification({
    //     type: 'warning',
    //     title: 'Uh Oh!',
    //     message: 'Something messed up creating this workorder...not sure why!'
    //   })
    // }
    
  }).catch(err => {
    // console.log('Error saving Job Site: ', err)
  })
}

const updateBillingAddress = (e, addy) => {
  // console.log('Update Billing Address: ', e)
  // console.log(addy)
  if (addy && addy.address) {
    setState({...state, billingAddress: addy})
  }
}
const updateJobSiteAddress = (e, addy) => {
  // console.log('Update Job Site Address: ', e)
  // console.log(addy)
  if (addy && addy.address) {
    setState({...state, jobSite: addy.id, jobSiteAddress: addy})
  }
}
const updateSalesRep = (e, rep) => {
  // console.log('Update Sales rep ', e)
  // console.log(rep)
  if (rep && rep._id) {
    setState({...state, salesRep: rep})
  }
}
return (
  <div className={classes.root}>
    <Dialog open={open} fullWidth={true} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create New Site</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Provide The Basic Site Info Below
          </DialogContentText>
          <Grid container>
            <Grid item lg={9} md={8} sm={6} xs={12}>
            <TextField
            autoFocus
            className={classes.paddedStuff}
            margin="dense"
            id="JobSiteName"
            label="Site Name"
            type="text"
            onChange={(e) => setState({...state, jobSiteName: e.target.value})}
            value={state.jobSiteName}
            fullWidth
          />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <TextField
              autoFocus
              className={classes.paddedStuff}
              margin="dense"
              id="jobsitebuildingNumber"
              label="Building Number"
              type="text"
              onChange={(e) => setState({...state, buildingNumber: e.target.value})}
              value={state.buildingNumber}
              fullWidth
            />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
            
            className={classes.paddedStuff}
            margin="dense"
            id="CommunityName"
            label="Community Name"
            type="text"
            onChange={(e) => setState({...state, communityName: e.target.value})}
            value={state.communityName}
            fullWidth
          />
            </Grid>
            <Grid item lg={6} md={6} sm={6} xs={12}>
            <TextField
            
            className={classes.paddedStuff}
            margin="dense"
            id="GateCode"
            label="Gate Code"
            type="text"
            onChange={(e) => setState({...state, gateCode: e.target.value})}
            value={state.gateCode}
            fullWidth
          />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
            className={classes.paddedStuff}
            margin="dense"
            id="JobSiteAddress"
            label="Job Site Address"
            type="text"
            onChange={(e) => setState({...state, address: e.target.value})}
            value={state.address}
            fullWidth
          />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <TextField
                label="Job Site City"
                fullWidth
                value= {state.city}
                className={classes.paddedStuff}
                onChange={ e => setState({...state, city:e.target.value } ) }
                // variant="outlined"
                // value= {state.service.time}
                // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                // defaultValue="Default Value"
                />
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
              <TextField
                label="Job Site State"
                fullWidth
                value= {state.state}
                className={classes.paddedStuff}
                onChange={ e => setState({...state, state:e.target.value } ) }
              />
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={6}>
            <TextField
                label="Job Site Zipcode"
                fullWidth
                // type="number"
                // variant="outlined"
                value= {state.zipcode}
                className={classes.paddedStuff}
                onChange={ e => setState({...state, zipcode:e.target.value } ) }
                />
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
            <TextField
                label="Job Site Notes"
                fullWidth
                // type="number"
                // variant="outlined"
                value= {state.notes}
                className={classes.paddedStuff}
                onChange={ e => setState({...state, notes:e.target.value } ) }
                />
            </Grid>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
            <TextField
                label="Job Site Directions"
                fullWidth
                // type="number"
                // variant="outlined"
                value= {state.directions}
                className={classes.paddedStuff}
                onChange={ e => setState({...state, directions:e.target.value } ) }
                />
            </Grid>
          </Grid>
         
          
         

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" disabled={loading || !state.state || !state.city || !state.address} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(NewJobSite))