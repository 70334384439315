import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import ProtectedButton from '../../actions/protectedButtons';
import { IconButton } from '@material-ui/core';
import NewEditTakeoffModal from './NewEditTakeoffModal'

const EditOrderQuantity = (props) => {
// console.log('Time to Edit: ', props)
  const [state, setState] = React.useState({
    qty: 0,
    size: ''
  })
  const [loading, setLoading] = React.useState(false)
  const [item, setItem] = React.useState({})

  React.useEffect(() => {
    // setState({...state, qty: props.item.orderQuantity || 0 })
    setItem(props.item)
  }, [props.item])

  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleUpdateSize = (e) => {
    setState({...state, size: e.target.value})
  }



  const handleDeleteService = () => {
    axios({
      method: 'post',
      url:  `${props.url}/api/inventory/deleteTakeoffItem`,
      data: item,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Axios response to update inventory ', response)
      // props.updateParent('updated', response.data)
      setLoading(false)
      // setState({})
      // setJobNumber(response.data)
      // if (response.data._id) {
      //   // props.newNoteResponse('newNote', response.data)
      //   props.updateContact(response.data)
      props.updateParent('deleted', item)
        // setState({note: ''})
        props.notification({
          type: 'success',
          title: 'Inventory Item Deleted!',
          message: 'This Item Has Been Deleted!'
        })
  })
  }

  const handleUpdateFromEdit = (type, data) => {
    // console.log('Update From Edit:', type)
    // console.log('Edited item::', data)
    props.updateParent(type, data)
  }

return (
  <div>
    
      <Dialog
        // fullScreen={false}
        maxWidth={'md'}
        // width='lg'
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Modify Takeoff Item</DialogTitle>
        <DialogContent>
          <ProtectedButton type="Shed Inventory" kind="delete" perms={props.perms}>
                  <Button
                  variant="contained"
                  style={{backgroundColor: '#ed4545', position: 'absolute', right: 20}}
                  size="small"
                  startIcon={<DeleteIcon />}
                  onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteService() } }
                  // onClick={handleDeleteService}
                  // onClick={offerDeleteOption}
                  // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
                >
                  Delete Item
                </Button> 
              </ProtectedButton>
     
          <DialogContentText>
            Please Update The Proper Fields Below:
          </DialogContentText>
          <NewEditTakeoffModal item={item} {...props} updateParent={handleUpdateFromEdit} />
       </DialogContent>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(EditOrderQuantity)