import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { Button, CircularProgress } from '@material-ui/core';
import Paper from '@material-ui/core/Paper'
import axios from 'axios'
import ChooseScope from '../Scopes/ChooseScope'

// This component is for anything related to a specific sized item
// so a 36" box tree might require equipment cost - that can be integrated here
// The labor time associated in total man hours can be added here
// The supervision time can be added here
const SizeDetails = (props) => {
// console.log('Size Details:', props)

  const handleBack= () => {
    props.history.push('/settings')
  }

  const handleUpdateScopes = (data) => {
  // console.log('Our Scope list has been updated ', data)
    props.updateSizeOptions(data)

  }

  return (
    <div>
      <h3>Size Configurations/Impacts</h3>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <ChooseScope item={props.item} updateScopes={handleUpdateScopes} />
        </Grid>
      </Grid>
  </div>
  )
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      scopes: state.scopes
    }
  }
  
  export default connect(mapStateToProps)(SizeDetails)