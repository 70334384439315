import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, CircularProgress }  from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

const ViewInvoiceModal = (props) => {
  // console.log('INvoice Modal: ', props)
  const [pdf, setPDF] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [workorder, setWorkorder] = React.useState({})
  React.useEffect(() => {
    // console.log('Use Effect INVOICE MODAL', props)
    setPDF(props.pdf)
  }, [props])

  React.useEffect(() => {
    // console.log('Workorder changed on us!!!', props)
    // setPDF(props.pdf)
    if (props.workorder && props.workorder._id) setWorkorder(props.workorder)
  }, [props.workorder])

  const handleClose = () => {
    // console.log('Closing invoice modal')
    setLoading(false)
    props.svcDetailsResponse('Close')
  }

  const handleEmailInvoice = () => {
    // console.log('Email Invoice')
    setLoading(true)
    let updateObject = {
      client: props.client,
      pdf: pdf,
      user: props.user
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/customer/emailInvoice`,
      data: updateObject,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      setLoading(false)
      // console.log('EMAIL INVOICE RSPONSE: ', response)
      props.notification({
        type: 'success',
        title: 'Invoice Emailed',
        message: 'Email Sent. Grab a cup of coffee, you did great!'
      })
    })
  }
  const handleApproveInvoice = () => {
    // console.log('Approve Invoice')
    setLoading(true)
    let updateObject = {
      client: props.client,
      user: props.user,
      pdf: pdf,
      workorder: props.workorder
    }
    // console.log('Update Object: ', updateObject)
    axios({
      method: 'post',
      url:  `${props.url}/api/invoice/approveInvoice`,
      data: updateObject,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      setLoading(false)
      // console.log('Approve INVOICE RSPONSE: ', response)
      props.notification({
        type: 'success',
        title: 'Invoice Approved',
        message: 'Done!'
      })
    })
  }
  const handleDeclineInvoice = () => {
    // console.log('Decline Invoice')
  }
  return (
    <div>
       <Dialog
        // fullScreen={true}
        maxWidth='sm'
        fullWidth
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Invoice</DialogTitle>
        <DialogContent>

          <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="800px" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(ViewInvoiceModal))