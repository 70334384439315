import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '10%',
    margin: '20px',
    zIndex: 9999999
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
    marginBottom: 15
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  }
}));

// function htmlToText(html){
//   // console.log('Strip html tags: ', html)
//   //remove code brakes and tabs
//   html = html.replace(/\n/g, "");
//   html = html.replace(/\t/g, "");

//   //keep html brakes and tabs
//   html = html.replace(/<table>/g, "");
//   html = html.replace(/<tr>/g, "");
//   html = html.replace(/<td>/g, "");
//   html = html.replace(/<\/td>/g, "\t");
//   html = html.replace(/<\/table>/g, "\n");
//   html = html.replace(/<\/tr>/g, "\n");
//   html = html.replace(/<\/p>/g, "\n");
//   html = html.replace(/<\/div>/g, "\n");
//   html = html.replace(/<div>/g, "");
//   html = html.replace(/<\/h>/g, "\n");
//   html = html.replace(/<br>/g, "\n"); 
//   html = html.replace(/<br( )*\/>/g, "\n");
//   // console.log('Stripped: ', html)
//   //parse html into text
//   // var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
//   // return dom.body.textContent;
//   return html;
// }

const defaultState = {
  dupes: [],
  loading: false
}
const DuplicatesModal = (props) => {
  // console.log('Duplicates Modal ', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState(defaultState)
  React.useEffect(() => {
    // console.log('Use Effect Dupes')
    setOpen(props.open)
  }, [props])

const handleClose = () => {
  // console.log('Closing New Job Modal')
  // setOpen(false)
  // setState({...state, jobName: '' })
  props.updateParent('Close')
}

const handleConfirm = (data) => {
  // console.log('CONFIRMED NEW JOB!!', data)
}
const handleSave = () => {
  setLoading(true)
  // console.log('handle save', state)
  // console.log(props)
  let obj = {
    client: props.newClient,
    user: props.user,
    ignoreDupes: true
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/customer/addNew`,
    data: obj
  }).then(response => {
    setLoading(false)
    // console.log('Axios response from Add Customer: ', response)
    if (response && response.data && response.data._id) {
      // console.log('We have a new client!!', response.data)
      props.notification({
        type: 'success',
        title: 'Customer Created!',
        message: 'Customer Was Created Successfully'
      })
      props.updateParent('Saved', response.data)
    }
  }).catch(err => {
    setLoading(false)
    // console.log('Error saving client: ', err)
    props.notification({
      type: 'success',
      title: 'Error Creating Customer',
      message: `${err.message}`
    })
  })
}

return (
  <div className={classes.root}>
    <Dialog open={open} fullWidth={true} onClose={handleClose} maxWidth='lg' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Possible Duplicates</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please See If One Of These Is Your Client
          </DialogContentText>
          <TableContainer>
            <Table className={classes.table} aria-label="Duplicates">
              <TableHead>
                <TableRow>
                  <TableCell>Name (Status)</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>City</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.dupes.map((row, index) => (
                  <TableRow key={row.index}>
                    <TableCell scope="row">
                    {row.companyName ? row.companyname : `${row.firstName} ${row.lastName}`} - {row.displayName} - {row.searchName} - {row.active ? <Chip style={{background: `green` }} size="small" label="Active"/> : <Chip style={{background: `rgba(223,192,111, .3)` }} size="small" label="Inactive"/> }
                    </TableCell>
                    <TableCell >{row.email}</TableCell>
                    <TableCell >{row.phone}</TableCell>
                    <TableCell >{row.address}</TableCell>
                    <TableCell >{row.city}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <DialogContentText>
            If one of these matches your client, please use that Customer instead of creating a new one. If none of these are matches, please choose Create New.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSave} color="primary">
            Create New
          </Button>
          <Button color="secondary" disabled={null} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(DuplicatesModal))