import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Typography } from '@material-ui/core'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import { fade, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  sweetBorder: {
    border: '1px solid',
    borderRadius: '10px'
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  }
}));

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const MonthEndReport = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    depositData: [],
    paymentData: [],
    invoiceData: []
  })
  // const [startDate, setStartDate] = React.useState(new Date())
  // const [endDate, setEndDate] = React.useState(new Date())

  React.useEffect(() => {
  // console.log('Month End Effect: ', props)
    setState(props.data)
    // if (props && props.dates) {
      // setStartDate(props.dates.start._d)
      // setEndDate(props.dates.end._d)
    // }
  }, [props.data])

  function downloadFile(absoluteUrl) {
    var link = document.createElement('a');
    link.href = absoluteUrl;
    link.download = 'true';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // delete link;
 };

  const handleCSVExport = async () => {
    downloadFile(`${props.url}/api/reports/monthEndProductionToCSV`)
    // const response = await axios.get(`${props.url}/api/reports/accountBalanceToCSV`)
    // if (response && response.data) {
    // // console.log('Acct Balance EXCEL EXPORT', response.data)
    // }
  }

  return (
      <Card style={{margin: '5px'}}>
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            Month End Production:
          </Typography>
          <Button
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        startIcon={<ImportExportIcon />}
        onClick={handleCSVExport}
      >
        Export to Excel
      </Button>
      <br />
            Date Range: {moment(props.dates.start).format('MM/DD/YYYY')} - {moment(props.dates.end).format('MM/DD/YYYY')}
           
          <Grid container spacing={1} wrap={'nowrap'}>
            <Grid item lg={3} sm={6} style={{margin: '5px'}} className={classes.sweetBorder}>
              <Grid container spacing={1} style={{margin: '5px'}}>
                <Grid item lg={12} xs={12}>
                  Invoiced By Category
                </Grid>
                {state.invoiceData ? state.invoiceData.map((row, index) => (
                  <Grid item lg={12} key={index}>
                    {row.name} - {currencyFormat(row.total ? row.total : row.amount ? row.amount : 0)}
                  </Grid>
                )) : null}
              </Grid>
            </Grid>
            <Grid item lg={3} sm={6} style={{margin: '5px'}} className={classes.sweetBorder}>
              <Grid container spacing={1} style={{margin: '5px'}}>
                <Grid item lg={12} xs={12}>
                  Payments By Type
                </Grid>
                  <Grid item lg={12} >
                    Prepayments - {currencyFormat(state.prepayments)}
                  </Grid>

                 
                  <Grid item lg={12} >
                    Credits - {currencyFormat(state.credits)}
                  </Grid>

                 
                  <Grid item lg={12} >
                  Payments - {currencyFormat(state.payments)}
                  </Grid>
              </Grid>
            </Grid>
            <Grid item lg={3} sm={6} style={{margin: '5px'}} className={classes.sweetBorder}>
              <Grid container spacing={1} style={{margin: '5px'}}>
                <Grid item lg={12} xs={12}>
                  Deposit Info
                </Grid>
                <Grid item lg={12} >
                    Prepayments Created - {currencyFormat(state.newDeposits)}
                  </Grid>
                  <Grid item lg={12} >
                    Prepayment Balance - {currencyFormat(state.depositBalance)}
                  </Grid>
              </Grid>
            </Grid>
          </Grid>
          
        </CardContent>
      </Card>
  )
}

  const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(MonthEndReport)
