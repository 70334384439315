import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from '@material-ui/icons/Search'

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const ListContainer = styled.div`
display: flex;
flex-direction: column;
padding: 10px;
border: 5px solid #ccc;
border-radius: 10px;
overflow: hidden;
min-height: 550px;
background-color: #eee;
`;

const ListItemRow = styled.div`
  border: 4px solid green;
  display: flex;
  align-self: 'stretch';
  padding: 5px;
  margin: 5px;
  background-color: #fff;
  border-radius: 15px;
  flex-direction: row;
  align-items: center;
`;

const ListItem = styled.div`
  color: #555;
  font-size: 16px;
  display: flex;
  margin: 5px;
  min-width: 60px;
  align-self: ${props => (props.grow ? 'stretch' : 'center')};
  flex-grow: ${props => (props.grow ? 3 : 0)};
`;

const ListItemHeaderRow = styled.div`

display: flex;
align-self: 'stretch';
padding: 5px;
margin: 5px;
background-color: #fff;
border-radius: 15px;
flex-direction: row;
`;

const ListHeaderItem = styled.div`
color: #aaa;
font-size: 18px;
display: flex;
font-weight: 800;
align-self: 'stretch';
padding: 5px;
margin: 5px;
min-width: 60px;
align-self: ${props => (props.grow ? 'stretch' : 'center')};
flex-grow: ${props => (props.grow ? 3 : 0)};
`;

const DefaultLineItemContainer = styled(TableBody)`
background-color: ${props => (props.isDraggingOver ? '#83a893' : 'inherit')};
-webkit-user-select: none;
-ms-user-select: none;
user-select: none;
min-height: 100px;
`;

const DefaultLineItemRow = styled(TableRow)`
  border: ${props => ((props.isClockedIn || props.isDragging) ? '3px solid #444' : '2px solid lightgrey')};
  color: ${props => ((props.absenceType || props.isCheckedIn || props.isClockedIn)  ? '#ddd;' : '#444;')};
  border-radius: 5px;
  padding: 4px;
  width: 100%;
  margin: 2px;
  &:hover {
    background-color: #f5f5f5;
  };
  background-color: ${props => (
    props.isDragging ? '#34eb80' : 'white' )};
`;

const useStyles = makeStyles(theme => ({
  moveIcon: {
    '&:hover': {
      background: "#FFFF00",
      cursor: 'pointer',
      borderRadius: 20,
      color: 'red'
   }
  },
  table: {
    width: '100%',
    padding: 0,
    margin: 0
  },
  // tableheadRow: {
  //   padding: 10
  // },
  tableHeadCell: {
    fontSize: 14,
    color: '#bbb'
  },
  tableRow: {
    height: 25,
    padding: 5,
    margin: 5
  },
  tableCell: {
    height: 25,
    margin: 5,
    color: '#888',
    padding: 0
  }
}));

const EstimateListComponent = (props) => {
// console.log('ESTIMATE LIST: ', props)
  const classes = useStyles();
  const [state, setState] = React.useState({
    counter: 0,
    list: []
  })
  // const [list, setList] = React.useState(props.ourList)
  const [filteredList, setFilteredList] = React.useState(props.ourList)
  const [searchValue, setSearchValue] = React.useState('')
  // React.useEffect(() => {
  //   // console.log('Estimate changed USEEffect Called')
  //   if (props.ourList && props.ourList.length) {
  //     setList(props.ourList)
  //   }
  // } ,[props.estimate])

  React.useEffect(() => {
  // console.log('Estimate Line Item Quantity Changed....deletion or addition', props.ourList)
    // console.log('Current LIST:', list)
    if (props.ourList && props.ourList.length) {
      // setList(props.ourList)
      filterOut(props.ourList, searchValue)
      // setFilteredList(props.ourList)
      // setState({...state, list: props.ourList, filteredList: props.ourList })
    }
  } ,[props.ourList])

  const handleOpenItem = (item, index) => {
    props.handleOpenItem(item, index)
  }

  function arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    return arr
  }

  const handleDeleteItem = (d, i) => {
    // console.log('delete:', d)
    let message = `Are you sure you wish to delete this item? ${d.description}`
    if (window.confirm(message)) {
      // let updatedList = filteredList // props.ourList
      // // console.log('Get rid of ', updatedList[i])
      // updatedList.splice(i, 1)
      // console.log('Filtered List Deleted:', updatedList)
      let ourList = props.ourList
      // ourList.splice(i, 1)
      let index = ourList.findIndex(item => (item._id === d._id))
    // console.log('Index', index)
      if (index > -1) {
        ourList.splice(index, 1)
      // console.log('LIst after Deletion:', ourList)
        // setList(ourList)
      }
      props.updateList(ourList)
    }

  }

  function handleOnDragEnd(result) {
    // console.log('DRAG END:', result)
    if (!result.destination) return;
    if (props.estimate.accepted) {
      props.notification({
        type: 'warning',
        title: 'Cannot Modify Estimate',
        message: 'Estimate Accepted - No Modifications Allowed'
      })
      return
    }
    const items = Array.from(props.ourList);
    let [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    props.updateList(items)
  }

  
  const filterOut = (r, searchText) => {
    let string = searchText
    let newArr = []
    r.filter(o => {
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log('String', str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
  // console.log('Set The List after Search', newArr)
    setFilteredList(newArr)
    setState({...state, filteredList, newArr, counter: state.counter++ })
  }

  const handleChange = (event) => {
  // console.log('Searching: ', event.target.value)
    setSearchValue(event.target.value)
    if (event.target.value === '') {
      // setFilteredRows(estimates)
      setFilteredList(props.ourList)
    } else {
      filterOut(props.ourList, event.target.value)
    }
    // setPage(0)
  }

  return (
    <div>
      <TextField
          name="search" 
          value={ searchValue } 
          onChange={ handleChange }
          label="Search Line Items"
          type="search"
          variant="outlined"
          margin="normal"
          
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
      /> 
      Matches: { filteredList.length } / {props.ourList.length }
      <Table className={classes.table}>
      <TableHead className={classes.tableheadRow}>
        <TableRow>
          <TableCell className={classes.tableHeadCell} style={{ flexBasis: "5%" }}>
            Move
          </TableCell>
          <TableCell className={classes.tableHeadCell} style={{ flexBasis: "10%" }}>
            Quantiy
          </TableCell>
          <TableCell className={classes.tableHeadCell} style={{ flexBasis: "15%" }}>
            Unit
          </TableCell>
          <TableCell className={classes.tableHeadCell} style={{ flexBasis: "40%" }}>
            Description
          </TableCell>
          <TableCell className={classes.tableHeadCell} style={{ flexBasis: "15%" }}>
            Price
          </TableCell>
          <TableCell className={classes.tableHeadCell} style={{ flexBasis: "10%" }}>
            Delete
          </TableCell>
        </TableRow>
      </TableHead>
      
        <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="dropArea">
                {(provided, snapshot) => (
                  <DefaultLineItemContainer className="listItem" {...provided.droppableProps} ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                  >
                  {
                    filteredList.map((item, index) => {
                    // console.log('Item', item)
                      return (
                        <Draggable key={item._id} draggableId={item._id} index={index} style={{width: '100%'}}>
                          {(provided, snapshot) => (
                            <DefaultLineItemRow className={classes.tableRow} onDoubleClick={e => handleOpenItem(item, index)} key={index} 
                              ref={provided.innerRef} {...provided.draggableProps}
                                isDragging={snapshot.isDragging}>
                               <TableCell {...provided.dragHandleProps} style={{ flexBasis: "5%" }}>
                                <IconButton disabled>
                                  <DragIndicatorIcon />
                                </IconButton>
                              </TableCell>  
                              <TableCell className={ classes.tableCell }  style={{ flexBasis: "10%" }}>
                                { item.qty }
                              </TableCell>
                              <TableCell className={ classes.tableCell }  style={{ flexBasis: "10%" }}>
                                { (item.unit && item.unit.value) ? item.unit.value : item.unit }
                              </TableCell>
                              <TableCell  className={ classes.tableCell } style={{ flexBasis: "40%", marginLeft: 10 }}>
                                { item.description }
                               {
                                item.internalNotes ? (
                                  <>
                                    <br />
                                    <span style={{ padding: 5, fontSize: '0.8em', color: 'red', textAlign: 'center', backgroundColor: '#eee' }}>{ item.internalNotes }</span>
                                  </>
                                ) : null 
                               }
                              </TableCell>
                              <TableCell className={ classes.tableCell }  style={{ flexBasis: "15%" }}>
                                {currencyFormat(item.totalPrice)}
                              </TableCell>
                              <TableCell className={ classes.tableCell } style={{ flexBasis: "10%" }}>
                                <IconButton disabled={props.estimate.accepted} onClick={e => handleDeleteItem(item, index)} aria-label="delete">
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </DefaultLineItemRow>
                          )}
                        </Draggable>
                      )
                    })
                  }
                  {provided.placeholder}
                </DefaultLineItemContainer>
              )}
            </Droppable>
          </DragDropContext>
      </Table>
    </div>
    // <ListContainer>
    //   <ListItemHeaderRow>
    //   <ListHeaderItem>
    //       Reorder
    //     </ListHeaderItem>
    //   <ListHeaderItem>
    //       QUANTITY
    //     </ListHeaderItem>
    //     <ListHeaderItem>
    //       UNIT
    //     </ListHeaderItem>
    //     <ListHeaderItem grow={true}>
    //       DESCRIPTION
    //     </ListHeaderItem>
    //     <ListHeaderItem>
    //       PRICE
    //     </ListHeaderItem>
    //     <ListHeaderItem>
    //       DELETE
    //     </ListHeaderItem>
    //   </ListItemHeaderRow>
    //   {
    //     list.map((item, index) => {
          
    //     // console.log('ITEM 206: ', item)
    //       return (
          
    //             <ListItemRow onDoubleClick={e => handleOpenItem(item, index)} key={index}         >
    //               <ListItem>
    //                 <IconButton onClick={e => handleMoveItem('up', item, index)} style={{ backgroundColor: 'transparent' }} aria-label="move-up" disabled={index === 0}>
    //                   <ArrowUpwardIcon  className={classes.moveIcon}  />
    //                 </IconButton>
    //                 <IconButton style={{ backgroundColor: 'transparent' }} onClick={e => handleMoveItem('down', item, index)} aria-label="move-down" disabled={index === list.length - 1}>
    //                   <ArrowDownwardIcon className={classes.moveIcon}  />
    //                 </IconButton>
    //               </ListItem>
                 
                              
    //               <ListItem>
    //                 {item.qty}
    //               </ListItem>
    //               <ListItem>
    //                 {item.unit} 
    //               </ListItem>
    //               <ListItem grow={true}>
    //                 {item.description} 
    //                 { item.inventoryItem ? '*' : '' }
    //               </ListItem>
    //               <ListItem>
    //                 {item.totalPrice}
    //               </ListItem>
    //               <ListItem>
    //                 <IconButton onClick={e => handleDeleteItem(item, index)} aria-label="move-up">
    //                   <DeleteIcon />
    //                 </IconButton>
    //               </ListItem>
    //             </ListItemRow>

    //     )
    //   })}
    // </ListContainer>
    )
}

export default EstimateListComponent
