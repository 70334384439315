import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import styled from 'styled-components';
import CreateMilestoneModal from './CreateMilestoneModal'
import DeleteComponent from '../Common/DeleteComponent'
import PageviewIcon from '@material-ui/icons/Pageview';
import EditIcon from '@material-ui/icons/Edit';
import EditMilestoneModal from './EditMilestoneModal'
import Chip from '@material-ui/core/Chip';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { makeStyles } from '@material-ui/core/styles';

const MilestoneBoxContainer = styled.div`
  margin: 0.25rem;
  padding: 0.3rem;
  background-color: #ddd;
  min-height: 50rem;
  max-height: 100rem;
  width: '100%';
  border: 2px solid rgb(119,156,164);
  border-radius: 9px;
  overflow: hidden;
  overflow-y: scroll;
`

const DefaultLineItemContainer = styled.div`
  background-color: ${props => (props.isDraggingOver ? '#83a893' : 'inherit')};
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 100px;
`;

const MilestoneBox = styled.div`
  padding: 0.3rem;
  margin: 0.5rem;
  background-color: rgba(172,202,205, 0.5);
  border: 0.25rem solid rgb(119,156,164);
  border-radius: 10px;
  min-height: 2rem;
  max-height: 6rem;
  overflow: ellipsis;
  display: flex;
  flex-direction: column;
  align-self: stretch;
`
const MilestoneTopRow = styled.div`
  width: '100%';
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: ellipsis;
`
const MilestoneTitle = styled.div`
  font-size: 1.1rem;
  color: rgb(81, 110, 118);
  font-weight: 400;
`
const MilestoneStatus = styled.div`
  padding: 0.25rem;
  margin: 0.25rem;
  background-color: ${props => (props.ourColors ? props.ourColors.background : '#aaa')};
  color: ${props => (props.ourColors ? props.ourColors.text : '#eee')};
  border-radius: 10px;
`
const MilestoneDetailsArea = styled.div`
  width: '90%';
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 18rem;
  overflow: hidden;
`
const MilestoneDetails = styled.div`
  width: '90%';
  padding: 0.1rem;
  margin-bottom: 10px;
`
const MilestoneSubTasks = styled.div`
  width: '90%';
  padding: 0.1rem;
  background-color: rgba(122,23,89, 0.4);
  margin-bottom: 5px;
`
const MilestoneBottomRow = styled.div`
  margin-top: 5px;
  max-height: 53px;
  margin-bottom: 2px;
  width: '100%';
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`
const MilestoneAssignmentDetails = styled.div`
  width: '90%';
  padding: 0.5rem;
  position: relative;
  left: 10px;
  text-align: center;
`
const MilestoneActionsArea = styled.div`
  width: '100%';
  display: flex;
  align-self; flex-end;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.1rem;
  vertical-align: middle;
`
const MilestoneAction = styled.div`
  padding: 0.1rem;
  display: flex;
  margin-left: 1rem;
`
const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  color: rgb(81, 110, 118);
  font-size: 1.4rem;
`
const getColors = (status) => {
  let returnObject = {
    background: '#aaa',
    text: '#eee'
  }
  if (status === 'Past Due') {
    returnObject.text = '#eee'
    returnObject.background = '#c93434'
  }
  if (status === 'Delayed') {
    returnObject.text = '#eee'
    returnObject.background = 'rgba(199,185,36,0.5)'
  }
  if (status === 'In Progress') {
    returnObject.text = '#eee'
    returnObject.background = 'rgba(69,169,84,0.9)'
  }
  if (status === 'Project Pause') {
    returnObject.text = '#eee'
    returnObject.background = 'rgba(232,142,16,0.9)'
  }
  if (status === 'Complete') {
    returnObject.text = '#eee'
    returnObject.background = 'rgba(36,158,189,0.9)'
  }

  return returnObject
}

const SubTaskList = ({ list }) => {
// console.log('Subtask List:', list)
  if (list && list.length) {
  // console.log('We have a list')
    return (
      <>
        {
          list.map((item, index) => {
            
            return (
              <div key={index}>
                {item.title} - {item.status} - {item.details}
              </div>
            )
          })
        }
      </>
    )
  } else {
    return null
  }

}

const MilestoneItem = (props) => {
  // console.log('Milestone ITEM 193', props)
  const classes = useStyles()
  const milestone = props.milestone
  let ourColors = getColors(milestone.status)

  const handleClickOnViewDetails = (e) => {
    props.viewDetails(milestone)
  }

  const handleEdit = (e) => {
    props.editItem(milestone)
  }
  const handleDelete = () => {
    // console.log('Delete this one...', milestone)
    props.deleteMilestone(milestone)
  }

  const isComplete = milestone.status === 'Complete'
// console.log('The item??', props)
  return (
    <MilestoneBox onDoubleClick={handleEdit} >
      <MilestoneTopRow>
        <MilestoneTitle >
          <span className={isComplete ? classes.completedTitle : classes.title }>{milestone.name}</span>
        </MilestoneTitle>
        <MilestoneTitle >
          <CustomButton label="View" title="View" icon={<PageviewIcon />} handleOnclick={handleClickOnViewDetails} />
          {/* <CustomButton label="Edit" title="Edit" icon={<EditIcon />} handleOnclick={handleEdit} /> */}
          <DeleteComponent
              type="ProjectMilestone"
              unprotected={milestone?.user && milestone.user === props.user._id}
              item={milestone}
              title="Delete Milestone?"
              verificationText={`Are you sure you wish to Delete this milestone?`}
              confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
              confirmationButton="DELETE"
              denyText="NO"
              ifApproved={e => handleDelete()}
              deleteFunction={'projectMilestone'}
            />
        </MilestoneTitle>
      </MilestoneTopRow>
      <MilestoneBottomRow>
        <span>
          {milestone.assignedTo || ''}
        </span>
        <span>
       
        {milestone.status ? milestone.status : ''}
      </span>
      </MilestoneBottomRow>
    </MilestoneBox>
  )
}

const CustomButton = ({ label, title, icon, handleOnclick }) => {
  const classes= useStyles()
// console.log('Custom Button:', )
  return (
    <Tooltip
      title={title}
      classes={{ tooltip: classes.smallTooltip }}
      >
       <IconButton aria-label={label} onClick={handleOnclick} >
          {icon}
        </IconButton> 
      </Tooltip>
  )
}

// const milestones = [
//   {
//     title: 'Get Native Plant Salvage Quote',
//     details: 'Need to generate an estimate for Plant Salvage',
//     status: 'In Progress',
//     assignedTo: 'Jake Plocher'
//   },
//   {
//     title: 'Create Landscaping Estimate',
//     details: 'Need to generate an estimate for Landscaping',
//     status: 'Not Started',
//     assignedTo: 'Unassigned',
//     subTasks: [
//       {
//         title: 'Do Takeoffs',
//         details: 'Complete Takeoffs from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Complete Measurements',
//         details: 'Complete Measurements from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Fill out Quantities and Measurements',
//         details: 'Input Takeoffs/Measurements into Portal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Get Pricing for Plant Materials',
//         details: 'Generate RFP',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       }
//     ]
//   },
//   {
//     title: 'Create Landscaping Estimate',
//     details: 'Need to generate an estimate for Landscaping',
//     status: 'Not Started',
//     assignedTo: 'Unassigned',
//     subTasks: [
//       {
//         title: 'Do Takeoffs',
//         details: 'Complete Takeoffs from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Complete Measurements',
//         details: 'Complete Measurements from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Fill out Quantities and Measurements',
//         details: 'Input Takeoffs/Measurements into Portal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Get Pricing for Plant Materials',
//         details: 'Generate RFP',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Complete Measurements',
//         details: 'Complete Measurements from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Fill out Quantities and Measurements',
//         details: 'Input Takeoffs/Measurements into Portal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Get Pricing for Plant Materials',
//         details: 'Generate RFP',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       }
//     ]
//   },
//   {
//     title: 'Create Hardscape Estimate',
//     details: 'Need to generate an estimate for hardscaping',
//     status: 'Not Started',
//     assignedTo: 'Unassigned',
//     subTasks: [
//       {
//         title: 'Do Takeoffs',
//         details: 'Complete Takeoffs from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Complete Measurements',
//         details: 'Complete Measurements from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Fill out Quantities and Measurements',
//         details: 'Input Takeoffs/Measurements into Portal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Get Pricing for Plant Materials',
//         details: 'Generate RFP',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       }
//     ]
//   },
//   {
//     title: 'Submit Estimate to Builder',
//     details: `Project paused because builder has decided they want to build a 1,000ft water feature and Cavan has to design it
//      - but Cavan has been busy so he has not had any time to work on this. I would help him but I'm completely clueless and have no clue how to help`,
//     status: 'Project Pause',
//     assignedTo: 'Jerry DeLay',
//     subTasks: [
//       {
//         title: 'Get Pricing from Jerry',
//         details: 'Sent Email for Estimate',
//         status: 'In Progress',
//         assignedTo: 'Jerry'
//       },
//       {
//         title: 'Complete Markup',
//         details: 'Complete 15% Markup - add to our Proposal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       }
//     ]
//   },
//   {
//     title: `Sniff Kevin's Balls`,
//     details: `Someone needs to `,
//     status: 'Complete',
//     assignedTo: 'Jerry DeLay',
//     subTasks: [
//       {
//         title: 'Get Pricing from Jerry',
//         details: 'Sent Email for Estimate',
//         status: 'In Progress',
//         assignedTo: 'Jerry'
//       },
//       {
//         title: 'Complete Markup',
//         details: 'Complete 15% Markup - add to our Proposal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       }
//     ]
//   },
//   {
//     title: 'Get Native Plant Salvage Quote',
//     details: 'Need to generate an estimate for Plant Salvage',
//     status: 'In Progress',
//     assignedTo: 'Jerry DeLay',
//     subTasks: [
//       {
//         title: 'Get Pricing from Jerry',
//         details: 'Sent Email for Estimate',
//         status: 'In Progress',
//         assignedTo: 'Jerry'
//       },
//       {
//         title: 'Complete Markup',
//         details: 'Complete 15% Markup - add to our Proposal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       }
//     ]
//   },
//   {
//     title: 'Create Landscaping Estimate',
//     details: 'Need to generate an estimate for Landscaping',
//     status: 'Not Started',
//     assignedTo: 'Unassigned',
//     subTasks: [
//       {
//         title: 'Do Takeoffs',
//         details: 'Complete Takeoffs from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Complete Measurements',
//         details: 'Complete Measurements from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Fill out Quantities and Measurements',
//         details: 'Input Takeoffs/Measurements into Portal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Get Pricing for Plant Materials',
//         details: 'Generate RFP',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       }
//     ]
//   },
//   {
//     title: 'Create Landscaping Estimate',
//     details: 'Need to generate an estimate for Landscaping',
//     status: 'Not Started',
//     assignedTo: 'Unassigned',
//     subTasks: [
//       {
//         title: 'Do Takeoffs',
//         details: 'Complete Takeoffs from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Do Takeoffs',
//         details: 'Complete Takeoffs from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Complete Measurements',
//         details: 'Complete Measurements from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Fill out Quantities and Measurements',
//         details: 'Input Takeoffs/Measurements into Portal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Get Pricing for Plant Materials',
//         details: 'Generate RFP',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Complete Measurements',
//         details: 'Complete Measurements from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Fill out Quantities and Measurements',
//         details: 'Input Takeoffs/Measurements into Portal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Get Pricing for Plant Materials',
//         details: 'Generate RFP',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Complete Measurements',
//         details: 'Complete Measurements from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Fill out Quantities and Measurements',
//         details: 'Input Takeoffs/Measurements into Portal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Get Pricing for Plant Materials',
//         details: 'Generate RFP',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Create Hardscape Estimate',
//         details: 'Need to generate an estimate for hardscaping',
//         status: 'Not Started',
//         assignedTo: 'Unassigned',
//         subTasks: [
//           {
//             title: 'Do Takeoffs',
//             details: 'Complete Takeoffs from Plans',
//             status: 'Not Started',
//             assignedTo: 'Unassigned'
//           },
//           {
//             title: 'Complete Measurements',
//             details: 'Complete Measurements from Plans',
//             status: 'Not Started',
//             assignedTo: 'Unassigned'
//           },
//           {
//             title: 'Fill out Quantities and Measurements',
//             details: 'Input Takeoffs/Measurements into Portal',
//             status: 'Not Started',
//             assignedTo: 'Unassigned'
//           },
//           {
//             title: 'Get Pricing for Plant Materials',
//             details: 'Generate RFP',
//             status: 'Not Started',
//             assignedTo: 'Unassigned'
//           }
//         ]
//       },
//       {
//         title: 'Submit Estimate to Builder',
//         details: `Project paused because builder has decided they want to build a 1,000ft water feature and Cavan has to design it
//          - but Cavan has been busy so he has not had any time to work on this. I would help him but I'm completely clueless and have no clue how to help`,
//         status: 'Project Pause',
//         assignedTo: 'Jerry DeLay',
//         subTasks: [
//           {
//             title: 'Get Pricing from Jerry',
//             details: 'Sent Email for Estimate',
//             status: 'In Progress',
//             assignedTo: 'Jerry'
//           },
//           {
//             title: 'Complete Markup',
//             details: 'Complete 15% Markup - add to our Proposal',
//             status: 'Not Started',
//             assignedTo: 'Unassigned'
//           }
//         ]
//       },
//       {
//         title: `Sniff Kevin's Balls`,
//         details: `Someone needs to `,
//         status: 'Complete',
//         assignedTo: 'Jerry DeLay',
//         subTasks: [
//           {
//             title: 'Get Pricing from Jerry',
//             details: 'Sent Email for Estimate',
//             status: 'In Progress',
//             assignedTo: 'Jerry'
//           },
//           {
//             title: 'Complete Markup',
//             details: 'Complete 15% Markup - add to our Proposal',
//             status: 'Not Started',
//             assignedTo: 'Unassigned'
//           }
//         ]
//       }
//     ]
//   },
//   {
//     title: 'Create Hardscape Estimate',
//     details: 'Need to generate an estimate for hardscaping',
//     status: 'Not Started',
//     assignedTo: 'Unassigned',
//     subTasks: [
//       {
//         title: 'Do Takeoffs',
//         details: 'Complete Takeoffs from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Complete Measurements',
//         details: 'Complete Measurements from Plans',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Fill out Quantities and Measurements',
//         details: 'Input Takeoffs/Measurements into Portal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       },
//       {
//         title: 'Get Pricing for Plant Materials',
//         details: 'Generate RFP',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       }
//     ]
//   },
//   {
//     title: 'Submit Estimate to Builder',
//     details: `Project paused because builder has decided they want to build a 1,000ft water feature and Cavan has to design it
//      - but Cavan has been busy so he has not had any time to work on this. I would help him but I'm completely clueless and have no clue how to help`,
//     status: 'Project Pause',
//     assignedTo: 'Jerry DeLay',
//     subTasks: [
//       {
//         title: 'Get Pricing from Jerry',
//         details: 'Sent Email for Estimate',
//         status: 'In Progress',
//         assignedTo: 'Jerry'
//       },
//       {
//         title: 'Complete Markup',
//         details: 'Complete 15% Markup - add to our Proposal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       }
//     ]
//   },
//   {
//     title: `Sniff Kevin's Balls`,
//     details: `Someone needs to `,
//     status: 'Complete',
//     assignedTo: 'Jerry DeLay',
//     subTasks: [
//       {
//         title: 'Get Pricing from Jerry',
//         details: 'Sent Email for Estimate',
//         status: 'In Progress',
//         assignedTo: 'Jerry'
//       },
//       {
//         title: 'Complete Markup',
//         details: 'Complete 15% Markup - add to our Proposal',
//         status: 'Not Started',
//         assignedTo: 'Unassigned'
//       }
//     ]
//   }
// ]

const MilestoneList = (props) => {
  // const milestones = props.milestones
  // console.log("Milestone List", props)
  // let sortedMilestones = [...props.milestones].sort((a, b) => {
  //   if (a.status === 'Complete' && b.status !== 'Complete') return 1;
  //   if (a.status !== 'Complete' && b.status === 'Complete') return -1;
  //   return 0;
  // });

  function handleOnDragEnd(result) {
    if (!result.destination) return;
  
    const items = Array.from(props.milestones);
    let [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
  
    // After reordering, sort the milestones to ensure completed items are at the bottom.
    let sortedMilestones = items.sort((a, b) => {
      if (a.status === 'Complete' && b.status !== 'Complete') return 1;
      if (a.status !== 'Complete' && b.status === 'Complete') return -1;
      return a.index || 0 - b.index || 0
    });
  
    props.updateList(sortedMilestones);
  }

  // function handleOnDragEnd(result) {
  //   // console.log('DRAG END:', result)
  //   if (!result.destination) return;

  //   const items = Array.from(props.milestones);
  //   let [reorderedItem] = items.splice(result.source.index, 1);
  //   items.splice(result.destination.index, 0, reorderedItem);
  //   // console.log('Reorder:', items)
  //   props.updateList(items)
  // }

  let sortedMilestones = props.milestones.sort((a, b) => {
    if (a.status === 'Complete' && b.status !== 'Complete') return 1;
    if (a.status !== 'Complete' && b.status === 'Complete') return -1;
    return a.index || 0 - b.index || 0
  });

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="dropArea">
        {(provided, snapshot) => (
          <DefaultLineItemContainer className="listItem" {...provided.droppableProps} ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            
          >
            {
            sortedMilestones.map((item, index) => {
              return (
                <Draggable key={item._id} draggableId={item._id} index={index} style={{width: '100%'}}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      isDragging={snapshot.isDragging}
                      >
                          <MilestoneItem key={index} employees={props.employees} user={props.user} milestone={item} viewDetails={props.viewMilestoneDetails} editItem={props.editMilestone} deleteMilestone={props.deleteMilestone} 
                      
                      />

                    </div>
                    
              )
            }
            </Draggable>
          )
          })}
          {provided.placeholder}
          </DefaultLineItemContainer>
        )}
      </Droppable>
    </DragDropContext>
  )
}

const ProjectMilestonesList = (props) => {
  console.log('Milestones ', props)
  const [isNewMilestoneModalOpen, setNewMilestoneModalOpen] = React.useState(false)
  const [isEditMilestoneModalOpen, setEditMilestoneModalOpen] = React.useState(false)
  const [activeMilestone, setActiveMilestone] = React.useState({})
  const handleOpenNewMilestoneModal = () => {
    setNewMilestoneModalOpen(true)
  }

  const addNewMilestone = (newMilestone) => {
    console.log('Add milestone', newMilestone)
    setNewMilestoneModalOpen(false)
  }

  const closeNewMilestoneModal = (data) => {
  // console.log('Close mIlestone modal', data)
    setNewMilestoneModalOpen(false)
  }

  const handleDeleteMilestone = (itemToDelete) => {
    console.log('Delete this:', itemToDelete)
    props.deleteMilestoneFromProject(itemToDelete)
  }

  const handleEditeMilestone = (itemToEdit) => {
    console.log('782', itemToEdit)
    setActiveMilestone(itemToEdit)
    setEditMilestoneModalOpen(true)
  }
  const updateMilestone = (editedMilestone) => {
    console.log('Save the changes', editedMilestone)
    setActiveMilestone(null)
    setEditMilestoneModalOpen(false)
    props.updateMilestone(editedMilestone)
  }

  const closeEditMilestoneModal = () => {
    setActiveMilestone(null)
    setEditMilestoneModalOpen(false)
  }

  const handleUpdateList = (list) => {
    console.log('Reorder these fuckers:', list)
    props.reorderMilestones(list)
  }

  return (
    <div>
      <CreateMilestoneModal {...props} open={isNewMilestoneModalOpen} updateParent={addNewMilestone} closeModal={closeNewMilestoneModal} />
      <EditMilestoneModal {...props} milestone={activeMilestone} open={isEditMilestoneModalOpen} updateParent={updateMilestone} closeModal={closeEditMilestoneModal} />
      <MilestoneBoxContainer>
        <TopRow>
          <Title>Project Milestones</Title>
          <Button
            onClick={handleOpenNewMilestoneModal}
            variant="contained"
            color="primary"
          >Add Milestone</Button>
        </TopRow>
        <MilestoneList {...props} deleteMilestone={handleDeleteMilestone} editMilestone={handleEditeMilestone} updateList={handleUpdateList} />
      </MilestoneBoxContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  // console.log('JOB LIST MAP STATE TO PROPS: ', state)
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    user: state.user,
    employees: state.employees,
    url: state.url
  }
}

export default connect(mapStateToProps)(ProjectMilestonesList)

const useStyles = makeStyles(theme => ({
  smallTooltip: {
    fontSize: '9px',
  },
  completedTitle: {
    textDecoration: 'line-through',
    textDecorationColor: 'red'
  },
  title: {
    // color: 'blue'
  }
}))