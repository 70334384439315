import React, { Component } from 'react'
import { fade, makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import moment from 'moment'
import axios from 'axios'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '5px'
  },
  itemContainer: {
    border: '3px solid #769ba2',
    borderRadius: 8,
    backgroundColor: '#fff',
    margin: 10,
    padding: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly'
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    backgroundColor: '#ACCACD',
    padding: 5,
    margin: 5,
    height: '100%',
    borderRadius: 5
  },
  crewName: {
    display: 'flex',
    alignSelf: 'center',
    fontSize: '1.2em',
    color: '#aaa',
    fontWeight: 650
  },
  activityDetailContainer: {
    border: '2px solid #769ba2',
    borderRadius: 8,
    padding: '10px',
    margin: '5px',
    width: '100%'
  },
  activityDetails: {
    fontSize: '0.98em',
  },
  activityTimestamp: {
    position: 'relative',
    bottom: 5,
    right: 5,
    textAlign: 'right',
    fontSize: '0.8em',
    fontWeight: 100,
    fontColor: '#ccc'
  }
}))

const CrewDailyLogLastTen = (props) => {
  const classes = useStyles();
  const [list, setList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);
  const observer = React.useRef();

  React.useEffect(() => {
    let isMounted = true;
    async function fetchLogs(crewId, page) {
      setLoading(true);
      const response = await axios.get(`${props.url}/api/crews/crewDailyLog?crew=${crewId}&limit=10&page=${page}`);
      setLoading(false);
      if (response && response.data && response.data.length > 0) {
        if (isMounted) setList((prevList) => [...prevList, ...response.data]);
      } else {
        if (isMounted) setHasMore(false); // No more data to fetch
      }
    }
    if (props.crew && props.crew._id && hasMore && isMounted) {
      fetchLogs(props.crew._id, page);
    }
    return () => {
      isMounted = false;
    }
  }, [props.crew, page, hasMore]);

  const lastLogElementRef = React.useCallback(node => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPage(prevPage => prevPage + 1);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, hasMore]);

  return (
    <div className={classes.root}>
      <ListOfActivity list={list} lastLogElementRef={lastLogElementRef} />
      {!hasMore && <div>No more logs to load.</div>}
    </div>
  )
}

const ListOfActivity = ({ list, lastLogElementRef  }) => {
  // console.log('List;', list)
  return (
    <div>
     {
        list.map((item, index) => {
          if (list.length === index + 1) {
            return <ActivityDetails ref={lastLogElementRef} item={item} key={item._id} />
          } else {
            return <ActivityDetails item={item} key={item._id} />
          }
        })
      }
    </div>
  )
}

const ActivityDetails = React.forwardRef(({ item }, ref) => {
  const classes = useStyles();
  let created_at = moment(item.created_at).format('M/D h:mma');
  return (
    <div ref={ref} className={classes.activityDetailContainer}>
      <div className={classes.activityDetails}>
        {item.activity}
      </div>
      <div className={classes.activityTimestamp}>
        {created_at}
      </div>
    </div>
  )
});

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(CrewDailyLogLastTen)
