import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Table, Chip, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@material-ui/core';
const hmiColor = 'rgb(2 164 9)'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '99vh',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default
  },
  paper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  tableContainer: {
    // flexGrow: 1,
    overflow: 'auto',
    padding: 10
  },
  loadingContaineper: {
    flexGrow: 1, // Makes the Paper component fill the available space in the flex container
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    justifyContent: 'center',
    alignItems: 'center'    
  },
  row: {
    height: '75px',
    padding: 5,
    backgroundColor: '#ddd'
  },
  rowAbsent: {
    height: '75px',    
    backgroundColor: 'hsla(209, 93%, 34%, 0.85)',
    padding: 5
  },
  rowCheckedIn: {
    height: '75px',    
    backgroundColor: '#c4c428',
    padding: 5
  },
  rowClockedIn: {
    height: '75px',
    backgroundColor: 'hsla(21, 93%, 44%, 0.9)',

    padding: 5
  },
  rowTextItem: {
    color: '#fff',
    fontWeight: '600',
    fontSize: '26px'
  },
  rowTextItemDark: {
    color: '#333',
    fontWeight: '700',
    fontSize: '22px'
  },
  table: {
    '& thead th, & tbody td, & tfoot td': {
      border: 'none !important',  // Increase specificity and enforce style with !important
    },
    '& *': {
      borderColor: 'transparent !important'  // Force transparent borders for all child elements
    }
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'stretch'
  }
}));

function ViewHMITVRosterData(props) {
  const classes = useStyles()
  const [state, setState] = React.useState({
    loading: false,
    data: [],
    lastUpdated: ''
  })

  useEffect(() => {
    let isMounted = true; // Flag to check if the component is mounted
    const fetchInterval = 150000; // in milliseconds

    // Function to fetch data
    const fetchLatestData = async () => {
      if (!isMounted) return; // If the component is not mounted, do not proceed (avoid memory leaks)
      setState(prevState => ({ ...prevState, loading: true }));

      try {
        const url = `${props.url}/api/roster/hmiTVData`; // URL from props
        const response = await axios.get(url);
        if (isMounted && response.data) {
          console.log('Got some data!!', response.data);
          const lastUpdated = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute:'2-digit' })
          setState({ data: response.data, loading: false, lastUpdated });
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        if (isMounted) {
          setState(prevState => ({ ...prevState, loading: false }));
        }
      }
    };

    fetchLatestData(); // Initial fetch

    const interval = setInterval(fetchLatestData, fetchInterval); // Set interval for repeated fetches

    // Cleanup function to clear the interval and update the isMounted flag
    return () => {
      isMounted = false;
      clearInterval(interval);
    };
  }, [props.url]); // Dependencies array includes props.url to handle changes in the URL, though this is usually static


  return (
    <div className={classes.root}>
    <Paper className={classes.paper}>
      {
        state.loading ? <div className={classes.loadingContainer} ><CircularProgress size={28} /></div> : <ResponsiveTable data={state.data} />
      }
      <div className={classes.footerContainer}>
        Last Updated {state.lastUpdated}
      </div>
    </Paper>
  </div>
  );
}

const ResponsiveTable = ({ data }) => {
  const classes = useStyles();
  const [groupIndex, setGroupIndex] = useState(0);
  console.log('DATa', data)
  // Define the group size and calculate the groups
  const groupSize = 10
  const dataGroups = useMemo(() => {
    let groups = [];
    for (let i = 0; i < data.length; i += groupSize) {
      groups.push(data.slice(i, i + groupSize));
    }
    return groups;
  }, [data, groupSize]);

  // Change to the next group every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setGroupIndex(prevIndex => (prevIndex + 1) % dataGroups.length); // cycles through groups
    }, 10000); // 10000 ms = 10 seconds

    return () => clearInterval(interval);
  }, [dataGroups.length]);
  if (dataGroups && dataGroups.length) {

    return (
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} stickyHeader size="small">
          {/* <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right">Crew</TableCell>
            </TableRow>
          </TableHead> */}
          <TableBody>
            {dataGroups[groupIndex].map((row, index) => {
              const classByStatus = row.Status === 'Clocked-In' ? 'rowClockedIn' : row.Status === 'Checked-In' ? 'rowCheckedIn' : row.Status === 'Absent' ? 'rowAbsent' : 'row'
              const textStyle = (classByStatus === 'row') ? 'rowTextItemDark' : 'rowTextItem'
            return (
              <TableRow key={index} className={classes[classByStatus]}>
                <TableCell component="td" scope="row" className={classes[textStyle]}>{row.Name}</TableCell>
                <TableCell component="td" align="center" className={classes[textStyle]}>{row.Status}</TableCell>
                <TableCell component="td" align="right" className={classes[textStyle]}>{row.Crew}</TableCell>
              </TableRow>
            )})}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <div className={classes.loadingContainer} ><CircularProgress size={28} /></div>
    )
  }
};
// props.isDragging ? '#34eb80': (
//   props.absenceType ? (
//     props.absenceType === 'off' ? 'rgba(149,49,188,1)' : props.absenceType === 'nocall' ? 'rgba(90,46,8,1)' : props.absenceType === 'approved' ? 'rgba(73,128,36,1)' : 'blue'
//   ) : props.isCheckedIn ? (props.isClockedIn ? '#a63e06' : '#c4c428') : 'inherit'))};

const Status = ({ status }) => {
  if (status === 'Absent') {
    return <Chip style={{color: '#fff', background: 'rgba(73,128,36,1)' }} size="small" label="Off" />
  } else if (status === 'Clocked-In') {
    return <Chip style={{color: '#fff', background: '#a63e06' }} size="small" label="Clocked-In" />
  } else if (status === 'Checked-In') {
    return <Chip style={{color: '#fff', background: '#c4c428' }} size="small" label="Checked-In" />
  } else {
    return ''
  }
}
const mapStateToProps = (state) => {
  return {
    url: state.url
  }
}

export default connect(mapStateToProps)(ViewHMITVRosterData)

