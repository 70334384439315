import React from 'react';
import {
  Grid,
  TextField,
  Slider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  InputAdornment
} from '@material-ui/core';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';

const handleFocus = (event) => event.target.select()
// Generate confidence options from 10% to 100%
const confidenceOptions = Array.from({ length: 10 }, (_, i) => (i + 1) * 10);

const ForecastInputs = ({ state, setState }) => {
  const handleDateChange = (date) => {
    setState(prev => ({
      ...prev,
      expectedCloseDate: date
    }));
  };

  const handleConfidenceChange = (event) => {
    setState(prev => ({
      ...prev,
      confidence: event.target.value
    }));
  };

  const handleDurationChange = (event) => {
    setState(prev => ({
      ...prev,
      duration: event.target.value
    }));
  };

  return (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <Grid container spacing={2}>
        {/* Expected Close Date */}
        <Grid item lg={4} md={4} sm={12} xs={12}>
            <KeyboardDatePicker
              fullWidth
              variant="inline"
              inputVariant="outlined"
              format="MM/dd/yyyy"
              id="expected-close-date"
              label="Expected Close Date"
              value={state.expectedCloseDate}
              onFocus={handleFocus}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
        </Grid>

        {/* Confidence Dropdown */}
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Confidence</InputLabel>
            <Select
              value={state.confidence}
              onChange={handleConfidenceChange}
              label="Confidence"
            >
              {confidenceOptions.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}%
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Duration */}
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            label="Duration"
            type="number"
            onFocus={handleFocus}
            value={state.duration}
            onChange={handleDurationChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">months</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ForecastInputs;