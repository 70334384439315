import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import PlusIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ServiceConfiguration from './services/ServiceConfiguration'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import VisibilityIcon from '@material-ui/icons/Visibility';

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

// const useStyles = makeStyles(theme => ({
const styles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      margin: 10,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  topButtons: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center',
      margin: 5,
    },
    [theme.breakpoints.down('md')]: {
      margin: 5,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  helperText: {
    fontSize: '1.5em',
    color: '#bbb'
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    
  },
  list: {
    width: '100%',
    height: 600,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  containerArea: {
    // backgroundColor: 'red',
    // border: '3px solid blue'
  },
  categoryHeader: {
    fontSize: '1.3em',
    fontWeight: 800
  },
  pageHeader: {
    fontSize: '1.4em',
    fontWeight: 600,
    color: '#ccc'
  },
  tabsroot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 550,
  },
  tabsroot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 550,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: 550
  },
  tabDetailContainer: {
    // width: 270,
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'stretch'
  },
  tabDetails: {
    // display: 'flex',
    // alignSelf: 'stretch',
    // flexGrow: 1
  }
  })
);

const ConfigurePDFOptions = (props) => {
// console.log('ConfigurePDFOptions ', props)
  const classes = styles()
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState({
    counter: 0,
    prettyVersion: false
  })
  const [estimate, setEstimate] = React.useState({})
  const [pdf, setPDF] = React.useState(null)


  const handleSubmit = (showInternalDetails) => {
    let estimateId = props.estimate._id
    const obj = {
      estimate: props.estimate,
      estimateId: estimateId,
      prettyPDF: state.prettyVersion,
      internal: 0
    }
    console.log('obj to send', obj)
    let internalStyle = 0

    let url = `${props.url}/api/proposal/handleGeneratePDF?estimateId=${estimateId}&internal=${internalStyle}`
    let method = 'get'
    if (state.prettyVersion) {
      method = 'post'
      url = `${props.url}/api/proposal/createPDFNewStyleProposal`
    }
    if (showInternalDetails && showInternalDetails > 0) {
      internalStyle = 1
      url = `${props.url}/api/proposal/handleGeneratePDF?estimateId=${estimateId}&internal=${internalStyle}`
      method = 'get'
    }
    console.log('url:', url)
    axios({
      method: method,
      url:  url,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log(response.data)
      setPDF(response.data)
    })
  }
  const handleChangePDFType = () => {
    setState({...state, prettyVersion: !state.prettyVersion })
  }
  return (
    <Paper style={{  margin: 10, padding: 10, height: '100%', overflowY: 'auto' }}>
      <Grid container spacing={2}>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            <h1>Configure PDF Options</h1>
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12}>
            PDF Output: <FormControlLabel
                        control={
                          <Switch
                            checked={state.prettyVersion}
                            onChange={handleChangePDFType}
                            name="PDF Type"
                            color="primary"
                          />
                        }
                        label={state.prettyVersion ? 'Pretty PDF' : 'Simple PDF'}
                      />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
          <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={handleSubmit}
              aria-label="Submit"
              // disabled={!right.length}
            >
              Generate PDF Preview
            </Button></Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}  >
            {
             pdf ?
                  <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<VisibilityIcon />}
                
                  onClick={e => handleSubmit(1)}
                >Display Internal Style</Button>
                : null
            }
            </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12} style={{ overflowY: 'auto', maxHeight: 800, textAlign: 'center', width: '50%' }}>
           
             PDF Will Populate Below
           
          </Grid>
          <Grid item lg={12}>
          {
             pdf ? <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="860" /> : null
           }
          </Grid>
     
      </Grid>
    </Paper>
  )
}

const mapStateToProps = (state) => {
// console.log('MAP PROPS: ,', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    services: state.services
  }
}

export default React.memo(connect(mapStateToProps)(ConfigurePDFOptions))