import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'

// function createData(name, status, created, lastactivity, tourbooked) {
//   return { name, status, created, lastactivity, tourbooked };
// }
function currencyFormat (num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
// const rows = [
//   createData('Tom Smith', 'New', '12/17/2019', null, null),
//   createData('Donut', 'Working', '12/18/2019', 51, 4.9),
//   createData('Eclair', 'New', '11/29/2019', 24, 6.0),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Honeycomb', 408, 3.2, 87, 6.5),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Jelly Bean', 375, 0.0, 94, 0.0),
//   createData('KitKat', 518, 26.0, 65, 7.0),
//   createData('Lollipop', 392, 0.2, 98, 0.0),
//   createData('Marshmallow', 318, 0, 81, 2.0),
//   createData('Nougat', 360, 19.0, 9, 37.0),
//   createData('Oreo', 437, 18.0, 63, 4.0),
// ];

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
  // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'displayName', numeric: false, disablePadding: true, label: 'Client Name' },
  { id: 'jobName', numeric: false, disablePadding: false, label: 'Job Name' },
  { id: 'invoiceNumber', numeric: false, disablePadding: false, label: 'Invoice Number' },
  { id: 'dueDate', numeric: false, disablePadding: false, label: 'Due Date' },
  { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
  { id: 'balance', numeric: false, disablePadding: false, label: 'Balance' }
];

function EnhancedTableHead(props) {
// console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
  // console.log('Sort Property: ', property)
  // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: 10
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  rowPad: {
    height: '40px !important',
    // padding: 'px'
  }
}));

function EnhancedTable(props) {
// console.log('Service List Props: ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [open, setOpen] = React.useState(false)
  const [snoutWeevilModalOpen, setSnoutWeevilModalOpen] = React.useState(false)
  const [employee, setEmployee] = React.useState({});
  // const [svcModalOpen, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [showAllEmployees, setShowAllEmployees] = React.useState(false)
  const [rows, setRows] = React.useState([])
  const [allEmployees, setAllEmployees] = React.useState([])
  const [filteredRows, setFilteredRows] = React.useState([])
  const [crews, setCrews] = React.useState([])
  const [state, setState] = React.useState({
    searchMatches: 0,
    openSnoutWeevil: false
  })
  const [showNewEmployeeModal, setShowNewEmployeeModal] = React.useState(false)

  async function fetchData(entityToUse) {
    // console.log('Fetch our Non paid invoices')
    setRows([])
    setFilteredRows([])
    setLoading(true)
    const response = await axios.get(`${props.url}/api/invoice/nonPaidInvoiceList?entity=${entityToUse}`)
    // console.log(response)
    setLoading(false)
    if (response.data && response.data.length) {
    // // console.log('Got Employees!!')
    //   if (toReturn) {
    //     return response.data
    //   } else {
    //     setLoading(false)
    //     setAllEmployees(response.data.employees)
    //     if (showAllEmployees) {
      setRows(response.data)
      setFilteredRows(response.data)
    }
    //       setCrews(response.data.crews)
    //     } else {
    //       let activeClients = response.data.employees.filter(item => { return item.activeEmployee })
    //     // console.log('Got %d activeClients: ', activeClients.length)
    //       setFilteredRows(activeClients)
    //       setRows(activeClients)
    //       setCrews(response.data.crews)
    //     }
    //   }
    // } else {
    // // console.log('Fucked up')
    // }
  }

  React.useEffect(() => {
    // console.log('Use effectset Employee list: ', props.clients)
    fetchData('All')
  }, [])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
  // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // const handleClick = (event, name) => {
  //   const selectedIndex = selected.indexOf(name);
  // // console.log('handle click ', name)
  // // console.log('Index: ', selectedIndex)
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const storeSearchMatches = (d) => {
  // console.log('Search matches: ', d)
    // setState({...state, searchMatches: d.length})
  }
  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r, searchText) => {
  // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    // console.log('Lenght: ', newArr.length)
    // console.log(newArr)
    // storeSearchMatches(newArr)
    // setsearchMatches(newArr.length)
    // return newArr
    setFilteredRows(newArr)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  const handleChange = (name) => (event) => {
  // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  const openSvcModal = (svc) => {
  // console.log('Open Employee modal: ', svc)
    setEmployee(svc)
    setOpen(true)
  }
  
// const updateFromSvcModal = (t, d) => {
// // console.log('Update from service modal: ', d)
//   if (t === 'closeModal') setOpen(false)
//   if (t === 'update') {
//     let services = rows
//     // console.log('Got %d clients: ', clients.length)
//     let index = services.findIndex(service =>  (service._id === d._id))
//     if (index > -1) {
//     // console.log('Svc already exists', index)
//     // console.log(services[index])
//       services[index] = d
//     // console.log('Agai: ', services[index])
//       // console.log(services)
//       setRows(services)
//       setFilteredRows(services)
//     } else {
//       services.push(d)
//       setRows(services)
//       setFilteredRows(services)
//     }
//     setOpen(false)
//   }
// }

const updateFromEmployeeModal = (d) => {
// console.log(d)
  if (d === 'closeModal') {
    fetchData()
    setOpen(false)
  }
}

const setShowAllOrNot = () => {
// console.log('To Shor all or not', state)
  setsearchValue('')
  if (showAllEmployees) {
  // console.log('Gonna just show the active now')
    setShowAllEmployees(false)
    let activeClients = allEmployees.filter(item => { return item.activeEmployee })
    // console.log('Got %d activeClients: ', activeClients.length)
    setFilteredRows(activeClients)
    setRows(activeClients)
  } else {
  // console.log('Show everyone')
    setShowAllEmployees(true)
    setFilteredRows(allEmployees)
    setRows(allEmployees)
  }
  setPage(0)
}

const handleAddEmployee = () => {
// console.log('Add employee')
  setShowNewEmployeeModal(true)
}

const updateParent = (d) => {
  if (d.employee) {
  // console.log('Open this mother fucker!!', d)
    setEmployee(d.employee)
    setOpen(true)
    setShowNewEmployeeModal(false)
  }
}

const AbsentChip = ({ type }) => {
  if (type === 'off') {
    return  <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label="Off" />
  }
  if (type === 'approved') {
    return  <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label="PTO" />
  }
  if (type === 'vacation') {
    return  <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label="Vacation" />
  }
  if (type === 'nocall') {
    return  <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label="No Call/No Show" />
  }
  return ''
}

const EmployeeStatus = ({ employee }) => {
  return (
  <span style={{'marginLeft': '20px'}}>{
    employee.isClockedIn ? (
      <Chip style={{color: '#fff', background: '#a2d67a' }} size="small" label="Clocked-In" />
    ) : employee.isCheckedIn ? (
      <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label="Checked-In" />
    ) : employee.absent ? (
      <AbsentChip type={employee.absent} />
    ) : ''
  }</span>
  )
}

const handleUpdateVisibleEntity = (newEntity) => {
// console.log('Entity Selected', newEntity)
  if (newEntity && newEntity._id) {
    // setEntity(newEntity)
    fetchData(newEntity._id)
  } else {
    // setEntity({})
    fetchData('All')
  }
}
  return (
    <div className={classes.root}>
      <h1>Un-Paid Invoices</h1>
     <Paper className={classes.paper}>
      {
          (props.user && props.user.entities && props.user.entities.length > 1) ? (
            <Grid container style={{padding: '10px'}}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ChooseEntityComponent allowAll={true} title={'Visible Entities'} disabled={loading} user={props.user} updateParent={handleUpdateVisibleEntity} />
            </Grid>
          </Grid>
          ) : ''
        }
        <Grid container style={{padding: '10px'}}>
          <Grid item lg={3} xs={6}>
          Total Non-Paid Invoices: {rows.length}
          </Grid>
          <Grid item lg={4}>
          </Grid>
        </Grid>
        <Grid container style={{padding: '10px'}}>
          <Grid item lg={6} xs={6}>

            <div className={classes.search}>
                <div >
                  <SearchIcon /> Search { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
                </div>
                <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input>
              </div>
          </Grid>
          <Grid item lg={6}>

          </Grid>

        </Grid>
        {
          loading ? (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress size={85} style={{ color: '#769ba2', margin: 'auto' }} /> 
            </div>
          ) : (
            <div className={classes.tableWrapper} style={{margin: '10px'}}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
                options={{
                  rowStyle: {height: 55}
                }}
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody >
                  { stableSort(filteredRows, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      // console.log('Row map: ', row)
                      // console.log('Total rows; ', rows)
                      const isItemSelected = isSelected(row._id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      // setsearchMatches(rows.length)
                      // console.log(row)
                      return (
                        
                        <TableRow
                          hover
                          // onClick={event => handleClick(event, row._id)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row._id}
                          className={classes.rowPad}
                          selected={isItemSelected}
                          
                        >


                              
                            <TableCell component="th" id={labelId} scope="row" padding="none">
                              
                              <Link target="_blank"
                                to={{
                                pathname: '/clients/' + row.client._id,
                                // search: '?sort=name',
                                // hash: '#the-hash',
                                state: {customer: row.client, stuff: 'Customer List'}
                              }}> {row.client.displayName}
                              
                            </Link>
                            </TableCell>
                            <TableCell align="left">

                              { row.jobName }
                            </TableCell>
                            <TableCell align="left">{ row.invoiceNumber } </TableCell>
                            <TableCell align="left">{moment(row.dueDate).format('MM-DD-YYYY')} </TableCell>
                            <TableCell align="left">
                            {currencyFormat(row.amount)}
                            </TableCell>
                            <TableCell align="left">
                            {currencyFormat(row.balance)}
                            </TableCell>

                        </TableRow>
                      );
                    })
                  }
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              { loading ?<CircularProgress style={{ color: '#769ba2'}} size={ 16 } /> : ''}
                        
            </div>
          )

        }
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    user: state.user
  }
}

export default connect(mapStateToProps)(EnhancedTable)

