import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Checkbox,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import axios from 'axios';
import InvoiceCreationDialog from './MultiWoInvoiceDialog'
import InvoiceDisplayDetailsModal from './InvoiceDisplayDetailsModal';


const initialTemplate = `
<p>{{jobName}}</p>
<p>Landscape Installation as per proposal #{{estimateNumber}}</p>
<p>Estimate Amount: {{totalEstimateAmount}}</p>
<p>Progress Payment - {{estimateName}}</p>
<p>Previously Paid: {{previouslyInvoiced}}</p>
<p>Progress Payment Due: {{invoiceAmount}}</p>
<p>Remaining Balance After Progress Payment: {{remainingBalance}}</p>
`;

let defaultVariables = {
  jobName: 'Job Name',
  estimateName: 'Estimate Name',
  estimateNumber: 'Estimate #',
  totalEstimateAmount: 'Total',
  previouslyInvoiced: 'Previously Paid',
  invoiceAmount: 'This Amount',
  materialsTotal: 'Materials Total',
  equipmentTotal: 'Equipment',
  laborTotal: 'Labor',
  remainingBalance: 'Balance'
};

const WorkorderInvoicing = (props) => {
  // console.log('Workorder Invoicing ', props);
  // const [workorders, setWorkorders] = useState(props.workorders || []);
  const [selectedWorkorders, setSelectedWorkorders] = useState([]);
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false);
  const [invoiceAmount, setInvoiceAmount] = useState({});
  const [orderBy, setOrderBy] = useState('number');
  const [order, setOrder] = useState('asc');
  const [searchTerm, setSearchTerm] = useState('');
  const [showInvoiceDisplayDetailsModal, setShowInvoiceDisplayDetailsModal] = useState(false)
  const [lineItems, setLineItems] = useState([])
  const [variables, setVariables] = useState(defaultVariables)
  const workorders = [...props.workorders]
  // useEffect(() => {
  //   console.log('Workorder has changed 63 ', props.workorders)
  //   setWorkorders(props.workorders || []);
  // }, [props.workorders]);

  const handleCreateInvoice = async (itemsPassed, convertedVariables) => {
    // console.log('Creating invoice with line items:', itemsPassed);
    // console.log('Converted Variables', convertedVariables)
    setVariables(convertedVariables)
    setShowInvoiceDisplayDetailsModal(true)
    setLineItems(itemsPassed)
  }


  const generateInvoice = async (displayDetails) => {
    const obj = {
      workorders: selectedWorkorders,
      lineItems: lineItems,
      clientId: props?.clientId,
      displayDetails
    }
    // console.log('Object to send', obj)

    try {
      const response = await axios.post(`${props.url}/api/invoice/multiwoinvoice`, obj);
      // console.log('Invoice Generated', response.data)
      const invoice = response.data?.invoice
      if (invoice && invoice._id) {
        props.notification({
          type: 'success',
          title: 'Invoice Created',
          message: `Invoice #${invoice.invoiceNumber} has been created for $${invoice.amount}. You can now send it to the customer if you'd like.`
        })
        setOpenInvoiceDialog(false);
        setSelectedWorkorders([])
        setInvoiceAmount({})
        // Open Invoice Details
       
        if (props?.client?._id && invoice?._id) {
          props.updateParent({ invoice: invoice, data: obj })
          window.open(`/clients/${props?.client?._id}/Invoices/${invoice?._id}`, "_blank")
        }
      } else {
        props.notification({
          type: 'warning',
          title: 'Invoice Failed to Generate',
          message: `Invoice Failed to Generate `
        })
      }

    } catch (error) {
      console.error('Error creating invoice:', error);
      props.notification({
        type: 'warning',
        title: 'Invoice Failed to Generate',
        message: `Invoice Failed to Generate: ${error?.message}`
      })
    }


  };
  
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredWorkorders = workorders.filter((workorder) =>
    Object.values(workorder).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedWorkorders = filteredWorkorders.sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === 'asc' ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handleWorkorderSelect = (workorderId) => {
    const newSelectedWorkorders = selectedWorkorders.includes(workorderId)
      ? selectedWorkorders.filter(id => id !== workorderId)
      : [...selectedWorkorders, workorderId];
    setSelectedWorkorders(newSelectedWorkorders);
  };

  const handleOpenInvoiceDialog = () => {
    const initialInvoiceAmount = {};
    selectedWorkorders.forEach(id => {
      const workorder = workorders.find(w => w._id === id);
      initialInvoiceAmount[id] = workorder.price - workorder.invoicedAmount;
    });
    setInvoiceAmount(initialInvoiceAmount);
    setOpenInvoiceDialog(true);
  };

  const handleCloseInvoiceDialog = () => {
    setOpenInvoiceDialog(false);
  };

  const handleInvoiceAmountChange = (workorderId, amount) => {
    setInvoiceAmount({
      ...invoiceAmount,
      [workorderId]: parseFloat(amount) || 0,
    });
  };

  const handleCloseModal = () => {
    setShowInvoiceDisplayDetailsModal(false)
  }

  const convertVariables = () => {
    // selectedWorkorders.
    return variables
  }

  const handleSaveDisplayDetails = ({ content, showLineItems }) => {
    // setInvoiceNotes(notes);
    // setShowLineItems(showLineItems);
    console.log('Saved handleSaveDisplayDetails HTML:', content);
    console.log('Show Line Items:', showLineItems);
    const convertedVariables = convertVariables()
    const displayDetails = {
      content,
      showLineItems,
      variables: convertedVariables
    }
    generateInvoice(displayDetails)
  };

  // console.log('203 filtered workorder', filteredWorkorders)
  return (
    <div style={{ width: '100%', margin: '10px', padding: '10px' }}>

      <InvoiceDisplayDetailsModal
        open={showInvoiceDisplayDetailsModal}
        handleClose={handleCloseModal}
        variables={variables}
        onSave={handleSaveDisplayDetails}
      />
      <InvoiceCreationDialog
        open={openInvoiceDialog}
        onClose={() => setOpenInvoiceDialog(false)}
        selectedWorkorders={selectedWorkorders}
        workorders={workorders}
        onCreateInvoice={handleCreateInvoice}
        variables={variables}
      />
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search workorders..."
        value={searchTerm}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        style={{ marginBottom: '20px' }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenInvoiceDialog}
        disabled={selectedWorkorders.length === 0}
        style={{ marginBottom: '20px' }}
      >
        Create Invoice
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selectedWorkorders.length > 0 && selectedWorkorders.length < sortedWorkorders.length}
                  checked={selectedWorkorders.length === sortedWorkorders.length}
                  onChange={() => {
                    if (selectedWorkorders.length === sortedWorkorders.length) {
                      setSelectedWorkorders([]);
                    } else {
                      setSelectedWorkorders(sortedWorkorders.map(w => w._id));
                    }
                  }}
                />
              </TableCell>
              {['number', 'serviceName', 'job.name', 'description', 'price', 'invoicedAmount', 'status'].map((column) => (
                <TableCell key={column}>
                  <TableSortLabel
                    active={orderBy === column}
                    direction={orderBy === column ? order : 'asc'}
                    onClick={() => handleRequestSort(column)}
                  >
                    {column === 'job.name' ? 'Job Name' : column.charAt(0).toUpperCase() + column.slice(1)}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedWorkorders.map((workorder) => (
              <TableRow key={workorder._id}>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedWorkorders.includes(workorder._id)}
                    onChange={() => handleWorkorderSelect(workorder._id)}
                  />
                </TableCell>
                <TableCell>{workorder.number} {workorder.name || ''}</TableCell>
                <TableCell>{workorder.serviceName || ''}</TableCell>
                <TableCell>{workorder?.job?.name || ''}</TableCell>
                <TableCell>{workorder.description}</TableCell>
                <TableCell>${workorder?.price?.toFixed(2) || 0}</TableCell>
                <TableCell>${workorder?.invoicedAmount?.toFixed(2) || 0}</TableCell>
                <TableCell>{workorder.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default WorkorderInvoicing;