import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { fade, makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
// import Cards from 'react-credit-cards';
// import { createForm } from 'final-form'
// import Styles from './Styles'
// import Card from './Card'
// import {
//   formatCreditCardNumber,
//   formatCVC,
//   formatExpirationDate
// } from './cardUtils'


const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  textSaveLineBreaks: {
    whiteSpace: 'pre-line'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  inputArea: {
    marginBottom: 10
  }
}));

let requiresUpdate = 0;

const defaultValues = {
  name: '',
  description: '',
  isPrice: false
}
function SimpleModal(props) {
  // console.log('Rqquired LIne Items Props: ', props)
  const classes = useStyles();
  const [state, setState] = React.useState(defaultValues)

  React.useEffect(() => {
    if (props.editing && props.inputItem && props.inputItem.name) {
      // console.log('EDITING this: ', props)
      setState(props.inputItem)
    }
  }, [props])


  const divStyle = {
    // margin: '40px',
    // border: '5px solid pink',
    display: 'inline'
  };

  const handleClose = () => {
    // console.log(useRef.current)
    setState(defaultValues)
    props.closeModal()
  }

  const handleSubmit = () => {
    // console.log('Use Ref:', item)
    // Verify we dont already have this in use
    let data = {...state}
    data.name = state.name.toUpperCase()
    // Verify this isnt a duplicate
    // let inputs = props.service.requiredInputs
    // if (inputs && inputs.length) {
    //   // console.log('Check to see if we have this already: ', state.name)
    //   let index = inputs.findIndex(item => item.name.toUpperCase() === state.name.toUpperCase())
    //   if (index > -1) {
    //     // console.log('Duplicate')
    //     // window.alert('Duplicate')
    //     let item = inputs[index]
    //   // console.log(item)
    //   // console.log(data)
    //     if ((item.name === data.name) && (item.description === data.description)) {
    //     // console.log('All equal buddy')
    //       // We are just saving an old one
    //       handleClose()
    //       return
    //     } else {
    //       props.notification({
    //         title: 'Duplicate Required Input Name!',
    //         message: `The name ${data.name} has already been used for ${item.description}. Please either choose a different name or go edit that Required Input.`,
    //         type: 'warning'
    //       })
    //       return
    //     }
    //   }
    // }
    // window.alert('Save this thing')
    let itemToSave = {...data}
    // console.log('Item to save', itemToSave)
    // Capitalize name
    // let name = state.name.toUpperCase()
    // itemToSave.name = name
    // console.log(itemToSave)
    setState(defaultValues)
    props.saveItem(itemToSave, props.editing, props.inputItem)
  }


  return (
    <div style={ divStyle }>
      <Dialog
        maxWidth='md'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">What Input Do We Need?</DialogTitle>
        <DialogContent> 
          <DialogContentText>
            Please provide a Description of the Input for the User to see. For example, "How many trees are we trimming? How many linear feet is the wall?"
          </DialogContentText>
          <TextField
            id="LineItem"
            label="Required Input Descripton"
            type="text"
            fullWidth
            value={state.description}
            // ref={inputRef}
            className={classes.inputArea}
            onChange={e => setState({...state, description: e.target.value})}
            variant="outlined"
            // onChange={ e => setState({...state, serviceCode: e.target.value}) }
            // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
            InputLabelProps={{
              shrink: true,
            }}
          />
          <DialogContentText>
            What Is the Name Of This Input? This will be the name you'll use to reference this input in the Required Line Item
          </DialogContentText>
           <TextField
            id="Name"
            label="Required Input Name"
            type="text"
            fullWidth
            value={state.name}
            // ref={inputRef}
            className={classes.inputArea}
            onChange={e => setState({...state, name: e.target.value})}
            variant="outlined"
            // onChange={ e => setState({...state, serviceCode: e.target.value}) }
            // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
            InputLabelProps={{
              shrink: true,
            }}
            helperText={`This will be used in the template so please make it as short as possible`}
          />
           <DialogContentText>
            Add This Input to the Price of the Estimate?
          </DialogContentText>
          <FormControlLabel
                    control={<Checkbox checked={state.isPrice} onChange={ e => setState({...state, isPrice: !state.isPrice } ) } name="isPrice" />}
                    label="Include In Estimate Price"
                  />

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(SimpleModal)