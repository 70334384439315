import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const defaultValues = {
  percent: 50,
  date: null,
  amount: 0
}

const AddScheduledPaymentModal = (props) => {
// console.log('AddScheduledPaymentModal: ', props)
  const [state, setState] = React.useState(defaultValues)

  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    let total = parseFloat((state.percent * .01) * props.jobTotal).toFixed(2)
    // console.log('TOTAL: ', total)
    let date = moment(new Date()).format('YYYY-MM-DD')
  // console.log('Date: ', date)
  // console.log('SEt State total: ', total)
    setState({...state, amount: total, date: date})
    handleCheckAmounts()
  }, [props])

  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleSubmit = () => {
  // console.log('Handle Submit', state)
    // let obj = {...state}
    // axios({
    //   method: 'post',
    //   url:  `${props.url}/api/employees/manuallyAddShift`,
    //   data: obj,
    //   // validateStatus: (status) => {
    //   // // console.log('Validate status: ', status)
    //   //   if (status && status === 500) {
  
    //   //   }
    //   // },
    // }).then(response => {
    // // console.log('Axios response to update manuallyAddShift Time Entry ', response)
    //     props.notification({
    //       type: 'success',
    //       title: `Shift Has Been Added`,
    //       message: 'We Created That New Shift!'
    //     })

      props.updateParent('saved', state)
      setState(defaultValues)
    // })
  }

  const handleDateChange = (e) => {
    setState({...state, date: e.target.value})
  }

  const handlePercentChange = (e) => {
    setLoading(true)
    let total = parseFloat((e.target.value * .01) * props.jobTotal).toFixed(2)
    // console.log('TOTAL: ', total)
    setState({...state, amount: total, percent: e.target.value })
    // handleCheckAmounts(true)
  }

  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

  const handleCheckAmounts = (notify) => {
    setLoading(true)
    let newTotal = parseFloat(Number(props.totalPayments) - 0 + Number(state.amount)).toFixed(2)
  // console.log('Total Payments; %d -- This Payment: %d -- New Total: %d', props.totalPayments, state.amount, newTotal)
    if (newTotal > props.jobTotal) {
    // console.log('TOO MUCH!', newTotal - props.jobTotal)
        if (notify) {
          props.notification({
          type: 'warning',
          title: 'That Exceeds The Job Total',
          message: `The Job Total is ${currencyFormat(props.jobTotal)} and This Would Total ${currencyFormat(newTotal)} in Invoices.`
        })
      }
        let max = parseFloat(parseFloat(props.jobTotal).toFixed(2) - parseFloat(props.totalPayments).toFixed(2)).toFixed(2)
      // console.log('Max: ', max)
        let pct = max / parseFloat(props.jobTotal)
      // console.log('PERCENT: ', pct)
        let percInt = parseInt(pct * 100)
        setState({...state, amount: max, percent: percInt })
    }

    setLoading(false)
    // }
  }
return (
  <div>
    
      <Dialog
        // fullScreen={false}
        // maxWidth={'sm'}
        // width='lg'
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Add Scheduled Payment</DialogTitle>
        <DialogContent>
          <DialogContentText>
              Job Total: {currencyFormat(props.jobTotal)}
            </DialogContentText>
            <DialogContentText>
              Invoiced So Far: {currencyFormat(props.totalPayments)}
          </DialogContentText>
          <DialogContentText>
            Please Adjust The Requested Payment As Needed:
          </DialogContentText>

            <TextField
              id="date"
              label="Due Date"
              type="date"
              value={state.date } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              onChange={handleDateChange}
              // onChange={e => handleTimeChange('clockIn', e.target.value)}
              // onChange={e => setState({...state, clockIn: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />


            <TextField
              id="percent"
              label={`% of Total (${props.jobTotal})`}
              type="number"
              
              value={state.percent } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              onBlur={e => handleCheckAmounts(true)}
              onChange={handlePercentChange}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />
          <TextField
              id="amount"
              label="Amount"
              type="number"
              disabled
              value={state.amount } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              // onChange={e => setState({...state, percent: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />
            <TextField
                id="notes"
                label="Notes"
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                // className={classes.textSaveLineBreaks}
                value={state.notes}
                onChange={e => setState({...state, notes: e.target.value})}
                // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                // defaultValue="Default Value"
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button disabled={loading || !state.date || !state.amount || !state.percent} onClick={handleSubmit} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(AddScheduledPaymentModal))