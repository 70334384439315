import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import ApprovalActions from './ApprovalActions';
import ProtectedButton from '../../actions/protectedButtons';
import WarrantyPhotos from './WarrantyPhotos'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    backgroundColor: '#eee',
    margin: 5,
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  paper: {
    // width: '100%',
    marginTop: 10,
    marginBottom: theme.spacing(2),
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
      maxWidth: 960,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '50vh',
      maxWidth: 1360,
    },
    // margin: 20
  },
  headerText: {
    fontSize: 32,
    color: '#aaa',
    // color: 'rgb(119,156,164)',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 18,
      // color: 'red'
    },
    [theme.breakpoints.down('xs')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 14,
      // color: 'pink'
    },
    [theme.breakpoints.up('md')]: {
      // minHeight: '50vh',
      // maxWidth: 1360,
      textAlign: 'center',
      fontSize: 24,
      // color: 'green'
    }
  },
  giantText: {
    textAlign: 'center',
    fontSize: 48,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
    marginBottom: -15
  },
  subText: {
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
  },
  squigglyText: {
    textAlign: 'center',
    fontSize: 42,
    fontFamily: 'Inspiration',
    color: 'rgb(119,156,164)',
  },
  signUpText: {
    marginTop: 15,
    fontFamily: 'Amiri',
    fontSize: 32,
    textAlign: 'center',
    color: 'rgb(119,156,164)',
  },
  disclaimerText: {
    fontSize: 16,
    marginBottom: 30,
    marginTop: 15,
    color: '#aaa'
  },
  nameText: {
    fontSize: 24,
    marginTop: 10,
    color: '#aaa'
  },
  poDetailContainer: {
    width: '100%',
    height: '100%',
    margin: 10,
    padding: 10
  },
  poTitleItem: {
    fontSize: '1.3em',
    fontColor: '#ccc'
  },
  poDetailItem: {

  }
}));

function POApprovalComponent(props) {
// console.log('PO Approval ', props)
  const [po, setPO] = React.useState({})

  let { poId } = useParams()

  React.useEffect(() => {
    let isMounted = true
    // console.log('***** ***   *    PO ID has changed!!', poId)

    const fetchPurchaseRequest = async (poId) => {
      // console.log('Fetching ', poId)
      let url = `${props.url}/api/purchaseorders/viewPO?id=${poId}`
    // console.log('URL to retrieve: ', url)
      const response = await axios.get(url)
      if (response && response.data && isMounted) {
      // console.log('PO to Aprovae: ', response.data)
        setPO(response.data)
      }
    }
    if (poId && poId.toString().length) fetchPurchaseRequest(poId)
    return () => {
      isMounted = false
    }
  }, [poId])

  const classes = useStyles();
  const updateFromApprovalAction = (data) => {
    setPO(data)
    // props.closeModal()
  }

  const isDisabled = (po.status === 'Deleted' || po.status === 'Reconciled' || po.status === 'Approved' || po.status === 'Denied') ? true : false
  return (
    <div className={classes.root}>
     <Paper className={classes.paper}>
       <Grid container>
          
        <Grid item lg={12} xs={12} sm={12} md={12}>
            <Typography className={classes.giantText}>
              Purchase Request #{po.number}
            </Typography>
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          {
              po.status === 'Deleted' ? <Chip style={{color: '#fff', background: '#715', marginLeft: 10 }} size="small" label="Deleted" /> : null
            }
          {
              po.status === 'Reconciled' ? <Chip style={{color: '#fff', background: '#39a33d', marginLeft: 10 }} size="small" label="Reconciled" /> : null
            }
            {
              po.status === 'Pending Approval' ? <Chip style={{color: '#fff', background: '#39a33d', marginLeft: 10 }} size="small" label="Pending Approval" /> : null
            }
            {
              po.status === 'Approved' ? <Chip style={{color: '#fff', background: '#191', marginLeft: 10 }} size="small" label="Approved" /> : null
            }
            {
              po.status === 'Denied' ? <Chip style={{color: '#fff', background: '#911', marginLeft: 10 }} size="small" label="Denied" /> : null
            }
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <PurchaseRequestDetails po={po} />
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          {
            po.warranty ? (
              <WarrantyPhotos po={po} />
            ) : null
          }
        </Grid>
        
        <ProtectedButton type="Approve POs" kind="update" perms={props.perms}>
                           
            <ApprovalActions po={po} updateParent={updateFromApprovalAction} />
          
        </ProtectedButton> 
        
        </Grid>
     </Paper>
    </div>
  );
}

const PurchaseRequestDetails = ({ po }) => {
  const classes = useStyles()
  return (
    <div className={classes.poDetailContainer}>
      <div className={classes.poTitleItem}>Request Type:</div>
      <div  className={classes.poDetailItem}>
        {po.poType}
      </div>
      {
        po.poType === 'Client Project/Job' ? (
          <ClientProjectDetails po={po} />
        ) : null
      }
      <div className={classes.poTitleItem}>Division:</div>
      <div  className={classes.poDetailItem}>
        {po.division}
      </div> 
      <div className={classes.poTitleItem}>Preferred Vendor</div>
      <div  className={classes.poDetailItem}>
        {po.vendorName}
      </div>
      <div className={classes.poTitleItem}>Requested By:</div>
      <div  className={classes.poDetailItem}>
        {po.requestorName}
      </div> 
      <div className={classes.poTitleItem}>Needed By:</div>
      <div  className={classes.poDetailItem}>
        {moment(po.needed_by).format('dddd MM/DD/YY')}
      </div>
      <div className={classes.poTitleItem}>Request Details:</div>
      <div  className={classes.poDetailItem}>
        {po.description}
      </div> 
    </div>
  )
}

const ClientProjectDetails = ({ po }) => {
  const classes = useStyles()
  if (po && po.client) {
    return (
      <div>
        <div className={classes.poTitleItem}>Needed For:</div>
        <div  className={classes.poDetailItem}>
          {po.clientName}
          {
          po.projectName ? `    - (${po.projectName})`  : null
        }
        </div>
        
      </div>
    )
  } else {
    return (
      <div>No Client</div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(POApprovalComponent)

