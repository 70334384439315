import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import styled from 'styled-components';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Paper from '@material-ui/core/Paper'
import { currencyFormat } from '../../services/common'
import AddNewOptionModal from '../Inventory/options/AddNewOptionModal'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const Container = styled.div`
  min-height: 280px;
  border: 2px solid #ccc;
  border-radius: 10px;
  margin-top: 10px;
  padding: 15px;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

const InputOptionsContainer = styled.div`
  min-height: 280px;
  margin: 5px;
`;

const ItemInputContainer = styled.div`
  margin: 10px;
  padding: 10px;
`;


const SupplierPriceComponent = (props) => {
  console.log('Supplier Price Check Component', props)
  const [state, setState] = React.useState({
    counter: 0
  })
  const [suppliesAndMaterials, setSuppliesAndMaterials] = React.useState([])
  const [showInputOptions, setShowInputOptions] = React.useState(false)
  const [priceCheckResults, setPriceCheckResults] = React.useState([])

  React.useEffect(() => {
    let isMounted = true

    const handleNewPriceCheck = async () => {
      const response = await axios.get(`${props.url}/api/company/listNewTakeOffs`)
      if (isMounted && response && response.data && response.data.length) {
       
        setSuppliesAndMaterials(response.data)
      }
      // window.alert('PRICE CHECK bitch')
  
    }

    if (props.item && props.item._id) {
      console.log('Set our options!!', props.item)
    } else {
      handleNewPriceCheck()
    }
    return () => {
      isMounted = false
    }
  }, [])

  const handleOpen = () => {
    setShowInputOptions(!showInputOptions)
  }
  
  const handleReceivePriceCheck = (data) => {
    // console.log('Our price hcekc', data)
    if (props.vendor) data.vendor = props.vendor
    let list = priceCheckResults
    list.unshift(data)
    // console.log('LIST:', list)
    setPriceCheckResults(list)
    setState({...state, counter: state.counter++ })
  }
  return (
    <div>
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={handleOpen}
        startIcon={<AttachMoneyIcon />}
        >
        Price Check
      </Button>
      {
        showInputOptions ? (
          <Container>
            <PriceInputOptions {...props} takeoffs={suppliesAndMaterials} updateParent={handleReceivePriceCheck} />
            <PriceCheckOutput {...props} list={priceCheckResults} />
          </Container>
        ) : null
      }
    </div>
  )
}

const PriceInputOptions = (props) => {
  return (
    <InputOptionsContainer >
      <ItemInput {...props} />
    </InputOptionsContainer>
  )
}

const defaultValues = {
  notes: '',
  price: '',
  option: null,
  item: null
}

const ItemInput = (props) => {
  const [item, setItem] = React.useState(null)
  const [ourOptions, setOptions] = React.useState([])
  const [chosenOption, setChosenOption] = React.useState(null)
  const [state, setState] = React.useState(defaultValues)
  const [vendor, setVendor] = React.useState({})
  const [showNewOptionModal, setShowNewOptionModal] = React.useState(false)

  React.useEffect(() => {
    if (props.vendor && props.vendor._id) {
      setVendor(props.vendor)
    }
  }, [props.vendor])

  React.useEffect(() => {
    if (props.item && props.item._id && props.item.options && props.item.options.length) {
      setItem(props.item)
      setOptions(props.item.options)
    }
  }, [props.item])

  const handleUpdateItem = (e, newItem) => {
  console.log('New Item', newItem)
  const val = e.target.value
  console.log('New Value', val)
    setItem(newItem)
    if (newItem && newItem.options && newItem.options.length) {
    // console.log('Set the options', newItem.options)
      setOptions(newItem.options)
    }
  }

  const handleChooseOption = (e, newOpt) => {
    console.log('This is the option:', newOpt)
    if (newOpt && newOpt.name === 'Create New') {

    } else {
      setChosenOption(newOpt)
    }
  }

  const handleSave = async () => {
    let priceCheckDetails = {
      item: item,
      option: chosenOption,
      price: state.price,
      notes: state.notes,
      vendor: vendor
    }
    const response = await axios.post(`${props.url}/api/vendors/incomingPriceCheck`, priceCheckDetails)
    // console.log('Resp', response.data)
    if (response && response.data && response.data._id) {
      props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Thank You For Helping Us Be Better Informed'
      })
      props.updateParent(priceCheckDetails)
  
      setChosenOption({})
      if (props.item && props.item._id && props.item.options && props.item.options.length) {
        setItem(props.item)
        setOptions(props.item.options)
      } else {
        setItem({})
        setOptions([])
      }

      if (props.vendor && props.vendor._id) {
        setVendor(props.vendor)
      } else {
        setVendor({})
      }
      setState(defaultValues)
    } else {

    }
    // console.log(priceCheckDetails)
  }

  const handleChange = (e) => {
    setState({...state, [e.target.id]: e.target.value })
  }

  const handleUpdateVendor = (e, newVendor) => {
    setVendor(newVendor)
  }
  const handleCloseNewOptionModal = (type, data) => {
    console.log('Add New option response', type)
    console.log(data)
    setShowNewOptionModal(false)
    if (type === 'newOptionAdded') {
          // set our option as the last option
      if (data && data._id) {
        const newOption = data.options[data.options.length - 1]
        const newItem = { ...data, optionId: newOption._id }
        console.log('New Item', newItem)
        console.log('Set the option', newOption)
        setItem(newItem)
        setChosenOption(newOption)
        // setState({...state, option: newOption, pricePer: newOption.pricePer })
        // props.addNewOption(newItem)
      }
    }


  }

  let scope = {
    name: 'Scope Name',
    _id: '448489348349'
  }

  return (
    <div>
       <AddNewOptionModal scope={scope} item={item} sizes={props.sizes} open={showNewOptionModal} closeModal={handleCloseNewOptionModal} />
   
    <ItemInputContainer>

      {
        (props.vendor && props.vendor._id) ? null : (
          <Autocomplete
            limitTags={5}
            id={`vendor-input`}
            // value={state.description}
            options={props.vendors || []}
            value={vendor}
            // getOptionLabel={(option) => option.name}
            getOptionLabel={(option) => {
              return option.name
            }}
            style={{ marginBottom: 10 }}
            // onChange={handleDescriptionChange}
            onChange={handleUpdateVendor}
            // filterOptions={(options, params) => {
            //   return `${option.name} ${option.alternateName}`
            // }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            // value={state.activeLineItem}
            fullWidth
            // InputProps={{ style: { fontSize: 20, color: '#fff', backgroundColor: '#333' } }}
            PaperComponent={({ children }) => (
              <Paper style={{ padding: 5, fontSize: 20, color: '#bbb', backgroundColor: '#fff' }}>{children}</Paper>
            )}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField
                {...params} 
                fullWidth
                label="Vendor"
                variant="outlined"
                placeholder="Who Is The Vendor?"
                />
            }
          />
        )
      }
      <Autocomplete
        limitTags={5}
        id={`lineitem-input`}
        // value={state.description}
        options={props.takeoffs || []}
        value={item}
        style={{ marginBottom: 10, marginTop: 5  }}
        // getOptionLabel={(option) => option.name}
        getOptionLabel={(option) => {
          let optionLabel = ''
          if (option && option.name) optionLabel += option.name
          if (option && option.alternateName) optionLabel += ' ' + ' - (' + option.alternateName + ')'
          if (option && option.notes) {
            let partialNotes = option.notes.substring(0, 70)
            optionLabel += '   -  ' + partialNotes.trim()
            if (option.notes.length > 75) optionLabel += '...'
          }
          // console.log('Option', option)
          // console.log(optionLabel)
          return optionLabel
        }}
      
        // onChange={handleDescriptionChange}
        onChange={handleUpdateItem}
        // filterOptions={(options, params) => {
        //   return `${option.name} ${option.alternateName}`
        // }}
        selectOnFocus
        disabled={props.item}
        clearOnBlur
        handleHomeEndKeys
        // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
        // value={state.activeLineItem}
        fullWidth
        // InputProps={{ style: { fontSize: 20, color: '#fff', backgroundColor: '#333' } }}
        PaperComponent={({ children }) => (
          <Paper style={{ padding: 5, fontSize: 20, color: '#bbb', backgroundColor: '#fff' }}>{children}</Paper>
        )}
        // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
        renderInput={(params) => <TextField
            {...params} 
            fullWidth
            label="Price Check Item"
            variant="outlined"
            
            placeholder="What Item Are We Looking For?"
            />
        }
      />
    



      {
        (ourOptions && ourOptions.length) ? (
          
          <Autocomplete
              // Other props...
                          limitTags={5}
                          style={{ marginBottom: 10, marginTop: 5  }}
                        id={`option-input`}
                        // value={state.description}
                        options={[...ourOptions, { name: 'Create New' }]}
                        value={chosenOption}
              onChange={(event, newValue) => {
                // console.log('New val:', newValue)

                if (newValue && newValue.name && newValue.name === 'Create New') {
                  // Open the modal when "Create a New Option" is selected
                  // console.log('Open the modal!!!')
                  setShowNewOptionModal(true)
                  // handleModalOpen();
                } else {
                  // Handle other option selection logic here
                  handleChooseOption(event, newValue)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  variant="outlined"
                  label="Option"
                  placeholder="Which Option?"
                />
              )}
              renderOption={(option) => {
                if (option.inputValue) {
                  // Render "Create a New Option" item
                  return (
                    <div>
                      Create a New Option
                    </div>
                  );
                }
                // Render other options as you normally would
                return (
                  <div>
                    {option.name} {option.size ? option.size : ''} { option.notes ? option.notes: ''}
                    {/* Customize the rendering of other option properties here */}
                  </div>
                );
              }}

              getOptionLabel={(option) => {
                if (typeof option === 'string') {
                  return option;
                }
                let optionLabel = '';
                if (option && option.name) optionLabel += option.name;
                if (option && option.size) optionLabel += ' - (' + option.size + ')';
                if (option && option.notes) {
                  let partialNotes = option.notes.substring(0, 70);
                  optionLabel += ' - ' + partialNotes.trim();
                  if (option.notes.length > 75) optionLabel += '...';
                }
                if (option.inputValue) {
                  return 'Save ' + option.inputValue + ' as a New Option';
                }
                return optionLabel; // Ensure that a string is returned for all cases
              }}
              
            />
        ) : null
      }
      <TextField
        fullWidth
        variant="outlined"
        label="Price"
        placeholder="$5.00"
        id="price"
        type="number"
        value={state.price}
        style={{ marginBottom: 10, marginTop: 5  }}
        onChange={handleChange}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Notes"
        id="notes"
        value={state.notes}
        onChange={handleChange}
        style={{ marginBottom: 10 }}
        placeholder="Notes About This Purchase"
      />
      <Button
        variant="contained"
        fullWidth
        color="primary"
        onClick={handleSave}
        disabled={!state.price || !chosenOption}
      >Save</Button>
    </ItemInputContainer>
  </div>
  )
}

const PriceCheckOutput = (props) => {
  // console.log('Price check opts', props)
  const list = props.list
  return (
    <div>
      <h4>Results {props.list.length}</h4>
      <div>
        {
          list.map((item, index) => {
            return (
              <div key={index}>{(item.option && item.option._id) ? `${item.option.name} - ` : ''} {item.item ? item.item.name : ''}  {item.price ? ` - ${currencyFormat(item.price)}  ` : '' } {item.vendor ? item.vendor.name : ''}</div>
            )
          })
        }
      </div>
    </div>
  )
}
const mapStateToProps = (state) => {
  console.log('Vendors?? 493', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(SupplierPriceComponent)
