import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { fade, makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
// import ServiceLineItemConfiguration from './ServiceLineItemConfiguration'
import Paper from '@material-ui/core/Paper';
// import AddNewItemModal from './AddNewItemModal'
import EditTakeOffItem from '../../Inventory/EditTakeOffItem'
import ChooseMaterialOptions from './ChooseMaterialOptions'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  textSaveLineBreaks: {
    whiteSpace: 'pre-line'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

let requiresUpdate = 0;

const filter = createFilterOptions();

const defaultValues = {
  description: '',
  activeLineItem: {}
}

function SimpleModal(props) {
console.log('Choose Supplies & Materials ', props)
  const classes = useStyles();
  const closeModal = props.closeFunc
  const user = props.user
  const url = props.url
  const notification = props.notification
// console.log(url)

const [open, setOpen] = React.useState(false)
const [loading, setLoading] = React.useState(false);
const [takeOffList, setTakeOffList] = React.useState(props.takeOffList)
const [filteredTakeoffs, setFilteredTakeoffs] = React.useState([])
const [state, setState] = React.useState(defaultValues)
const [item, setItem] = React.useState({
  name: ''
})
const [value, setValue] = React.useState(null)
const [isOpen, toggleOpen] = React.useState(false)
const [dialogValue, setDialogValue] = React.useState({
  title: '',
  year: '',
});

React.useEffect(() => {
// console.log('DB Item props change', props)
    console.log('Choose Supplies & Materials PROPS CHANGED ', props)
  if (props.itemToEdit && props.itemToEdit.inventoryItemId) {
    setItem(props.itemToEdit)
    setOpen(true)
  } else {
    console.log('No item to edit....')
    // setItem({ name: '' })
  }
  // filter our list of takeoffs to the scope
  if (props.takeOffList && props.takeOffList.length) {
    filterTheTakeoffs(props.takeOffList)
  } else {
    console.log('Cant filter the takeoffs...????', props)
  }
}, [props])

const filterTheTakeoffs = (list) => {
  console.log('Filter this shit:', list)
  console.log(props)
  const scopeId = props?.service?.service?.scope
  console.log('Find anything w/ this scope:', scopeId)
  if (scopeId) {
    let newList = list.filter(item => (item?.scopeIds?.includes(scopeId)))
    console.log('Got our new list:', newList)
    setFilteredTakeoffs(newList)
  } else {
    setFilteredTakeoffs(list)
  }

}

const toggleModal = () => {
  setOpen(!open)
}


const handleClose = () => {
  setItem({ name: ''})
  setValue(null)
  setState(defaultValues)
  setOpen(false)
}

const handleCloseNewItemModal = (d) => {
  console.log('Close Takeoff Edit Item modal', d)
  // toggleOpen(false)
}

const addItem = (d) => {
  // Add the new item to our list
  console.log('Add our item: ', d)
  setValue('')
  let newTakeoffs = takeOffList
  newTakeoffs.unshift(d)
  setTakeOffList(newTakeoffs)
  setItem(d)
}

const handleSaveNewLineItem = (d, isEditing) => {
console.log('Save DB Item: 191', d)
if (!d.inventoryItemId && d.option && d.option.takeOff) d.inventoryItemId = d.option.takeOff
  // Add this to our Estimate
  setValue(null)
  setItem({ name: '' })
  setOpen(false)
  console.log('State at the moment...', state)
  console.log('props item??', props)
  console.log('Item exist??', item)
  if (state && state._id) {
    console.log('Send state')
      props.addLineItemToEstimate(d, state, isEditing)
  } else {
    console.log('Send Item instead', item)
    props.addLineItemToEstimate(d, item, isEditing)
  }

}

const handleUpdateLineItem = (d) => {
console.log('Update DB ITem::: 199', d)
  props.updateEstimateLineItem(d, state)
  setValue(null)
  setItem({ name: '' })
  setOpen(false)
}

const responseFromEditItem = (type, d) => {
  console.log('responseFromEditItem', d)
  console.log('Type:', type)
  if (type === 'saved') {
    // New Item dont close the modal - but update the item
    setItem(d)
    console.log('This is a new item')
    setValue('')
    let newTakeoffs = [...takeOffList]
    newTakeoffs.unshift(d)
    setTakeOffList(newTakeoffs)
    console.log("Updated Takeoffs", newTakeoffs)
  }
  if (type === 'updated') {
    // Close the Add Item Modal
    toggleOpen(false)
    // Verify this item exists in the list already (because we update options sometimes a new inv item can look like an existing one here)
    console.log('Existing Item:', item)
    let newTakeoffs = [...takeOffList]
    setValue('')
    if (item && item._id) {
      // Make sure that its in our list of stuff
      let index = newTakeoffs.findIndex(toItem => (toItem._id === item._id))
      console.log(index)
      if (index > -1) {
        // Already exists
        newTakeoffs[index] = d
      } else {
        console.log('Gotta add this one to our takeofflist')
        newTakeoffs.unshift(d)
      }
    } else {
      console.log('This is a new item')    
      newTakeoffs.unshift(d)
    }
    setTakeOffList(newTakeoffs)
    console.log("Updated Takeoffs", newTakeoffs)
    setItem(d)
  }
  if (type === 'updateOptions') {
    console.log('Add our options to this bitch!!!', d)
    let existingItem = { ...item }
    if (d && d.options && d.options.length) {
      existingItem = d
    } else {
      existingItem.options = d
    }
    console.log('Set OUr updated item w/ the options:', existingItem)
    setItem(existingItem)
  }
}

const handleAddNewLineItemOption = (itemWithOptions) => {
  console.log('Add option to item', itemWithOptions)
  setItem(itemWithOptions)
}

console.log('Vendors 226', props.vendors)

  return (
    <div>
      {/* <AddNewItemModal open={isOpen} handleCloseModal={handleCloseNewItemModal} item={item} addItem={addItem} /> */}
      <EditTakeOffItem item={item} sizes={props.sizes} scopes={props.scopes} vendors={props.vendors} open={isOpen} updateParent={responseFromEditItem} handleCloseModal={handleCloseNewItemModal} addItem={addItem} />
      <Dialog
        maxWidth={'lg'}
        fullWidth
        open={open}
        onClose={handleClose}
        >
          <DialogTitle>Configure Supplies & Materials</DialogTitle>
          <DialogContent>
            <Autocomplete
              freeSolo
              limitTags={5}
              id={`description-input`}
              // value={state.description}
              options={filteredTakeoffs}
              value={value}
              // getOptionLabel={(option) => option.name}
              getOptionLabel={(option) => {
                console.log('Get option label', option)
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return 'Save ' + option.inputValue + ' as a New Item';
                }
             
                // Regular option
                let optionName = 'OPTION'
                if (option.name) optionName = option.name
                if (option.description) optionName += '  ' + option.description
                if (option.notes) optionName += ' - ' + option.notes
                console.log(optionName)
                return optionName
              }}
              // style={{ width: 200 }}
              // onChange={handleDescriptionChange}
              onChange={(event, newValue) => {
              // console.log('Chan/e in value:', newValue)
                event.preventDefault()
                if (newValue && newValue._id) {
                  // console.log('AN Active object was selected')
                  setItem(newValue)
                  setState({...state, ...newValue})
                } else if (newValue) {
                  setItem({
                    title: newValue,
                    name: newValue
                  })
                  toggleOpen(true);
                } else {
                // console.log('NEW VALUE', newValue)
                  // setItem({
                  //   title: newValue,
                  //   name: newValue
                  // })
                  // toggleOpen(true);
                }
                if (typeof newValue === 'string') {
                  setValue({
                    title: newValue,
                    name: newValue
                  });
                  // setItem({
                  //   title: newValue,
                  //   name: newValue
                  // })
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setValue({
                    title: newValue.inputValue,
                    name: newValue.inputValue
                  });
                  setItem({
                    title: newValue.inputValue,
                    name: newValue.inputValue,
                    description: newValue.inputValue
                  })
                  // toggleOpen(true);
                  setDialogValue({
                    title: newValue.inputValue,
                    year: '',
                  });
                } else {
                  // console.log('NEWV AL: ', newValue)
                  setValue(newValue);
                  if (newValue && newValue.name) {
                    // setItem(newValue)
                  }
                
                }
              }}
              filterOptions={(options, params) => {
                console.log('Filtered Options', options)
                // console.log('Params', params)
                // console.log('Input value: ', params.inputValue)
                const filtered = filter(options, params);
        
                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Add "${params.inputValue}"`,
                    name: 'Test This'
                  });
                }
        
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
              // value={state.activeLineItem}
              fullWidth
              // InputProps={{ style: { fontSize: 20, color: '#fff', backgroundColor: '#333' } }}
              PaperComponent={({ children }) => (
                <Paper style={{ padding: 5, fontSize: 20, color: '#bbb', backgroundColor: '#fff' }}>{children}</Paper>
              )}
              // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
              renderInput={(params) => <TextField {...params}  fullWidth label="Search Database for Supplies/Materials" variant="outlined" />}
            />
            <ChooseMaterialOptions url={props.url} editing={props.editing} sizes={props.sizes} vendors={props.vendors} takeoffs={filteredTakeoffs} addLineItemToEstimate={handleSaveNewLineItem} updateLineItemOnEstimate={handleUpdateLineItem} item={item} service={props.service} scopes={props.scopes} addNewOptionToItem={handleAddNewLineItemOption} />
          </DialogContent>
        </Dialog>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SearchIcon />}
          onClick={toggleModal}
        >Choose Materials/Supplies</Button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(SimpleModal))