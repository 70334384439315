import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SupplierComponent from './SupplierComponent'
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AddOptionsComponent from '../Inventory/options/AddOptionsComponent'

const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const treeSizes = [
  {
    name: `24" Box`
  },
  {
    name: '30" Box'
  },
  {
    name: `36" Box`
  },
  {
    name: `42" Box`
  },
  {
    name: `48" Box`
  }
]

const plantSizes = [
  {
    name: `1 Gallon`
  },
  {
    name: `2 Gallon`
  },
  {
    name: `3 Gallon`
  },
  {
    name: `5 Gallon`
  },
  {
    name: `7 Gallon`
  },
  {
    name: `10 Gallon`
  },
  {
    name: `20 Gallon`
  }
]

const flowerSizes = [
  {
    name: `Flat (16-4")`
  },
  {
    name: `Jumbo Pack`
  },
  {
    name: `1 Gallon`
  }
]

const treeCategories = [
  {
    name: 'Citrus'
  },
  {
    name: 'Evergreen'
  },
  {
    name: 'Semi-Evergreen'
  },
  {
    name: 'Native'
  },
  {
    name: 'Ornamental'
  }
]

const plantCategories = [
  {
    name: 'Ground Covers'
  },
  {
    name: 'Shrubs'
  },
  {
    name: 'Vines'
  },
  {
    name: 'Flowering'
  }
]

const flowerCategories = [
  {
    name: 'Perrennial'
  },
  {
    name: 'Annual'
  }
]

const NewLineItemInput = (props) => {
// console.log('NewLineItemInput props', props)

  let name = ''
  if (props.item && props.item.name) name = props.item.name
// console.log('NAME 45: ', name)
  const defaultState = {
    scope: props.scope || 'Other',
    name: name,
    description: '',
    notes: '',
    sku: '',
    mfg: '',
    upc: '',
    partNumber: '',
    category: '',
    size: null,
    subCategory: '',
    options: []
  }
  const scope = props.scope ? props.scope.name || 'None' : 'UnKnown'
  const [state, setState] = React.useState(defaultState)
  const [value, setValue] = React.useState(null)
  const [dialogValue, setDialogValue] = React.useState(null)
  const [open, toggleOpen] = React.useState(false);
  const [item, setItem] = React.useState({
    name: ''
  })
  const [category, setCategory] = React.useState(null)
  const [categoryList, setCategoryList] = React.useState([])
  const [subCategoryList, setSubcategoryList] = React.useState([])
  const [sizeList, setSizeList] = React.useState([])
// console.log('STATE 80: ', state)
  // React.useEffect(() => {
  //   if (props.scope === 'Trees') {
  //     setCategory('Trees')
  //     setCategoryList(treeCategories)
  //     setSizeList(treeSizes)
  //   }
  //   if (props.scope === 'Plants') {
  //     setCategory('Plants')
  //     setCategoryList(plantCategories)
  //     setSizeList(plantSizes)
  //   }
  //   if (props.scope === 'Flowers') {
  //     setCategory('Flowers')
  //     setCategoryList(flowerCategories)
  //     setSizeList(flowerSizes)
  //   }
  //   // setSubcategoryList()
  // }, [props.scope])

  React.useEffect(() => {
    // console.log('Scope changed', scope)
    if (props.scope && props.scope.categories && props.scope.categories.length) {
    // console.log('Set Category list:', props.scope.categories)
      setCategoryList(props.scope.categories)
      // console.log('set list', props.scope.categories)
    } else {
      // console.log('empty list')
      setCategoryList([])
    }
  }, [props.scope])

  React.useEffect(() => {
    if (props.item) {
    // console.log('Set State 182 props.item', props.item)
      setItem(props.item)
      setState({...state, name: props.item.name})
    }

    // if (scope && scope.categories && scope.categories.length) {
    //   setCategoryList(scope.categories)
    // } else {
    //   setCategoryList([])
    // }
  }, [props.item])
  // React.useEffect(() => {
  // // console.log('State of our Plant Input changed')
  //   // props.updatePlantDetails(state)
  // }, [state])


  const handleUpdateSize = (e) => {
    setState({...state, size: e.target.value})
  }
  
  const handleCategoryChange = (e) => {
    // console.log(e.target)
  // console.log('Change categoryes...')
    const category = e.target.value
    setState({...state, category: category})
    // let category = e.target.value
  // console.log('new Category', category)
    if (category && category.subCategories && category.subCategories.length) {
    // console.log('Set subs', category.subCategories)
      setSubcategoryList(category.subCategories)
    } else {
    // console.log('Set subs blank')
      setSubcategoryList([])
    }
    // if ()
    // if (cat === 'PVC Parts') {
    //   setSubcategoryList(pvcPartsSubCategoryList)
    // }
    // if (cat === 'Poly Parts') {
    //   setSubcategoryList(polyPartsSubCategoryList)
    // }
  }

  const handleUpdateUOM = (e, newOne) => {
    // // console.log('New UOM: ', newOne)
    setDialogValue({...dialogValue, unitsOfMeasure: newOne})
  }

  const handleUpdateParent = () => {
  // console.log('UPdate PRaent 002028', state)
    // console.log('dv', dialogValue)
  // console.log('Scope:', scope)
    let itemToSend = {...state}
    itemToSend.scope = scope
    props.updateParent(itemToSend)
  }

  const handleAddOptions = (data) => {
  // console.log('Update Optionson thi sNEW Item options', data)
    let opts = state.options
    opts.push(data)
    setState({ ...state, options: opts })
    // Save our Options if this is a new item???
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <Grid container style={{width: '100%'}} spacing={2}>
        <Grid item lg={12} sm={12}>
          <span style={{ fontSize: '1.09em', color: '#bbb' }}>
            Add New Item To The {scope} Scope:
          </span>
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              fullWidth
              label="Common Name"
              value={state.name}
              onChange={e=>setState({...state, name: e.target.value})}
              onBlur={handleUpdateParent}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              fullWidth
              label="Alternative Name"
              value={state.alternateName}
              onChange={e=>setState({...state, alternateName: e.target.value})}
              onBlur={handleUpdateParent}
            />
          </FormControl>
        </Grid>
        <Grid item lg={12} sm={12}>
          <TextField
            id="description"
            variant="outlined"
            value={state.description}
            onChange={e=>setState({...state, description: e.target.value})}
            onBlur={handleUpdateParent}
            label="Description"
            type="text"
            fullWidth
          />
        </Grid>
        
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <AddOptionsComponent {...props} updateParent={handleAddOptions} />
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl variant="outlined" style={{width: '100%'}} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state.category}
              onChange={handleCategoryChange}
              onBlur={handleUpdateParent}
              label="Category"
            >
              {
                categoryList.map(unit => {
                  // console.log('Category option:', unit)
                  return (
                  <MenuItem key={unit._id} value={unit} >{unit.name}</MenuItem>
                )})
              }
            </Select>
        </FormControl>
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl variant="outlined" style={{width: '100%'}} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Sub-Category</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state.subCategory}
              onChange={e=>setState({...state, subCategory: e.target.value})}
              onBlur={handleUpdateParent}
              label="Unit"
            >
              {
                subCategoryList.map(unit => {
                // console.log('Subt category option:', unit)
                  return (
                  <MenuItem key={unit.name} value={unit.name} >{unit.name}</MenuItem>
                )})
              }
            </Select>
        </FormControl>
        </Grid>
        {/* <Grid item lg={12}>
        <FormControl fullWidth >
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            fullWidth
            options={sizeList}
            onChange={handleUpdateUOM}
            // onChange={e => setState({ ...state, notes: e.target.value })}
            onBlur={handleUpdateParent}
            disableCloseOnSelect
            style={{marginTop: 25}}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Unit(s) of Measurement" placeholder="How Do We Measure (Each, Per Foot, Per Ton, etc)" />
            )}
          />
      </FormControl>
        </Grid> */}
        {/* <Grid item lg={12} sm={12}>
        <TextField
              variant="outlined"
              fullWidth
              minRows={4}
              label="Internal Notes"
              onChange={e => setState({ ...state, notes: e.target.value })}
              onBlur={handleUpdateParent}
            />
        </Grid> */}
      </Grid>
    </div>
  )
}

export default NewLineItemInput