import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { setCrew } from '../../actions/connectionActions'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import moment from 'moment'
import ViewWorkorder from './ViewWorkorder'

const Schedule = (props) => {
// console.log('Schedule Component', props)
  const [wos, setWos] = React.useState([])
  const [wo, setWo] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [showWorkorderDetails, setShowWorkorderDetails] = React.useState(false)

  React.useEffect(() => {
    setWos(props.workorders)
  }, [props.workorders])

  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleEditWo = (wo) => {
  // console.log('Edit WO: ', wo)
    setWo(wo)
    setShowWorkorderDetails(true)
  }

  const handleUpdateWorkorder = (type, woId) => {
  // console.log('Save/Update WO: ', wo)
    setShowWorkorderDetails(false)
    if (type === 'complete' && woId) {
      let newWOs = [...wos].filter(wo => (wo._id !== woId))
      setWos(newWOs)
    }
  }

  return (
    <div>
      <ViewWorkorder open={showWorkorderDetails} workorder={wo} updateParent={handleUpdateWorkorder} />
          <Dialog
          // fullScreen={false}
          // maxWidth={lg}
          // width='md'
          fullWidth={true}
          maxWidth = {'lg'}
          // maxwidth='md'
          // style={{width: '90hw'}}
          open={props.open}
          // scroll='paper'
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          >
                {/* <Dialog
        maxWidth='lg'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      > */}
          <DialogTitle id="responsive-dialog-title">
            {props.crew ? props.crew.name : ''} 
           </DialogTitle>
          <DialogContent>
          <Table
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Job Name</TableCell>
                <TableCell>Scheduled Date</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Service Type</TableCell>
                <TableCell>Estimated Hours</TableCell>
                <TableCell>Created Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { wos.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row._id}
                      onDoubleClick={() => handleEditWo(row)}
                    >
                      <TableCell component="td" id={row._id} scope="row" padding="none">
                        {row.jobName}
                      </TableCell>
                      <TableCell component="td"  scope="row" padding="none">
                        {moment(row.scheduledDate).format('MM/DD/YYYY hh:mma')}
                      </TableCell>
                      <TableCell component="td" scope="row" padding="none">
                        {row.jobSiteAddress}
                      </TableCell>
                      <TableCell component="td" scope="row" padding="none">
                        {row.serviceName}
                      </TableCell>
                      <TableCell component="td"scope="row" padding="none" align="center">
                        {row.estMinutes ? `${Math.round(row.estMinutes / 60)}` : ''}
                      </TableCell>
                      <TableCell component="td" scope="row" padding="none" align="center">
                         {moment(row.created_at).format('MM/DD/YYYY')}
                      </TableCell>
                      <TableCell component="td"scope="row" padding="none">
                         
                      </TableCell>
                      
                    </TableRow>
                  )})
              }
            </TableBody>
          </Table>
            </DialogContent>
            </Dialog>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    crew: state.crew
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCrew: (crew) => { dispatch(setCrew(crew)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)