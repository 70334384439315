import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SignaturePad from 'react-signature-canvas';

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: stretch;
  padding: 10px;
`;

const InnerContainer = styled.div`
  overflowY: scroll;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1180px;
  border-radius: 20px;
  border: 4px solid #ddd;
  align-self: stretch;

`;

const TabsChoice = styled.div`
  min-width: 150px;
  width: 25%;
  overflow: auto;
  height: 250px;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  height: 250px;
  align-self: stretch;
  overflow-y: scroll;
  background-color: #fff;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

const FinePrintArea = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center
`;

const FinePrintDetails = styled.div`
  font-size: 12px;
  color: #555;
  font-weight: 800;
  text-align: center;
`;

const ButtonArea = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: center;
`;

const SubmitButton = styled(Button)`
  width: 300px;
`;
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{width: '100%'}}>
          <Typography  component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  },
  signatureCanvas: {
    // backgroundColor: 'red',
    borderColor: '#ddd',
    border: '2px solid',
    width: '100%',
    minHeight: '200px',
    maxHeight: '280px',
    maxWidth: '800px'
  }
}));

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const SignatureTest = (props) => {
  // console.log('ADD NEW IITEM PROPS:', props)
  const classes = useStyles();
  const sigPadRef = React.useRef({})
  const [signatureImg, setSignatureImg] = React.useState(null)
  const [consentChecked, setConsentChecked] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const handleAdd = (e) => {
  // console.log('REf: ', sigPadRef)
    sigPadRef.current.clear()
  }

  const handleClear = () => {
    setSignatureImg(null)
    sigPadRef.current.clear()
  }

  const handleTrim = () => {
    setSignatureImg( sigPadRef.current.getTrimmedCanvas()
      .toDataURL('image/png'))
  }

  const handleCheck = () => {
    let checked = !consentChecked
    setConsentChecked(checked)
  }

  const handleEndStroke = () => {
    setSignatureImg( sigPadRef.current.getTrimmedCanvas()
    .toDataURL('image/png'))
  }

  const handleSubmit = () => {
    setLoading(true)
    // console.log('Handle Submit')
    props.submit(signatureImg)
    // , (err, resp) => {
    // // console.log('ERR submission', err)
    // // console.log('Response from submision: ', resp)
    //   setLoading(false)
    // })
    // setTimeout(() => { setLoading(false) }, 1500)
  }
  return (
    <div>
      <SignaturePad
          penColor='green'
          canvasProps={{className: classes.signatureCanvas}}
          ref={sigPadRef}
          onEnd={handleEndStroke}
          />
      <FinePrintArea>
        <FinePrintDetails>
          By Signing Above, I consent to the use of Electronic Signature.
        </FinePrintDetails>
      </FinePrintArea>
      <ButtonArea>
        
        <Button onClick={handleClear } size="small" variant="contained" color="secondary">Clear Signature</Button>
      </ButtonArea>
      <FinePrintArea>
        <FinePrintDetails>
           <Checkbox value={consentChecked} onClick={handleCheck} /> By Checking This Box, I Submit By Penalty of Perjury That I Am {props.contact.firstName} {props.contact.lastName}, and I Agree to the Terms and Conditions of this Proposal.
          </FinePrintDetails>
      </FinePrintArea>
      <ButtonArea>
          <SubmitButton variant="contained" disabled={!signatureImg || !consentChecked || loading} onClick={handleSubmit} color="primary">
            Let's Do This!
            
            { loading && (<CircularProgress size={24}
            sx={{
              color: 'green',
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '10px',
            }} /> )}</SubmitButton>


             
      </ButtonArea>
      {/* { signatureImg ?
        <img style={{width: '50%'}} src={signatureImg} />
      : null
      } */}
    </div>
  )
}
const mapStateToProps = (state) => {
  // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
  }
}

export default connect(mapStateToProps)(SignatureTest)
