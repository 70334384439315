import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
// import moment from 'moment'
import SignatureBox from './SignatureBox'
import LineItemDetails from './OnlineProposalLineItems'
import ESignatureModal from './ESignatureModal'

const hmiColor = 'rgb(2 164 9)'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    backgroundColor: '#eee'
  },
  paper: {
    // width: '100%',
    marginTop: 10,
    marginBottom: theme.spacing(2),
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
      maxWidth: 960,
      textAlign: 'center'
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '50vh',
      maxWidth: 1360,
      textAlign: 'center'
    },
    // margin: 20
  },
  sigBox: {
    // backgroundColor: 'green',
    margin: 20,
    width: '100%',
    textAlign: 'center'
  },
  headerImageClass: {
    width: '100%'
  },
  headerImages: {
    marginTop: 5,
    width: '100%',
    overflow: 'hidden'
  },
  footerImages: {
    marginTop: 5,
    width: '100%',
    overflow: 'hidden'
  },
  disclosureArea: {
    fontSize: '0.8rem',
    color: '#bbb',
    textAlign: 'center'
  },
  headerText: {
    fontSize: 32,
    color: '#aaa',
    // color: 'rgb(119,156,164)',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 18,
      // color: 'red'
    },
    [theme.breakpoints.down('xs')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 14,
      // color: 'pink'
    },
    [theme.breakpoints.up('md')]: {
      // minHeight: '50vh',
      // maxWidth: 1360,
      // textAlign: 'center'
      fontSize: 24,
      // color: 'green'
    }
  },
  headerLogo: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '65%',
      maxHeight: '100px'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300,
      maxHeight: '80px'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '90%',
      maxHeight: '190px'
    }
  },
  giantText: {
    textAlign: 'center',
    fontSize: 48,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
    marginBottom: -15
  },
  subText: {
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
  },
  giantTextHMI: {
    textAlign: 'center',
    fontSize: 48,
    fontFamily: 'Amiri',
    color: hmiColor,
    marginBottom: -15
  },
  subTextHMI: {
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'Amiri',
    color: hmiColor,
  },
  squigglyText: {
    textAlign: 'center',
    fontSize: 42,
    fontFamily: 'Inspiration',
    color: 'rgb(119,156,164)',
  },
  signUpText: {
    marginTop: 15,
    fontFamily: 'Amiri',
    fontSize: 32,
    textAlign: 'center',
    color: 'rgb(119,156,164)',
  },
  signUpTextHMI: {
    marginTop: 15,
    fontFamily: 'Times',
    fontSize: 32,
    textAlign: 'center',
    color: hmiColor,
  },
  disclaimerText: {
    fontSize: 16,
    marginBottom: 30,
    marginTop: 15,
    color: '#aaa'
  },
  nameText: {
    fontSize: 24,
    marginTop: 10,
    color: '#aaa'
  },
  buttonHMI: {
    backgroundColor: hmiColor
  }
}));

function ViewOnlineProposal(props) {
  // console.log('View Online Proposal ', props)
  const [proposal, setProposal] = React.useState({})
  const [logoURL, setLogoURL] = React.useState(null)
  const [contact, setContact] = React.useState({})
  const [pdf, setPDF] = React.useState({})
  const [entity, setEntity] = React.useState({})
  const [showESigModal, setShowESigModal] = React.useState(false)
  React.useEffect(() => {
    let isMounted = true

    const fetchEstimate = async (estimateId, contactId) => {
      if (contactId === '[object Object]') {

      } else {
        let url = `${props.url}/api/proposal/getOnlineProposalDetails?estimateId=${estimateId}&contactId=${contactId}`
        // console.log('URL to retrieve: ', url)
        const response = await axios.get(url)
        if (response && response.data && isMounted) {
          // console.log('STUFF: ', response.data)
          setProposal(response.data.estimate)
          setContact(response.data.contact)
          const entityObj = response.data?.estimate?.entity
          setEntity(entityObj)
          let url = response.data.estimate.entity._id
          let logo = '/' + url + '_Logo_W_BestOfValleyChips.png'
          // <img src="/Logo_W_BestOfValleyCHips.png" />
          // console.log('URL: ', url)
          // console.log('Logo: ', logo)
          if (entityObj && entityObj.slugName && entityObj.slugName === 'hmi') logo = `${process.env.PUBLIC_URL}/${url}_Logo.png`
          setLogoURL(logo)
          let est = response.data.estimate
          if (est && est.url) {
            await axios.get(est.url, {
              withCredentials: false,
              headers: {
                'Content-Type': 'application/octet-stream',
                'Accept': 'application/pdf, application-octet-streatm'
              },
              responseType: 'blob'
            }).then((response) => {
            // }).then(response => response.arrayBuffer())
            //   .then(blob => {
            //   // console.log('Blob: ', blob)
                // let body = response.data
                // let base64Version = Buffer.from(body).toString('base64')
                // console.log('Base 64', base64Version)
                // // let blob = response.data.blob()
                // let newStuff = 'data:application/pdf;base64,' + base64Version
                // console.log(newStuff)
                // console.log('response:', response)
                let newBlob = new Blob([response.data], {type: "application/pdf"})
                // console.log(newBlob)
                const data = window.URL.createObjectURL(newBlob);
                // console.log(data)
                // var link = document.createElement('a');
                // link.href = data;
                // link.download="file.pdf";
                // link.click();
                setPDF(data)
              })
          }
          // setLogoURL(`../../../${url}_Logo.png`)
        }
      }
      // console.log('Get Estimate: ', estimateId)
      // console.log('Get Contact: ', contactId)
    }
    // console.log(props.history.match)
    // console.log(props.history.match.params)
    // console.log("Props.match", props.match.params)
    if (props.match && props.match.params && props.match.params.estimateId) {
      // console.log(props.match.params.estimateId)
      if (props.match && props.match.params && props.match.params.contactId) {
        fetchEstimate(props.match.params.estimateId, props.match.params.contactId)
      }
    }
    //  && props.history.match && props.history.match.params && props.history.match.params.estimateId) {
    // // console.log(props.history.match)
    // // console.log(props.history.match.params)
      
    // // console.log('Find estimate ', props.history.match.params.estimateId)
    // }
    return () => {
      isMounted = false
    }
  }, [])

  const classes = useStyles();

 const handleOpenESigModal = () => {
    setShowESigModal(true)
  }

  const handleCloseESigModal = () => {
    setShowESigModal(false)
  }

  const handleEsignatureSubmission = (name) => {
    // console.log('Handle esignature: ', name)
    setShowESigModal(false)
    let data = {
      estimateId: proposal._id,
      contactId: contact._id,
      name
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/proposal/approveEstimate`,
      data: data,
    }).then(response => {
      // console.log('Approved Estimate', response.data)
      if (response.data && response.data.estimate && response.data.estimate._id) {
         
          if (response.data.estimate.waiveDeposit) {
            props.notification({
              type: 'success',
              title: 'Proposal Accepted!',
              message: 'Thank you for accepting this proposal, we will be in touch soon.'
            })
          } else {
            props.notification({
              type: 'success',
              title: 'Proposal Accepted!',
              message: 'Thank you for accepting this proposal, no work will be started without a deposit being received by the office.'
            })
            props.history.push(`/estimate/postApproval/${response.data.estimate._id}/${contact._id}`, response.data)
          }
          // props.history.push(`/estimate/deposit/${response.data.estimate._id}/${contact._id}`, response.data)
          // return callback(null, response.data)
      }
      // // console.log('Axios response to add to takeoff inventory ', response)
      // if (response && response.data && response.data._id) {
      //   // props.addItem(response.data)
      //   props.notification({
      //     type: 'success',
      //     title: 'Item Added Successfully!',
      //     message: 'Thank you for helping us add to the database!'
      //   })
      // } else {
      //   props.notification({
      //     type: 'warning',
      //     title: 'Item Not Added!',
      //     message: 'There appears to be an issue, but not an error. Tell the humans Line 158 is the culprit'
      //   })
      // }

    }).catch(err => {
      // // console.log('Error caught when uploading pic: ', err)
      props.notification({
        type: 'warning',
        title: 'Failed to Approve Estimate!',
        message: err.message
      })
      // return callback(err)
    })

  }

  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  let headerImageURL = `${process.env.PUBLIC_URL}/OnlineProposalHeaderImage.png`
  let footerImage = `${process.env.PUBLIC_URL}/OnlineProposalFooterImage.png`
  let firstLine = 'BEAUTIFUL'
  let secondLine = 'OUTDOOR LIVING SPACES THAT BENEFIT'
  let thirdLine = 'The Mind, Body, And Soul'
  let isHMI = false
  if (entity && entity.slugName === 'hmi') {
    headerImageURL = `${process.env.PUBLIC_URL}/hmiHeader.png`
    firstLine = 'Large Enough to Meet Your Needs'
    secondLine = 'Small Enough to Care'
    thirdLine = ''
    isHMI = true
    footerImage = `${process.env.PUBLIC_URL}/hmiFooter.png`
  }
  return (
    <div className={classes.root}>
     <Paper className={classes.paper}>
       <ESignatureModal open={showESigModal} contact={contact} estimate={proposal} handleClose={handleCloseESigModal} submit={handleEsignatureSubmission} />
        <Grid container>
          
        <Grid item lg={12} xs={12} sm={12} md={12} style={{textAlign: 'center'}}>
          {/* { proposal._id ? <img alt="Logo" src={ logoURL}  style={{ marginTop: '5px', maxHeight: 100, maxWidth: 300}}/> : null } */}
            { proposal._id ? <img alt="Logo" src={ logoURL} className={classes.headerLogo} /> : null }
          </Grid>
          <Grid item lg={12} xs={12} className={classes.headerImages}>
        
            <img src={headerImageURL} className={classes.headerImageClass} />
            {/* <img src="/Logo_W_BestOfValleyCHips.png" /> */}
          </Grid>

          <Grid item lg={12} xs={12} sm={12} md={12} className={isHMI ? classes.giantTextHMI : classes.giantText} >
            {/* {(proposal && proposal.entity) ? proposal.entity.name : ''} is excited to propose to you a grand adventure
             */}
            {firstLine}
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={isHMI ? classes.subTextHMI : classes.subText} >
            {/* {(proposal && proposal.entity) ? proposal.entity.name : ''} is excited to propose to you a grand adventure
             */}
           {secondLine}
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.squigglyText} >
            {/* {(proposal && proposal.entity) ? proposal.entity.name : ''} is excited to propose to you a grand adventure
             */}
            {thirdLine}
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12}  className={classes.nameText}>
             Proposal #{proposal.number} - {proposal.name}
          </Grid>
          {
            (proposal.signed_at && proposal.url) ? (
              <object style={{padding: '15px', minHeight: '960px'}} aria-label="PDF" data={ pdf ? pdf : null } width="100%" type="application/pdf" />
            ) : (
              <React.Fragment>
                <Grid item lg={12} xs={12} sm={12} md={12} >
                  <LineItemDetails estimate={proposal}  />
            
                </Grid>
                <Grid item lg={12} xs={12} sm={12} md={12} className={isHMI ? classes.signUpTextHMI : classes.signUpText}>
                  
                  <strong>Total Price: {currencyFormat(proposal.totalPrice)} </strong> 
                
                </Grid>
                <Grid item lg={12} xs={12} sm={12} md={12} className={isHMI ? classes.signUpTextHMI : classes.signUpText}>
                  
                <Button variant="contained" color="primary" className={isHMI ? classes.buttonHMI : null} onClick={handleOpenESigModal}>
                  Let's Get Started!</Button>
                
                </Grid>
                <Grid item lg={12} xs={12} sm={12} md={12} className={classes.disclaimerText}>
                  {proposal.entity ? proposal.entity.name : ''} is proud to present to your a digital version of your proposal ready for you to accept in just seconds by clicking the button above. 
                </Grid>
              </React.Fragment>
            )
          }

          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.footerImages}>
            <img src={footerImage} width="100%"/>
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.disclosureArea}>
            This proposal was intended to be viewed by {(contact && contact._id) ? `${contact.firstName} ${contact.lastName}` : ''}, if you were sent this link inadvertantly please close the browser now.
          </Grid>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.disclosureArea}>
            Copyright { proposal.entity ? proposal.entity.name : ''} { new Date().getFullYear()}, All Rights Reserved.
          </Grid>
        </Grid>
     </Paper>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(ViewOnlineProposal)

