import React, { useState } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TextField
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  datePicker: {
    marginTop: theme.spacing(1),
  },
}));

const ServiceScheduler = ({ servicesRemaining, updateDate }) => {
  const classes = useStyles();
  const [scheduledDates, setScheduledDates] = useState({});

  const getDefaultDate = (service) => {
    const year = service.year;
    if (service.type === 'month') {
      // Default to the 15th of the month
      return new Date(year, new Date(`${service.name} 1, 2000`).getMonth(), 15);
    } else if (service.type === 'quarter') {
      // Default to the middle of the quarter
      const quarterStartMonth = (parseInt(service.name[1]) - 1) * 3;
      return new Date(year, quarterStartMonth + 1, 15);
    }
  };

  const handleDateChange = (service, date) => {
    setScheduledDates(prev => ({
      ...prev,
      [`${service.type}-${service.name}-${service.year}`]: date
    }));
  };

  const handleClickOnFutureScheduleDate = (service) => {
    console.log('Click on this one', service)
    let date = scheduledDates[`${service.type}-${service.name}-${service.year}`] || getDefaultDate(service)
    console.log('This is teh date', date)
    updateDate({ date, service })
  }

  return (
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="service scheduling table">
          <TableHead>
            <TableRow>
              <TableCell>Service Period</TableCell>
              <TableCell>Year</TableCell>
              <TableCell>Scheduled Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {servicesRemaining.map((service, index) => (
              <TableRow key={index} style={{ cursor: 'pointer' }} onClick={e => handleClickOnFutureScheduleDate(service) }>
                <TableCell component="th" scope="row">
                  {service.name}
                </TableCell>
                <TableCell>{service.year}</TableCell>
                <TableCell>
                  <DatePicker
                    disableToolbar
                    variant="inline"
                    format="MM/dd/yyyy"
                    margin="normal"
                    id={`date-picker-${index}`}
                    label="Schedule Service"
                    value={scheduledDates[`${service.type}-${service.name}-${service.year}`] || getDefaultDate(service)}
                    onChange={(date) => handleDateChange(service, date)}
                    className={classes.datePicker}
                    autoOk={true}
                    disablePast
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default ServiceScheduler;