import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, InputLabel, Select, FormControl,
  DialogContentText
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AddNewOptionModal from '../Inventory/options/AddNewOptionModal'

const filter = createFilterOptions();
const handleFocus = (event) => event.target.select();

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const yardOptions = [
  'New River',
  'Cave Creek'
]

const areaOptions = [...Array(21).keys()].slice(1)

const defaultStateValues = {
  fromYard: '',
  toYard: '',
  items: []
}

const TransferInventory = ({ url, open, handleClose, takeoffs, handleSave }) => {
  // console.log('Add Lot INventory modal', takeoffs)
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState(defaultStateValues)

  const handleSubmit = () => {
    console.log('Submit this...', state)
  }

  const handleChange = (e, type) => {
    console.log('%s -- %s', type, e.target.value)
    setState({...state, [type]: e.target.value })
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth aria-labelledby="form-dialog-title">
     <DialogContent>
      <DialogContentText>Transfer Inventory</DialogContentText>
      <TextField
        id="fromLot"
        label="Lot Transfer From"
        type="text"
        style={{marginBottom: '10px'}}
        variant="outlined"
        fullWidth
        select
        value={state.fromYard}
        onChange={e => handleChange(e, 'fromYard')}
        // disabled={!selectedOption}
        helperText="Which Nursery Are We Transferring From?"
      >
                  {
            [...yardOptions].map((yard, index) => {
              return (
                <MenuItem key={index} value={yard}>{yard}</MenuItem>
              )
            })
          }
        </TextField>
        
      <TextField
        id="toLot"
        label="Lot Transfer To"
        type="text"
        select
        style={{marginBottom: '10px'}}
        variant="outlined"
        fullWidth
        value={state.toYard}
        onChange={e => handleChange(e, 'toYard')}
        // disabled={!selectedOption}
        helperText="Which Nursery Are We Transferring To?"
      >          {
        [...yardOptions].map((yard, index) => {
          return (
            <MenuItem key={index} value={yard}>{yard}</MenuItem>
          )
        })
      }
    </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TransferInventory;
