import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const CampusExits = (props) => {
// console.log('Campus Exits: ', props)
  if (props.data && props.data.exitsCampus && props.data.exitsCampus.length) {
    let data = props.data.exitsCampus
  // console.log('DATA: ', data)
    return data.map((item, index) => {
    // console.log('EXITS: ', item)
      return (
        <div key={index}>{moment(item.created_at).format('MM/DD/YYYY  hh:mm a')}</div>
      )
    })

  } else {
    return <div>N/A</div>
  }

}

const CampusReturns = (props) => {
  // console.log('Campus Return: ', props)
  if (props.data && props.data.returnsToCampus && props.data.returnsToCampus.length) {
    let data = props.data.returnsToCampus
  // console.log('DATA: ', data)
    return data.map((item, index) => {
    // console.log('EXITS: ', item)
      return (
        <div key={index}>{moment(item.created_at).format('MM/DD/YYYY  hh:mm a')}</div>
      )
    })

  } else {
    return <div>N/A</div>
  }

}

const Breaks = (props) => {
// console.log('Breaks ', props)
  if (props.data && props.data.breaks && props.data.breaks.length) {
    let data = props.data.breaks
  // console.log('DATA: Breaks', data)
    return data.map((item, index) => {
    // console.log('IND Break ', item)
      return (
        <div key={index}>{moment(item.start).format('MM/DD/YYYY  hh:mm a')}  - {item.end ? moment(item.end).format('MM/DD/YYYY  hh:mm a') : ' '}</div>
      )
    })

  } else {
    return <div>N/A</div>
  }

}

const TimeToEdit = (props) => {
  // console.log('Time to Edit: ', props)
  const [state, setState] = React.useState({
    clockIn:  '',
    clockOut: '',
    hoursWorked: '',
    clockInTime: '12:00 am',
    breaks: []
  })

  React.useEffect(() => {
  // console.log(props.data)
    let clockIn = moment(props.data.clockIn).local()
    let clockOut = moment(props.data.clockOut).local()
  // console.log('Clock In: ', clockIn)
    setState({...state, clockIn: clockIn, clockOut: clockOut, hoursWorked: props.data.hoursWorked, breaks: props.data.breaks })
  }, [props])

  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleSubmit = () => {
  // console.log('Handle Submit', state)
    let clockIn = state.clockIn
    let clockOut = state.clockOut
    if (clockOut < clockIn) {
      // if (window.confirm('Your Clock-Out Time Is Before the Clock-In Time...Should We Reverse Them?')) {
      // console.log('Reverse em!')
        setState({...state, clockIn: clockOut, clockOut: clockIn})
        props.notification({
          type: 'warning',
          title: `Uh Oh - That Doesn't Seem Right!`,
          message: 'Your Clock-In Time Was After The Clock-Out - So We Reversed Them!'
        })
    }
    let obj = {
      shiftId: props.data._id,
      data: state,
      user: props.user
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/employees/updateEmployeeTimeEntry`,
      data: obj,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Axios response to update Time Entry ', response)
    // Add employee onto the responded shift
      props.updateParent('saved', response.data)
    })
  }

  const handleTimeChange = (type, value) => {
  // console.log('Type: ', type)
    let isValid = moment(value, 'YYYY-MM-DDThh:mm').isValid()
  // console.log('Valid? ', isValid)
    if (isValid) {
    // console.log('Value: ', value)
      let clockIn = null
      let clockOut = null
      if (type === 'clockIn') {
        clockIn =  moment(value, 'YYYY-MM-DDThh:mm').local()
        clockOut = moment(state.clockOut, 'YYYY-MM-DDThh:mm').local()
      }
      if (type === 'clockOut') {
        clockIn = moment(state.clockIn, 'YYYY-MM-DDThh:mm').local()
        clockOut = moment(value, 'YYYY-MM-DDThh:mm').local()
      }
    // console.log('Clock IN', clockIn)
    // console.log('Clock out: ', clockOut)
      let hoursWorked = Math.abs(clockIn - clockOut) / 36e5
    // console.log('Hours WOrked: ', hoursWorked)
      hoursWorked = parseFloat(hoursWorked).toFixed(2)
    // console.log(hoursWorked)
      // if (clockOut < clockIn && state.weWantThis) {
      //   if (window.confirm('Your Clock-Out Time Is Before the Clock-In Time...Should We Reverse Them?')) {
      //   // console.log('Reverse em!')
      //     setState({...state, clockIn: clockOut, clockOut: clockIn, hoursWorked: hoursWorked})
      //     props.notification({
      //       type: 'warning',
      //       title: `Uh Oh - That Doesn't Seem Right!`,
      //       message: 'Your Clock-In Time Was After The Clock-Out - So We Reversed Them!'
      //     })
      //   } else {
      //     setState({...state, clockIn: clockIn, clockOut: clockOut, hoursWorked: 0})
      //   }
      // } else {
        setState({...state, clockIn: clockIn, clockOut: clockOut, hoursWorked: hoursWorked})
      // }
    }
  }

return (
  <div>
    
      <Dialog
        // fullScreen={false}
        // maxWidth={'sm'}
        // width='lg'
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Modify Time</DialogTitle>
        <DialogTitle>{ props?.data?.employee?.displayName }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Adjust The Shift As Needed:
          </DialogContentText>
          <TextField
              id="checkedIn"
              label="Checked-In"
              type="text"
              disabled
              value={moment(props.data.checkIn).format('MM/DD/YYYY  HH:mma') } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              // onChange={e => setState({...state, hoursWorked: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />
            <TextField
              id="date"
              label="Clock-In"
              type="datetime-local"
              value={moment(state.clockIn).format('YYYY-MM-DDTHH:mm') } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              onChange={e => handleTimeChange('clockIn', e.target.value)}
              // onChange={e => setState({...state, clockIn: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />

           <TextField
              id="date"
              label="Clock-Out"
              type="datetime-local"
              fullWidth
              value={moment(state.clockOut).format('YYYY-MM-DDTHH:mm') } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              // onChange={e => setState({...state, clockOut: e.target.value })}
              onChange={e => handleTimeChange('clockOut', e.target.value)}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              style={{marginBottom: '20px'}}
            />

            <TextField
              id="hoursWorked"
              label="Hours Worked"
              type="text"
              disabled
              value={state.hoursWorked } // "2017-05-24"}
              // className={classes.textField}
              variant="outlined"
              // onChange={e => setState({...state, hoursWorked: e.target.value })}
              // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{marginBottom: '20px'}}
            />

            <TextField
                id="notes"
                label="Notes"
                multiline
                rows={3}
                variant="outlined"
                fullWidth
                // className={classes.textSaveLineBreaks}
                value={state.notes}
                onChange={e => setState({...state, notes: e.target.value})}
                // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                // defaultValue="Default Value"
            />
            <div style={{margin: 5}}>Campus Exits: <CampusExits data={props.data} /> </div>
            <div style={{margin: 5}}>Campus Returns: <CampusReturns data={props.data} /> </div>
            <div style={{margin: 5}}>Breaks <Breaks data={props.data} /> </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit()} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(TimeToEdit))