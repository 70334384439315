import React from 'react'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import NewContactModal from './NewContactModal'
import EditContactModal from './EditContactModal'
import axios from 'axios'
import Chip from '@material-ui/core/Chip';
import { setContacts } from '../../actions/connectionActions'

const ContactList = (props) => {
  // console.log('Contact List: ', props)
  const [newContactModalOpen, setNewContactModalOpen] =  React.useState(false)
  const [editContactModalOpen, setEditContactModalOpen] =  React.useState(false)
  const [contacts, setContacts] = React.useState([])
  const [activeContact, setActiveContact] = React.useState({})
  const [state, setState] = React.useState({
    contacts: []
  })
  
  React.useEffect(() => {
    // console.log('Get Contacts: ')
    async function getContacts() {
      // console.log('Get our CONTACTS')
      const response = await axios.get(`${props.url}/api/customer/getContacts?id=${props.client._id}`)
    // console.log('CONTACTS LOADED!!!', response)
      if (response && response.data) {
        setContacts(response.data)
        props.setContacts(response.data)
        setState({...state, contacts: response.data})
        // props.updateContacts(response.data)

      }
    }
    if (props.client && props.client._id) getContacts()
  }, [props.client])

  const handleEditContact = (contact, index) => {
    // console.log('Open Edit Contact Modal', contact)
    setEditContactModalOpen(true)
    setActiveContact(contact)
  }

  const handleAddContact = () => {
    // console.log('Add Contact')
    // Open New Contact Modal
    setNewContactModalOpen(true)
  }

  const saveContact = (newContact) => {
    // console.log('Save this contact!', newContact)
    let newContacts = state.contacts
    newContacts.push(newContact)
    setState({...state, contacts: newContacts})
    setNewContactModalOpen(false)
  }

  const updateContact = (contact) => {
    // console.log('Update this contact', contact)
    let newContacts = state.contacts
    let index = state.contacts.findIndex(item =>  (contact._id === item._id));
    // let ourContact = state.contacts[index]
    // console.log('Contact to update', ourContact)
    newContacts[index] = contact
    setState({...state, contacts: newContacts})
    setEditContactModalOpen(false)
  }

  const closeNewContactModal = () => {
    setNewContactModalOpen(false)
  }

  const closeEditContactModal = () => {
    setEditContactModalOpen(false)
  }
  const handleDeleteContact = (contact, index) => {
    // setLoading(true)
    // console.log('handle save new contact', state)
    if (window.confirm('Are you sure you wish to delete this contact? This is not reversible.')) {
      // console.log(props)
      let updatedContacts = contacts
      // console.log('Contacts', updatedContacts)
      updatedContacts.splice(index, 1)
      // console.log('Updated Contacts: ', updatedContacts)
      // setContacts(updatedContacts)
      setState({...state, contacts: updatedContacts})
      let obj = {
        contact: contact,
        user: props.user
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/customer/deleteContact`,
        data: obj,
        // validateStatus: (status) => {
        //   // console.log('Validate status: ', status)
        //   if (status && status === 500) {
    
        //   }
        // },
      }).then(response => {
        // console.log('Axios response to delete Contact: ', response)
        // setLoading(false)
        props.notification({
          type: 'success',
          title: 'Contact Deleted!',
          message: `You'll never have to think about ${contact.firstName} again!`
        })
      }).catch(err => {
        // console.log('Err: ', err)
      })
    }
  }
  // const PhoneList = (props) => {
  //   const phones = props.phoneNumbers
  //   let html = '<div>'
  //   phones.map((phone, index) => {
  //     html += `<Grid container`
  //   })
  // }
  return (
    <div>
      <NewContactModal saveContact={saveContact} client={props.client} open={newContactModalOpen} closeModal={closeNewContactModal} />
      <EditContactModal updateContact={updateContact} client={props.client} contact={activeContact} open={editContactModalOpen} closeModal={closeEditContactModal} />

       <Box display="flex" flexDirection="row" justifyContent="space-around" alignContent="center">
        <Box flexGrow={3} alignSelf="flex-start" style={{fontSize: '20px'}}>
          Contacts &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Tooltip title="Add Contact" >
                  <Button  
                    variant="contained"
                    color="primary"
                    size="small"
                    alt="Add Contact"
                    style={{fontSize: '10px'}}
                    onClick={handleAddContact}
                  >
                  Add 
                  </Button>
                </Tooltip>
        </Box>
       </Box>
       <Box display="flex" flexDirection="row" bgcolor="background.paper"  flexWrap="wrap" >
         { state.contacts.map((contact, index) => (
           <Box width={220} key={index} m={1} bgcolor="grey.200" >
              <Grid container>
                <Grid item lg={12} xs={12} style={{fontSize: '16px'}}>
                  {contact.firstName} {contact.lastName}
           
                </Grid>
                <Grid item lg={12} xs={12} style={{fontSize: '14px'}}>
                  {contact.email} 
                  {/* { contact.emailAddresses.map((email, keyIndex) => {
                    return (
                      <Grid key={keyIndex} item >
                        {email.address} 
                      </Grid>
                    )
                  })} */}
                </Grid>
                <Grid item lg={12} xs={12} style={{fontSize: '8px'}}>
                  {contact.receiveInvoices ? <Chip style={{fontSize: '8px', color: '#fff', background: '#2a9e0d' }} variant="outlined" size="small" label="Invoices" /> : ''}
                  {contact.receiveStatements ? <Chip style={{fontSize: '8px', color: '#fff', background: '#2a9e0d' }} variant="outlined" size="small" label="Statements" /> : ''}
                  {contact.receiveMarketing ? <Chip style={{fontSize: '8px', color: '#fff', background: '#2a9e0d' }} variant="outlined" size="small" label="Marketing" /> : ''}
                </Grid>
                <Grid item style={{fontSize: '12px'}}>
                  { contact.phoneNumbers.map((phone, keyIndex) => {
                    return (
                      <Grid key={keyIndex} item >
                        {phone.number} - {phone.type}
                      </Grid>
                    )
                  })}
                </Grid>
                <Grid item lg={12} xs={12} style={{fontSize: '12px'}}>
                  <div style={{overflowWrap: 'break-word', wordWrap: 'break-word', width: '100%'}} >
                    {contact.notes}
                    </div>
                </Grid>
                <Grid item lg={12} xs={12} style={{fontSize: '14px', whiteSpace: 'noWrap'}}>
                <Tooltip title="Edit Contact">
                  <Button  
                    variant="contained"
                    color="secondary"
                    size="small"
                    style={{fontSize: '8px'}}
                    onClick={() => handleEditContact(contact, index)}
                  >
                  Edit 
                  </Button>
                </Tooltip>
                <Tooltip title="Delete Contact">
                  <Button  
                    variant="contained"
                    size="small"
                    alt="Delete Contact"
                    onClick={() => handleDeleteContact(contact, index)}
                    style={{fontSize: '8px', color: 'white', marginLeft: '3px', backgroundColor: 'red'}}
                  >
                  Delete 
                  </Button>
                </Tooltip>
                </Grid>
              </Grid>
          </Box>
         ))}
      </Box>
    </div>
  )
}
  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }

  const mapDispatchToProps = (dispatch) => {
  // console.log('Set our CONTACTS!!!!!!!', dispatch)
    return {
      setContacts: (contacts) => { dispatch(setContacts(contacts)) }
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(ContactList)
