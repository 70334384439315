import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import styles from './customer.module.scss'
import Grid from '@material-ui/core/Grid'
import { Button, CircularProgress, TextField, InputAdornment } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import Chip from '@material-ui/core/Chip';
import SearchIcon from '@material-ui/icons/Search';

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'customerName', numeric: false, disablePadding: false, label: 'Customer' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'entity', numeric: false, disablePadding: false, label: 'Entity' },
  { id: 'details', numeric: false, disablePadding: false, label: 'Details' },
];

function EnhancedTableHead(props) {
  // console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort, num } = props;
  const createSortHandler = property => event => {
    // console.log('Sort Property: ', property)
    // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.center ? 'center' : headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    overflowX: 'scroll',
    minHeight: '40vh',
    maxHeight: '65vh'
  },
  tableWrapper: {
    overflowX: 'scroll',
    minHeight: '40vh',
    maxHeight: '65vh'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue',
    fontSize: '1.2em'
  },
  rowContainer: {
    // border: '3px solid red',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rowItem: {
    // border: '2px solid blue',
    fontSize: '1.25em',
    padding: 5,
    margin: 5
    // marginRight: 15
  },
  rowImage: {
    // border: '1px solid green',
    display: 'flex',
    alignSelf: 'stretch'
    // margin: -10,
    // padding: -5,
  },
  alphabetNav: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    margin: 4,
    overflowX: 'auto'
  },
  alphabetButton: {
    margin: 2,
    padding: 1,
    cursor: 'pointer',
    border: '2px solid #ccc'
  },
  selectedAlphabetButton: {
    margin: 2,
    padding: 1,
    cursor: 'pointer',
    backgroundColor: '#accacd',
    border: '2px solid #516e76'
  }
}));

const ContactDashboard = (props) => {
  const classes = useStyles()
  const [contacts, setContacts] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [searchValue, setsearchValue] = React.useState('');
  const [filteredRows, setFilteredRows] = React.useState([])

  React.useEffect(() => {
    let isMounted = true
    // console.log('Props.location has changed...', props)
    // let cachedData = sessionStorage.getItem('customerData')
    // console.log('Cached data ', cachedData)
    // cachedData = JSON.parse(cachedData)
    // console.log('Cached Data:', cachedData)
    // if (cachedData && cachedData.list && cachedData.list.length) {
    // // console.log('We have cached data to use!!')
    //   let timeSince = Date.now() - cachedData.timestamp
    // // console.log('Time Since:', timeSince)
    //   if (isMounted) {
    //     setLoading(false)
    //     setClients(cachedData.list)
    //     setState({...state, clients: cachedData.list})
    //   }
    //   if (timeSince > 30000) {
    //   // console.log('Refresh this shit')
    //     fetchData(null, true)
    //   }
    // } else {
    //   fetchData()
    // }
    async function fetchData(toReturn, noLoad) {
      // console.log('Fetch our clients')
      if (!noLoad) setLoading(true)
      axios.get(`${props.url}/api/customer/getContacts`).then((response, status, data) => {
        // console.log(response)
        // console.log(status)
        // console.log(data)
        console.log('Response.data', response.data)
        if (response && response.data && response.data.length) {
          if (isMounted) {
            setLoading(false)
            // setClients(response.data.clients)
            setContacts(response.data)
            setFilteredRows(response.data)
          }
          // if (toReturn) {
          //   return response.data.clients
          // } else {
          //   if (isMounted) {
          //     setLoading(false)
          //     setClients(response.data.clients)
          //     setState({...state, clients: response.data.clients, configs: response.data.configs })
          //   }
            
          // }
        } else {
          // console.log('Fucked up', response)
          if (response === 'Unauthorized') props.history.push('/restricted')
        }
      }).catch((error, status) => {
        // console.log('Error', error)
        // console.log(status)
      })
  
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      filterOut(contacts, null)
    } else {
      filterOut(contacts, event.target.value)
    }
    setPage(0)
  }

  const filterOut = (r, searchText) => {
    if (!searchText) return contacts;
    let rowsToUse = r || contacts
    let newRows = rowsToUse.filter(client => {
      let matchesSearch = searchText
        ? Object.values(client).some(value =>
            value?.toString().toLowerCase().includes(searchText.toLowerCase())
          )
        : true;
      // console.log('Matches Search', matchesSearch)
      return matchesSearch;
    });
    setFilteredRows(newRows)
  }


  return (
    <div className={ styles.root }>
     <Paper>
      <Grid container>
        <Grid item lg={6} xs={12}>
          <h1>Contact Dashboard</h1>
        </Grid>
        <Grid item lg={3} xs={6}>
         <div className={classes.search}>
                {/* <div >
                  <SearchIcon /> Search { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
                </div> */}
                {/* <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input> */}
                <TextField
                    name="search" 
                    value={ searchValue } 
                    onChange={ handleChange('search') }
                    label="Search"
                    type="search"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                />
                {/* <div className={classes.fakeLink} onClick={toggleDeepSearch}>Go Deeper</div> */}
              </div>
            
        </Grid>
        <Grid item lg={3} xs={6}>
         
        </Grid>
        <Grid item lg={12} xs={12}>
          
        </Grid>
        <Grid item lg={12} xs={12}>
          <ContactTable
            filteredRows={filteredRows}
            contacts={contacts}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            order={order}
            setOrder={setOrder}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            page={page}
            setPage={setPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleChangePage={handleChangePage}
            dense={dense}
            loading={loading}
            setLoading={setLoading}
            handleRequestSort={handleRequestSort}
          />
        </Grid>
      </Grid>

    </Paper>
  </div>
  )
}

const ContactTable = ({
  filteredRows,
  rowsPerPage,
  setRowsPerPage,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  page,
  setPage,
  handleChangeRowsPerPage,
  handleChangePage,
  dense,
  loading,
  setLoading,
  handleRequestSort,
  contacts
}) => {
  console.log('Contact Table', filteredRows)
  console.log('Contacts...', contacts)
  const classes = useStyles()
  
  return (
    <div>
    <div className={classes.tableWrapper} style={{margin: '10px'}}>
    <Table
      className={classes.table}
      aria-labelledby="tableTitle"
      size={dense ? 'small' : 'medium'}
      aria-label="enhanced table"
      stickyHeader
    >
      <EnhancedTableHead
        classes={classes}
        order={order}
        orderBy={orderBy}
        // onSelectAllClick={handleSelectAllClick}
        onRequestSort={handleRequestSort}
        // rowCount={rows.length}
      />
      <TableBody>
        { stableSort(filteredRows, getSorting(order, orderBy))
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row, index) => {
            console.log('Row map: ', row)
            // console.log('Total rows; ', rows)
            // const isItemSelected = isSelected(row._id);
            const labelId = `enhanced-table-checkbox-${index}`
            return (
              
              <TableRow
                hover
                // onClick={event => handleClick(event, row._id)}
                role="checkbox"
                // aria-checked={isItemSelected}
                tabIndex={-1}
                key={row._id}
                // selected={isItemSelected}
              >
                <TableCell component="td" id={labelId} scope="row" padding="none">
                     <Link to={{
                          pathname: '/clients/' + row.client + '/Customer/' + row.client,
                        }}
                        target="_blank"
                        >
                          {
                            `${row.name} (${row.agId})`
                          }
                        
                      </Link> 
          
                </TableCell>
                <TableCell component="td" id={labelId} scope="row" padding="none">
                  {row.customerName}
                </TableCell>

                

                <TableCell align="left">{ row.email } </TableCell>
                <TableCell align="left" >
                  <div  >
                    <div >
                      <img alt="Logo" src={`${process.env.PUBLIC_URL}/${row.entity}_Logo.png`} style={{margin: '5px', maxWidth: 170, maxHeight: 40}} />
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  {row.HOA ? <Chip style={{color: '#fff', background: '#f1c40f' }} size="small" label="HOA" /> : ''}
                  {row.contractor ? <Chip style={{color: '#fff', background: '#f7d509' }} size="small" label="Contractor" /> : ''}
                  {/* {row.paperInvoice ? <Chip style={{color: '#fff', background: '#013466' }} size="small" label="Paper Invoice" /> : ''} */}
                  {row.doNotEmail ? <Chip style={{color: '#fff', background: '#ef0000' }} size="small" label="Do NOT Email" /> : ''}
                  {row.autopayMTC ? <Chip style={{color: '#fff', background: '#4cb929' }} size="small" label="Autopay (Maintenance)" /> : ''}
                  {row.autopayAll ? <Chip style={{color: '#fff', background: '#33a330' }} size="small" label="Autopay (All)" /> : ''}
                  {row.active ? '' : <Chip key={'deactive'} style={{background: `rgba(223,192,111, .3)` }} size="small" label="Inactive" /> }
                  {row.activeMtc ? <Chip style={{color: '#fff', background: '#2ca932' }} size="small" label="Active MTC" /> : ''}
                  {!row.collectionNotifications ? <Chip style={{color: '#fff', background: '#a86032' }} size="small" label="No Collections" /> : ''}
                    &nbsp;&nbsp;&nbsp;

                  
                </TableCell>
              </TableRow>
            );
          })
        }

      </TableBody>
    </Table>
    { loading ?<CircularProgress style={{color: '#fff'}} size={ 16 } /> : ''}
    </div>
    <TablePagination
      rowsPerPageOptions={[10, 20, 50, 100, 500, 1000, 2000]}
      component="div"
      count={filteredRows.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
    />
                  
    </div>
  )
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      configs: state.configs
    }
  }
  
  // const mapDispatchToProps = (dispatch) => {
  //   // console.log('Dispatch----', dispatch)
  //   return {
  //     setSalesReps: (salesReps) => { dispatch(setSalesReps(salesReps)) },
  //     setEmployees: (employees) => { dispatch(setEmployees(employees)) }
  //   }
  // }

  // export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Customer))
  export default React.memo(connect(mapStateToProps)(ContactDashboard))
