import React from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import moment from 'moment'
import { currencyFormat, currencyFormatWithOutFlair } from '../../../services/common'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import AddNewOptionModal from '../../Inventory/options/AddNewOptionModal'

const handleFocus = (event) => event.target.select()

const ConfigurationContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
align-content: center;
text-align: center;
border-radius: 15px;
margin-top: 25px;
width: 100%;
height: 100%;
`;

const DetailsArea = styled.div`
  border: 2px solid #ccc;
  border-radius: 15px;
  padding: 20px;
  margin: 10px;
  margin-top: 25px;
  width: 100%;
  height: 100%;
`;

const LabelText = styled.div`
  color: #222;
  font-size: 16px;
  text-align: left;
  margin: 5px;
`;

const defaultValues = {
  qty: 0,
  unit: '',
  description: '',
  totalPrice: 0,
  costPer: 0,
  pricePer: 0,
  counter: 0,
  editing: false,
  inventoryItemId: null,
  optionId: null,
  option: {},
  item: {}
}

const ConfigureItem = (props) => {
console.log('CONFIGURE ITEM42: ', props)
  const [state, setState] = React.useState(defaultValues)

  React.useEffect(() => {
    console.log('Choose Material Options UseEffect', props)
    if (props.item && props.item._id) updateOurItemIfNeeded(props.item)
  }, [props.item])

  const updateOurItemIfNeeded = async (item) => {
    console.log('If Item doesnt match state - update it...', item)
    console.log('Props.item', props.item)
    console.log('state', state)
    if (state.item === item) {
      console.log('It all matches!!!')
    } else {
      console.log('Item doesnt match')
    }
      // console.log('CONFIG INPUT USEEFFECT - Set Quantity and shit here', props)
      let newState = {}
      let templatedDescription =null
      if (props.service && props.service.service && props.service.service.lineItemTemplate) {
        templatedDescription = props.service.service.lineItemTemplate
      }
      if (props.item && props.item.templatedDescription) templatedDescription = props.item.templatedDescription
    // console.log('Templated Description: ', templatedDescription)
      if (props.item && props.item._id) {
        console.log('132 **** has _Id')
        if (props.item && props.item.inventoryItemId && props.item.optionId) {
          console.log('This is a new style inventory item....', props.item)
          newState = { ...props.item }
        } else if (props.item._id.includes('uuid')) {
          console.log('134 isnt an inventory item...****')
          console.log('We have an item to edit!!', props.item)
          let qty = 0
          let pricePer = 0
          let costPer = 0
          let totalPrice = 0
          if (props.item.qty) qty = props.item.qty
          if (props.item.pricePer) pricePer = Number(props.item.pricePer)
          if (props.item.costPer) costPer = Number(props.item.costPer)
          // if (props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length === 1) {
          // pricePer = Number(costPer * 1.4).toFixed(2)
          totalPrice = Number(props.item.totalPrice)
        // console.log('Set QTY: %d - - Cost: %d    Price: %d   Total: %d', qty, costPer, pricePer, totalPrice)
          newState.qty = qty
          newState.unit = props.item.unit
          newState.description = props.item.description
          newState.costPer = costPer
          newState.pricePer = pricePer
          newState.totalPrice = totalPrice
          newState.templatedDescription = templatedDescription
          newState.editing = true
        // console.log('New State', newState)
        // console.log('Total Price', totalPrice)
        // console.log('Newstate total price', newState.totalPrice)
        } else {
          console.log('156 Inventory Item ****', props)
          // If editing
          // console.log('is this editing??', props.editing)
          let totalPrice = 0
          let pricePer = 0
          let costPer = props.item.price || 0
          pricePer = Number(costPer * 1.4).toFixed(2)
          if (props.item.optionId) {
            // console.log('Set price per based on optionId', props.item.optionId)
            const opt = props.item.options.find(opt => (opt._id.toString() === props.item.optionId))
            // console.log('opt 142', opt)
            if (opt && opt._id && opt.msrp) pricePer = Number(opt.msrp)
          }
          totalPrice = Number(pricePer * state.qty).toFixed(2)
          // newState.qty = props.state.qty
          // newState.unit = props.state.unit
          newState.description = props.item.description
          newState.costPer = costPer
          newState.pricePer = pricePer
          newState.totalPrice = totalPrice
          // newState.templatedDescription = templatedDescription
          newState.inventoryItemId = props.item._id
        // console.log('New State::', newState)
        }
      }
      
    // console.log('**** ***   UseEffectCurrent State', state)
      
      console.log('UseEffect State Update', newState)
      setState({...state, ...newState })
  }
  // const handleSaveNewLineItem = () => {
  //   console.log('Save Line Item', state)
  //   // Add this to our Estimate
  //   let itemToSend = {
  //     ...state
  //   }
  //   itemToSend.item = props.item
  //   // setState({...state, ...defaultValues })
  //   props.addLineItemToEstimate(itemToSend)
  // }

  const handleUpdateExistingLineItem = () => {
  console.log('Update  Line Item', state)
    let itemToSend = {
      ...state
    }
    if (props.item._id && !props.item._id.includes('uuid')) {
      console.log('Add our inventoryI tem id', props.item._id)
      // itemToSend.inventoryItemId = props.item._id
      itemToSend.invItem = props.item._id
      itemToSend.inventoryItemId = props.item._id
    } else {
      console.log('No uuid present...??')
    }
    // itemToSend._id = props.item._id
    // itemToSend.inventoryItemId = state.inventoryItemId
    // console.log('Send this: ', itemToSend)
    // console.log('State: ', state)
    props.updateLineItemOnEstimate(itemToSend)
  // console.log('SetState 382')
    // setState({...state, ...defaultValues })
  }

  // const handleFocus = (e) => {
  //   e.preventDefault()
  //   e.target.focus()
  //   e.target.setSelectionRange(0, e.target.value.length)
  // }

  const handleCostChangeUpdate = (e) => {
    let pricePer = 0
    let totalPrice = 0
    let costPer = e.target.value
    pricePer = Number(costPer * 1.4).toFixed(2)
    totalPrice = Number(pricePer * state.qty).toFixed(2)
  // console.log('SetState 398')
    setState({...state, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice})
    
  }

  const handlePriceManualChange = (e) => {
    let pricePer = e.target.value
    let totalPrice = 0
    totalPrice = Number(pricePer * state.qty).toFixed(2)
  // console.log('SetState 406')
    setState({...state, pricePer: pricePer, totalPrice: totalPrice})
  }

  const handleQtyUpdate = (e) => {
    let pricePer = state.pricePer
    let totalPrice = 0
    totalPrice = Number(pricePer * e.target.value).toFixed(2)
  // console.log('SetState 414')
    setState({...state, qty: e.target.value, totalPrice: totalPrice})
  }

  const handleUpdateItem = (data, isEditing) => {
  console.log('Update Estimate line item', data)
  console.log('New or edit', isEditing)
    if (isEditing) {
    console.log('EDIT!!!!')
    }
    // if (!data.inventoryItemId && data.option.takeOff)  data

      let newState = {...state, ...data}
      newState.qty = parseInt(data.qty)
      newState.option = data.option
      newState.optionId = data.option._id
      newState.unit = data.option.size

      newState.costPer = data.costPer
      newState.totalPrice = data.totalPrice
      newState.pricePer = data.pricePer
      newState.inventoryItemId = data.inventoryItemId
      newState.description = data.description
    // console.log(newState)
      setState({...state, ...newState})
      console.log('Edited Item to save:', newState)

    setState({...state, ...newState})
    if (isEditing) {
      console.log('Editing...update NOT add')
      props.updateLineItemOnEstimate(newState, isEditing)
    } else {
      props.addLineItemToEstimate(newState, isEditing)
    }
  }

  const handleAddNewOption = (itemWithAddedOption) => {
    // console.log('Add new option', itemWithAddedOption)
    // console.log('Current state', state)
    // console.log('Props', props)
    props.addNewOptionToItem(itemWithAddedOption)
  }

  return (
    <ConfigurationContainer>
     
      <Grid container spacing={2}>
      {
        (props.item && props.item._id) ? (
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <ItemDetails item={props.item} url={props.url} sizes={props.sizes} vendors={props.vendors} editing={props.editing} updateParent={handleUpdateItem} handleAddNewOption={handleAddNewOption} takeoffs={props.takeoffs} />
          </Grid>
        ) : null
      }
      </Grid>
    </ConfigurationContainer>
  )
}

const ItemDetails = (props) => {
  console.log('Choose Material Option Details:', props)

  const handleUpdateFromDropdown = (data, isEditing) => {
    console.log('Update OPTION chosen:', data)
    props.updateParent(data, isEditing)
  }

  let options = []
  if (props.item && props.item.options && props.item.options.length) {
    options = props.item.options
  } else {
    if (props.takeoffs && props.takeoffs.length) {
      let index = props.takeoffs.findIndex(item => (item._id === props.item.inventoryItemId))
      if (index > -1) {
        let itemToPick = props.takeoffs[index]
        if (itemToPick && itemToPick.options && itemToPick.options.length) {
          options = itemToPick.options
        }
      }
    }
  }
  // Add crewate4 new
  // options.push({
  //   name: 'Create New',
  //   _id: 'create'
  // })
  const theOptions = options
  return (
    <span style={{ textAlign: 'left' }}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <LabelText style={{ color: '#ccc', fontSize: '1.25em'}}>You Chose an item ({props.item.name})</LabelText>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <LabelText style={{ margin: 10, color: '#aaa', fontSize: '1.05em'}}>{props.item.description}</LabelText>
         
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          {
            props.item.pictureURL ? <img src={props.item.pictureURL} height="150px" /> : null
          }
         
        </Grid>
        
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <LabelText style={{ margin: 10, color: '#aaa', fontSize: '.85em'}}>{props.item.notes}</LabelText>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <LabelText style={{ margin: 10 }} >Please Choose Which Option You'd Like to Add:</LabelText>
          <OptionDropDowns item={props.item} url={props.url} sizes={props.sizes} options={theOptions} vendors={props.vendors} takeoffs={props.takeoffs} addNewOption={props.handleAddNewOption} updateParent={handleUpdateFromDropdown} editing={props.editing} />

          {/* <OptionList options={props.item.options} updateItem={handleUpdateQty} /> */}
        </Grid>
    </span>
  )
}

const defaultStValues = {
  qty: 0,
  unit: '',
  description: '',
  totalPrice: 0,
  costPer: 0,
  pricePer: 0,
  counter: 0,
  editing: false,
  inventoryItem: null,
  optionId: null,
  item: null,
  internalNotes: '',
  materialBudget: 0,
  laborHours: 0,
  techHours: 0,
  sprayTechHours: 0,
  operatorHours: 0,
  supervisorHours: 0,
  bobcatDays: 0,
  bigCraneDays: 0,
  smallCraneDays: 0,
  option: {},
  openCostModal: false,
  costChanged: false
}

const OptionDropDowns = (props) => {
  let { options, updateParent, takeoffs } = {...props}
// console.log('Option dropdowns:', options)
// console.log('takeoffs', takeoffs)
  const [state, setState] = React.useState(defaultStValues)
  const [showNewOptionModal, setShowNewOptionModal] = React.useState(false)

  React.useEffect(() => {
    console.log('PROPS changed 252', props)
    let newState = {}
    if (props.item && props.item.inventoryItemId) {
      console.log('This is a new style inventory item....318', props.item)
      newState = { ...props.item }
    }
    if (props.item) {
      newState.qty = props.item.qty || state.qty
      newState.costPer = props.item.costPer || 0
      newState.pricePer = props.item.pricePer || 0
    }
    
    if (props.item && props.item.optionId) {
      let index = options.findIndex(item => (item._id === props.item.optionId))
      if (index > -1) {
        newState.option = options[index]
        newState.pricePer = Number(options[index].msrp) > 0 ? options[index].msrp : 0
      }
    } else {
      if (options && options.length === 1) newState.option = options[0]
    }
    newState.totalPrice = Number(newState.pricePer * newState.qty).toFixed(2)
    console.log('set our options state:', newState)
    setState({...state, ...newState })
  }, [props.item])


  const pushUpdatedData = (data, isEditingActive) => {
    console.log('Time to update parent', data)
    console.log('Is editing active', isEditingActive)
    let obj = {...props.item, ...data}
    console.log('Obj', obj)
    updateParent(obj, isEditingActive)
  }

  const handleUpdate = (e) => {
    let theId = e.currentTarget.id.split('-')[0]
    // console.log('ID: ', theId)
    e.preventDefault()
    setState({...state, [theId]: e.target.value })
  }

  const handleSaveNewLineItem = () => {
    console.log('Save NEW lin eitem', state)
    let newState = {...state }
    newState.inventoryItem = props.item._id
    if (state && state.costChanged) {
      newState.openCostModal = true
    } else {
      console.log('Update Parent Called:', newState)
      pushUpdatedData(newState, false)
    }
    setState({...state, ...newState })
    // pushUpdatedData(state, false)
  }

  const handleUpdateExistingLineItem = () => {
    console.log('Update existing line item', state)
    if (state && state.costChanged ) {
      setState({...state, openCostModal: true })
    } else {
      pushUpdatedData(state, true)
    }
    // pushUpdatedData(state, true)
  }

  const handleSavePriceCheckUpdate = (data) => {
    console.log('Save our price data!!', data)
    // handleSaveNewLineItem(true)
    setState({...state, costChanged: false, openCostModal: false })
    pushUpdatedData(state, props.editing)
  }
  


  const handleCostChangeUpdate = (e) => {
    // let pricePer = 0
    // let totalPrice = 0
    let costPer = e.target.value
    // pricePer = Number(costPer * 1.4).toFixed(2)
    // totalPrice = Number(pricePer * state.qty).toFixed(2)
    console.log('Cost change --- ask them where and when??')
    setState({ ...state, costPer: costPer, costChanged: true})
  }

  const handlePriceManualChange = (e) => {
    let pricePer = e.target.value
    let totalPrice = 0
    totalPrice = Number(pricePer * state.qty).toFixed(2)
    setState({...state, pricePer: pricePer, totalPrice: totalPrice})
  }

  const handleChooseOption = (e) => {
    console.log('Choose option:', e.target.value)
    const optionChosen = e.target.value
    let pricePer = 0
    let newState = {}
    if (optionChosen === 'create') {
      console.log('Create a new item!!!')
      setShowNewOptionModal(true)
    } else if (optionChosen && optionChosen._id) {
      newState.option = optionChosen
      let unitObj = {
        label: e.target.value.size,
        value: e.target.value.size
      }
      if (e.target.value && e.target.value.price && e.target.value.price > 0) {
        let markup = 2.25
        if (props.estimate && props.estimate.entity && props.estimate.entity === '640a56404965b71a9edd0e7d') {
          markup = 1.15
        }
        pricePer = parseFloat(e.target.value.price * markup).toFixed(2)
        newState.costPer = optionChosen.price
      }
      newState.unit = unitObj
      if (optionChosen && optionChosen.msrp) pricePer = parseFloat(optionChosen.msrp).toFixed(2)
      // console.log('Set Price per;', pricePer)
      newState.pricePer = pricePer
      newState.totalPrice = Number(pricePer * state.qty).toFixed(2)
      setState({...state, ...newState })
    }
  }
  
  const handleQtyUpdate = (e) => {
    console.log(e.target.value)
    let pricePer = state.pricePer || 0
    let totalPrice = 0
    totalPrice = Number(pricePer * e.target.value).toFixed(2)
    console.log(totalPrice)
    setState({...state, qty: e.target.value, totalPrice: totalPrice})
  }

  const handleCloseQuickPriceUpdate = () => {
    setState({...state, openCostModal: false })
  }

  const handleCloseNewOptionModal = (type, data) => {
    console.log('Add New option response', type)
    console.log(data)
    setShowNewOptionModal(false)
    // set our option as the last option
    if (data && data._id) {
      const newOption = data.options[data.options.length - 1]
      const newItem = { ...data, optionId: newOption._id }
      setState({...state, option: newOption, pricePer: newOption.pricePer })
      props.addNewOption(newItem)
    }
  }

  let scope = {
    name: 'Scope Name',
    _id: '448489348349'
  }

  return (
    <div>
      
      <AddNewOptionModal scope={scope} item={props.item} sizes={props.sizes} open={showNewOptionModal} closeModal={handleCloseNewOptionModal} />
       <QuickPriceUpdate url={props.url} open={state.openCostModal} item={props.item} vendors={props.vendors} updatedCost={state.costPer} option={state.option} closeModal={handleCloseQuickPriceUpdate} savePriceUpdate={handleSavePriceCheckUpdate} />
      <Grid container spacing={2}>
      <Grid item lg={2} md={3} sm={4} xs={3}>
        <TextField
          fullWidth
          id="qty"
          type="number"
          variant="outlined"
          label="Quantity"
          onFocus={handleFocus}
          value={state.qty}
          onChange={handleQtyUpdate}
        />
      </Grid>
      <Grid item lg={8} md={7} sm={6} xs={9}>
          <TextField
            fullWidth
            id="option"
            variant="outlined"
            label="Option"
            select
            // disabled={!options || options.length < 1}
            value={state.option}
            onChange={handleChooseOption}
            >
              {
                (options && options.length) ? (
                  options.map((opt, index) => {
                    return (
                      <MenuItem key={index} id={`option-${index}`} value={opt}>
                        {opt.name} ({opt.size})
                      </MenuItem>
                    )
                  })
                ) : null
              }
               <MenuItem value={'create'} key={9999}>Add New Option</MenuItem>
          </TextField>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}></Grid>
      <Grid item lg={2} md={3} sm={4} xs={6}>
          <TextField
            label="Cost Per"
            variant="outlined"
            type="text"
            value={state.costPer}
            onChange={handleCostChangeUpdate}
            onFocus={handleFocus}
            helperText={ (state.option && state.option.last_price_update) ? moment(state.option?.last_price_update).format('MM/DD/YYYY hh:mma') : '' }
            />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={6}>
          <TextField
            label="Price Per"
            variant="outlined"
            type="text"
            value={state.pricePer}
            onChange={handlePriceManualChange}
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={6}>
          <TextField
            label="Total Price"
            variant="outlined"
            type="text"
            value={state.totalPrice}
            onChange={e=>setState({...state, totalPrice: e.target.value})}
            onFocus={handleFocus}
            disabled
            />
        </Grid>

      </Grid>
      <div>
        {
            props.editing ? <Button style={{margin: 10}} fullWidth variant="contained" color="primary" disabled={state.qty === 0 || ((options && options.length) && (!state.option || !state.option._id || state.option === {}))} onClick={handleUpdateExistingLineItem}>Update Line Item</Button> :
            <Button style={{margin: 10}} fullWidth variant="contained" color="primary" disabled={state.qty === 0 || ((options && options.length) && (!state.option || !state.option._id || state.option === {}))} onClick={handleSaveNewLineItem}>Save Line Item</Button>
          }
      </div>
    
    </div>
  )
}

const OptionList = ({ options, updateItem }) => {
  const handleModification = (e) => {
    let id = e.target.id.split('-')[1]
    let value = e.target.value
    let msg = `You modified #${id} to ${value}`
    // window.alert(msg)
    updateItem({id, value})
  }
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>QTY</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Size</TableCell>
          <TableCell>Notes</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          options.map((opt, index) => {
            let theColor = '#FFF'
            if (index %2 === 0) theColor = '#DDD'
            return (
              <TableRow key={index} style={{ height: '80%', backgroundColor: theColor }}>
                <TableCell>
                  <TextField
                    id={`opt-${opt._id}`}
                    size="smaill"
                    style={{
                      fontSise: '0.65em'
                    }}
                    InputProps={{ min: 0, style: {
                      height: '1.25em',
                      textAlign: 'center'
                    }}}
                    inputProps={{ min: 0, style: {
                      height: '1.25em',
                      textAlign: 'center'
                    }}}
                    onChange={handleModification}
                  />
                </TableCell>
                <TableCell>{opt.name}</TableCell>
                <TableCell>{opt.size}</TableCell>
                <TableCell>{opt.notes}</TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
  )
}

const QuickPriceUpdate = (props) => {
  // console.log('Quick Price Check', props)
  const defaultValues = {
    notes: ''
  }
  const [vendor, setVendor] = React.useState({})
  const [state, setState] = React.useState(defaultValues)

  const handleSave = async () => {
    // console.log('Save oru data!!!', props)
    const itemId = (props.item && props.item.inventoryItemId) ? props.item.inventoryItemId : (props.option && props.option.takeOff) ? props.option.takeOff : (props.item && props.item._id) ? props.item && props.item._id : null
    let priceCheckDetails = {
      item: itemId,
      option: props?.option?._id,
      price: props.updatedCost,
      notes: state.notes,
      vendor: vendor?._id
    }
    console.log('Priceheck:', priceCheckDetails)
    const response = await axios.post(`${props.url}/api/vendors/incomingPriceCheck`, priceCheckDetails)
    // console.log('Resp', response.data)
    if (response && response.data && response.data._id) {
      // props.pushUpdatedData(priceCheckDetails)
      props.savePriceUpdate(priceCheckDetails)
      setState(defaultValues)
    } else {
      console.log('No price check in response???', response.data)
    }
  }

  const handleClose = () => {
    props.closeModal()
  }
  const handleUpdateVendor = (e, newVendor) => {
    console.log('Chosen vendors:', newVendor)
    setVendor(newVendor)
  }

  const handleChange = (e) => {
    setState({...state, [e.target.id]: e.target.value})
  }

  return (
    <Dialog
      open={props.open}
      size={'sm'}
      onClose={handleClose}
      fullWidth>
      <DialogTitle>Quick Price Update</DialogTitle>
        <DialogContent>
          
          <Typography>Please Let Us Know More</Typography>
          <DetailsArea>
            <Typography>Where Did You Find This Price?  {currencyFormat(props?.updatedCost)}</Typography>
           
            <Autocomplete
            limitTags={5}
            id={`vendor-input`}
            // value={state.description}
            options={props.vendors || []}
            value={vendor}
            // getOptionLabel={(option) => option.name}
            getOptionLabel={(option) => {
              return option.name
            }}
            style={{ marginBottom: 10 }}
            // onChange={handleDescriptionChange}
            onChange={handleUpdateVendor}
            // filterOptions={(options, params) => {
            //   return `${option.name} ${option.alternateName}`
            // }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            // value={state.activeLineItem}
            fullWidth
            // InputProps={{ style: { fontSize: 20, color: '#fff', backgroundColor: '#333' } }}
            PaperComponent={({ children }) => (
              <Paper style={{ padding: 5, fontSize: 20, color: '#bbb', backgroundColor: '#fff' }}>{children}</Paper>
            )}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField
                {...params} 
                fullWidth
                label="Vendor"
                variant="outlined"
                placeholder="Who Is The Vendor?"
                />
            }
          />
          </DetailsArea>
          <DetailsArea>
            <Typography>Please Verify It Is For This Option & Price:</Typography>
            <LabelText>{currencyFormat(props?.updatedCost)}</LabelText>
            <br />
            <LabelText>{props?.option?.name}  {props?.option?.size}</LabelText>
            <LabelText>{props?.option?.notes}</LabelText>
            
          </DetailsArea>
          <DetailsArea>
            <Typography>Please Provide Any Relevant Details:</Typography>
            <TextField
              fullWidth
              variant="outlined"
              label="Notes"
              id="notes"
              value={state.notes}
              onChange={handleChange}
              style={{ marginBottom: 10 }}
              placeholder="Notes About This Price"
            />
          </DetailsArea>
        </DialogContent>

        <DialogActions>
          <Button color="secondary" onClick={handleClose}>CANCEL</Button>
          <Button color="primary" onClick={handleSave}
            disabled={!vendor || !vendor._id}
          >CORRECT</Button>
        </DialogActions>
      </Dialog>
  )
}

// const mapStateToProps = (state) => {
//   // console.log('MAP PROPS: ,', state)
//   return {
//     url: state.url,
//     user: state.user,
//     notification: state.notification
//   }
// }

export default ConfigureItem
//  connect(mapStateToProps)(ConfigureItem)