import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';

const MultipleDrivers = (props) => {
// console.log('Multi Drivers: ', props)
  const [state, setState] = React.useState({
    drivers: []
  })

  React.useEffect(() => {
  // console.log('Multi Drivers Use Efect', props.crew)
    if (props.crew && props.crew.assignedEmployees) {
      const drivers = props.crew.assignedEmployees.filter(item => (item.isDriver && item.isClockedIn))
      setState({...state, drivers: drivers })
    }
  }, [props.crew])

  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleSubmit = () => {
  // console.log('Handle Submit', state)
    
  }

  const chooseDriver = (driver) => {
  // console.log('Driver is:', driver)
    props.updateParent('save', driver._id)
  }
return (
  <div>
    
      <Dialog
        // fullScreen={false}
        // maxWidth={'sm'}
        // width='lg'
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Assigned Driver</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Choose The Driver For the Day:
          </DialogContentText>
           {
             state.drivers.map((driver, index) => (
                 <div key={driver._id} style={{margin: '10px'}}>
                    <Tooltip title={driver.displayName}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => chooseDriver(driver)}
                          fullWidth> {driver.displayName} </Button>
                    </Tooltip>
                   </div>
             ))
           }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>

        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(MultipleDrivers)