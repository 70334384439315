import React from 'react'
import { Button, CircularProgress, IconButton }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { currencyFormat, currencyFormatWithOutFlair } from '../../services/common'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  groupContainer: {
    border: '3px solid #ddd',
    borderRadius: 8,
    padding: 10,
    marginBottom: theme.spacing(3),
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignSelf: 'stretch',
    alignItems: 'center'
  },
  headerArea: {
    display: 'flex',
    flexGrow: 1,
    // backgroundColor: 'red',
    fontSize: '1.3em',
    fontWeight: 'bold'
  },
  detailsArea: {
    display: 'flex',
    flexGrow: 3,
    justifyContent: 'space-evenly',
    alignContent: 'flex-start',
    alignSelf: 'stretch'
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  logItemContainer: {
    display: 'flex',
    justifyContent: 'space-between', 
    margin: '5px', 
    padding: '10px'
  },
  logItemDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    alignItems: 'center',
    flexGrow: 3
  },
  dumpFeeArea: {
    marginLeft: 20,
    alignItems: 'center',
    width: '15%',
    justifyContent: 'center',
    display: 'flex',
  },
  actionArea: {
    marginLeft: 20,
  },
  topRowButton: {
    margin: 45,
    marginTop: 10,
    marginBottom: 10,
    width: 276
  },
  summaryOutputContainer: {
    display: 'flex',
    alignSelf: 'stretch'
  },
  summaryDataContainer: {
    margin: 20,
    padding: 20
  },
  centerCell: {
    textAlign: 'center'
  }
}))

const CountyLogData = (props) => {
  const classes = useStyles();
  // console.log("CountyLogData LOG", props)
  // Get the shit grouped by agreement
  const ourAgs = props.ags.filter(item => ((item.customerType === 'County' && !item.isNorthernPkwy === true)))
  // console.log('Our Ags foru County', ourAgs)
  // let groupedData = []
  // props.data.map((item, index) => {
  //   const agreementIndex = ourAgs.findIndex(ag => (ag._id === item.mtcAgreement))
  //   console.log('INDEX:', agreementIndex)
  //   if (agreementIndex > -1) {
  //     console.log('We didit ')
  //     const agreement = ourAgs[agreementIndex]
  //     console.log('Agreement associated', agreement)
  //     console.log('Log Data:', item)
  //     let grpIndex = groupedData.findIndex(groupItem => (groupItem.mtcAgreement === item.mtcAgreement))
  //     if (grpIndex > -1) {
  //       console.log('We already have this one!!!')
  
  //         let newData = { ...groupedData[grpIndex] }
  //         console.log('Use this', newData)
  //         newData.hoursWorked += Number(item.hoursWorked)
  //         console.log('After save', newData)
  //         let currentLogs = newData.dailyData
  //         currentLogs.push(item)
  //         newData.dailyData = currentLogs
  //         groupedData[grpIndex] = newData
        
  //     } else {
  //       console.log('create this shit')
  //       groupedData.push({
  //         mtcAgreement: item.mtcAgreement,
  //         crewName: agreement.jobName,
  //         hoursWorked: item.hoursWorked,
  //         dailyData: [ item ]
  //       })
  //     }
  //     console.log('Gruped data!!', groupedData)
  //   }

  
  //   // let data = {
  //   //   crewName
  //   // }
  //   // let currentTotalHours = groupedData[item.crew]
  // })
  const handleEditLog = (logId) => {
    // console.log('Edit this one', logId)
    const arrItem = [ ...props.data ]
    const itemToEdit = arrItem.find(item => (item._id === logId))
    props.editeDailyLogItem(itemToEdit)
  }

  const handleDeleteLog = (logId) => {
    // console.log('Delete this one:', logId)
    const arrItem = [ ...props.data ]
    let index = arrItem.findIndex(item => (item._id === logId))

    if (index > -1) {
      const itemToRemove = arrItem[index]
      // console.log('Delete this one', itemToRemove)
      props.deleteDailyLogItem(itemToRemove)
    }
  }
  let northernParkwaySummaryData = []
  if (props.mcdotData && props.mcdotData.data) northernParkwaySummaryData = props.mcdotData.data
  // console.log('Northern pkw data', northernParkwaySummaryData)
  let sortedDailyData = [...northernParkwaySummaryData].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });
  // console.log('Sorted Data:', sortedDailyData)
  let hoursWorked = 0
  let sortedPropsData = [...props.data].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });
  props.data.map(item => {
    hoursWorked += (Number(item.hoursWorked) * item.crewSize)
  })
  hoursWorked = parseFloat(hoursWorked).toFixed(2)
  return (
    <div className={classes.paper}>
      County Service Visits
      <div className={classes.summaryOutputContainer}>
        <SummaryDataTable {...props } />
      </div>
      <div  className={classes.groupContainer}>
        <div className={classes.topRow}>
          <div className={classes.headerArea}> </div>
          <div className={classes.detailsArea}>
            <div>
              {hoursWorked} Total Hours 
            </div>
            <div>
              { props.data.length ? `(${props.data.length} Logs)` : null }
            </div>
          </div>
        </div>
              <div style={{ padding: '10px', margin: '5px' }}>
                {sortedPropsData.map((dailyLog, dailyLogIndex) => {
                  // console.log('County Daily Log Item to display:', dailyLog)
                  // Get Jobsite info from the ags
                  let ag = ourAgs.find(item => (item._id === dailyLog.mtcAgreement))
                  // console.log('Ag::', ag)
                  let thisJobSite = {
                    name: ''
                  }
                  if (dailyLog && dailyLog.jobSite && dailyLog.jobSite._id) {
                    let site = dailyLog.jobSite
                    if (site._id === dailyLog.jobSite._id) thisJobSite = {
                      name: `${site.coummunityName ? site.coummunityName : ''} ${site.name ? site.name : ''} ${site.buildingNumber ? `(${site.buildingNumber})` : ''} ${site.address ? site.address : ''}`,
                      siteId: site._id
                    }
                  } else {
                    if (ag && ag.jobSites && ag.jobSites.length) {
                      ag.jobSites.map(site => {
                        if (site._id === dailyLog.jobSite) thisJobSite = {
                          name: `${site.coummunityName ? site.coummunityName : ''} ${site.name ? site.name : ''} ${site.buildingNumber ? `(${site.buildingNumber})` : ''} ${site.address ? site.address : ''}`,
                          siteId: site._id
                        }
                      })
                    }
                  }

                  // console.log('Jobsite 215', thisJobSite)
                  let bgColor = dailyLogIndex %2 === 0 ? '#ddd' : '#fff'
                  let dumpFeeTotal = 0
                  let dumpFees = []
                  if (dailyLog && dailyLog.dumpFees && dailyLog.dumpFees.length) dumpFees = dailyLog.dumpFees
                  dumpFees.map(dumpFee => {
                    // console.log('dump Fee', dumpFee)
                    if (dumpFee && dumpFee.fee) {
                      let fee = parseFloat(dumpFee.fee)
                      if (fee && fee > 0) {
                        dumpFeeTotal += Number(fee)
                      }
                    }                    
                  })
                  return (
                    <div key={dailyLogIndex} className={classes.logItemContainer} style={{ backgroundColor: bgColor }}>
                      <div className={classes.logItemDetails} >
                        <div>{dailyLog.date}</div>
                        <div>{ thisJobSite.name } </div>
                        <div>{dailyLog.crewSize} Men {dailyLog.crewName ? `on ${dailyLog.crewName} ` : null}</div>
                        <div>
                          {
                            (dailyLog.laborHours || dailyLog.irrigationHours || dailyLog.herbicideHours) ? (
                              <div>
                                 <div>
                                    {
                                      dailyLog.laborHours ? `Worked For ${dailyLog.laborHours} hours as General Labor` : null
                                    }
                                  </div>
                                  <div>
                                    {
                                      dailyLog.irrigationHours ? `Worked For ${dailyLog.irrigationHours} hours as Irrigation Tech` : null
                                    }
                                  </div>
                                  <div>
                                    {
                                      dailyLog.herbicideHours ? `Worked For ${dailyLog.herbicideHours} hours as Spray Tech` : null
                                    }
                                  </div>
                              </div>
                            ) : (
                              <div>Worked For {dailyLog.hoursWorked} hours as {dailyLog.laborType} </div>
                            )
                          }
                         
                          
                        
                        </div>
                       
                      </div>
                      <div className={classes.dumpFeeArea}>{ dumpFeeTotal > 0 ? `Dump Fees: ${currencyFormat(dumpFeeTotal)}` : '   ' }</div>
                      <div className={classes.actionArea}>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={e => handleEditLog(dailyLog._id)}>
                            Edit
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={e => handleDeleteLog(dailyLog._id)}>
                            Delete
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </div>
          </div>
        </div>
          )
      }

      // let data = []
      // if (props && props.mcdotData && props.mcdotData.data && props.mcdotData.data.length) data = props.mcdotData.data
      // console.log(data)
      // let hourSummary = null
      // if (props.mcdotData && props.mcdotData.hourSummary) hourSummary = props.mcdotData.hourSummary


      
const SummaryDataTable = (props) => {
  const classes= useStyles()
  // console.log('Return a summary table', props)
  let data = props.summaryData
  let totalVisits = 0
  let totalToBill = 0
  let totalExpectedVisits = 0
  return (
    <div className={classes.summaryDataContainer}>
      Summary data
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Site Name
            </TableCell>
            <TableCell >
              Preferred Day
            </TableCell>
            <TableCell  >
             Frequency
            </TableCell>
            <TableCell  >
             Assigned Crew
            </TableCell>
            <TableCell align='center' >
              # of Visits
            </TableCell>
            <TableCell align='center' >
              # of Visits Expected
            </TableCell>
            <TableCell align='center'  >
              Per Visit Amount
            </TableCell>
            <TableCell  align='center' >
              Total To Bill
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          data.map((item, index) => {
            // console.log('County Summary data item:', item)
            let totalAmountForThisSite = 0
            totalVisits += item.numberOfVisits
            totalExpectedVisits += item.expectedNumberOfVisits
            if (item && item.numberOfVisits) {
              let totalTimesToBill = item.numberOfVisits > item.expectedNumberOfVisits ? item.expectedNumberOfVisits : item.numberOfVisits || 1
              if (item.amount && item.amount > 0) {
                if (item.amountType === 'monthly') {
                  totalAmountForThisSite = Number(item.amount)
                } else if (item.amountType === 'perVisit') {
                totalAmountForThisSite = totalTimesToBill * Number(item.amount)
              } else {
                console.log('WTF is wrong with this one', item)
              }
            }
          }
            totalToBill += totalAmountForThisSite
            return (
              <TableRow key={index}>
                <TableCell>
                {item.communityName ? item.communityName : null} {item.name} {item.buildingNumber ? `(Building #${item.buildingNumber})` : null}
                </TableCell>
                <TableCell >
                  {item.preferredDay}
                </TableCell  >
                <TableCell  >
                  {item.frequency}
                </TableCell>
                <TableCell  >
                  {item.crewName || ''}
                </TableCell>
                <TableCell align='center' >
                  {item.numberOfVisits}
                </TableCell>
                <TableCell align='center' >
                  {item.expectedNumberOfVisits}
                </TableCell>
                <TableCell align='center' >
                  {currencyFormat(item.amount)}
                </TableCell>
                <TableCell align='center' >
                 {currencyFormat(totalAmountForThisSite)}
                </TableCell>
                
              </TableRow>
            )
          })
        }
         <TableRow key={'abc'}>
                <TableCell>
                Total
                </TableCell>
                <TableCell >
                 
                </TableCell  >
                <TableCell  >
                
                </TableCell>
                <TableCell align='center' >
                 {totalVisits}
                </TableCell>
                <TableCell align='center' >
                {totalExpectedVisits}
                </TableCell>
                <TableCell align='center' >
                 {currencyFormat(totalToBill)}
                </TableCell>
                
              </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}
export default CountyLogData