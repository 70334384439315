import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import NewNoteModal from './NewNoteModal'
import EditNoteModal from './EditNoteModal'
import Grid from '@material-ui/core/Grid'
import axios from 'axios'
import moment from 'moment'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const CustomerNotes = (props) => {
  // console.log('Customer Notes: ', props)
  const [showNewNoteModal, setShowNewNoteModal] = React.useState(false)
  const [showEditNoteModal, setShowEditNoteModal] = React.useState(false)
  const [notes, setNotes] = React.useState([])
  const [activeNote, setActiveNote] = React.useState({})
  const [state, setState] = React.useState({
    notes: [],
    client: {}
  })
  React.useEffect(() => {
    // console.log('Use Effect Customer Note')
    // let jobName = 'Job - '

    if (props.client && props.client._id) {
      getClientNotes(props.client)
      setState({...state, client: props.client})
    }
    // if (props.client.lastName) jobName = props.client.lastName + '- '
    // if (props.client.companyName) jobName = props.client.companyName + '- '
    // setOpen(props.open)
    // setState({...state, jobName: jobName})
  }, [props.client])

  const getClientNotes = (client) => {
    axios({
      method: 'get',
      url:  `${props.url}/api/customer/getClientNotes?id=${client._id}`,
    }).then(response => {
      // console.log('REsponse from getting customer notes: ', response)
      if (response && response.data && response.data.length) {
        setNotes(response.data)
        setState({...state, notes: response.data})
      }
    })
  }

  const addNote = () => {
    // console.log('Add a Note')
    setShowNewNoteModal(true)
  }

  const newNoteResponse = (type, data) => {
    // console.log('Response from new note: ', type)
    if (type === 'Close') setShowNewNoteModal(false)
    if (type === 'newNote') {
      setShowNewNoteModal(false)
      // console.log('Add this to our note list', data)
      let newNotes = notes
      newNotes.unshift(data)
      setNotes(newNotes)
      setState({...state, notes: newNotes})
    }
  }

  const editNoteResponse = (type, data) => {
    // console.log('Response from edit note: ', type)
    if (type === 'Close') setShowEditNoteModal(false)
    if (type === 'updatedNote') {
      setShowEditNoteModal(false)
      let newNotes = notes
      let noteIndex = newNotes.findIndex(noteItem =>  noteItem['_id'] === data._id);
      let noteToUpdate = notes[noteIndex]
      noteToUpdate.note = data.note
      setNotes(newNotes)
      setState({...state, notes: newNotes})
    }
  }

const editNote = (note) => {
  // console.log('Edit Note: ', note)
  setActiveNote(note)
  setShowEditNoteModal(true)
}
  return (
    <div style={{width: '100%', maxHeight: '300px', overflowY: 'scroll', minHeight: '80px'}}>
      <div>
        <Button
            variant="contained"
            color="secondary"
            style={{margin: '5px', position: 'absolute', right: '30px'}}
            // className={classes.button}
            // startIcon={<CreditCardIcon />}
            size="small"
            onClick={addNote}
          >
            Add Note
          </Button>
        </div>
      <NewNoteModal client={props.client}  newNoteResponse={newNoteResponse} open={showNewNoteModal} />
      <EditNoteModal client={props.client} note={activeNote} editNoteResponse={editNoteResponse} open={showEditNoteModal} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={5}>Note Details</TableCell>
            <TableCell>Author</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {state.notes.map(row => (
            <TableRow key={row._id}>
              <TableCell colSpan={5}>{row.note}</TableCell>
              <TableCell>{row.created_by}</TableCell>
              <TableCell>{moment(row.created_at).format('MM/DD/YYYY [at] hh:mm a')}</TableCell>
              <TableCell><a style={{ textDecoration: 'underline', color: 'blue', cursor: 'Pointer'}} onClick={() => editNote(row)}>Edit</a></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(CustomerNotes))