import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

const MultipleUploads = (props) => {
  return (
    <div style={{ margin: 15, textAlign: 'center' }}>
      <div style={{ fontSize: '1.2em', color: '#bbb' }}>PO Related Files ({props.uploads.length})</div>
      <div style={{ margin: 15, textAlign: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
      {
        props.uploads.map((item, index) => {
          let theURL = item.url
          if (theURL && !theURL.includes('https')) {
            theURL = `https://${theURL}`
          }
          let pdfURL = `https://docs.google.com/gview?url=${theURL}&embedded=true`
          return (
            <div key={index}>
              { (item.type.includes('pdf')) ? (
              <div style={{ width: '100%', minWidth: 400, height: 'auto' }}>
                <iframe src={pdfURL} width="100%" height="800px" frameborder="0"></iframe>
              </div> 
              ) : (item.type.includes('image')) ? (
                <div style={{ minWidth: 300, maxWidth: 400, display: 'flex',   }}>
                  <img src={theURL} style={{ width: '100%', height: 'auto' }} />
                </div>
                ) : (item.type.includes('csv') || item.type.includes('office')) ? <iframe width="100%" height="900px" src={`https://docs.google.com/gview?url=${item.url}&embedded=true`}></iframe> : null
              }
            </div>
          )
        })
      }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(MultipleUploads)
