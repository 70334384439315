import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { CircularProgress, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper'
import DeleteIcon from '@material-ui/icons/Delete';
import ProtectedButton from '../../actions/protectedButtons';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import NewTaxLocaleModal from './NewTaxLocaleModal'
import TaxLocaleList from './TaxLocaleList'
import axios from 'axios'

const TaxSettings = (props) => {
// console.log('Tax Settings Component', props)
  const [taxLocales, setTaxLocales] = React.useState(false)
  const [showNewTaxLocaleModal, setShowNewTaxLocaleModal] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const openNewTaxLocaleModal = () => {
    setShowNewTaxLocaleModal(true)
  }

  const closeNewTaxLocaleModal = () => {
    setShowNewTaxLocaleModal(false)
  }

  const saveNewLocale = (newLocale) => {

  }

  async function fetchData(toReturn) {
  // console.log('Fetch our Users')
    setLoading(true)
    const response = await axios.get(`${props.url}/api/settings/listTaxLocales`)
  // console.log(response)
    if (response.data && response.data.length > 0) {
    // console.log('Got Tax Locales!!', response.data)
      setTaxLocales(response.data)
    } else {
    // console.log('Fucked up')
    }
  }

  React.useEffect(() => {

    fetchData()
  }, [])
  return (
    <div style={{padding: '5px', margin: '10px'}}>
      <NewTaxLocaleModal open={showNewTaxLocaleModal} updateParent={closeNewTaxLocaleModal} saveNewLocale={saveNewLocale} />
      <Grid container>
        <Grid item lg={6} xs={12}>
          <h1>Tax Settings</h1>
        </Grid>
        <Grid item lg={6} xs={12}>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Paper style={{ minHeight: '80vh' }}>
            <Grid container style={{ padding: '10px' }}>
              <Grid item lg={12} xs={12}>
                <strong style={{paddingRight: '10px'}}>Tax Locations</strong>
                <ProtectedButton type="Settings" kind="update" perms={props.perms}>
                  <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddCircleIcon />}
                  onClick={openNewTaxLocaleModal}
                  >
                    Add New
                  </Button>
                </ProtectedButton>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Grid container style={{ padding: '5px' }}>
                  <TaxLocaleList locales={taxLocales} loading={loading} />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      
  </div>
  )
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      perms: state.perms
    }
  }
  
  export default connect(mapStateToProps)(TaxSettings)