import React from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';
import HotelIcon from '@material-ui/icons/Hotel';
import RepeatIcon from '@material-ui/icons/Repeat';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment'
import * as Muicon from "@material-ui/icons";
// import LocalFloristIcon from '@material-ui/icons/LocalFlorist';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: '650px',
    width: '100%',
    backgroundColor: '#fff',
    overflowY: 'auto'
  },
  paper: {
    padding: '6px 16px'
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
  date: {
    fontSize: '1.2em',
    color: '#ccc',
    marginTop: 10
  }
}));

const getIcon = (category) => {
  let icon = 'HotelIcon'
  // console.log('Category: ', category)
  switch(category) {
    case 'Clean-Ups': {
      icon = 'LocalShipping';
      break;
    }
    case 'Flowers': {
      icon = 'LocalFlorist';
      break;
    }
    case 'Irrigation': {
      icon = 'Opacity';
      break;
    }
    case 'Landscapes': {
      icon = 'Landscape';
      break;
    }
    case 'Lighting': {
      icon = 'WbIncandescentTwoTone';
      break;
    }
    case 'Monthly Maintenance': {
      icon = 'NaturePeople';
      break;
    }
    case 'Other': {
      icon = 'AllInclusive';
      break;
    }
    case 'Pest Control': {
      icon = 'Android';
      break;
    }
    case 'Specialty': {
      icon = 'Build';
      break;
    }
    case 'Tree Trimming': {
      icon = 'Nature';
      break;
    }
    case 'Water Feature': {
      icon = 'Bathtub';
      break;
    }
    default: {
      icon = 'Repeat';
      break;
    }
  }
 
  let OurIcon = Muicon[icon]
  return <OurIcon />
}

const ProjectTimeline = (props) => {
  // console.log('Project TImeline: ', props)
  const classes = useStyles();
  // console.log('# of Workorders to Display')
  React.useEffect(() => {
    // console.log('Project Timeline Use Effect WORKORDERS CHANGED', props)
  }, [props])
  
  return (
    <Paper className={classes.root} >
      Workorders {(props.workorders && props.workorders.length) ? props.workorders.length : '(None Associated)'}
      <Timeline align="alternate">
        {/* <TimelineItem>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              9:30 am
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot>
              <FastfoodIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                Eat
              </Typography>
              <Typography>Because you need strength</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              10:00 am
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <LaptopMacIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                Code
              </Typography>
              <Typography>Because it&apos;s awesome!</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" variant="outlined">
              <HotelIcon />
            </TimelineDot>
            <TimelineConnector className={classes.secondaryTail} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                Sleep
              </Typography>
              <Typography>Because you need rest</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="secondary">
              <RepeatIcon />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={classes.paper}>
              <Typography variant="h6" component="h1">
                Repeat
              </Typography>
              <Typography>Because this is the life you love!</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem> */}
        {
          (props.workorders && props.workorders.length) ? props.workorders.map((wo, index) => {
            // console.log('Get Icon: ', wo.serviceCategory)
            // console.log(wo)
            // let CustomIcon = getIcon(wo.serviceCategory)
            // console.log('ICON NAME: 191:', icon)
            let isOdd = index % 2
            let bgColor = "secondary"
            if (isOdd) bgColor = "primary"
            // console.log('IS ODD: %s  -- Index: %d', isOdd, index)
            return (
              <TimelineItem key={wo._id}>
                <TimelineOppositeContent>
                  <Typography variant="body2" color="textSecondary" className={classes.date}>
                    {wo.scheduledDate ? moment(wo.scheduledDate).format('ddd MM/DD/YY') : 'Unscheduled'}
                    
                  </Typography>
                </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color={bgColor}>
                  {getIcon(wo.serviceCategory)}
                </TimelineDot>
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={classes.paper}>
                  <Typography variant="h6" component="h1">
                  {wo.jobName} #{wo.number}
                  </Typography>
                  <Typography> {wo.serviceName} ({wo.serviceCategory}) </Typography>
                </Paper>
              </TimelineContent>
            </TimelineItem>
            )
          }) : null
        }
      </Timeline>
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ProjectTimeline)