import React, { Component } from 'react'

const RegistrationFailed = (props) => {
  return (
    <>
      <h1>Well, this is akward. That registration ID doesn't match or is expired. Please contact your Administrator.</h1>
    </>
  )
}

export default RegistrationFailed