import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import CustomerIssueList from './CustomerIssueList'

const CustomerIssueModal = ({ open, closeModal, list }) => {

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      maxWidth="lg"
      fullWidth
      >
        <DialogTitle>Customer Issues</DialogTitle>
        <DialogContent>
          <CustomerIssueList list={list} />
        </DialogContent>
      </Dialog>
  )
}

export default CustomerIssueModal