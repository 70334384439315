import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetails from './ServiceDetails'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import DialogContentText from '@material-ui/core/DialogContentText';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

const ServiceDetailListFunction = React.memo(function TestMemo(props) {
  console.log('Service Detail List props: ', props)
  const classes = useStyles();

  const [state, setState] = React.useState({
    jobDetails: [],
    job: props.job || {},
    updates: [],
    workorders: [],
    counter: 0
  })
  const [crewList, setCrewList] = React.useState([])
  const [serviceList, setServiceList] = React.useState([])
  const [workorders, setWorkorders] = React.useState([])
  React.useEffect(() => {
    // console.log('Service Detail List USE Effect', props)
    // async function fetchData() {
    //   //   let clientId = null
    //   // console.log('OLD FETCH JOB SERVICE DETAILS')
    //   //   if (props && props.job && props.job.number) {
    //   //     // setJob(props.job)
    //   //     // setState({ ...state, job: props.job })
    //   //     // console.log('We have a job get the associated services')
    //   // //     // console.log('We have a client ID!')
    //   // //     clientId = props.match.params.clientId
    //   //     const response = await axios.get(`${props.url}/api/customer/jobservicedetails?id=${props.job._id}`)
    //   //     // console.log('SERVICE DETAILS:::: ', response)
    //   //     if (response && response.data && response.status === 200) {
    //   //       let data = response.data
    //   //       // if (data && data.crews) setState({...state, crewList: data.crews})
    //   //       // if (data && data.jobDetails) setState({...state, jobDetails: data.jobDetails})
    //   //         // setJobDetails(data.jobDetails)
    //   //         // console.log('We have our associated workorder: ', data)
    //   //         // console.log('Service Details are here: ', data.serviceDetails)
    //   //         getOurServiceDetailsOrganized(data, props.crewList, props.serviceList)
    //   //       }
    //   //     }
    //   }
      setWorkorders(props.workorders)
      // fetchData()
      setCrewList(props.crewList)
      setServiceList(props.serviceList)
      setState({...state, workorders: props.workorders})
      // getOurServiceDetailsOrganized(props.workorders, props.crewList, props.serviceList)
  }, [props])

const handleAddService = () => {
  // console.log('Add Service')
  let newJobDetails = state.workorders
  // console.log('Existing WOs: ', newJobDetails)
  newJobDetails.push({
    description: '',
    notes: '',
    crew: '',
    date: '',
    time: '',
    name: '',
    servicetype: '',
    number: 'TBD'
  })
  // {
  //   "JobNum":"48862",
  //   "ModifiedServices":[
  //      {
  //         "JobNum":"48862",
  //         "WorkNum":-1,
  //         "Description":"Did this work? ",
  //         "Hours":12,
  //         "Price":1,
  //         "Schedule":true,
  //         "DateCompleted":null,
  //         "Invoiced":false,
  //         "WorkingHrsPerDay":8,
  //         "Taxable":"No",
  //         "IsNewService":true,
  //         "Service":"458",
  //         "Section":"0"
  //      }
  // setWorkorders(newJobDetails)
  // console.log('Workorders: ', workorders)
  setState({...state, workorders: newJobDetails})
}

const updateService = (svc, typeOfUpdate, callback) => {
// console.log('Gotta update this service...', svc)
  // console.log('Update Service', state)
  // Find this jobDetail and update it here??
  props.updateOurJob(svc, typeOfUpdate, (wo) => {
    // console.log('Got our new WO', wo)
    return callback(wo)
  })
}

const getOurServiceDetailsOrganized = (workorders, crews, serviceList) => {
  // console.log('WOS to Extract Services: ', workorders)
  let workorder = workorders[0]
  let services = []
  let organizedServices = []
  // console.log('Workorder: ', workorder)
  if (workorder && workorder.serviceDetails && workorder.serviceDetails.length) {
    services = workorder.serviceDetails
    // console.log('Organize our service details', services)
    services.map(svc => {
      // console.log('Service: ', svc)
      // console.log('We have our crew details now...Crew Name: ', svc['CrewName'])
      // console.log('Crews Length: ', crews.length)
      // console.log('Serivce List: ', serviceList.length)
      let index = crews.findIndex(crewItem =>  (parseInt(crewItem['crewNumber']) === parseInt(svc['crewNumber'])));
      let ourCrew = crews[index]
      let completedDate = svc.completedDate
      let invoiceDate = workorder.invoiceDate
      // let isComplete = 
      let isInvoiced = false
      if (workorder.invoiceDate) isInvoiced = true
      let serviceIndex = serviceList.findIndex(crewItem =>  (parseInt(crewItem['agValue']) === parseInt(svc['serviceNum'])));
      let ourService = serviceList[serviceIndex]
      // console.log('Our Crew: ', ourCrew)
      let htmlNotes = svc['notes']
      // console.log('HTML Notes: ', htmlNotes)
      var temp = document.createElement("div");
      temp.innerHTML = htmlNotes
      var cleanNotes = temp.textContent || temp.innerText;
      // let startDate = new Date()
      let scheduledDate = new Date()
      if (svc['scheduled']) scheduledDate = svc['scheduled']
      let htmlDesc = svc['description'];
      var temp = document.createElement("div");
      temp.innerHTML = htmlDesc
      let cleanDescription = temp.textContent || temp.innerText
      //  // console.log('Set Date: ', startDate)
      //  description: desc, crew: ourCrew, 
      //  date: startDate, 
      //  notes: sanitized,
      //   time: data.jobDetails[0]['Hours'], 
      //   jobDetails: data.jobDetails, 
      //   price: data.jobDetails[0]['Price']})
      let service = {
        notes: cleanNotes,
        description: cleanDescription,
        crew: ourCrew,
        date: moment(scheduledDate).format('MM/DD/YYYY'),
        time: svc.estHours,
        price: svc.price,
        number: svc.workOrdernumber,
        name: svc.name,
        requiresUpdate: false,
        servicetype: ourService,
        completedDate,
        invoiceDate,
        isInvoiced
      }
      // console.log('Service to add: ', service)
      organizedServices.push(service)
    })
  } else {
    // console.log('No Service Details')
  }
  // console.log('Set our Job Details: ', organizedServices)
  setState({ ...state, workorders: workorders, crewList: crews, jobDetails: organizedServices })
}

const handleDelete = (wo, index) => {
  // console.log('Handle the deletion of htis item: ', wo)
  // console.log(index)
  let jobToDelete = state.workorders[index]
  // console.log(jobToDelete)
  let newJobDetails = [...state.workorders]
  newJobDetails.splice(index, 1)
  // if (newJobDetails.length < 1) 
  setState({...state, workorders: newJobDetails})
  let obj = {
    workorder: wo,
    index: index,
    job: props.job
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/jobs/deleteWorkorder`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response: ', response)
    props.notification({
      type: 'success',
      title: 'Service Deleted!',
      message: 'This Service Was Deleted'
    })
  })
}
  return (
    <div>
    # of Workorders { state.workorders.length } <br />
    { state.workorders.map((wo, serviceIndex) => {
      // console.log('Workorder: ', wo)
      return (
          <ServiceDetails taxLocales={props.taxLocales} client={props.client} uploads={props.uploads} project={props.project} workorder={wo} job={props.job} handleDelete={handleDelete} updateAService={ updateService } serviceList={serviceList} crews={crewList} key={serviceIndex} serviceIndex={serviceIndex} />
      )
    })}
              <Button
                variant="contained"
                // style={{backgroundColor: '#ed4545'}}
                className={classes.button}
                size="small"
                color="primary"
                // disabled={props.job.estimate}
                startIcon={<AddCircleIcon />}
                onClick={() => handleAddService()}
              >
                Add Service
              </Button> 
             
            </div>
  )

})
const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ServiceDetailListFunction)