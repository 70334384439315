import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TextField,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Chip
} from '@material-ui/core';
import axios from 'axios'
import moment from 'moment'
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import VisibilityIcon from '@material-ui/icons/Visibility';

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log(': ', array)
  // console.log('CMP: ', Stable sortcmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'displayName', numeric: false, disablePadding: true, label: 'Customer' },
  { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
  { id: 'created_at', numeric: false, disablePadding: true, label: 'Created' },
  { id: 'opens', numeric: false, disablePadding: true, label: 'Opens' },
  { id: 'clicks', numeric: false, disablePadding: true, label: 'Clicks' },
  { id: 'totalDue', numeric: false, disablePadding: true, label: 'History' },
  { id: 'crewname', numeric: false, disablePadding: false, label: 'Job' },
  { id: 'notes', numeric: false, disablePadding: false, label: 'Customer Notes' },
  { id: 'details', numeric: false, disablePadding: false, label: 'Details' },
];

function EnhancedTableHead(props) {
  // console.log('Got our props', props)
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    // console.log('Sort Property: ', property)
    // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  flowerChip: {
    margin: theme.spacing(0.5),
  },
}));

function EnhancedTable(props) {
  // console.log('Email Dashboard Blast ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [invoiceDetailsOpen, setinvoiceDetailsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const [invoice, setInvoice] = React.useState({});
  const [crewList, setCrewList] = React.useState([]);
  const [serviceList, setServiceList] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([])
  const [rows, setRows] = React.useState([]);
  const [activityTypes, setActivityTypes] = useState([])
  const [activityType, setActivityType] = useState('');

  useEffect(() => {
    let isMounted = true;
    const fetchDashboardStats = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${props.url}/api/customer/getEmailDashboardData`);
        if (isMounted) {
          console.log('Got Our Email Dashboard Data ', response.data);
          setActivityTypes(response.data);
        }
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchDashboardStats();
    return () => { isMounted = false };
  }, [props.url]);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };


 const handleActivityTypeChange = async (event) => {
  const newActivityType = event.target.value;
  setActivityType(newActivityType);
  setLoading(true);
  console.log('Find ', newActivityType)
  try {
    
    const response = await axios.get(`${props.url}/api/customer/getActivitiesByType`, {
      params: { type: newActivityType }
    });
    console.log('rsepon', response.data)
    setRows(response.data);
    setFilteredRows(response.data);
    setPage(0);
  } catch (error) {
    console.error('Error fetching activities by type:', error);
  } finally {
    setLoading(false);
  }
};

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    // console.log('handle click ', name)
    // console.log('Index: ', selectedIndex)
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: ', r)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // setsearchMatches(newArr.length)
    // return newArr
    setFilteredRows(newArr)
    // setsearchMatches(newArr.length)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  
  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setPage(0)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
  }

  const openModal = (invoice) => {
    // console.log(event)
    // e.preventDefault;
    // console.log('Open INVOICEDETAIL modal: ', invoice)
    // props.openModal(invoice)
    setInvoice(invoice)
    setinvoiceDetailsOpen(true)
  }

  const invoiceDetailsResponse = (s, t) => {
    // console.log('Response from our invoice details!!', s)
    if (s === 'Deleted') {
      // console.log('Deleted invoice - remove it from the list', t)
      let index = rows.findIndex(row =>  (parseInt(row['Invoice']) === parseInt(t['Invoice'])))
      // console.log('INDEX: ', index)
      // console.log(rows[index])
      let invoices = rows
      invoices.splice(index, 1)
      setRows(invoices)
      setinvoiceDetailsOpen(false)
    } else if (s === 'Payment Made') {
      // console.log('Payment made baby!!!', t)
      let index = rows.findIndex(row =>  (row['_id'] === t['_id']))
      // console.log('INDEX: ', index)
      // console.log(rows[index])
      let invoices = rows
      invoices[index] = t
      // .splice(index, 1)
      // invoices.push(t)
      // console.log('Invoices with updated payment...', invoices)
      setRows(invoices)
      // setFilteredRows(invoices)
    } else {
      setinvoiceDetailsOpen(false)
    }
  }

  const ActivityHistory = (props) => {
    // console.log('props.history', props)
    let history = props.data.history
    // console.log("History", history)
    return (
      <div>
        {history.map((item, index) => {
         return (
           <div key={index}>{item.type} at {moment(item.date).format('MM/DD/YYYY [at] hh:mma')}</div>
         ) 
        })}
        </div>
    )  
  }

  const OurNotes = (props) => {
    let note = props.data
    // console.log('Nots: ', note)
    if (note.includes('/seasonal-changes?')) {
      return <a href={note}>Unique URL</a>
    } else {
      return <span>{note}</span>
    }
  }

  const renderSelectedFlowers = (row) => {
    const selectedFlowers = row.actionDetails?.selectedFlowers || {};
    if (Object.keys(selectedFlowers).length === 0) return 'No flowers selected';
    return Object.entries(selectedFlowers).map(([flower, colors]) => (
      <Tooltip title={colors.join(', ')} key={flower}>
        <Chip
          label={`${flower} (${colors.length})`}
          className={classes.flowerChip}
        />
      </Tooltip>
    ));
  };

  const getValueByPath = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const getCustomerName = (row) => {
    return row.actionDetails?.customerInfo?.name || 
           row.client?.displayName || 
           `${row.client?.firstName} ${row.client?.lastName}`.trim() || 
           'N/A';
  };

  const getEmail = (row) => {
    return row.email || row.actionDetails?.email || 'N/A';
  };

  const renderHistoryTooltip = (row) => {
    const history = row.history || [];
    return (
      <Tooltip title={
        <div>
          {history.map((item, index) => (
            <div key={index}>
              {item.type} at {moment(item.date).format('MM/DD/YYYY [at] hh:mma')}
            </div>
          ))}
        </div>
      }>
        <span>{history.length} events</span>
      </Tooltip>
    );
  };

  return (
    <div className={classes.root}>
      {loading ? <CircularProgress color="primary" size={36} /> : (
        <Paper className={classes.paper}>
         <TextField
              labelId="activity-type-label"
              id="activity-type-select"
              value={activityType}
              select
              variant="outlined"
              onChange={handleActivityTypeChange}
              label="Activity Type" 
            >
              <MenuItem key={'aaa'} value="all">
                <em>All</em>
              </MenuItem>
              {
                activityTypes.map((type, index) => {
                  return (
                    <MenuItem key={index} value={type}>{type}</MenuItem>
                  )
                })
              }
            </TextField>

          
          <div className={classes.tableWrapper} style={{margin: '10px'}}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='small'
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(filteredRows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                      >
                        <TableCell component="td" id={labelId} scope="row" padding="none">
                          {getCustomerName(row)}
                        </TableCell>
                        <TableCell>{getEmail(row)}</TableCell>
                        <TableCell>{moment(row.created_at).format('MM/DD/YYYY [at] hh:mma')}</TableCell>
                        <TableCell>{row.opens ? row.opens.length : 0}</TableCell>
                        <TableCell>{row.clicks ? row.clicks.length : 0}</TableCell>
                        <TableCell>{renderHistoryTooltip(row)}</TableCell>
                        <TableCell>{row.jobNumber || 'N/A'}</TableCell>
                        <TableCell>{row.notes || ''}</TableCell>
                        <TableCell>{renderSelectedFlowers(row)}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={9} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            component="div"
            count={filteredRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default React.memo(connect(mapStateToProps)(EnhancedTable))

