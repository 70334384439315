import React, { Component } from 'react'
import styles from './home.module.scss'
import { Card, CardContent, Typography } from '@material-ui/core'
import RegistrationFinish  from '../RegistrationFinish/RegistrationFinish'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import axios from 'axios'
import SearchEngine from './SearchEngine'
import ProtectedButton from '../../actions/protectedButtons'
import { setUrl, setAuth, setUser, setNotifications, setPaypal, setGPS, setPerms, setServices, setSalesReps, setClients, setEmployees, setTasks, setUsers, setCrews, setConfigs, setSnoutWeevilButtonActive, setFertilizationButtonActive } from '../../actions/connectionActions'
import SupervisorCrewView from './SupervisorCrewView'
import { Link } from 'react-router-dom'

function isAndroid() {
  return /Android/i.test(navigator.userAgent)
}

function isIos() {
  return /(iPhone|iPad|iPod)/i.test(navigator.userAgent)
}

const Home = (props) => {
  // console.log('home props', props)
  const [loading, setLoading] = React.useState(false)
  const [crewsSupervised, setCrewsSupervised] = React.useState([])
  // handleDLRequest = async () => {
  //   setState({
  //     ...state,
  //     loading: true
  //   })
  //   await axios.get(`${this.props.url}/api/user/getAndroidApp`)
  //   setState({
  //     ...state,
  //     loading: false
  //   })
  // }
  const getEmployee = async (userId) => {
    // console.log('Find our employee', userId)
    const employeeAndCrews = await axios.get(`${props.url}/api/employees/getEmployeeDetails?userId=${userId}`)
    if (employeeAndCrews && employeeAndCrews.data && employeeAndCrews.data.employee) {
      // console.log('Got employee and crews...')
      let supervisedCrews = []
      const employee = employeeAndCrews.data.employee
      const crews = employeeAndCrews.data.crews
      if (employee && employee.crewsSupervised && employee.crewsSupervised.length) {
        // console.log('Find our crews that are supervised', employee.crewsSupervised)
        employee.crewsSupervised.map(item => {
          let index = crews.findIndex(crew => (crew._id === item))
          if (index > -1) supervisedCrews.push(crews[index])
        })
      }
      // console.log('got em!', supervisedCrews)
      setCrewsSupervised(supervisedCrews)
    }
  }
  const getStuff = async () => {
    // console.log('Get Stuff', props)
    const getOurStuff = await axios.get(`${props.url}/api/company/getStuff`)
    // console.log('Got Our stuff', getOurStuff)
    if (getOurStuff.data && getOurStuff.data.clients) props.setClients(getOurStuff.data.clients)
    if (getOurStuff.data && getOurStuff.data.employees) props.setEmployees(getOurStuff.data.employees)
    if (getOurStuff.data && getOurStuff.data.salesReps) props.setSalesReps(getOurStuff.data.salesReps)
    if (getOurStuff.data && getOurStuff.data.tasks) props.setTasks(getOurStuff.data.tasks)
    if (getOurStuff.data && getOurStuff.data.services) props.setServices(getOurStuff.data.services)
    if (getOurStuff.data && getOurStuff.data.users) props.setUsers(getOurStuff.data.users)
    if (getOurStuff.data && getOurStuff.data.crews) props.setCrews(getOurStuff.data.crews)
    if (getOurStuff && getOurStuff.data && getOurStuff.data.configs && getOurStuff.data.configs.length) {
      // console.log('Got our Configs!!", ', getOurStuff.data.configs)
      // Use DFL al the time
      props.setConfigs(getOurStuff.data.configs)
      let isFertActive = getOurStuff.data.configs[0].fertilizationButtonActive
      let isSnoutWeevileActive = getOurStuff.data.configs[0].snoutWeevilButtonActive
      props.setFertilizationButtonActive(isFertActive)
      props.setSnoutWeevilButtonActive(isSnoutWeevileActive)
    }
  }

  React.useEffect(() => {
    if (props.salesReps && props.salesReps.length) {

    } else {
      // console.log('Get teh fucking sales reps!!!', props)
      if (props.user && props.user._id) {
        // setTimeout(() => {
        // // console.log('Get it now')
        getStuff()
       
        // }, 1500)
      }
    }
    if (props.user && props.user._id) {
      getEmployee(props.user._id)
    }
  }, [])

  const handleDownloadApp = async () => {
    setLoading(true)
    axios({
      url: `${props.url}/api/company/downloadAndroidmobileApp`,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      setLoading(false)
      if (response && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        var link = document.createElement('a');
        link.href = url;
        let fileName = 'dflmobileapp'
        link.setAttribute('download', `${fileName}.apk`)
        document.body.appendChild(link)
        link.click();
      }
    })
  }

    const { user } = props
    let isAndroidDevice = isAndroid()
    let isIosDevice = isIos()
    const iOSUrl = 'https://apps.apple.com/us/app/terral/id6466199013'
    return (
      <div className={ styles.home }>
        {
          user && !user.active ?
          <RegistrationFinish></RegistrationFinish> :
          null
        }
        <Card className={ styles.card }>
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              DFL Portal
            </Typography>
           
         
              <div style={{display: 'flex', justifyContent: 'center'}}>
                {
                  user && user._id ? <SearchEngine  /> : null
                }
              </div>
       
              
          </CardContent>
        </Card>
        {
          (user && user._id && crewsSupervised && crewsSupervised.length) ? <SupervisorCrewView crews={crewsSupervised} /> : null
        }
        {
          user && user._id ? (
            <React.Fragment>
             
              <Card className={ styles.card }>
                <CardContent>
                  {
                    isAndroidDevice ? (
                    
                        loading ? <div>DOWNLOADING NOW</div> :                    
                      <Typography color="textSecondary" gutterBottom>
                      Download the Android Mobile App: <a onClick={ handleDownloadApp } style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underlined' }}>CLICK HERE</a>
                    </Typography>
                    ) : ''
                  }
                  {
                    isIosDevice ? (
                      <Typography color="textSecondary" gutterBottom>
                        Download the iOS Mobile App: <Link
                                                        to={{
                                                          pathname: iOSUrl
                                                        }}
                                                        target="_blank"
                                                        >CLICK HERE</Link> 
                      </Typography>
                    ) : ''
                  }
                 
                  <Typography variant="h5" component="h2" gutterBottom>
                    Tasks
                  </Typography>
                </CardContent>
              </Card>
            </React.Fragment>
          ) : null
        }
       
    
      </div>
    )

}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    user: state.user,
    perms: state.perms,
    salesReps: state.salesReps
  }
}

// export default withRouter(connect(mapStateToProps)(Home))
const mapDispatchToProps = (dispatch) => {
  // console.log('DISTPACTH: ', dispatch)
  return {
    setUrl: url => { dispatch(setUrl(url)) },
    setSalesReps: (salesReps) => { dispatch(setSalesReps(salesReps)) },
    setAuth: authenticated => { dispatch(setAuth(authenticated)) },
    setUser: (user) => { dispatch(setUser(user)) },
    setUsers: (users) => { dispatch(setUsers(users)) },
    setPerms: (perms) => { dispatch(setPerms(perms)) },
    setNotifications: notifications => { dispatch(setNotifications(notifications)) },
    setPaypal: paypal => { dispatch(setPaypal(paypal)) },
    setGPS:  (position) => { dispatch(setGPS(position)) },
    setEmployees: (employees) => { dispatch(setEmployees(employees)) },
    setTasks: (tasks) => { dispatch(setTasks(tasks)) },
    setCrews: (crews) => { dispatch(setCrews(crews)) },
    setClients: (clients) => { dispatch(setClients(clients)) },
    setServices: (services) => { dispatch(setServices(services)) },
    setFertilizationButtonActive: (fertilizationButtonActive) => { dispatch(setFertilizationButtonActive(fertilizationButtonActive)) },
    setSnoutWeevilButtonActive: (snoutWeevilButtonActive) => { dispatch(setSnoutWeevilButtonActive(snoutWeevilButtonActive)) },
    setConfigs: (configs) => { dispatch(setConfigs(configs)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
