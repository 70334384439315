import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { ThumbDown } from '@material-ui/icons';
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 5,
    width: '100%'
  },
  paper: {
    // width: '100%',
    marginTop: 10,
    marginBottom: theme.spacing(2),
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
      maxWidth: 960,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '50vh',
      maxWidth: 1360,
    },
    // margin: 20
  },
  headerText: {
    fontSize: 32,
    color: '#aaa',
    // color: 'rgb(119,156,164)',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 18,
      // color: 'red'
    },
    [theme.breakpoints.down('xs')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 14,
      // color: 'pink'
    },
    [theme.breakpoints.up('md')]: {
      // minHeight: '50vh',
      // maxWidth: 1360,
      textAlign: 'center',
      fontSize: 24,
      // color: 'green'
    }
  },
  giantText: {
    textAlign: 'center',
    fontSize: 48,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
    marginBottom: -15
  },
  subText: {
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
  },
  squigglyText: {
    textAlign: 'center',
    fontSize: 42,
    fontFamily: 'Inspiration',
    color: 'rgb(119,156,164)',
  },
  signUpText: {
    marginTop: 15,
    fontFamily: 'Amiri',
    fontSize: 32,
    textAlign: 'center',
    color: 'rgb(119,156,164)',
  },
  disclaimerText: {
    fontSize: 16,
    marginBottom: 30,
    marginTop: 15,
    color: '#aaa'
  },
  nameText: {
    fontSize: 24,
    marginTop: 10,
    color: '#aaa'
  },
  poDetailContainer: {
    width: '100%',
    height: '100%',
    margin: 10,
    padding: 10
  },
  poTitleItem: {
    fontSize: '1.3em',
    fontColor: '#ccc'
  },
  poDetailItem: {

  },
  container: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  item: {
    display: 'flex',
    margin: 5
  }
}));

function OTRequestActions(props) {
  console.log('OT Request', props)
  const otRequest = props.otRequest
  let po = {
    _id: 'asdfasdfasdf'
  }
// console.log('PO Actions ', props)
  const classes = useStyles();

  const handleDeny = async () => {
    const response = await axios.post(`${props.url}/api/employees/otApproval?id=${otRequest._id}&toApprove=0`)
  console.log('Response:', response)
    if (response && response.data && response.data.success) {
      props.notification({
        title: 'Request Denied',
        type: 'info',
        message: 'We have notified the requestor NOT to proceed with working past their normal time.'
      })
      let responseOTRequest = response.data?.updatedItem
      props.updateParent(responseOTRequest)
    }
  }

  const handleApprove = async () => {
    const response = await axios.post(`${props.url}/api/employees/otApproval?id=${otRequest._id}&toApprove=1`)
  console.log('Response:', response)
    if (response && response.data && response.data.success) {
      props.notification({
        title: 'Request Approved',
        type: 'success',
        message: 'We have notified the requestor to proceed with their work!'
      })
      let responseOTRequest = response.data?.updatedItem
      props.updateParent(responseOTRequest)
    }
  }
  const isDisabled = (otRequest.status === 'Approved' || otRequest.status === 'Denied') ? true : false
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.item}>
        <Button disabled={isDisabled} onClick={handleApprove} variant="contained" color="primary" startIcon={<ThumbUpIcon />}>
                  Approve</Button>
        </div>
      
        <div className={classes.item}>
        <Button disabled={isDisabled} onClick={handleDeny} variant="contained" color="secondary" startIcon={<ThumbDownIcon />}>
                  Deny</Button>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.item}>
          { (otRequest?.status === 'Approved' || otRequest?.status === 'Denied') ? <ApprovalDetails otRequest={otRequest} /> : null }
        </div>
    </div>
  </div>
  );
}

const ApprovalDetails = ({otRequest}) => {
  if (otRequest?.status === 'Approved') {
    return (
      <div style={{ textAlign: 'center', fontSize: '0.85em'}}>
        Approved by {otRequest?.approved_by ? otRequest?.approved_by.name : ' someone'} on { moment(otRequest?.approved_at).format('MM/DD/YY hh:mma') }
      </div>
    )
  }
  if (otRequest?.status === 'Denied') {
    return (
      <div style={{ textAlign: 'center', fontSize: '0.85em'}}>
        Denied by {otRequest?.denied_by ? otRequest?.denied_by.name : ' someone'} on { moment(otRequest?.denied_at).format('MM/DD/YY hh:mma') }
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(OTRequestActions)

