import React, { useState } from 'react';
import { connect } from 'react-redux'
import {
  Container,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Typography,
  makeStyles,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  languageToggle: {
    alignSelf: 'flex-end',
  },

}));
const handleFocus = (event) => event.target.select();

const TimeOffRequestForm = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false)
  const [isEnglish, setIsEnglish] = useState(true);
  const [name, setName] = useState('');
  const [badgeNumber, setBadgeNumber] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [notes, setNotes] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [matchingEmployees, setMatchingEmployees] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    handlePostToBackEnd()
  }

  const handlePostToBackEnd = (passedEmployeeId) => {
    setLoading(true)
    // Handle form submission logic here
    console.log({ name, badgeNumber, startDate, endDate, notes });
    // Get the phone number if it exists
    const phoneNumber = props?.match?.params?.phoneNumber
    console.log('Phone', phoneNumber)
    // Create an Absence uSing this
    let obj = { name, badgeNumber, startDate, endDate, notes, phoneNumber }
    let postURL = passedEmployeeId ? `${props.url}/api/employees/submitAbsence?type=self&employeeId=${passedEmployeeId}` : `${props.url}/api/employees/submitAbsence?type=self`
    axios({
      method: 'post',
      url:  postURL,
      data: obj,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    console.log('Axios response to update service ', response)
      setLoading(false)
      // setState({})
      // setJobNumber(response.data)
      if (response.data._id) {
        // props.updateParent('Saved', response.data)
        // setState({})
        props.notification({
          type: 'success',
          title: 'Saved Changes to this Employee!',
          message: 'Well Done!!'
        })
      }
      if (response.data && response.data.possibleMatches && response.data.possibleMatches.length) {
        props.notification({
          type: 'success',
          title: 'Multiple Possible Matching Employees',
          message: 'Please Choose Your Name From The List'
        })
        setMatchingEmployees(response.data.possibleMatches)
        setOpenDialog(true)
      }
    }).then(err => {
      console.log('Error:', err)
      setLoading(false)
    })
  };

  // const handleNameBlur = () => {
  //   const matches = searchEmployees(name);
  //   if (matches.length > 1) {
  //     setMatchingEmployees(matches);
  //     setOpenDialog(true);
  //   } else if (matches.length === 1) {
  //     setName(matches[0].name);
  //     setBadgeNumber(matches[0].badgeNumber);
  //   }
  // };

  const handleEmployeeSelect = (employee) => {
    // setName(employee.name);
    // setBadgeNumber(employee.badgeNumber);
    setOpenDialog(false);
    handlePostToBackEnd(employee?._id)
  };

  const text = {
    title: isEnglish ? 'Time Off Request' : 'Solicitud de Tiempo Libre',
    name: isEnglish ? 'Name' : 'Nombre',
    badgeNumber: isEnglish ? 'Employee Badge Number' : 'Número de Placa del Empleado',
    startDate: isEnglish ? 'Start Date' : 'Fecha de Inicio',
    endDate: isEnglish ? 'End Date' : 'Fecha de Finalización',
    notes: isEnglish ? 'Notes' : 'Notas',
    submit: isEnglish ? 'Submit Request' : 'Enviar Solicitud',
    language: isEnglish ? 'English' : 'Español',
    selectEmployee: isEnglish ? 'Select Your Profile' : 'Seleccione Su Perfil',
    division: isEnglish ? 'Division' : 'División',
  };

  return (
    <Container maxWidth="sm">
      <Paper>
      <form onSubmit={handleSubmit} className={classes.form}>
        <Typography variant="h5" align="center">
          {text.title}
        </Typography>
        
        <FormControlLabel
          control={
            <Switch
              checked={isEnglish}
              onChange={() => setIsEnglish(!isEnglish)}
              color="primary"
            />
          }
          label={text.language}
          className={classes.languageToggle}
        />

        <TextField
          label={text.name}
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          required
          onFocus={handleFocus}
        />

        <TextField
          label={text.badgeNumber}
          value={badgeNumber}
          onChange={(e) => setBadgeNumber(e.target.value)}
          fullWidth
          required
          onFocus={handleFocus}
        />

        <TextField
          label={text.startDate}
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          fullWidth
          required
          onFocus={handleFocus}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          label={text.endDate}
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          fullWidth
          required
          onFocus={handleFocus}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <TextField
          label={text.notes}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          fullWidth
          onFocus={handleFocus}
          multiline
          rows={4}
        />

        <Button type="submit" variant="contained" color="primary">
          {text.submit}
        </Button>
      </form>
      </Paper>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>{text.selectEmployee}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <List>
            {matchingEmployees.map((employee, index) => {
              // console.log('employee', employee)
              const employeeName = `${employee.firstName} ${employee.lastName}`
              return (
              <ListItem button key={index} onClick={() => handleEmployeeSelect(employee)}>
                <ListItemText 
                  primary={employeeName}
                  secondary={`${text.division}: ${employee.division}, ${text.badgeNumber}: ${employee.number}`}
                />
              </ListItem>
            )})}
          </List>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    url: state.url,
    notification: state.notification
  }
}
  
export default connect(mapStateToProps)(TimeOffRequestForm)
