import React from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';


const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const MonthEndOptions = (props) => {
// console.log('Got our MonthEndOptions', props)

const [open, setOpen] = React.useState(false);
const [modalOpen, setModalOpen] = React.useState(props.open);

const [range, setRange] = React.useState(30);
const [startDate, setStartDate] = React.useState(moment(new Date()).subtract(30, 'days').startOf('month'));
const [endDate, setEndDate] = React.useState(moment(new Date()).subtract(30, 'days').endOf('month'));
const classes = useStyles();

const handleChange = (event) => {
  setRange(event.target.value);
  let newStartDate = moment().subtract(event.target.value, 'days')
// console.log('New start Date', newStartDate)
  setStartDate(newStartDate)
  setOpen(false);
};

const handleClose = () => {
  setOpen(false);
  // props.onUpdate()
}

const handleOpen = () => {
  setOpen(true);
};

const handleSubmit = (newWay) => {
// console.log('Submit this statement!!')
  // setStartDate(moment(new Date()).subtract(30, 'days'))
  // setEndDate(new Date())
  // setRange(30)
  props.runReport({start: startDate, end: endDate})
}

const handleCloseModal = () => {
  props.closeModal('closeModal')
}
  return (
    <div>
        <Dialog
          // fullScreen={true}
          maxWidth='sm'
          open={props.open}
          // onClose={handleClose}
          PaperProps={{
            style: {
              overflow: 'hidden',
              height: '300px'
            }}}
          aria-labelledby="responsive-dialog-title"
          >
          <DialogTitle id="responsive-dialog-title">Commission Report</DialogTitle>
          <DialogContent>
            <Grid container spacing={6}>
            <Grid item lg={12} xs={12}>
              Please choose the timeframe you'd like covered by this report:
            </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  id="date"
                  label="Beginning Date"
                  type="date"
                  className={classes.paddedStuff}
                  fullWidth
                  value={moment(startDate).format('YYYY-MM-DD') } // "2017-05-24"}
                  className={classes.textField}
                  onChange={ e => setStartDate(e.target.value) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField
                  id="date"
                  label="End Date"
                  type="date"
                  className={classes.paddedStuff}
                  fullWidth
                  value={moment(endDate).format('YYYY-MM-DD') } // "2017-05-24"}
                  className={classes.textField}
                  onChange={ e => setEndDate(e.target.value) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit(4)} color="primary">
            Submit
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
    </div>
  )
 

}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(MonthEndOptions)