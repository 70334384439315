import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IconButton, Button, CircularProgress, isMuiElement, Drawer }  from '@material-ui/core';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Tooltip from '@material-ui/core/Tooltip';
import NewReminderComponent from '../Reminders/NewReminderComponent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    zIndex: 8,
    [theme.breakpoints.down('sm')]: {
      // marginLeft: theme.spacing(2),
      width: '100%',
      margin: 0
    }
  },
  supplyItemBox: { 
    margin: 5, 
    padding: 10, 
    border: '1px solid #ccc', 
    borderRadius: 10 
  },
  supplyItemTopRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  supplyItemBottomRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: 1
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '10px',
    margin: '5px'
  },
  uploadDropZone: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: '2px dashed #0658c2',
    borderRadius: 10,
    width: 300,
    height: 55,
    margin: 15,
    padding: 10,
    "&:hover": {
      backgroundColor: '#444',
      color: '#eee',
      border: '2px dashed rgb(183 224 152)',
    }
},
leadDesireContainer: {
  border: '2px solid #0658c2',
  borderRadius: 5,
  backgroundColor: '#eee',
  margin: 2,
  padding: 2,
  height: 200,
  overlow: 'hidden'
},
  leadDesireBox: {
    margin: 2,
    marginTop: -5,
    padding: 2,
    height: 150,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  topRowLeadDesires: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0
  },
  leadDesireDetails: {
    display: 'flex',
    
    // justifyContent: 'center'
  },
  bigLabelText: {
    fontSize: 22,
    color: '#aaa',
    padding: 2
  },
  medLabelText: {
    fontSize: 18,
    color: '#888',
    padding: 2
  },
  punchlistDetailsContainer: {
    // backgroundColor: 'blue',
    marginVertical: 8,
    padding: 5
  },
  punchlistDetailsItemsContainer: {
    maxHeight: '25vh',
    overflowY: 'auto',
    padding: 10
  },
  punchlistDetailsItems: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignSelf: 'stretch',
    width: '100%'
  },
  punchlistDetailsItem: {

  },
  subHeaderText: {
    fontWeight: 400,
    color: '#999'
  }
}));

const activityTypes = [
  'Phone Call',
  'Text Message',
  'Email',
  'On-Site',
  'In Office',
  'Request Pricing',
  'Send Estimate'
]

const activtyDrawerDefaultState = {
  notes: '',
  contact: {},
  activityType: 'Phone Call',
  reminder: null,
  assignedToObject: {}
}

const ActivityDrawer = (props) => {
console.log('Project Milestone drawer', props)
  const classes = useStyles()
  const [state, setState] = React.useState(activtyDrawerDefaultState)
  const [activityType, setActivityType] = React.useState(activityTypes[0])
  const [contact, setContact] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [showNewActivityDrawer, setShowNewActivityDrawer] = React.useState(false)

  React.useEffect(() => {
    console.log('Use effect 210', props)
  }, [props.milestone])

  const updateActivityType = (e, newType) => {
    setActivityType(newType)
    setState({...state, activityType: newType})
  }

  const handleSave = () => {
    let obj = {
      notes: state.notes,
      contact: state.contact,
      type: state.activityType,
      project: props.project,
      reminder: state.reminder
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/addActivity`,
      data: obj,
    }).then(response => {
      setLoading(false)
      props.handleCloseDrawer()
      if (response.data._id) {
        props.notification({
          type: 'success',
          title: 'Activity Saved!',
          message: 'This Activity Has Been Saved!'
        })
        props.updateParentWithNewActivity(response.data)
      } else {
        props.notification({
          type: 'warning',
          title: 'Uh Oh!',
          message: 'Something messed up adding this activity...not sure why!'
        })
      }
    }).catch(err => {
      // console.log('Error saving workorder: ', err)
      props.notification({
        type: 'danger',
        title: 'Uh Oh!',
        message: 'Error Number 215 - Projects - Activity Drawer'
      })
    })
  }

  const handleContactChange = (e, newContact) => {
  // console.log('Change in contacat:', newContact)
    // e.preventDefault()
    if (newContact && newContact._id) {
    // console.log('Cohntact')
      setContact(newContact)
      setState({...state, contact: newContact })
    }
  }

  const handleNewReminder = (reminderData) => {
  // console.log('Handle new reminder', reminderData)
    setState({...state, reminder: reminderData})
  }

  const handleOpenNewActivity = () => {
    setShowNewActivityDrawer(true)
  }

  const handleCloseDrawer = () => {
  // console.log('Close new Activity Drawer')
    setShowNewActivityDrawer(false)
  }

  return (
    <React.Fragment>
      <MilestoneActivityDrawer {...props} open={showNewActivityDrawer} handleCloseDrawer={handleCloseDrawer} />
      <Grid container style={{ minWidth: 550, maxWidth: 700 }}>
        <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem} style={{ backgroundColor: 'rgb(119, 156, 164)', color: '#fff', textAlign: 'center', fontSize: '20px'}} >
          <span style={{ margin: 15 }}>
              Milestone Details
          </span>
          <span onClick={props.handleCloseDrawer} style={{ cursor: 'pointer', position: 'absolute', right: 5, top: 5 }}>
            X
          </span>
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem} >
          <MilestoneSummary {...props} handleAddActivity={handleOpenNewActivity} handleAssignedToChange={props.handleAssignedToChange} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const MilestoneSummary = (props) => {
console.log('Milestone Summary', props)
  const classes = useStyles()
  const theList = props.milestone.supplies_needed || []

  return (
    <React.Fragment>
      <Grid container>
        <Grid item lg={12} xs={12} md={12} sm={12} className={classes.bigLabelText}>
          Name: {props.milestone.name}
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12} className={classes.medLabelText}>
          Status: {props.milestone.status}
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12} className={classes.medLabelText}>
          
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12} className={classes.medLabelText} style={{ marginTop: 20, marginLeft: 10 }}>
          Supply Details
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem}>
          <SuppliesList list={theList} style={{ maxHeight: 300, overflowY: 'scroll', backgroundColor: 'pink' }} />
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12} className={classes.medLabelText} style={{ marginTop: 20, marginLeft: 10 }}>
         Associated Punchlist Items:
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem}>
          <PunchlistItems milestone={props.milestone} employees={props.employees} punchlistItems={props.punchlistItems} style={{ maxHeight: 300, overflowY: 'scroll', backgroundColor: 'pink' }} />
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12} className={classes.medLabelText} style={{ marginTop: 20,  marginHorizontal: 10  }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <div>Activity History</div>
            <AddActivityButton AddActivity={props.handleAddActivity} />
          </div>
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem}>
          {/* <ActivityDetails /> */}
          {
            JSON.stringify(props.milestone.activities)
          }
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

const PunchlistItems = ({ punchlistItems, milestone, employees }) => {
  const classes = useStyles()
  console.log('348 PunchlistItems ', milestone)
  console.log(punchlistItems)
  const filteredItems = punchlistItems.filter(item => (item.milestone === milestone._id))
  return (
    <div className={classes.punchlistDetailsContainer}>
      <div># of Associated Punchlist Items: {filteredItems?.length || 0}</div>
      <div className={classes.punchlistDetailsItemsContainer}>
        <Table >
          <TableHead>
            <TableRow>
              <TableCell className={classes.subHeaderText}>Punchlist Item</TableCell>
              <TableCell className={classes.subHeaderText}>Status</TableCell>
              <TableCell className={classes.subHeaderText}>Assigned To</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              {
              filteredItems.map((item, index) => {
                console.log('356 item', item)
                let assignedTo = null
                if (item.assignedTo) assignedTo = employees.find(emp => (emp._id === item.assignedTo))
                return (
                  <TableRow key={index} className={classes.punchlistDetailsItem}>
                  
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell>{assignedTo?._id && `${assignedTo.firstName} ${assignedTo.lastName}`}</TableCell>
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>

      </div>
    </div>
  )
}

const AddActivityButton = (props) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      onClick={props.AddActivity}
    >Add Activity</Button>
  )
}

const SuppliesList = (props) => {
  const classes = useStyles()
  return (
    <div style={{ maxHeight: '350px', overflowY: 'scroll', padding: 10 }}>
      {
        props.list.map((item, index) => {
        // console.log('List Item:', item)
          return (
            <div key={index} className={classes.supplyItemBox}>
              <div className={classes.supplyItemTopRow}>
               
                <div>
                    Name: {item.name}
                </div> 
                <div>
                    QTY: {item.qty} 
                </div>  
                <div style={{ textAlign: 'right'}}>
                Vendor: {item.vendor} 
                </div>  
              </div>
              <div className={classes.supplyItemBottomRow}>

                Status: {item.status}
              </div>
            </div>
            
          )
        })
      }
    </div>
  )
}

const MilestoneActivityDrawer = (props) => {
  const handleCloseDrawer = () => {
  // console.log('Close the drawer!!!!')
    props.handleCloseDrawer()
  }
  return (
    <Drawer
        anchor={'right'}
        open={props.open}
        onClose={handleCloseDrawer}
      >
        <NewMilestoneActivityDrawer {...props} handleCloseDrawer={props.handleCloseDrawer} />
      </Drawer>
  )
}

  const NewMilestoneActivityDrawer = (props) => {
    // console.log('activity drawer', props)
    const classes = useStyles()
    const [state, setState] = React.useState(activtyDrawerDefaultState)
    const [activityType, setActivityType] = React.useState(activityTypes[0])
    const [contact, setContact] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [contacts, setContacts] = React.useState([])
  
    React.useEffect(() => {
      let ourContacts = props.contacts || []
    // console.log('Activity drawer use effect', props)
      if (props.project && props.project.contacts && props.project.contacts.length) {
  
        ourContacts = ourContacts.concat(props.project.contacts)
      // console.log('Our Contacts', ourContacts)
        
      }
      setContacts(ourContacts)
    }, [props])
  
    const updateActivityType = (e, newType) => {
      setActivityType(newType)
      setState({...state, activityType: newType})
    }
  
    const handleSave = () => {
    // console.log('SAVE ', props)
      let newActivity = {
        notes: state.notes,
        notes: state.notes,
        contact: state.contact,
        type: state.activityType,
        project: props.project,
        reminder: state.reminder
      }
      let obj = {
        activity: newActivity,
        milestoneId: props.milestone._id
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/projects/addMilestoneActivity`,
        data: obj,
      }).then(response => {
      // console.log(response)
        setLoading(false)
        props.handleCloseDrawer()
        if (response.data._id) {
          props.notification({
            type: 'success',
            title: 'Activity Saved!',
            message: 'This Activity Has Been Saved!'
          })
          props.updateParentWithNewActivity(response.data)
        } else {
          props.notification({
            type: 'warning',
            title: 'Uh Oh!',
            message: 'Something messed up adding this activity...not sure why!'
          })
        }
      }).catch(err => {
        // console.log('Error saving workorder: ', err)
        props.notification({
          type: 'danger',
          title: 'Uh Oh!',
          message: 'Error Number 215 - Projects - Activity Drawer'
        })
      })
    }
  
    const handleContactChange = (e, newContact) => {
    // console.log('Change in contacat:', newContact)
      // e.preventDefault()
      if (newContact && newContact._id) {
      // console.log('Cohntact')
        setContact(newContact)
        setState({...state, contact: newContact })
      }
    }
  
    const handleNewReminder = (reminderData) => {
    // console.log('Handle new reminder', reminderData)
      setState({...state, reminder: reminderData})
    }
  
    return (
      <React.Fragment>
        <Grid container style={{ minWidth: 300, maxWidth: 700 }}>
          <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem} style={{ backgroundColor: 'rgb(119, 156, 164)', color: '#fff', textAlign: 'center', fontSize: '20px'}} >
            <span style={{ margin: 15 }}>
                Activity Details
            </span>
            <span onClick={props.handleCloseDrawer} style={{ cursor: 'pointer', position: 'absolute', left: 10, top: 5 }}>
              X
            </span>
          </Grid>
          <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem} >
            Please Choose an Activity Type:
            <Autocomplete
              id="ActivityTpe"
              options={activityTypes || []}
              getOptionLabel={(option) => option}
              // style={{ width: 200 }}
              className={classes.paddedStuff}
              onChange={updateActivityType}
              fullWidth
              selectOnFocus
              onInputChange={(e, inputValue) => {
              // console.log('input changed', inputValue)
                setState({...state, activityType: inputValue })
              }}
              freeSolo
              // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
              value = {activityType}
              // onChange={ e => setState({...state, crew: e.target.value, requiresUpdate: true }) }
              renderInput={(params) => <TextField variant="outlined" {...params} label="Activity Type" />}
            />
          </Grid>
          <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem} >
            <Autocomplete
              id="contact"
              options={contacts || []}
              getOptionLabel={(option) => {
                return `${option.firstName || ''} ${option.lastName || ''}`
              }}
              value={contact}
              selectOnFocus
              className={classes.paddedStuff}
              onChange={handleContactChange}
              onInputChange={(e, inputValue) => {
                // console.log('input changed', inputValue)
                let newContact = {
                  firstName: inputValue
                }
                setState({...state, contact: newContact })
              }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Contact" placeholder="Who?" />
              )}
            />
          </Grid>
          <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem} >
            <TextField
                multiline
                variant="outlined"
                rows={3}
                maxRows={6}
                className={classes.paddedStuff}
                label="Notes"
                onChange={ e => setState({...state, notes: e.target.value })}
                name="notes"
                value={ state.notes }
                fullWidth
              />
          </Grid>
          <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem} >
            <NewReminderComponent {...props} className={classes.paddedStuff} disabled={!state.contact || !state.notes } updateParent={handleNewReminder} />
          </Grid>
          <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem} >
            <Button color="primary" variant="contained" fullWidth disabled={!state.contact || !state.notes } onClick={handleSave}>
              Save Activity    
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }

const MilestoneDetailsDrawer = (props) => {
  console.log('Milestone Details Drawer', props)
  const [activityDrawerOpen, setActivityDrawerOpen] = React.useState(false)

  const handleOpenDrawer = () => {
    setActivityDrawerOpen(true)
  }
  
  const handleCloseDrawer = () => {
    props.onClose(false)
  }
  return (


      <Drawer
        anchor={'left'}
        open={props.open}
        onClose={handleCloseDrawer}
      >
        <ActivityDrawer {...props} handleCloseDrawer={handleCloseDrawer} />
      </Drawer>
    
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    employees: state.employees
  }
}

export default connect(mapStateToProps)(MilestoneDetailsDrawer)
