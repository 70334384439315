import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, CircularProgress }  from '@material-ui/core';
import axios from 'axios'
import ProtectedButton from '../../actions/protectedButtons';

const useStyles = makeStyles({
  table: {
    minWidth: 600,
  },
  button: {
    width: '40px'
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

const DepositList = (props) => {
  // console.log('DepositList List Props: ', props)
  const classes = useStyles();
  const [deposits, setDeposits] = React.useState(props.deposits || [])
  const [loading, setLoading] = React.useState(false)
  // React.useEffect(() => {
  //   // console.log('Use Effect Payment List')
  //   if (props.deposits) {
  //     setDeposits(props.deposits)
  //   }
  //   // if (props.stuff && props.stuff.payments) {
  //   //   setDeposits(props.stuff.payments)
  //   // } 
    
  // }, [props])

  function currencyFormat(num) {
    // console.log('The Num: ', num)
    if (!num) num = 0
    // console.log('NUM: ', num)
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

 const editDeposit = (item) => {
   // console.log('MOdify this: ', item)
 }

 const deleteDeposit = (deposit) => {
  let obj = {
    client: props.client,
    deposit: deposit,
    user: props.user
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/payment/deleteDeposit`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to delete deposit: ', response)
    // // setJobNumber(response.data)
    // if (response.data) {
    //   // props.jobDetailsResponse(response.data)
    //   props.notification({
    //     type: 'success',
    //     title: 'Job Number' + response.data.number + ' was updated and saved!',
    //     message: 'Well Done!!'
    //   })
    //   props.jobDetailsResponse('Edited', response.data)
    //   // setOpen(false)
    // } else {
    //   // console.log('No fucking workorder')
    //   props.notification({
    //     type: 'warning',
    //     title: 'Uh Oh!',
    //     message: 'Something messed up saving this workorder...not sure why!'
    //   })
    // }
    
  }).catch(err => {
    // console.log('Error deleting deposit: ', err)
  }) 
 }
 const NotesDetail = ({ row }) => {
   // console.log('Deposit Notes Detail: ', row)
   let html = ''
   if (row.transactionId) {
     html = (
       `Transaction ID: ${row.transactionId} (${row.accountType} - ${row.accountNumber}) - ${row.notes}`
     )
   } else if (row.accountType && row.accountNumber) {
     html = (
       `${row.accountType} - ${row.accountNumber} - ${row.notes}`
     )
   } else if (row.accountType) {
     html = (
       `${row.accountType} - ${row.notes}`
     )
   } else if (row.accountNumber) {
    html = (
      `${row.accountNumber} - ${row.notes}`
    )
  } else {
     html = row.notes
   }
   return html
 }
// console.log(props.deposits)
  return (
    <div style={{maxHeight: '500px', overflowY: 'scroll'}}>
      <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
          <TableCell >Created</TableCell>
          <TableCell >How to Apply</TableCell>
            <TableCell>Invoice Number</TableCell>
            <TableCell>Job Number</TableCell>
            <TableCell >Amount</TableCell>
            <TableCell >Balance</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell >Status</TableCell>
            <TableCell >Action</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
          {props.deposits ? props.deposits.map((row) => (
            <TableRow key={row._id}>
              {/* <TableCell component="th" scope="row">
                {row.type === 'Prepayment' ? 'Deposit Used' : row.type}
              </TableCell> */}
              <TableCell >{moment(row.created_at).format('MM/DD/YYYY - hh:mm a')}</TableCell>
              <TableCell >{row.howToApply}</TableCell>
              <TableCell >{row.invoiceNumber}</TableCell>
              <TableCell >{row.jobNumber}</TableCell>
              <TableCell >{currencyFormat(row.amount || 0)}</TableCell>
              <TableCell >{currencyFormat(row.balance || 0)}</TableCell>
              <TableCell ><NotesDetail row={row} /></TableCell>
              <TableCell >{row.status}</TableCell>
              <TableCell >
                {/* {row.status !== 'Deleted' ? ( */}
                  <ProtectedButton type="Deposits" kind="delete" perms={props.perms}>
                    <Button
                      variant="contained"
                      // style={{backgroundColor: '#ed4545'}}
                      className={classes.button}
                      size="small"
                      // startIcon={<DeleteIcon />}
                      onClick={() => deleteDeposit(row)}
                      // onClick={handleDeleteService}
                      // onClick={offerDeleteOption}
                      // onClick={ () => { window.confirm('Are you sure you wish to delete this deposit? I?') ? handleDeleteService() : null } }
                    >
                      Delete
                    </Button>
                  </ProtectedButton>
                  
                {/* ) : ''} */}
                
              </TableCell>
              
            </TableRow>
          )) : null }
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    user: state.user,
    perms: state.perms
  }
}

export default React.memo(connect(mapStateToProps)(DepositList))

