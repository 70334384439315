import React, { useState, useCallback } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, TextField, Select, MenuItem, IconButton, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DropDownButton from '../../Common/DropdownButton'
import { debounce } from 'lodash';
import DeleteForever from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit'
// import { handleFocus } from '../../../services/common'
import { handleFocus } from '../../Common/index'

const crewSizes = ['1 Man', '2 Men', '3 Men', '4 Men', '5 Men']

const laborTypes = [
  {
    name: 'General Labor'
  },
  {
    name: 'Install Labor'
  },
  {
    name: 'Irrigation Tech'
  },
  {
    name: 'Spray Tech'
  },
  {
    name: 'Lighting Tech'
  },
  {
    name: 'Mowing'
  },
  {
    name: 'Operator'
  }
]

const initialLaborObject = {
  "equipmentType": "None",
  "equipmentDays": 0,
  "laborDays": 0,
  "crewSize": 0,
  "supervisionHours": 0,
  "techHours": 0,
  "sprayTechHours": 0,
  "operatorHours": 0,
  "smallCraneDays": 0,
  "bigCraneDays": 0,
  "bobcatDays": 0,
  "laborHours": 0,
  "installHours": 0,
  "mowingHours": 0
}

const handleConvertOurDataToLaborDetails = (data, props) => {
  console.log('Convert this labor shit', data)
  let returnObject = {
    ...initialLaborObject
  }
  data.forEach(item => {
    console.log('Labor item to add to our return object...', item)
    const laborType = item.laborType
    const isHours = item.type === 'Hours'
    const size = parseInt(item.size)
    let totalHours = Number(item.quantity)
    if (!isHours) {
      totalHours = 8 * totalHours
    }
    totalHours = Number(totalHours) * size
    console.log('Total Labor Hours:', totalHours)
    if (laborType === 'General Labor') {
      returnObject.laborHours = Number(returnObject.laborHours) + Number(totalHours)
    }
    if (laborType === 'Install Labor') {
      returnObject.installHours = Number(returnObject.installHours) + Number(totalHours)
    }
    if (laborType === 'Irrigation Tech') {
      returnObject.techHours = Number(returnObject.techHours) + Number(totalHours)
    }
    if (laborType === 'Spray Tech') {
      returnObject.sprayTechHours = Number(returnObject.sprayTechHours) + Number(totalHours)
    }
    if (laborType === 'Lighting Tech') {
      returnObject.techHours = Number(returnObject.techHours) + Number(totalHours)
    }
    if (laborType === 'Mowing') {
      returnObject.mowingHours = Number(returnObject.mowingHours) + Number(totalHours)
    }
    if (laborType === 'Operator') {
      returnObject.operatorHours = Number(returnObject.operatorHours) + Number(totalHours)
    }
  })
  console.log('send this upward ', returnObject)
  returnObject.breakdown = data
  props.updateParent(returnObject)
}



const LaborDetailsTable = (props) => {
  console.log('Labor Details table', props)
  const classes = useStyles()
  const [rows, setRows] = useState([
    { quantity: '', type: 'Days', size: '3 Men', laborType: 'General Labor', totalPrice: '0', suggestedPrice: '0', priceOverride: false },
  ]);

  React.useEffect(() => {
    if (props.service) {
      console.log('SEt the labor details on this service ', props.service)
      if (props.service && props.service.laborDetails) {
        console.log('We have labor details...', props.service.laborDetails)
        if (props.service.laborDetails && props.service.laborDetails.breakdown && props.service.laborDetails.breakdown.length) {
          setRows(props.service.laborDetails.breakdown)
        }
      }
    }
  }, [props.service])
  
  // React.useEffect(() => {
  //   // Trigger the debounced update function whenever proposalData changes
  //   updateParent(rows, 'interval');
  //   // // Make sure to cancel the debounced invocation on component unmount
  //   // return  () => {
  //   //   // handleSaveEstimateToBackend(estimate, props)
  //   //   updateBackend.cancel()
  //   // }
  //   console.log('Do our rows match our breakdown?? ', rows)
  //   console.log('props.service.labordetails', props.service?.laborDetails?.breakdown)
  //   if (rows === props.service?.laborDetails?.breakdown) {
  //     console.log('Match!!')
  //   } else {
  //     handleConvertOurDataToLaborDetails(rows, props)
  //   }

  // }, [rows])

  const updateParent = (newData) => {
    handleConvertOurDataToLaborDetails(newData, props)
  }
  // const updateBackend = useCallback(debounce((newData, type) => {
  //   console.log('Updating backend %S with:', type, newData);
  //   handleConvertOurDataToLaborDetails(newData, props)
  //   // Your API call to update the backend goes here
  // }, 7500), [])

  const handleRowChange = (index, field, value, isPriceOverride) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    if (isPriceOverride) updatedRows[index]['priceOverride'] = true
    if (field !== 'totalPrice') {
      updatedRows[index]['priceOverride'] = false
      updatedRows[index]['totalPrice'] = updatedRows[index]['suggestedPrice']
    }
    // setRows(updatedRows);
    handleConvertOurDataToLaborDetails(updatedRows, props)
  };

  const handleAddRow = () => {
    let updatedRows = [
      ...rows, { quantity: '', type: 'Days', size: '3 Men', laborType: 'General Labor', suggestedPrice: ''  }
    ]
    handleConvertOurDataToLaborDetails(updatedRows, props)
   
  };

  const handleDeleteRow = (index) => {
    if (window.confirm(`Are you sure you wish to delete this Labor Entry?`)) {
      const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
      // setRows(updatedRows);
      handleConvertOurDataToLaborDetails(updatedRows, props)
    }

  };

  const handleSelectLaborType = (event, rowIndex) => {
    const newRows = rows.map((row, index) => {
      if (index === rowIndex) {
        return { ...row, laborType: event.target.value, priceOverride: false, totalPrice: row.suggestedPrice };
      }
      return row;
    });
    // setRows(newRows);
    handleConvertOurDataToLaborDetails(newRows, props)
  };

  const handleDeleteLineItem = ({rowIndex, row }) => {
    // console.log('Delete Row # %d ', rowIndex)
    let newRows = [...rows]
    newRows.splice(rowIndex, 1)
    handleConvertOurDataToLaborDetails(newRows, props)
  }

  const handleSaveData = (rowIndex, data) => {
    // console.log('Save data', rowIndex)
    // console.log('Data', data)
    let newRows = [...rows]
    newRows[rowIndex] = {
      ...newRows[rowIndex],
      ...data
    }
    handleConvertOurDataToLaborDetails(newRows, props)
    handleBlur()
  }

  const handleBlur = () => {
    props.handleBlur()
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
          <TableCell className={classes.smallCell}>Labor Qty</TableCell>
          <TableCell className={classes.mediumCell}>Duration Type</TableCell>
          <TableCell className={classes.mediumCell}>Crew Size</TableCell>
          <TableCell className={classes.biggerCell}>Labor Type</TableCell>
          <TableCell className={classes.smallCell}>Total Price</TableCell>
          <TableCell className={classes.tinyCell}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
           
            // console.log('Labor ITEMLLL:: ', row)
            return (
            <TableRow key={index}>
              <TableCell className={classes.smallCell}>
                <TextField
                  value={row.quantity}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={(e) => handleRowChange(index, 'quantity', e.target.value)}
                  type="number"
                  inputProps={{min: 0, style: { textAlign: 'center' }}}
                />
              </TableCell >
            
              <TableCell className={classes.mediumCell}>
                <Select
                  value={row.type}
                  onBlur={handleBlur}
                  onChange={(e) => handleRowChange(index, 'type', e.target.value)}
                  fullWidth
                >
                  <MenuItem value="Days">Days</MenuItem>
                  <MenuItem value="Hours">Hours</MenuItem>
                </Select>
              </TableCell>
              <TableCell className={classes.mediumCell}>
                <Select
                  value={row.size}
                  onBlur={handleBlur}
                  onChange={(e) => handleRowChange(index, 'size', e.target.value)}
                  fullWidth
                >
                  {crewSizes.map((size) => (
                    <MenuItem key={size} value={size}>{size}</MenuItem>
                  ))}
                </Select>
              </TableCell>
              <TableCell className={classes.biggerCell}>
                <TextField
                  value={row.laborType}
                  select
                  onBlur={handleBlur}
                  onChange={(event) => handleSelectLaborType(event, index)}
                  fullWidth
                >
                  {
                    laborTypes.map((type, laborIndex) => {
                      // console.log('Labor type', type)
                      return (
                        <MenuItem key={laborIndex} value={type.name}>{type.name}</MenuItem>
                      )
                    })
                  }
                </TextField>
                {
                  row.notes ? `${row.notes}` : ''
                }
              </TableCell>
              <TableCell className={classes.smallCell}>
                <TextField
                  value={row.totalPrice}
                  inputProps={{min: 0, style: { textAlign: 'center' }}}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={(e) => handleRowChange(index, 'totalPrice', e.target.value, true)}
                  type="number"
                  className={row?.priceOverride ? classes.isPriceOverride : classes.textField}
                  helperText={ row?.priceOverride ? `${row?.suggestedPrice}` : '' }
                  FormHelperTextProps={{
                    className: row?.priceOverride ? classes.isPriceOverrideHelperText : ''
                  }}
                />
              </TableCell >
              <TableCell className={classes.tinyCell}>
                <ActionArea row={row} deleteItem={handleDeleteLineItem} rowIndex={index} updateParent={handleSaveData} />
              </TableCell>
            </TableRow>
          
          
          )
                
      })}
        </TableBody>
      </Table>
      <Button variant="contained" color="primary" onClick={handleAddRow}>Add Labor Row</Button>
    </>
  );
};

const ActionArea = ({ row, deleteItem, rowIndex, updateParent }) => {
  const classes = useStyles()
  const [showMenu, setShowMenu] = React.useState(false)
  const [showNotesModal, setShowNotesModal] = React.useState(false)

  const handleClose = () => {
    setShowMenu(!showMenu)
  }

  const handleOptionSelection = (d) => {
    console.log('Chose this ', d)
    if (d.name === 'Delete') {
      setShowMenu(true)
    }
    if (d.name === 'Notes') {
      setShowNotesModal(true)
    }
  }
  const handleDelete = () => {
    setShowMenu(false)
    deleteItem({
      rowIndex, 
      row
    })
  }
  const handleCloseNotesModal = () => {
    setShowNotesModal(false)
  }

  const handleSaveTheNotes = (notes) => {
    console.log('Save these notes...', notes)
    handleCloseNotesModal()
    updateParent(rowIndex, { notes: notes })
  }
  return (
    <div className={classes.actionAreaContainer}>
       <LineItemNotesModal row={row} open={showNotesModal} handleClose={handleCloseNotesModal} handleSave={handleSaveTheNotes} />
      <Dialog
        open={showMenu}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Line Item</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you Sure You Wish To Delete This Line Item?
          </DialogContentText>
          <DialogContentText>{row.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <DropDownButton
        name='Action'
        options={dropDownOptions}
        chooseOption={handleOptionSelection}
      />
    </div>
  )
}

const dropDownOptions = [
  {
    name: 'Notes',
    icon: <EditIcon />
  },
  {
    name: 'Delete',
    icon: <DeleteForever />
  },
  // {
  //   name: 'View Attachments',
  //   icon: 'DeleteIcon',
  // },
  
]

const LineItemNotesModal = ({ open, row, handleClose, handleSave }) => {
  const [state, setState] = React.useState({
    notes: ''
  })

  React.useEffect(() => {
    if (row.notes && row.notes.length) {
      setState({...state, notes: row.notes })
    } else {
      setState({...state, notes: '' })
    }
  }, [row])


  const handleChange = (e) => {
    setState({...state, notes: e.target.value })
  }

  const saveIt = () => {
    handleSave(state.notes)
  }

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        size={'md'}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Notes</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please add any relevant notes below:
          </DialogContentText>
          <TextField
            multiline
            minRows={4}
            value={state.notes}
            fullWidth
            variant="outlined"
            // onBlur={handleBlur}
            onChange={handleChange}
          
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={saveIt} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '98%',
    margin: 10
  },
  table: {
    minWidth: 650,
  },
  tableCells: {
    borderRight: '1px solid #333',
    borderLeft: '1px solid #333'
  },
  descriptionCell: {
    borderRight: '1px solid #333',
    borderLeft: '1px solid #333',
    width: '40%', // Making Description column larger
  },
  otherCell: {
    border: '1px solid #333',
    borderRight: '1px solid #333',
    borderLeft: '1px solid #333',
    // width: '5%', // Adjust other columns' width as needed
  },
  mediumCell: {
    border: '1px solid #333',
    width: '18%',
  },
  biggerCell: {
    width: '30%',
    border: '1px solid #333'
  },
  smallCell: {
    width: '10%',
    border: '1px solid #333'
  },
  tinyCell: {
    width: '5%',
    border: '1px solid #333'
  },
  isPriceOverride: {
    '& input': {
      color: 'red',
    },
  },
  isPriceOverrideHelperText: {
    color: 'red',
    textAlign: 'center'
  }
}));


export default LaborDetailsTable;
