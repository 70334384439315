import React from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: '480px',
    overflow: 'auto',
    backgroundColor: 'rgba(255, 255, 255, 0.5)'
  },
  stickyHeader: {
    position: 'sticky',
    top: 0,
    background: theme.palette.background.paper,
    zIndex: 1,
  },
  tableRow: {
    '&:not(:last-child)': {
      borderBottom: `${theme.spacing(2)}px solid ${theme.palette.background.default}`,
    },
  },
  radioGroup: {
    flexDirection: 'row',
  },
}));

const LineItemSelector = ({ currentType, setType }) => {
  const classes = useStyles()
  const handleChange = (e) => {
    if (e?.target?.value) setType(e.target.value)
  }
  return (
    <RadioGroup
      className={classes.radioGroup}
      value={currentType}
      // disabled={!selectedItems[id]?.workorder}
      onChange={handleChange}
    >
      <FormControlLabel value="materials" control={<Radio />} label="Materials" />
      <FormControlLabel value="equipment" control={<Radio />} label="Equipment" />
      <FormControlLabel value="subcontractor" control={<Radio />} label="SubContractor" />
      <FormControlLabel value="labor" control={<Radio />} label="Labor" />
    </RadioGroup>
  )
}
                          
export default LineItemSelector
