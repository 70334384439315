import React, { useState } from 'react';
import { connect } from 'react-redux'
import axios from 'axios'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Fab, Menu, MenuItem, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import ViewListIcon from '@material-ui/icons/ViewList';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AddNewOptionModal from '../../Inventory/options/AddNewOptionModal'
import { currencyFormatWithOutFlair, currencyFormat, handleFocus } from '../../../services/common';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DropDownButton from '../../Common/DropdownButton'
import LaborDetailsTable from './LaborDetailsPaperStyle';
import AddIcon from '@material-ui/icons/Add';
import EquipmentList from './EquipmentList'
import FloatingActionMenu from './PaperStyleEstimateFAB'
import AssessmentIcon from '@material-ui/icons/Assessment';
import CalculationModal from '../CalculationModal'

const initialItems = [
  { _id: 1, description: 'Item 1', qty: 1, costPer: 0, msrp: 100 },
  { _id: 2, description: 'Item 2', qty: 2, costPer: 22, msrp: 200 },
  // Add more items as needed
];

const PaperStyleLineItemComponent = (props) => {
  // console.log('Paper Style Line Items', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [serviceList, setServiceList] = React.useState([])
  
  React.useEffect(() => {
    if (props.estimate && props.estimate.serviceList && props.estimate.serviceList.length) {
      // setServiceList(props.estimate.serviceList)
      let newList = []
        console.log('We have a bunch of scopes...')
        props.estimate.serviceList.map((scope, scopeIndex) => {
          console.log('got over this cope', scope)
          if (scope && scope.services && scope.services.length) {
            console.log('Add some services', scope.services)
            scope.services.map(svc => {
              const newSvc = { ...svc, scopeId: scope._id, scopeName: scope.name, scopeIndex: scopeIndex }
              console.log('Add new service ', newSvc)
              newList.push(newSvc)
            })
            // serviceList = serviceList.concat(scope.services)
          }
        })
        console.log('Se this as our serviceList', newList)
        setServiceList(newList)
    } else {
      setServiceList([])
    }
  }, [props.estimate])

  const handleAddServiceButtonClick = () => {
    console.log('Add New Service!!')
  }

  const updateEstimateParent = (newEstimate, saveToDb) => {
    console.log('Save this estimate ', newEstimate)
    props.setEstimate(newEstimate)
    if (saveToDb) props.saveToBackEnd(newEstimate)
  }

  const handleSaveService = (serviceFromMongo) => {
    console.log('Handle Save Service', serviceFromMongo)
    if (serviceFromMongo && serviceFromMongo.type) {
      console.log('We have a type so tis not new ')
      serviceFromMongo = { ...serviceFromMongo.service }
      const serviceId = serviceFromMongo?._id
      let newServices = [...serviceList]
      let svcIndex = newServices.findIndex(svc => (serviceFromMongo._id === serviceId))
      console.log('Svc index', svcIndex)
      if (svcIndex > -1) {        
        let svcToUpdate = newServices[svcIndex]
        console.log('Service to update', svcToUpdate)
        let newSvcData = {
          ...serviceFromMongo
        }
        console.log('Updated Sevice 82', newSvcData)
        newServices[svcIndex] = newSvcData
        setServiceList(newServices)
        let newEstimate = { ...props.estimate }
        let serviceList = newEstimate.serviceList
        serviceList[0].services = newServices
        newEstimate.serviceList = serviceList
        updateEstimateParent(newEstimate)
        // props.updateParent('services', { services: newServices, scopeIndex: serviceFromMongo.scopeIndex } )
      }
        // props.updateParent
    } else {
      console.log('Add a new service')
      const svc = {
        ...serviceFromMongo,
        lineItems: [
          {
            qty: 0,
            pricePer: 0,
            totalPrice: 0,
            inventoryItem: null,
            inventoryItemId: 'TBD',
            description: '',
            _id: `TBD_${Date.now()}`
          }
        ]
      }
      console.log('Our new service to save', svc)
      console.log('Estimate services:', props.estimate)
      let currentServiceList = [...serviceList, svc]
      console.log('Set this as our updated services', currentServiceList)
      // setServiceList(currentServiceList)
      let newEstimate = { ...props.estimate }
      let serviceListNew = newEstimate.serviceList
      if (serviceListNew && serviceListNew.length) {
        serviceListNew[0].services = currentServiceList
      } else {
        serviceListNew = [
          {
            type: 'Misc',
            services: currentServiceList
          }
        ]
      }
      newEstimate.serviceList = serviceListNew
      updateEstimateParent(newEstimate)
      // props.updateParent('services', { services: currentServiceList, scopeIndex: 0 } )
    }

    // let scopeId = (svc && svc.scope) ? svc.scope.toString() : ''
    // let scopeIndex = props.scopes.findIndex(item => {
    //   // console.log('Scope item', item)

    //   return (item._id.toString() === scopeId)
    // })
    // // console.log('scope index', scopeIndex)
    // if (scopeIndex > -1) {
    //   let scopeToUse = props.categories[scopeIndex]
    //   console.log('Scope to USE', scopeToUse)
    //   let newService = {
    //     services: [],
    //     type: scopeToUse.name
    //   }
    //   if (currentServiceList && currentServiceList.length) {
    //     console.log('add service to our existing list', currentServiceList)
    //     let currentScopesIndex = [...currentServiceList].findIndex(scope => (scope.type === scopeToUse.name))
    //     if (currentScopesIndex > -1) {          
    //       let ourScopeItem = { ...currentServiceList[currentScopesIndex] }
    //       console.log('Add to sthis scope...', ourScopeItem)
    //       let newSvcsOnScope = [...ourScopeItem.services, svc]
    //       ourScopeItem.services = newSvcsOnScope
    //       currentServiceList[currentScopesIndex] = ourScopeItem
    //     } else {
    //       console.log('Add new scope and service!!!')
    //       newService.services = [svc]
    //       console.log('Add this one', newService)
    //       currentServiceList.push(newService)
    //     }
    //   } else {
    //     console.log('Add this one', newService)
    //     newService.services = [svc]
    //     currentServiceList = [newService]
    //   }
    //   console.log('Set this new service list:', currentServiceList)
    //   setServiceList(currentServiceList)
    // } else {
    //   console.log('Add this service that has no scope...')
    //   currentServiceList.push({
    //     type: 'Other',
    //     services: [svc]
    //   })
    //   setServiceList(currentServiceList)
    // }

  }

  const handleAddMaterials = (data) => {
    console.log('handle add materials', data)
    let newServices = [...serviceList]
    console.log('New services', newServices)
    let services = []
    let theServiceListIndex = -1
    let serviceIndex = -1
    newServices.map((svcListItem, svcListItemIndex) => {
      svcListItem.services.map((svcItem, index) => {
        // services.push(svcItem)
        if (svcItem._id === data.service._id) {
          theServiceListIndex = svcListItemIndex
          serviceIndex = index
        }
      })
    })
    if (theServiceListIndex > -1) {
      console.log('Use this service list Item', newServices[theServiceListIndex])
      const newServiceItem = newServices[theServiceListIndex]
      if (newServiceItem && newServiceItem.services && newServiceItem.services.length) services = newServiceItem.services

      console.log('59,', services)
      // const index = services.findIndex(item => (item._id === data.service._id))
      console.log('Index', serviceIndex)
      if (serviceIndex > -1) {
        let svc = services[serviceIndex]
        console.log('This is the services we need to add a lin eitem to', svc)
        let lineItems = []
        if (svc && svc.lineItems && svc.lineItems.length) lineItems = [ ...svc.lineItems ]
        // const servicesService = svc.servics[serviceIndex]
        let uuid = `uuid${Date.now()}-svc-${svc._id}`
        let newLineItem = {
          _id: uuid,
          description: '',
          qty: 0,
          pricePer: 0,
          costPer: 0,
          totalPrice: 0,
          unit: null
        }
        let newLineItems = [
          lineItems,
          // newLineItem
        ]
        console.log('Line items w/ new item!!', newLineItems)
        services[serviceIndex].lineItems = newLineItems
        newServices[theServiceListIndex].services = services
        console.log('New ServiceList to set', newServices)
        // setServiceList(newServices)
        let newEstimate = { ...props.estimate }
        let serviceList = newEstimate.serviceList
        serviceList.services = newServices
        newEstimate.serviceList = serviceList
        updateEstimateParent(newEstimate)
      } 
    }  
  }

  const blankLineItem = {
    qty: 0,
    pricePer: 0,
    totalPrice: 0,
    inventoryItem: null,
    inventoryItemId: 'TBD',
    description: '',
    _id: `TBD_${Date.now()}`
  }

  const handleUpdateFromLineItems = (type, data, otherState) => {
    console.log('Update line items...', type)
    console.log('Data', data)
    console.log('Other State data', otherState)
    let scopeIndex = data?.scopeIndex || 0
    if (type === 'deleteService') {
      console.log('Delete this service:', data)
      const serviceId = otherState?.service?._id
      const serviceIndex = data.index
      let currentServices = [...serviceList]
      console.log('Current Services', currentServices)
      if (serviceIndex > -1) {
        currentServices.splice(serviceIndex, 1)
        console.log('After delete ', currentServices)
        let newEstimate = { ...props.estimate }
        let newServiceList = [...newEstimate.serviceList]
        console.log('Old service list', serviceList)
        console.log('The current services to add to it ', currentServices)
        newServiceList[0].services = [...currentServices]
        console.log('Service List to update', serviceList)
        newEstimate.serviceList = newServiceList
        updateEstimateParent(newEstimate)
        // props.updateParent('saveServices', { services: currentServices, scopeIndex: scopeIndex } )
      }
    } else if (type === 'newOptionAdded') {
      console.log('We added a new option to our inventory item...', data)
      let scopeIndex = otherState?.service?.scopeIndex
      const serviceId = otherState?.service?._id
      let currentServices = [...serviceList]
      console.log('Current Services', currentServices)
      let svcIndex = currentServices.findIndex(svc => (svc._id === serviceId))
      if (svcIndex > -1) {
        let svcToUpdate = { ...currentServices[svcIndex] }
        console.log('Service to update...', svcToUpdate)
        if (svcToUpdate.lineItems && svcToUpdate.lineItems.length) {
          let rowToUpdate = { ...svcToUpdate.lineItems[otherState.rowIndex] }
          rowToUpdate.options = data.options
          rowToUpdate.option = data.options[data.options.length - 1]
          rowToUpdate.optionId = data.options[data.options.length - 1]._id
          rowToUpdate.pricePer = rowToUpdate.option?.msrp
          let totalPrice = 0
          if (parseFloat(rowToUpdate.pricePer) > 0 && parseFloat(rowToUpdate.qty) > 0) {
            totalPrice = parseFloat(Number(rowToUpdate.pricePer) * parseFloat(rowToUpdate.qty)).toFixed(2)
            rowToUpdate.totalPrice = totalPrice
          }
          let newLineItems = [ ...svcToUpdate.lineItems]
          newLineItems[otherState.rowIndex] = rowToUpdate
        
          svcToUpdate.lineItems = newLineItems
          
          console.log('Set this ', rowToUpdate)
          console.log('New Line Items', newLineItems)
          console.log('Services updated', svcToUpdate)
          currentServices[svcIndex] = svcToUpdate
          // setServiceList(currentServices)
          console.log('Save this:', currentServices)
          let newEstimate = { ...props.estimate }
          let serviceList = newEstimate.serviceList
          serviceList[0].services = currentServices
          newEstimate.serviceList = serviceList
          updateEstimateParent(newEstimate)
          // props.updateParent('services', { services: currentServices, scopeIndex: scopeIndex } )
        }
      }
    } else if (type === 'updateEquipment') {
        console.log('Update our Equipment!!!!')
        let newEstimate = { ...props.estimate, equipmentDetails: data }
        console.log('Save this estimate', newEstimate)
        updateEstimateParent(newEstimate, true)
    } else if (type === 'updateDumpingAndHauling') {
      console.log('Update our updateDumpingAndHauling!!!!')
      let newEstimate = { ...props.estimate, ...data }
      console.log('Save this estimate', newEstimate)
      updateEstimateParent(newEstimate, true)
    } else if (type === 'updateSupervision') {
      console.log('Update our updateSupervision!!!!')
      let newEstimate = { ...props.estimate, ...data }
      console.log('Save this estimate', newEstimate)
      updateEstimateParent(newEstimate, true)
    } else if (type === 'save') {
      console.log('Save this item...', data)
      // const { scopeIndex, rowIndex, data, serviceIndex } = data
      // console.log('Update Our Service lsit...Scope: %d   Service: %d   Row: %d ', scopeIndex, serviceIndex, rowIndex)
      let currentServices = [...serviceList]
      console.log('Current services', currentServices)
      const {
        serviceIndex,
        scopeIndex,
        rowIndex
      } = data
      const updatedRow = { ...data.data }
      console.log('Updated Row', updatedRow)
      if (serviceIndex > -1) {
        let svcToUpdate = currentServices[serviceIndex]
        console.log('Service to update', svcToUpdate)
        let lineItems = [ ...svcToUpdate.lineItems ]
        if (rowIndex > -1) {
          lineItems[rowIndex] = updatedRow
          svcToUpdate.lineItems = lineItems
          console.log('Srevice Updated w/ new line items', svcToUpdate)
          currentServices[serviceIndex] = svcToUpdate
          console.log('Set these as our servcis', currentServices)
          // setServiceList(currentServices)
          let newEstimate = { ...props.estimate }
          let serviceList = newEstimate.serviceList
          serviceList[scopeIndex].services = currentServices
          newEstimate.serviceList = serviceList
          updateEstimateParent(newEstimate)
        }
      }
      // let svcIndex = currentServices.findIndex(svc => (svc._id === serviceId))
      // if (svcIndex > -1) {
    } else {
      console.log('Gotta Update/Change some line items....probably just replacing the serviceList entirely')
      const newValue = data.value
      const serviceId = data.serviceId
      console.log('Service List', serviceList)
      console.log('New value', newValue)
      let currentServices = [...serviceList]
      let svcIndex = currentServices.findIndex(svc => (svc._id === serviceId))
      if (svcIndex > -1) {
        console.log('We already have this service...use it', svcIndex)
        let svcToUpdate = { ...currentServices[svcIndex] }
        console.log('Svc To Update', svcToUpdate)
        let lineItems = [blankLineItem]
        if (svcToUpdate && svcToUpdate.lineItems && svcToUpdate.lineItems.length) lineItems = [ ...svcToUpdate?.lineItems ]
        if (data?.rowId?.includes('TBD_')) {
          console.log('This is a new row we need to convert...')
        } else {
          console.log('Not a new row...')
        }
        console.log('Line Items to go over', lineItems)
        let itemToUpdate = { ...lineItems[data.rowIndex] }
        console.log('Update this guy:', itemToUpdate)
        let itemUpdated = { ...itemToUpdate, [data.id]: data.value }
        if (type === 'chooseItem') {
          itemToUpdate.pricePer = 0
          itemToUpdate.totalPrice = 0
        }
        if (data.id === 'inventoryItem') {
          console.log('This is our inventory item..', itemUpdated)
          
          // console.log('UUID: ', uuid)
          // Create our Line Item
          console.log('Make our Proposal Line Item')
          let newValue = {
            ...data.value
          }
          if (!data.value) {
            newValue = {
              qty: 0,
              pricePer: 0,
              totalPrice: 0,
              inventoryItem: null,
              inventoryItemId: 'TBD',
              description: '',
              _id: `TBD_${Date.now()}`
            }
          }
          const activeInventoryItem = { ...newValue }
          let uuid = `uuid${Date.now()}-${activeInventoryItem._id}_${serviceId}`
          console.log('our Inventory item to save', activeInventoryItem)
          let cleanItem = {
            _id: uuid,
            qty: itemToUpdate.qty,
            name: activeInventoryItem.name,
            altName: activeInventoryItem.altName,
            // option: itemToUpdate.option,
            // unit: itemToUpdate.option.size || '',
            description: activeInventoryItem.description,
            totalPrice: itemToUpdate.totalPrice,
            // costPer: itemToUpdate.costPer,
            pricePer: itemToUpdate.pricePer,
            // lastPriceUpdate: item.option.last_price_update || null,
            inventoryItemId: activeInventoryItem._id,
            // optionId: item.option._id || null,
            templatedDescription: activeInventoryItem.templatedDescription ? activeInventoryItem.templatedDescription : null,
            isDefault: activeInventoryItem.isDefault ? activeInventoryItem.isDefault : false
          }
          console.log('Clean Item to save', cleanItem)
          itemUpdated = { ...cleanItem }
        }
        if (data.id === 'option') {
          itemUpdated.optionId = data.value._id
          if (data.value.msrp) itemUpdated.pricePer = data.value.msrp
          if (data.value.price) itemUpdated.costPer = data.value.price
          if (itemUpdated.qty) {
            let newPricePer = itemUpdated.qty * itemUpdated.pricePer
            itemUpdated.totalPrice = currencyFormatWithOutFlair(newPricePer)
          }
        }
        console.log('Item updated', itemUpdated)
        lineItems[data.rowIndex] = itemUpdated
        // Add a line item if this is our last one
        let totalRows = lineItems.length
        if (totalRows - 1 === data.rowIndex) {
          console.log('This is our LAST ROW...')
          let newRow = {
            qty: 0,
            pricePer: 0,
            totalPrice: 0,
            inventoryItem: null,
            inventoryItemId: 'TBD',
            description: '',
            _id: `TBD_${Date.now()}`
          }
          lineItems.push(newRow)
        }
        svcToUpdate.lineItems = lineItems
        console.log('Srevice Updated w/ new line items', svcToUpdate)
        currentServices[svcIndex] = svcToUpdate
        console.log('Set these as our servcis', currentServices)
        // setServiceList(currentServices)
        let newEstimate = { ...props.estimate }
        let serviceList = newEstimate.serviceList
        serviceList[0].services = currentServices
        newEstimate.serviceList = serviceList
        updateEstimateParent(newEstimate)
        // props.updateParent('services', { services: currentServices, scopeIndex: scopeIndex } )
      }
    }
  }

  const handleSaveEstimateDescription = (data, index) => {
    console.log('Handle Save Estimate Description', data)
    let newEstimate = { ...props.estimate }
    let serviceList = newEstimate.serviceList
    let currentServices = [...serviceList[0].services]
    let svcToUpdate = { ...currentServices[index], description: data.description }
    currentServices[index] = svcToUpdate
    console.log('Save this updated description', svcToUpdate)
    serviceList[0].services = currentServices
    newEstimate.serviceList = serviceList
    updateEstimateParent(newEstimate)
    // props.updateParent('services', { services: serviceList, scopeIndex: data.scopeIndex } )
  }

  return (
    <div className={classes.root}>
      <LineItemTable objectives={props.objectives} estimate={props.estimate} services={props.services} sizes={props.sizes} takeoffs={props.takeoffs} categories={props.categories} serviceList={serviceList} addMaterialsToService={handleAddMaterials} handleAddServiceButtonClick={handleAddServiceButtonClick} saveService={handleSaveService} updateParent={handleUpdateFromLineItems} saveDescription={handleSaveEstimateDescription} saveToBackEnd={props.saveToBackEnd} />
    </div>
  )
}

const defaultStateVals = {
  option: null,
  unit: null,
  inventoryItem: null,
  service: null,
  scopeIndex: null,
  rowIndex: null,
  row: null,
  dumpingAndHaulingFees: 0,
  supervisionFee: 0
}

const unitsOfMeasureAll = [
  {
    value: 'Each',
    label: 'Each'
  },
  {
    value: 'Flat',
    label: 'Flat'
  },
  {
    value: 'Pint',
    label: 'Pint'
  },
  {
    value: 'Quart',
    label: 'Quart'
  },
  {
    value: '1 Gallon',
    label: '1 Gallon'
  },
  {
    value: '2 Gallon',
    label: '2 Gallon'
  },
  {
    value: '3 Gallon',
    label: '3 Gallon'
  },
  {
    value: '5 Gallon',
    label: `5 Gallon`
  },
  {
    value: '10 Gallon',
    label: `10 Gallon`
  },
  {
    value: '15 Gallon',
    label: `15 Gallon`
  },
  {
    value: '25 Gallon',
    label: `25 Gallon`
  },
  {
    value: 'Bare Root',
    label: `Bare Root`
  },
  {
    value: `24" Box`,
    label: `24" Box`
  },
  {
    value: `30" Box`,
    label: `30" Box`
  },
  {
    value: `32" Box`,
    label: `32" Box`
  },
  {
    value: `36" Box`,
    label: `36" Box`
  },
  {
    value: `42" Box`,
    label: `42" Box`
  },
  {
    value: `48" Box`,
    label: `48" Box`
  },
  {
    value: `54" Box`,
    label: `54" Box`
  },
  {
    value: `60" Box`,
    label: `60" Box`
  },
  {
    value: `66" Box`,
    label: `66" Box`
  },
  {
    value: `70" Box`,
    label: `70" Box`
  },
  {
    value: `72" Box`,
    label: `72" Box`
  },
  {
    value: `78" Box`,
    label: `78" Box`
  },
  {
    value: `84" Box`,
    label: `84" Box`
  },
  {
    value: 'Square Foot',
    label: 'Square Foot'
  },
  {
    value: 'Linear Foot',
    label: 'Linear Foot'
  },
  {
    value: 'Flat',
    label: 'Flat'
  },
  {
    value: 'Ton',
    label: 'Ton'
  },
  {
    value: 'create',
    label: 'Add New Size'
  }
]

const LineItemTable = (props) => {
  console.log('Line Item Table', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState(defaultStateVals)
  const [showNewOptionModal, setShowNewOptionModal] = React.useState(false)
  const [showCalculationModal, setShowCalculationModal] = React.useState(false)

  const handleItemChange = (_id, type, value) => {
    console.log(_id, type, value)
  }

  console.log('Deal w/ our scopeList list', props.scopeList)

  let serviceList = []
  if (props.serviceList && props.serviceList.length) serviceList = props.serviceList
  // if (props.scopeList && props.scopeList.length) {
  //   console.log('We have a bunch of scopes...')
  //   props.scopeList.map(scope => {
  //     console.log('got over this cope', scope)
  //     if (scope && scope.services && scope.services.length) {
  //       console.log('Add some services', scope.services)
  //       scope.services.map(svc => {
  //         const newSvc = { ...svc, scopeId: scope._id, scopeName: scope.name }
  //         console.log('Add new service ', newSvc)
  //         serviceList.push(newSvc)
  //       })
  //       // serviceList = serviceList.concat(scope.services)
  //     }
  //   })
  // }

  const handleUnitChange = (e, newUnit) => {
    // console.log('Handle unit change', e.target.value)
    // console.log('new unit', newUnit)
    // setState({...state, unit: newUnit})
    // setUnit(newUnit)
    if (newUnit && newUnit.value === 'create') {
      setShowNewOptionModal(true)
    } else {
      setState({...state, unit: newUnit })
    }
  }

  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      console.log(e.target)
      const { id, value } = e.target
      console.log('%s: %s ', id, value)
      let newArr = []
      let string = e.target.value
      if (e.target.id === 'chooseUnit') {

        unitsOfMeasureAll.filter(o => {
          let str = o['label'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          // setState({...state, salesRep: newArr[0]})
          handleUnitChange(e, newArr[0])
        }
      }
      if (e.target.id === 'chooseOption') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        props.inventoryItem.options.filter(o => {
          let str = o['name'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          // setState({...state, salesRep: newArr[0]})
          handleChooseOptionByAutoComplete(e, newArr[0])
        }
      }
    }
  }

  const handleChooseOption = (e) => {
    console.log('Choose option:', e.target.value)
    const optionChosen = e.target.value
    if (optionChosen === 'create') {
      console.log('Create a new option')
      setShowNewOptionModal(true)
    } else {
      let pricePer = 0
      let newState = {}
      // Get the last price
      if (optionChosen && optionChosen._id) {
        newState.option = optionChosen._id
        let unitObj = {
          label: e.target.value.size,
          value: e.target.value.size
        }
        if (e.target.value && e.target.value.price && e.target.value.price > 0) {
          let markup = 2.25
          if (props.estimate && props.estimate.entity && props.estimate.entity === '640a56404965b71a9edd0e7d') {
            markup = 1.15
          }
          pricePer = parseFloat(e.target.value.price * markup).toFixed(2)
          newState.costPer = optionChosen.price
        }
        newState.unit = unitObj
        if (optionChosen && optionChosen.msrp) pricePer = parseFloat(optionChosen.msrp).toFixed(2)
        console.log('Set Price per;', pricePer)
        newState.pricePer = pricePer
        newState.totalPrice = Number(pricePer * state.qty).toFixed(2)
        newState.option = optionChosen
        console.log('SEt it', newState)
        setState({...state, ...newState })
      }
    }
  }

  const handleChooseOptionByAutoComplete = (rowId, service, chosenOption) => {
    console.log('Choose by autocomplete!!')
    console.log('Row ID', rowId)
    console.log('service', service)
    console.log('Value::', chosenOption)
    let newServices = [...serviceList]
    if (rowId && chosenOption && chosenOption._id) {
      console.log('Find the service to update')
      let svcIndex = newServices.findIndex(svc => (svc._id === service._id))
      console.log('Svc index', svcIndex)
      if (svcIndex > -1) {        
        let svcToUpdate = newServices[svcIndex]
        console.log('Service to update', svcToUpdate)
        let newLineItems = [ ...svcToUpdate.lineItems ]
        let lineItemIndex = [...newLineItems].findIndex(lineItem => (lineItem._id.toString() === rowId.toString()))
        console.log('Line Item index', lineItemIndex)
        if (lineItemIndex > -1) {
          let lineItemToUpdate = { ...newLineItems[lineItemIndex] }
          console.log('Update this line item', lineItemToUpdate)
          if (lineItemToUpdate && lineItemToUpdate.inventoryItem && lineItemToUpdate.inventoryItem.options && lineItemToUpdate.inventoryItem.options.length) {
            const lineItemOptions = [ ...lineItemToUpdate.inventoryItem.options ]
            let optIndex = lineItemOptions.findIndex(opt => (opt._id === chosenOption._id))
            let selectedOption = lineItemOptions[optIndex]
            lineItemToUpdate.option = selectedOption
            lineItemToUpdate.optionId = selectedOption._id
            console.log('Selected Option..', selectedOption)
            console.log('399')
            newLineItems[lineItemIndex] = lineItemToUpdate
            // newServices.lineItems = newLineItems
            const data = {
              services: newServices,
              scopeId: service.scope
            }
            // props.updateParent('optionChosen', data)
          }
        }
      }
    }
    // console.log('Choose by index', e)
    // console.log('New item', newItem)
    // if (newItem && newItem.value === 'create') {
    //   setShowNewOptionModal(true)
    // } else if (newItem && newItem._id) {
    //   handleChooseOption({
    //     target: {
    //       value: newItem
    //     }
    //   })
    // } else {
    //   handleClearOption()
    // }
  }



  const updateServiceDetails = async (dataToUpdate, svcIndex) => {
    console.log('On Service ID: %s update this: %o ', svcIndex, dataToUpdate)
    let newServices = [...serviceList]
    // let svcIndex = newServices.findIndex(svc => (svc._id === serviceId))
    console.log('Svc index', svcIndex)
    if (svcIndex > -1) {        
      let svcToUpdate = newServices[svcIndex]
      console.log('Service to update', svcToUpdate)
      let newSvcData = {
        ...svcToUpdate,
        ...dataToUpdate
      }
      console.log('Updated Sevice', newSvcData)
      // newServices[svcIndex] = newSvcData
      // setServices(newServices)
      // props.updateParent
      props.saveService({ type: 'update', service: newSvcData })
    }
  }

  const handleClearOption = () => {
    const newState = {
      option: null,
      // costPer: 0,
      // pricePer: 0,
      // totalPrice: 0,
      // description: ''
    }
    setState({...state, ...newState })
  }

  const handleAddMaterials = (data) => {
    console.log('Add materials to this:', data)
    props.addMaterialsToService(data)
  }

  const handleSaveDescription = (svc, index) => {
    props.saveDescription(svc, index)
  }

  const handleCloseNewOptionModal = (type, d) => {
    console.log('Type', type)
    console.log('Close mdoal', d)
    console.log('Inventory item', props.inventoryItem)
    setShowNewOptionModal(false)
    let newState = { ...state }
    if (type === 'newOptionAdded') {
      // console.log('Current State adding new option', state)
      // Item w/ new options returned
      // d = the item
      console.log('Adding new option...', newState)
      const optionToSave = d.options[d.options.length -1]
      console.log('The last option is our new one', optionToSave)
      let unitObj = {
        label: optionToSave.size.name,
        value: optionToSave.size.name
      }
      let pricePer = 0
      if (optionToSave.msrp && parseFloat(optionToSave.msrp) > 0) {
        pricePer = parseFloat(optionToSave.msrp).toFixed(2)
      }
      newState.unit = unitObj
      newState.option = optionToSave
      newState.pricePer = pricePer
      newState.totalPrice = Number(pricePer * state.qty).toFixed(2)
      console.log('Set our state w/ new option...', newState)
      if (!newState.scopeIndex && newState?.service?.scopeIndex) newState.scopeIndex = newState.service.scopeIndex
      // setState({...state, ...newState })
      props.updateParent(type, d, newState)
    } else if (d && d.name) {
      // Add this new option...
      console.log('Add a new option', d)
      const optionToSave = {
        name: d.name,
        msrp: d.msrp,
        notes: d.notes,
        takeOff: props.inventoryItem._id,
        size: d?.size?.name,
        sizeId: d?.size?._id
      }
      console.log('Save thi soption', optionToSave)
      let unitObj = {
        label: optionToSave.size.name,
        value: optionToSave.size.name
      }
      let pricePer = 0
      if (optionToSave.msrp && parseFloat(optionToSave.msrp) > 0) {
        pricePer = parseFloat(optionToSave.msrp).toFixed(2)
      }
      newState.unit = unitObj
      newState.pricePer = pricePer
      newState.totalPrice = Number(pricePer * state.qty).toFixed(2)
      console.log('Set our state w/ new option...', newState)
      setState({...state, ...newState })
      props.updateParent('newOption', optionToSave)
    }
  }

  const addNewOptionToService = (service, row, rowIndex) => {
    console.log('Add new option to this service', service)
    console.log('Row', row)
    console.log('Row index', rowIndex)
    setState({
      ...state,
      inventoryItem: row?.inventoryItem,
      service: service,
      rowIndex: rowIndex,
      row: row
    })
    setShowNewOptionModal(true)
  }

  const handleDeleteLineItem = ({service, serviceIndex, rowIndex, row }) => {
    console.log('Delete this one', row)
    let newServices = [...serviceList]

    console.log('Svc index', serviceIndex)
    if (serviceIndex > -1) {        
      let svcToUpdate = newServices[serviceIndex]
      console.log('Service to update', svcToUpdate)
      let lineItems = [...svcToUpdate?.lineItems ]
      if (rowIndex > -1) {
        lineItems.splice(rowIndex, 1)
        console.log('Line items after deletion', lineItems)
        svcToUpdate.lineItems = lineItems
        console.log('Updated Sevice after deleting line item...', svcToUpdate)  
        props.saveService({ type: 'update', service: svcToUpdate })
      }
    }
  }

  const handleDeleteService = (service, index) => {
    if (window.confirm(`Are you sure you wish to delete this service??`)) {
      console.log('Delete service', service, index)
      props.updateParent('deleteService', { serviceId: service._id, index: index } )
    }
  }

  const handleBlur = () => {
    props.saveToBackEnd()
  }

  const handleUpdateEquipment = (d) => {
    console.log('Update Equipment', d)
    props.updateParent('updateEquipment', d)
  }

  const handleUpdateDumpingAndHauling = (data) => {
    console.log('Dumping ANd Hauling data:', data)
    props.updateParent('updateDumpingAndHauling', data)
  }

  const handleUpdateSupervision = (data) => {
    console.log('SUpervision Fee:', data)
    props.updateParent('updateSupervision', data)
  }

  const calculateStuffForThisService = (svc, svcIndex) => {
    let newState = {
      ...state,
      service: svc
    }
    setState({...state, ...newState})
    setShowCalculationModal(true)
  }
  
  const handleCloseCalculationModal = () => {
    setShowCalculationModal(false)
  }
  const addCalculationsToEstimate = (data) => {
    console.log('Calculation response', data)
    setShowCalculationModal(false)
    console.log('Current svc', state.service)
    const svcToUpdate = {
      ...state.service
    }
    let laborDetails = svcToUpdate.laborDetails
    console.log('Labor Details currently', laborDetails)
    const laborDaysFor4ManCrew = data?.details?.laborAndEquipment?.totalLaborDays
    const totalLaborCost = Number(data?.details?.totalLaborCost) || 0
    console.log('Labor Cost', totalLaborCost)
    const newLaborItem = {
      laborType: 'Install Labor',
      size: '4 Men',
      type: 'Days',
      quantity: laborDaysFor4ManCrew,
      totalPrice: totalLaborCost,
      suggestedPrice: totalLaborCost,
      priceOverride: true
    }
    let laborBreakdown = laborDetails.breakdown || []
    let newTotalInstallHours = Number(laborDetails.installHours) + (8 * laborDaysFor4ManCrew)
    laborBreakdown.push(newLaborItem)
    let newLaborDetails = {
      ...laborDetails,
      breakdown: laborBreakdown,
      installHours: newTotalInstallHours
    }
    svcToUpdate.laborDetails = newLaborDetails
    console.log('Labor Updated after calculation...')
    props.saveService({ type: 'update', service: svcToUpdate })
  }
  let allSvcs = []
  if (props.services && props.services.length) allSvcs = props.services
  // console.log('All Services lenght', allSvcs.length)
  let filteredServices = allSvcs.filter(item => {
    // console.log('907', item)
    let serviceExists = false
    serviceList.forEach(svc => {
      // console.log('910 svc', svc)
      if (svc && svc._id === item._id) serviceExists = true
    })
    if (!serviceExists) return item
    return null
  })
  // console.log('Filtered SErvices', filteredServices.length)
  // console.log('All of our included services...', serviceList)
  
  const createOption = {
    _id: 'create',
    value: 'create',
    label: 'Create New',
    name: 'Add New Option'
  }
  const ourOptions = [ createOption ] 
  let scope = {
    name: 'Scope Name',
    _id: '448489348349'
  }
  return (
    <div className={classes.estimateLineItemsContainer}>
      <AddNewOptionModal scope={scope} item={state.inventoryItem} sizes={props.sizes} open={showNewOptionModal} closeModal={handleCloseNewOptionModal} />
      <CalculationModal open={showCalculationModal} estimate={props.estimate} data={state} service={state.service} closeModal={handleCloseCalculationModal} updateParent={addCalculationsToEstimate} />
    <Table className={classes.table} aria-label="editable table">
        <TableBody >
        {serviceList.map((service, index) => {
              // console.log('Service 601:', service)
              let lineItems = service.lineItems || [{
                qty: 0,
                pricePer: 0,
                totalPrice: 0,
                inventoryItem: null,
                inventoryItemId: 'TBD',
                description: '',
                _id: `TBD_${Date.now()}`
              }]

              const handleLaborUpdate = (data) => {
                console.log('Update labor on this servicve...', service)
                console.log('Labor Details', data)
                const svcToUpdate = {
                  ...service,
                  laborDetails: data
                }
                props.saveService({ type: 'update', service: svcToUpdate })
              }

              const handleServiceOption = (opt) => {
                console.log('Chose ', opt)
                if (opt && opt.functionToCall) {
                  if (opt.functionToCall === 'deleteService') {
                    handleDeleteService(service, index)
                  }
                }
              }
             
              const handleCalculate = () => {
                console.log('Run Calculation...')
                calculateStuffForThisService(service, index)
              }
              // let uuid = `uuid${Date.now()}-svc-${service._id}`
              // lineItems.push({
              //   qty: '',
              //   value: '',
              //   label: '',
              //   name: '',
              //   unit: '',
              //   _id: uuid,
              //   uuid: uuid
              // })
              return (
                <TableRow key={index} >
                   <TableCell colSpan={6} > 
                    <Table aria-label="editable table">
    
                      <TableBody className={classes.serviceTableBody}> 
              
               
                {/* This TableRow acts as the service name header */}
                <TableRow className={classes.serviceAreaRow}>
                  <TableCell colSpan={5} className={classes.subHeaderText}>{service.name}</TableCell>
                  <TableCell>
                    <div className={classes.rowStyleContainer}>
                      <Tooltip title="Calculate" >
                        <IconButton edge='end' onClick={handleCalculate} alt="Calculate" aria-label="Calculate">
                          <AssessmentIcon />
                        </IconButton>
                      </Tooltip>
                      <DropDownButton
                        name='Modify Service'
                        options={serviceOptions}
                        chooseOption={handleServiceOption}
                      />  
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow className={classes.serviceAreaRow}>
                  <TableCell colSpan={6}>
                    <DescriptionDetails objectives={props.objectives} service={service} serviceIndex={index} updateServiceDetails={updateServiceDetails} handleSaveDescription={handleSaveDescription} />
                  </TableCell>
                </TableRow>
                <TableRow style={{ border: '1px solid #333'}}>
                  <TableCell align="right" style={{ border: '1px solid #333' }}>Quantity</TableCell>
                  <TableCell align="center" style={{ border: '1px solid #333' }}>Size/Option</TableCell>
                  <TableCell style={{ border: '1px solid #333' }}>Description</TableCell>
                  {/* <TableCell align="right" style={{ border: '1px solid #333' }}>Cost Per ($)</TableCell> */}
                  <TableCell align="right" style={{ border: '1px solid #333' }}>Price Per ($)</TableCell>
                  <TableCell align="right" style={{ border: '1px solid #333' }}>Total ($)</TableCell>
                  <TableCell align="right" style={{ border: '1px solid #333' }}>Action</TableCell>
                </TableRow>
                  {lineItems.map((row, rowIndex) => {
                    // console.log('Row Item 639::::', row)

                    const handleAutoCompleteChooseOption = (e, optChosen) => {
                      // console.log('Option Chosen', optChosen)
                      // console.log('Val', e.target.value)
                      // console.log('row', row)
                      // console.log('service', service)
                      if (optChosen?.value === 'create') {
                        // console.log('CREATE A NEW OPTION')
                        addNewOptionToService(service, row, rowIndex)
                      } else {
                        const itemToChange = {
                          rowId: row._id,
                          rowIndex: rowIndex,
                          serviceId: service._id,
                          id: 'option',
                          value: optChosen,
                          scopeIndex: service.scopeIndex
                        }
  
                        props.updateParent('chooseOption', itemToChange)
                      } 
                    }

                    const handleChange = (e) => {
                      // console.log('Handle change', e.target.value)
                      // console.log('Row', row)
                      // console.log('Index', rowIndex)
                      const itemToChange = {
                        rowId: row._id,
                        rowIndex: rowIndex,
                        serviceId: service._id,
                        id: e.target.id,
                        value: e.target.value,
                        scopeIndex: service.scopeIndex
                      }
                      // console.log('Handle Change', itemToChange)
                      props.updateParent('change', itemToChange)
                    }

                    const handleAutoCompleteChooseTakeOff = (e, newItem) => {
                      // console.log('choose this item', newItem)
                      // console.log('Service', service)
                      // console.log('Index', index)
                      const itemToChange = {
                        rowId: row._id,
                        rowIndex: rowIndex,
                        serviceId: service._id,
                        id: 'inventoryItem',
                        value: newItem,
                        scopeIndex: service.scopeIndex
                      }
                      // console.log('Autocomplete takeoff chosen', itemToChange)
                      props.updateParent('chooseItem', itemToChange)
                    }

                  

                    let inventoryItemId = null
                    let inventoryOption = null
                    if (row && row.inventoryItemId) inventoryItemId = row.inventoryItemId
                    if (inventoryItemId) {
                      // console.log('Find Inventory item', inventoryItemId)
                      let itemIndex = props.takeoffs.findIndex(to => (to._id === inventoryItemId))
                      if (itemIndex > -1) {
                        // console.log('Use this guy', props.takeoffs[itemIndex])
                        inventoryItemId = props.takeoffs[itemIndex]._id
                        inventoryOption = props.takeoffs[itemIndex]
                        row.inventoryItem = inventoryOption
                      }
                    }

                    const handleUpdateEstimate = () => {
                      const itemToSave = {
                        serviceId: service._id,
                        scopeIndex: service.scopeIndex,
                        services: serviceList
                      }
                      // props.updateEstimate(itemToSave)
                    }

                    const handleSaveItemFromActionArea = (data) => {
                      console.log('sAVE THIS ITEM NOTES...1102', data)
                      console.log('Current row', row)
                      const itemToChange = {
                        ...row,
                        ...data
                      }
                      console.log('Handle Change Notes ', itemToChange)
                      const objToUpdate = {
                        data: itemToChange,
                        rowId: row._id,
                        rowIndex: rowIndex,
                        serviceId: service._id,
                        serviceIndex: index,
                        scopeIndex: service.scopeIndex || 0
                      }
                      props.updateParent('save', objToUpdate)
                    }

                    const createOption = {
                      _id: 'create',
                      value: 'create',
                      label: 'Create New',
                      name: 'Add New Option'
                    }
                    let ourOptions = [ createOption ]
                    let options = null
                    if (row && row.inventoryItem  && row.inventoryItem.options && row.inventoryItem.options.length) {
                      ourOptions = [...row.inventoryItem.options || [], createOption ]
                      options =  [...row.inventoryItem.options || [], createOption ]
                    }
                    const isPriceDiscounted = (Number(row?.option?.msrp) > Number(row.pricePer))
                    const isPricedAtMSRP =  (Number(row?.option?.msrp) === Number(row.pricePer))
                    // console.log('ROW:: 660', row)
                    // console.log('R 662 ', ourOptions)
                    return (
                    <TableRow key={rowIndex} >
                    
                      <TableCell align="right" className={classes.otherCell}>
                        <TextField
                          value={row.qty}
                          id="qty"
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          // onChange={(e) => handleItemChange(row._id, 'quantity', e.target.value)}
                          type="number"
                          fullWidth
                        />
                      </TableCell>
                      <TableCell align="center" className={classes.mediumCell}>
                        
                      {
                          (row.inventoryItemId) ? (
                          <Autocomplete
                              id="chooseOption"
                              options={ourOptions || []}
                              getOptionLabel={(option) => {
                                return `${option.name}`
                              }}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={row.option}
                              selectOnFocus
                              disabled={!row.inventoryItem}
                              // onBlur={handleUpdate}
                              onChange={handleAutoCompleteChooseOption}
                              // onChange={e => handleChooseOptionByAutoComplete(row._id, service, e.target.value )}
                              renderInput={(params) => (
                                <TextField {...params} label="Option" placeholder="Which Option?" onKeyDown={handleTabInteraction} />
                              )}
                            />
                          ) : (
                            <Autocomplete
                              id="chooseUnit"
                              options={unitsOfMeasureAll || []}
                              getOptionLabel={(option) => {
                                return `${option.label}`
                              }}
                              value={state.unit}
                              selectOnFocus
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              isClearable={true}
                              // onBlur={handleUpdate}
                              onChange={handleUnitChange}
                              renderInput={(params) => (
                                <TextField {...params} label="Option" placeholder="Which Unit?" onKeyDown={handleTabInteraction} />
                              )}
                          />
                          )
                        }
                      </TableCell>
                      <TableCell className={classes.descriptionCell}>
                        {
                          (row.inventoryItemId) ? (
                            <Autocomplete
                              id="takeOffItem"
                              options={props.takeoffs || []}
                              getOptionLabel={(option) => {
                                return `${option.name} ${option.description ? ` - ${option.description.substring(0, 79)}${option.description.length > 79 ? '...' : ''}` : ''}`
                              }}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={row.inventoryItem}
                              selectOnFocus
                              isClearable={true}
                              // onBlur={handleUpdate}
                              onChange={handleAutoCompleteChooseTakeOff}
                              renderInput={(params) => (
                                <TextField {...params} label="Materials" placeholder="Which Item?" onKeyDown={handleTabInteraction} />
                              )}
                            />
                          ) : (
                            <TextField
                              id="description"
                              value={row.description}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              // onChange={(e) => handleItemChange(row._id, 'description', e.target.value)}
                              type="text"
                              fullWidth
                            />
                          )
                        }
                        {
                          row.notes ? <RowNotes notes={row.notes} /> : ''
                        }
                      </TableCell>
                      {/* <TableCell align="right" className={classes.otherCell}>
                        <TextField
                          value={row.costPer}
                          onChange={(e) => handleItemChange(row._id, 'costPer', e.target.value)}
                          type="number"
                          fullWidth
                        />
                      </TableCell> */}
                      <TableCell align="right" className={classes.otherCell}>
                        <TextField
                          value={row.pricePer}
                          onFocus={handleFocus}
                          id="pricePer"
                          onChange={handleChange}
                          className={isPriceDiscounted ? classes.isPriceDiscounted : classes.textField}
                          // onChange={(e) => handleItemChange(row._id, 'msrp', e.target.value)}
                          type="number"
                          fullWidth
                          onBlur={handleBlur}
                          helperText={row?.option?.msrp ? isPriceDiscounted ? `${currencyFormat(row?.option?.msrp)}` : isPricedAtMSRP ? '' : `${currencyFormat(row?.option?.msrp)}` : ''}
                          FormHelperTextProps={{
                            className: isPriceDiscounted ? classes.isPriceDiscountedHelperText : ''
                          }}
                        />
                      </TableCell>
                      <TableCell align="right" className={classes.otherCell}>
                        <div className={isPriceDiscounted ? classes.isPriceDiscountedHelperText : ''}>{currencyFormatWithOutFlair(row.qty * row.pricePer || 0)}</div></TableCell>
                      <TableCell  align="right" className={classes.otherCell} >
                        <ActionArea row={row} deleteItem={handleDeleteLineItem} service={service} serviceIndex={index} rowIndex={rowIndex} saveItem={handleSaveItemFromActionArea} />
                      </TableCell>
                    </TableRow>
                   
                  )})}
                   {/* <TableRow key={'addService'} className={classes.addMaterialsRow}>
                      <TableCell colSpan={5} >
                        <AddMaterialsButton addMaterial={e => handleAddMaterials({ service: service })} />
                      </TableCell>
                    </TableRow> */}
                   <TableRow key={`service_${service._id}_labor`}  className={classes.serviceAreaRow}>
                      <TableCell colSpan={6}>
                        <LaborDetailsComponent service={service} updateParent={handleLaborUpdate} handleBlur={handleBlur} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableCell>
            </TableRow>
            )})
          }
          <TableRow key={'addService'} >
            <TableCell colSpan={5}>
              <AddServiceButton addService={props.handleAddServiceButtonClick} services={filteredServices} saveService={props.saveService} />
            </TableCell>
          </TableRow>
          <TableRow key={'equipmentDetails'} >
            <TableCell colSpan={5}>
             { serviceList?.length > 0 ? <EquipmentList estimate={props.estimate} updateParent={handleUpdateEquipment} handleBlur={handleBlur} /> : null }
            </TableCell>
          </TableRow>
          <TableRow key={'dumpingAndHaulingRow'} >
            <TableCell colSpan={5}>
             { serviceList?.length > 0 ? <DumpingAndHauling estimate={props.estimate} updateParent={handleUpdateDumpingAndHauling} /> : null }
            </TableCell>
          </TableRow>
          <TableRow key={'supervisionRow'} >
            <TableCell colSpan={5}>
             { serviceList?.length > 0 ? <SuprevisionFees estimate={props.estimate} updateParent={handleUpdateSupervision} /> : null }
            </TableCell>
          </TableRow>
          <TableRow key={'totalsRow'} >
            <TableCell colSpan={5}>
             <TotalsRow estimate={props.estimate} />
            </TableCell>
          </TableRow>
          </TableBody>
      </Table>
    </div>
    )
}

const DumpingAndHauling = (props) => {
  const classes = useStyles()
  const [state, setState] = React.useState({
    dumpingAndHaulingFee: 0
  })

  React.useEffect(() => {
    let newState = {}
    if (props.estimate && props.estimate.dumpingAndHaulingFee) {
      newState.dumpingAndHaulingFee = props.estimate.dumpingAndHaulingFee
    } else {
      newState.dumpingAndHaulingFee = 0
    }
    setState({...state, ...newState})
  }, [props])

  const handleChange = (e) => {
    setState({...state, dumpingAndHaulingFee: e.target.value })
  }

  const handleBlur = () => {
    props.updateParent(state)
  }

  return (
    <div>
      <div className={classes.subHeaderText}>Dumping & Hauling Fee</div>
      <div style={{ maxWidth: 300 }}>
        <TextField
          type="number"
          onBlur={handleBlur}
          value={state.dumpingAndHaulingFee}
          fullWidth
          onFocus={handleFocus}
          variant="outlined"
          onChange={handleChange}
          />
      </div>
    </div>
  )
}

const SuprevisionFees = (props) => {
  const classes = useStyles()
  const [state, setState] = React.useState({
    supervisionFee: 0
  })

  React.useEffect(() => {
    let newState = {}
    if (props.estimate && props.estimate.supervisionFee) {
      newState.supervisionFee = props.estimate.supervisionFee
    } else {
      newState.supervisionFee = 0
    }
    setState({...state, ...newState})
  }, [props])


  const handleChange = (e) => {
    setState({...state, supervisionFee: e.target.value })
  }

  const handleBlur = () => {
    props.updateParent(state)
  }

  return (
    <div>
      <div className={classes.subHeaderText}>Supervision Fee</div>
      <div style={{ maxWidth: 300 }}>
        <TextField
          type="number"
          onBlur={handleBlur}
          value={state.supervisionFee}
          fullWidth
          onFocus={handleFocus}
          variant="outlined"
          onChange={handleChange}
          />
      </div>
    </div>
  )
}

const RowNotes = ({ notes }) => {

  return (
    <div>{notes}</div>
  )
}

const TotalsRow = ({ estimate }) => {
  const classes = useStyles()
  console.log('Totals Row', estimate)

  return (
    <div className={classes.totalsRowContainer}>
      <div className={classes.totalsRowContainerItem}>
          Taxable Amount: {currencyFormat(estimate?.taxableAmount)}
      </div>
      <div className={classes.totalsRowContainerItem}>
       Tax: {currencyFormat(estimate?.taxAmount)}
      </div>
      <div className={classes.totalsRowContainerItem}>  Total: {currencyFormat(estimate?.totalPrice || 0)}</div>
   
    </div>
  )
}
const DescriptionDetails = ({ service, serviceIndex, updateServiceDetails, handleSaveDescription, objectives }) => {
  // const [state, setState] = React.useState({
  //   description: service.description || ''
  // })

  // React.useEffect(() => {

  // }, [service])

  const handleChange = (e) => {
    updateServiceDetails({ description: e.target.value }, serviceIndex)
  }

  const handleBlur = () => {
    console.log('Blur', service)
    handleSaveDescription(service, serviceIndex)
  }
  
  const handleChooseObjectiveAsDescription = (d) => {
    // let newService = {...service, description: d}
    // handleSaveDescription(newService, serviceIndex)
    updateServiceDetails({ description: d }, serviceIndex)
  }

  const handleClick = () => {
    console.log('Click')
  }

  return (
    <>
     
    <TextField
      multiline
      minRows={4}
      value={service.description}
      fullWidth
      variant="outlined"
      onBlur={handleBlur}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {/* <IconButton
              onClick={handleClick} // Define this function to handle the icon button click event
              edge="end"
            >
             <AddIcon size={18} />
            </IconButton> */}
            <DescriptionAddButton service={service} objectives={objectives} setOurDescription={handleChooseObjectiveAsDescription} />
          </InputAdornment>
        ),
      }}
    />
    </>
  )
}

const serviceOptions = [
  {
    name: 'Change Service',
    icon: <EditIcon />,
    functionToCall: 'editService'
  },
  {
    name: 'Delete Service',
    icon: <DeleteForeverIcon />,
    functionToCall: 'deleteService'
  }
]

const objectiveOptions = [
  {
    name: 'Choose Objective',
    icon: <ViewListIcon />
  }
]

const DescriptionAddButton = ({ service, objectives, setOurDescription }) => {
  const classes = useStyles()
  const [showMenu, setShowMenu] = React.useState(false)
  const [showChooseObjectiveModal, setShowChooseObjectiveModal] = React.useState(false)

  const handleClose = () => {
    setShowMenu(!showMenu)
  }

  const handleOptionSelection = (d) => {
    console.log('Chose this ', d)
    if (d.name === 'Choose Objective') {
      console.log('Show Our Objectives Modal')
      setShowChooseObjectiveModal(true)
    }
  }
  const handleDelete = () => {
    setShowMenu(false)
  }

  const closeObjectiveModal = () => {
    setShowChooseObjectiveModal(false)
  }

  const handleObjectiveSelected = (d) => {
    console.log('We chose an  objective ', d)
    closeObjectiveModal()
    if (d && d.description) {
      setOurDescription(d.description)
    }
  }
  return (
    <div className={classes.actionAreaContainer}>
      <ChooseObjectiveModal open={showChooseObjectiveModal} objectives={objectives} selectOption={handleObjectiveSelected} handleClose={closeObjectiveModal} />
      <Dialog
        open={showMenu}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Line Item</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you Sure You Wish To Delete This Line Item?
          </DialogContentText>
          <DialogContentText>{service.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <DropDownButton
        name='Action'        
        icon={<ViewListIcon />}
        options={objectiveOptions}
        chooseOption={handleOptionSelection}
      />
    </div>
  )
}

const ChooseObjectiveModal = ({ open, selectOption, handleClose, objectives }) => {
  console.log('Objectives', objectives)
  const classes = useStyles()
  const [chosenObjective, setChosenObjective] = React.useState(null)

  React.useEffect(() => {
    setChosenObjective(objectives[0])
  }, [objectives])

  const handleChange = (e) => {
    console.log('Change ', e.target.value)
    setChosenObjective(e.target.value)
  }
  const handleSelect = () => {
    selectOption(chosenObjective)
  }
  // console.log('Objective', chosenObjective)
  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        width={'md'}
      >
        <DialogTitle id="alert-dialog-title">Choose Objective</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You can choose from a list of pre-written Objectives below
          </DialogContentText>
          <TextField
            select
            variant="outlined"
            id="objective"
            value={chosenObjective}
            onChange={handleChange}
            fullWidth
          >
            {
              objectives.map((item, index) => {

                return (
                  <MenuItem key={index} value={item}>{item.name}</MenuItem>
                )
              })
            }
          </TextField>
          <div className={classes.objectiveContainer}>
            {
              chosenObjective?._id ? (
                <div className={classes.objectiveArea}>
                  {chosenObjective.description}
                </div>
              ) : null
            }
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSelect} color="primary" autoFocus>
            Select
          </Button>
        </DialogActions>
      </Dialog>
  )
}

const defaultActionState = {
  showNoteModal: false,
  showMenu: false,
  notes: ''
}

const ActionArea = ({ row, deleteItem, service, serviceIndex, rowIndex, saveItem }) => {
  const classes = useStyles()
  const [state, setState] = React.useState(defaultActionState)

  React.useEffect(() => {
    if (row.notes && row.notes.length) {
      setState({...state, notes: row.notes })
    } else {
      setState({...state, notes: '' })
    }
  }, [row])

  const handleClose = () => {
    setState({...state, showMenu: !state.showMenu })
  }

  const handleOptionSelection = (d) => {
    console.log('Chose this ', d)
    if (d.name === 'Delete') {
      // setShowMenu(true)
      setState({...state, showMenu: true})
    }
    if (d.name === 'Add/Edit Note') {
      // setShowNoteModal(true)
      setState({...state, showNoteModal: true })
    }
  }
  const handleDelete = () => {
    setState({...state, showMenu: false})
    deleteItem({
      rowIndex, 
      row,
      service,
      serviceIndex
    })
  }

  const handleCloseNotesModal = () => {
    setState({...state, showNoteModal: false })
  }

  const handleSaveTheNotes = (notes) => {
    console.log('Save our notes...', notes)
    saveItem({ notes: notes })
    setState({...state, showNoteModal: false })
  }

  return (
    <div className={classes.actionAreaContainer}>
      <LineItemNotesModal row={row} open={state.showNoteModal} handleClose={handleCloseNotesModal} handleSave={handleSaveTheNotes} />
      <Dialog
        open={state.showMenu}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Line Item</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you Sure You Wish To Delete This Line Item?
          </DialogContentText>
          <DialogContentText>{row.description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <DropDownButton
        name='Action'
        options={dropDownOptions}
        chooseOption={handleOptionSelection}
      />
    </div>
  )
}

const LineItemNotesModal = ({ open, row, handleClose, handleSave }) => {
  const [state, setState] = React.useState({
    notes: ''
  })

  React.useEffect(() => {
    if (row.notes && row.notes.length) {
      setState({...state, notes: row.notes })
    } else {
      setState({...state, notes: '' })
    }
  }, [row])


  const handleChange = (e) => {
    setState({...state, notes: e.target.value })
  }

  const saveIt = () => {
    handleSave(state.notes)
  }

  return (
    <Dialog
        open={open}
        onClose={handleClose}
        size={'md'}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Notes</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please add any relevant notes below:
          </DialogContentText>
          <TextField
            multiline
            minRows={4}
            value={state.notes}
            fullWidth
            variant="outlined"
            // onBlur={handleBlur}
            onChange={handleChange}
          
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={saveIt} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  )
}
const dropDownOptions = [
  {
    name: 'Add/Edit Note',
    icon: <EditIcon />
  },
  {
    name: 'Delete',
    icon: <DeleteForeverIcon />
  },
  // {
  //   name: 'View Attachments',
  //   icon: 'DeleteIcon',
  // },
  
]

const AddMaterialsButton = (props) => {
  const classes = useStyles()

    return (
      <div className={classes.addMaterialsButtonArea}>
        <Button
          className={classes.addMaterialsButton}
          onClick={props.addMaterial}
        
        >
          Add Materials
        </Button>
      </div>
    )

}

const AddServiceButton = (props) => {
  console.log('AddService', props)
  const classes = useStyles()
  const [showInputs, setShowInputs] = React.useState(false)
  const [service, setService] = React.useState({})

  const addService = () => {
    setShowInputs(true)
  }
  const handleSave = () => {
    setShowInputs(false)
    props.saveService(service)
    setService({})
  }

  const updateServiceType = (e, newSvc) => {
    // console.log('Update New Service', e.target.value)
    // console.log('new svc', newSvc)
    setService(newSvc)
  }
  let serviceSet = []
  let filteredServices = [...props.services].filter(item => {
    return item
  })
  if (showInputs) {
    return (
      <div className={classes.addServiceButtonContainer}>
        <Autocomplete
          id={`servicename`}
          options={filteredServices}
          className={classes.serviceSelection}
          getOptionLabel={(option) => {
            // console.log('Options: ', option)
            return option.name || ''
          }}
          // style={{ width: 200 }}
          onChange={updateServiceType}
          // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
          // value = {state.serviceType}
          // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
          renderInput={(params) => <TextField {...params} fullWidth label="Service Type" variant="outlined" />}
        />
       <Button
          className={classes.saveButton}
          onClick={handleSave}
          variant="contained"
          color="primary"
        >
          Choose Service
        </Button>
      </div>
    )
  } else {
    return (
      <div className={classes.addServiceButtonContainer}>
        <Button
          className={classes.addServiceButton}
          onClick={addService}
         
        >
          Add Service
        </Button>
      </div>
    )
  }

}

const LaborDetailsComponent = (props) => {
  const classes = useStyles()

  const handleUpdate = (data) => {
    console.log('Update Labor Details', data)
    // Convert to the laborDetails Object
    props.updateParent(data)
  }
  return (
    <div className={classes.root}>
      <LaborDetailsTable service={props.service} updateParent={handleUpdate} handleBlur={props.handleBlur} />
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '98%',
    margin: 10
  },
  table: {
    minWidth: 650,
  },
  tableCells: {
    borderRight: '1px solid #333',
    borderLeft: '1px solid #333'
  },
  descriptionCell: {
    borderRight: '1px solid #333',
    borderLeft: '1px solid #333',
    width: '40%', // Making Description column larger
  },
  otherCell: {
    borderRight: '1px solid #333',
    borderLeft: '1px solid #333',
    width: '5%', // Adjust other columns' width as needed
  },
  mediumCell: {
    borderRight: '1px solid #333',
    borderLeft: '1px solid #333',
    width: '15%',
  },
  fullWidthCell: {
    width: '70%',
    border: '1px solid #333'
  },
  addServiceButtonContainer: {
    backgroundColor: '#eee',
    border: '2px solid #ddd',
    borderRadius: 10,
    width: '100%',
    padding: 25,
    margin: 'auto',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  addServiceButton: {
    backgroundColor: '#aaa',
    width: '50%'
  },
  saveButton: {
    marginTop: 15,
    width: '50%'
  },
  serviceSelection: {
    
    width: '60%'
  },
  serviceAreaRow: {
    border: '1px solid #333'
  },
  estimateLineItemsContainer: {
    border: '2px solid #aaa',
    // backgroundColor: 'pink',
    margin: 0,
    padding: 5
  },
  serviceContainer: {
    border: '2px solid #3f3',
    margin: 10,
    padding: 100,
    borderRadius: 8,
    backgroundColor: 'green',
    marginBottom: '100px'
  },
  lineItemsBody: {
    border: '5px solid #000',
    backgroundColor: 'blue',
    marginBottom: '100px',
    padding: 30,
    margin: 10,
    marginBottom: 100
  },
  serviceTableBody: {
    border: '7px solid #ccc',
    borderRadius: 30,
    // backgroundColor: '#fefffe',
    margin: 5
  },
  subHeaderText: {
    fontSize: '1.2em',
    fontWeight: 700
  },
  addMaterialsRow: {
    maxHeight: 46,
    backgroundColor: '#eee',
    '&:hover': {
      backgroundColor: "#accacd",
   },

  },
  addMaterialsButtonArea: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 5
  },
  addMaterialsButton: {
    backgroundColor: '#aaa',
    width: '50%',
    border: '2px solid #aaa',
    '&:hover': {
      // backgroundColor: "#accacd",
      border: '2px solid #516e76'
   },
  },
  actionAreaContainer: {
    // backgroundColor: '#eee',
    // padding: 10,
    // margin: 5,
    width: '100%'
  },
  rowStyleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  isPriceDiscounted: {
    '& input': {
      color: 'red',
    },
  },
  isPriceDiscountedHelperText: {
    color: 'red'
  },
  totalsRowContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end',
    alignItems: 'flex-end'
  },
  totalsRowContainerItem: {
    fontWeight: 600,
    fontSize: 28
  }
}));

const mapStateToProps = (state) => {
  // console.log('Map to Props ESTIMATE PROCESSS: ', state)
  return {
    url: state.url,
    notification: state.notification,
    services: state.services
  }
}

export default connect(mapStateToProps)(PaperStyleLineItemComponent)