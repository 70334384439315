import React from 'react';
import { 
  List, 
  ListItem, 
  ListItemText, 
  Collapse, 
  Typography, 
  Paper,
  makeStyles,
  Grid
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell } from 'recharts';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  chartContainer: {
    marginTop: theme.spacing(4),
  },
}));

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];


const RepairList = ({ repairs }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState({});

  const handleClick = (id) => {
    setOpen((prevOpen) => ({ ...prevOpen, [id]: !prevOpen[id] }));
  };

    // Prepare data for the line chart (costs over time)
    const lineChartData = repairs
    .sort((a, b) => new Date(a.date) - new Date(b.date))
    .map(repair => ({
      date: repair.date,
      cost: repair.cost
    }));

    // Prepare data for the pie chart (repair type distribution)
    const pieChartData = repairs.reduce((acc, repair) => {
      acc[repair.type] = (acc[repair.type] || 0) + 1;
      return acc;
    }, {});
    const pieChartDataArray = Object.entries(pieChartData).map(([name, value]) => ({ name, value }));


  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.chartContainer}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Repair Costs Over Time</Typography>
          <LineChart width={400} height={300} data={lineChartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="cost" stroke="#8884d8" />
          </LineChart>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h6">Repair Type Distribution</Typography>
          <PieChart width={400} height={300}>
            <Pie
              data={pieChartDataArray}
              cx={200}
              cy={150}
              labelLine={false}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
            >
              {pieChartDataArray.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </Grid>
      </Grid>
      
      <List component="nav">
        {repairs.map((repair) => (
          <React.Fragment key={repair.id}>
            <ListItem button onClick={() => handleClick(repair.id)}>
              <ListItemText primary={`${repair.equipmentName} - ${repair.date}`} />
              {open[repair.id] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open[repair.id]} timeout="auto" unmountOnExit>
              <Paper className={classes.paper}>
                <Typography variant="h6">Repair Details</Typography>
                <Typography>Description: {repair.description}</Typography>
                <Typography>Type: {repair.type}</Typography>
                <Typography>Cost: ${repair.cost}</Typography>
                <Typography variant="h6" style={{ marginTop: '16px' }}>Purchases</Typography>
                <List>
                  {repair.purchases.map((purchase, index) => (
                    <ListItem key={index}>
                      <ListItemText 
                        primary={purchase.item} 
                        secondary={`Quantity: ${purchase.quantity}, Cost: $${purchase.cost}`} 
                      />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="h6" style={{ marginTop: '16px' }}>Mechanic's Notes</Typography>
                <Typography>{repair.mechanicNotes}</Typography>
              </Paper>
            </Collapse>
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default RepairList;