import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, CircularProgress }  from '@material-ui/core';
import axios from 'axios'
import ProtectedButton from '../../actions/protectedButtons';

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  button: {
    width: '40px'
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#fff',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
});

const PaymentList = (props) => {
  // console.log('Payment List Props: ', props)
  const classes = useStyles();
  const [payments, setPayments] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    // console.log('Use Effect Payment List')
    if (props.payments) {
      setPayments(props.payments)
    }
    if (props.stuff && props.stuff.payments) {
      setPayments(props.stuff.payments)
    } 
    
  }, [props])

  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
 }

 const handleDeletePayment = (item) => {
   // console.log('Delete this payment: ', item)
   setLoading(true)
   axios({
    method: 'post',
    url:  `${props.url}/api/payment/delete`,
    data: { payment: item, user: props.user, client: props.client }
  }).then(response => {
    // console.log('Payment Delete Response: ', response)
    let index = payments.findIndex(pmt =>  (pmt._id === item._id));
    let ourPmt = payments[index]
    // console.log('Payment to Remove: ', ourPmt)
    let pmts = payments
    pmts.splice(index, 1)
    setPayments(pmts)
    setLoading(false)
  })
 }

 const handleVoidPayment = (item) => {
  // console.log('Void this payment: ', item)
  setLoading(true)
  axios({
   method: 'post',
   url:  `${props.url}/api/payment/void`,
   data: { payment: item, user: props.user, client: props.client }
 }).then(response => {
   // console.log('Payment Void Response: ', response)
   let index = payments.findIndex(pmt =>  (pmt._id === item._id))
   let pmts = payments
   let ourPmt = pmts[index]
   // console.log('Payment to Void: ', ourPmt)
   ourPmt.status = 'Voided'
   setPayments(pmts)
   setLoading(false)
 })
}

const handleRefundPayment = (item) => {
  // console.log('Refund this payment: ', item)
  setLoading(true)
  axios({
   method: 'post',
   url:  `${props.url}/api/payment/refundPayment`,
   data: { payment: item, user: props.user, client: props.client }
 }).then(response => {
   // console.log('Payment refundPayment Response: ', response)
   let index = payments.findIndex(pmt =>  (pmt._id === item._id))
   let pmts = payments
   let ourPmt = pmts[index]
   // console.log('Payment to refundPayment: ', ourPmt)
   ourPmt.status = 'Refunded'
   setPayments(pmts)
   setLoading(false)
 })
}

 const ActionButton = (props) => {
   let ourButton = <span></span>
   if (props.row && props.row.status === 'Authorized') {
     ourButton = (
      <Button
      variant="contained"
      // style={{backgroundColor: '#ed4545'}}
      className={classes.button}
      size="small"
      // startIcon={<DeleteIcon />}
      onClick={() => { if (window.confirm('Are you sure you wish to void this item?')) handleVoidPayment(props.row) } }
      // onClick={handleDeleteService}
      // onClick={offerDeleteOption}
      // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
    >
      Void
    </Button> 
     )
   }
   if (props.row && (props.row.type !== 'Prepayment' && props.row.type !== 'Credit Memo') && props.row.status === 'Paid' && props.row.manualInput === false) {
    ourButton = (
      <Button
      variant="contained"
      // style={{backgroundColor: '#ed4545'}}
      // className={classes.button}
      size="small"
      onClick={() => { if (window.confirm('Are you sure you wish to refund this item?')) handleRefundPayment(props.row) } }
      // startIcon={<DeleteIcon />}
      // onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeletePayment(props.row) } }
      // onClick={handleDeleteService}
      // onClick={offerDeleteOption}
      // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
    >
      Refund
    </Button> 
     )
   }
   if (props.row && props.row.status === 'Due' || props.row.status === 'Pending' || props.row.manualInput === true) {
    ourButton = (
      <div className={classes.wrapper}>
        <ProtectedButton type="Payments" kind="delete" perms={props.perms}>
          <Button
            variant="contained"
            style={{backgroundColor: '#ed4545'}}
            // className={classes.button}
            size="small"
            disabled={loading}
            startIcon={<DeleteIcon />}
            onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeletePayment(props.row) } }
            // onClick={handleDeleteService}
            // onClick={offerDeleteOption}
            // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
          >
            Delete
        </Button>
        </ProtectedButton>


     </div>
     )
  }
  return ourButton
 }
  return (
    <div>
      Search
      <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Payment Type</TableCell>
            <TableCell >Amount</TableCell>
            <TableCell >Total (Including Fees)</TableCell>
            <TableCell >Status</TableCell>
            <TableCell >Paid Date</TableCell>
            <TableCell >Action</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((row) => (
            <TableRow key={row._id}>
              <TableCell component="th" scope="row">
                {row.type === 'Prepayment' ? 'Deposit Used' : row.type}
              </TableCell>
              <TableCell >{currencyFormat(row.amount)}</TableCell>
              <TableCell >{(row.totalCharge && row.totalCharge > 0) ? currencyFormat(row.totalCharge) : ''}</TableCell>
              <TableCell >{row.status}</TableCell>
              <TableCell >{row.paidDate ? moment(row.paidDate).format('MM/DD/YYYY - hh:mm a') : '' }</TableCell>
              <TableCell ><ActionButton key={row._id} row={row} perms={props.perms} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  )
}
const mapStateToProps = (state) => {
  // console.log('Payment List Map State to Props', state)
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    user: state.user,
    perms: state.perms
  }
}

export default React.memo(connect(mapStateToProps)(PaymentList))

