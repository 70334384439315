import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const MuiTableCell = withStyles(theme => ({
  root: {
    height: 10,
    padding:0
  }
}))(TableCell)

function replaceTemplatedVerbiage(template, item, useTemplatedDescription, service) {
  let newStr = template
  if (useTemplatedDescription) {
    newStr = item.templatedDescription
  }
  newStr = newStr.replace('{QTY}', item.qty)
  newStr = newStr.replace('{SIZE}', item.unit)
  newStr = newStr.replace('{DESC}', item.description)
// console.log('Check fo rany required inputs as well here..', service)
// console.log('New STr', newStr)
  return newStr
}

const getOurItemDescription = (item, service) => {
// console.log('getOurItemDescription', item)
// console.log('Service', service)
  let cleanDesc = ''
  if (item.templatedDescription) {
    cleanDesc = replaceTemplatedVerbiage(item.templatedDescription, item, false, service)
  } else {
    cleanDesc = item.description
  }
  return cleanDesc
}

const LineItemList = (props) => {
  console.log('Proposal LIst of Items: ', props)
  let lineItems = []
  
  if (props.service && props.service.service && props.service.service.lineItems && props.service.service.lineItems.length) {
    console.log('Use Service.service line items....', props.service.service.lineItems)
    lineItems = props.service.service.lineItems
  } else if (props.service && props.service.lineItems && props.service.lineItems.length) {
    console.log('use this shit')
    lineItems = props.service.lineItems
  }
  console.log('Line Items:', lineItems)
  
  const handleEditLineItem = (item, index, toDelete) => {
    // console.log('Edit htis line item!!!', item)
    if (toDelete) {
      props.handleEditLineItem(item, true)
    } else {
      props.handleEditLineItem(item)
    }
  }

  let template = null
  if (props.service && props.service.service && props.service.service.lineItemTemplate) {
  // console.log('Template this bitch', props.service.service)
    template = props.service.service.lineItemTemplate
  }
  return (
    <div>
      <Table>
        <TableHead >
          <TableRow >
          <MuiTableCell style={{ fontSize: '1.25em', fontDecoration: 'bold-italic' }}>Name</MuiTableCell>
          <MuiTableCell style={{ fontSize: '1.25em', fontDecoration: 'bold-italic' }}>Quantity</MuiTableCell>
          <MuiTableCell style={{ fontSize: '1.25em', fontDecoration: 'bold-italic' }}>Size</MuiTableCell>
          <MuiTableCell style={{ fontSize: '1.25em', fontDecoration: 'bold-italic' }}>Price</MuiTableCell>
            <MuiTableCell style={{ fontSize: '1.25em', fontDecoration: 'bold-italic' }}>Edit</MuiTableCell>
            <MuiTableCell style={{ fontSize: '1.25em', fontDecoration: 'bold-italic' }}>Remove</MuiTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            lineItems.map((item, index) => {
              // Deal w/ Template
              console.log('Make our Clean Verbiage!!!', item)
              let optionDetails = ''
              if (item && item.option && item.option.name) {
                optionDetails = `(${item.option.name})`
              }
              // let cleanVerbiage = `${item.qty > 0 ? item.qty : ''} ${item.description}`
              // if (item.templatedDescription) {
              //   cleanVerbiage = replaceTemplatedVerbiage(template, item, true, props.service)
              // } else if (template) {
              //   cleanVerbiage = replaceTemplatedVerbiage(template, item, props.service)
              // }
              // console.log('Clean Verbiage: ', cleanVerbiage)
              if (item && item.name) {
                console.log('Item.name.../')
                return (
                  <TableRow key={index}>
                    <MuiTableCell>{item.name} {optionDetails} </MuiTableCell>
                    <MuiTableCell>{item.qty} </MuiTableCell>
                    <MuiTableCell>{item.unit} </MuiTableCell>
                    <MuiTableCell>{item.totalPrice} </MuiTableCell>
                    <MuiTableCell>
                      <IconButton
                        onClick={e => handleEditLineItem(item, index)}>
                        <EditIcon />
                      </IconButton>
                    </MuiTableCell>
                    <MuiTableCell>                  
                      <IconButton size='small' onClick={e => handleEditLineItem(item, index, true)}>
                        <RemoveCircleIcon />
                      </IconButton>
                    </MuiTableCell>
                  </TableRow>
                )
              } else {
                console.log('No item.name...')
                return (
                  <TableRow key={index}>
                    <MuiTableCell>{item.description} </MuiTableCell>
                    <MuiTableCell>{item.qty} </MuiTableCell>
                    <MuiTableCell>{item.unit ? item.unit : item.size ? item.size : ''} </MuiTableCell>
                    <MuiTableCell>{item.totalPrice} </MuiTableCell>
                    <MuiTableCell>
                      <IconButton
                        onClick={e => handleEditLineItem(item, index)}>
                        <EditIcon />
                      </IconButton>
                    </MuiTableCell>
                    <MuiTableCell>                  
                      <IconButton size='small' onClick={e => handleEditLineItem(item, index, true)}>
                        <RemoveCircleIcon />
                      </IconButton>
                    </MuiTableCell>
                  </TableRow>
                )
              }

            })
          }
        </TableBody>
      </Table>

    </div>
  )
}

export default LineItemList