import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IconButton, Button, CircularProgress, isMuiElement, Drawer }  from '@material-ui/core';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Tooltip from '@material-ui/core/Tooltip';
import NewReminderComponent from '../../Reminders/NewReminderComponent'
import { socket } from '../../SocketIo/Socket'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const useStyles = makeStyles(theme => ({

  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: 1
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '10px',
    margin: '5px'
  },
  uploadDropZone: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    border: '2px dashed #0658c2',
    borderRadius: 10,
    width: 300,
    height: 55,
    margin: 15,
    padding: 10,
    "&:hover": {
      backgroundColor: '#444',
      color: '#eee',
      border: '2px dashed rgb(183 224 152)',
    }
},
leadDesireContainer: {
  border: '2px solid #0658c2',
  borderRadius: 5,
  backgroundColor: '#eee',
  margin: 2,
  padding: 2,
  height: 200,
  overlow: 'hidden'
},
  leadDesireBox: {
    margin: 2,
    marginTop: -5,
    padding: 2,
    height: 150,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '0.4em'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    }
  },
  topRowLeadDesires: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0
  },
  leadDesireDetails: {
    display: 'flex',
    
    // justifyContent: 'center'
  },
  incomingMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: '10px',
    marginLeft: '10px'
  },
  outgoingMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginBottom: '10px',
    marginRight: '10px'
  },
  incomingMessage: {
    maxWidth: '70%',
    padding: '10px',
    backgroundColor: '#DAE0E6', // Light green ACCACD
    borderRadius: '10px 10px 10px 0',
    marginBottom: '5px',
  },
  outgoingMessage: {
    maxWidth: '70%',
    padding: '10px',
    backgroundColor: '#ACCACD', // Light gray
    borderRadius: '10px 10px 0 10px',
    marginBottom: '5px',
  },
  authorName: {
    fontSize: '12px',
    color: '#555', // Dark gray
  },
  roomListItemContainer: {
    width: '100%',
    border: '2px solid #769ba2',
    padding: '15px',
    cursor: 'pointer'
  }

}));

const containerStyles = {
  position: 'relative',
  backgroundColor: '#eee',
  height: '88vh', // or a specific height value
  // overflowY: 'scroll'
};

const textInputStyles = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  padding: '10px',
  backgroundColor: 'white', // or any other color
  zIndex: 2,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly'
};

const messageListStyles = {
  maxHeight: '800px',
  overflow: 'scroll'
};

const activityTypes = [
  'Phone Call',
  'Text Message',
  'Email',
  'On-Site',
  'In Office',
  'Request Pricing',
  'Send Estimate'
]

const activtyDrawerDefaultState = {
  notes: '',
  contact: {},
  activityType: 'Phone Call',
  reminder: null,
  counter: 0
}

const ActivityDrawer = (props) => {
  console.log('activity drawer', props)
  const classes = useStyles()
  const [state, setState] = React.useState(activtyDrawerDefaultState)
  const [loading, setLoading] = React.useState(false)
  const [messages, setMessages] = React.useState([])
  const [room, setRoom] = React.useState({})
  const [employee, setEmployee] = React.useState({})
  const [rooms, setRooms] = React.useState([])
  
  React.useEffect(() => {
  // console.log('ROOM HAS CHANGED')
    if (room && room._id) {
      const socketName = `ChatRoom_${room._id}`
      // console.log('Subscribe to chat room:', socketName)
    // console.log('Room socket', room)
      socket.on(socketName, (data) => {
      // console.log('ROom NOtification:', data)
        if (data && data.type === 'MESSAGE') {
          let message = data.data
          let author = data.author
          // author.displayName = `${author.firstName} ${author.lastName}`
          let newMsg = { ...message }
          newMsg.author = author
          if (message.created_by === props.user._id) {
            // console.log('This is the fucking guy - dont do it!!')
          } else {
            // console.log('Different dude')
            setMessages(prevMessages => [...prevMessages, newMsg ])
          }
        // console.log('New Mesasage to add', newMsg)
          // setMessages(prevMessages => [...prevMessages, newMsg ])
        }
      })

      return () => {
      // console.log('Disconnect from socket', socketName)
        socket.off(socketName)
      }
    }


  }, [room])

  const fetchMessages = async (roomName) => {
    setLoading(true)
    const response = await axios.get(`${props.url}/api/messages/getMessagesByRoom?roomName=${roomName}`)
    setLoading(false)
    if (response && response.data) {
      console.log("Set our SHIT!", response.data)
      setMessages(response.data.messages)
      setRoom(response.data.room)
      setRooms(response.data.memberRooms)
      setEmployee(response.data.employee)
    } else {
      setMessages([])
      setRooms([])
    }
  }

  React.useEffect(() => {
    let isMounted = true

    // fetchRooms
    if (props.room) fetchMessages(props.room)
    
    return () => {
      isMounted = false
    }

  }, [props.room])

  const handleSendMessage = (text) => {
    const data = {
      room: room._id,
      author: employee._id,
      text: text,
      type: 'text'
    }
  // console.log('Send Message', data)
    axios({
      method: 'post',
      url:  `${props.url}/api/messages/sendMessage`,
      data: data,
    }).then(response => {
    // console.log('Sent SMS: ', response.data)
      if (response && response.data && response.data.error) {
        props.notification({
          type: 'warning',
          title: 'System Error Sending',
          message: JSON.stringify(response.data.error)
        })
      } else if (response && response.data && response.data._id) {
        // let existingMessages = messages
        // existingMessages.push({
        //   text: text,
        //   author: employee,
        //   room: room._id,
        //   created_at: new Date(),
        //   type: 'text',
        //   _id: response.data._id
        // })
        let newMessage = {
          text: text,
          author: employee,
          room: room._id,
          created_at: new Date(),
          type: 'text',
          _id: response.data._id
        }
        // setMessages(existingMessages)
        setMessages(prevMessages => [...prevMessages, newMessage ])
        // setState({...state, counter: state.counter++ })
        props.notification({
          type: 'success',
          title: 'Sent!',
          message: `Your Message Was Sent!`
        })
      }
      // if (response && response.data && response.data.success) {
      //   props.notification({
      //     type: 'success',
      //     title: 'Sent!',
      //     message: `Your ${props.type} has been successfully emailed to the customer!`
      //   })
      //   handleClose()
      // }
      // scrollToBottom()
    }).catch(err => {
      // // console.log('Error caught when uploading pic: ', err)
      props.notification({
        type: 'warning',
        title: 'Failed to Send!',
        message: err.message
      })
    })
  };

  const handleOpenRoom = (roomId) => {
    // console.log('Open Room roomId:', roomId)
    let roomIndex = rooms.findIndex(item => (roomId === item._id))
    if (roomIndex > -1) {
      setRoom(rooms[roomIndex])
      // setRoom({...room, ...rooms[roomIndex] })
      fetchMessages(rooms[roomIndex]['name'])
    }
  }

  const handleBack = () => {
    setRoom({})
  }

  const clearRoomUnread = (roomToClear) => {
    // console.log('Clear ', roomToClear);
    // console.log('Rooms', rooms);
    let roomIndex = rooms.findIndex(item => roomToClear._id.toString() === item._id.toString());
  
    if (roomIndex > -1) {
      // console.log("Room to update", roomIndex);
      setRooms(prevRooms => 
        prevRooms.map((room, index) => 
          index === roomIndex ? { ...room, unreadMessages: 0 } : room
        )
      );
    }
  }
  

  const handleUpdateLastSeen = async (msg, roomId) => {
    // console.log('Update last seen id', msg)
    // console.log('roomId', roomId)
    if (roomId === room._id) {
      clearRoomUnread(room)
    }
    let data = {
      messageId: msg._id,
      roomId: roomId
    }
    const response = await axios({
      method: 'post',
      url:  `${props.url}/api/messages/updateLastSeen`,
      data: data
    })
    if (response && response.error) {
    // console.log('Erroor ', response.error)
    }
    if (response.data && response.data._id) {
      // console.log('Last Seen updated.', response.data)
    }
  }

  return (
    <div style={{ width: '100%'}}>
       <Grid container >
            <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem} style={{ backgroundColor: 'rgb(119, 156, 164)', color: '#fff', textAlign: 'center', fontSize: '20px'}} >
              <span style={{ margin: 15 }}>
                  Chats/Messages
              </span>
              <span onClick={props.handleCloseDrawer} style={{ cursor: 'pointer', position: 'absolute', left: 10, top: 5 }}>
                X
              </span>
            </Grid>
      {
        (room && room.name) ? (
         
            <Grid item lg={12} xs={12} md={12} sm={12} className={classes.gridItem} >
                            <IconButton onClick={handleBack} edge='end' alt="Close" aria-label="Close">
                                <ArrowBackIcon />
                              </IconButton>
                              {room.humanName}
                             
              <MessageList list={messages} room={room} employee={employee} handleSendMessage={handleSendMessage} handleBack={handleBack} updatLastSeen={handleUpdateLastSeen} />
            </Grid>
         
        ) : (
          <RoomList rooms={rooms} openRoom={handleOpenRoom} />
        )
      }
       </Grid>
    </div>
  )
}

const RoomList = ({ rooms, openRoom }) => {
  // console.log('Room List rooms', rooms)
  const classes= useStyles()

  const handleOpenChatRoom = (id) => {
    // console.log('Open Chat room', id)
    openRoom(id)
  }

  return (
    <div style={{ width: '100%', maxHeight: 800, overflow: 'scroll', marginTop: '20px' }}>
      List of Rooms
      {
        (rooms && rooms.length) && rooms.map(room => {
          // console.log('ROom map', room)
          return (
            <div key={room._id} id={room._id} className={classes.roomListItemContainer} onClick={() => handleOpenChatRoom(room._id)} >
              <div>
                {room.humanName} 
                {
                  room.unreadMessages > 0 && <Chip style={{color: '#fff', background: '#f1c40f', marginLeft: '20px' }} size="small" label={room.unreadMessages} />
                }
              </div>
              {/* <div>{room.members.length} Members</div> */}
              {
                (room && room.members && room.members.length) && <MemberList members={room.members} />
              }
            </div>
          )
        })
      }
    </div>
  )
}

const MemberList = ({ members }) => {
  // console.log('members', members)
  let memberNames = members.map(member => {
    return member.displayName
  })
  let naems = memberNames.join(', ')
  return (
    <div>
     {naems}
    </div>
  )
}

const MessageList = ({ list, room, employee, handleSendMessage, handleBack, updatLastSeen }) => {
  const messagesEndRef = useRef(null);
  const [messageText, setMessageText] = useState('');


  const sendMessage = () => {
    handleSendMessage(messageText)
    setMessageText('')
  }

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start" });
    // console.log('Scroll to bottom...', room)
    if (room && room._id && list && list.length) updatLastSeen(list[list.length - 1], room._id)
  }

  useEffect(() => {
    // console.log('Messages List Updated 347')
    if (messagesEndRef.current) {
      // console.log('Scroll down...')
      scrollToBottom();
    }
  }, [list]);


  return (
 
      <div style={containerStyles}>
      <BackButton handleBack={handleBack} />
      
      <div style={messageListStyles}>
      {
        list.map((item, index) => {
          let isIncoming = (item.author._id === employee._id) ? false : true
          return (
            <div key={index}>
              {
                isIncoming ? <IncomingMessage message={item} /> : <OutgoingMessage message={item} />
              }
             
            </div>
          )
        })
      }
       <div style={{ marginTop: 35 }} ref={messagesEndRef} >&nbsp;</div>
       </div>
      <TextInputArea setMessageText={setMessageText} sendMessage={sendMessage} messageText={messageText} />
   
    </div>
  )
}

const BackButton = ({ handleBack }) => {

  return (
    <Tooltip
      title="Back"
      >
         <IconButton
          onClick={handleBack}
          name="arrow-left"
        />
      </Tooltip>
       
     
  )
}

const TextInputArea = ({ setMessageText, sendMessage, messageText }) => {
  return (
    <div style={textInputStyles}>
    <input
      type="text"
      value={messageText}
      onChange={(e) => setMessageText(e.target.value)}
      placeholder="Type your message..."
      style={{ width: '80%', padding: '15px', borderRadius: '5px' }}
    />
    <Button variant="contained" color="primary" onClick={sendMessage} style={{ marginLeft: '10px', padding: '10px 15px' }}>
      Send
    </Button>
  </div>
  )
}
const IncomingMessage = ({ message }) => {
  const classes = useStyles();
  return (
    <div className={classes.incomingMessageContainer}>
      <div className={classes.incomingMessage}>
        {message.text}
      </div>
      <div className={classes.authorName}>
        <CreatedInfo message={message} />
      </div>
    </div>
  );
}

const OutgoingMessage = ({ message }) => {
  const classes = useStyles();
  return (
    <div className={classes.outgoingMessageContainer}>
      <div className={classes.outgoingMessage}>
        {message.text}
      </div>
      <CreatedInfo message={message} />
    </div>
  );
}

const CreatedInfo = ({ message }) => {
// console.log('Created Info:', message)
  const classes = useStyles();
  return (
    <div>
      <div className={classes.authorName}>
        {message.author.displayName}
      </div>
      <div className={classes.authorName}>
      {moment(message.created_at).format('M/D/YY h:mma')}
    </div>
  </div>

  )
}
const ActivityDrawerContainer = (props) => {
// console.log('Actiivty drawer container', props)
  const [activityDrawerOpen, setActivityDrawerOpen] = React.useState(false)

  const handleOpenDrawer = () => {
    setActivityDrawerOpen(true)
  }
  
  const handleCloseDrawer = () => {
    // setActivityDrawerOpen(false)
    props.onClose()
  }
  return (
    <React.Fragment>
      {/* <Tooltip title="Log Activity">
        <IconButton
          size="small"
          edge="end"
          onClick={handleOpenDrawer}
          style={{marginLeft: 50}}
          >
          <ContactPhoneIcon />
        </IconButton>
      </Tooltip> */}

      <Drawer
        anchor={'right'}
        open={props.open}
        onClose={handleCloseDrawer}
      >
        <ActivityDrawer {...props} handleCloseDrawer={handleCloseDrawer} />
      </Drawer>
    </React.Fragment>
    
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    employee: state.employee
  }
}

export default connect(mapStateToProps)(ActivityDrawerContainer)
