import React from 'react'
import moment from 'moment'
import { isEven } from '../../services/common'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import SearchIcon from '@material-ui/icons/Search';

const RouteList = (props) => {
// console.log('List of Houses:', props)
  const classes = useStyles()
  const [searchValue, setSearchValue] = React.useState('')
  const [filteredRows, setFilteredRows] = React.useState(props.list)

  React.useEffect(() => {
  // console.log('Set our List:', props.list)
    setFilteredRows(props.list)
  }, [props.list])
  
  const filterOut = (r, searchText) => {
    // console.log('FIlter out: ', r)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // setsearchMatches(newArr.length)
    // return newArr
    setFilteredRows(newArr)
    // setsearchMatches(newArr.length)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  
  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    // setPage(0)
    setSearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(props.list)
    } else {
      filterOut(props.list, event.target.value)
    }
  }

  const handleOpen = (ag) => {
  // console.log('OPen:', ag)
  }
  let totalHours = 0
  if (props.list && props.list.length) {
    let hours = 0
    props.list.map(item => {
      hours += Number(item.estMinutes / 60 || 0)
    })
    if (hours > 0) totalHours = hours
  }
  let weekOfTheYear = moment(new Date()).week()
// console.log('Week of the year: ', weekOfTheYear)
  let evenWeek = isEven(weekOfTheYear)
  let thisWeek = evenWeek ? 'Even' : 'Odd'
  return (
    <div className={classes.root}>
      List of Stops: {props.list.length} ({props.day}) -- Week Type: {props.typeOfWeek}
      <br /> 
      Chosen Crew: {(props.crew && props.crew.name) ? props.crew.name : 'All' }<br />
      <div>
        Total Hours of Work {totalHours.toFixed(2)}
      </div>
      <div>
        This Week is an {thisWeek} Week
      </div>
      <div className={classes.search}>
            <div >
              <SearchIcon /> Search { searchValue ? `(${filteredRows.length} Matches)` : ''}
            </div>
            <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input>
          </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Job Name</TableCell>
            <TableCell>Est Minutes</TableCell>
            <TableCell>Preferred Day</TableCell>
            <TableCell>Frequency</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell>Service Details</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
        filteredRows.map((item, index) => {
          return (
            <TableRow key={index} id={item._id} className={classes.agreementContainer} onDoubleClick={e => handleOpen(item)}>
              <TableCell>
                <Link 
                    style={{pointer: 'cursor'}}
                    target="_blank"
                    to={{
                          pathname: '/clients/' + item.client + '/mtcAgreement/' + item._id,
                          // search: '?sort=name',
                          // hash: '#the-hash',
                          state: { mtcAgreement: item }
                        }}>  {item.jobName}
                        
                  </Link>
                </TableCell>
                <TableCell>{item.estMinutes}</TableCell>
                <TableCell>{item.preferredDay}</TableCell>
                <TableCell>{item.frequency} 
                
                        {
                          item.frequency === 'biweekly' ? `   -   ${item.evenOrOddWeeks} Weeks` : ''
                        }
                </TableCell>
                <TableCell>{item.siteNotes}</TableCell>
                <TableCell><ServiceDetails item={item} /></TableCell>
              </TableRow>
          )
        })
      }
        </TableBody>
      </Table>

    </div>
  )
}

export default RouteList

const translateIt = (val) => {
  const opts = {
    1: 'Monthly',
    2: 'Quarterly',
    3: 'Bi-Annually',
    4: 'Annually'
  }
  return opts[val]
}

const ServiceDetails = ({ item }) => {
  const classes = useStyles()

  // <MenuItem value={1}>Monthly</MenuItem>
  // <MenuItem value={2}>Quarterly</MenuItem>
  // <MenuItem value={3}>Bi-Annually</MenuItem>
  // <MenuItem value={4}>Annually</MenuItem>
  return (
    <div style={{ color: '#bbb', fontSize: 12, padding: 10, whiteSpace: 'nowrap' }}>
      <div>
        Water Feature Checks: {translateIt(item.waterFeatureChecks)}
      </div>
      <div>
        Irrigation Checks:  {translateIt(item.irrigationChecks)}
      </div>
      <div>
        Lighting Checks: {translateIt(item.lightingChecks)}
      </div>
      <div>
        SnoutWevil:  {translateIt(item.snoutWeevil)}
      </div>
      <div>
        Fertilizations: {translateIt(item.fertilizations)}
      </div>   
    </div>
  )
}
const useStyles = makeStyles(theme => ({
  // const styles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        // backgroundColor: theme.palette.secondary.main,
        textAlign: 'center'
      },
      [theme.breakpoints.down('md')]: {
        margin: 10,
        // backgroundColor: theme.palette.secondary.main,
      },
      [theme.breakpoints.up('md')]: {
        // backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('lg')]: {
        // backgroundColor: '#ccc',
      },
    },
    topButtons: {
      [theme.breakpoints.down('sm')]: {
        // backgroundColor: theme.palette.secondary.main,
        // textAlign: 'center',
        margin: 5,
      },
      [theme.breakpoints.down('md')]: {
        margin: 5,
        // backgroundColor: theme.palette.secondary.main,
      },
      [theme.breakpoints.up('md')]: {
        // backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('lg')]: {
        // backgroundColor: '#ccc',
      },
    },
    mainContainer: {
      border: '3px solid #aaa',
      // height: '80vh',
      width: '100%',
      padding: 5
    },
    topRowArea: {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'stretch',
      justifyContent: 'flext-start',
      // backgroundColor: 'red'
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    dayChooserArea: {
      width: '30%'
    },
    agreementContainer: {
      padding: 5,
      margin: 10,
      // border: '2px solid #bbb',
      // borderRadius: 30
      // backgroundColor: 'blue'
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    })
  );
