import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

const WarrantyPhotos = (props) => {
  const [uploads, setUploads] = React.useState([])

  React.useEffect(() => {
    let isMounted = true
    const fetchPhoto = async (id) => {
    // console.log('Fetch Photos', id)
      let url = `${props.url}/api/uploads/getWarrantyUploadsForPO?id=${id}`
    // console.log('URL to retrieve: ', url)
      const response = await axios.get(url)
      if (response && response.data) {
      // console.log('Got our uploads...', response.data)
        setUploads(response.data)
      }
    }
    if (props.po && props.po._id) fetchPhoto(props.po._id)

    return () => {
      isMounted = false
    }
  }, [props.po])
  return (
    <div style={{ margin: 15, textAlign: 'center', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
    <div style={{ fontSize: '1.2em', color: '#bbb' }}>Warranty Photos ({uploads.length})</div>
    {
      uploads.map((item, index) => {
        let theURL = item.url
        if (theURL && !theURL.includes('https')) {
          theURL = `https://${theURL}`
        }
        return (
          <div key={index} style={{ display: 'flex', maxWidth: 300, margin: '10px' }}>
            <img src={theURL} style={{ width: '100%', height: 'auto' }} />
          </div>
        )
      })
    }
  </div>

  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(WarrantyPhotos)
