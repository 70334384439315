import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // // width: 200,
  },
}));

const NewTaskModal = (props) => {
  // console.log('New Task Modal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [clients, setClientList] = React.useState([])
  const [projectList, setProjectList] = React.useState([])
  const [jobs, setJobs] = React.useState([])
  const [associatedProject, setAssociatedProject] = React.useState({})

  const [state, setState] = React.useState({
    note: '',
    assigned_to: {},
    client: {},
    associatedProject: {},
    counter: 0
  })

  const inputRef = React.useRef({ })

  React.useEffect(() => {
    let isMounted = true
    const fetchClients = async () => {
      setLoading(true)
      // ?byEntity=610a290f45ef7427f94b2e78
      let url = `${props.url}/api/customer/clients?active=true`
      axios.get(url).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setClientList(response.data)
        }
      })
    }
    if (props.user && props.user._id) fetchClients()
  }, [])

  React.useEffect(() => {
  // console.log('CReate Task Props changed', props)
    let newState = {}
    if (props && props.client && props.client._id) {
      newState.client = props.client
      if (!props.project) fetchProjectsAssociatedToClient(props.client._id)
    } else {
      newState.client = {}
    }
    if (props && props.project && props.project._id) {
      newState.project = props.project
    } else {
      newState.project = {}
    }
  // console.log('Set our state', newState)
    setState({...state, ...newState})
  }, [props])

  const handleClose = () => {
    setAssociatedProject({})
    // setAssociatedProject({})
    setState({...state, assigned_to: {}, client: {}, associatedProject: {}, note: '' })
  }

  const handleSave = () => {
    // console.log(inputRef)
    setLoading(true)
    // setClient({})
    // setState({...state, note})
  // console.log('Handle save: ', state)
    let data = {
      details: inputRef.current['details'],
      assigned_to: state.assigned_to,
      dueDate: inputRef.current['dueDate'],
      client: state.client._id
    }
    if (associatedProject && associatedProject._id) data.project = associatedProject._id
  // console.log(data)
    axios({
      method: 'post',
      url:  `${props.url}/api/tasks/createTask`,
      data: data
    }).then(response => {
      setLoading(false)
      if (response.data && response.data._id) {
        props.notification({
          type: 'success',
          title: 'Task Saved',
          message: 'Task Saved And Assigned!'
        })
        handleClose()
        // props.updateParent(response.data)
        // props.closeModal()
      }
    })
  }
  
  const updateRef = (e) => {
    inputRef.current[e.target.id] = e.target.value
  }

  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
        let newArr = []
        let string = e.target.value
        props.users.filter(o => {
          let str = o['name'].toString().toLowerCase() || ''
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          setState({...state, assigned_to: newArr[0]})
        }
      }
  }
  const fetchProjectsAssociatedToClient = async (_id) => {
    axios.get(`${props.url}/api/projects/listProjects?clientId=${_id}`).then(response => {
      if (response && response.data && response.data.projects) {
        // console.log('Asociated projects', response.data)
        setProjectList(response.data.projects)
        // setSt
        // if (props.estimate && props.estimate.project) {
        //   let index = response.data.findIndex(item => (item._id === props.estimate.project))
        //   if (index > -1) {
        //     setAssociatedProject(response.data[index])
        //     setState({...state, associatedProject: response.data[index]})
        //   }
        // }
      }
      if (response && response.data && response.data.jobs) {
        // console.log('Set Jobs')
        setJobs(response.data.jobs)
      }
    })
  }

  const updateAssignedClient = (e, newData) => {
    if (newData && newData._id) {
      setState({...state, client: newData })
      // Get Projects for this client
      fetchProjectsAssociatedToClient(newData._id)
    }
  }

  const updateProject = (e, proj) => {
    setAssociatedProject(proj)
    setState({...state, associatedProject: proj })
  }

  const updateAssignedTo = (e, newData) => {
    setState({...state, assigned_to: newData})
  }
  // const filteredEmployees = () => {
  //   function hasUser (employee) {
  //   // console.log(employee)
  //     if (employee && employee.user) {
  //       return employee
  //     }
  //   }
  //   // console.log('Props.employees', props.employees)
  //   let filtered = props.employees.filter(hasUser)
  //   // console.log('filtered:', filtered)
  //   return filtered
  // }
  // let t = filteredEmployees()
  // console.log('T:', t)
  return (

      <Dialog open={props.open} fullWidth={true}  maxWidth='md' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Create New Task</DialogTitle>
          <DialogContent >
            <DialogContentText>
                Please Assign This Task:
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`assigned_to`}
                  options={props.users || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.name || ''
                  }}
                  value={state.assigned_to}
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateAssignedTo}
                  // onChange={e=>setState({...state, employee: e.target.value})}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  // value = {state.status}
                  // ref={inputRef.status}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Assign Task To" helperText="Who Is The Task Assigned To?" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`client`}
                  options={clients || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.displayName || ''
                  }}
                  value={state.client}
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateAssignedClient}
                  // onChange={e=>setState({...state, employee: e.target.value})}
                  disabled={loading || props.client}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  // value = {state.status}
                  // ref={inputRef.status}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Associated Client" helperText="With Which Customer Is This Associated?" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
              </Grid>

              
                
              
              <Grid item lg={12} md={12} sm={12} xs={12}>

              {
                  (state.client && state.client._id) ? (
                    <Autocomplete
                      id={`project`}
                      options={projectList || []}
                      getOptionLabel={(option) => {
                        // console.log('Options: ', option)
                        return option.name || ''
                      }}
                      // value={state.client}
                      // style={{ width: 200 }}
                      // style={{marginBottom: '20px'}}
                      onChange={updateProject}
                      // onChange={e=>setState({...state, employee: e.target.value})}
                      disabled={loading || props.project}
                      // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                      // value = {state.status}
                      // ref={inputRef.status}
                      // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                      renderInput={(params) => <TextField {...params} label="Associated Project" helperText="With Which Project Is This Associated?" onKeyDown={handleTabInteraction} variant="outlined" />}
                    />
                  ) : null
                }
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} style={{marginTop: 15}}>
              <DialogContentText>
                Please Add Task Details Below:
              </DialogContentText>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    id="details"
                    label="Task Details"
                    // style={{ margin: 8 }}
                    // value={inputRef.projectName}
                    onChange={updateRef}
                    // onChange={e=> setState({...state, projectName: e.target.value})}
                    // ref={inputRef['projectName']}
                    placeholder="Task Details"
                    helperText="Please Describe The Task In Detail"
                    fullWidth
                    multiline
                    rows={4}
                    // value={inputRef.current['projectName']}
                    margin="normal"
                    variant="outlined"
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                  id="dueDate"
                  label="Requested Completion Time"
                  type="datetime-local"
                  defaultValue={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
                  // ref={inputRef.dueDate}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={updateRef}
                  fullWidth
                />
                </Grid>
              </Grid>

            </Grid>          
          </DialogContent>
          <DialogActions>
             <Button onClick={props.closeModal} color="primary">
              Close
            </Button> 
            <Button color="primary" disabled={loading} onClick={handleSave}>
              {
                loading ? <CircularProgress /> : 'Save'
              }
            </Button>
          </DialogActions>
        </Dialog>
  
  )
}


  const mapStateToProps = (state) => {
    // console.log('STATE TO PROPS', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      employees: state.employees,
      users: state.users
    }
  }
  
  export default connect(mapStateToProps)(NewTaskModal)