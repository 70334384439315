'use strict'
import React from 'react'
import ListOfItems from './ListOfItems'
import Paper from '@material-ui/core/Paper';
import axios from 'axios'
import { connect } from 'react-redux'

const CavansQueue = (props) => {
  const [clients, setClients] = React.useState([])
  const [projects, setProjects] = React.useState([])
  // const []
  React.useEffect(() => {
    let isMounted = true
    const fetchClients = async () => {
      // setLoading(true)
      axios({
        method: 'get',
        url: `${props.url}/api/customer/clients`
      }).then(response => {
      // console.log('Got our clients for User Queues; ', response.data)
        // setLoading(false)
        if (isMounted) {
          setClients(response.data)
          // setState({ ...state, next_touch_at: fortyEightHours })
          // setFilteredRows(response.data)
        }
      })
    }
    const fetchProjects = async () => {
      // setLoading(true)
      axios({
        method: 'get',
        url: `${props.url}/api/projects/listProjects`
      }).then(response => {
      // console.log('Got our projects for User Queues; ', response.data)
        // setLoading(false)
        if (isMounted && response.data && response.data.projects) {
          setProjects(response.data.projects)
          // setState({ ...state, next_touch_at: fortyEightHours })
          // setFilteredRows(response.data)
        }
      })
    }

    fetchClients()
    fetchProjects()
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <Paper style={{ margin: 10 }}>
      
      <ListOfItems clients={clients} projects={projects} />
    </Paper>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(CavansQueue)
