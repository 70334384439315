import React, { useState } from 'react';
import { connect } from 'react-redux'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useParams } from 'react-router-dom'

const handleFocus = e => e.target.select()

const URL = `${process.env.PUBLIC_URL}/flowers`
const useStyles = makeStyles((theme) => ({
  logoArea: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    alignSelf: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    marginBottom: 15,
  },
  headerArea: {
    marginTop: 25,
    textAlign: 'center',
    padding: 15,
    fontWeight: 500,
    color: '#888',
  },

  media: {
    height: 140,
  },
  priceIndicator: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
  },
  colorCheckbox: {
    marginRight: theme.spacing(1),
  },
  nameRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  flowerCard: {
    maxWidth: 200,
    margin: 'auto',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selectedCard: {
    backgroundColor: theme.palette.action.selected,
  },
}));

const flowerTypes = [
  {
    name: 'Geraniums',
    colors: ['red', 'white', 'pink', 'salmon'],
    image: `${URL}/geranium3.jfif`,
    priceLevel: 1,
  },
  {
    name: 'Petunias',
    colors: ['red', 'white', 'pink', 'blue', 'purple'],
    image: `${URL}/petunia.jpg`,
    priceLevel: 2,
  },
  {
    name: 'Cyclamen',
    colors: ['red', 'white', 'pink'],
    image: `${URL}/cyclamen.jpg`,
    priceLevel: 3,
  },
  {
    name: 'Pansies',
    colors: ['red', 'white', 'pink', 'blue', 'purple'],
    image: `${URL}/pansies.jpg`,
    priceLevel: 2,
  },
  {
    name: 'Snapdragons',
    colors: ['red', 'white', 'pink', 'blue', 'orange'],
    image: `${URL}/snapdragons.jpg`,
    priceLevel: 2,
  },
  {
    name: 'Trailing Bacopa',
    colors: ['blue', 'white'],
    image: `${URL}/bacopa.jpeg`,
    priceLevel: 2,
  },
  {
    name: 'Trailing Alyssum',
    colors: ['white'],
    image: `${URL}/trailingallysum.webp`,
    priceLevel: 2,
  },
  {
    name: 'Lobelia',
    colors: ['blue'],
    image: `${URL}/lobelia.webp`,
    priceLevel: 2,
  },
  {
    name: 'Kalanchoe',
    colors: ['red', 'orange', 'white', 'yellow'],
    image: `${URL}/kalanchoe.webp`,
    priceLevel: 2,
  },
];

const WinterFlowersSelection = (props) => {
  const classes = useStyles();
  const [wantFlowers, setWantFlowers] = useState(false);
  const [selectedFlowers, setSelectedFlowers] = useState({});
  const [sameAsLastYear, setSameAsLastYear] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [notes, setNotes] = useState('');
  const [orderSubmitted, setOrderSubmitted] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    phone: '',
    address: ''
  });
  const [state, setState] = useState({
    numberOfPots: 0,
    numberOfPlanters: 0
  })

  const { clientId } = useParams()

  // React.useEffect(() => {
  //   console.log('Get customer details to dsiplay', clientId)
  //   const fetchClientInfo = async () => {
  //     const resp = await axios.get(`${props.url}/api/customer/punchlistItems?woId=${woId}`);
  //   }
  //   fetchClientInfo()
  // }, [clientId])

  const handleCustomerInfoChange = (event) => {
    const { name, value } = event.target;
    setCustomerInfo(prevInfo => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handleFlowerSelect = (flowerName, color) => {
    setSelectedFlowers((prev) => {
      const prevFlowerColors = prev[flowerName] || [];
      if (prevFlowerColors.includes(color)) {
        // Deselect color
        const updatedColors = prevFlowerColors.filter((c) => c !== color);
        if (updatedColors.length === 0) {
          // Remove the flower entry if no colors are selected
          const { [flowerName]: _, ...rest } = prev;
          return rest;
        } else {
          return {
            ...prev,
            [flowerName]: updatedColors,
          };
        }
      } else {
        // Select color
        return {
          ...prev,
          [flowerName]: [...prevFlowerColors, color],
        };
      }
    });
  };

  const renderPriceIndicator = (priceLevel) => {
    return (
      <div className={classes.priceIndicator}>
        {[...Array(priceLevel)].map((_, index) => (
          <span>$</span>
        ))}
      </div>
    );
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleCardClick = (event, flowerName) => {
    event.preventDefault();
    handleFlowerSelect(flowerName);
  };

  const handleCheckboxClick = (event) => {
    event.stopPropagation();
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleProceed = (action) => {
    // Close the modal
    setOpenModal(false);
    let updateObject = {
      client: clientId,
      wantFlowers,
      selectedFlowers,
      sameAsLastYear,
      notes,
      ...(clientId ? {} : customerInfo),
      ...state
    };
    axios({
      method: 'post',
      url:  `${props.url}/api/jobs/orderWinterFlowers`,
      data: updateObject,
    }).then(response => {
      if (response?.data?.success) {
        setOrderSubmitted(true);
        props.notification({
          type: 'success',
          title: 'Successfully Submitted',
          message: 'Thank you, we will be in touch very shortly to upgrade your landscape!'
        })
      }
      // console.log('EMAIL StatementModal RSPONSE: ', response)

    })
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically send the form data to your backend
    console.log({
      wantFlowers,
      selectedFlowers,
      sameAsLastYear,
    });
    // alert('Selection submitted!');
    setOpenModal(true);
  };

  const handleScheduleMeeting = () => {
    // Implement scheduling logic here
    alert('Redirecting to schedule a meeting...');
  };

  const handleRequestQuote = () => {
    // Implement request quote logic here
    alert('Redirecting to request a quote...');
  };

  let logoURL = 'https://dflportal.com/build/logowide2.png';

  if (orderSubmitted) {
    return (
      <Paper style={{ padding: '20px', maxWidth: '800px', margin: 'auto', textAlign: 'center' }}>
        <div className={classes.logoArea}>
          <img
            alt="Logo"
            src={`${logoURL}`}
            style={{
              marginLeft: '-100px',
              marginTop: '5px',
              maxHeight: 100,
              maxWidth: 300,
            }}
          />
        </div>
        <Typography variant="h4" gutterBottom>
          Thanks for your order!
        </Typography>
        <Typography variant="body1">
          We appreciate your selection. We'll be in touch soon to confirm the details of your winter flowers.
        </Typography>
      </Paper>
    );
  }

  const handleChangeQty = (e) => {
    setState(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
  }

  return (
    <Paper style={{ padding: '20px', maxWidth: '800px', margin: 'auto' }}>
      <div className={classes.logoArea}>
        <img
          alt="Logo"
          src={`${logoURL}`}
          style={{
            marginLeft: '-100px',
            marginTop: '5px',
            maxHeight: 100,
            maxWidth: 300,
          }}
        />
      </div>
      <Typography variant="h4" className={classes.headerArea} gutterBottom>
        Winter Flowers Selection
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={wantFlowers}
                onChange={(e) => setWantFlowers(e.target.checked)}
                color="primary"
              />
            }
            label="I want flowers this winter"
          />
        </FormGroup>

        {wantFlowers && !sameAsLastYear && (
          <>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ marginBottom: '30px' }}
            >
              Please note: The flower selections below are to gather your preferences for this season.
              While we will do our best to accommodate your choices, specific colors and varieties
              cannot be guaranteed due to seasonal availability and supplier stock. Your selections
              help us understand your preferences so we can create a beautiful arrangement for your
              landscape.
            </Typography>
          <FormControl
            component="fieldset"
            style={{ marginTop: '18px' }}
            fullWidth
          >

            <Typography style={{ marginBottom: '10px' }} component="legend">Select Preferred Flower Varieties</Typography>
            <Grid container spacing={3}>
              {flowerTypes.map((flower) => (
                  <Grid item xs={12} sm={6} md={4} key={flower.name}>
                    <Card
                      className={`${classes.flowerCard} ${
                        selectedFlowers[flower.name] ? classes.selectedCard : ''
                      }`}
                    >
                      <CardMedia
                        className={classes.media}
                        image={flower.image}
                        title={flower.name}
                      />
                      <CardContent>
                        <div className={classes.nameRow}>
                          <Typography gutterBottom variant="h6" component="h2">
                            {flower.name}
                          </Typography>
                        </div>
                        <FormGroup>
                          {flower.colors.map((color) => (
                            <FormControlLabel
                              key={`${flower.name}-${color}`}
                              control={
                                <Checkbox
                                  checked={
                                    selectedFlowers[flower.name]?.includes(color) || false
                                  }
                                  onChange={() => handleFlowerSelect(flower.name, color)}
                                  color="primary"
                                  className={classes.colorCheckbox}
                                />
                              }
                              label={formattedColorName(color)}
                            />
                          ))}
                        </FormGroup>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </FormControl>
          </>
        )}

        <FormGroup style={{ marginTop: '20px' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sameAsLastYear}
                onChange={(e) => {
                  setSameAsLastYear(e.target.checked);
                  if (e.target.checked) {
                    setSelectedFlowers({});
                  }
                }}
                color="primary"
              />
            }
            label="Same as Last Year"
          />
        </FormGroup>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          style={{ marginTop: '20px', marginRight: '10px' }}
          disabled={
            !wantFlowers ||
            (wantFlowers && !sameAsLastYear && Object.keys(selectedFlowers).length === 0)
          }
        >
          Submit Selection
        </Button>

        {/* <Button
          variant="outlined"
          color="secondary"
          style={{ marginTop: '20px', marginRight: '10px' }}
          onClick={handleScheduleMeeting}
        >
          Schedule a Meeting
        </Button>

        <Button
          variant="outlined"
          color="secondary"
          style={{ marginTop: '20px' }}
          onClick={handleRequestQuote}
        >
          Request a Quote
        </Button> */}
      </form>
      <Dialog open={openModal} onClose={handleModalClose}>
        <DialogTitle>Next Steps</DialogTitle>
        <DialogContent>
        {!clientId && (
            <Grid container spacing={2} style={{ marginBottom: '20px' }}>
              <Grid item xs={12}>
                <Typography variant="h6">Customer Information</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="name"
                  label="Name"
                  value={customerInfo.name}
                  onChange={handleCustomerInfoChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="email"
                  label="Email"
                  type="email"
                  onFocus={handleFocus}
                  value={customerInfo.email}
                  onChange={handleCustomerInfoChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  onFocus={handleFocus}
                  name="phone"
                  label="Phone"
                  value={customerInfo.phone}
                  onChange={handleCustomerInfoChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  onFocus={handleFocus}
                  name="address"
                  label="Jobsite Address"
                  value={customerInfo.address}
                  onChange={handleCustomerInfoChange}
                />
              </Grid>
            </Grid>
          )}
           <Grid container spacing={2} style={{ marginBottom: '20px' }}>
              <Grid item xs={12}>
                <Typography variant="h6">Please Let Us Know About Your Needs</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  onFocus={handleFocus}
                  name="numberOfPots"
                  label="Number of Pots"
                  value={state.numberOfPots}
                  onChange={handleChangeQty}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  onFocus={handleFocus}
                  name="numberOfPlanters"
                  label="Number of Planters"
                  value={state.numberOfPlanters}
                  onChange={handleChangeQty}
                />
              </Grid>
            </Grid>
          <Typography variant="body1">
            Thank you for your selection! If you have any notes or special requests, please let us know below:
          </Typography>
          <TextField
          autoFocus
          margin="dense"
          id="notes"
          label="Notes/Special Requests"
          type="text"
          variant='outlined'
          fullWidth
          onFocus={handleFocus}
          multiline
          rows={4}
          value={notes}
          onChange={handleNotesChange}
        />
          <Typography variant="body1">
            We will send you a quote via email to approve before we proceed with any plantings. If you do not receive a quote in the next few business days, please call the office 480 488-0128.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => handleProceed('proceed')}
            disabled={(!clientId && Object.values(customerInfo).some(value => value === ''))}
            color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

const formattedColorName = (color) => {
  let firstChar = color[0].toUpperCase()
  let newColor = firstChar + color.substr(1, color.length - 1)
  return newColor
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(WinterFlowersSelection))
