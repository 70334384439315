import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField';
import axios from 'axios'
import { Table, TableHead, TableRow, TableBody, TableCell, Button } from '@material-ui/core';
import { currencyFormat } from '../../services/common'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 0,
    marginTop: 10,
    backgroundColor: '#eee',
    minHeight: 80,
    border: '1px solid #ccc',
    borderRadius: 6,
    padding: 10,
    maxHeight: 350,
    overflowY: 'scroll'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  compHistoryBox: {

  },
  paddedStuff: {
    padding: 10
  }
}))

const CompensationComponent = (props) => {
  const classes = useStyles()
  const [state, setState] = React.useState({
    counter: 0
  })
  const [showComp, setShowComp] = React.useState(false)
  const [payHistory, setPayHistory] = React.useState([])

  const fetchPayHistory = async (employeeId) => {
    const resp = await axios.get(`${props.url}/api/employees/getPayHistory?employeeId=${employeeId}`)
    // console.log('Response: ', resp.data)
    if (resp && resp.data && resp.data.length) setPayHistory(resp.data)
  }

  const handleToggleComp = () => {
    if (showComp) {

    } else {
      if (props.employee && props.employee._id) fetchPayHistory(props.employee._id)
    }
    setShowComp(!showComp)
  }

  const handlePayChangeUpdate = async (data) => {
    // console.log('UPdated our pay!!! save this:', data)
    let ourData = {...data}
    ourData.created_by = props.user
    // console.log('Pay CHange data to save...', ourData)
    let exisingPayHistory = payHistory
    exisingPayHistory.unshift(ourData)
    setPayHistory(exisingPayHistory)
    setState({...state, counter: state.counter++ })
    props.notification({
      title: 'Updated Compensation!',
      message: `Successfully updated the pay rate for ${props.employee.firstName}.`,
      type: 'success'
    })
  }

  return (
    <React.Fragment >
      <Button
        variant="contained"
        color="secondary"
        onClick={handleToggleComp}
        >{ `${showComp ? 'Hide' : 'View' } Compensation Details` }
      </Button>
      <CompensationDetails {...props} show={showComp} payHistory={payHistory} updateParent={handlePayChangeUpdate} />
    </React.Fragment>
  )
}

const CompensationDetails = (props) => {
  const [state, setState] = React.useState({
    pay: props.employee.pay
  })
  const [showPayChangeReason, setShowPayChangeReason] = React.useState(false)

  const show = props.show
// console.log('Show Compensation history', show)
  const classes = useStyles()

  const handleSavePayChange = (d) => {
  // console.log('Save change reason:', d)
    setShowPayChangeReason(false)
    // Post this to the bback
    const obj = {
      payChangeData: d,
      employeeId: props.employee._id
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/employees/updateEmployeeComp`,
      data: obj,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Response from updating pay change:', response.data)
      // props.notification()
      props.updateParent(response.data)
      
    })
  }

  const handleModifyPay = () => {
    setShowPayChangeReason(true)
  }

  if (show) {
    return (
      <div className={classes.root}>
        <PayChangeJustificationModal open={showPayChangeReason} employee={props.employee} saveReason={handleSavePayChange} />

      <div style={{ fontSize: '1.12em', color: '#aaa' }}>
        Compenstation History
      </div>
      <div>
      <Button
        variant="contained"
        style={{ marginLeft: 'auto' }}
        color="secondary"
        onClick={handleModifyPay}
        >Modify
      </Button>
      </div>

      <Comphistory {...props} payHistory={props.payHistory} />
      </div>
    )
  } else {
    return null
  }
}

const CompensationHistoryItem = (props) => {
  const classes=useStyles()
  return (
    <div className={classes.compHistoryBox}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Approver</TableCell>
            <TableCell>Old Rate</TableCell>
            <TableCell>New Rate</TableCell>
            <TableCell>Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
        </TableBody>
      </Table>
    </div>
  )
}

const Comphistory = (props) => {
  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Approver</TableCell>
            <TableCell>Old Rate</TableCell>
            <TableCell>New Rate</TableCell>
            <TableCell>Reason</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props.payHistory.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{moment(item.created_at).format('MM/DD/YY')}</TableCell>
                  <TableCell>{item.created_by ? item.created_by.name ? item.created_by.name : '' : '' }</TableCell>
                  <TableCell>{currencyFormat(item.oldRate)}</TableCell>
                  <TableCell>{currencyFormat(item.newRate)}</TableCell>
                  <TableCell>{item.notes}</TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </div>
  )
}

const PayChangeJustificationModal = (props) => {
  const classes = useStyles()
  const [state, setState] = React.useState({
    reason: '',
    rate: props.employee.pay
  })
  const handleChange = (e) => {
    setState({...state, [e.target.id]: e.target.value})
  }

  const handleSave = () => {
    let data = {
      notes: state.reason,
      rate: state.rate
    }
    props.saveReason(data)
  }
  return (
    <Dialog open={props.open} maxWidth={'md'} fullWidth>
      <DialogTitle>Pay Change Details</DialogTitle>
      <DialogContent>
        <DialogContentText>What Is the New Pay Rate?</DialogContentText>
         <TextField         
          // className={classes.paddedStuff}
            margin="dense"
            id="rate"
            label="Hourly Rate"
            type="text"
            variant="outlined"
            onChange={handleChange}
            value={state.rate}
            fullWidth
            helperText="How Much Is The New Rate?"
          />
          <div></div>
           <DialogContentText>Why Are We Modifying the Pay?</DialogContentText>
          <TextField
              id="reason"
              label="Notes"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              // className={classes.textSaveLineBreaks}
              // value={state.notes}
              onChange={handleChange}
              // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
              // defaultValue="Default Value"
          />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleSave}
          >Save</Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(CompensationComponent)