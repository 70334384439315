import React, { useState } from 'react';
import { Dialog, Backdrop, Fade, Typography, Paper, List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PageviewIcon from '@material-ui/icons/Pageview';

const ChangeLogDialog = ({ changeLog, notes }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  // Handles the hover state
  const handleOpen = () => {
    console.log("Open it up")
    setOpen(true);
  }
  const handleClose = () => setOpen(false);
  console.log('ChangeLog:', changeLog)
  const changes = changeLog.changes
  return (
      <div>
          {notes} 
          <span onClick={handleOpen} style={{ marginLeft: 10, cursor: 'pointer', color: 'blue' }}>
                View Details
          </span>

          {/* Dialog Definition */}
          <Dialog
              aria-labelledby="transition-Dialog-title"
              aria-describedby="transition-Dialog-description"
              className={classes.Dialog}
              open={open}
              maxWidth={'md'}
              fullWidth
              onClose={handleClose}
             
          >
                  <Paper className={classes.paper}>
                      <Typography variant="h6" id="transition-Dialog-title">
                          Change Details
                      </Typography>
                      <Typography variant="h7" id="transition-Dialog-title">
                          {`Changes Made By: ${changeLog.created_by}`}
                      </Typography>
                        {renderChangeItems(changes?.added || {}, "Added", classes)}
                        {renderChangeItems(changes?.deleted || {}, "Deleted", classes)}
                        {renderChangeItems(changes?.updated || {}, "Updated", classes)}
                  </Paper>
          </Dialog>
      </div>
  );
};

const renderChangeItems = (changes, title) => {
    const renderItem = (key, value) => {
        // If the key is 'jobSites' and the value is an object, treat it specially
        if (key === 'jobSites' && typeof value === 'object') {
            return (
                <List key={key}>
                    {Object.entries(value).map(([siteKey, siteChanges]) => {
                        const siteName = siteChanges.name || 'Unknown Site';
                        return (
                            <div key={siteKey}>
                                <Typography variant="subtitle1">{siteName}</Typography>
                                {Object.entries(siteChanges).map(([propKey, propValue]) => {
                                    if (propKey !== 'name') { // Skip name as it's used as a header
                                        return (
                                            <ListItem key={propKey}>
                                                <ListItemText primary={`${propKey} updated to ${propValue}`} />
                                            </ListItem>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        );
                    })}
                </List>
            );
        } else {
            // Handle regular properties
            const text = `${key} updated to ${JSON.stringify(value, null, 2)}`;
            return (
                <ListItem key={key}>
                    <ListItemText primary={text} />
                </ListItem>
            );
        }
    };
    if (changes) {
        return (
            <div>
                <Typography variant="h6" style={{ marginTop: 20 }}>{title}</Typography>
                <List>
                    {Object.entries(changes).map(([key, value]) => renderItem(key, value))}
                </List>
            </div>
        );
    } else {
        return null
    }
};

const renderValue = (value) => {
    if (typeof value === 'string' || typeof value === 'number') {
        return <Typography>To: {value}</Typography>;
    } else if (typeof value === 'object' && value !== null) {
        return (
            <List>
                {Object.entries(value).map(([subKey, subValue]) => (
                    <ListItem key={subKey}>
                        <ListItemText primary={subKey} secondary={renderValue(subValue)} />
                    </ListItem>
                ))}
            </List>
        );
    }
    return <Typography>{JSON.stringify(value)}</Typography>;
};

const formatChangeDescription = (changeType, jobSiteChanges, siteName) => {
    return Object.entries(jobSiteChanges).map(([property, value]) => {
        const newValue = typeof value === 'object' ? JSON.stringify(value, null, 2) : value;
        return `${jobSiteChanges.created_by || 'Someone'} ${changeType} ${property} to ${newValue} at ${siteName}`;
    });
};

// Styles for the Dialog and the container
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        // boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxWidth: '600px',
        maxHeight: '80%',
        overflow: 'auto',
    },
    header: {
        marginTop: theme.spacing(2),
    },
}));

export default ChangeLogDialog
