import React from 'react'

const ComplexInvoiceDisplayDescription = ({ invoice }) => {
  console.log('Complex Invoice DSipslay', invoice)

  return (
    <div>
        <span dangerouslySetInnerHTML={{__html: invoice.htmlContent }} ></span>
    </div>
  )
}

export default ComplexInvoiceDisplayDescription
