import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import SateliteView from '../Crews/SateliteView'
// import styles from './customer.module.scss'
import MergeProjectsComponent from './MergeProjectsComponent'


const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const defaultInputValues = {
  name: ''
}

const statusOptions = [
  'Archived',
  'Lost',
  'New',
  'Pending Meeting',
  'Pending Estimate',
  'Estimate Created',
  'Estimate Sent',
  'Pending Revisions',
  'Revisions Sent',
  'Pending Plans',
  'Tentative Start Date',
  'Pending Deposit',
  'Deposit Paid',
  'Pending HOA Sub.',
  'HOA Submittal',
  'In Progress',
  'Pending Progress Payment',
  'Pending Final Walk',
  'Final Walk',
  'Pending Final Payment',
  'Final Bill',
  'Final Paid',
  'Begin 90 Days Free Mtc',
  'Pending 3 Month',
  'Warranty Work Done',
  'Complete'
]

const defaultStateVals = {
  // status: 'New',
  salesRep: {},
  projectManager: {},
  foreman: {},
  name: '',
  description: '',
  _id: '',
  startDate: null,
  endDate: null,
  jobSite: {},
  completedDate: null,
  totalLaborCostsEstimate: '',
  totalMaterialCostsEstimate: '',
  totalSubCostsEstimate: ''
}

const EditProjectModal = (props) => {
  // console.log('Edit PRoject Modal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [client, setClient] = React.useState({})
  const [state, setState] = React.useState(defaultStateVals)

  const inputRef = React.useRef({})

  React.useEffect(() => {
    let newState = { ...state }
  // console.log('PROJECT changed::', props.project)
    if (props.project && props.project._id) {
      // console.log('Set the project...', props.project)
      newState._id = props.project._id
      newState.name = props.project.name
      if (props.project.startDate) newState.startDate = props.project.startDate
      if (props.project.endDate) newState.endDate = props.project.endDate
      newState.description = props.project.description
      newState.completedDate = props.project?.completedDate
      // console.log('PROJECT TO GET REP: ', props.project)
      inputRef.current.name = 'TEST' // props.project.name
      let salesRep = {}
      if (props.project?.totalLaborCostsEstimate) newState.totalLaborCostsEstimate = props.project.totalLaborCostsEstimate
      if (props.project?.totalMaterialCostsEstimate) newState.totalMaterialCostsEstimate = props.project.totalMaterialCostsEstimate
      if (props.project?.totalSubCostsEstimate) newState.totalSubCostsEstimate = props.project.totalSubCostsEstimate
      if (props.project?.completedDate) newState.completedDate = props.project.completedDate


      if (props.project && props.project.salesRep && props.project.salesRep._id) {
        // console.log('Sales REp: ', props.project.salesRep)
        newState.salesRep = props?.project?.salesRep
        // console.log('InputRef:', inputRef)
        // let index = props.salesReps.findIndex(item => item._id === props.project.salesRep._id)
        // console.log('INDEX SALES REP:', index)
        // if (index > -1) {
        //   salesRep = props.salesReps[index]
        //   newState.salesRep = salesRep

        // }
      }
      if (props.project && props.project.projectManager && props.project.projectManager._id) {
        // console.log('projectManager: ', props.project.projectManager)
        newState.projectManager = props.project.projectManager
        // console.log('InputRef:', inputRef)
        // let index = props.employees.findIndex(item => item._id.toString() === props.project.projectManager._id.toString())
        // console.log('INDEX SALES REP:', index)
        // if (index > -1) {
        //   // salesRep = props.salesReps[index]
        //   newState.projectManager = props.employees[index]

        // }
      }
      if (props.project && props.project.foreman && props.project.foreman._id) {
        // console.log('Foreman: ', props.project.foreman)
        newState.foreman = props.project.foreman
        // console.log('InputRef:', inputRef)
        // let index = props.employees.findIndex(item => item._id.toString() === props.project.foreman._id.toString())
        // console.log('INDEX SALES REP:', index)
        // if (index > -1) {
        //   // salesRep = props.salesReps[index]
        //   newState.foreman = props.employees[index]

        // }
      }
      if (props?.project?.jobSite) {
        let theJobSite = props?.jobSites.find(item => (item._id === props.project.jobSite))
        newState.jobSite = theJobSite
      }
    // console.log('Set State', newState)
      // _id: props.project._id, name: props.project.name, salesRep: salesRep, description: props.project.description
    //  console.log('217', newState)
    } else {
      newState = defaultStateVals
    }
    // console.log('Set State editproject', newState)
    setState({...state, ...newState })
  }, [props])

  const handleSave = () => {
  // console.log(inputRef)
    setLoading(true)

    axios({
      method: 'post',
      url:  `${props.url}/api/projects/edit`,
      data: state
    }).then(response => {
      setLoading(false)
      if (response.data && response.data._id) {
        props.notification({
          type: 'success',
          title: 'Project Saved',
          message: 'That Project Has Been Saved. Great Job!'
        })
        setClient({})
        setState({...state, status: 'New', name: ''})
        props.updateParent(response.data)
        // props.closeModal()
      }

    })
  }
  const updateCustomer = (e, customer) => {
    if (customer && customer._id) {
      setClient(customer)
      inputRef.current.client = customer
    }
  }

  const updateSalesRep = (e, rep) => {
    if (rep && rep._id) {
      inputRef.current.salesRep = rep
      setState({...state, salesRep: rep })
    }
  }

  const updatePerson = (e, rep) => {
  // console.log('ID;', e.target.id)
    let type = e.target.id.split('-')[0]
    if (rep && rep._id) {
      inputRef.current[type] = rep
      setState({...state, [type]: rep })
    }
  }

  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      // console.log(e.target)
      // console.log('Tab', e.target.id)
      if (e.target.id === 'customername') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          setClient(newArr[0])
        }
      } else {
        if (e.target.id === 'projectManager') {
          let newArr = []
          let string = e.target.value
          props.employees.filter(o => {
            let str = o['displayName'].toString().toLowerCase()
            if (str.includes(string.toLowerCase())) {
              return newArr.push(o)
            } else {
              return null
            }
          })
          // console.log(newArr)
          if (newArr && newArr.length) {
            // setClient(newArr[0])
            setState({...state, projectManager: newArr[0]})
          }
        }
        if (e.target.id === 'foreman') {
          let newArr = []
          let string = e.target.value
          props.employees.filter(o => {
            let str = o['displayName'].toString().toLowerCase()
            if (str.includes(string.toLowerCase())) {
              return newArr.push(o)
            } else {
              return null
            }
          })
          // console.log(newArr)
          if (newArr && newArr.length) {
            // setClient(newArr[0])
            setState({...state, foreman: newArr[0]})
          }
        }
      }
    }
  }

  const updateStatus = (e, status) => {
    inputRef.current.status = status
  }
  const updateRef = (e) => {
    inputRef.current[e.target.id] = e.target.value
  }

  const handleChangeDates = (e) => {
  // console.log('Change date', e.target.value)
    // console.log('Current end date', state.endDate)
    let newState = {}
    let type = e.target.id
    // console.log('Type: ', type)
    if (type === 'startDate') {
      if (moment(state.endDate) < moment(e.target.value) || !state.endDate) {
        // console.log('update end date too!', state.endDate)
        newState.endDate = moment(e.target.value).add(6, 'weeks').format('YYYY-MM-DD')
      }
      newState.startDate = moment(e.target.value).format('YYYY-MM-DD')
    }
    if (type === 'endDate') {
      if (moment(e.target.value) < moment(state.startDate)) {
        props.notification({
          type: 'warning',
          title: `That Doesn't Seem Right`,
          message: `${moment(e.target.value).format('MM/DD/YY')} is before the Project Start Date - ${moment(state.startDate).format('MM/DD/YY')}`
        })
      } else {
        newState.endDate = moment(e.target.value).format('YYYY-MM-DD')
      }
      
    }
    if (type === 'completedDate') {

        newState.completedDate = moment(e.target.value).format('YYYY-MM-DD')

      
    }
    // console.log('Set new state', newState)
    setState({...state, ...newState}) 
  }

  const updateJobsite = (e, newSite) => {
    // console.log('Update jobsite', e.target.value)
    // console.log('Site', newSite)
    setState(prevState => {
      setState({
        ...prevState,
        jobSite: newSite
      })
    })
  }

  return (
    <div >
      <Dialog open={props.open} fullWidth={true} onClose={props.closeModal} maxWidth='md' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <div style={{ display: 'flex'}}>Edit Project ({state.name}) </div>
              <div style={{ display: 'flex'}}>
                <span style={{ marginLeft: 'auto' }}>
                  <MergeProjectsComponent project={props.project} />
                </span>
              </div>
            </div>
            
           
          </DialogTitle>
          <DialogContent >
            
            <DialogContentText>
              Please Provide Project Information Below
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="name"
                  label="Project Name"
                  // style={{ margin: 8 }}
                  value={state.name}
                  // onChange={updateRef}
                  onChange={e=> setState({...state, name: e.target.value})}
                  // ref={inputRef['name']}
                  placeholder="Project Name"
                  helperText="What Should We Call This Project Internally?"
                  fullWidth
                  // value={inputRef.current['name']}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`jobSite`}
                  options={props.jobSites || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return `${option.name ? option.name : ''} ${option.address ? option.address : ''} ${option.buildingNumber ? `(Bldg #${option.buildingNumber})` : ''} ${option.city ? `${option.city}` : ''}`
                  }}
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateJobsite}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.jobSite}
                  // ref={inputRef.salesperson}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Jobsite" helperText="What Jobsite?" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
              </Grid>
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`customername`}
                  options={props.clients || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.displayName || ''
                  }}
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateCustomer}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {client}
                  // ref={inputRef.client}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Customer" helperText="Who Is The Client?" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
              </Grid> */}
              {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`status`}
                  options={statusOptions || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option || ''
                  }}
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateStatus}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  // value = {state.status}
                  // ref={inputRef.status}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Project Status" helperText="What Is The Project Status?" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
              </Grid> */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`salesperson`}
                  options={props.salesReps || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.displayName || ''
                  }}
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updateSalesRep}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.salesRep}
                  // ref={inputRef.salesperson}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Salesperson" helperText="Who Is The Assigned Salesperson?" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`projectManager`}
                  options={props.employees || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.displayName || ''
                  }}
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updatePerson}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.projectManager}
                  // ref={inputRef.salesperson}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Project Manager" helperText="Who Is The Assigned Project Manager?" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id={`foreman`}
                  options={props.employees || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.displayName || ''
                  }}
                  // style={{ width: 200 }}
                  // style={{marginBottom: '20px'}}
                  onChange={updatePerson}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.foreman}
                  // ref={inputRef.salesperson}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Foreman" helperText="Who Is The Assigned Foreman?" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="description"
                  label="Project Description"
                  // style={{ margin: 8 }}
                  // ref={inputRef.projectDescription}
                  placeholder="Project Description"
                  helperText="What Is This Project?"
                  fullWidth
                  multiline
                  rows={3}
                  value={state.description}
                  onChange={e => setState({...state, description: e.target.value })}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  id="startDate"
                  label="Start Date"
                  type="date"
                  // value={moment(state.invoiceDate).format('YYYY-MM-DD') } // "2017-05-24"}
                  className={classes.textField}
                  value={moment(state.startDate).format('YYYY-MM-DD')}
                  variant="outlined"
                  onChange={ handleChangeDates }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextField
                  id="endDate"
                  label="Completion Date"
                  type="date"
                  value={moment(state.endDate).format('YYYY-MM-DD')}
                  // value={moment(state.invoiceDate).format('YYYY-MM-DD') } // "2017-05-24"}
                  className={classes.textField}
                  variant="outlined"
                  onChange={ handleChangeDates }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <EstimatedCostsModule state={state} setState={setState} handleChangeDates={handleChangeDates} />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
              Cancel
            </Button> */}
            <Button color="primary" disabled={loading} onClick={handleSave}>
              {
                loading ? <CircularProgress /> : 'Save'
              }
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  )
}

const EstimatedCostsModule = ({ state, setState, handleChangeDates }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={2}>
       <Grid item lg={4} md={4} sm={6} xs={12}>
       <TextField
          id="totalLaborCostsEstimate"
          label="Estimated Labor Cost"
          // style={{ margin: 8 }}
          value={state.totalLaborCostsEstimate}
          // onChange={updateRef}
          onChange={e=> setState({...state, totalLaborCostsEstimate: e.target.value})}
          // ref={inputRef['name']}
          placeholder="Estimated Labor Costs"
          helperText="What is our Estimate Labor Costs?"
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
       </Grid>
       <Grid item lg={4} md={4} sm={6} xs={12}>
       <TextField
          id="totalMaterialCostsEstimate"
          label="Estimated Material Cost"
          // style={{ margin: 8 }}
          value={state.totalMaterialCostsEstimate}
          // onChange={updateRef}
          onChange={e=> setState({...state, totalMaterialCostsEstimate: e.target.value})}
          // ref={inputRef['name']}
          placeholder="Estimated Materials Costs"
          helperText="What is our Estimated Materials Costs?"
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
       </Grid>
       {/* <Grid item lg={3} md={4} sm={6} xs={12}>
       <TextField
          id="totalSubCostsEstimate"
          label="Subcontractor Cost"
          // style={{ margin: 8 }}
          value={state.totalSubCostsEstimate}
          // onChange={updateRef}
          onChange={e=> setState({...state, totalSubCostsEstimate: e.target.value})}
          // ref={inputRef['name']}
          placeholder="Estimated Subcontractor Costs"
          helperText="What is our Estimate Subcontractor Costs?"
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
       </Grid> */}
       <Grid item lg={4} md={4} sm={6} xs={12}>
        <TextField
          id="totalSubCostsEstimate"
          label="Subcontractor Cost"
          // style={{ margin: 8 }}
          value={state.totalSubCostsEstimate}
          // onChange={updateRef}
          onChange={e=> setState({...state, totalSubCostsEstimate: e.target.value})}
          // ref={inputRef['name']}
          placeholder="Estimated Subcontractor Costs"
          helperText="What is our Estimate Subcontractor Costs?"
          fullWidth
          margin="normal"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
       </Grid>
       <Grid item lg={12} md={12} sm={12} xs={12}>
          <TextField
            id="completedDate"
            label="No Longer Live Date"
            type="date"
            value={moment(state.completedDate).format('YYYY-MM-DD')}
            // value={moment(state.invoiceDate).format('YYYY-MM-DD') } // "2017-05-24"}
            className={classes.textField}
            variant="outlined"
            onChange={ handleChangeDates }
            InputLabelProps={{
              shrink: true,
            }}
          />
       </Grid>
    </Grid>
  )
}

  const mapStateToProps = (state) => {
    // console.log('MAP STATE TO PROPS EDIT PROJECT MODAL: ', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      salesReps: state.salesReps,
      employees: state.employees
    }
  }
  
  export default connect(mapStateToProps)(EditProjectModal)