import React, { Component } from 'react'
import styles from './profile.module.scss'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions
} from '@material-ui/core'
import axios from 'axios'
import DeleteDialog from '../Common/DeleteDialog'

class Profile extends Component {
  constructor(props) {
    super(props)
  // console.log('Profile PRops', props)
    this.state = {
      open: false,
      showDeleteDialog: false
    }
  }

  removeUser = async () => {
    const { id, email } = this.props.user
    const userdata = {
      data: {
        id,
        email
      }
    }
    this.setState({
      loading: true
    })
    const response = await axios.delete(`${this.props.url}/api/user/profileremove`, userdata)
    this.setState({
      loading: false
    })
    if (response.data.success) {
      this.props.notification({
        type: 'success',
        title: 'Success!',
        message: 'Profile deleted!'
      })
      this.props.history.push('/logout')
    } else {
      this.props.notification({
        type: 'danger',
        title: 'Delete failed!',
        message: 'Try again later!'
      })
    }
  }

  removeDialogClick = () => {
    this.setState({
      open: this.state.open ? false : true
    })
  }

   openDeleteDialog = () => {
  // console.log('open dleete ')
    this.setState({
      showDeleteDialog: true
    })
  }

  updateFromDeleteDialog = (deleteIt) => {
    this.setState({
      showDeleteDialog: false
    })
  // console.log('To Delete or Not To Delete?', deleteIt)
    // if (deleteIt) {
    //   window.alert('DELETE IT!')
    // } else {
    //   window.alert('Save it!')
    // }
  }
  render() {
    return (
      <div className={ styles.profile }>
        <DeleteDialog
          title="Delete Your Profile?"
          verificationText="Are you sure?"
          confirmationOfIntentText="This action is irreversible, you will lose access to this site and all of your data!"
          confirmationButton="DELETE"
          denyText="NO"
          open={this.state.showDeleteDialog}
          closeModal={this.updateFromDeleteDialog}
        />
        <Card className={ styles.card }>
          <CardContent>
            <Typography variant="h5" component="h2" gutterBottom>
              Profile
            </Typography>
            <Typography color="textSecondary">
              Username
            </Typography>
            <Typography component="p" gutterBottom>
              { this.props.user.username }
            </Typography>
            <Typography color="textSecondary">
              Name
            </Typography>
            <Typography component="p" gutterBottom>
              { this.props.user.name }
            </Typography>
            <Typography color="textSecondary">
              Email
            </Typography>
            <Typography component="p" gutterBottom>
              { this.props.user.email }
            </Typography>
            <Typography color="textSecondary">
              Telephone Number (SMS Alerts)
            </Typography>
            <Typography component="p" gutterBottom>
              { this.props.user.telephone ? this.props.user.telephone : <span style={{ color: 'red', fontSize: '1.2em' }}>Please Provide a Telephone #</span> }
            </Typography>
          </CardContent>
          <CardActionArea>
            <CardActions>
              <Button
                className={ styles.button }
                variant="contained"
                color="neutral"
              >
                <Link className={ styles.link } to="/profile/modify">Modify</Link>
              </Button>
            </CardActions>
          </CardActionArea>
        </Card>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(Profile)
