import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, CircularProgress }  from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const Container = styled.div`
  min-width: 400px;
  min-height: 100px;
`;

const CheckInButton = (props) => {

  const [state, setState] = React.useState({
    activeEmployee: {
      displayName: ''
    }
  })

  const checkInEmployee = (id, emp) => {
  // console.log('id: ', id)
  // console.log('CHeck in employee', emp)
    setState({...state, activeEmployee: emp})
  }

  const confirmCheckIn = () => {
    axios({
      method: 'post',
      url:  `${props.url}/api/roster/checkInEmployee`,
      data: state.activeEmployee,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('RESPONSE FROM CHECK-IN', response)
      if (response.data && response.data._id) {
      // console.log('REfresh the list')
        setState({...state, activeEmployee: null})
        props.refreshEmployeeList()
      }
    })
  }

  const handleClose = () => {
    props.closeModal()
  }
  return (
    <div style={{padding: '5px'}}>
      <Dialog
        maxWidth='md'
        onClose={handleClose}
        open={props.open}
        aria-labelledby="responsive-dialog-title"
      >
         <DialogTitle id="responsive-dialog-title">Check-In Employee</DialogTitle>
        <DialogContent style={{textAlign: 'center', color: '#fff', overflow: 'hidden'}}>
          <Container>
          <Autocomplete
                  id="Employee"
                  options={props.employees.filter(item => { return !item.isCheckedIn})}
                  getOptionLabel={(option) => option.displayName}
                  // style={{ width: 200 }}
                  onChange={checkInEmployee}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.activeEmployee}
                  // onChange={ e => setState({...state, employee: {...state.employee, employeeType: e.target.value}, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Employee To Check-In" variant="outlined" />}
                />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={confirmCheckIn}
            >Check-In</Button>
          </Container>
      </DialogContent>
    </Dialog> 
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(CheckInButton)
