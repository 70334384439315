import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from '@material-ui/core/TextField';
import NewSupplierModal from './NewSupplierModal'
import { SelectionState } from 'draft-js';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const defaultSupplierList = [
  {
    name: 'Horizon',
    _id: '453453453453'
  },
  {
    name: 'Home Depot',
    _id: '453453453453'
  },
  {
    name: 'Summer Winds Nursery',
    _id: '453453453453'
  }
]

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

const filter = createFilterOptions();

const SupplierComponent = (props) => {

  const [supplierList, setSupplierList] = React.useState(props.vendors || [])
  const [supplier, setSupplier] = React.useState({ name: '' })
  const [showNewSupplierModal, setShowNewSupplierModal] = React.useState(false)
  const [state, setState] = React.useState({
    temp: ''
  })

  React.useEffect(() => {
    if (props.vendors && props.vendors.length) {
      setSupplierList(props.vendors)
      if (props.vendor && props.vendor !== {}) {
        // console.log('We have a vendor:', props.vendor)
        // let vendor = ''
        setSupplier(props.vendor)
        // let index = props.vendors.findIndex(item => (item._id === props.vendor))
        // if (index > -1) setSupplier(props.vendors[index])
      }
    }
  }, [props])
  
  const handleUpdateSupplierList = (e, newSupplierList) => {
    // let list = supplierList
    // list.push(newSupplier)
    // setSupplierList(list)
    if (props.single) {
      if (newSupplierList && newSupplierList._id) {
        setSupplier(newSupplierList)
        props.updateParent(newSupplierList)
      }
      if (newSupplierList && newSupplierList.name && newSupplierList.name === 'Add New Vendor') {
        setState({...state, temp: newSupplierList.inputValue })
        setShowNewSupplierModal(true)
      }
    } else {
    // console.log('Update Supplier List', newSupplierList)
      e.preventDefault()
      // console.log('New: ', newSupplierList)
      let newSupplier = newSupplierList[newSupplierList.length - 1]
      if (newSupplier && newSupplier.inputValue) {
      // console.log('new item!!!')
        setState({...state, temp: newSupplier.inputValue })
        setShowNewSupplierModal(true)
      }
      if (newSupplier && newSupplier._id) {
      // console.log('An existing item was chosen')
      }
      if (typeof newSupplier === 'string') {
      // console.log('They press entered')
        setShowNewSupplierModal(true)
      }
      if (props.updateSupplierList) props.updateSupplierList(newSupplierList)
    }
    // // console.log(e.target)
    // // console.log(newSupplier)
  }

  const handleSaveNewSupplier = (d) => {
  // console.log('Handle Save this supplier', d)
    if (d && d._id) {
      setSupplier(d)
      setShowNewSupplierModal(false)
      props.updateParent(d)
    }
  }

  const handleCloseNewSupplierModal = () => {
    setShowNewSupplierModal(false)
    setSupplier({ name: '' })
    // props.closeModal()
  }
  return (
    <div>
      <NewSupplierModal open={showNewSupplierModal} name={state.temp} closeModal={handleCloseNewSupplierModal} updateParent={handleSaveNewSupplier} />

      {/* <LabelText>Please Provide Supplier Information for this item:</LabelText> */}
      <FormControl fullWidth >
      {/* <Autocomplete
        freeSolo
        id={`description-input`}
        // value={state.description}
        options={supplierList}
        value={suppliers}
        // getOptionLabel={(option) => option.name}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return 'Save ' + option.inputValue + ' as a New Item';
          }
          // Regular option
          return option.name;
        }}
        // style={{ width: 200 }}
        // onChange={handleDescriptionChange}
        onChange={(event, newValue) => {
          // console.log('Change in value:', newValue)
          event.preventDefault()
          if (newValue && newValue._id) {
            // console.log('AN Active object was selected')
            setItem(newValue)
            setState({...state, ...newValue})
          } else {
            toggleOpen(true);
            setItem({
              title: newValue,
              name: newValue
            })
          }
          if (typeof newValue === 'string') {
            setValue({
              title: newValue,
              name: newValue
            });
            // setItem({
            //   title: newValue,
            //   name: newValue
            // })
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              title: newValue.inputValue,
              name: newValue.inputValue
            });
            setItem({
              title: newValue.inputValue,
              name: newValue.inputValue,
              description: newValue.inputValue
            })
            // toggleOpen(true);
            setDialogValue({
              title: newValue.inputValue,
              year: '',
            });
          } else {
            // console.log('NEWV AL: ', newValue)
            setValue(newValue);
            if (newValue && newValue.name) {
              // setItem(newValue)
            }
          
          }
        }}
        filterOptions={(options, params) => {
          // console.log('Filtered Options', options)
          // console.log('Params', params)
          // console.log('Input value: ', params.inputValue)
          const filtered = filter(options, params);
  
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
              name: 'Test This'
            });
          }
  
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
        // value={state.activeLineItem}
        fullWidth
        // InputProps={{ style: { fontSize: 20, color: '#fff', backgroundColor: '#333' } }}
        PaperComponent={({ children }) => (
          <Paper style={{ padding: 5, fontSize: 20, color: '#bbb', backgroundColor: '#fff' }}>{children}</Paper>
        )}
        // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
        renderInput={(params) => <TextField {...params}  fullWidth label="Supplier(s)" variant="outlined" placeholder="Which Suppliers Provide This Product?"/>}
      /> */}
          <Autocomplete
            freeSolo
            // multiple
            id="supplier-list"
            fullWidth
            options={supplierList}
            onChange={handleUpdateSupplierList}
            value={supplier}
            disableCloseOnSelect={props.single ? false : true }
            style={{marginTop: 25}}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return 'Save ' + option.inputValue + ' as a New Vendor';
              }
              // Regular option
              return option.name;
            }}
            // renderOption={(option, { selected }) => {
            //   // // console.log('OPTIONS: ', option)
            //   if (option.inputValue) {
            //     return (
            //       <React.Fragment>
            //     <Checkbox
            //       icon={icon}
            //       checkedIcon={checkedIcon}
            //       style={{ marginRight: 8 }}
            //       checked={selected}
            //     />
            //     {`Add ${option.inputValue} as a New Supplier`}
            //   </React.Fragment>
            //     )
            //   } else {
            //     return (
            //   <React.Fragment>
            //     <Checkbox
            //       icon={icon}
            //       checkedIcon={checkedIcon}
            //       style={{ marginRight: 8 }}
            //       checked={selected}
            //     />
            //     {option.name}
            //   </React.Fragment>
            //     )}
            // }}
            
            filterOptions={(options, params) => {
              // // console.log('Filtered Options', options)
              // // console.log('Params', params)
              // // console.log('Input value: ', params.inputValue)
              const filtered = filter(options, params);
      
              // Suggest the creation of a new value
              if (params.inputValue !== '') {
                filtered.push({
                  inputValue: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                  name: 'Add New Vendor'
                });
              }
      
              return filtered;
            }}

            renderInput={(params) => (
              <TextField {...params} label={props.label} placeholder={props.placeholder} helperText={props.helperText} />
            )}
          />
      </FormControl>
    </div>
  )
}

export default SupplierComponent
