import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import EditTaskDueDate from './EditTaskDueDate'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // // width: 200,
  },
  modalStyle: {
    fullScreen: true,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '90%'
    }
  }
}));

const ViewTaskModal = (props) => {
  // console.log('View Task Modal  Props: ', props)
  // console.log('VIEW TASK::: ', props.task)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [showEditDueDateModal, setShowEditDueDateModal] = React.useState(false)
  const [state, setState] = React.useState({
    note: '',
    assigned_to: {},
    task: props.task,
    reassign: false,
    notes: '',
    reassigned_to: {},
    complete: false
  })

  const inputRef = React.useRef({ })

  // const handleSave = () => {
  //   // console.log(inputRef)
  //   setLoading(true)
  //   // setClient({})
  //   // setState({...state, note})
  //   // console.log('Handle save: ', state)
  //   let data = {
  //     details: inputRef.current['details'],
  //     assigned_to: state.assigned_to,
  //     dueDate: inputRef.current['dueDate']
  //   }
  //   // console.log(data)
  //   axios({
  //     method: 'post',
  //     url:  `${props.url}/api/tasks/createTask`,
  //     data: data
  //   }).then(response => {
  //     setLoading(false)
  //     if (response.data && response.data._id) {
  //       props.notification({
  //         type: 'success',
  //         title: 'Task Saved',
  //         message: 'Task Saved And Assigned!'
  //       })
  //       props.updateParent(response.data)
  //       // props.closeModal()
  //     }
  //   })
  // }
  
  const updateRef = (e) => {
    inputRef.current[e.target.id] = e.target.value
  }

  const updateAssignedTo = (e, newData) => {
    setState({...state, assigned_to: newData})
  }

  const updateReAssignedTo = (e, newData) => {
    // console.log(state)
    setState({...state, reassigned_to: newData})
  }
  // const filteredEmployees = () => {
  //   function hasUser (employee) {
  //     // console.log(employee)
  //     if (employee && employee.user) {
  //       return employee
  //     }
  //   }
  //   // console.log('Props.employees', props.employees)
  //   let filtered = props.employees.filter(hasUser)
  //   // console.log('filtered:', filtered)
  //   return filtered
  // }
  // let t = filteredEmployees()
  // console.log('T:', t)
  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
        let newArr = []
        let string = e.target.value
        props.users.filter(o => {
          let str = o['name'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          // setClient(newArr[0])
          setState({...state, reassigned_to: newArr[0]})
        }
      }
  }

  const handleReAssign = () => {
    // console.log('Reassign')
    setState({...state, reassign: !state.reassign, complete: false })
  }

  const handleReAssignTask = () => {
    // console.log('Save this shit now', state)
    setLoading(true)
    let data = {
      notes: state.notes,
      taskId: props.task._id,
      reassigned_to: state.reassigned_to
    }
    // console.log(data)
    axios({
      method: 'post',
      url:  `${props.url}/api/tasks/reassignTask`,
      data: data
    }).then(response => {
      setLoading(false)
      if (response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Task Reassigned',
          message: 'Task Was Re-Assigned - Way To Delegate!'
        })
        setState({...state, notes: '', complete: false, reassign: false })
        if (response.data.task) props.updateParent(response.data.task, 'complete')
        // props.closeModal()
      }
    })
  }

  const handleCompleteTask = () => {
    // console.log('Complete')
    setLoading(true)
    let data = {
      notes: state.notes,
      taskId: props.task._id
    }
    // console.log(data)
    axios({
      method: 'post',
      url:  `${props.url}/api/tasks/completeTask`,
      data: data
    }).then(response => {
      setLoading(false)
      if (response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Task Completed',
          message: 'Task Was Completed!'
        })
        setState({...state, notes: '', complete: false, reassign: false })
        if (response.data.task) props.updateParent(response.data.task, 'complete')
        // props.closeModal()
      }
    })
  }

  const handleCompleteToggle = () => {
    // let showReassignStuff = false
    // if (state.complete) {
    //   // 
    // } else {
    //   showReassignStuff = 
    // }
    setState({...state, complete: !state.complete, reassign: false })
  }

  const handleEditTaskDate = (e) => {
    e.preventDefault()
  // console.log(props.task)
    setShowEditDueDateModal(true)
  }

  const updateFromEditDueDateModal = (type, data) => {
    setShowEditDueDateModal(false)
    if (type === 'saved') {
      props.updateParent(data, 'saved')
    }
  }

  let dueDate = moment(props.task.dueDate)
  let now = moment()
  let completed = now
  if (props.task.completed_at) completed = moment(props.task.completed_at)
  // console.log('Due Date: ', dueDate)
  // console.log('Completed Date: ', completed)
  let duration = completed.from(dueDate)
  // moment.duration(completed.diff(dueDate))
  // console.log('Duration:', duration)
  // Determine how to show Duration
  // Days Months Hours

  return (

      <Dialog open={props.open} fullWidth={true} className={classes.modalStyle} onClose={props.closeModal}  aria-labelledby="form-dialog-title">
        <EditTaskDueDate open={showEditDueDateModal} updateParent={updateFromEditDueDateModal} task={props.task} />
          <DialogTitle id="form-dialog-title">View Task</DialogTitle>
          <DialogContent >
            <DialogContentText>
            
                {(props.task && props.task.created_by && props.task.created_by.name) ? props.task.created_by.name : 'Someone'} created this task for {(props.task && props.task.assigned_to && props.task.assigned_to.name) ? props.task.assigned_to.name : 'you'}. They would like {(props.task && props.task.assigned_to && props.task.assigned_to.name) ? props.task.assigned_to.name : 'you'} to complete it by <a href="#" onClick={handleEditTaskDate}>{(props.task && props.task.dueDate) ? moment(props.task.dueDate).format('MM/DD/YYYY hh:mm a') : 'yesterday'}</a>.
                
            </DialogContentText>
            <Grid container spacing={2}>
              {/* {JSON.stringify(props.task)} */}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="details"
                  label="Task Details"
                  // style={{ margin: 8 }}
                  value={props.task.details || ''}
                  // onChange={updateRef}
                  // onChange={e=> setState({...state, projectName: e.target.value})}
                  // ref={inputRef['projectName']}
                  placeholder="Task Details"
                  helperText="Task Details (What We Are Hoping You Can Do)"
                  fullWidth
                  disabled
                  multiline
                  rows={7}
                  // value={inputRef.current['projectName']}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Grid>
              {
                props.task.client ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    id="client"
                    label="Associated Client"
                    // style={{ margin: 8 }}
                    value={props.task.client.displayName || ''}
                    // onChange={updateRef}
                    // onChange={e=> setState({...state, projectName: e.target.value})}
                    // ref={inputRef['projectName']}
                    placeholder="Customer Name"
                    // helperText="Task Details (What We Are Hoping You Can Do)"
                    fullWidth
                    disabled
                    // multiline
                    // rows={7}
                    // value={inputRef.current['projectName']}
                    margin="normal"
                    variant="outlined"
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>
                ) : null
              }
                            {
                props.task.project ? (
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <TextField
                    id="client"
                    label="Associated Project"
                    // style={{ margin: 8 }}
                    value={props.task.project.name || ''}
                    // onChange={updateRef}
                    // onChange={e=> setState({...state, projectName: e.target.value})}
                    // ref={inputRef['projectName']}
                    placeholder="Project Name"
                    // helperText="Task Details (What We Are Hoping You Can Do)"
                    fullWidth
                    disabled
                    // multiline
                    // rows={7}
                    // value={inputRef.current['projectName']}
                    margin="normal"
                    variant="outlined"
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                </Grid>
                ) : null
              }
              {
                props.task.completionNotes ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                      id="completion-notes"
                      label="Completion Notes"
                      // style={{ margin: 8 }}
                      value={props.task.completionNotes || ''}
                      // onChange={updateRef}
                      // onChange={e=> setState({...state, projectName: e.target.value})}
                      // ref={inputRef['projectName']}
                      placeholder="Completed"
                      helperText="Notes From User Who Completed Task"
                      fullWidth
                      disabled
                      multiline
                      rows={3}
                      // value={inputRef.current['projectName']}
                      margin="normal"
                      variant="outlined"
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                    />
                  </Grid>
                ) : null
              }
              { props.task.completed_at ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    Completed {duration}
                  </Grid>
                ) : null
              }
              <Grid item lg={6} md={6} sm={12} xs={12}>
         
                      <Button
                      size="medium"
                      color="primary"
                      variant="contained"
                      disabled={state.complete}
                      onClick={handleCompleteToggle}
                      >Complete
                    </Button>

            
                
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12} style={{textAlign: 'right'}}>
  
                  <Button
                      size="medium"
                      color="secondary"
                      disabled={state.reassign}
                      variant="contained"
                      onClick={handleReAssign}
                      >Re-Assign
                    </Button>

              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              {
                state.reassign ? (
                  <Autocomplete
                  id={`reassigned_to`}
                  options={props.users || []}
                  getOptionLabel={(option) => {
                    // console.log('Options: ', option)
                    return option.name || ''
                  }}
                  // style={{ width: 200 }}
                  style={{marginTop: '20px'}}
                  onChange={updateReAssignedTo}
                  // onChange={e=>setState({...state, employee: e.target.value})}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.reassigned_to}
                  // ref={inputRef.status}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Re-Assign Task To" helperText="Who Is The Task Re-Assigned To?" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
                ) : null
              }

            </Grid>
            
              {
                state.reassign ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                    id="notes"
                    label="Task Re-Assignment Notes"
                    // style={{ margin: 8 }}
                    value={state.notes || ''}
                    // onChange={updateRef}
                    onChange={e=> setState({...state, notes: e.target.value})}
                    // ref={inputRef['projectName']}
                    placeholder="Notes About Reassignment"
                    helperText="Please Explain Why We Are Re-Assigning This Task"
                    fullWidth
                    // onChan
                    multiline
                    rows={3}
                    // value={inputRef.current['projectName']}
                    margin="normal"
                    variant="outlined"
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                  <Button
                    size="medium"
                    color="secondary"
                    fullWidth
                    variant="contained"
                    disabled={!state.reassigned_to || !state.notes }
                    onClick={handleReAssignTask}
                    >Re-Assign Task
                  </Button>
                </Grid>
                ) : null
              }
            
            {
                state.complete ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <TextField
                    id="completionnotes"
                    label="Task Completion Notes"
                    // style={{ margin: 8 }}
                    value={state.notes || ''}
                    // onChange={updateRef}
                    onChange={e=> setState({...state, notes: e.target.value})}
                    // ref={inputRef['projectName']}
                    placeholder="Notes About Task"
                    helperText="Please Provide Any Notes You'd Like To Include About Completion of This Task"
                    fullWidth
                    // onChan
                    multiline
                    rows={3}
                    // value={inputRef.current['projectName']}
                    margin="normal"
                    variant="outlined"
                    // InputLabelProps={{
                    //   shrink: true,
                    // }}
                  />
                  <Button
                    size="medium"
                    color="primary"
                    fullWidth
                    variant="contained"
                    // disabled={!state.reassigned_to || !state.notes }
                    onClick={handleCompleteTask}
                    >Complete Task
                  </Button>
                </Grid>
                ) : null
              }
            <Grid item lg={12} md={12} sm={12} xs={12}>
                
            </Grid>
            {/* <Grid item lg={12} md={12} sm={12} xs={12}>
  
              <Autocomplete
                id={`assigned_to`}
                options={props.users || []}
                getOptionLabel={(option) => {
                  // console.log('Options: ', option)
                  return option.name || ''
                }}
                // style={{ width: 200 }}
                // style={{marginBottom: '20px'}}
                onChange={updateAssignedTo}
                // onChange={e=>setState({...state, employee: e.target.value})}
                disabled={loading}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                // value = {state.status}
                // ref={inputRef.status}
                // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} label="Assign Task To" helperText="Who Is The Task Assigned To?" onKeyDown={handleTabInteraction} variant="outlined" />}
              />
            </Grid>

            <DialogContentText>
              Please Add Task Details Below:
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="details"
                  label="Task Details"
                  // style={{ margin: 8 }}
                  // value={inputRef.projectName}
                  onChange={updateRef}
                  // onChange={e=> setState({...state, projectName: e.target.value})}
                  // ref={inputRef['projectName']}
                  placeholder="Task Details"
                  helperText="Please Describe The Task In Detail"
                  fullWidth
                  multiline
                  rows={4}
                  // value={inputRef.current['projectName']}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Grid>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                id="dueDate"
                label="Requested Completion Time"
                type="datetime-local"
                defaultValue={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
                // ref={inputRef.dueDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={updateRef}
                fullWidth
              />
              </Grid>
            </Grid> */}

          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
              Cancel
            </Button> */}
            {/* <Button color="primary" disabled={loading} onClick={handleSave}>
              {
                loading ? <CircularProgress /> : 'Save'
              }
            </Button> */}
          </DialogActions>
        </Dialog>
  
  )
}


  const mapStateToProps = (state) => {
    // console.log('STATE TO PROPS', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      employees: state.employees,
      users: state.users
    }
  }
  
  export default connect(mapStateToProps)(ViewTaskModal)