import React from 'react';
import InputCreditCardDetails from './InputCCDetails';
import AddCustomerEcheck from './InputECheckDetails';
import { makeStyles } from '@material-ui/core/styles';


function PaymentForm({ selectedPaymentMethod, updateParent }) {
  const classes = useStyles()
  if (!selectedPaymentMethod) return null;

  return (
    <div className={classes.paymentFormContainer}>
      {selectedPaymentMethod === 'eCheck' ? (
        <EcheckInputDetails updateParent={updateParent} />
      ) : (
        <CreditCardFormDetails updateParent={updateParent} />
      )}
    </div>
  );
}

const EcheckInputDetails = ({ updateParent }) => {
  const updatePmtMethodResponse = (data) => {
    console.log('Update from eCheck', data)
    updateParent('eCheck', data)
  }
  return (
    <AddCustomerEcheck updateParent={updatePmtMethodResponse} />
  )
}

const CreditCardFormDetails = ({ updateParent }) => {

  const updatePmtMethodResponse = (data) => {
    console.log('Update from credit card', data)
    updateParent('creditCard', data)


  }
  const client = {
    
  }
  const contact = {

  }
  return (
    <InputCreditCardDetails updateParent={updatePmtMethodResponse} />
  )
}


const useStyles = makeStyles((theme) => ({
  paymentFormContainer: {
    padding: 10,
    margin: 5
  },
}))

export default PaymentForm;
