import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
// import SupplyInventoryInputItem from '../Proposals/SupplyInventoryInputItem'
import SupplierComponent from '../Proposals/SupplierComponent'
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import ProjectList from './ProjectList'
import FileUpload from './VendorInvoiceUpload'
import { FileUploader } from "react-drag-drop-files";

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const defaultInputValues = {
  projectName: ''
}

const statusOptions = [
  'New',
  'Pending Meeting',
  'Pending Estimate',
  'Estimate Sent',
  'Pending Revisions',
  'Revisions Sent',
  'Pending Plans',
  'Pending Deposit',
  'Pending HOA Sub.',
  'In Progress',
  'Pending Progress Payment',
  'Pending Final Walk',
  'Pending Final Payment',
  'Pending 3 Month',
  'Complete'
]

const defaultState = {
  status: 'New',
  projectName: '',
  vendor: {},
  invoiceType: '',
  invoiceStatus: 'Due',
  upload: null,
  uploadDetails: {},
  counter: 0
}

const VendorInvoiceModal = (props) => {
  // console.log('VendorInvoiceModal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [client, setClient] = React.useState({})
  const [project, setProject] = React.useState({ name: '' })
  const [projects, setProjects] = React.useState([])
  const [invoiceType, setInvoiceType] = React.useState(null)
  const [state, setState] = React.useState(defaultState)

  const inputRef = React.useRef({  })
  const handleSave = () => {
    // console.log(inputRef)
    // console.log(project)
    // console.log(client)
    // console.log(invoiceType)
    // console.log(state.invoiceType)
    // console.log(state.vendor)
    let data = { ...inputRef.current}
    data.vendor = state.vendor
    data.type = invoiceType
    data.status = state.invoiceStatus
    if (project && project._id) data.project = project._id
    if (client && client._id) data.client = client._id
    // console.log('Save Vendor Invocie: ', data)
    setLoading(true)
    // setClient({})
    // setState({...state, status: 'New', projectName: ''})
    axios({
      method: 'post',
      url:  `${props.url}/api/company/saveVendorInvoice`,
      data: data
    }).then(response => {
      setLoading(false)
      if (response.data && response.data._id) {
        setState(defaultState)
        props.notification({
          type: 'success',
          title: 'Invoice Saved',
          message: 'That Invoice Has Been Saved. Great Job!'
        })
        // props.updateParent(response.data)
        props.closeModal()
      }

    })
  }
  const updateCustomer = async (e, customer) => {
    if (customer && customer._id) {
      setClient(customer)
      inputRef.current.client = customer
      // console.log('Get associated projects', customer)
      const response = await axios.get(`${props.url}/api/projects/listProjects?clientId=${customer._id}`)
      if (response && response.data && response.data.length) {
        setProjects(response.data)
      }
    }
  }

  const updateSalesRep = (e, rep) => {
    if (rep && rep._id) {
      inputRef.current.salesRep = rep
    }
  }
  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
      // console.log(e.target)
      if (e.target.id === 'customername') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          setClient(newArr[0])
        }
      }
    }
  }

  const updateStatus = (e, status) => {
    inputRef.current.status = status
  }
  const updateRef = (e) => {
    inputRef.current[e.target.id] = e.target.value
    setState({...state, counter: state.counter++})
  }

  const handleChosenSupplier = (vendor) => {
    // console.log('Our Vendor: ', vendor)
    setState({...state, vendor: vendor })
  }

  const handleValueChange = (e) => {
    // console.log(e.target.id + ' --- ' + e.target.value)
    if (e.target.id === 'invoiceType') {
      setInvoiceType(e.target.value)
    }
    setState({...state, [e.target.id]: e.target.value })
  }

  const handleInvoiceTypeChange = (e) => {
    setInvoiceType(e.target.value)
    setState({...state, invoiceType: e.target.value })
  }

  const updateProject = (e, project) => {
    if (project && project._id) {
      setProject(project)
      inputRef.current.project = project
    }
  }

  const handleUpdateFiles = (d) => {
  // console.log('Update our files!!', d)
    let url = d[0]['url']
    let id = d[0]['_id']
    // console.log(url)
    inputRef.current.fileURL = url
    inputRef.current.upload = id
    setState({...state, upload: id, uploadDetails: d[0] })
  }

  const handleLoadingFromModal = (d) => {
  // console.log('Handle Loading from modal')
    setLoading(d)
  }

  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "XLSX", "CSV", "TXT"];
  const [file, setFile] = React.useState(null);
  const handleChange = (file) => {
  // console.log('FILES TO UPLOAD: ', file)
    setFile(file);
  };

  return (
    <div >
      <Dialog open={props.open} fullWidth={false} onClose={props.closeModal} maxWidth='sm' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Input Vendor Invoice</DialogTitle>
          <DialogContent >
            <DialogContentText>
              Please Provide Vendor Information
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={12} sm={12}>
                <SupplierComponent label="Vendor Name" vendors={props.vendors} placeholder="Vendor Name" helperText="Who Sent The Invoice" single={true} updateParent={handleChosenSupplier} />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id="invoiceNumber"
                  label="Invoice Number"
                  // style={{ margin: 8 }}
                  // value={inputRef.projectName}
                  onChange={updateRef}
                  // onChange={e=> setState({...state, projectName: e.target.value})}
                  // ref={inputRef['projectName']}
                  placeholder="Invoice Number"
                  helperText="What Is The Invoice Number?"
                  fullWidth
                  // value={inputRef.current['projectName']}
                  margin="normal"
                  // variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id="invoiceTotal"
                  label="Invoice Total"
                  // style={{ margin: 8 }}
                  // value={inputRef.projectName}
                  onChange={updateRef}
                  // onChange={e=> setState({...state, projectName: e.target.value})}
                  // ref={inputRef['projectName']}
                  placeholder="Invoice Total"
                  helperText="What Is The Total Amount Of This Invoice?"
                  fullWidth
                  // value={inputRef.current['projectName']}
                  margin="normal"
                  // variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <TextField
                  id="poNumber"
                  label="PO Number"
                  // style={{ margin: 8 }}
                  // value={inputRef.projectName}
                  onChange={updateRef}
                  // onChange={e=> setState({...state, projectName: e.target.value})}
                  // ref={inputRef['projectName']}
                  placeholder="PO Number"
                  helperText="What Is The PO Number?"
                  fullWidth
                  // value={inputRef.current['projectName']}
                  // margin="normal"
                  // variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormControl fullWidth >
                  <InputLabel >Invoice Status</InputLabel>
                  <Select
                    id="invoiceStatus"
                    value={state.invoiceStatus}
                    onChange={e => setState({...state, invoiceStatus: e.target.value })}
                    
                    // onChange={(e) => setState({...state, irrigationCheckSchedule: e.target.value })}
                  >
                    <MenuItem value={'Due'}>Due</MenuItem>
                    <MenuItem value={'Paid'}>Paid</MenuItem>
                  </Select>
                  <FormHelperText>Has The Invoice Been Paid?</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>

                <FormControl fullWidth >
                  <InputLabel >Type of Invoice</InputLabel>
                  <Select
                    id="invoiceType"
                    value={state.invoiceType}
                    onChange={handleInvoiceTypeChange}
                    // onChange={(e) => setState({...state, irrigationCheckSchedule: e.target.value })}
                  >
                    <MenuItem value={'Client Project/Job'}>Client Project/Job</MenuItem>
                    <MenuItem value={'Yard Stock'}>Yard Stock</MenuItem>
                    <MenuItem value={'Tools'}>Tools</MenuItem>
                    <MenuItem value={'Inventory'}>Inventory</MenuItem>
                    <MenuItem value={'Marketing'}>Marketing</MenuItem>
                    <MenuItem value={'Repair'}>Repair</MenuItem>
                    <MenuItem value={'Office'}>Repair</MenuItem>
                    <MenuItem value={'Other'}>Other</MenuItem>
                  </Select>
                  <FormHelperText>What Kind of Invoice Is This?</FormHelperText>
                </FormControl>
              </Grid>
              {/* Invoice Type: {invoiceType}<br />
              State InvoiceType: {state.invoiceType} */}
              {
                (invoiceType && invoiceType === 'Client Project/Job') ? (
                  <React.Fragment>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Autocomplete
                        id={`customername`}
                        options={props.clients || []}
                        getOptionLabel={(option) => {
                          // console.log('Options: ', option)
                          return option.displayName || ''
                        }}
                        fullWidth
                        // style={{ width: 200 }}
                        // style={{marginBottom: '20px'}}
                        onChange={updateCustomer}
                        disabled={loading}
                        // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                        value = {client}
                        // ref={inputRef.client}
                        // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                        renderInput={(params) => <TextField {...params} label="Customer" helperText="Who Is The Client?" onKeyDown={handleTabInteraction} />}
                      />
                    </Grid>
                    {
                      (client && projects && projects.length) ? (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Autocomplete
                          id={`projectname`}
                          options={projects || []}
                          getOptionLabel={(option) => {
                            // console.log('Options: ', option)
                            return `${option.name}` || ''
                          }}
                          fullWidth
                          // style={{ width: 200 }}
                          // style={{marginBottom: '20px'}}
                          onChange={updateProject}
                          disabled={loading}
                          // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                          value = {project}
                          // ref={inputRef.client}
                          // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                          renderInput={(params) => <TextField {...params} label="Project" helperText="Which Project?" onKeyDown={handleTabInteraction} />}
                        />
                      </Grid>
                      ) : null
                    }
                    
                </React.Fragment>
                ) : null
              }
              
             
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="description"
                  label="Invoice Description"
                  // style={{ margin: 8 }}
                  // ref={inputRef.projectDescription}
                  placeholder="Invoice Description"
                  helperText="What Is This Invoice Covering?"
                  fullWidth
                  multiline
                  rows={3}
                  onChange={updateRef}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FileUploader handleChange={handleChange} name="file" types={fileTypes} label="What the fuck" multiple/>
              </Grid>
              
              <Grid item lg={12} md={12} sm={12} xs={12}>
                  {
                    (state.uploadDetails && state.uploadDetails._id) ? null : (
                      <FileUpload project={project} updateFiles={handleUpdateFiles} setParentLoading={handleLoadingFromModal} />
                    )
                  } 
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                {
                  state.uploadDetails._id ?
                    (
                      `${state.uploadDetails.name } ---  ${state.uploadDetails.size}`
                    ) : null
                }
              </Grid>
              
              {/* {
                (project && project._id) ? (
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                      <FileUpload project={project} updateFiles={handleUpdateFiles} />
                  </Grid>
                ) : null 
              }  */}
              
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
              Cancel
            </Button> */}
            <Button color="primary" disabled={loading || !state.invoiceType || !state.vendor._id || !inputRef.current['invoiceNumber'] || !inputRef.current['invoiceTotal'] } onClick={handleSave}>
              {
                loading ? <CircularProgress /> : 'Save'
              }
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  )
}


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(VendorInvoiceModal)