import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  input: {
    width: '50px',
    textAlign: 'center',
  },
  countControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap'
  },
}));

const yardOptions = [
  'New River',
  'Cave Creek'
]

const ItemList = ({ items, itemCounts, setItemCounts, state, setState }) => {
  // console.log("Item List", items)
  const classes = useStyles();

  

  const handleInputChange = (id, value) => {
    setItemCounts((prevCounts) =>
      prevCounts.map((item) =>
        item._id === id ? { ...item, received: value } : item
      )
    );
  };

  const incrementCount = (id) => {
    setItemCounts((prevCounts) =>
      prevCounts.map((item) =>
        item._id === id ? { ...item, received: item.received + 1 } : item
      )
    );
  };

  const decrementCount = (id) => {
    setItemCounts((prevCounts) =>
      prevCounts.map((item) =>
        item._id === id && item.received > 0
          ? { ...item, received: item.received - 1 }
          : item
      )
    );
  };

  const handleSelect = (e, itemId) => {
    console.log('SELECT', e.target.value)
    console.log('Item::', itemId)
    const selectedYard = e.target.value;
    let newItemCounts = [...itemCounts].map((item) => {
      console.log('Prevcounts item', item)
      console.log('Comparing: ', item._id, 'with: ', itemId)
      if (String(item._id) === String(itemId)) {
        console.log('This one matches....');
        return { ...item, yard: selectedYard };
      } else {
        return item;
      }
    })
    console.log('New Item Counts', newItemCounts)
    setItemCounts(newItemCounts)
    // setState({...state, counter: state.counter++ })
    // (prevCounts) =>
    // prevCounts.map((item) => {
    //   console.log('Prevcounts item', item)
    //   console.log('Comparing: ', item._id, 'with: ', itemId)
    //   if (String(item._id) === String(itemId)) {
    //     console.log('This one matches....');
    //     return { ...item, yard: selectedYard };
    //   } else {
    //     return item;
    //   }
    // }));
  }

  // let cleanItems = []
  // items.map(item => {
  //   let opts = item.options

  // })
  // console.log('Item counts', itemCounts)

  const handleFocus = e => e.target.select()
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name (Alt Name)</TableCell>
            <TableCell>Option</TableCell>
            <TableCell >Quantity Ordered</TableCell>
            <TableCell >Quantity Previously Received</TableCell>
            <TableCell align="right">Quantity Received</TableCell>
            <TableCell align="right">Yard</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => {
            console.log('Item::98', item)
            let receivedQty = 0
            let itemCt = itemCounts.find((countItem) => countItem._id.toString() === item._id.toString())
            console.log('ItemCT:', itemCt)
            if (itemCt && itemCt.received) receivedQty = itemCt.received
            let isAccountedFor = false
            if (itemCt?.previousReceipt === itemCt?.count) isAccountedFor = true
            return (
            <TableRow key={item._id}>
              <TableCell component="th" scope="row">
                {item?.takeOffItem?.name} {item?.takeOffItem?.altName ? `(${item?.takeOffItem?.altName})` : ''}
              </TableCell>
              <TableCell align="center">{item?.optionItem?.name}</TableCell>
              <TableCell align="center">{item.count}</TableCell>
              <TableCell align="center">{itemCt?.previousReceipt || 0}</TableCell>
              <TableCell align="center">
                
                  {
                    isAccountedFor ? receivedQty : (
                      <div className={classes.countControls}>
                      <IconButton
                        onClick={() => decrementCount(item._id)}
                        aria-label="decrease"
                      >
                        <RemoveIcon />
                      </IconButton>
                      <TextField
                        className={classes.input}
                        type="number"
                        onFocus={handleFocus}
                        value={receivedQty}
                        onChange={(e) =>
                          handleInputChange(item._id, parseInt(e.target.value, 10))
                        }
                        inputProps={{ min: 0 }}
                      />
                      <IconButton
                        onClick={() => incrementCount(item._id)}
                        aria-label="increase"
                      >
                        <AddIcon />
                      </IconButton>
                      </div>
                    )
                  }

            
              </TableCell>
              <TableCell align="center">
                <TextField
                  id="yard"
                  label="Yard"
                  select
                  style={{marginBottom: '10px'}}
                  variant="outlined"
                  fullWidth
                  value={itemCt?.yard || ''}
                  onChange={e => handleSelect(e, item._id)}
                  // disabled={!selectedOption}
                  helperText="Where is this going? New River or Cave Creek?"
                >
                  {
                    [...yardOptions].map((yard, index) => {
                      return (
                        <MenuItem key={index} value={yard}>{yard}</MenuItem>
                      )
                    })
                  }
                </TextField>
              </TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

// ItemList.propTypes = {
//   items: PropTypes.arrayOf(
//     PropTypes.shape({
//       _id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//       name: PropTypes.string.isRequired,
//       option: PropTypes.string.isRequired,
//       count: PropTypes.number.isRequired,
//       received: PropTypes.number.isRequired,
//     })
//   ).isRequired,
// };

export default ItemList;
