import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  imageList: {
    width: '100%',
    height: '100%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',   // this ensures the image maintains its aspect ratio
    position: 'relative',
  },
}));

export default function TitlebarImageList({ photos }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ImageList rowHeight={300} className={classes.imageList}>
        <ImageListItem key="Subheader" cols={2} style={{ height: 'auto' }}>
          <ListSubheader component="div">Photos</ListSubheader>
        </ImageListItem>
        {photos.map((item) => {
          let theURL = item.url
          if (!theURL.includes('https')) theURL = `https://${item.url}`
          return (
          <ImageListItem key={item.url}>
            <img src={theURL} alt={item.title} />
            <ImageListItemBar
              title={item.title}
              subtitle={<span>{item.author}<br />{moment(item.created_at).format('MM/DD/YY hh:mma')} </span>}
              // actionIcon={
              //   <IconButton aria-label={`info about ${item.title}`} className={classes.icon}>
              //     <InfoIcon />
              //   </IconButton>
              // }
            />
          </ImageListItem>
        )})}
      </ImageList>
    </div>
  );
}
