import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux'
import axios from 'axios'
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, IconButton, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import EditEstimateDetails from '../EditEstimateDetails';
import { socket } from '../../SocketIo/Socket'
// import LastUpdateByDetails from '../../../Common/LastUpdatedByDetails'
import PaperStyleLineItemComponent from './PaperStyleLineItemComponent'
import { debounce } from 'lodash';
import FloatingActionMenu from './PaperStyleEstimateFAB'
// import Icon1 from '@material-ui/icons/Star'; // Example icon for Option 1
import PreviewIcon from '@material-ui/icons/Visibility'; // Example icon for Option 2
import EmailIcon from '@material-ui/icons/Email';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SaveIcon from '@material-ui/icons/Save';
import PDFPreviewModal from '../../Common/PDFViewerModal'

const handleSaveEstimateToBackend = async (estimate, props) => {
  const resp = await axios.post(`${props.url}/api/proposal/updateEstimate?paperStyle=1`, {
    estimateId: estimate._id,
    estimate: estimate,
    services: estimate.serviceList
  })
  console.log('Update Estimate response', resp)
}

const PaperStyleProposalModule = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [estimate, setEstimate] = React.useState({})
  const [takeoffs, setTakeoffs] = React.useState([])
  const [sizes, setSizes] = React.useState([])
  const [scopes, setScopes] = React.useState([])
  const [categories, setCategories] = React.useState([])
  const [jobSites, setJobSites] = React.useState([])
  const [client, setClient] = React.useState({})
  const [addys, setAddys] = React.useState([])
  const [showConfigurationModal, setShowConfigurationModal] = React.useState(false)
  const [showEditEstimateModal, setShowEditEstimateModal] = React.useState(false)
  const [objectives, setObjectives] = React.useState([])

  const [state, setState] = React.useState({
    counter: 0,
    estimate: {},
    service: {},
    categories: [],
    client: {}
  })
  const [pdf, setPDF] = React.useState(null)
  const [showPDFPreview, setShowPDFPreview] = React.useState(false)

  React.useEffect(() => {
    // console.log('Socket.connect')
    socket.on(`estimateUpdate`, (data) => {
      // console.log('Estimate Update Socket shit::::', data)
      // console.log('We got an estimate???', props)
      dynamicEstimateUpdate(data, state, props)
    })

    return () => {
      // console.log('Socket DIsconnect estimateUpdate')
      socket.off('estimateUpdate')
    }
  }, [props, state])

  const dynamicEstimateUpdate = (updatedEstimate, lastState, lastProps) => {
      console.log('Updating estimates from socket data!!! PAPER STYLE PROPOSAL', updatedEstimate)
      console.log('Last State:', lastState)
      console.log('Last Props', lastProps)
    // console.log('Estimate today:', props)
      console.log('StATE:', state)
      console.log('PROPS:', props)
      console.log('Current estimate:', estimate)
      if (updatedEstimate && updatedEstimate._id === estimate._id) {
        console.log('This is OUR Estimate!!!', updatedEstimate)
        let doIt = true
        if (updatedEstimate && updatedEstimate.updated_by && updatedEstimate.updated_by._id === props.user._id && doIt){
          console.log('Ignore it - we did it...')
        } 
        // else {
          let mewEst = {...estimate, ...updatedEstimate}
          console.log('Merged Estimate:', mewEst)
          setEstimate(mewEst)
          let newService = {...state.service }
          console.log('New Service', newService)
          if (newService && newService.type) {
            let theIndex = -1
            mewEst.serviceList.map((svListItem, index) => {
              console.log('Svlistitem', svListItem)
              if (svListItem && svListItem.type === newService.type) {
                theIndex = index
              }
            })
            console.log('The Index', theIndex)
            if (theIndex > -1) {
              let svcItem = mewEst.serviceList[theIndex]
              console.log('This is our service item',  svcItem)            
              mewEst.serviceList[theIndex]['services'].map((item, index) => {
                console.log('Services index item...', item)
                if (item._id === state.service.service._id) {
                  console.log('This one!!!!!', item)
                  newService.service = item
                }
              })
            }
            console.log('Set state.service ****000****', newService)


            setState({...state, estimate: mewEst, service: newService })
          } else {
            console.log('No active service???')
            setState({...state, estimate: mewEst })
          }
  

      } else {
      // console.log('No match', props.estimate)
      }
    }
    

    const fetchObjectives = () => {
      let type = 'objective'
      axios.get(`${props.url}/api/settings/getGenericOptions?type=${type}`).then((response, status, data) => {
        console.log('Got the eobjectives!!', response.data)
        setLoading(false)
        if (response && response.data) {
          setObjectives(response.data)
        }
      })
    }

  React.useEffect(() => {
  // console.log('Proposal props updated...', props)

    let isMounted = true
    const fetchEstimate = async (id) => {
      // console.log('Fetch: ', id)
      fetchObjectives()
      setLoading(true)
      let url = `${props.url}/api/proposal/getProposal?id=${id}&newTakeoffs=true`
      axios.get(url).then(async (response, status, data) => {
      console.log('Estimate found::', response.data)
        setLoading(false)
        if (response && response.data && response.data.estimate) {
          if (isMounted) {
            if (response.data.estimate && response.data.estimate.lineItems && response.data.estimate.lineItems.length) {
              // if (window.confirm('Would You Like to Update this to Work The New Way?')) {
                console.log('Update this bitch', response.data.estimate)
              // }
            }
            let estimateToSave = null
            if (response.data && response.data.estimate._id) {
              const estDetails = await calculateEstimateDetails(response.data.estimate.serviceList || [])
              console.log('Estimate Details:', estDetails)
              estimateToSave = {
                ...response.data.estimate,
                // ...estDetails
              }
            }
            if (response.data.estimate && response.data.estimate.lineItems && response.data.estimate.lineItems.length) {
              response.data.estimate = estimateToSave
              checkOurEstimateForExistingLineItems(response.data)
            } else {
              setEstimate(estimateToSave)
              console.log('EXISTING STATE ****000****', state)
              setState({...state, estimate: estimateToSave, client: response.data.client, vendors: response.data.vendors })
            }
       
          }
        }
        if (response && response.data && response.data.takeoffs) {
          if (isMounted) setTakeoffs(response.data.takeoffs)
        }
        if (response && response.data && response.data.client) {
          if (isMounted) {
          // console.log(response.data)
            setClient(response.data.client)
            let addresses = []
            let theClient = response.data.client
            setJobSites(response.data.jobSites)
          // console.log('got jobsites', response.data.jobSites)
            for (const addy of response.data.jobSites) {
              addresses.push({
                name: addy.name,
                address: addy.address,
                address2: addy.address2,
                city: addy.city,
                state: addy.state,
                zip: addy.zip
              })
            }
          // console.log('Addys so far ', addresses)
            addresses.unshift({
              name: 'Client Billing Address',
              address: theClient.address,
              address2: theClient.address2,
              city: theClient.city,
              state: theClient.state,
              zip: theClient.zip
            })
          // console.log('addys', addresses)
            setAddys(addresses)
          }
        }
      })
    }

    if (props.match && props.match.params && props.match.params.estimateId && (!estimate._id || estimate._id.toString() !== props.match.params.estimateId)) {
      // console.log('Get it!!', props.match.params.estimateId)
      fetchEstimate(props.match.params.estimateId)
    }

    return () => {
      isMounted = false
    }
  }, [props])

    React.useEffect(() => {
      let isMounted = true

      fetchCategories()
      fetchSizes()
    return () => {
      isMounted = false
    }
  }, [])

  // React.useEffect(() => {
  //   // Trigger the debounced update function whenever proposalData changes
  //   updateBackend(estimate, 'interval');
  //   // Make sure to cancel the debounced invocation on component unmount
  //   return  () => {
  //     handleSaveEstimateToBackend(estimate, props)
  //     updateBackend.cancel()
  //   }
  // }, [estimate, updateBackend, props])

  // const updateBackend = useCallback(debounce((newData, type) => {
  //   console.log('Updating backend %S with:', type, newData);
  //   handleSaveEstimateToBackend(newData, props)
  //   // Your API call to update the backend goes here
  // }, 7500), [])

  const fetchSizes = async () => {
    // console.log('Get our sizes and shit')
      axios
      .get(
        `${props.url}/api/settings/getStuffForNewItem`
        )
      .then(({ data }) => {
        let newState = {}
        if (data && data.sizes) {
          setSizes(data.sizes)
        }
        if (data && data.scopes) {
          setScopes(data.scopes)
          setCategories(data.scopes)
          newState.categories = data.scopes
        }
        
        setState({...state, ...newState})
      }).catch(function (err) {
      // console.log('Error getting stuff', err)
      });
    }

  const fetchCategories = async (callback) => {
    setLoading(true)
    axios.get(`${props.url}/api/settings/listScopes`).then((response, status, data) => {
      setLoading(false)
      if (response && response.data) {
     
          console.log('Set Categories: ')
          console.log('Existing state ****000****', state)
          setState({...state, categories: response.data})
          setCategories(response.data)
        if (callback) {
          return callback(response.data)
        }
      }
    })
  }

const fetchOurScopes = () => {
  return new Promise((resolve) => fetchCategories((resp) => {
    resolve(resp)
  }))
}

  const checkOurEstimateForExistingLineItems = async (data) => {
    const est = data.estimate
    const client = data.client
    console.log('check for old line items ', est)
    if (est && est.serviceList && est.serviceList.length) {
      console.log('Looks like its been touched before', est.serviceList)
    } else {
      console.log('VIRGIN ESTIMATE IN HAND', est.serviceList)
      console.log('props', props)
      console.log('state', state)
      let ourScopes = []
      if (categories && categories.length) {
        console.log('No need t ofetch them')
        ourScopes = categories
      } else {
        console.log('Ftech the categories')
        ourScopes = await fetchOurScopes()
      }
      console.log('We got scopes!!', ourScopes)
      // Need to assign it a SCOPE - PLANT MATERIAL
      // Misc Work as needed - 5f6ed72068560223b41ebe62
      let svcIndex = props.services.findIndex(item => (item._id === '5f6ed72068560223b41ebe62'))
      if (svcIndex > -1) {
        let assignedSvc = props.services[svcIndex]
        assignedSvc.lineItems = est.lineItems
        console.log('Assigned Service - have scope???', assignedSvc)
        let serviceList = {
          type: 'Plant Material',
          services: [assignedSvc],
          scopeId: '62cf4c2afcf82e4a90f05c77'
        }
        console.log('svc list:', serviceList)
        est.serviceList = [serviceList]
        console.log('Categores??', ourScopes)
        let cats = [...ourScopes]
        let catId = '62cf4c2afcf82e4a90f05c77'
        console.log('find our socpe', cats)
        let catIndex = cats.findIndex(item => (item._id.toString() === catId))
        console.log('Cat index', catIndex)
        if (catIndex > -1) {
          est.scopes = [cats[catIndex]]
        }
        console.log('Estimate w/ serices now!!', est)
      }

    }
    // conso
    setEstimate(est)
    console.log('Existing state ****000****', state)
    setState({...state, estimate: est, client: client, vendors: data.vendors })
  }

  const handleUpdateFromLineItems = async (type, updatedData) => {
    console.log('Save the updated data on this estimate...', type)
    console.log(updatedData)
    if (type === 'saveServices') {
      const newServices = updatedData?.services || []
      const scopeIndex = updatedData.scopeIndex
      let scopes = [...estimate.serviceList]
      let newScope = { ...scopes[scopeIndex] }
      newScope.services = newServices
      scopes[scopeIndex] = newScope
      const estDetails = await calculateEstimateDetails(scopes)
      let newEstimate = {
        ...estimate,
        serviceList: scopes,
        ...estDetails
      }
      setEstimate(newEstimate)
      handleSaveEstimateToBackend(newEstimate, props)
    } if (type === 'services') {
      const newServices = updatedData?.services || []
      const scopeIndex = updatedData.scopeIndex

      // console.log('Replace the services on this estimate...', estimate)
      let scopes = [...estimate.serviceList]
      let newScope = { ...scopes[scopeIndex] }
      newScope.services = newServices
      scopes[scopeIndex] = newScope
      // console.log('New Scopes (estimate.serviceList', scopes)
      // console.log('Estimate now:', estimate)
      const estDetails = await calculateEstimateDetails(scopes)
      // console.log('Est Details', estDetails)
      let newEstimate = {
        ...estimate,
        serviceList: scopes,
        ...estDetails
      }
      // console.log('Save this', newEstimate)
      setEstimate(newEstimate)
      // updateBackEndIfNecesarry(new Date())
      // handleSaveEstimateToBackend(newEstimate, scopes, props)
      // scopes.forEach(scope => {
      //   if (scope && scope.services && scope.services.length) {
      //     scope.services.forEach(svc => {
      //       if (svc._id === )
      //     })
      //   }
      // })
    } else {
      console.log('Unknown type...', type)
    }
  }

  const calculateEstimateDetails = (scopes) => {
    let totalItems = 0
    let totalPrice = 0
    // console.log('Calculate Estimate Details', scopes)
    let isTaxable = false
    let taxableAmount = 0
    scopes.forEach(scope => {
      if (scope.services && scope.services.length) {
        scope.services.forEach(svc => {
          // Add Labor Details
          if (svc.laborDetails) {

          }
          if (svc && svc.lineItems && svc.lineItems.length) {
            svc.lineItems.forEach(lineItem => {
              console.log('INCLUDE::', lineItem)
              totalItems += Number(lineItem.qty || 0)
              const lineItemTotal = Number(lineItem.qty || 0) * Number(lineItem.pricePer)
              totalPrice += lineItemTotal
              console.log('TOTAL PRICE', totalPrice)
              if (lineItem && lineItem.inventoryItemId && lineItem.pricePer) {
                isTaxable = true
                taxableAmount += lineItemTotal
              }
            })
          }
        })
      }
    })
    let objToReturn = {
      totalItems: totalItems,
      taxable: isTaxable,
      taxableAmount: taxableAmount,
      totalPrice: parseFloat(totalPrice).toFixed(2)
    }
    return objToReturn
  }

  // const showInternalDetails = false
  const handlePreviewPDFNow = async (showInternalDetails) => {
    let estimateId = estimate._id
    const obj = {
      estimate: estimate,
      estimateId: estimateId,
      prettyPDF: false,
      internal: 0
    }
    // console.log('obj to send', obj)
    let internalStyle = 0

    let url = `${props.url}/api/proposal/handleGeneratePDF?estimateId=${estimateId}&internal=${internalStyle}&tos=true`
    let method = 'get'
    if (state.prettyVersion) {
      method = 'post'
      url = `${props.url}/api/proposal/createPDFNewStyleProposal`
    }
    if (showInternalDetails && showInternalDetails > 0) {
      internalStyle = 1
      url = `${props.url}/api/proposal/handleGeneratePDF?estimateId=${estimateId}&internal=${internalStyle}&tos=1`
      method = 'get'
    }
    // console.log('url:', url)
    axios({
      method: method,
      url:  url,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    console.log('477 PDF::', response.data)
      setPDF(response.data)
      setShowPDFPreview(true)
    })
  }

  const handleChooseFABAction = (optChosen) => {
    console.log('FAB Menu selection', optChosen)
    if (optChosen === 'save') {
      handleSaveEstimateToBackend(estimate, props)
    }
    if (optChosen === 'previewPDF') {
      handlePreviewPDFNow()
    }
    if (optChosen === 'editEstimate') {
      setShowEditEstimateModal(true)
    }
  }

  const handleChildRequestToSave = async (newEst) => {
    console.log('Backend Request to save (onBlur)...', newEst)
    if (newEst) {
      handleSaveEstimateToBackend(newEst, props)
    } else {
      handleSaveEstimateToBackend(estimate, props)
    }
    
   
  }

  const closePDFPreviewModal = () => {
    setShowPDFPreview(false)
  }

  const closeEditEstimateModal = () => {
    setShowEditEstimateModal(false)
  }

  const updateEstimateDetails = (d) => {
    // console.log('Update estimate details:', d)
    if (d && d._id) {
      let est = estimate
      est.name = d.name
      est.accepted = d.accepted
      est.taxable = d.taxable
      est.taxLocale = d.taxLocale
      est.taxAmount = d.taxAmount
      est.jobSite = d.jobSite
      // Sales Rep is the object
      let salesRep = null
      if (d && d.salesRep && d.salesRep._id) {
        est.salesRep = d.salesRep._id
        salesRep = d.salesRep
      }
      // console.log('Estimate w/ Sales REp:', est)
      if (d.depositData) {
        est.depositData = d.depositData
        // console.log('D.DepositData: ', d.depositData)
      }
      if (d.depositAmount) est.depositAmount = d.depositAmount
      if (d.depositPercent) est.depositPercent = d.depositPercent
      if (d.associatedProject && d.associatedProject._id) est.project = d.associatedProject._id
      if (d && d.billingAddress) est.billingAddressObject = d.billingAddress
      if (d.chosenLocales) {
        // console.log('Got LOcales', d.chosenLocales)
        est.chosenLocales = d.chosenLocales
      }
      if (d.waiveDeposit) {
        est.waiveDeposit = true
      } else {
        est.waiveDeposit = false
      }
      est.summaryPricing = d.summaryPricing
      // console.log('ETIMATE UPDATED: ', est)
      // setEstimate(est)
      setShowEditEstimateModal(false)
      handleSaveEstimateToBackend(est, props)
    }
      // updateEstimate(est, 781)
      // setEstimate(est)
      // setState({...state, estimate: est, counter: state.counter++})
    }

    const saveClonedEstimateLineItems = (d) => {
      console.log('Saved cloned EStimate', d)
    }

    const handleInternalPDF = () => {
      handlePreviewPDFNow(1)
    }
    // const showInternalStyleButton = () => {
    //   return (
    //     <Button variant="outlined" onClick={handleInternalPDF} color="secondary" >Show Internal Style</Button>
    //   )
    // }
  let entity = {
    name: '',
    _id: '',
    rocNumber: '',
    billingAddress: '',
    billingCity: '',
    billingState: '',
    billingZip: ''
  }
  let foundEntity = [...props.entities].find(item => (item._id === estimate.entity))
  // console.log('Found Entity', foundEntity)
  if (foundEntity && foundEntity._id) entity = foundEntity
  // console.log('....')
  let salesRepName = ''
  let salesRepFound = [...props.salesReps].find(item => (item._id === estimate.salesRep))
  if (salesRepFound && salesRepFound._id) salesRepName = salesRepFound.displayName

  // let client = {
  //   displayName: '',
  //   address: '',
  //   city: '',
  //   state: '',
  //   zip: ''
  // }
  // if (props.clients && props.clients.length) {
  //   let foundClient = [...props.clients].find(item => (item._id === estimate.client))
  //   if (foundClient && foundClient._id) client = foundClient
  // }
  let jobSite = {
    address: '',
    city: '',
    state: '',
    zip: ''
  }
  if (estimate && estimate.jobSite) {
    // console.log('Addys', jobSites)
    // jobSite = jobSites.find(item => (item._id === estimate.jobSite))
    let index = jobSites.findIndex(item => (item._id === estimate.jobSite))
    if (index > -1) jobSite = jobSites[index]
  }
  // console.log('Jobsite for htis', jobSite)
  console.log('PDF:: 613', pdf)
  return (
    <div className={classes.root}>
      <PDFPreviewModal open={showPDFPreview} pdf={pdf} handleClose={closePDFPreviewModal} extraButton={ <Button variant="outlined" onClick={handleInternalPDF} color="secondary" >Show Internal Style</Button> } />
      <EditEstimateDetails open={showEditEstimateModal} jobSites={jobSites} addys={jobSites} estimate={estimate} closeModal={closeEditEstimateModal} updateEstimateDetails={updateEstimateDetails} saveClonedEstimateLineItems={saveClonedEstimateLineItems} />
      
      <Paper className={classes.paper}>
       { loading ? <CircularProgress size={88} /> : (
        <div className={classes.estimateOutline}>
          <div className={classes.estimateHeader}>
            <div className={classes.logoArea}>
              <div> <img alt="Logo" src={`${process.env.PUBLIC_URL}/${estimate.entity}_Logo.png`} style={{margin: '5px', maxWidth: 300, maxHeight: 150}} /></div>
              <div>{entity.billingAddress} </div>
              <div>{entity.billingCity}, {entity.billingState} {entity.billingZip}</div>
              <div>Phone {entity.telephone} Fax 480-488-6230</div>
            </div>
            <div className={classes.rocInfoArea}>ROC #{entity.rocNumber}</div>
            <div className={classes.estimateDetailsArea}>
              <div style={{ fontSize: '1.3em', fontWeight: 600, textAlign: 'right' }}>ESTIMATE</div>
              <div style={{  textAlign: 'right' }}>Estimate #{estimate.number}</div>
              <div style={{  textAlign: 'right' }}>{estimate.name}</div>
              <div style={{  textAlign: 'right' }}>CREATED: {new Date(estimate.created_at).toLocaleDateString({ year: "numberic", month: "short", day: "numberic" })}</div>
              <div style={{  textAlign: 'right' }}>DESIGNER: {salesRepName}</div>
              
            </div>
          </div>
          <div className={classes.customerDetailsContainer}>
           
            <div><CustomerInformationArea entity={entity} estimate={estimate} client={client} jobSite={jobSite} /></div>
          </div>
          <div>
            <PaperStyleLineItemComponent objectives={objectives} estimate={estimate} setEstimate={setEstimate} sizes={sizes} vendors={state.vendors} categories={categories} scopes={scopes} takeoffs={takeoffs} updateParent={handleUpdateFromLineItems} saveToBackEnd={handleChildRequestToSave} />
          </div>
        </div>
       )
      }
        <ActionButton chooseAction={handleChooseFABAction} />
      </Paper>
    </div>
  )
}

const CustomerInformationArea = ({ estimate, entity, client, jobSite }) => {
  const classes = useStyles()
  console.log('Cusomter Info Called 339', estimate)
  console.log('client', client)
  console.log('Jobsite', jobSite)
  let clientName = ''
  if (client && client._id && client._id.length) {
    clientName = client.searchName ? client.searchName : client.displayName
  }
  return (
    <div>
      <div>{clientName}</div>
      <div>{estimate.name}</div>
      <div>{jobSite.address || ''}</div>
      <div>{jobSite.city || ''}, {jobSite.state || ''} {jobSite.zip || ''}</div>
    </div>
  )
}

const ActionButton = ({ chooseAction }) => {
  const classes = useStyles()
  const handleClick = (optClicked) => {
    chooseAction(optClicked)
  }

  const menuOptions = [
    { label: 'Save Estimate', onClick: () => handleClick('save'), icon: <SaveIcon /> },
    { label: 'Preview PDF', onClick: () => handleClick('previewPDF'), icon: <PreviewIcon /> },
    { label: 'Edit Estimate Details', onClick: () => handleClick('editEstimate'), icon: <EditIcon /> },
    { label: 'Send To Customer', onClick: () => handleClick('sendToCustomer'), icon: <EmailIcon /> },
    { label: 'Convert to Job', onClick: () => handleClick('convertToJob'), icon: <PostAddIcon /> },
    // Add more options as needed
  ];

  return (
    <FloatingActionMenu options={menuOptions} isFixed={true} style={{ position: 'fixed', bottom: 40, right: 40 }} />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '95%',
    margin: 'auto'
  },
  paper: {
    margin: 10,
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'center',
    height: '85vh',
    overflowY: 'scroll'
  },
  estimateOutline: {
    margin: 20,
    border: '2px solid #333',
    borderRadius: 2,
    width: '90%',
    height: '100%',
    minHeight: '960px',
    overflowY: 'scroll'
  },
  estimateHeader: {
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    padding: 10,
    margin: 10
  },
  customerDetailsContainer: {
    margin: 15,
    padding: 10,
    border: '1px solid #333',
    maxWidth: '500px',
    maxHeight: '300px',
    minHeight: '150px'
  },
  estimateDetailsArea: {
  },
  actionButtonContainer: {
    position: 'absolute',
    right: 40,
    bottom: 100,
    borderRadius: 50,
    backgroundColor: 'orange',
    padding: 10,
    margin: 5
  },
  floatingActionButton: {
    borderRadius: 50
  }
}));

const mapStateToProps = (state) => {
  // console.log('Map to Props ESTIMATE PROCESSS: ', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms,
    clients: state.clients,
    salesReps: state.salesReps,
    services: state.services,
    entities: state.entities
  }
}

export default connect(mapStateToProps)(PaperStyleProposalModule)