import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, TextField, InputAdornment, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl, FormLabel } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
// import UploadDetailsModal from './UploadDetailsModal'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import ProtectedButton from '../../actions/protectedButtons';
import { desc, stableSort, getSorting } from '../../services/common'
import PostAddIcon from '@material-ui/icons/PostAdd';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';
import { TrainRounded } from '@material-ui/icons';
import DeleteComponent from '../Common/DeleteComponent'
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EditPOModal from './EditPurchaseOrderModal'

const headCells = [
  { id: 'number', numeric: false, disablePadding: false, label: 'PO Number' },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
  { id: 'clientName', numeric: false, disablePadding: false, label: 'Client Name' },
  { id: 'projectName', numeric: false, disablePadding: false, label: 'Project Name' },

  // { id: 'assigned_to', numeric: false, disablePadding: false, label: 'Assigned To' },
  // { id: 'created_by', numeric: false, disablePadding: false, label: 'Assigned By' },
  // { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'description', numeric: false, disablePadding: false, label: 'Notes' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status', isDate: true },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Created', isDate: true },
  // { id: 'completed_at', numeric: false, disablePadding: false, label: 'Completed' },
  // { id: 'ontime', numeric: false, disablePadding: false, label: 'On-Time' },
  { id: 'action', numeric: false, disablePadding: false, label: '' }
];

function EnhancedTableHead(props) {
  // console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    // console.log('Sort Property: ', property)
    // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function EnhancedTable(props) {
  // console.log('QUEUE', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [rows, setRows] = React.useState([])
  const [filteredRows, setFilteredRows] = React.useState([])
  const [activeItem, setActiveItem] = React.useState({})
  const [state, setState] = React.useState({
    searchMatches: 0,
    project: {},
    showCompleted: true
  })
  const [showUploadDetails, setShowUploadDetails] = React.useState(false)
  const [isEditOpen, setIsEditOpen] = React.useState(false)

  React.useEffect(() => {
    let isMounted = true

    const fetchUserQueueItems = async () => {
      // setLoading(true)
      axios({
        method: 'get',
        url: `${props.url}/api/purchaseorders/list?userOnly=1`
      }).then(response => {
        console.log('Got our POs for User PO List; ', response.data)
        // setLoading(false)
        if (isMounted) {
          setRows(response.data)
          // setState({ ...state, next_touch_at: fortyEightHours })
          setFilteredRows(response.data)
        }
      })
    }

    fetchUserQueueItems()

    return () => {
      isMounted = false
    }
  }, [])

  const handleRequestSort = (event, property) => {
  // console.log(event.target)
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
  // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    // console.l
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, task) => {
    // console.log('Double Click')
    setActiveItem(task)
    setOpen(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
    })
    setFilteredRows(newArr)

  }
  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  const handleOpenEditModal = (row) => {
    setActiveItem(row)
    setIsEditOpen(true)
  }

  const handleCloseModal = () => {
    setIsEditOpen(false)
    setActiveItem({})
  }

  const handleDeleteItem = (itemToDelete) => {
    let  list = rows
    let index = list.findIndex(item => (item._id === itemToDelete._id))
    if (index > -1) {
      list.splice(index, 1)
    }
    setRows(list)
    filterOut(list, searchValue)
  }

  const handleAddItem = (newItem) => {
    let list = rows
    list.unshift(newItem)
    setRows(list)
    filterOut(list, searchValue)
  }

  const handleItemAfterUpdate = (resp) => {
  // console.log('Item Updated...', resp)
    let list = rows
    let index = list.findIndex(item => (item._id === resp.itemToEdit._id))
    if (index > -1) {
      list[index] = resp.updateResponse
      setRows(list)
      filterOut(list, searchValue)
    }
  }

  const handleCompleteItem = (completedItem) => {
  // console.log('Complete this:', completedItem)
    axios({
      method: 'post',
      url: `${props.url}/api/userqueues/markItemComplete`,
      data: completedItem
    }).then(response => {
      // handleDeleteItem(item)
      if (response.data) {
      // console.log('Item deleted', response.data)
        let  list = rows
        let index = list.findIndex(item => (item._id === completedItem._id))
        if (index > -1) {
          list.splice(index, 1)
        }
        setRows(list)
        filterOut(list, searchValue)
      }
    })
  }

  return (
    <div className={classes.root}>
      <EditPOModal open={isEditOpen} po={activeItem} closeModal={handleCloseModal} />
      {/* <EditItemModal open={isEditOpen} url={props.url} item={activeItem} closeModal={handleCloseModal} clients={props.clients} projects={props.projects} updateItem={handleItemAfterUpdate} /> */}
      <Grid container style={{padding: '10px'}}>
        <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
        </Grid>
      </Grid>
        Total Items: {rows.length} { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
      <Grid container style={{padding: '10px'}}>
            
          <Grid item lg={6} xs={6}>

                <TextField
                    name="search" 
                    value={ searchValue } 
                    onChange={ handleChange('search') } 
                    label="Search"
                    type="search"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                />
          </Grid>
        </Grid>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            options={{
              rowStyle: {height: 55}
            }}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody >
              { stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  console.log('Row map: ', row)
                  // console.log('Total rows; ', rows)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let poDisplayNumber = ''
                  if (row.status === 'Approved' || row.approved_at?.length) poDisplayNumber = row.number
                    return (
                    
                      <TableRow
                        hover
                        onDoubleClick={event => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id || index}
                        className={ row.status === 'Reconciled' ? classes.rowPadCOMPLETE : classes.rowPad }
                        selected={isItemSelected}
                      >
                      

                        <TableCell align="left">
                          {
                            poDisplayNumber
                          }                          
                        </TableCell>
                        <TableCell align="left">
                          
                          { row.poType }
                        </TableCell>
                        <TableCell align="left">
                       
                        <Link 
                            style={{pointer: 'cursor'}}
                            target="_blank"
                            to={{
                                  pathname: '/clients/' + row.client,
                                  // search: '?sort=name',
                                  // hash: '#the-hash',
                                  // state: { client: row }
                                }}>  { row.clientName }
                                
                          </Link>
                        </TableCell>
                        <TableCell>
                         
                          <Link 
                            style={{pointer: 'cursor'}}
                            target="_blank"
                            to={{
                                  pathname: '/projects/' + row.project,
                                  // search: '?sort=name',
                                  // hash: '#the-hash',
                                  // state: { project: row.project }
                                }}>   { row.projectName }
                                
                          </Link>
                        </TableCell>
                        <TableCell>
                          {row.description}
                        </TableCell>
                        <TableCell align="left"> {row.status}</TableCell>
                        <TableCell align="left"> {row.created_at ?  moment(row.created_at).format('MM/DD/YYYY hh:mm a') : ''} </TableCell>
                        <TableCell align="right"> 
                          <ActionRow row={row} {...props} editThisRow={handleOpenEditModal} />
                        </TableCell>
                      </TableRow>
                    );
 
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          { loading ?<CircularProgress style={{color: '#fff'}} size={ 16 } /> : ''}
                    
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          // onChangePage={handleChangePage}
          onPageChange={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
  )
}

const ActionRow = ({ row, perms, editThisRow }) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = React.useState(false)

  const handleEdit = () => {
  // console.log('Edit this one:', row)
    // setIsOpen(true)
    editThisRow(row)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }


  return (
    <div className={classes.ActionRow}>
      
      <Tooltip title="Edit">
          <IconButton  edge='end' aria-label="edit" onClick={ handleEdit }>
          <EditIcon id={row._id}  />
        </IconButton>
      </Tooltip>
    </div>
  )
}

const fortyEightHours = new Date(new Date().getTime() - 0 + (42 * 60 * 60 * 1000)).toISOString().slice(0, -8)
const initialStateValues = {
  client: null,
  project: null,
  name: '',
  notes: '',
  next_touch_at: fortyEightHours
}

const initialEditStateValues = {
  client: null,
  project: null,
  name: '',
  notes: '',
  next_touch_at: fortyEightHours
}
const EditItemModal = (props) => {
// console.log('EDIT ITEM MODAL', props)
  const classes = useStyles()
  const [state, setState] = React.useState(initialEditStateValues)
  const [client, setClient] = React.useState({})
  // const [clients, setClients] = React.useState([])
  const [associatedProject, setAssociatedProject] = React.useState(null)
  const [projectList, setProjectList] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const clients = props.clients
// console.log('Clients lenght...', clients.length)
  // React.useEffect(() => {
  //   let isMounted = true
  //   const fetchClients = async () => {
  //     // setLoading(true)
  //     const urlToFetch =  `${props.url}/api/customer/clients`
  //     // console.log('URL to ftech clients:', urlToFetch)
  //     axios({
  //       method: 'get',
  //       url: urlToFetch
  //     }).then(response => {
  //       // console.log('Got our clients for new estimate; ', response.data)
  //       // setLoading(false)
  //       if (response && response.data && isMounted) {
  //         setClients(response.data)
  //         // setFilteredRows(response.data)
  //       } 
  //     })
  //   }
  //   fetchClients()
  //   return () => {
  //     isMounted = false
  //   }
  // }, [])

  React.useEffect(() => {
    // Set OUR SHIT
    // console.log('Set it', props.item)
    let newState = {...props.item}
    let nextTouchDate = new Date(moment(props.item.next_touch_at)).toISOString().slice(0, -8)
    if (props.item && props.item.client) {
      let index = props.clients.findIndex(item => (item._id === props.item.client))
      if (index > -1) {
        setClient(props.clients[index])
        newState.client = props.clients[index]._id
        let projects = props.projects.filter(item => (item.client && item.client._id === props.clients[index]._id))
        setProjectList(projects)
        if (props.item && props.item.project) {
          let index = props.projects.findIndex(item => (item._id === props.item.project))
          if (index > -1) {
            setAssociatedProject(props.projects[index])
            newState.project = props.projects[index]._id
          }
        }
      // console.log('Update Our state...', newState)
        // setState({...state, ...newState})
      }
    }
    newState.next_touch_at = nextTouchDate // nextTouch
  // console.log('Set it:', newState)
    setState({...state, ...newState})
  }, [props.item])

  const handleCloseModal = () => {
    setClient({})
    setProjectList([])
    setAssociatedProject({})
    setState(initialStateValues)
    props.closeModal()
  }

  const updateClient = (e, newClient) => {
    if (newClient && newClient._id) {
      getJobSites(newClient)
      setClient(newClient)
      setState({...state, client: newClient._id })
    } else {
      setClient({})
      // setJobSites([])
    }
    setAssociatedProject({})
  }

  const getJobSites = async (customer) => {
    setLoading(true)
      axios({
    method: 'get',
    url: `${props.url}/api/customer/getAllJobSites?id=${customer._id}`
  }).then(response => {
    // console.log('Got our job sites; ', response.data)
    setLoading(false)
    // setJobSites(response.data.jobSites)
    if (response.data.projects && response.data.projects.length) {
      setProjectList(response.data.projects)
    } else {
      setProjectList([])
    }
  })

}

const formatOptionForProject = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.name) {
    return `${opt.name}`
  } else {
    return ''
  }
}


  const updateProject = (e, proj) => {
    // console.log('Prev associated: ', associatedProject)
    // console.log('state.associated', state)
    if (proj && proj._id) {
      // console.log('NEW PROJECT', proj)
      // setAssociatedProject(proj)?
      setAssociatedProject(proj)
      setState({...state, project: proj._id })
      // setState({...state})
    } else {
      // console.log(proj)
      setAssociatedProject(null)
      setState({...state, project: null })
    }
  }

  const handleUpdate = () => {
    setLoading(true)
    const obj = {
      data: state,
      client: client,
      project: associatedProject,
      itemToEdit: props.item
    }
    axios({
      method: 'post',
      url: `${props.url}/api/userqueues/updateQueueItem`,
      data: obj
    }).then(response => {
      // console.log('Saved Our Item ', response.data)
      if (response.data && response.data._id) {
        obj.updateResponse = response.data
        props.updateItem(obj)
      }
      setLoading(false)
      handleCloseModal()
  })
  }

  // console.log('STATE', state)
  return (
    <Dialog
      open={props.open}
      size={'md'}
      onClose={handleCloseModal}
      >
        <DialogTitle>
          Edit Item
        </DialogTitle>
        <DialogContent>
            <DialogContentText>Please Update Details For This Queue Item</DialogContentText>
            <TextField
              label="Name"
              variant="outlined"
              type="text"
              spellCheck={true}
              style={{marginBottom: 15 }}
              value={state.name}
              className={classes.paddedStuff}
              onChange={e => setState({...state, name: e.target.value})}
              fullWidth
            />
            <Autocomplete
            id="Client"
            options={clients || []}
            getOptionLabel={(option) => {
              // console.log('options: ', option)
              return option.displayName ? `${option.displayName} (${option.agId})` : ''
            }}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            // style={{marginBottom: 10, marginTop: 15}}
            onChange={updateClient}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {client}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Customer" helperText={`Is this for a Customer? If not, leave it blank`} />}
          />
           <Autocomplete
                id="Associated Project"
                options={projectList || []}
                getOptionLabel={(option) => (formatOptionForProject(option) || '')}
                // style={{ width: 200 }}
                className={classes.paddedStuff}
                // style={{marginBottom: 10, marginTop: 15}}
                onChange={updateProject}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {associatedProject || ''}
                // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} variant="outlined" label="Project" helperText={`Is this associated with a Project? If not, leave it blank`} />}
              />
              
            <TextField
                type="datetime-local"
                variant="outlined"
                label="Next Touch By"
                onChange={ e => setState({...state, next_touch_at: e.target.value })}
                name="datetime-local"
                value={ state.next_touch_at }
                fullWidth
                // defaultValue={tomorrow}
                className={classes.paddedStuff}
                // onBlur={updateParent}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={`When Would You Like The Next Touch To Be?`}
              />
             
              <TextField
              label="Details/Notes"
              variant="outlined"
              type="text"
              spellCheck={true}
              multiline
              rows={6}
              style={{marginBottom: 15 }}
              value={state.notes}
              className={classes.paddedStuff}
              onChange={e => setState({...state, notes: e.target.value})}
              fullWidth
            />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleCloseModal}>CANCEL</Button>
          <Button disabled={!client || !client._id || !state.name} variant="contained" color="primary" onClick={handleUpdate}>UPDATE</Button>
        </DialogActions>
      </Dialog>
  )
}

const ListOfItems = (props) => {
  const classes = useStyles()
  // React.useEffect(() => {

    // console.log('JSON: ', json)
    // let ourList = List
    // console.log('List ling:', ourList.length)
    // ourList = ourList.concat(theList)
    // console.log('List Length: ', ourList.length)
  // }, [])
  return (
    <div className={classes.root}>
      <div className={classes.largeLabel}>
        My Purchase Requests
      </div>
      <EnhancedTable {...props} />
    </div>
  )
}
  const mapStateToProps = (state) => {
    return {
      authenticated: state.authenticated,
      notification: state.notification,
      url: state.url,
      perms: state.perms
    }
  }
  
  export default connect(mapStateToProps)(ListOfItems)

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    margin: 10,
    backgroundColor: '#fff'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  rowPad: {
    height: '40px !important',
    padding: '2px'
  },
  largeLabel: {
    fontSize: '1.6em',
    color: '#aaa',
    margin: 10
  },
  paddedStuff: {
    paddingBottom: 15
  },
  ActionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  }
}));
