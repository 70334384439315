import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { Button, CircularProgress, TextField, IconButton, Checkbox, FormControlLabel } from '@material-ui/core';
import axios from 'axios'
import CreateNewEstimateModal from './NewEstimateModal'
import Grid from '@material-ui/core/Grid';
import moment from 'moment'
import InputAdornment from "@material-ui/core/InputAdornment"
import SearchIcon from '@material-ui/icons/Search'
import Chip from '@material-ui/core/Chip';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Tooltip from '@material-ui/core/Tooltip';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import BallotIcon from '@material-ui/icons/Ballot';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    margin: 10
  },
  table: {
    width: '100%',
    padding: 0,
    margin: 0
  },
  tableHeadCell: {
    fontSize: 14,
    color: '#bbb',
    padding: 0
  },
  tableRow: {
    height: 33,
    padding: 0
  },
  tableCell: {
    height: 33,
    margin: 5,
    padding: 0,
    color: '#888'
  }
}))

const ProposalList = (props) => {
// console.log('Proposal List: ', props)
  const classes = useStyles();
  const [showModal, setShowModal] = React.useState(false)
  const [estimates, setEstimates] = React.useState([])
  const [activeEstimate, setActiveEstimate] = React.useState({})
  const [addys, setAddresses] = React.useState([])
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20)
  const [state, setState] = React.useState({
    count: 0,
    showArchived: false,
    showConverted: true
  })
  const [jobSites, setJobSites] = React.useState([])
  const [allAddresses, setAllAddresses] = React.useState([])
  const [searchValue, setSearchValue] = React.useState('')
  const [filteredRows, setFilteredRows] = React.useState([])

  React.useEffect(() => {
    let isMounted = true
    const fetchEstimates = async () => {
      axios({
        method: 'get',
        url: `${props.url}/api/proposal/list?_id=${props.client._id}&salesRep=true`
      }).then(response => {
        // console.log('Got our estimates; ', response.data)
        if (isMounted) {
          setEstimates(response.data)
          setFilteredRows(response.data)
        }
      })
    }
    if (props.client && props.client._id && !props.project) fetchEstimates()
    return () => {
      isMounted = false
    }
  }, [props.client])

  React.useEffect(() => {
    let isMounted = true
    // const fetchEstimates = async () => {
    //   axios({
    //     method: 'get',
    //     url: `${props.url}/api/proposal/list?project=${props.project._id}`
    //   }).then(response => {
    //     // console.log('Got our estimates; ', response.data)
    //     if (isMounted) {
    //       setEstimates(response.data)
    //       setFilteredRows(response.data)
    //     }
    //   })
    // }
    if (props.estimates && props.estimates.length) {
      // console.log('Set Estimatessss: ', props.estimates)
      // console.log('We have estimates!!')
      // if (props.stateData && props.stateData.itemToOpen && props.stateData.itemToOpen === 'Proposal' && props.stateData.openId) {
      // // console.log('Open this bitch', props.stateData.openId)
      //   // handleClick
      //   let index = props.estimates.findIndex(item => {
      //   // console.log('Item._id', item)
      //     if (item._id === props.stateData.openId) return item
      //   })
      // // console.log('Open Estimate Index', index)
      // // console.log(props.estimates[index])
      //   if (index > -1) {
      //     if (isMounted) handleClick(null, props.estimates[index])
      //   }
      // }
      setEstimates(props.estimates)
      setFilteredRows(props.estimates)
    }

    // if (props.stateData && props.stateData.itemToOpen && props.stateData.itemToOpen === 'Proposal' && props.stateData.openId && props.estimates && props.estimates.length) {
    // // console.log('Open this bitch', props.stateData.openId)
    //   // handleClick
    //   let index = props.estimates.findIndex(item => (item._id === props.stateData.openId))
    // // console.log('Open Estimate Index', index)
    // // console.log(props.estimates[index])
    //   if (index > -1) {
    //     if (isMounted) handleClick(null, props.estimates[index])
    //   }
    // }


    return () => {
      isMounted = false
    }
  }, [props.estimates])

  React.useEffect(() => {
    let isMounted = true
  console.log('props changed', props)
    if (props.stateData && props.stateData.itemToOpen && props.stateData.itemToOpen === 'Proposal' && props.stateData.openId && estimates && estimates.length) {
    console.log('Open this bitch', props.stateData.openId)
      // handleClick
      let index = estimates.findIndex(item => (item._id === props.stateData.openId))
    console.log('Open Estimate Index', index)
    console.log(estimates[index])
      if (index > -1) {
        console.log('Weoot mother fuckin woot')
        if (isMounted) {
          setActiveEstimate(estimates[index])
          setShowModal(true)
        }
      }
    }
    return () => {
      isMounted = false
    }
  }, [props.stateData])
  // React.useEffect(() => {
  // // console.log('Props.stateData changed', props.stateData)
  //   if (props.stateData && props.stateData.itemToOpen === 'Proposal' && props.stateData.openId) {
  //   // console.log('Open this bitch', props.stateData.openId)
  //     // handleClick
  //     let index = props.estimates.findIndex(item => (item._id === props.stateData.openId))
  //     if (index > -1) {
  //       handleClick(null, props.estimates[index])
  //     }
  //   }
  // }, [props.stateData])
  // React.useEffect(() => {
  //   let isMounted = true
  //   const fetchEstimates = async () => {
  //     axios({
  //       method: 'get',
  //       url: `${props.url}/api/proposal/list?project=${props.project._id}`
  //     }).then(response => {
  //       // console.log('Got our estimates; ', response.data)
  //       if (isMounted) {
  //         setEstimates(response.data)
  //         setFilteredRows(response.data)
  //       }
  //     })
  //   }
  //   if (props.project && props.project._id) fetchEstimates()
  //   return () => {
  //     isMounted = false
  //   }
  // }, [props.project])

  // React.useEffect(() => {
  //   // console.log('props.client has changed')
  //   if (props.)
  //   // if (props.estimates && props.estimates.length) {
  //   //   setEstimates(props.estimates)
  //   //   if (filteredRows && filteredRows.length) {
  //   //   } else {
  //   //     // console.log('Set Filtered rows to props.estimates')
  //   //     setFilteredRows(props.estimates)
  //   //   }

  //   // } else {
  //   //   setEstimates([])
  //   //   setFilteredRows([])
  //   // }
  // }, [props.client])
  
  React.useEffect(() => {
  // console.log("CREATE ALL ADDRESS LIST FOR ESTIMATE", props.client)
    if (props.jobSites && props.jobSites.length) {
      setJobSites(props.jobSites)
      let addys = []
      for (const addy of props.jobSites) {
        addys.push({
          name: addy.name,
          address: addy.address,
          address2: addy.address2,
          city: addy.city,
          state: addy.state,
          zip: addy.zip
        })
      }
      // console.log('Addys so far ', addys)
      addys.unshift({
        name: 'Client Billing Address',
        address: props.client.address,
        address2: props.client.address2,
        city: props.client.city,
        state: props.client.state,
        zip: props.client.zip
      })
      // console.log('Addys: ', addys)
      // console.log('And props.jobsites:', props.jobSites)
      // console.log(jobSites)
      setAllAddresses(addys)
    } else {
      setJobSites([])
      setAllAddresses([])
    }


  }, [props])
  
  const handleNewEstimate = () => {
    // console.log('Make a new Estimate')
    setShowModal(true)
  }

  // const handleTestPDF = async () => {
  //   let obj = {
  //     client: props.client
  //   }
  //   axios({
  //     method: 'post',
  //     url:  `${props.url}/api/proposal/addNew`,
  //     data: obj,
  //     // validateStatus: (status) => {
  //     //   // console.log('Validate status: ', status)
  //     //   if (status && status === 500) {
  
  //     //   }
  //     // },
  //   }).then(response => {
  //     // console.log('Axios response to new proposal ', response)
  //   })
  // }
  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const filterOut = (r, searchText) => {
    let string = searchText
    let newArr = []
    r.filter(o => {
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log('String', str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    setFilteredRows(newArr)

  }

  const handleClick = (e, item) => {
    e.preventDefault()
    // console.log('Open estimate: ', item)
    setActiveEstimate(item)
    setShowModal(true)
  }

  const saveEstimate = async (d, toClose) => {
  // console.log('Save Estimate 205: ', d)
    if (d && d._id) {
      let ourEstimates = estimates
      // console.log('We have %d estimates ', ourEstimates.length)
      let index = await ourEstimates.findIndex(item => (item._id === d._id))
      // console.log('index: ', index)
      if (index > -1) {
        // let theEstimate = ourEstimates[index]
        ourEstimates[index] = d
        setEstimates(ourEstimates)
        filterOut(ourEstimates, searchValue)
        // setFilteredRows(ourEstimates)
      } else {
        ourEstimates.unshift(d)
        setEstimates(ourEstimates)
        filterOut(ourEstimates, searchValue)
        // setFilteredRows(ourEstimates)
      }
      // console.log('%d total estimates left ', ourEstimates.length)
      // console.log(ourEstimates)
      if (toClose) {
        setActiveEstimate({})
        setShowModal(false)
        setState({...state, count: state.count - 0 + 1, estimate: {}})
      } else {
        setState({...state, count: state.count - 0 + 1})
        setActiveEstimate(d)
      }
    }
    // setShowModal(false)
  }

  const handleCloseNewEstimateModal = (estimate) => {
    // console.log('Close Estimate Modal')
    if (estimate && estimate._id) {
      saveEstimate(estimate, true)
    } else {
      setActiveEstimate({})
      setShowModal(false)
      setState({...state, count: state.count - 0 + 1, estimate: {}})
    }
  }

  const deleteEstimate = (d) => {
    // console.log('Delet Estimate: ', d)
    axios({
      method: 'post',
      url: `${props.url}/api/proposal/delete?id=${d._id}`
    }).then(async response => {
      setShowModal(false)
      // console.log('Got our estimates; ', response.data)
      // console.log('Delete Estimate: ', response)
      let estList = estimates
      let index = await estList.findIndex(item => (item._id === d._id))
      // console.log('index: ', index)
      if (index > -1) {
        estList.splice(index, 1)
        setEstimates(estList)
        setFilteredRows(estList)
      }
      props.notification({
        type: 'success',
        title: `Estimate Deleted (${d.name})`,
        message: `You successfully deleted that Estimate. Well done.`
      })
      setState({...state, count: state.count - 0 + 1})
    })
  }

  const postConversionProcess = async (d) => {
    // console.log('Post Conversion - open job: ', d)
    handleCloseNewEstimateModal()
    props.postConversionProcess(d)
  }

  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setSearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(estimates)
    } else {
      filterOut(estimates, event.target.value)
    }
    setPage(0)
  }

  const updateProposalList = (d) => {
    // console.log('List: ', filteredRows)
    // console.log('Estimate: ', d)
    // let estimateList = filteredRows
    // let index = estimateList.findIndex(item => (item._id === d._id))
    // if (index > -1) {
    //   estimateList[index] = d
    //   // setFilteredRows(estimateList)
    // }
    
    props.updateProposalList(filteredRows)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExportAcceptedEstimates = () => {
  // console.log('Export Estimates')
    let obj = {
      estimates: estimates,
      client: props.client
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/company/exportToCSV`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Export response: ', response.data)
      let newBlob = new Blob([response.data], {type: "text/csv"})
      // console.log(newBlob)
      const data = window.URL.createObjectURL(newBlob);
      // console.log(data)
      var link = document.createElement('a');
      link.href = data;
      let fileName = 'CSV_Export'.replace(/[^a-z0-9]/gi, '_').toLowerCase()
      link.download=`${fileName}.csv`;
      link.click();
    })
  }

  const toggleDisplayArchived = () => {
    setState({...state, showArchived: !state.showArchived })
  }

  const toggleDisplayConverted = () => {
    setState({...state, showConverted: !state.showConverted })
  }

  let ourFilteredProposalsByArchive = filteredRows.filter(row => {
    if (state.showArchived) {
      return row
    } else {
      return (row.status !== 'Archived')
    }
  })

  let ourFilteredProposals = [...ourFilteredProposalsByArchive].filter(row => {
    if (state.showConverted) {
      return row
    } else {
      if (row && row.status && row.status.includes('Converted')) {
        // Ignore it
      } else {
        return row
      }
      // return (row && row.status && !row.status.includes('Converted'))
    }
  })

  // console.log('Current state', state)
  // console.log('Estimates: %d    Filtered Rows: %d    Archived Filtered: %d   Filtered By Converted: %d ', estimates.length, filteredRows.length, ourFilteredProposalsByArchive.length, ourFilteredProposals.length)
  // console.log('Fitered Rows', filteredRows)
  // console.log('ARchived', ourFilteredProposalsByArchive)
  // console.log('Ourfilter', ourFilteredProposals)

  return (
    <div style={{width: '100%'}}>
       <Grid container spacing={2}>
       <Grid item lg={4} md={4} sm={6}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleNewEstimate}
              disabled={!props.client.active}
              label="Test"
              
            >Create Estimate</Button>
          </Grid>
         <Grid item lg={8} md={8} sm={6}>
          <Tooltip title="Export to CSV" >
            <IconButton edge='end' onClick={handleExportAcceptedEstimates} alt="Export Accepted Estimates" aria-label="Export Accepted Estimates">
              <ImportExportIcon />
            </IconButton>
          </Tooltip>
         </Grid>
         <Grid item xs={12} md={4}>
          {/* <Grid item lg={12} md={12} sm={12} > */}
            {/* <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-end', alignItems: 'center', justifyContent: 'center' }}> */}
              <TextField
                name="search" 
                value={ searchValue } 
                onChange={ handleChange('search') }
                label="Search Proposals"
                type="search"
                variant="outlined"
                margin="normal"
                fullWidth
                style={{ flexGrow: 1 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
                {/* <React.Fragment>
                    <Checkbox
                    // indeterminate={numSelected > 0 && numSelected < rowCount}
                    // checked={numSelected === rowCount}
                    onChange={toggleDisplayArchived}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={state.showArchived}
                    inputProps={{ 'aria-label': 'include archived' }}
                  />Include Archived Estimates
                </React.Fragment>
              </div> */}
              </Grid>
          <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {/* Space between the search box and checkbox, potentially for future components */}
            <FormControlLabel
              control={<Checkbox name="showArchived" onChange={toggleDisplayArchived} checked={state.showArchived} />}
              label={state.showArchived ? `Click to Hide Archived Estimates` : `Click to Show Archived Estimates`}
            />
            <FormControlLabel
              control={<Checkbox name="showConverted" onChange={toggleDisplayConverted} checked={state.showConverted} />}
              label={state.showConverted ? `Click to Hide Converted Estimates` : `Click to Show Converted Estimates`}
            />
          </Grid>
          <Grid item xs={12} md={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {/* Checkbox positioned towards the end of the row */}
            
          </Grid>
          <Grid item lg={12} style={{fontSize: '1.3em', color: '#bbb', margin: 5}}>
            Matching Estimates: {ourFilteredProposals.length}
          </Grid>
          
        </Grid>

      <CreateNewEstimateModal open={showModal} project={props.project} entity={props.entity} contacts={props.contacts} jobSites={props.jobSites} addys={allAddresses} postConversionProcess={postConversionProcess} estimate={activeEstimate} handleCloseModal={handleCloseNewEstimateModal} saveEstimate={saveEstimate} deleteEstimate={deleteEstimate} updateProposalList={updateProposalList} />
      { (ourFilteredProposals && ourFilteredProposals.length) ? (
        <Grid container>
          <Grid item lg={12} md={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHeadCell}>
                    Proposal Name
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Project
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Proposal Number
                  </TableCell>
                  <TableCell className={classes.tableHeadCell}>
                    Status
                  </TableCell>
                  {/* <TableCell className={classes.tableHeadCell}>
                    Total Cost
                  </TableCell>
                   */}
                   <TableCell className={classes.tableHeadCell}>
                    Total Price
                  </TableCell>
                  {/* <TableCell className={classes.tableHeadCell}>
                    Created By
                  </TableCell> */}
                  <TableCell className={classes.tableHeadCell}>
                    Designer
                  </TableCell>
                  {/* <TableCell className={classes.tableHeadCell}>
                    Created At
                  </TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                { ourFilteredProposals.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => {
                  let projectName = ''
                  if (item.project) {
                    let project = props?.projects.find(proj => (proj._id === item.project))
                    if (project?._id) projectName = project.name
                  }
                  return (
                  <TableRow className={classes.tableRow} key={index}>
                    <TableCell className={classes.tableCell}>
                       <NameArea item={item} handleClick={handleClick} />
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        {projectName}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                        {
                          item.number
                            ?
                            <span >
                              #{item.number}
                            </span>
                            : null
                        }
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {item.status}
                    </TableCell>
                    {/* <TableCell className={classes.tableCell}>
                      {currencyFormat(item.totalCost)}
                    </TableCell>
                     */}
                     <TableCell className={classes.tableCell}>
                      {currencyFormat(item.totalPrice)}
                    </TableCell>
                    {/* <TableCell className={classes.tableCell}>
                      {((item.created_by && item.created_by.name) ? item.created_by.name : '')}
                    </TableCell> */}
                    <TableCell className={classes.tableCell}>
                      {((item.salesRep && item.salesRep.displayName) ? item.salesRep.displayName : '')}
                    </TableCell>
                    {/* <TableCell className={classes.tableCell}>
                      {moment(item.created_at).format('MM/DD/YYYY [at] hh:mma')}
                    </TableCell> */}
                  </TableRow>
                  )
                })
                }
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50, 100, 500]}
              component="div"
              count={ourFilteredProposals.length}
              rowsPerPage={rowsPerPage}
              page={page}
              // onChangePage={handleChangePage}
              // onPageChange={handleChangePage}
              // onChangeRowsPerPage={handleChangeRowsPerPage}
              // onRowsPerPageChange={handleChangeRowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />  
            </Grid>
            
     
        </Grid>
      ) : '' }
    </div>
  )
}

const NameArea = ({ item, handleClick}) => {
  return (
    <div style={{ margin: '0.4em' }}>
      <div>
        <a  onClick={e=>handleClick(e, item)} href="#" >{item.name ? item.name : 'Proposal'}</a>
        {
          item.signed_at ? <Chip style={{color: '#fff', background: '#39a33d', marginLeft: 10 }} size="small" label="eSigned" /> : null
        }
        {
          item.accepted ? <Chip style={{color: '#fff', background: '#82d8f5', marginLeft: 10 }} size="small" label="Accepted" /> : null
        }
        
        &nbsp;&nbsp;&nbsp;&nbsp;---<a href={`/proposals/${item._id}`} target="_blank" >NEW STYLE </a>
          <Tooltip title="View Paper Style Estimate" >
            <IconButton edge='end' alt="View Estimate Paper Style" aria-label="Paper Style Estimate">
              <a href={`/newestimateview/${item._id}`} target="_blank" ><BallotIcon fontSize="medium" /></a>
            </IconButton>
          </Tooltip>
      </div>
      <div style={{ fontSize: '0.75rem', color: '#aaa', margin: 4 }}>
        {`Created By ${((item.created_by && item.created_by.name) ? item.created_by.name : '')} on ${moment(item.created_at).format('MM/DD/YYYY [at] hh:mma')}`}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms,
    projects: state.projects
  }
}

export default connect(mapStateToProps)(ProposalList)
