import React from 'react'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  statusBarContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    alignSelf: 'center',
    padding: 5,
    margin: 5,
    zIndex: 9999,
    width: '80%',
    border: '2px solid #777',
    borderRadius: 3
  },
  statusBarHeader: {
    // backgroundColor: 'blue',
    padding: 5,
    fontSize: '1.1em',
    fontWeight: 700
  },
  statusBarContent: {
    // backgroundColor: 'red',
    padding: 5,
    fontSize: '1.02em'
  }
}))

const HeadsUpStatusBar = ({ title, message, color }) => {
  const classes = useStyles()
  if (!title || !message) return null
  return (
    <div className={classes.statusBarContainer} style={{ backgroundColor: color || '#fff' }}>
      <div className={classes.statusBarHeader}>{title}</div>
      <div className={classes.statusBarContent}>{message}</div>
    </div>
  )
}

 
export default HeadsUpStatusBar
