import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PlanInputItem from '../Proposals/PlanInputItem'
import SupplyInventoryInputItem from '../Proposals/SupplyInventoryInputItem'
import SupplierComponent from '../Proposals/SupplierComponent'
import EditLineItemInput from './EditLineItemInput'
import SuppliersAvailableComponent from '../Vendors/AvailableVendorsList'
import SupplierPriceUpdateComponent from '../Vendors/SupplierPriceUpdateComponent'
import DeleteComponent from '../Common/DeleteComponent'
import InventoryPhotosComponent from './InventoryPhotosComponent'
import ChooseScopesIncluded from '../Proposals/scopes/ChooseScopesIncluded'

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: 2px solid #eee;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: stretch;
  padding: 10px;
  height: 100%;
`;
const ChooseCategoriesStyle = styled.div`
  flex: 1;
  padding: 10px;
  margin-right: 10px;
`;

const EditLineItemStyle = styled.div`
  flex: 3;
`;
const GeneralInfoContainer = styled.div`
border-radius: 10px;
border: 4px solid #ccc;
padding: 15px;
`;

const TabsChoice = styled.div`
  min-width: 150px;
  width: 25%;
  overflow: auto;
  height: 400px;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  overflow-y: scroll;
  background-color: #fff;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{width: '100%'}}>
          <Typography  component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  }
}));

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const defaultVals = {
  name: '',
  notes: '',
  suppliers: [],
  scope: '',
  altName: '',
  description: '',
  category: '',
  subCategory: '',
  counter: 0,
  isHerbicide: false,
  herbicideDetails: {
    activeIngredient: '',
    formulation: '',
    targetWeeds: [],
    applicationMethod: [],
    safetyPrecautions: '',
    epaRegistrationNumber: '',
    manufacturer: '',
    category: '',
    msds_url: '',
  }
}

const NewEditTakeoffModal = (props) => {
// console.log('NewEditTakeoffModal PROPS:', props)
  const classes = useStyles();

  const [dialogValue, setDialogValue] = React.useState(defaultStateValues);
  const [state, setState] = React.useState(defaultVals)
  // const [scopeList, setScopes] = React.useState([])
  // const [sizes, setSizes] = React.useState([])
  // const [vendors, setVendors] = React.useState([])
  const [tabOpenValue, setTabOpenValue] = React.useState(0)
  const [chosenScope, setChosenScope] = React.useState({})
  const [chosenScopes, setChosenScopes] = React.useState([])
  const [photos, setPhotos] = React.useState([])

  const chosenScopesRef = React.useRef([])

  React.useEffect(() => {
  console.log('Edit Takeoff Modal Props Changed...', props)
    // if (props.item && props.item.options)
    // if (props.item && props.item.name) {
    //   setState({...state, name: props.item.name })
    //   setDialogValue({...dialogValue, name: props.item.name, description: props.item.name})
    // } else {
    //   setState({...state, name: '' })
    //   setDialogValue({...dialogValue, name: '', description: '' })
    // }
    let newState = {}
    if (props.item && props.item._id) {
      if (props.item && props.item.notes) {
        newState.notes = props.item.notes
      }
      newState.isHerbicide = props.item?.isHerbicide
      newState.herbicideDetails = props.item?.herbicideDetails
      // if (props.item && props.item.scope) {
      //   // Find our scope
      //   console.log('Find scope for this one', props.scopes)
      //   let scopeId = props.item.scope
      //   if (scopeId && scopeId._id) scopeId = scopeId._id
      //   let scopes = props.scopes
      //   console.log('ScopeID', scopeId)
      //   let index = scopes.findIndex(item => (item.name === scopeId))
      //   console.log('index from scopes', index)
      //   // console.log(scopes)
      //   if (index > -1) {
      //     let scopetoSet = scopes[index]
      //     console.log('Set this as our scope:', scopetoSet)
      //     setTabOpenValue(index)
      //     setChosenScope(scopetoSet)
      //   }
      // } else {
      //   setTabOpenValue(0)
      //   setChosenScope(props.scopes[0])
      // }
      if (props.item && props.item.scopeIds && props.item.scopeIds.length) {
        let scopesToSet = []
        props.item.scopeIds.map(scopeId => {
          let index = props.scopes.findIndex(item => (item._id === scopeId))
          console.log('index from scopes', index)
          // console.log(scopes)
          if (index > -1) {
            let scopetoSet = props.scopes[index]
            // setChosenScope(scopetoSet)
            scopesToSet.push(scopetoSet)
          }
        })
        console.log(scopesToSet)
        setChosenScopes(prevScopes => {
          return scopesToSet
        })
        // setState({ ...state, counter: state.counter++ })
      }
      if (props.item.supplierIds && props.item.supplierIds.length) {
        console.log('Show the suppliers too', props.item.supplierNames)
        let chosenSuppliers = []
        props.item.supplierIds.map(chosenVendorId => {
          let vendorIndex = props.vendors.findIndex(vendor => (vendor._id.toString() === chosenVendorId.toString()))
          if (vendorIndex > -1) {
            chosenSuppliers.push(props.vendors[vendorIndex])
          }
        })
        console.log('Assign these:', chosenSuppliers)
        newState.suppliers = chosenSuppliers
      }
      console.log('Set the state:', newState)
      setState({ ...state, ...newState, counter: state.counter++ })
    } else {
      // NEW
      console.log('262.....')
      setChosenScope(props.scopes[0])
      setState({ ...state, counter: state.counter++ })
    }


  }, [props])

  const handleChange = (event, newValue) => {
  // console.log('New value', newValue)
    let listItem = props.scopes[newValue]
    setChosenScope(listItem)
    setTabOpenValue(newValue);
  }

  const handleClose = () => {
    setDialogValue(defaultStateValues);

    props.handleCloseModal(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  // console.log('Save this shit!!!', state)

    // console.log(dialogValue)
    // console.log('Chosen Scopes:', chosenScopesRef)
    // console.log(props)
    // // console.log(dialogValue)
    // // console.log('Got it all?')
    let dataToSend = {...state}
    dataToSend.scopes = chosenScopesRef.current
    // dataToSend.scope = chosenScope
    dataToSend.itemToEdit = props.item
    console.log('Send OUR MODIFICATIOPN:', dataToSend)

    axios({
      method: 'post',
      url:  `${props.url}/api/company/modifyTakeOff`,
      data: dataToSend,
    }).then(response => {
      // // console.log('Axios response to add to takeoff inventory ', response)
      if (response && response.data && response.data.success) {
        // if (response.data.deleteOld) {
        // // console.log('Delete the old fucker too!!')
        //   handleDeleteItem(props.item)
        // }
        // props.addItem(response.data)
        if (props.item && props.item._id) {
          console.log('Editing -- dont do nothing special')
          props.notification({
            type: 'success',
            title: 'Item Edited Successfully!',
            message: 'Thank you for helping us clean up the database!'
          })
          props.updateParent('updated', response.data.item)
        } else {
          console.log('New Item - dont close the modal so we can add options after we have a saved item')
          props.notification({
            type: 'success',
            title: 'Item Added Successfully!',
            message: 'Thank you for helping us add to the database!'
          })
          props.updateParent('saved', response.data.item)
        }

      } else {
        let err = response.data.error
        let message = 'There appears to be an issue, please tell the humans Line 249 is the culprit'
        if (err && err.message) message = err.message
        props.notification({
          type: 'warning',
          title: 'Item Not Modified!',
          message: message
        })
      }

    }).catch(err => {
      // // console.log('Error caught when uploading pic: ', err)
      props.notification({
        type: 'warning',
        title: 'Failed to Update Item!',
        message: err.message
      })
    })
  };

  const handleCategoryChange = (e) => {
    setDialogValue({...dialogValue, category: e.target.value})
  }
  const handleUpdateUOM = (e, newOne) => {
    // // console.log('New UOM: ', newOne)
    setDialogValue({...dialogValue, unitsOfMeasure: newOne})
  }

  const handleNewInputInfo = (newItem, type) => {
  console.log('Update New Input Details Details: ', newItem)
  console.log('Type of update', type)
  setState({...state, inputDetails: newItem})

  }

  const updateOurOptions = (item) => {
    console.log('Update the options:', item)
    props.updateParent('updateOptions', item)

  }

  const handleAddSuppliers = (newSuppierList) => {
  console.log('Add these suppliers', newSuppierList)
    setState({...state, suppliers: newSuppierList})
  }

  const handleChangeInput = (e) => {
  // console.log('Change:', e.target.value)
    setState({...state, [e.target.id]: e.target.value})
  }

  const handleDeleteItem = (item) => {
    // console.log('Delete this shit!', item)
    props.updateParent('deleted', item)
  }

// console.log('Chosen Scope:', chosenScope)

  const handleEditOption = (itemToEdit) => {
  // console.log('Edit an option!', itemToEdit)
    props.editOption(itemToEdit)
  }

  const handleScopeChoices = (data) => {
    console.log('Scope choice updated', data)
    chosenScopesRef.current = data
    // UPdate Categories also
    // console.log('ChosenScopes:', chosenScopes)
    if (data.length !== chosenScopes.length) {
      // console.log('Update scopes now!!!')
      setChosenScopes(data)
    }
    // setChosenScopes(data)
  }

  return (
    <Dialog maxWidth={'lg'} fullWidth open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <form onSubmit={handleSubmit}>
      <DialogTitle id="form-dialog-title">
        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
          <div style={{ display: 'flex', flexGrow: 2}}>
            {
              (props.item && props.item._id) ? 'Edit Item In Our Database' : 'Add Item to Database'
            }

          </div>
          <div style={{ display: 'flex' }}>
            {
              (props.item && props.item._id) ? (
                <DeleteComponent
                  type="Shed Inventory"
                  item={props.item}
                  title="Delete Takeoff Item?"
                  verificationText={`Are you sure you wish to Delete this?`}
                  confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
                  confirmationButton="DELETE"
                  denyText="NO"
                  ifApproved={handleDeleteItem}
                  deleteFunction={'inventoryItem'}
                />
              ) : null
            }
            

          </div>
        </div>
        
        </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Modify an Inventory Item
        </DialogContentText>      
      <GeneralInfoContainer>

      <FormControl fullWidth>

      <LabelText>Please Provide As Much Detailed Info As Possible:</LabelText>
      <CategoryContainer>
            <ChooseCategoriesStyle>
              <ChooseScopesIncluded chosenScopes={chosenScopes} scopes={props.scopes} updateParent={handleScopeChoices} />
          </ChooseCategoriesStyle>
          <EditLineItemStyle>
              <EditLineItemInput scope={chosenScope} state={state} setState={setState} chosenScopes={chosenScopes} scopes={props.scopes} sizes={props.sizes} item={props.item} editThisItem={handleEditOption} updateOurOptions={updateOurOptions} updateParent={handleNewInputInfo} />
          </EditLineItemStyle>
        </CategoryContainer>
      </FormControl>
        {
          (props.item && props.item._id) ? (
            <React.Fragment>
      
        <LabelText>Please Choose The Suppliers That Carry This Item:</LabelText>

              <SuppliersAvailableComponent vendors={props.vendors} chosenVendors={state.suppliers} helperText="Pick Suppliers" label="Available At" placeholder="Where Can We Get This Item?" updateParent={handleAddSuppliers} />
              <SupplierPriceUpdateComponent vendors={props.vendors} sizes={props.sizes} item={props.item} />
            </React.Fragment>
          ) : null
        }
       
        <LabelText>Please Provide Any Internal Notes About This Item:</LabelText>
        <TextField
          multiline
          id="notes"
          minRows={4}
          fullWidth
          variant="outlined"
          value={state.notes}
          onChange={handleChangeInput}
          />
      
      { (props.item && props.item._id) ? <InventoryPhotosComponent item={props.item} /> : null }
       
      </GeneralInfoContainer>
             
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button type="submit" color="primary">
          Save
        </Button>
      </DialogActions>
    </form>
   </Dialog>
  )
}
const mapStateToProps = (state) => {
  // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
    scopes: state.scopes
  }
}

export default connect(mapStateToProps)(NewEditTakeoffModal)
