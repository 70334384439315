import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress, Switch } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SupplierComponent from '../Proposals/SupplierComponent'
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import OptionDetailModal from './options/OptionDetailModal';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import HerbicideDetails from './HerbicideDetails';

const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const treeSizes = [
  {
    name: `24" Box`
  },
  {
    name: '30" Box'
  },
  {
    name: `36" Box`
  },
  {
    name: `42" Box`
  },
  {
    name: `48" Box`
  }
]

const plantSizes = [
  {
    name: `1 Gallon`
  },
  {
    name: `2 Gallon`
  },
  {
    name: `3 Gallon`
  },
  {
    name: `5 Gallon`
  },
  {
    name: `7 Gallon`
  },
  {
    name: `10 Gallon`
  },
  {
    name: `20 Gallon`
  }
]

const flowerSizes = [
  {
    name: `Flat (16-4")`
  },
  {
    name: `Jumbo Pack`
  },
  {
    name: `1 Gallon`
  }
]

const treeCategories = [
  {
    name: 'Citrus'
  },
  {
    name: 'Evergreen'
  },
  {
    name: 'Semi-Evergreen'
  },
  {
    name: 'Native'
  },
  {
    name: 'Ornamental'
  }
]

const plantCategories = [
  {
    name: 'Ground Covers'
  },
  {
    name: 'Shrubs'
  },
  {
    name: 'Vines'
  },
  {
    name: 'Flowering'
  }
]

const flowerCategories = [
  {
    name: 'Perrennial'
  },
  {
    name: 'Annual'
  }
]

const EditLineItemInput = (props) => {
  // console.log('EditLineItemInput props', props)

  let name = ''
  if (props.item && props.item.name) name = props.item.name
// console.log('NAME 45: ', name)
  const defaultState = {
    scope: props.scope || 'Other',
    name: name,
    altName: '',
    description: '',
    notes: '',
    sku: '',
    mfg: '',
    upc: '',
    partNumber: '',
    category: '',
    size: { name: 'Pick a Size' },
    subCategory: '',
    isHerbicide: false,
    herbicideDetails: {
      activeIngredient: '',
      formulation: '',
      targetWeeds: [],
      applicationMethod: [],
      safetyPrecautions: '',
      epaRegistrationNumber: '',
      manufacturer: '',
      category: '',
      msds_url: '',
    }
  }
  const scope = props.scope ? props.scope.name || 'None' : 'UnKnown'
  const state = props.state
  const setState = props.setState
  const [value, setValue] = React.useState(null)
  const [dialogValue, setDialogValue] = React.useState(null)
  const [open, toggleOpen] = React.useState(false);
  const [item, setItem] = React.useState({
    name: ''
  })
  const [category, setCategory] = React.useState(null)
  const [categoryList, setCategoryList] = React.useState([])
  const [subCategoryList, setSubcategoryList] = React.useState([])

  // Clear our old Use Effects and refactor (category was disappearing)
  React.useEffect(() => {
    // When these update we need to update categories
    if (props.chosenScopes && props.chosenScopes.length) {
      const newCats = props.chosenScopes.reduce((acc, scope) => {
        if (scope && scope.categories && scope.categories.length) {
          acc = acc.concat(scope.categories);
        }
        return acc;
      }, []);
      console.log('All categories', newCats);
      setCategoryList(newCats);
    } else {
      setCategoryList([]);
    }
  }, [props.chosenScopes]);
  
  React.useEffect(() => {
    if (props.item) {
      console.log('Set State 182 props.item', props.item);
      setItem(props.item);
  
      let newState = {
        name: props.item.name,
        notes: props.item.notes,
        altName: props.item.altName,
        description: props.item.description,
      };
  
      // Set size
      if (props.item.size && props.item.sizeId) {
        const size = props.sizes.find(size => size._id === props.item.sizeId);
        if (size) newState.size = size;
      }
  
      let scopesWithDetails = [];
      let categoryOpts = [];
  
      if (props.item.scopeIds && props.item.scopeIds.length) {
        console.log('Set our Scope IDs', props.item.scopeIds);
  
        props.item.scopeIds.forEach(scopeId => {
          const scope = props.scopes.find(item => item._id === scopeId);
          if (scope) {
            scopesWithDetails.push(scope);
            categoryOpts = categoryOpts.concat(scope.categories || []);
          }
        });
  
        console.log('Set these as checked scopes:', scopesWithDetails);
        setCategoryList(categoryOpts);
      } else if (props.chosenScopes && props.chosenScopes.length) {
        console.log('Set categories based on chosen scopes!!', props.chosenScopes);
  
        props.chosenScopes.forEach(scopeId => {
          const scope = props.scopes.find(item => item._id === scopeId);
          if (scope) {
            scopesWithDetails.push(scope);
            categoryOpts = categoryOpts.concat(scope.categories || []);
          }
        });
  
        console.log('Set these as checked scopes:', scopesWithDetails);
        setCategoryList(categoryOpts);
      } else {
        console.log('Clear categories');
        setCategoryList([]);
        setSubcategoryList([]);
      }
  
      if (props.item.category) {
        console.log('Set the category', props.item.category);
        const category = categoryOpts.find(item => item.name === props.item.category);
        if (category) {
          setCategory(category);
          newState.category = category;
  
          if (category.subCategories && category.subCategories.length) {
            if (props.item.subCategory) {
              newState.subCategory = props.item.subCategory;
            }
            setSubcategoryList(category.subCategories);
          } else {
            setSubcategoryList([]);
          }
        }
      }
  
      console.log('Set new state 225:', newState);
      setState(prevState => ({
        ...prevState,
        ...newState,
      }));
    }
  }, [props.item]);
  


  const openNewCategoryModal = () => {
    window.alert('Not Quite Built Yet')
  }

  const handleUpdateSize = (e) => {
    setState({...state, size: e.target.value})
  }
  
  const handleCategoryChange = (e) => {
    // console.log(e.target)
  // console.log('Change categoryes...')
    const category = e.target.value
    if (e.target.value && e.target.value._id && e.target.value._id === 'NEW') {
      openNewCategoryModal()
    } else {
      setState({...state, category: category})
      // let category = e.target.value
    // console.log('new Category', category)
      if (category && category.subCategories && category.subCategories.length) {
      // console.log('Set subs', category.subCategories)
        setSubcategoryList(category.subCategories)
      } else {
      // console.log('Set subs blank')
        setSubcategoryList([])
      }
    }

  }

  const handleUpdateUOM = (e, newOne) => {
    // // console.log('New UOM: ', newOne)
    setDialogValue({...dialogValue, unitsOfMeasure: newOne})
  }

  const handleUpdateParent = () => {
  // console.log('UPdate PRaent 002028', state)
    // console.log('dv', dialogValue)
  // console.log('Scope:', scope)
    let itemToSend = {...state}
    itemToSend.scope = scope
    props.updateParent(itemToSend)
  }

  const handleOptionsUpdate = (data) => {
    // console.log('Update Options', data)
    // This should be our upated item
    if (data && data._id) {
      // console.log('UPdate the parento:', data)
      data.scope = scope
      // props.updateOurOptions(data, 'options')
      let itemToSend = {...state, ...data}
      itemToSend.scope = scope
      props.updateOurOptions(itemToSend)
    }
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <Grid container style={{width: '100%'}} spacing={2}>
        <Grid item lg={12} sm={12}>
          <span style={{ fontSize: '1.09em', color: '#bbb' }}>
            Edit Item In The {scope} Scope:
          </span>
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              fullWidth
              label="Common Name"
              value={state.name}
              onChange={e=>setState({...state, name: e.target.value})}
              
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              fullWidth
              label="Alternative Name"
              value={state.altName}
              onChange={e=>setState({...state, altName: e.target.value})}
              
            />
          </FormControl>
        </Grid>
        <Grid item lg={12} sm={12}>
          <TextField
            id="description"
            variant="outlined"
            value={state.description}
            onChange={e=>setState({...state, description: e.target.value})}
            
            label="Description"
            type="text"
            helperText="This is what will show up on the Estimate Line Item"
            fullWidth
          />
        </Grid>
        
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <OptionDetailModal {...props} scope={props.scope} updateParent={handleOptionsUpdate} />
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl variant="outlined" style={{width: '100%'}} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state.category}
              onChange={handleCategoryChange}
              
              label="Category"
            >
              {
                categoryList.map(unit => {
                  // console.log('Category option:', unit)
                  return (
                  <MenuItem key={unit._id} value={unit} >{unit.name}</MenuItem>
                )})
              }
            </Select>
        </FormControl>
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl variant="outlined" style={{width: '100%'}} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Sub-Category</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state.subCategory}
              onChange={e=>setState({...state, subCategory: e.target.value})}
              
              label="Unit"
            >
              {
                subCategoryList.map(unit => {
                // console.log('Subt category option:', unit)
                  return (
                  <MenuItem key={unit.name} value={unit.name} >{unit.name}</MenuItem>
                )})
              }
            </Select>
        </FormControl>
        </Grid>
        <Grid item lg={12} sm={12}>
          <FormControlLabel
            control={
              <Switch
                checked={state.isHerbicide}
                onChange={(e) => setState({...state, isHerbicide: e.target.checked})}
                name="isHerbicide"
                color="primary"
              />
            }
            label="Is this an herbicide?"
          />
        </Grid>
        {state.isHerbicide && (
            <Grid item xs={12}>
              <HerbicideDetails state={state} setState={setState} />
            </Grid>
          )}
      </Grid>
    </div>
  )
}

export default EditLineItemInput