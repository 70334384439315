import React from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Permissions from './Permissions'
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete';
import ProtectedButton from '../../actions/protectedButtons';
import AssignedEntitiesComponent from './UserAssignedEntitiesComponent'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import InputMask from "react-input-mask";
import TextField from '@material-ui/core/TextField';
import { setUser } from '../../actions/connectionActions'
import CachedIcon from '@material-ui/icons/Cached';

const Container = styled(Paper)`
  margin: 10px;
  min-height: 85vh;
  color: #555;
  font-size: 18px;
  padding: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const Item = styled.div`
  display: flex;
`;

const RolesContainer = styled(Box)`
  display: flex;
  margin-top: 25px;
  margin-bottom: 15px;
  max-width: 50%;
`;

const RolesList = styled(Box)`
  display: flex;
  align-self: stretch;
  font-size: 16px;
`;

const PermissionsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  width: 50%
`;

const Topbar = styled(Box)`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  width: 80%;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderLeftSide = styled(Box)`
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-grow: 1;
`;

const HeaderRightSide = styled(Box)`
display: flex;
justify-content: flex-end;
align-items: flex-end;
flex-grow: 1;
`;

const roles = [
  'User', 'Foreman', 'Office', 'Office Manager', 'Crew Supervisor', 'Operations Supervisor', 'Admin', 'SuperAdmin'
]

const UserDetailComponent = (props) => {
console.log('User Details Component', props)
  let availableRoles = [...roles]
  const userType = props?.user?.userType
  let roleIndex = roles.findIndex((role, index) => {
  // console.log('Role #%d (%s) -- %s: ', index, role, userType)
    return (role === userType)
  })
  if (roleIndex > -1) {
    availableRoles = roles.splice(0, roleIndex - 0 + 1)
  }
  if (userType && userType !== '' && !availableRoles.includes(userType)) {
  // console.log('Unauthorized')
    return (
      <div>
        UNAUTHORIZED
      </div>
    )
  } else {
    return (
      <UserDetail {...props} availableRoles={availableRoles} />
    )
  }
}

const UserDetail = (props) => {
console.log('User Details: ', props)
  const [profile, setProfile] = React.useState({})
  const [state, setState] = React.useState({
    userType: '',
    permissions: [],
    profile: {},
    name: '',
    isActive: true,
    telephone: ''
  })
  const [loading, setLoading] = React.useState(false)
  // const [entities, setEntities] = React.useState([])
  const availableRoles = props.availableRoles

  const inputRef = React.useRef('')

  const getProfile = async (id) => {
    const userData = await axios.get(`${props.url}/api/user/userProfile?id=${id}`)
    // console.log('Got our profile: ', userData)
    if (userData && userData.data && userData.data.user && userData.data.user._id) {
      const profile = userData.data.user
    console.log('Profile to set: ', profile)
      setProfile(profile)
      // If you are on your own profile update it in real time
      // if (userData.data.user._id === props.user._id) props.setUser(profile)
      // inputRef.value = profile.name
      setState({...state, profile: profile, telephone: profile.telephone, isActive: profile.active, name: profile.name, permissions: profile.permissions, userType: profile.userType, entities: profile.entities})
    }
    // if (userData && userData.data.entities && userData.data.entities.length) {
    //   // console.log('Set entities', userData.data.entities)
    //   setEntities(userData.data.entities)
    // } else {
    //   // console.log('Set blank entities')
    //   setEntities([])
    // }
    // If user type 
  }

  React.useEffect(() => {
  // console.log('not related:', props.match.params)
    if (props.match.params.userId) getProfile(props.match.params.userId)
    // if (props.location && props.location.state && props.location.state.profile) {
    //   setProfile(props.location.state.profile)
    //   if (props.location.state.profile.userType && props.location.state.profile.permissions) {
    //     setState({...state, permissions: props.location.state.profile.permissions, userType: props.location.state.profile.userType})
    //   }
    // }
  },[])

  // React.useEffect(() => {
  // // console.log('PROFILE CHANGED: ', profile)
  //   setState({...state, name: profile.name})

  // }, [profile])

  const handleSave = async () => {
    // console.log('Save this employee', profile)
    // console.log(state)
    // console.log('Entiites Assigned: ', entities)
    // profile.entities = entities
    profile.name = state.name
    profile.active = state.isActive
    profile.userType = state.userType
    profile.telephone = state.telephone
    // const data = {
    //   // state,
    //   profile,
    //   // user: props.user
    // }
    // console.log(profile)
    axios.post(`${props.url}/api/user/updateUserProfile`, profile).then((response) => {
    // console.log(response)
      if (response.data._id) {
        // console.log('Set Permissions/Profile', response.data)
        // setProfile(response.data)
        // setState({...state, permissions: response.data.permissions})
        props.notification({
          type: 'success',
          title: 'User Profile Updated!',
          message: 'Successfully saved those changes.'
        })
      }
    }).catch(e => {
    // console.log('Error saving', e)
      props.notification({
        type: 'warning',
        title: 'Failed to Update Profile',
        message: `We're Sorry, We Were Unable To Update This User. Perhaps you don't have the right permissions?`
      })
    })

  }

  const handlePermissionsUpdate = (d) => {
    // console.log('Permissions update: ', d)
    let ourProfile = profile
    ourProfile.permissions = d
    setProfile(ourProfile)
    setState({...state, permissions: d})
  }

  const handleDeleteUser = () => {
  // console.log('Delete This User')
    const data = {
      user: props.user,
      profile: profile
    }
    setLoading(true)
    axios.post(`${props.url}/api/user/remove`, data).then((response) => {
    // console.log(response)
      setLoading(false)
      if (response.data.success) {
      // console.log('Remove /Profile', response.data)
        props.notification({
          type: 'success',
          title: 'User Deleted',
          message: `We are sad to see ${profile.name} go, but what's done is done.`
        })
        props.history.goBack()
      } else {
        props.notification({
          type: 'warning',
          title: 'Unable to Delete User',
          message: `Perhaps you don't have the proper permissions?`
        })
      }
    }).catch(e => {
    // console.log('Error deleting', e)
    })
  }

  const DeleteButton = () => {
    // console.log('Shoe delete button ro not? ', props.user)
    // let toShow = false
    // props.user.permissions.map(perm => {
    // // console.log(perm)
    //   if (perm.type === 'Users') {
    //     if (perm.delete) {
    //       toShow = true
    //     }
    //   }
    // })
    // console.log('To Show Delet Button: ', toShow)
    // if (toShow) {
      if (loading) {
        return (
          <Button
          variant="contained"
          style={{backgroundColor: '#ed4545'}}
          size="small"
          startIcon={<DeleteIcon />}
          disabled
          >
            <CircularProgress color="primary" size={ 24 } />
          </Button>
        )
  
        } else {
        return (
          <Button
          variant="contained"
          style={{backgroundColor: '#ed4545'}}
          size="small"
          startIcon={<DeleteIcon />}
          onClick={() => { if (window.confirm('Are you sure you wish to delete this user? This is not reversible.')) handleDeleteUser() } }
        >
          { loading ? 'DELETING' : 'Delete' }</Button>     
        )
      }
}
const saveEntities = (entities) => {
// console.log('This user should be assigned to: ', entities)
  setState({...state, entities})
  let newProfile = profile
  newProfile.entities = entities
// console.log('Updated PRofile', newProfile)
  setProfile(newProfile)
}

const updateDefaultEntity = (entity, entities) => {
  // console.log('Update Default Entity: ')
  let newProfile = profile
  newProfile.entities = entities
  newProfile.defaultEntity = entity
  // console.log('Updated PRofile', newProfile)
  setProfile(newProfile)
  setState({...state, profile: newProfile, name: profile.name})
}

const handleBack = (e) => {
  e.preventDefault()
  props.history.goBack()
}

const updateName = (e) => {
  setState({...state, name: e.target.value})
  // inputRef.current[e.target.id] = e.target.value
}

const updatePhone = (e) => {
  setState({...state, telephone: e.target.value})
}
let userType = null
if (props.user && props.user.userType) userType = props.user.userType
  return (
    <div>
      <Container>
        <Topbar>
          <HeaderLeftSide>
            <Button
            variant="contained"
            size="small"
            startIcon={<ArrowBackIcon />}
            onClick={handleBack}
            >
              Back
            </Button>         
          </HeaderLeftSide>
          <HeaderRightSide>
            <ProtectedButton type="Users" kind="delete" perms={props.perms}>
              <DeleteButton />
            </ProtectedButton>
            

          </HeaderRightSide>
        </Topbar>
        <Topbar>
          <HeaderLeftSide>
            <Item ><h2>{state.name}</h2></Item>
          </HeaderLeftSide>
          <HeaderRightSide>
            <Button
              variant="contained"
              color="primary"
              size="small"
              // style={{ textTransform: "none", padding: "14px 0px" }} 
              // onClick={handleAddLineItem}
              startIcon={<CachedIcon />}
              >Reset PW</Button>
          </HeaderRightSide>
        </Topbar>
       
        <Item>
          Name: &nbsp;&nbsp;
          <TextField
            id="name"
            // label="Name"
            type="text"
            value={state.name}
            // defaultValue={profile.name || ''}
            // ref={inputRef}
            // className={classes.textField}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            onChange={updateName}
            // fullWidth
          />
        </Item>
        <Item>
          Telephone: &nbsp;&nbsp;
          <InputMask name="telephone" mask="(999) 999-9999" maskPlaceholder=" " onChange={updatePhone}  value={state.telephone }>
                  {
                    (inputProps) => {
                      return (
                      <TextField                        
                        margin="dense"
                        id="Telephone"
                        label={"Telephone Number #"}
                        type="telephone"                        
                        {...inputProps}
                    />
                      )
                    }
                  }
              </InputMask>
        </Item>
        <Item>Email Address: {profile.email}</Item>
        <Item>Username: {profile.username}</Item>
        <Item>Default Entity: {profile.defaultEntity ? profile.defaultEntity.name : 'None'}</Item>
        {/* {JSON.stringify(profile)} */}
        <ProtectedButton type="Entities" kind="update" perms={props.perms}>
          Name: {profile.name}
          <AssignedEntitiesComponent entities={props.user.entities} userProfile={profile} saveEntities={saveEntities} updateDefaultEntity={updateDefaultEntity} />
          {/* {AssignedEntitiesComponent({ userProfile: profile, entities: entities, saveEntities: saveEntities, updateDefaultEntity: updateDefaultEntity })} */}
        </ProtectedButton>
        <RolesContainer>
          <FormControl fullWidth>
            <ProtectedButton type="Users" kind="update" perms={props.perms}>
            <FormControl component="fieldset" >
                  <FormLabel component="legend">Active User</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.isActive}
                      onChange={ e => setState({...state, isActive: !state.isActive }) }
                      name="Active" />}
                      label="Active"
                    />
                  </FormGroup>
                </FormControl>
          </ProtectedButton>
          </FormControl>
          <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">User Type</InputLabel>
                  <RolesDropDownOptions availableRoles={availableRoles} state={state} setState={setState} />
                <FormHelperText>Type of User (Grants Basic Permissions & Views)</FormHelperText>
              </FormControl>
       
        
          
        </RolesContainer>
        <PermissionsContainer>
            Please Choose Specific Permissions for this User:
            <Permissions profile={profile} updateParent={handlePermissionsUpdate} />
        </PermissionsContainer>
        <Button
          size="medium"
          color="primary"
          variant="contained"
          onClick={handleSave}
          >Save</Button>
      </Container>
    </div>
  )
}



const RolesDropDownOptions = ({ availableRoles, state, setState }) => {
  console.log('Roles dropdown', availableRoles)
  console.log('state', state)
    return (
      <Select
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      value={state.userType}
      onChange={(e) => setState({...state, userType: e.target.value })}
    >
      {
        availableRoles.map((role, index) => {
          console.log('Role', role)
          return (
            <MenuItem key={index} value={role}>{role}</MenuItem>
          )
        })
      }
    </Select>
  
    )


}



const mapStateToProps = (state) => {
  // console.log('Entities available 432??', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms
  }
}

const mapDispatchToProps = (dispatch) => {
  // console.log('DISTPACTH: ', dispatch)
  return {
    setUser: (user) => { dispatch(setUser(user)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailComponent)