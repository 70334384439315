import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import SingleUpload from './UploadViewers/SingleUpload'
import MultipleUploads from './UploadViewers/MultipleUploads'

const POUploads = (props) => {
// console.log('PO Uploads:', props)
  const [uploads, setUploads] = React.useState([])
  
  React.useEffect(() => {
    let isMounted = true
    const fetchPhoto = async (id) => {
    // console.log('Fetch Photos', id)
      let url = `${props.url}/api/uploads/getUploadsForPO?id=${id}`
    // console.log('URL to retrieve: ', url)
      const response = await axios.get(url)
      if (response && response.data) {
      // console.log('Got our uploads...', response.data)
        setUploads(response.data)
      }
    }
    if (props.po && props.po._id) fetchPhoto(props.po._id)

    return () => {
      isMounted = false
    }
  }, [props.po])
  return (
    <div>
      {
        uploads.length > 1 ? <MultipleUploads po={props.po} uploads={uploads} /> : <SingleUpload po={props.po} uploads={uploads} />
      }
    </div>
  )

}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(POUploads)
