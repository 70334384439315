
import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, IconButton }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRounded from '@material-ui/icons/EditRounded'
import AddRequiredLineItemModal from './AddRequiredLineItemModal'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components'

const DefaultLineItemContainer = styled.div`
background-color: ${props => (props.isDraggingOver ? '#34eb80' : 'inherit')};
-webkit-user-select: none;
-ms-user-select: none;
user-select: none;
min-height: 100px;
`;

const DefaultLineItemBox = styled(Grid)`
  border: ${props => ((props.isClockedIn || props.isDragging) ? '3px solid #444' : '2px solid lightgrey')};
  color: ${props => ((props.absenceType || props.isCheckedIn || props.isClockedIn)  ? '#ddd;' : '#444;')};
  border-radius: 5px;
  padding: 4px;
  margin: 2px;
  background-color: ${props => (
    props.isDragging ? '#fff' : '#eee' )};
`;

const DefaultLineItemList = ({ service, handleDeleteDefaultLineItem, handleEditDefaultLineItem, updateDefaultLineItemOrder }) => {
  // console.log('Default Line Items ', service)
  const [list, setList] = React.useState([])

  React.useEffect(() => {
    let defaultItems = []
    if (service && service.defaultLineItems && service.defaultLineItems.length) {
      defaultItems = service.defaultLineItems
    }
    setList(defaultItems)
  }, [service])

  const DeleteButton = (props) => {
    return (
      <IconButton onClick={e => handleDeleteDefaultLineItem(props.item, props.index)} edge='end' alt="Delete" aria-label="Delete">
        <DeleteIcon />
      </IconButton>
    )
  }

  const EditButton = (props) => {
    return (
      <IconButton onClick={e => handleEditDefaultLineItem(props.item, props.index)} edge='start' alt="Edit" aria-label="Edit">
        <EditRounded />
      </IconButton>
    )
  }

  const onDragStart = () => {
    // console.log('Drag Start')
    document.body.style.color = 'orange';
    document.body.style.transition = 'background-color 0.2s ease'
  }

  const onDragUpdate = update => {
    // console.log('Drag Update', update)
    const { destination } = update
    const opacity = destination ? destination.index / Object.keys(2).length : 0;
    document.body.style.backgroundColor = `rgba(153, 141, 217, ${opacity})`
  }

  const onDragEnd = result => {
    // console.log('DRAG END', result)
    document.body.style.color = 'inherit';
    document.body.style.backgroundColor = 'inherit'
    // console.log('on Drag End', result)
    const { destination, source, draggableId } = result;
    // console.log('Destination: ', destination)
    // console.log('Source: ', source)
    // console.log('ID: ', draggableId)
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
    updateDefaultLineItemOrder(items)
  }

  return (
    <div style={{  padding: 5}}>
       <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="defaultLineItems">
            {(provided, snapshot) => (
              <DefaultLineItemContainer {...provided.droppableProps} ref={provided.innerRef}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {list.map((item, index) => {
                  return (
                    <Draggable key={item} draggableId={item} index={index}>
                      {(provided, snapshot) => (
                        <DefaultLineItemBox container style={{ border: '1px #ddd solid', backgroundColor: '#fff', borderRadius: 4, padding: 4, marginTop: 5, }} 
                          ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} isDragging={snapshot.isDragging}
                        >
                         
                            <Grid item lg={9} xs={12} style={{ marginTop: 10 }}>
                              {`${item}`}
                            </Grid>
                            <Grid item lg={3} xs={12} style={{ textAlign: 'right' }}>
                            <EditButton item={item} index={index} />
                            <DeleteButton item={item} index={index} />
                            </Grid>
                          </DefaultLineItemBox>

                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </DefaultLineItemContainer>
            )}
          </Droppable>
        </DragDropContext>
    </div>
  )
}

const DefaultLineItemComponent = (props) => {
  // console.log('Default Line Items', props)
  const [loading, setLoading] = React.useState(false)
  const [showNewInput, setShowNewInput] = React.useState(false)
  const [inEditMode, setEditMode] = React.useState(false)
  const [activeItem, setActiveInputItem] = React.useState({})

  const addDefaultLineItem = () => {
    setShowNewInput(true)
  }
  
  const handleSaveNewDefaultLineItem = (inputItem) => {
    // console.log('Save ', inputItem)
  // console.log(inputItem.index)
    setShowNewInput(false)
    if (inEditMode && activeItem) {
      // Update Existing Item
      // console.log('Update this item: ', activeItem)
      // console.log(' With this: ', inputItem)
      let newSvc = props.service
      let defaultLineItems = newSvc.defaultLineItems
      // let index = defaultLineItems.findIndex(item => (activeItem === item.name))
      if (inputItem.index > -1) {
        defaultLineItems[inputItem.index] = inputItem.text
        // console.log('Updated %d w/ this:', index, inputItem)
        // newSvc.defaultLineItems = r
        // console.log('Update this bitch after EDITING an input: ', newSvc)
        props.updateService(newSvc, true)
      }
      setActiveInputItem({})
      setEditMode(false)
    } else {
      // Save a New Item
      setLoading(true)
    // console.log(props)
      let obj = {
        lineItem: inputItem.text,
        service: props.service
      }
    // console.log(obj)
      axios({
        method: 'post',
        url:  `${props.url}/api/company/addDefaultLineItem`,
        data: obj,
        // validateStatus: (status) => {
        // // console.log('Validate status: ', status)
        //   if (status && status === 500) {
    
        //   }
        // },
      }).then(response => {
        // console.log('Axios response to add required input to  service ', response.data)
        let newSvc = response.data
        newSvc.scope = props.service.scope
        props.updateService(newSvc)
        setLoading(false)
      })
    }
    // console.log('Save this Required Input: ', inputItem)
  }

  const handleCloseRequiredLineItemModal= () => {
    // console.log('Close Modal')
    setShowNewInput(false)
  }

  const handleDeleteDefaultLineItem = (item, index) => {
    // console.log('Delete this: ', item)
    let msg = `Are you sure you wish to delete this Default Line Item (${item})? This is not reversible`
    if (window.confirm(msg)) {
      let newSvc = props.service
      let defaultLineItems = newSvc.defaultLineItems
      // let index = defaultLineItems.findIndex(inputItem => (inputItem === item))
      if (index > -1) {
        defaultLineItems.splice(index, 1)
        // newSvc.defaultLineItems = r
        // console.log('Update this bitch: ', newSvc)
        props.updateService(newSvc, true)
      }
    }
  }

  const handleEditDefaultLineItem = (item, index) => {
    // console.log('Edit this; ', item)
    setActiveInputItem({ text: item, index: index })
    setEditMode(true)
    setShowNewInput(true)
  }

  const handleUpdateDefaultLineItemOrder = (items) => {
    let newSvc = props.service
    newSvc.defaultLineItems = items
    props.updateService(newSvc, true)
  }
  return (
    <div >
      <AddRequiredLineItemModal open={showNewInput} editing={inEditMode} defaultLineItem={activeItem} service={props.service} closeModal={handleCloseRequiredLineItemModal} saveItem={handleSaveNewDefaultLineItem} />
      <Grid item lg={12} xs={12} style={{ fontSize: '1.25em', fontColor: '#aaa'}}>
        Default Line Items
      </Grid>
      <Grid item lg={12} xs={12} style={{ fontSize: '1.10em', fontColor: '#bbb'}}>
        Line Items That Will Populate Whenever This Service Is Chosen
      </Grid>
      <Grid item lg={12} xs={12} style={{ textAlign: 'right', margin: 8 }}>
        <Button variant="contained" color="secondary" onClick={addDefaultLineItem}>Add Default Line Item</Button>
      </Grid>
      <Grid item lg={12} xs={12} style={{ fontSize: '1.05em', fontColor: '#ccc'}}>
        Current Default Line Items:
      </Grid>
      <Grid item lg={12} xs={12}>
        <DefaultLineItemList service={props.service} handleDeleteDefaultLineItem={handleDeleteDefaultLineItem} handleEditDefaultLineItem={handleEditDefaultLineItem} updateDefaultLineItemOrder={handleUpdateDefaultLineItemOrder} />
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(DefaultLineItemComponent)
