import React from 'react'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import Chip from '@material-ui/core/Chip'; 
import styled from 'styled-components'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip, IconButton } from '@material-ui/core'
import DialogContentText from '@material-ui/core/DialogContentText';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const LineItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const AddLineItemButton = (props) => {
  return (
    <>
    <Button 
      variant="contained"
      size="small"
      color="primary"
      onClick={props.toggleModal}
      >
      Add Line Item
    </Button>
    </>
    
  )
}

const LineItemList = ({ lineItems, deleteTheLine }) => {
  const handleDelete = (line) => {
  // console.log('Delete Line Item')
    deleteTheLine(line)
  }
  return (
    <div style={{ marginBottom: '1rem', marginTop: '1rem', paddingTop: '1em', paddingBottom: '1rem', backgroundColor: '#fff' }}>
      {
        lineItems.map((line, index) => {
          return (
            <Grid container key={index} >
              <Grid item lg={8} md={8} sm={6} xs={6}>{line}</Grid>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                <Tooltip title="Delete" >
                  <IconButton
                    size="small"
                    edge="end"
                    style={{marginLeft: '4em'}}
                    onClick={e => handleDelete(line)}
                    >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
              
            </Grid>
          )
        })
      }
    </div>
  )
}

const NewLineItemModal = (props) => {
  const defaultState = {
    lineItem: ''
  }
  const [state, setState] = React.useState(defaultState)
  const handleClose = () => {
    props.closeModal()
  }

  const handleSave = () => {
    props.saveLineItem(state.lineItem)
    setState(defaultState)
  }
  return (
    <Dialog open={props.open} onClose={handleClose} maxWidth={'md'} fullWidth>
      <DialogTitle>New Invoice Line Item</DialogTitle>
      <DialogContent>
        <DialogContentText>Please Enter the Line Item Below:</DialogContentText>
        <span style={{ fontSize: '1.1em', color: '#aaa'}}>
        You can use the following variables: {`{MONTH}`}
      </span>
        <TextField
          id="lineitem"
          label="Line Item"
          multiline
          minRows={2}
          variant="outlined"
          fullWidth
          // className={classes.textSaveLineBreaks}
          value={state.lineItem}
          onChange={e => setState({...state, lineItem: e.target.value })}
          // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
          // defaultValue="Default Value"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">Close</Button>
        <Button onClick={handleSave} color="primary" >Save</Button>
      </DialogActions>
    </Dialog>
  )
}

const InvoiceLineItemsToShowUpComponent = (props) => {
  const defaultState = {
    lineItems: [
      'Monthly Maintenance'
    ]
  }
  const [showNewLineItemModal, setShowNewLineItemModal] = React.useState(false)
  const [state, setState] = React.useState(defaultState)

  React.useEffect(() => {
    if (props.existingItems && props.existingItems.length) {
      setState({...state, lineItems: props.existingItems})
    }
  }, [])

  const toggleModal = () => {
    setShowNewLineItemModal(!showNewLineItemModal)
  }

  const handleAddLineItem = (data) => {
    setShowNewLineItemModal(false)
  // console.log('Add this:', data)
    let newLineItems = [...state.lineItems]
    newLineItems.push(data)
    setState({...state, lineItems: newLineItems})
    props.saveLineItem(newLineItems)
  }

  const handleDeleteLineItem = (line) => {
    let newLineItems = [...state.lineItems]
    let index = newLineItems.findIndex(item => (item === line))
    if (index > -1) {
      newLineItems.splice(index, 1)
      setState({...state, lineItems: newLineItems })
    }
    props.saveLineItem(newLineItems)
  }
  
  return (
    <div style={{minHeight: '80px'}}>
      <NewLineItemModal open={showNewLineItemModal} closeModal={toggleModal} saveLineItem={handleAddLineItem} />
      
     <LineItemContainer>
     <DialogContentText>Invoice Line Items</DialogContentText>
      <AddLineItemButton  toggleModal={toggleModal} />
      
     </LineItemContainer>
      <span style={{ fontSize: '1.1em', color: '#aaa'}}>
        These will be the line items that show up on the Maintenance Invoice
      </span>
     
      <LineItemList lineItems={state.lineItems} deleteTheLine={handleDeleteLineItem} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(InvoiceLineItemsToShowUpComponent))