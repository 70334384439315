import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AddAlertIcon from '@material-ui/icons/AddAlert';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Box from '@material-ui/core/Box'


const tomorrow = new Date(new Date().getTime() - 0 + (24 * 60 * 60 * 1000)).toISOString().slice(0, -8)

const defaultValues = {
  showReminderDetails: false,
  details: '',
  reminderTime: tomorrow,
  urgent: false
}

const NewReminderComponent = (props) => {
  
  // console.log('New Remidner component props', props)
  // console.log('TOMORROW', tomorrow)
  const [state, setState] = React.useState(defaultValues)
  
  React.useEffect(() => {
    // console.log('State changed - report it')
    props.updateParent(state)
  }, [state])

  const handleToggleOpenReminderModal = () => {
    // console.log('Open reminder', props)
    let newState = {...defaultValues}
    newState.showReminderDetails = !state.showReminderDetails
    // console.log('REMINDER STATE:', newState)
    setState(newState)
    props.updateParent(newState)
  }

  const updateParent = () => {
    props.updateParent(state)
  }

  const updateUrgency = () => {
    setState({...state, urgent: !state.urgent })
  }
  return (
    <div style={{ margin: 10, padding: 5 }}>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Tooltip title="Add Reminder">
            <Button
              size="small"
              edge="end"
              variant="contained"
              color="secondary"
              startIcon={<AddAlertIcon />}
              onClick={handleToggleOpenReminderModal}
              >
              {
                state.showReminderDetails ? 'Cancel Reminder' : 'Add Reminder'
              }
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
        {
          state.showReminderDetails ? (
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              multiline
              variant="outlined"
              rows={3}
              maxRows={6}
              label="Reminder Details"
              onChange={ e => setState({...state, details: e.target.value })}
              name="details"
              value={ state.details }
              fullWidth
              // onBlur={updateParent}
            />
          </Grid>
          <Grid item lg={8} md={8} sm={6} xs={12}>
              <TextField
                type="datetime-local"
                variant="outlined"
                label="Reminder Time"
                onChange={ e => setState({...state, reminderTime: e.target.value })}
                name="datetime-local"
                value={ state.reminderTime }
                fullWidth
                defaultValue={tomorrow}
                style={{ marginTop: 10 }}
                // onBlur={updateParent}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={6} xs={12}>
              <FormControl component="fieldset" style={{ marginTop: '16px' }}>
                <Box display="flex" alignItems="center">
                  
                  <FormControlLabel
                    control={<Checkbox checked={state.urgent} onChange={updateUrgency} name="isUrgent" />}
                    label=""
                  />
                  <FormLabel component="legend">Is This an Urgent Request?</FormLabel>
                </Box>
              </FormControl>
            </Grid>
          </Grid>
          ) : null
        }   
    </div>
  )
}
export default NewReminderComponent
