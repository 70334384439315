import React from 'react';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from '@material-ui/core/TextField';
import NewSupplierModal from './NewSupplierModal'
import { SelectionState } from 'draft-js';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const defaultSupplierList = [
  {
    name: 'Horizon',
    _id: '453453453453'
  },
  {
    name: 'Home Depot',
    _id: '453453453453'
  },
  {
    name: 'Summer Winds Nursery',
    _id: '453453453453'
  }
]

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

const filter = createFilterOptions();

const AvailableVendorsComponent = (props) => {
// console.log('Available Vendors List Component', props)
  const [supplierList, setSupplierList] = React.useState(props.vendors || defaultSupplierList)
  const [supplier, setSupplier] = React.useState({ name: '' })
  const [showNewSupplierModal, setShowNewSupplierModal] = React.useState(false)
  const [chosenSuppliers, setChosenSuppliers] = React.useState([])
  const [state, setState] = React.useState({
    temp: ''
  })

  React.useEffect(() => {
    // console.log('props changed')
    if (props && props.chosenVendors && props.chosenVendors.length) {
      setChosenSuppliers(props.chosenVendors)
      // console.log('Set our chosen vendors', props.chosenVendors)
    } else {
      setChosenSuppliers([])
    }
    // if (props.vendors && props.vendors.length) {
    // // console.log('Set suppliers:', props.vendors)
    //   setSupplierList(props.vendors)
    //   if (props.vendor && props.vendor !== {}) {
    //     // console.log('We have a vendor:', props.vendor)
    //     // let vendor = ''
    //     setSupplier(props.vendor)
    //     // let index = props.vendors.findIndex(item => (item._id === props.vendor))
    //     // if (index > -1) setSupplier(props.vendors[index])
    //   }
    // } else {
    //   setSupplierList([])
    // }
  }, [props])
  
  const handleUpdateSupplierList = (e, newSupplierList) => {
    // let list = supplierList
  // console.log('New supplier list:', newSupplierList)
    // list.push(newSupplier)
    // setSupplierList(list)
    if (props.single) {
      if (newSupplierList && newSupplierList._id) {
        setSupplier(newSupplierList)
        props.updateParent(newSupplierList)
      }
      if (newSupplierList && newSupplierList.name && newSupplierList.name === 'Add New Vendor') {
        setState({...state, temp: newSupplierList.inputValue })
        setShowNewSupplierModal(true)
      }
    } else {
    // console.log('Update Supplier List', newSupplierList)
      e.preventDefault()
      // console.log('New: ', newSupplierList)
      let newSupplier = newSupplierList[newSupplierList.length - 1]
      if (newSupplier && newSupplier.inputValue) {
      // console.log('new item!!!')
        setState({...state, temp: newSupplier.inputValue })
        setShowNewSupplierModal(true)
      }
      if (newSupplier && newSupplier._id) {
      // console.log('An existing item was chosen')
      }
      if (typeof newSupplier === 'string') {
      // console.log('They press entered')
        setShowNewSupplierModal(true)
      }
      if (props.updateSupplierList) props.updateSupplierList(newSupplierList)
    }
    // // console.log(e.target)
    // // console.log(newSupplier)
  }

  const handleSaveNewSupplier = (d) => {
  // console.log('Handle Save this supplier', d)
    if (d && d._id) {
      setSupplier(d)
      setShowNewSupplierModal(false)
      props.updateParent(d)
    }
  }

  const handleCloseNewSupplierModal = () => {
    setShowNewSupplierModal(false)
    setSupplier({ name: '' })
    // props.closeModal()
  }

  const handleUpdateAvailableVendors = (e, newVendors) => {
  // console.log('New Vendors:', newVendors)
    
    props.updateParent(newVendors)
  }
  return (
    <div>
      <NewSupplierModal open={showNewSupplierModal} name={state.temp} closeModal={handleCloseNewSupplierModal} updateParent={handleSaveNewSupplier} />


      <FormControl fullWidth >

          <Autocomplete
            freeSolo
            multiple
            id="supplier-list"
            fullWidth
            options={props.vendors || []}
            getOptionLabel={(option) => {
              // console.log('option:', option)
              return option.name
            }}
            defaultValue={[]}
            value={chosenSuppliers}
            filterSelectedOptions
            onChange={handleUpdateAvailableVendors}
            renderInput={(params) => (
              <TextField
                {...params}
                label={props.label}
                placeholder={props.placeholder}
                variant="outlined"
                helperText={props.helperText}
              />
            )}

          />
      </FormControl>
    </div>
  )
}

export default AvailableVendorsComponent
