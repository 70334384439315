import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PlanInputItem from '../PlanInputItem'
import SupplyInventoryInputItem from '../SupplyInventoryInputItem'
import SupplierComponent from '../SupplierComponent'
import NewLineItemInput from '../NewLineItemInput'
import SuppliersAvailableComponent from '../../Vendors/AvailableVendorsList'
import SupplierPriceUpdateComponent from '../../Vendors/SupplierPriceUpdateComponent'
import InventoryPhotosComponent from '../../Uploads/PhotoUploadComponent'
// import ChooseCategoriesIncluded from '../scopes/ChooseScopesIncluded'

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border: 2px solid #eee;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: stretch;
  padding: 10px;
`;

const GeneralInfoContainer = styled.div`
border-radius: 10px;
border: 4px solid #ccc;
padding: 15px;
`;

const TabsChoice = styled.div`
  min-width: 150px;
  width: 25%;
  overflow: auto;
  height: 400px;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  overflow-y: scroll;
  background-color: #fff;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{width: '100%'}}>
          <Typography  component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  }
}));

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const unitsOfMeasure = [
  {
    name: 'Each'
  },
  {
    name: 'Pint'
  },
  {
    name: 'Quart'
  },
  {
    name: '1 Gallon'
  },
  {
    name: '2 Gallon'
  },
  {
    name: '3 Gallon'
  },
  {
    name: '5 Gallon'
  },
  {
    name: '10 Gallon'
  },
  {
    name: '15 Gallon'
  },
  {
    name: '25 Gallon'
  },
  {
    name: '24" Box'
  },
  {
    name: '30" Box'
  },
  {
    name: '36" Box'
  },
  {
    name: '48" Box'
  },
  {
    name: '60" Box'
  },
  {
    name: `70" Box`,
    label: `70" Box`
  },
  {
    name: `72" Box`,
    label: `72" Box`
  },
  {
    name: `78" Box`,
    label: `78" Box`
  },
  {
    value: `84" Box`,
    label: `84" Box`
  },
  {
    name: 'Square Foot'
  },
  {
    name: 'Linear Foot'
  },
  {
    name: 'Flat'
  },
  {
    name: 'Ton'
  }
]


const AddNewItemModal = (props) => {
  // console.log('ADD NEW IITEM PROPS:', props)
  const classes = useStyles();
  const defaultVals = {
    inputDetails: {
      name: props.item.name || ''
    },
    name: props.item.name || '',
    notes: '',
    suppliers: []
  }
  const [dialogValue, setDialogValue] = React.useState(defaultStateValues);
  const [state, setState] = React.useState(defaultVals)
  const [list, setList] = React.useState([])
  const [sizes, setSizes] = React.useState([])
  const [vendors, setVendors] = React.useState([])

  React.useEffect(() => {
    let isMounted = true
    async function fetchScopes () {
      axios
      .get(
        `${props.url}/api/settings/getStuffForNewItem`
        )
      .then(({ data }) => {
        if (isMounted) {
          if (data && data.scopes) {
            setList(data.scopes)
            setDbItem(data.scopes[0])
          }
          if (data && data.sizes) {
            setSizes(data.sizes)
          }
          if (data && data.vendors) {
            setVendors(data.vendors)
          }
        }


      }).catch(function (err) {
      // console.log('Error getting stuff', err)
      });
    }
    fetchScopes()
    return () => {
      isMounted = false
    }
  }, [])

  React.useEffect(() => {
  // console.log('PROPS.item changed', props.item)
    // if (props.item && props.item.name) {
    //   setState({...state, name: props.item.name })
    //   setDialogValue({...dialogValue, name: props.item.name, description: props.item.name})
    // } else {
    //   setState({...state, name: '' })
    //   setDialogValue({...dialogValue, name: '', description: '' })
    // }

  }, [props.item])

  const [tabOpenValue, setTabOpenValue] = React.useState(0)
  const [dbItem, setDbItem] = React.useState({})

  const handleChange = (event, newValue) => {
  // console.log('New value', newValue)
    let listItem = list[newValue]
    setDbItem(listItem)
    setTabOpenValue(newValue);
  }

  const handleClose = () => {
    setDialogValue(defaultStateValues);

    props.handleCloseModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  // console.log('Save this shit!!!', state)
    // console.log(dialogValue)
    // console.log(props)
    // // console.log(dialogValue)
    // // console.log('Got it all?')
    let dataToSend = {...state}
    dataToSend.scope = dbItem
    
  // console.log('Send iT:', dataToSend)
    handleClose();
    axios({
      method: 'post',
      url:  `${props.url}/api/company/saveNewTakeOff`,
      data: dataToSend,
    }).then(response => {
      // // console.log('Axios response to add to takeoff inventory ', response)
      if (response && response.data && response.data._id) {
        props.addItem(response.data)
        props.notification({
          type: 'success',
          title: 'Item Added Successfully!',
          message: 'Thank you for helping us add to the database!'
        })
      } else {
        props.notification({
          type: 'warning',
          title: 'Item Not Added!',
          message: 'There appears to be an issue, but not an error. Tell the humans Line 158 is the culprit'
        })
      }

    }).catch(err => {
      // // console.log('Error caught when uploading pic: ', err)
      props.notification({
        type: 'warning',
        title: 'Failed to Save Item!',
        message: err.message
      })
    })
  };

  const handleCategoryChange = (e) => {
    setDialogValue({...dialogValue, category: e.target.value})
  }
  const handleUpdateUOM = (e, newOne) => {
    // // console.log('New UOM: ', newOne)
    setDialogValue({...dialogValue, unitsOfMeasure: newOne})
  }

  const handleNewInputInfo = (newItem) => {
  // console.log('Update New Input Details Details: ', newItem)
    // console.log('c: ', c)
    // console.log('db Item', dbItem)
    // console.log('props;', props)
    // if (c && c.unitsOfMeasure && c.unitsOfMeasure.length) d.unitsOfMeasure = c.unitsOfMeasure
    setState({...state, inputDetails: newItem})
  }

  // const handleSave = () => {
  // // console.log('Save this shit!!!', state)
  // // console.log(dialogValue)
  // // console.log(props)

  // }

  const handleAddSuppliers = (newSuppierList) => {
  // console.log('Add these suppliers', newSuppierList)
    setState({...state, suppliers: newSuppierList})
  }

  const handleChangeInput = (e) => {
  // console.log('Change:', e.target.value)
    setState({...state, [e.target.id]: e.target.value})
  }

  const handleScopeChoices = (data) => {
    console.log('Scopes chosen', data)
  }
  return (
    <Dialog maxWidth={'lg'} fullWidth open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <form onSubmit={handleSubmit}>
      <DialogTitle id="form-dialog-title">Add a New Item To Our Database</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Looks like this is a new item to our system, right? If so, let's get the basic info about this item real quick!
        </DialogContentText>
        {/* <LabelText>Please provide the item name:</LabelText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          value={dialogValue.name}
          onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
          label="Name"
          type="text"
          fullWidth
        />
        <LabelText>Please provide the full description that you wish to have show up when you choose this item for an Estimate:</LabelText>
        <TextField
          margin="dense"
          id="description"
          value={dialogValue.description}
          onChange={(event) => setDialogValue({ ...dialogValue, description: event.target.value })}
          label="Description"
          type="text"
          fullWidth
        /> */}
      
      <GeneralInfoContainer>

      <FormControl fullWidth>

      <LabelText>Please choose the appropriate category and fill in the rest of the input boxes below:</LabelText>
      <CategoryContainer>
        {/* <ChooseCategoriesIncluded estimate={props.estimate} categories={list} submitOptions={handleScopeChoices} /> */}
      
          <TabsChoice>
              <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabOpenValue}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            {
              list.map((item, index) => {
                return (
                  <Tab key={index} label={item.name} {...a11yProps(index)} />
                )
              })
            }
            {/* <Tab label="Trees" {...a11yProps(0)} />
            <Tab label="Plants" {...a11yProps(1)} />
            <Tab label="Flowers" {...a11yProps(2)} />
            <Tab label="Irrigation" {...a11yProps(3)} />
            <Tab label="Lighting" {...a11yProps(4)} />
            <Tab label="Water Feature" {...a11yProps(5)} />
            <Tab label="Hardscape" {...a11yProps(6)} />
            <Tab label="Grass/Turf/Sod" {...a11yProps(7)} />
            <Tab label="Landscape" {...a11yProps(8)} />
            <Tab label="Misc. Supplies" {...a11yProps(9)} />
            <Tab label="Demo/Clean-Up" {...a11yProps(10)} />
            <Tab label="Equipment" {...a11yProps(11)} />
            <Tab label="Labor" {...a11yProps(12)} />
            <Tab label="Notes" {...a11yProps(13)} />
            <Tab label="Tax" {...a11yProps(14)} /> */}
          </Tabs>
              </TabsChoice>
            <TabDisplay>
            <TabPanel value={1} index={1} style={{width: '100%'}}>
              <NewLineItemInput  scope={dbItem} sizes={sizes} item={props.item} updateParent={handleNewInputInfo} />
          </TabPanel>
          {/* <TabPanel value={tabOpenValue} index={1}>
              <PlanInputItem department={'Plants'} item={props.item} updatePlantDetails={updatePlantDetails} />
          </TabPanel>
          <TabPanel value={tabOpenValue} index={2}>
              <PlanInputItem department={'Flowers'} item={props.item} updatePlantDetails={updatePlantDetails} />
          
          </TabPanel>
          <TabPanel value={tabOpenValue} index={3} style={{width: '100%'}}>
            <SupplyInventoryInputItem department={'Irrigation'} />
          </TabPanel>
          <TabPanel value={tabOpenValue} index={4}>
            Lighting
          </TabPanel>
          <TabPanel value={tabOpenValue} index={5}>
            Water Features
          
          </TabPanel>
          <TabPanel value={tabOpenValue} index={6}>
            Hardscape
          </TabPanel>
          <TabPanel value={tabOpenValue} index={7}>
            Grass/Turf/Sod
          </TabPanel>
          <TabPanel value={tabOpenValue} index={8}>
            Landscape
          </TabPanel>
          
          
          <TabPanel value={tabOpenValue} index={9}>
          Misc. Supplies
          
          </TabPanel>
          <TabPanel value={tabOpenValue} index={10}>
           Demo Clean=up
          </TabPanel>
          <TabPanel value={tabOpenValue} index={11}>
            Equipment
          
          </TabPanel>
          <TabPanel value={tabOpenValue} index={12}>
           Labor
          
          </TabPanel>
          <TabPanel value={tabOpenValue} index={13}>
           Notes
          
          </TabPanel>
          <TabPanel value={tabOpenValue} index={14}>
           Tax
          
          </TabPanel> */}
      
        </TabDisplay>
        </CategoryContainer>
      </FormControl>
        <LabelText>Please Choose The Suppliers That Carry This Item:</LabelText>
        <SuppliersAvailableComponent vendors={vendors} helperText="Pick Suppliers" label="Available At" placeholder="Where Can We Get This Item?" updateParent={handleAddSuppliers} />
        <SupplierPriceUpdateComponent sizes={sizes} />
        <LabelText>Please Provide Any Internal Notes About This Item:</LabelText>
        <TextField
          multiline
          id="notes"
          rows={4}
          fullWidth
          variant="outlined"
          value={state.notes}
          onChange={handleChangeInput}
          />
      </GeneralInfoContainer>
      {/* <InventoryPhotosComponent item={props.item} /> */}

        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button type="submit" color="primary">
          Add
        </Button>
      </DialogActions>
    </form>
   </Dialog>
  )
}
const mapStateToProps = (state) => {
  // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
  }
}

export default connect(mapStateToProps)(AddNewItemModal)
