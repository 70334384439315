import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  }
}));


const EditInvoiceDateModal = (props) => {
  // console.log('EditInvoiceDateModal ', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState({
    invoiceDate: props.invoiceDate
  })

  React.useEffect(() => {
    // console.log('Use Effect New Job')

    setState({...state, invoiceDate: props.invoiceDate})
  }, [props])

const handleClose = () => {
  // console.log('Closing New Job Modal')
  // setOpen(false)
  // setState({...state, jobName: '' })
  props.updateParent('Close')
}

const handleConfirm = (data) => {
  // console.log('CONFIRMED NEW JOB!!', data)
}
const handleSave = () => {
  props.updateParent('Save', state.invoiceDate)
}

const updateBillingAddress = (e, addy) => {
  // console.log('Update Billing Address: ', e)
  // console.log(addy)
  if (addy && addy.address) {
    setState({...state, billingAddress: addy})
  }
}
const updateJobSiteAddress = (e, addy) => {
  // console.log('Update Job Site Address: ', e)
  // console.log(addy)
  if (addy && addy.address) {
    setState({...state, jobSite: addy.id, jobSiteAddress: addy})
  }
}
const updateSalesRep = (e, rep) => {
  // console.log('Update Sales rep ', e)
  // console.log(rep)
  if (rep && rep._id) {
    setState({...state, salesRep: rep})
  }
}

const formatOption = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.address) {
    return `${opt.name} - ${opt.address} ${opt.city}`
  } else {
    return ''
  }
}

return (
  <div className={classes.root}>
    <Dialog open={props.open} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Modify Invoice Due Date</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Choose the Invoice Date
          </DialogContentText>
          <TextField
            id="date"
            label="Invoice Date"
            type="date"
            value={moment(state.invoiceDate).format('YYYY-MM-DD') } // "2017-05-24"}
            className={classes.textField}
            variant="outlined"
            onChange={ e => setState({...state, invoiceDate: e.target.value}) }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(EditInvoiceDateModal))