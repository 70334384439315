import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, TextField, MenuItem } from '@material-ui/core';
import { handleFocus } from '../../Common/index'

const initialEquipment = [
  { id: 1, name: 'Bobcat', duration: '', durationType: 'Days', pricePerDay: 600, pricePerHour: 110, total: 0, suggested: 0, override: false },
  // { id: 2, name: 'Tractor', days: '', hours: '', pricePerDay: 800, pricePerHour: 180, total: 0 },
  { id: 3, name: 'Small Crane', duration: '', durationType: 'Days', pricePerDay: 900, pricePerHour: 150, total: 0, suggested: 0, override: false },
  { id: 4, name: 'Big Crane', duration: '', durationType: 'Days', pricePerDay: 1200, pricePerHour: 200, total: 0, suggested: 0, override: false  },
];

const EquipmentTable = (props) => {
  const [equipment, setEquipment] = useState(initialEquipment);

  React.useEffect(() => {
    if (props.estimate && props.estimate.equipmentDetails && props.estimate.equipmentDetails.length) {
      setEquipment(props.estimate.equipmentDetails)
    } else {
      setEquipment(initialEquipment)
    }
  }, [props.estimate])
  
  const handleCheckboxChange = (index) => {
    const newEquipment = [...equipment];
    newEquipment[index].checked = !newEquipment[index].checked;
    if (!newEquipment[index].checked) newEquipment[index].total = 0
    setEquipment(newEquipment);
    props.updateParent(newEquipment)
  };

  const handleInputChange = (index, field, value) => {
    const newEquipment = [...equipment];
    newEquipment[index][field] = value;

    // Calculate total price
    const duration = newEquipment[index]['duration'] || 0;
    const durationType = newEquipment[index].durationType || 'Days'
    let totalPrice = 0
    if (durationType === 'Days') {
      totalPrice = duration * newEquipment[index].pricePerDay
    } else {
      totalPrice = duration * newEquipment[index].pricePerHour
    }
    newEquipment[index].total = totalPrice
    if (field === 'total') {
      newEquipment[index].total = value
      newEquipment[index].override = true
    } else {
      newEquipment[index].override = false
    }
    setEquipment(newEquipment);
    props.updateParent(newEquipment)
  };

  const handleChooseDuration = (index, field, value) => {
    console.log('Select index: %d -- %s   -- %s ', index, field, value)
  }

  const handleBlur = () => {
    props.handleBlur()
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox"></TableCell>
          <TableCell>Equipment</TableCell>
          <TableCell>Duration</TableCell>
          <TableCell>Hours/Day</TableCell>
          <TableCell>Price/Day</TableCell>
          <TableCell>Price/Hour</TableCell>
          <TableCell>Total Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {equipment.map((item, index) => (
          <TableRow key={item.id}>
            <TableCell padding="checkbox">
              <Checkbox
                checked={!!item.checked}
                onChange={() => handleCheckboxChange(index)}
              />
            </TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>
              {item.checked && (
                <TextField
                  type="number"
                  variant="outlined"
                  value={item.duration}
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onChange={(e) => handleInputChange(index, 'duration', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </TableCell>
            <TableCell>
              {item.checked && (
                <TextField
                  type="number"
                  variant="outlined"
                  value={item.durationType}
                  onBlur={handleBlur}
                  select
                  onChange={(e) => handleInputChange(index, 'durationType', e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="Hours">Hours</MenuItem>
                  <MenuItem value="Days">Days</MenuItem>
                </TextField>
              )}
            </TableCell>
            <TableCell>${item.pricePerDay}</TableCell>
            <TableCell>${item.pricePerHour}</TableCell>
            <TableCell>
            {item.checked ? (
              <TextField
                type="number"
                variant="outlined"
                value={item.total}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={(e) => handleInputChange(index, 'total', e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
                ) : `$${item.total}`

              }
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default EquipmentTable;
