import React from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import SearchIcon from '@material-ui/icons/Search';
import { InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  prettyText: {
    fontSize: '1.5em',
    color: '#56b349'
  }
}));

const PostPaymentsModule = (props) => {
  // console.log('Got our PostPaymentsModule', props)

const [open, setOpen] = React.useState(false);
const [modalOpen, setModalOpen] = React.useState(props.open);
const [invoices, setInvoices] = React.useState([])
const [range, setRange] = React.useState(30);
const [startDate, setStartDate] = React.useState(moment(new Date()).subtract(30, 'days'));
const [endDate, setEndDate] = React.useState(new Date());
const classes = useStyles();
const [loading, setLoading] = React.useState(false)
const [totalCharge, setTotalCharge] = React.useState(0)
const [invoicesToPay, setInvoicesToPay] = React.useState([])
const [paymentTotal, setPaymentTotal] = React.useState(0)
const [paymentNumber, setPaymentNumber] = React.useState('')
const [state, setState] = React.useState({
  invoices: []
})
const [searchValue, setSearchValue] = React.useState('')
const searchRef = React.useRef(null)
const [list, setList] = React.useState([])
const [selected, setSelected] = React.useState({})
const [client, setClient] = React.useState({})
const [clientMatches, setClientMatches] = React.useState(0)
const [invoiceMatches, setInvoiceMatches] = React.useState(0)
const [paymentAmountOverride, setPaymentAmountOverride] = React.useState(false)
const cancel = React.useRef(null)

const getInvoicesForClient = async (client) => {
  setLoading(true)
  // console.log('Get invoices for this guy: ', client)
  setPaymentAmountOverride(false)
  const response = await axios.get(`${props.url}/api/invoice/getAllClientInvoices?due=true&id=${client._id}`)
   // console.log('Invoices!!', response)
   if (response && response.data) {
    let invoices = response.data
    if (invoices && invoices.length < 1) {
      props.notification({
        type: 'warning',
        title: 'We Ran Into A Problem!',
        message: 'No Invoices Found.'
      })
    }
    setInvoices(invoices)
    setList(response.data.clients)
    setState({...state, invoices: invoices})
    setLoading(false)
   } else {
     // console.log('Something went wrong')
     props.notification({
      type: 'warning',
      title: 'We Ran Into A Problem!',
      message: 'An Error Occurred Trying to Get Invoices for this customer. E153.'
    })
   }
}

// React.useEffect(() => {
//   // console.log('Post Pyaments - g')
// }, [props])

const handleChange = (event) => {
  setRange(event.target.value);
  let newStartDate = moment().subtract(event.target.value, 'days')
  // console.log('New start Date', newStartDate)
  setStartDate(newStartDate)
  setOpen(false);
};

const handleClose = () => {
  // setOpen(false);
  setInvoicesToPay([])
  setTotalCharge(0)
  // props.onUpdate()
  setPaymentNumber('')
  setClient({})
  setPaymentTotal(0)
  setInvoices([])
  setList([])
  setState({...state, invoices: []})
  props.closeModal()
}

const handleOpen = () => {
  setOpen(true);
};

const handleSubmit = async () => {
  // console.log('Submit this bulk Payment!!')

  let numberToPay = invoicesToPay.length
  let message = `Are you sure you wish to pay these ${numberToPay} invoices for ${currencyFormat(totalCharge)}?`
  let leftOverAmount = 0
  if (paymentTotal > totalCharge) {
    leftOverAmount = paymentTotal - totalCharge
    message += ` This will leave a left over amount of ${currencyFormat(leftOverAmount)} which will be turned into a Deposit.`
  }

  if (window.confirm(message)) {
    // let checkNumber = window.prompt('Please Enter the Check Number:')
    // console.log('CHeck Number: ', checkNumber)
    // if (checkNumber === null) {
    //   // console.log('Cancelled out')
    //   setLoading(false)
    // } else {
      setLoading(true)
      let data = {
        invoicesToPay: invoicesToPay,
        user: props.user,
        checkNumber: paymentNumber,
        client: client,
        paymentNumber: paymentNumber,
        paymentTotal: paymentTotal,
        prettyTotal: currencyFormat(paymentTotal),
        totalCharge: totalCharge
      }
      // console.log('Data to send: ', data)
      const response = await axios.post(`${props.url}/api/payment/makeBulkPayment`, data)
      // console.log('Paid Bulk Invoices!!', response)
      setLoading(false)
      let invoices = state.invoices
      // console.log('Invoices before splice', invoices)
      for (const item of invoicesToPay) {
        let index = invoices.findIndex(inv => inv._id.toString() === item._id.toString())
        if (index > -1) {
          invoices.splice(index, 1)
        }
      }
      // console.log('Invoices left: ', invoices)
      setTotalCharge(0)
      setInvoicesToPay([])
      setPaymentTotal(0)
      setPaymentNumber(0)
      setState({...state, invoices: invoices })
      if (response && response.data) {
        props.notification({
          type: 'success',
          title: 'Payments Made!',
          message: 'Payments Were Created and Invoices Were Paid!'
        })
        // handleClose()
        let audio = new Audio('Cha_Ching.mp3');
        audio.play();
      } else {
        // console.log('Something went wrong')
        props.notification({
         type: 'warning',
         title: 'We Ran Into A Problem!',
         message: 'An Error Occurred E1234.'
       })
      }
    // }
  }
}

// const handleCloseModal = () => {
//   props.closeModal()
// }
const handleClear = () => {
  setInvoicesToPay([])
  setPaymentTotal(0)
  let newInvoices = state.invoices
  for (const inv of newInvoices) {
    inv.selected = false
  }
  setState({...state, invoices: newInvoices })
  setTotalCharge(0)
}

const handleSelectClient = (client) => {
  // console.log('Select: ', client)
  // console.log(client.selected)
  searchRef.current.value = ''
  setList([])
  setClientMatches(0)
  setClient(client)
  getInvoicesForClient(client)
}

const handleSelect = (invoice) => {
  // console.log('Select: ', invoice)
  // console.log('Payment Total ', paymentTotal)
  let totalSelected = totalCharge
  // console.log('Total Payment Amount', totalCharge)
  let selectedInvoices = invoicesToPay
  // console.log('Selected Invoices: ', selectedInvoices)
  let ourPaymentAmount = 0
  let humanEnterPaymentAmount = paymentAmountOverride
  if (invoice.selected) {
    invoice.selected = false
    if (invoice.partial) {
      totalSelected = totalSelected - invoice.partialPaymentAmount
      invoice.partial = false
      invoice.partialPaymentAmount = null
    } else {
      totalSelected = totalSelected - invoice.balance
    }
    // console.log('New Total Charge', totalSelected)
    if (totalSelected < 0.10) totalSelected = 0
    let index = selectedInvoices.findIndex(item =>  (invoice._id === item._id ));
    // console.log('Index: ', index)
    if (index > -1) selectedInvoices.splice(index, 1)
    // console.log('Selected Invoices: ', selectedInvoices)

    // let invoiceSelected = selectedInvoices[index]
    // selectedInvoices = selectedInvoices.splice(index, 1)
    if (selectedInvoices && selectedInvoices.length > 0) {
      // console.log('Deal with this')
      if (humanEnterPaymentAmount) {
        // Do nothing - human entered the amount
      } else {
        let newTotal = 0
        selectedInvoices.map(item => { newTotal = parseFloat(newTotal - 0 + item.balance).toFixed(2) })
        setPaymentTotal(newTotal)
      }
    } else {
      if (humanEnterPaymentAmount) {
      } else {
        setPaymentTotal(0)
        ourPaymentAmount = 0
      }
    }
    setInvoicesToPay(selectedInvoices)
    // console.log('Total Charge: ', totalSelected)
    setTotalCharge(totalSelected)
  } else {
    // console.log('Choose invoice ', invoice.balance)
    if (humanEnterPaymentAmount) {
      if (paymentTotal === 0) {
        let newTotal = parseFloat(invoice.balance).toFixed(2)
        if (selectedInvoices && selectedInvoices.length > 0) {
          selectedInvoices.map(item => { newTotal = parseFloat(newTotal - 0 + item.balance).toFixed(2) })
        }
        setPaymentTotal(newTotal)
        ourPaymentAmount = newTotal
      } else {
        ourPaymentAmount = paymentTotal
      }
    } else {
      let newTotal = parseFloat(invoice.balance).toFixed(2)
      if (selectedInvoices && selectedInvoices.length > 0) {
        selectedInvoices.map(item => { newTotal = parseFloat(newTotal - 0 + item.balance).toFixed(2) })
      } else {
        // setPaymentTotal(0)
      }
      setPaymentTotal(newTotal)
      ourPaymentAmount = newTotal
    }
  //   setPaymentTotal(invoice.balance)
  //   ourPaymentAmount = invoice.balance
  // }
  // if (paymentTotal && paymentTotal > 0) {
  //   // Payment Total was inputted by human
  //   ourPaymentAmount = paymentTotal
  // } else {
  //   setPaymentTotal(invoice.balance)
  //   ourPaymentAmount = invoice.balance
  // }

  let leftOver = ourPaymentAmount - totalCharge
  // console.log('Total Amount Charged %d -(new thing %d) total Payment Amount %d--- left over %d', totalCharge, ourPaymentAmount, paymentTotal, leftOver)
    if (leftOver > 0) {
      // console.log('We have money!!!')
      invoice.selected = true
      totalSelected = totalSelected - 0 + invoice.balance
      if (totalSelected < 0.10) totalSelected = 0
      selectedInvoices.push(invoice)
      if (totalSelected > ourPaymentAmount) {
        // Partial Payment on this one...unless available amount is $0
        if (ourPaymentAmount - totalCharge > 0) {
          let partialPaymentAmount = ourPaymentAmount - totalCharge
          // console.log('This gives us a partial payment amount of ', partialPaymentAmount)
          totalSelected = totalCharge - 0 + partialPaymentAmount
            // setInvoices(invoices)
            // console.log('Invoices: ', invoices)
            // console.log('Invoices to pay: ', invoicesToPay)
            invoice.selected = true
            invoice.partial = true
            invoice.partialPaymentAmount = partialPaymentAmount
            // totalSelected = totalSelected - 0 + invoice.balance
            // selectedInvoices.push(invoice)
            setInvoicesToPay(selectedInvoices)
            // console.log('Total Charge: ', totalSelected)
            setTotalCharge(totalSelected)
        } else {
          props.notification({
            type: 'warning',
            title: 'We Cannot Do This...',
            message: `You Have Spent All of Your Money`
          })
        }
    
            // let newInvoices = invoices
            // let index = newInvoices.findIndex(item => item._id === invoice._id)
            // console.log('Index of our selected item: ', index)
            // if (index > -1) newInvoices[index]['selected'] = false
            // console.log('New Invocie: ', newInvoices)
            // setState({...state, invoices: newInvoices})
    
        // setLoading(false)
      } else {
        setInvoicesToPay(selectedInvoices)
        // console.log('Total Charge: ', totalSelected)
        setTotalCharge(totalSelected)
        // console.log(invoice.selected)
        // console.log('Invoiecs to Pay: ', selectedInvoices)
      }
    } else {
      // console.log('You got now money!')
      props.notification({
        type: 'warning',
        title: 'We Cannot Do This...',
        message: `You Have Spent All of Your Money`
      })
    }
  }
}

function currencyFormat(num) {
  // console.log('Currencyformat: ', num)
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
const updateSearch = async (val) => {
  // console.log('Search Value: ', val)

}

const source = axios.CancelToken.source();

const handleSearch = async () => {
  // const cancelToken = axios.CancelToken.source()
  // console.log('Cancel?', cancel)
  if (cancel.current !== null) {
    // console.log('Cnacel this bitch')
    // cancel()
    source.cancel()
  }
  if (loading) source.cancel()
  // cancel = 'adfas'
  // console.log('Hnadle search', searchRef.current.value)
  // console.log(searchRef)
  setPaymentAmountOverride(false)
  setPaymentTotal(0)
  setInvoicesToPay([])
  setTotalCharge(0)
  setPaymentNumber('')
  setInvoices([])
  setState({...state, invoices: []})
  setClient({})
  if (searchRef.current.value === '') {
    // console.log('Clear')
    setClientMatches(0)
    setClient({})
    setList([])
    return null
  }
  if (searchRef.current.value.length < 3) {
    setClient({})
    setList([])
    return null
  }
  setLoading(true)
  setClient({})
  cancel.current = source.token
  axios.get(`${props.url}/api/customer/postPaymentsSearch?val=${searchRef.current.value}`, {
    cancelToken: source.token
  }).then(async (response) => {


  //  // console.log('Post payments search!!', response)
   if (response && response.data) {
    //  // console.log('matches', response.data)
    let numberOfMatchingClients = response.data.clientMatches
    setClientMatches(numberOfMatchingClients)
    setInvoiceMatches(response.data.invoicesMatching)
    let invoices = response.data.invoices
    // console.log(invoices)
    cancel.current = null
    // let clients = response.data.clients
    // // console.log('Clients: ', clients)
    // let contacts = response.data.contacts
    // // let listToSet = clients
    // // console.log('Contacts: ', contacts)
    // let newClients = []
    // invoices.map(item => {
    //   item.client.invoiceNumber = item.invoiceNumber
    //   item.client.jobName = item.jobName
    //   item.client.balance = item.balance
    //   item.client.amount = item.amount
    //   item.client.jobNumber = item.jobNumber
    //   if (clients && clients.length) {
    //     let index = clients.findIndex(ourClient => (ourClient._id.toString() === item.client._id.toString()))
    //     if (index > -1) {
    //       // Skip
    //       let theClient = clients[index]
    //       theClient.invoiceNumber = item.invoiceNumber
    //       theClient.jobName = item.jobName
    //       theClient.balance = item.balance
    //       theClient.amount = item.amount
    //       theClient.jobNumber = item.jobNumber
    //     } else {
    //       newClients.push(item.client)
    //     }
    //   } else {
    //     // let index = invoices.findIndex(ourClient => (ourClient._id.toString() === item.client._id.toString()))
    //     newClients.push(item.client)
    //   }
    // })
    // let newClientsClean = new Set(newClients)
    // console.log('Cliean: ', newClientsClean)
    // clients = clients.concat(newClientsClean)
    if (response.data.list) setList(response.data.list)
    // setInvoices(invoices)
    // setState({...state, invoices: invoices})
    setLoading(false)
   }
  }).catch(e => {
    // console.log('ERR: ', e)
    setLoading(false)
    if (axios.isCancel(e)) {
      // console.log('Cancellation')
    } else {
      props.notification({
        type: 'warning',
        title: 'We Ran Into A Problem!',
        message: `An Error Occurred Trying to Find Customers That Match./n${JSON.stringify(e)}`
      })
    }
  })
}

const handleKeyPress = (e) => {
  if (e.keyCode == 13) handleSearch()
}

const handleFocus = (event) => event.target.select();

const handlePaymentUpdate = (e) => {
  setPaymentAmountOverride(true)
  setPaymentTotal(Number(e.target.value))
  // console.log('SEt Payment Amount: ', e.target.value)
}

const PaymentDetails = ({ invoices }) => {
  // if (paymentTotal && paymentNumber) {
   return (
    <>
    
    <Grid item lg={12} xs={12} className={classes.prettyText}>
        { totalCharge > 0 ? `Total Charge: ${currencyFormat(totalCharge)}` : ''  }
      </Grid>
        <Grid item lg={12} xs={12}>
          <h3>Please choose the invoices you'd like to cover with this payment:</h3>
      </Grid>
      <Grid item lg={12} xs={12}>
        <div>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  Invoice Number
                </TableCell>
                <TableCell>
                  Invoice Date
                </TableCell>
                <TableCell>
                  Job Name (Number)
                </TableCell>
                <TableCell>
                  Invoice Amount
                </TableCell>
                <TableCell>
                  Total Due
                </TableCell>
                <TableCell>
                  Invoice Balance
                </TableCell>
               
                <TableCell>
                  Select
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {state.invoices.map(invoice => (
                  <TableRow key={invoice._id}>
                  <TableCell>
                    {invoice.invoiceNumber}
                  </TableCell>
                  <TableCell>
                    {moment(invoice.created_at).format('MM/DD/YYYY')}
                  </TableCell>
                  <TableCell>
                    {invoice.jobName} ({invoice.jobNumber})
                  </TableCell>
                  <TableCell>
                    {currencyFormat(invoice.amount)}
                  </TableCell>
                  <TableCell>
                  {currencyFormat(invoice.totalDue)}
                  </TableCell>
                  <TableCell>
                  {currencyFormat(invoice.balance)}
                  </TableCell>
                 
                  <TableCell>
                      <FormControlLabel
                        control={<Checkbox checked={invoice.selected} onChange={ e => handleSelect(invoice) } name="Selected" />}
                        label={invoice.selected ? 'Included' : 'Select'}
                      />
                  </TableCell>
                </TableRow>
              ))}
              {
                state.invoices.length < 1 ? (
                  <TableRow >
                      No Invoices Found
                  </TableRow> 
                ) : ''
              }
            </TableBody>
          </Table>

        </div>
      </Grid>
      </>
    )
  // } else {
  //   return null
  // }
}
  return (
    <div>
        <Dialog
          // fullScreen={true}
          maxWidth='lg'
          fullWidth
          open={props.open}
          onClose={handleClose}
          // PaperProps={{
          //   style: {
          //     overflow: 'hidden',
          //     height: '500px'
          //   }}}
          aria-labelledby="responsive-dialog-title"
          >
          <DialogTitle id="responsive-dialog-title">Post Payment(s)</DialogTitle>
          <DialogContent>
          <Grid container spacing={1}>
            <Grid item lg={12}>
                  Please Enter the Customer Name or Invoice Number:
            </Grid>
            <Grid item lg={6}>
              <TextField
                label="Payor Information"
                variant="outlined"
                type="text"
                fullWidth
                helperText="Enter Customer Name/Address/Email/Job Name/Job Number/Invoice Number/Amount From Check"
                // disabled={state.isInvoiced || state.isComplete}
                // value={searchValue}
                onFocus={handleFocus}
                onChange={handleSearch}
                inputRef={searchRef}
                // onClick={ handleClear }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" color="primary" onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={handleKeyPress}
                // onChange={ e => updateSearch(e.target.value) }
                // defaultValue="Default Value"
              />
              {/* <Autocomplete
              id={`taxLocale`}
              fullWidth
              options={list}
              // freeSolo
              // autoSelect
              multiple
              value={state.chosenItem}
              disableCloseOnSelect
              style={{marginTop: 25}}
              getOptionLabel={(option) => option._id}
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option._id}
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Tax Locale(s)" inputRef={searchRef} onChange={handleSearch} placeholder="Taxable Area(s)" />
              )}
            /> */}
            </Grid>
            <Grid item lg={6}>
              {/* <TextField
                label="Invoice Number"
                variant="outlined"
                type="text"
                fullWidth
                helperText="Enter The Invoice Number"
                // disabled={state.isInvoiced || state.isComplete}
                // value={searchValue}
                inputRef={searchRef}
                // onClick={ handleClear }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" color="primary" onClick={e => handleSearch('invoice')}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={e => handleKeyPress('invoice')}
                // onChange={ e => updateSearch(e.target.value) }
                // defaultValue="Default Value"
              /> */}
              
            </Grid>
      <Grid item lg={12} xs={12} className={classes.prettyText}>
            {(client && client._id) ? `${client.displayName} (${client.agId})` : '' }
      </Grid>
      <Grid item lg={12} xs={12}>
       
      {
        (client && client._id) ? '' : (
          
        

           <div>
       <Grid item lg={12} sm={12} className={classes.prettyText}>
              {
                clientMatches > 25 ? `${clientMatches} clients matched that search. Please narrow it down.` : ''
              }
              {
                (clientMatches < 25 && clientMatches > 0) ? `${clientMatches} clients matched that search.` : ''
              }
              {
                (invoiceMatches < 25 && invoiceMatches > 0) ? `${invoiceMatches} Invoices matched that search.` : ''
              }
            </Grid>
     { list.length ? (
        <div style={{ border: 'solid 3px #bbb', borderRadius: 15, padding: 10, marginBottom: 10}}>
        <Grid container spacing={2}>
          <h3>Please choose the customer:</h3>
     
      <Grid item lg={12} xs={12}>
        <div style={{maxHeight: 450, overflowY: 'scroll'}} >
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
              <TableCell>
                  Select
                </TableCell>
                <TableCell>
                  Client Name
                </TableCell>
                <TableCell>
                  Other Information
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { (list && list.length) ? list.map(client => (
                  <TableRow key={client._id}>
                    <TableCell>
                      <FormControlLabel
                        control={<Checkbox checked={client.selected} onChange={ e => handleSelectClient(client) } name="Selected" />}
                        label={client.selected ? 'Included' : 'Select'}
                      />
                  </TableCell>
                  <TableCell>
                    {client.displayName} ({client.agId})
                  </TableCell>
                  {/* <TableCell>
                    {client.defaultJobSiteAddress} ({client.address})
                  </TableCell> */}
                  <TableCell>
                    {client.invoiceNumber ? `Invoice Number: ${client.invoiceNumber} (Amt: ${currencyFormat(client.amount)} - Bal: ${currencyFormat(client.balance)})` : `${client.defaultJobSiteAddress} (${client.address})` }
                    {client.jobName ? `  -  Job Number: ${client.jobNumber} Job Name: ${client.jobName}` : ''}
                  </TableCell>
                  
                </TableRow>
              )) : null}
            </TableBody>
          </Table>

        </div>
      </Grid>
      </Grid>
      </div>
      ) : null }

            </div>
      )}
             </Grid>
            <Grid item lg={6}>
              <Grid container spacing={2}>
                <Grid item lg={12}>
                  Please Enter the Total Amount of the Payment:
                </Grid>
                <Grid item lg={12}>
                  <TextField
                    label="Payment Amount"
                    variant="outlined"
                    type="number" min="0" step="0.01"
                    fullWidth
                    helperText="Amount of the Entire Payment"
                    // disabled={state.isInvoiced || state.isComplete}
                    value= {paymentTotal}
                    onFocus={handleFocus}
                    // onClick={ handleClear }
                    onChange={ handlePaymentUpdate }
                    // defaultValue="Default Value"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6}>
                
                <Grid container spacing={2}>
                  <Grid item lg={12}>
                    Please Enter the Check Number/PO For this Payment:
                  </Grid>
                  <Grid item lg={12}>
                    <TextField
                      label="Payment ID"
                      helperText="Check Number/Direct Deposit ID"
                      variant="outlined"
                      type="Text"
                      fullWidth
                      onFocus={handleFocus}
                      // disabled={state.isInvoiced || state.isComplete}
                      value= {paymentNumber}
                      onChange={ e => setPaymentNumber(e.target.value) }
                      // defaultValue="Default Value"
                    />
                  </Grid>
                </Grid>
              </Grid>
          </Grid>

            <Grid container spacing={2}>
              <PaymentDetails invoices={invoices} />
            </Grid>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit()} color="primary" disabled={!paymentNumber || !paymentTotal}>
            { loading ? <CircularProgress color="primary" size={ 24 } />  : '' } Submit
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
    </div>
  )
 

}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(PostPaymentsModule))