import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { MenuItem } from "@material-ui/core";
import { fade, makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import AddCommentIcon from '@material-ui/icons/AddComment';


const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  textSaveLineBreaks: {
    whiteSpace: 'pre-line'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

const defaultValues = {
  equipmentType: 'None',
  equipmentDays: 0,
  laborDays: 0,
  crewSize: 0,
  supervisionHours: 0,
  techHours: 0,
  sprayTechHours: 0,
  operatorHours: 0,
  smallCraneDays: 0,
  bigCraneDays: 0,
  bobcatDays: 0
}
const handleFocus = (event) => event.target.select()

function LaborEquipmentSupervision(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(defaultValues)

  React.useEffect(() => {
    console.log('Labor Props changed 121', props)
    if (props.laborDetails) {
      console.log('Set labor details', props)
      let updatedItem = { ...defaultValues, ...props.laborDetails }
      console.log('Updated item:', updatedItem)
      // if (!updatedItem.hasOwnProperty('bigCraneDays')) updatedItem.bigCraneDays = 0
      setState({...state, ...updatedItem })
    } else {
      setState(defaultValues)
    }
  }, [props])



  // const handleStoreOurStuff = (data) => {
  // // console.log('Save our stuff!', data)
  //   let newState = {...state}
  //   newState[data.item] = data.value
  // // console.log('State to update to:', newState)
  //   setState({...state, ...newState })
  //   props.updateParent(newState)
  // }

  const handleChange = (e) => {
    let newState = { ...state }
    newState[e.target.id] = e.target.value
    setState({...state, ...newState})
    console.log('Update labor NOW ', newState)
    props.updateParent(newState)
  }
   return (
   
       <div style={{
           border: '1px solid #ccc', borderRadius: 5, margin: 10, padding: 10, backgroundColor: '#ccc', color: '#444', fontSize: '1.3em',
        }}>
           <div style={{  textAlign: 'center'  }}>Labor Details</div>
 
      <Grid container style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', flexWrap: 'wrap' }}>
        <Grid item lg={4} md={4} sm={6} xs={12} style={{ padding: 10,backgroundColor: '#fff', border: '1px solid #bbb', borderRadius: 8 }}>
          <TextField
              label="Labor Hours"
              variant="outlined"
              type="number"
              value={state.laborHours || 0}
              id="laborHours"
              onChange={handleChange}
              // onChange={e=>setState({...state, laborHours: e.target.value })}
              fullWidth
              onFocus={handleFocus}
              style={{ flexBasis: 'calc(75% - 5px)', marginRight: '5px' }}
              helperText="How many total Labor Hours do we estimate is needed to complete this service?"
              />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12} style={{ padding: 10,  backgroundColor: '#fff', border: '1px solid #bbb', borderRadius: 8 }}>
          <TextField
            label="Tech Hours"
            variant="outlined"
            type="number"
            id="techHours"
            onChange={handleChange}
            style={{ flexBasis: 'calc(75% - 5px)', marginRight: '5px' }}
            value={state.techHours || 0}
            // onChange={e=>setState({...state, techHours: e.target.value })}
            fullWidth
            onFocus={handleFocus}
            helperText="How many total Tech Hours do we estimate is needed to complete this service?"
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12} style={{ padding: 10,  backgroundColor: '#fff', border: '1px solid #bbb', borderRadius: 8 }}>
          <TextField
            label="Spray Tech Hours"
            variant="outlined"
            type="number"
            style={{ flexBasis: 'calc(75% - 5px)', marginRight: '5px' }}
            value={state.sprayTechHours || 0}
            // onChange={e=>setState({...state, sprayTechHours: e.target.value })}
            fullWidth
            onFocus={handleFocus}
            id="sprayTechHours"
            onChange={handleChange}
            helperText="How many total Spray Tech Hours do we estimate is needed to complete this service?"
          />
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={12} style={{ padding: 10, backgroundColor: '#fff', border: '1px solid #bbb', borderRadius: 8 }}>
           <TextField
            label="Operator Hours"
            variant="outlined"
            type="number"
            value={state.operatorHours || 0}
            // onChange={e=>setState({...state, operatorHours: e.target.value })}
            fullWidth
            onFocus={handleFocus}
            id="operatorHours"
            onChange={handleChange}
            style={{ flexBasis: 'calc(75% - 5px)', marginRight: '5px' }}
            helperText="How many total Operator Hours do we estimate is needed to complete this service?"
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} style={{ padding: 10, backgroundColor: '#fff', border: '1px solid #bbb', borderRadius: 8 }}>
             <TextField
            label="Supervisor Hours"
            variant="outlined"
            type="number"
            value={state.supervisorHours || 0}
            id="supervisorHours"
            onChange={handleChange}
            // onChange={e=>setState({...state, supervisorHours: e.target.value })}
            fullWidth
            onFocus={handleFocus}
            style={{ flexBasis: 'calc(75% - 5px)', marginRight: '5px' }}
            helperText="How many total Supervisor Hours do we estimate is needed to complete this service?"
            />
          </Grid>
      </Grid>
      <div style={{  textAlign: 'center'  }}>Equipment Details</div>
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'space-between', padding: '10px', flexWrap: 'wrap' }}>
          <Grid item lg={4} md={4} sm={6} xs={12}  style={{ padding: 10, backgroundColor: '#fff', border: '1px solid #bbb', borderRadius: 8 }}>
            <TextField
              label="Bobcat Days"
              variant="outlined"
              type="number"
              value={state.bobcatDays || 0}
              // onChange={e=>setState({...state, bobcatDays: e.target.value })}
              fullWidth
              id="bobcatDays"
              onChange={handleChange}
              onFocus={handleFocus}
              helperText="How many total days do we estimate the bobcat is needed to complete this service?"
              />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12}  style={{ padding: 10, backgroundColor: '#fff', border: '1px solid #bbb', borderRadius: 8 }}>
            <TextField
              label="Small Crane Days"
              variant="outlined"
              type="number"
              id="smallCraneDays"
              onChange={handleChange}
              value={state.smallCraneDays || 0}
              fullWidth
              onFocus={handleFocus}
              helperText="How many total days do we estimate the small crane is needed to complete this service?"
              />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={12} style={{ padding: 10, backgroundColor: '#fff', border: '1px solid #bbb', borderRadius: 8 }}>
            <TextField
              label="Big Crane Days"
              variant="outlined"
              type="number"
              id="bigCraneDays"
              value={state.bigCraneDays || 0}
              onChange={handleChange}
              fullWidth
              onFocus={handleFocus}
              helperText="How many total days do we estimate the 90 Ton crane is needed to complete this service?"
              />
          </Grid>
        </Grid>
    </div>
  );
}

const EquipmentConfiguration = (props) => {
  return (
    <div style={{ border: '1px solid #ccc', borderRadius: 5, margin: 10, padding: 10, backgroundColor: '#fff' }}>
      <EquipmentType updateParent={props.updateParent}/>
      <EquipmentHours updateParent={props.updateParent}/>
    </div>
  )
}

const EquipmentHours = (props) => {
  const handleUpdateCrewHours  = (e) => {
    props.updateParent({ item: 'equipmentDays', value: e.target.value }) 
  }

  return (
    <div >
      <TextField
        fullWidth
        size="small"
        label="Equipment Days"
        type="number"
        onChange={handleUpdateCrewHours}
        helperText="How Many Days Do We Need The Equipment?"
      />
    </div>
  )
}
const EquipmentType = (props) => {
  const handleUpdateCrewSize = (e) => {
  // console.log('EquipmentType', e.target.value)
    props.updateParent({ item: 'equipmentType', value: e.target.value }) 
 }
  return (
    <div>
      <TextField
        fullWidth
        size="small"
        label="Equipment Type"
        select
        onChange={handleUpdateCrewSize}
        >
          <MenuItem value={'None'}>None</MenuItem>
          <MenuItem value={'Bobcat'}>Bobcat</MenuItem>
          <MenuItem value={'Small Crane'}>Small Crane</MenuItem>
          <MenuItem value={'Big Crane'}>Big Crane</MenuItem>
        </TextField>
    </div>
  )
}

const SupHours = (props) => {
  const handleUpdateCrewHours  = (e) => {
    props.updateParent({ item: 'supervisionHours', value: e.target.value })
  }

  return (
    <div style={{ border: '1px solid #ccc', borderRadius: 5, margin: 10, padding: 10, backgroundColor: '#fff' }}>
      <TextField
        fullWidth
        size="small"
        label="Supervision Hours"
        type="number"
        onChange={handleUpdateCrewHours}
        helperText="How Many Hours Do We Expect To Need On-Site Supervision?"
      />
    </div>
  )
}

const TechHours = (props) => {
  const handleUpdateCrewHours  = (e) => {
    props.updateParent({ item: 'techHours', value: e.target.value })
  }

  return (
    <div style={{ border: '1px solid #ccc', borderRadius: 5, margin: 10, padding: 10, backgroundColor: '#fff' }}>
      <TextField
        fullWidth
        size="small"
        label="Tech Hours"
        type="number"
        onChange={handleUpdateCrewHours}
        helperText="How Many Hours Do We Expect To Need Techs On-Site?"
      />
    </div>
  )
}

const ManHoursConfiguration = (props) => {
  const saveOurStuff = (data) => {
    props.updateParent(data)
  }
  return (
    <div style={{ border: '1px solid #ccc', borderRadius: 5, margin: 10, padding: 10, backgroundColor: '#fff' }}>
      <CrewSize updateParent={saveOurStuff} />
      <CrewHours updateParent={saveOurStuff} />
    </div>
  )
}

const CrewHours = (props) => {
  const handleUpdateCrewHours  = (e) => {
    props.updateParent({ item: 'laborDays', value: e.target.value })
  }

  return (
    <div >
      <TextField
        fullWidth
        size="small"
        label="Crew Days"
        type="number"
        onChange={handleUpdateCrewHours}
        helperText="How Many Days Do We Expect It To Take The Crew?"
      />
    </div>
  )
}
const CrewSize = (props) => {
  const handleUpdateCrewSize = (e) => {
  // console.log('Set Crew size:', e.target.value)
    props.updateParent({ item: 'crewSize', value: e.target.value })
  }
  return (
    <div>
      <TextField
        fullWidth
        label="Crew Size"
        select
        size="small"
        onChange={handleUpdateCrewSize}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
        </TextField>
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(LaborEquipmentSupervision))