import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PDFReader, MobilePDFReader  } from 'react-read-pdf';
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: 20,
    height: '100vh'
  },
  formControl: {
    margin: theme.spacing(1),
  },
  pdfContainer: {
    width: '80%',
    height: '90vh',
    border: '1px solid #ccc',
    backgroundColor: 'pink'
  },
  chunksContainer: {
    marginTop: theme.spacing(2),
  },
}));

const PdfSplitter = ({ pdfUrl }) => {
  const classes = useStyles();
  const [selectedChunks, setSelectedChunks] = useState([]);
  const [startPage, setStartPage] = useState('');
  const [endPage, setEndPage] = useState('');
  const [pdf, setPDF] = useState({})

  React.useEffect(() => {
    let isMounted = true
    const fetchPDF = async (url) => {
    // console.log('FETCH: ', url)
      let cleanURL = ''
      if (url.includes('https')) {
        cleanURL = url
      } else {
        cleanURL = `https://${url}`
      }
    // console.log('CLEAN: ', cleanURL)
      await axios.get(cleanURL, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/pdf, application-octet-streatm'
        },
        responseType: 'blob'
      }).then((response) => {
      // }).then(response => response.arrayBuffer())
      //   .then(blob => {
      //   // console.log('Blob: ', blob)
          // let body = response.data
          // let base64Version = Buffer.from(body).toString('base64')
          // console.log('Base 64', base64Version)
          // // let blob = response.data.blob()
          // let newStuff = 'data:application/pdf;base64,' + base64Version
          // console.log(newStuff)
          // console.log('response:', response)
          let newBlob = new Blob([response.data], {type: "application/pdf"})
          // console.log(newBlob)
          const data = window.URL.createObjectURL(newBlob);
          // console.log(data)
          // var link = document.createElement('a');
          // link.href = data;
          // link.download="file.pdf";
          // link.click();
          setPDF(data)
        })
      // console.log(response)
      // if (response && response.data) {
      // }
    }
    if (pdfUrl) {
      fetchPDF(pdfUrl)
    }

    return () => {
      isMounted = false
    }
  }, [pdfUrl])

  const handleAddChunk = () => {
    if (startPage && endPage && parseInt(startPage) <= parseInt(endPage)) {
      setSelectedChunks([...selectedChunks, { start: parseInt(startPage), end: parseInt(endPage) }]);
      setStartPage('');
      setEndPage('');
    }
  };

  const handleSubmit = () => {
    // Handle submit logic here, send selectedChunks to the backend
    console.log(selectedChunks);
  };
  
// return ( <PDFReader url={pdfUrl} page={5} /> )
  return (
    <Container className={classes.root}>
      <Typography variant="h4" gutterBottom>
        PDF Splitter
      </Typography>
    
      <Grid container spacing={2} alignItems="center" className={classes.chunksContainer}>
        <Grid item>
          <TextField
            label="Start Page"
            type="number"
            value={startPage}
            onChange={(e) => setStartPage(e.target.value)}
            className={classes.formControl}
            inputProps={{ min: 1 }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="End Page"
            type="number"
            value={endPage}
            onChange={(e) => setEndPage(e.target.value)}
            className={classes.formControl}
            inputProps={{ min: 1 }}
          />
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleAddChunk} className={classes.formControl}>
            Add Chunk
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        Selected Chunks:
      </Typography>
      <ul>
        {selectedChunks.map((chunk, index) => (
          <li key={index}>{`Pages ${chunk.start} to ${chunk.end}`}</li>
        ))}
      </ul>
      <Button variant="contained" color="secondary" onClick={handleSubmit}>
        Submit
      </Button>
      <Paper className={classes.pdfContainer}> 
      {
        pdf ? <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="800px" /> : 'Loading' 
      }
      </Paper>
    </Container>
  );
};

export default PdfSplitter;
