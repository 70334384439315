import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
// import ServiceDetailList from './ServiceDetailList'
// import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputMask from "react-input-mask";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialInput from '@material-ui/core/Input';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const defaultState = {
  firstName: '',
  lastName: '',
  email: '',
  note: '',
  receiveInvoices: true,
  receiveStatements: true,
  receiveMarketing: true,
  phoneNumbers: [
    {
      number: '',
      type: 'Primary'
    }
  ]
}

const AddNewContact = (props) => {
  // console.log('AddNewContact ', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState(defaultState)

  React.useEffect(() => {
    // console.log('Use Effect New Contact')
    // let jobName = 'Job - '
    // if (props.client.lastName) jobName = props.client.lastName + '- '
    // if (props.client.companyName) jobName = props.client.companyName + '- '
    setOpen(props.open)
    // setState({...state, jobName: jobName})
  }, [props])

const handleClose = () => {
// console.log('Closing New Contact Modal')
  // setOpen(false)
  setState(defaultState)
  props.closeModal('Close')
}

const handleConfirm = (data) => {
// console.log('CONFIRMED NEW JOB!!', data)
}
const handleSave = () => {
  // setLoading(true)
// console.log('handle save new contact', state)
  setState(defaultState)
  props.saveContact(state)
  // console.log(props)
  // let obj = {
  //   client: props.client,
  //   contact: state,
  //   user: props.user
  // }
  // axios({
  //   method: 'post',
  //   url:  `${props.url}/api/company/createNewVendorContact`,
  //   data: obj,
  //   // validateStatus: (status) => {
  //   // // console.log('Validate status: ', status)
  //   //   if (status && status === 500) {

  //   //   }
  //   // },
  // }).then(response => {
  // // console.log('Axios response to create new Contact: ', response)
  //   setLoading(false)
  //   // setJobNumber(response.data)
  //   if (response.data._id) {
  //     // props.newNoteResponse('newNote', response.data)
  //     // props.saveContact(response.data)
  //     // setState({note: ''})
  //     props.notification({
  //       type: 'success',
  //       title: 'New Contact Saved!',
  //       message: 'Well Done!!'
  //     })
  //     // setOpen(false)
  //     // setState(defaultState)
     
  //     setState(defaultState)
  //     props.saveContact(response.data)
  //   } else {
  //     // console.log()
  //     props.notification({
  //       type: 'warning',
  //       title: 'Uh Oh!',
  //       message: 'Something messed up creating this contact...not sure why!'
  //     })
  //   }
    
  // }).catch(err => {
  // // console.log('Error saving workorder: ', err)
  // })
}
const handlePhoneNumberChange = (e, phone, index) => {
  // console.log('Handle Phone Number Change: ', phone)
// console.log(index)
// console.log(e.target.value)
  let newPhoneNumbers = [...state.phoneNumbers]
  newPhoneNumbers[index] = {
    number: e.target.value,
    type: phone.type
  }
  // console.log('new Phone Numbers: ', newPhoneNumbers)
  setState({...state, phoneNumbers: newPhoneNumbers})
}

const addPhoneNumber = () => {
  let newPhoneNumbers = [...state.phoneNumbers]
  newPhoneNumbers.push({
    number: '',
    type: 'Primary'
  })
  setState({...state, phoneNumbers: newPhoneNumbers})
}

const removePhoneNumber = (index) => {
  if (state.phoneNumbers.length > 1) {
    if (window.confirm('Are you sure you wish to delete this phone number? This is not reversible.')) {
      let newPhoneNumbers = [...state.phoneNumbers]
      newPhoneNumbers.splice(index, 1)
      setState({...state, phoneNumbers: newPhoneNumbers})
    }
  }
}

const changePhoneType = (e, phone, index) => {
// console.log('Phone Type Change: ', phone)
// console.log(e.target.value)
  let updatedNumbers = state.phoneNumbers
  updatedNumbers[index]['type'] = e.target.value
  setState({...state, phoneNumbers: updatedNumbers})
}

return (
  <div className={classes.root}>
    <Dialog open={open} fullWidth={true} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create New Vendor Contact</DialogTitle>
        <DialogContent >
        <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
          <CloseIcon />
        </IconButton>
          <DialogContentText>
            Please Add The Contact Details Below
          </DialogContentText>
          <Grid container>
            <Grid item lg={12}>
              <h3>Contact Information</h3>
            </Grid>

            <Grid item lg={6} xs={6} className={classes.gridItem}> 
              <TextField
                
                label="Contact First Name"
                onChange={ e => setState({...state, firstName: e.target.value })}
                name="customerfirstname"
                value={ state.firstName }
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item lg={6} xs={6} className={classes.gridItem}> 
                        
              <TextField
              
                label="Contact Last Name"
                onChange={ e => setState({...state, lastName: e.target.value })}
                name="customerlastname"
                value={ state.lastName }
                margin="normal"
                fullWidth
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <TextField
                  className={classes.paddedStuff}
                  margin="dense"
                  id="Email"
                  label="Email"
                  type="text"
                  onChange={(e) => setState({...state, email: e.target.value})}
                  value={state.email}
                  fullWidth
              />
             
            </Grid>
              { state.phoneNumbers.map((phone, index) => {
              // console.log('PHONE NUMBER LIST: 318', phone)
                return (
                <React.Fragment key={index}>
                <Grid item lg={5} xs={5}>
               
                      <InputMask mask="(999) 999-9999" maskPlaceholder=" " onChange={e => handlePhoneNumberChange(e, phone, index)}  value={phone.number} >
                   {
                     () => {
                       return (
                        <TextField
                          className={classes.paddedStuff}
                          margin="dense"
                          id="Telephone"
                          label={"Telephone Number #" + (index - 0 + 1)}
                          type="telephone"
                          // onChange={(e) => handlePhoneNumberChange(e, phone, index)}
                          // // value={state.phoneNumbers[index]['number']}
                          // value={phone.number}
                          fullWidth
                      />
                       )
                     }
                   }
              
               
                      </InputMask>
                  
                      </Grid>
                      <Grid item lg={4} xs={4}>
                      <FormControl className={classes.formControl} fullWidth>
                        <Select
                          value={phone.type}
                          onChange={(e) => changePhoneType(e, phone, index)}
                          // onChange={(e) => setState({...state, phone['type']: e.target.value })}
                          className={classes.selectEmpty}
                          inputProps={{ 'aria-label': 'Phone Number Type' }}
                          fullWidth
                          placeholder="Phone Number Type"
                        >
                          <MenuItem value={"Primary"}>
                            Primary
                          </MenuItem>
                          <MenuItem value={"Home"}>Home</MenuItem>
                          <MenuItem value={"Mobile"}>Mobile</MenuItem>
                          <MenuItem value={"Work"}>Work</MenuItem>
                        </Select>
                        <FormHelperText>Phone Number Type</FormHelperText>
                      </FormControl>
                      </Grid>
                      <Grid item lg={2} xs={2} style={{
                          display: 'flex',
                          marginLeft: '50px',
                          alignItems: 'center',
                          flexWrap: 'wrap'
                      }}>
                       <IconButton edge='start' alt="Remove" aria-label="Remove" onClick={removePhoneNumber}>
                          <RemoveCircleIcon />
                        </IconButton>
                        { index === state.phoneNumbers.length - 1 ?  <IconButton edge='end' alt="Add" aria-label="Add" onClick={addPhoneNumber}>
                          <AddCircleIcon />
                        </IconButton> : ''}
                      </Grid>
                </React.Fragment>
              )
            })}
          </Grid>
          <TextField
            
            className={classes.paddedStuff}
            variant="outlined"
            id="Note"
            label="Contact Notes"
            multiline
            rows={4}
            type="text"
            onChange={(e) => setState({...state, notes: e.target.value})}
            value={state.notes}
            fullWidth
          />

        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading || !state.firstName } onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(AddNewContact))