import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';

const EditTaxLocaleModal = (props) => {

  const [state, setState] = React.useState({
    absenceType: 'off',
    notes: '',
    rate: 0,
    name: '',
    _id: ''
  })
  
  React.useEffect(() => {
    if (props.locale && props.locale._id) {
      setState({...state, notes: props.locale.notes, name: props.locale.name, rate: props.locale.rate, _id: props.locale._id })
    }
  }, [props.locale])

  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleSubmit = () => {
    if (state.rate > .99 || state.rate < 0) {
        props.notification({
        type: 'warning',
        title: 'Error Saving Tax Locale',
        message: `The tax rate ${state.rate} is NOT valid. Please enter the rate in decimal format, for example 5% should be written as .05`
      })
    } else {
      // state._id = props.locale._id
      axios({
        method: 'post',
        url:  `${props.url}/api/settings/updateTaxLocale`,
        data: state
      }).then(response => {
        if (response && response.data && response.data.locale) {
          // console.log('Tax Locale Saved', response.data)
          props.notification({
            type: 'success',
            title: 'Tax Locale Updated',
            message: `We have saved ${state.rate} as the Tax Rate for ${state.name}. Well done!`
          })
          props.updateParent('saved', response.data.locale)
        } else {
          if (response && response.data && response.data.error) {
          // console.log('MESAGE: ', response.data.message)
            props.notification({
              type: 'warning',
              title: 'Error Saving Tax Locale',
              message: response.data.message
            })
          }
        }

      }).catch(err => {
      // console.log('Error reporting absence', err)
        props.notification({
          type: 'warning',
          title: 'Error Saving Tax Locale',
          message: err.message
        })
      })
    }
  }
return (
  <div>
    
      <Dialog
        // fullScreen={false}
        // maxWidth={'lg'}
        // width='lg'
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Edit Tax Locale ({props.locale.name})</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Provide Details for this Locale:
          </DialogContentText>
          <TextField
                id="name"
                label="Locale Name"
                variant="outlined"
                fullWidth
                // className={classes.textSaveLineBreaks}
                value={state.name}
                onChange={e => setState({...state, name: e.target.value})}
                style={{marginBottom: '10px'}}
                // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                // defaultValue="Default Value"
            />
            <TextField
                id="name"
                label="Locale Tax Rate (DECIMAL FORMAT)"
                variant="outlined"
                fullWidth
                // className={classes.textSaveLineBreaks}
                value={state.rate}
                onChange={e => setState({...state, rate: e.target.value})}
                style={{marginBottom: '10px'}}
                placeholder="9.5% Should Be Written as .095"
                // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                // defaultValue="Default Value"
            />
              <TextField
                id="notes"
                label="Notes"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                // className={classes.textSaveLineBreaks}
                value={state.notes}
                onChange={e => setState({...state, notes: e.target.value})}
                // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                // defaultValue="Default Value"
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit()} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(EditTaxLocaleModal)