import React from 'react';
import { connect } from 'react-redux'
import PhotoUploadComponent from '../Uploads/PhotoUploadComponent'
import { Button, CircularProgress, MenuItem }  from '@material-ui/core';
import axios from 'axios'
import DeleteComponent from '../Common/DeleteComponent'

const InventoryPhotoComponent = (props) => {
  const [photos, setPhotos] = React.useState([])
  const [state, setState] = React.useState({ counter: 0 })
  React.useEffect(() => {
    let isMounted = true
    const fetchPhotos = async (id) => {
      console.log("Find photos", props.item)
      let photoList = []
      if (props.item.pics && props.item.pics.length) {
        props.item.pics.map(pic => {
          photoList.push({
            url: pic,
            _id: new Date()
          })
        })
      }
      const resp = await axios.get(`${props.url}/api/inventory/getPhotos?id=${id}`)
      console.log('Photos', resp.data)
    if (isMounted) {
      if (resp.data && resp.data.length) {
        console.log('Photos thus far', photoList)
        photoList = photoList.concat(resp.data)
        console.log('photolist', photoList)
        setPhotos(photoList)
      } else {
        setPhotos(photoList)
      }
    }

    }

  // console.log('PROPS.item changed', props.item)
    if (props.item && props.item._id && isMounted) {
      fetchPhotos(props.item._id)
    }

    return () => {
      isMounted = false
    }
  }, [props.item])

  const handleRemovePhotoFromFrontEnd = (photoToRemove) => {
  // console.log('Remove this photo', photoToRemove)

    let list = photos
  // console.log('from this list', list)
    let index = list.findIndex(item => (item._id === photoToRemove._id))
    if (index > -1) {
      list.splice(index, 1)
      setPhotos(list)
      setState({...state, counter: state.counter++ })
    }
  }
  const handleUpdatePhotos = (data) => {
  // console.log('Update photos.', data)
    if (data && data.filesUploaded && data.filesUploaded.length) {
    // console.log('Upload some photos', data.filesUploaded)
      let list = []
      if (photos && photos.length) list = photos
    // console.log('Current photos', list)
      list = data.filesUploaded.concat(list)
    // console.log('Updated list:', list)
      setPhotos(list)
    }
  }

  return (
    <div>
      <Button>Add Photo(s)</Button>
      <PhotoUploadComponent item={props.item} message={`Click Here or Drop Photos Of ${props.item.name}`} updateParent={handleUpdatePhotos} />
      <PhotoList photos={photos} deletePhoto={handleRemovePhotoFromFrontEnd} />
      {
        (props.item && props.item.pictureURL) ? <div style={{ maxWidth: 300, marginTop: 20, textAlign: 'center'}} ><img src={props.item.pictureURL} width='90%' /></div> : null
      }
    </div>
  )
}

const PhotoList = (props) => {

  const handleDeleteItem = (data) => {
  // console.log('Do we delete it ?', data)
    props.deletePhoto(data)
    // setShowDeleteDialog(false)
    // if (data) {
    //   handleDeleteActiveItem()
    // }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap' }}>
      {
        (props.photos && props.photos.length) ? (
          props.photos.map((item, index) => {
            let theURL = item.url
            if (theURL && !theURL.includes('https')) {
              theURL = `https://${theURL}`
            }
            return (

              <div key={index} style={{ minWidth: 350, maxWidth: 450, display: 'flex', margin: 15 }}>
                
                <img src={theURL} style={{ width: '100%', height: 'auto' }} />
                <span style={{ position: 'relative', top: 3, right: 45  }}>
                  <DeleteComponent
                    type="Uploads"
                    item={item}
                    title="Delete Photo?"
                    verificationText={`Are you sure you wish to Delete this photo?`}
                    confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
                    confirmationButton="DELETE"
                    denyText="NO"
                    color="secondary"
                    ifApproved={handleDeleteItem}
                    deleteFunction={'inventoryItemPhoto'}
                  />
                </span>
              </div>

            )
          })
        ) : null
      }
    </div>
  )
}

const PhotoList2 = (props) => {
  return (
    <div>
      {
        (props.photos && props.photos.length) ? (
          props.photos.map((item, index) => {
            return (
              <Photo key={index} photo={item} />
            )
          })
        ) : null
      }
    </div>
  )
}

const Photo = (props) => {
// console.log('Photo props', props)
  const photo = props.photo
  let url = photo.url
// console.log('URL:', url)
  return (
    <div>
      <img src={url} width={199} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(InventoryPhotoComponent)
