
import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress, IconButton }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import EditRounded from '@material-ui/icons/EditRounded'
import AddRequiredInputModal from './CreateNewRequiredInputModal'



const RequiredInputList = ({ service, handleDeleteRequiredInput, handleEditRequiredInput }) => {
  // console.log('Required INputs; ', service)
  const DeleteButton = (props) => {
    return (
      <IconButton onClick={e => handleDeleteRequiredInput(props.item)} edge='end' alt="Delete" aria-label="Delete">
        <DeleteIcon />
      </IconButton>
    )
  }

  const EditButton = (props) => {
    return (
      <IconButton onClick={e => handleEditRequiredInput(props.item)} edge='start' alt="Edit" aria-label="Edit">
        <EditRounded />
      </IconButton>
    )
  }

  let inputArray = []
  if (service && service.requiredInputs && service.requiredInputs.length) inputArray = service.requiredInputs
  return (
    <div style={{  padding: 5}}>
      { inputArray.map((item, index) => {
        return (
          <Grid container key={index} style={{ border: '1px #ddd solid', backgroundColor: '#eee', borderRadius: 4, padding: 4, marginTop: 5, }} >
            <Grid item lg={9} xs={12} style={{ marginTop: 6 }}>
              {`${item.description} -- -- {${item.name}}`}
            </Grid>
            <Grid item lg={3} xs={12} style={{ textAlign: 'right' }}>
            <EditButton item={item} />
            <DeleteButton item={item} />
            </Grid>
          </Grid>
        )
      })}
    </div>
  )
}

const RequiredInputsComponent = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [showNewInput, setShowNewInput] = React.useState(false)
  const [inEditMode, setEditMode] = React.useState(false)
  const [activeItem, setActiveInputItem] = React.useState({})

  const addnewRequiredInput = () => {
    setShowNewInput(true)
  }
  
  const handleSaveNewRequiredInput = (inputItem) => {
    setShowNewInput(false)
    if (inEditMode && activeItem) {
      // Update Existing Item
      // console.log('Update this item: ', activeItem)
      // console.log(' With this: ', inputItem)
      let newSvc = props.service
      let requiredInputs = newSvc.requiredInputs
      let index = requiredInputs.findIndex(item => ((activeItem.name === item.name) && (activeItem.description === item.description)))
      if (index > -1) {
        requiredInputs[index] = inputItem
        // console.log('Updated %d w/ this:', index, inputItem)
        // newSvc.requiredInputs = r
        // console.log('Update this bitch after EDITING an input: ', newSvc)
        props.updateService(newSvc, true)
      }
      setActiveInputItem({})
      setEditMode(false)
    } else {
      // Save a New Item
      setLoading(true)
    // console.log(props)
      let obj = {
        inputItem: inputItem,
        service: props.service
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/company/addRequiredInputToService`,
        data: obj,
        // validateStatus: (status) => {
        // // console.log('Validate status: ', status)
        //   if (status && status === 500) {
    
        //   }
        // },
      }).then(response => {
        // console.log('Axios response to add required input to  service ', response.data)
        let newSvc = response.data
        newSvc.scope = props.service.scope
        props.updateService(newSvc)
        setLoading(false)
      })
    }
    // console.log('Save this Required Input: ', inputItem)
  }

  const handleCloseRequiredLineItemModal= () => {
    // console.log('Close Modal')
    setShowNewInput(false)
  }

  const handleDeleteRequiredInput = (item) => {
    // console.log('Delete this: ', item)
    let msg = `Are you sure you wish to delete this Required Input (${item.name})? This is not reversible`
    if (window.confirm(msg)) {
      let newSvc = props.service
      let requiredInputs = newSvc.requiredInputs
      let index = requiredInputs.findIndex(inputItem => ((inputItem.name === item.name) && (inputItem.description === item.description)))
      if (index > -1) {
        requiredInputs.splice(index, 1)
        // newSvc.requiredInputs = r
        // console.log('Update this bitch: ', newSvc)
        props.updateService(newSvc, true)
      }

    }
  }

  const handleEditRequiredInput = (item) => {
    // console.log('Edit this; ', item)
    setActiveInputItem(item)
    setEditMode(true)
    setShowNewInput(true)
  }

  return (
    <div >
      <AddRequiredInputModal open={showNewInput} editing={inEditMode} inputItem={activeItem} service={props.service} closeModal={handleCloseRequiredLineItemModal} saveItem={handleSaveNewRequiredInput} />
      <Grid item lg={12} xs={12} style={{ fontSize: '1.25em', fontColor: '#aaa'}}>
        Required Inputs
      </Grid>
      <Grid item lg={12} xs={12} style={{ fontSize: '1.10em', fontColor: '#bbb'}}>
        Info We Want To Get Every Time We Select This Service
      </Grid>
      <Grid item lg={12} xs={12} style={{ textAlign: 'right', margin: 8 }}>
        <Button variant="contained" color="primary" onClick={addnewRequiredInput}>Add Required Input</Button>
      </Grid>
      <Grid item lg={12} xs={12} style={{ fontSize: '1.05em', fontColor: '#ccc'}}>
        Current Required Inputs:
      </Grid>
      <Grid item lg={12} xs={12}>
        <RequiredInputList service={props.service} handleDeleteRequiredInput={handleDeleteRequiredInput} handleEditRequiredInput={handleEditRequiredInput} />
      </Grid>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(RequiredInputsComponent)
