import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 5
  },
  flexItem: {
    flexGrow: 1,
    padding: 5
  },
  bigText: {
    fontSize: 16,
    color: '#bbb',
    margin: 5
  }
}))

const defaultValues = {
  percentage: 50,
  amount: 0,
  type: 'percent',
  totalEstimatePrice: 0
}

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const ChooseDepositComponent = (props) => {
  // console.log('Deposit props udpateD: ', props)
  const classes = useStyles();
  const [state, setState] = React.useState(defaultValues)

  React.useEffect(() => {
    let isMounted = true

    // console.log('Choose Deposit Component', props)
    if (props.estimate && props.estimate._id) {
      // console.log('Update estimate Deposit Info...', props)
      let totalEstimatePrice = props.estimate.totalPrice
      let estimateTotal = props.estimate.totalPrice
      // console.log('estimate total: ', estimateTotal)
      if (props.estimate && props.estimate.taxable) {
        let estimateTaxAmount = Number(parseFloat(props.estimate.taxAmount).toFixed(2))
        // console.log('Update Tax Amount: ', props.estimate.taxAmount)
        totalEstimatePrice = Number(parseFloat(estimateTotal - 0 + estimateTaxAmount).toFixed(2))
        // console.log('Total with tax', totalEstimatePrice)
      }
      let depAmt = Number(props.estimate.depositPercent)
      let depositAmount = Number(parseFloat(totalEstimatePrice * (depAmt / 100)).toFixed(2))
      // console.log('Deposit Amount', depositAmount)
      let percentFixed = Number(parseFloat(props.estimate.depositPercent).toFixed(2))
      let obj = {...state, amount: depositAmount, percentage: percentFixed, totalEstimatePrice: totalEstimatePrice}
      // console.log('Update Deposit Amounts ', obj)
      // props.updateAmounts(percentFixed, depositAmount, 'percent')
      if (isMounted) setState(obj)
    }

    return () => {
      isMounted = false
    }
  }, [props])

  const handlePercentChange = (e) => {
    let percentageAmt = e.target.value
    if (e.target.value > 100) {
      // setState({...state, percentage: 100})
    
      percentageAmt = 100
    } else {
      // setState({...state, percentage: e.target.value})
 
    }
    let estimateTotal = state.totalEstimatePrice
    // console.log('estimate total: ', estimateTotal)
    let depositAmount = parseFloat(estimateTotal * (percentageAmt / 100)).toFixed(2)
    props.updateAmounts(percentageAmt, depositAmount, 'percent')
    setState({...state, percentage: percentageAmt, amount: depositAmount, type: 'percent'})
  }

  const handleAmountChange = (e) => {
    let depositAmount = e.target.value
    if (depositAmount > state.totalEstimatePrice) {
      depositAmount = state.totalEstimatePrice
    }
    let percentageRequired = 0
    let pct = depositAmount / state.totalEstimatePrice
    // console.log('PERCENT', pct)
    percentageRequired =  parseInt(pct * 100)
    props.updateAmounts(pct * 100, depositAmount, 'amount')
    setState({...state, percentage: percentageRequired, amount: depositAmount, type: 'amount'})
  }

  return (
    <div className={classes.root}>
      <div className={classes.bigText}>How Much Is The Initial Deposit?</div>
      <div className={classes.container}>
        <div className={classes.flexItem}>
          <TextField
              type="number"
              label={`% of Total (${currencyFormat(state.totalEstimatePrice)})`}
              variant="outlined"
              fullWidth
              value={state.percentage}
              onChange={handlePercentChange}
            />
        </div>
        <div className={classes.flexItem}>
          <TextField
              type="number"
              label="Amount"
              variant="outlined"
              fullWidth
              value={state.amount}
              disabled
              // onChange={handleAmountChange}
            />
        </div>
      </div>

          

    </div>
  )
}

export default ChooseDepositComponent