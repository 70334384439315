import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'

const SingleUpload = (props) => {
  const upload = props.uploads.length ? props.uploads[0] : {}
  let theURL = upload.url ? upload.url : ''
  if (theURL && !theURL.includes('https')) {
    theURL = `https://${theURL}`
  }
  let pdfURL = `https://docs.google.com/gview?url=${theURL}&embedded=true`
  const item = upload
// console.log('Upload Item: 13', item)
  return (
    <div style={{ margin: 15, textAlign: 'center' }}>
      <div style={{ fontSize: '1.2em', color: '#bbb' }}>PO Related File</div>
      <div>
        {
          (item && item._id) ? (
            (item.type.includes('pdf')) ? (
              <div style={{ width: '100%', minWidth: 400 }}>
                <iframe src={pdfURL} width="100%" height="800px" frameBorder="0"></iframe>
              </div> 
              ) : (item.type.includes('image')) ? (
                <div style={{ minWidth: 300  }}>
                  <img src={theURL} style={{ width: '100%', height: 'auto' }} />
                </div>
                ) : (item.type.includes('csv') || item.type.includes('office')) ? <iframe width="100%" height="900px" src={`https://docs.google.com/gview?url=${item.url}&embedded=true`}></iframe> : null
          
          ) : 'No Upload'
        }
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(SingleUpload)
