import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const IssueCreditModal = (props) => {
  // console.log('IssueCreditModal  Props: ', props)
  const classes = useStyles();

  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  // const [invoice, setInvoice] = React.useState(null)
  const [state, setState] = React.useState({
    date: new Date(),
    amount: 0,
    notes: ''
  })

  React.useEffect(() => {
    setState({...state, amount: null, notes: null})
  }, [])
const handleClose = () => {
  // console.log('Closing Issue Modal')
  setState({...state, amount: null, notes: null})
  props.closeModal()
}

const handleSave = () => {
  setLoading(true)
  // console.log('Save this credit!!!', state)
    // console.log('Send this credit to The Server')
    let obj = {
      client: props.client,
      data: state,
      user: props.user
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/payment/issueCredit`,
      data: obj
    }).then(response => {
      setLoading(false)
      // console.log('Axios response from issueCredit: ', response)
      if (response && response.data && response.data._id) {
        // console.log('We have a credit issued!!', response.data)
        props.notification({
          type: 'success',
          title: 'Credit Applied!',
          message: 'Credit Was Created Successfully'
        })
        // handleClose('success', response.data)
        props.updateParent(response.data)
      } else {
        props.notification({
          type: 'warning',
          title: 'Unknown Error',
          message: 'We Do Not Know Why This Shows Up...'
        })
      }
  })
}

return (
  <div className={classes.root}>
    <Dialog open={props.open} fullWidth={false} onClose={handleClose} maxWidth='sm' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Issue Credit</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Provide Details On This Credit
          </DialogContentText>
          <Grid container>
            <Grid item xs={12} lg={12}>
              <TextField
                  id="amount"
                  label="Credit Amount"
                  type="number"
                  className={classes.paddedStuff}
                  fullWidth
                  value={state.amount || ''} // "2017-05-24"}
                  // className={classes.textField}
                  onChange={ e => setState({...state, amount: parseFloat(e.target.value)}) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                  id="notes"
                  label="Notes"
                  type="text"
                  className={classes.paddedStuff}
                  fullWidth
                  value={state.notes || ''} // "2017-05-24"}
                  // className={classes.textField}
                  onChange={ e => setState({...state, notes: e.target.value}) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
            </Grid>
          </Grid>
          
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading || !state.notes || !state.amount} onClick={handleSave}>
            {
              loading ? <CircularProgress /> : 'Save'
            }
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(IssueCreditModal))