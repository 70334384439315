import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import { Button}  from '@material-ui/core';


// Need to offer an input box for someone to enter the associated Workorder Number
// Need to then check if that already exists
const ClientGeneratedWorkorderComponent = (props) => {
  const wo = props.workorder
  const client = props.client
  const [isInvalid, setIsInvalid] = React.useState(false)
  const [workordersUsedOn, setWorkordersUsedOn] = React.useState([])
  const [woValue, setWoValue] = React.useState('')
  let helperText = 'Please Enter a Unique Workorder Number (Provided by Customer)'

  React.useEffect(() => {
    if (props.workorder && props.workorder.clientApprovedWorkorderNumber) {
      setWoValue(props.workorder.clientApprovedWorkorderNumber)
    }
  }, [props.workorder])
  
  const checkForDupes = (num, forceIt) => {
    let data = {
      workorderId: wo._id,
      number: num,
      clientId: client._id,
      forceIt: forceIt
    }
    console.log('POST HTIS:', data)
    axios({
      method: 'post',
      url:  `${props.url}/api/jobs/checkForDuplicateClientWorkorderNumbers`,
      data: data
    }).then(response => {
      console.log('Axios response from checking for dupes: ', response.data)
      if (response.data && response.data.success) {
        props.updateOurWorkorder(response.data.wo)
      }
      if (response.data && response.data.length) {
        console.log('Gotta show these duplciate Workorder Numbers', response.data.length)
        helperText = 'DUPLICATE -- PLEASE TRY AGAIN!!'
        setIsInvalid(true)
        setWorkordersUsedOn(response.data)
      }
    })
  }

  const handleBlur = (e) => {
    console.log('Check if this is good:', e.target.value)
    checkForDupes(e.target.value, false)
  }

  const handleForce = () => {
    setIsInvalid(false)
    checkForDupes(woValue, true)
  }

  const handleCloseModal = () => {
    setIsInvalid(false)
    setWoValue('')
    setWorkordersUsedOn([])
  }

  return (
    <div>
       <DuplicatesModal open={isInvalid} dupes={workordersUsedOn} handleCloseModal={handleCloseModal} handleForce={handleForce} />
      <TextField
        type="text"
        label="Client Approved Workorder Number"
        variant="outlined"
        helperText={helperText}
        onBlur={handleBlur}
        value={woValue}
        // disabled={props.workorder.clientApprovedWorkorderNumber}
        onChange={e => setWoValue(e.target.value)}
      />
    </div>
  )
}

const DuplicatesModal = ({ dupes, open, handleCloseModal, handleForce }) => {

  return (
    <Dialog
      open={open}
      maxWidth={'sm'}
      onClose={handleCloseModal}
      >
        <DialogTitle>Workorder Number Already Used</DialogTitle>
        <DialogContent>
          <DialogContentText>This Workorder Number Was Already Used:</DialogContentText>
          {
            dupes.map(dupe => {

              return (
                <div>
                  {dupe.number} {dupe.jobName}
                </div>
              )
            })
          }
         
         <DialogContentText style={{ marginTop: 20 }}>Are you certain that you can re-use this Workorder Number?</DialogContentText>
        </DialogContent>
       
        <DialogActions>
          <Button
            onClick={handleCloseModal}
            color="primary"
            style={{marginRight: 20}}
          >No, I'll Double Check</Button>
           <Button
            color="secondary"
            onClick={handleForce}
          >
          YES! - DO IT NOW!  </Button>
        
        </DialogActions>
      </Dialog>
  )
}

export default ClientGeneratedWorkorderComponent
