import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Fab, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import ViewListIcon from '@material-ui/icons/ViewList'

const defaultOpts = [
  {
    name: 'Choose'
  }
]

const ITEM_HEIGHT = 48;

export default function DropDownButton(props) {
  // console.log('Dropdown menu props', props)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChoose = (opt) => {
    props.chooseOption(opt)
    handleClose()
  }
  const options = (props.options && props.options.length) ? props.options : defaultOpts
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
       { props.icon ? props.icon : <MoreVertIcon /> }
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      
      >
        {options.map((option, index) => (
          <MenuItem key={index} selected={option === options[0]} onClick={() => handleChoose(option)}>
          {
            option.icon ? <ListItemIcon>
              {option.icon}
            </ListItemIcon> : null
          }
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
