import React from 'react'
import CreateTaskModal from './CreateTaskModal';
import { Button } from '@material-ui/core'

const CreateTaskComponent = (props) => {
    const [anchorElTasks, setAnchorElTasks] = React.useState(null);
    const [showCreateTaskModal, setShowCreateTaskModal] = React.useState(false)


    const handleCreateTask = () => {
        // alert('h')
        setShowCreateTaskModal(true)
        setAnchorElTasks(null);
    }

    const handleNewTaskResponse = (d) => {
        // setAnchorElTasks(null);
        setShowCreateTaskModal(false)
    }


    return (
        <div>
            <CreateTaskModal open={showCreateTaskModal} updateParent={handleNewTaskResponse} closeModal={handleNewTaskResponse} />
            <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={handleCreateTask}
                >
                Create Task
                </Button>
        </div>
        
    )
    
}
    
export default CreateTaskComponent
        