import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { fade, makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    padding: 5
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  textSaveLineBreaks: {
    whiteSpace: 'pre-line'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

const statusOptions = [
  'Archive',
  'Lost',
  'Duplicate',
  'Pending Update',
  'Sent to Customer',
  'Pending Customer Review',
  'Pending Revisions',
  'Accept'
]

const defaultStateValues = {
  status: statusOptions[0]
}

function StatusModal(props) {
  // console.log('Status', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState(defaultStateValues)
  const estimate = props.item

  React.useEffect(() => {
    if (props.item && props.item.status) {
      let status = null
      let index = statusOptions.findIndex(item => (item === props.item.status))
      if (index > -1) {
        status = statusOptions[index]
        setState({...state, status: status })
      }
    } else {
      setState({...state, status: statusOptions[0] })
    }
  }, [props.item])

  const handleClose = () => {
    props.closeModal()
  }
  const handleChangeStatus = (e) => {
  // console.log('Change Status: ', e.target.value)
    setLoading(true)
    let status = null
    let index = statusOptions.findIndex(item => (item === e.target.value))
    if (index > -1) {
      status = statusOptions[index]
    }
    setState({...state, status: status })
    // let data = {
    //   _id: props.project._id,
    //   status: e.target.value
    // }
    // axios({
    //   method: 'post',
    //   url:  `${props.url}/api/projects/changeStatus`,
    //   data: data
    // }).then(response => {
    //   setLoading(false)
    //   let ourProject = {...project}
    //   ourProject.status = status
    //   props.updateParent(ourProject, 'saved')
    // })
  }

  const handleSave = () => {
    props.updateParent(state.status)
  }

  return (
    <Dialog
      maxWidth={"sm"}
      fullWidth
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Change Estimate Status</DialogTitle>
      <DialogContent>
        
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
              Estimate Status: {estimate.status}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
                  
                  id="status"
                  select
                  value={state.status}
                  fullWidth
                  // style={{ width: '50%', maxWidth: 350 }}
                  variant="outlined"
                  onChange={handleChangeStatus}
                  label="Estimate Status"
                >
                  {
                    statusOptions.map(unit => {
                      return (
                      <MenuItem key={unit} value={unit} >{unit}</MenuItem>
                    )})
                  }
                </TextField>
          </Grid>
        </Grid>
        
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          color="primary">Update</Button>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(StatusModal)