import React from 'react';
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button, CircularProgress }  from '@material-ui/core';
import axios from 'axios'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import ContactComponent from './AddVendorContact'
import ContactListComponent from './ContactListComponent'
import InputMask from "react-input-mask";

const defaultStateValues = {
  name: '',
  address: '',
  creditLimit: 0,
  paymentTerms: 'Net 30',
  city: '',
  state: '',
  zipcode: '',
  vendorType: 'None',
  mailChecks: true,
  counter: 0,
  phoneNumber: ''
}

const NewSuppllierModal = (props) => {
  const [state, setState] = React.useState(defaultStateValues)
  const [loading, setLoading] = React.useState(false)
  const [showNewContactModal, setShowNewContactModal] = React.useState(false)
  const [contactList, setContactList] = React.useState([])
  const [newContacts, setNewContacts] = React.useState([])
  const inputRef = React.useRef({
    name: ''
  })
  const nameRef = React.useRef(null)
  const updateRef = (e) => {
  // console.log(inputRef)
    inputRef.current[e.target.id] = e.target.value
  // console.log(nameRef)
  }

  React.useEffect(() => {

    if (props.name) {
      // inputRef.current['name'] = props.name
      // nameRef.current.value = props.name
      // document.getElementById('name').value = props.name
      setState({...state,
        name: props.name
      })
    }
  }, [props])

  const handleValueChange = (e) => {
    // console.log('ID:', e.target.id)
    // console.log(e.target.value)
    setState({...state, [e.target.id]: e.target.value })
  }

  const handleSupplierTypeChange = (e) => {
    setState({...state, vendorType: e.target.value })
  }

  const handlePaymentTermsChange = (e) => {
    setState({...state, paymentTerms: e.target.value })
  }

  const handleFocus = (e) => {
    e.preventDefault()
    e.target.focus()
    e.target.setSelectionRange(0, e.target.value.length)
  }

  const handleSave = () => {
    setLoading(true)
    // setClient({})
    // setState({...state, note})
    // console.log('Handle save: ', state)
    // let data = {
    //   details: inputRef.current['details'],
    //   assigned_to: state.assigned_to,
    //   dueDate: inputRef.current['dueDate'],
    //   client: state.client._id
    // }
    // console.log(data)
    axios({
      method: 'post',
      url:  `${props.url}/api/company/createVendor`,
      data: state
    }).then(response => {
      setLoading(false)
      if (response.data && response.data._id) {
        let vendor = response.data
        props.notification({
          type: 'success',
          title: 'Vendor Saved',
          message: 'Vendor Saved And Assigned!'
        })
        if (newContacts && newContacts.length) {
          let obj = {
            contacts: newContacts,
            vendor: response.data
          }
          axios({
            method: 'post',
            url:  `${props.url}/api/company/createNewVendorContacts`,
            data: obj,
            // validateStatus: (status) => {
            // // console.log('Validate status: ', status)
            //   if (status && status === 500) {
  
            //   }
            // },
          }).then(response => {
          // console.log('Axios response to create new Contacts: ', response)
            setLoading(false)
            // setJobNumber(response.data)
            if (response.data && response.data.length) {
              // props.newNoteResponse('newNote', response.data)
              // props.saveContact(response.data)
              // setState({note: ''})
              props.notification({
                type: 'success',
                title: 'New Contact(s) Saved!',
                message: 'Well Done!!'
              })
              setState(defaultStateValues)
              props.updateParent(vendor)
              // setOpen(false)
              // setState(defaultState)
          
              // setState(defaultState)
              // props.saveContact(response.data)
            } else {
              // console.log()
              props.notification({
                type: 'warning',
                title: 'Uh Oh!',
                message: 'Something messed up creating this contact...Please Try Again (ViewWoDetails 135)!'
              })
            }
            
          }).catch(err => {
          // console.log('Error saving workorder: ', err)
          })
        } else {
          setState(defaultStateValues)
          props.updateParent(vendor)
        }  
        // props.closeModal()
      }
    })
  }

  const saveContact = (contact) => {
  // console.log('Save this contact to our Contact List')
    let contacts = contactList
    contacts.unshift(contact)
    setShowNewContactModal(false)
    setContactList(contacts)
    let existingListOfNewContacts = newContacts
    existingListOfNewContacts.unshift(contact)
    setNewContacts(existingListOfNewContacts)
    setState({...state, counter: state.counter++ })
  }

  const handleCoseAddVCModal = () => {
    setShowNewContactModal(false)
  }

  const handlePhoneNumberChange = (e) => {
    setState({...state, phoneNumber: e.target.value })
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      // size={'lg'}
      maxWidth='lg'
      fullWidth
    >
      <ContactComponent open={showNewContactModal} saveContact={saveContact} closeModal={handleCoseAddVCModal} />
      <DialogTitle>Add a New Vendor</DialogTitle>
      <DialogContent>
        <DialogContentText>Vendor Details:</DialogContentText>
        <Grid container spacing={1}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TextField
              id="name"
              label="Vendor Name"
              // style={{ margin: 8 }}
              value={state.name}
              // inputRef={nameRef}
              // onChange={updateRef}
              onChange={handleValueChange}
              // ref={inputRef['projectName']}
              placeholder="Vendor Name"
              helperText="Who Sent the Invoice?"
              fullWidth
              // value={inputRef.current['name']}
              margin="normal"
              variant="outlined"
              // InputLabelProps={{
              //   shrink: true,
              // }}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <InputMask mask="(999) 999-9999" maskPlaceholder=" " onChange={e  => handlePhoneNumberChange(e) }  value={state.phoneNumber} >
                {
                  () => {
                    return (
                      <TextField
                        margin="normal"
                        id="Telephone"
                        label={"Telephone Number" }
                        type="telephone"
                        variant="outlined"
                        // onChange={(e) => handlePhoneNumberChange(e, phone, index)}
                        // // value={state.phoneNumbers[index]['number']}
                        // value={phone.number}
                        helperText="What is The Main Number?"
                        fullWidth
                    />
                    )
                  }
                }          
              </InputMask>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              id="address"
              label="Vendor Address"
              // style={{ margin: 8 }}
              value={state.address}
              // inputRef={nameRef}
              // onChange={updateRef}
              onChange={handleValueChange}
              // ref={inputRef['projectName']}
              placeholder="Vendor Address"
              // helperText="Who Sent the Invoice?"
              fullWidth
              // value={inputRef.current['name']}
              margin="normal"
              variant="outlined"
              // InputLabelProps={{
              //   shrink: true,
              // }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <TextField
              id="city"
              label="City"
              // style={{ margin: 8 }}
              value={state.city}
              // inputRef={nameRef}
              // onChange={updateRef}
              onChange={handleValueChange}
              // ref={inputRef['projectName']}
              placeholder="City"
              // helperText="Who Sent the Invoice?"
              fullWidth
              // value={inputRef.current['name']}
              margin="normal"
              variant="outlined"
              // InputLabelProps={{
              //   shrink: true,
              // }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={6}>
            <TextField
              id="state"
              label="State"
              // style={{ margin: 8 }}
              value={state.state}
              // inputRef={nameRef}
              // onChange={updateRef}
              onChange={handleValueChange}
              // ref={inputRef['projectName']}
              placeholder="State"
              // helperText="Who Sent the Invoice?"
              fullWidth
              // value={inputRef.current['name']}
              margin="normal"
              variant="outlined"
              // InputLabelProps={{
              //   shrink: true,
              // }}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={6}>
            <TextField
              id="zipcode"
              label="Zipcode"
              // style={{ margin: 8 }}
              value={state.zipcode}
              // inputRef={nameRef}
              // onChange={updateRef}
              onChange={handleValueChange}
              // ref={inputRef['projectName']}
              placeholder="Zipcode"
              // helperText="Who Sent the Invoice?"
              fullWidth
              // value={inputRef.current['name']}
              margin="normal"
              variant="outlined"
              // InputLabelProps={{
              //   shrink: true,
              // }}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormControl fullWidth >
                <InputLabel id="vendorType">Type of Vendor</InputLabel>
                <Select
                  labelId="vendorType"
                  id="vendorType"
                  value={state.vendorType}
                  onChange={handleSupplierTypeChange}
                  // onChange={(e) => setState({...state, irrigationCheckSchedule: e.target.value })}
                >
                  <MenuItem value={'None'}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Supplier'}>Supplier</MenuItem>
                  <MenuItem value={'Subcontractor'}>Sub-Contractor</MenuItem>
                  <MenuItem value={'Labor'}>Labor</MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
                <FormHelperText>Vendor Type</FormHelperText>
              </FormControl>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormControl fullWidth >
                <InputLabel id="paymentTerms">Payment Terms</InputLabel>
                <Select
                  labelId="paymentTerms"
                  id="paymentTerms"
                  value={state.paymentTerms}
                  onChange={handlePaymentTermsChange}
                  // onChange={(e) => setState({...state, irrigationCheckSchedule: e.target.value })}
                >
                  <MenuItem value={0}>
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={'Net 7'}>Net 7</MenuItem>
                  <MenuItem value={'Net 10'}>Net 10</MenuItem>
                  <MenuItem value={'Net 30'}>Net 30</MenuItem>
                  <MenuItem value={'Net 60'}>Net 60</MenuItem>
                  <MenuItem value={'Other'}>Other</MenuItem>
                </Select>
                <FormHelperText>Payment Terms</FormHelperText>
              </FormControl>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
                {/* <InputLabel id="paymentTerms">Payment Terms</InputLabel> */}
            <TextField
                id="creditLimit"
                label="Credit Limit"
                // style={{ margin: 8 }}
                value={state.creditLimit}
                // inputRef={nameRef}
                // onChange={updateRef}
                onFocus={handleFocus}
                onChange={handleValueChange}
                // ref={inputRef['projectName']}
                placeholder="Credit Limit"
                helperText="Current Credit Limit"
                fullWidth
                // value={inputRef.current['name']}
                // margin="normal"
                // variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
                <InputLabel id="paymentTerms">How Does This Vendor Wish To Receive Payment?</InputLabel>
                <FormControlLabel
                      
                        control={
                          <Switch
                            checked={state.mailChecks}
                            onChange={() => setState({ ...state,  mailChecks: !state.mailChecks })}
                            name="How To Receive Checks?"
                            color="primary"
                          />
                        }
                        label={state.mailChecks ? 'Mailed' : 'Pick-Up'}
                      />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
              <TextField
              id="description"
              label="Vendor Description"
              // style={{ margin: 8 }}
              value={state.description}
              // inputRef={nameRef}
              // onChange={updateRef}
              onChange={handleValueChange}
              // ref={inputRef['projectName']}
              placeholder="Vendor Description"
              helperText="Notes About the Vendor?"
              fullWidth
              // value={inputRef.current['name']}
              margin="normal"
              variant="outlined"
              // InputLabelProps={{
              //   shrink: true,
              // }}
              multiline
              rows={4}
            />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
                <Button
                  color="primary"
                  // fullWidth
                  variant="contained"
                  style={{ width: '50%'}}
                  label="Add Contact"
                  onClick={e => setShowNewContactModal(true)}
                >Add Contact</Button>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
                <ContactListComponent contacts={contactList} state={state} />
              </Grid>
        </Grid>
        {/* {JSON.stringify(contactList)} */}
      </DialogContent>
      <DialogActions>
        <Button color="primary" disabled={loading} onClick={handleSave}>
              {
                loading ? <CircularProgress /> : 'Save'
              }
            </Button>
      </DialogActions>
    </Dialog>
  )
}

// export default NewSuppllierModal
const mapStateToProps = (state) => {
  // console.log('STATE TO PROPS', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    // employees: state.employees,
    // users: state.users
  }
}

export default connect(mapStateToProps)(NewSuppllierModal)
