import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, IconButton }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HoursWorkedModal from './HoursWorkedModal'
import ListOfClients from './ListOfClients'
// import ServiceInputModal from './ServiceInputModal'
import InvoiceInputModal from './InvoiceInputModal'
import InvoiceList from './ListOfInvoices'

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  paper: {
    width: '100%',
    height: '100%',
    minHeight: '80vh',
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  topRowButton: {
    margin: 45,
    marginTop: 10,
    marginBottom: 10,
    width: 276
  }
}))

const Dashboard = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [showHoursWorkedModal, setShowHoursWorkedModal] = React.useState(false)
  const [clientList, setClientList] = React.useState([])
  const [crews, setCrews] = React.useState([])
  const [supplies, setSupplies] = React.useState([])
  const [takeOffs, setTakeOffs] = React.useState([])
  const [services, setServices] = React.useState([])
  const [showServiceInput, setShowServiceInput] = React.useState(false)
  const [showInputInvoiceModal, setShowInputInvoiceModal] = React.useState(false)
  const [activeMtcClients, setActiveMtcClients] = React.useState([])
  const [recentInvoices, setInvoices] = React.useState([])
  
  React.useEffect(() => {
    let intervalId = setInterval(() => {
      fetchInvoices()
    }, 35000)
    let isMounted = true

    const fetchInvoices = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/invoice/listRecentBHLInvoices`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data && isMounted) {
        // console.log('BHL Invoices', response.data)
          setInvoices(response.data)
        }
      })
    }

    fetchInvoices()
    return () => {
      clearInterval(intervalId)
      isMounted = false
    }
  }, [])

  React.useEffect(() => {
    let isMounted = true

    // const fetchActiveMtcAgreements = async () => {
    //   setLoading(true)
    //   axios.get(`${props.url}/api/customer/getActiveMtcAgreements?entity=610a290f45ef7427f94b2e78`).then((response, status, data) => {
    //     setLoading(false)
    //     if (response && response.data) {
    //       if (isMounted) setActiveMtcClients(response.data)
    //     }
    //   })
    // }
    const fetchClients = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/customer/clients?byEntity=610a290f45ef7427f94b2e78`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setClientList(response.data)
        }
      })
    }
    const fetchCrews = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/crews/listCrews`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setCrews(response.data.crews)
        }
      })
    }

    const fetchSupplies = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/company/listBHLSupplies`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setSupplies(response.data)
        }
      })
    }

    const fetchTakeOffs = async () => {
      axios.get(`${props.url}/api/company/listTakeOffs`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
        // console.log('Takeoffs', response.data)
          if (isMounted) setTakeOffs(response.data)
        }
      })
    }

    const fetchServices = async () => {
      axios.get(`${props.url}/api/company/listservices`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
        // console.log('Services', response.data)
          if (isMounted) setServices(response.data)
        }
      })
    }

    fetchClients()
    fetchCrews()
    fetchSupplies()
    fetchTakeOffs()
    fetchServices()
    // fetchActiveMtcAgreements()
    return () => {
      isMounted = false
    }
  }, [])

  const handleInputInvoice = () => {
    setShowInputInvoiceModal(true)
  }

  const handleInputHoursWorked = () => {
    setShowHoursWorkedModal(true)
  }

  const handleInputServices = () => {
    setShowServiceInput(true)
  }

  const TopRow = (props) => {
    return (
      <>
        <HoursWorkedModal  open={showHoursWorkedModal} clients={activeMtcClients} crews={crews} closeModal={e => setShowHoursWorkedModal(false)} supplies={supplies} services={services} takeoffs={takeOffs} />
        {/* <ServiceInputModal open={showServiceInput} clients={clientList} crews={crews} closeModal={e => setShowServiceInput(false) } /> */}
        <InvoiceInputModal open={showInputInvoiceModal} clients={clientList} closeModal={e=>setShowInputInvoiceModal(false)} />
       {/* <Button className={classes.topRowButton} style={{backgroundColor: '#cc8a3f'}} variant="contained" size="small" startIcon={<AddCircleIcon />} onClick={handleInputServices}>
          Input Services Performed
        </Button> */}
        <Button className={classes.topRowButton} disabled={loading} style={{backgroundColor: '#3fc7cc'}} variant="contained" size="small" startIcon={<AddAlarmIcon />} onClick={handleInputHoursWorked}>
          Input Service Visit Details
        </Button>
        <Button className={classes.topRowButton} style={{backgroundColor: '#cc3f4d'}} variant="contained" size="small" startIcon={<NoteAddIcon />} onClick={handleInputInvoice}>
          Input Invoice
        </Button>
      </>
    )
  }

  return (
    <div className={classes.root}>
      
      <Paper className={classes.paper}>
        <h1>BHL Dashboard</h1>
        <TopRow />
        <InvoiceList invoices={recentInvoices} />
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(Dashboard)