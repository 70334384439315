import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
// import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { isEven, findNextAppropriateDate } from '../../../services/common';

const AssignmentConfirmationDialog = ({
  open,
  onClose,
  crew,
  site,
  selectedDate,
  onConfirm,
  jobSiteDetails,
  markers
}) => {
  const [confirmDate, setConfirmDate] = useState(selectedDate);
  const [scheduledDates, setScheduledDates] = useState({});
  const [crewHoursByDate, setCrewHoursByDate] = useState({});
  const [selectedServices, setSelectedServices] = useState({});

  useEffect(() => {
    if (selectedDate) {
      setConfirmDate(selectedDate);
      updateFutureAssignments(selectedDate);
    }
  }, [selectedDate, jobSiteDetails]);

  useEffect(() => {
    if (jobSiteDetails?.servicesRemaining) {
      const initialSelectedServices = {};
      jobSiteDetails.servicesRemaining.forEach((service) => {
        initialSelectedServices[`${service.type}-${service.name}-${service.year}`] = true;
      });
      setSelectedServices(initialSelectedServices);
    }
  }, [jobSiteDetails]);

  const findNextMatchingDate = (lastServiceDate, frequency, preferredDay, evenOrOddWeeks) => {
    console.log('Find Next Date: %s - %s - %s - %s', lastServiceDate, frequency, preferredDay, evenOrOddWeeks);
  
    const today = moment().startOf('day');
    let nextDate = moment(lastServiceDate).startOf('day');
    const preferredDayNum = moment().day(preferredDay).day();
    const targetEvenWeek = evenOrOddWeeks.toLowerCase() === 'even';
  
    // Adjust the date to the preferred day of the week
    const adjustToPreferredDay = (date) => {
      while (date.day() !== preferredDayNum) {
        date.add(1, 'day');
      }
      return date;
    };
  
    // Find the next even/odd week that matches the preferred day
    const findNextEvenOddDay = (date) => {
      let tempDate = date.clone();
      while ((tempDate.week() % 2 === 0) !== targetEvenWeek || tempDate.day() !== preferredDayNum) {
        tempDate.add(1, 'day');
      }
      return tempDate;
    };
  
    // Add the interval and adjust the date, respecting boundaries
    const addIntervalAndAdjust = (interval, minWeeks, maxWeeks) => {
      // Add the minimum interval first (e.g., 10 weeks for quarterly)
      nextDate.add(minWeeks, 'weeks');
  
      // Adjust to the next matching even/odd week and day
      let adjustedDate = findNextEvenOddDay(nextDate.clone());
  
      // If the adjusted date is still too soon (before reaching the maxWeeks limit), keep moving forward
      while (adjustedDate.diff(lastServiceDate, 'weeks') < minWeeks || adjustedDate.diff(lastServiceDate, 'weeks') > maxWeeks) {
        nextDate.add(1, 'week');
        adjustedDate = findNextEvenOddDay(nextDate.clone());
      }
  
      return adjustedDate;
    };
  
    switch (frequency.toLowerCase()) {
      case 'weekly':
        nextDate = addIntervalAndAdjust({ value: 1, units: 'week' }, 1, 1);
        break;
      case 'bi-weekly':
        nextDate = addIntervalAndAdjust({ value: 2, units: 'week' }, 2, 2);
        break;
      case 'monthly':
        nextDate = addIntervalAndAdjust({ value: 4, units: 'week' }, 4, 5); // Monthly roughly every 4-5 weeks
        break;
      case 'quarterly':
        nextDate = addIntervalAndAdjust({ value: 10, units: 'week' }, 10, 14); // Quarterly every 10-14 weeks
        break;
      case 'semi-annually':
        nextDate = addIntervalAndAdjust({ value: 26, units: 'week' }, 26, 26);
        break;
      default:
        console.error('Unsupported frequency:', frequency);
        return null;
    }
  
    return nextDate.toDate();
  };
 
 
  const updateFutureAssignments = (baseDate) => {
    if (!jobSiteDetails?.servicesRemaining) {
      setScheduledDates({});
      return;
    }
  
    const newScheduledDates = {};
    const newCrewHoursByDate = { ...crewHoursByDate };
    let lastScheduledService = jobSiteDetails?.lastWorkorder?.scheduledDate || baseDate;
    const dayOfTheWeek = moment(baseDate).format('dddd');
    // const evenOrOdd = isEven(moment(baseDate).week()) ? 'Even' : 'Odd';
    let weekOfTheYear = moment(selectedDate).week()
    let isItEven = isEven(weekOfTheYear)
    const evenOrOdd = isItEven ? 'Even' : 'Odd';
    const frequency = site?.frequency || 'monthly'
    jobSiteDetails.servicesRemaining.forEach((service, index) => {
      let assignmentDate;
      if (index === 0) {
        assignmentDate = moment(baseDate).toDate();
      } else {
        console.log('Find Assignment date:', lastScheduledService, frequency, dayOfTheWeek, evenOrOdd)
        assignmentDate = findNextAppropriateDate(lastScheduledService, frequency, dayOfTheWeek, evenOrOdd);
        console.log('Assignment date:', assignmentDate)
      }

      const dateString = moment(assignmentDate).format('YYYY-MM-DD');
      newScheduledDates[`${service.type}-${service.name}-${service.year}`] = assignmentDate;
      
      // Calculate or fetch crew hours for this date
      if (!newCrewHoursByDate[dateString]) {
        // Replace this with actual crew hours calculation or fetching logic
        newCrewHoursByDate[dateString] = Math.random() * 8; // Simulated crew hours
      }

      // Add site's estimated hours to the crew's hours for this date
      newCrewHoursByDate[dateString] += Number(site.estimatedHours || 0);
      newCrewHoursByDate[dateString] = Math.round(newCrewHoursByDate[dateString] * 100) / 100;

      lastScheduledService = assignmentDate;
    });

    setScheduledDates(newScheduledDates);
    setCrewHoursByDate(newCrewHoursByDate);
  };

  const handleCheckboxChange = (event, serviceKey) => {
    setSelectedServices(prev => ({
      ...prev,
      [serviceKey]: event.target.checked
    }));
  };

  const handleConfirm = () => {
    const confirmedDates = Object.keys(scheduledDates).reduce((acc, key) => {
      if (selectedServices[key]) {
        acc[key] = scheduledDates[key];
      }
      return acc;
    }, {});

    onConfirm({
      crew,
      site,
      date: confirmDate,
      scheduledDates: confirmedDates,
    });
    onClose();
  };

  const dayOfTheWeek = moment(selectedDate).format('dddd');
  const evenOrOdd = isEven(moment(selectedDate).week()) ? '(Even)' : '(Odd)';
  if (!selectedDate || !crew || !jobSiteDetails || !jobSiteDetails?.servicesRemaining) return null
  return (
    
      <Dialog open={open} onClose={onClose} aria-labelledby="assignment-confirmation-dialog" maxWidth="md" fullWidth>
        <DialogTitle id="assignment-confirmation-dialog">Confirm Assignment</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">
            Assigning {site.frequency} Job ({site.name}) to {crew.name}
          </Typography>
          <Typography variant="subtitle1">
            {dayOfTheWeek} {evenOrOdd}
          </Typography>
          {jobSiteDetails?.servicesRemaining?.length ? (
            <TableContainer component={Paper}>
              <Table aria-label="service scheduling table">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">Confirm</TableCell>
                    <TableCell>Service Period</TableCell>
                    <TableCell>Year</TableCell>
                    <TableCell>Scheduled Date</TableCell>
                    <TableCell>Crew Hours That Day</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {jobSiteDetails.servicesRemaining.map((service, index) => {
                    const serviceKey = `${service.type}-${service.name}-${service.year}`;
                    const scheduledDate = scheduledDates[serviceKey];
                    const dateString = moment(scheduledDate).format('YYYY-MM-DD');
                    return (
                      <TableRow key={index}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedServices[serviceKey] || false}
                            onChange={(event) => handleCheckboxChange(event, serviceKey)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {service.name}
                        </TableCell>
                        <TableCell>{service.year}</TableCell>
                        <TableCell>
                          {scheduledDate ? moment(scheduledDate).format('YYYY-MM-DD') : 'N/A'}
                        </TableCell> 
                        <TableCell>{crewHoursByDate[dateString] ? crewHoursByDate[dateString].toFixed(2) : 'N/A'}</TableCell>      
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" variant="contained">
            Confirm Assignment
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default AssignmentConfirmationDialog;