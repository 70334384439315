import React from 'react';

const AudioPlayer = ({ audioURL }) => {
  return (
    <audio controls>
      <source src={audioURL} type="audio/mp4" />
      Your browser does not support the audio element.
    </audio>
  );
};

export default AudioPlayer;
