import React, { useState } from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import { Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';

const HolidayModule = ({ updateBackEnd, currentHolidays }) => {
  const [holidays, setHolidays] = useState(currentHolidays);
  const [open, setOpen] = useState(false);
  const [currentHoliday, setCurrentHoliday] = useState('');
  const [editIndex, setEditIndex] = useState(-1);

  const handleClickOpen = () => {
    setCurrentHoliday('');
    setEditIndex(-1);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    let updatedHolidays = [...holidays];
    if (editIndex >= 0) {
      updatedHolidays[editIndex] = currentHoliday;
    } else {
      updatedHolidays.push(currentHoliday);
    }
    setHolidays(updatedHolidays);
    updateBackEnd(updatedHolidays);
    handleClose();
  };

  const handleDelete = (index) => {
    const updatedHolidays = holidays.filter((_, i) => i !== index);
    setHolidays(updatedHolidays);
    updateBackEnd(updatedHolidays);
  };

  const handleEdit = (index) => {
    setCurrentHoliday(holidays[index]);
    setEditIndex(index);
    setOpen(true);
  };

  return (
    <div>
      <Typography variant="h6">Holiday List</Typography>
      <List>
        {holidays.map((holiday, index) => (
          <ListItem key={index}>
            <ListItemText primary={holiday} />
            <ListItemSecondaryAction>
              <IconButton edge="end" aria-label="edit" onClick={() => handleEdit(index)}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Add Holiday
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editIndex >= 0 ? 'Edit Holiday' : 'Add Holiday'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Holiday Date"
            type="date"
            value={currentHoliday}
            onChange={(e) => setCurrentHoliday(e.target.value)}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default HolidayModule;
