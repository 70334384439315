import React from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { Button, CircularProgress, IconButton, Paper, Checkbox }  from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { currencyFormat, currencyFormatWithOutFlair } from '../../services/common'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DailyLogTable from './HMIDailyLogsTable'
import DailyLogInputModal from './DailyLogInputModal'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import ViewDailyLogDetails from './ViewDailyLogDetails'

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  groupContainer: {
    border: '3px solid #ddd',
    borderRadius: 8,
    padding: 10,
    marginBottom: theme.spacing(3),
  },
  topRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignSelf: 'stretch',
    alignItems: 'center'
  },
  headerArea: {
    display: 'flex',
    flexGrow: 1,
    // backgroundColor: 'red',
    fontSize: '1.3em',
    fontWeight: 'bold'
  },
  detailsArea: {
    display: 'flex',
    flexGrow: 3,
    justifyContent: 'space-evenly',
    alignContent: 'flex-start',
    alignSelf: 'stretch'
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  },
  logItemContainer: {
    display: 'flex',
    justifyContent: 'space-between', 
    margin: '5px', 
    padding: '10px'
  },
  logItemDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    alignItems: 'center',
    flexGrow: 3
  },
  dumpFeeArea: {
    marginLeft: 20,
    alignItems: 'center',
    width: '15%',
    justifyContent: 'center',
    display: 'flex',
  },
  actionArea: {
    marginLeft: 20,
  },
  topRowButton: {
    margin: 45,
    marginTop: 10,
    marginBottom: 10,
    width: 276
  },
  summaryOutputContainer: {
    display: 'flex',
    alignSelf: 'stretch'
  },
  summaryDataContainer: {
    margin: 20,
    padding: 20
  },
  centerCell: {
    textAlign: 'center'
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  }
}))

const defaultFilters = {
  'ADOT': true,
  'Northern Parkway': true,
  'County': true,
  'Commercial': true,
}

const defaultStatevalues = {
  filters: defaultFilters
}

const HMIDailyLogs = (props) => {
  const classes = useStyles();
  // console.log("HMIDailyLogs", props)
  const [rows, setRows] = React.useState([])
  // const [filteredRows, setFilteredRows] = React.useState([])
  const [showEditModal, setShowEditModal] = React.useState(false)
  const [activeItem, setActiveItem] = React.useState({})
  const [ags, setAgs] = React.useState([])
  const [mtcSites, setMtcSites] = React.useState([])
  const [state, setState] = React.useState(defaultStatevalues)
  const [showViewDetailsModal, setShowViewDetailsModal] = React.useState(false)

  
  React.useEffect(() => {
    let isMounted = true

    const fetchDailyLogs = async () => {
      axios.get(`${props.url}/api/hmi/fetchDailyLogs?forApproval=1`).then((response, status, data) => {
        if (response && response.data) {
        console.log('Daily Logs', response.data)
        setRows(response.data)
        // setFilteredRows(response.data)
        }
      })
    }
    const fetchActiveMtcAgreements = async () => {
      // setLoading(true)
      axios.get(`${props.url}/api/hmi/getActiveMtcAgreements`).then((response, status, data) => {
        // setLoading(false)
        if (response && response.data) {
          console.log('Ags:', response.data)
          if (isMounted) setAgs(response.data)
        }
      })
    }

    const fetchAllMtcSites = async () => {
      axios.get(`${props.url}/api/hmi/getActiveSites`).then((response, status, data) => {
        // setLoading(false)
        if (response && response.data && response.data.length) {
        console.log('Sites', response.data)
          if (isMounted && response.data) {
            setMtcSites(response.data)
          }
        }
      })
    }

    fetchDailyLogs()
    fetchActiveMtcAgreements()
    fetchAllMtcSites()
    return () => {
      isMounted = false
    }
  }, [])

  React.useEffect(() => {
    console.log('Rows changed...check our filters', state.filters)

  }, [rows])

  const handleDelete = (itemToDelete) => {
    console.log('delete this', itemToDelete)
    let newRows = [...rows].filter(item => (item._id !== itemToDelete._id))
    console.log('New rows to set', newRows)
    setRows(newRows)
    // setFilteredRows(newRows)
  }

  const handleEdit = (itemToEdit) => {
    console.log('Edit this ', itemToEdit)
    
    setActiveItem(itemToEdit)
    setShowEditModal(true)
  }

  const handleDailyLogInput = () => {
    setActiveItem(null)
    setShowEditModal(true)
  }


  const handleResponseFromDailyInput = (data, type) => {
    console.log('Daily Input Response', data)
    console.log('Type', type)
    if (type === 'new') {
      console.log('Add this to our daily logs...', data)
      let newDataLogs = [...rows, ...data]
      console.log('New dta logs', newDataLogs)
      setRows(newDataLogs)
    }
    if (type === 'update') {
      console.log('UPdate this guy', data)
      let newRows = [...rows].map(item => {
        if (item._id === data._id) {
          return data
        } else {
          return item
        }
      })
      console.log('New ros', newRows)
      setRows(newRows)
      setShowEditModal(false)
    }
    if (type === 'close') setShowEditModal(false)
  }

  const toggleFilters = (type) => {
    // console.log('Toggle Filters', type)
    let isChecked = state.filters[type]
    // console.log('is checked', isChecked)
    let newFilters = {
      ...state.filters,
      [type]: !isChecked
    }
    // console.log('new Filters', newFilters)

    // setFilteredRows(filteredItems)
    setState({...state, filters: newFilters })
  }

  const handleViewDetails = (itemId) => {
    console.log('View Details', itemId)
    const itemToView = rows.find(item => (item._id === itemId))
    if (itemToView && itemToView._id) {
      setActiveItem(itemToView)
      console.log('This is it', itemToView)
      setShowViewDetailsModal(true)
    }
  }

  const handleUpdateFromViewDailyLogDetails = (type, data) => {
    setShowViewDetailsModal(false)
    console.log('Update from daily log details...', type)
    console.log(data)
    if (data && data._id && type === 'update') {
      let newRows = [...rows]
      let index = newRows.findIndex(item => (data._id === item._id))
      if (index > -1) {
        newRows[index] = data
        setRows(newRows)
        // setFilteredRows(newRows)
      }
    }
  }

  const handleApproveDailyLogItem = (logId) => {
    console.log('This one needs to be approved...', logId)
    axios.post(`${props.url}/api/hmi/approveDailyLog`, { logId }).then((response, status, data) => {
      console.log('Response from approval', response)
      if (response && response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Approved',
          message: 'We have approved that log item!!'
        })
        let newRows = [...rows]
        let index = newRows.findIndex(item => (logId === item._id))
        if (index > -1) {
          newRows.splice(index, 1)
          setRows(newRows)
          // setFilteredRows(newRows)
        }
      }
    }).catch((err, status) => {
      console.log('ERror status:', status)
      console.log(err)
    })
  }

  let filteredRows = rows.filter(item => {
    // console.log('Item to check??', item)
    const newFilters = state.filters
    let customerType = item.logType
    // console.log('Filter:', newFilters[customerType])
    if (newFilters[customerType]) {
      return item
    }
  })
  // console.log('Filtered rows!', filteredRows)


  return (
  <div className={classes.root}>
    <ViewDailyLogDetails open={showViewDetailsModal} item={activeItem} updateParent={handleUpdateFromViewDailyLogDetails} ags={ags} />
     <DailyLogInputModal notification={props.notification} url={props.url} mtcSites={mtcSites} open={showEditModal} itemToEdit={activeItem} ags={ags} crews={props.crews} updateParent={handleResponseFromDailyInput} />
    <Paper className={classes.paper}>
      <h1>Daily Log Data</h1>
    
      <Button className={classes.topRowButton} style={{backgroundColor: '#aaeeff'}} variant="contained" size="small" startIcon={<LibraryAddIcon />} onClick={handleDailyLogInput} >
        Input Daily Log
      </Button>
      <div className={classes.filterContainer}>
        <CheckBoxItem name="ADOT" filters={state.filters} toggleFilters={toggleFilters} />
        <CheckBoxItem name="Northern Parkway" filters={state.filters} toggleFilters={toggleFilters} />
        <CheckBoxItem name="County" filters={state.filters} toggleFilters={toggleFilters} />
        <CheckBoxItem name="Commercial" filters={state.filters} toggleFilters={toggleFilters} />
      </div>
      <DailyLogTable {...props} mtcSites={mtcSites} ags={ags} rows={filteredRows} deleteDailyLogItem={handleDelete} editDailyLogItem={handleEdit} viewItemDetails={handleViewDetails} approveDailyLogItem={handleApproveDailyLogItem} />
    </Paper>
  </div>
  )
}

const CheckBoxItem = ({ name, filters, toggleFilters }) => {
  const isChecked = filters[name]
  console.log('Is checked', isChecked)
  return (
    <div>
       <Checkbox
            checked={isChecked}
            onChange={()=> toggleFilters(name)}
        />
        <div>{name}</div>
    </div>
  )
}
      
const SummaryDataTable = (props) => {
  const classes= useStyles()
  console.log('Return a summary table', props)
  let data = props.summaryData
  let totalVisits = 0
  let totalToBill = 0
  return (
    <div className={classes.summaryDataContainer}>
      Summary data
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              Site Name
            </TableCell>
            <TableCell >
              Preferred Day
            </TableCell>
            <TableCell  >
             Frequency
            </TableCell>
            <TableCell align='center' >
              # of Visits
            </TableCell>
            <TableCell align='center'  >
              Per Visit Amount
            </TableCell>
            <TableCell  align='center' >
              Total To Bill
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {
          data.map((item, index) => {
            // console.log('County Summary data item:', item)
            let totalAmountForThisSite = 0
            if (item.numberOfVisits && item.amount && item.amountType === 'perVisit') totalAmountForThisSite = item.numberOfVisits * Number(item.amount)
            totalToBill += totalAmountForThisSite
            totalVisits += item.numberOfVisits
            return (
              <TableRow key={index}>
                <TableCell>
                {item.communityName ? item.communityName : null} {item.name} {item.buildingNumber ? `(Building #${item.buildingNumber})` : null}
                </TableCell>
                <TableCell >
                  {item.preferredDay}
                </TableCell  >
                <TableCell  >
                  {item.frequency}
                </TableCell>
                <TableCell align='center' >
                  {item.numberOfVisits}
                </TableCell>
                <TableCell align='center' >
                  {currencyFormat(item.amount)}
                </TableCell>
                <TableCell align='center' >
                 {currencyFormat(totalAmountForThisSite)}
                </TableCell>
                
              </TableRow>
            )
          })
        }
         <TableRow key={'abc'}>
                <TableCell>
                Total
                </TableCell>
                <TableCell >
                 
                </TableCell  >
                <TableCell  >
                
                </TableCell>
                <TableCell align='center' >
                 {totalVisits}
                </TableCell>
                <TableCell align='center' >
                
                </TableCell>
                <TableCell align='center' >
                 {currencyFormat(totalToBill)}
                </TableCell>
                
              </TableRow>
        </TableBody>
      </Table>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    entities: state.entities,
    perms: state.perms,
    crews: state.crews
  }
}

export default connect(mapStateToProps)(HMIDailyLogs)