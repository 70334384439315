import React from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import axios from 'axios'
import { socket } from '../SocketIo/Socket'
import BatchStatementDetails from './BatchStatementDetails'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: '14px'
  }
}));

const debug = false

const StatementOptionsModal = (props) => {
  // console.log('Got our statement modal', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [statementName, setStatementName] = React.useState('')
  const [batchStatement, setBatchStatement] = React.useState({})
  const [statementMessage, setStatementMessage] = React.useState('')
  const [state, setState] = React.useState({
    statement: {
      start_time: null,
      pdfsGenerated: 0,
      name: 'TBD'
    },
    numberRun: 0,
    clients: 0,
    isRunning: true
  })
// const handleChange = (event) => {
//   setRange(event.target.value);
//   let newStartDate = moment().subtract(event.target.value, 'days')
//   // console.log('New start Date', newStartDate)
//   setStartDate(newStartDate)
//   setOpen(false);
// };
// React.useEffect(() => {
//   // console.log('Set Statement Name')
//   const date = new Date()
//   // console.log('Date: ', date)
//   const month = date.toLocaleString('default', { month: 'short' });
//   // console.log('Month: ', month)
//   const year = date.getFullYear()
//   // console.log('Year: ', year)
//   setStatementName(month + year)
// }, [props])

// React.useEffect(() => {
//   socket.on('batchStatement', function (msg) {
//     // console.log('GOT A SOCKET MESSAGE: ', msg)
//     // setInProcess(true)
//     // setButtonVisibility('none')
//     // let newMessage = msg
//     // if (!msg.client) newMessage.client = statementMessage.client
//     // if (!msg.step) newMessage.step = statementMessage.step
//     // if (isNaN(msg.step)){
//     //   newMessage.step = msg.step
//     // } else {
//     //   newMessage.step = ''
//     // }
//     // if (!msg.message) newMessage.message = statementMessage.message
//     // if (msg && msg.step === 'Complete') {
//     //   setInProcess(false)
//     //   newMessage.client = ''
//     //   newMessage.step = 'Process Complete'
//     //   setTimeout(function () {
//     //     // console.log('Hide this shit and show the button again')
//     //     setVisibility('hidden')
//     //     setButtonVisibility('inline-block')
//     //     setInProcess(false)
//     //   }, 10000)
//     // } else {
//     if (msg && msg.message) {
//       setStatementMessage(msg.message)
//       setState({...state, numberRun: msg.numberRun, clients: msg.clients })
//     } else {
//       setStatementMessage(msg)
//     }
//     // setVisibility('visible')
//     // }
//   })
//   return () => {
//     socket.off('batchStatement')
//   }
// }, [])

React.useEffect(() => {
  const date = new Date()
  let nameOfStatement = 'Test2021'
  // console.log('Date: ', date)
  const month = date.toLocaleString('default', { month: 'short' });
  // console.log('Month: ', month)
  const year = date.getFullYear()
  // console.log('Year: ', year)
  if (debug) {
    setStatementName('Test2022')
  } else {
    setStatementName(month + year)
    nameOfStatement = month + year
  }

  const getStatementDetails = async () => {
    axios({
      method: 'get',
      url: `${props.url}/api/invoice/getBatchStatementStatus?name=${nameOfStatement}`,
    })
      .then(({ data }) => {
        // console.log('Statement Status: ', data)
        // setLeads(data)
        if (data && data._id) {
          setBatchStatement(data)
          setState({...state, isRunning: data.isRunning, statement: data, clients: data.totalCustomers, numberRun: data.pdfsGenerated})
        } else {
          // No Statement
          setState({...state, isRunning: false, name: `${nameOfStatement}`})
        }
      }).catch(function (err) {
        // console.log('Error getting stuff', err)
      });
  }
  getStatementDetails()
}, [])
// const handleClose = () => {
//   setOpen(false);
//   // props.onUpdate()
// }

// const handleOpen = () => {
//   setOpen(true);
// };

const handleSubmit = () => {
  // console.log('Submit this statement!!')
  // setStartDate(moment(new Date()).subtract(30, 'days'))
  // setEndDate(new Date())
  // setRange(30)
  // props.onUpdate(startDate, endDate, newWay)
  setState({...state, isRunning: true })
    axios({
    method: 'post',
    url: `${props.url}/api/invoice/runMonthEnd`,
    data: {
      user: props.user,
      name: statementName,
      limit: 9999
    }
  })
    .then(({ data }) => {
      // console.log('Statement Started', data)
      if (data && data._id) setState({...state, isRunning: true, statement: data, clients: data.totalCustomers, numberRun: data.pdfsGenerated})
      // setLeads(data)
    }).catch(function (err) {
      // console.log('Error getting stuff', err)
    });
}

const handleCloseModal = () => {
  props.closeModal()
}
  return (
    <div>
        <Dialog
          // fullScreen={true}
          maxWidth='sm'
          open={props.open}
          // onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
          >
          <DialogTitle id="responsive-dialog-title">Month End Details</DialogTitle>
          <DialogContent>
            { state.statement._id ? (
              <Grid container spacing={2}>
                <BatchStatementDetails statementData={state} statementName={statementName} batch={batchStatement} />
              </Grid>
            ) : (
              <Grid container spacing={2}>
                <Grid item lg={12} xs={12}>
                  <div className={classes.label}>
                    Statement Name
                  </div>
                  {statementName}
                </Grid>
                {/* <Grid item lg={12} xs={12}>
                  <div className={classes.label}>
                    Statement Start Time
                  </div>
                  {state.statement.start_time ? moment(state.statement.start_time).format('ddd MMM DD YYYY - hh:mmA') : 'TBD'}
                </Grid>
                <Grid item lg={12} xs={12}>
                  <div className={classes.label}>
                    PDFs Generated
                  </div>
                  {state.numberRun ? state.numberRun : 0}
                </Grid> */}
                <Grid item lg={12} xs={12}>
                  Please click Submit when you are ready to start running this Month End.
                </Grid>
                {/* <Grid item lg={12} xs={12}>
                  {state.numberRun ? state.numberRun : 0} of {state.clients ? state.clients : 0} Complete
                </Grid>
                <Grid item lg={12} xs={12}>
                  {statementMessage ? statementMessage : ''}
                </Grid> */}
              </Grid>
            ) }

          </DialogContent>
          <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>

          <Button disabled={state.isRunning || batchStatement.isRunning} onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
          </Dialog>
    </div>
  )
 

}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(StatementOptionsModal))