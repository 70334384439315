import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import SupplyInventoryInputItem from './SupplyInventoryInputItem';
import NewSuppllierModal from './NewSupplierModal';
import ShedInventoryItem from './ShedInventoryItem'
import LineItem from './ModifyTakeOffItem'

const ConfigurationContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
align-content: center;
text-align: center;
border-radius: 15px;
height: 110px;
margin-top: 25px;
width: 100%;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  }
}));


const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

const defaultValues = {
  qty: 0,
  unit: '',
  description: '',
  totalPrice: 0,
  costPer: 0,
  pricePer: 0,
  inventoryItem: null
}

const EditLineItemModal = (props) => {
  // console.log('Edit Line Item 81: ', props)
  const classes = useStyles();
  const [state, setState] = React.useState(defaultValues)
  const [inventoryItem, setInventoryItem] = React.useState({})

  React.useEffect(() => {
    let isMounted = true
    const fetchInventoryItem = async (id) => {
      axios({
        method: 'get',
        url: `${props.url}/api/inventory/findInventoryItem?_id=${id}`
      }).then(response => {
      // console.log('Got Our inventory item: ', response.data)
        if (isMounted) {
        // console.log('SEt inventory item', response.data)
          setInventoryItem(response.data)
        }
      })
    }
    
  // console.log('Item has changed (EDIT LINE ITEM)', props.item)
    if (props.item && props.item.description) {
    // console.log('descriptiopn 100: ', props.item)
      let inventoryItem = null
      if (props.item) {
        if (props.item.inventoryItem) inventoryItem = props.item.inventoryItem
      // console.log('set State: ')
        // setState({...state, inventoryItem: inventoryItem, qty: props.item.qty, description: props.item.description, costPer: props.item.costPer, pricePer: props.item.pricePer, totalPrice: props.item.totalPrice })  
      }
      if (props.item && props.item.inventoryItem) {
        // console.log('Get iNventory Item!!!!!')
        fetchInventoryItem(props.item.inventoryItem)
      } else {
        setInventoryItem({})
      }
    }

  // If we have an inventory item attached we need to get the info there

    
    // if (props.item && props.item.sku) {
    //   let pricePer = 0
    //   let totalPrice = 0
    //   let costPer = props.item.totalPrice
    //   pricePer = Number(props.item.pricePer).toFixed(2)
    //   totalPrice = Number(props.item.totalPrice).toFixed(2)
    //   setState({...state, description: props.item.name, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice })
    // } else {
    //   if (props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length === 1) {
    //     setState({...state, unit: props.item.unitsOfMeasure[0], description: props.item.description})
    //   } else if (props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length < 1) {
    //     setState({...state, unit: 'EA', description: props.item.description})
    //   } else {
    //     setState({...state, description: props.item.description})
    //   }
    // }
    return () => {
      isMounted = false
    }
  }, [props.item])

  const handleSaveNewLineItem = () => {
    // console.log('Save Line Item', state)
    // Add this to our Estimate
    let itemToSend = {
      ...state
    }
  // console.log('Item tos ave: , ', itemToSend)
    // itemToSend.item = props.item
    setState(defaultValues)
    props.updateLineItem(itemToSend)
  }

  const handleFocus = (e) => {
    e.preventDefault()
    e.target.focus()
    e.target.setSelectionRange(0, e.target.value.length)
  }

  const handleCostChangeUpdate = (e) => {
    let pricePer = 0
    let totalPrice = 0
    let costPer = e.target.value
    pricePer = Number(costPer * 1.4).toFixed(2)
    totalPrice = Number(pricePer * state.qty).toFixed(2)
    setState({...state, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice})
  }

  const handlePriceManualChange = (e) => {
    let pricePer = e.target.value
    let totalPrice = 0
    totalPrice = Number(pricePer * state.qty).toFixed(2)
    setState({...state, pricePer: pricePer, totalPrice: totalPrice})
  }

  const handleQtyUpdate = (e) => {
    let pricePer = state.pricePer
    let totalPrice = 0
    totalPrice = Number(pricePer * e.target.value).toFixed(2)
    setState({...state, qty: e.target.value, totalPrice: totalPrice})
  }

  const handleClose = () => {
    props.closeModal()
  }

  const handleDelete = () => {
    props.deleteItem(props.item)
  }

  const updateInentoryLineItem = (d, c) => {
  // console.log('UPdating Inventory Linte Ite; ', d)
  // console.log(c)
    let itemToSend = {
      ...d
    }
    itemToSend.itemToEdit = itemToSend.item
    itemToSend.item = null
  // console.log('Item to save as updated line item:::: , ', itemToSend)
    // itemToSend.item = props.item
    setState(defaultValues)
    props.updateLineItem(itemToSend)
  }

  const InventoryItemDetails = (props) => {
    let item = props.item
    // console.log('ITEM 187: ', item)
    // <>{JSON.stringify(inventoryItem)}</>
    return (
     
      <>
      <LineItem takeOffList={props.takeOffList} state={state} item={item} estimate={props.estimate} addLineItemToEstimate={updateInentoryLineItem} />

      </>
    )
  }

  return (
    <Dialog maxWidth={'lg'} fullWidth open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <form>
      <DialogTitle id="form-dialog-title">Edit Line Item</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please Make Your Line Item Edits:
        </DialogContentText>
        { (inventoryItem && inventoryItem._id) ? (
          <InventoryItemDetails item={inventoryItem} takeOffList={props.takeOffList} estimate={props.estimate} />
        ): <InventoryItemDetails item={props.item} takeOffList={props.takeOffList} estimate={props.estimate} />}
        <ConfigurationContainer>
      

        {/* <Grid item lg={1} >
          <TextField
              label="Quantity"
              variant="outlined"
              type="text"
              value={state.qty}
              onChange={handleQtyUpdate}
              onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2}>

        <FormControl variant="outlined" style={{width: '100%'}}>
        <InputLabel id="demo-simple-select-outlined-label">Unit</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.unit}
          onChange={e=>setState({...state, unit: e.target.value})}
          label="Unit"
        >
          {props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length ? (
             props.item.unitsOfMeasure.map(unit => {
               return (
               <MenuItem key={unit.name} value={unit.name} >{unit.name}</MenuItem>
             )})
          ) : (
            <MenuItem value={'EA'} >Each</MenuItem>
          )}
        </Select>
      </FormControl>
        </Grid>
        <Grid item lg={5}>
          <TextField
            label="Description"
            variant="outlined"
            type="text"
            value={state.description || ''}
            onChange={e=>setState({...state, description: e.target.value})}
            fullWidth
            />
        </Grid>
        <Grid item lg={1}>
          <TextField
            label="Cost Per"
            variant="outlined"
            type="text"
            value={state.costPer}
            onChange={handleCostChangeUpdate}
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={1}>
          <TextField
            label="Price Per"
            variant="outlined"
            type="text"
            value={state.pricePer}
            onChange={handlePriceManualChange}
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2}>
          <TextField
            label="Total Price"
            variant="outlined"
            type="text"
            value={state.totalPrice}
            onChange={e=>setState({...state, totalPrice: e.target.value})}
            onFocus={handleFocus}
            disabled
            />
        </Grid>
      </Grid> */}
    </ConfigurationContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete} color="secondary">
          Delete
        </Button>
        <Button onClick={handleSaveNewLineItem} color="primary">
          Save
        </Button>
      </DialogActions>
    </form>
   </Dialog>
  )
}
const mapStateToProps = (state) => {
  // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
  }
}

export default connect(mapStateToProps)(EditLineItemModal)
