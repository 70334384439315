import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';

const BadgeModal = (props) => {
  // console.log('Badge Modal', props)
  const [state, setState] = React.useState({
    absenceType: 'off',
    notes: ''
  })
  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleSubmit = () => {
    const msg = `Are you sure you wish to order this badge for ${props.employee.firstName}? This will cost approximately $10 and we cannot undo it once you have confirmed.`
    if (window.confirm(msg)) {
      console.log('Order it!')
      const obj = {
        badgeId: props.badgeData?.id,
        employee: props.employee
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/employees/orderBadge?type=order`,
        data: obj
      }).then(response => {
      // console.log('Absence Reported', response.data)
        props.notification({
          type: 'success',
          title: 'Badge Ordered',
          message: `This badge has been added to our list of orders for ${props.employee.firstName}.`
        })
        props.updateParent('absent', state.absenceType)
      }).catch(err => {
      // console.log('Error reporting absence', err)
        props.notification({
          type: 'warning',
          title: 'Error Ordering Badge',
          message: `Error Ordering Badge (Error Code: BM50)`
        })
      })
    }
  }
  const img = `data:image/jpeg;base64,${props.badgeData?.data?.front}`
  // console.log('Image', img)
  const backImg =  `data:image/jpeg;base64,${props.badgeData?.data?.back}`
  // const img2 = 'data:image/jpegbase64,/9j/4AAQSkZJRgABAQEAAAAAAAD/4QAiRXhpZgAATU0AKgAAAAgAAQESAAMAAAABAAEAAIdpAAQAAAABAAAAJgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAA7aADAAQAAAABAAABPAAAAAD/2wBDAAoHBwkHBgoJCAkLCwoMDxkQDw4ODx4WFxIZJCAmJSMgI3...'
return (
  <div>
    
      <Dialog
        // fullScreen={false}
        maxWidth={'lg'}
        // width='lg'
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Badge -  {props.employee.displayName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Verify the Information In The Badge Below Is Correct:
          </DialogContentText>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '25px', marginBottom: '25px'}}>
            <img src={img} />
            <img src={backImg} />
          </div>
          
          <DialogContentText>
            If everything looks good, you may press Order below to add this to our next Badge Order. We order badges as-needed and after the order is placed, it will take approximately 3-5 Business Days for the badge to arrive.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSubmit()} disabled={props.disabled} color="primary">
            Order
          </Button>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(BadgeModal)