import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import InputMask from "react-input-mask";

const EmployeePhoneInput = (props) => {
  const classes = useStyles();
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [error, setError] = React.useState({
    message: '',
    isError: false
  })
  const [success, setSuccess] = React.useState({
    message: '',
    isActive: false
  })
  const handleChange = (e) => {
    setPhoneNumber(e.target.value)
  }

  const handleSend= () => {
    // console.log(phoneNumber)
    setError({...error, isError: false, message: '' })
    setSuccess({...success, message: '', isActive: false})
    axios({
      method: 'post',
      url:  `${props.url}/api/company/updateEmployeePhoneNumber`,
      data: { phoneNumber },
    }).then(response => {
      console.log('match phone: ', response.data)
      if (response && response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Sent!',
          message: response.data.message
        })
        // handleClose()
        setSuccess({...success, message: response.data.message, isActive: true})
      }
      if (response && response.data && response.data.error) {
        props.notification({
          type: 'warning',
          title: 'System Error Sending SMS',
          message: response.data.message
        })
        setError({...error, message: response.data.message, isError: true })
      }

    }).catch(err => {
      // // console.log('Error caught when uploading pic: ', err)
      props.notification({
        type: 'warning',
        title: 'Failed to Send!',
        message: err.message
      })
    })
  }
  const isInvalid = (phoneNumber.replace(/\D/g, '').length < 10) ? true : false

  return (
    <Paper className={classes.paper}>
      <Grid container >
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          <Typography variant="h4" className={classes.title}>
            Update Employee Phone
            <br/>
            Actualizar teléfono del empleado
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          <Typography variant="subtitle1" className={classes.subText}>
            Por favor proporciónenos un buen número de teléfono donde pueda recibir un mensaje de texto. Le enviaremos un enlace a ese número de teléfono ahora.
            <br/>
            Please provide us a good telephone number where you can receive a text message. We will send you a link to that phone number now.
           
          </Typography>
         
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          <InputMask mask="(999) 999-9999" maskPlaceholder=" " onChange={handleChange}  value={phoneNumber} fullWidth>
              {
                () => {
                  return (
                  <TextField
                    label="Telephone / Teléfono" 
                    className={classes.telephone}
                    fullWidth
                    helperText={'Enter Your Phone Number / Ingrese su número telefónico'}
                    // onChange={handleChange}
                />
                  )
                }
              }
          </InputMask>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          <Button
            onClick={handleSend}
            variant="contained"
            color="primary"
            fullWidth
            disabled={isInvalid}
          >SUBMIT / ENTREGAR</Button>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          {
            (error && error.isError) && (
              <div  style={{ backgroundColor: 'rgba(244, 54, 58, 0.4)', borderRadius: 5, padding: 20, margin: 20 }}>
                Error: {error.message}
              </div>
            )
          }
                    {
            (success && success.isActive) && (
              <div style={{ backgroundColor: 'rgba(54, 244, 58, 0.4)', borderRadius: 5, padding: 20, margin: 20 }}>
                 {success.message}
              </div>
            )
          }
        </Grid>
      </Grid> 
  </Paper>
  )
}



  const useStyles = makeStyles((theme) => ({
    paper: {
      maxWidth: '50%', // it will take 50% on large screens
      width: '100%',   // it will take full width on small screens
      margin: '0 auto', // this centers the Paper horizontally
      padding: theme.spacing(4), // some internal padding
      [theme.breakpoints.down('sm')]: { // breakpoint for small screens
        maxWidth: '100%',
      },
      marginTop: '10%'
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(2), // some space below the title
    },
    subText: {
      textAlign: 'left',
      fontSize: '1.2em',
      color: '#bbb',
      marginBottom: theme.spacing(4), // some space below the subtext
    },
    telephone: {
      // maxWidth: '200px', // adjust as needed
    },
    paddedGridItem: {
      padding: 10,
      margin: 10
    }
  }));

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(EmployeePhoneInput)