import React, { useState, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputMask from "react-input-mask";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid'
import { fade, makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Cards from 'react-credit-cards';
// import { createForm } from 'final-form'
// import Styles from './Styles'
import Card from '../Customer/Card'
// import {
//   formatCreditCardNumber,
//   formatCVC,
//   formatExpirationDate
// } from './cardUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  textSaveLineBreaks: {
    whiteSpace: 'pre-line'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  disclaimerText: {
    fontSize: '.8em',
    textAlign: 'center',
    color: '#aaa'
  }
}));

let requiresUpdate = 0;

const defaultStateValues = {
  isBusiness: false,
  cvc: '',
  expiry: '',
  expiryInput: '',
  focused: '',
  name: '',
  number: '',
  numberInput: '',
  name: '',
  firstname: '',
  lastname: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  client: {},
  customerType: ''
}

function InputCreditCardDetailsForm(props) {

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [locationName, setLocationName] = React.useState('');
  const [individual, setIndividual] = React.useState(true);
  const [address, setAddress] = React.useState('');
  const [city, setCity] = React.useState('');
  const [locState, setLocState] = React.useState('');
  const [zipcode, setZipcode] = React.useState('');
  const [saveCardDetails, setSaveCardDetails] = React.useState(false)
  const [state, setState] = React.useState(defaultStateValues);

  const [formIsValid, setFormIsValid] = useState(false);

const checkIfValidForm = () => {
  // Example condition: checks if all necessary state fields are filled and valid
  let nameIsValid = false
  if (!state.isBusiness) {
    if (state.firstname.trim() !== '' && state.lastname.trim() !== '') nameIsValid = true
  } else {
    if (state.companyname.trim() !== '') nameIsValid = true
  }
  return (
    nameIsValid&&
    state.address.trim() !== '' &&
    state.city.trim() !== '' &&
    state.state.trim() !== '' &&
    state.zipcode.trim() !== '' &&
    state.numberInput.trim() !== '' &&
    state.expiryInput.trim() !== '' &&
    state.cvc.trim() !== ''
  );
};

// Using useEffect to update the form's validity state whenever relevant state changes
useEffect(() => {
  setFormIsValid(checkIfValidForm());
}, [state.firstname, state.lastname, state.companyname, state.address, state.city, state.state, state.zipcode, state.numberInput, state.expiryInput, state.cvc]);


  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Handle submit...', e)
    let data = {
      ...state,
      individual: !state.isBusiness
    }
    props.updateParent(data)
  }

  const handleChangeType = () => {
    setState({...state, isBusiness: !state.isBusiness})
  }
  const updateCardNumber = (value) => {
    // console.log('Update mask', value)
    let result = value.replace(/[^0-9]/g,"")
    setState({...state, numberInput: value, number: result })
  }

  const updateCardExpiration = (value) => {
    // console.log('Update mask', value)
    let result = value.replace(/[^0-9]/g,"")
    setState({...state, expiryInput: value, expiry: result })
  }
  

  const divStyle = {
    // margin: '40px',
    // border: '5px solid pink',
    display: 'inline'
  };
  const isBusiness = state.isBusiness
  const isInvalid = checkIfValidForm()
  return (
    <div style={ divStyle }>
          <Card
            cvc={state.cvc}
            expiry={state.expiry}
            focused={state.focus}
            name={state.name}
            number={state.number}
        />
          <ValidatorForm
                id="myForm"
                // ref="myForm"
                onSubmit={ handleSubmit }
                onError={ errors => console.log(errors) }
                // className={ styles.content }
              >
                <Grid container>
                <Grid item lg={12} xs={12} className={classes.gridItem}> 
                  Choose Individual or Business: <br />
                  <FormControlLabel
                        control={
                          <Switch
                            checked={!isBusiness}
                            onChange={handleChangeType}
                            name="Individual"
                            color="primary"
                          />
                        }
                        label={!isBusiness ? 'Individual' : 'Business'}
                      />
                  </Grid>
                  { !isBusiness ? 
                    (
                      <React.Fragment>
                      <Grid item lg={6} xs={6} className={classes.gridItem}> 
                          <TextValidator
                            label="Cardholder First Name"
                            onChange={ e => setState({...state, firstname: e.target.value, name: e.target.value + ' ' + state.lastname })}
                            name="cardholderfirstname"
                            value={ state.firstname }
                            validators={[
                              'required',
                              'minStringLength:2',
                              'maxStringLength:100'
                            ]}
                            errorMessages={[
                              'this field is required',
                              'min 2 characters',
                              'max 100 characters'
                            ]}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={6} xs={6} className={classes.gridItem}> 
                          <TextValidator
                            label="Cardholder Last Name"
                            onChange={ e => setState({...state, lastname: e.target.value, name: state.firstname + ' ' + e.target.value })}
                            name="cardholderlastname"
                            value={ state.lastname }
                            validators={[
                              'required',
                              'minStringLength:2',
                              'maxStringLength:100'
                            ]}
                            errorMessages={[
                              'this field is required',
                              'min 2 characters',
                              'max 100 characters'
                            ]}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        </React.Fragment>
                      ) : (
                        <Grid item lg={12} xs={12} className={classes.gridItem}> 
                                    
                          <TextValidator
                            label="Company Name"
                            onChange={ e => setState({...state, companyname: e.target.value, name: e.target.value })}
                            name="companyname"
                            value={ state.companyname }
                            validators={[
                              'required',
                              'minStringLength:2',
                              'maxStringLength:100'
                            ]}
                            errorMessages={[
                              'this field is required',
                              'min 2 characters',
                              'max 100 characters'
                            ]}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                      )
                    }
                    <Grid item lg={12} xs={12} className={classes.gridItem}> 
                        <TextField
                      label="Billing Address"
                      fullWidth
                      value= {state.address}
                        onChange={ e => setState({...state, address:e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />
                    </Grid>
                    <Grid item lg={6} xs={12} className={classes.gridItem}>
                      <TextField
                        label="Billing City"
                        fullWidth
                        value= {state.city}
                        onChange={ e => setState({...state, city:e.target.value } ) }
                        // variant="outlined"
                        // value= {state.service.time}
                        // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                        />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                      <TextField
                        label="Billing State"
                        fullWidth
                        value= {state.state}
                        onChange={ e => setState({...state, state:e.target.value } ) }
                      />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                    <TextField
                        label="Billing Zipcode"
                        fullWidth
                        type="number"
                        // variant="outlined"
                        value= {state.zipcode}
                        onChange={ e => setState({...state, zipcode:e.target.value } ) }
                        />
                    </Grid>

                    <Grid item lg={12} xs={12} className={classes.gridItem}>
                        <InputMask name="cardnumber" mask="9999-9999-9999-9999" maskPlaceholder="_" onChange={e => updateCardNumber(e.target.value)} value={state.numberInput} >
                        {
                          () => {
                            return (
                              <TextValidator
                                label="Card Number"
                                // onChange={ e => setState({...state, number: e.target.value }) }
                                name="cardnumber"
                                // value={ state.number }
                                validators={[
                                  'required',
                                  'minStringLength:15',
                                  'maxStringLength:20'
                                ]}
                                errorMessages={[
                                  'this field is required',
                                  'min 15 characters',
                                  'max 20 characters'
                                ]}
                                margin="dense"
                                fullWidth
                              />
                            )
                          }
                        }
                      
                      
                      </InputMask>
                    </Grid>
                    <Grid item lg={6} xs={6} className={classes.gridItem}>
                    <InputMask mask="99/99" maskPlaceholder="_" onChange={e => updateCardExpiration(e.target.value)} value={state.expiryInput} >
                          {
                            () => {
                              return (
                                <TextValidator
                                  label="Expiration"
                                  // onChange={ e => setState({...state, expiry:e.target.value } ) }
                                  name="expiry"
                                  // value={ state.expiry }
                                  validators={[
                                    'required',
                                    'minStringLength:4',
                                    'maxStringLength:5'
                                  ]}
                                  errorMessages={[
                                    'this field is required',
                                    'min 4 characters',
                                    'max 5 characters'
                                  ]}
                                  margin="dense"
                                  fullWidth
                                />
                              )
                            }
                          }
                        
                          </InputMask>
                    </Grid>
                    <Grid item lg={6} xs={6} className={classes.gridItem}> 
                          <TextValidator
                        label="CVC"
                        onChange={ e => setState({...state, cvc:e.target.value } ) }
                        name="cvc"
                        value={ state.cvc }
                        validators={[
                          'required',
                          'minStringLength:3',
                          'maxStringLength:4'
                        ]}
                        errorMessages={[
                          'this field is required',
                          'min 3 characters',
                          'max 4 characters'
                        ]}
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
               
              
               
                
                </Grid>
          </ValidatorForm>
            <p className={classes.disclaimerText}>
              Charges processed via credit card will include a 3.75% surcharge. No surcharge is required for eCheck Payments.
            </p>
            {/* <p>
              <FormControlLabel
                    control={<Checkbox checked={saveCardDetails} onChange={ e => setSaveCardDetails(!saveCardDetails) } name="saveDetails" />}
                    label="Save Card Details (We Will Never Charge Your Card Without Your Permission)"
                  />
            </p> */}
            <p>
              <Button variant="contained" fullWidth color="secondary" disabled={!formIsValid} onClick={handleSubmit}> { loading ? <CircularProgress color="primary" size={ 24 } /> : 'SUBMIT' }</Button>
            </p>

    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(InputCreditCardDetailsForm))