import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { GoogleMap, LoadScript, Polygon, Circle, Marker, DrawingManagerF } from '@react-google-maps/api';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem, Tabs, Tab, Checkbox, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const defaultCenter = {
  lat: 33.91023013848473,
  lng: -112.084847523098
};

const containerStyle = {
  width: '100%',
  height: '100%'
};

const getPolygonArea = (paths) => {
  if (window.google && window.google.maps && window.google.maps.geometry) {
    const latLngPaths = paths.map(path => new window.google.maps.LatLng(path.lat, path.lng));
    const areaMeters = window.google.maps.geometry.spherical.computeArea(latLngPaths);
    const areaFeet = areaMeters * 10.7639; // Convert square meters to square feet
    return { areaMeters, areaFeet };
  }
  return { areaMeters: 0, areaFeet: 0 }; // Default area if google maps is not available
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    margin: 5
  },
  sidebarContainer: {
    width: '25%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',
    height: '100%',
    backgroundColor: '#ddd',
  },
  mapContainer: {
    width: '80%',
    height: '100vh'
  },
  radiusControl: {
    position: 'absolute',
    top: theme.spacing(2),
    left: '20%',
    zIndex: 1,
    backgroundColor: 'white',
    padding: theme.spacing(1),
    borderRadius: 4,
    boxShadow: theme.shadows[2],
  },
  areaItemContainer: {
    // margin: 15,
    // padding: 10
  },


  itemColorBlock: {
    height: 50,
    width: 50,
    borderRadius: 5,
    border: '2px solid #111'
  },
  saveArea: {
    marginTop: 50,
    display: 'flex',
    alignSelf: 'stretch',
    justifyContent: 'center'
  },
  tabContent: {
    margin: 10
    ,
    padding: 10,
    backgroundColor: '#eee',
    width: '100%',
    height: '100%'
  },
  polygonItemContent: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  polygonInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  sidebarItemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    borderRadius: 8,
    border: '2px solid #ccc',
    marginBottom: 10,
    marginTop: 5,
    padding: theme.spacing(1),
    cursor: 'pointer',
    alignSelf: 'stretch'
  },
  selectedSidebarItem: {
    border: '2px solid blue',
    borderRadius: 8,

  },
  visibilityToggle: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  exclusionArea: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',  // or any color you prefer
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.15)',  // slightly darker on hover
    }
  }
}));


const MapWithDrawing = (props) => {
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [polygons, setPolygons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState(null);
  const [geofenceRadius, setGeofenceRadius] = useState(100); // radius in meters
  const [center, setCenter] = useState(defaultCenter);
  const [newArea, setNewArea] = useState({ 
    name: '', 
    strokeColor: '#FF0011', 
    fillColor: '#FF1100', 
    paths: [],
    type: 'area', // 'area' or 'subtraction'
    parentArea: null // reference to parent area for subtraction
  });
  const [isDrawing, setIsDrawing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [zoom, setZoom] = useState(19);
  const [jobSite, setJobSite] = useState({});
  const [isDrawingEnabled, setIsDrawingEnabled] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [drawingMode, setDrawingMode] = useState(null)

  // const drawingManagerRef = useRef(null);
  const mapRef = useRef(null);
  const circleRef = useRef(null);
  const drawingManagerRef = useRef(null);

  useEffect(() => {
    let isMounted = true

    const fetchJobSiteAreas = async (siteId) => {
      // console.log('Fetch site areas', siteId)
      setLoading(true)
      const response = await axios.get(`${props.url}/api/customer/fetchJobSiteAreas?id=${siteId}`)
      // console.log('Invoices!!', response)
      setLoading(false)
      if (response && response.data && response.data._id) {
        // console.log('Response areas', response.data)
        if (isMounted) {
          setJobSite(response.data)
          let currentSite = response.data
          if (currentSite && currentSite.location && currentSite.location.coordinates) {
            console.log('Use Jobsite as Center', currentSite)
            setCenter({
              lat: currentSite.location.coordinates[1],
              lng: currentSite.location.coordinates[0]
            })
           }
          if (response.data && response.data.areas && response.data.areas.length) setPolygons(response.data.areas)
        }
      }
    }
    // const savedPolygons = JSON.parse(localStorage.getItem('polygons'));
    // if (savedPolygons) {
    //   // setPolygons(savedPolygons);
    // }
    if (props?.match?.params?.jobsiteId) fetchJobSiteAreas(props?.match?.params?.jobsiteId)
    // if (props?.location?.state?.jobSite?._id) {
    //   setJobSite(props.location.state.jobSite)
    // }

    return () => {
      isMounted = false
    }
  }, []);

  const onCircleRadiusChanged = () => {
    if (circleRef.current) {
      const newRadius = circleRef.current.getRadius();
      setGeofenceRadius(Math.round(newRadius));
    }
  };

  const calculateNetArea = (polygon) => {
    const mainArea = getPolygonArea(polygon.paths).areaFeet;
    const subtractions = polygons.filter(p => p.type === 'subtraction' && p.parentArea === polygon.name);
    const totalSubtracted = subtractions.reduce((sum, p) => sum + getPolygonArea(p.paths).areaFeet, 0);
    return mainArea - totalSubtracted;
  };

  const handleGeofenceChange = (event) => {
    setGeofenceRadius(Number(event.target.value));
  };

  const handlePolygonCompleteNEW = (polygon) => {
    const paths = polygon.getPath().getArray().map(latlng => ({ lat: latlng.lat(), lng: latlng.lng() }));
    const finalArea = {
      ...newArea,
      paths
    };
    
    setPolygons([...polygons, finalArea]);
    polygon.setMap(null);
    setIsDrawingEnabled(false);
    setNewArea({ 
      name: '', 
      strokeColor: '#FF0000', 
      fillColor: '#FF0000', 
      paths: [],
      type: 'area',
      parentArea: null 
    });
  };

  const handlePolygonComplete = (polygon) => {
    const paths = polygon.getPath().getArray().map(latlng => ({ lat: latlng.lat(), lng: latlng.lng() }));
    setNewArea(prev => ({ ...prev, paths }));
    setIsDialogOpen(true);
    polygon.setMap(null);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
    setIsDrawing(false);
  };

  const handleZoomChanged = () => {
    if (mapRef.current) {
      setZoom(mapRef.current.getZoom());
    }
  };

  const handleSaveArea = () => {
    if (newArea.type === 'subtraction' && !newArea.parentArea) {
      alert('Please select a parent area for subtraction');
      return;
    }
    
    const newPolygons = [...polygons, newArea];
    setPolygons(newPolygons);
    setIsDialogOpen(false);
    setNewArea({ 
      name: '', 
      strokeColor: '#FF0000', 
      fillColor: '#FF0000', 
      paths: [],
      type: 'area',
      parentArea: null 
    });
    // setSelectedPolygon(newPolygons.length - 1)
    setIsDrawing(false);
    setDrawingMode(null) // window.google.maps.drawing.OverlayType.POLYGON)
    // setIsDrawing(true);
    // if (drawingManagerRef.current) {
    //   drawingManagerRef.current.state.drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    // }

  };

  const handleTabChange = (event, newValue) => {
    console.log('Set tab', newValue)
    console.log('current tab', activeTab)
    setActiveTab(newValue);
    // Reset drawing mode when switching tabs
    if (newValue === 1) {
      // startDrawingPolygon();
      setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON)
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewArea({ ...newArea, [name]: value });
  };

  const handleSidebarClick = (index) => {
    handlePolygonClick(index)
  }

  const handlePolygonClick = (index) => {
    setSelectedPolygon(index);
    const polygon = polygons[index];
    setNewArea({ name: polygon.name, strokeColor: polygon.strokeColor, fillColor: polygon.fillColor, paths: polygon.paths });
    setIsDialogOpen(true);
  };

  const handleDeletePolygon = () => {
    const msg = `Are you sure you wish to delete this area?`
    if (window.confirm(msg)) {
      const newPolygons = polygons.filter((_, index) => index !== selectedPolygon);
      setPolygons(newPolygons);
      // localStorage.setItem('polygons', JSON.stringify(newPolygons));
      setSelectedPolygon(null);
      setIsDialogOpen(false);
    }
  };

  const handleUpdatePolygon = () => {
    const updatedPolygons = polygons.map((polygon, index) =>
      index === selectedPolygon ? newArea : polygon
    );
    setPolygons(updatedPolygons);
    // localStorage.setItem('polygons', JSON.stringify(updatedPolygons));
    setSelectedPolygon(null);
    setIsDialogOpen(false);
  };

  const startDrawingPolygon = () => {
    // setIsDialogOpen(true);
    setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON)
    setIsDrawing(true);
    // if (drawingManagerRef.current) {
    //   drawingManagerRef.current.state.drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    // }
  };

  const getPolygonCenter = useCallback((paths) => {
    if (window.google && window.google.maps) {
      const bounds = new window.google.maps.LatLngBounds();
      paths.forEach(path => {
        bounds.extend(new window.google.maps.LatLng(path.lat, path.lng));
      });
      return bounds.getCenter();
    }
    return center; // Default center if google maps is not available
  }, []);

  const createLabelIcon = (text) => {
    if (window.google && window.google.maps) {
      const svg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="200" height="30">
          <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="Arial" font-size="16" fill="black">${text}</text>
        </svg>`;
      return {
        url: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg),
        scaledSize: new window.google.maps.Size(200, 30),
        anchor: new window.google.maps.Point(100, 15)
      };
    }
  };

  const handleSaveAreas = () => {

    if (newArea.type === 'subtraction' && !newArea.parentArea) {
      alert('Please select a parent area for subtraction');
      return;
    }
    
    setIsDialogOpen(false);
    setIsDrawingEnabled(true);
    if (drawingManagerRef.current) {
      drawingManagerRef.current.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    }
    let totalArea = 0
    let netArea = 0

    let polygonsWithAreas = polygons.map(item => {
      const area = getPolygonArea(item.paths)
      console.log('Area of this one', area)
      const netAreaOfThisItem = calculateNetArea(item);
      console.log('Net Area of this one', netAreaOfThisItem)
     
      if (item?.type !== 'subtraction') {
        netArea += Number(netAreaOfThisItem)
        totalArea += Number(area.areaFeet)
      }
      return {
        ...item,
        area: area.areaFeet.toFixed(2)
      }
    })
    console.log('Polygons w/ areas', polygonsWithAreas)
   console.log('Total area: %s   Net Area: %s', totalArea, netArea)
  }
const oldSaveAreas = () => {
    // console.log('Time to save these areas!!!', polygons)
    let polygonsWithAreas = polygons.map(item => {
      const area = getPolygonArea(item.paths)
      return {
        ...item,
        area: area.areaFeet.toFixed(2)
      }
    })
    // let jobSite = null
    // if (props?.location?.state?.jobSite?._id) {
    //   jobSite = props.location.state.jobSite
    // }
    setLoading(true)
    // console.log('handle save', state)
    // console.log(props)
    let obj = {
      areas: polygonsWithAreas,
      jobSite
    }
    console.log(obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/customer/updateJobSite?areasOnly=1`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Axios response to create new Job Site: ', response)
      setLoading(false)
    }).catch(e => {
      console.log('Error saving our areas....')
    })
  }

  const handleSaveGeofenceArea = (d) => {
    console.log('Save Geofence area!!', d)
    console.log('Center', center)
    console.log('Radius', geofenceRadius)
  }
  const libs = ['drawing', 'geometry']
  // let center = defaultCenter
  // if (props?.location?.state?.jobSite?._id) {
  //   const jobSite = props.location.state.jobSite
  //   console.log('Jobsite', jobSite)
  //   if (jobSite && jobSite.location && jobSite.location.coordinates) {
  //     center = {
  //       lat: jobSite.location.coordinates[1],
  //       lng: jobSite.location.coordinates[0]
  //     }
  //   }
  //   // setJobSite(props.location.state.jobSite)
  // }
 if (jobSite && jobSite.location && jobSite.location.coordinates) {
  console.log('Use Jobsite as Center', jobSite)
  // setCenter({
  //   lat: jobSite.location.coordinates[1],
  //   lng: jobSite.location.coordinates[0]
  // })
 }

 const handleTogglePolygonVisibility = (index) => {
  setPolygons(prevPolygons => 
    prevPolygons.map((polygon, i) => 
      i === index 
        ? { ...polygon, isHidden: !polygon.isHidden }
        : polygon
    )
  );
};


  // console.log('Center', center)
  let label = 'Property'
  const isEditing = selectedPolygon !== null
  return (
    <div className={styles.root}>
    <div className={styles.mapContainer}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GMAPKEY} libraries={['drawing', 'geometry']}>
        {/* <div className={styles.radiusControl}>
          <TextField
            type="number"
            label="Geofence Radius (meters)"
            value={geofenceRadius}
            onChange={handleGeofenceChange}
            variant="outlined"
            size="small"
          />
        </div> */}
        
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          onZoomChanged={handleZoomChanged}
          onLoad={map => mapRef.current = map}
          options={{
            streetViewControl: true,
            draggable: true,
            zoomControl: true,
            keyboardShortcuts: false,
            scaleControl: true,
            scrollwheel: true,
            mapTypeId: 'satellite'
          }}
        >
          {
            activeTab === 0 && <Circle
                onLoad={circle => circleRef.current = circle}
                onRadiusChanged={onCircleRadiusChanged}
                center={center}
                radius={geofenceRadius}
                options={{
                  strokeColor: '#FF0000',
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                  fillColor: '#FF0000',
                  fillOpacity: 0.1,
                  editable: true,
                  draggable: false,
                }}
              />
          }
         
          

          {/* Existing Polygons */}
          {polygons.map((polygon, index) => {
            if (polygon.isHidden) return null;
            const netArea = calculateNetArea(polygon);

            return (
              <React.Fragment key={index}>
                <Polygon
                  paths={polygon.paths}
                  options={{
                    fillColor: polygon.fillColor,
                    fillOpacity: polygon.type === 'subtraction' ? 0.7 : 0.4,
                    strokeColor: polygon.strokeColor,
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    editable: selectedPolygon === index,
                    draggable: selectedPolygon === index
                  }}
                  onClick={() => handlePolygonClick(index)}
                />
                {zoom >= 15 && polygon.type !== 'subtraction' && (
                  <Marker
                    position={getPolygonCenter(polygon.paths)}
                    icon={createLabelIcon(`${polygon.name}: ${netArea.toFixed(2)} sq ft`)}
                  />
                )}
              </React.Fragment>
            );
          })}
          <DrawingManagerF
            onLoad={manager => drawingManagerRef.current = manager}
            onPolygonComplete={handlePolygonComplete}
            options={{
              drawingControl: activeTab === 1,
              drawingMode: drawingMode, // isDrawingEnabled ? window.google.maps.drawing.OverlayType.POLYGON : null,
              polygonOptions: {
                fillColor: newArea.fillColor,
                fillOpacity: 0.4,
                strokeColor: newArea.strokeColor,
                strokeOpacity: 1,
                strokeWeight: 2,
                editable: true,
                draggable: true
              }
            }}
          />
        </GoogleMap>

        <Dialog open={isDialogOpen} onClose={handleDialogClose}>
          <DialogTitle>{isEditing ? 'Edit Area' : 'Create New Area'}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Area Name"
              type="text"
              fullWidth
              value={newArea.name}
              onChange={handleInputChange}
            />
            <Select
              fullWidth
              margin="dense"
              name="type"
              value={newArea.type}
              onChange={handleInputChange}
              label="Area Type"
            >
              <MenuItem value="area">Main Area</MenuItem>
              <MenuItem value="subtraction">Subtraction Area</MenuItem>
            </Select>
            {newArea.type === 'subtraction' && (
              <Select
                fullWidth
                margin="dense"
                name="parentArea"
                value={newArea.parentArea}
                onChange={handleInputChange}
                label="Parent Area"
              >
                {polygons
                  .filter(p => p.type === 'area')
                  .map(p => (
                    <MenuItem key={p.name} value={p.name}>{p.name}</MenuItem>
                  ))
                }
              </Select>
            )}
            <TextField
              margin="dense"
              name="strokeColor"
              label="Outline Color"
              type="color"
              fullWidth
              value={newArea.strokeColor}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="fillColor"
              label="Fill Color"
              type="color"
              fullWidth
              value={newArea.fillColor}
              onChange={handleInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">Cancel</Button>
            {selectedPolygon !== null ? (
              <>
                <Button onClick={handleDeletePolygon} color="secondary">Delete</Button>
                <Button onClick={handleUpdatePolygon} color="primary">Update</Button>
              </>
            ) : (
              <Button onClick={handleSaveArea} color="primary">Save</Button>
            )}
          </DialogActions>
        </Dialog>
      </LoadScript>
    </div>
    <div className={styles.sidebarContainer}>
      <Sidebar 
        startDrawing={startDrawingPolygon} 
        polygons={polygons} 
        clickPolygon={handleSidebarClick} 
        selectedPolygonIndex={selectedPolygon} 
        saveAreas={handleSaveAreas} 
        jobSite={jobSite}
        geofenceRadius={geofenceRadius}
        updateGeoFenceArea={handleGeofenceChange}
        handleTabChange={handleTabChange}
        activeTab={activeTab}
        saveGeofenceArea={handleSaveGeofenceArea}
        onTogglePolygonVisibility={handleTogglePolygonVisibility}
      />
    </div>
  </div>
  );
};

const Sidebar = (props) => {
  const styles = useStyles()
  const polygons = props?.polygons || []
  const selectedPolygonIndex = props.selectedPolygonIndex;
  const jobSite = props?.jobSite || null
  const client = jobSite?.client

  const handleToggleVisibility = (event, index) => {
    event.stopPropagation(); // Prevent click from selecting the polygon
    if (props.onTogglePolygonVisibility) {
      props.onTogglePolygonVisibility(index);
    }
  };

  const handleClick = (index) => {
    props.clickPolygon(index)
  }

  // const handleTabChange = props.handleTabChange()
  const activeTab = props.activeTab
  console.log('SIDEbar', props)
  return (
    <div className={styles.sideBarDetails}>
      {jobSite && jobSite._id && (
        <div>
          {client && client._id && <div>{client.displayName}</div>}
          {jobSite.name} {jobSite?.areas?.length ? `(${jobSite.areas.length} Areas Mapped)` : ''}
        </div>
      )}
      <div>
      Active Tab: {activeTab} props: {props.activeTab}
      </div>
      <Tabs value={activeTab} onChange={props.handleTabChange}>
        <Tab label="Measure Property" />
        <Tab label="Draw Areas" />
      </Tabs>

      {activeTab === 0 && (
        <div className={styles.tabContent}>
          <TextField
            type="number"
            label="Geofence Radius (meters)"
            value={props.geofenceRadius}
            onChange={props.updateGeoFenceArea}
            variant="outlined"
            size="small"
          />
           <div className={styles.saveArea}>
              <Button variant="contained" color="primary" onClick={props.saveGeofenceArea}>
                Save Geofence Area
              </Button>
            </div>
        </div>
      )}

      {activeTab === 1 && (
        <div className={styles.tabContent}>
          <Button variant="contained" color="primary" onClick={props.startDrawing}>
            Create New Area
          </Button>
          <div className={styles.areaItemContainer}>
            {polygons.map((item, index) => {
              const area = getPolygonArea(item.paths);
              const itemColorBlock = {
                height: 50,
                width: 50,
                borderRadius: 5,
                backgroundColor: item.fillColor,
                border: `2px solid ${item.strokeColor}`
              };
              const isExclusionArea = item?.type === 'subtraction'
              const itemStyles = `${styles.sidebarItemContainer} ${
                index === selectedPolygonIndex ? styles.selectedSidebarItem : ''
              } ${isExclusionArea ? styles.exclusionArea : ''}`;
              
              return (
                <div key={index} className={itemStyles} onClick={() => handleClick(index)}>
                  {item.name} ({area.areaFeet.toFixed(2)} SF) 
                  <div style={itemColorBlock}>&nbsp;</div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<Visibility />}
                        checkedIcon={<VisibilityOff />}
                        checked={item.isHidden}
                        onChange={(e) => handleToggleVisibility(e, index)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    }
                    label=""
                    className={styles.visibilityToggle}
                  />
                </div>
              );
            })}
          </div>
          {polygons?.length > 0 && (
            <div className={styles.saveArea}>
              <Button variant="contained" color="primary" onClick={props.saveAreas}>
                Save Areas
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(MapWithDrawing)

// [, { backgroundColor: item.fillColor, border: `2px solid ${item.strokeColor}` }]