import React from 'react'
import { socket } from '../SocketIo/Socket'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.45)',
    fontSize: '14px'
  }
}));

const BatchStatementDetails = (props) => {
  // console.log('Batch Statement details', props)
  const statementData = props.statementData
  const classes = useStyles();
  const [statementMessage, setStatementMessage] = React.useState('')
  const [batch, setBatch] = React.useState({
    name: props.statementName || 'N/A'
  })
  const [state, setState] = React.useState({
    statement: {
      start_time: null,
      pdfsGenerated: 0,
      name: 'TBD'
    },
    message: 'Pending Server Update',
    numberRun: 0,
    clients: 0,
    nonEmailClients: 0,
    nonEmailClientTotal: 0,
    hoaDetails: {
      clientsRun: 0,
      invoicesSent: 0
    }
  })
  const [hoaDetails, setHOADetails] = React.useState({
    clientsRun: 0,
    invoicesRun: 0,
    invoicesSent: 0
  })
  const [autopayDetails, setAutopayDetails] = React.useState({
    mtcInvoicesProcessed: 0,
    mtcAuthTotal: 0,
    allInvoicesProcessed: 0,
    allAuthTotal: 0
  })
  const [emailClientDetails, setEmailClientDetails] = React.useState({
    total: 0,
    sent: 0
  })
  const [paperClientDetails, setPaperClientDetails] = React.useState({
    total: 0,
    sent: 0
  })
  React.useEffect(() => {
    // console.log('Run once...')
    if (props.batch) setBatch(props.batch)
    // setState({...state, numberRun: statementData.numberRun, clients:  statementData.clients })
  }, [props])
  
  React.useEffect(() => {
    socket.on('monthEndUpdates', function (msg) {
      // console.log('MONTH END UPDATES: ', msg)
      if (msg.batch && msg.batch._id) {
        setBatch(msg.batch)
        if (msg.batch && msg.batch.hoaDetails && msg.batch.hoaDetails.clientsRun) {
          // setState({...state, hoaDetails: msg.batch.hoaDetails  })
          setHOADetails(msg.batch.hoaDetails)
        }

        if (msg.batch && msg.batch.autoPayDetails && msg.batch.autoPayDetails.mtcInvoicesProcessed) {
          setAutopayDetails(msg.batch.autoPayDetails)
        }
      }
    })

    socket.on('batchStatement', function (msg) {
      // console.log('GOT A SOCKET MESSAGE: ', msg)
      // console.log('State: ', state)
      // setInProcess(true)
      // setButtonVisibility('none')
      // let newMessage = msg
      // if (!msg.client) newMessage.client = statementMessage.client
      // if (!msg.step) newMessage.step = statementMessage.step
      // if (isNaN(msg.step)){
      //   newMessage.step = msg.step
      // } else {
      //   newMessage.step = ''
      // }
      // if (!msg.message) newMessage.message = statementMessage.message
      // if (msg && msg.step === 'Complete') {
      //   setInProcess(false)
      //   newMessage.client = ''
      //   newMessage.step = 'Process Complete'
      //   setTimeout(function () {
      //     // console.log('Hide this shit and show the button again')
      //     setVisibility('hidden')
      //     setButtonVisibility('inline-block')
      //     setInProcess(false)
      //   }, 10000)
      // } else {
        let stuffToUpdate = {}
      if (msg && msg.message) {
        // setStatementMessage(msg.message)
        // console.log('Do we have number run and clients??? ', msg)
        // console.log(state)
        // let updatedState = {...state}
        // stuffToUpdate.message = msg.message
        setState({...state, message: msg.message})
      }
      if (msg.nonEmailClientTotal) {
        let newStuff = paperClientDetails
        newStuff.total = msg.nonEmailClientTotal
        newStuff.sent = msg.nonEmailClients
        setPaperClientDetails({...paperClientDetails, newStuff})
      }
      if (msg.clients) {
        let newStuff = emailClientDetails
        newStuff.total = msg.clients
        newStuff.sent = msg.numberRun
        setEmailClientDetails({...emailClientDetails, newStuff })
        // if (msg.numberRun) stuffToUpdate.emailClientsCompleted = msg.numberRun
      }
      // let updateStuff = {...state, ...stuffToUpdate}
      // console.log('Updat State based on incoming SOCKET:', updateStuff)
      // setState(updateStuff)
    })
    return () => {
      socket.off('batchStatement')
      socket.off('monthEndUpdates')
    }
  }, [])

  let autoPayMtcDetails = ''
  let autoPayAllDetails = ''
  let mtcInvoicesProcessed = 0
  let allInvoicesProcessed = 0
  let mtcDollars = 0
  let allDollars = 0
  if (batch && batch.autoPayDetails && batch.autoPayDetails.mtcInvoicesProcessed) {
  // console.log('147')
    mtcInvoicesProcessed = batch.autoPayDetails.mtcInvoicesProcessed
    autoPayMtcDetails += mtcInvoicesProcessed + ' Invoices For ('
  }
  if (batch && batch.autoPayDetails && batch.autoPayDetails.mtcAuthTotal) {
  // console.log('152')
    mtcDollars = batch.autoPayDetails.mtcAuthTotal
    autoPayMtcDetails += ' $' + mtcDollars + ')'
  }

  if (batch && batch.autoPayDetails && batch.autoPayDetails.allInvoicesProcessed) {
  // console.log('158')
    allInvoicesProcessed = batch.autoPayDetails.allInvoicesProcessed
    autoPayAllDetails += allInvoicesProcessed + ' Invoices For ('
  }
  if (batch && batch.autoPayDetails && batch.autoPayDetails.allAuthTotal) {
  // console.log('163')
    allDollars = batch.autoPayDetails.allAuthTotal
    autoPayAllDetails += ' $' + allDollars + ')'
  }
console.log('BATCH', batch)
console.log(autoPayAllDetails)
console.log(autoPayMtcDetails)

  return (
    <>

    <Grid item lg={12} xs={12}>
      <div className={classes.label}>
        Statement Name
      </div>
      {batch.name}
    </Grid>
    <Grid item lg={6} xs={12}>
      <div className={classes.label}>
        Statement Start Time
      </div>
      {moment(batch.start_time).format('ddd MMM DD YYYY - hh:mmA')}
    </Grid>
    <Grid item lg={6} xs={12}>
      <div className={classes.label}>
        Statement Last Update Time
      </div>
      {moment(batch.updated_at).format('ddd MMM DD YYYY - hh:mmA')}
    </Grid>
                
    <Grid item lg={12} xs={12}>
        <h2 style={{textAlign: 'center', color: '#bbb' }}>{batch.currentStep || ''}</h2>
      </Grid>

      <Grid item lg={12} xs={12}>
        <h3>Autopay Details</h3>
      </Grid>
      <Grid item lg={6} xs={12}>
        <div className={classes.label}>
          Maintenance
        </div>
        {autoPayMtcDetails}
      </Grid>
      <Grid item lg={6} xs={12}>
        <div className={classes.label}>
          All
        </div>
        {autoPayAllDetails}
      </Grid>
      <Grid item lg={12} xs={12}>
        <h3>Bulk PDF (HOA & Non-Email Customers)</h3>
      </Grid>
      <Grid item lg={6} xs={12}>
        <div className={classes.label}>
          Non-Email Customers To Include
        </div>
        {paperClientDetails.total || 0}
      </Grid>
      <Grid item lg={6} xs={12}>
        <div className={classes.label}>
          PDFs Generated for Non-Email Customers
        </div>
        {paperClientDetails.sent || 0}
      </Grid>
      <Grid item lg={12} xs={12}>
        <h3>HOA Invoices Emailed</h3>
      </Grid>
      <Grid item lg={6} xs={12}>
        <div className={classes.label}>
          HOAs Completed
        </div>
        {hoaDetails.clientsRun || 0}
      </Grid>
      <Grid item lg={6} xs={12}>
        <div className={classes.label}>
          Invoices Sent
        </div>
        {hoaDetails.invoicesSent || 0}
      </Grid>
      <Grid item lg={12} xs={12}>
        <h3>Individual Statements To Email</h3>
      </Grid>
      <Grid item lg={6} xs={12}>
        <div className={classes.label}>
          Total Customers Included
        </div>
        {emailClientDetails.total || 0}
      </Grid>
      <Grid item lg={6} xs={12}>
        <div className={classes.label}>
          PDFs Generated
        </div>
        {emailClientDetails.sent || 0}
      </Grid>

      <Grid item lg={12} xs={12}>
        <div className={classes.label}>
          Current Activity
        </div>
        {state.message ? state.message : ''}
      </Grid>
    </>
  )
}

export default React.memo(BatchStatementDetails)