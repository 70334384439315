import React, { useState, useRef } from 'react';
import { Button, TextField, Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Paper } from '@material-ui/core';
import { GoogleMap, LoadScript, DrawingManager, Polygon } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';
import { connect } from 'react-redux'
import axios from 'axios'
import CustomMarker from './CustomMarker'
import Grid from '@material-ui/core/Grid'
import moment from 'moment'
import { useLocation } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const mapLibraries = ["drawing", "geometry"];

const containerStyle = {
  width: '100%',
  height: '90vh'
};

const center = {
  lat: 33.81972977616888,
  lng: -111.9539555679331
};

const position = {
  lat: 33.7,
  lng: -111.97
}

const onLoad = marker => {
// console.log('marker: ', marker)
}
// const image = "https://dflportal.com/favicon.png"
const dfl = "https://dflportal.com/favicon.png"
const benhart = "https://www.benhartlandscaping.com/wp-content/themes/benhart/img/icons/favicon-16x16.png"

const colors = ['#ACCACD', '#1c801f', '#84d1e0', '#84e0b0', '#bbe084', '#689623', '#239670']

const distinctHexColorsOLD = [
  "#FF5733", "#33FF57", "#5733FF", "#FF33E3", "#E333FF",
  "#33FFE3", "#FFFF33", "#33FFFF", "#FF5733", "#33FF57",
  "#5733FF", "#FF33E3", "#E333FF", "#33FFE3", "#FFFF33",
  "#33FFFF", "#33FF33", "#FF3333", "#3333FF", "#33FFFF",
  "#AA00FF", "#00AAFF", "#FF00AA", "#FFAA00", "#00FFAA",
  "#5500FF", "#0055FF", "#FF0055", "#FF5500", "#0055FF",
  "#11FF00", "#FF0011", "#0011FF", "#FF1100", "#0011FF",
  "#FF00FF", "#00FFFF", "#FFFF00", "#00FF00", "#FF0000",
  "#0000FF", "#0000AA", "#000055", "#555555", "#AAAAAA",
  "#111111", "#222222", "#333333", "#444444", "#666666",
  "#777777", "#888888", "#999999", "#BBBBBB", "#CCCCCC",
  "#DDDDDD", "#EEEEEE", "#F0F0F0", "#F1F1F1", "#F2F2F2",
  "#F3F3F3", "#F4F4F4", "#F5F5F5", "#F6F6F6", "#F7F7F7",
  "#F8F8F8", "#F9F9F9", "#FAFAFA", "#FBFBFB", "#FCFCFC",
  "#FEFEFE", "#C0C0C0", "#B0B0B0", "#A0A0A0", "#909090",
];

const calculateVisitFrequency = (frequency) => {
  let numberOfVisits = 0
  switch (frequency) {
      case 'weekly':
          numberOfVisits = 52
          break;
      case 'biweekly':
          numberOfVisits = 26
          break;
      case 'monthly':
          numberOfVisits = 12
          break;
  }
  return numberOfVisits / 12
}

const calculateJakesThing = (markers) => {
  let totalHoursOfWorkPerMonth = 0
  let totalDollarsChargedPerMonth = 0
  markers.map(item => {
    // console.log('Calculate our hours and rate')
    let numberOfVisitsPerMonth = calculateVisitFrequency(item.frequency)
    // console.log('Visits:', numberOfVisitsPerMonth)
    let monthlyRate = item.price
    let minutesOfWorkPerVisit = item.estMinutes
    let hour = minutesOfWorkPerVisit / 60
    // console.log('Hours of work:', hour)
    let totalHoursPerMonth = numberOfVisitsPerMonth * hour
    let dolhr = monthlyRate / totalHoursPerMonth
    // console.log('$ dollars per hour', dolhr)
    dolhr = parseFloat(dolhr).toFixed(2)
    // console.log('This guy is:', dolhr)
    totalHoursOfWorkPerMonth += Number(totalHoursPerMonth)
    totalDollarsChargedPerMonth += Number(monthlyRate)
  })
  // Average charge per HOUR
  // console.log("Total WORK", totalHoursOfWorkPerMonth)
  // console.log('TOtal Revenue:', totalDollarsChargedPerMonth)
  let ourAverage = parseFloat(totalDollarsChargedPerMonth / totalHoursOfWorkPerMonth).toFixed(2)
  // console.log('Average:', ourAverage)
  return ourAverage
}

const calculateOurDollarsPerHours = (item) => {
  if (item && item.estMinutes) {
      let frequency = item.frequency
      let numberOfVisitsPerMonth = calculateVisitFrequency(frequency)
      let estMinutes = item.estMinutes
      // let totalMinutesOfWorkAnnually = Number(estMinutes) * Number(numberOfVisitsPerYear)
      let hour = estMinutes / 60
      console.log('Hours of work:', hour)
      let totalHoursPerMonth = numberOfVisitsPerMonth * hour
      let amt = item.price
      let dolhr = amt / totalHoursPerMonth
      console.log('$ dollars per hour', dolhr)
      dolhr = parseFloat(dolhr).toFixed(2)
      return dolhr
  }
  return null
}

function generateUniqueHexColors(count) {
  const colors = new Set();
  const result = [];

  while (colors.size < count) {
    const color = `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0')}`;
    colors.add(color);
  }

  colors.forEach((color) => {
    result.push(color);
  });

  return result;
}

// Example usage to generate 70 unique colors:
const distinctHexColors = generateUniqueHexColors(100);

// const distinctHexColors = ["#c331a2", "#998531", "#839fc7", "#4b61b6", "#131409", "#bb2989", "#2ee1f2", "#f3f039", "#a5ca90", "#55106b", "#ce082f", "#0f447f", "#df8145", "#8eb7ee", "#8c9fa5", "#6858e8", "#9689e0", "#69f468", "#404061", "#972e3e", "#a02f62", "#2ba010", "#ed93b1", "#149091", "#793be2", "#fe3d0e", "#1a239f", "#7a0aee", "#d96f62", "#18546d", "#510ad6", "#6dca75", "#101224", "#a42c25", "#8c1739", "#a558ba", "#4e2aea", "#4ce829", "#96e0c8", "#4f3842", "#fd2953", "#3d020c", "#eb6419", "#50bbce", "#292208", "#26e6b2", "#ea857e", "#9b44d0", "#c52f5c", "#056e88", "#21dd3b", "#bc6eea", "#b24545", "#857d44", "#1331fa", "#731d99", "#b60b0c", "#b43d94", "#787348", "#f015a9", "#6ed073", "#dc496d", "#b87447", "#880e01", "#dbf99d", "#5308e2", "#0a54dc", "#f38648", "#77cbbe", "#c48ad1"]
const polygonOptions = {
  fillOpacity: 0.3,
  fillColor: '#ff0000',
  strokeColor: '#ff0000',
  strokeWeight: 2,
  draggable: true,
  editable: true
}

const Sidebar = (props) => {
  console.log('SIDEBAR;', props)
  const crews = props.crews
  let markers = props.markers
  console.log('SIde bar: ', markers)
  const legendData = []
  markers.map(item => {
    let isInLegendIndex = legendData.findIndex(crew => (crew.crewId === item.crewId))
    if (isInLegendIndex < 0) {
      let color = setMarkerColorBasedOnCrew(item.crewId, crews)
      console.log('Color:', color)
      legendData.push(
        {
        color: color,
        crewName: item.crewName,
        crewId: item.crewId
      })
    }
  })
  let avgHourlyRate = calculateJakesThing(markers) || 'Not Available'
  console.log('Avg hourly rate:', avgHourlyRate)
  console.log('Legend Data:', legendData)
  return (
    <div style={{ overflowY: 'scroll', padding: 10, width: '100%', height: '90vh', zIndex: 9999, backgroundColor: '#bbb'}} >
      <Grid container spacing={2}>
      <Grid item lg={12} xs={12} sm={12} md={12}>
        Total Markers: {markers.length}
      </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
          Average Hourly Rate: {avgHourlyRate}
        </Grid>
      <Grid item lg={12} xs={12} sm={12} md={12}>
         <MarkerDetails markers={props.selectedMarkers} handleClearPolygons={props.clearPolygons} />
        </Grid>
        <Grid item lg={12} xs={12} sm={12} md={12}>
          <FilterArea crews={crews} updateCrews={props.handleUpdateVisibleCrews} updateDays={props.handleUpdateVisibleDays} updateWeeks={props.handleUpdateVisibleByWeek} updateFrequency={props.handleUpdateVisibilityByFrequency} />
        </Grid>
       
        {/* <Grid item lg={12} xs={12}>
          <h2>Color Legend: ({legendData.length} Items)</h2>
        </Grid>
        <Grid item lg={12} xs={12} md={12} sm={12} style={{ padding: 10 }}>
          {
            legendData.map(item => {
              return (
                <div key={item.crewId} style={{ backgroundColor: item.color, marginVertical: 25, padding: 35 }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
                  <div>Checkbox</div>
                  <div>
                      {
                        item.crewName
                      }
                  </div>
                  </div>

                  <div>
                        # of Work Hours
                  </div>
                </div>
              )
            })
          }
        </Grid> */}
      </Grid>
    </div>
  )
}

const MarkerDetails = ({ markers, handleClearPolygons}) => {
  console.log('Markers in details...', markers.length)
  let totalMinutesOfWork = 0
  let totalDollars = 0
  markers.map(item => {
    totalMinutesOfWork += Number(item.estMinutes)
    totalDollars += Number(item.price)
  })
  totalDollars = parseFloat(totalDollars).toFixed(2)
  return (
    <div>
      <Button onClick={handleClearPolygons}>Clear</Button>
      Markers Selected: {markers.length}
      <br />
      Total Minutes of Work: {totalMinutesOfWork}
      <br/>
      Total $: {totalDollars}
      {
        markers.map((item, index) => {
          console.log(item)
          return (
            <div key={index} style={{ margin: 5, padding: 10, borderRadius: 10, backgroundColor: '#fff' }}>
              Client: {item.clientName}<br/>
              Price: {item.price}<br/>
              Minutes: {item.estMinutes}
            </div>
          )
        })
      }
    </div>
  )
}
// function stringToColorCode(inputString) {
//   // Take the first 6 characters of the input string
//   const substring = inputString.substring(0, 6);

//   // Convert the substring to a hexadecimal number
//   const colorCode = parseInt(substring, 16);

//   // Convert the number to a 6-digit hexadecimal string
//   const hexColor = colorCode.toString(16).padStart(6, '0');

//   // Add a '#' prefix to make it a valid color code
//   return `#${hexColor}`;
// }
const setMarkerColorBasedOnCrew = (crewId, crews) => {
  console.log('Get hex for crew', crewId)
  let hexColor = '#f43acb'
  let crewIndex = crews.findIndex(crew => (crew._id === crewId))
  if (crewIndex > -1) {
    console.log('INDEX: ', crewIndex)
    hexColor = distinctHexColors[crewIndex]
  }
  console.log('Hex color:', hexColor)
  // const substring = crewId.substring(0, 9)
  // const colorCode = parseInt(substring, 16);
  // const hexColor = colorCode.toString(16).padStart(6, '0');
  return `${hexColor}`;
}

function MyComponent(props) {
  const [markers, setMarkers] = React.useState([])
  const [crews, setCrews] = React.useState([])
  const [state, setState] = React.useState({
    pending: false
  })
  const [activeMarker, setActiveMarker] = React.useState({})
  const [filteredCrews, setFilteredCrews] = React.useState([])
  const [filteredDays, setFilteredDays] = React.useState([])
  const [weekChosen, setWeekChosen] = React.useState('All')
  const [frequencyChosen, setFrequencyChosen] = React.useState('All')
  const [selectedMarkers, setSelectedMarkers] = React.useState([])
  const [polygons, setPolygons] = useState([])

  const mapRef = useRef();
  const polygonRefs = useRef([]);
  const activePolygonIndex = useRef();
  const autocompleteRef = useRef();
  const drawingManagerRef = useRef();


  // const [chosenWeek, setChosenWeek] = React.useState('All')
  // const dfl = `${props.url}/pickupTruck.svg`
  // const benhart = "https://www.benhartlandscaping.com/wp-content/themes/benhart/img/icons/favicon-16x16.png"

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const chosenWeek = searchParams.get('chosenWeek');
  const day = searchParams.get('day');




  React.useEffect(() => {
    let isMounted = true

    const fetchMarkers = async () => {
      const response = await axios.get(`${props.url}/api/mtcRoutes/fetchMarkers?week=${chosenWeek}&day=${day}`)
      if (response.data && response.data.length) {
        console.log('Marker Data: ', response.data)
        if (isMounted) {
          let coloredCrews = []
          response.data.map(item => {
            let randomNum = Math.floor((Math.random() * 70) + 1)
            let color = distinctHexColors[randomNum]
            console.log('Crew Color:', color)
            let cleanItem = {
              ...item,
              color
            }
            coloredCrews.push(cleanItem)
          })
          setCrews(coloredCrews)
          filterOurMarkers(coloredCrews)
          setFilteredCrews(coloredCrews)
        }
      }
    }

    fetchMarkers()

    return () => {
      isMounted = false
    }
  }, [])

  const filterOurMarkers = (data, days, week, frequency) => {
    console.log('Markers to Filter:', data)
    let cleanMarkers = []
    data.map(crew => {
      console.log('Crew:', crew)
      crew.stops.map((stop, stopIndex) => {
        console.log('Stop:', stop)
        const markerPosition = {
          lat: Number(stop.latitude),
          lng: Number(stop.longitude),
          // heading: Number(activity.location.coords.heading)
        }
        let newMarker = {
          _id: Date.now()+stopIndex,
          crewId: crew._id,
          color: crew.color,
          crewName: crew.crewName,
          position: markerPosition,
          ...stop
        }
        console.log('New Marker:', newMarker)
        if (week && week !== 'All') {
          console.log('Week too,,', week)
          // evenOrOddWeeks
          if (stop.frequency === 'biweekly' && week !== stop.week) {
            console.log('This one should be ignored')
            return
          }
        }
        if (frequency && frequency !== 'All') {
          console.log('Filter by frequency as well...', frequency)
          console.log(stop.frequency)
          if (stop.frequency !== frequency) return 
        }
        if (days && days.length) {
          console.log('Filter by day too', days)
          if (days.includes(stop.day)) cleanMarkers.push(newMarker)
        } else {
          cleanMarkers.push(newMarker)
        }
 
      })
    })
    console.log('Markers to set:', cleanMarkers)
    setMarkers(cleanMarkers)
    setFilteredCrews(data)
  }
  // const OurCrews = () => {
  // // console.log('Get our crew data...', crews)
  //   if (crews && crews.length) {
  //     let markersToUse = []
  //     crews.map(crew => {
  //     // console.log('Crew to work on: ,', crew)
  //       // Get the last 5 checkins for this guy
  //       let data = crew.data.splice(crew.data.length - 5, crew.data.length)
  //     // console.log('Data to use: ', data)
  //       markersToUse = markersToUse.concat(data)
  //     })
  //   // console.log('We have %d total markers!!', markersToUse)
  //     setMarkers(markersToUse)
  //     return markersToUse
  //   } else {
  //     return <span>Nothing to Show</span>
  //   }
  // }


  const updateActiveMarker = (d) => {
  console.log('UPdate Active Marker: ', d)
    setActiveMarker(d)
  }

  const OurMarkers = ({ markers }) => {
    console.log('Generate our Markes', markers.length)
    let icon = dfl
    if (markers && markers.length) {
      // console.log('Active Marker', activeMarker)
      let ourMarkers = []
      markers.map((jobsite, index) => {
      console.log('Marker jobsite: ', jobsite)
        // if (addy && addy.entity === '610a290f45ef7427f94b2e78') {
        //   icon = benhart
        // }
        let markerPosition = {}
        if (jobsite && jobsite.longitude && jobsite.latitude) {
        // console.log('Got activity.coords:::', activity.location)
          markerPosition = {
            lat: Number(jobsite.latitude),
            lng: Number(jobsite.longitude),
            // heading: Number(activity.location.coords.heading)
          }
          // activity.location = activity.location.coords
        } else {
        console.log('No fucking lat/lng...227', jobsite)
        }
        // console.log(markerPosition)
          let color = '#214'
          if (jobsite.color) color = jobsite.color
          if (activeMarker._id === jobsite._id) {
            color = '#832'
            // console.log('Change that color!!', color)
          }
          // color = setMarkerColorBasedOnCrew(jobsite.crewId, crews)
          // console.log('COLOR by Crew: ', color)
          // console.log(activeMarker)
          ourMarkers.push(
            <CustomMarker
              onLoad={onLoad}
              key={index}
              position={markerPosition}
              icon={icon}
              color={color}
              jobsite={jobsite}
              activeMarker={activeMarker}
              updateActiveMarker={updateActiveMarker}
            />
          )

      })
      return ourMarkers
    } else {
      return (
        <CustomMarker
          onLoad={onLoad}
          position={position}
          icon={icon}
        />
      )
    }
  }

  const updateTheMarkersOnTheMap = (data) => {
    // console.log('Update the visible markers:', data)
    let crewsToInclude = crews.filter(crew => (data.includes(crew._id)))
    // console.log('Use theese markers:', crewsToInclude)
    filterOurMarkers(crewsToInclude, filteredDays, weekChosen, frequencyChosen)
  }

  const updateMarkersByDay = (data) => {
    // console.log('Update Markers by day', data, weekChosen)
    if (data && data.length) {
      filterOurMarkers(filteredCrews, data, weekChosen, frequencyChosen)
      setFilteredDays(data)
    }
  }

  const updateMarkersByWeek = (data) => {
    console.log('Update By Week', data)
    filterOurMarkers(filteredCrews, filteredDays, data, frequencyChosen)
    setWeekChosen(data)
  }

  const updateMarkersByFrequency = (data) => {
    console.log('Update By Frequency', data)
    filterOurMarkers(filteredCrews, filteredDays, weekChosen, data)
    setFrequencyChosen(data)
  }

  function onOverlayComplete(event) {
    // console.log('Overlay complete:', event)
    let newMarkers = []
    console.log('Type:', event.type)
    if (event.type === "circle") {
      const circle = event.overlay;
      
      markers.forEach(marker => {
          const isInside = window.google.maps.geometry.spherical.computeDistanceBetween(
              marker.position, 
              circle.getCenter()
          ) <= circle.getRadius();
          
          if (isInside) {
              // Marker is inside the circle
              // console.log('This one is inside ', marker)
              newMarkers.push(marker)
          }
      });
  } else if (event.type === "polygon") {
    const polygon = event.overlay;

    markers.forEach(marker => {
        const isInside = window.google.maps.geometry.poly.containsLocation(
            marker.position,
            polygon
        );
        
        if (isInside) {
            // Marker is inside the polygon
            console.log('This one is inside the polygon', marker);
            newMarkers.push(marker)
        }
    });
  }
  console.log('FInished coming up w/ updated markers', newMarkers)
  setSelectedMarkers(newMarkers)
}

const onLoadPolygon = (polygon, index) => {
  polygonRefs.current[index] = polygon;
}

const onClickPolygon = (index) => {
  console.log('on polygon click')
  activePolygonIndex.current = index; 
}

const handlePolygonClick = (index) => {
  console.log('Click Polygon')
  const shouldDelete = window.confirm("Do you want to delete this polygon?");
    if (shouldDelete) {
      // Create a new array without the clicked polygon
      const newPolygons = polygons.filter((_, i) => i !== index);
      setPolygons(newPolygons);
    }
}


const onEditPolygon = (index) => {
  console.log("On Edit polygon")
  const polygonRef = polygonRefs.current[index];
  if (polygonRef) {
      const coordinates = polygonRef.getPath()
          .getArray()
          .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));

      const allPolygons = [...polygons];
      allPolygons[index] = coordinates;
      setPolygons(allPolygons)
  }
}
const clearAllPolygons= () => {
  setPolygons([])
}
console.log('Polygons:', polygons)
  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GMAPKEY}
      libraries={mapLibraries}
    >
    
      <Grid container>
      <Grid item lg={12} xs={12} md={12} sm={12}>
        {/* <FilterArea crews={crews} /> */}
        {markers.length}
      </Grid>
        <Grid item lg={9} xs={9} md={9} sm={9}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={11}
          >
              <DrawingManager 
                  defaultDrawingMode={'POLYGON'}
                  onOverlayComplete={onOverlayComplete}
                  defaultOptions={{
                    drawingControl: true,
                    drawingControlOptions: {
                      position: 'TOP_CENTER',
                      drawingModes: [
                        'POLYGON'
                      ]
                    },
                    polygonOptions: {
                      fillColor: '#accacd',
                      fillOpacity: 1,
                      strokeWeight: 5,
                      clickable: true,
                      editable: true,
                      draggable: true,
                      zIndex: 1
                    }
                  }}
              
              />
               {/* {
                        polygons.map((iterator, index) => (
                            <Polygon
                                key={index}
                                onLoad={(event) => onLoadPolygon(event, index)}
                                onMouseDown={() => onClickPolygon(index)}
                                onMouseUp={() => onEditPolygon(index)}
                                onDragEnd={() => onEditPolygon(index)}
                                onClick={() => handlePolygonClick(index)}
                                options={polygonOptions}
                                paths={iterator}
                                draggable
                                editable
                            />
                        ))
                    } */}

            { /* Child components, such as markers, info windows, etc. */ }
            <>
            { (markers && markers.length) ? <OurMarkers markers={markers} /> : null }
            </>
          
          </GoogleMap>
        </Grid>
        <Grid item lg={3} xs={3} md={3} sm={3}>    
          <Sidebar markers={markers} selectedMarkers={selectedMarkers} clearPolygons={clearAllPolygons} setActiveMarker={setActiveMarker} activeMarker={activeMarker} crews={crews} handleUpdateVisibleCrews={updateTheMarkersOnTheMap} handleUpdateVisibleDays={updateMarkersByDay} handleUpdateVisibleByWeek={updateMarkersByWeek} handleUpdateVisibilityByFrequency={updateMarkersByFrequency} />
        </Grid>
      </Grid>

    </LoadScript>
  )
}
const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'No Preference'
]

const weekOptions = [
  'All',
  'Odd',
  'Even'
]

const FilterArea = (props) => {
  const [date, setDate] = React.useState(new Date())
  const [selected, setSelected] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [weekChosen, setWeekChosen] = React.useState('All')
  const [frequency, setFrequency] = React.useState('All')

  React.useEffect(() => {
    props.updateCrews(selected)
  }, [selected])


  React.useEffect(() => {
    props.updateDays(selectedDays)
  }, [selectedDays])

  React.useEffect(() => {
    props.updateWeeks(weekChosen)
  }, [weekChosen])

  React.useEffect(() => {
    props.updateFrequency(frequency)
  }, [frequency])

  const handleSelect = (id) => {
      if (selected.includes(id)) {
          setSelected((prev) => prev.filter((i) => i !== id));
      } else {
          setSelected((prev) => [...prev, id]);
      }
  };

  const handleSelectAll = () => {
      if (selected.length === props.crews.length) {
          setSelected([]);
      } else {
          setSelected(props.crews.map((crew) => crew._id));
      }
  };

  const handleSelectDays = (id) => {
    if (selectedDays.includes(id)) {
      setSelectedDays((prev) => prev.filter((i) => i !== id));
    } else {
      setSelectedDays((prev) => [...prev, id]);
    }
};

const handleSelectAllDays = () => {
    if (selectedDays.length === days.length) {
      setSelectedDays([]);
    } else {
      setSelectedDays(days.map((day) => day));
    }
};

const handleSelectFrequency = () => {
  console.log('Select Frequency')
};

  const handleClose = () => {
    // props.closeModal()
  }

  const handleUpdateDate = (e) => {
    setDate(e.target.value)
  }
  return (
    <div style={{ paddingVertical: 20 }}>
      <DayOptions selected={selectedDays} handleSelectAll={handleSelectAllDays} handleSelect={handleSelectDays} />
      <WeekChooser weekChosen={weekChosen} changeWeek={setWeekChosen} />
      <FrequencyChooser frequencyChosen={frequency} changeFrequency={setFrequency} />
      <CrewOptions crews={props.crews} selected={selected} handleSelectAll={handleSelectAll} handleSelect={handleSelect} />
    </div>
  )
}

const FrequencyChooser = (props) => {
  console.log('FrequencyChooser Chooser ', props)
  
    const handelChangeFrequency = (e) => {
      props.changeFrequency(e.target.value)
    }
  
    return (
      <Paper style={{ marginTop: 20, padding: 10, paddingBottom: 15 }} >
        <div style={{ fontSize: '18px', color: '#aaa' }}>Choose Which Type of Frequency Should Be Included:</div>
         <FormControl fullWidth>
          <TextField
            value={props.frequencyChosen}
            onChange={handelChangeFrequency}
            inputProps={{ 'aria-label': 'Day of the Week' }}
            fullWidth
            select
            style={{ margin: 5, padding: 10}}
            placeholder="Choose Which Day"
          >
             <MenuItem value={"All"}>
              All
            </MenuItem>
            <MenuItem value={"weekly"}>
              Weekly
            </MenuItem>
            <MenuItem value={"biweekly"}>Bi-Weekly</MenuItem>
          </TextField>
          <FormHelperText>Weekly/Bi-Weekly/All</FormHelperText>
        </FormControl>
      </Paper>
    )
  }

  const WeekChooser = (props) => {
    console.log('Week Chooser ', props)
    
      const handleChangeWeek = (e) => {
        props.changeWeek(e.target.value)
      }
    
      return (
        <Paper style={{ marginTop: 20, padding: 10, paddingBottom: 15 }} >
          <div style={{ fontSize: '18px', color: '#aaa' }}>Choose Which Weeks Should Be Included:</div>
           <FormControl fullWidth>
            <TextField
              value={props.weekChosen}
              onChange={handleChangeWeek}
              inputProps={{ 'aria-label': 'Day of the Week' }}
              fullWidth
              select
              style={{ margin: 5, padding: 10}}
              placeholder="Choose Which Day"
            >
               <MenuItem value={"All"}>
                All
              </MenuItem>
              <MenuItem value={"Odd"}>
                Odd (1, 3, 5, 7...)
              </MenuItem>
              <MenuItem value={"Even"}>Even (2, 4, 6, 8...)</MenuItem>
            </TextField>
            <FormHelperText>Odd Or Even Weeks of the Year</FormHelperText>
          </FormControl>
        </Paper>
      )
    }


const DayOptions = ({ selected, handleSelectAll, handleSelect }) => {

return (
  <Paper style={{ overflowY: 'scroll', marginTop: 20 }}>
     <div style={{ fontSize: '18px', color: '#aaa' }}>Choose Which Days Should Be Included:</div>
      <Table size="small">
          <TableHead>
              <TableRow>
                  <TableCell padding="checkbox">
                      <Checkbox
                          checked={selected.length === days.length}
                          onChange={handleSelectAll}
                      />
                  </TableCell>
                  <TableCell>Day (SELECT ALL)</TableCell>
              </TableRow>
          </TableHead>
          <TableBody>
              {days.map((day, index) => {
                const checked = selected.includes(day) ? true : false
             
                return (
                  <TableRow key={index}
                    
                  >
                      <TableCell padding="checkbox">
                          <Checkbox
                              checked={checked}
                              onChange={() => handleSelect(day)}
                          />
                      </TableCell>
                     
                      <TableCell >{day}</TableCell>
                  </TableRow>
              )})}
          </TableBody>
      </Table>
  </Paper>
)
}

const CrewOptions = ({ selected, crews, handleSelectAll, handleSelect }) => {

  return (
    <Paper style={{ overflowY: 'scroll', marginTop: 20 }}>
       <div style={{ fontSize: '18px', color: '#aaa' }}>Choose Which Crews Should Be Included:</div>
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox">
                        <Checkbox
                            checked={selected.length === crews.length}
                            onChange={handleSelectAll}
                        />
                    </TableCell>
                    <TableCell>Crew Name (SELECT ALL)</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {crews.map((crew) => {
                  const checked = selected.includes(crew._id) ? true : false
                  let bgColor = checked ? crew.color : 'inherit'
                  let fontColor = checked ? '#fff' : 'inherit'
                  return (
                    <TableRow key={crew._id}
                      
                    >
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={checked}
                                onChange={() => handleSelect(crew._id)}
                            />
                        </TableCell>
                       
                        <TableCell style={{ backgroundColor: bgColor, color: fontColor }} >{crew.crewName}</TableCell>
                    </TableRow>
                )})}
            </TableBody>
        </Table>
    </Paper>
  )
}

// export default React.memo(MyComponent)
const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(React.memo(MyComponent))

// import React from 'react'
// import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
// import { Button, CircularProgress }  from '@material-ui/core';
// const options = {
//   zoomControlOptions: {
//     position: 'right-center',
//     // ...otherOptions
//   }
// }

// function MyComponent() {
//   const { isLoaded, loadError } = useJsApiLoader({
//     googleMapsApiKey: {process.env.REACT_APP_GMAPKEY} // ,
//     // ...otherOptions
//   })

//   const renderMap = () => {
//   // console.log('Render Map')
//     // wrapping to a function is useful in case you want to access `window.google`
//     // to eg. setup options or create latLng object, it won't be available otherwise
//     // feel free to render directly if you don't need that
//     const onLoad = () => {
//     // console.log('On Load')
//       function onLoad (mapInstance) {
//         // do something with map Instance
//       // console.log('On Load....')
//       }
//     }
//     return <GoogleMap
//       options={options}
//       onLoad={onLoad}
//       style={{ height: '90vh', width: '100vw'}}
//     >
//       {
//         // ...Your map components
//       }
//     </GoogleMap>
//   }

//   if (loadError) {
//     return <div>Map cannot be loaded right now, sorry.</div>
//   }

//   return isLoaded ? renderMap() : <CircularProgress />
// }

// export default React.memo(MyComponent)
