import React from 'react'
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText'
import MenuItem from '@material-ui/core/MenuItem';

const IndividualQuestions = ({ question, updateParent }) => {
  // Each Question - style it pretty and shit
  const inputRef = React.useRef(null)

  const handleInput = (e) => {
    inputRef.current = e.target.value
    let ourQuestion = {...question}
    ourQuestion.value = inputRef.current
    updateParent(ourQuestion)
  }

  return (
    <div style={{ padding: 10, margin: 5 }}>
      <DialogContentText>{question.question}</DialogContentText>
      {
        (question && question.options && question.options.length) ? (
          <TextField
            label={question.defaultValue}
            helperText={question.helperText}
            fullWidth
            select
            ref={inputRef}
            variant="outlined"
            // onClick={handleInput}
            onChange={handleInput}
            >
              {
                question?.options.map((item, index) => {


                  return (
                    <MenuItem key={index} value={item} >
                      {item}
                    </MenuItem>
                  )
                })
              }
            
              </TextField>
        ) : (
        <TextField
          label={question.defaultValue}
          helperText={question.helperText}
          fullWidth
          ref={inputRef}
          variant="outlined"
          onChange={handleInput}
          />
        )
      }
      
    </div>
  )
}

export default IndividualQuestions