import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  gridItem: {
    width: '800px'
  }
}));

const ServiceDetailsModal = (props) => {
// console.log('Got our ServiceDetailsModal modal', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
const [open, setOpen] = React.useState(false);
const categories = [
  'Monthly Maintenance', 'Landscapes', 'Specialty', 'Clean-Ups', 'Irrigation', 'Tree Trimming', 'Lighting', 'Pest Control', 'Water Feature', 'Flowers', 'Other'
]
const [statementName, setStatementName] = React.useState('Nov2020')
const [state, setState] = React.useState({
  name: '',
  description: '',
  category: ''
});

  React.useEffect(() => {
  // console.log('Use effect Service Details', props)
    let service = props.service
    setState({...state,
    name: service.name,
    category: service.category || 0,
    description: service.description
  })
  }, [props.service]);
// const handleChange = (event) => {
//   setRange(event.target.value);
//   let newStartDate = moment().subtract(event.target.value, 'days')
// // console.log('New start Date', newStartDate)
//   setStartDate(newStartDate)
//   setOpen(false);
// };

// const handleClose = () => {
//   setOpen(false);
//   // props.onUpdate()
// }

// const handleOpen = () => {
//   setOpen(true);
// };

const handleSubmit = () => {
// console.log('Update this service!', state)
  setLoading(true)
// console.log('handle save', state)
// console.log(props)
  let obj = {
    details: state,
    service: props.service,
    user: props.user
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/company/updateservice`,
    data: obj,
    // validateStatus: (status) => {
    // // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
  // console.log('Axios response to update service ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response.data._id) {
      // props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'Saved Changes to that Service!',
        message: 'Well Done!!'
      })
      // setState({...state,
      //   name: response.data.name,
      //   category: response.data.category || 0,
      //   description: response.data.description
      // })
      // handleCloseModal()
      props.updateParent('update', response.data)
      // setOpen(false)
    } else {
    // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up creating this workorder...not sure why!'
      })
    }
    
  }).catch(err => {
  // console.log('Error saving workorder: ', err)
  })
}

const handleCloseModal = () => {
  props.updateParent('closeModal')
}

const setServiceName = (name) => {
  setState({...state, name: name})
}
  return (
    <div>
        <Dialog
          // fullScreen={false}
          maxWidth={'lg'}
          width='lg'
          // maxwidth='lg'
          // style={{width: 700}}
          open={props.open}
          // scroll='paper'
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          >
                {/* <Dialog
        maxWidth='lg'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      > */}
          <DialogTitle id="responsive-dialog-title">Service Details</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} className={classes.gridItem}>
              <Grid item lg={12} xs={12}>
                <TextField
                  id="Service Name"
                  label="Service Name"
                  type="text"
                  fullWidth
                  value={state.name}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setServiceName(e.target.value) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <TextField
                  id="Service Description"
                  label="Service Description"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  value={state.description}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setState({...state, description: e.target.value}) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="simple-select-helper-label">Category</InputLabel>
                  <Select
                    labelId="simple-select-helper-label"
                    id="simple-select-helper"
                    value={state.category}
                    variant="outlined"
                    onChange={(e) => setState({...state, category: e.target.value })}
                  >
                    <MenuItem value={0}>
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>Monthly Maintenance</MenuItem>
                    <MenuItem value={2}>Landscapes</MenuItem>
                    <MenuItem value={3}>Specialty</MenuItem>
                    <MenuItem value={4}>Clean-Ups</MenuItem>
                    <MenuItem value={5}>Irrigation</MenuItem>
                    <MenuItem value={6}>Tree Trimming</MenuItem>
                    <MenuItem value={7}>Lighting</MenuItem>
                    <MenuItem value={8}>Pest Control</MenuItem>
                    <MenuItem value={9}>Water Feature</MenuItem>
                    <MenuItem value={10}>Flowers</MenuItem>
                    <MenuItem value={11}>Other</MenuItem>
                   
                  </Select>
                  <FormHelperText>Service Category</FormHelperText>
                </FormControl>
              </Grid>

            </Grid>
          </DialogContent>

          <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit(4)} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
    </div>
  )
 

}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ServiceDetailsModal)