import React from 'react'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { currencyFormat } from '../../../services/common'

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    margin: 10
  },
  wrapCell: {
    width: "25%",
    maxHeight: 50,
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    overflow: 'ellipsis'
  }
}))

const TheTableHead = (props) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Name</TableCell>
        <TableCell>Size</TableCell>
        {/* <TableCell>Notes</TableCell> */}
        <TableCell>Cost</TableCell>
        <TableCell>MSRP</TableCell>
        <TableCell>Last Checked</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  )
}

const TableStuff = (props) => {
  const classes = useStyles()
  const rows = props.rows
  return (
    <>
      {
        rows.map((row, index) => {
          console.log(row)
          let color = '#fff'
          if (index % 2 === 0) color = '#ddd'
          return (
            <TableRow key={index} style={{ backgroundColor: color }}>
              <TableCell >{row.name}</TableCell>
              <TableCell>{row.size}</TableCell>
              {/* <TableCell className={classes.wrapCell}>{row.notes}</TableCell> */}
              <TableCell> {currencyFormat(row.price)} </TableCell>
              <TableCell> {currencyFormat(row.msrp)} </TableCell>
              <TableCell>{row.last_price_update ? moment(row.last_price_update).format('MM/DD/YY hh:mma') : ''} </TableCell>
              <TableCell><ActionButtons handleEdit={e => props.handleEdit(row) } handleDelete={ e => props.handleDelete(row) }/></TableCell>
            </TableRow>
          )
        })
      }
    </>
  )
}

const ActionButtons = (props) => {
  return (
    <>
      <IconButton aria-label="edit" size="small" onClick={props.handleEdit}>
        <EditIcon style={{ transform: 'scale(0.75)' }} />
      </IconButton>
      <IconButton aria-label="edit" size="small">
        <DeleteIcon style={{ transform: 'scale(0.75)' }} onClick={props.handleDelete} />
      </IconButton>
    </>
  )
}

const ListOfExistingOptions = (props) => {
  const list = props.list
// console.log('List:', list)
  // if (list && list.length) {
  //   return (
  //     <div>
  //       {
  //         list.map((item, index) => {
  //           return (
  //             <div key={index}>{item.name} - {item.size} {item.notes ? item.notes : ''} {item.price ? `  - Price: ${item.price}` : ''}</div>
  //           )
  //         })
  //       }
  //     </div>
  //   )
  // } else {
  //   return <div>No Options</div>
  // }
  const handleEditOption = (item) => {
  // console.log('Open this bitch up!', item)
    props.editItem(item)
  }

  const handleDeleteOption = (item) => {
    // console.log('Delete this option', item)
    let message = `Are you sure you wish to delete this option?`
    if (window.confirm(message)) props.deleteItem(item)
  }

  return (
    <div style={{ padding: 5, maxHeight: 250, overflowY: 'scroll'}}>

      <Table size="small" tablelayout="fixed" >
      <TheTableHead />
        <TableBody>
        
          <TableStuff rows={list} handleEdit={handleEditOption} handleDelete={handleDeleteOption }/>
        </TableBody>
      </Table>
    </div>
  )
}

const mapStateToProps = (state) => {
// console.log('Map State to Props add options', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ListOfExistingOptions)