import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box';
// import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios'
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress }  from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';

const Container = styled(Paper)`
  min-height: 85vh;
  margin: 5px;
  && {
    color: grey;
    background-color: white;
  }
`
const Header = styled(Box)`
  text-align: center;
  font-size: 4em;
  color: rgb(119 156 164);
`;

const ScannerInput = styled(Grid)`
  background-color: grey;
  color: #fff;
  font-size: 5em;
  min-height: 100px;
  text-align: center;
`
const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0;
  justify-content: space-between;
  align-items: space-between;
  align-self: stretch;
`;

const OrderDetails = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px;
  min-height: 500px;
  flex-wrap: wrap;
  border: 3px solid rgb(119 156 164);
`;

const ScanDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 500px;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 5px;
  flex-wrap: wrap;
  border: 3px solid rgb(119 156 164);
`;

const ShedView = (props) => {
  const [state, setState] = React.useState({
    inputValue: '',
    scan: '',
    file: ''
  })
  const [loading, setLoading] = React.useState(false)
  const [bulkQty, setBulkQty] = React.useState('')
  const [showBulkItem, setShowBulkItem] = React.useState(false)
  const [showQuantity, setShowQuantity] = React.useState(false)
  const [ignoreKeyPress, setIgnoreKeyPress] = React.useState(false)
  const [order, setOrder] = React.useState([])
  const [item, setItem] = React.useState({})

  const findItemInDatabase = async (sku) => {
  // console.log('FInd THis: ', sku)
    setLoading(true)
    const response = await axios.get(`${props.url}/api/inventory/findItem?sku=${sku}`)
  // console.log(response)
    setLoading(false)
    if (response && response.data && response.data._id) {
      setItem(response.data)
    } else {
    // console.log('New Inventory')
      props.notification({
        type: 'info',
        title: 'New Inventory?',
        message: `This barcode (${sku}) is not in the system. You can add it now if you'd like.`
      })
      setItem({
        name: '',
        price: 0,
        sku: sku,
        quantity: 0,
        min_quantity: 0,
        bulkQty: '',
        _id: 'new',
        notes: ''
      })
    }
  }

  window.onkeypress = function (e) {
  // console.log('Press: ', e)
    if (ignoreKeyPress) {
    // console.log('Ignore this shit!!')
    } else {
      if (e.code === "Enter") {
      // console.log('Scan Complete')
        if (showBulkItem) {
        // console.log('Bulk Item scan: ', state.scan)
          const lastScan = state.scan
          setState({...state, scan: '', lastScan: lastScan, inputValue: ''})
          saveBulkItem(lastScan, item)
        } else {
        // console.log(state.scan)
          let existingOrder  = order
        // console.log('Order so far: ', existingOrder)
          existingOrder.push(state.scan)
        // console.log('Order updated: ', existingOrder)
          setOrder(existingOrder)
          const lastScan = state.scan
          setState({...state, scan: '', lastScan: lastScan, inputValue: ''})
          findItemInDatabase(lastScan)
        }
      } else {
        let updatedScan = state.scan + e.key
      // console.log('Updated Scan: ', updatedScan)
        updatedScan = updatedScan.toUpperCase()
        setState({...state, scan: updatedScan })
      }
    }
  }

  React.useEffect(() => {

  }, [])

  const saveBulkItem = (bulkUPC, individualItem) => {
  // console.log('Bulk Item: ', bulkUPC)
  // console.log('Item to link to: ', individualItem)
    setShowQuantity(true)
  }

  const OrderDetailComponent = ({ details }) => {
  // console.log('Order Details: ', details)
    return <>
      <Grid container>
        {details.map((item, index) => (
          <Grid item key={index} lg={12}>
            {item}
          </Grid>
        ))}
      </Grid>
    </>
  }

  const handleSave = () => {
  // console.log('save', item)
    setLoading(true)
    let obj = {
      item: item,
      user: props.user
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/inventory/updateItem`,
      data: obj,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log('Axios response to update inventory ', response)
      setLoading(false)
      setState({...state, scan: '', inputValue: ''})
      // setState({})
      // setJobNumber(response.data)
      // if (response.data._id) {
      //   // props.newNoteResponse('newNote', response.data)
      //   props.updateContact(response.data)
        // setState({note: ''})
        props.notification({
          type: 'success',
          title: 'Inventory Item Saved!',
          message: 'Updates To This Item Have Been Saved!'
        })
  })
}

const handleLinkBulkItem = () => {
// console.log('Link Bulk Item to this: ', item)
  setState({...state, scan: '', inputValue: ''})
  setShowBulkItem(true)
}

const handleUnlinkBulkItem = () => {
  setState({...state, scan: '', inputValue: ''})
  let newItem = item
  newItem.bulkItem = false
  newItem.individualItem = null
  setItem(newItem)
}
const handleCloseBulkItemModal = () => {
  setShowBulkItem(false)
  setShowQuantity(false)
  setBulkQty('')
}

const BulkItemComponent = (props) => {
  return (
    <Dialog
    // fullScreen={false}
    // maxWidth={'sm'}
    // width='lg'
    // fullScreen
    // maxwidth='lg'
    // style={{width: '90hw'}}
    open={props.open}
    // scroll='paper'
    onClose={handleCloseBulkItemModal}
    aria-labelledby="responsive-dialog-title"
    >

    <DialogTitle id="responsive-dialog-title">Link Bulk Item</DialogTitle>
    <DialogContent>

      {
        showQuantity ? (
          <>
           <DialogContentText>
            Please Enter the Quantity of This Package:
            </DialogContentText>
            <TextField
                  label="Bulk Quantity"
                  onChange={ e => setBulkQty(e.target.value)}
                  name="BulkQuantity"
                  value={ bulkQty }
                  margin="normal"
                  fullWidth
                  autoFocus
                />
            <Button label="Save" variant="contained" fullWidth color="primary" onClick={handleLinkBulkItemSave}>Save</Button>
            
          </>
        ) : (
          <DialogContentText>
          Please Scan the Bulk Packaging Now
        </DialogContentText>
        )
      }
    </DialogContent>
  </Dialog>
  )
}

const handleLinkBulkItemSave = () => {
// console.log('Save Bulk ITem', state.lastScan)
  let data = {
    item: item,
    bulkQty: bulkQty,
    bulkUPC: state.lastScan
  }
// console.log(data)
  setLoading(true)
  axios({
    method: 'post',
    url:  `${props.url}/api/inventory/linkBulkItem`,
    data: data,
    // validateStatus: (status) => {
    // // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
  // console.log('Axios response to update inventory ', response)
    // props.updateParent('updated', response.data)
    setLoading(false)
    handleCloseBulkItemModal()
    setState({...state, scan: '', lastScan: '', inputValue: ''})
    // setState({})
    // setJobNumber(response.data)
    if (response.data._id) {
      setItem(response.data)
    }
    //   // props.newNoteResponse('newNote', response.data)
    //   props.updateContact(response.data)
      // setState({note: ''})
      props.notification({
        type: 'success',
        title: 'Bulk Item Connected!',
        message: 'Updates To This Item Have Been Saved!'
      })
})
}

const handleAddPhoto = () => {
// console.log('Add Photo')
  hiddenFileInput.current.click()
  // hiddenFileInput.blur()
}

const hiddenFileInput = React.useRef(null);

const handleFileChange = event => {
// console.log('file chanage')
  const fileUploaded = event.target.files[0];
// console.log('File UPlaoded: ', fileUploaded)
  // props.handleFile(fileUploaded);
  setState({...state, file: fileUploaded })
  let ourItem = item
  ourItem.newPhoto = fileUploaded
  // ourItem.photos = [
  //   {
  //     url: URL.createObjectURL(fileUploaded),
  //     name: fileUploaded.name,
  //     data: fileUploaded
  //   }
  // ]
  ourItem.pictureURL = URL.createObjectURL(fileUploaded)
  setItem(ourItem)
};

const handleSavePhoto = () => {
  // console.log('Save the photo', state)
  // console.log(item)
  setLoading(true)
  // let obj = {
  //   item: item,
  //   user: props.user,
  //   file: state.file
  // }
  try {
    const formData = new FormData();
    formData.append('photo', state.file);
    formData.append('item', JSON.stringify(item));
    formData.append('name', item.name);
    axios({
      method: 'post',
      url:  `${props.url}/api/inventory/addPhoto`,
      data: formData,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {

      //   }
      // },
    }).then(response => {
      // console.log('Axios response to update inventory ', response)
      setLoading(false)
      let newItem = item
      newItem.newPhoto = null
      setItem(newItem)
      setState({...state, scan: '', inputValue: ''})
      // hiddenFileInput.blur()
      document.getElementById('sku').focus()
      // setState({})
      // setJobNumber(response.data)
      // if (response.data._id) {
      //   // props.newNoteResponse('newNote', response.data)
      //   props.updateContact(response.data)
        // setState({note: ''})
      if (response && response.data && response.data.success) {
      // console.log('We succeeded!!!!')
        props.notification({
          type: 'success',
          title: 'Photo Update Successful!',
          message: 'Your photo was uploaded and saved in the cloud for posterity!'
        })
      } else {
      // console.log('We have an issue', response.data)
        let errMessage = 'Something went wrong somewhere....oh boy this is not good.'
        if (response.data.error && response.data.error.message) errMessage = response.data.error.message
        props.notification({
          type: 'warning',
          title: 'Photo Update Failed!',
          message: errMessage
        })
      }

    }).catch(err => {
    // console.log('Error caught when uploading pic: ', err)
    })
  }
  catch (e) {
  // console.log('err 353', e)
  }
}

const openIndividualItem = () => {
// console.log('Open individual item', item)
  let existingOrder  = order
  // console.log('Order so far: ', existingOrder)
  existingOrder.push(item.individualItem.sku)
  // console.log('Order updated: ', existingOrder)
  setOrder(existingOrder)
  setState({...state, lastScan: item.individualItem.sku })
  setItem(item.individualItem)
}

const updateItemAndResetScan = (paramToChange, value) => {
// console.log('Update item and reset scan', value)
// console.log(paramToChange)
  let newItem = item
  newItem[paramToChange] = value
  setItem(newItem)
  setState({...state, scan: '', lastScan: state.lastScan, inputValue: ''})
}

  const ScanDetailComponent = ({ item }) => {
  // console.log('Item: ', item)
    
    if (item && item._id) {
      let url = ''
      if (item && item.photos && item.photos.length) url = item.photos[0]['url']
      if (item.bulkItem) {
        if (item && item.individualItem) {
          let indItem = item.individualItem
          if (indItem && indItem.photos && indItem.photos.length) url = indItem.photos[0]['url']
        }
      }
      if (item.pictureURL) url = item.pictureURL
      return <>
       
          { loading ? (
            <Grid container style={{margin: '8px'}}>
              <Grid item lg={12} xs={12} style={{ textAlign: 'center'}}>
                <CircularProgress size={144} />
              </Grid>
            </Grid>
          ) : 
          (
            <Grid container style={{margin: '8px'}}>
          <Grid item lg={12}>{item.name}
            { item.bulkItem ? 
                <strong>BULK ITEM ({item.bulkQty + ' of ' }<span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline'}} onClick={openIndividualItem}>{item.individualItem.name}</span>)</strong> : '' }
          </Grid>
          <Grid item lg={6}>
            <Grid container style={{margin: 10}}>
              {/* <Grid item lg={6} sm={6} md={6}></Grid> */}
              <Grid item lg={6} sm={6} md={6} >
                {
                  item.bulkItem ? <Button label="Unlink Bulk Item" variant="contained" color="secondary" onClick={handleUnlinkBulkItem}>NOT Bulk</Button> : <Button label="Add Bulk Item" variant="contained" color="secondary" onClick={handleLinkBulkItem}>Link Bulk Item</Button>
                }
              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12}>
                <TextField
                label="Name"
                onFocus={e => setIgnoreKeyPress(true)}
                onBlur = { e => setIgnoreKeyPress(false) }
                onChange={ e => updateItemAndResetScan('name', e.target.value) }
                name="itemName"
                value={ item.name }
                margin="normal"
                fullWidth
              />
              </Grid>
              <Grid item lg={12}>
                <TextField
                label="Price"
                onFocus={e => setIgnoreKeyPress(true)}
                onBlur = { e => setIgnoreKeyPress(false) }
                onChange={ e => updateItemAndResetScan('price', e.target.value)}
                name="itemPrice"
                value={ item.price }
                margin="normal"
                fullWidth
                disabled={item.bulkItem}
              />
              </Grid>
              <Grid item lg={4}>
                <TextField
                label="Quantity On Hand"
                onFocus={e => setIgnoreKeyPress(true)}
                onBlur = { e => setIgnoreKeyPress(false) }
                onChange={ e => updateItemAndResetScan('quantity', e.target.value) }
                name="itemQty"
                value={ item.quantity }
                margin="normal"
                fullWidth
                disabled={item.bulkItem}
              />
              </Grid>
              <Grid item lg={4}>
                <TextField
                label="Minimum Quantity"
                onFocus={e => setIgnoreKeyPress(true)}
                onBlur = { e => setIgnoreKeyPress(false) }
                onChange={ e => updateItemAndResetScan('min_quantity', e.target.value) }
                name="itemMinQty"
                value={ item.min_quantity }
                margin="normal"
                fullWidth
                disabled={item.bulkItem}
              />
              </Grid>
              <Grid item lg={4}>
                <TextField
                  label="Bulk Quantity"
                  onFocus={e => setIgnoreKeyPress(true)}
                onBlur = { e => setIgnoreKeyPress(false) }
                  onChange={ e => updateItemAndResetScan('bulkQty', e.target.value) }
                  name="BulkQty"
                  value={ item.bulkQty }
                  margin="normal"
                  fullWidth
                  disabled={!item.bulkItem}
              />
              </Grid>
              <Grid item lg={12}>
                <TextField
                label="SKU"
                id="sku"
                onFocus={e => setIgnoreKeyPress(true)}
                onBlur = { e => setIgnoreKeyPress(false) }
                onChange={ e => updateItemAndResetScan('sku', e.target.value) }
                name="itemSKU"
                value={ item.sku }
                margin="normal"
                fullWidth
                
              />
            </Grid>
            <Grid item lg={12}>
                <TextField
                label="Notes"
                onFocus={e => setIgnoreKeyPress(true)}
                onBlur = { e => setIgnoreKeyPress(false) }
                onChange={ e => updateItemAndResetScan('notes', e.target.value) }
                name="itemNotes"
                value={ item.notes }
                margin="normal"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item lg={12}>
            <Button color="primary" variant="contained" fullWidth disabled={loading} onClick={handleSave} >
              Save
            </Button>
          </Grid>
          </Grid>
          <Grid item lg={6} style={{textAlign: 'center'}}>
            <img width="250" src={url} style={{margin: '25px'}}></img>
            { item.newPhoto ? (
              <>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSavePhoto}
                  fullWidth
                    >Save Photo 
                  </Button>
              </>
              ) : (
                <>
              <br />
                  <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddPhoto}
                    >Add/Change Photo 
                  </Button>
                  <input
                    type="file"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{display: 'none'}}
                  />
                </>
              )
            }
          </Grid>
                   
        </Grid>
        )
            }
      </>
    } else {
      return <></>
    }
  }

    return <>
    { BulkItemComponent({ open: showBulkItem }) }
    <Container>
      <Header>Inventory Update</Header>
      <ScannerInput container>
        <Grid item lg={12}>
          {state.lastScan}
        </Grid>
      </ScannerInput>
      <MainContent>
        <OrderDetails><OrderDetailComponent details={order} /></OrderDetails>
        <ScanDetails>{ScanDetailComponent({ item: item})}</ScanDetails>
      </MainContent>
    </Container>
  </>
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ShedView)