import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { Button, CircularProgress, TextField } from '@material-ui/core';
import Paper from '@material-ui/core/Paper'
import axios from 'axios'
import Autocomplete from '@material-ui/lab/Autocomplete';


const ScopeChooser = (props) => {
  // console.log('Scope Chooser', props)
  const [associatedScopes, setAssociatedScopes] = React.useState([])

  React.useEffect(() => {
    if (props.item && props.item.scopes && props.item.scopes.length) {
      let scopes = props.scopes
      let chosenScopes = props.item.scopes
      let scopesToAssociate = []
      for (const item of chosenScopes) {
        let index = scopes.findIndex(scopeItem => (scopeItem._id === item))
        if (index > -1) {
          let theScope = scopes[index]
          scopesToAssociate.push(theScope)
        }
      }
      // console.log('Set these', scopesToAssociate)
      setAssociatedScopes(scopesToAssociate)
    } else {
      setAssociatedScopes([])
    }
  }, [props.item])

  const handleScopeChange = (e, newScopes) => {
    // console.log('New Scopes', newScopes)
    setAssociatedScopes(newScopes)
  }

  const handleUpdate = (e, newScopes) => {
    // console.log('Upate', newScopes)
    // console.log(associatedScopes)
    props.updateScopes(associatedScopes)
  }

  return (
    <div>
      <Autocomplete
            id="contact"
            options={props.scopes || []}
            getOptionLabel={(option) => {
              return `${option.name}`
            }}
            value={associatedScopes}
            selectOnFocus
            multiple
            onBlur={handleUpdate}
            onChange={handleScopeChange}
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Scope" placeholder="Scopes To Associate?" />
            )}
          />
    </div>
  )
}

const ChooseScopeComponent = (props) => {
  // Scope Picker
  // console.log('Pick a scope:', props)
  return (
    <>
      <h4>Choose Scope(s) That This Size Will Belong To</h4>
      <ScopeChooser  {...props} />
    </>
  )
}

const mapStateToProps = (state) => {
  // console.log('States availabel ', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    scopes: state.scopes
  }
}
  
  export default connect(mapStateToProps)(ChooseScopeComponent)