import React from 'react'
import { connect } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Permissions from './Permissions'
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import axios from 'axios'
import DeleteIcon from '@material-ui/icons/Delete';
import ProtectedButton from '../../actions/protectedButtons';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import SearchIcon from "@material-ui/icons/Search";
import InputAdornment from "@material-ui/core/InputAdornment";
import CloseIcon from '@material-ui/icons/Close';

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  width: 100%;
  margin-top 20px;
`;

const DefaultButtonContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items; center;
  justify-content: center;
  margin: 10;
  padding: 10;
`;

const DefaultButton = styled(Box)`
  display: flex;
  alignSelf: stretch;
  margin: 10;
  padding: 10;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: '100%',
    height: 250,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const AssignedCrews = (props) => {
  // console.log('ASSIGNED CREWS', props)

  const classes = useStyles();

  const [profile, setProfile] = React.useState({})
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [crews, setCrews] = React.useState([])
  const [availableCrews, setAvailableCrews] = React.useState([])
  const [leftChecked, setLeftChecked] = React.useState([])
  const [rightChecked, setRightChecked] = React.useState([])
  const [state, setState] = React.useState({
    searchValue: ''
  })
  const [filteredRows, setFilteredRows] = React.useState([])
  // React.useEffect(() => {
  // // console.log('USER PROFILE CHANGED ASSIGNED ENTITY')
  //   if (props.userProfile && props.userProfile.entities && props.userProfile.entities.length) {
  //     setEntities(props.userProfile.entities)
  //     setProfile(props.userProfile)
  //   }
  // }, [props.userProfile])

  React.useEffect(() => {
    setAvailableCrews(props.crews)
    setFilteredRows(props.crews)
    // console.log('EMPLOYEE: ', props.employee)
    if (props.employee && props.employee.crewsSupervised && props.employee.crewsSupervised.length) {
      // console.log('Assign Crews', props.employee.crewsSupervised)
      let allCrews = props.crews
      let assignedCrews = []
      props.employee.crewsSupervised.map(item => {
        let index = allCrews.findIndex(i => (i._id.toString() === item.toString() ))
        // console.log('INDEX: ', index)
        if (index > -1) {
          assignedCrews.push(allCrews[index])
          allCrews.splice(index, 1)
        }
      })
      // console.log('Set Our Assigned Crews')
      setCrews(assignedCrews)
      setAvailableCrews(allCrews)
    }
  }, [props.employee])

  React.useEffect(() => {
    props.saveCrews(crews)
  }, [crews])

//  const updateavailableCrews = (availEntities) => {
//   // console.log('Entities has changed!!!', entities)
//     let userEntities = entities
//     let entitiesToShowAsAvailable = availEntities
//     for (const item of userEntities) {
//       const index = entitiesToShowAsAvailable.findIndex(entity => {
//         if (item._id === entity._id) {
//           return entity
//         }
//       })
//     // console.log('Entity Index: ', index)
//     // console.log(item)
//       if (index > -1) {
//         entitiesToShowAsAvailable.splice(index, 1)
//       }
//     }
//     let ids = entitiesToShowAsAvailable.filter(item => { return item._id })
//     setLeft(ids)
//     let assignedIds = entities.filter(item => { return item._id })
//     setRight(assignedIds)
//     setavailableCrews(entitiesToShowAsAvailable)
//   }

  // let leftChecked = intersection(checked, left);
  // let rightChecked = intersection(checked, right);

  // console.log('Left Checked: ', leftChecked)
  // console.log('Right Checked: ', rightChecked)
  // console.log('Left: ', left)
  // console.log("Right: ", right)
  // console.log('Checked: ', checked)

  const handleToggle = (value) => () => {
    // console.log('Toggle: ', value)
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    // console.log('New Checked: ', newChecked)

    let ids = availableCrews.map(item => { return item._id })
    let idsOfAssignedEntities = crews.map(item => { return item._id })
    // Set Right and Left
    // console.log('ids', ids)
    setLeftChecked(intersection(newChecked, ids))
    setRightChecked(intersection(newChecked, idsOfAssignedEntities))
    setChecked(newChecked);
  };

  const handleAllRight = () => {
    // setRight(right.concat(left));
    // Move the filtered rows - in case of  search value
    // console.log('Filtered Rows length: ', filteredRows)
    // console.log('# assigned Crews: ', crews.length)
    let newEnts = crews.concat(filteredRows)
    // console.log('Crews assigned now: ', newEnts.length)
    setCrews(newEnts)
    setFilteredRows([])
    let crewsAvailable = props.crews
    newEnts.map(crew => {
      let index = crewsAvailable.findIndex(i => (i._id.toString() === crew._id.toString() ))
      // console.log('INDEX: of assigned crews ', index)
      if (index > -1) {
        // assignedCrews.push(allCrews[index])
        // console.log('Remove this: ', crewsAvailable[index])
        crewsAvailable.splice(index, 1)
      }
    })
    setAvailableCrews(crewsAvailable)
    setCrews(newEnts)
    saveEntities(newEnts)
  };

  const handleCheckedRight = () => {
    // console.log('Handle CHeck Right: ', crews)
    // console.log('Left Checked: ', leftChecked)
    let newLeft = availableCrews
    let newRight = crews
    // Set our Left Box
    for (const item of leftChecked) {
    // console.log(item)
      let index = newLeft.findIndex(ent =>  (ent['_id'] === item));
      // let index = -1
      // availableCrews.findIndex((ent, index) => { 
      //   // console.log(ent._id)
      //   // if (ent._id === item) {
      //   // // console.log('match')
      //   //   return ent
      //   // }
      //   (parseInt(ent._id.toString() === item.toString()))
      // })
      // console.log("INDEX", index)
      if (index > -1) {
        let itemToMove = availableCrews[index]
        newRight.push(itemToMove)
        newLeft.splice(index, 1)
        // console.log('Updated Left: ', newLeft)
        setFilteredRows(newLeft)
      }
    }
    setLeftChecked([])
    // console.log('New Right: ', newRight)
    setCrews(newRight)
    // setRight(right.concat(leftChecked));
    // setLeft(not(left, leftChecked));
    setChecked([]);
    saveEntities(newRight)
    // updateavailableCrews(availableCrews)
  };

  const handleCheckedLeft = () => {
    // console.log('CHeck Left')
    // console.log('RIGHT CHECKED', rightChecked)
    let newLeft = availableCrews
    let newRight = crews
    // Set our Left Box
    for (const item of rightChecked) {
      // console.log(item)
      let index = newRight.findIndex(ent =>  (ent['_id'] === item));
      // let index = -1
      // availableCrews.findIndex((ent, index) => { 
      //   // console.log(ent._id)
      //   // if (ent._id === item) {
      //   // // console.log('match')
      //   //   return ent
      //   // }
      //   (parseInt(ent._id.toString() === item.toString()))
      // })
      // console.log("INDEX", index)
      if (index > -1) {
        let itemToMove = newRight[index]
        newLeft.push(itemToMove)
        newRight.splice(index, 1)
        // console.log('Updated Left: ', newLeft)
        setFilteredRows(newLeft)
      }
    }
    // setLeftChecked([])
    setRightChecked([])
    // console.log('New Right: ', newRight)
    setCrews(newRight)
    // setRight(right.concat(leftChecked));
    // setLeft(not(left, leftChecked));
    setChecked([]);
    setCrews(newRight)
    saveEntities(newRight)
    // setEntities(entities.concat(rightChecked));
    // setRight(not(right, rightChecked));
    // setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
  // console.log('ALL crews', props.crews)
    // console.log('Available: ', availableCrews)
    // let newAvail = availableCrews.concat(crews)
    // console.log('New Avail: ', newAvail)
    setCrews([]);
    setFilteredRows(props.crews);
    setAvailableCrews(props.crews)
    saveEntities([])
  };

  const saveEntities = (ents) => {
    // console.log('Save this shit')
    // setTimeout(() => {
    props.saveCrews(ents)
    // }, 2500)
  }

  const customList = (items) => {
    // console.log('Custom List: ', items)
    return (
    <Paper className={classes.paper}>
      <List component="div" role="list">
        {items.map((item, index) => {
          const labelId = `transfer-list-item-${index}-label`;

          return (
            <ListItem key={index} role="listitem" button onClick={handleToggle(item._id)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(item._id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.name} />
              <ListItemText id={labelId + 'division'} primary={item.division} style={{margin: '5px', padding: '5px'}} />
            </ListItem>
          );
        })}
        <ListItem />
        {items.length === 0 ? (
          <span style={{padding: '10', margin: 10}}>List Empty</span>
        ) : ''}
      </List>
    </Paper>
  )};

  const handleUpdateDefaultEntity = () => {
  // console.log('UPdate Default Entity!')
    let index = crews.findIndex(ent =>  (ent['_id'] === rightChecked[0]))
    if (index > -1) {
      const theEntity = crews[index]
    // console.log(theEntity)
      // props.updateDefaultEntity(theEntity, entities)
    }
  }

  const filterOut = (r, searchText) => {
  // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    setFilteredRows(newArr)
  }

  const handleSearch = (e) => {
    setState({...state, searchValue: e.target.value})
    filterOut(availableCrews, e.target.value)
  }

  const clearSearch = () => {
    setState({...state, searchValue: ''})
    setFilteredRows(availableCrews)
  }
  return (
    <Container>
      Please Choose the Crews Assigned to {props.employee.firstName} (Assigned: {crews.length})
    <Grid
    container
    spacing={2}
    alignItems="center"
    className={classes.root}
  >
     <Grid item lg={4} md={4}><h3>Available Crews</h3></Grid>
     <Grid item lg={2} md={2}></Grid>
     <Grid item lg={4} md={4}><h3>Assigned Crews</h3></Grid>
     <Grid item lg={4} md={4} sm={4}>
      <TextField
        className={classes.paddedStuff}
        margin="dense"
        variant="outlined"
        id="search"
        label="Search Available Crews"
        type="text"
        onChange={handleSearch}
        value={state.searchValue}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                arial-label="clear search"
                 onClick={clearSearch}
                 size="small"
                >
                  <CloseIcon />
                  </IconButton>
            </InputAdornment>
          )
        }}
    />
     </Grid>
     <Grid item lg={2} md={2}></Grid>
     <Grid item lg={4} md={4}>

    </Grid>
    <Grid item style={{width: '45%'}}>{customList(filteredRows)}</Grid>
    <Grid item>
      <Grid container direction="column" alignItems="center">
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleAllRight}
          disabled={filteredRows.length === 0}
          aria-label="move all right"
        >
          ≫
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          aria-label="move selected right"
        >
          &gt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          aria-label="move selected left"
        >
          &lt;
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={classes.button}
          onClick={handleAllLeft}
          disabled={crews.length === 0}
          aria-label="move all left"
        >
          ≪
        </Button>
      </Grid>
    </Grid>
    <Grid item style={{width: '45%'}}>
      {customList(crews)}
    </Grid>
  </Grid>
  
  </Container>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms,
    crews: state.crews
  }
}

export default connect(mapStateToProps)(AssignedCrews)