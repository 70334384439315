import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import SendIcon from '@material-ui/icons/Send';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle }  from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

const LabelText = styled.div`
  color: #bbb;
  font-size: 1.2em;
  padding: 5px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  hoverButton: {
    cursor: 'pointer',
    borderRadius: '50%',
    margin: 'auto',
    '&:hover': {
      color: '#aaa'
    }
  },
  onRight: {
    marginLeft: 'auto'
  },
  subList: {
    padding: 0,
    margin: 5,
    borderRadius: 5
  }
}));

const SubCategoryList = (props) => {
  const classes = useStyles()
  const [list, setList] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0
  })
  React.useEffect(() => {
  // console.log('SUbcategory list', props.list)
    setList(props.list)
  }, [props.list])

  const handleChange = (e, index) => {
  // console.log('Change', e.target.value, index)
    let newItem = {
      name: e.target.value
    }
    let newList = list
    newList[index] = newItem
    // e.target.value
    setList(newList)
  // console.log(newList)
    setState({...state, counter: state.counter++})
  }

  const handleUpdateParent = () => {
    props.updateSubCategoryList(list)
  }

  return (
    <div>
      {
        list.map((item, index) => {
          return (
            <TextField
              fullWidth
              key={index}
              value={item.name}
              onChange={ e => handleChange(e, index)}
              variant="outlined"
              onBlur={handleUpdateParent}
              className={classes.subList}
              />
          )
        })
      }
    </div>
  )
}

const defaultStateValues = {
  name: 'Un-Named',
  counter: 0
}

const EditModal = (props) => {
  const classes = useStyles()
  const [state, setState] = React.useState(defaultStateValues)
  const [subCategories, setSubCategoryList] = React.useState([])

  React.useEffect(() => {
    if (props.category && props.category.name) setState({ ...state, name: props.category.name })
    if (props.category && props.category.subCategories && props.category.subCategories.length ) {
      setSubCategoryList(props.category.subCategories)
    } else {
      setSubCategoryList([])
    }
  }, [props.category])

  const handleClose = () => {
    props.closeModal()
  }

  const handleSave = () => {
    // console.log('Save', state)
    // console.log('Subs', subCategories)
    props.updateCategory({ category: props.category, name: state.name, subCategories: subCategories })
  }

  const handleNewSubCategory = () => {
    let list = subCategories
    list.push({
      name: '',
      _id: Date.now()
    })
  // console.log(list)
    setSubCategoryList(list)
    setState({ ...state, counter: state.counter++ })
  }

  const handleUpdateSubCategories = (updatedList) => {
    // console.log('UPdated list of subcategories', updatedList)
    // setState({ ...state, counter: state.counter++ })
    setSubCategoryList(updatedList)
    setState({ ...state, counter: state.counter++ })
  }

  return (
    <Dialog
      open={props.open}
      maxWidth={'sm'}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>Edit {state.name || ''}</DialogTitle>
      <DialogContent>
        <LabelText>Please Modify As Needed:</LabelText>
        <TextField
              variant="outlined"
              fullWidth
              id="name"
              label="Category Name"
              value={state.name || ''}
              // onChange={ updateRef }
              onChange={e=>setState({...state, name: e.target.value})}
              // onBlur={handleUpdateParent}
            />
          <LabelText>Please Add/Remove Sub-Categories:</LabelText>
          <div
            style={{ width: '100%', display: 'flex' }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.onRight}
              onClick={handleNewSubCategory}>Add New Sub-Category</Button>
          </div>
          <SubCategoryList list={subCategories} updateSubCategoryList={handleUpdateSubCategories} />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          >Save</Button>
      </DialogActions>
    </Dialog>
  )
}
const NewCategoryModal = (props) => {

  const name = React.useRef(null)
  const handleClose = () => {
    props.closeModal()
  }

  const handleSave = () => {
    props.saveCategory(name)
  }

  const updateRef = (e) => {
    // console.log(inputRef)
    name.current = e.target.value
    // console.log(nameRef)
  }

  return (
    <Dialog
      open={props.open}
      maxWidth={'sm'}
      fullWidth
      onClose={handleClose}
    >
      <DialogTitle>Add New Category</DialogTitle>
      <DialogContent>
        <LabelText>Please Enter the Category Name:</LabelText>
        <TextField
              variant="outlined"
              fullWidth
              id="name"
              label="Category Name"
              // value={state.name}
              onChange={ updateRef }
              // onChange={e=>setState({...state, name: e.target.value})}
              // onBlur={handleUpdateParent}
            />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          >Save</Button>
      </DialogActions>
    </Dialog>
  )
}
const items = [
  {
    name: 'Test',
    index: 0
  },
  {
    name: 'Test 2',
    index: 1
  }
]
const ScopeCategorySelection = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [openIndex, setOpenIndex] = React.useState(null)
  const [showNewCategoryModal, setShowNewCategoryModal] = React.useState(false)
  const [itemToEdit, setItemToEdit] = React.useState(null)
  const [showEditModal, setShowEditModal] = React.useState(false)

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOpen = (index) => {
    if (openIndex === index) {
      setOpenIndex(null)
    } else {
      setOpenIndex(index)
    }
  }

  const handleNewCategory = () => {
    setShowNewCategoryModal(true)
  }

  const handleSaveNewCategory = (name) => {
    setShowNewCategoryModal(false)
    // console.log('save', name)
    props.saveCategory(name.current)
  }

  const handleEdit = (i) => {
  // console.log('Edit: ', i)
  // console.log(props.list[i])
    setItemToEdit(props.list[i])
    setShowEditModal(true)
  }

  const updateCategory = (data) => {
  // console.log('Save this', data)
    props.updateCategory(data)
    setShowEditModal(false)
  }
  return (
    <div style={{ width: '100%' }}>
      <EditModal open={ showEditModal } category={itemToEdit} updateCategory={updateCategory} closeModal={ e=> setShowEditModal(false) } />
      <NewCategoryModal open={ showNewCategoryModal } closeModal={ e => setShowNewCategoryModal(false) } saveCategory={handleSaveNewCategory} />
      <LabelText>
        Choose Scope Categories & Sub-Categories
      </LabelText>
      <div
        style={{ width: '100%', display: 'flex' }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.onRight}
          onClick={handleNewCategory}>Add New</Button>
      </div>
      {
        props.list.length ? (
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Scope Categories
              </ListSubheader>
            }
            className={classes.root}
          >
            {
              props.list.map((item, index) => {
                let bgColor = '#ccc'
                if (index % 2 === 0) bgColor = '#ddd'
                return (
                  <React.Fragment>
                    <ListItem 
                    style={{ backgroundColor: bgColor, border: '1px solid #bbb'}}
                    button
                    key={index}
                    onClick={e => handleOpen(index)}
                    // onDoubleClick={ e => handleEdit(index) }
                  >
                    
                      <ListItemIcon
                        onClick={ e => handleEdit(index) }
                        className={classes.hoverButton}
                      >
                        <EditIcon />
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                      {
                        (item.subCategories && item.subCategories.length) ? (openIndex === index) ? <ExpandLess /> : <ExpandMore /> : null
                      }
                    </ListItem>
                    {
                      (item.subCategories && item.subCategories.length) ? (
                        <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {
                              item.subCategories.map((subcategory, index) => {
                              // console.log('sub', subcategory)
                                return (
                                  <ListItem key={index} button className={classes.nested}>
                                    {/* <ListItemIcon>
                                      <StarBorder />
                                    </ListItemIcon> */}
                                    <ListItemText primary={`${subcategory.name || ''}`} />
                                  </ListItem>
                                )
                              })
                            }
                            
                          </List>
                        </Collapse>
                      ) : null
                    }

                  </React.Fragment>
                )
              })
            }
    
          </List>
        ) : null
      }
    </div>
  )
}

export default ScopeCategorySelection