import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import { Editor } from 'react-draft-wysiwyg';
// import {EditorState} from "draft-js";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const NewNoteModal = (props) => {
  // console.log('NewProjectModal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
  const [content, setContentState] = React.useState({})

  const inputRef = React.useRef()

  React.useEffect(() => {
    // console.log('PROPS.note changed', props)
    // setState({...state, name: props.item.name })
    // setDialogValue({...dialogValue, name: props.item.name, description: props.item.name})
    // let html = '<h1>Test</h1>'
    // setContentState(content)
    let html = ``;
    if (props.note && props.note.content) {
      html = props.note.content
    }
  // console.log('HTML: ', html)
    if (html && html.blockMap) {
      return
    }
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      setContentState(contentState)
      setEditorState(editorState)
      // inputRef.current.focus()
    }
    // inputRef.current.focus()
  }, [props.note])

  const onEditorStateChange = (editorStateChange) => {
    // console.log(editorStateChange)
    setEditorState(editorStateChange)
  }

  const handleBlur = (data) => {
    // console.log(data)
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    // console.log(html)
    setContentState(html)
    // props.updateContent(html)
  }
  const onContentStateChange = (contentState) => {
    // console.log(contentState)
    setContentState(contentState)
  }

  
  const handleSave = () => {
    // console.log(inputRef)
    setLoading(true)
    // setClient({})
    // setState({...state, note})
  // console.log('Save', content)
    // console.log('REf', inputRef.current)
    let data = {
      content: content,
      projectId: props.project._id,
      editing: props.note.editing,
      existingNote: props.note
    }
  // console.log('Post data', data)
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/addProjectNotes`,
      data: data
    }).then(response => {
      setLoading(false)
      if (response.data && response.data._id) {
        props.notification({
          type: 'success',
          title: 'Project Note Saved',
          message: 'That Note Has Been Saved. Great Job!'
        })
        props.updateParent(response.data)
        // props.saveNote(content)
        // props.closeModal()
      }
    }).catch((err) => {
    // console.log('error', err)
      props.notification({
        type: 'warning',
        title: 'Project Note Error Saving',
        message: err.message || 'Undefined Error 190'
      })
    })
  }

  // const handleSave = () => {
  //   setLoading(true)
  //   let data = {
  //     content,
  //     contactId: props.contact._id
  //   }
  //   axios({
  //     method: 'post',
  //     url:  `${props.url}/api/contact/addNote`,
  //     data: data,
  //   }).then(response => {
  //     // console.log('Sent Email: ', response.data)
  //     setLoading(false)
  //     if (response && response.data && response.data.error) {
  //       props.notification({
  //         type: 'warning',
  //         title: 'Error Saving Note',
  //         message: response.data.message
  //       })
  //       return
  //     }
  //     if (response && response.data && response.data._id) {
  //       // console.log(response.data)
  //       props.notification({
  //         type: 'success',
  //         title: 'Note Saved!',
  //         message: `Now You'll Never Forget`
  //       })
  //       props.closeModal(response.data)
  //     } else {
  //       props.notification({
  //         type: 'warning',
  //         title: 'Error With This Note!',
  //         message: 'Something Seems to Have Gone Wrong...'
  //       })
  //     }


  //   }).catch(err => {
  //     // // console.log('Error caught when uploading pic: ', err)
  //     props.notification({
  //       type: 'warning',
  //       title: 'Error With This Note!',
  //       message: err.message
  //     })
  //   })
  // };

  const updateRef = (e) => {
    inputRef.current[e.target.id] = e.target.value
  }
  const handleFocus = (e) => {
    e.target.select()
    e.target.focus()
  }

  return (
    <div className={classes.root}>
      <Dialog open={props.open} fullWidth={false} onClose={props.closeModal} maxWidth='lg' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">New Note</DialogTitle>
          <DialogContent >
            <DialogContentText>
              What Would You Like To Remember?
            </DialogContentText>
           <div style={{ border: '3px solid gray', marginTop: 10, marginBottom: 20, minHeight: 400}} >
              <Editor
                // initialContentState={content}
                editorState={editorState}
                // wrapperClassName="wrapper"
                // editorClassName="editor"
                handleFocus
                ref={inputRef}
                onEditorStateChange={onEditorStateChange}
                // onContentStateChange={onContentStateChange}
                onBlur={handleBlur}
              />
          </div>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
              Cancel
            </Button> */}
            <Button color="primary" disabled={loading} onClick={handleSave}>
              {
                loading ? <CircularProgress /> : (props.note && props.note.editing) ? 'Update' : 'Save'
              }
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  )
}


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(NewNoteModal)