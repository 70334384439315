import React from 'react'
import AudioPlayer from './AudioPlayer'

const listStyle = {
  display: 'flex',
  alignSelf: 'stretch',

}

const UploadsList = (props) => {
// console.log('Customer ISSUE UPLOAD LIST:::', props)
  const issue = props.issue
  let list = null
  if (issue && issue.uploads) list = issue.uploads
// console.log('Show the uploads for this one:', issue)
  return (
    <div style={listStyle}>
      {
        (list && list.length) && <MiniGallery list={list} />
      }
    </div>
  )
}

// const MiniGallery = ({ list }) => {
// // console.log('Mini Gallery list:', list)
//   return (
//     <div>
//       {
//         list.map((item, index) => {
//           return (
//             <div style={{ maxHeight: 100 }}>
//               <img src={item.url} />
//             </div>
//           )
//         })
//       }
//     </div>
//   )
// }
const MiniGallery = ({ list }) => {
// console.log('Mini Gallery list:', list)
  return (
    <div style={{ display: 'flex', overflowX: 'auto', width: '100%' }}>
      {
        list.map((item, index) => {
          if (item.name && item.name.includes('jpg')) {
            return (
              <div key={index} style={{ flex: '0 0 auto', height: '100px', overflow: 'hidden', marginRight: '10px' }}>
                <img src={item.url} style={{ height: '100%', width: 'auto' }} />
              </div>
            )
          } else if (item && item.name.includes('mp4')) {
            return (
              <div key={index} ><AudioPlayer audioURL={item.url} /></div>
            )
          } else {
            return (
              <div key={index} >Other</div>
            )
          }
        })
      }
    </div>
  )
}


// const MiniGallery = ({ list }) => {
// // console.log('Mini Gallery list:', list)
//   return (
//     <div style={{ maxWidth: 300, overflowX: 'scroll' }}>
//       {
//         list.map((item, index) => {
//           return (
//             <div style={{ maxHeight: '150px', width: '80px' }}>
//               <img src={item.url} style={{ maxWidth: '90%', height: 'auto' }} />
//             </div>
//           )
//         })
//       }
//     </div>
//   )
// }

export default UploadsList