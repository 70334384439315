import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import InputMask from "react-input-mask";
import SetPINModal from './SetPINModal'
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

const defaultValues = {
  pin1: '',
  pin2: '',
  employee: {}
}

const SelfVerifyPIN = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState(defaultValues)
  const [showPINModal, setShowPINModal] = React.useState(false)
  const [requestDetails, setRequestDetails] = React.useState({})

  React.useEffect(() => {
    let isMounted = true

    const fetchRequest = async (id) => {
        let url = `${props.url}/api/company/getEmployeePinRequestDetails?requestId=${id}`
        // console.log('URL to retrieve: ', url)
        const response = await axios.get(url)
        console.log(response.data)
        if (response && response.data && response.data.success && isMounted) {
          setRequestDetails(response.data.request)
          setState({...state, employee: response.data.request.employee})
        }
      }

    if (props.match && props.match.params && props.match.params.requestId) {
      fetchRequest(props.match.params.requestId)
    }

    return () => {
      isMounted = false
    }
  }, [])


  const handleSave = () => {
    console.log('Save', state)
    let obj = {
      employee: state.employee,
      pin: state.pin1
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/company/updateEmployeePINRequest`,
      data: obj
    }).then(response => {
      // console.log('Absence Reported', response.data)
      if (response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Employee PIN Saved',
          message: `Your PIN Has Been Saved - You Can Now Use This PIN To Login To The Mobile App!`
        })
        setState(defaultValues)
        props.closeModal()
      } else {
        props.notification({
          type: 'warning',
          title: 'Error Saving PIN',
          message: response.data.message
        })
      }
    })

  }

  
  const handleUpdatePIN = (e, num) => {
    if (num === 1) {
      setState({...state, pin1: e.target.value})
    }
    if (num === 2) {
      setState({...state, pin2: e.target.value})
    }
  }
  return (
    <Paper className={classes.paper}>
       
      <Grid container >
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          <Typography variant="h4" className={classes.title}>
            Create Employee PIN
            <br/>
            Crear PIN de empleado
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          <Typography variant="subtitle1" className={classes.subText}>
          Ingrese un PIN que recordará. Utilizará esto en lugar de una contraseña para verificar su identidad cuando inicie sesión en la aplicación móvil en un futuro próximo.
            <br/>
            Please enter a PIN that you will remember. You will use this instead of a password to verify your identity when you login to the mobile app.
           
          </Typography>
         
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          <FormControl fullWidth style={{marginBottom: '20px'}}>
                      <TextField
                      id="pin1"
                      label="PIN Attempt #1"
                      variant="outlined"
                      fullWidth
                      type="password"
                      
                      // className={classes.textSaveLineBreaks}
                      value={state.pin}
                      onChange={e => handleUpdatePIN(e, 1)}
                      inputProps={{
                        maxLength: 8,
                        style: {
                          height: '70px',
                          padding: '0 14px',
                          fontSize: '25px'
                        },
                    }}
                      // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                  />
                  <FormHelperText>El PIN debe tener entre 4 y 8 dígitos: algo que puedas recordar / PIN Should Be 4 to 8 Digits - Something  You Can Remember</FormHelperText>
                </FormControl>
                  <FormControl fullWidth style={{marginBottom: '20px'}}>
                        <TextField
                        id="pin2"
                        label="PIN Attempt #2"
                        variant="outlined"
                        fullWidth
                        type="password"
                        
                        // className={classes.textSaveLineBreaks}
                        value={state.pin2}
                        onChange={e => handleUpdatePIN(e, 2)}
                        inputProps={{
                          maxLength: 8,
                          style: {
                            height: '70px',
                            padding: '0 14px',
                            fontSize: '25px'
                          },
                      }}
                        // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                    />
                    <FormHelperText>El PIN debe tener entre 4 y 8 dígitos: algo que puedas recordar / PIN Should Be 4 to 8 Digits - Something  You Can Remember</FormHelperText>
                  </FormControl>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          <Button
            onClick={handleSave}
            variant="contained"
            color="primary"
            fullWidth
            disabled={!state.pin1 || !state.pin2 || (!state.employee || !state.employee._id)}
          >Save / Ahorrar </Button>
        </Grid>
        {/*
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          {
            (error && error.isError) && (
              <div  style={{ backgroundColor: 'rgba(244, 54, 58, 0.4)', borderRadius: 5, padding: 20, margin: 20 }}>
                Error: {error.message}
              </div>
            )
          }
                    {
            (success && success.isActive) && (
              <div style={{ backgroundColor: 'rgba(54, 244, 58, 0.4)', borderRadius: 5, padding: 20, margin: 20 }}>
                 {success.message}
              </div>
            )
          }
        </Grid> */}
      </Grid> 
  </Paper>
  )
}



  const useStyles = makeStyles((theme) => ({
    paper: {
      maxWidth: '50%', // it will take 50% on large screens
      width: '100%',   // it will take full width on small screens
      margin: '0 auto', // this centers the Paper horizontally
      padding: theme.spacing(4), // some internal padding
      [theme.breakpoints.down('sm')]: { // breakpoint for small screens
        maxWidth: '100%',
      },
      marginTop: '10%'
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(2), // some space below the title
    },
    subText: {
      textAlign: 'left',
      fontSize: '1.2em',
      color: '#bbb',
      marginBottom: theme.spacing(4), // some space below the subtext
    },
    telephone: {
      // maxWidth: '200px', // adjust as needed
    },
    paddedGridItem: {
      padding: 10,
      margin: 5
    }
  }));

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(SelfVerifyPIN)