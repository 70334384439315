import React, { useState } from 'react';
import {
  Modal,
  Backdrop,
  Fade,
  Paper,
  Typography,
  TextField,
  Button,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '400px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const defaultState = {
  clockIn: null,
  clockOut: null,
  employee: '',
  hoursWorked: 0
}

const AddShiftModal = ({ open, handleClose, employees, onSave }) => {
  const classes = useStyles();
  // const [selectedEmployee, setSelectedEmployee] = useState('');
  // const [clockIn, setClockIn] = useState(null);
  // const [clockOut, setClockOut] = useState(null);
  // const [hoursWorked, setHoursWorked] = useState(0)
  const [state, setState] = useState(defaultState)
  const resetState = () => {
    // setSelectedEmployee('')
    // setClockIn(null)
    // setClockOut(null)
    // setHoursWorked(0)
    setState(defaultState)
  }
  const handleSave = () => {
    if (state?.employee?._id && state.clockIn && state.clockOut) {
      onSave(state);
      resetState()
      handleClose();
    } else {
      alert('Please fill in all fields');
    }
  };

  const updateEmployee = (e, newEmp) => {
    setState(prevState => ({...prevState, employee: newEmp }))
  }

  const handleTimeChange = (type, value) => {
    // console.log('Type: ', type)
    // console.log('Value', value)
      let isValid = moment(value, 'YYYY-MM-DDThh:mm').isValid()
      let allValid = false
      let newState = {}
    // console.log('Valid? ', isValid)
      if (isValid) {
      // console.log('Value: ', value)
        let clockIn = null
        let clockOut = null
        if (type === 'clockIn') {
          clockIn =  moment(value, 'YYYY-MM-DDThh:mm').local()
          clockOut = state.clockOut // moment(state.clockOut, 'YYYY-MM-DDThh:mm').local()
          newState.clockIn = clockIn
        }
        if (type === 'clockOut') {
          clockIn = state.clockIn // moment(state.clockIn, 'YYYY-MM-DDThh:mm').local()
          clockOut = moment(value, 'YYYY-MM-DDThh:mm').local()
          newState.clockOut = clockOut
        }
        if (clockIn?.isValid() && clockOut?.isValid()) allValid = true
      console.log('Clock IN', clockIn)
      console.log('Clock out: ', clockOut)
      // newState.clockIn = clockIn
      // newState.clockOut = clockOut
      let hoursWorkedRounded = 0
      if (allValid) {
        hoursWorkedRounded = Math.abs(clockIn - clockOut) / 36e5
        hoursWorkedRounded = parseFloat(hoursWorkedRounded).toFixed(2)
        console.log(hoursWorkedRounded)
        // newState.hoursWorked = hoursWorkedRounded
      }
      newState.hoursWorked = hoursWorkedRounded
        // let hoursWorkedRounded = Math.abs(clockIn - clockOut) / 36e5
      console.log('Hours WOrked: ', hoursWorkedRounded)

        // if (type === 'clocK')
        console.log('New state', newState)
        setState(prevState => ({...prevState, ...newState }))
      }
    }

    const formatDateForInput = (date) => {
      return date ? moment(date).format('YYYY-MM-DDTHH:mm') : '';
    }

    // console.log('STATE', state)
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Typography variant="h5" gutterBottom>
            Add Employee Shift
          </Typography>
          <form className={classes.form}>
            <FormControl fullWidth>
              {/* <InputLabel id="employee-select-label">Employee</InputLabel> */}
              <Autocomplete
                id="emp-id"
                labelId="employee-select-label"
                value={state.employee}
                onChange={updateEmployee}
                options={employees || []}
                getOptionLabel={(option) => (option.displayName || '')}
                className={classes.paddedStuff}
                
                renderInput={(params) => <TextField {...params} label="Employee"  />}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="clock-in"
                type='datetime-local'
                // value={state?.clockIn}
                label="Clock-In"
                value={formatDateForInput(state.clockIn)}
                // value={moment(state.clockIn).format('MM/DD/YYYY  HH:mma') }
                onChange={e => handleTimeChange('clockIn', e.target.value)}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="clock-out"
                type='datetime-local'
                // value={state.clockOut }
                value={formatDateForInput(state.clockOut)}
                label="Clock-Out"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => handleTimeChange('clockOut', e.target.value)}
                fullWidth
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                id="hoursworked"
                type='number'
                value={state.hoursWorked}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                label="Hours Worked"
              />
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save Shift
            </Button>
          </form>
        </Paper>
      </Fade>
    </Modal>
  );
};

export default AddShiftModal;