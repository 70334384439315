import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import moment from 'moment';

const EditOrderQuantity = (props) => {
  // console.log('EDIT QTY MODAL: ', props)
  const [state, setState] = React.useState({
    qty: 0
  })

  React.useEffect(() => {
    setState({...state, qty: props.item.orderQuantity || 0 })
  }, [props.item])

  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleSubmit = () => {
  // console.log('Handle Submit', state)
    props.updateParent(state.qty)
  }

  const handleQtyChange = () => {
  // console.log('Handle qty change')
  }

return (
  <div>
    
      <Dialog
        // fullScreen={false}
        // maxWidth={'sm'}
        // width='lg'
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Modify Quantity</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Adjust The Quantity of {props.item.name}
          </DialogContentText>
          <div style={{ textAlign: 'center' }}>
            <img src={props.item.pictureURL} height="250px" />
            </div>
          <br />
            <TextField
                label="Quantity"
                onChange={ e => setState({...state, qty: e.target.value })}
                name="itemQty"
                value={ state.qty }
                margin="normal"
                fullWidth
                variant="outlined"
              />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button disabled={!state.qty} onClick={() => handleSubmit()} variant="contained" color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(EditOrderQuantity)