import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, TextField, InputAdornment }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl, FormLabel } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
// import UploadDetailsModal from './UploadDetailsModal'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ViewTaskModal from './ViewTaskModal';
import DeleteIcon from '@material-ui/icons/Delete';
import ProtectedButton from '../../actions/protectedButtons';
import CreateTaskComponent from './NewTaskComponent'

function desc(a, b, orderBy, isDate) {
  // console.log('desc: ', a)
  // console.log('desc b: ', b)
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array.length)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  // console.log('Matches: ', stabilizedThis.length)
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const headCells = [
  // { id: 'name', numeric: false, disablePadding: false, label: 'Task Name' },
  { id: 'details', numeric: false, disablePadding: false, label: 'Task Details' },
  { id: 'assigned_to', numeric: false, disablePadding: false, label: 'Assigned To' },
  { id: 'created_by', numeric: false, disablePadding: false, label: 'Assigned By' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'created_at', numeric: false, disablePadding: false, label: 'Created' },
  { id: 'dueDate', numeric: false, disablePadding: false, label: 'Due' },
  { id: 'completed_at', numeric: false, disablePadding: false, label: 'Completed' },
  { id: 'ontime', numeric: false, disablePadding: false, label: 'On-Time' },
  { id: 'action', numeric: false, disablePadding: true, label: 'Action' }
];

function EnhancedTableHead(props) {
  // console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    // console.log('Sort Property: ', property)
    // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    margin: 10
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  rowPad: {
    height: '40px !important',
    padding: '2px'
  },
  rowPadCOMPLETE: {
    height: '40px !important',
    backgroundColor: '#eee',
    padding: '2px'
    // border: '3px solid green',
    // color: 'inherit'
    // padding: 'px'
  }
}));

function EnhancedTable(props) {
// console.log('TASK LIST TABLE', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [rows, setRows] = React.useState([])
  const [filteredRows, setFilteredRows] = React.useState([])
  const [activeTask, setActiveTask] = React.useState({})
  const [state, setState] = React.useState({
    searchMatches: 0,
    project: {},
    showCompleted: false
  })
  const [showUploadDetails, setShowUploadDetails] = React.useState(false)
  const activeTasks = props.tasks.filter(item => { return item.status !== 'Complete' })

  React.useEffect(() => {
    if (props.tasks && props.tasks.length) {
      // setRows(props.tasks)
      if (state.showCompleted) {
        filterOut(props.tasks, searchValue)
      } else {
        
        // console.log('Active Tasks', activeTasks)
        filterOut(activeTasks, searchValue)
      }
    } else {
      // setRows([])
      setFilteredRows([])
    }
    // let isMounted = true
    // const fetchTasks = async () => {
    //   const response = await axios.get(`${props.url}/api/tasks/listTasks`)
    //   if (response && response.data && response.data.length) {
    //   // console.log('Tasks', response.data)
    //     setRows(response.data)
    //     setFilteredRows(response.data)
    //   }
    // }
    // fetchTasks()
  }, [props])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, task) => {
    // console.log('Double Click')
    setActiveTask(task)
    setOpen(true)
  }
  //   const selectedIndex = selected.indexOf(name);
  // // console.log('handle click ', name)
  // // console.log('Index: ', selectedIndex)
  //   let newSelected = [];

  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selected, name);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selected.slice(1));
  //   } else if (selectedIndex === selected.length - 1) {
  //     newSelected = newSelected.concat(selected.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selected.slice(0, selectedIndex),
  //       selected.slice(selectedIndex + 1),
  //     );
  //   }

  //   setSelected(newSelected);
  // };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const storeSearchMatches = (d) => {
    // console.log('Search matches: ', d)
    // setState({...state, searchMatches: d.length})
  }
  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // return newArr
    // console.log('Lenght: ', newArr.length)
    // console.log(newArr)
    // storeSearchMatches(newArr)
    // setsearchMatches(newArr.length)
    // return newArr
    // setRows(newArr)
    setFilteredRows(newArr)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(props.tasks)
    } else {
      const rows = state.showCompleted ? props.tasks : activeTasks
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }


  const handleCloseTaskModal = () => {
    setOpen(false)
  }

  const handleViewTaskResponse = (data, type) => {
    // console.log('Data: ', data)
    // console.log('Type: ', type)

    if (type === 'complete') {
      setOpen(false)
      const rows = state.showCompleted ? props.tasks : activeTasks
      let newTasks = rows
      let index = newTasks.findIndex(item => {
        // console.log(item)
        return (item._id.toString() === data._id.toString()) 
      })
      // console.log(index)
      if (index > -1) {
        newTasks.splice(index, 1)
        // console.log(newTasks)
        // setRows(newTasks)
        filterOut(newTasks, searchValue)
        // setState({...state, counter: state.counter++})
      }
    }

    if (type === 'saved') {
      // update our task
      // console.log('Save this task: ', data)
      // console.log(activeTask)
      let newTasks = rows
      let index = newTasks.findIndex(item => {
        // console.log(item)
        return (item._id.toString() === data._id.toString()) 
      })
      // console.log(index)
      if (index > -1) {
        newTasks[index] = data // (index, 1)
        // console.log(newTasks)
        // setRows(newTasks)
        setActiveTask(data)
        // setState({...state, counter: state.counter++})
      }
    }
  }

  const handleDeleteTask = async (task) => {
    setLoading(true)
    if (window.confirm(`Are you sure that you wish to delete this task? This is not reversible.`)) {
      const rows = state.showCompleted ? props.tasks : activeTasks
      let newTasks = rows
      let index = newTasks.findIndex(item => {
        // console.log(item)
        return (item._id.toString() === task._id.toString()) 
      })
      // console.log(index)
      if (index > -1) {
        // newTasks[index] = data // (index, 1)
        newTasks.splice(index, 1)
        // console.log(newTasks)
        // setRows(newTasks)
        // setActiveTask(data)

        filterOut(newTasks, searchValue)
        setState({...state, counter: state.counter++})
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/tasks/deleteTask`,
        data: task
      }).then(response => {
        setLoading(false)
        // if (response.data && response.data._id) {
          props.notification({
            type: 'success',
            title: 'Task Deleted',
            message: 'Task Has Been Deleted'
          })
      })
    } else {
      setLoading(false)
    }
  }

  const handleToggleShowCompletedTasks = (e) => {
  // console.log(e.target.value)
    let showCompleted = !state.showCompleted
    // alert(showCompleted)
    const rows = showCompleted ? props.tasks : activeTasks
    let ourRows = rows
    if (showCompleted) {
      ourRows = props.tasks
    } else {
      ourRows = props.tasks.filter(item => { return item.status !== 'Complete' })
    }
    // setRows(ourRows)
    if (searchValue) {
      filterOut(ourRows, searchValue)
    } else {
      setFilteredRows(ourRows)
    }
    setState({...state, showCompleted })
  }

  return (
    <div className={classes.root}>
      <ViewTaskModal open={open} task={activeTask} closeModal={handleCloseTaskModal} updateParent={handleViewTaskResponse} />
      <CreateTaskComponent client={props.client} project={props.project} />
        Total Tasks: {props.tasks.length} { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
      <Grid container style={{padding: '10px'}}>
            
          <Grid item lg={6} xs={6}>

                <TextField
                    name="search" 
                    value={ searchValue } 
                    onChange={ handleChange('search') } 
                    type="text"
                    label="Search"
                    type="search"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                />
          </Grid>
          <Grid item lg={6} xs={6} style={{ marginTop: 35, textAlign: 'right' }}>
          <FormControl component="fieldset" >
                  {/* <FormLabel component="legend">Show Completed Tasks</FormLabel> */}
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={state.showCompleted}
                      onChange={ handleToggleShowCompletedTasks }
                      name="Completed" />}
                      label={ state.showCompleted ? 'Show Completed Tasks' : 'Show Completed Tasks'}
                    />
                  </FormGroup>
                </FormControl>
          </Grid>
          <Grid item lg={6}>
          {/* <FormControlLabel
                    control={<Checkbox checked={showAllEmployees} onChange={setShowAllOrNot} name="showAllEmployees" />}
                    label="Include Inactive"
                  /> */}
          </Grid>

        </Grid>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            options={{
              rowStyle: {height: 55}
            }}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody >
              { stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log('Row map: ', row)
                  // console.log('Total rows; ', rows)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  let url = ''
                  if (row && row.photos && row.photos.length) url = row.photos[0]['url']
                  // setsearchMatches(rows.length)

                    return (
                    
                      <TableRow
                        hover
                        onDoubleClick={event => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        className={ row.status === 'Complete' ? classes.rowPadCOMPLETE : classes.rowPad }
                        selected={isItemSelected}
                      >
                      

                        <TableCell align="left">
                          
                          { row.details }
                        </TableCell>

                        <TableCell align="left">
                        { (row.assigned_to && row.assigned_to.name) ? row.assigned_to.name : '' }
                        </TableCell>
                        <TableCell>
                          { (row.created_by && row.created_by.name) ? row.created_by.name : '' }
                        </TableCell>
                        <TableCell>
                          {row.status}
                        </TableCell>
                        <TableCell align="left"> {moment(row.created_at).format('MM/DD/YYYY hh:mm a')} </TableCell>
                        <TableCell align="left"> {row.dueDate ? moment(row.dueDate).format('MM/DD/YYYY hh:mm a') : ''} </TableCell>
                        <TableCell align="left"> { row.completed_at ? moment(row.completed_at).format('MM/DD/YYYY hh:mm a') : ''} </TableCell>
                        <TableCell align="left"> { row.completed_at ? moment(row.completed_at) <= moment(row.dueDate) ? 'On Time' : 'Late' : ''} </TableCell>
                        <TableCell align="left"> 
                          <ProtectedButton type="Tasks" kind="delete" perms={props.perms}>
                            <IconButton edge='end' aria-label="delete" onClick={e => handleDeleteTask(row)} >
                              <DeleteIcon id={row._id}  />
                            </IconButton>
                          </ProtectedButton>
                        </TableCell>
                      </TableRow>
                    );
 
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          { loading ?<CircularProgress style={{color: '#fff'}} size={ 16 } /> : ''}
                    
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          // onChangePage={handleChangePage}
          onPageChange={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
  )
}

  const mapStateToProps = (state) => {
    return {
      authenticated: state.authenticated,
      notification: state.notification,
      url: state.url,
      perms: state.perms,
      client: state.client
    }
  }
  
  export default connect(mapStateToProps)(EnhancedTable)
