import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import { Button, CircularProgress, TextField, IconButton, Typography }  from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import PlusIcon from '@material-ui/icons/Add';
import { fade, makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import PrintIcon from '@material-ui/icons/Print'
import MapIcon from '@material-ui/icons/Map';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputMask from "react-input-mask";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isEven } from '../../services/common'
import { Link } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DeleteComponent from '../Common/DeleteComponent'

const MtcChecklistComponent = (props) => {
  const classes = styles()
  const [list, setList] = React.useState([])

  React.useEffect(() => {
    let isMounted = false

    const fetchDefaultList = async () => {
      const response = await axios.get(`${props.url}/api/settings/getDefaultMtcChecklistItems`)
      console.log('Response', response.data)
      setList(response.data)
    }
    fetchDefaultList()
    return () => {
      isMounted = false
    }
  }, [])

  const handleUpdateItem = async (newListItems, updatedItem) => {
    console.log('Set New list', newListItems)
    const obj = {
      updatedItem: updatedItem,
      newList: newListItems
    }
    const response = await axios.post(`${props.url}/api/settings/setDefaultMtcChecklistItems`, obj)
    console.log('Update Response', response.data)
    setList(response.data)
  }

  const handleDeleteItem = async (itemToDelete) => {
    let currentList = [ ...list ]
    let index = currentList.findIndex(item => (item._id === itemToDelete._id))
    if (index > -1) {
      currentList.splice(index, 1)
      // const response = await axios.post(`${props.url}/api/settings/deleteChecklistItem`, itemToDelete)
      // console.log('Update Response', response.data)
      setList(currentList)
    }
  }
  
  return (
    <div className={classes.root}>
      <h1>Mtc Checklist</h1>
      <Paper style={{ minHeight: '80vh', marginRight: 20, padding: 10  }}>
        <Typography>Please Add/Modify Our List of Items That Are Available for Mtc Checklist</Typography>
        <ChecklistItems list={list} updateItem={handleUpdateItem} deleteItem={handleDeleteItem} />
      </Paper>
    </div>
  )
}

const ChecklistItems = (props) => {
  
  const list = props.list
  console.log(list)
  const [isModalOpen, setModalOpen] = React.useState(false)
  const [item, setItem] = React.useState({})

  const handleOpenModal = () => {
    setModalOpen(true)
  }
  const handleUpdateFromModal = (data) => {
    // console.log('Update from modal', data)
    setModalOpen(false)
    if (data && data.type) {
      if (data.type === 'save') {
        let newList = [ ...list, data.data ]
        props.updateItem(newList, data.data)
      }
      if (data.type === 'update') {
        let newItems = [...list]
        newItems.map((listItem, index) => {
          if (listItem._id === item._id) {
            newItems[index] = data.updatedItem
          }
        })
        console.log(newItems)
        props.updateItem(newItems, data.updatedItem)
        setItem({})
      }
    }
  }

  const handleEdit = (itemToEdit) => {
    setItem(itemToEdit)
    setModalOpen(true)
  }

  const handleDelete = (itemToDelete) => {
    props.deleteItem(itemToDelete)
  }

  return (
    <div>
      <ModifyItemModal open={isModalOpen} item={item} updateParent={handleUpdateFromModal} />
      <IconButton onClick={handleOpenModal} ><AddIcon size={48} /></IconButton>
      {
        list.map((item, index) => {
          return (
            <div key={index}>
              {item.english} / {item.spanish}
              <IconButton onClick={ e => handleEdit(item) }><CreateIcon size={14} /></IconButton>
              <DeleteComponent
                type="Settings"
                item={item}
                title="Delete Item?"
                verificationText={`Are you sure you wish to Delete this checklist item?`}
                confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
                confirmationButton="DELETE"
                denyText="NO"
                ifApproved={e => handleDelete(item)}
                deleteFunction={'mtcChecklistItem'}
              />
            </div>
          )
        })
      }
    </div>
  )
}

const defaultVals = {
  english: '',
  spanish: ''
}

const ModifyItemModal = (props) => {
  const [state, setState] = React.useState(defaultVals)

  React.useEffect(() => {
    setState({ ...state, ...props.item })
  }, [props.item])

  const handleClose = () => {
    props.updateParent()
  }
  const updateItem = (e) => {
    setState({...state, [e.target.id]: e.target.value})
  }
  const handleSave = () => {
    console.log('Save it', state)
    if (props.item && props.item.english) {
      props.updateParent({
        type: 'update',
        originalItem: props.item,
        updatedItem: state
      })
      setState(defaultVals)
    } else {
      props.updateParent({
        type: 'save',
        data: state
      })
      setState(defaultVals)
    }
  }
  return (
    <Dialog
      size="md"
      fullWidth
      open={props.open}
      onClose={handleClose}
      style={{ margin: 10, padding: 15 }}
      >
        <DialogContent>
          <DialogTitle>{(props.item && props.item.english ) ? 'Modify' : 'Add'} Item</DialogTitle>
            <TextField
                id="english"
                label="English"
                value={state.english}
                helperText="What Will Someone Viewing the App See in English? This should be short and concise ideally"
                fullWidth
                variant="outlined"
                
                // className={classes.paddedStuff}
                onChange={ updateItem }
              />
              <TextField
                id="spanish"
                label="Spanish"
                value={state.spanish}
                helperText="What Will Someone Viewing the App See in Spanish? This should be short and concise ideally"
                fullWidth
                variant="outlined"
                
                // className={classes.paddedStuff}
                onChange={ updateItem }
              />
        </DialogContent>
       
     
      <DialogActions>
      <Button
          onClick={handleClose}
          >CANCEL</Button>
        <Button
          onClick={handleSave}
          >SAVE</Button>
      </DialogActions>
      </Dialog>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    crews: state.crews
  }
}

export default React.memo(connect(mapStateToProps)(MtcChecklistComponent))

// const useStyles = makeStyles(theme => ({
  const styles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(1),
      [theme.breakpoints.down('sm')]: {
        // backgroundColor: theme.palette.secondary.main,
        textAlign: 'center'
      },
      [theme.breakpoints.down('md')]: {
        margin: 10,
        // backgroundColor: theme.palette.secondary.main,
      },
      [theme.breakpoints.up('md')]: {
        // backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('lg')]: {
        // backgroundColor: '#ccc',
      },
    },
    topButtons: {
      [theme.breakpoints.down('sm')]: {
        // backgroundColor: theme.palette.secondary.main,
        // textAlign: 'center',
        margin: 5,
      },
      [theme.breakpoints.down('md')]: {
        margin: 5,
        // backgroundColor: theme.palette.secondary.main,
      },
      [theme.breakpoints.up('md')]: {
        // backgroundColor: theme.palette.primary.main,
      },
      [theme.breakpoints.up('lg')]: {
        // backgroundColor: '#ccc',
      },
    },
    mainContainer: {
      border: '3px solid #aaa',
      height: '80vh',
      width: '100%',
      padding: 5
    }
  }))