import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, InputLabel, Select, FormControl
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AddNewOptionModal from '../Inventory/options/AddNewOptionModal'
import axios from 'axios'
import EditTakeOffItem from '../Inventory/EditTakeOffItem'

const filter = createFilterOptions();
const handleFocus = (event) => event.target.select();

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const yardOptions = [
  'New River',
  'Cave Creek'
]

const areaOptions = [...Array(21).keys()].slice(1)

const defaultStateValues = {
  count: 0,
  pricePer: 0,
  costPer: 0,
  notes: '',
  yard: '',
  area: ''
}

const TakeoffModal = ({ url, open, handleClose, takeoffs, handleSave }) => {
  // console.log('Add Lot INventory modal', takeoffs)
  const classes = useStyles();
  const [selectedTakeoff, setSelectedTakeoff] = useState(''); 
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [options, setOptions] = useState([])
  const [showNewOptionModal, setShowNewOptionModal] = useState(false)
  const [sizes, setSizes] = useState([])
  const [scopes, setScopes] = useState([])
  const [vendors, setVendors] = useState([])
  const [showAddNewTakeoffModal, setShowAddNewTakeoffModal] = useState(false)
  const [state, setState] = useState(defaultStateValues)

  // const [scope,]
  React.useState(() => {
    const fetchSizes = async () => {
      // console.log('Get our sizes and shit')
        axios
        .get(
          `${url}/api/settings/getStuffForNewItem`
          )
        .then(({ data }) => {
          console.log('38 Data....', data)
          let newState = {}
          if (data && data.sizes) {
            setSizes(data.sizes)
          }
          if (data && data.scopes) {
            setScopes(data.scopes)
            // setCategories(data.scopes)
            newState.categories = data.scopes
          }
          if (data && data.vendors) {
            setVendors(data.vendors)
          }
          // setState({...state, ...newState})
        }).catch(function (err) {
        // console.log('Error getting stuff', err)
        });
      }

      fetchSizes()
  }, [])



  const handleSubmit = () => {
    const obj = {
      selectedTakeoff,
      selectedOption,
      costPer: state.costPer,
      pricePer: state.pricePer,
      count: state.count,
      yard: state.yard,
      area: state.area
    }
    // console.log(obj)
    handleSave(obj)
    resetState()
  };

  const resetState = () => {
    setSelectedOption('')
    setSelectedTakeoff('')
    setState(defaultStateValues)
    setLoading(false)
  }

  const chooseTakeoff = (e, itemSelected) => {
    // console.log('Choose takeoff i', itemSelected)
    if (itemSelected && itemSelected._id) {
      if (itemSelected._id === 'create') {
      setShowAddNewTakeoffModal(true)
      } else {
        setSelectedTakeoff(itemSelected)
        setSelectedOption('')
        setOptions(itemSelected.options)
      }
    } else if (itemSelected && itemSelected.name && itemSelected.name === 'Add New Item') {
      setShowAddNewTakeoffModal(true)
    }
  }

  const selectOurOption = (e, chosenOpt) => {
    // console.log('Chosoe this one', chosenOpt)
    if (chosenOpt && chosenOpt._id === 'create') {
      console.log('Make new option')
      setShowNewOptionModal(true)
    } else {
      setSelectedOption(chosenOpt)
    }
  }

  const handleChange = (e) => {
    const itemToset = e.target.id
    const valToSet = e.target.value
    setState(prevState => ({
      ...prevState,
      [itemToset]: valToSet
    }))
  }

  const handleCloseNewOptionModal = (type, data) => {
    console.log('Type', type)
    console.log('Data', data)
    if (type === 'newOptionAdded') {
      if (data && data.options && data.options.length) {
        setOptions(data.options)
        let optionChosen = data.options[data.options.length - 1]
        if (optionChosen && optionChosen.msrp) setState({...state, pricePer: optionChosen.msrp })
        setSelectedOption(optionChosen)
      }
    }
    setShowNewOptionModal(false)
  }

  const responseFromEditItem = (d, savedItem) => {
    // console.log('Update our item:', d)
    // console.log('Saved item:', savedItem)
    if (d === 'closeModal') setShowAddNewTakeoffModal(false)
    if (d === 'saved') {
      chooseTakeoff(d, savedItem)
    }
    // if (d === 'updated' || d === 'updateOptions') {
    // // console.log('Updated this bitch', savedItem)
    //   // Update the list
    //   let inventoryItems = rows
    //   // console.log('Got %d clients: ', clients.length)
    //   let index = inventoryItems.findIndex(item =>  (item._id === savedItem._id))
    // console.log('Index:', index)
    // console.log('Might need to search by Inventory item', inventoryItem)
    //   if (index < 0) {
    //   console.log('search by inventory item')
    //     index = inventoryItems.findIndex(item =>  (item._id === inventoryItem._id))
    //   console.log('Index!', index)
    //   }
    //   if (index > -1) {
    //     inventoryItems[index] = savedItem
    //     // console.log('Agai: ', services[index])
    //     // console.log(services)
    //     setRows(inventoryItems)
    //     filterOut(inventoryItems, searchValue)
    //     // setFilteredRows(inventoryItems)
    //   } else {
    //     // services.push(d)
    //     // setRows(services)
    //     // setFilteredRows(services)
    //   }
    //   // console.log('Did we update the item??')
    //   setInventoryItem(savedItem)
    //   if (d === 'updated') setOpen(false)
    // }
    // if (d === 'deleted') {
    //   // Update the list
    //   let inventoryItems = rows
    //   // console.log('Got %d clients: ', clients.length)
    //   let index = inventoryItems.findIndex(item =>  (item._id === savedItem._id))
    //   if (index > -1) {
    //     // inventoryItems[index] = savedItem
    //     inventoryItems.splice(index, 1)
    //     // console.log('Agai: ', services[index])
    //     // console.log(services)
    //     setRows(inventoryItems)
    //     filterOut(inventoryItems, searchValue)
    //     // setFilteredRows(inventoryItems)
    //   } else {
    //     // services.push(d)
    //     // setRows(services)
    //     // setFilteredRows(services)
    //   }
      setShowAddNewTakeoffModal(false)
  }

  const closeEditModal = () => { setShowAddNewTakeoffModal(false) }

  const handleSelect = (e) => {
    setState({...state, yard: e.target.value })
  }

  const addItem = (d) => {
    console.log('Add item...', d)
  }

  let scope = {
    name: 'Scope Name',
    _id: '448489348349'
  }

  const createOption = {
    _id: 'create',
    value: 'create',
    label: 'Create New',
    name: 'Add New Option'
  }
  let ourOptions = [ createOption ]
  if (options && options.length) ourOptions = [ ...options, createOption ]
  // console.log('Our takeoff items', ourTakeoffs)
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <AddNewOptionModal scope={scope} item={selectedTakeoff} sizes={sizes} open={showNewOptionModal} closeModal={handleCloseNewOptionModal} />
      <EditTakeOffItem item={null} sizes={sizes} scopes={scopes} vendors={vendors} open={showAddNewTakeoffModal} updateParent={responseFromEditItem} handleCloseModal={closeEditModal} addItem={addItem} />
      <DialogTitle id="form-dialog-title">Select Takeoff Item</DialogTitle>
      <DialogContent>
        <Autocomplete
          freeSolo
          id={`takeoffitem`}
          options={takeoffs || []}
          // getOptionLabel={(option) => `${option.name ? option.name : ''} ${option.altName ? `(${option.altName})` : ''}`}
          style={{ marginBottom: '10px' }}
          onChange={chooseTakeoff}
          value={selectedTakeoff}
          getOptionLabel={(option) => {
            // console.log('242 option', option)
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return 'Save ' + option.inputValue + ' as a New Item';
            }
            // Regular option
            return `${option.name ? option.name : ''} ${option.altName ? `(${option.altName})` : ''}`
          }}
                
          filterOptions={(options, params) => {

            const filtered = filter(options, params);
    
            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                title: `Add "${params.inputValue}"`,
                name: 'Add New Item'
              });
            }
    
            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={selectedTakeoff ? '' : 'Please Choose'}
              label="Takeoff Item"
              variant="outlined"
              helperText="What Is the Type of Plant Material/Item?"
            />
          )}
        />
        <Autocomplete
          id={`takeoffoption`}
          options={ourOptions || []}
          getOptionLabel={(option) => {
            // console.log('Options: ', option)
            if (option && option._id) {
              return `${option.name ? option.name : 'No name'}`
            } else {
              return null
            }
            
          }}
          // style={{ width: 200 }}
          style={{marginBottom: '10px'}}
          onChange={selectOurOption}
          disabled={loading || !selectedTakeoff || selectedTakeoff === '' || (selectedTakeoff && (!selectedTakeoff._id || selectedTakeoff._id === 'create'))}
          // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
          value = {selectedOption}
          // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
          renderInput={(params) => <TextField {...params} placeholder="Choose an Option" variant="outlined" helperText="What Size/Color/Etc?" />}
        />
        <TextField
          id="count"
          label="Count"
          type="number"
          variant="outlined"
          style={{marginBottom: '10px'}}
          fullWidth
          value={state.count}
          onChange={handleChange}
          disabled={!selectedOption}
          onFocus={handleFocus}
          helperText="How Many?"
        />
        <TextField
          id="costPer"
          label="Cost Per"
          type="number"
          variant="outlined"
          style={{marginBottom: '10px'}}
          fullWidth
          value={state.costPer}
          onChange={handleChange}
          onFocus={handleFocus}
          disabled={!selectedOption}
          helperText="How Much Do They Cost Per?"
        />
        <TextField
          id="pricePer"
          label="Price Per"
          type="number"
          style={{marginBottom: '10px'}}
          variant="outlined"
          fullWidth
          value={state.pricePer}
          onFocus={handleFocus}
          onChange={handleChange}
          disabled={!selectedOption}
          helperText="How Much Do We Wish to Sell These For? (Optional)"
        />
        <TextField
          id="yard"
          label="Yard"
          select
          style={{marginBottom: '10px'}}
          variant="outlined"
          fullWidth
          value={state.yard}
          onChange={handleSelect}
          disabled={!selectedOption}
          helperText="Where is this? New River or Cave Creek?"
        >
          {
            [...yardOptions].map((yard, index) => {
              return (
                <MenuItem key={index} value={yard}>{yard}</MenuItem>
              )
            })
          }
        </TextField>
        <TextField
          id="area"
          label="Area"
          type="number"
          style={{marginBottom: '10px'}}
          variant="outlined"
          fullWidth
          value={state.area}
          onFocus={handleFocus}
          onChange={handleChange}
          disabled={!selectedOption}
          helperText="Which Area of the Nursery?"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TakeoffModal;
