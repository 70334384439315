import React from 'react'
import EmployeeList from './EmployeeList.js'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core';

const Employees = (props) => {
  // console.log('Serivce Component', props)

  return (
    <div style={{padding: '5px', margin: '10px'}}>
      <Grid container>
        <Grid item lg={6} xs={12}>
          <h1>Employees</h1>
        </Grid>
        <Grid item lg={6} xs={12}>
        </Grid>
        <Grid item lg={12} xs={12}>
        <EmployeeList />
        </Grid>
      </Grid>
      
  </div>
  )
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(Employees)