import React, { Component } from 'react'
import { fade, makeStyles } from '@material-ui/core/styles'
import { Typography, IconButton } from '@material-ui/core'
import moment from 'moment'
import CrewDailyLogLastTen from './CrewDailyLogLastTen'
import ChatIcon from '@material-ui/icons/Chat';
import ChatDrawer from '../Common/Chat/ChatDrawer'
import Tooltip from '@material-ui/core/Tooltip';
import Badge from '@material-ui/core/Badge';
import ExploreIcon from '@material-ui/icons/Explore';
import EventNote from '@material-ui/icons/EventNote'
import CrewMapViewSupervisorView from '../Crews/CrewMapViewSupervisorView'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%'
    // margin: '20px'
  },
  mainContainer: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%'
  },
  itemContainer: {
    border: '3px solid #769ba2',
    borderRadius: 8,
    backgroundColor: '#fff',
    margin: 10,
    padding: 10,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
 
 
 
    //  justifyContent: 'space-evenly',
    marginTop: 2,
    maxHeight: 400,
    overflowY: 'scroll',
    alignSelf: 'stretch',
    width: 450
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    backgroundColor: '#ACCACD',
    padding: 5,
    margin: 5,
    height: '100%',
    borderRadius: 5
  },
  crewName: {
    display: 'flex',
    alignSelf: 'center',
    fontSize: '1.2em',
    color: '#aaa',
    fontWeight: 650,
    alignContent: 'center',
    justifyContent: 'center'
  },
  crewDetailsArea: {
    // display: 'flex',
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
    // flexDirection: 'column'
    fontSize: '1.05em',
    fontWeight: 300,
    color: '#888'
  },
  dailyLogArea: {
    marginTop: 10,
    // backgroundColor: 'red',
    width: '100%'
  },
  subTitleText: {
    fontSize: '1.15em',
    color: '#777',
    fontWeight: 600
  },
  itemTitleArea: {
    height: 95,
    display: 'flex',
    width: '100%',
    alignSelf: 'stretch',
    flexDirection: 'column'
  }
}))

const defaultStateValues = {
  view: 'dailyLog',
  showDailyLogs: true
}

const CrewsSupervisedList = (props) => {
  const classes = useStyles()
  const [state, setState] = React.useState(defaultStateValues)
  const handleViewToggle = () => {
    setState(prevState => ({
      ...prevState,
      showDailyLogs: !prevState.showDailyLogs
    }))
  }
  return (
    <div className={classes.root}>
      <div className={classes.mainContainer}>
       <Typography color="textSecondary" gutterBottom>
          See The Most Recent Info About Your Crews
        </Typography>
      <div style={{ marginRight: '15px'}}>
        {
          (state.showDailyLogs) ? (
            <Tooltip title="View Crews on Map">
              <IconButton onClick={handleViewToggle } size="medium" align="edge" alt="View Crews on Map" label="View Crews on Map" aria-label="View Crews on Map">
                <ExploreIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="View Daily Logs">
              <IconButton onClick={handleViewToggle } size="medium" align="edge" alt="View Daily Logs" label="View Daily Logs" aria-label="View Daily Logs">
                <EventNote />
              </IconButton>
            </Tooltip>
          )
        }
       
         
      </div>
      <div style={{ marginRight: '15px'}}>
       
      </div>
     
      </div>
      {
          state.showDailyLogs ? <ListOfCrews crews={props.crews} /> : <CrewMapViewSupervisorView crews={props.crews} />
      }
    </div>
  )
}

const ListOfCrews = (props) => {
  const classes = useStyles()
  const { crews } = props
// console.log('Supervised crews', crews)

  return (
    <div className={classes.listContainer}>

      {
        crews.map(crew => (
          <CrewItem key={crew._id} crew={crew} />
        ))
      }
    </div>
    
  )
}

const CrewItem = (props) => {
  const classes = useStyles()
  const { crew } = props
  let lastUpdate = null
  if (crew && crew.lastLocation && crew.lastLocation.timestamp) {
  // console.log('Timestamp:', crew.lastLocation.timestamp)
    let dateObj = crew.lastLocation.timestamp
  // console.log('Date Obj', dateObj)
    lastUpdate = moment(dateObj, 'YYYY-MM-DDThh:mm:ss.000Z').fromNow()
  // console.log(lastUpdate)
  }
  return (
  


    <div className={classes.itemContainer}>
      {
          lastUpdate && 
            <div style={{ alignSelf: 'flex-end', fontSize: '0.8em', color: '#aaa', fontWeight: 100 }}>
             Updated {lastUpdate}
            </div>
        }
      <div className={classes.itemTitleArea}>
      
      <div className={classes.crewName}>{crew.name}</div>
      <div className={classes.crewDetailsArea}>
        {/* <div className={classes.crewName}>{crew.name}</div> */}
        <div>{crew.locationName}</div>
        <div>{crew.currentJobName}</div>
        <div>{crew.activityStatus ? crew.activityStatus : null}</div>
  
      </div>
      </div>
      <div style={{ marginTop: '5px', padding: '10px' }}>
        <CrewActions crew={crew} user={props.user} />
        
      </div>
      
      <div className={classes.dailyLogArea}>
       <span className={classes.subTitleText}>
       Daily Log
      </span>

      {
        (crew && crew._id) && <CrewDailyLogLastTen crew={crew} />
      }
      </div>
    </div>
  )
}

const CrewActions = ({ crew, user }) => {
  const [isChatDrawerOpen, setIsChatDrawerOpen] = React.useState(false)
  const toggleChatDrawer = (side, openUpdate, num) => event => {
    // console.log('Toggle Chat ', side)
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
    // console.log(openUpdate)
    // console.log(num)
      setIsChatDrawerOpen(openUpdate)
    }

    const handleGPSHistory = () => {
      let newPageUrl = `birdseyeview?crew=${crew._id}`
      // history.push(url)
      window.open(newPageUrl, "_blank")
    }

  return (
    <div style={{ margin: 5, padding: 10 }}>
      <ChatDrawer 
        open={isChatDrawerOpen}
        onClose={toggleChatDrawer('right', false, 4)}
        room={`Crew_${crew._id}`}
      />
        <Tooltip title="View Chat" style={{ marginLeft: 35 }}>
          <IconButton onClick={toggleChatDrawer('right', true, 25)}aria-label="view" color="primary"
              
              >
                <Badge  color="secondary">
                  <ChatIcon />
                </Badge>
              
                </IconButton>
                
      </Tooltip>
      <Tooltip title="View Crew GPS History" style={{ marginLeft: 35 }}>
          <IconButton onClick={handleGPSHistory}aria-label="view" color="primary"
              
              >
                  <ExploreIcon />
              
                </IconButton>
                
      </Tooltip>
    </div>
  )
}

export default CrewsSupervisedList
