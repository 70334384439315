import React, { useState } from 'react';
import { Grid, ButtonBase, IconButton } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { fade, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  thumbnailContainer: {
    display: 'flex',
    overflowX: 'auto',
    padding: theme.spacing(1),
  },
  thumbnail: {
    margin: theme.spacing(0.5),
    width: 100,
    height: 100,
    objectFit: 'cover',
  },
  navButton: {
    alignSelf: 'center',
  },
  previewGalleryContainer: {
    height: '100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    justifyContent: 'flex-start'
  },
}));

const PreviewGallery = ({ images, item, setItem, currentImage, setCurrentImage }) => {
  const classes = useStyles();
  // const [currentImage, setCurrentImage] = useState(0);
  const [page, setPage] = useState(0);
  const thumbnailsPerPage = 15;

  const handleNext = () => {
    // console.log('Next...')
    setCurrentImage((prev) => (prev + 1) % images.length);
    if (currentImage % thumbnailsPerPage === thumbnailsPerPage - 1) {
      setPage((prev) => (prev + 1) % Math.ceil(images.length / thumbnailsPerPage));
    }
  };

  const handlePrev = () => {
    // console.log("Prev")
    setCurrentImage((prev) => (prev - 1 + images.length) % images.length);
    if (currentImage % thumbnailsPerPage === 0) {
      setPage((prev) => (prev - 1 + Math.ceil(images.length / thumbnailsPerPage)) % Math.ceil(images.length / thumbnailsPerPage));
    }
  };

  const selectImage = (index) => {
    // console.log('select', index)
    setCurrentImage(index);
    setPage(Math.floor(index / thumbnailsPerPage));
    let newItem = images[index]
    // console.log('Set this as our new item', newItem)
    // setItem(newItem)
  };

  const displayedImages = images.slice(page * thumbnailsPerPage, (page + 1) * thumbnailsPerPage);

  console.log("Page:", page)
  return (
    <div className={classes.previewGalleryContainer}>
      <IconButton onClick={handlePrev} className={classes.navButton} disabled={page === 0}>
        <ArrowLeftIcon />
      </IconButton>
      <Grid container wrap="nowrap" className={classes.thumbnailContainer}>
        {displayedImages.map((img, index) => {
          const photoIndex = page * thumbnailsPerPage + index;
          let photoURL = img.url;
          if (photoURL && !photoURL.includes('https')) photoURL = `https://${photoURL}`;
          return (
            <ButtonBase key={photoIndex} onClick={() => selectImage(photoIndex)}>
              <img src={photoURL} alt={`Thumbnail ${photoIndex}`} className={classes.thumbnail} />
            </ButtonBase>
          );
        })}
      </Grid>
      <IconButton onClick={handleNext} className={classes.navButton} disabled={page >= Math.ceil(images.length / thumbnailsPerPage) - 1}>
        <ArrowRightIcon />
      </IconButton>
    </div>
  );
};

export default PreviewGallery;
