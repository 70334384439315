import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { CircularProgress, Grid, Button }  from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning'
import CustomerIssueModal from './CustomerIssueModal'
import CustomerIssueList from './CustomerIssueList'

const CustomerIssueComponent = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState({
    workorder: {},
    photos: [],
    issues: []
  })
  const [showModal, setShowModal] = React.useState(false)

  async function fetchData(wo) {
  console.log('Get Customer Issue for Workorder', wo)
    setLoading(true)
    // let url = `${props.url}/api/company/listCustomerIssues`
    let url = `${props.url}/api/jobs/getIssuesForWorkorder?wo=${wo._id}`
    const response = await axios.get(url)
  // console.log('25 - ISSUES:', response.data)
    setLoading(false)
    if (response.data && response.data.length) {
    // console.log('Got WOS to Assign', response.data)

      setState({...state, workorder: wo, issues: response.data})
    } else {
    // console.log('Fucked up getting Customer Issues')
    }
  }

  React.useEffect(() => {
  // console.log('Assing WOrkorders Init')
    if (props.workorder && props.workorder._id) {
      fetchData(props.workorder)
    } else {
      // setState({...state, photos: [], workorder: {} })
      // fetchData()
    }

  }, [props.workorder])

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <div >
      <CustomerIssueModal open={showModal} list={state.issues} closeModal={closeModal} />
      {
        props.workorder ? (
          <CustomButton num={state.issues.length} openModal={openModal} />
        ) : (
          <CustomerIssueList list={state.issues} />
        )
      }
  </div>
  )
}

const CustomButton = ({ num, openModal }) => {
  let label = `${num > 0 ? num : 'No' } Issues To View`
  return (
    <Button
      variant="contained"
      // color="warning"
      style={{ backgroundColor: '#d9c13b' }}
      startIcon={<WarningIcon color="#eee" /> }
      disabled={num < 1}
      onClick={openModal}
      >{label}</Button>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    user: state.user
  }
}

export default connect(mapStateToProps)(CustomerIssueComponent)