import React from 'react';
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

const SignatureInput = (props) => {
  const [state, setState] = React.useState({
    name: ''
  })
  const handleNameUpdate = (e) => {
    setState({...state, name: e.target.value})
  }
  
  const handleBlur = () => {
    props.setTheName(state.name)
  }
  return (
    <div style={{marginTop: 15}}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <LabelText>Please Type Your Full Name:</LabelText>
          <TextField
              label="Your Name"
              variant="outlined"
              type="text"
              fullWidth
              value={state.name}
              onChange={handleNameUpdate}
              onBlur={handleBlur}
              style={{marginTop: 5}}
              // onFocus={handleFocus}
              InputProps={{
                style: {
                  fontSize: '2rem'
                }
              }}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <LabelText> Name As It Will Appear:</LabelText>
          <TextField
              label="Name As It Will Appear"
              variant="outlined"
              type="text"
              fullWidth
              value={state.name}
              disabled
              style={{marginTop: 5}}
              // style={{fontFamily: 'Inspiration', fontSize: 24}}
              // onChange={handleQtyUpdate}
              // onFocus={handleFocus}
              InputProps={{
                style: {
                  fontFamily: 'Inspiration', color: '#000', fontSize: '2rem'
                }
              }}
            />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{textAlign: 'center'}}>
           Please note that you will be emailed a copy of the signed document to {props.contact.email} and a copy will be maintained online for your future review. If you have any questions, please call the office.
        </Grid>
      </Grid>
    </div>

  )
}
export default SignatureInput
