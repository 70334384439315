import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import RequiredInputs from './RequiredInputsArea'
import DefaultLineItems from './DefaultLineItems'
import AddRequiredLineItemModal from './AddRequiredLineItemModal'
import AddRequiredInputModal from './CreateNewRequiredInputModal'
import Equations from './Equations'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    backgroundColor: '#ddd'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid #eee',
    // marginRight: '5px',
    // padding: '5px'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  gridItem: {
    width: '800px'
  },
  headerText: {
    fontSize: '1.3rem',
    color: '#777'
  },
  headerSubText: {
    fontSize: '1.15rem',
    color: '#bbb'
  },
  subHeaderText: {
    fontSize: '1.02rem',
    color: '#999'
  }
}));

const RequiredInputList = ({ service }) => {
// console.log('Required INputs; ', service)
  let inputArray = []
  if (service && service.requiredInputs && service.requiredInputs.length) inputArray = service.requiredInputs
  return (
    <>
      { inputArray.map((item, index) => {
        return (
          <p key={index}>{`{${item.name}} - ${item.description}`}</p>
        )
      })}
    </>
  )
}

const DefaultLineItemsList = ({ service }) => {
// console.log('Default Line Items; ', service)
  let defaultLineItemArray = []
  if (service && service.defaultLineItems && service.defaultLineItems.length) defaultLineItemArray = service.defaultLineItems
  return (
    <>
      { defaultLineItemArray.map((item, index) => {
        return (
          <p key={index}>{`${item}`}</p>
        )
      })}
    </>
  )
}

const crewTypes = [ 'Maintenance', 'Landscape', 'Hardscape', 'Specialty', 'Tech', 'Spray Tech', 'Irrigation Tech', 'Mechanic', 'Driver' ]
const billingCategories = [
    'Monthly Maintenance', 'Landscapes', 'Pools', 'Specialty', 'Clean-Ups', 'Irrigation', 'Tree Trimming', 'Lighting', 'Pest Control', 'Water Feature', 'Flowers', 'Herbicide', 'Other'
  ]
const defaultStateValues = {
  name: '',
  category: '',
  description: '',
  serviceCode: 'TBD',
  notes: '',
  scope: null,
  verb: null,
  lineItemTemplate: null,
  service: {},
  equation: '',
  categoryName: ''
}

const ServiceDetailsModal = (props) => {
  // console.log('Got our Svc Detail Modal', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [defaultLineItems, setDefaultLineItems] = React.useState([])
  const categories = props.categories

  const [showLineItemModal, setShowLineItemModal] = React.useState(false)
  const [showNewInput, setShowNewInput] = React.useState(false)
  const [state, setState] = React.useState(defaultStateValues);


  React.useEffect(() => {
    // console.log('Use effect Service Details', props)
    let service = props.service
    if (service && service._id) {
      if (service._id === 'new') {
        setState({...state, ...defaultStateValues})
      } else {
        // console.log('get our scopes and shit', props.categories)
        // console.log(service)
        // let ourScope = null
        // if (service.scope) {
        // // console.log('Service scope: ', service.scope)
        //   let index = props.categories.findIndex(item => (item._id === service.scope))
        // // console.log('INdex; ', index)
        //   if (index > -1) {
        //     ourScope = props.categories[index]
        //   }
        // }
        // console.log('OUR SCOPE: ', ourScope)
        let scope = {}
        if (service.scope && service.scope._id) {
          let ourScope = service.scope
          // console.log('SET SERVICE: ', service)
          // console.log('SCOPE', ourScope)
          let scopes = props.categories
          let index = scopes.findIndex(item => (item._id === ourScope._id))
          if (index > -1) {
            scope = props.categories[index]
          }
        }
        // console.log('SCope to set:', scope)
        setState({...state,
          name: service.name,
          category: service.crewType || 'None',
          categoryName: service.categoryName,
          description: service.description,
          serviceCode: service.serviceCode,
          notes: service.notes,
          scope: scope,
          verb: service.verb,
          lineItemTemplate: service.lineItemTemplate,
          service: service,
          equation: service.equation
        })
      }
    }
  }, [props.service]);
// const handleChange = (event) => {
//   setRange(event.target.value);
//   let newStartDate = moment().subtract(event.target.value, 'days')
// // console.log('New start Date', newStartDate)
//   setStartDate(newStartDate)
//   setOpen(false);
// };

// const handleClose = () => {
//   setOpen(false);
//   // props.onUpdate()
// }

// const handleOpen = () => {
//   setOpen(true);
// };
const updateService = (service, updateBackEndToo) => {
  let scope = {}
  if (service.scope && service.scope._id) {
    let ourScope = service.scope
    // console.log('SET SERVICE: ', service)
    // console.log('SCOPE', ourScope)
    let scopes = props.categories
    let index = scopes.findIndex(item => (item._id === ourScope._id))
    if (index > -1) {
      scope = props.categories[index]
    }
  }
  // console.log('SCope to set:', scope)
  setState({...state,
    name: service.name,
    category: service.crewType || 0,
    categoryName: service.categoryName,
    description: service.description,
    serviceCode: service.serviceCode,
    notes: service.notes,
    scope: scope,
    verb: service.verb,
    lineItemTemplate: service.lineItemTemplate,
    service: service,
    equation: service.equation
  })

  if (updateBackEndToo) {
  // console.log('Update the backend here...')
    // updateServiceOnDB(service)
  }
}

const handleDeleteService = () => {
  // Delete Service
  props.updateParent('delete', props.service._id)
}

const updateServiceOnDB = (svc) => {
// console.log('Save this to the backend....', state)
  let ourScope = state.scope
  let obj = {
    service: props.service,
    details: svc
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/company/updateservice`,
    data: obj,
    // validateStatus: (status) => {
    // // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
  // console.log('Axios response to update service ', response)
    setLoading(false)
    if (response && response.data && response.data._id) {
      // props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'Saved Changes to that Service!',
        message: 'Well Done!!'
      })
      let newService = response.data
      newService.scope = ourScope
      // props.updateParent('update', newService)
    }
  })
}

const handleSubmit = () => {
  // console.log('Update this service!', state)
  setLoading(true)
  // console.log('handle save', state)
  // console.log(props)
  let ourScope = state.scope
  let obj = {
    details: state,
    service: props.service
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/company/updateservice`,
    data: obj,
    // validateStatus: (status) => {
    // // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
  // console.log('Axios response to update service ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response && response.data && response.data._id) {
      // props.updateParent('Saved', response.data)
      // setState({})
      props.notification({
        type: 'success',
        title: 'Saved Changes to that Service!',
        message: 'Well Done!!'
      })
      // setState({...state,
      //   name: response.data.name,
      //   category: response.data.category || 0,
      //   description: response.data.description
      // })
      // handleCloseModal()
      let newService = response.data
      newService.scope = ourScope
      props.updateParent('update', newService)
      // setOpen(false)
    } else if (response && response.data && response.data.err) {
    // console.log('Got an error code: ', response.data.err)
      let err = response.data.err
      props.notification({
        type: 'danger',
        title: 'Uh Oh! Houston, We Have a Problem...Error Code ' + err.code,
        message: err.errmsg
      })
    } else {
    // console.log('No fucking reponse.data???')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up creating this service...not sure why!'
      })
    }
  }).catch(err => {
  // console.log('Error saving Service: ', err)
    props.notification({
      type: 'danger',
      title: 'Uh Oh! Houston, We Have a Problem...',
      message: err.message
    })
  })
}

const handleCloseModal = () => {
  props.updateParent('closeModal')
}

const setServiceName = (name) => {
  setState({...state, name: name})
}

const addNewRequiredLineItem = () => {
  // Open Up Modal to add new line item
// console.log('Open Modal')
  setShowLineItemModal(true)
}

const handleCloseRequiredLineItemModal = () => {
  setShowLineItemModal(false)
}
const handleSaveNewRequiredLineItem = (d) => {
  setLoading(true)
// console.log('Add thsi: ', d)
  setShowLineItemModal(false)
// console.log(props)
  let obj = {
    lineItem: d,
    service: props.service
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/company/addDefaultLineItem`,
    data: obj,
    // validateStatus: (status) => {
    // // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
  // console.log('Axios response to add default line item to  service ', response.data)
    updateService(response.data)
    setLoading(false)
  })
}

  return (
    <div>
      <AddRequiredLineItemModal open={showLineItemModal} service={props.service} closeModal={handleCloseRequiredLineItemModal} saveItem={handleSaveNewRequiredLineItem} />
        <Dialog
          // fullScreen={false}
          maxWidth={'lg'}
          width='lg'
          // fullWidth
          // maxwidth='lg'
          // style={{width: 700}}
          open={props.open}
          // scroll='paper'
          onClose={handleCloseModal}
          aria-labelledby="responsive-dialog-title"
          >
                {/* <Dialog
        maxWidth='lg'
        // fullScreen={fullScreen}
        open={props.open}
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
      > */}
          <DialogTitle id="responsive-dialog-title">Service Details</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} className={classes.gridItem}>
            <Grid item lg={10} xs={10}></Grid>
            <Grid item lg={2} xs={2}>
              <Button
                variant="contained"
                style={{backgroundColor: '#ed4545'}}
                className={classes.button}
                size="small"
                startIcon={<DeleteIcon />}
                onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) handleDeleteService() } }
                // onClick={handleDeleteService}
                // onClick={offerDeleteOption}
                // onClick={ () => { window.confirm('Are you sure you wish to delete this item?') ? handleDeleteService() : null } }
              >
                Delete
              </Button> 
            </Grid>
            <Grid item lg={2} xs={2}>
                <TextField
                  id="Service Code"
                  label="Service Code"
                  type="text"
                  fullWidth
                  value={state.serviceCode}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setState({...state, serviceCode: e.target.value}) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={10} xs={10}>
                <TextField
                  id="Service Name"
                  label="Service Name"
                  type="text"
                  fullWidth
                  value={state.name}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setServiceName(e.target.value) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <TextField
                  id="Service Description"
                  label="Service Description"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  value={state.description}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setState({...state, description: e.target.value}) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={12} xs={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="simple-select-helper-label">Crew Types</InputLabel>
                  <Select
                    labelId="simple-select-helper-label"
                    id="simple-select-helper"
                    value={state.category}
                    variant="outlined"
                    onChange={(e) => setState({...state, category: e.target.value })}
                  >
                    <MenuItem value={'None'}>
                      <em>None</em>
                    </MenuItem>
                    {
                      crewTypes.map((item, index) => {
                        return (
                          <MenuItem key={index} value={item}>{item}</MenuItem>
                        )
                      })
                    }
                   
                  </Select>
                  <FormHelperText>Service Crew Type</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={12} xs={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel id="simple-select-helper-label">Billing Category</InputLabel>
                  <Select
                    labelId="simple-select-helper-label"
                    id="simple-select-helper"
                    value={state.categoryName}
                    variant="outlined"
                    onChange={(e) => setState({...state, categoryName: e.target.value })}
                  >
                    {
                      billingCategories.map((cat, index) => {
                        return (
                          <MenuItem key={index} value={cat}>
                          <em>{cat}</em>
                        </MenuItem>
                        )
                      })
                    }
                   
                   
                  </Select>
                  <FormHelperText>Service Category</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item lg={12} xs={12}>
                <TextField
                  id="Service Notes"
                  label="Service Notes"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  value={state.notes}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setState({...state, notes: e.target.value}) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* <Grid item lg={12} xs={12}>
                <TextField
                  id="Service Verb"
                  label="Service Verb"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  value={state.verb}
                  // className={classes.textField}
                  variant="outlined"
                  onChange={ e => setState({...state, verb: e.target.value}) }
                  // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid> */}
               { (props.service && props.service._id !==  'new') ?
                <>
                  <Grid item lg={12} xs={12} className={classes.headerText}>
                      Proposal Stuff
                  </Grid>
                  <Grid item lg={12} xs={12} className={classes.headerSubText}>
                    Things below are related to Proposals/Estimates
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel id="simple-select-helper-label">Category (Scope)</InputLabel>
                      <Select
                        labelId="simple-select-helper-label"
                        id="simple-select-helper"
                        value={state.scope}
                        variant="outlined"
                        onChange={(e) => setState({...state, scope: e.target.value })}
                      >
                        {
                          props.categories.map((item, index) => {
                            return (
                              <MenuItem value={item} key={item._id}>{item.name}</MenuItem>
                            )
                          })
                        }
            
                      
                      </Select>
                      <FormHelperText>Service Category (Scope)</FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} xs={12} className={classes.subHeaderText}>
                  {`Use the Line Item Template to determine what each Line Item (chosen from the Database) will look like on the proposal. You can use the expected inputs of Quantity {QTY}, Size {SIZE}, and Description {DESC}`}
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    
                    <TextField
                      id="Line Item Template"
                      label="Line Item Template"
                      type="text"
                      fullWidth
                      multiline
                      rows={4}
                      value={state.lineItemTemplate}
                      // className={classes.textField}
                      variant="outlined"
                      onChange={ e => setState({...state, lineItemTemplate: e.target.value}) }
                      // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={`You can use {QTY}, {SIZE}, {DESC}, {PRICE} and those items will be filled in on the proposal automatically`}
                    />
                  </Grid>

                  <Grid item lg={12} xs={12} style={{ marginBottom: '10px', border: '2px solid #888', borderRadius: '5px', backgroundColor: '#ccc'}}>
                      <RequiredInputs service={state.service} updateService={updateService} />
                  </Grid>
                  <Grid item lg={12} xs={12} style={{ marginBottom: '10px', border: '2px solid #888', borderRadius: '5px', backgroundColor: '#ccc'}}>
                      {/* <Equations service={state.service} /> */}
                      {/* <TextField
                      id="Line Item Equation"
                      label="Line Item Equation"
                      type="text"
                      fullWidth
                      multiline
                      rows={4}
                      value={state.equation}
                      // className={classes.textField}
                      variant="outlined"
                      onChange={ e => setState({...state, equation: e.target.value}) }
                      // onChange={ e => handleServiceChange(state.workorder, 'scheduledDate', e.target.value, state.serviceIndex) }
                      InputLabelProps={{
                        shrink: true,
                      }}
                      helperText={`What Equation Are We Using`}
                    /> */}
                  </Grid>
                  <Grid item lg={12} xs={12} style={{ marginBottom: '10px', border: '2px solid #888', borderRadius: '5px', backgroundColor: '#ccc'}}>
                      <DefaultLineItems service={state.service} updateService={updateService} />
                  </Grid>
                  </> : null
            }
                </Grid>
              
          </DialogContent>

          <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit(4)} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
          </Dialog>
    </div>
  )
 

}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(ServiceDetailsModal)