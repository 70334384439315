import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios'
import { Dialog, DialogActions, DialogContent, DialogTitle, Paper, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ChooseScopes from './ChooseScopes';
import ChooseServices from './services/ChooseServices'
import { setServices } from '../../actions/connectionActions';
import ConfigureProposalOptions from './ConfigureProposalOptions'
import ConfigurePDFOptions from './ConfigurePDFOptions'
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import EditEstimateDetails from './EditEstimateDetails';
import ConfigurationModal from './services/ConfigureServiceDetailsModal';
import { socket } from '../SocketIo/Socket'
import LastUpdateByDetails from '../Common/LastUpdatedByDetails'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    height: '85vh',
    // overflowY: 'auto'
  },
  modal: {
    width: '100%'
  },
  configContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    minHeight: 500,
    maxHeight: 800
  },
  leftSide: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: 'pink',
  },
  rightSide: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: 'orange',
  }
}));

function getSteps() {
  return ['Choose Proposal Scopes', 'Configure Services', 'Configure Proposal Details', 'Create PDF'];
}

const ProposalWizardContainer = (props) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [loading, setLoading] = React.useState(false)
  const [estimate, setEstimate] = React.useState({})
  const [takeoffs, setTakeoffs] = React.useState([])
  const [sizes, setSizes] = React.useState([])
  const [categories, setCategories] = React.useState([])
  const [jobSites, setJobSites] = React.useState([])
  const [addys, setAddys] = React.useState([])
  const [showConfigurationModal, setShowConfigurationModal] = React.useState(false)
  const [showEditEstimateModal, setShowEditEstimateModal] = React.useState(false)
  const [state, setState] = React.useState({
    counter: 0,
    estimate: {},
    service: {},
    categories: [],
    client: {}
  })

  React.useEffect(() => {
    console.log('Socket.connect')
    socket.on(`estimateUpdate`, (data) => {
      // console.log('Estimate Update Socket shit::::', data)
      // console.log('We got an estimate???', props)
      dynamicEstimateUpdate(data, state, props)
    })

    return () => {
      console.log('Socket DIsconnect estimateUpdate')
      socket.off('estimateUpdate')
    }
  }, [props, state])

  const dynamicEstimateUpdate = (updatedEstimate, lastState, lastProps) => {
      console.log('Updating estimates from socket data!!!', updatedEstimate)
      console.log('Last State:', lastState)
      console.log('Last Props', lastProps)
    // console.log('Estimate today:', props)
      console.log('StATE:', state)
      console.log('PROPS:', props)
      console.log('Current estimate:', estimate)
      if (updatedEstimate && updatedEstimate._id === estimate._id) {
        console.log('This is OUR Estimate!!!', updatedEstimate)
        let doIt = true
        if (updatedEstimate && updatedEstimate.updated_by && updatedEstimate.updated_by._id === props.user._id && doIt){
          console.log('Ignore it - we did it...')
        } else {
          let mewEst = {...estimate, ...updatedEstimate}
          console.log('Merged Estimate:', mewEst)
          setEstimate(mewEst)
          let newService = {...state.service }
          console.log('New Service', newService)
          if (newService && newService.type) {
            let theIndex = -1
            mewEst.serviceList.map((svListItem, index) => {
              console.log('Svlistitem', svListItem)
              if (svListItem && svListItem.type === newService.type) {
                theIndex = index
              }
            })
            console.log('The Index', theIndex)
            if (theIndex > -1) {
              let svcItem = mewEst.serviceList[theIndex]
              console.log('This is our service item',  svcItem)            
              mewEst.serviceList[theIndex]['services'].map((item, index) => {
                console.log('Services index item...', item)
                if (item._id === state.service.service._id) {
                  console.log('This one!!!!!', item)
                  newService.service = item
                }
              })
            }
            console.log('Set state.service ****000****', newService)


            setState({...state, estimate: mewEst, service: newService })
          } else {
            console.log('No active service???')
            setState({...state, estimate: mewEst })
          }
  
        }
      } else {
      // console.log('No match', props.estimate)
      }
    }
    

  React.useEffect(() => {
  // console.log('Proposal props updated...', props)

    let isMounted = true
    const fetchEstimate = async (id) => {
      // console.log('Fetch: ', id)
      setLoading(true)
      setActiveStep(0)
      let url = `${props.url}/api/proposal/getProposal?id=${id}&newTakeoffs=true`
      axios.get(url).then((response, status, data) => {
      // console.log('Estimate found::', response.data)
        setLoading(false)
        if (response && response.data && response.data.estimate) {
          if (isMounted) {
            if (response.data.estimate && response.data.estimate.lineItems && response.data.estimate.lineItems.length) {
              // if (window.confirm('Would You Like to Update this to Work The New Way?')) {
                console.log('Update this bitch', response.data.estimate)
              // }
            }
            if (response.data.estimate && response.data.estimate.lineItems && response.data.estimate.lineItems.length) {
              checkOurEstimateForExistingLineItems(response.data)
            } else {
              setEstimate(response.data.estimate)
              console.log('EXISTING STATE ****000****', state)
              setState({...state, estimate: response.data.estimate, client: response.data.client, vendors: response.data.vendors })
            }
       
          }
        }
        if (response && response.data && response.data.takeoffs) {
          if (isMounted) setTakeoffs(response.data.takeoffs)
        }
        if (response && response.data && response.data.client) {
          if (isMounted) {
          // console.log(response.data)
            // setClient(response.data.client)
            let addresses = []
            let theClient = response.data.client
            setJobSites(response.data.jobSites)
          // console.log('got jobsites', response.data.jobSites)
            for (const addy of response.data.jobSites) {
              addresses.push({
                name: addy.name,
                address: addy.address,
                address2: addy.address2,
                city: addy.city,
                state: addy.state,
                zip: addy.zip
              })
            }
          // console.log('Addys so far ', addresses)
            addresses.unshift({
              name: 'Client Billing Address',
              address: theClient.address,
              address2: theClient.address2,
              city: theClient.city,
              state: theClient.state,
              zip: theClient.zip
            })
          // console.log('addys', addresses)
            setAddys(addresses)
          }
        }
      })
    }

    if (props.match && props.match.params && props.match.params.estimateId && (!estimate._id || estimate._id.toString() !== props.match.params.estimateId)) {
      // console.log('Get it!!', props.match.params.estimateId)
      fetchEstimate(props.match.params.estimateId)
    }

    return () => {
      isMounted = false
    }
  }, [props])

    React.useEffect(() => {
      let isMounted = true

      fetchCategories()
      fetchSizes()
    return () => {
      isMounted = false
    }
  }, [])

  const fetchSizes = async () => {
    // console.log('Get our sizes and shit')
      axios
      .get(
        `${props.url}/api/settings/getStuffForNewItem`
        )
      .then(({ data }) => {
        if (data && data.sizes) {
          setSizes(data.sizes)
        }
      }).catch(function (err) {
      // console.log('Error getting stuff', err)
      });
    }

  const fetchCategories = async (callback) => {
    setLoading(true)
    axios.get(`${props.url}/api/settings/listScopes`).then((response, status, data) => {
      setLoading(false)
      if (response && response.data) {
     
          console.log('Set Categories: ')
          console.log('Existing state ****000****', state)
          setState({...state, categories: response.data})
          setCategories(response.data)
        if (callback) {
          return callback(response.data)
        }
      }
    })
  }

const fetchOurScopes = () => {
  return new Promise((resolve) => fetchCategories((resp) => {
    resolve(resp)
  }))
}

  const checkOurEstimateForExistingLineItems = async (data) => {
    const est = data.estimate
    const client = data.client
    console.log('check for old line items ', est)
    if (est && est.serviceList && est.serviceList.length) {
      console.log('Looks like its been touched before', est.serviceList)
    } else {
      console.log('VIRGIN ESTIMATE IN HAND', est.serviceList)
      console.log('props', props)
      console.log('state', state)
      let ourScopes = []
      if (categories && categories.length) {
        console.log('No need t ofetch them')
        ourScopes = categories
      } else {
        console.log('Ftech the categories')
        ourScopes = await fetchOurScopes()
      }
      console.log('We got scopes!!', ourScopes)
      // Need to assign it a SCOPE - PLANT MATERIAL
      // Misc Work as needed - 5f6ed72068560223b41ebe62
      let svcIndex = props.services.findIndex(item => (item._id === '5f6ed72068560223b41ebe62'))
      if (svcIndex > -1) {
        let assignedSvc = props.services[svcIndex]
        assignedSvc.lineItems = est.lineItems
        console.log('Assigned Service - have scope???', assignedSvc)
        let serviceList = {
          type: 'Plant Material',
          services: [assignedSvc]
        }
        console.log('svc list:', serviceList)
        est.serviceList = [serviceList]
        console.log('Categores??', ourScopes)
        let cats = [...ourScopes]
        let catId = '62cf4c2afcf82e4a90f05c77'
        console.log('find our socpe', cats)
        let catIndex = cats.findIndex(item => (item._id.toString() === catId))
        console.log('Cat index', catIndex)
        if (catIndex > -1) {
          est.scopes = [cats[catIndex]]
        }
        console.log('Estimate w/ serices now!!', est)
      }

    }
    // conso
    setEstimate(est)
    console.log('Existing state ****000****', state)
    setState({...state, estimate: est, client: client, vendors: data.vendors })
  }

  const handleNext = () => {
    // if (activeStep === 1) {
    //   setActiveStep((prevActiveStep) => prevActiveStep + 2);
    // } else {
    //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChooseScopes = (scopesChosen) => {
  // console.log('We chose some scopes:', scopesChosen)
    let newEst = estimate
    newEst.scopes = scopesChosen
  // console.log('New Estimate: ', newEst)
    // newEst.scopesArray = scopesChosenObject
    setEstimate(newEst)
    let itemsToUpdate = {estimate: newEst, counter: state.counter++, scopes: scopesChosen }
  // console.log('About to update our estimate with this:', itemsToUpdate)
    updateEstimate(newEst, 'propwizcont192', true, null, itemsToUpdate)
      // let itemsToUpdate = {estimate: newEst, counter: state.counter++, service: svcItem }
    // console.log('UPdate our state to this: ', itemsToUpdate)
    // updateEstimate(newEst, 610, null, null, itemsToUpdate)
    // handleNext()
  }

  const handleAddServices = (servicesChosen) => {
  // console.log('Add Services: ', servicesChosen)
    let newEst = estimate
    // if (newEst.services && newEst.services[])
    newEst.services = servicesChosen
    setEstimate(newEst)
    // handleNext()
  }

  const handleManualMove = (index) => {
    setActiveStep(index)
  }

  const matchUpDefaultLineItemAndRequiredInputs = (service, item) => {
// console.log('*****   **  Match Up Our Required Inputs and our Default Line Items here ****** ', item)
  let returnItemString = item
  let isPrice = false
  let value = 0
  if (service.requiredInputs && service.requiredInputs.length) {
  // console.log('Figure out what template items we need to reuse', service.requiredInputs)
    service.requiredInputs.map(inputItem => {
      let itemToSearchFor = `{${inputItem.name}}`
    // console.log('Looking for: ', itemToSearchFor)
      if (returnItemString.includes(itemToSearchFor)) {
        returnItemString = returnItemString.replace(itemToSearchFor, inputItem.value)
        // isPrice = true
        value = inputItem.value
      }
    // console.log(returnItemString)
    })
  }
// console.log('******   FINISHED OUR MATCHING PROCESS *****')
  return { description: returnItemString, isPrice: isPrice, value: value }
}

  const handleUpdateServices = (svcs, isFinished, newSvc, type) => {
    console.log('Update Services: ', svcs)
    console.log('NEW SERVICE: ', newSvc)
    // Break out Required Line Items onto the estimate
    let listOfItems = []
    let serviceToSave = newSvc.value
    let defaultLineItems = []
    let wasPriceUsed = false
    let isPriceNeeded = false
    let priceToUse = 0
    // if (serviceToSave && serviceToSave.requiredInputs && serviceToSave.requiredInputs.length) {
    // // console.log('WE have to deal with these inputs and make them actual LINE ITEMS!!!', serviceToSave.requiredInputs)
    //   serviceToSave.requiredInputs.map((item, index) => {
    //     if (item.isPrice) {
    //     // console.log('Add this item price to our Estimate somehow')
    //     }
    //     if (serviceToSave && serviceToSave.defaultLineItems && serviceToSave.defaultLineItems.length) {
          
    //   })
    // }
    if (serviceToSave && serviceToSave.requiredInputs && serviceToSave.requiredInputs.length) {
    // console.log('find our price somewhere...', serviceToSave.requiredInputs)
      serviceToSave.requiredInputs.map(item => {
        if (item.isPrice) {
        // console.log("PRICE:", item)
          isPriceNeeded = true
          priceToUse = item.value
        }
      })
    }
    if (serviceToSave && serviceToSave.defaultLineItems && serviceToSave.defaultLineItems.length) {
    // console.log('WE have to deal with these defaultLineItems and make them actual LINE ITEMS!!!', serviceToSave.defaultLineItems)
      // lets just add them as line items
      let lineItemIndex = 0
      for (const lineItem of serviceToSave.defaultLineItems) {
        let price = 0
        let templatedInfo = matchUpDefaultLineItemAndRequiredInputs(serviceToSave, lineItem)
      // console.log('Templated Info:', templatedInfo)
        let descriptionWithTemplating = templatedInfo.description
        // let isPrice = templatedInfo.isPrice
        if (isPriceNeeded) {
        // console.log('Use this as a price!!', lineItem)
          if (!wasPriceUsed) price = priceToUse
          wasPriceUsed = true
        }
      // console.log('Templated Desc:', descriptionWithTemplating)
        let newItem = {
          _id: `${Date.now()}_${lineItemIndex}`,
          qty: 1,
          unit: null,
          description: descriptionWithTemplating,
          totalPrice: price,
          costPer: 0,
          templatedDescription: lineItem,
          isDefault: true
        }
      // console.log('Add this default line item: ', newItem)
        // addLineItem(newItem, serviceToSave, newSvc.type)
        defaultLineItems.push(newItem)
        lineItemIndex++
      }
      if (serviceToSave.lineItems && serviceToSave.lineItems.length) {
        serviceToSave.lineItems = serviceToSave.lineItems.concat(defaultLineItems)
      } else {
        serviceToSave.lineItems = defaultLineItems
      }
    }

    // if (serviceToSave && serviceToSave.requiredInputs && serviceToSave.requiredInputs.length) {
    // // console.log('find our price somewhere...', serviceToSave.requiredInputs)
    //   serviceToSave.requiredInputs.map(item => {
    //     if (item.isPrice) isPriceNeeded = true
    //   })
    // // console.log('Is Price Needed?: %s  --- WAS PRICE usED %S:', isPriceNeeded, wasPriceUsed)
    //   if (isPriceNeeded && !wasPriceUsed) {
    //   // console.log('Add in Our PRice MOTHERFUCKER!!!!!')

    //   }
    // }
    let newEst = estimate
    let serviceList = estimate.serviceList
    if (serviceList && serviceList.length) {
      // serviceList
    // console.log('Existing Service list!!!')
      if (serviceList.length === svcs.length) {
      // console.log('They seem to match??')
      } else {
      // console.log('Why the fuck not???')
      }
    } else {
      serviceList = []

    }
    // newEst.serviceList = svcs
    // console.log('Is FInished: ', isFinished)
    // console.log('New Service: ', newSvc)
    // New Svc has type and then value of the service
    // console.log('Type: ', type)
    let currentSvc = {
      type: newSvc.type,
      service: serviceToSave
    }
    // console.log('Save serice as: ', ourService)
    let ourService = {}
    if (serviceList && serviceList.length) {
    // console.log('Update our Service LIst....')
      newEst.serviceList = svcs

      // }
    } else {
    // console.log('Firs tservice - build it out')
      ourService = {
        type: newSvc.type,
        services: [{...newSvc.value}]
      }
      serviceList.push(ourService)
    // console.log('SErvice LIst before adding a new service!!', serviceList)
      newEst.serviceList = serviceList
    }
    console.log('Set our state service:', currentSvc)
  // console.log('save Estimate w/ UPDATED SERVICE LIST: ', newEst)
    // setEstimate(newEst)
    // setState({...state, counter: state.counter++, service: currentSvc})
    let updateOurState = {
      service: currentSvc
    }
    console.log('ITs here isn it???? -- add type')
    console.log('State right now', state)
    console.log('UPdate our state ****000****', updateOurState)
    setState({...state, ...updateOurState})
    if (isFinished) {
    // console.log('ESTIMATE SAVED: ', newEst)
      updateEstimate(newEst, 491, null, null, updateOurState)
      // handleNext()
    } else {
      console.log('Lets open config modal!!')
      setShowConfigurationModal(true)
      updateEstimate(newEst, 349, null, null, updateOurState)
    }
    // console.log('Show our modal now!!!')

  }

  const updateEstimate = (estimateToSave, calledFrom, doNext, specificSaveOnly, updatedStateItems) => {
    console.log('Update Estimate: now!!', estimateToSave, calledFrom)
    let obj = {
      estimateId: estimateToSave._id,
      estimate: estimateToSave,
      lineItems: estimateToSave.lineItems,
      services: estimateToSave.serviceList,
      scopes: estimateToSave.scopes,
      specificSaveOnly: specificSaveOnly
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/proposal/updateEstimate`,
      data: obj,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      console.log('UPDATED OUR PROPOSAL', response.data)
      // setEstimate(response.data)
      // setState({...state, estimate: response.data })
      
      // props.saveEstimate(response.data)
      setEstimate(response.data)
    // console.log('Set Our estimate hre...current state', state)
     
      let stuffToUpdate = {...updatedStateItems}
      console.log('Existing state ****000****', state)
      console.log('Stuff to update: ', stuffToUpdate)
      stuffToUpdate.estimate = response.data
      stuffToUpdate.counter = state.counter++
      console.log('Update state', stuffToUpdate)
      let newState = {...state, ...stuffToUpdate}
      console.log('Stat to set now:::,', newState)
      setState({...state, ...stuffToUpdate})
      if (newState && newState.service && newState.service.type) {
        updateService(response.data.serviceList, newState)
      } else {
        console.log('Not updating this shit!!!!', newState)
      }

      if (doNext) {
        console.log('Handle next....')
        handleNext()
      }
    }) 
  }

  const updateService = (serviceList, updatedState) => {
    console.log('UPdate service', serviceList)
    console.log(props)
    console.log('STATE', state)
    console.log('updatedState - what we are using', updatedState)
    // let newService = {...updatedState.service}
    let type = null
    let serviceId = null
    if (updatedState.service && updatedState.service.type) {
      type = updatedState.service.type
    }
    if (updatedState.service && updatedState.service.categoryName) {
      type = updatedState.service.categoryName
    }
    if (updatedState.service && updatedState.service.service && updatedState.service.service._id) serviceId = updatedState.service.service._id
    console.log('ServiceId:', serviceId)
    console.log('Scope TYPE:', type)
      let serviceListIndex = serviceList.findIndex(item => (item.type === type))
      if (serviceListIndex > -1) {
        console.log('We have our services list!!!')

        const activeServiceId = serviceId
        let services = []
        let scope = serviceList[serviceListIndex]
        console.log('Scope', scope)
        if (scope && scope.services && scope.services.length) services = scope.services
        let updatedService = {}
        console.log('Find index', services)
        let svcIndex = services.findIndex(item => (item._id === activeServiceId))
        console.log('Service Index:', svcIndex)
        if (svcIndex > -1) {
          updatedService = services[svcIndex]
        }
        console.log('Updated Service:: w/ proper line items??? ', updatedService)
        console.log('State.service:', updatedState.service)
        let svcObject = { ...updatedState.service }
        svcObject.service = updatedService
        console.log('set this one:', svcObject)
        console.log('Existing state ****000****', state)
        setState(prevState => {
          console.log('Prev state:', prevState)
          console.log('New state srevice:', svcObject)
          return {...prevState, service: svcObject}
        })
      } else {
        console.warn('Cannot update service for some reason....????????????')
      }
  }

  const getCleanDescription = (data) => {
    // console.log('Get Clean desc', data)
    let returnItem = data.split(' ').join('').slice(0, 20)
    // console.log('Turn %d into this: %s', data, returnItem)
    return returnItem
  }

  const addLineItem = (item, activeInventoryItem, service, incomingScopeType) => {
    console.log('ADd this line item to our estimate', item)
    console.log('For this inventory item:', activeInventoryItem)
    let cleanItem = {}
    let newList = estimate.lineItems
    console.log('Existing incomingScopeType??', incomingScopeType)
    // incomingScopeType is always undefined
    if (activeInventoryItem && activeInventoryItem._id) {
      let inventoryItem = null
      inventoryItem = activeInventoryItem._id
      let cleanDesc = ''
      if (activeInventoryItem.description) {
        cleanDesc = getCleanDescription(activeInventoryItem.description)
      } else {
        cleanDesc = `nodesc_${Date.now()}`
      }
      let cleanUnit = ''
      // if (item.unit) cleanUnit = getCleanDescription(item.unit)
      let uuid = `uuid${Date.now()}-${inventoryItem}_${item.option._id}`
    // console.log('UUID: ', uuid)
      // Create our Line Item
      console.log('Make our Proposal Line Item')
      cleanItem = {
        _id: uuid,
        qty: item.qty,
        name: activeInventoryItem.name,
        altName: activeInventoryItem.altName,
        option: item.option,
        unit: item.option.size || '',
        description: activeInventoryItem.description,
        totalPrice: item.totalPrice,
        costPer: item.costPer,
        pricePer: item.pricePer,
        lastPriceUpdate: item.option.last_price_update || null,
        inventoryItemId: activeInventoryItem._id,
        optionId: item.option._id || null,
        templatedDescription: activeInventoryItem.templatedDescription ? activeInventoryItem.templatedDescription : null,
        isDefault: activeInventoryItem.isDefault ? activeInventoryItem.isDefault : false
      }
      console.log('Add this line item (MATERIAL FROM DB)', cleanItem)
      newList.push(cleanItem)
    } else {
      console.log('Not an inventoyr item - maybe jus ta note??', item)
      let cleanDesc = ''
      let cleanUnit = ''
      // if (item.unit) cleanUnit = getCleanDescription(item.unit)
      let uuid = `uuid${Date.now()}-noDesc`
    // console.log('UUID: ', uuid)
      // Create our Line Item
      console.log('Make our Proposal Line Item')
      cleanItem = {
        _id: uuid,
        qty: item.qty,
        name: item.name || '',
        altName: item.altName || '',
        unit: item.unit || '',
        description: item.description,
        internalNotes: item.internalNotes,
        totalPrice: item.totalPrice,
        costPer: item.costPer,
        pricePer: item.pricePer,
        templatedDescription: null,
        isDefault: false
      }
      console.log('Add this line item (NOTE/CUSTOM LINE ITEM)', cleanItem)
      newList.push(cleanItem)
    }

    // console.log('List o fitems: ', newList)
    // console.log('Service type?? ', state)
      let activeService = null
      if (state && state.service && state.service.service) activeService = state.service.service
      let scopeType = null
      if (state && state.service && state.service.type) scopeType = state.service.type
      if (service) activeService = service
      if (incomingScopeType) scopeType = incomingScopeType
      if (activeService && activeService.service && activeService.service._id) activeService = activeService.service
    // console.log('Active SErvice', activeService)
      // lineItem._id = inventoryItem._id
      // newList.push(item)
      // setOurList(newList)
      // setState({...state, name: 'UPdated'})
    // console.log('Scope Type', scopeType)
      let newEst = estimate
      // let serviceList = newEst.serviceList
      let serviceList = newEst.serviceList
    // console.log('List of Services:', serviceList)
      let scopeIndex = serviceList.findIndex(item => (item.type === scopeType))
    // console.log('SCope index', scopeIndex)
      // IF we are editing a line item we need to delete that one first...
      // let activeEdit = false
      // newEst.lineItems = newList


      // newEst.lineItems = newList
      // Find our Existing Scope
  
      let newSvc = {}
      if (scopeIndex > -1 ) {
      // console.log('update some shit here...482???')
        let ourItem = serviceList[scopeIndex]
      // console.log('Oure Item:', ourItem)
        let listOfServices = ourItem.services
        let serviceIndex = listOfServices.findIndex(item => (item._id === activeService._id))
      // console.log('Service Index', serviceIndex)
        if (serviceIndex > -1) {
          newSvc = listOfServices[serviceIndex]
        // console.log('Service:: ', newSvc)
          let lineItemsOnSvc = []
          if (newSvc && newSvc.lineItems && newSvc.lineItems.length) lineItemsOnSvc = newSvc.lineItems
        // console.log('Line Items;', lineItemsOnSvc)
          lineItemsOnSvc.push(cleanItem)
          newSvc.lineItems = lineItemsOnSvc
        // console.log('Updated Service:', newSvc)
        }
      } else {
      // console.log('This scope isnt here yeat???')
        // let newItem = {
        //   type: scopeType,
        //   services: 
        // }
        // serviceList.push(newItem)
      }
  
      let svcItem = {
        type: scopeType,
        service: newSvc
      }
    // console.log('Estimate to save:', newEst)
    // console.log('w/ updated ServiceList', serviceList)
      // setState({...state, estimate: newEst, counter: state.counter++, service: svcItem })
      // estimateToSave, calledFrom, doNext, specificSaveOnly, updatedStateItems
      console.log('Service IteM:', svcItem)
      let itemsToUpdate = {estimate: newEst, counter: state.counter++, service: svcItem }
    console.log('UPdate our state to this: ', itemsToUpdate)
      updateEstimate(newEst, 610, null, null, itemsToUpdate)
   

  }

  const handleUpdateLineItem = (oldItem, updatedItem, service) => {
    console.log('props', props)
    console.log('state', state)
  console.log('Update this old item: ', oldItem)
  console.log('To this :', updatedItem)
    delete updatedItem.item
    // updatedItem._id = oldItem._id
    console.log('On this service: ', service)
    let scopeType = null
    if (service && service.type) scopeType = service.type
    console.log('Scope TYpe', scopeType)
    let serviceToUpdate = service
    if (serviceToUpdate && serviceToUpdate.service) serviceToUpdate = serviceToUpdate.service
    console.log('Service To Update:', serviceToUpdate)
    let updatedEstimate = estimate
  // console.log('451 UPDATED ITEM -- is it fulL?', updatedItem)
    let serviceList = updatedEstimate.serviceList || []
    console.log('Service list: 591', serviceList)
    let scopeIndex = serviceList.findIndex(item => (scopeType === item.type))
    console.log('Scope Index', scopeIndex)
    if (scopeIndex > -1) {
        let scopeToUpdate = serviceList[scopeIndex]
      // console.log('Find the actual Service now', scopeToUpdate)
        let serviceIndex = scopeToUpdate.services.findIndex(item => (item._id === serviceToUpdate._id))
        if (serviceIndex > -1) {
        // console.log('Service to updat!!!')
          let svcToUpdate = scopeToUpdate.services[serviceIndex]
        // console.log('Find the line item in here: ', svcToUpdate)
          let lineItemIndex = svcToUpdate.lineItems.findIndex(item => (item._id === oldItem._id))
          if (lineItemIndex > -1) {
            let lineItemToUpdate = svcToUpdate.lineItems[lineItemIndex]
          console.log('Update this:', lineItemToUpdate)
          // Notes or Inventory Items
            // svcToUpdate.lineItems[lineItemIndex] = updatedItem
            // lineItemToUpdate = updatedItem
            // Find the Inventory Item
          // console.log('takeoffs', takeoffs)
            let inventoryItem = {
              name: '',
              altName: '',
            }
            console.log('Takeoffs:: ', takeoffs)
            let invIndex = takeoffs.findIndex(item => (item._id.toString() === updatedItem.inventoryItemId ))
            if (invIndex > -1) {
              inventoryItem = takeoffs[invIndex]
            }
            console.log('Inventory Item...', inventoryItem)
            let cleanItem = {}
            if (inventoryItem && inventoryItem._id) {
              cleanItem = {
                _id: lineItemToUpdate._id,
                uuid: lineItemToUpdate.uuid,
                qty: updatedItem.qty,
                name: inventoryItem.name,
                altName: inventoryItem.altName,
                option: updatedItem.option,
                options: inventoryItem.options,
                unit: updatedItem.option.size,
                pricePer: updatedItem.pricePer,
                costPer: updatedItem.costPer,
                totalPrice: updatedItem.totalPrice,
                lastPriceUpdate: updatedItem.lastPriceUpdate,
                inventoryItemId: updatedItem.inventoryItemId,
                optionId: updatedItem.optionId,
                templatedDescription: inventoryItem.templatedDescription,
                description: inventoryItem.description,
                isDefault: inventoryItem.isDefault
              }
            } else {
              console.log('No inventory item - update our note or whatever...')
              cleanItem = {
                _id: lineItemToUpdate._id,
                uuid: lineItemToUpdate.uuid,
                qty: updatedItem.qty,
                name: updatedItem.name,
                altName: updatedItem.altName,
                internalNotes: updatedItem.internalNotes,
                unit: updatedItem.unit,
                pricePer: updatedItem.pricePer,
                costPer: updatedItem.costPer,
                totalPrice: updatedItem.totalPrice,
                description: updatedItem.description
              }
            }
           
            lineItemToUpdate = cleanItem
            svcToUpdate.lineItems[lineItemIndex] = cleanItem
              console.log('updated item:', lineItemToUpdate)
          // console.log('Are the services updated?? ', scopeToUpdate)
            // Save New Estimate here
          }
        }
      console.log('New estimate to save', updatedEstimate)
        let itemsToUpdate = {
          estimate: updatedEstimate,
          counter: state.counter++
        }
        updateEstimate(updatedEstimate, 646, null, null, itemsToUpdate)
        // setState({...state, estimate: updatedEstimate, counter: state.counter++ })
      } else {
        console.log('CANNOT EDIT THIS ITEM - NO SCOPE FOUND????')
      }
  }

  const closeConfigModal = (data) => {
    if (data) {
      console.log('Got some labor data??', data)
      let newState = { ...state }
      console.log('new state:', newState)
      let service = newState.service
      let serviceToUpdate = service.service
      let updatedEstimate = estimate
      // console.log('451 UPDATED ITEM -- is it fulL?', updatedItem)
      console.log('Service:', service)
      console.log('Service Updating:', serviceToUpdate)
      let serviceList = updatedEstimate.serviceList
      let scopeIndex = serviceList.findIndex(item => (service.type === item.type))
      if (scopeIndex > -1) {
          let scopeToUpdate = serviceList[scopeIndex]
        // console.log('Find the actual Service now', scopeToUpdate)
          let serviceIndex = scopeToUpdate.services.findIndex(item => (item._id === serviceToUpdate._id))
          if (serviceIndex > -1) {
          // console.log('Service to updat!!!')
            let svcToUpdate = scopeToUpdate.services[serviceIndex]
          // console.log('Find the line item in here: ', svcToUpdate)
            svcToUpdate.laborDetails = data
            let itemsToUpdate = {
              estimate: updatedEstimate,
              counter: state.counter++
            }
            console.log('Update our estimate...', itemsToUpdate)
            updateEstimate(updatedEstimate, 628, null, null, itemsToUpdate)
          }
        }
    }
    setShowConfigurationModal(false)
  }

  const handleEditService = (item, index) => {
    console.log('Open Configuration Modal with this:', item)
    // setServices(item)
    console.log('Existings tate', state)
    console.log('Set State ****000****', item)
    setState({...state, service: item })
    setShowConfigurationModal(true)
  }

  const handleEditLineItem = (item) => {
  // console.log('Handle Edit the line item specifically', item)
  // console.log('state service??', state)
  }

  const handleSubmitServices = (svcs) => {
  // console.log('Submit Services: ', svcs)
  // console.log('state', state)
    let newEst = estimate
    newEst.serviceList = svcs
    updateEstimate(newEst, 671, true)
    // setEstimate(newEst)
    // setState({...state, estimate: newEst, counter: state.counter++})
    // handleNext()
  }

  const handleDeleteService = (item, type) => {
  // console.log('Delete this service from our estimate', item)
    let newEst = estimate
  // console.log('Type ', type)
    let svcIndex = newEst.serviceList.findIndex(serviceItem => (serviceItem.type === type))
  // console.log('SVC Index', svcIndex)
    if (svcIndex > -1) {
      let index = newEst.serviceList[svcIndex]['services'].findIndex(serviceItem => (serviceItem._id === item._id))
    // console.log('index: ', index)
      if (index > -1) {
        newEst.serviceList[svcIndex]['services'].splice(index, 1)
      }
    // console.log('Updates:', newEst)
      // setEstimate(newEst)
      updateEstimate(newEst, 691)
      // setState({...state, estimate: newEst, counter: state.counter++})
    }

  }

  const handleDeleteLineItemFromService = (lineItemToDelete) => {
  // console.log('Delete this: ', lineItemToDelete)
  // console.log('Find it on the state.service:', state.service)
    let newEst = estimate
    let itemsToUpdate = {}
    // console.log('ESTIMATE: ', newEst)
    let svcIndex = newEst.serviceList.findIndex(serviceItem => (serviceItem.type === state.service.type))
    // console.log('SVC Index', svcIndex)
    if (svcIndex > -1) {
      let index = newEst.serviceList[svcIndex]['services'].findIndex(serviceItem => (serviceItem._id === state.service.service._id))
      // console.log('index: ', index)
      if (index > -1) {
        let service = newEst.serviceList[svcIndex]['services'][index]
        // console.log('SErvice to find shit??', service)
        let lineItems = service.lineItems
        let lineItemIndex = lineItems.findIndex(item => (item._id === lineItemToDelete._id))
        if (lineItemIndex > -1) {
          lineItems.splice(lineItemIndex, 1)
        // console.log('Updated Line Items', lineItems)
        }
      // console.log('Set State to this service', service)
        let svcItem = {
          type: state.service.type,
          service: service
        }
        console.log('Service item::', svcItem)
        itemsToUpdate = { counter: state.counter++, service: svcItem }
      }
    }
    // setEstimate(newEst)
  // console.log('UPdate our estimate to this;', newEst)
  // console.log('Set state to this: ', itemsToUpdate)
    updateEstimate(newEst, 719, null, null, itemsToUpdate)
    // setState({...state, estimate: newEst, counter: state.counter++})
    // }
  }

  const handleCompleteProposalDetails = () => {
    handleNext()
  }

  const handleSaveProposalOptions = (updatedEstimate, specificSaveOnly) => {
    // console.log('Save these changes to the DB!!', updatedEstimate)
    updateEstimate(updatedEstimate, 730, null, specificSaveOnly)
    // setEstimate(updatedEstimate)
    // setState({...state, estimate: updatedEstimate, counter: state.counter++})
  }

  const handleEditEstimateInfo = () => {
    setShowEditEstimateModal(true)
  }

  const closeEditEstimateModal = () => {
    setShowEditEstimateModal(false)
  }

  const updateEstimateDetails = (d) => {
    // console.log('Update estimate details:', d)
    if (d && d._id) {
      let est = estimate
      est.name = d.name
      est.accepted = d.accepted
      est.taxable = d.taxable
      est.taxLocale = d.taxLocale
      est.taxAmount = d.taxAmount
      est.jobSite = d.jobSite
      // Sales Rep is the object
      let salesRep = null
      if (d && d.salesRep && d.salesRep._id) {
        est.salesRep = d.salesRep._id
        salesRep = d.salesRep
      }
      // console.log('Estimate w/ Sales REp:', est)
      if (d.depositData) {
        est.depositData = d.depositData
        // console.log('D.DepositData: ', d.depositData)
      }
      if (d.depositAmount) est.depositAmount = d.depositAmount
      if (d.depositPercent) est.depositPercent = d.depositPercent
      if (d.associatedProject && d.associatedProject._id) est.project = d.associatedProject._id
      if (d && d.billingAddress) est.billingAddressObject = d.billingAddress
      if (d.chosenLocales) {
        // console.log('Got LOcales', d.chosenLocales)
        est.chosenLocales = d.chosenLocales
      }
      if (d.waiveDeposit) {
        est.waiveDeposit = true
      } else {
        est.waiveDeposit = false
      }
      est.summaryPricing = d.summaryPricing
      // console.log('ETIMATE UPDATED: ', est)
      // setEstimate(est)
      setShowEditEstimateModal(false)
      updateEstimate(est, 781)
      // setEstimate(est)
      // setState({...state, estimate: est, counter: state.counter++})
    }
  }

  const saveClonedEstimateLineItems = (proposal) => {
    updateEstimate(proposal, 788)
    // setEstimate(proposal)
    // setState({...state, estimate: proposal, counter: state.counter++})
  }

  return (
    <React.Fragment>
      <EditEstimateDetails open={showEditEstimateModal} jobSites={jobSites} addys={addys} estimate={estimate} closeModal={closeEditEstimateModal} updateEstimateDetails={updateEstimateDetails} saveClonedEstimateLineItems={saveClonedEstimateLineItems} />
      <ConfigurationModal open={showConfigurationModal} vendors={state.vendors} handleEditLineItem={handleEditLineItem} handleUpdateLineItem={handleUpdateLineItem} deleteLineItem={handleDeleteLineItemFromService} estimate={estimate} scopes={categories} sizes={sizes} service={state.service} takeoffs={takeoffs} addLineItem={addLineItem} closeModal={closeConfigModal} />

    <Paper style={{ backgroundColor: '#eee', margin: 10 }}>
    <div style={{ display: 'flex', alignSelf: 'stretch', justifyContent: 'space-between', flexDirection: 'row', alignContent: 'center' }}>
      <h1>Proposal Wizard</h1>
      {
        (estimate && estimate.updated_by && estimate.updated_by._id) ? (
          <div style={{ margin: 10 }}>
            
            <LastUpdateByDetails estimate={estimate} />
          </div>
      
         ) : null
      }
    </div>
      <div>
        {state.client ? state.client.searchName : ''}
      </div>
      {estimate.name} (#{estimate.number}) <Tooltip title="Edit Estimate" >
        <IconButton edge='end' onClick={handleEditEstimateInfo} alt="Edit Estimate" aria-label="Edit Estimate">
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Stepper activeStep={activeStep} alternativeLabel nonLinear>
        {steps.map((label, index) => (
          <Step key={label} >
            <StepButton
              onClick={e => handleManualMove(index, label)}
              >{label}
              </StepButton>
            {/* <StepLabel>{label}</StepLabel> */}
          </Step>
        ))}
      </Stepper>
      <div className={classes.container}>
        {
              activeStep === 0 ? (
                <ChooseScopes updateParent={handleChooseScopes} estimate={estimate} categories={categories} />
              ) : activeStep === 1 ? (
                (estimate && estimate.scopes && estimate.scopes.length) ? <ChooseServices estimate={estimate} updateParent={handleUpdateServices} finishedAddingServices={handleSubmitServices} sizes={sizes} counter={state.counter} handleEditService={handleEditService} handleDeleteService={handleDeleteService} /> : null
              ) : activeStep === 2 ? (
                <ConfigureProposalOptions estimate={estimate} saveOptions={handleSaveProposalOptions} complete={handleCompleteProposalDetails} />
              ) : activeStep === 3 ? (
                <ConfigurePDFOptions estimate={estimate} />
              ) : null
            }
      </div>

    </Paper>
    </React.Fragment>
  )
}
const mapStateToProps = (state) => {
  // console.log('Map to Props ESTIMATE PROCESSS: ', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    perms: state.perms,
    clients: state.clients,
    salesReps: state.salesReps,
    services: state.services
  }
}

export default connect(mapStateToProps)(ProposalWizardContainer)
