import React, { Component } from 'react'
import styles from './registration.module.scss'
import { connect } from 'react-redux'
import {
  Paper,
  Typography,
  Button,
  Grid,
  CircularProgress
} from '@material-ui/core'
import { ValidatorForm, TextValidator} from 'react-material-ui-form-validator'
import axios from 'axios'

const CompleteRegistration = (props) => {
  // console.log('Finish registration: ', props)
  const [state, setState] = React.useState({
    invitationId:  props.match.params.invitationId || '',
    name: '',
    email: '',
    password: '',
    passwordAgain: '',
    username: ''
  })
  const [loading, setLoading] = React.useState(false)

  const fetchData = async (id) => {
    axios
      .get(
        `${props.url}/api/auth/getInvitationDetails?inviteId=${id}`
        )
      .then(({ data }) => {
      // console.log(data)
        // setLeads(data)
        if (data && data._id) {
          setState({...state, name: data.name, email: data.email })
        }
      }).catch(function (err) {
      // console.log('Error getting stuff', err)
      });
  }

  React.useEffect(() => {
    if (props && props.match && props.match.params && props.match.params.invitationId) {
      // setState({...state, invitationId: props.match.params.invitationId })
      fetchData(props.match.params.invitationId)
    }
  }, [props.location])

  React.useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== state.password) {
        return false
      }
      return true
    }, [])
  })

  const handleSubmit = async () => {
  // console.log('Submit this registration', state)
    setLoading(true)
    const response = await axios.put(`${props.url}/api/auth/finishRegistration`, state)
  // console.log(response)
    setLoading(false)
    if (response.data.success) {
      props.notification({
        type: 'success',
        title: 'Signed up!',
        message: 'Check your emails!'
      })
      props.history.push('/')
    } else {
      props.notification({
        type: 'danger',
        title: 'Sign up failed!',
        message: 'Email already registered!'
      })
    }
  }

  return (
<div className={ styles.registration }>
        <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
          <Paper>
            <Typography className={ styles.title } component="h1" variant="h5">
              Sign up
            </Typography>
            <ValidatorForm
              // ref="form"
              onSubmit={ handleSubmit }
              // onError={ errors => console.log(errors) }
              className={ styles.content }
            >
              <TextValidator
                label="Username"
                // onChange={ this.handleChange }
                onChange={ e => setState({...state, username: e.target.value })}
                name="username"
                value={ state.username || '' }
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
                autoFocus
              />
              <TextValidator
                label="Name"
                // onChange={ this.handleChange }
                onChange={ e => setState({...state, name: e.target.value })}
                name="name"
                value={ state.name || '' }
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
              <TextValidator
                label="Email"
                // onChange={ this.handleChange }
                onChange={ e => setState({...state, email: e.target.value })}
                name="email"
                value={ state.email || '' }
                validators={[
                  'required',
                  'isEmail',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'email is not valid',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
              <TextValidator
                label="Password"
                // onChange={ this.handleChange }
                onChange={ e => setState({...state, password: e.target.value })}
                name="password"
                type="password"
                value={ state.password || '' }
                validators={[
                  'required',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
              <TextValidator
                label="Password again"
                // onChange={ this.handleChange }
                onChange={ e => setState({...state, passwordAgain: e.target.value })}
                name="passwordAgain"
                type="password"
                value={ state.passwordAgain || '' }
                validators={[
                  'required',
                  'isPasswordMatch',
                  'minStringLength:5',
                  'maxStringLength:100'
                ]}
                errorMessages={[
                  'this field is required',
                  'password mismatch',
                  'min 5 characters',
                  'max 100 characters'
                ]}
                margin="normal"
                fullWidth
                autoComplete="new-password"
              />
              {
                loading ?
                <Button
                  className={ styles.button }
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled
                >
                  <CircularProgress color="primary" size={ 24 } />
                </Button> :
                <Button
                  type="submit"
                  className={ styles.button }
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Sign up
                </Button>
              }
            </ValidatorForm>
          </Paper>
        </Grid>
      </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(CompleteRegistration)
