import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import SearchIcon from '@material-ui/icons/Search';
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import Autocomplete from '@material-ui/lab/Autocomplete';

const EmployeeSearchComponent = (props) => {
  console.log('Employee serach', props)
  const [searchValue, setSearchValue] = React.useState({})


  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
    // console.log(e.target)
      if (e.target.id === 'employee') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.employees.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          handleChooseEmployee(null, newArr[0])
        }
      }
    }
  }

  const handleChooseEmployee = (e, newEmp) => {
    if (newEmp && newEmp._id) {
      // setSearchValue(newEmp)
      props.selectTheEmployee(newEmp)
    } else {
      // setSearchValue({})
    }
  }
  let employeesToShow = props.employees
  if (props.filterBy) {
    employeesToShow = props.filterBy(props.employees)
  }
  return (
    <div style={{ margin: 10 }}>
      Employee Search
      <Autocomplete
          id="employee"
          options={employeesToShow}
          getOptionLabel={(option) => {
            return `${option.displayName ? option.displayName : ''} ${option.division ? `(${option.division})` : '' }`
          }}
          value={searchValue}
          onChange={handleChooseEmployee}
          renderInput={(params) => <TextField fullWidth onKeyDown={handleTabInteraction} variant="outlined" {...params} label="Employee" />}
        />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification,
    crews: state.crews,
    employees: state.employees
  }
}

export default React.memo(connect(mapStateToProps)(EmployeeSearchComponent))