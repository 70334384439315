import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import PlusIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ServiceConfiguration from './services/ServiceConfiguration'
import GenericCustomOptionsModal from './GenericCustomOptionsModal'
import PackageOptionsModal from './PackageOptions'
import ScopeOfWorkList from './ScopeOfWorkList'

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const styles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      margin: 10,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  topButtons: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center',
      margin: 5,
    },
    [theme.breakpoints.down('md')]: {
      margin: 5,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  helperText: {
    fontSize: '1.5em',
    color: '#bbb'
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    
  },
  list: {
    width: '100%',
    height: 600,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  containerArea: {
    // backgroundColor: 'red',
    // border: '3px solid blue'
  },
  categoryHeader: {
    fontSize: '1.3em',
    fontWeight: 800
  },
  pageHeader: {
    fontSize: '1.4em',
    fontWeight: 600,
    color: '#ccc'
  },
  tabsroot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 550,
  },
  tabsroot: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 550,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: 550
  },
  tabDetailContainer: {
    // width: 270,
    // display: 'flex',
    // flexDirection: 'row',
    // alignItems: 'stretch'
  },
  tabDetails: {
    // display: 'flex',
    // alignSelf: 'stretch',
    // flexGrow: 1
  },
  containerBoundary: {
    border: '1px solid #ccc',
    borderRadius: '1px',
    margin: '8',
    padding: 5
  },
  subContainerArea: {
    border: '1px solid #aaa',
    backgroundColor: '#eee',
    borderRadius: '5px',
    marginTop: 5,
    marginBottom: 8,
    padding: 15
  },
  headingText: {
    color: '#999',
    fontSize: '1.4em'
  },
  headingSubText: {
    color: '#999',
    fontSize: '1.2em'
  }
  })
);

const PickList = ({ estimate }) => {
  let items = []
  if (estimate && estimate.serviceList && estimate.serviceList.length) {
    estimate.serviceList.map(serviceItem => {
    // console.log('Service Item', serviceItem)
      if (serviceItem && serviceItem.services.length) {
        serviceItem.services.map(service => {
        // console.log('Service: ', service)
          if (service.lineItems && service.lineItems.length) {
            service.lineItems.map(lineItem => {
            // console.log('Line Item:::: ', lineItem)
              if (lineItem && lineItem.inventoryItemId) {
              // console.log('Include this: ', lineItem)
                items.push(lineItem)
              }
            })
          }
        })
      }
    })
  }
  return (
    <>
      Pick List Items ({items.length})
      {
        items.map((item, index) => {
          return (
            <p key={index}>{item.qty} - {item.description}</p>
          )
        })
      }
    </>
  )
}

const GenericDetails = (props) => {
  const classes = styles()
  return (
    <Grid container className={classes.containerBoundary}>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.headingText}>
        {props.title}
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12} className={classes.headingSubText} >
        {props.subTitle}
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12} style={{textAlign: 'right'}} >
        <Button onClick={e => props.handleOpenModal(props.modalName)} color="primary" variant="contained">Choose</Button>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.subContainerArea}>
          <span dangerouslySetInnerHTML={{__html: props.currentValue }} ></span>
      </Grid>
    </Grid>
  )
}

const GenericDetailsMultiple = (props) => {
// console.log('Multiple', props)
  const classes = styles()
  return (
    <Grid container className={classes.containerBoundary}>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.headingText}>
        {props.title}
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12} className={classes.headingSubText} >
        {props.subTitle}
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12} style={{textAlign: 'right'}} >
        <Button onClick={e => props.handleOpenModal(props.modalName, true)} color="primary" variant="contained">Choose</Button>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.subContainerArea}>
        {
          props.currentValue.map((item, index) => {
            return (
              <span key={index} dangerouslySetInnerHTML={{__html: item }} ></span> 
            )
          })
        }         
      </Grid>
    </Grid>
  )
}

const PackagesSelected = ({ packages }) => {
  if (packages && packages.length) {
    return (
      <>
        {
          packages.map((pkg, index) => {
            if (!pkg.selected) return null
            return (
              <div key={index}>
                {pkg.name} - {pkg.description} - {(pkg.price && pkg.price > 0) ? currencyFormat(pkg.price) : ''}
              </div>
            )
          })
        }
      </>
    )
  } else {
    return 'No Packages Selected'
  }
}
const PackageOptions = (props) => {
  const classes = styles()
  return (
    <Grid container className={classes.containerBoundary}>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.headingText}>
        {props.title}
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12} className={classes.headingSubText} >
        {props.subTitle}
      </Grid>
      <Grid item lg={6} md={6} sm={6} xs={12} style={{textAlign: 'right'}} >
        <Button onClick={e => props.handleOpenModal(props.modalName)} color="primary" variant="contained">Choose</Button>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.subContainerArea}>
        <PackagesSelected packages={props.estimate.packageOptions} />
      </Grid>
    </Grid>
  )
}

const ConfigureProposalOptions = (props) => {
// console.log('ConfigureProposalOptions ', props)
  const classes = styles()
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState({
    counter: 0,
    objectiveTextOnly: '',
    objective: '',
    introductionTextOnly: '',
    introduction: ''
  })
  const [pdf, setPDF] = React.useState(null)
  const [modalOpen, setModalOpen] = React.useState(false)
  const [isMultiple, setIsMltiple] = React.useState(false)
  const [showPackageOptionsModalOpen, setShowPackageOptionsModalOpen] = React.useState(false)

  const handleSubmit = () => {
  // console.log('Generate PDF NOW')
    let data = {
      estimate: props.estimate
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/proposal/createPDFNewStyleProposal`,
      data: data,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
    // console.log(response.data)
      setPDF(response.data)
    })
  }

  const handleOpenModal = (modalNameToOpen, isMultiple) => {
    setState({...state, optionType: modalNameToOpen, isMultiple: isMultiple})
    setModalOpen(true)
  }

  const handleNextPage = () => {
    setModalOpen(false)
    props.complete()
  }

  const handleSaveOption = (data) => {
    // console.log('Save generic option', data)
    // console.log('Is multiple??', state)
    let { html, type } = data
    setModalOpen(false)
    let newEst = props.estimate
    // if (type === 'disclosures') {
    //   let existingDisclosures = newEst.disclosures
    //   if (existingDisclosures && existingDisclosures.length) {
    //     existingDisclosures.push(html)
    //     newEst.disclosures = existingDisclosures
    //   } else {
    //     newEst.disclosures = [html]
    //   }
    // } else {
    //   newEst[type] = html
    // }
    if (state.isMultiple) {
      let oldItems = newEst[type] || []
      if (html && html.htmlContent) {
        oldItems.push(html.htmlContent)
      } else {
        oldItems.push(html)
      }
      newEst[type] = oldItems
    } else {
      if (html && html.htmlContent) {
        newEst[type] = html.htmlContent
      } else {
        newEst[type] = html
      }
    }
    // console.log('HTML: ', html)
    // console.log('Option type; ', type)
    // console.log('UPdated Estimate saving new info:', newEst)
    props.saveOptions(newEst)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setShowPackageOptionsModalOpen(false)
  }

  const handleOpenPkgOptions = () => {
    setShowPackageOptionsModalOpen(true)
  }

  const handleSavePackage = (data) => {
    // console.log('Save package', data)
    setShowPackageOptionsModalOpen(false)
    let estimatePackageOptions = []
    // data.options.map(item => {
    //   if (item.selected) {
    //     estimatePackageOptions.push(item)
    //   }
    // })
    let newEst = props.estimate
    newEst.packageOptions = data.options
    // console.log('New Estimate::: ', newEst)
    props.saveOptions(newEst)
  }

  const updateScopeOfWorkOrder = (orderedList) => {
    // console.log('Save our updated scope of work', orderedList)
    orderedList.map((item, newIndex) => {
      item.index = newIndex
    })
    let newEst = props.estimate
    newEst.scopeOfWork = orderedList
    props.saveOptions(newEst, 'scopeOfWorkOrderOnly')
  }

  return (
    <Paper style={{  margin: 10, padding: 10, height: '100%', margin: 'auto', maxWidth: '1280px', overflowY: 'auto' }}>
      <GenericCustomOptionsModal open={modalOpen} type={state.optionType} isMultiple={state.isMultiple} estimate={props.estimate} handleSaveOption={handleSaveOption} closeModal={handleCloseModal} />
      <PackageOptionsModal open={showPackageOptionsModalOpen} estimate={props.estimate} handleSaveOption={handleSavePackage} closeModal={handleCloseModal} />
      <Grid container spacing={2}>
          <Grid item lg={12} xs={12} sm={12} md={12} className={classes.headingSubText}>
            <h1>Configure Proposal Details</h1>
            In order to fully generate a proposal, we need to select the various components from our pre-built lists. You will need to go through and choose from each of the following items:
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} >
          <GenericDetails
              modalName="introduction"
              title={`Introduction/Greeting`}
              subTitle="Please Choose Your Greeting/Introduction"
              currentValue={props.estimate.introduction || ''}
              handleOpenModal={handleOpenModal} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <GenericDetails
              modalName="objective"
              title={`Objective`}
              subTitle="Please Choose Your Objective"
              currentValue={props.estimate.objective || ''}
              handleOpenModal={handleOpenModal} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <GenericDetailsMultiple
              modalName="proposalNotes"
              title={`Notes`}
              subTitle="Please Choose Your Notes"
              currentValue={props.estimate.proposalNotes || []}
              handleOpenModal={handleOpenModal} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <GenericDetails
              modalName="areas"
              title={`Areas`}
              subTitle="Please Choose Areas"
              currentValue={props.estimate.areas || ''}
              handleOpenModal={handleOpenModal} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <ScopeOfWorkList estimate={props.estimate} updateScopeOfWorkOrder={updateScopeOfWorkOrder} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <GenericDetailsMultiple
              modalName="disclosures"
              title={`Disclosures`}
              subTitle="Disclosures"
              currentValue={props.estimate.disclosures || []}
              handleOpenModal={handleOpenModal} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <GenericDetails
              modalName="finalParagraph"
              title={`Final Paragraph`}
              subTitle="Please Choose A Final Paragraph"
              currentValue={props.estimate.finalParagraph || ''}
              handleOpenModal={handleOpenModal} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <GenericDetails
              modalName="closing"
              title={`Closing`}
              subTitle="Please Choose A Closing"
              currentValue={props.estimate.closing || ''}
              handleOpenModal={handleOpenModal} />
          </Grid>

          {/* <Grid item lg={12}>
            <PickList estimate={props.estimate} />
          </Grid>
          */}
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <GenericDetails
                modalName="paymentSchedule"
                title={`Payment Schedule`}
                subTitle="Please Choose A Payment Schedule"
                currentValue={props.estimate.paymentSchedule || ''}
                handleOpenModal={handleOpenModal} />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12} >
            <PackageOptions
              estimate={props.estimate}
              modalName="packageOptions"
              title={`Package Options`}
              subTitle="Please Configure the Package"
              currentValue={ props.estimate.packageOptions }
              handleOpenModal={handleOpenPkgOptions}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
              {/* <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={handleSubmit}
                  aria-label="Submit"
                  // disabled={!right.length}
                >
                  Create PDF Preview
                </Button> */}
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  onClick={handleNextPage}
                  aria-label="Submit"
                  // disabled={!right.length}
                >
                  Save Proposal Details
                </Button>
            </Grid>
            <Grid item lg={12}>
              {
                pdf ? <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="860" /> : null
              }
            </Grid>
      </Grid>
    </Paper>
  )
}

const mapStateToProps = (state) => {
  // console.log('MAP PROPS: ,', state)
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(ConfigureProposalOptions))