import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, IconButton, Badge, TextField, InputAdornment, Grid }  from '@material-ui/core';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { FileUploader } from 'react-drag-drop-files';
import { useHistory } from "react-router-dom";
import RoomIcon from '@material-ui/icons/Room';
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PrintIcon from '@material-ui/icons/Print';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import ProtectedButton from '../../actions/protectedButtons'
import StorageIcon from '@material-ui/icons/Storage';
import KPIChart from './KPIChart'
import PdfSplitter from '../Uploads/PdfSplitter';

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  paper: {
    width: '100%',
    height: '100%',
    minHeight: '85vh',
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  searchBoxContainer: {
    width: '50%',
    margin: 10,
    padding: 10
  },
  dashboardItem: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 250, // Adjust based on your content
  },
  dashboardItemName: {
    fontSize: '1.1rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  dashboardItemNumber: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  dashboardItemNameSmall: {
    fontSize: '1.1rem',
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1),
  },
  dashboardItemNumberSmall: {
    // fontSize: 'em',
    // fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  chartContainer: {
    marginTop: theme.spacing(3),
    position: 'relative',
    height: '300px',
  },
  stats: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  status: {
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  title: {
    color: theme.palette.text.secondary,
    paddingBottom: theme.spacing(1),
  },
  statusIcon: {
    color: theme.palette.icon,
  },
}))

const cards = [
  {
    title: 'ORDERS',
    query: { measures: ['Orders.count'] },
    difference: 'Orders',
    duration: 1.25,
  },
  {
    title: 'TOTAL USERS',
    query: { measures: ['Users.count'] },
    difference: 'Users',
    duration: 1.5,
  },
  {
    title: 'COMPLETED ORDERS',
    query: { measures: ['Orders.percentOfCompletedOrders'] },
    progress: true,
    duration: 1.75,
  },
  {
    title: 'TOTAL PROFIT',
    query: { measures: ['LineItems.price'] },
    duration: 2.25,
  },
 ];

const HMIOfficeDashboard = (props) => {
  console.log('HMIOfficeDashboard Props', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    let isMounted = true
    
    // const fetchEmployees = async () => {
    //   console.log('Fetch Employees')
    //   axios.get(`${props.url}/api/hmi/fetchFuelSmartData`).then((response, status, data) => {
    //     // setLoading(false)
    //     if (response && response.data) {
    //     console.log('Smart Tank Data', response.data)
    //       if (isMounted && response.data.length) {
    //         // setMtcSites(response.data.markers)
    //         console.log('HEre is the fuel data', response.data)
    //         setSmartTankData(response.data)
    //       }
    //     }
    //   })
    // }
    // fetchEmployees()

  }, [])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <h1>Office Dashboard</h1>
        <div className={classes.searchBoxContainer}>
            <Grid
            container
            spacing={4}
          >
            {cards.map((item, index) => {
              console.log('Card Item', item)
            return (
              <Grid
                key={item.title + index}
                item
                lg={3}
                sm={6}
                xl={3}
                xs={12}
              >
                <KPIChart {...item}/>
              </Grid>
            )
            })}
            <Grid
              item
              lg={8}
              md={12}
              xl={9}
              xs={12}
            >
            </Grid>
          </Grid>
        </div>
        <PdfSplitter pdfUrl={'https://sfo2.digitaloceanspaces.com/dflspaces/ProjectUploads/1718420655221__scan__dfl_scanner_20240614_133314.pdf'} />
      </Paper>
    </div>
  )
}


const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    entities: state.entities,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(HMIOfficeDashboard)