import React from 'react'
import CustomerList from './CustomerList.js'
import { connect } from 'react-redux'
import axios from 'axios'
import styles from './customer.module.scss'
import Grid from '@material-ui/core/Grid'
// import MaintenanceRunDetails from './MaintenanceRunDetails.js'
import BatchStatementDetails from './BatchStatementInfo.js'
import FertilizationModal from './FertilizationModal'
import SnoutWeevilModal from './SnoutWeevilModal'
import PostPaymentsModule from './PostPaymentsModule'
import { Button, CircularProgress } from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import { setSalesReps, setEmployees } from '../../actions/connectionActions'

const Customer = (props) => {
  // console.log('Customer Component', props)
  const [clients, setClients] = React.useState([])
  const [state, setState] = React.useState({
    clients: [],
    snoutWeevilModalOpen: false,
    client: {},
    configs: []
  })
  const [loading, setLoading] = React.useState(false)
  const [showPaymentModule, setShowPaymentModule] = React.useState(false)
  
  React.useEffect(() => {
    let isMounted = true
    // console.log('Props.location has changed...', props)
    // let cachedData = sessionStorage.getItem('customerData')
    // console.log('Cached data ', cachedData)
    // cachedData = JSON.parse(cachedData)
    // console.log('Cached Data:', cachedData)
    // if (cachedData && cachedData.list && cachedData.list.length) {
    // // console.log('We have cached data to use!!')
    //   let timeSince = Date.now() - cachedData.timestamp
    // // console.log('Time Since:', timeSince)
    //   if (isMounted) {
    //     setLoading(false)
    //     setClients(cachedData.list)
    //     setState({...state, clients: cachedData.list})
    //   }
    //   if (timeSince > 30000) {
    //   // console.log('Refresh this shit')
    //     fetchData(null, true)
    //   }
    // } else {
    //   fetchData()
    // }
    async function fetchData(toReturn, noLoad) {
      // console.log('Fetch our clients')
      if (!noLoad) setLoading(true)
      axios.get(`${props.url}/api/customer/clientsAndStuff`).then((response, status, data) => {
        // console.log(response)
        // console.log(status)
        // console.log(data)
        console.log('Response.data', response.data)
        if (response && response.data && response.data.clients) {
          if (isMounted) {
            setLoading(false)
            setClients(response.data.clients)
            const configs = response.data.configs
            console.log('Configs', configs)
            setState({...state, clients: response.data.clients, configs: configs })
          }
          // if (toReturn) {
          //   return response.data.clients
          // } else {
          //   if (isMounted) {
          //     setLoading(false)
          //     setClients(response.data.clients)
          //     setState({...state, clients: response.data.clients, configs: response.data.configs })
          //   }
            
          // }
        } else {
          // console.log('Fucked up', response)
          if (response === 'Unauthorized') props.history.push('/restricted')
        }
      }).catch((error, status) => {
        // console.log('Error', error)
        // console.log(status)
      })
  
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [props.location])

  const updateCustomerList = async (searchValue) => {
  // console.log('UPdate Customers...', searchValue)
    // let updatedList = await fetchData()
    // console.log('Update the list to work with search items
    // console.log('Got our updated client list: ', updatedList.length)
    // setClients(updatedList)
    // setState({...state, clients: updatedList})
    // return callback(updatedList)
  }

  const openSnoutWeevilModal = async (client) => {
    // console.log('Open Snout Weevil Modal for this client', client)
    setState({...state, client: client, snoutWeevilModalOpen: true})
  }

  const closeSnoutWeevilModal = () => {
    setState({...state, snoutWeevilModalOpen: false})
  }

  const closePostPaymentsModal = () => {
    setShowPaymentModule(false)
  }
  return (
    <div className={ styles.customer }>
      <PostPaymentsModule open={showPaymentModule} closeModal={closePostPaymentsModal} />
      <FertilizationModal closeModal={closeSnoutWeevilModal} open={state.snoutWeevilModalOpen} client={state.client} />
      {/* <SnoutWeevilModal closeModal={closeSnoutWeevilModal} open={state.snoutWeevilModalOpen} client={state.client} /> */}
      <Grid container>
        <Grid item lg={6} xs={12}>
          <h1>Customers</h1>
        </Grid>
        <Grid item lg={3} xs={6}>
            
            <Button
                variant="contained"
                color="primary"
                disabled={loading}
                startIcon={<AttachMoneyIcon />}
                size="small"
                onClick={() => setShowPaymentModule(true)}
              >
              Post Payment(s)
              </Button>  
          {/* <MaintenanceRunDetails stuff={ props } style={{align: 'center', textAlign: 'center'}} /> */}
        </Grid>
        <Grid item lg={3} xs={6}>
          <BatchStatementDetails stuff={ props } style={{align: 'center', textAlign: 'center'}} />
        </Grid>
      </Grid>
      { loading ? <CircularProgress color="primary" size={ 96 } /> :
        <CustomerList updateCustomerList={updateCustomerList} openSnoutWeevilModal={openSnoutWeevilModal} className={ styles.customer } clients={ clients } stuff={ props } configs={state.configs} />
      }
  </div>
  )
}

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      configs: state.configs
    }
  }
  
  // const mapDispatchToProps = (dispatch) => {
  //   // console.log('Dispatch----', dispatch)
  //   return {
  //     setSalesReps: (salesReps) => { dispatch(setSalesReps(salesReps)) },
  //     setEmployees: (employees) => { dispatch(setEmployees(employees)) }
  //   }
  // }

  // export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Customer))
  export default React.memo(connect(mapStateToProps)(Customer))
