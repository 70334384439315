import React from 'react';
import { connect } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import Tooltip from '@material-ui/core/Tooltip';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

/*
  Follow Component
  -- If Following show as following
  -- If not, show as not
*/

const FollowingComponent = (props) => {
  // console.log('Following Component: ', props)
  const [selected, setSelected] = React.useState(false)

  React.useEffect(() => {
    checkOurShit(props.data)
  }, [props.data])

  const checkOurShit = (data) => {
    // console.log('Check if we are selected', data)
    // console.log('user;', props.user)
    if (data && data.followers && data.followers.length && props.user._id) {
      let isSelected = false
      data.followers.map(item => {
        if (item === props.user._id) {
          // console.log('We are following!!')
          isSelected = true
        }
      })
      setSelected(isSelected)
    }
  }

  const toggleSelect = async () => {
    // console.log('Toggle Selected', !selected)
    setSelected(!selected)
    let obj = {
      type: props.type,
      _id: props._id,
      value: !selected
    }
    // console.log('Post:', obj)
    const response = await axios.post(`${props.url}/api/company/followItem`, obj)
    // console.log('Following Updated:', response.data)
  }

  return (
    <React.Fragment >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div>
          <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
              onClick={toggleSelect}
            />
        </div>
        <div>
          Follow
        </div>
      </div>
    </React.Fragment>
    
  )
}

const mapStateToProps = (state) => {
  // console.log('Map to Props Edit Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
    user: state.user
  }
}

export default connect(mapStateToProps)(FollowingComponent)