import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const defaultInputValues = {
  projectName: ''
}

const categoryOptions = [
  'Vendor Quotes',
  'Vendor Invoice',
  'Customer Invoice',
  'Purchase Order',
  'Photos',
  'Plans',
  'Estimates',
  'Other'
]

const EditUploadDetails = (props) => {
// console.log('Edit UPload Details  Props: 130', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [client, setClient] = React.useState({})
  const [pdf, setPDF] = React.useState({})
  const [state, setState] = React.useState({
    status: 'New',
    projectName: '',
    notes: '',
    name: ''
  })

  const inputRef = React.useRef({  })

  React.useEffect(() => {
    let isMounted = true
    const fetchPDF = async (url) => {
    // console.log('FETCH: ', url)
      let cleanURL = ''
      if (url.includes('https')) {
        cleanURL = url
      } else {
        cleanURL = `https://${url}`
      }
    // console.log('CLEAN: ', cleanURL)
      await axios.get(cleanURL, {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/octet-stream',
          'Accept': 'application/pdf, application-octet-streatm'
        },
        responseType: 'blob'
      }).then((response) => {
      // }).then(response => response.arrayBuffer())
      //   .then(blob => {
      //   // console.log('Blob: ', blob)
          // let body = response.data
          // let base64Version = Buffer.from(body).toString('base64')
          // console.log('Base 64', base64Version)
          // // let blob = response.data.blob()
          // let newStuff = 'data:application/pdf;base64,' + base64Version
          // console.log(newStuff)
          // console.log('response:', response)
          let newBlob = new Blob([response.data], {type: "application/pdf"})
          // console.log(newBlob)
          const data = window.URL.createObjectURL(newBlob);
          // console.log(data)
          // var link = document.createElement('a');
          // link.href = data;
          // link.download="file.pdf";
          // link.click();
          setPDF(data)
        })
      // console.log(response)
      // if (response && response.data) {
      // }
    }
    if (props.upload && props.upload._id) {
    // console.log('Set our inptu ref; ', inputRef, props.upload)
      let name = props.upload.name
      let notes = props.upload.notes
      let category = props.upload.documentType
      if (props.upload.category) category = props.upload.category
      // var newBlob = new Blob([blob], {type: "application/pdf"})
      if (props.upload && props.upload.type === 'application/pdf') {
      // console.log('Display our PDF')
        fetchPDF(props.upload.url)
      }
      setState({...state, name, notes, category})
    }

    return () => {
      isMounted = false
    }
  }, [props])

  const handleSave = () => {
    // console.log(inputRef)
    setLoading(true)
    setClient({})
    let data = state // inputRef.current
    data.projectId = props.project._id
    data.uploadId = props.upload._id
    data.category = state.category
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/updateUploadDetails`,
      data: data
    }).then(response => {
      setLoading(false)
      if (response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Uploads Saved',
          message: 'That Upload Has Been Saved. Great Job!'
        })
        props.updateUpload(response.data.upload)
        // setState({...state, name: '', notes: ''})
        // props.updateParent(response.data)
        // props.closeModal()
      }

    })
  }
  const updateCustomer = (e, customer) => {
    if (customer && customer._id) {
      setClient(customer)
      inputRef.current.client = customer
    }
  }

  const updateSalesRep = (e, rep) => {
    if (rep && rep._id) {
      inputRef.current.salesRep = rep
    }
  }
  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
    // console.log(e.target)
      if (e.target.id === 'customername') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          setClient(newArr[0])
        }
      }
    }
  }

  const updateStatus = (e, status) => {
    inputRef.current.status = status
  }
  const updateRef = (e) => {
    inputRef.current[e.target.id] = e.target.value
  }
  const handleFocus = (e) => {
    e.target.select()
  }

  const updateProject = (e, newProj) => {
  // console.log('Set the project', newProj)
  }
  
  return (
    <div className={classes.root}>
      <Dialog open={props.open} fullWidth={false} onClose={props.closeModal} maxWidth='sm' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Edit Upload Notes</DialogTitle>
          <DialogContent >
            <DialogContentText>
              What Would You Like To Change?
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="name"
                  label="File Name"
                  // style={{ margin: 8 }}
                  value={state.name}
                  // onChange={updateRef}
                  onFocus={handleFocus}
                  onChange={e=> setState({...state, name: e.target.value})}
                  // ref={inputRef['projectName']}
                  placeholder="File Name"
                  helperText="What Should We Call This File?"
                  fullWidth
                  // value={inputRef.current['projectName']}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Grid>
             
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TextField
                  id="notes"
                  label="File Description"
                  // style={{ margin: 8 }}
                  onFocus={handleFocus}
                  // ref={inputRef.projectDescription}
                  placeholder="File Description"
                  helperText="What Is This File?"
                  fullWidth
                  multiline
                  rows={3}
                  value={state.notes}
                  onChange={e=> setState({...state, notes: e.target.value})}
                  margin="normal"
                  variant="outlined"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={state.category}
                  fullWidth
                  variant="outlined"
                  onChange={e=>setState({...state, category: e.target.value})}
                  label="Category"
                >
                  {
                    categoryOptions.map(unit => {
                      return (
                      <MenuItem key={unit} value={unit} >{unit}</MenuItem>
                    )})
                  }
                </Select>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Autocomplete
                  id="project"
                  options={props.projects || []}
                  getOptionLabel={(project) => (project && project._id) ? `${project.name} - (${project.number})` : 'Please Choose a Project'}
                  onChange={updateProject}
                  defaultValue={'Please Choose'}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {state.project}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Associated Project" />}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                
                {
                  (props.upload && props.upload.type && props.upload.type.includes('pdf')) ? 
                    <object style={{padding: '15px'}} aria-label="PDF" data={ pdf } type="application/pdf" width="100%" height="800px" /> : null
                }
                {
                  (props.upload && props.upload.type && props.upload.type.includes('image')) ?  <img src={props.upload.url} style={{maxWidth: 300, maxHeight: 300}} /> : null
                }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
              Cancel
            </Button> */}
            <Button color="primary" disabled={loading} onClick={handleSave}>
              {
                loading ? <CircularProgress /> : 'Save'
              }
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  )
}


  const mapStateToProps = (state) => {
    // console.log('Map state to props 395', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
      projects: state.projects
    }
  }
  
  export default connect(mapStateToProps)(EditUploadDetails)