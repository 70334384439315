import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import { fade, makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import AddCommentIcon from '@material-ui/icons/AddComment';
import moment from 'moment'

const handleFocus = (event) => event.target.select()

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  textSaveLineBreaks: {
    whiteSpace: 'pre-line'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

let requiresUpdate = 0;
const defaultValues = {
  qty: 0,
  unit: '',
  description: '',
  totalPrice: 0,
  costPer: 0,
  pricePer: 0,
  counter: 0,
  editing: false,
  inventoryItemId: null,
  optionId: null,
  option: {},
  item: {},
  internalNotes: ''
}

function SimpleModal(props) {
  const classes = useStyles();
  const closeModal = props.closeFunc
  const user = props.user
  const url = props.url
  const notification = props.notification
// console.log(url)

const [open, setOpen] = React.useState(false)
const [loading, setLoading] = React.useState(false);
const [notes, setNotes] = React.useState(null)
const [state, setState] = React.useState(defaultValues)

React.useEffect(() => {
  console.log('Custom Item props change', props)
  if (props.itemToEdit && props.itemToEdit._id && props.itemToEdit.inventoryItemId) {
    setOpen(false)
  } else {
    if (props.itemToEdit && props.itemToEdit._id) {
      let customObj = { ...props.itemToEdit }
      setOpen(true)
      setState({...defaultValues, ...customObj})
    } else {
      setOpen(false)
    }
  }

}, [props])


const openModal = () => {
  setNotes('')
  setOpen(true)
}


const handleClose = () => {
  props.closeModal()
 
  setOpen(false)
}

const handleUpdateNote = () => {
  // console.log('Update custom Line item')
  setOpen(false)
  // let newItem = props.itemToEdit
  props.handleUpdateLineItem(state)
}
const handleSaveNote = () => {
  // console.log(notes)
  console.log('Save this shit!', state)
  setOpen(false)
  props.saveCustomLineItem(state)
  // setState(defaultValues)
}


const handleCostChangeUpdate = (e) => {
  let pricePer = 0
  let totalPrice = 0
  let costPer = e.target.value
  pricePer = Number(costPer * 1.4).toFixed(2)
  totalPrice = Number(pricePer * state.qty).toFixed(2)
// console.log('SetState 398')
  setState({...state, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice})
}

const handlePriceManualChange = (e) => {
  let pricePer = e.target.value
  let totalPrice = 0
  totalPrice = Number(pricePer * state.qty).toFixed(2)
// console.log('SetState 406')
  setState({...state, pricePer: pricePer, totalPrice: totalPrice})
}

const handleQtyUpdate = (e) => {
  let pricePer = state.pricePer
  let totalPrice = 0
  totalPrice = Number(pricePer * e.target.value).toFixed(2)
// console.log('SetState 414')
  setState({...state, qty: e.target.value, totalPrice: totalPrice})
}

const addLineItemClicked = () => {
  setState(defaultValues)
  setOpen(true)
}

  return (
    <div>
      <Dialog
        maxWidth={'md'}
        open={open}
        fullWidth
        onClose={handleClose}
        >
          <DialogTitle>Add Custom Line Item/Note</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Line Item Note"
              variant="outlined"
              // ref={noteRef}
              value={state.description}
              // onChange={e => setNotes(e.target.value)}
              // onClick={() => noteRef.current.focus()}
              onChange={e=>setState({...state, description: e.target.value})}
              multiline
              minRows={4}
              helperText="What Would You Like to Add to the Proposal as a Line Item?"
            />
            
            <Grid container spacing={2}>
            <Grid item lg={2} md={3} sm={4} xs={6}>
          <TextField
            label="Qty"
            variant="outlined"
            type="text"
            value={state.qty}
            
            onChange={handleQtyUpdate}
            onFocus={handleFocus}
            />
        </Grid>
            <Grid item lg={2} md={3} sm={4} xs={6}>
          <TextField
            label="Cost Per"
            variant="outlined"
            type="text"
            value={state.costPer}
            
            onChange={handleCostChangeUpdate}
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={6}>
          <TextField
            label="Price Per"
            variant="outlined"
            type="text"
            value={state.pricePer}
            onChange={handlePriceManualChange}
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2} md={3} sm={4} xs={6}>
          <TextField
            label="Total Price"
            variant="outlined"
            type="text"
            value={state.totalPrice}
            // onChange={e=>setState({...state, totalPrice: e.target.value})}
            onFocus={handleFocus}
            disabled
            />
        </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              label="Internal Notes"
              variant="outlined"
              // ref={noteRef}
              value={state.internalNotes}
              onChange={e=>setState({...state, internalNotes: e.target.value})}
              // onClick={() => noteRef.current.focus()}
              multiline
              minRows={4}
              helperText="What Would You Like to Add to the Proposal as a Line Item?"
            />
          </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {
              (props.itemToEdit && props.itemToEdit._id) ? (
                <Button
                  color="primary"
                  onClick={handleUpdateNote}
                >Update </Button>
              ) : (
                <Button
                  color="primary"
                  onClick={handleSaveNote}
                >Save</Button>
              )
            }
            <Button
              color="secondary"
              onClick={handleClose}
            >Cancel</Button>
          </DialogActions>
        </Dialog>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddCommentIcon />}
            onClick={addLineItemClicked}
          >Add Custom Line Item</Button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(SimpleModal))