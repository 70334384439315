import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  option: {
    cursor: 'pointer',
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      marginRight: theme.spacing(1),
    },
    height: '80px'
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderColor: theme.palette.primary.dark,
  },
  notSelected: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
  }
}));

function PaymentOptions({ setSelectedOption, selectedOption  }) {
  const classes = useStyles();

  const handleSelect = (option) => {
    setSelectedOption(option);
  };

  return (
    <div>
      <div 
        className={`${classes.option} ${selectedOption === 'eCheck' ? classes.selected : classes.notSelected}`}
        onClick={() => handleSelect('eCheck')}
      >
        <img src={`${process.env.PUBLIC_URL}/eCheckIcon.png`} alt="Electronic Check" height="48px"  />
        <span>Electronic Check</span>
      </div>
      <div 
        className={`${classes.option} ${selectedOption === 'CreditCard' ? classes.selected : classes.notSelected}`}
        onClick={() => handleSelect('CreditCard')}
      >
        <img src={`${process.env.PUBLIC_URL}/credit-card-logos.png`} alt="Credit Card" height="48px" />
        <span>Credit Card</span>
      </div>
    </div>
  );
}

export default PaymentOptions;
