import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IconButton, Button, CircularProgress, isMuiElement, Drawer }  from '@material-ui/core';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Tooltip from '@material-ui/core/Tooltip';
import ChatDrawer from '../Common/Chat/ChatDrawer'
import ChatIcon from '@material-ui/icons/Chat';
import Badge from '@material-ui/core/Badge';

const JobChat = (props) => {

  const [isChatDrawerOpen, setIsChatDrawerOpen] = React.useState(false)
  const toggleChatDrawer = (side, openUpdate, num) => event => {
    // console.log('Toggle Chat ', side)
      if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
    // console.log(openUpdate)
    // console.log(num)
      setIsChatDrawerOpen(openUpdate)
  }

  const handleIt = () => {
    setIsChatDrawerOpen(true)
  }
  const wo = props.workorder
  return (
    <div>
      <ChatDrawer 
        open={isChatDrawerOpen}
        onClose={toggleChatDrawer('right', false, 4)}
        room={`Job_${wo._id}`}
      />
       <CustomButton openModal={handleIt} />
    </div>
  )
}

const CustomButton = ({ openModal }) => {
  let label = `Chat`
  return (
    <Tooltip title="View Chat" style={{ marginLeft: 35 }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<ChatIcon color="#eee" /> }
        // disabled={num < 1}
        onClick={openModal}
        >{label}</Button>
    </Tooltip>
  )
}

export default JobChat
