const moment = require('moment')

// const findNextServiceDate = (lastServiceDate, frequency, preferredDay, evenOrOddWeeks) => {
//   console.log('Find Next Date: %s - %s - %s - %s', lastServiceDate, frequency, preferredDay, evenOrOddWeeks);

//   const today = moment().startOf('day');
//   let nextDate = moment(lastServiceDate).startOf('day');
//   const preferredDayNum = moment().day(preferredDay).day();
//   const targetEvenWeek = evenOrOddWeeks.toLowerCase() === 'even';

//   // Adjust the date to the preferred day of the week
//   const adjustToPreferredDay = (date) => {
//     while (date.day() !== preferredDayNum) {
//       date.add(1, 'day');
//     }
//     return date;
//   };

//   // Find the next even/odd week that matches the preferred day
//   const findNextEvenOddDay = (date) => {
//     let tempDate = date.clone();
//     while ((tempDate.week() % 2 === 0) !== targetEvenWeek || tempDate.day() !== preferredDayNum) {
//       tempDate.add(1, 'day');
//     }
//     return tempDate;
//   };

//   // Add the interval and adjust the date, respecting boundaries
//   const addIntervalAndAdjust = (interval, minWeeks, maxWeeks) => {
//     // Add the minimum interval first (e.g., 10 weeks for quarterly)
//     nextDate.add(minWeeks, 'weeks');

//     // Adjust to the next matching even/odd week and day
//     let adjustedDate = findNextEvenOddDay(nextDate.clone());

//     // If the adjusted date is still too soon (before reaching the maxWeeks limit), keep moving forward
//     while (adjustedDate.diff(lastServiceDate, 'weeks') < minWeeks || adjustedDate.diff(lastServiceDate, 'weeks') > maxWeeks) {
//       nextDate.add(1, 'week');
//       adjustedDate = findNextEvenOddDay(nextDate.clone());
//     }

//     return adjustedDate;
//   };

//   switch (frequency.toLowerCase()) {
//     case 'weekly':
//       nextDate = addIntervalAndAdjust({ value: 1, units: 'week' }, 1, 1);
//       break;
//     case 'bi-weekly':
//       nextDate = addIntervalAndAdjust({ value: 2, units: 'week' }, 2, 2);
//       break;
//     case 'monthly':
//       nextDate = addIntervalAndAdjust({ value: 4, units: 'week' }, 4, 5); // Monthly roughly every 4-5 weeks
//       break;
//     case 'quarterly':
//       nextDate = addIntervalAndAdjust({ value: 10, units: 'week' }, 10, 14); // Quarterly every 10-14 weeks
//       break;
//     case 'semi-annually':
//       nextDate = addIntervalAndAdjust({ value: 26, units: 'week' }, 26, 26);
//       break;
//     default:
//       console.error('Unsupported frequency:', frequency);
//       return null;
//   }

//   return nextDate.toDate();
// };

const findNextServiceDate = (lastServiceDate, frequency, preferredDay, evenOrOddWeeks) => {
  console.log('Find Next Date: %s - %s - %s - %s', lastServiceDate, frequency, preferredDay, evenOrOddWeeks);
  
  const today = moment().startOf('day');
  let nextDate = moment(lastServiceDate).startOf('day');
  const preferredDayNum = moment().day(preferredDay).day();
  const targetEvenWeek = evenOrOddWeeks.toLowerCase() === 'even';

  const adjustToPreferredDay = (date) => {
    while (date.day() !== preferredDayNum) {
      date.add(1, 'day');
    }
    return date;
  };

  const findNextEvenOddDay = (date) => {
    let tempDate = date.clone();
    while ((tempDate.week() % 2 === 0) !== targetEvenWeek || tempDate.day() !== preferredDayNum) {
      tempDate.add(1, 'day');
    }
    return tempDate;
  };

  const addIntervalAndAdjust = (interval, minWeeks, maxWeeks) => {
    // Add the minimum interval first
    nextDate.add(minWeeks, 'weeks');

    // For monthly frequency, ensure we're in the next month
    if (frequency.toLowerCase() === 'monthly') {
      while (nextDate.month() === moment(lastServiceDate).month() || 
             nextDate.diff(moment(lastServiceDate), 'days') < 28) {
        nextDate.add(1, 'week');
      }
    }

    // Adjust to the preferred day
    nextDate = adjustToPreferredDay(nextDate);

    // If it's not the correct even/odd week, move to the next week
    if ((nextDate.week() % 2 === 0) !== targetEvenWeek) {
      nextDate.add(1, 'week');
    }

    // Ensure we're within the allowed range
    while (nextDate.diff(lastServiceDate, 'weeks') < minWeeks) {
      nextDate.add(2, 'weeks'); // Add 2 weeks to maintain even/odd pattern
    }

    return nextDate;
  };

  switch (frequency.toLowerCase()) {
    case 'weekly':
      nextDate = addIntervalAndAdjust({ value: 1, units: 'week' }, 1, 1);
      break;
    case 'bi-weekly':
      nextDate = addIntervalAndAdjust({ value: 2, units: 'week' }, 2, 2);
      break;
    case 'monthly':
      nextDate = addIntervalAndAdjust({ value: 4, units: 'week' }, 4, 6);
      break;
    case 'quarterly':
      nextDate = addIntervalAndAdjust({ value: 12, units: 'week' }, 12, 16);
      break;
    case 'semi-annually':
      nextDate = addIntervalAndAdjust({ value: 26, units: 'week' }, 26, 30);
      break;
    default:
      console.error('Unsupported frequency:', frequency);
      return null;
  }

  return nextDate.toDate();
};

module.exports = {
  findNextServiceDate
}
