import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, IconButton, Badge, Typography }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    // width: '80%',
    margin: '10px',
  },
  paper: {
    width: '100%',
    height: '100%',
    minHeight: '80vh',
    padding: 10,
    marginBottom: theme.spacing(2),
  },
  grid: {
    // margin: '10px',
    // padding: '10px',
    // width: '95%'
  }
}))

const LotInventoryList = (props) => {
  console.log('LOT INVENTORY LIST:: 40', props)
  return (
    <div>
      List of Items
      <div>
        <LotInventoryTable {...props} />
      </div>
    </div>
  )
}

const LotInventoryTable = (props) => {
  let list = [];
  if (props && props.list && props.list.inventoryByYard) {
    list = props.list.inventoryByYard;
  }

  // Group items by takeoffItemName
  const groupedItems = list.reduce((acc, item) => {
    if (!acc[item.takeoffItemName]) {
      acc[item.takeoffItemName] = [];
    }
    acc[item.takeoffItemName].push(item);
    return acc;
  }, {});
  return (
    <Table>
    <TableHead>
      <TableRow>
        <TableCell colSpan={7}>
          <Typography variant="h6">Lot Inventory</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Item Name</TableCell>
        <TableCell>Option Name</TableCell>
        <TableCell>Yard</TableCell>
        <TableCell>On Hand Qty</TableCell>
        <TableCell>Incoming Qty</TableCell>
        <TableCell>Assigned Qty</TableCell>
        <TableCell>Area</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {Object.keys(groupedItems).map((takeoffItemName, itemIndex) => (
        <React.Fragment key={itemIndex}>
          <TableRow>
            <TableCell colSpan={7} style={{ backgroundColor: '#f0f0f0' }}>
              <Typography variant="subtitle1">{takeoffItemName}</Typography>
            </TableCell>
          </TableRow>
          {groupedItems[takeoffItemName].map((item, subIndex) => (
            item.yards.map((yard, yardIndex) => (
              <TableRow key={`${itemIndex}-${subIndex}-${yardIndex}`}>
                <TableCell />
                <TableCell>{item.takeoffOptionName}</TableCell>
                <TableCell>{yard.yard}</TableCell>
                <TableCell>{yard.totalOnHandQty}</TableCell>
                <TableCell>{yard.totalIncomingQty}</TableCell>
                <TableCell>{yard.totalAssignedQty}</TableCell>
                <TableCell>{yard.area}</TableCell>
              </TableRow>
            ))
          ))}
        </React.Fragment>
      ))}
    </TableBody>
  </Table>
  )
}

// const LotInventoryTable = (props) => {
//   let list = []
//   if (props && props.list && props.list.inventoryItems) list = props.list.inventoryItems
//   return (
//     <Table>
//       <TableHead>
//         <TableRow>
//           <TableCell>Item Name</TableCell>
//           <TableCell>Option Name</TableCell>
//           <TableCell>On Hand Qty</TableCell>
//           <TableCell>Incoming Qty</TableCell>
//           <TableCell>Wholesale Cost</TableCell>
//           <TableCell>MSRP</TableCell>
//           <TableCell>Yard</TableCell>
//           <TableCell>Area</TableCell>
//           <TableCell>Last Updated</TableCell>
//         </TableRow>
//       </TableHead>
//       <TableBody>
//         {
//           list.map((row, index) => {
//             console.log('list row', row)
//             return (
//               <TableRow
//                 key={index}
//                 >
//                   <TableCell>{row?.takeoffItem?.name}</TableCell>
//                   <TableCell>{row?.takeoffOptionItem?.name}</TableCell>
//                   <TableCell>{row.onHandQty}</TableCell>
//                   <TableCell>{row.incomingQty}</TableCell>
//                   <TableCell>{row.costPer}</TableCell>
//                   <TableCell>{row.pricePer}</TableCell>
//                   <TableCell>{row.yard}</TableCell>
//                   <TableCell>{row.area}</TableCell>
//                   <TableCell>{new Date(row.updated_at).toLocaleDateString({ format: 'MM/DD/YY'})} {new Date(row.updated_at).toLocaleTimeString({ time: 'short' })}</TableCell>
                  
//               </TableRow>
//             )
//           })
//         }
//       </TableBody>
//     </Table>
//   )
// }

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url,
    entities: state.entities,
    perms: state.perms
  }
}

export default connect(mapStateToProps)(LotInventoryList)