import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import axios from 'axios';
import styled from 'styled-components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  border: 4px solid #ddd;
  margin-top: 20px;
  margin-bottom: 10px;
  align-self: stretch;
  padding: 10px;
`;

const TabsChoice = styled.div`
  min-width: 150px;
  width: 25%;
  overflow: auto;
  height: 250px;
  display: flex;
  background-color: #fff;
`;

const TabDisplay = styled.div`
  display: flex;
  flex-grow: 1;
  height: 250px;
  align-self: stretch;
  overflow-y: scroll;
  background-color: #fff;
`;

const LabelText = styled.div`
  margin-top: 20px;
  font-size: 18px;
  color: #555;
  font-weight: 800;
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{width: '100%'}}>
          <Typography  component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  }
}));

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

const defaultValues = {
  message: '',
  recipients: []
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const SendToCustomerModal = (props) => {
  // console.log('Send SMS To Customer Modal:', props)
  const classes = useStyles();

  const [dialogValue, setDialogValue] = React.useState(defaultStateValues);
  const [state, setState] = React.useState(defaultValues)
  const [recipientList, setRecipientList] = React.useState([])
  const [item, setItem] = React.useState({})
  const [open, toggleOpen] = React.useState(false);


  const [tabOpenValue, setTabOpenValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setTabOpenValue(newValue);
  }

  const handleClose = () => {
    setDialogValue(defaultStateValues);

    props.handleCloseModal();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log('Send this shit!!!', recipientList)
    // console.log(dialogValue)
    // console.log(props)
    // // console.log(dialogValue)
    // // console.log('Got it all?')
    // handleClose();
    let data = {
      // estimateId: props.estimate._id,
      recipientList,
      message: state.message
      // emailContent: content,
      // fromEmail: `${state.fromEmail}@${props.entity.domain}`
    }
    // console.log(data)
    axios({
      method: 'post',
      url:  `${props.url}/api/customer/sendSMS`,
      data: data,
    }).then(response => {
      // console.log('Sent SMS: ', response.data)
      if (response && response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: 'Text Message Sent!',
          message: 'Your Message Has Been Delivered!'
        })
        handleClose()
        setState(defaultValues)
      }
      if (response && response.data && response.data.error) {
        props.notification({
          type: 'warning',
          title: 'System Error Sending Text Message',
          message: response.data.message
        })
      }

    }).catch(err => {
      // // console.log('Error caught when uploading pic: ', err)
      props.notification({
        type: 'warning',
        title: 'Failed to Send Estimate!',
        message: err.message
      })
    })
  };

  const handleCategoryChange = (e) => {
    setDialogValue({...dialogValue, category: e.target.value})
  }
  const handleUpdateUOM = (e, newOne) => {
    // // console.log('New UOM: ', newOne)
    setDialogValue({...dialogValue, unitsOfMeasure: newOne})
  }

  const updatePlantDetails = (d, c) => {
    // console.log('Update Plant Details: ', d)
    // console.log('c: ', c)
    if (c && c.unitsOfMeasure && c.unitsOfMeasure.length) d.unitsOfMeasure = c.unitsOfMeasure
    setState({...state, inputDetails: d})
  }

  // const handleSave = () => {
  //   // console.log('Save this shit!!!', state)
  //   // console.log(dialogValue)
  //   // console.log(props)

  // }

  const contacts = [
    {
      displayName: 'Smith, Tom'
    }
  ]

  const handleContactChange = (e, newItem) => {
    // console.log('Contact change: ', newItem)
    setRecipientList(newItem)
  }

  const senderOptions = () => {
    let options = [
      {
        firstName: 'The',
        lastName: 'Office',
        email: 'Office Email'
      }
    ]
    return options
  }

  const phoneNumbers = [
    '602 518-5996',
    '602 424-4473'
  ]

  const filteredContacts = []
  props.contacts.map(contact => {
    if (contact && contact.phoneNumbers && contact.phoneNumbers.length) {
      for (const num of contact.phoneNumbers) {
        if (num && num.type === 'Mobile') {
          filteredContacts.push({
            firstName: contact.firstName,
            lastName: contact.lastName,
            number: num.number
          })
        }
      }
    }
  })
  return (
    <Dialog maxWidth={'lg'} fullWidth open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
    <form onSubmit={handleSubmit}>
      <DialogTitle id="form-dialog-title">Send Text Message To Customer</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please Choose Who Should Receive This Message
        </DialogContentText>

        {/* <LabelText>Choose/Enter The Sender (Who Is Sending This Email?):</LabelText>
      <FormControl fullWidth >
      <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            fullWidth
            options={senderOptions}
            // onChange={handleUpdateUOM}
            onChange={handleContactChange}
            disableCloseOnSelect
            style={{marginTop: 25}}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.email}`}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                 {option.firstName} {option.lastName} - {option.email}
              </React.Fragment>
            )}
            
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Recipient List" placeholder="Who Should Receive A Copy of This Estimate?" />
            )}
          />  */}

      {/* </FormControl> */}
      <LabelText>Choose the Receipient(s):</LabelText>
      <FormControl fullWidth >
      <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            fullWidth
            options={filteredContacts}
            // onChange={handleUpdateUOM}
            onChange={handleContactChange}
            // disableCloseOnSelect
            style={{marginTop: 25}}
            getOptionLabel={(option) => `${option.firstName} ${option.lastName} - ${option.number}`}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                 {option.firstName} {option.lastName} - {option.number}
              </React.Fragment>
            )}
            
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Recipient List" placeholder="Who Should Receive A Copy of This Message?" />
            )}
          /> 

      </FormControl>

      { (recipientList.length) ? <FormControl fullWidth >

    
        <LabelText>Enter The Message You'd Like Them To Receive:</LabelText>
        

        <TextField
            autoFocus
            className={classes.paddedStuff}
            variant="outlined"
            id="Note"
            label="Message"
            multiline
            rows={4}
            type="text"
            onChange={(e) => setState({...state, message: e.target.value})}
            value={state.message}
            fullWidth
          /> 
      </FormControl> : null }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={!state.message}
        >
          Send
        </Button>
      </DialogActions>
    </form>
   </Dialog>
  )
}
const mapStateToProps = (state) => {
  // console.log('Map to Props Send SMS: ', state)
  return {
    url: state.url,
    notification: state.notification,
    user: state.user,
    contacts: state.contacts
  }
}

export default connect(mapStateToProps)(SendToCustomerModal)
