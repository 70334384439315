import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';

// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    // margin: '5px'
  }
}));

// function htmlToText(html){
//   // console.log('Strip html tags: ', html)
//   //remove code brakes and tabs
//   html = html.replace(/\n/g, "");
//   html = html.replace(/\t/g, "");

//   //keep html brakes and tabs
//   html = html.replace(/<table>/g, "");
//   html = html.replace(/<tr>/g, "");
//   html = html.replace(/<td>/g, "");
//   html = html.replace(/<\/td>/g, "\t");
//   html = html.replace(/<\/table>/g, "\n");
//   html = html.replace(/<\/tr>/g, "\n");
//   html = html.replace(/<\/p>/g, "\n");
//   html = html.replace(/<\/div>/g, "\n");
//   html = html.replace(/<div>/g, "");
//   html = html.replace(/<\/h>/g, "\n");
//   html = html.replace(/<br>/g, "\n"); 
//   html = html.replace(/<br( )*\/>/g, "\n");
//   // console.log('Stripped: ', html)
//   //parse html into text
//   // var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
//   // return dom.body.textContent;
//   return html;
// }

const EditNote = (props) => {
  // console.log('Edit Note: ', props)
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState({
    note: ''
  })
  React.useEffect(() => {
    // console.log('Use Effect Edit Note')
    // let jobName = 'Job - '
    // if (props.client.lastName) jobName = props.client.lastName + '- '
    // if (props.client.companyName) jobName = props.client.companyName + '- '
    setOpen(props.open)
    setState({...state, note: props.note.note})
  }, [props])

const handleClose = () => {
  // console.log('Closing New Note Modal')
  // setOpen(false)
  setState({
    note: ''
  })
  props.editNoteResponse('Close')
}

const handleConfirm = (data) => {
  // console.log('CONFIRMED NEW JOB!!', data)
}
const handleSave = () => {
  setLoading(true)
  // console.log('handle save', state)
  // console.log(props)
  let newNote = props.note
  newNote.note = state.note
  let obj = {
    client: props.client,
    note: newNote,
    user: props.user
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/customer/editNote`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Axios response to Edit Note: ', response)
    setLoading(false)
    // setState({})
    // setJobNumber(response.data)
    if (response.data._id) {
      props.editNoteResponse('updatedNote', response.data)
      setState({note: ''})
      props.notification({
        type: 'success',
        title: 'Your Note Was Updated And Saved!',
        message: 'Well Done!!'
      })
      // setOpen(false)
    } else {
      // console.log('No fucking workorder')
      props.notification({
        type: 'warning',
        title: 'Uh Oh!',
        message: 'Something messed up editing this note...please try again.'
      })
    }
    
  }).catch(err => {
    // console.log('Error saving workorder: ', err)
  })
}
return (
  <div className={classes.root}>
    <Dialog open={open} fullWidth={true} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Customer Note</DialogTitle>
        <DialogContent >
        <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
          <CloseIcon />
        </IconButton>
          <DialogContentText>
            Please Enter Your Notes Below
          </DialogContentText>
          <TextField
            autoFocus
            className={classes.paddedStuff}
            variant="outlined"
            id="Note"
            label="Customer Note"
            multiline
            rows={4}
            type="text"
            onChange={(e) => setState({...state, note: e.target.value})}
            value={state.note}
            fullWidth
          />

        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading || !state.note} onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(EditNote))