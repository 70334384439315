import React, { useState } from 'react';
import { connect } from 'react-redux'
import PaymentOptions from './PaymentMethodOptionsRadioButtons';
import PaymentForm from './PaymentForm';
import {
  TextField,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText
} from '@material-ui/core'
import { currencyFormat, currencyFormatWithOutFlair } from '../../services/common'
import { handleFocus } from '../Common/index';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';

const defaultStateVals = {
  paymentMethod: '',
  paymentAmount: '0',
  alertMessage: '',
  showAlert: false,
  showPaymentResponse: false,
  showPaymentAlert: false,
  paymentAlertMessage: '',
  alertSeverity: 'warning'
}
function PaymentManager(props) {
  const classes = useStyles()
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentAmount, setPaymentAmount] = React.useState('0')
  const [state, setState] = React.useState(defaultStateVals)
  const [showPaymentProcessingModal, setShowPaymentProcessingModal] = React.useState(false)

  const balance = (props && props.invoice && props.invoice.balance) ? props.invoice.balance : 0

  React.useEffect(() => {
    if (props.invoice && props.invoice._id) {
      setState({...state, paymentAmount: parseFloat(props.invoice.balance).toFixed(2) })
    }
  }, [props.invoice])

  const handleChangeAmountToPay = (e) => {
    // setState(e.target.value)
    setState({
      ...state,
      paymentAmount: e.target.value
    })
  }

  const validatePaymentAmount = () => {
    let newState = {
      showAlert: false,
      alertMessage: ''
    }
    if (Number(state.paymentAmount) > Number(balance)) {
      const message = `The invoice balance is only ${currencyFormat(balance)}.\n\n If you pay ${currencyFormat(state.paymentAmount)} it will result in an overpayment, which will be applied as a Deposit on your account. \nAre you sure you wish to do this?`
     
      props.notification({
        type: 'warning',
        title: 'Payment Amount Error',
        message: message
       })
      newState.alertMessage = message
      newState.showAlert = true
    }
    setState({...state, ...newState})
  }
  const handleConfirmOverPayment = () => {
    const message = `Proceeding with a payment of ${currencyFormat(state.paymentAmount)}`
     
    props.notification({
      type: 'success',
      title: 'Overpayment Confirmation',
      message: message
     })
     setState({...state, showAlert: false })
  }

  const handlePaymentDetailsUpdate = (type, data) => {
    // console.log('Time to go to the backend', type, data)
    // Payment Error
    let clientId = null
    if (props.invoice && props.invoice.client) clientId = props.invoice.client
    if (clientId && clientId._id) clientId = props.invoice.client._id
    setShowPaymentProcessingModal(true)
    const paymentDetails = {
      ...data,
      amount: state.paymentAmount
    }
    let obj = {
      client: clientId,
      data: paymentDetails,
      customerType: data.individual ? 'individual' : !data.isBusiness ? 'individual' : 'business', 
      invoice: props.invoice,
      overpayment: 0,
      type: type
    }
  // console.log(obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/payment/makePayment?online=true`,
      data: obj
    }).then(response => {
    // console.log('Respnse from makin payment', response)
    setShowPaymentProcessingModal(false)
      if (response && response.data && response.data.authNetResponse) {
        if (response.data.authNetResponse && response.data.authNetResponse.error) {
        
          handleErrorMakingPayment(response.data.authNetResponse.errorMessage)
        } else {
          if  (response.data.authNetResponse.responseCode && response.data.authNetResponse.responseCode === "1") {
            // console.log('Payment went through!!')
            handleSuccessMakingPayment(response.data.message)
          } else {
            // console.log('118', response.data.authNetResponse?.response?.transactionResponse)
            let msg = '118 Error Validation'
            let errors = response.data.authNetResponse?.response?.transactionResponse?.errors
            // console.log('ERors 121', errors)
            if (errors && errors.error) {
              // console.log('We have errors!!', errors.error)
              msg = ``
              errors.error.forEach(err => {
                msg += `${err.errorText}\n`
              })
            }
            // console.log('128', msg)
            handleErrorMakingPayment(msg)
          }
        
        }
      
      }
    })

    // setTimeout(() => {
    //   setShowPaymentProcessingModal(false)
    //   handleErrorMakingPayment()
    // }, 4000)
  }

  const handleErrorMakingPayment = (message) => {
    // const message = 'We had an error processing that payment'
    props.notification({
      type: 'error',
      title: 'Payment Error',
      message: message
     })
     setState({...state, alertSeverity: 'error', showPaymentAlert: true, paymentAlertMessage: message })
     props.updateParent('error', message)
    //  setTimeout(() => {
    //   handleSuccessMakingPayment()
    //  }, 9900)
  }

  const handleSuccessMakingPayment = (message) => {
    // const message = 'We have finished processing that payment'
    props.notification({
      type: 'success',
      title: 'Payment Processed',
      message: message
     })
     setSelectedPaymentMethod(null)
     setState({...state, alertSeverity: 'success', showPaymentAlert: true, paymentAlertMessage: message })
     props.updateParent('success', message, state.paymentAmount)
  }

  return (
    <div className={classes.container}>
      <PaymentProcessingModal open={showPaymentProcessingModal} />
      {
        state.showAlert ? (
          <Alert
            action={
              <Button color="inherit" size="small" variant="outlined" onClick={handleConfirmOverPayment}>
                CONFIRM
              </Button>
            }
            variant="outlined"
            severity="warning"
          >
            {state.alertMessage}
          </Alert> 
        ) : null
      }
     
      <div className={classes.containerItem}>
        <div className={classes.paymentMethodsHeader}>
          Payment Amount
        </div>
        <div>
          <TextField
            variant='outlined'
            fullWidth
            type="number"
            onSelect={handleFocus}
            value={state.paymentAmount}
            onChange={handleChangeAmountToPay}
            onBlur={validatePaymentAmount}
            helperText={ balance > 0 ? `How Much of the ${currencyFormat(balance)} Do You Wish To Pay?`: 'This Invoice Has Already Been Paid'}
            inputProps={{min: 0, style: { textAlign: 'center' }}}
            />
        </div>
      </div>
      <div className={classes.containerItem}>
        <div className={classes.paymentMethodsHeader}>Payment Methods</div>
        <PaymentOptions setSelectedOption={setSelectedPaymentMethod} selectedOption={selectedPaymentMethod} />
        
      </div>
      <div className={classes.containerItem}>
        <PaymentForm selectedPaymentMethod={selectedPaymentMethod} updateParent={handlePaymentDetailsUpdate} />
      </div>
      {
        state.showPaymentAlert ? (
          <Alert
          variant="outlined"
          severity={state.alertSeverity}
        >
          {state.paymentAlertMessage}
        </Alert> 
        ) : null
      }
    </div>
  );
}

const PaymentProcessingModal = ({ open }) => {
  const classes = useStyles()
  return (
    
    <Dialog  open={open} fullWidth={true} maxWidth='sm' aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Processing Payment</DialogTitle>
        <DialogContent >
          <div className={classes.modalStyle}>

            <DialogContentText>
              Please Be Patient While We Process Your Payment
            </DialogContentText>
            <div className={classes.centered}>
                <CircularProgress size={64} />
            </div>
            
            <DialogContentText>
              Please Don't Close This or Refresh
            </DialogContentText>
          </div>
          
        </DialogContent>
    </Dialog>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    margin: 10,
    padding: 15,
  },
  containerItem: {
    margin: 5,
    marginBottom: 15
  },
  paymentMethodsHeader: {
    fontSize: '1.2em',
    fontWeight: '600',
    color: '#aaa',
    textAlign: 'center',
  },
  modalStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'stretch',
    textAlign: 'center'
  },
  centered: {
    margin: 'auto'
  }
}))

const mapStateToProps = (state) => {
  // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
    authenticated: state.authenticated
  }
}

export default connect(mapStateToProps)(PaymentManager)
