import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, TextField, InputAdornment, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions }  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { socket } from '../SocketIo/Socket'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl, FormLabel } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
// import UploadDetailsModal from './UploadDetailsModal'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import ProtectedButton from '../../actions/protectedButtons';
import { desc, stableSort, getSorting } from '../../services/common'
import PostAddIcon from '@material-ui/icons/PostAdd';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';
import { TrainRounded } from '@material-ui/icons';
import DeleteComponent from '../Common/DeleteComponent'
import DoneAllIcon from '@material-ui/icons/DoneAll';

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'clientName', numeric: false, disablePadding: false, label: 'Client Name' },
  { id: 'projectName', numeric: false, disablePadding: false, label: 'Project Name' },
  // { id: 'details', numeric: false, disablePadding: false, label: 'Task Details' },
  // { id: 'assigned_to', numeric: false, disablePadding: false, label: 'Assigned To' },
  // { id: 'created_by', numeric: false, disablePadding: false, label: 'Assigned By' },
  // { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'notes', numeric: false, disablePadding: false, label: 'Notes' },
  { id: 'last_touched_at', numeric: false, disablePadding: false, label: 'Last Touch', isDate: true },
  { id: 'next_touch_at', numeric: false, disablePadding: false, label: 'Next Touch', isDate: true },
  // { id: 'completed_at', numeric: false, disablePadding: false, label: 'Completed' },
  // { id: 'ontime', numeric: false, disablePadding: false, label: 'On-Time' },
  { id: 'action', numeric: false, disablePadding: false, label: '' }
];

function EnhancedTableHead(props) {
  // console.log('Got our props', props)
  
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    // console.log('Sort Property: ', property)
    // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function EnhancedTable(props) {
  // console.log('QUEUE', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [rows, setRows] = React.useState([])
  const [filteredRows, setFilteredRows] = React.useState([])
  const [activeItem, setActiveItem] = React.useState({})
  const [state, setState] = React.useState({
    searchMatches: 0,
    project: {},
    showCompleted: true
  })
  const [showUploadDetails, setShowUploadDetails] = React.useState(false)
  const [isEditOpen, setIsEditOpen] = React.useState(false)

  React.useEffect(() => {
    let isMounted = true

    const fetchUserQueueItems = async () => {
      // setLoading(true)
      axios({
        method: 'get',
        url: `${props.url}/api/userqueues/list`
      }).then(response => {
        // console.log('Got our clients for User Queues; ', response.data)
        // setLoading(false)
        if (isMounted) {
          setRows(response.data)
          // setState({ ...state, next_touch_at: fortyEightHours })
          setFilteredRows(response.data)
        }
      })
    }

    fetchUserQueueItems()

    return () => {
      isMounted = false
    }
  }, [])

  const handleRequestSort = (event, property) => {
  // console.log(event.target)
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
  // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    // console.l
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, task) => {
    // console.log('Double Click')
    setActiveItem(task)
    setOpen(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r, searchText) => {
    // console.log('FIlter out: %s - %s', r, searchText)
    let string = searchText
    let newArr = []
    r.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
    })
    setFilteredRows(newArr)

  }
  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  const handleOpenEditModal = (row) => {
    setActiveItem(row)
    setIsEditOpen(true)
  }

  const handleCloseModal = () => {
    setIsEditOpen(false)
    setActiveItem({})
  }

  const handleDeleteItem = (itemToDelete) => {
    let  list = rows
    let index = list.findIndex(item => (item._id === itemToDelete._id))
    if (index > -1) {
      list.splice(index, 1)
    }
    setRows(list)
    filterOut(list, searchValue)
  }

  const handleAddItem = (newItem) => {
    let list = rows
    list.unshift(newItem)
    setRows(list)
    filterOut(list, searchValue)
  }

  const handleItemAfterUpdate = (resp) => {
  // console.log('Item Updated...', resp)
    let list = rows
    let index = list.findIndex(item => (item._id === resp.itemToEdit._id))
    if (index > -1) {
      list[index] = resp.updateResponse
      setRows(list)
      filterOut(list, searchValue)
    }
  }

  const handleCompleteItem = (completedItem) => {
  // console.log('Complete this:', completedItem)
    axios({
      method: 'post',
      url: `${props.url}/api/userqueues/markItemComplete`,
      data: completedItem
    }).then(response => {
      // handleDeleteItem(item)
      if (response.data) {
      // console.log('Item deleted', response.data)
        let  list = rows
        let index = list.findIndex(item => (item._id === completedItem._id))
        if (index > -1) {
          list.splice(index, 1)
        }
        setRows(list)
        filterOut(list, searchValue)
      }
    })
  }

  return (
    <div className={classes.root}>
      <EditItemModal open={isEditOpen} url={props.url} item={activeItem} closeModal={handleCloseModal} clients={props.clients} projects={props.projects} updateItem={handleItemAfterUpdate} />
      <Grid container style={{padding: '10px'}}>
        <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <AddButton {...props} handleAddItem={handleAddItem}/>
        </Grid>
      </Grid>
        Total Items: {rows.length} { searchValue ? filteredRows.length > 1 ? `(${filteredRows.length} Matches)` : `(${filteredRows.length} Match)` : ''}
      <Grid container style={{padding: '10px'}}>
            
          <Grid item lg={6} xs={6}>

                <TextField
                    name="search" 
                    value={ searchValue } 
                    onChange={ handleChange('search') } 
                    type="text"
                    label="Search"
                    type="search"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      )
                    }}
                />
          </Grid>
        </Grid>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            options={{
              rowStyle: {height: 55}
            }}
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody >
              { stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log('Row map: ', row)
                  // console.log('Total rows; ', rows)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                    
                      <TableRow
                        hover
                        onDoubleClick={event => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id || index}
                        className={ row.status === 'Complete' ? classes.rowPadCOMPLETE : classes.rowPad }
                        selected={isItemSelected}
                      >
                      

                        <TableCell align="left">
                          
                          { row.name }
                        </TableCell>

                        <TableCell align="left">
                       
                        <Link 
                            style={{pointer: 'cursor'}}
                            target="_blank"
                            to={{
                                  pathname: '/clients/' + row.client,
                                  // search: '?sort=name',
                                  // hash: '#the-hash',
                                  // state: { client: row }
                                }}>  { row.clientName }
                                
                          </Link>
                        </TableCell>
                        <TableCell>
                         
                          <Link 
                            style={{pointer: 'cursor'}}
                            target="_blank"
                            to={{
                                  pathname: '/projects/' + row.project,
                                  // search: '?sort=name',
                                  // hash: '#the-hash',
                                  // state: { project: row.project }
                                }}>   { row.projectName }
                                
                          </Link>
                        </TableCell>
                        <TableCell>
                          {row.notes}
                        </TableCell>
                        <TableCell align="left"> {row.last_touched_at ?  moment(row.last_touched_at).format('MM/DD/YYYY hh:mm a') : ''}</TableCell>
                        <TableCell align="left"> {row.next_touch_at ?  moment(row.next_touch_at).format('MM/DD/YYYY hh:mm a') : ''} </TableCell>
                        <TableCell align="right"> 
                          <ActionRow row={row} {...props} editThisRow={handleOpenEditModal} deleteItem={handleDeleteItem} completeItem={handleCompleteItem} />
                        </TableCell>
                      </TableRow>
                    );
 
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          { loading ?<CircularProgress style={{color: '#fff'}} size={ 16 } /> : ''}
                    
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100, 500]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          // onChangePage={handleChangePage}
          onPageChange={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
    </div>
  )
}

const ActionRow = ({ row, perms, editThisRow, deleteItem, completeItem }) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = React.useState(false)

  const handleEdit = () => {
  // console.log('Edit this one:', row)
    // setIsOpen(true)
    editThisRow(row)
  }

  const handleCloseModal = () => {
    setIsOpen(false)
  }

  const handleDeleteItem = (resp) => {
  // console.log('Delete it:', resp)
    deleteItem(resp)
  }

  const handleComplete = () => {
    // console.log('Mark complete', item)
    completeItem(row)
  }

  return (
    <div className={classes.ActionRow}>
      
      <Tooltip title="Edit">
          <IconButton  edge='end' aria-label="edit" onClick={ handleEdit }>
          <EditIcon id={row._id}  />
        </IconButton>
      </Tooltip>
      <Tooltip title="Complete">

        <IconButton  edge='end' aria-label="edit" onClick={ handleComplete }>
          <DoneAllIcon id={row._id}  />
        </IconButton>
      </Tooltip>
      <DeleteComponent
        type="Tasks"
        item={row}
        title="Delete Item?"
        verificationText={`Are you sure you wish to Delete this item?`}
        confirmationOfIntentText="This action is irreversible, this item will be gone forever!"
        confirmationButton="DELETE"
        denyText="NO"
        color="secondary"
        ifApproved={handleDeleteItem}
        deleteFunction={'userQueueItem'}
      />
    </div>
  )
}

const fortyEightHours = new Date(new Date().getTime() - 0 + (42 * 60 * 60 * 1000)).toISOString().slice(0, -8)
const initialStateValues = {
  client: null,
  project: null,
  name: '',
  notes: '',
  next_touch_at: fortyEightHours
}

const initialEditStateValues = {
  client: null,
  project: null,
  name: '',
  notes: '',
  next_touch_at: fortyEightHours
}
const EditItemModal = (props) => {
// console.log('EDIT ITEM MODAL', props)
  const classes = useStyles()
  const [state, setState] = React.useState(initialEditStateValues)
  const [client, setClient] = React.useState({})
  // const [clients, setClients] = React.useState([])
  const [associatedProject, setAssociatedProject] = React.useState(null)
  const [projectList, setProjectList] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const clients = props.clients
// console.log('Clients lenght...', clients.length)
  // React.useEffect(() => {
  //   let isMounted = true
  //   const fetchClients = async () => {
  //     // setLoading(true)
  //     const urlToFetch =  `${props.url}/api/customer/clients`
  //     // console.log('URL to ftech clients:', urlToFetch)
  //     axios({
  //       method: 'get',
  //       url: urlToFetch
  //     }).then(response => {
  //       // console.log('Got our clients for new estimate; ', response.data)
  //       // setLoading(false)
  //       if (response && response.data && isMounted) {
  //         setClients(response.data)
  //         // setFilteredRows(response.data)
  //       } 
  //     })
  //   }
  //   fetchClients()
  //   return () => {
  //     isMounted = false
  //   }
  // }, [])

  React.useEffect(() => {
    // Set OUR SHIT
    // console.log('Set it', props.item)
    let newState = {...props.item}
    let nextTouchDate = new Date(moment(props.item.next_touch_at)).toISOString().slice(0, -8)
    if (props.item && props.item.client) {
      let index = props.clients.findIndex(item => (item._id === props.item.client))
      if (index > -1) {
        setClient(props.clients[index])
        newState.client = props.clients[index]._id
        let projects = props.projects.filter(item => (item.client && item.client._id === props.clients[index]._id))
        setProjectList(projects)
        if (props.item && props.item.project) {
          let index = props.projects.findIndex(item => (item._id === props.item.project))
          if (index > -1) {
            setAssociatedProject(props.projects[index])
            newState.project = props.projects[index]._id
          }
        }
      // console.log('Update Our state...', newState)
        // setState({...state, ...newState})
      }
    }
    newState.next_touch_at = nextTouchDate // nextTouch
  // console.log('Set it:', newState)
    setState({...state, ...newState})
  }, [props.item])

  const handleCloseModal = () => {
    setClient({})
    setProjectList([])
    setAssociatedProject({})
    setState(initialStateValues)
    props.closeModal()
  }

  const updateClient = (e, newClient) => {
    if (newClient && newClient._id) {
      getJobSites(newClient)
      setClient(newClient)
      setState({...state, client: newClient._id })
    } else {
      setClient({})
      // setJobSites([])
    }
    setAssociatedProject({})
  }

  const getJobSites = async (customer) => {
    setLoading(true)
      axios({
    method: 'get',
    url: `${props.url}/api/customer/getAllJobSites?id=${customer._id}`
  }).then(response => {
    // console.log('Got our job sites; ', response.data)
    setLoading(false)
    // setJobSites(response.data.jobSites)
    if (response.data.projects && response.data.projects.length) {
      setProjectList(response.data.projects)
    } else {
      setProjectList([])
    }
  })

}

const formatOptionForProject = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.name) {
    return `${opt.name}`
  } else {
    return ''
  }
}


  const updateProject = (e, proj) => {
    // console.log('Prev associated: ', associatedProject)
    // console.log('state.associated', state)
    if (proj && proj._id) {
      // console.log('NEW PROJECT', proj)
      // setAssociatedProject(proj)?
      setAssociatedProject(proj)
      setState({...state, project: proj._id })
      // setState({...state})
    } else {
      // console.log(proj)
      setAssociatedProject(null)
      setState({...state, project: null })
    }
  }

  const handleUpdate = () => {
    setLoading(true)
    const obj = {
      data: state,
      client: client,
      project: associatedProject,
      itemToEdit: props.item
    }
    axios({
      method: 'post',
      url: `${props.url}/api/userqueues/updateQueueItem`,
      data: obj
    }).then(response => {
      // console.log('Saved Our Item ', response.data)
      if (response.data && response.data._id) {
        obj.updateResponse = response.data
        props.updateItem(obj)
      }
      setLoading(false)
      handleCloseModal()
  })
  }

  // console.log('STATE', state)
  return (
    <Dialog
      open={props.open}
      size={'md'}
      onClose={handleCloseModal}
      >
        <DialogTitle>
          Edit Item
        </DialogTitle>
        <DialogContent>
            <DialogContentText>Please Update Details For This Queue Item</DialogContentText>
            <TextField
              label="Name"
              variant="outlined"
              type="text"
              spellCheck={true}
              style={{marginBottom: 15 }}
              value={state.name}
              className={classes.paddedStuff}
              onChange={e => setState({...state, name: e.target.value})}
              fullWidth
            />
            <Autocomplete
            id="Client"
            options={clients || []}
            getOptionLabel={(option) => {
              // console.log('options: ', option)
              return option.displayName ? `${option.displayName} (${option.agId})` : ''
            }}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            // style={{marginBottom: 10, marginTop: 15}}
            onChange={updateClient}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {client}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Customer" helperText={`Is this for a Customer? If not, leave it blank`} />}
          />
           <Autocomplete
                id="Associated Project"
                options={projectList || []}
                getOptionLabel={(option) => (formatOptionForProject(option) || '')}
                // style={{ width: 200 }}
                className={classes.paddedStuff}
                // style={{marginBottom: 10, marginTop: 15}}
                onChange={updateProject}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {associatedProject || ''}
                // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} variant="outlined" label="Project" helperText={`Is this associated with a Project? If not, leave it blank`} />}
              />
              
            <TextField
                type="datetime-local"
                variant="outlined"
                label="Next Touch By"
                onChange={ e => setState({...state, next_touch_at: e.target.value })}
                name="datetime-local"
                value={ state.next_touch_at }
                fullWidth
                // defaultValue={tomorrow}
                className={classes.paddedStuff}
                // onBlur={updateParent}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={`When Would You Like The Next Touch To Be?`}
              />
             
              <TextField
              label="Details/Notes"
              variant="outlined"
              type="text"
              spellCheck={true}
              multiline
              rows={6}
              style={{marginBottom: 15 }}
              value={state.notes}
              className={classes.paddedStuff}
              onChange={e => setState({...state, notes: e.target.value})}
              fullWidth
            />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleCloseModal}>CANCEL</Button>
          <Button disabled={!client || !client._id || !state.name} variant="contained" color="primary" onClick={handleUpdate}>UPDATE</Button>
        </DialogActions>
      </Dialog>
  )
}



const AddButton = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <>
      <AddNewItemModal {...props} open={isOpen} closeModal={handleClose} addNewItem={props.handleAddItem} clients={props.clients} />
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        startIcon={<PostAddIcon size={16} />}
        >Add New Item</Button>
    </>


  )
}



// const tomorrow = new Date(new Date().getTime() - 0 + (24 * 60 * 60 * 1000)).toISOString().slice(0, -8)

const AddNewItemModal = (props) => {
  const classes = useStyles()
  const [state, setState] = React.useState(initialStateValues)
  const [client, setClient] = React.useState({})
  // const [clients, setClients] = React.useState([])
  const [associatedProject, setAssociatedProject] = React.useState(null)
  const [projectList, setProjectList] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  

  const clients = props.clients

  // React.useEffect(() => {
  //   let isMounted = true
  //   const fetchClients = async () => {
  //     // setLoading(true)
  //     axios({
  //       method: 'get',
  //       url: `${props.url}/api/customer/clients`
  //     }).then(response => {
  //       // console.log('Got our clients for new estimate; ', response.data)
  //       // setLoading(false)
  //       if (isMounted) {
  //         setClients(response.data)
  //         setState({ ...state, next_touch_at: fortyEightHours })
  //         // setFilteredRows(response.data)
  //       }
  //     })
  //   }
  //   fetchClients()
  //   return () => {
  //     isMounted = false
  //   }
  // }, [])

  const handleCloseModal = () => {
    setClient({})
    setAssociatedProject({})
    setProjectList([])
    setState(initialStateValues)
    props.closeModal()
  }

  const updateClient = (e, newClient) => {
    if (newClient && newClient._id) {
      setAssociatedProject({})
      getJobSites(newClient)
      setClient(newClient)
    } else {
      setClient({})
      setAssociatedProject({})
      setProjectList([])
      // setJobSites([])
    }
  }

  const getJobSites = async (customer) => {
    setLoading(true)
      axios({
    method: 'get',
    url: `${props.url}/api/customer/getAllJobSites?id=${customer._id}`
  }).then(response => {
    // console.log('Got our job sites; ', response.data)
    setLoading(false)
    // setJobSites(response.data.jobSites)
    setProjectList(response.data.projects)
  })

}

const formatOptionForProject = (opt) => {
  // console.log('Format this: ', opt)
  if (opt && opt.name) {
    return `${opt.name}`
  } else {
    return ''
  }
}


  const updateProject = (e, proj) => {
    // console.log('Prev associated: ', associatedProject)
    // console.log('state.associated', state)
    if (proj && proj._id === 'new') {
      // console.log('NEW PROJECT', proj)
      setAssociatedProject(proj)
      setState({...state, associatedProject: proj })
    } else {
      // console.log(proj)
      setAssociatedProject(proj)
      setState({...state, associatedProject: proj })
    }
  }

  const handleSave = () => {
    setLoading(true)
    const obj = {
      data: state,
      client: client,
      project: associatedProject
    }
    axios({
      method: 'post',
      url: `${props.url}/api/userqueues/addQueueItem`,
      data: obj
    }).then(response => {
    // console.log('Saved Our Item ', response.data)
      if (response.data && response.data._id) {
        props.addNewItem(response.data)
      }
      setLoading(false)
      handleCloseModal()
  })
}
  // const isDisabled = () => {
  //   if (!client || !client._id || !state.name) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }
  // console.log('Is Disabled:', isDisabled)
  return (
    <Dialog
      open={props.open}
      size={'md'}
      onClose={handleCloseModal}
      >
        <DialogTitle>
          <h3>Add New Queue Item</h3>
        </DialogTitle>
        <DialogContent>
            <DialogContentText>Please Input Details For This New Queue Item</DialogContentText>
            <TextField
              label="Name"
              variant="outlined"
              type="text"
              spellCheck={true}
              style={{marginBottom: 15 }}
              value={state.name}
              className={classes.paddedStuff}
              onChange={e => setState({...state, name: e.target.value})}
              fullWidth
            />
            <Autocomplete
            id="Client"
            options={clients || []}
            getOptionLabel={(option) => {
              // console.log('options: ', option)
              return option.displayName ? `${option.displayName} (${option.agId})` : ''
            }}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            // style={{marginBottom: 10, marginTop: 15}}
            onChange={updateClient}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {client}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label="Customer" helperText={`Is this for a Customer? If not, leave it blank`} />}
          />
           <Autocomplete
                id="Associated Project"
                options={projectList}
                getOptionLabel={(option) => (formatOptionForProject(option) || '')}
                // style={{ width: 200 }}
                className={classes.paddedStuff}
                // style={{marginBottom: 10, marginTop: 15}}
                onChange={updateProject}
                // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                value = {associatedProject || ''}
                // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                renderInput={(params) => <TextField {...params} variant="outlined" label="Project" helperText={`Is this associated with a Project? If not, leave it blank`} />}
              />
              
            <TextField
                type="datetime-local"
                variant="outlined"
                label="Next Touch By"
                onChange={ e => setState({...state, next_touch_at: e.target.value })}
                name="datetime-local"
                value={ state.next_touch_at }
                fullWidth
                // defaultValue={fortyEightHours}
                className={classes.paddedStuff}
                // onBlur={updateParent}
                InputLabelProps={{
                  shrink: true,
                }}
                helperText={`When Would You Like The Next Touch To Be?`}
              />
              <TextField
              label="Details/Notes"
              variant="outlined"
              type="text"
              spellCheck={true}
              multiline
              rows={6}
              style={{marginBottom: 15 }}
              value={state.notes}
              className={classes.paddedStuff}
              onChange={e => setState({...state, notes: e.target.value})}
              fullWidth
            />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={handleCloseModal}>CANCEL</Button>
          <Button disabled={!state.name || !state.next_touch_at} variant="contained" color="primary" onClick={handleSave}>SAVE</Button>
        </DialogActions>
      </Dialog>
  )
}
// const theList = [
//   {
//     name: 'Source Pool Tile',
//     clientName: 'GM Hunt',
//     projectName: 'IVR39',
//     notes: 'Waiting on Call Back from Jordan @ Solstice',
//     lastTouch: new Date(),
//     nextTouch: new Date()
//   },
// ]
// const json = require('./mockData.json')
// console.log('JSON...', json)

const ListOfItems = (props) => {
  const classes = useStyles()
  // React.useEffect(() => {

    // console.log('JSON: ', json)
    // let ourList = List
    // console.log('List ling:', ourList.length)
    // ourList = ourList.concat(theList)
    // console.log('List Length: ', ourList.length)
  // }, [])
  return (
    <div>
      <div className={classes.largeLabel}>
        My Queue
      </div>
      <EnhancedTable {...props} />
    </div>
  )
}
  const mapStateToProps = (state) => {
    return {
      authenticated: state.authenticated,
      notification: state.notification,
      url: state.url,
      perms: state.perms
    }
  }
  
  export default connect(mapStateToProps)(ListOfItems)

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%',
    margin: 10
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  },
  rowPad: {
    height: '40px !important',
    padding: '2px'
  },
  largeLabel: {
    fontSize: '1.6em',
    color: '#aaa',
    margin: 10
  },
  paddedStuff: {
    paddingBottom: 15
  },
  ActionRow: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-between'
  }
}));

  const List = [{"name":"nibh in quis justo maecenas","clientName":"Gigaclub","projectName":"quisque","notes":"Vestibulum ac est lacinia nisi venenatis tristique. Fusce congue, diam id ornare imperdiet, sapien urna pretium nisl, ut volutpat sapien arcu sed augue.","last_touched_at":"2023-05-03T16:07:08Z","next_touch_at":"2023-08-16T08:32:54Z"},
  {"name":"purus aliquet at","clientName":"Quatz","projectName":"vulputate justo","notes":"Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris viverra diam vitae quam. Suspendisse potenti. Nullam porttitor lacus at turpis.","last_touched_at":"2023-06-18T03:01:24Z","next_touch_at":"2023-04-27T11:23:42Z"},
  {"name":"luctus nec","clientName":"Twitterwire","projectName":"tortor risus dapibus","notes":"Mauris enim leo, rhoncus sed, vestibulum sit amet, cursus id, turpis. Integer aliquet, massa id lobortis convallis, tortor risus dapibus augue, vel accumsan tellus nisi eu orci. Mauris lacinia sapien quis libero.","last_touched_at":"2022-07-07T00:30:48Z","next_touch_at":"2023-06-17T06:10:17Z"},
  {"name":"neque duis bibendum morbi","clientName":"Oyoloo","projectName":"quis turpis sed","notes":"Pellentesque eget nunc.","last_touched_at":"2022-07-01T00:01:35Z","next_touch_at":"2023-05-12T14:02:52Z"},
  {"name":"platea dictumst aliquam","clientName":"Browsecat","projectName":"nunc rhoncus dui","notes":"Nunc rhoncus dui vel sem. Sed sagittis.","last_touched_at":"2023-03-26T18:27:04Z","next_touch_at":"2023-07-02T04:18:16Z"},
  {"name":"quis turpis eget elit sodales","clientName":"Skimia","projectName":"lacus at velit","notes":"Donec ut dolor.","last_touched_at":"2023-04-09T05:24:47Z","next_touch_at":"2023-04-04T00:09:42Z"},
  {"name":"felis sed","clientName":"Flashset","projectName":"velit","notes":"Pellentesque at nulla. Suspendisse potenti. Cras in purus eu magna vulputate luctus.","last_touched_at":"2022-06-08T19:25:36Z","next_touch_at":"2023-05-23T03:52:57Z"},
  {"name":"ante vivamus","clientName":"Einti","projectName":"quam pharetra magna","notes":"Phasellus sit amet erat. Nulla tempus.","last_touched_at":"2023-01-31T14:15:33Z","next_touch_at":"2023-07-26T11:22:12Z"},
  {"name":"amet sem fusce consequat","clientName":"Zoomlounge","projectName":"tortor eu","notes":"Integer tincidunt ante vel ipsum. Praesent blandit lacinia erat. Vestibulum sed magna at nunc commodo placerat.","last_touched_at":"2022-11-04T03:31:33Z","next_touch_at":"2022-09-04T18:58:21Z"},
  {"name":"nisi eu orci","clientName":"Snaptags","projectName":"dapibus at","notes":"Etiam vel augue. Vestibulum rutrum rutrum neque. Aenean auctor gravida sem.","last_touched_at":"2023-03-27T22:13:59Z","next_touch_at":"2023-07-28T03:17:19Z"},
  {"name":"integer tincidunt","clientName":"Youspan","projectName":"rutrum rutrum","notes":"Morbi quis tortor id nulla ultrices aliquet. Maecenas leo odio, condimentum id, luctus nec, molestie sed, justo. Pellentesque viverra pede ac diam.","last_touched_at":"2023-01-09T17:27:13Z","next_touch_at":"2022-11-01T16:51:26Z"},
  {"name":"ipsum praesent blandit","clientName":"Feedfire","projectName":"massa id lobortis","notes":"Suspendisse potenti. Cras in purus eu magna vulputate luctus.","last_touched_at":"2022-05-30T17:42:31Z","next_touch_at":"2022-12-21T08:06:19Z"},
  {"name":"donec dapibus","clientName":"Katz","projectName":"ut blandit","notes":"Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non, pretium quis, lectus. Suspendisse potenti.","last_touched_at":"2023-04-08T12:00:45Z","next_touch_at":null},
  {"name":"nisi venenatis","clientName":"Twitterworks","projectName":"at diam nam","notes":"Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec pharetra, magna vestibulum aliquet ultrices, erat tortor sollicitudin mi, sit amet lobortis sapien sapien non mi. Integer ac neque.","last_touched_at":"2022-05-13T13:46:47Z","next_touch_at":"2023-05-19T22:42:44Z"},
  {"name":"cras mi pede malesuada in","clientName":"Jetpulse","projectName":"magna at nunc","notes":"Praesent lectus. Vestibulum quam sapien, varius ut, blandit non, interdum in, ante. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Duis faucibus accumsan odio.","last_touched_at":"2022-12-11T20:45:13Z","next_touch_at":"2022-10-15T06:24:17Z"},
  {"name":"habitasse platea","clientName":"Kayveo","projectName":"rutrum","notes":"Integer pede justo, lacinia eget, tincidunt eget, tempus vel, pede. Morbi porttitor lorem id ligula.","last_touched_at":"2022-12-09T16:26:21Z","next_touch_at":"2023-06-08T08:29:25Z"},
  {"name":"hac habitasse platea dictumst","clientName":"Zazio","projectName":"at","notes":"In blandit ultrices enim. Lorem ipsum dolor sit amet, consectetuer adipiscing elit.","last_touched_at":"2022-04-17T20:46:03Z","next_touch_at":"2023-02-13T03:12:26Z"},
  {"name":"adipiscing lorem","clientName":"Skivee","projectName":"sit","notes":"Cras mi pede, malesuada in, imperdiet et, commodo vulputate, justo. In blandit ultrices enim.","last_touched_at":"2023-03-01T09:08:29Z","next_touch_at":"2023-01-01T03:01:45Z"},
  {"name":"sed vel enim sit amet","clientName":"Flipopia","projectName":"quam nec dui","notes":"Vivamus metus arcu, adipiscing molestie, hendrerit at, vulputate vitae, nisl. Aenean lectus.","last_touched_at":"2023-06-16T00:27:53Z","next_touch_at":"2023-04-06T11:50:56Z"},
  {"name":"pede ullamcorper augue a","clientName":"Rhynyx","projectName":"diam in magna","notes":"Aenean lectus. Pellentesque eget nunc. Donec quis orci eget orci vehicula condimentum.","last_touched_at":"2023-03-06T12:32:30Z","next_touch_at":"2022-06-11T04:04:26Z"},
  {"name":"suspendisse ornare consequat lectus","clientName":"Linkbuzz","projectName":"turpis integer aliquet","notes":"In hac habitasse platea dictumst. Aliquam augue quam, sollicitudin vitae, consectetuer eget, rutrum at, lorem.","last_touched_at":"2023-06-07T12:58:21Z","next_touch_at":"2022-09-11T04:32:29Z"},
  {"name":"ac consequat metus sapien","clientName":"Eazzy","projectName":"parturient montes","notes":"Vestibulum ac est lacinia nisi venenatis tristique. Fusce congue, diam id ornare imperdiet, sapien urna pretium nisl, ut volutpat sapien arcu sed augue.","last_touched_at":"2022-04-06T04:50:32Z","next_touch_at":"2023-02-22T09:56:16Z"},
  {"name":"semper rutrum nulla nunc purus","clientName":"Zoozzy","projectName":"ac tellus semper","notes":"Vestibulum ac est lacinia nisi venenatis tristique. Fusce congue, diam id ornare imperdiet, sapien urna pretium nisl, ut volutpat sapien arcu sed augue. Aliquam erat volutpat.","last_touched_at":"2023-01-31T04:29:43Z","next_touch_at":"2022-12-25T23:14:29Z"},
  {"name":"non velit nec nisi vulputate","clientName":"Realblab","projectName":"amet diam","notes":"Proin interdum mauris non ligula pellentesque ultrices.","last_touched_at":"2023-03-14T07:12:57Z","next_touch_at":"2022-09-26T18:12:30Z"},
  {"name":"luctus cum sociis natoque","clientName":"Roodel","projectName":"elit proin interdum","notes":"Sed vel enim sit amet nunc viverra dapibus. Nulla suscipit ligula in lacus.","last_touched_at":"2023-02-11T21:16:45Z","next_touch_at":"2022-12-11T16:16:19Z"},
  {"name":"aliquam augue","clientName":"Aivee","projectName":"fermentum donec","notes":"Nulla neque libero, convallis eget, eleifend luctus, ultricies eu, nibh. Quisque id justo sit amet sapien dignissim vestibulum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nulla dapibus dolor vel est.","last_touched_at":"2022-10-13T07:36:40Z","next_touch_at":null},
  {"name":"quisque id","clientName":"InnoZ","projectName":"ipsum","notes":"Aenean lectus. Pellentesque eget nunc. Donec quis orci eget orci vehicula condimentum.","last_touched_at":"2022-12-17T18:45:52Z","next_touch_at":"2023-07-17T22:51:28Z"},
  {"name":"erat id mauris vulputate","clientName":"Yodel","projectName":"nunc proin","notes":"Morbi porttitor lorem id ligula.","last_touched_at":"2022-05-29T03:06:05Z","next_touch_at":"2023-07-27T02:53:25Z"},
  {"name":"lectus suspendisse potenti in eleifend","clientName":"Livefish","projectName":"ultrices","notes":"Fusce consequat. Nulla nisl.","last_touched_at":"2022-12-25T19:45:50Z","next_touch_at":"2023-04-01T15:51:26Z"},
  {"name":"vehicula condimentum curabitur","clientName":"Latz","projectName":"eget semper rutrum","notes":"Integer ac neque.","last_touched_at":"2022-11-20T19:40:28Z","next_touch_at":"2022-10-15T04:50:44Z"},
  {"name":"tellus nulla","clientName":"Skippad","projectName":"lobortis est phasellus","notes":"Phasellus sit amet erat.","last_touched_at":"2022-03-14T13:11:13Z","next_touch_at":"2023-03-08T00:13:02Z"},
  {"name":"in hac habitasse platea","clientName":"Ozu","projectName":"morbi","notes":"Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec pharetra, magna vestibulum aliquet ultrices, erat tortor sollicitudin mi, sit amet lobortis sapien sapien non mi. Integer ac neque.","last_touched_at":"2023-06-11T14:30:09Z","next_touch_at":"2022-10-23T06:16:31Z"},
  {"name":"ultrices mattis odio donec","clientName":"Riffwire","projectName":"tortor eu","notes":"Praesent lectus. Vestibulum quam sapien, varius ut, blandit non, interdum in, ante.","last_touched_at":"2023-04-01T03:24:26Z","next_touch_at":"2023-05-25T16:22:36Z"},
  {"name":"vestibulum sit","clientName":"Yakijo","projectName":"sagittis","notes":"Cras in purus eu magna vulputate luctus. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus vestibulum sagittis sapien.","last_touched_at":"2023-03-15T18:57:31Z","next_touch_at":"2023-04-08T08:18:20Z"},
  {"name":"quis lectus suspendisse potenti","clientName":"Zoonder","projectName":"donec ut","notes":"In sagittis dui vel nisl.","last_touched_at":"2022-09-08T07:15:20Z","next_touch_at":"2022-12-29T16:06:21Z"},
  {"name":"sed justo pellentesque viverra","clientName":"Jaxworks","projectName":"eu massa donec","notes":"Aenean sit amet justo.","last_touched_at":"2022-07-06T05:04:51Z","next_touch_at":"2022-11-11T15:37:01Z"},
  {"name":"mattis odio","clientName":"Voolia","projectName":"vel augue vestibulum","notes":"Maecenas tristique, est et tempus semper, est quam pharetra magna, ac consequat metus sapien ut nunc. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Mauris viverra diam vitae quam. Suspendisse potenti.","last_touched_at":"2022-10-05T13:59:32Z","next_touch_at":"2023-02-11T19:39:12Z"},
  {"name":"curabitur gravida","clientName":"Yakijo","projectName":"luctus","notes":"Nulla facilisi. Cras non velit nec nisi vulputate nonummy.","last_touched_at":"2022-11-24T07:49:22Z","next_touch_at":"2023-07-29T11:26:10Z"},
  {"name":"blandit nam nulla integer","clientName":"Gigaclub","projectName":"rutrum nulla","notes":"Mauris enim leo, rhoncus sed, vestibulum sit amet, cursus id, turpis.","last_touched_at":"2023-03-05T20:12:03Z","next_touch_at":"2023-06-08T15:15:53Z"},
  {"name":"aliquet at","clientName":"Trupe","projectName":"turpis enim blandit","notes":"Maecenas pulvinar lobortis est.","last_touched_at":"2022-10-19T16:02:57Z","next_touch_at":"2022-07-06T21:27:18Z"},
  {"name":"ut nunc vestibulum","clientName":"Zoombox","projectName":"metus","notes":"Phasellus sit amet erat. Nulla tempus.","last_touched_at":"2022-07-10T22:56:48Z","next_touch_at":"2023-04-19T10:26:04Z"},
  {"name":"phasellus id","clientName":"Rooxo","projectName":"tempus sit","notes":"Sed vel enim sit amet nunc viverra dapibus.","last_touched_at":"2022-09-08T11:45:44Z","next_touch_at":"2022-11-05T12:02:15Z"},
  {"name":"nisi venenatis tristique fusce","clientName":"Pixope","projectName":"metus aenean","notes":"Morbi quis tortor id nulla ultrices aliquet.","last_touched_at":"2022-11-04T01:10:24Z","next_touch_at":"2023-02-08T10:45:12Z"},
  {"name":"vestibulum ante","clientName":"Ozu","projectName":"erat","notes":"Nullam orci pede, venenatis non, sodales sed, tincidunt eu, felis. Fusce posuere felis sed lacus. Morbi sem mauris, laoreet ut, rhoncus aliquet, pulvinar sed, nisl.","last_touched_at":"2022-04-14T18:53:15Z","next_touch_at":"2022-10-27T17:29:44Z"},
  {"name":"proin eu mi nulla","clientName":"Cogilith","projectName":"nisl","notes":"Nulla facilisi. Cras non velit nec nisi vulputate nonummy. Maecenas tincidunt lacus at velit.","last_touched_at":"2022-12-24T15:53:31Z","next_touch_at":"2022-09-17T15:44:21Z"},
  {"name":"sed nisl nunc","clientName":"Roomm","projectName":"pharetra magna","notes":"Donec diam neque, vestibulum eget, vulputate ut, ultrices vel, augue. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec pharetra, magna vestibulum aliquet ultrices, erat tortor sollicitudin mi, sit amet lobortis sapien sapien non mi. Integer ac neque.","last_touched_at":"2023-05-20T20:04:02Z","next_touch_at":"2022-10-04T11:33:53Z"},
  {"name":"primis in faucibus","clientName":"Nlounge","projectName":"dapibus dolor","notes":"Nulla nisl. Nunc nisl.","last_touched_at":"2022-08-04T14:12:35Z","next_touch_at":"2022-07-07T14:10:46Z"},
  {"name":"ut massa","clientName":"Brainbox","projectName":"eleifend","notes":"In hac habitasse platea dictumst. Morbi vestibulum, velit id pretium iaculis, diam erat fermentum justo, nec condimentum neque sapien placerat ante. Nulla justo.","last_touched_at":"2022-11-02T00:11:02Z","next_touch_at":"2023-06-08T05:53:23Z"},
  {"name":"ultrices posuere","clientName":"Topiczoom","projectName":"eget","notes":"Aenean auctor gravida sem. Praesent id massa id nisl venenatis lacinia. Aenean sit amet justo.","last_touched_at":"2022-09-14T10:25:37Z","next_touch_at":"2023-01-31T08:03:54Z"},
  {"name":"etiam vel","clientName":"Topicstorm","projectName":"eget orci vehicula","notes":"Phasellus id sapien in sapien iaculis congue. Vivamus metus arcu, adipiscing molestie, hendrerit at, vulputate vitae, nisl. Aenean lectus.","last_touched_at":"2022-04-09T20:20:04Z","next_touch_at":"2022-07-03T18:19:16Z"}]