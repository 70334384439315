import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';

const AbsenteeModal = (props) => {

  const [state, setState] = React.useState({
    incidentType: 'off',
    notes: ''
  })
  const handleCloseModal = () => {
    props.closeModal('closeModal')
  }

  const handleSubmit = () => {
    let obj = {
      employee: props.employee,
      incidentType: state.incidentType,
      notes: state.notes
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/employees/submitIncident`,
      data: obj
    }).then(response => {
    // console.log('Incident Reported', response.data)
      props.notification({
        type: 'success',
        title: 'Incident Recorded',
        message: `This incident has been recorded and will be on ${props.employee.firstName}'s permanent file.`
      })
      props.updateParent(response.data)
    }).catch(err => {
    // console.log('Error reporting incident', err)
      props.notification({
        type: 'warning',
        title: 'Error Saving Incident',
        message: `Error Saving Incident (Error Code: IR50)`
      })
    })
  }
return (
  <div>
    
      <Dialog
        // fullScreen={false}
        // maxWidth={'lg'}
        width='md'
        fullWidth
        // fullScreen
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Report Incident -  {props.employee.displayName}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please choose the type of incident for {props.employee.firstName} from the list below:
          </DialogContentText>
          <FormControl fullWidth style={{marginBottom: '20px'}}>
                <InputLabel id="demo-simple-select-helper-label">Type of Incident</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={state.incidentType}
                  defaultValue="Please Choose"
                  onChange={(e) => setState({...state, incidentType: e.target.value })}
                >
                  <MenuItem value={'verbal'}>Verbal Warning</MenuItem>
                  <MenuItem value={'written'}>Written Warning</MenuItem>
                  <MenuItem value={'injury'}>Injury</MenuItem>
                  <MenuItem value={'accident'}>Accident</MenuItem>
                  <MenuItem value={'legal'}>Legal Incident</MenuItem>
                  <MenuItem value={'termination'}>Termination</MenuItem>
                  <MenuItem value={'other'}>Other</MenuItem>
                </Select>
                <FormHelperText>Type of Incident</FormHelperText>
              </FormControl>
              <TextField
                id="notes"
                label="Notes"
                multiline
                rows={4}
                variant="outlined"
                fullWidth
                // className={classes.textSaveLineBreaks}
                value={state.notes}
                onChange={e => setState({...state, notes: e.target.value})}
                // onChange={ e => handleServiceChange(state.workorder, 'serviceDescription', e.target.value, state.serviceIndex) }
                // defaultValue="Default Value"
            />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={() => handleSubmit()} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default connect(mapStateToProps)(AbsenteeModal)