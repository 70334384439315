import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import AdbIcon from '@material-ui/icons/Adb';
import { Button, CircularProgress, IconButton, TextField, Typography}  from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/AddCircle';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import SignatureBox from '../Proposals/SignatureBox'
import ESignatureModal from '../Proposals/ESignatureModal'
import DailyLogModificationModal from './ADOTLogModificationModal'
import BigImageComponent from '../Uploads/BigImageComponent'
import { socket } from '../SocketIo/Socket'
import FileUploadComponent from '../Uploads/UploadComponent'
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import UpdateIcon from '@material-ui/icons/Update';
import EmojiRating from '../Common/EmojiRating'
// import { PDFReader, MobilePDFReader  } from 'react-read-pdf';

const useStyles = makeStyles(theme => ({
  root: {
    margin: 5,
    padding: 5,
    display: 'flex',
    justifyContent: 'center',
    justifyItems: 'center',
    backgroundColor: '#eee',
  },
  mobileRoot: {
    height: '90vh',
    width: '100%',
    flexGrow: 1,
    zIndex: 9999,
    backgroundColor: '#eee'
  },
  paper: {
    // width: '100%',
    marginTop: 10,
    marginBottom: theme.spacing(2),
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '50vh',
      maxWidth: 960,
      textAlign: 'center'
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '50vh',
      maxWidth: 1360,
      textAlign: 'center'
    },
    // margin: 20
  },
  sigBox: {
    // backgroundColor: 'green',
    margin: 20,
    width: '100%',
    textAlign: 'center'
  },
  headerImages: {
    marginTop: 5,
    width: '100%',
    overflow: 'hidden'
  },
  bgImage: {
    // backgroundImage: `url("${process.env.PUBLIC_URL}/adotbg.png")`,
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    backgroundColor: 'rgba(222, 222, 222, .3)',
    // backgroundBlendMode: 'hue',
    // minHeight: '100vh',
    // height: '100%',
      width: '800px',
    // overflow: 'hidden'
    // height: '2100px'
  },
  footerImages: {
    marginTop: 5,
    width: '100%',
    overflow: 'hidden'
  },
  disclosureArea: {
    fontSize: '0.8rem',
    color: '#bbb',
    textAlign: 'center'
  },
  headerText: {
    fontSize: 32,
    color: '#aaa',
    // color: 'rgb(119,156,164)',
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 18,
      // color: 'red'
    },
    [theme.breakpoints.down('xs')]: {
      // backgroundColor: 'pink'
      // width: '100%'
      fontSize: 14,
      // color: 'pink'
    },
    [theme.breakpoints.up('md')]: {
      // minHeight: '50vh',
      // maxWidth: 1360,
      // textAlign: 'center'
      fontSize: 24,
      // color: 'green'
    }
  },
  headerLogo: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '65%'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 300
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '90%'
    }
  },
  giantText: {
    textAlign: 'center',
    fontSize: 48,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
    marginBottom: -15
  },
  subText: {
    textAlign: 'center',
    fontSize: 24,
    fontFamily: 'Amiri',
    color: 'rgb(119,156,164)',
  },
  squigglyText: {
    textAlign: 'center',
    fontSize: 42,
    fontFamily: 'Inspiration',
    color: 'rgb(119,156,164)',
  },
  signUpText: {
    marginTop: 15,
    fontFamily: 'Amiri',
    fontSize: 32,
    textAlign: 'center',
    color: 'rgb(119,156,164)',
  },
  disclaimerText: {
    fontSize: 16,
    marginBottom: 30,
    marginTop: 15,
    color: '#aaa'
  },
  nameText: {
    fontSize: 24,
    marginTop: 10,
    color: '#aaa'
  },
  mainBody: {
    border: '2px solid #ccc',
    width: '90%',
    margin: 'auto',
    padding: 10,
    textAlign: 'left' 
  },
  rightAlign: {
    textAlign: 'right'
  },
  bigText: {
    fontWeight: 600
  },
  workDetailsItem: {
    margin: 2,
    padding: 3
    // backgroundColor: '#ffa'
  }, 
  workDetailsContainer: {
    margin: 5,
    padding: 5,
    // backgroundColor: 'pink',
    overflowX: 'scroll',
    // minHeight: '40vh',
    // maxHeight: '55vh'
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: 10,
    overflow: 'hidden'
  },
  imageItem: {
    marginRight: '15px',
    maxHeight: '180px'
  },
  textInput: {
    maxWidth: '80%',
    // margin: 10,
    padding: 15,
    textAlign: 'center'
  },
  paddedRow: {
    margin: 10,
    // padding: 15
  },
  centeredText: {
    textAlign: 'center'
  },
  centeredRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  rejectedText: {
    color: 'red'
  },
  approvedText: {
    color: 'green'
  }
  
}));

// What it should say:  Please review this form for accuracy and sign to approve
// What it should look like:  This should be the ADOT form that they can look at and approve, they should review it, and have a box over the signature line that can click and sign with their finger or a pre generated signature.
// Logos? Colors? Black and white for everything but the Logo on the bottom of the page.

function ViewADOTFormOnline(props) {
  // console.log('View ADOT Form online', props)
  const [loading, setLoading] = React.useState(false)
  const [dailyLog, setDailyLog] = React.useState({})
  const [pdf, setPDF] = React.useState({})
  const [pdfUrl, setPdfUrl] = React.useState('')
  const [showESigModal, setShowESigModal] = React.useState(false)
  const [contact, setContact] = React.useState({})
  const [showModificationModal, setShowModificationModal] = React.useState(false)
  const [images, setImages] = React.useState([])
  const [showBigImages, setShowBigImages] = React.useState(false)
  const [activeItem, setActiveItem] = React.useState({})
  const [notes, setNotes] = React.useState('')
  const [rating, setRating] = React.useState(0)

  React.useEffect(() => {
    // console.log('Use Effect MTC Agreement Details')
    socket.on(`adotlog-${dailyLog?._id}`, (msg) => {
      dealWithSocketData(msg)
    })
    return () => {
      socket.off(`adotlog-${dailyLog?._id}`)
    }
  }, [dailyLog])

  const fetchPDFDetails = async (data) => {
    // console.log('Fetch PDF Details', data)
    if (data?.images?.length) {
      data.images.forEach(image => {
        if (image && image.category === 'ADOT Daily Work Report') {
          fetchPDF(image)
          setPdfUrl(image.url)
        }
      })
    }
  }

  const dealWithSocketData = async (msg) => {
    // console.log('Socket message for this guy!!!', msg)
    if (msg?.data?._id) {
      setDailyLog(msg.data)
    }
    if (msg.approved && msg.url) {
      // console.log('Fetch the pdf!!', msg.url)
      fetchPDF(msg)
    }
  }

  const fetchPDF = async (obj) => {
    // console.log('Fetch this PDF:', obj)
    let cleanURL = ''
    let url = obj.url
    if (url.includes('https')) {
      cleanURL = url
    } else {
      cleanURL = `https://${url}`
    }
    // console.log('CLEAN: ', cleanURL)
    await axios.get(cleanURL, {
      withCredentials: false,
      headers: {
        'Content-Type': 'application/octet-stream',
        'Accept': 'application/pdf, application-octet-stream'
      },
      responseType: 'blob'
    }).then((response) => {

          let newBlob = new Blob([response.data], {type: "application/pdf"})
          // console.log(newBlob)
          const data = window.URL.createObjectURL(newBlob);

          setPDF(data)
  
    }).catch((e) => {
      console.log('E', e)
    })
  }

  React.useEffect(() => {
    let isMounted = true

  const fetchADOTFOrm = async (dailyLogId) => {
    setLoading(true)
      let url = `${props.url}/api/hmi/getADOTFormDetails?dailyLogId=${dailyLogId}&contactId=${props?.match?.params?.contactId}`
      // console.log('URL to retrieve: ', url)
      const response = await axios.get(url)
      setLoading(false)
      if (response && response.data && isMounted) {
        console.log('STUFF: ', response.data)
        if (response.data.log) setDailyLog(response.data.log)
        if (response.data.images) setImages(response.data.images)
        if (response.data.contact) setContact(response.data.contact)
        if (response?.data?.log?.status === 'Approved') {
          fetchPDFDetails(response.data)
        }
        if (response.data?.log?.notes) {
          setNotes(response.data?.log?.notes || '')
        } else {
          setNotes('')
        }
        if (response.data?.log?.rating) {
          setRating(response.data?.log?.rating)
        } else {
          setRating(0)
        }
      }
    }

    if (props.match && props.match.params && props.match.params.dailyLogId) {
      fetchADOTFOrm(props.match.params.dailyLogId)
    }

    return () => {
      isMounted = false
    }
  }, [])

  const classes = useStyles();

 const handleOpenESigModal = () => {
    setShowESigModal(true)
  }

  const handleCloseESigModal = () => {
    setShowESigModal(false)
  }

  const handleEsignatureSubmission = (name) => {
    // console.log('Handle esignature: ', name)
    setShowESigModal(false)
    let data = {
      dailyLog: dailyLog?._id,
      // contactId: contact._id,
      name,
      notes,
      rating
    }
    // console.log('Post', data)
    axios({
      method: 'post',
      url:  `${props.url}/api/hmi/eSignADOTForm`,
      data: data,
    }).then(response => {
      // console.log('Approved ADOT FORM', response.data)
      if (response.data?.dailyLog) {
        props.notification({
          type: 'success',
          title: 'Daily Work Report Approved!',
          message: 'Thank you for approving this Daily Work Report. You will receive a copy of the signed PDF document in your email shortly.'
        })
      }
      // if (response.data && response.data.estimate && response.data.estimate._id) {
         
      //     if (response.data.estimate.waiveDeposit) {
      //       props.notification({
      //         type: 'success',
      //         title: 'Proposal Accepted!',
      //         message: 'Thank you for accepting this proposal, we will be in touch soon.'
      //       })
      //     } else {
      //       props.notification({
      //         type: 'success',
      //         title: 'Proposal Accepted!',
      //         message: 'Thank you for accepting this proposal, no work will be started without a deposit being received by the office.'
      //       })
      //       props.history.push(`/estimate/postApproval/${response.data.estimate._id}/${contact._id}`, response.data)
      //     }
      //     // props.history.push(`/estimate/deposit/${response.data.estimate._id}/${contact._id}`, response.data)
      //     // return callback(null, response.data)
      // }

    }).catch(err => {
      console.log('Error caught when uploading pic: ', err)
      props.notification({
        type: 'warning',
        title: 'Failed to Approve Form!',
        message: err.message
      })
      // return callback(err)
    })

  }

  const handleModify = () => {
    // console.log('Open modification modal....')
    setShowModificationModal(true)
  }

  const handleLargeImage = (img) => {
    // console.log('Show this large image', img)
    setShowBigImages(true)
    setActiveItem(img)
  }

  const handleCloseBigImageModal = () => {
    setShowBigImages(false)
  }

  const handleModificationResponse = (data, type) => {
    setShowModificationModal(false)
    if (type === 'update') {
      // console.log('Update our current items??', data)
      let newDailyLog = {
        ...dailyLog,
        crewSize: data.crewSize,
        totalLaborHours: data.totalLaborHours,
        totalSupervisorHours: data.totalSupervisorHours,
        dumpFees: data.dumpFees,
        timeIn: data.timeIn,
        timeOut: data.timeOut
      }
      setDailyLog(newDailyLog)
    }
  }

  const handleUpdateResponse = (d) => {
    console.log('UPdate REsponse from upload component...', d)
    if (d?.filesUploaded?.length) {
      // Add File to our uploads list
      let updatedImages = [
        ...images,
        ...d.filesUploaded
      ]
      console.log('Updated images', updatedImages)
      setImages(updatedImages)
    }
  }

  const handleReject = () => {
    // console.log('Handle Reject...')
    let data = {
      dailyLog: dailyLog?._id,
      contactId: contact?._id || null,
      name: contact?.displayName || 'Unknown',
      notes,
      rating
    }
    // console.log('Post', data)
    axios({
      method: 'post',
      url:  `${props.url}/api/hmi/rejectADOTForm`,
      data: data,
    }).then(response => {
      // console.log('Approved ADOT FORM', response.data)
      if (response.data?.dailyLog) {
        props.notification({
          type: 'success',
          title: 'Daily Work Report Rejected!',
          message: 'Thank you for your feedback.'
        })
      }
    }).catch(error => {
      // console.log('Error')
      props.notification({
        type: 'warning',
        title: 'Rejection Failed!',
        message: `${error?.message || 'Unknown Error at Line 490'}`
      })
    })
  }

  const handleRatingChange = (newRating) => {
    // console.log('New rating:', newRating);
    // Do something with the new rating
    setRating(newRating)
  };

  function currencyFormat(num) {
    if (!num) num = 0
    return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  let isApproved = false
  const isMobile = false // window.innerWidth < 768;
  
  if (isMobile) {
    return (
      <div className={classes.mobileRoot}>
        {
           (pdfUrl && pdfUrl.length > 50) ? (
           
              <iframe src={pdfUrl} width="100%" height="860px" />
          ) : 'No PDF URL Available From this Resource'
        }
      </div>
    )
  } else {
    return (
      <div className={classes.root}>
        
      <Paper className={classes.paper}>
        <DailyLogModificationModal open={showModificationModal} notification={props.notification} itemToEdit={dailyLog} url={props.url} updateParent={handleModificationResponse} />
        <ESignatureModal open={showESigModal} contact={contact} handleClose={handleCloseESigModal} submit={handleEsignatureSubmission} />
        <BigImageComponent open={showBigImages} item={activeItem} images={images} closeModal={handleCloseBigImageModal} />
        {
                (pdf && pdf.length) ? (
                  <Grid container style={{ height: '100%' }}>
                    <Grid item lg={12} xs={12} className={classes.headerImages}>
                  
                      <img src={`https://hmiofaz.com/wp-content/uploads/2023/04/hmi-logo-300.png`} />
                      {/* <img src="/Logo_W_BestOfValleyCHips.png" /> */}
                    </Grid>
                    <Grid item lg={12} xs={12} >
                      Approved Daily Work Report
                    </Grid>
                    <Grid item lg={12} xs={12} >
                      Approved by {dailyLog?.approved_by} on {moment(dailyLog?.approved_at).format('MM/DD/YY [at] hh:mma')} 
                    </Grid>
                    <Grid item lg={12} xs={12} >
                      <iframe src={pdfUrl} width="100%" height="860px" />
                      {/* <object
                          data={pdf}
                          type="application/pdf"
                          width="100%"
                          height="960px"
                        >
                          <p>Your browser does not support PDFs. Please download the PDF to view it: <a href={pdf}>Download PDF</a>.</p>
                        </object> */}
                    </Grid>
                  </Grid>
                ) :
          <Grid container>

            <Grid item lg={12} xs={12} className={[classes.headerImages, classes.centeredRow]}>
          
              <img src={`https://hmiofaz.com/wp-content/uploads/2023/04/hmi-logo-300.png`} />
              {/* <img src="/Logo_W_BestOfValleyCHips.png" /> */}
            </Grid>
            <Grid item lg={12} xs={12} className={classes.centeredRow}>
              Please review this data for accuracy before approving
            </Grid>
            <Grid item lg={12} xs={12} >
              <ADOTFormDetails data={dailyLog} images={images} handleLargeImage={handleLargeImage} />
              
            </Grid>
            <Grid item lg={12} xs={12} className={classes.paddedRow}>
              <FileUploadComponent dailyLogId={dailyLog?._id} clientId={contact?.client || null} updateParent={handleUpdateResponse} message={`Click/Drag Files Here to Upload`} />
            <Grid item lg={12} xs={12} className={classes.centeredRow}>
              <TextField
                multiline
                minRows={4}
                label="Notes"
                fullWidth
                variant="outlined"
                value={notes}
                // className={classes.textInput}
                helperText={'Add any relevant notes for the HMI Office'}
                onChange={e => setNotes(e.target.value)}
              />

            </Grid>
            </Grid>

                  <Grid item lg={12} xs={12} sm={12} md={12} className={classes.signUpText}>
                    <Button variant="contained" color="primary" onClick={handleOpenESigModal} startIcon={ <ThumbUpIcon /> }>
                    Approve
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" color="secondary" onClick={handleModify} startIcon={<UpdateIcon />}>
                      Modify
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button variant="contained" style={{ backgroundColor: '#e65f55' }} onClick={handleReject} startIcon={ <ThumbDownIcon /> } >
                     Reject
                    </Button>
                  
                  </Grid>
            <Grid item lg={12} xs={12}>
            <Typography variant="h4" gutterBottom className={classes.centeredText}>
              How satisfied are you?
            </Typography>
            <EmojiRating initialRating={rating || 0} onChange={handleRatingChange} />
            </Grid>
                
            <Grid item lg={12} xs={12} sm={12} md={12} className={classes.disclosureArea}>
            The information contained on this page is confidential and intended only for {contact?._id ? `${contact.firstName} ${contact.lastName}` : ` authorized individuals`}.  If you are not the intended recipient, any disclosure, copying, distribution, electronic storage or use of this is not authorized. If you received this communication in error or without authorization, please delete the original message from your computer and network. Thank you
            </Grid>
            <Grid item lg={12} xs={12} sm={12} md={12} className={classes.disclosureArea}>
              Copyright HMI Landscape { new Date().getFullYear()}, All Rights Reserved.
            </Grid>
          </Grid>
        }
      </Paper>
      </div>
    );
  }
}

const ADOTFormDetails = ({ data, images, handleLargeImage }) => {
  // console.log('Form data', data)
  const classes = useStyles()
  let classType = null
  if (data.status === 'Rejected') {
    classType = classes.rejectedText
  }
  if (data.status === 'Approved') {
    classType = classes.approvedText
  }
  return (
    <div className={classes.mainBody}>
      <Grid container spacing={2}>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.centeredRow}>
        <Typography variant="h4" gutterBottom className={[classes.centeredText, classType ]}>
              {data.status}
        </Typography>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.rightAlign}>
        <span className={classes.bigText}>Date: </span>{
          data.date ? `${moment(data.date).format('MM-DD-YY')}` : ''}
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <div>
          <span className={classes.bigText}>Crew Name: </span>{data?.crewName || ''}
            </div> 
            <span className={classes.bigText}>Crew Size:</span> {data.crewSize}
        </Grid>
       
        {/* <Grid item lg={12} md={12} sm={12} xs={12} >
          <span className={classes.bigText}>Time Crew Arrived:</span>{data?.timeIn || ''}
        </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.rightAlign}>
          
          <div>
            <span className={classes.bigText}>Time Crew Arrived: </span>{data?.timeIn || ''}
          </div>
          <div>
            <span className={classes.bigText}>Time Crew Leaves: </span>{data?.timeOut || ''}
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <WorkDetails jobs={data.jobs || []} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <div>
            <span className={classes.bigText}>Total Labor Hours: </span>{data?.totalLaborHours || ''}
          </div>
          <div>
            <span className={classes.bigText}>Total Supervisor Hours: </span>{data?.totalSupervisorHours || ''}
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <DumpFeeDetails dumpFees={data.dumpFees || []} />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          <ImagePreview images={images} viewLargeImage={handleLargeImage} />
        </Grid>
      </Grid>

    </div>
  )
}

const DumpFeeDetails = ({ dumpFees }) => {
  const classes = useStyles()
  if (dumpFees && dumpFees.length) {
    return (
      <div className={classes.workDetailsContainer}>
         <span className={classes.bigText}>Dump Fee Details:</span>
         {
          dumpFees.map((dumpFee, index) => {
            return (
              <div key={index}>
               {dumpFee.tons} Tons (${dumpFee.fee}) 
              </div>
            )
          })
         }
      </div>
    )
  } else {
    return null
  }
}

const ImagePreview = ({ images, viewLargeImage }) => {
  const classes = useStyles()
  return (
    <div className={classes.imageContainer}>
      {
      images.map((image, index) => {
        console.log(image)
        if (image && image.category === 'ADOT Daily Work Report') return null
        return (
          <div key={index} className={classes.imageItem} onClick={e => viewLargeImage(image)}>
            <img src={image.url} width="150" />
          </div>
        )
      })}
    </div>
  )
}

const WorkDetails = ({ jobs }) => {
  const classes = useStyles()
  return (
    <div className={classes.workDetailsContainer}>
      <Table
        stickyHeader
        className={classes.table}
      >
        <TableHead>
          <TableRow>
            <TableCell>Activity</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Milepost (Start/End)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            jobs.map((job, index) => {
              let activityDetails = ``
              if (job.workDone) {
                job.workDone.map((item, workIndex) => {
                  if (item && item.checked) {
                    if (workIndex > 0) activityDetails += `\n`
                    activityDetails += `${item.label}`
                  }
                })
              }
              return (
                <TableRow key={index} className={classes.workDetailsItem}>
                  <TableCell>{activityDetails}</TableCell>
                  <TableCell>{job?.freeway}</TableCell>
                  <TableCell>{job?.startLocation} / {job?.endLocation} </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>

    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(ViewADOTFormOnline)

