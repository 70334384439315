import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const defaultEquipmentTypes = [
  'Vehicle',
  'Trailer',
  'Mower',
  'Trimmer',
  'Jackhammer',
  'Cell Phone',
  'Tablet',
  'Key',
  'Keycard',
  'Other'
]


const CellPhoneDetails = (props) => {
  let classes = useStyles();
  const [state, setState] = React.useState({
    equipment: {}
  })
  const cellNumber = React.useRef(null)

  const updateEquip = (e) => {
  // console.log('UPdate: ', e.target.value)
  // console.log(e.target.id)
    // console.log(cellNumber)
    props.updateEquip(e)
  }
  return (
    <Grid container>
        <Grid item lg={6} xs={12}>
                  <TextField
                    // autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="cellNumber"
                    label="Cell Phone Number"
                    type="text"
                    // ref={cellNumber}
                    onBlur={updateEquip}
                    // onChange={(e) => setState({...state, equipment: {...state.equipment, number: e.target.value}})}
                    // value={state.equipment.number}
                    fullWidth
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                  <TextField
                    // autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="model"
                    label="Cell Phone Model Name"
                    type="text"
                    onBlur={updateEquip}
                    // onChange={(e) => setState({...state, equipment: {...state.equipment, name: e.target.value}})}
                    // value={state.equipment.name}
                    fullWidth
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                  <TextField
                    // autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="make"
                    label="Manufacturer"
                    type="text"
                    onBlur={updateEquip}
                    // onChange={(e) => setState({...state, equipment: {...state.equipment, manufacturer: e.target.value}})}
                    // value={state.equipment.manufacturer}
                    fullWidth
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                  <TextField
                    // autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="mac"
                    label="MAC Address"
                    type="text"
                    onBlur={updateEquip}
                    // onChange={(e) => setState({...state, equipment: {...state.equipment, mac: e.target.value}})}
                    // value={state.equipment.mac}
                    fullWidth
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                  <TextField
                    // autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="imei"
                    label="IMEI Number"
                    type="text"
                    onBlur={updateEquip}
                    // onChange={(e) => setState({...state, equipment: {...state.equipment, imei: e.target.value}})}
                    // value={state.equipment.imei}
                    fullWidth
                />
              </Grid>
    </Grid>
  )
}
const NewEquipmentModal = (props) => {
  // console.log('NewEquipmentModal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [individual, setIndividual] = React.useState(true);
  const [activeEntity, setEntity] = React.useState({})
  // const [invoice, setInvoice] = React.useState(null)
  const [state, setState] = React.useState({
    equipment: {},
    entity: null,
    name: ''
  })
  const [equipmentType, setEquipmentType] = React.useState('')

  const equipRef = React.useRef(null)

  React.useEffect(() => {
    // console.log('Props::', props)
    if (props && props.user && props.user.entities && props.user.entities.length) {
      setEntity(props.user.entities[0])
    }
  }, [props])


const handleClose = () => {
// console.log('Closing DepositModal Modal')
  props.closeModal()
}

const handleSave = () => {
  setLoading(true)
// console.log('Save this Equipment!!!', equipRef)
  // if (equipRef && equipRef.current && (equipRef.current.celLNumber || equipRef.current.name)) {
  //   equipRef.current.type = equipmentType
  // } else {
  //   // return
  //   // equipRef.current = {
  //   //   type: equipmentType
  //   // }
  // }
    // console.log('Send this equpiment to The Server')
    let obj = {
      equipment: equipRef.current,
      entity: activeEntity,
      user: props.user
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/equipment/addNewEquipment`,
      data: obj
    }).then(response => {
      setLoading(false)
    // console.log('Axios response from Add addNewEquipment: ', response)
      if (response && response.data && response.data._id) {
      // console.log('We have a new equipment!!', response.data)
        props.notification({
          type: 'success',
          title: 'Equipment Created!',
          message: 'Equipment Was Created Successfully'
        })
        setState({...state,
          equipment: {}
        })
        // handleClose('success', response.data)
        props.updateParent({equipment: response.data})
      } else {
        props.notification({
          type: 'warning',
          title: 'Equipment Error',
          message: response.data.message
        })
        // props.notification({
        //   type: 'warning',
        //   title: 'Unknown Error',
        //   message: 'We Do Not Know Why This Shows Up...'
        // })
      }
  // }).catch(response => {
  // // console.log('Error saving equipment')
  //   if (response && response.data && response.data.message) {
  //   // console.log('Got an error', response.data.message)
  //     props.notification({
  //       type: 'warning',
  //       title: 'Equipment Error',
  //       message: response.data.message
  //     })
  //   }
  })
}



const updateEquipmentType = (id, value) => {
  // console.log('Update equipment: ', id)
// console.log('Value: ', value)
  if (equipRef && equipRef.current) {

  } else {
    equipRef.current = {}
  }
  equipRef.current.type = value
  setEquipmentType(value)

  // setState({...state, equipment: {...state.equipment, type: value}})
}

const updateEquip = (e) => {
  // console.log('Update our Equipmenth: ', e)
  // console.log('Equip Ref', equipRef)
  if (equipRef && equipRef.current) {

  } else {
    equipRef.current = {}
  }
  let typeToUpdate = e.target.id
  let val = e.target.value
  // console.log('Equipment %s -- %s ', typeToUpdate, val)
  equipRef.current [typeToUpdate] = val
  // setState({...state, equipment: {...state.equipment, [typeToUpdate]: val}})
}

const handleEquipmentUpdate = (newEquip) => {
// console.log('Update our equipment', newEquip)
// console.log(equipRef)
  equipRef.current = {...newEquip}
  // if (newEquip && newE)
  // setState({...state, equipment: newEquip })
}

const CetEquipmentDetails = (props) => {
  if (equipmentType !== 'Vehicle' && equipmentType !== 'Cell Phone' && equipmentType !== 'Trailer' && equipmentType !== 'Truck') {
    return (
      <React.Fragment>
              <Grid item lg={9} xs={12}>
                  <TextField
                    // autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Name"
                    label="Equipment Name"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, name: e.target.value}})}
                    value={state.equipment.name}
                    fullWidth
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                  <TextField
                    // autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Year"
                    label="Year"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, year: e.target.value}})}
                    value={state.equipment.year}
                    fullWidth
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                  <TextField
                    // autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Make"
                    label="Manufacturer"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, manufacturer: e.target.value}})}
                    value={state.equipment.manufacturer}
                    fullWidth
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                  <TextField
                    // autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Model"
                    label="Model"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, model: e.target.value}})}
                    value={state.equipment.model}
                    fullWidth
                />
              </Grid>
      </React.Fragment>
    )
  }
  if (equipmentType === 'Vehicle' || equipmentType === 'Trailer' || equipmentType === 'Truck' || equipmentType.includes('Mower')) {
    return (
      <VehicleInput updateParent={handleEquipmentUpdate} />
    )
  }
  if (equipmentType === 'Cell Phone' || equipmentType.includes('Tablet')) {
    return <CellPhoneDetails updateEquip={updateEquip} />
    // (
    //   <React.Fragment>
    //     <Grid item lg={6} xs={12}>
    //               <TextField
    //                 // autoFocus
    //                 className={classes.paddedStuff}
    //                 margin="dense"
    //                 id="Number"
    //                 label="Cell Phone Number"
    //                 type="text"
    //                 onChange={(e) => setState({...state, equipment: {...state.equipment, number: e.target.value}})}
    //                 value={state.equipment.number}
    //                 fullWidth
    //             />
    //           </Grid>
    //           <Grid item lg={6} xs={12}>
    //               <TextField
    //                 // autoFocus
    //                 className={classes.paddedStuff}
    //                 margin="dense"
    //                 id="Name"
    //                 label="Cell Phone Model Name"
    //                 type="text"
    //                 onChange={(e) => setState({...state, equipment: {...state.equipment, name: e.target.value}})}
    //                 value={state.equipment.name}
    //                 fullWidth
    //             />
    //           </Grid>
    //           <Grid item lg={4} xs={12}>
    //               <TextField
    //                 // autoFocus
    //                 className={classes.paddedStuff}
    //                 margin="dense"
    //                 id="Make"
    //                 label="Manufacturer"
    //                 type="text"
    //                 onChange={(e) => setState({...state, equipment: {...state.equipment, manufacturer: e.target.value}})}
    //                 value={state.equipment.manufacturer}
    //                 fullWidth
    //             />
    //           </Grid>
    //           <Grid item lg={4} xs={12}>
    //               <TextField
    //                 // autoFocus
    //                 className={classes.paddedStuff}
    //                 margin="dense"
    //                 id="MAC"
    //                 label="MAC Address"
    //                 type="text"
    //                 onChange={(e) => setState({...state, equipment: {...state.equipment, mac: e.target.value}})}
    //                 value={state.equipment.mac}
    //                 fullWidth
    //             />
    //           </Grid>
    //           <Grid item lg={4} xs={12}>
    //               <TextField
    //                 // autoFocus
    //                 className={classes.paddedStuff}
    //                 margin="dense"
    //                 id="IMEI"
    //                 label="IMEI Number"
    //                 type="text"
    //                 onChange={(e) => setState({...state, equipment: {...state.equipment, imei: e.target.value}})}
    //                 value={state.equipment.imei}
    //                 fullWidth
    //             />
    //           </Grid>
    //   </React.Fragment>
    // )
  }
  return (
    <>
    </>
  )
}

const handleUpdateVisibleEntity = (activeEntity) => {
  // console.log('Entity to assigN: ', activeEntity)
  setEntity(activeEntity)
  setState({...state, entity: activeEntity._id })
}

return (
  <div className={classes.root}>
    <Dialog open={props.open} fullWidth={false} onClose={handleClose} maxWidth='sm' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Equipment</DialogTitle>
        <DialogContent >
          <DialogContentText>
            Please Provide Equipment Information Below
          </DialogContentText>
          <Grid container>
            {
                    (props.user && props.user.entities && props.user.entities.length > 1) ? (
                      <Grid item lg={12} xs={12}>
                        <DialogContentText>
                          Please Choose The Customer's Entity
                        </DialogContentText>
                        <Grid item xs={12} lg={12} style={{padding: 10}}>
                          <ChooseEntityComponent allowAll={false} defaultEntity={activeEntity} title={'Assigned Entity'} user={props.user} updateParent={handleUpdateVisibleEntity} />
                            { (activeEntity && activeEntity._id) ? (
                              <div style={{display: 'flex', justifyContent: 'center'}}>
                                <img alt="Logo" src={`${process.env.PUBLIC_URL}/${activeEntity._id}_Logo.png`} style={{margin: '5px', padding: '5px', maxWidth: 220, maxHeight: 100}} /> 
                              </div>
                              
                            ) : '' }
                        </Grid>
                      </Grid>
                  
                    ) : (
                      (activeEntity && activeEntity._id) ? (
                        <React.Fragment>
                          <div style={{display: 'flex', justifyContent: 'center'}}>
                            <img alt="Logo" src={`${process.env.PUBLIC_URL}/${activeEntity._id}_Logo.png`} style={{margin: '5px', padding: '5px', maxWidth: 220, maxHeight: 100}} /> 
                          </div>
                        </React.Fragment>
                        
                      ) : '' 
                    )
                  }
            <Grid item lg={12} xs={12}>
              {
                (activeEntity && activeEntity._id) ? (
                <Autocomplete
                  id="EquipmentType"
                  options={props.equipmentTypes}
                  // getOptionLabel={(option) => option.name}
                  // style={{ width: 200 }}
                  onChange={updateEquipmentType}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {equipmentType}
                  // onChange={ e => setState({...state, equipment: {...state.equipment, equipmentType: e.target.value}, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Equipment Type" variant="outlined" />}
                />
                ) : null
              }
            </Grid>
           {
            (equipmentType && equipmentType.length) && <CetEquipmentDetails />
           } 
            {/* <Grid item lg={12} xs={12}>
              <CetEquipmentDetails />
            </Grid> */}
                {/* <Grid item lg={3} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Number"
                    label="Number"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, number: e.target.value}})}
                    value={state.equipment.number}
                    fullWidth
                />
              </Grid>
              <Grid item lg={9} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Name"
                    label="Equipment Name"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, name: e.target.value}})}
                    value={state.equipment.name}
                    fullWidth
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Year"
                    label="Year"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, year: e.target.value}})}
                    value={state.equipment.year}
                    fullWidth
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Make"
                    label="Manufacturer"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, manufacturer: e.target.value}})}
                    value={state.equipment.manufacturer}
                    fullWidth
                />
              </Grid>
              <Grid item lg={4} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Model"
                    label="Model"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, model: e.target.value}})}
                    value={state.equipment.model}
                    fullWidth
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="Plate"
                    label="Plate"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, plate: e.target.value}})}
                    value={state.equipment.plate}
                    fullWidth
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                  <TextField
                    autoFocus
                    className={classes.paddedStuff}
                    margin="dense"
                    id="VIN"
                    label="VIN"
                    type="text"
                    onChange={(e) => setState({...state, equipment: {...state.equipment, vin: e.target.value}})}
                    value={state.equipment.vin}
                    fullWidth
                />
              </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading || !state.entity || !activeEntity || !equipmentType} onClick={handleSave}>
            {
              loading ? <CircularProgress /> : 'Save'
            }
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }

  const VehicleInput = (props) => {
  // console.log('Vehicle Input:', props)
    const classes = useStyles()
    const [state, setState] = React.useState({
      equipment: {},
      name: '',
      counter: 0
    })

    const handleBlur = () => {
      props.updateParent(state.equipment)
    }
    return (
      <React.Fragment>
      <Grid item lg={9} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            id="Name"
            onBlur={handleBlur}
            label="Equipment Name"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, name: e.target.value}})}
            value={state.equipment.name}
            fullWidth
        />
      </Grid>
      <Grid item lg={4} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            onBlur={handleBlur}
            id="Year"
            label="Year"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, year: e.target.value}})}
            value={state.equipment.year}
            fullWidth
        />
      </Grid>
      <Grid item lg={4} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            onBlur={handleBlur}
            id="Make"
            label="Manufacturer"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, manufacturer: e.target.value}})}
            value={state.equipment.manufacturer}
            fullWidth
        />
      </Grid>
      <Grid item lg={4} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            onBlur={handleBlur}
            id="Model"
            label="Model"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, model: e.target.value}})}
            value={state.equipment.model}
            fullWidth
        />
      </Grid>
      <Grid item lg={6} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            onBlur={handleBlur}
            id="Plate"
            label="Plate"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, plate: e.target.value}})}
            value={state.equipment.plate}
            fullWidth
        />
      </Grid>
      <Grid item lg={6} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            onBlur={handleBlur}
            id="mileage"
            label="Mileage"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, mileage: e.target.value}})}
            value={state.equipment.mileage}
            fullWidth
        />
      </Grid>
      <Grid item lg={12} xs={12}>
          <TextField
            // autoFocus
            className={classes.paddedStuff}
            margin="dense"
            onBlur={handleBlur}
            id="VIN"
            label="VIN"
            type="text"
            onChange={(e) => setState({...state, equipment: {...state.equipment, vin: e.target.value}})}
            value={state.equipment.vin}
            fullWidth
        />
      </Grid>
      
</React.Fragment>
    )
  }

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(NewEquipmentModal)