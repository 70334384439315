import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SupplierComponent from './SupplierComponent'
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const filter = createFilterOptions();
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const treeSizes = [
  {
    name: `24" Box`
  },
  {
    name: '30" Box'
  },
  {
    name: `36" Box`
  },
  {
    name: `42" Box`
  },
  {
    name: `48" Box`
  }
]

const plantSizes = [
  {
    name: `1 Gallon`
  },
  {
    name: `2 Gallon`
  },
  {
    name: `3 Gallon`
  },
  {
    name: `5 Gallon`
  },
  {
    name: `7 Gallon`
  },
  {
    name: `10 Gallon`
  },
  {
    name: `20 Gallon`
  }
]

const flowerSizes = [
  {
    name: `Flat (16-4")`
  },
  {
    name: `Jumbo Pack`
  },
  {
    name: `1 Gallon`
  }
]

const treeCategories = [
  {
    name: 'Citrus'
  },
  {
    name: 'Evergreen'
  },
  {
    name: 'Semi-Evergreen'
  },
  {
    name: 'Native'
  },
  {
    name: 'Ornamental'
  }
]

const plantCategories = [
  {
    name: 'Ground Covers'
  },
  {
    name: 'Shrubs'
  },
  {
    name: 'Vines'
  },
  {
    name: 'Flowering'
  }
]

const flowerCategories = [
  {
    name: 'Perrennial'
  },
  {
    name: 'Annual'
  }
]

const PlantInventoryInputItem = (props) => {
// console.log('PLANT INVE: , props', props)

  let name = ''
  if (props.item && props.item.name) name = props.item.name
// console.log('NAME 45: ', name)
  const defaultState = {
    department: props.department || 'Misc. Plants/Flowers',
    name: name,
    description: '',
    notes: '',
    sku: '',
    mfg: '',
    upc: '',
    partNumber: '',
    category: ''
  }

  const [state, setState] = React.useState(defaultState)
  const [value, setValue] = React.useState(null)
  const [dialogValue, setDialogValue] = React.useState(null)
  const [open, toggleOpen] = React.useState(false);
  const [item, setItem] = React.useState({
    name: ''
  })
  const [category, setCategory] = React.useState(null)
  const [categoryList, setCategoryList] = React.useState([])
  const [subCategoryList, setSubcategoryList] = React.useState([])
  const [sizeList, setSizeList] = React.useState([])
// console.log('STATE 80: ', state)
  React.useEffect(() => {
    if (props.department === 'Trees') {
      setCategory('Trees')
      setCategoryList(treeCategories)
      setSizeList(treeSizes)
    }
    if (props.department === 'Plants') {
      setCategory('Plants')
      setCategoryList(plantCategories)
      setSizeList(plantSizes)
    }
    if (props.department === 'Flowers') {
      setCategory('Flowers')
      setCategoryList(flowerCategories)
      setSizeList(flowerSizes)
    }
    // setSubcategoryList()
  }, [props.department])

  React.useEffect(() => {
    if (props.item) {
    // console.log('Set State 88 PLANTS', props.item)
      setItem(props.item)
      setState({...state, name: props.item.name})
    }
  }, [props.item])
  // React.useEffect(() => {
  // // console.log('State of our Plant Input changed')
  //   // props.updatePlantDetails(state)
  // }, [state])


  const handleCategoryChange = (e) => {
    setState({...state, category: e.target.value})
    const cat = e.target.value
    // if (cat === 'PVC Parts') {
    //   setSubcategoryList(pvcPartsSubCategoryList)
    // }
    // if (cat === 'Poly Parts') {
    //   setSubcategoryList(polyPartsSubCategoryList)
    // }
  }

  const handleUpdateUOM = (e, newOne) => {
    // // console.log('New UOM: ', newOne)
    setDialogValue({...dialogValue, unitsOfMeasure: newOne})
  }

  const handleUpdateParent = () => {
  // console.log('UPdate PRaent')
    props.updatePlantDetails(state, dialogValue)
  }
  return (
    <React.Fragment>
      <Grid container style={{width: '100%'}} spacing={2}>
        <Grid item lg={6} sm={12}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              fullWidth
              label="Common Name"
              value={state.name}
              onChange={e=>setState({...state, name: e.target.value})}
              onBlur={handleUpdateParent}
            />
          </FormControl>
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              fullWidth
              label="Alternative Name"
              value={state.alternateName}
              onChange={e=>setState({...state, alternateName: e.target.value})}
              onBlur={handleUpdateParent}
            />
          </FormControl>
        </Grid>
        <Grid item lg={12}>
          <TextField
            id="description"
            variant="outlined"
            value={state.description}
            onChange={e=>setState({...state, description: e.target.value})}
            onBlur={handleUpdateParent}
            label="Description"
            type="text"
            fullWidth
          />
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl variant="outlined" style={{width: '100%'}} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state.category}
              onChange={handleCategoryChange}
              onBlur={handleUpdateParent}
              label="Category"
            >
              {
                categoryList.map(unit => {
                  return (
                  <MenuItem key={unit.name} value={unit.name} >{unit.name}</MenuItem>
                )})
              }
            </Select>
        </FormControl>
        </Grid>
        <Grid item lg={6} sm={12}>
          <FormControl variant="outlined" style={{width: '100%'}} fullWidth>
            <InputLabel id="demo-simple-select-outlined-label">Sub-Category</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state.subCategory}
              onChange={e=>setState({...state, subCategory: e.target.value})}
              onBlur={handleUpdateParent}
              label="Unit"
            >
              {
                subCategoryList.map(unit => {
                  return (
                  <MenuItem key={unit} value={unit} >{unit}</MenuItem>
                )})
              }
            </Select>
        </FormControl>
        </Grid>
        <Grid item lg={12}>
        <FormControl fullWidth >
          <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            fullWidth
            options={sizeList}
            onChange={handleUpdateUOM}
            // onChange={e => setState({ ...state, notes: e.target.value })}
            onBlur={handleUpdateParent}
            disableCloseOnSelect
            style={{marginTop: 25}}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </React.Fragment>
            )}
            
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Unit(s) of Measurement" placeholder="How Do We Measure (Each, Per Foot, Per Ton, etc)" />
            )}
          />
      </FormControl>
        </Grid>
        {/* <Grid item lg={6} sm={12}>
        <Autocomplete
        freeSolo
        id={`description-input`}
        // value={state.description}
        options={sizeList}
        value={value}
        // getOptionLabel={(option) => option.name}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return 'Save ' + option.inputValue + ' as a New Item';
          }
          // Regular option
          return option.name;
        }}
        // style={{ width: 200 }}
        // onChange={handleDescriptionChange}
        onChange={(event, newValue) => {
          // console.log('Change in value:', newValue)
          event.preventDefault()
          if (newValue && newValue._id) {
            // console.log('AN Active object was selected')
            setItem(newValue)
            setState({...state, ...newValue})
          } else {
            toggleOpen(true);
            setItem({
              title: newValue,
              name: newValue
            })
          }
          if (typeof newValue === 'string') {
            setValue({
              title: newValue,
              name: newValue
            });
            // setItem({
            //   title: newValue,
            //   name: newValue
            // })
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              title: newValue.inputValue,
              name: newValue.inputValue
            });
            setItem({
              title: newValue.inputValue,
              name: newValue.inputValue,
              description: newValue.inputValue
            })
            // toggleOpen(true);
            setDialogValue({
              title: newValue.inputValue,
              year: '',
            });
          } else {
            // console.log('NEWV AL: ', newValue)
            setValue(newValue);
            if (newValue && newValue.name) {
              // setItem(newValue)
            }
          
          }
        }}
        filterOptions={(options, params) => {
          // console.log('Filtered Options', options)
          // console.log('Params', params)
          // console.log('Input value: ', params.inputValue)
          const filtered = filter(options, params);
  
          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              title: `Add "${params.inputValue}"`,
              name: 'Test This'
            });
          }
  
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
        // value={state.activeLineItem}
        fullWidth
        // InputProps={{ style: { fontSize: 20, color: '#fff', backgroundColor: '#333' } }}
        PaperComponent={({ children }) => (
          <Paper style={{ padding: 5, fontSize: 20, color: '#bbb', backgroundColor: '#fff' }}>{children}</Paper>
        )}
        // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
        renderInput={(params) => <TextField {...params}  fullWidth label="Size" variant="outlined" />}
      />
        </Grid> */}
        <Grid item lg={12} sm={12}>
        <TextField
              variant="outlined"
              fullWidth
              label="Notes"
              onChange={e => setState({ ...state, notes: e.target.value })}
              onBlur={handleUpdateParent}
            />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default PlantInventoryInputItem