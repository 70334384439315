import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { connect } from 'react-redux'
import axios from 'axios'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputMask from "react-input-mask";
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid'
import { fade, makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
// import Cards from 'react-credit-cards';
// import { createForm } from 'final-form'
// import Styles from './Styles'
import Card from './Card'
// import {
//   formatCreditCardNumber,
//   formatCVC,
//   formatExpirationDate
// } from './cardUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  textSaveLineBreaks: {
    whiteSpace: 'pre-line'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  }
}));

let requiresUpdate = 0;

function SimpleModal(props) {
  const classes = useStyles();
  // console.log('Payment Method Modal Props: ', props)
  // console.log('FOrm: ', createForm)
  // const preventDefault = event => event.preventDefault();
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const closeModal = props.closeFunc
  // const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  // const [modalStyle] = React.useState(getModalStyle);
  const user = props.user
  // console.log('Got our user: ', user)
  // const lead = props.lead
  const url = props.url
  const notification = props.notification
  // console.log(url)

const [open, setOpen] = React.useState(false)
const [client, setClient] = React.useState(props.client)
// const [confirmOpen, setconfirmOpen] = React.useState(false);
// const [selectedTour, setselectedTour] = React.useState({});
// const [newTourOpen, setnewTourOpen] = React.useState(false);
// const [tours, setTours] = React.useState([]);
// const [tourNotes, settourNotes] = React.useState('');
const [loading, setLoading] = React.useState(false);
const [locationName, setLocationName] = React.useState('');
const [individual, setIndividual] = React.useState(true);
const [address, setAddress] = React.useState('');
const [city, setCity] = React.useState('');
const [locState, setLocState] = React.useState('');
const [zipcode, setZipcode] = React.useState('');
const [state, setState] = React.useState({
  cvc: '',
  expiry: '',
  expiryInput: '',
  focused: '',
  name: '',
  number: '',
  numberInput: '',
  name: '',
  firstname: '',
  lastname: '',
  address: '',
  city: '',
  state: '',
  zipcode: '',
  client: props.client,
  customerType: ''
});

  useEffect(() => {
    // console.log('Use effect', requiresUpdate)
    // console.log('Use Effect Create Location Modal')
    // setAddress('')
    // setLocationName('')
    // setCity('')
    // setLocState('')
    // setZipcode('')
    let name = ''
    if (props.client && props.client.companyName) {
      setIndividual(false)
      name = props.client.companyName
    } else {
      name = props.client.firstName + ' ' + props.client.lastName || ''
    }
    setState({...state, 
      client: props.client,
      firstname: props.client.firstName || '',
      lastname: props.client.lastName || '',
      address: props.client.address || '',
      city: props.client.city || '',
      state: props.client.state || '',
      zipcode: props.client.zip || '',
      customerType: props.client.companyName || '',
      companyname: props.client.companyName || '',
      name: name
    })
  //   axios
  //     .get(
  //       `${url}/api/tours/getAll`
  //       )
  //     .then(({ data }) => {
  //       // console.log(data)
  //       setTours(data)
  //     }).catch(function (err) {
  //       // console.log('Error getting stuff', err)
  //     });
  }, [props.client]);


  const handleClick = (d) => {
    // console.log('Save Card Info', d)
    // console.log(state)
    setLoading(true)
    let data = state
    if (individual) {
      data.customerType = 'individual'
    } else {
      data.customerType = 'business'
    }
    // console.log(client)
    axios({
      method: 'post',
      url:  `${url}/api/customer/addPaymentMethod`,
      data: data,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {

      //   }
      // },
    }).then(response => {
      // console.log('Axios response: ', response)
      if (response && response.data.error) {
        // console.log('Error: ', response.data.error)
        props.notification({
          type: 'warning',
          title: 'We Ran Into A Problem!',
          message: response.data.error.messages.message[0]['text']
        })
      }
      if (response && response.data && response.data.API_Response) {
        // console.log('All goodl')
        let data = response.data
        if (data && data.API_Response) {
          // console.log('Got some data', data.API_Response)
          let apiResponse = data.API_Response
          if (apiResponse && apiResponse.messages && apiResponse.messages.resultCode === 'Error') {
            // console.log('ERROR Saving payment profile!!!', apiResponse.messages.message)
            let errMessage = apiResponse.messages.message[0]['text']
            props.notification({
              type: 'danger',
              title: 'Failure!',
              message: errMessage
            })
          } else {
            // console.log('Worked??', apiResponse.customerProfileId)
            props.notification({
              type: 'success',
              title: 'Success!',
              message: 'Payment Profile saved!'
            })
            setTimeout(() => { handleClose('refreshClientDetails', apiResponse.customerProfileId) }, 1500)
          }
        }
    }
      setLoading(false)
    }).catch(error => {
      // console.log('AXios error: ', error)
      setLoading(false)
    })
    
    // axios.post(
    //     `${url}/api/customer/addPaymentMethod`,
    //     state
    //     )
    //   .then(res => {
    //     // console.log('AUTH>NET RESPONSE', res)
    //     setLoading(false)
    //     if (res && res.data && res.data.error) {
    //       let data = res.data
    //       // console.log('DATA.ERROR:')
    //       // console.log(data.error)
    //       let errMessage = 'ERROR'
    //       if (data && data.error && data.error.messages) {
    //         // console.log(data.error.messages)
    //         errMessage = data.error.messages.message[0]['text']
    //       }
    //       // console.log(errMessage)
    //       setLoading(false)
    //       props.notification({
    //         type: 'danger',
    //         title: 'Failure!',
    //         message: errMessage
    //       })
    //     } else {
    //       // console.log('No res...')
    //     }
    //     if (res && res.data) {
    //       // console.log('RES.DATA: ', res.data)
    //       props.notification({
    //         type: 'success',
    //         title: 'Success!',
    //         message: 'Payment Profile saved!'
    //       })
    //     }
    //     // setTours(data)
    //   }).catch(function (err) {
    //     // console.log('ERRO: ', err)
    //   })
      //   // console.log('Error getting stuff', err)
      //   if (err && err.error) {
      //     // console.log('err.ERROR:')
      //     // console.log(err.error)
      //     let errMessage = 'ERROR'
      //     if (err && err.error && err.error.messages) {
      //       // console.log(err.error.messages)
      //       errMessage = err.error.messages.message[0]['text']
      //     }
      //     // console.log(errMessage)
      //     setLoading(false)
      //     props.notification({
      //       type: 'danger',
      //       title: 'Failure!',
      //       message: errMessage
      //     })
      //   }
      // });
    // let obj = {
    //   locationname: locationName,
    //   address: address,
    //   city: city,
    //   state: locState,
    //   zipcode: zipcode
    // }
    // requiresUpdate++
    // console.log('Location Object', obj)
    // setOpen(false)
    // closeModal(obj)
  };
  const handleClickAddNew = (d) => {
    // console.log('Save location and Create a New')
    
    let obj = {
      locationname: locationName,
      address: address,
      city: city,
      state: locState,
      zipcode: zipcode
    }
    // console.log('Location Object', obj)
    // SEND TO BACK END
    requiresUpdate++
    setOpen(false)
    closeModal(obj, 'new')
    setTimeout(function (){
      // console.log('Open it again')
      setOpen(true)
    }, 500)
  };

  const handleClickConfigure = function (e) {
    // console.log('Save location and CONFIGURE', e)
    requiresUpdate++
    let obj = {
      locationname: locationName,
      address: address,
      city: city,
      state: locState,
      zipcode: zipcode
    }
    // console.log('Location Object', obj)

    // Send to back end

    requiresUpdate++
    setOpen(false)
    closeModal(obj, 'configure')
  };

  // const handleChange = (name) => (event) => {
  //   // console.log('Handle Change')
  //   // console.log('Name: ', name)
  //   // console.log('Event: ', event)
  //   // this[name].apply
  //   // this.setState({
  //   //   [name]: event.target.value,
  //   // })
  // }

  // const handleClickChoose = (row) => {
  //   // console.log('Choosing time')
  //   // console.log(e.target)
  //   // console.log(row)
  //   // e.preventDefault();
  //   setconfirmOpen(true)
  //   setselectedTour({date: row.date, _id: row._id, time: row.time, notes: row.notes})
  //   // console.log(selectedTour)
  // }
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (d, f) => {

    setOpen(false);
    setState({...state,
      number: '',
      numberInput: '',
      cvc: ''
    })
    // console.log(d)
    if (d) props.updateParent(d, f)
  };

  const divStyle = {
    // margin: '40px',
    // border: '5px solid pink',
    display: 'inline'
  };

  const onSubmit = async values => {
    // console.log(values)
    // console.log(state)
  }

  const handleSubmit =  async (e) => {
    // console.log('Handle submit: ', e)
  }

  const updateCardNumber = (value) => {
    // console.log('Update mask', value)
    let result = value.replace(/[^0-9]/g,"")
    // console.log('Nums only', result)
    // let ourResult = ''
    // if (result && parseInt(result[0])) {
    //   // console.log('Is a number!')
    //   ourResult = result.join("")
    // }
    setState({...state, numberInput: value, number: result })
  }
  const updateCardExpiration = (value) => {
    // console.log('Update mask', value)
    let result = value.replace(/[^0-9]/g,"")
    // console.log('Nums only', result)
    // let ourResult = ''
    // if (result && parseInt(result[0])) {
    //   // console.log('Is a number!')
    //   ourResult = result.join("")
    // }
    setState({...state, expiryInput: value, expiry: result })
  }
  return (
    <div style={ divStyle }>
     <Button size="small" variant="contained" color="secondary" onClick={() => { handleOpen() }}>Add Credit Card</Button>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        style={{overflow: 'hidden !important'}}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">Please Provide Payment Information</DialogTitle>
        <DialogContent> 
          {/* <Typography variant="subtitle1" id="simple-modal-descriptions">
          
          </Typography> */}
          <Card
          cvc={state.cvc}
          expiry={state.expiry}
          focused={state.focus}
          name={state.name}
          number={state.number}
        />
          <ValidatorForm
                id="myForm"
                // ref="myForm"
                onSubmit={ handleSubmit }
                onError={ errors => console.log(errors) }
                // className={ styles.content }
              >
                <Grid container>
                <Grid item lg={12} xs={12} className={classes.gridItem}> 
                  Choose Individual or Business: <br />
                  <FormControlLabel
                        control={
                          <Switch
                            checked={individual}
                            onChange={() => setIndividual(!individual)}
                            name="Individual"
                            color="primary"
                          />
                        }
                        label={individual ? 'Individual' : 'Business'}
                      />
                  </Grid>
                  { individual ? 
                    (
                      <React.Fragment>
                      <Grid item lg={6} xs={6} className={classes.gridItem}> 
                          <TextValidator
                            label="Accountholder First Name"
                            onChange={ e => setState({...state, firstname: e.target.value, name: e.target.value + ' ' + state.lastname })}
                            name="cardholderfirstname"
                            value={ state.firstname }
                            validators={[
                              'required',
                              'minStringLength:2',
                              'maxStringLength:100'
                            ]}
                            errorMessages={[
                              'this field is required',
                              'min 2 characters',
                              'max 100 characters'
                            ]}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={6} xs={6} className={classes.gridItem}> 
                                    
                          <TextValidator
                            label="Accountholder Last Name"
                            onChange={ e => setState({...state, lastname: e.target.value, name: state.firstname + ' ' + e.target.value})}
                            name="cardholderlastname"
                            value={ state.lastname }
                            validators={[
                              'required',
                              'minStringLength:2',
                              'maxStringLength:100'
                            ]}
                            errorMessages={[
                              'this field is required',
                              'min 2 characters',
                              'max 100 characters'
                            ]}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        </React.Fragment>
                      ) : (
                        <Grid item lg={12} xs={12} className={classes.gridItem}> 
                                    
                          <TextValidator
                            label="Company Name"
                            onChange={ e => setState({...state, companyname: e.target.value, name: e.target.value })}
                            name="companyname"
                            value={ state.companyname }
                            validators={[
                              'required',
                              'minStringLength:2',
                              'maxStringLength:100'
                            ]}
                            errorMessages={[
                              'this field is required',
                              'min 2 characters',
                              'max 100 characters'
                            ]}
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                      )
                    }
                    <Grid item lg={12} xs={12} className={classes.gridItem}> 
                        <TextField
                      label="Billing Address"
                      fullWidth
                      value= {state.address}
                        onChange={ e => setState({...state, address:e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />
                    </Grid>
                    <Grid item lg={6} xs={6} className={classes.gridItem}>
                      <TextField
                        label="Billing City"
                        fullWidth
                        value= {state.city}
                        onChange={ e => setState({...state, city:e.target.value } ) }
                        // variant="outlined"
                        // value= {state.service.time}
                        // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                        />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                      <TextField
                        label="Billing State"
                        fullWidth
                        value= {state.state}
                        onChange={ e => setState({...state, state:e.target.value } ) }
                      />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                    <TextField
                        label="Billing Zipcode"
                        fullWidth
                        type="number"
                        // variant="outlined"
                        value= {state.zipcode}
                        onChange={ e => setState({...state, zipcode:e.target.value } ) }
                        />
                    </Grid>

                    <Grid item lg={12} xs={12} className={classes.gridItem}>
                        <InputMask mask="9999-9999-9999-9999" maskPlaceholder="_" onChange={e => updateCardNumber(e.target.value)} value={state.numberInput} >
                        {
                          () => {
                            return (
                              <TextValidator
                                label="Card Number"
                                // onChange={ e => setState({...state, number: e.target.value }) }
                                name="cardnumber"
                                // value={ state.number }
                                validators={[
                                  'required',
                                  'minStringLength:15',
                                  'maxStringLength:20'
                                ]}
                                errorMessages={[
                                  'this field is required',
                                  'min 15 characters',
                                  'max 20 characters'
                                ]}
                                margin="dense"
                                fullWidth
                              />
                            )
                          }
                        }
                      
                      
                      </InputMask>
                    </Grid>
                    <Grid item lg={6} xs={6} className={classes.gridItem}>
                    <InputMask mask="99/99" maskPlaceholder="_" onChange={e => updateCardExpiration(e.target.value)} value={state.expiryInput} >
                  
                        {
                          () => {
                            return (
                              <TextValidator
                                  label="Expiration"
                                  // onChange={ e => setState({...state, expiry:e.target.value } ) }
                                  name="expiry"
                                  // value={ state.expiry }
                                  validators={[
                                    'required',
                                    'minStringLength:4',
                                    'maxStringLength:5'
                                  ]}
                                  errorMessages={[
                                    'this field is required',
                                    'min 4 characters',
                                    'max 5 characters'
                                  ]}
                                  margin="dense"
                                  fullWidth
                                />
                            )
                          }
                        }
                          </InputMask>
                    </Grid>
                    <Grid item lg={6} xs={6} className={classes.gridItem}> 
                          <TextValidator
                        label="CVC"
                        onChange={ e => setState({...state, cvc:e.target.value } ) }
                        name="cvc"
                        value={ state.cvc }
                        validators={[
                          'required',
                          'minStringLength:3',
                          'maxStringLength:4'
                        ]}
                        errorMessages={[
                          'this field is required',
                          'min 3 characters',
                          'max 4 characters'
                        ]}
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
               
              
               
                
                </Grid>
          </ValidatorForm>
            <p>
            {
                  loading ?
                  <Button variant="contained" fullWidth color="secondary" disabled> <CircularProgress color="primary" size={ 24 } /></Button> :
                  <Button variant="contained" fullWidth color="secondary" onClick={handleClick}>Save</Button>
                }
            </p>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          {/* <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(SimpleModal))