import React from 'react'
import styled from 'styled-components'
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper'

const Container = styled(Box)`
  display: flex;
  flex-direction: row;
  font-size: 16px;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  color: #777;
`;

const LeftSide = styled(Box)`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
margin: 5px;
height: 500px;
width: 50%;
`;

const RightSide = styled(Box)`
display: flex;
justify-content: center;
margin: 5px;

height: 500px;
`;

const RestrictedContent = () => {
  return (
    <Paper style={{height: '80vh', margin: '10px'}}>
      <h1>Restricted Content - You Are Not Authorized</h1>
      <Container>
        <LeftSide>
          <h2>Unauthorized Request</h2>

          <h4>
          It appears that you have requested access to a resource that requires specific authorization.

          Please see your Administrator about updating your priveleges.
        
          </h4>
          <div
          onClick={() => { window.location.href = "/" }}
          style={{cursor: 'pointer', textDecoration: 'underline'}}
        >
          Return Home
        </div>
        </LeftSide>
        <RightSide>
          <img src="../../restricted.png" />
        </RightSide>
      </Container>
    </Paper>
  )
}
export default RestrictedContent