import React, { useRef, useState } from 'react';
import { connect } from 'react-redux'
import axios from 'axios'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { Card, CardContent, Typography } from '@material-ui/core'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Fade from '@material-ui/core/Fade';
// import CheckInButton from './CheckInButton'
import { Button, CircularProgress }  from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DriverLogo from '@material-ui/icons/DirectionsCar';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ClockOutIcon from '@material-ui/icons/TimerOff';
import Tooltip from '@material-ui/core/Tooltip';
import CrewModal from '../Crews/CrewModal'
// import EmployeeModal from './EmployeeDetailsModal'
// import EcoIcon from '@material-ui/icons/Eco';
import TempIcon from '@material-ui/icons/Title';
import SickIcon from '@material-ui/icons/Hotel';
import PTOIcon from '@material-ui/icons/InsertEmoticon';
import NoCallIcon from '@material-ui/icons/Block';
import VacationIcon from '@material-ui/icons/Flight';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import TestComponent from './TestComponent'
import EventNoteIcon from '@material-ui/icons/EventNote';
// import ForemanIcon from '@material-ui/icons/Facebook';
// import ForemanIcon from '@material-ui/icons/SupervisedUserCircle';
// import ForemanIcon from '@material-ui/icons/SupervisorAccount';
import ForemanIcon from '@material-ui/icons/RecordVoiceOver';
// import MultiDriversModal from './MultiDriversModal'
import { setCrew } from '../../actions/connectionActions'
// import Schedule from '../Schedule/Schedule'
import { socket } from '../SocketIo/Socket'
import ViewWODetails from './ViewWODetails'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import Autocomplete from '@material-ui/lab/Autocomplete';
// const Container = styled.div`
//   margin: 2px;
//   width: 100%;
//   height: 100%;
// `;

const Container = styled(Paper)`
  min-height: 85vh;
  && {
    color: grey;
    background-color: white;
  }`

const HeaderBar = styled.div`
  display: flex;
  justify-content: justify;
  align-items: center;
  margin: 5px;
  align-self: stretch;
  `;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 5px;
  flex-wrap: wrap;
`;

const CrewBoxFooter = styled(Box)`
display: flex;
flex-grow: 1;
justify-content: space-between;
`;

const FooterLeftSide = styled(Box)`
display: flex;
justify-content: flex-start;
align-items: flex-start;
margin: 5px;
`;

const FooterRightSide = styled(Box)`
display: flex;
justify-content: flex-end;
align-items: flex-end;
margin: 5px;
`;

const ClockIn = styled.div`
  margin-left: auto;
  margin-bottom: -21px;
`;

const CrewBox = styled.div`
background-color: inherit;
-webkit-user-select: none;
-ms-user-select: none;
user-select: none;
min-height: 200px;
`;

const OutterContainer = styled.div`
  overflow-x: scroll;
  overflow-y: hide;
  max-height: 75vh;
`;

const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
`;

const CrewContainer = styled.div`
  background-color: rgb(119 156 164);
  border: 3px solid rgb(81 110 118);
  border-radius: 5px;
  padding: 5px;
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  margin: 5px;
  margin-bottom: 15px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const CrewBoxOld = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid darkgrey;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  min-height: 200px;
  background-color: inherit;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const CrewLine = styled.div`
  background-color: ${props => (props.pendingInvoicing ? 'green' : props.lastMonth ? '#e32c10' : props.isLate ? 'yellow' : '#eee')};
  display: flex;
  color: ${props => (props.pendingInvoicing ? '#eee' : props.lastMonth ? '#eee' : 'inherit')};
  flex-direction: column;
  align-items: flex-start;
  border: 2px solid rgb(81 110 118);
  border-radius: 3px;
  padding: 2px;
  margin-bottom: 3px;
  &:hover {
    border: 2px solid white;
    background-color: rgb(172 202 205);
    color: #fff;
  }
`;

const TopInfo = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 1.15em;
`;

const BottomInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: stretch;

`;

const WOName = styled.div`
  font-size: 1.1em;
  font-weight: 500;
`;

const WOCommunity = styled.div`
  font-size: 0.8em;
  align-self: flex-start;
  font-weight: bold;
`;

const WOType = styled.div`
  align-self: flex-end;
`;
const CrewItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
`;

const EmpBox = styled.div`
  border: ${props => ((props.isClockedIn || props.isDragging) ? '3px solid #444' : '2px solid lightgrey')};
  color: ${props => ((props.absenceType || props.isCheckedIn || props.isClockedIn)  ? '#ddd;' : '#444;')};
  border-radius: 5px;
  padding: 4px;
  margin: 2px;
  background-color: ${props => (
    props.isDragging ? '#34eb80': (
      props.absenceType ? (
        props.absenceType === 'off' ? 'rgba(149,49,188,1)' : props.absenceType === 'nocall' ? 'rgba(134,14,8,1)' : props.absenceType === 'approved' ? 'rgba(73,128,36,1)' : 'blue'
      ) : props.isCheckedIn ? (props.isClockedIn ? '#a63e06' : '#c4c428') : 'inherit'))};
`;

const EmployeeStuff = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
`;

const EmpDetails = styled.div`
  margin-left: auto;
  padding-left: 10px;
`;

const CrewName = styled.div`
  font-size: 1.3em;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
`;

const CheckedInBox = styled.div`
  font-size: 2em;
  color: darkgrey;
`;

const WorkorderViewByCrew = (props) => {
  const [state, setState] = React.useState({
    crews: [],
    loading: false,
    employee: {},
    empModalOpen: false,
    employees: [],
    checkedInEmployees: [],
    clockedInEmployees: [],
    absentEmployees: [],
    activeCrew: {},
    division: 'Landscape',
    workorders: [],
    crewList: [],
    services: []
  })
  const [division, setDivision] = React.useState('Landscape')
  const [crewModalOpen, setCrewModalOpen] = React.useState(false)
  const [activeCrew, setActiveCrew] = React.useState({})
  const [showWOModal, setShowWOModal] = React.useState(false)
  const [activeWO, setActiveWO] = React.useState({})
  const [crewScheduleOpen, setCrewScheduleOpen] = React.useState(false)
  const [empModalOpen, setEmpModalOpen] = React.useState(false)
  const [multiDriverModalOpen, setMultiDriverModalOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const getCrewsAndShit = async (divisionPassed, entityToUse) => {
    setLoading(true)
  // console.log('DIvision Passed', divisionPassed)
    let divisionToChoose = division
    if (division) divisionToChoose = divisionPassed
    // console.log('Get Crews: ', divisionToChoose, divisionPassed)
    if (divisionToChoose) {
      axios
      .get(
        `${props.url}/api/crews/getOpenWorkorders?division=${divisionToChoose}&entity=${entityToUse}`
        )
      .then(({ data }) => {
        setLoading(false)
      // console.log('Got Crew Data', data)
        // setLeads(data)
        // setEmployees(data.employees)
        // let empsCheckedIn = data.employees.filter(item => { return item.isCheckedIn })
        // // setCheckedInEmployees(empsCheckedIn)
        // let empsClockedIn = data.employees.filter(item => { return item.isClockedIn })

        // let empsAbsent = data.employees.filter(item => { return item.absent })
        // // setClockedInEmployees(empsClockedIn)
        // // setCrews(data.crews)
        if (data.crews) {
          setState({...state, crews: data.crews, crewList: data.crewList, services: data.services })
        } else {
          setState({...state, crews: [], crewList: [], services: [] })
        }

      }).catch(function (err) {
        // console.log('Error getting stuff', err)
      });
    }
    // if ()

  }

  React.useEffect(() => {
    const preferredDivision = localStorage.getItem('division')
  // console.log('Preferred Division: ', preferredDivision)
    if (preferredDivision) {
      setDivision(preferredDivision)
      setState({...state, division: preferredDivision})
      getCrewsAndShit(preferredDivision, 'All')
    } else {
      setDivision('Maintenance')
      setState({...state, division: 'Maintenance'})
      // setState({...state, division: 'Maintenance'})
      getCrewsAndShit('Maintenance', 'All')
    }

  socket.on('dailyRoster', function (data) {
  // console.log('GOT A SOCKET MESSAGE: ', data)
    // updatedCrew ---> store this as a crew
    if (data.type === 'updatedCrew') {
      // console.log('UPdate the crews now - our division', division)
      // console.log('PReferred Division? ', preferredDivision)
      // console.log()
      const savedDivision = localStorage.getItem('division')
    // console.log('Saved Division: ', savedDivision)
      getCrewsAndShit(savedDivision, 'All')
    }
  })
  return () => {
    socket.off('dailyRoster')
  }
}, [])

  // React.useEffect(() => {
  //   // console.log('Get the crews and shit')
  //   const preferredDivision = localStorage.getItem('division')
  // // console.log('Preferred Division: ', preferredDivision)
  //   if (preferredDivision) {
  //     setDivision(preferredDivision)
  //     // setState({...state, division: preferredDivision})
  //     getCrewsAndShit(preferredDivision)
  //   } else {
  //     // setState({...state, division: 'Maintenance'})
  //     getCrewsAndShit('Maintenance')
  //   }
  // }, [])

  // React.useEffect(() => {
  //   // console.log('Get the crews and shit')
  //   const preferredDivision = localStorage.getItem('division')
  // // console.log('Preferred Division: ', preferredDivision)
  //   if (preferredDivision) {
  //     // setState({...state, division: preferredDivision})
  //     // getCrewsAndShit(preferredDivision)
  //     setDivision(preferredDivision)
  //   }
  // }, )

  const onDragStart = () => {
    // // console.log()
    document.body.style.color = 'orange';
    document.body.style.transition = 'background-color 0.2s ease'
  }

  const onDragUpdate = update => {
    const { destination } = update
    const opacity = destination ? destination.index / Object.keys(state.crews).length : 0;
    document.body.style.backgroundColor = `rgba(153, 141, 217, ${opacity})`
  }

  const onDragEnd = result => {
    document.body.style.color = 'inherit';
    document.body.style.backgroundColor = 'inherit'
    // console.log('on Drag End', result)
    const { destination, source, draggableId } = result;
    // console.log('Destination: ', destination)
    // console.log('Source: ', source)
    // console.log('ID: ', draggableId)
    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    // Crew Start
    let allCrews = state.crews
    let startIndex = allCrews.findIndex(crewItem =>  (crewItem['_id'].toString() === source.droppableId.toString()));
    const start = allCrews[startIndex]
    // console.log('Crew Start: ', start)
    // Crew Finish
    let finishIndex = allCrews.findIndex(crewItem =>  (crewItem['_id'].toString() === destination.droppableId.toString()));
    const finish = allCrews[finishIndex]
  // console.log('Crew Finish', finish)

    // Update FROM Crew
    // const fromCrew = Array.from(start.assignedEmployees)
    let employeeBeingDragged = start.assignedEmployees[source.index]
    // console.log('Employee Being Dragged: ', employeeBeingDragged)
    start.assignedEmployees.splice(source.index, 1)
    // const newFromCrew = {
    //   ...start,
    //   assignedEmployees: fromCrew
    // }
  // console.log('Finish Emps: ', finish.assignedEmployees)
    finish.assignedEmployees.splice(destination.index, 0, employeeBeingDragged)
    // setCrews(allCrews)
    assignEmployeeToCurrentCrew(employeeBeingDragged, finish)
    setState({...state, crews: allCrews})
  }

  const assignEmployeeToCurrentCrew = (employee, crew) => {
    // console.log('Assign this guy to this crew: ', employee.firstName)
    // console.log('Crew: ', crew.name)
    axios({
      method: 'post',
      url:  `${props.url}/api/roster/moveEmployee`,
      data: {
        employeeId: employee._id,
        crewId: crew._id
      }
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Checked In Employee: ', response.data)
    })
  }

  const handleOpenCheckInModal = () => {
    // setOpen(true)
  }
  const closeCheckInModal = () => {
    // setOpen(false)
  }

  const refreshList = () => {
    // setOpen(false)
    getCrewsAndShit(null, 'All')
  }

  const openEmployeeModal = (emp) => {
    // console.log('Open employee modal: ', emp)
    setState({...state, employee: emp, empModalOpen: true})
    // setActiveEmp(emp)
    setEmpModalOpen(true)
    // axios({
    //   method: 'get',
    //   url:  `${props.url}/api/employees/getRecentTimeClockData?id=${emp._id}&limit=8`,
    // }).then((response) => {
    //   // console.log('Time clock data: ', response.data)
    //   // setTimeClockData(response.data)
    //   emp.timeClockData = response.data
    //   setActiveEmp(emp)
    //   setEmpModalOpen(true)
    // }).catch((err) => {
    //   // console.log('Time Clock Error: ', err)
    // })

  }

  const openCrewModal = (e, crew) => {
    e.preventDefault();
    // console.log(e.currentTarget);
    // console.log('Open Crew Modal', crew)
    setCrewModalOpen(true)
    setActiveCrew(crew)
  }

  const updateFromCrewModal = (d) => {
    // console.log('Repsonse from Crew Modal', d)
    if (d === 'closeModal') setCrewModalOpen(false)
    // getCrewsAndShit(null, 'All')
  }

  const updateFromEmployeeModal = (d) => {
    // console.log('Update From Employee Modal', d)
    getCrewsAndShit(null, 'All')
    setState({...state, employee: {}})
    // setActiveEmp({})
    // if (d === 'closeModal') 
    setEmpModalOpen(false)
  }

  const handleCheckIn = (crew, index, empIndex, emp) => {
    // console.log('Check In: ', emp)
    // console.log('CrEW: ', crew.name)
    // console.log('Index: ', index)
    // console.log('Employee Index;', empIndex)
    let newCrews = state.crews
    let crewToFix = newCrews[index]
    let emps = crewToFix['assignedEmployees']
    let empToUpdate = emps[empIndex]
    empToUpdate.isCheckedIn = true
    empToUpdate.absent = null
    // console.log('Update Employess: ', newCrews)
    let empsCheckedIn = state.checkedInEmployees
    empsCheckedIn.push(emp)
    // setCheckedInEmployees(empsCheckedIn)
    setState({...state, crews: newCrews, checkedInEmployees: empsCheckedIn})
    axios({
      method: 'post',
      url:  `${props.url}/api/roster/checkInEmployee`,
      data: emp,
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Checked In Employee: ', response.data)
      props.notification({
        type: 'success',
        title: 'Checked-In!',
        message: 'They Are Here and Accounted For!!'
      })
      // getCrewsAndShit()
    })
  }

  const handleClockIn = (crew, index, empIndex, emp) => {
  // // console.log('Clock In: ', emp)
  // // console.log('CrEW: ', crew.name)
  // // console.log('Index: ', index)
  // // console.log('Employee Index;', empIndex)
  let newCrews = state.crews
  let crewToFix = newCrews[index]
  let emps = crewToFix['assignedEmployees']
  let empToUpdate = emps[empIndex]
  empToUpdate.isClockedIn = true
  // // console.log('Update Employess: ', newCrews)
  let empsClockedIn = state.clockedInEmployees
  empsClockedIn.push(emp)
  // setClockedInEmployees(empsClockedIn)
  setState({...state, crews: newCrews, clockedInEmployees: empsClockedIn})
  axios({
    method: 'post',
    url:  `${props.url}/api/roster/clockInEmployee`,
    data: emp,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Checked In Employee: ', response.data)
    props.notification({
      type: 'success',
      title: 'Employee Clocked-In!',
      message: 'They Are On The Clock!!'
    })
    // getCrewsAndShit()
  })
}



const updateFromMultiDrivers = (kind, d) => {
  // if (kind==='closeModal') {
    setMultiDriverModalOpen(false)
  // }
  if (kind === 'save') {
  // console.log('Multi Drivers - who is our driver?', d)
    axios({
      method: 'post',
      url:  `${props.url}/api/roster/clockOutCrew`,
      data: {
        userId: props.user._id,
        crewId: state.activeCrew._id,
        driver: d
      },
      // validateStatus: (status) => {
      //   // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Clocked Out Crew: ', response.data)
      setState({...state, loading: false})
      // getCrewsAndShit()
      getCrewsAndShit(division, 'All')
      props.notification({
        type: 'success',
        title: 'Crew Has Been Clocked Out!',
        message: 'Well Done!!'
      })
      // setState({...state, crews: response.data.crews})
    })
  }
}


const handleChangeDivisions = (division) => {
// console.log('Change Division: ', division)
  if (division) {
    localStorage.setItem('division', division)
    // setState({...state, division: division})
    setDivision(division)
    getCrewsAndShit(division, 'All')
  }
}

  const handleViewWO = (wo, crew) => {
  // console.log('View this wo', wo)
    setActiveWO(wo)
    setActiveCrew(crew)
    setShowWOModal(true)
  }

  const updateFromCrewSchedule = () => {
    setCrewScheduleOpen(false)
    props.setCrew(null)
  }
  const AbsenceDisplay = ({ type }) => {
    if (type === 'vacation') {
      return <span style={{ marginLeft: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center' }} ><Tooltip title="Vacation"><VacationIcon style={{fontSize: '10px'}} /></Tooltip></span>
    }
    if (type === 'approved') {
      return <span style={{ marginLeft: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center' }} ><Tooltip title="PTO"><PTOIcon style={{fontSize: '10px'}} /></Tooltip></span>
    }
    if (type === 'off') {
      return <span style={{ marginLeft: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center' }} ><Tooltip title="Called/Off"><SickIcon style={{fontSize: '10px'}} /></Tooltip></span>
    }
    if (type === 'nocall') {
      return <span style={{ marginLeft: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center' }} ><Tooltip title="No-Call/No-Show"><NoCallIcon style={{fontSize: '10px'}} /></Tooltip></span>
    }
    return null
  }

  const WorkorderList = ({ workorders, crew }) => {
  // console.log(workorders)
    const today = new Date()
    const pastDue = moment(today).subtract(2, 'days')
    const lastMonthEnd = moment(today).startOf('month')
  // console.log('Last month::: ', lastMonthEnd)
    // console.log('Past Due', pastDue)
    return (
      workorders.map(( item, index ) => {
      // console.log('WO: ', item)
        let isLate = false
        let pendingInvoicing = false
        let lastMonth = false
        if(moment(item.scheduledDate) < lastMonthEnd) {
        // console.log('last month')
          lastMonth = true
        }
        if (moment(item.scheduledDate) < pastDue) {
        // console.log('This is late!')
          isLate = true
        }

        if (item.workComplete) {
          pendingInvoicing = true
        }
        return (

        <CrewLine key={item._id} lastMonth={lastMonth} isLate={isLate} pendingInvoicing={pendingInvoicing} index={index} onDoubleClick={(e) => handleViewWO(item, crew)}>
          <TopInfo>
            <WOName>{item.jobName}</WOName>
          </TopInfo>
          <BottomInfo>
                
                <WOCommunity>{item.communityName}</WOCommunity>
                <WOType>{item.serviceName}</WOType>
          </BottomInfo>
          {/* <CrewItem>{item.jobName}</CrewItem>
          <CrewItem>{item.communityName}</CrewItem>
          <CrewItem>{item.serviceName}</CrewItem> */}
        </CrewLine>
        )
      } 
    ))
  }

  const CrewList = (props) => {
    const [isDragging, setDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const scrollContainer = useRef(null);
  
    const handleMouseDown = (e) => {
      console.log('Dragging')
      setDragging(true);
      setStartX(e.clientX);
    };
  
    const handleMouseUp = () => {
      console.log('Stop')
      setDragging(false);
    };
  
    const handleMouseMove = (e) => {

      if (!isDragging) return;
      console.log('mobe...')
      const newX = e.clientX;
      const scrollLeft = scrollContainer.current.scrollLeft;
      scrollContainer.current.scrollLeft = scrollLeft - (newX - startX);
      setStartX(newX);
    };

    return (
      <MainContainer
        style={{
          display: 'flex',
          overflowX: 'auto',
          cursor: 'grab'
        }}
        ref={scrollContainer}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseMove={handleMouseMove}
      >

       { state.crews.map((crew, index) => (
        
            <CrewContainer key={crew._id}>
              <Grid container style={{ verticalAlign: 'top'}}>
                <Grid item lg={12}>
                  <CrewName onDoubleClick={(e) => openCrewModal(e, crew)}>{crew.name} </CrewName>
                </Grid>
              </Grid>

                
              <CrewBox >
                <WorkorderList workorders={crew.wos} crew={crew} />            
            </CrewBox>

                       
       
            </CrewContainer>
         
        ))}
      </MainContainer>
      
    )
  }

  const handleUpdateFromWODetails = (d) => {
    if (d === 'closeModal') setShowWOModal(false)
    // getCrewsAndShit(division, 'All')
  }

  const handleUpdateVisibleEntity = (newEntity) => {
  // console.log('New entity', newEntity)
    getCrewsAndShit(division, newEntity._id)
  }

  return (
    <div style={{padding: '8px', margin: '5px'}}>
      <h1>Workorders By Crew</h1>
      {/* <MultiDriversModal open={multiDriverModalOpen} crew={state.activeCrew} updateParent={updateFromMultiDrivers} /> */}
      <ViewWODetails crew={activeCrew} open={showWOModal} workorder={activeWO} updateParent={handleUpdateFromWODetails} crews={state.crewList} services={state.services} />
      <Container>
        {
          (props.user && props.user.entities && props.user.entities.length > 1) ? (
            <Grid container style={{padding: '10px'}}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ChooseEntityComponent allowAll={true} title={'Visible Entities'} disabled={loading} user={props.user} updateParent={handleUpdateVisibleEntity} />
            </Grid>
          </Grid>
          ) : ''
        }
        <Grid container>
          <Grid item lg={6}>

            <HeaderBar>
         

               </HeaderBar>
            </Grid>
          <Grid item lg={2}> </Grid>
          <Grid item lg={4}>
          <FormControl fullWidth
             >
                
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={division}
                  fullWidth
                  onChange={(e) => handleChangeDivisions(e.target.value)}
                  // onChange={(e) => setState({...state, division: e.target.value })}
                >
                  <MenuItem value={'Maintenance'}>Maintenance</MenuItem>
                  <MenuItem value={'Landscape Install'}>Landscape</MenuItem>
                  <MenuItem value={'Specialty'}>Specialty</MenuItem>
                  <MenuItem value={'Irrigation and Lighting'}>Irrigation and Lighting</MenuItem>
                  <MenuItem value={'Yard'}>Yard</MenuItem>
                </Select>
                <FormHelperText>Crews To Display</FormHelperText>
              </FormControl>
              </Grid>
            
      
        </Grid>

         <OutterContainer>
          {
            loading ? <CircularProgress style={{ textAlign: 'center', margin: '30px' }} color="primary" size={ 96 } /> : <CrewList crewsToFilter={state.crews} />
          }
         </OutterContainer>
                

    

      </Container>
    </div>
  ) 
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCrew: (crew) => { dispatch(setCrew(crew)) }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkorderViewByCrew)