import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import SuppliesUsedModal from '../BHL/SuppliesUsedModal'
import TakeOffsUsedModal from '../BHL/TakeOffsUsedModal'
import ShiftsToChooseFromList from './ShiftsToChooseFromList'
import ReconcileShiftToWorkorderModal from './ReconcileShiftToWorkorderModal'
// import { setEmployees } from '../../actions/connectionActions'
// import { setEmployees } from '../../actions/connectionActions'
import TodayIcon from '@material-ui/icons/Today';
import AddPaymentModal from './AddPaymentModal'
import { setEmployees } from '../../actions/connectionActions'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const filter = createFilterOptions();
const OPTIONS_LIMIT = 5

const filterOptions = (options, state) => {
  return filter(options, state).slice(0, OPTIONS_LIMIT);
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: '20px',
    zIndex: 8
  },
  paper: {
    width: '100%',
    minWidth: '500px',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const InvoiceTable = (props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Invoice Number</TableCell>
          <TableCell>Invoice Date</TableCell>
          <TableCell>Sub-Total</TableCell>
          <TableCell>Taxes</TableCell>
          <TableCell>Total Due</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Notes</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {
          props.invoices.map(invoice => {
              return (
              <TableRow key={invoice._id}>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell>{moment(invoice.created_at).format('MM/DD/YYYY')}</TableCell>
                <TableCell>{currencyFormat(invoice.amount)}</TableCell>
                <TableCell>{currencyFormat(invoice.taxAmount)}</TableCell>
                <TableCell>{currencyFormat(invoice.totalDue)}</TableCell>
                <TableCell>{invoice.status}</TableCell>
                <TableCell>{invoice.notes}</TableCell>
              </TableRow>
            )
          })
        }
      </TableBody>
    </Table>
  )
}
const defaultValues = {
  onSite: null,
  offSite: null,
  hoursWorked: 0,
  travelTime: 0,
  notes: '',
  servicesPerformed: [],
  workorder: {},
  shifts: [],
  counter: 0,
  selected: [],
  jobTotal: 0
}

const EditProgressPaymentsModal = (props) => {
  // console.log('HoursWorkedModal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState(defaultValues)
  const [clientList, setClientList] = React.useState([])
  const [client, setClient] = React.useState({})
  const [employee, setEmployee] = React.useState({})
  const [crew, setCrew] = React.useState({})
  const [crews, setCrews] = React.useState([])
  const [shifts, setShifts] = React.useState([])
  const [servicesPerformed, setServicesPerformed] = React.useState([])
  const [suppliesUsed, setSuppliesUsed] = React.useState([])
  const [showSuppliesUsedModal, setShowSuppliesUsedModal] = React.useState(false)
  const [clientWorkorders, setClientWorkorders] = React.useState([{serviceName: '', jobName: '', jobNumber: ''}])
  const [workorder, setWorkorder] = React.useState({})
  const [job, setJob] = React.useState({})
  const [selectedShifts, setSelectedShifts] = React.useState([])
  const [showReconcileShiftModal, setShowReconcileShiftModal] = React.useState(false)
  const [payments, setPayments] = React.useState([])
  const [invoices, setInvoices] = React.useState([])
  const [showModal, setShowModal] = React.useState(false)
  const [totalPayments, setTotalPayments] = React.useState(0)
  // const [state, setState] = React.useState({
  //   counter: 0
  // })
  const serviceRef = React.useRef(null)

  React.useEffect(() => {
    let isMounted = true
    async function fetchPayments (jobId) {
      setLoading(true)
      axios.get(`${props.url}/api/payment/getByJob?job=${jobId}`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted && response.data.payments) setPayments(response.data.payments)
          if (isMounted && response.data.invoices) {
            setInvoices(response.data.invoices)
            let tot = 0
            for (const invoice of response.data.invoices) {
            // console.log('%d + %d = : ', tot, invoice.totalDue)
            // console.log('PLUS')
              tot = parseFloat(tot).toFixed(2) - 0 + parseFloat(invoice.totalDue).toFixed(2)
            // console.log('Tot2: ', tot)
            }
          // console.log('TOTAL: ', tot)
            setTotalPayments(tot)
          }
        }
      })
    }
    // console.log('Selected Shifts Changed:', selectedShifts)
    // console.log('Job Changed lets get the totals')
    if (job && job._id) {
      fetchPayments(job._id)
      let total = 0
      for (const wo of props.workorders) {
        if (wo.job.toString() === job._id.toString()) {
          // console.log('This is our guy!!', wo)
          let tot = wo.price
          total = total - 0 + tot
          if (wo.taxAmount && wo.taxAmount > 0) total = total - 0 + wo.taxAmount
          // console.log('new Total: ', total)
        }
      }
      if (isMounted) setState({...state, jobTotal: total })
    }

    return () => {
      isMounted = false
    }
  }, [job])

  React.useEffect(() => {
    let isMounted = true
    // const fetchClients = async () => {
    //   setLoading(true)
    //   axios.get(`${props.url}/api/customer/clients`).then((response, status, data) => {
    //     setLoading(false)
    //     if (response && response.data) {
    //       if (isMounted) setClientList(response.data)
    //     }
    //   })
    // }
    // fetchClients()
    setClientList(props.clients)
    setCrews(props.crews)
    return () => {
      isMounted = false
    }
  }, [props])

  React.useEffect(() => {
    // console.log('Client Changed - get workorders for ', client)
    let isMounted = true

    async function fetchWos(id) {
      // console.log('Get id:', id)
      setLoading(true)
      axios.get(`${props.url}/api/jobs/getClientWorkorders?id=${id}`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          // console.log('Res', response.data)
          if (isMounted) {
            setClientWorkorders(response.data)
          }
        }
      })

    }
    if (client && client._id) fetchWos(client._id)
    return () => {
      isMounted = false
    }
  }, [client])

const handleClose = () => {
  // console.log('Closing DepositModal Modal')
  // console.log('CLEAR THE DATA HERE')
  setCrew({})
  setShifts([])
  setSelectedShifts([])
  setPayments([])
  setInvoices([])
  setState(defaultValues)
  props.closeModal()
}

const handleTimeChange = (type, value) => {
  // console.log('Type: ', type)
  let isValid = moment(value, 'YYYY-MM-DDThh:mm').isValid()
  // console.log('Valid? ', isValid)
  if (isValid) {
    // console.log('Value: ', value)
    let onSite = null
    let offSite = null
    if (type === 'onSite') {
      onSite =  moment(value, 'YYYY-MM-DDThh:mm').local()
      // offSite = moment(value, 'YYYY-MM-DDThh:mm').add(1, 'hours').local()
      if (state.offSite) {
        offSite = state.offSite // moment(state.offSite, 'YYYY-MM-DDThh:mm').local()
      } else {
        offSite = moment(value, 'YYYY-MM-DDThh:mm').add(1, 'hours').local()
      }

    }
    if (type === 'offSite') {
      onSite = moment(state.onSite, 'YYYY-MM-DDThh:mm').local()
      offSite = moment(value, 'YYYY-MM-DDThh:mm').local()
    }
    // console.log('onSite IN', onSite)
    // console.log('offSite out: ', offSite)
    let hoursWorked = Math.abs(onSite - offSite) / 36e5
    // console.log('Hours WOrked: ', hoursWorked)
    hoursWorked = parseFloat(hoursWorked).toFixed(2)
    // console.log(hoursWorked)
    // if (clockOut < clockIn && state.weWantThis) {
    //   if (window.confirm('Your Clock-Out Time Is Before the Clock-In Time...Should We Reverse Them?')) {
    //     // console.log('Reverse em!')
    //     setState({...state, clockIn: clockOut, clockOut: clockIn, hoursWorked: hoursWorked})
    //     props.notification({
    //       type: 'warning',
    //       title: `Uh Oh - That Doesn't Seem Right!`,
    //       message: 'Your Clock-In Time Was After The Clock-Out - So We Reversed Them!'
    //     })
    //   } else {
    //     setState({...state, clockIn: clockIn, clockOut: clockOut, hoursWorked: 0})
    //   }
    // } else {
      setState({...state, onSite: onSite, offSite: offSite, hoursWorked: hoursWorked})
    // }
  }
}

const updateCustomer = (e, customer) => {
  if (customer && customer._id) {
    setClient(customer)
  }
}

const updateJob = (e, job) => {
  // console.log('update workorder:', wo)
  if (job && job._id) {
    setJob(job)
    setState({...state, job: job})
  }
}


const fetchShifts = async (crewId, isEmployee) => {
  // console.log('FETCH SHIFTS CALLED')
  setLoading(true)
  let url = `${props.url}/api/roster/shiftsByCrew?crewId=${crewId}`
  if (isEmployee) {
    url = `${props.url}/api/roster/shiftsByCrew?employeeId=${crewId}`
  }
  axios.get(url).then((response, status, data) => {
    setLoading(false)
    if (response && response.data && response.data.length) {
      setShifts(response.data)
      setState({...state, shifts: response.data})
    } else {
      setShifts([])
      setState({...state, shifts: []})
      props.notification({
        title: 'No Shifts Found',
        message: 'We cannot find any shifts for that crew',
        type: 'warning'
      })
    }
  })
}

React.useEffect(() => {
  let isMounted = true

  if (crew && crew._id && isMounted) {
    fetchShifts(crew._id)
  }

  return () => {
    isMounted = false
  }
}, [crew])

const updateCrew = (e, crew) => {
  if (crew && crew._id) {
    setCrew(crew)
    setEmployee({})
    // fetchShifts(crew._id)
  }
}

const updateWO = (e, wo) => {
  setWorkorder(wo)
}
React.useEffect(() => {
  let isMounted = true

  if (employee && employee._id && isMounted) {
    fetchShifts(employee._id, true)
  }

  return () => {
    isMounted = false
  }
}, [employee])

const updateEmployee = (e, emp) => {
  if (emp && emp._id) {
    setEmployee(emp)
    setCrew({})
    // fetchShifts(emp._id, true)
  }
}

const handleTabInteraction = (e) => {
  if (e.keyCode == 9) {
    // console.log(e.target)
    if (e.target.id === 'customername') {
      // window.alert(`Tab on custoemr ${e.target.value}`)
      let newArr = []
      let string = e.target.value
      props.clients.filter(o => {
        let str = o['displayName'].toString().toLowerCase()
        if (str.includes(string.toLowerCase())) {
          return newArr.push(o)
        } else {
          return null
        }
      })
      // console.log(newArr)
      if (newArr && newArr.length) {
        setClient(newArr[0])
      }
    }
    if (e.target.id === 'crewlistautocomplete') {
      // console.log('Set Our crew!!', e.target.value)
      let newArr = []
      let string = e.target.value
      props.crews.filter(o => {
        let str = o['name'].toString().toLowerCase()
        if (str.includes(string.toLowerCase())) {
          return newArr.push(o)
        } else {
          return null
        }
      })
      // console.log(newArr)
      if (newArr && newArr.length) {
        setCrew(newArr[0])
        // fetchShifts(newArr[0]['_id'])
      }
    }
    if (e.target.id === 'employees') {
      // console.log('Set Our crew!!', e.target.value)
      let newArr = []
      let string = e.target.value
      props.employees.filter(o => {
        let str = o['displayName'].toString().toLowerCase()
        if (str.includes(string.toLowerCase())) {
          return newArr.push(o)
        } else {
          return null
        }
      })
      // console.log(newArr)
      if (newArr && newArr.length) {
        setEmployee(newArr[0])
        // fetchShifts(newArr[0]['_id'], true)
      }
    }
  }
}

// const handleClose = () => {
//   props.closeModal()
// }

const handleFocus = (event) => event.target.select();

const handleSave = async () => {
  // console.log('Save this shit')
  let obj = {
    state,
    client,
    crew,
    suppliesUsed
  }
  setLoading(true)
  const response = await axios.post(`${props.url}/api/project/updateProgressPayments`, obj)
  // console.log('Paid Bulk Invoices!!', response)
  setLoading(false)
  if (response && response.data && response.data._id) {
    setState(defaultValues)
    setClient({})
    handleClose()
    props.notification({
      type: 'success',
      title: 'Added Service Notes & Hours Worked',
      message: 'We Have Saved That Data!!'
    })
    // serviceRef.current.value = null
  }
}

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
const handleAddPayment= () => {
  setShowModal(true)
}

const AddPaymentButton = () => {
  return (
    <Button startIcon={<TodayIcon />} onClick={handleAddPayment} variant="contained" color="primary" style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Add" aria-label="Add">
      Add Payment
    </Button>
  )
}

const closeOutShifts = async (shifts) => {
  setShowReconcileShiftModal(false)
  // console.log('Clsoe thse out:', shifts)
  setLoading(true)
  const shiftList = shifts.map(shift => { return shift._id })
  // console.log('SHIFT LIST: ', shiftList)
  const response = await axios.post(`${props.url}/api/company/laborTrackCompleteByShift`, shiftList)
  // console.log('Paid Bulk Invoices!!', response)
  setLoading(false)
  if (response && response.data) {
    // console.log('Track complete', response.data)
    let theShifts = shifts
    // console.log('Shift LIST: ', theShifts)
    for (const shift of shiftList) {
      // console.log("shift 629", shift)
      let index = theShifts.findIndex(item => (item._id === shift))
    // console.log(index)
      if (index > -1) theShifts.splice(index, 1)
    }
    // console.log('Set Shifts:', theShifts)
    setShifts(theShifts)
    setSelectedShifts([])
    setCrew({})
    setEmployee({})
    // console.log('Got Service Visits Associated with these shifts: ', response.data)
    // setServiceVisits(response.data)
  }
}

const updateFromAddPayment = async (type, d) => {
// console.log('Update From Add Progress payment: ', type)
  if (type === 'closeModal') {
    setShowModal(false)
  }
  if (type === 'saved') {
  // console.log('Add Payment: ', d)

    // Check to make sure it's not too much
    let tot = parseFloat(Number(totalPayments) - 0 + Number(d.amount)).toFixed(2)
    // let tot = parseFloat(totalPayments).toFixed(2) - 0 + parseFloat(d.amount).toFixed(2)
  // console.log('New Total: ', tot)
    let diff = parseFloat(tot - state.jobTotal).toFixed(2)
  // console.log('Difference: ', diff)
    if (tot > state.jobTotal && diff > 0.10) {
      props.notification({
        title: `That's Just Too Darn Much`,
        message: `I'm Sorry Partner, But That Would Be ${diff} More Than The Total Job`,
        type: 'warning'
      })
    } else {
      let pmts = payments
    // console.log('TOTAL TO SET: ', tot)
      setTotalPayments(parseFloat(tot).toFixed(2))
      setShowModal(false)
      // pmts.unshift(d)
      // setPayments(pmts)
      let obj = {
        details: d,
        job: job,
        project: props.project
      }
      const response = await axios.post(`${props.url}/api/projects/createNewPayment`, obj)
      // console.log('Paid Bulk Invoices!!', response)
      setLoading(false)
      if (response && response.data && response.data._id) {
      // console.log('Payment Saved!!', response.data)
        let inv = invoices
        inv.unshift(response.data)
        setInvoices(inv)
        setState({...state, counter: state.counter - 0 + 1})
        // handleClose()
      }
    }
  }
}

return (
  <div className={classes.root}>
    {/* <ReconcileShiftToWorkorderModal open={showReconcileShiftModal} shifts={selectedShifts} serviceVisits={serviceVisits} clients={clientList} saveServiceVisit={createServiceVisit} closeModal={closeReconciliationModal} updateShifts={updateShifts} finishedWithShifts={closeOutShifts} /> */}
    <AddPaymentModal open={showModal} job={job} jobTotal={state.jobTotal} totalPayments={totalPayments} updateParent={updateFromAddPayment} />
    <Dialog className={classes.paper} open={props.open} fullWidth={true} maxWidth='lg' onClose={handleClose} aria-labelledby="form-dialog-title">
      {/* <SuppliesUsedModal open={showSuppliesUsedModal} client={client} crew={crew} supplies={props.supplies} closeModal={e => setShowSuppliesUsedModal(false)} /> */}
        <DialogTitle id="form-dialog-title">
          Progress Payments
            
        </DialogTitle>
        <DialogContent >
          {
            (job && job._id) ? (
              <>
               <DialogContentText>
                    {`${job.name} (#${job.number})`}
                  </DialogContentText>
                <DialogContentText>
                    Job Total: {currencyFormat(state.jobTotal)}
                  </DialogContentText>
                  <DialogContentText>
                    Invoiced  Total: {currencyFormat(totalPayments)}
                  </DialogContentText>
                  <DialogContentText>
                    Please Choose A Workorder:
                  </DialogContentText>
              </>
            ) : null
          }
          
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
                <Autocomplete
                  id={`jobsList`}
                  options={props.jobs || []}
                  getOptionLabel={(option) => {
                  // console.log('Options: ', option)
                    return (option && option._id) ? `${option.name} #${option.number}` : ''
                  }}
                  // style={{ width: 200 }}
                  style={{marginBottom: '20px'}}
                  onChange={updateJob}
                  onFocus={handleFocus}
                  disabled={loading}
                  // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
                  value = {job}
                  // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
                  renderInput={(params) => <TextField {...params} label="Job" onKeyDown={handleTabInteraction} variant="outlined" />}
                />
                
              </Grid>
              
                {
                  (job && job._id) ? (
                    <React.Fragment>
                      <span style={{display: 'flex', justifyContent: 'flex-end', marginTop: '-15px'}}>
                        {/* { ((crew || employee) && !shifts) ? <AddShiftButton /> : null } */}
                        <AddPaymentButton /> 
                      </span>
                      <Grid item xs={12} lg={12}>
                          {/* {JSON.stringify(payments)} */}
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <InvoiceTable invoices={invoices} />
                    </Grid>
                    </React.Fragment>
                    
                  ) : null
                }
             
              
          </Grid>
 
         

        </DialogContent>
        <DialogActions>
            {/* {selectedShifts.length ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSave}
                  >Save</Button>
                ) : null } */}
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          {/* <Button color="primary" onClick={handleSave} disabled={!crew._id || !client._id || !state.hoursWorked || !state.onSite || !state.offSite}>
            {
              loading ? <CircularProgress /> : 'Save'
            }
          </Button> */}
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default React.memo(connect(mapStateToProps)(EditProgressPaymentsModal))