import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import NewLineItemComponent from '../LineItemConfiguration'
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import AddCommentIcon from '@material-ui/icons/AddComment';
import LineItemList from './ServiceLineItemList';
// import AddDBItemModal from './AddDBItemModal';
import AddCustomLineItemModal from './AddCustomLineItemModal';
import ConfigureMaterialsAndSuppliesModal from './ConfigureMaterialsAndSuppliesModal';
import LaborEquipmentAndSupervisionComponent from './LaborEquipmentAndSupervision'

const MainContainer = styled.div`
  border: 3px solid #aaa;
  background-color: #eee;
  border-radius: 15px;
  min-height: 65vh;
`

const TopRow = styled.div`
  align-self: stretch;
  display: flex;
  justify-content: space-between;
`

const CloseModalContainer = styled.div`
  display: flex;
  align-self: flex-end;
  margin: 0;
  padding: 1rem;  
`
const ServiceTitle = styled.div`
  display: flex;
  align-self: flex-start;
  margin: 0;
  padding: 1rem;  
`

const ActionRow = styled.div`
  display: flex;
  justify-content: space-around;
`

const AddDBItemContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 1rem;  
`
const AddCustomItemContainer = styled.div`
  display: flex;
  margin: 0;
  padding: 1rem;  
`

const LineItemsContainer = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 1rem;
  margin: 1rem;
  min-height: 50vh;
  max-height: 60vh;
  overflow: scroll;
`

const ConfigurationModal = (props) => {
  console.log('Configuration PROPS; 132 ', props)
  if (props.service && props.service.type) {
    console.log('WE HAVE A PROPS.SERVICE TYPE ', props.service.type)
  } else {
    console.log('NO FUCKING PROPS.SERVICE TYPE ', props.service.type)
  }
  const [activeLineItem, setActiveLineItem] = React.useState(null)
  const [editingActive, setEditingActive] = React.useState(false)
  const [laborDetails, setLaborDetails] = React.useState({})
  const [state, setState] = React.useState({
    counter: 0
  })
  React.useEffect(() => {
    console.log('SERVICE UPDATED 81: ', props.service)
    if (props.service && props.service.service && props.service.service.laborDetails && props.service.service.laborDetails.hasOwnProperty('laborHours')) {
      console.log('set the labor details', props.service.laborDetails)
      setLaborDetails(props.service.service.laborDetails)
      setState({...state, counter: state.counter++ })
    } else if (props.service && props.service.laborDetails && props.service.laborDetails.hasOwnProperty('laborHours')) {
        console.log('Ha ha -- our service item is shallower than expected!!!', props.service.laborDetails)
        setLaborDetails(props.service.laborDetails)
        setState({...state, counter: state.counter++ })
    } else {
      setLaborDetails({})
      console.log('Check and see if we have labor details on the lineItems themselves')
      if (props.service && props.service.service && props.service.service.lineItems && props.service.service.lineItems.length) {
        // checkIfWeHaveLaborOnTheLineItems
        console.log(props.service.service.lineItems)
      }
    }
  }, [props.service])

  const addLineItemToEstimate = (lineItem, inventoryItem, isEditing) => {
    if (isEditing) {
      console.log('Editing Line Item')
    } else {
      console.log('Add Line Item: ', lineItem)
      console.log('Inventory ITem', inventoryItem)
      setEditingActive(false)
      props.addLineItem(lineItem, inventoryItem, props.service)
      setActiveLineItem(null)
    }

  }

  const handleEditLineItem = (lineItem, deleteItem) => {
  console.log('EDIT LINE ITEM')
    if (deleteItem) {
      // console.log('Delete this: ', lineItem)
      let confirmationMessage = `Are you sure you wish to delete this item? ${lineItem.templatedDescription || lineItem.description} - This is not reversible.`
      if (window.confirm(confirmationMessage)) props.deleteLineItem(lineItem)
    } else {
    console.log('Edit this somehow', lineItem)
      setEditingActive(true)
      setActiveLineItem(lineItem)
      if (lineItem && lineItem.inventoryItemId) {
      console.log('DB ITem')
      } else {
        console.log('Custom Item')
      }
    }

  }

  const handleUpdateLineItem = (lineItem, inventoryItem) => {
  console.log('UPdate Line Item: 123', lineItem)
    // console.log('Props ?', props)
    console.log('Inventory Item??', inventoryItem)
    setEditingActive(false)
    // console.log('Active Service????', props.service)
  // console.log('Old Item', activeLineItem)
    // props.addLineItem(lineItem, inventoryItem, props.service)
    props.handleUpdateLineItem(activeLineItem, lineItem, props.service)
    // props.handleUpdateLineItem(lineItem, inventoryItem, props.service)
    setActiveLineItem({})
  }

  const handleClose = () => {
    props.closeModal()
  }

  const handleOpenDbItemModal = () => {

  }

  const handleSaveCustomLineItem = (lineItem) => {
    console.log('Add this line item 144', lineItem)
    props.addLineItem(lineItem)
    setActiveLineItem({})
  }

  const saveLaborShit = (data) => {
    console.log('Save htis labor stuff:', data)
    setLaborDetails(data)
  }

  const handleSaveService = () => {
  // console.log('Get our hours and shit...')
  // console.log(laborDetails)
  // console.log(props)
    props.closeModal(laborDetails)
  }

  const handleCloseCustomLineItemModal = () => {
    setActiveLineItem({})
  }

  let serviceName = ''
  if (props.service && props.service.service && props.service.service._id) serviceName = props.service.service.name
  console.log('Props.vendors::', props.vendors)
  console.log('Config Svc Details', props)
  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
      // fullScreen
      // fullWidth
      // sx={{
      //   "& .MuiDialog-container": {
      //     "& .MuiPaper-root": {
      //       width: "100%",
      //       maxWidth: "1500px",  // Set your width here
      //     },
      //   },
      // }}
      fullWidth={true}
      maxWidth='lg'
      // PaperProps={{ sx: { width: "60%", height: "100%" } }}
      >
        <DialogTitle>
          <TopRow>
            <ServiceTitle>Modify Service Details - {serviceName}</ServiceTitle>
            <CloseModalContainer>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </CloseModalContainer>
          </TopRow>
        </DialogTitle>
        <DialogContent>
          <MainContainer>            
            <ActionRow>
              <AddDBItemContainer >
                <ConfigureMaterialsAndSuppliesModal scopes={props.scopes} sizes={props.sizes} vendors={props.vendors} takeOffList={props.takeoffs} editing={editingActive} service={props.service} addLineItemToEstimate={addLineItemToEstimate} itemToEdit={activeLineItem} updateEstimateLineItem={handleUpdateLineItem} />
                {/* <AddDBItemModal takeOffList={props.takeoffs} service={props.service} addLineItemToEstimate={addLineItemToEstimate} itemToEdit={activeLineItem} updateEstimateLineItem={handleUpdateLineItem} />                 */}
              </AddDBItemContainer>
              <AddCustomItemContainer>
                <AddCustomLineItemModal saveCustomLineItem={handleSaveCustomLineItem} sizes={props.sizes} itemToEdit={activeLineItem} handleUpdateLineItem={handleUpdateLineItem} closeModal={handleCloseCustomLineItemModal} />
              </AddCustomItemContainer>
            </ActionRow>
            <LaborEquipmentAndSupervisionComponent laborDetails={laborDetails} updateParent={saveLaborShit} />
            <LineItemsContainer>
              <LineItemList estimate={props.estimate} service={props.service} handleEditLineItem={handleEditLineItem} />
            </LineItemsContainer>
          </MainContainer>
          
          {/* <NewLineItemComponent takeOffList={props.takeoffs} addLineItemToEstimate={addLineItemToEstimate} updateEstimateLineItem={handleUpdateLineItem} item={activeLineItem} service={props.service} />
           */}
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSaveService}
          >Save</Button>
        </DialogActions>
      </Dialog>
  )
}

export default ConfigurationModal