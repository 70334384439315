import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import axios from 'axios'
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid'
// import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { currencyFormat } from '../../services/common'
import PieChart from './PieChart'
import { socket } from '../SocketIo/Socket'

const useStyles = makeStyles(theme => ({
  root: {
    width: '95%'
  },
  paper: {
    width: '100%',
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  modal: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center'
      margin: 0
    },
    [theme.breakpoints.down('md')]: {
      margin: 0,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
      margin: '5%'
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
      // margin: '5%'
    },
  },
  updateInfo: {
    color: '#aaa',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center'
      fontSize: 12,
      textAlign: 'center'
      // width: '100%',
      // textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: 16
      // textAlign: 'right'
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
      fontSize: 17,
      textAlign: 'right'
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'right',
      fontSize: 18,
      // backgroundColor: '#ccc',
      // margin: '5%'
    },
  },
  titleText: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
      textAlign: 'left'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 22,
      textAlign: 'left'
    }
  },
  subTitleText: {
      color: '#bbb',
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 14,
      textAlign: 'center'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      textAlign: 'left'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 17,
      textAlign: 'left'
    }
  },
  notesBox: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: 'pink'
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '10vh',
    },
    border: '3px solid #bbb',
    borderRadius: 15,
    overflowY: 'scroll',
    maxHeight: 270,
    padding: 10,
    marginTop: 10
    // backgroundColor: '#eee'
  },
  wrapper: {
    maxWidth: '260px',
    minWidth: '100px',
    whiteSpace: 'nowrap',
    display: 'inline'
  },
  box: {
    borderLeft: '7px solid #333',
    padding: '7px',
    backgroundColor: '#234543',
    width: '100%',
    color: '#aaa',
    fontSize: '10px'
  },
  bigText: {
   color: '#777',
   fontSize: '16px'
   },
   estimateTotal: {
    borderLeft: '7px solid #56b349',
    padding: '7px',
    backgroundColor: '#eef8ed',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  estimatedCost: {
    borderLeft: '7px solid #ad8409',
    padding: '7px',
    backgroundColor: '#fdf7e7',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  invoicesPaid: {
    borderLeft: '7px solid #d7393f',
    padding: '7px',
    backgroundColor: '#f6d5d6',
    // width: '100%',
    color: '#444',
    fontSize: '10px',
    display: 'inlineBlock'
  },
  invoicesDue: {
    borderLeft: '7px solid #ad8409',
    padding: '7px',
    backgroundColor: '#fdf7e7',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  paymentsCollected: {
    borderLeft: '7px solid #56b349',
    padding: '7px',
    backgroundColor: '#eef9ed',
    width: '100%',
    color: '#444',
    fontSize: '10px'
  },
  projectBalance: {
    borderLeft: '7px solid #1eabd6',
    padding: '7px',
    backgroundColor: '#82d4ed',
    // width: '100%',
    color: '#444',
    fontSize: '10px',
    display: 'inlineBlock'
  },
}))

const ProjectDashboardDetails = (props) => {
  const classes = useStyles()
// console.log('Project Dashbaord Details', props)
  const [state, setState] = React.useState({
    totLaborCost: 0,
    totalLaborHours: 0,
    totalRevenue: 0
  })
  const [projectsByStage, setProjectsByStage] = React.useState([])
  const [totalAcceptedEstimateRevenu, setTotalAcceptedEstimateRevenue] = React.useState(0)
  const [totalAssociatedVendorInvoices, setTotalAssociatedVendorInvoices] = React.useState(0)
  const [woRevenue, setWoRevenue] = React.useState(0)
  const [totalRevenue, setTotalRevenue] = React.useState(0)


  React.useEffect(() => {
    let isMounted = true
    socket.on('project-dashboard', function (data) {
    console.log('Project Dashbaord data updated', data)
      let newState = {}
      let totalRevenue = 0
    // console.log('respns', data)
      if (isMounted) {
        if (data && data.breakdownByStatus) setProjectsByStage(data.breakdownByStatus)
        if (data && data.totalAcceptedRev) {
          setTotalAcceptedEstimateRevenue(data.totalAcceptedRev)
          totalRevenue += parseFloat(data.totalAcceptedRev)
          if (data && data.woRevenue) {
            setWoRevenue(data.woRevenue)
            totalRevenue += parseFloat(data.woRevenue)
          }
          setTotalRevenue(totalRevenue)
          newState.totalRevenue = totalRevenue
        }
        if (data && data.totalAssociatedVendorInvoices) setTotalAssociatedVendorInvoices(data.totalAssociatedVendorInvoices)
        if (data && data.totalLaborCost) newState.totLaborCost = data.totalLaborCost
        if (data && data.totalLaborHours) newState.totalLaborHours = data.totalLaborHours
        let gp = totalRevenue - data.totalLaborCost - data.totalAssociatedVendorInvoices
        newState.potentialProfit = gp
      // console.log('Updating dashboard w/ new state:', newState)
        setState({...state, ...newState })
      }
    })
    
    return () => {
      isMounted = false
      socket.off(`project-dashboard`)
    }
  }, [])


  React.useState(() => {
    let isMounted = true
    const fetchData = async () => {
      let url = `${props.url}/api/projects/getDashboardData`
      const response = await axios.get(url)
      if (response && response.data && isMounted) {
        console.log('Dashbaord data', response.data)
        let newState = {}
        let totalRevenue = 0
      // console.log('respns', response.data)
        if (response.data && response.data.breakdownByStatus) setProjectsByStage(response.data.breakdownByStatus)
        // if (response.data && response.data.totalAcceptedRev) setTotalAcceptedEstimateRevenue(response.data.totalAcceptedRev)
        if (response.data && response.data.totalAcceptedRev) {
          setTotalAcceptedEstimateRevenue(response.data.totalAcceptedRev)
          totalRevenue += parseFloat(response.data.totalAcceptedRev)
        // console.log('Total:', totalRevenue)
          if (response.data && response.data.woRevenue) {
            setWoRevenue(response.data.woRevenue)
          // console.log('Wo revenue', response.data.woRevenue)
            totalRevenue += parseFloat(response.data.woRevenue)
          // console.log('tot2', totalRevenue)
          }
          setTotalRevenue(totalRevenue)
          newState.totalRevenue = totalRevenue
        // console.log('Tota', totalRevenue)
        }
        if (response.data && response.data.totalAssociatedVendorInvoices) setTotalAssociatedVendorInvoices(response.data.totalAssociatedVendorInvoices)
        if (response.data && response.data.totalLaborCost) newState.totLaborCost = response.data.totalLaborCost
        if (response.data && response.data.totalLaborHours) newState.totalLaborHours = response.data.totalLaborHours
        let gp = totalRevenue - response.data.totalLaborCost - response.data.totalAssociatedVendorInvoices
        newState.potentialProfit = gp
        setState({...state, ...newState })
      }
    }
    fetchData()
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className={classes.root}>
     <Paper className={classes.paper}>
     <Grid container >
              <Grid item lg={3} md={3} sm={6} xs={12}>
                    
                    <div className={classes.wrapper} >
                      <div className={classes.estimateTotal}>
                        <div className={classes.bigText}>
                          Total Revenue
                        </div>
                        <div className={classes.actuaLItem}>
                           Amount: <span className={classes.bigText}>{ currencyFormat(totalRevenue) } </span>
                        </div>
                        
                      </div>
                    </div>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                    
                    <div className={classes.wrapper} >
                      <div className={classes.estimatedCost}>
                        <div className={classes.bigText}>
                        Total Labor Cost
                        </div>
                        <div className={classes.actuaLItem}>
                           Amount: <span className={classes.bigText}>{ currencyFormat(state.totLaborCost) } </span>
                           <span style={{ marginLeft: 10}} >
                              Man Hours: <span className={classes.bigText}>{ parseInt(state.totalLaborHours) } </span>
                            </span>
                        </div>
                        
                      </div>
                    </div>
                </Grid>
                <Grid item lg={3} md={3} sm={6} xs={12}>
                   
                    <div className={classes.wrapper} >
                      <div className={classes.invoicesPaid}>
                        <div className={classes.bigText}>
                        Total Cost of Goods
                        </div>
                        <div className={classes.actuaLItem}>
                           Amount: <span className={classes.bigText}>{ currencyFormat(totalAssociatedVendorInvoices)} </span>
                        </div>
                        
                      </div>
                    </div>
                </Grid>

                <Grid item lg={3} md={3} sm={6} xs={12}>
                    
                    <div className={classes.wrapper} >
                      <div className={classes.projectBalance}>
                        <div className={classes.bigText}>
                          Potential GP
                        </div>
                        <div className={classes.actuaLItem}>
                           Amount: <span className={classes.bigText} style={{ color: state.potentialProfit < 0 ? '#D21F3C' : 'inherit' }}>{ currencyFormat(state.potentialProfit)} </span>
                        </div>
                        
                      </div>
                    </div>
                </Grid>
                {/* <Grid item lg={12} md={12} sm={12} xs={12}>
                  <PieChart data={projectsByStage} />
                </Grid> */}
              </Grid>
      </Paper>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default connect(mapStateToProps)(ProjectDashboardDetails)

