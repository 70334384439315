import React from 'react'

const TestComponent = (props) => {
  // console.log('Test Component', props)
  const [time, setTime] = React.useState('')


  // const updateTime = () => {
  //   setInterval(() => {
  //     // console.log('Update TIme!')
  //     let theTime = new Date().toLocaleString()
  //     setTime(theTime)
  //   }, 1000);
  // }

  React.useEffect(() => {
    let theTime = new Date().toLocaleString()
    setTime(theTime)
    // updateTime()
    let timer = setInterval(() => {
      // console.log('Update TIme!')
      // let theTime = new Date().toLocaleString()
      setTime(new Date().toLocaleString())
    }, 1000);
    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <div>{time}</div>
  )
}
export default TestComponent