import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

function isAndroid() {
  return /Android/i.test(navigator.userAgent)
}

function isIos() {
  return /(iPhone|iPad|iPod)/i.test(navigator.userAgent)
}

const MobileAppDownloadPage = (props) => {
  const classes = useStyles();
  let isAndroidDevice = isAndroid()
  let isIosDevice = isIos()
  const androidURL = 'https://play.google.com/store/apps/details?id=com.terral&pcampaignid=web_share'
  const iOSUrl = 'https://apps.apple.com/us/app/terral/id6466199013'
  const [state, setState] = React.useState({
    message: '',
    loading: false,
    os: 'Not Detected'
  })
  const [error, setError] = React.useState({
    message: '',
    isError: false
  })
  const [success, setSuccess] = React.useState({
    message: '',
    isActive: false
  })
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (isAndroidDevice) {
      setState({...state, os: 'Android'})
    }
    if (isIosDevice) {
      setState({...state, os: 'iOS'})
    }
  }, [isAndroidDevice, isIosDevice])

  const handleDownload = () => {
    console.log('Download now')
    if (state.os === 'Android') {
      handleDownloadApp()
    }
    if (state.os === 'iOS') {
      window.location.replace(iOSUrl)
    }
    
  }

  const handleDownloadApp = async () => {
    setLoading(true)
    axios({
      url: `${props.url}/api/company/downloadAndroidmobileApp`,
      method: 'GET',
      responseType: 'blob'
    }).then((response) => {
      setLoading(false)
      if (response && response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        var link = document.createElement('a');
        link.href = url;
        let fileName = 'dflmobileapp'
        link.setAttribute('download', `${fileName}.apk`)
        document.body.appendChild(link)
        link.click();
      }
    })
  }
  return (
    <Paper className={classes.paper}>
      <Grid container >
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          <Typography variant="h4" className={classes.title}>
            Download Mobile App
            <br/>
            Descargar la aplicación móvil
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          <Typography variant="subtitle1" className={classes.subText}>
            Haga clic en el botón para comenzar la descarga
            <br/>
            Please Click the Button to Begin Your Download
           
          </Typography>
         
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          <Typography variant="subtitle1" className={classes.subText}>
            {
              state.os === 'Not Detected' ? <span>App Not Available For This Device</span> : state.os === 'iOS' ? <span>Link Will Take you to the App Store</span> : <span>Link Will Download the Android App</span>
            }
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>

        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
       
        { loading ?<CircularProgress style={{color: 'green', textAlign: 'center' }} size={ 96 } /> : 
          <Button
            onClick={handleDownload}
            variant="contained"
            color="primary"
            fullWidth
            className={classes.iosButton}
            disabled={state.os === 'Not Detected'}
          >Download / Descarga</Button>
        }
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.paddedGridItem}>
          {
            (error && error.isError) && (
              <div  style={{ backgroundColor: 'rgba(244, 54, 58, 0.4)', borderRadius: 5, padding: 20, margin: 20 }}>
                Error: {error.message}
              </div>
            )
          }
                    {
            (success && success.isActive) && (
              <div style={{ backgroundColor: 'rgba(54, 244, 58, 0.4)', borderRadius: 5, padding: 20, margin: 20 }}>
                 {success.message}
              </div>
            )
          }
        </Grid>
      </Grid> 
  </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
    paper: {
      maxWidth: '50%', // it will take 50% on large screens
      width: '100%',   // it will take full width on small screens
      margin: '0 auto', // this centers the Paper horizontally
      padding: theme.spacing(2), // some internal padding
      [theme.breakpoints.down('sm')]: { // breakpoint for small screens
        maxWidth: '100%',
      },
      marginTop: '10%'
    },
    title: {
      textAlign: 'center',
      marginBottom: theme.spacing(2), // some space below the title
    },
    subText: {
      textAlign: 'left',
      fontSize: '1.2em',
      color: '#bbb',
      textAlign: 'center',
      marginBottom: theme.spacing(2), // some space below the subtext
    },
    telephone: {
      // maxWidth: '200px', // adjust as needed
    },
    paddedGridItem: {
      padding: 10,
      margin: 5,
     
    },
    iosButton: {
      // backgroundImage: "linear-gradient(.25turn, #f00, #00f)"
    }
  }));

  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(MobileAppDownloadPage)