import React from 'react'

const WorkorderChecklist = ({ list }) => {
  // console.log('WO Checklist:', list)
  return (
    <div>
       Checklist Items: {list.length}
      {/* {
        list.map((item, index) => {
          return (
            <div key={index}>
              Checklist Items: {list.length}
            </div>
          )
        })
      } */}
    </div>
  )
}
export default WorkorderChecklist