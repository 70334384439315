import React from 'react'
import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { connect } from 'react-redux'
import { fade, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Chip from '@material-ui/core/Chip';
import axios from 'axios'
import JobDetailsModal from './JobDetails'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button';
import NewJobSiteModal from './NewJobSiteModal'
import JobSiteModal from './JobSiteModal'
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import { Paper, CircularProgress, IconButton } from '@material-ui/core';
// import Checkbox from '@material-ui/core/Checkbox';
// import IconButton from '@material-ui/core/IconButton';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import DeleteIcon from '@material-ui/icons/Delete';
// import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment'
import { Link } from 'react-router-dom'
// import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
// function createData(name, status, created, lastactivity, tourbooked) {
//   return { name, status, created, lastactivity, tourbooked };
// }
import ImportExportIcon from '@material-ui/icons/ImportExport';
import Tooltip from '@material-ui/core/Tooltip';

// const rows = [
//   createData('Tom Smith', 'New', '12/17/2019', null, null),
//   createData('Donut', 'Working', '12/18/2019', 51, 4.9),
//   createData('Eclair', 'New', '11/29/2019', 24, 6.0),
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
//   createData('Gingerbread', 356, 16.0, 49, 3.9),
//   createData('Honeycomb', 408, 3.2, 87, 6.5),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
//   createData('Jelly Bean', 375, 0.0, 94, 0.0),
//   createData('KitKat', 518, 26.0, 65, 7.0),
//   createData('Lollipop', 392, 0.2, 98, 0.0),
//   createData('Marshmallow', 318, 0, 81, 2.0),
//   createData('Nougat', 360, 19.0, 9, 37.0),
//   createData('Oreo', 437, 18.0, 63, 4.0),
// ];

function desc(a, b, orderBy, isDate, isInt) {
  if (isDate) {
    if (b[orderBy] < a[orderBy] || b[orderBy] == null) {
      // console.log('B is < A')
      return -1;
    }
    if (b[orderBy] > a[orderBy] || a[orderBy] == null) {
      // console.log('B is > A')
      return 1;
    }
    // console.log('B is ? A')
    // console.log('A: ', a[orderBy])
    // console.log('B: ', b[orderBy])
    return 0;
  } else if (isInt) {
    if (parseInt(b[orderBy]) < parseInt(a[orderBy]) || b[orderBy] == null) {
      return -1;
    }
    if (parseInt(b[orderBy]) > parseInt(a[orderBy]) || a[orderBy] == null) {
      return 1;
    }
    return 0;
  } else {
    let itemA = a[orderBy]
    if (itemA) itemA = itemA.toString().toLowerCase()
    let itemB = b[orderBy]
    if (itemB) itemB = itemB.toString().toLowerCase()
    if (itemB < itemA || itemB == null) {
      return -1;
    }
    if (itemB > itemA || itemA == null) {
      return 1;
    }
    return 0;
  }
}

function stableSort(array, cmp) {
  // console.log('Stable sort: ', array)
  // console.log('CMP: ', cmp)
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  if (orderBy==='lastUpdated') {
    // console.log('Last Updated')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, true) : (a, b) => -desc(a, b, orderBy, true);
  } else if (orderBy === 'buildingNumber') {
    console.log('BUILDING NUMBER!!!')
    return order === 'desc' ? (a, b) => desc(a, b, orderBy, false, true) : (a, b) => -desc(a, b, orderBy, false, true);
  } else {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Site Name' },
  { id: 'address', numeric: false, disablePadding: true, label: 'Address' },
  { id: 'buildingNumber', numeric: false, disablePadding: true, label: 'BLDG #' },
  { id: 'city', numeric: false, disablePadding: true, label: 'City' },
  { id: 'zip', numeric: false, disablePadding: true, label: 'Zip' },
  { id: 'communityName', numeric: false, disablePadding: true, label: 'Community Name' },
  { id: 'siteNotes', numeric: false, disablePadding: false, label: 'Notes' },
  { id: 'isDefault', numeric: false, disablePadding: false, label: 'Default Job Site' }
  // { id: 'servicedetails', numeric: true, disablePadding: false, label: 'Services' },
  // { id: 'crewname', numeric: false, disablePadding: false, label: 'Crew' }

];

function EnhancedTableHead(props) {
  // console.log('Got our props', props)
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    // console.log('Sort Property: ', property)
    // console.log('sort event; ', event)
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all leads' }}
          />
        </TableCell> */}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fakeLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: 'blue'
  }
}));

function EnhancedTable(props) {
  // console.log('Job Site LIST Props: ', props)
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('-created');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [searchValue, setsearchValue] = React.useState('');
  const [searchMatches, setsearchMatches] = React.useState(0);
  const [jobDetailsOpen, setJobDetailsOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false)
  const [job, setJob] = React.useState({});
  const [crewList, setCrewList] = React.useState([]);
  const [serviceList, setServiceList] = React.useState([])
  const [filteredRows, setFilteredRows] = React.useState([])
  const [showNewJobSiteModal, setShowNewJobSiteModal] = React.useState(false)
  const [showJobSiteModal, setShowJobSiteModal] = React.useState(false)
  const [jobSite, setJobSite] = React.useState({})
  // setLeads(props.leads)
  // let clients  = []
  // if (clients && clients.length > 0) clients = props.clients
  // console.log('Clients on table: ', clients)
  // const rows = leads
  const [rows, setRows] = React.useState([]);
  // setRows(leads)
  // setRows(leads)
  // useEffect(() => {
  //   // console.log('Use effect LEADS', leads)
  //   axios
  //     .get(
  //       `${url}/api/tours/getAll`
  //       )
  //     .then(({ data }) => {
  //       // console.log(data)
  //       setLeads(data)
  //     }).catch(function (err) {
  //       // console.log('Error getting stuff', err)
  //     });
  // }, [leads]);

  React.useEffect(() => {
    // console.log('Use effectset JOB SITE LIST: ', props)
    setRows(props.jobSites)
    setFilteredRows(props.jobSites)
   
  }, [props])

  React.useEffect(() => {
    // console.log('JobSites Props have changed!!!!!! ', props)
    setRows(props.jobSites)
    setFilteredRows(props.jobSites)
   
  }, [props.jobSites])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    // console.log('Set order by property: ' + property + ' in ' + isDesc + ' order')
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n._id);
      // console.log(newSelecteds)
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    // console.log('handle click ', name)
    // console.log('Index: ', selectedIndex)
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = event => {
  //   setDense(event.target.checked);
  // };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows = 0// rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  const filterOut = (r) => {
    // console.log('FIlter out: ', r)
    let string = searchValue
    let newArr = []
    rows.filter(o => {
      // console.log('Object:  ', o)
      Object.keys(o).some(k => {
        // console.log(o[k])
        if(o[k]) {
          // console.log('OK: ', o[k])
          let str = o[k].toString().toLowerCase() 
          // console.log(str)
          if (str.includes(string.toLowerCase())) {
            // console.log('Mathc')
            return newArr.push(o)
          } else {
            return null
          }
        } else {
          return null
        }
      })
      // return newArr
    })
    // console.log(newArr)
    // return newArr
    setFilteredRows(newArr)
    // setsearchMatches(newArr.length)
    // let newArr = r.filter(i => {
    //   if (i.includes('test')) return i
    // })
    // return newArr
    // return r.filter((obj)=>{
    //   return Object.keys(obj).reduce((acc, curr)=>{
    //         return acc || obj[curr] ? obj[curr].toString().toLowerCase().includes(string) : obj[curr];
    //   }, false);
    // })
  }
  const handleChange = (name) => (event) => {
    // console.log('Searching: ', event.target.value)
    setsearchValue(event.target.value)
    if (event.target.value === '') {
      setFilteredRows(rows)
    } else {
      filterOut(rows, event.target.value)
    }
    setPage(0)
  }

  const openJobModal = (job) => {
    // console.log(event)
    // e.preventDefault;
    // console.log('Open job modal: ', job)
    // props.openJobModal(job)
    setJob(job)
    setJobDetailsOpen(true)
  }

  const responseFromJobDetails = (s) => {
    // console.log('Response from our job details!!', s)
    setJobDetailsOpen(false)
  }
  const cleanNotes = (note) => {
    // console.log('HTML Notes: ', note)
    var temp = document.createElement("div");
    temp.innerHTML = note
    var cleanNotes = temp.textContent || temp.innerText;
    return cleanNotes
  }
const updateFromNewJobSite = (d) => {
  // console.log('New Jobsite Modal ', d)
  setShowNewJobSiteModal(false)
  // if (d === 'Close') setShowNewJobSiteModal(false)
  if (d && d._id) {
    // console.log('New Job Site', d)
    props.parentResponse('newSite', d)
    let sites = filteredRows
    sites.unshift(d)
    // console.log('New Sites: ', sites)
    setRows(sites)
    // console.log('Rows: ', rows)
    setFilteredRows(rows)
    // console.log('Filtered Rows: ', filteredRows)
    setJobSite({})
  }
}

const openJobSiteModal = (site) => {
  // console.log('Open Job Site modal')
  setJobSite(site)
  setShowJobSiteModal(true)
}

const updateFromJobSite = (type, data) => {
  // console.log('Update from job site!!!', type)
  if (type === 'Close') {
    // console.log('Close the modal')
  }
  if (type === 'Delete') {
    // console.log('Delete the Site', data)
    let index = filteredRows.findIndex(item =>  (item._id.toString() === data._id.toString()))
    // console.log('Our Site Index: ', index)
    let ourSite = filteredRows[index]
    // console.log(ourSite)
    let sites = filteredRows
    // sites[index] = data
    sites.splice(index, 1)
    // sites.push(data)
    // console.log('Our Updated Job Sites!!', sites)
    setFilteredRows(sites)
  }
  if (type === 'Updated') {
    // console.log('Get our Job Sites again...')
    props.parentResponse('Update', data)
    // console.log(data)
    // console.log('Existing Sites: ', filteredRows)
    // let index = filteredRows.findIndex(item =>  (item._id.toString() === data._id.toString()))
    // console.log('Our Site Index: ', index)
    // let ourSite = filteredRows[index]
    // console.log(ourSite)
    // let sites = filteredRows
    // sites[index] = data
    // // sites.splice(index, 1)
    // // sites.push(data)
    // console.log('Our Updated Job Sites!!', sites)
    // setFilteredRows(sites)
  }
  setShowJobSiteModal(false)
}

const handleExportJobSites = () => {
// console.log('Export Josites')
  let obj = {
    jobsites: rows,
    client: props.client
  }
  axios({
    method: 'post',
    url:  `${props.url}/api/company/exportToCSV`,
    data: obj,
    // validateStatus: (status) => {
    //   // console.log('Validate status: ', status)
    //   if (status && status === 500) {

    //   }
    // },
  }).then(response => {
    // console.log('Export response: ', response.data)
    let newBlob = new Blob([response.data], {type: "text/csv"})
    // console.log(newBlob)
    const data = window.URL.createObjectURL(newBlob);
    // console.log(data)
    var link = document.createElement('a');
    link.href = data;
    let fileName = props.client.searchName.replace(/[^a-z0-9]/gi, '_').toLowerCase()
    link.download=`${fileName}.csv`;
    link.click();
  })
}

  return (
    <div className={classes.root}>
      <NewJobSiteModal client={props.client} open={showNewJobSiteModal} updateParent={updateFromNewJobSite} />
      <JobSiteModal client={props.client} jobSite={jobSite} open={showJobSiteModal} updateParent={updateFromJobSite} />
      <JobDetailsModal client={props.client} job={job} crewList={crewList} serviceList={serviceList} jobDetailsResponse={responseFromJobDetails} open={jobDetailsOpen} />
      { loading?  <CircularProgress color="primary" size={ 36 } /> : 
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item lg={4} md={4} sm={6} xs={6}>
          Total Job Sites {filteredRows.length}
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={6}>
            <Tooltip title="Export to CSV" >
              <IconButton edge='end' onClick={handleExportJobSites} alt="Export Jobsites to CSV" aria-label="Export Jobsites to CSV">
                <ImportExportIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
          <Button
            variant="contained"
            color="secondary"
            // style={{margin: '5px', position: 'absolute', right: '20px'}}
            className={classes.button}
            // startIcon={<CreditCardIcon />}
            size="small"
            onClick={() => setShowNewJobSiteModal(true)}
          >
            Create New
          </Button>
          </Grid>
        </Grid>
          
       
        {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
        <div className={classes.search}>
            <div >
              <SearchIcon /> Search
            </div>
            <input name="search" value={ searchValue } onChange={ handleChange('search') } type="text"></input>
          </div>
        <div className={classes.tableWrapper} style={{margin: '10px'}}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size='small'
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={filteredRows.length}
            />
            <TableBody>
              {stableSort(filteredRows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  // console.log('JOB SITE LIST ROW:: ', row)
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  // setsearchMatches(rows.length)
                  // console.log(row)
                  return (
                    <TableRow
                      hover
                      // onClick={event => handleClick(event, row.number)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                    >
                      <TableCell component="th" id={labelId} onClick={() => openJobSiteModal(row)} scope="row" padding="none">
                        <span className={classes.fakeLink} >{row.name || 'Job Site'}</span>
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.address} 
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.buildingNumber ? ` Bldg #${row.buildingNumber}` : ''}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <span >{row.city}</span>
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <span >{row.zip}</span>
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        <span >{row.communityName}</span>
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                          {/* {cleanNotes(row.siteNotes)}  */}
                          {row.cleanSiteNotes}
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                          { row.isDefault ?  <Chip key='Default' size="small" label="Default" /> : ''}
                      </TableCell>
                      
                    </TableRow>
                  );
                })
              }
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          // onPageChange={handleChangePage}
          onChangePage={handleChangePage}
          // onRowsPerPageChange={handleChangeRowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      }
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    authenticated: state.authenticated,
    notification: state.notification,
    url: state.url
  }
}

export default React.memo(connect(mapStateToProps)(EnhancedTable))

