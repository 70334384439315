import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper';
import moment from 'moment'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import { Button, CircularProgress, TextField }  from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import PlusIcon from '@material-ui/icons/Add';
import { fade, makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import FiberNewIcon from '@material-ui/icons/FiberNew';
// import VendorList from './VendorList'
import { FileUploader } from "react-drag-drop-files";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileUploadList from './FileUploadList'
import UploadStatusModal from './UploadStatusModal';
import { socket } from '../SocketIo/Socket'

// const useStyles = makeStyles(theme => ({
const styles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      textAlign: 'center'
    },
    [theme.breakpoints.down('md')]: {
      margin: 10,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  topButtons: {
    [theme.breakpoints.down('sm')]: {
      // backgroundColor: theme.palette.secondary.main,
      // textAlign: 'center',
      margin: 5,
    },
    [theme.breakpoints.down('md')]: {
      margin: 5,
      // backgroundColor: theme.palette.secondary.main,
    },
    [theme.breakpoints.up('md')]: {
      // backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('lg')]: {
      // backgroundColor: '#ccc',
    },
  },
  uploadDropZone: {
    backgroundColor: '#ddd',
    border: '3px dashed #f00',
    borderRadius: 15,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'flex',
    width: 380,
    padding: 10,
    '&:hover': {
      backgroundColor: '#1f2'
    }
  }
  })
);


const BulkUpload = (props) => {
  const classes = styles()
  const [newProjectModalOpen, setNewProjectModalOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [clients, setClientList] = React.useState([])
  const [salesList, setSalesList] = React.useState([])
  const [projects, setProjects] = React.useState([])
  const [uploads, setUploads] = React.useState([])
  const [vendors, setVendors] = React.useState([])
  const [purchaseOrders, setPOs] = React.useState([])
  const [reconciledPOs, setReconciledPOs] = React.useState([])
  const [purchaseOrder, setPO] = React.useState({})
  const [filesToAdd, setNewFiles] = React.useState([])
  const [duplicateFiles, setDuplicateFiles] = React.useState([])
  const [state, setState] = React.useState({
    counter: 0,
    projects: [],
    filesToUpload: [],
    files: {
      errorFiles: [],
      duplicateFiles: [],
      filesUploaded: []
    }
  })
  const [vendorInvoiceInputModalOpen, setVendorInvoiceInputModalOpen] = React.useState(false)
  const [showUploadStatusModal, setShowUploadStatusModal] = React.useState(false)
  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "XLSX", "CSV", "TXT", "JPEG"];
  const [file, setFile] = React.useState([])

  React.useEffect(() => {
    let isMounted = true
    const fetchClients = async () => {
      setLoading(true)
      // ?byEntity=610a290f45ef7427f94b2e78
      let url = `${props.url}/api/customer/clients`
      axios.get(url).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setClientList(response.data)
        }
      })
    }

    const fetchVendors = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/company/getVendors`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setVendors(response.data)
        }
      })
    }

    const fetchPOs = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/projects/getOpenPOs?includeReconciled=true`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          console.log('Go Purchase Orders: ', response.data)
          if (isMounted) {
            setPOs(response.data.po)
            setReconciledPOs(response.data.reconciledPOs)
          }
        }
      })
    }

    const fetchUploads = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/projects/getUntaggedUploads`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) {
            setUploads(response.data)
            setState({...state, uploads: response.data})

          }
        }
      })
    }

    // socket.on(`${props.user._id}_uploads`, function (msg) {
    //   // console.log('Upload Update Status:', msg)
    //   // console.log('FILES: ', msg.files)
    //   setState({...state, filesToUpload: msg.files })
    // })

    // socket.on(`bulk_uploads`, function (msg) {
    //   // console.log('BULK UPLOADS UPDATED', msg)
    //   // console.log('FILES: ', msg.files)
    //   let newFiles = msg.filesUploaded
    //   setNewFiles(newFiles)
    //   // console.log('New Files:', newFiles)
    //   // console.log('Existing Files:', uploads)
    //   // let existingFiles = uploads
    //   // console.log('We have %d total files now', existingFiles)
    //   // for (const file of newFiles) {
    //   // // console.log('Add this one!', file)
        
    //   // }
    //   // existingFiles.unshift(newFiles)
    //   // console.log('All Files!!', existingFiles)
    //   // setUploads(existingFiles)
    //   // setState({...state, counter: state.counter++ })
    // })

    fetchUploads()
    fetchClients()
    fetchVendors()
    fetchPOs()
    return () => {
      isMounted = false
      // socket.off(`${props.user._id}_uploads`)
      // socket.off(`bulk_uploads`)
    }
  }, [])

  React.useEffect(() => {
  // console.log('Files to add has changed', filesToAdd)
    let existingFiles = uploads
  // console.log('we have %d existing files', existingFiles.length)
    let newFiles = filesToAdd.concat(existingFiles)
  // console.log('New Files:', newFiles)
    setUploads(newFiles)
    // setState({...state, counter: state.counter++ })
  }, [filesToAdd])

  const handleUpdateVisibleEntity = (activeEntity) => {
    // console.log('Active Entity: ', activeEntity)
  }

  const handleOpenNewProjectModal = () => {
    setNewProjectModalOpen(true)
  }

  const handleAddProject = (d) => {
    // console.log('New Project to save!', d)
    if (d && d._id) {
      let projs = state.projects
      projs.unshift(d)
      setProjects(projs)
      // console.log(projs)
      setNewProjectModalOpen(false)
      let counter = state.counter - 0 + 1
      setState({...state, projects: projs, counter: counter })
    }

  }

  const uploadFilesNow = (fileList) => {
    // console.log('Time to upload this shit!!', fileList)
    let formData = new FormData()
    fileList.forEach(file => {
      // console.log('Append to formdata:', file)
      formData.append('theFileList', file)
    })
    // console.log('Form Data: ', formData)
    let url = `${props.url}/api/projects/uploadProjectFilesMultiple`
    // console.log(url)
    setShowUploadStatusModal(true)
    axios({
      method: 'POST',
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: progressEvent => {
        console.log('Upload Progress', progressEvent)
      }
    }).then(resp => {
      console.log('REsponse from axios::', resp)
      let allGood = true
      let allTheFiles = {
        errorFiles: [],
        duplicateFiles: [],
        filesUploaded: []
      }
      if (resp && resp.data && resp.data.duplicateFiles && resp.data.duplicateFiles.length) {
        // console.log('Duplicate Files: ', resp.data.duplicateFiles)
        props.notification({
          type: 'warning',
          title: 'Duplicate Upload Detected',
          message: `${resp.data.duplicateFiles.length} files were duplicates and were NOT saved.`
        })
        setDuplicateFiles(resp.data.duplicateFiles)
        allTheFiles.duplicateFiles = resp.data.duplicateFiles
        allGood = false
      }
      if (resp && resp.data && resp.data.errorFiles && resp.data.errorFiles.length) {
        // console.log('Files Not ALlowed', resp.data.errorFiles)
        // props.notification({
        //   type: 'danger',
        //   title: 'File Type Violation',
        //   message: `${resp.data.errorFiles.length} files were invalid file types.`
        // })
        allTheFiles.errorFiles = resp.data.errorFiles
        allGood = false
      }
      if (resp && resp.data && resp.data.filesUploaded && resp.data.filesUploaded.length) {
        // console.log('WE uploaded some files motherfucker')
        // props.notification({
        //   type: 'success',
        //   title: 'Upload Complete',
        //   message: `${resp.data.filesUploaded.length} files were uploaded and saved`
        // })
        // let updatedFileList = uploads
        // resp.data.filesUploaded.map(item => {
        //   updatedFileList.unshift(item)
        // })
        // // console.log('Updated File List: ', updatedFileList)
        // setUploads(updatedFileList)
        allTheFiles.filesUploaded = resp.data.filesUploaded
        setNewFiles(resp.data.filesUploaded)
       
      }
      setState({...state, counter: state.counter++, files: allTheFiles })
      // if (allGood) {
      //   setTimeout(() => {
      //     setShowUploadStatusModal(false)
      //   }, 2000)
      // }
    })
  }

  const handleChange = (fileList) => {
    // console.log('FILES TO UPLOAD: ', fileList)
    let files = Array.from(fileList)
    // console.log('files', files)
    // fileList.map(item => (files.push(item)))
    // console.log('files: ', files)
    let newFiles = file.concat(files)
    setFile(newFiles)
    setState({...state, filesToUpload: newFiles})
  // console.log('New Files to upload:', newFiles)
    // UPload the files now
    uploadFilesNow(files)
  };

  const FileList = ({ files }) => {

    return (
      <ul>
        {
          files.map(item => {
            return <li>{item.name}</li>
          })
        }
      </ul>
    )
    // let html = `<ul>`
    // files.map(item => {
    //   html += `<li>{item.name}</li>`
    // })
    // html += `</ul>`
    // return html
  }

  // const handleDragChange = (dragging) => {
  // // console.log('Dragging: ', dragging)
  // }

  const updateUpload = (d) => {
    // console.log('We saved this upload...so we can get rid of it now.')
    let index = uploads.findIndex(item => (item._id === d._id))
    if (index > -1) {
      // console.log(index)
      let list = uploads
      list.splice(index, 1)
      setUploads(list)

      setState({...state, counter: state.counter++ })
    }
  }

  const deleteItem = (item) => {
    // console.log('Delete this item', item)
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/deleteUpload`,
      data: item
    }).then(response => {
      setLoading(false)
      if (response.data && response.data._id) {
        props.notification({
          type: 'success',
          title: 'Upload Deleted',
          message: 'That Upload Has Been Deleted!'
        })
        let list = uploads
        let index = list.findIndex(item2 => (item2._id === response.data._id))
      // console.log('Upload index', index)
        if (index > -1) {
          list.splice(index, 1)
          // setFilteredRows(list)
          // setRows(list)
          setUploads(list)
          setState({...state, counter: state.counter++ })
        }
      }
    })
  }
  const closeStatusModal = (e) => {
    setShowUploadStatusModal(false)
    setFile([])
    // setState({...state, filesToUpload: []})
  }

  const handleDragChange= (e, d) => {
    // console.log('Drag change', e)
    // console.log(d)
  }

  const handleSelect = (e, d) => {
    // console.log('Select: ', e)
    // console.log(d)
  }

  const errorFilesItem = [
    {
        "fieldname": "theFileList",
        "originalname": "favicon_io (2).zip",
        "encoding": "7bit",
        "mimetype": "application/x-zip-compressed",
        "notAllowed": true,
        "name": "favicon_io (2).zip",
        "destination": "D:\\dev\\dfl\\portal/server/uploads",
        "filename": "favicon_io (2).zip",
        "path": "D:\\dev\\dfl\\portal\\server\\uploads\\favicon_io (2).zip",
        "size": 200878,
        "invalidType": true,
        "status": "Invalid File Type"
    }
]
const filesUploadedItem = [
  {
      "etag": "\"902bdcbf9e11a7542de432785ae03d32\"",
      "key": "ProjectUploads/1696268283130__10421_e_scopa_trl_bldg_plans.pdf",
      "url": "https://dflspaces.sfo2.digitaloceanspaces.com/ProjectUploads/1696268283130__10421_e_scopa_trl_bldg_plans.pdf",
      "untagged": true,
      "warranty": false,
      "_id": "651afffb4f55872084db7f80",
      "created_at": "2023-10-02T17:38:03.434Z",
      "created_by": "Chad Seymour",
      "user": "5f3b7c02b90a0f3352a99cb5",
      "type": "application/pdf",
      "status": "Pending",
      "md5Hash": "902bdcbf9e11a7542de432785ae03d32",
      "scanType": "dfl",
      "name": "10421 E Scopa Trl Bldg Plans.pdf",
      "size": 372021,
      "__v": 0
  },
  {
      "etag": "\"7b09c0140327d06429646f82b09cb8ef\"",
      "key": "ProjectUploads/1696268283545__screenshot-0929-115851.png",
      "url": "https://dflspaces.sfo2.digitaloceanspaces.com/ProjectUploads/1696268283545__screenshot-0929-115851.png",
      "untagged": true,
      "warranty": false,
      "_id": "651afffb4f55872084db7f83",
      "created_at": "2023-10-02T17:38:03.768Z",
      "created_by": "Chad Seymour",
      "user": "5f3b7c02b90a0f3352a99cb5",
      "type": "image/png",
      "status": "Pending",
      "md5Hash": "7b09c0140327d06429646f82b09cb8ef",
      "scanType": "dfl",
      "name": "Screenshot-0929-115851.png",
      "size": 178226,
      "__v": 0
  }
]

 const dupeFileItem = {
    "file": {
        "fieldname": "theFileList",
        "originalname": "Screenshot-0929-115851.png",
        "encoding": "7bit",
        "mimetype": "image/png",
        "name": "Screenshot-0929-115851.png",
        "destination": "D:\\dev\\dfl\\portal/server/uploads",
        "filename": "Screenshot-0929-115851.png",
        "path": "D:\\dev\\dfl\\portal\\server\\uploads\\Screenshot-0929-115851.png",
        "size": 178226,
        "duplicateFile": true,
        "status": "Duplicate File"
    },
    "existingFiles": [
        {
            "_id": "651af4dc4f55872084db6f7e",
            "etag": "\"7b09c0140327d06429646f82b09cb8ef\"",
            "key": "ProjectUploads/1696265435876__screenshot-0929-115851.png",
            "url": "https://dflspaces.sfo2.digitaloceanspaces.com/ProjectUploads/1696265435876__screenshot-0929-115851.png",
            "untagged": true,
            "warranty": false,
            "created_at": "2023-10-02T16:50:36.109Z",
            "created_by": "Chad Seymour",
            "user": "5f3b7c02b90a0f3352a99cb5",
            "type": "image/png",
            "status": "Pending",
            "md5Hash": "7b09c0140327d06429646f82b09cb8ef",
            "scanType": "dfl",
            "name": "Screenshot-0929-115851.png",
            "size": 178226,
            "__v": 0
        }
    ]
}

const debug = {
  "duplicateFiles": [
    dupeFileItem
  ],
  "errorFiles": errorFilesItem,
  "filesUploaded": filesUploadedItem
}

  return (
    <Grid container style={{margin: 5}} spacing={2}>
      {/* <UploadStatusModal files={state.filesToUpload} filesResponse={debug} open={true} closeModal={closeStatusModal} /> */}
      <UploadStatusModal files={state.filesToUpload} filesResponse={state.files} open={showUploadStatusModal} closeModal={closeStatusModal} />
        <Grid item lg={6} xs={12} sm={12} md={6}>
          <h1>Bulk Upload</h1>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Paper style={{ height: '80vh', marginRight: 20, padding: 10, overflowY: 'scroll' }}>
            {/* <VendorList vendors={vendors} /> */}
            <Grid container spacing={2}>
              <Grid item lg={4} md={6} xs={12}>
              <FileUploader
                handleChange={handleChange}
                name="file" 
                multiple
                fileOrFiles='Array'
                onSelect={handleSelect}
                onDraggingStateChange={handleDragChange}
                
                
                
                >
                  <div style={{ textAlign: 'center', border: '3px dashed #ccc', borderRadius: '10px', padding: '15px', cursor: 'pointer' }}>
                    Click Here to Upload or Drag and Drop Files Here
                  </div>
                  </FileUploader>
              {/* <div
                className={classes.uploadDropZone}
                onDraggingStateChange={handleDragChange}
                hoverTitle=" "
                // style={{ display: 'flex',
                //   alignItems: 'center',
                //   flexWrap: 'wrap',
                //   border: '2px dashed',
                //   borderRadius: '10px',
                //   maxWidth: '400px',
                //   padding: '10px',
                //   '&:hover': {
                //     backgroundColor: '#f00'
                //   },
                //   '': {
                //     backgroundColor: '#f00'
                //   }
                // }}
                // onMouseEnter={(e) => {
                //   e.target.style.backgroundColor = '#e13570';
                //   e.target.style.border = '2px solid rgb(31, 0, 69)';
                //   e.target.style.boxShadow = '-2px 0px 7px 2px #e13570';
                // }}
                // onMouseLeave={(e) => {
                //     e.target.style.backgroundColor = 'rgb(31, 0, 69)';
                //     e.target.style.border = '2px solid #593676';
                //     e.target.style.boxShadow = '-2px 0px 7px 2px #e13570';
                // }}
                ondragover ={ e => {
                    e.target.style.backgroundColor = 'rgb(31, 0, 69)';
                    e.target.style.border = '2px solid #593676';
                    e.target.style.boxShadow = '-2px 0px 7px 2px #e13570';
                }}
              >
                <CloudUploadIcon />
                <p style={{padding: '10px'}}>
                  Click Here or Drag & Drop Files To Upload</p>
              </div>
              </FileUploader> */}
            {/* {
              (file && file.length) ? (
                <FileList files={file} />
              ) : null
            } */}
              </Grid>
              {/* <Grid item lg={12} md={12} xs={12}>
                Non-Sorted Uploads
              </Grid> */}
              <Grid item lg={12} md={12} xs={12}>
                <FileUploadList files={uploads} clients={clients} vendors={vendors} purchaseOrders={purchaseOrders} reconciledPOs={reconciledPOs} updateUpload={updateUpload} deleteItem={deleteItem} />
              </Grid>
            </Grid>
            
          </Paper>
        </Grid>
    </Grid>
  )
}

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(BulkUpload))