import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { CircularProgress, Grid, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions }  from '@material-ui/core';
import NotesIcon from '@material-ui/icons/Notes';
import NotesList from './NotesList'

const WorkorderNotes = (props) => {
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState({
    workorder: {},
    notes: []
  })
  const [showModal, setShowModal] = React.useState(false)

  async function fetchData(wo) {
  // console.log('Get Photos for Workorder')
    setLoading(true)
    const response = await axios.get(`${props.url}/api/jobs/getWorkorderNotesForWorkorder?_id=${wo._id}`)
  // console.log(response)
    setLoading(false)
    if (response.data && response.data.length > 0) {
      setState({...state, workorder: wo, notes: response.data })
    } else {
    // console.log('Fucked up getting Workorder Photos')
    }
  }

  React.useEffect(() => {
  // console.log('Assing WOrkorders Init')
    if (props.workorder && props.workorder._id) {
      fetchData(props.workorder)
    } else {
      setState({...state, notes: [], workorder: {} })
    }

  }, [props.workorder])

  const openNotesDetails = () => {
    setShowModal(true)
  }

  const handleCloseModal = ()=> {
    setShowModal(false)
  }

  return (
    <div >
      <NotesListModal open={showModal} list={state.notes} handleCloseModal={handleCloseModal} />
      { loading ? <CircularProgress style={{ textAlign: 'center', margin: '30px' }} color="primary" size={ 96 } /> : <CustomButton num={state.notes.length} openModal={openNotesDetails} /> }
    </div>
  )
}

const NotesListModal = ({ open, list, handleCloseModal}) => {

  return (
    <Dialog
      maxWidth={'lg'}
      fullWidth
      open={open}
      onClose={handleCloseModal}
    >
      <DialogTitle>
        Workorder Notes
      </DialogTitle>
      <DialogContent>
        <NotesList list={list} />
      </DialogContent>
      <DialogActions>
        <Button
        onClick={handleCloseModal}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
const CustomButton = ({ num, openModal }) => {
  let label = `${num > 0 ? num : 'No' } Workorder Notes`
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<NotesIcon color="#eee" /> }
      disabled={num < 1}
      onClick={openModal}
      >{label}</Button>
  )
}

// const PhotoList = ({ photos }) => {
//   if (photos && photos.length) {
//     <div>
//       {photos.length} Photos To Show
//   </div>
//   } else {
//     return (
//       <div>
//         No Photos To Show
//       </div>
//     )
//   }
// }
const mapStateToProps = (state) => {
  return {
    url: state.url,
    authenticated: state.authenticated,
    notification: state.notification,
    user: state.user
  }
}

export default connect(mapStateToProps)(WorkorderNotes)