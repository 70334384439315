// import http from "../http-common";
import axios from 'axios'
// import { connect } from 'react-redux'

class UploadFilesService {
  upload(file, url, data, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("data", JSON.stringify(data))
    return axios.post(`${url}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress,
    });
  }

  getFiles() {
    return axios.get("/files");
  }
}

export default new UploadFilesService()
// const mapStateToProps = (state) => {
//   return {
//     url: state.url,
//     user: state.user,
//     notification: state.notification
//   }
// }

// export default connect(mapStateToProps)(new UploadFilesService())