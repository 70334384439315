import React from 'react'
import styled from 'styled-components'
import { Button, CircularProgress }  from '@material-ui/core';
import { Link } from 'react-router-dom'

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 99%;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
`;

const HeaderBar = styled.div`
    font-size: 28px;
    color: rgb(75 102 109);
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    margin: 10px;
    min-height: 80vh;
`;

const POSArea = styled.div`
    display: flex;
    min-height: 400px;
    align-self: stretch;
    margin: 10px;
    flex-grow: 3;
    justify-content: center;
    align-items: center;
    text-align: center;
`;

const OtherArea = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 400px;
    align-self: stretch;
    margin: 10px;
    flex-grow: 2;
`;

const POSButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    height: 400px;
    min-width: 400px;
    filter: drop-shadow(5px 5px 4px #516E76);
    background-color: #fff;
    border-radius: 15px;
    &: active {
        filter: drop-shadow(2px 2px 2px #ACCACD);
        border: 3px solid #516e76;
    }
    cursor: pointer;
    &: hover {
        border: 1px solid #516e76;
    }
`;

const ListItem = styled.div`
    margin: 10px;
    width: 100%;
    height: 100px;
    min-width: 300px;
    border: 2px solid #ACCACD;
    border-radius: 20px;
    backround-color: #fff;
    &: active {
        border: 4px solid #516e76;
        background-color: #ddd;
    }
    display: flex;
    align-items: center;
    cursor: pointer;
    &: hover {
        border: 1px solid #516e76;
    }
`;

const ItemDetails = styled.div`
    font-size: 28px;
    color: #ACCACD;
    text-decoration: bold;
    width: 100%;
    text-align: center;
`;

const POSText = styled.div`
    margin-top: 20px;
    font-size: 24px;
    color: #ACCACD;
    display: flex;
    text-align: center;
    margin: auto;
    align-self: stretch;
`;

const POSIcon = styled.div`
    display: flex;
    flex-grow: 3;
    padding: 20px;
`;

const InventoryMainPage = (props) => {

    const callApi = async () => {
      // console.log('Call API')
        let value = 'test search'
        const url = 'https://dflportal.com/api/auth/check'
        const body = JSON.stringify({ 'buscador': `${value.toUpperCase()}` })
      // console.log('BODY: ', body)
        var myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          params: body,
          redirect: 'follow'
        }
      // console.log('REquest OPtions: ', requestOptions)
        const data = await fetch(url, requestOptions)
        .then((response) => {
        // console.log('TEXT: ', response.text())
        })
        // .then((response) => response.map(mapResponseToValuesAndLabels))
        .then((final) => {
        // console.log('FINAL: ', final)
        })
      }

    return (
        <>
            <MainContainer>
                <HeaderBar>
                    Inventory
                </HeaderBar>
                <Container>
                    <POSArea>
                        <POSButton onClick={ () => props.history.push('/shedPOS') }>
                            <POSIcon><img height="280px" src="../../scanner.png" /></POSIcon>
                            <POSText>
                                Point Of Sale System
                            </POSText>
                        </POSButton>
                    </POSArea>
                    <OtherArea>
                        <ListItem>
                            <ItemDetails onClick={ () => props.history.push('/shedPOS') }>
                            Point of Sale (Checkout Orders)
                            </ItemDetails>
                        </ListItem>
                        <ListItem>
                            <ItemDetails onClick={ () => props.history.push('/inventoryUpdate') }>
                            Update Inventory
                            </ItemDetails>
                        </ListItem>
                        <ListItem>
                            <ItemDetails onClick={ () => props.history.push('/') }>
                            View Tech Shopping Carts
                            </ItemDetails>
                        </ListItem>
                        <ListItem>
                            <ItemDetails onClick={ () => props.history.push('/inventory') }>
                                View Shed Inventory List
                            </ItemDetails>
                        </ListItem>
                        <ListItem>
                            <ItemDetails onClick={ () => props.history.push('/takeoffinventory') }>
                                View TakeOff Inventory List
                            </ItemDetails>
                        </ListItem>
                        <ListItem>
                            <ItemDetails onClick={ () => props.history.push('/bhlsupplylist') }>
                                View BHL Supply List
                            </ItemDetails>
                        </ListItem>
                    </OtherArea>
                </Container>
            </MainContainer>
        </>
        )
}

export default InventoryMainPage