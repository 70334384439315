import React from 'react';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Switch } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

function currencyFormat(num) {
  if (!num) num = 0
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  inputRoot: {
    color: '#bbb',
    fontSize: 20
  },
  labelText: {
    marginTop: '20px',
    fontSize: '18px',
    color:' #555',
    fontWeight: '800'
  },
  normalLine: {
    color: 'green',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 5,
    padding: 10
  },
  selectedLine: {
    border: '1px solid green',
    borderRadius: 2,
    display: 'flex',    
    justifyContent: 'space-between',
    padding: 10,
    margin: 3
  },
  listContainer: {
    padding: 5,
    margin: 5,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row'
  }
}));

const filter = createFilterOptions();

const defaultStateValues = {
  name: '',
  category: '',
  description: ''
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const PackageOptionList = (props) => {
  // // console.log('Get our package options', props)
  const classes = useStyles()
  const [state, setState] = React.useState({
    options: props.options || []
  })

  // React.useState(() => {
  //   let isMounted = true
  //   // console.log('Props Update....')
  //   const totalPrice = props.estimate.totalPrice || 0
  //   // console.log('Total ', totalPrice)
  //   if (props.options && props.options.length) {
  //     let ourOptions = props.options
  //     for (const opt of ourOptions) {
  //       // console.log(opt)
  //       let isSelectedPackage = (opt.name === props.selectedPackage.name) ? true : false
  //       let price = parseFloat(totalPrice) * opt.ratio
  //       if (isSelectedPackage) {
  //         price = parseFloat(totalPrice)
  //         opt.description = 'As Shown Above'
  //       } else {
  //         opt.description = 
  //       }
  //       // console.log('price', price)
  //       opt.price = price
  //     }
  //     // console.log(ourOptions)
  //     setState({...state, options: ourOptions })
  //     props.updateOptions(ourOptions)
  //   } else {
  //     setState({...state, options: [] })
  //     props.updateOptions([])
  //   }

  //   return () => {
  //     isMounted = false
  //   }
  // }, [props])

  const handleChange = (e, index, isPrice) => {
    let newList = state.options
    // // console.log('////list so far', newList)
    newList[index][e.target.id] = e.target.value
    if (isPrice) {
      // Modify Ratio as well
      let item = newList[index]
      // console.log(item)
      let totalPrice = props.estimate.totalPrice
      let itemPrice = e.target.value
      // console.log('Total Price %d --- Item Price -- %d', totalPrice, itemPrice)
      let newRatio = parseFloat(itemPrice / totalPrice)
      // console.log('New Ratio:', newRatio)
      item.ratio = newRatio
    }
    setState({...state, options: newList })
    // console.log('Update our Optons...', newList)
    props.updateOptions(newList)
  }

  const toggleSelected = (e, index) => {
    // console.log('Toggle select %s  -- %d   ', e.target.value, index)
    let newList = state.options
    let option = newList[index]
    if (option && option.selected) {
      newList[index]['selected'] = false
    } else {
      newList[index]['selected'] = true
    }
    // let newValue = e.target.value
    // if (newValue) {
    //   newList[index][e.target.id] = true
    // } else {
    //   newList[index][e.target.id] = false
    // }
    setState({...state, options: newList })
    props.updateOptions(newList)
  }
  
  return (
    <Grid container className={ classes.listContainer }>
        {
          state.options.length ? state.options.map((option, index) => {
            let isSelected = false
            if (props.selectedPackage && props.selectedPackage.name && props.selectedPackage.name.toString() === option.name.toString()) isSelected = true
            // // console.log(option.name)
            // console.info(props.selectedPackage.name)
            // let name1 = option.name.toString().trim()
            // let name2 = ''
            // if (props.selectedPackage.name) name2 = props.selectedPackage.name.toString().trim()
            // // console.log(name1, name2)
            // if (name1 === name2) {
            //   // console.log('Thank fucking god')
            //   isSelected = 'Yup'
            // }
            return (
               <Grid className={isSelected ? classes.selectedLine : classes.normalLine} item key={index} lg={12} md={12} sm={12} xs={12}>
                  <>
                    <Checkbox
                      id="selected"
                      color="primary"
                      checked={option.selected}
                      disabled={isSelected}
                      inputProps={{
                        'aria-labelledby': 'Selected',
                      }}
                      onChange={e => toggleSelected(e, index) }
                    />
                   
                    <TextField
                      variant="outlined"
                      label="Package"
                      value={option.name}
                      disabled
                    />
                    <TextField
                      variant="outlined"
                      label="Description"
                      fullWidth
                      id="description"
                      value={option.description}
                      onChange={e => handleChange(e, index) }
                    />
                    {/* <TextField
                      id="ratio"
                      variant="outlined"
                      label="Ratio"
                      value={option.ratio}
                      onChange={e => handleChange(e, index) }
                    /> */}
                    <TextField
                      id="price"
                      variant="outlined"
                      disabled={option.name === props.selectedPackage.name}
                      label="Total Price"
                      value={option.price}
                      onChange={e => handleChange(e, index, true) }
                    />
                  </>
                </Grid>
            )
          }) : null
        }
    </Grid>
  )
}

const defaultValues = {
  title: 'Title',
  subTitle: 'Sub-Title',
  header: 'Header',
  helper: 'Helper',
  inputLabel: 'Input Label'
}

const PackageOptions = (props) => {
  console.log('PackageOptions Props:', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [htmlOnly, setHTMLOnly] = React.useState('')
  const [state, setState] = React.useState(defaultValues)
  const [options, setOptions] = React.useState([])
  const [selectedPackage, setSelectedPackage] = React.useState({})

  const optionsAvailable = [
    {
      name: 'Basic',
      description: 'Smaller/Less Items',
      ratio: .87,
      price: 0,
      selected: false,
      priced: false
    },
    {
      name: 'Upgrade',
      description: 'Smaller/Less Items',
      ratio: 1,
      price: 0,
      selected: false,
      priced: true
    },
    {
      name: 'Premium',
      description: 'Larger/More Items',
      ratio: 1.27,
      price: 0,
      selected: false,
      priced: false
    },
    {
      name: 'Wow',
      description: 'Exceptional',
      ratio: 1.47,
      price: 0,
      selected: false,
      priced: false
    }
  ]

  
  // React.useEffect(() => {
  //   console.log('************PACKAGE OPTION PROPS CHANGED***************', props)
  // }, [props])

  React.useEffect(() => {
    console.log('************PACKAGE OPTION PROPS CHANGED***************', props)
    if (props.estimate && props.estimate.packageOptions && props.estimate.packageOptions.length) {
      // console.log('Estimate Package Options', props.estimate.packageOptions)
      // if (props.estimate.packageOptions && props.estimate.packageOptions.options) 
      let pkgChosen = null
      // let ourOptions = optionsAvailable
      props.estimate.packageOptions.map(item => {
        if (item.priced) pkgChosen = item
        // let optionIndex = ourOptions.findIndex(optionItem => (optionItem.name === item.name))
        // if (optionIndex > -1) {
        //   ourOptions[optionIndex] = item
        // }
      })
     
      let newOptions = props.estimate.packageOptions
      console.log('New Opts', newOptions)
      setOptions(newOptions)
      console.log('Selected Package', pkgChosen)
      if (pkgChosen) setSelectedPackage(pkgChosen)
    } else {
      setOptions(optionsAvailable)
      setSelectedPackage(optionsAvailable[1])
    }
  }, [props.estimate])

  // React.useEffect(() => {
  //   console.log('Selected Package Changed HAS UPDATED - Reset Pricing Properly', optionsAvailable)
  //   console.log('Selected Package', selectedPackage)
  //   let chad = true
  //   if (selectedPackage && chad) {
  //     updateOptionsList(selectedPackage, optionsAvailable)
  //   }
  // }, [selectedPackage])

  const updateOptionsList = (selectedPackage, optionsAvailable) => {
    console.log('Update our shit!!', selectedPackage)
    if (optionsAvailable && optionsAvailable.length) {
      console.log("OPTIONS", optionsAvailable)
      
      const totalPrice = props.estimate.totalPrice || 0
      let ourOptions = optionsAvailable
      if (optionsAvailable && optionsAvailable.options && optionsAvailable.options.length) {
        ourOptions = optionsAvailable[0].options
        // console.log('Our options are options.options', options.options)
      } 
      let index = 0
      // console.log('Our Options', ourOptions)
      for (const opt of ourOptions) {
        // console.log(opt)
        let isSelectedPackage = (opt.name === selectedPackage.name) ? true : false
        let price = parseFloat(totalPrice) * opt.ratio
        if (isSelectedPackage) {
          // // console.log('SELECTED')
          price = parseFloat(totalPrice)
          opt.description = 'As Shown Above'
          opt.selected = true
          opt.priced = true
        } else {
          opt.priced = false
          // // console.log(index, opt.name, opt.description)
          // // console.log('OA:', optionsAvailable[index])
          let desc = optionsAvailable[index]['description']
          // // console.log('DESC', desc)
          if (selectedPackage.name === 'Basic' && index === 1) {
            opt.description = 'Larger/More Items'
          } else {
            opt.description = desc
          }
          // // console.log('Option Ratio to price:', opt.ratio)
          let ratioToSelectedPackage = opt.ratio - selectedPackage.ratio
          // // console.log('SElected Ratio: ', selectedPackage.ratio)
          // // console.log('Ratio to that', ratioToSelectedPackage)
          let surcharge = parseFloat(totalPrice) * ratioToSelectedPackage
          price = parseFloat(totalPrice) + parseFloat(surcharge)
        }
        // // console.log('price', price)
        opt.price = parseFloat(price).toFixed(2)
        index++
      }
      console.log(ourOptions)
      // setState(prevState => ({...prevState, options: ourOptions }))
      // props.updateOptions(ourOptions)
    } else {
      console.log('No Options....')
      // setState(prevState => ({...prevState, options: [] }))
      // setState({...state, options: [] })
      // props.updateOptions([])
    }
  }

  const handleClose = () => {
    props.closeModal()
  }
  const handleSubmit = (data) => {
    // // console.log('Handle submit called 261', data)
    // // console.log('Options: ', options)
    // // console.log('Selected Package', selectedPackage)
    props.handleSaveOption({ selectedPackage: selectedPackage, options: options })
  }

  const updateOption = (e, newOpt) => {
    // // console.log("Change:::", newOpt)
    if (newOpt) {
      setSelectedPackage(newOpt)
      // setHTMLOnly(newOpt)
    } else {
      setSelectedPackage(null)
      // setHTMLOnly(null)
    }
  }

  const updateOptions = (newOptions) => {
    // // console.log('Update Options: ', newOptions)
    setOptions(newOptions)
  }

  return (
    <Dialog maxWidth={'lg'} fullWidth open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">

      <DialogTitle id="form-dialog-title">Package Options</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please Choose Which Packages To Show On The Proposal
        </DialogContentText>
      <span className={classes.labelText}>Which Package Are We Pricing Out?</span>
      <FormControl fullWidth >
       <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12} style={{marginBottom: 8 }}>
          This will be the package that is highlighted to the customer, but below you will be able to choose if you'd like to also show other pricing options on the proposal.
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Autocomplete
            id={`selectedPackage`}
            options={options || []}
            getOptionLabel={(option) => {
              // // console.log('options: ', option)
              return option.name ? `${option.name}` : ''
            }}
            disabled={props.type === 'areas'}
            // style={{ width: 200 }}
            className={classes.paddedStuff}
            // style={{marginBottom: 10, marginTop: 15}}
            onChange={updateOption}
            // onChange={ (e) => updateCrew(service, e.target.id, e.target.value, serviceIndex) }
            value = {selectedPackage}
            // onChange={ e => setState({...state, serviceType: e.target.value, requiresUpdate: true }) }
            renderInput={(params) => <TextField {...params} variant="outlined" label={`Selected Package`} />}
          />
        </Grid>
        <span className={classes.labelText}>Which Package Options Should Appear On The Quote?</span>
        <Grid item lg={12} md={12} sm={12} xs={12} >
          Packages Selected Below Will Appear on the PDF Quote for the Customer To See as Options.
          <PackageOptionList options={options} estimate={props.estimate} selectedPackage={selectedPackage} updateOptions={updateOptions} />
        </Grid>
        
      </Grid>
      </FormControl>
     
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
 
   </Dialog>
  )
}
const mapStateToProps = (state) => {
  // // // console.log('Map to Props New Estimate: ', state)
  return {
    url: state.url,
    notification: state.notification,
    user: state.user
  }
}

export default connect(mapStateToProps)(PackageOptions)
