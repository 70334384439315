const ProtectedButton = (props) => {
  // console.log('Protected Button', props)
  const type = props.type
  const kind = props.kind
  const perms = props.perms
  // console.log('Permission Needed: %s/%s', type, kind)
  let toShow = false
  perms.map(perm => {
    // console.log(perm)
    if (perm.type === type) {
      if (perm[kind]) {
        toShow = true
      }
    }
  })
  if (toShow) {
    return props.children
  } else {
    return null
  }
}

export default ProtectedButton