import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card,
  CardContent,
  Typography,
  CardActionArea,
  CardActions
} from '@material-ui/core'

// Dialog FOrm to Confirm You Wish to Delete Something
// Incoming Props Will Include:
// Title
// VerificationText
// ConfirmationOfActionText
// confirmButtonText
// denyButtonText
// open
const DeleteDialog = (props) => {

  const handleCloseModal = () => {
    props.closeModal(false)
  }

  const handleConfirm = () => {
    props.closeModal(true)
  }
  
  return (
    <Dialog
      open={ props.open }
      onClose={ handleCloseModal }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.verificationText}<br/><br/>{props.confirmationOfIntentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ handleCloseModal } color="primary">
          {props.denyText}
        </Button>
        <Button onClick={ handleConfirm } color="secondary" autoFocus>
          {props.confirmationButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default DeleteDialog
