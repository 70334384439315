import React from 'react'
import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";

const variables = {
  SF: "Square Footage",
  DEPTH: "Depth",
  COVERAGE: "Coverage",
};

const operators = ["+", "-", "*", "/", "(", ")"];

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  equationInput: {
    flex: 1,
  },
  select: {
    minWidth: "160px",
  },
  button: {
    minWidth: "80px",
  },
}));

export default function EquationBuilder() {
  const classes = useStyles();
  const [equation, setEquation] = useState("");
  const [previousEquations, setPreviousEquations] = useState([]);

  const handleEquationChange = (event) => {
    setEquation(event.target.value);
  };

  const handleVariableOrOperatorClick = (event) => {
    setPreviousEquations((prevEquations) => [...prevEquations, equation]);
    setEquation((prevEquation) => prevEquation + event.target.value);
  };

  const handleBackspaceClick = () => {
    if (equation === "") {
      setEquation(previousEquations.pop() || "");
      setPreviousEquations(previousEquations);
    } else {
      setEquation((prevEquation) => prevEquation.slice(0, -1));
    }
  };

  const handleUndoClick = () => {
    setEquation(previousEquations.pop() || "");
    setPreviousEquations(previousEquations);
  };

  const variableOptions = Object.entries(variables).map(([value, label]) => (
    <MenuItem key={value} value={value}>
      {label}
    </MenuItem>
  ));

  const operatorOptions = operators.map((operator) => (
    <MenuItem key={operator} value={operator}>
      {operator}
    </MenuItem>
  ));

  return (
    <form className={classes.form}>
      <TextField
        label="Equation"
        variant="outlined"
        className={classes.equationInput}
        value={equation}
        onChange={handleEquationChange}
        disabled
      />
      <Select
        value=""
        variant="outlined"
        className={classes.select}
        onChange={handleVariableOrOperatorClick}
        
      >
        <MenuItem disabled value="">
          Select variable or operator
        </MenuItem>
        {variableOptions}
        {operatorOptions}
      </Select>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleBackspaceClick}
      >
        Backspace
      </Button>
      <Button
        variant="contained"
        color="secondary"
        className={classes.button}
        onClick={handleUndoClick}
        disabled={previousEquations.length === 0}
      >
        Undo
      </Button>
    </form>
  );
}
