import React, { useState, useEffect } from 'react';
import { EditorState, ContentState, Modifier, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {
  Modal,
  Paper,
  Button,
  Typography,
  TextField,
  Switch,
  FormControlLabel,
  makeStyles,
  MenuItem,
  FormControl
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '80%',
    maxWidth: 800,
    maxHeight: '90vh',
    overflow: 'auto',
  },
  editor: {
    border: '1px solid #F1F1F1',
    padding: '5px',
    borderRadius: '2px',
    marginBottom: theme.spacing(2),
  },
  formControl: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
}));

const defaultTemplate = `
<p>{{jobName}}</p>
<p></p>
`;

const progressTemplate = `
<p><strong>{{jobName}}</strong></p>
<p>Landscape Installation as per proposal #{{estimateNumber}}</p>
<p>Estimate Amount: {{totalEstimateAmount}}</p>
<p>Progress Payment - {{estimateName}}</p>
<p>Previously Invoiced: {{previouslyInvoiced}}
Progress Payment Due: {{invoiceAmount}}
Remaining Balance After Progress Payment: {{remainingBalance}}
<p>Any Extras will be billed separately</p>
</p>
`;

const progressWithBreakdownTemplate = `
<p>{{jobName}}
Landscape Installation as per proposal #{{estimateNumber}}
Estimate Amount: {{totalEstimateAmount}}
</p>
<p>Progress Payment - {{estimateName}}
Previously Invoiced: {{previouslyInvoiced}}
Current Balance Due: {{invoiceAmount}}
Remaining Balance After Progress Payment: {{remainingBalance}}
</p>
<p>Invoice Breakdown:
Materials: {{materialsTotal}}
Labor: {{laborTotal}}
Equipment: {{equipmentTotal}}
<p>Any Extras will be billed separately</p>
</p>
`;

const tandMTemplate = `
<p>{{jobName}}</p>
<p>Invoice Amount: {{invoiceAmount}}</p>
`;

const predefinedTemplates = {
  default: {
    name: 'Default',
    content: defaultTemplate
  },
  progress: {
    name: 'Progress Payment',
    content: progressTemplate
  },
  tandmjob: {
    name: 'T & M Job',
    content: tandMTemplate
  },
  progressWithDetails: {
    name: 'Progress Payment (with Breakdown)',
    content: progressWithBreakdownTemplate
  }
};

const InvoiceNotesEditorModal = ({ open, handleClose, initialTemplate, variables, onSave }) => {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showLineItems, setShowLineItems] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('default');

  // Helper function to replace variables in the template content
  const fillTemplateWithVariables = (templateContent, variables) => {
    let filledContent = templateContent;
    Object.keys(variables).forEach((key) => {
      const placeholder = `{{${key}}}`;
      filledContent = filledContent.replace(new RegExp(placeholder, 'g'), variables[key]);
    });
    return filledContent;
  };

  useEffect(() => {
    const loadEditorContent = (templateContent) => {
      const filledTemplate = fillTemplateWithVariables(templateContent, variables);
      const blocksFromHtml = htmlToDraft(filledTemplate);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      setEditorState(EditorState.createWithContent(contentState));
    };

    if (initialTemplate) {
      loadEditorContent(initialTemplate);
    } else {
      loadEditorContent(predefinedTemplates.default.content);
    }
  }, [initialTemplate, variables]);

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleShowLineItemsChange = (event) => {
    setShowLineItems(event.target.checked);
  };

  const handleSave = () => {
    let html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    onSave({ content: html, showLineItems });
    handleClose();
  };

  const handleTemplateChange = (event) => {
    const newTemplate = event.target.value;
    setSelectedTemplate(newTemplate);
    const templateContent = predefinedTemplates[newTemplate].content;
    const filledTemplate = fillTemplateWithVariables(templateContent, variables);
    const blocksFromHtml = htmlToDraft(filledTemplate);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    setEditorState(EditorState.createWithContent(contentState));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      className={classes.modal}
      aria-labelledby="invoice-notes-modal"
      aria-describedby="invoice-notes-modal-description"
    >
      <Paper className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Invoice Display Details
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Customize What the Customer Will See
        </Typography>
        <FormControl className={classes.formControl}>
          <TextField
            labelId="template-select-label"
            id="template-select"
            value={selectedTemplate}
            fullWidth
            variant="outlined"
            onChange={handleTemplateChange}
            select
            helperText="Choose From Pre-Existing Templates"
          >
            {Object.entries(predefinedTemplates).map(([key, template]) => (
              <MenuItem value={key} key={key}>{template.name}</MenuItem>
            ))}
          </TextField>
        </FormControl>
        <div className={classes.editor}>
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorStateChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
        </div>

        <FormControlLabel
          control={
            <Switch
              checked={showLineItems}
              onChange={handleShowLineItemsChange}
              name="showLineItems"
              color="primary"
            />
          }
          label="Show Line Item Details (Display All Line Items on Invoice)"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          style={{ marginTop: '16px' }}
        >
          Save Invoice Details
        </Button>
      </Paper>
    </Modal>
  );
};

export default InvoiceNotesEditorModal;
