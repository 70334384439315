import React from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import { Button, CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';


const ConfigurationContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
align-content: center;
text-align: center;
border-radius: 15px;
height: 110px;
margin-top: 25px;
width: 100%;
`;

const LabelText = styled.div`
  color: #222;
  font-size: 16px;
  text-align: left;
`;

const defaultValues = {
  qty: 0,
  unit: '',
  description: '',
  totalPrice: 0,
  costPer: 0,
  pricePer: 0
}

const ConfigureItem = (props) => {
// console.log('CONFIGURE ITEM EDIT 42: ', props)
  const [state, setState] = React.useState(defaultValues)

  React.useEffect(() => {
    // console.log('Item has changed', props.item)
    if (props.item && props.item.sku) {
      let pricePer = 0
      let totalPrice = 0
      let costPer = props.item.price
      pricePer = Number(costPer * 1.4).toFixed(2)
      totalPrice = Number(pricePer * state.qty).toFixed(2)
      setState({...state, description: props.item.name, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice })
    } else {
      if (props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length === 1) {
        setState({...state, unit: props.item.unitsOfMeasure[0], description: props.item.description})
      } else if (props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length < 1) {
        setState({...state, unit: 'EA', description: props.item.description})
      } else {
        setState({...state, description: props.item.description})
      }
    }

  }, [props.item])

  const handleSaveNewLineItem = () => {
    // console.log('Save Line Item', state)
    // Add this to our Estimate
    let itemToSend = {
      ...state
    }
    itemToSend.item = props.item
    props.addLineItemToEstimate(itemToSend)
    setState(defaultValues)
  }

  const handleFocus = (e) => {
    e.preventDefault()
    e.target.focus()
    e.target.setSelectionRange(0, e.target.value.length)
  }

  const handleCostChangeUpdate = (e) => {
    let pricePer = 0
    let totalPrice = 0
    let costPer = e.target.value
    pricePer = Number(costPer * 1.4).toFixed(2)
    totalPrice = Number(pricePer * state.qty).toFixed(2)
    setState({...state, costPer: costPer, pricePer: pricePer, totalPrice: totalPrice})
  }

  const handlePriceManualChange = (e) => {
    let pricePer = e.target.value
    let totalPrice = 0
    totalPrice = Number(pricePer * state.qty).toFixed(2)
    setState({...state, pricePer: pricePer, totalPrice: totalPrice})
  }

  const handleQtyUpdate = (e) => {
    let pricePer = state.pricePer
    let totalPrice = 0
    totalPrice = Number(pricePer * e.target.value).toFixed(2)
    setState({...state, qty: e.target.value, totalPrice: totalPrice})
  }
  return (
    <ConfigurationContainer>
      
      <Grid container spacing={2}>
        {/* <Grid item lg={12} xs={12}>
          <LabelText>The following information will show up on the estimate, please type it up accordingly:</LabelText>
        </Grid> */}
        <Grid item lg={1} >
          <TextField
              label="Quantity"
              variant="outlined"
              type="text"
              value={state.qty}
              onChange={handleQtyUpdate}
              onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2}>
          {/* <TextField
              label="Unit"
              variant="outlined"
              type="text"
              value={state.unit}
              onChange={e=>setState({...state, unit: e.target.value})}
              onFocus={handleFocus}
            /> */}
        <FormControl variant="outlined" style={{width: '100%'}}>
        <InputLabel id="demo-simple-select-outlined-label">Unit</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={state.unit}
          onChange={e=>setState({...state, unit: e.target.value})}
          label="Unit"
        >
          {props.item && props.item.unitsOfMeasure && props.item.unitsOfMeasure.length ? (
             props.item.unitsOfMeasure.map(unit => {
               return (
               <MenuItem key={unit.name} value={unit.name} >{unit.name}</MenuItem>
             )})
          ) : (
            <MenuItem value={'EA'} >Each</MenuItem>
          )}
        </Select>
      </FormControl>
        </Grid>
        <Grid item lg={5}>
          <TextField
            label="Description"
            variant="outlined"
            type="text"
            value={state.description || ''}
            onChange={e=>setState({...state, description: e.target.value})}
            fullWidth
            />
        </Grid>
        <Grid item lg={1}>
          <TextField
            label="Cost Per"
            variant="outlined"
            type="text"
            value={state.costPer}
            onChange={handleCostChangeUpdate}
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={1}>
          <TextField
            label="Price Per"
            variant="outlined"
            type="text"
            value={state.pricePer}
            onChange={handlePriceManualChange}
            onFocus={handleFocus}
            />
        </Grid>
        <Grid item lg={2}>
          <TextField
            label="Total Price"
            variant="outlined"
            type="text"
            value={state.totalPrice}
            onChange={e=>setState({...state, totalPrice: e.target.value})}
            onFocus={handleFocus}
            disabled
            />
        </Grid>
        <Grid item lg={12}>
          <Button style={{margin: 10}} fullWidth variant="contained" color="primary" disabled={!state.description} onClick={handleSaveNewLineItem}>Add Line Item</Button>
        </Grid>
      </Grid>
    </ConfigurationContainer>
  )
}

export default ConfigureItem