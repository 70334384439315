import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
// import { fade, makeStyles } from '@material-ui/core/styles'
import { fade, makeStyles, withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import UploadDetailsInputArea from './UploadDetailsInputArea'
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
// import ProjectUploadsList from '../Projects/ProjectUploadsList'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import VendorUploadList from './VendorUploadList'
import EditIcon from '@material-ui/icons/Edit';
import EditVendorDetailsModal from './EditVendorDetailsModal'
import ContactListComponent from './ContactListComponent'
import ContactComponent from './AddVendorContact'
import { formatPhoneNumber } from '../../services/common'

const VendorPhone = ({ vendor }) => {
  if (vendor && vendor.phoneNumber) { 
    let phoneNumber = formatPhoneNumber(vendor.phoneNumber) 
    return (
      <div>
        { phoneNumber }
      </div>
    )
  } else {
    return null
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const defaultInputValues = {
  projectName: ''
}

const categoryOptions = [
  'Vendor Quotes',
  'Vendor Invoice',
  'Customer Invoice',
  'Purchase Order',
  'Photos',
  'Plans',
  'Estimates',
  'Other'
]

function currencyFormat(num) {
  // console.log('Currencyformat: ', num)
  if (!num) num = 0
  return '$' + parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const EditVendorDetails = (props) => {
  // console.log('Edit Upload Details: ', props)
  // console.log('NewProjectModal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [client, setClient] = React.useState({})
  const [pdf, setPDF] = React.useState({})
  const [upload, setUpload] = React.useState({})
  const [uploads, setUploads] = React.useState([])
  const [invoices, setInvoices] = React.useState([])
  const [contacts, setContacts] = React.useState([])
  const [expanded, setExpanded] = React.useState('')
  const [showNewContactModal, setShowNewContactModal] = React.useState(false)
  const [showEditVendorModal, setShowEditVendorModal] = React.useState(false)
  const [state, setState] = React.useState({
    status: 'New',
    projectName: '',
    notes: '',
    name: '',
    amountDue: 0,
    amountPaid: 0
  })

  const VendorAddy = () => {
    if (props.vendor && props.vendor.address) {
      return (
        <>{props.vendor.telephone ? <div>formatPhoneNumber(props.vendor.telephone)</div> : null } {props.vendor.address} <br/> {props.vendor.city}, {props.vendor.state} {props.vendor.zipcode}</>
      )
    } else {
      return ''
    }
  }
  React.useEffect(() => {
    let isMounted = true
    setLoading(true)
    async function fetchVendors () {
      let url = `${props.url}/api/vendors/uploads?_id=${props.vendor._id}`
      axios.get(url).then((response, status, data) => {
      // console.log('RESPONSE: ', response.data)
        setLoading(false)
        if (response && response.data) {
          if (isMounted) {
            setUploads(response.data)
            let vendorInvoices = []
            response.data.map(item => {
              if (item.documentType === 'Vendor Invoice') vendorInvoices.push(item)
            })
          // console.log('Vendor Invoices: ', vendorInvoices)
            let paid = 0
            let due = 0
            vendorInvoices.map(item => {
              if (item.status === 'Due') due += parseFloat(item.amount)
              if (item.status === 'Paid') paid += parseFloat(item.amount)
            })
          // console.log('Paid: ', paid)
          // console.log('Due', due)
            setState({...state, amountDue: due, amountPaid: paid })
          }
        }
      })
    }

    async function fetchContacts () {
      let url = `${props.url}/api/vendors/contacts?_id=${props.vendor._id}`
      axios.get(url).then((response, status, data) => {
      // console.log('COntacts', response.data)
        setContacts(response.data)
      })
    }
    if (props.vendor && props.vendor._id) {
      fetchVendors()
      fetchContacts()
    }

    return () => {
      isMounted = false
    }
  }, [props.vendor])

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  }

  const handleSave = () => {
    // console.log(inputRef)
    // console.log('Handle save: ', state)
    // console.log(inputRef.current)
    // console.log('Upload Details:', uploadDetailsRef.current)
    setLoading(true)
    // setClient({})
    // let data = state // inputRef.current
    let data = {}
    // data.projectId = props.project._id
    // data.uploadId = props.upload._id
    // data.category = state.category
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/tagOurUpload`,
      data: data
    }).then(response => {
      setLoading(false)
      if (response.data && response.data._id) {
        props.notification({
          type: 'success',
          title: 'Uploads Saved',
          message: 'That Upload Has Been Saved. Great Job!'
        })
        // inputRef.current = {
        //   vendor: null,
        //   client: null,
        //   notes: null,
        //   amount: null,
        //   number: null,
        //   project: null
        // }
        setUpload({})
        setState({...state, status: '', notes: ''})
        props.updateUpload(response.data)
        // setState({...state, name: '', notes: ''})
        // props.updateParent(response.data)
        // props.closeModal()
      }

    })
  }

  const handleTabInteraction = (e) => {
    if (e.keyCode == 9) {
    // console.log(e.target)
      if (e.target.id === 'customername') {
        // window.alert(`Tab on custoemr ${e.target.value}`)
        let newArr = []
        let string = e.target.value
        props.clients.filter(o => {
          let str = o['displayName'].toString().toLowerCase()
          if (str.includes(string.toLowerCase())) {
            return newArr.push(o)
          } else {
            return null
          }
        })
        // console.log(newArr)
        if (newArr && newArr.length) {
          setClient(newArr[0])
        }
      }
    }
  }


  const handleFocus = (e) => {
    e.target.select()
  }

  const handleClose = () => {
    props.closeModal()
  }

  const handlePrevious = () => {
    props.handlePrevItem()
  }
  const handleNext = () => {
    props.handleNextItem()
  }

  const handleDelete = () => {
    if (window.confirm(`Are you sure you wish to delete ${props.vendor.name}? This action cannot be reversed. Proceed with caution.`)) {
      let data = {
        _id: props.vendor._id
      }
      axios({
        method: 'post',
        url:  `${props.url}/api/vendors/delete`,
        data: data
      }).then(response => {
        // setLoading(false)
        if (response.data && response.data._id) {
          props.notification({
            type: 'success',
            title: 'Vendor Deleted',
            message: `I've never even heard of ${props.vendor.name} - this will never be mentioned again between us.`
          })
          props.deleteVendor(props.vendor)
        }
        })
    }

  }

  const handleEdit = ()=> {
    setShowEditVendorModal(true)
  }

  const closeModal = () => {
    setShowEditVendorModal(false)
  }

  const modifyVendor = (vendor) => {
    closeModal()
    props.updateVendor(vendor)
  }

  const saveContactToDB = (contact) => {
    let data = {
      _id: props.vendor._id,
      contact: contact
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/vendors/addContact`,
      data: data
    }).then(response => {
      // setLoading(false)
      if (response.data && response.data._id) {
        props.notification({
          type: 'success',
          title: 'Vendor Contact Saved',
          message: `Saved ${contact.firstName} to the database.`
        })
      }
    })
  }
  const saveContact = (contact) => {
    // console.log('Save this contact to our Contact List')
    let newContacts = contacts
    newContacts.unshift(contact)
    setShowNewContactModal(false)
    setContacts(newContacts)
    saveContactToDB(contact)
    setState({...state, counter: state.counter++ })
  }

  const handleCoseAddVCModal = () => {
    setShowNewContactModal(false)
  }

  return (
    <div className={classes.root}>
      <EditVendorDetailsModal open={showEditVendorModal} vendor={props.vendor} vendors={props.vendors} closeModal={closeModal} updateParent={modifyVendor} />
      <ContactComponent open={showNewContactModal} saveContact={saveContact} closeModal={handleCoseAddVCModal} />
      <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth={'lg'} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
             <span style={{ display: 'flex', justifyContent: 'flex-end', verticalAlign: 'top'}} >
             <IconButton
              label="Delete"
              onClick={handleDelete}
              // startIcon=
              >
                <DeleteForeverIcon />
              </IconButton>
            </span>
           
           {props.vendor ? props.vendor.name : ''}
           <Tooltip title="Edit Details" >
                                <IconButton edge='end' onClick={handleEdit} alt="Edit Details" aria-label="Edit Details">
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
           
          </DialogTitle>
          <DialogContent >
          
            <DialogContentText>
              <VendorPhone vendor={props.vendor} />
             <VendorAddy />
             <br />
             {props.vendor ? props.vendor.description : ''}
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                  Invoices Due: {currencyFormat(state.amountDue)}<br />
                  Invoices Paid: {currencyFormat(state.amountPaid)}
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                {/* <UploadDetailsInputArea upload={upload} clients={props.clients} vendors={props.vendors} updateParent={updateFromUploadDetails} /> */}
              </Grid>
              
              <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
                <DialogContentText>Contacts</DialogContentText>
                <ContactListComponent contacts={contacts} state={state} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} style={{textAlign: 'center'}}>
                <Button
                  color="primary"
                  // fullWidth
                  variant="contained"
                  style={{ width: '50%'}}
                  label="Add Contact"
                  onClick={e => setShowNewContactModal(true)}
                >Add Contact</Button>
              </Grid>
              <Grid item lg={12} xs={12}>
               
                    <Accordion square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                  <AccordionSummary aria-controls="panel5d-content"  expandIcon={<ExpandMoreIcon />} id="panel5d-header">
                    <Typography>File Uploads {(uploads && uploads.length) ? `(${uploads.length} Files)` : '' } </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <VendorUploadList vendor={props.vendor} uploads={uploads} />
                  </AccordionDetails>
                </Accordion>
                
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
            {/* <Button color="primary" disabled={loading} onClick={handleSave}>
              {
                loading ? <CircularProgress /> : 'Save'
              }
            </Button> */}
          </DialogActions>
        </Dialog>
    </div>
  )
}


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(EditVendorDetails)