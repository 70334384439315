import React from 'react'
import { connect } from 'react-redux'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import WorkorderList from './SelectWorkordersToAssociate'
// const WorkorderList = (props) => {
//   return props.workorders.map(wo => {
//     return (
//      <p>
//       {wo.number}
//     </p>
//   )})
// }

const AssociateWorkorders = (props) => {
  // console.log('AssociateWorkorders: ', props)
  const [state, setState] = React.useState({
    workorders: []
  })

  const [loading, setLoading] = React.useState(false)
  const [workorders, setWorkorders] = React.useState([])
  const [selectedWorkorders, setSelectedWorkorders] = React.useState([])

  React.useEffect(() => {
    let isMounted = true
    async function fetchWorkorders(clientId) {
      axios.get(`${props.url}/api/projects/getWorkordersToAssociate?clientId=${clientId}`).then(response => {
              if (response.data && response.data.length && isMounted) {
              // console.log('Workorders to list: ', response.data)
                setWorkorders(response.data)
              }
      })
    }

    if (props.client && props.client._id) {
      // console.log('Get our workorders; ', props)
      // console.log('GET WORKORDERS FOR THIS CLIENT: ', props.client._id)
      fetchWorkorders(props.client._id)
    }
    return () => {
      isMounted = false
    }
  }, [props.client])

  const handleCloseModal = () => {
    props.updateParent('closeModal')
  }

  const handleSubmit = () => {
    // console.log('Handle Submit', state)
    // let clockIn = state.clockIn
    // let clockOut = state.clockOut
 
    //   employeeId: employee._id,
    //   data: state
    // }
    let data = {
      jobs: selectedWorkorders,
      project: props.project._id
    }
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/associateWorkorders`,
      data: data,
      // validateStatus: (status) => {
      // // console.log('Validate status: ', status)
      //   if (status && status === 500) {
  
      //   }
      // },
    }).then(response => {
      // console.log('Axios response to update manuallyAddShift Time Entry ', response)
      if (response && response.data && response.data.success) {
        props.notification({
          type: 'success',
          title: `Jobs Associated To Project`,
          message: `We Modified ${response.data.totalUpdated} out of ${response.data.requested} Jobs Selected!`
        })
      let updatedWorkorders = []
      for (const wo of selectedWorkorders) {
      // console.log(wo)
        let index = workorders.findIndex(item => (item._id === wo))
      // console.log(index)
        if (index > -1) {
          updatedWorkorders.push(workorders[index])
        }
      }
      // console.log('Updated WOrkordders', updatedWorkorders)
      props.updateParent('saved', updatedWorkorders)
      }
    })
  }

  const updateSelected = (d) => {
  // console.log('Selected WOrkorders to assoicate with this proejct', d)
    setSelectedWorkorders(d)
  }


return (
  <div>
    
      <Dialog
        // fullScreen={false}
        maxWidth={'md'}
        // width='lg'
        // fullScreen
        fullWidth
        // maxwidth='lg'
        // style={{width: '90hw'}}
        open={props.open}
        // scroll='paper'
        onClose={handleCloseModal}
        aria-labelledby="responsive-dialog-title"
        >

        <DialogTitle id="responsive-dialog-title">Associate Jobs</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Choose The Jobs Tied to This Project:
          </DialogContentText>
          <WorkorderList workorders={workorders} updateSelected={updateSelected} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
          {/* <Button onClick={() => handleSubmit(0)} color="primary">
            Submit
          </Button> */}
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
          {/* <Button onClick={ handleConfirm } color="primary">
            Confirm
          </Button> */}
        </DialogActions>
      </Dialog>
          </div>
          )
          }

const mapStateToProps = (state) => {
  return {
    url: state.url,
    user: state.user,
    notification: state.notification
  }
}

export default React.memo(connect(mapStateToProps)(AssociateWorkorders))