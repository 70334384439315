import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import SateliteView from '../Crews/SateliteView'
import { humanFileSize } from '../../services/common'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    // // width: 200,
  },
}));

const UploadStatusModal = (props) => {
  // console.log('UploadStatusModal Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)

  // const [state, setState] = React.useState({
  //   status: '',
  //   files: [],
  //   response: []
  // })

  // React.useEffect(() => {
  //   setState({...state, files: props.files, response:  props.filesResponse})
  // }, [props])

  const handleClose = () => {
    // setAssociatedProject({})
    // setAssociatedProject({})
    // setState({...state, assigned_to: {}, client: {}, associatedProject: {}, note: '' })
    props.closeModal()
  }

  const duplicateFiles = props.filesResponse.duplicateFiles
  const filesUploaded = props.filesResponse.filesUploaded
  const errorFiles = props.filesResponse.errorFiles

  return (

      <Dialog open={props.open} fullWidth={true}  maxWidth='md' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Upload Status</DialogTitle>
          <DialogContent >
            <DialogContentText>
                We Are Processing Your Uploads Now. When the upload is complete you will get details on each of the files uploaded below.
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <ul>
                {
                  props.files.map((item, index) => {
                    console.log('item: ', item)
                    return (
                      <li key={index}>
                          {item.name} - {item.size} 
                          <span style={{marginLeft: 15}}>
                                {item.notAllowed ? 'Not Allowed' : ''}
                              {item.duplicateFile ? 'Duplicate File Detected (Not Uploaded)' : ''}
                              {item.uploaded ? 'Uploaded Successfully' : ''}
                          </span>
                      
                      </li>
                    )
                  })}
                  </ul>
              </Grid>

            </Grid>
            {
              filesUploaded.length ? (
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12} style={{ fontSize: '1.2em', color: '#aaa', fontWeight: 700 }}>
                    { `${filesUploaded.length} File${ filesUploaded.length > 1 ? 's' : '' }  Uploaded` }
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                  {
                      props.filesResponse.filesUploaded.map((fileUploaded, index) => {
                     
                        return (                         
                            
                            <div key={index} style={{  margin: 10, borderRadius: 8, padding: 10, border: '1px solid #ccc', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10 }}>
                              <div>
                                {fileUploaded.name} {humanFileSize(fileUploaded.size, true) || ''}
                              </div>
                              <div>
                                <a href={`${fileUploaded.url}`} target="_blank">{fileUploaded.name}</a>
                              </div>
                              <div>
                                {
                                  fileUploaded.type.includes('image') ? (
                                    <img src={`${fileUploaded.url}`} height={100} />
                                  ) : null
                                }
                              </div>
                            </div>
                    
                        )
                      })
                  }
                  </Grid>
                </Grid>
              ) : null
            }

           
            { duplicateFiles.length ? (
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12} style={{ fontSize: '1.2em', color: '#aaa', fontWeight: 700 }}>
                    { `${duplicateFiles.length} Duplicate File${ duplicateFiles.length > 1 ? 's' : '' }  Detected` }
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {
                      duplicateFiles.map((item, index) => {
                        console.log('Duplocate File Item: ', item)
                        const file = item.file
                        const existingFiles = item.existingFiles
                        return (
                          <div key={index} style={{ margin: 10, borderRadius: 8, padding: 10, border: '1px solid orange'}}>
                            {file.originalname}
                            <div style={{ margin: 10}}>
                              {
                                existingFiles.map((existingFile, fileIndex) => {

                                  return (
                                    <div key={fileIndex} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', padding: 10 }}>
                                      <div>
                                        Uploaded by {existingFile.created_by} at {moment(existingFile.created_at).format('MM/DD/YY hh:mma')}
                                      </div>
                                      <div>
                                       <a href={`${existingFile.url}`} target="_blank">{existingFile.name}</a>
                                      </div>
                                      <div>
                                        {
                                          existingFile.type.includes('image') ? (
                                            <img src={`${existingFile.url}`} height={100} />
                                          ) : null
                                        }
                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                        </div>
                        )
                      })
                    }
                  </Grid>
              </Grid>
              ) : null
            }
             
            {
              errorFiles.length ? (
                <Grid container spacing={2}>
                  <Grid item lg={12} md={12} sm={12} xs={12} style={{ fontSize: '1.2em', color: '#aaa', fontWeight: 700 }}>
                  { `${errorFiles.length} File${ errorFiles.length > 1 ? 's' : '' }  Not Uploaded` }
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                  {
                      props.filesResponse.errorFiles.map((item, index) => {
                        console.log('Error File Item: ', item)
                        return (
                          <div key={index} style={{ margin: 10, borderRadius: 8, padding: 10, border: '1px solid red'}}>
                            File Not Allowed - {item.name}
                            <div>
                              Most likely this is a mimetype issue - we need to allow {item.mimetype}
                            </div>
                        </div>
                        )
                      })
                  }
                  </Grid>
                </Grid>
              ) : null
            }
   
          </DialogContent>
          <DialogActions>
             <Button onClick={handleClose} color="primary">
              Close
            </Button> 
          </DialogActions>
        </Dialog>
  
  )
}


  const mapStateToProps = (state) => {
    // console.log('STATE TO PROPS', state)
    return {
      url: state.url,
      user: state.user,
      notification: state.notification,
    }
  }
  
  export default connect(mapStateToProps)(UploadStatusModal)