import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import CloseIcon from '@material-ui/icons/Close'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import AddCircleIcon from '@material-ui/icons/AddCircle';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputMask from "react-input-mask";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import MilestoneEditor from './MilestoneEditor'
import { Editor } from 'react-draft-wysiwyg';
import NewReminderComponent from '../Reminders/NewReminderComponent'
// import SuppliesUsedModal from '../Supplies/SuppliesUsedModal'
import LineItemInput from '../Supplies/LineItemInput'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px'
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px',
    marginBottom: 5
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '10px',
    // margin: '5px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const filter = createFilterOptions();

const thirtyDays = new Date(new Date().getTime() - 0 + (30 * 24 * 60 * 60 * 1000)).toISOString().slice(0, -8)

const defaultState = {
  name: '',
  details: '',
  deadline: '',
  subTasks: [],
  assignedTo: '',
  reminder: {},
  supplies_needed: [],
  status: ''
}

const CreateNewMilestoneModal = (props) => {
  // console.log('CreateNewMilestoneModal  ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [state, setState] = React.useState(defaultState)
  const [value, setValue] = React.useState(null)
  const [item, setItem] = React.useState({ firstName: '' })
  const [openNewContactModal, toggleOpen] = React.useState(false)
  const [contact, setContact] = React.useState({})
  const [clientList, setClientList] = React.useState([])
  const [crews, setCrews] = React.useState([])
  const [supplies, setSupplies] = React.useState([])
  const [takeOffs, setTakeOffs] = React.useState([])
  const [services, setServices] = React.useState([])
  const [showServiceInput, setShowServiceInput] = React.useState(false)
  const [showInputInvoiceModal, setShowInputInvoiceModal] = React.useState(false)
  const [activeMtcClients, setActiveMtcClients] = React.useState([])

  React.useEffect(() => {
    let isMounted = true
    const fetchSupplies = async () => {
      setLoading(true)
      axios.get(`${props.url}/api/company/listBHLSupplies`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
          if (isMounted) setSupplies(response.data)
        }
      })
    }

    const fetchTakeOffs = async () => {
      axios.get(`${props.url}/api/company/listTakeOffs`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
        // console.log('Takeoffs', response.data)
          if (isMounted) setTakeOffs(response.data)
        }
      })
    }

    const fetchServices = async () => {
      axios.get(`${props.url}/api/company/listservices`).then((response, status, data) => {
        setLoading(false)
        if (response && response.data) {
        // console.log('Services', response.data)
          if (isMounted) setServices(response.data)
        }
      })
    }

    fetchSupplies()
    fetchTakeOffs()
    fetchServices()
    return () => {
      isMounted = false
    }
  }, [])


  const handleClose = () => {
    // console.log('Closing New Contact Modal')
    // setOpen(false)
    setState(defaultState)
    props.closeModal('Close')
  }

  const handleChange = (e) => {
    setState({...state, [e.target.id]: e.target.value })
  }

  const handleSave = () => {
    // setLoading(true)
  // console.log('handle save new milestone', state)
    let ourData = {...state}
  // console.log('Do we have anythingon the inputRef', item)
    if (item && item.firstName && state.assignedTo === "") ourData.assignedTo = item.firstName
    if (state.reminder && !state.reminder.showReminderDetails) ourData.reminder = null
    // ourData.assignedTo = state.
    ourData.status = state.status ? state.status : 'Not Started'
  // console.log(ourData)
    let obj = {
      project: props.project,
      data: ourData,
      contact: contact
    }
  // console.log('POst to ', obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/projects/newMilestone`,
      data: obj,
    }).then(response => {
      setLoading(false)
      if (response.data._id) {
        props.notification({
          type: 'success',
          title: 'Milestone Saved!',
          message: 'This Milestone Has Been Saved!'
        })
        setState(defaultState)
        props.updateParent(response.data)
      } else {
        props.notification({
          type: 'warning',
          title: 'Uh Oh!',
          message: 'Something messed up adding this Milestone...not sure why!'
        })
      }
    }).catch(err => {
      // console.log('Error saving workorder: ', err)
      props.notification({
        type: 'danger',
        title: 'Uh Oh!',
        message: 'Error Number 229 - Projects - Create Milestone'
      })
    })
  }
  const handleFocus = (event) => event.target.select();

  const handleNewReminder = (reminder) => {
    // console.log('Add Reminder to this Milestone', reminder)
    setState({...state, reminder: reminder })
  }

  const MilestoneDraftEditor = () => {
    const updateMilstoneDetails = (data) => {
      // console.log('Milestone details', data)
      setState({...state, details: data })
    }
    return (
      <MilestoneEditor id="details" updateContent={updateMilstoneDetails} />
    )
  }

  const handleContactChange = (e, newContact) => {
    // console.log('Change in contact:', newContact)
    // e.preventDefault()
    if (newContact && newContact._id) {
      // console.log('Cohntact')
      setContact(newContact)
      let name = newContact.firstName + ' ' + newContact.lastName
      setState({...state, assignedTo: name })
    }
  }

  const addSuppliesUsed = (data) => {
  // console.log('Add supplies used', data)
    setState({...state, supplies_needed: data })
  }

  return (
    <div className={classes.root}>
      <Dialog open={props.open} fullWidth={true} onClose={handleClose} maxWidth='md' aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Add New Milestone to the Project</DialogTitle>
          <DialogContent >
          <IconButton onClick={handleClose} style={{ 'position': 'absolute', 'top': '15px', 'right': '30px' }} edge='end' alt="Close" aria-label="Close">
            <CloseIcon />
          </IconButton>
            <DialogContentText>
              Please Add The Milestone Details Below
            </DialogContentText>
            <Grid container spacing={2}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <TextField
                  id="name"
                  label="Milestone Name"
                  // style={{ margin: 8 }}
                  value={state.name}
                  // onChange={updateRef}
                  onChange={ handleChange }
                  // ref={inputRef['projectName']}
                  onFocus={handleFocus}
                  placeholder="Name"
                  helperText="What Would You Call This Milestone?"
                  fullWidth
                  // value={inputRef.current['projectName']}
                  margin="normal"
                  variant="outlined"
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <TextField
                  id="status"
                  select
                  label="Milestone Status"
                  margin="normal"
                  value={state.status}
                  onChange={handleChange}
                  fullWidth
                  SelectProps={{
                    native: true,
                  }}
                  helperText="What Is The Status Of This Milestone?"
                  variant="outlined"
                >
                  {statusOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <Autocomplete
                  id="assignedTo"
                  options={props.employees || []}
                  getOptionLabel={(option) => {
                    return `${option.firstName || ''} ${option.lastName || ''}`
                  }}
                  value={state.contact}
                  selectOnFocus
                  onChange={handleContactChange}
                  onInputChange={(e, inputValue) => {
                    // console.log('input changed', inputValue)
                    let newContact = {
                      firstName: inputValue
                    }
                    setItem(newContact)
                  }}
                  freeSolo
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Milestone Assignment" helperText="Who Is This Assigned To" placeholder="Assigned?" />
                  )}
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <TextField
                  type="datetime-local"
                  id="deadline"
                  variant="outlined"
                  label="Milestone Deadline"
                  onChange={ handleChange }
                  name="datetime-local"
                  value={ state.deadline }
                  fullWidth
                  helperText="When Should This Be Completed By?"
                  // defaultValue={state.deadline}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item lg={12}>
                {/* <SuppliesUsedModal supplies={supplies} /> */}
                <LineItemInput updateParent={addSuppliesUsed} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <NewReminderComponent {...props} updateParent={handleNewReminder} />
              </Grid>
          </Grid>
            
            

          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
              Cancel
            </Button> */}
            <Button color="primary" disabled={loading || !state.name } onClick={handleSave}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
    </div>
    )
  }

  const statusOptions = [
    {
      value: 'Not Started',
      label: 'Not Started'
    },
    {
      value: 'Delayed',
      label: 'Delayed'
    },
    {
      value: 'Project Pause',
      label: 'Project Pause'
    },
    {
      value: 'In Progress',
      label: 'In Progress'
    },
    {
      value: 'Complete',
      label: 'Complete'
    }
  ]
  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }
  
  export default connect(mapStateToProps)(CreateNewMilestoneModal)