import React from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Paper from '@material-ui/core/Paper'
import { fade, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
// import AddCustomerPaymentMethods from './AddCustomerPaymentMethods'
// import AddCustomerEcheck from './AddCustomerEcheck'
import ServiceDetailList from './ServiceDetailList'
import InvoiceOptionsModal from './InvoiceOptionsModal'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Button, CircularProgress }  from '@material-ui/core';
import moment from 'moment';
import TextField from '@material-ui/core/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
// import DialogContentText from '@material-ui/core/DialogContentText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
// import styles from './customer.module.scss'
import ChooseEntityComponent from '../Entities/ChooseEntityComponent'
import DuplicatesModal from './DuplicatesModal'

const useStyles = makeStyles(theme => ({
  root: {
    width: '80%',
    margin: '20px',
    zIndex: 8
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  grid: {
    margin: '10px',
    padding: '10px',
    width: '95%'
  },
  gridParent: {
    margin: '10px',
    padding: '10px',
    width: '95%',
    border: '3px solid #aaa',
    borderRadius: '20px'
  },
  gridItem: {
    padding: '5px'
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paymentProfiles: {
    border: '1px solid',
    // marginRight: '5px',
    // padding: '5px'
  },
  bigText: {
    fontSize: '1.5em',
    color: '#333',
    textAlign: 'center'
  },
  paddedStuff: {
    padding: '5px',
    margin: '5px'
  }
}));

const NewCustomerModal = (props) => {
  // console.log('NewCustomerModal  Props: ', props)
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
  const [individual, setIndividual] = React.useState(true);
  // const [invoice, setInvoice] = React.useState(null)
  const [state, setState] = React.useState({
    firstname: '',
    lastname: '',
    displayName: '',
    companyname: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zipcode: '',
    telephone: '',
    email: '',
    autopayMTC: false,
    autopayAll: false,
    contractor: false,
    builder: false,
    government: false,
    HOA: false,
    doNotEmail: false,
    paperInvoice: false,
    jobSiteAddress: '',
    jobSiteCity: '',
    jobSiteZip: '',
    jobSiteIsBilling: false
  })
  const [showDupesModal, setShowDupesModal] = React.useState(false)
  const [dupes, setDupes] = React.useState([])
  const [activeEntity, setEntity] = React.useState({})

  React.useEffect(() => {
    if (props.user.defaultEntity) {
      // console.log('DEFAULT ENTITY: ', props.user.defaultEntity)
      let defaultEntity = null
      if (props.user.entities && props.user.entities.length) {
        if (props.user.entities.length > 1) {
          // console.log('Multiple Entitie', props.user.entities)
          let index = props.user.entities.findIndex(item => (item._id === props.user.defaultEntity))
          if (index > -1) {
            defaultEntity = props.user.entities[index]
          }
          // props.user.entities.map(item => {
          //   if (item._id === props.user.defaultEntity) {
          //     defaultEntity = item
          //   } 
          // })
          if (defaultEntity && defaultEntity._id) {
            // console.log('Default Entity: ', defaultEntity)
            setEntity(defaultEntity)
            setState({...state, entity: defaultEntity })
          }
        } else {
          setEntity(props.user.entities[0])
          setState({...state, entity: props.user.entities[0] })
        }

      } else {
        // console.log('No entitiees....')
      }
    } else {
      // console.log('NO Default ENTITY')
      // let defaultEntity = null
      if (props.user.entities && props.user.entities.length) {
        // console.log('Set entity', props.user.entities[0])
        setEntity(props.user.entities[0])
        setState({...state, entity: props.user.entities[0] })
      }
    }
  }, [props])

const handleClose = () => {
  // console.log('Closing DepositModal Modal')
  props.closeModal()
}

const handleSave = () => {
  setLoading(true)
  // console.log('Save this Client!!!', state)
    let obj = {
      client: state,
      user: props.user,
      assignedEntity: activeEntity._id
    }
    // console.log('Send this Deposit to The Server', obj)
    axios({
      method: 'post',
      url:  `${props.url}/api/customer/addNew`,
      data: obj
    }).then(response => {
      setLoading(false)
      // console.log('Axios response from Add Customer: ', response)
      if (response && response.data && response.data._id) {
        // console.log('We have a new client!!', response.data)
        props.notification({
          type: 'success',
          title: 'Customer Created!',
          message: 'Customer Was Created Successfully'
        })
        setState({...state,
          firstname: '',
          lastname: '',
          displayName: '',
          companyname: '',
          address: '',
          address2: '',
          city: '',
          state: '',
          zipcode: '',
          telephone: '',
          email: '',
          autopayMTC: false,
          autopayAll: false,
          contractor: false,
          builder: false,
          government: false,
          HOA: false,
          doNotEmail: false,
          paperInvoice: false,
          jobSiteAddress: '',
          jobSiteCity: '',
          jobSiteZip: '',
          jobSiteIsBilling: false
        })
        // handleClose('success', response.data)
        props.updateParent({client: response.data})
      } else if (response.data.possibleDuplicates) {
        props.notification({
          type: 'warning',
          title: 'Possible Duplicate Clients',
          message: `${response.data.message}`
        })
        setDupes(response.data.possibleDuplicates)
        setShowDupesModal(true)
      } else {
        props.notification({
          type: 'warning',
          title: 'Unknown Error',
          message: 'We Do Not Know Why This Shows Up...'
        })
      }
  })
}

const handleUpdateVisibleEntity = (activeEntity) => {
  // console.log('Entity to assigN: ', activeEntity)
  setEntity(activeEntity)
  setState({...state, entity: activeEntity._id })
}

const dupesModalResponse = async (type, data) => {
  if (type === 'Close') setShowDupesModal(false)
  if (type === 'Saved') {
    setShowDupesModal(false)
    props.updateParent({client: data})
  }
}

return (
  <div className={classes.root}>
    <DuplicatesModal open={showDupesModal} dupes={dupes} newClient={state} updateParent={dupesModalResponse} />
    <Dialog open={props.open} fullWidth={false} onClose={handleClose} maxWidth='sm' aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add New Client</DialogTitle>
        <DialogContent >
          {
            (props.user && props.user.entities && props.user.entities.length > 1) ? (
              <React.Fragment>
                <DialogContentText>
                  Please Choose The Customer's Entity
                </DialogContentText>
                <Grid item xs={12} lg={12} style={{padding: 10}}>
                  <ChooseEntityComponent allowAll={false} defaultEntity={activeEntity} title={'Assigned Entity'} user={props.user} updateParent={handleUpdateVisibleEntity} />
                    { (activeEntity && activeEntity._id) ? (
                      <div style={{display: 'flex', justifyContent: 'center'}}>
                        <img alt="Logo" src={`${process.env.PUBLIC_URL}/${activeEntity._id}_Logo.png`} style={{margin: '5px', padding: '5px', maxWidth: 220, maxHeight: 100}} /> 
                      </div>
                      
                    ) : '' }
                </Grid>
              </React.Fragment>
          
            ) : (
              (activeEntity && activeEntity._id) ? (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <img alt="Logo" src={`${process.env.PUBLIC_URL}/${activeEntity._id}_Logo.png`} style={{margin: '5px', padding: '5px', maxWidth: 220, maxHeight: 100}} /> 
                </div>
                
              ) : '' 
            )
          }
          <DialogContentText>
            Please Provide Customer Information
          </DialogContentText>
          <Grid container>
            <Grid item xs={12} lg={12}>
           
            </Grid>
            <Grid item lg={12} xs={12} > 
                  Choose Individual or Business: <br />
                  <FormControlLabel
                        control={
                          <Switch
                            checked={individual}
                            onChange={() => setIndividual(!individual)}
                            name="Individual"
                            color="primary"
                          />
                        }
                        label={individual ? 'Individual' : 'Business'}
                      />
                  </Grid>
                  <Grid item lg={12}>
                      <h3>Customer Information</h3>
                    </Grid>
                  { individual ? 
                    (
                      <React.Fragment>
                      <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItem}> 
                          <TextField
                            autoFocus
                            label="Customer First Name"
                            onChange={ e => setState({...state, firstname: e.target.value })}
                            name="customerfirstname"
                            value={ state.firstname }
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} className={classes.gridItem}> 
                                    
                          <TextField
                          
                            label="Customer Last Name"
                            onChange={ e => setState({...state, lastname: e.target.value })}
                            name="customerlastname"
                            value={ state.lastname }
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                        </React.Fragment>
                      ) : (
                        <Grid item lg={12} xs={12} className={classes.gridItem}> 
                                    
                          <TextField
                          autoFocus
                            label="Company Name"
                            onChange={ e => setState({...state, companyname: e.target.value })}
                            name="companyname"
                            value={ state.companyname }
                            margin="normal"
                            fullWidth
                          />
                        </Grid>
                      )
                    }
                    <Grid item lg={12}>
              <TextField
                className={classes.paddedStuff}
                margin="dense"
                id="DisplayName"
                label="Display Name"
                type="text"
                onChange={(e) => setState({...state, displayName: e.target.value})}
                value={state.displayName}
                fullWidth
            />
             <TextField
                className={classes.paddedStuff}
                margin="dense"
                id="Email"
                label="Email"
                type="text"
                onChange={(e) => setState({...state, email: e.target.value})}
                value={state.email}
                fullWidth
            />
             <TextField
                className={classes.paddedStuff}
                margin="dense"
                id="Telephone"
                label="Telephone Number"
                type="telephone"
                onChange={(e) => setState({...state, telephone: e.target.value})}
                value={state.telephone}
                fullWidth
            />
            </Grid>
            
            <Grid item lg={12} xs={12} className={classes.gridItem}> 
            <strong>Billing Address</strong>
                        <TextField
                      label="Billing Address"
                      fullWidth
                      value= {state.address}
                        onChange={ e => setState({...state, address:e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />
                      <TextField
                      label="Billing Address (2nd Line)"
                      fullWidth
                      value= {state.address2}
                        onChange={ e => setState({...state, address2: e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />
                    </Grid>
                    <Grid item lg={6} xs={6} className={classes.gridItem}>
                      <TextField
                        label="Billing City"
                        fullWidth
                        value= {state.city}
                        onChange={ e => setState({...state, city:e.target.value } ) }
                        // variant="outlined"
                        // value= {state.service.time}
                        // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                        />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                      <TextField
                        label="Billing State"
                        fullWidth
                        value= {state.state}
                        onChange={ e => setState({...state, state:e.target.value } ) }
                      />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                    <TextField
                        label="Billing Zipcode"
                        fullWidth
                        // type="number"
                        // variant="outlined"
                        value= {state.zipcode}
                        onChange={ e => setState({...state, zipcode:e.target.value } ) }
                        />
                    </Grid>
          </Grid>
          <Grid container>
            <strong>Job Site Details</strong>
            <Grid item lg={12} xs={12} className={classes.gridItem}>
              <FormControlLabel
                    control={<Checkbox checked={state.jobSiteIsBilling} onChange={ e => setState({...state, jobSiteIsBilling: !state.jobSiteIsBilling } ) } name="jobSiteSame" />}
                    label="Same As Billing Address"
                  />
            </Grid>
            { state.jobSiteIsBilling ? '' : (
              <React.Fragment>
                <Grid item lg={12} xs={12} className={classes.gridItem}> 
                        <TextField
                      label="Job Site Address"
                      fullWidth
                      value= {state.jobSiteAddress}
                        onChange={ e => setState({...state, jobSiteAddress:e.target.value } ) }
                      // variant="outlined"
                      // value= {state.service.time}
                      // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                      // defaultValue="Default Value"
                      />
                    </Grid>
                    <Grid item lg={6} xs={6} className={classes.gridItem}>
                      <TextField
                        label="Job Site City"
                        fullWidth
                        value= {state.jobSiteCity}
                        onChange={ e => setState({...state, jobSiteCity:e.target.value } ) }
                        // variant="outlined"
                        // value= {state.service.time}
                        // onChange={ e => handleServiceChange(state.service, 'time', e.target.value, state.serviceIndex) }
                        // defaultValue="Default Value"
                        />
                    </Grid>
                    <Grid item lg={3} xs={6} className={classes.gridItem}>
                    <TextField
                        label="Job Site Zipcode"
                        fullWidth
                        // type="number"
                        // variant="outlined"
                        value= {state.jobSiteZip}
                        onChange={ e => setState({...state, jobSiteZip:e.target.value } ) }
                        />
                    </Grid>
            </React.Fragment>
            )}
          </Grid>
          <Grid container>
              <Grid item lg={12} md={12} sm={12} xs={12} className={classes.gridItem}>
              <h3>Customer Settings</h3>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
              <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={state.autopayAll} onChange={ e => setState({...state, autopayAll: !state.autopayAll } ) } name="autopayAll" />}
                    label="Autopay (ALL)"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.autopayMTC} onChange={ e => setState({...state, autopayMTC: !state.autopayMTC } ) } name="autopayMTC" />}
                    label="Autopay (Maintenance Only)"
                  />
              </FormGroup>
              <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={state.paperInvoice} onChange={ e => setState({...state, paperInvoice: !state.paperInvoice } ) } name="paperInvoice" />}
                    label="Paper Invoice"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.doNotEmail}onChange={ e => setState({...state, doNotEmail: !state.doNotEmail } ) } name="doNotEmail" />}
                    label="Do NOT Email"
                  />
              </FormGroup>
              <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={state.HOA} onChange={ e => setState({...state, HOA: !state.HOA } ) } name="HOA" />}
                    label="HOA"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.contractor} onChange={ e => setState({...state, contractor: !state.contractor } ) } name="contractor" />}
                    label="Contractor"
                  />
              </FormGroup>
              <FormGroup row>
                <FormControlLabel
                    control={<Checkbox checked={state.builder} onChange={ e => setState({...state, builder: !state.builder } ) } name="Builder" />}
                    label="Builder"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={state.government} onChange={ e => setState({...state, government: !state.government } ) } name="Government" />}
                    label="Government"
                  />
              </FormGroup>
              </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setState({...state, editJobName: false})} color="primary">
            Cancel
          </Button> */}
          <Button color="primary" disabled={loading || (!activeEntity || !activeEntity._id) || !state.address || !state.zipcode || !state.city || !state.state || (!state.jobSiteIsBilling && (!state.jobSiteAddress || !state.jobSiteCity || !state.jobSiteZip))} onClick={handleSave}>
            {
              loading ? <CircularProgress /> : 'Save'
            }
          </Button>
        </DialogActions>
      </Dialog>
  </div>
  )
  }


  const mapStateToProps = (state) => {
    return {
      url: state.url,
      user: state.user,
      notification: state.notification
    }
  }

  export default React.memo(connect(mapStateToProps)(NewCustomerModal))