import React, { useState, useEffect } from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';

const EmojiRating = ({ onChange, initialRating }) => {
  const [rating, setRating] = useState(initialRating);

  const emojis = [
    { icon: SentimentVeryDissatisfiedIcon, label: 'Very Dissatisfied' },
    { icon: SentimentDissatisfiedIcon, label: 'Dissatisfied' },
    { icon: SentimentSatisfiedIcon, label: 'Neutral' },
    { icon: SentimentSatisfiedAltIcon, label: 'Satisfied' },
    { icon: SentimentVerySatisfiedIcon, label: 'Very Satisfied' }
  ];

  const handleRating = (value) => {
    setRating(value);
    if (onChange) {
      onChange(value);
    }
  };
  useEffect(() => {
    setRating(initialRating)
  }, [initialRating])


  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      {emojis.map((emoji, index) => (
        <Tooltip key={index} title={emoji.label}>
          <IconButton
            onClick={() => handleRating(index + 1)}
            style={{
              color: rating >= index + 1 ? '#ffc107' : '#bdbdbd',
              transform: rating >= index + 1 ? 'scale(1.1)' : 'scale(1)',
              transition: 'all 0.2s'
            }}
          >
            <emoji.icon />
          </IconButton>
        </Tooltip>
      ))}
    </div>
  );
};

export default EmojiRating;