export const setUrl = (url) => {
  return {
    type: 'setUrl',
    url
  }
}

export const setClient = (client) => {
  // console.log('SET CLIENT CALLED!!!', client)
  return {
    type: 'setClient',
    client
  }
}

export const setClients = (data) => {
  // console.log('SET CLIENT CALLED!!!', client)
  return {
    type: 'setClients',
    data
  }
}

export const setSalesReps = (data) => {
  // console.log('SET Sales Reps CALLED!!!', data)
  return {
    type: 'setSalesReps',
    data
  }
}

export const setUsers = (data) => {
  // console.log('SET USERS CALLED!!!', data)
  return {
    type: 'setUsers',
    data
  }
}

export const setEmployees = (data) => {
  // console.log('SET EMPLOYEES CALLED!!!', data)
  return {
    type: 'setEmployees',
    data
  }
}

export const setTasks = (data) => {
  // console.log('SET EMPLOYEES CALLED!!!', data)
  return {
    type: 'setTasks',
    data
  }
}

export const setActiveJob = (job) => {
  // console.log('SET Active Job CALLED!!!', job)
  return {
    type: 'setActiveJob',
    job
  }
}

export const setCrew = (crew) => {
  return {
    type: 'setCrew',
    crew
  }
}

export const setCrews = (crews) => {
  // console.log('Set Crews:', crews)
  return {
    type: 'setCrews',
    crews
  }
}

export const setAuth = (authenticated) => {
  return {
    type: 'setAuth',
    authenticated
  }
}

export const setGPS = (position) => {
// console.log('Set GPS: ', position)
  return {
    type: 'setGPS',
    position
  }
}

export const setNotifications = (notification) => {
  return {
    type: 'setNotifications',
    notification
  }
}

export const setUser = (user) => {
  return {
    type: 'setUser',
    user
  }
}

export const setPaypal = (paypal) => {
  return {
    type: 'setPaypal',
    paypal
  }
}

export const setTheme = (theme) => {
  return {
    type: 'setTheme',
    theme
  }
}

export const setServices = (data) => {
  return {
    type: 'setServices',
    data
  }
}
export const setPerms = (perms) => {
  return {
    type: 'setPerms',
    perms
  }
}

export const setSnoutWeevilButtonActive = (snoutWeevilButtonActive) => {
  // console.log('Set Snout Weevil', snoutWeevilButtonActive)
  return {
    type: 'setSnoutWeevilButtonActive',
    snoutWeevilButtonActive
  }
}

export const setFertilizationButtonActive = (fertilizationButtonActive) => {
  // console.log('Set that fert button', fertilizationButtonActive)
  return {
    type: 'setFertilizationButtonActive',
    fertilizationButtonActive
  }
}

export const setWinterFrostProtectionButtonActive = (winterFrostProtectionButtonActive) => {
  // console.log('Set that fert button', fertilizationButtonActive)
  return {
    type: 'setWinterFrostProtectionButtonActive',
    winterFrostProtectionButtonActive
  }
}

export const setEntities = (entities) => {
  return {
    type: 'setEntities',
    entities
  }
}

export const setContacts = (contacts) => {
  return {
    type: 'setContacts',
    contacts
  }
}

export const setProjects = (projects) => {
  return {
    type: 'setProjects',
    projects
  }
}

export const setScopes = (scopes) => {
  return {
    type: 'setScopes',
    scopes
  }
}

export const setConfigs = (configs) => {
  return {
    type: 'setConfigs',
    configs
  }
}
